import React, { Component } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../../components";
import { Column, ConfirmDialog, Button, EmptyInfo } from "../../../common";
import { notificationAction } from "../../../actions";
import "./createcollaboration.scss";
import isEmpty from "lodash/isEmpty";
import {
  addCollaborationApi,
  getAllOpportunitiesApi,
  getOpportunityIprosResumeApi,
  getCurrenciesApi
} from "../common/collaborationApi";
import moment from "moment";
import map from "lodash/map";
import CreateNewCollaboration from "../searcher/CreateCollaboration/components/CreateNewCollaboration";
import UserDetail from "../common/components/UserDetail";
import { onStateChangeAction } from "./SearcherCollaborationCreateAction";

class CreateCollaboration extends Component {
  // state = {
  //   isLoading: false,
  //   collaborationForm: {},
  //   opportunityList: [],
  //   iprosList: [],
  //   selectedIpro: {},
  //   collaborationForm: {
  //     invalidRequestId: false
  //   }
  // };
  state = {
    Currencies: []
  }

  componentDidMount() {
    this.getCurrencies();
    this.getOpportunities();
    this.networkToCollaboration();
  }

  networkToCollaboration = () => {
    const RequestId = JSON.parse(localStorage.getItem("OpportunityId"));
    const ResumeId = JSON.parse(localStorage.getItem("ResumeId"));
    if (RequestId && ResumeId) {
      const selectedOpportunity = {
        RequestId: RequestId,
        value: RequestId
      }
      this.handleFormSelectChange("OpprtunityId", selectedOpportunity);
      const { collaborationForm } = this.props;
      this.props.onStateChangeAction({
        collaborationForm: {
          ...collaborationForm,
          invalidRequestId: false,
          Request: RequestId,
          RequestId,
          invalidResumeId: false,
          ResumeId: ResumeId,
          ResumeId
        }
      });
    }
  }

  componentWillUnmount() {
    localStorage.removeItem("OpportunityId");
    localStorage.removeItem("ResumeId");
    this.props.onStateChangeAction({
      selectedIpro: {},
      iprosList: []
    });
  }

  getCurrencies(access_token) {
    getCurrenciesApi()
      .then(res => {
        if (res.success) {
          this.setState({
            Currencies: res.items.map(item => ({
              ...item,
              value: item.CurrencyId,
              label: item.Name
            }))
          });
        }
      })
      .catch(err => console.log("Err ", err));
  }

  getOpportunities = () => {
    getAllOpportunitiesApi(0).then(data => {
      if (data.success) {
        const opportunityList = data.items.Sent.map(item => ({
          ...item,
          value: item.RequestId,
          label: item.RequestName
        }));
        this.props.onStateChangeAction({ opportunityList });
      }
    });
  };
  getOpportunityIProsResume = requestId => {
    getOpportunityIprosResumeApi(requestId).then(data => {
      if (data.success) {
        const iprosList = map(data.items.user, item => {
          const newItem = {
            ...item,
            value: item.ResumeId,
            label: item.UserName
          };

          return newItem;
        });
        this.props.onStateChangeAction({
          iprosList
        });
      }
    });
  };

  handleFormSelectChange = (name, selectedOption) => {
    if (!selectedOption) return;
    const { collaborationForm } = this.props;
    const { value, RequestId } = selectedOption;
    if (name === "OpprtunityId") {
      if (RequestId) {
        this.getOpportunityIProsResume(RequestId);
        this.props.onStateChangeAction({
          collaborationForm: {
            ...collaborationForm,
            invalidRequestId: false,
            Request: RequestId,
            RequestId
          }
        });
        return;
      }
    }
    const { ResumeId } = selectedOption;
    if (name === "ResumeId") {
      if (ResumeId) {
        this.props.onStateChangeAction({
          collaborationForm: {
            ...collaborationForm,
            invalidResumeId: false,
            Resume: ResumeId,
            ResumeId
          },
          selectedIpro: this.props.iprosList.find(
            item => item.ResumeId === ResumeId
          )
        });
        return;
      }
    }
    if (name == "HourlyRateType") {
      if (value) {
        this.props.onStateChangeAction({
          collaborationForm: {
            ...collaborationForm,
            invalidHourlyRateType: false,
            HourlyRateType: value,
            value
          }
        });
        return;
      }
    }
    if (name == "DurationType") {
      if (value) {
        this.props.onStateChangeAction({
          collaborationForm: {
            ...collaborationForm,
            invalidDurationType: false,
            DurationType: value,
            value
          }
        });
        return;
      }
    }
  };

  handleDateChange = d => {
    const { collaborationForm } = this.props;
    try {
      this.props.onStateChangeAction({
        collaborationForm: {
          ...collaborationForm,
          invalidStartDate: false,
          StartDate: d,
          d
        }
      });
    } catch (e) {
    }
  };

  handleSliderChange = value => {
    const { collaborationForm } = this.props;
    if (value) {
      this.props.onStateChangeAction({
        collaborationForm: {
          ...collaborationForm,
          Duration: value,
          value
        }
      });
    }
  };

  handleFormFieldChange = e => {
    const { name, value } = e.target;
    if (name === "Title") {
      this.validateField({ name, value });
      return;
    }
    if (name == "Description") {
      this.validateField({ name, value });
      return;
    }
    if (name === "HourlyRate") {
      if (isNaN(value)) return;
      this.validateField({ name, value });
    }
  };

  validateSelect = name => {
    const { collaborationForm } = this.props;
    this.props.onStateChangeAction({
      collaborationForm: {
        ...collaborationForm,
        [`invalid${name}`]: true
      }
    });
  };

  validateField = ({ name, value }) => {
    const { collaborationForm } = this.props;

    this.props.onStateChangeAction({
      collaborationForm: {
        ...collaborationForm,
        [name]: value,
        [`invalid${name}`]: !value
      }
    });
  };

  makeMandatoryFieldRed = () => {
    const { collaborationForm } = this.props;
    this.props.onStateChangeAction({
      collaborationForm: {
        ...collaborationForm,
        invalidTitle: !undefined,
        Title: undefined,

        invalidStartDate: !undefined,
        StartDate: undefined,

        invalidDurationType: !undefined,
        DurationType: undefined,

        invalidHourlyRate: !undefined,
        HourlyRate: undefined,

        invalidRequestId: !undefined,
        RequestId: undefined,

        invalidResumeId: !undefined,
        ResumeId: undefined,

        invalidHourlyRateType: !undefined,
        HourlyRateType: undefined
      }
    });
  };

  handleCollaborationSave = () => {
    const { labels } = this.props;
    const { collaborationForm } = this.props;
    const { collTitleRequired } = labels;
    const {
      Title = "",
      HourlyRate = "",
      RequestId = "",
      ResumeId = "",
      Description = "",
      HourlyRateType = "",
      StartDate = "",
      DurationType = "",
      Duration = "4"
    } = collaborationForm;
    var collaboration = {
      Title,
      HourlyRate,
      HourlyRateType,
      RequestId,
      ResumeId,
      Description,
      StartDate,
      DurationType,
      Duration
    };

    if (
      isEmpty(Title) &&
      !StartDate &&
      !DurationType &&
      isEmpty(HourlyRate) &&
      !HourlyRateType &&
      !RequestId &&
      !ResumeId
    ) {
      const info = {
        message: labels.COLLABORATION_CREATE_REQURIED_FIELD_MESSAGE,
        status: "error"
      };
      this.makeMandatoryFieldRed();
      this.props.notificationAction(info);
      return;
    }

    if (isEmpty(Title)) {
      const info = {
        message: collTitleRequired,
        status: "error"
      };
      this.validateField({ name: "Title", value: Title });
      this.props.notificationAction(info);
      return;
    }

    if (!StartDate) {
      const info = {
        message: labels.collStartDateRequired,
        status: "error"
      };
      this.validateField({ name: "StartDate", value: StartDate });
      this.props.notificationAction(info);
      return;
    }
    if (!DurationType) {
      const info = {
        message: labels.collDurationTypeRequired,
        status: "error"
      };
      this.validateField({ name: "DurationType", value: DurationType });
      this.props.notificationAction(info);
      return;
    }
    if (isEmpty(HourlyRate)) {
      const info = {
        message: "Hourly rate required",
        status: "error"
      };
      this.validateField({ name: "HourlyRate", value: HourlyRate });
      this.props.notificationAction(info);
      return;
    }
    if (!HourlyRateType) {
      const info = {
        message: labels.collCurrancyTypeRequired,
        status: "error"
      };
      this.validateField({ name: "HourlyRateType", value: HourlyRateType });
      this.props.notificationAction(info);
      return;
    }
    if (!RequestId) {
      const info = {
        message: labels.collOppRequired,
        status: "error"
      };
      this.validateField({ name: "RequestId", value: RequestId });
      this.props.notificationAction(info);
      return;
    }
    if (!ResumeId) {
      const info = {
        message: labels.collIproRequired,
        status: "error"
      };
      this.validateField({ name: "ResumeId", value: ResumeId });
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({ isLoading: true });
    const duration = Duration + " " + DurationType;
    collaboration.Duration = duration;
    collaboration.Decription = Description;
    collaboration.StartDate = moment(StartDate).format("MM/DD/YYYY");
    addCollaborationApi({ collaboration })
      .then(() => {
        const info = {
          message: labels.collSuccessfullySent,
          status: "success"
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({
          isLoading: false,
          collaborationForm: {},
          selectedIpro: {},
          iprosList: []
        });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
        const info = {
          message: "Error",
          status: "error"
        };
        this.props.notificationAction(info);
      });
    return;
  };
  handleCreateNewClick = () => {
    this.props.onStateChangeAction({
      isLoading: false,
      collaborationForm: {},
      selectedIpro: {},
      iprosList: []
    });
  }
  render() {
    const { labels, isHelpActive } = this.props;
    const {
      collaborationForm,
      formCollapsed,
      detailCollapsed,
      dialogMessage,
      isLoading,
      opportunityList,
      iprosList,
      selectedIpro
    } = this.props;
    return (
      <PageWrapper className="create-collaboration-searcher-page">
        {dialogMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleYesClick}
              />
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              />
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={formCollapsed} className="col-1">
          <Column.Collapsed
            collapse={formCollapsed}
            onClick={() =>
              this.props.onStateChangeAction({ formCollapsed: false })
            }
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            text={labels.COLLABORATION_CREATE_TITLE}
            tooltipButton={
              labels.COLLABORATION_CREATE_SECTION_EXPAND_BUTTON_TOOLTIP
            }
            tooltipHelp={labels.COLLABORATION_CREATE_SECTION_EXPAND_BUTTON_HELP}
            testId="collaboration-form-expand-btn"
            testIdHelp="collaboration-form-expand-help-btn"
          />
          <Column.Head>
            <Column.HeaderTitle isActive={true}>
              {labels.COLLABORATION_CREATE_TITLE}
            </Column.HeaderTitle>
            <Button
              className="AddBtn"
              onClick={this.handleCreateNewClick}
              tooltipButton={labels.ToolTipCollaborationCreateButton}
              tooltipHelp={labels.HlpTooltipCollaborationCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId="add-new-company-btn"
              testIdHelp="add-new-company-help-btn"
            />
            <Button
              onClick={() =>
                this.props.onStateChangeAction({ formCollapsed: true })
              }
              className="collapseBtn"
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.COLLABORATION_CREATE_SECTION_COLLAPSE_BUTTON_TOOLTIP
              }
              tooltipHelp={
                labels.COLLABORATION_CREATE_SECTION_COLLAPSE_BUTTON_HELP
              }
              testId="collaboration-form-collapse-btn"
              testIdHelp="collaboration-form-collapse-help-btn"
            />
          </Column.Head>
          <Column.Body>
            <CreateNewCollaboration
              collaborationForm={collaborationForm}
              onCollaborationSave={this.handleCollaborationSave}
              onFormSelectChange={this.handleFormSelectChange}
              labels={labels}
              isLoading={isLoading}
              onFormFieldChange={this.handleFormFieldChange}
              onDateChange={this.handleDateChange}
              onSliderChange={this.handleSliderChange}
              opportunityList={opportunityList}
              iprosList={iprosList}
              Currencies={this.state.Currencies}
            />
          </Column.Body>
        </Column>
        <Column collapse={detailCollapsed} className="col-2">
          <Column.Collapsed
            collapse={detailCollapsed}
            onClick={() =>
              this.props.onStateChangeAction({ detailCollapsed: false })
            }
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            text={labels.COLLABORATION_IPRO_TITLE}
            tooltipButton={
              labels.COLLABORATION_IPRO_SECTION_EXPAND_BUTTON_TOOLTIP
            }
            tooltipHelp={labels.COLLABORATION_IPRO_SECTION_EXPAND_BUTTON_HELP}
            testId="collaboration-form-ipro-expand-btn"
            testIdHelp="collaboration-form-ipro-expand-help-btn"
          />
          <Column.Head>
            <Column.HeaderTitle isActive={true}>
              {labels.COLLABORATION_IPRO_TITLE}
            </Column.HeaderTitle>
            <Button
              className="collapseBtn"
              onClick={() =>
                this.props.onStateChangeAction({ detailCollapsed: true })
              }
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.COLLABORATION_IPRO_SECTION_COLLAPSE_BUTTON_TOOLTIP
              }
              tooltipHelp={
                labels.COLLABORATION_IPRO_SECTION_COLLAPSE_BUTTON_HELP
              }
              testId="collaboration-form-ipro-collapse-btn"
              testIdHelp="collaboration-form-ipro-collapse-help-btn"
            />
          </Column.Head>
          <Column.Body className="flex">
            {isEmpty(selectedIpro) ? (
              <EmptyInfo>{labels.EMPTY_IPRO_DETAIL}</EmptyInfo>
            ) : (
              <UserDetail selectedUser={selectedIpro} labels={labels} />
            )}
          </Column.Body>
        </Column>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, createCollaboration }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, ...createCollaboration };
};
export default connect(
  mapStateToProps,
  { notificationAction, onStateChangeAction }
)(CreateCollaboration);
