import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  show: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.phillipSearcherModal.SHOW_MODAL:
      return {
        ...state,
        show: action.payload,
      };

    default:
      return state;
  }
};
