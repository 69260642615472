import React from "react";
import { Modal, Input, Space } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { EmptyInfo } from "../../../../common";
import "./style.scss";
import ResumeListComponent from "../../../../common/resumeResumeList/resumeList";
const { Search } = Input;
const MobileFilter = ({
  isModalOpen,
  setIsModalOpen,
  globalStateShortlist,
  state,
  setState,
  setShowModal,
  menuOpportunities,
}) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        className="new-search-resume-main for-mobile-modal-shortlist"
        style={{ top: 20 }}
        zIndex={100}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <div style={{ height: "100%", marginTop: "5px" }}>
          <div className="h-100 d-flex flex-column">
            <div className="column-secend">
              <ResumeListComponent
                resumeList={globalStateShortlist?.shortListResume}
                state={state}
                selected={state}
                setSelected={setState}
                setShowModal={setShowModal}
                opportunityDropdown={menuOpportunities}
                name="shortlist"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default MobileFilter;
