import { Button, Input, Modal, Space } from "antd";
import React from "react";
import "../NewResumeSearch/search.scss";
import { useSelector } from "react-redux";
import { useState } from "react";
import closeIcon from '../../assets/images/Icons/close-new.png'
import ImageModal from '../../assets/images/shortlist-popup-edit.png'

const ShortListModal = ({ open, setOpen, handleCreateNew, handleRename }) => {
  const label = useSelector((state) => state?.systemLabel?.labels);
  const [error, setError] = useState(false);

  const handleCancel = () => {
    setOpen((st) => ({
      ...st,
      open: false,
      id: "",
      value: "",
    }));
  }

  return (
    <>
      <Modal
        zIndex={105}
        open={open?.open}
        onCancel={() => {
          handleCancel()
        }}
        centered
        footer={null}
        closable={false}
        className={`new-design-modal shortlist-modal `}
      // footer={
      //   <Space size={[12, 8]}>
      //     <button
      //       className="create-button background"
      //       onClick={() => {
      //         if (open?.value == "") {
      //          return setError(true);
      //         }
      //         if (open?.title == "Edit") {
      //           handleRename();
      //         } else handleCreateNew();
      //       }}
      //     >
      //       Save
      //     </button>
      //   </Space>
      // }
      >
        <div className="text-center mb-3 header-modal">
          <h5>{`${open?.title || ""} Shortlist`}</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="images-icon">
          <img src={ImageModal} alt="" />
          {open?.title == "Edit" ?
            <p className="paragraph">Edit your shortlist name</p>
            :
            <p className="paragraph">Please enter shortlist name to proceed.</p>
          }
        </div>
        <div className="form">
          <Input
            placeholder="Enter shortlist name"
            value={open?.value || ""}
            size="large"
            autoFocus={true}
            onChange={(e) => {
              e.persist();
              setOpen((St) => ({
                ...St,
                value: e.target.value,
              }));
              setError(false);
            }}
            style={
              error
                ? {
                  border: "1px solid red",
                }
                : {}
            }
          />
          <Button
            type="primary"
            block
            size="large"
            htmlType="submit"
            className="login-form-button mt-3"
            onClick={() => {
              if (open?.value == "") {
                return setError(true);
              }
              if (open?.title == "Edit") {
                handleRename();
              } else handleCreateNew();
            }}
          >
            {open?.title == "Edit" ? "Save" : "Create"}
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default ShortListModal;
