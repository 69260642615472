import React, { useState, useEffect } from "react";
import MenuDots from "../../../assets/newResume/menuDots";
import Eye from "../../../assets/newResume/eye";
import Trash from "../../../assets/newResume/trash";
import { Button, Popover } from "antd";
import CustomSelect from "./select";

const MenuOptions = ({
  value,
  options,
  onLevelClick,
  onDeleteIconClick,
  componentName,
  valueCertificete,
  onEyeIconClick,
  hideEye,
  handleScroll,
  suggestionSkill,
  skill,
  propValue,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  // useEffect(() => {
  //   if(showOptions){
  //     if (typeof window != 'undefined' && window.document) {
  //       document.getElementsByClassName("newResume").style.overflow="hidden"
  //     }

  //   }
  //   else {
  //     if (typeof window != 'undefined' && window.document) {
  //       document.getElementsByClassName("newResume").style.overflow="scroll"
  //     }

  //   }
  // }, [showOptions])

  return (
    <Popover
      placement="bottom"
      open={showOptions}
      content={
        <div style={{ textAlign: "left" }}>
          {componentName === "Certification" ? (
            <div>
              <CustomSelect
                options={options}
                width="100%"
                margin="0px"
                background="#fff"
                value={valueCertificete}
                handleChange={onLevelClick}
              />
            </div>
          ) : (
            <div>
              {options.map((item, ind) => (
                <p
                  style={{
                    color: value === item && "#8f82f5",
                    wordBreak:'keep-all'
                    //textAlign: "center",
                  }}
                  key={ind}
                  onClick={() => {
                    setShowOptions(!showOptions);
                    onLevelClick(item);
                  }}
                >
                  {item}
                </p>
              ))}
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* {!hideEye && (
              <span
                style={{ paddingRight: "10px" }}
               
                onClick={() => {
                  setShowOptions(!showOptions);
                  onEyeIconClick();
                  handleScroll();
                }}
              >
                <Eye  color={suggestionSkill === skill[propValue] ? "#8F82F5":""} />
              </span>
            )} */}
            <div
              className="d-flex justify-content-center align-items-between"
              onClick={onDeleteIconClick}
            >
              <span style={{ color: "red" }}>Delete</span>
              {/* <Trash  style={{width:"10px", marginLeft:10}}/> */}
            </div>
          </div>
        </div>
      }
      trigger="click"
    >
      <MenuDots onClick={() => setShowOptions((prev) => !prev)} />
    </Popover>
  );
};

export default MenuOptions;
