import axios from "axios";
import { ApiUrl } from "../../api";
export const getResumesApi = () => {
  return axios.get(ApiUrl.Resume.GetMyResume).then(({ data }) => data);
};

export const getMyPDFResumeApi = fileData => {
  return axios
    .post(ApiUrl.Resume.GetMyPDFResume, { fileData })
    .then(({ fileData }) => fileData);
};
