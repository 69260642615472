import React, { Children } from "react";
import moment from "moment";
import { DatePicker, Select } from "../../../../../common";
import { 
  GetCollaborationExpenseDetailApi,
  GetCollaborationTimeDetailApi,
  GetPoolTimeDetailApi,
  GetPoolExpenseDetailApi 
} from "../../../snapshotApi";
import { CustomInputNew } from "../../../../Tictell/views/components/CustomInput";

class TimeAndExpenses extends React.Component {
  render() {
    const{
      labels,
      options,
      selectedOption,
      StartDate,
      EndDate,
      TimeDetail,
      ExpenseDetail,
      PoolTimeDetail,
      PoolExpenseDetail,
      sharedPoolInfo,
      handleSharePoolDelete,
      handlePoolShare,
      handleSharePoolInputChange,
      handleSharePool
    } = this.props;
    const{
      Dateformatelabel,
      SNAPSHOT_COLLABORATION_START,
      SNAPSHOT_COLLABORATION_END,
      SNAPSHOT_THIS_COLLABORATION,
      SNAPSHOT_AVG_WEEK,
      SNAPSHOT_THIS_POOL,
      SNAPSHOT_COLLABORATION_SHAREWITH
    } = labels;
    const{
      PoolId,
      SharedInfo,
      IsPoolShared,
      newEmail
    } = sharedPoolInfo;
    return (
      <div className="time-expense-detail">
        <CustomInputNew label={labels?.searcher_snapshot_pool_time_period_label} >
          <Select
              name="Category"
              className={`select-input select-category`}
              value={selectedOption}
              clearable={false}
              searchable={false}
              options={options}
              onChange={this.props.handleOptionChange}
            />
        </CustomInputNew>
        <div className="start-end">
          <CustomInputNew label={SNAPSHOT_COLLABORATION_START} >
            <label className="datepicker-wrapper">
                <DatePicker
                  selected={StartDate && moment(StartDate)}
                  name="expenseDate"
                  dateFormat="MMM Do YYYY"
                  placeholderText={Dateformatelabel}
                  onChange={this.props.handleStartDateChange}
                />
              </label>
          </CustomInputNew>
          <span>-</span>
          <CustomInputNew label={SNAPSHOT_COLLABORATION_END} >
            <label className="datepicker-wrapper">
                <DatePicker
                  selected={EndDate && moment(EndDate)}
                  name="expenseDate"
                  dateFormat="MMM Do YYYY"
                  placeholderText={Dateformatelabel}
                  onChange={this.props.handleEndDateChange}
                />
              </label>
          </CustomInputNew>
        </div>
        <CustomInputNew label={labels?.searcher_snapshot_pool_time_label} >
          <div className="coll-time">
                <div>
                  <label>{SNAPSHOT_THIS_COLLABORATION}</label>
                  <label>{TimeDetail && TimeDetail.TotalTime}</label>
                  <label>{TimeDetail && TimeDetail.TotalEarning}</label>
                </div>
                <div>
                  <label>{SNAPSHOT_AVG_WEEK}</label>
                  <label>{TimeDetail && TimeDetail.AvgWeekTime}</label>
                  <label>{TimeDetail && TimeDetail.AvgWeekEarning}</label>
                </div>
                <div>
                <label>{SNAPSHOT_THIS_POOL}</label>
                  <label>{PoolTimeDetail && PoolTimeDetail.TotalTime}</label>
                  <label>{PoolTimeDetail && PoolTimeDetail.TotalEarning}</label>
                </div>
                <div>
                  <label>{SNAPSHOT_AVG_WEEK}</label>
                  <label>{PoolTimeDetail && PoolTimeDetail.AvgWeekTime}</label>
                  <label>{PoolTimeDetail && PoolTimeDetail.AvgWeekEarning}</label>
                </div>
          </div>
        </CustomInputNew>
        <CustomInputNew label={labels?.searcher_snapshot_pool_expense_label} >
          <div className="coll-expenses">
                <div>
                  <label>{SNAPSHOT_THIS_COLLABORATION}</label>
                  <label>{ExpenseDetail && ExpenseDetail.TotalExpenses}</label>
                </div>
                <div>
                  <label>{SNAPSHOT_AVG_WEEK}</label>
                  <label>{ExpenseDetail && ExpenseDetail.AvgWeekExpenses}</label>
                </div>
                <div>
                  <label>{SNAPSHOT_THIS_POOL}</label>
                  <label>{PoolExpenseDetail && PoolExpenseDetail.TotalExpenses}</label>
                </div>
                <div>
                  <label>{SNAPSHOT_AVG_WEEK}</label>
                  <label>{PoolExpenseDetail && PoolExpenseDetail.AvgWeekExpenses}</label>
                </div>
          </div>
        </CustomInputNew>

        {!IsPoolShared &&  
        <CustomInputNew label={SNAPSHOT_COLLABORATION_SHAREWITH} >
          <div className="new-share">
            <input 
              test-data-id={`sharePoolWithInput`}
              type="text" 
              onChange={handleSharePoolInputChange}
              onBlur={handleSharePoolInputChange} 
              value={newEmail} 
              />
              <button test-data-id={`AddSharePoolWithBtn`} className="add-btn" onClick={()=>handleSharePool()}></button>
            </div>
        {SharedInfo && SharedInfo.map(attach=>(
            <div className={"attach-list-item"} key={attach.UserEmail}>
              <label>{attach.UserEmail}</label>
              <button test-data-id={`DelSharePoolWithBtn`} className="closeBtn" onClick={()=>handleSharePoolDelete(attach)}></button>
            </div>
          ))} 
        </CustomInputNew>}
      </div>
    );
  }
}

export default TimeAndExpenses;
