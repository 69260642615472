import React, { Children } from "react";
import moment from "moment";
import { DatePicker, Select } from "../../../../../common";
import { CustomInputNew } from "../../../../Tictell/views/components/CustomInput";

class CollaborationDetail extends React.Component {
  render() {
    const{
      selectedCollaboration,
      labels,
      handleShareCollaborationDelete,
      handleShareCollaborationInputChange,
      handleCollaborationShare,
      handleCollaborationEndDateChange,
      handleCollaborationExtend,
      isEndDateCalendarOpen,
      handleDateOnBlur
    } = this.props;
    const{
      Dateformatelabel,
      SNAPSHOT_COLLABORATION_OWNER,
      SNAPSHOT_COLLABORATION_START,
      SNAPSHOT_COLLABORATION_END,
      SNAPSHOT_COLLABORATION_COMPENSATION,
      SNAPSHOT_COLLABORATION_WORKPLACE,
      SNAPSHOT_COLLABORATION_DESC,
      SNAPSHOT_COLLABORATION_SHAREWITH
    } = labels;
    const{
      CollaborationId,
      CollaborationOwner,
      StartDate,
      EndDate,
      HourlyRate,
      HourlyRateType,
      CompanyName,
      Decription,
      SharedInfo,
      IsShared,
      newEmail,
      PreviousEndDate
    } = selectedCollaboration;
    return (
      <div className="sense-collaboration-detail">
        {!IsShared &&   
        <div>
          <div className="new-share">
            <input 
              test-data-id={`shareCollabsWithInput`}
              type="text" 
              onChange={handleShareCollaborationInputChange}
              onBlur={handleShareCollaborationInputChange} 
              value={newEmail} 
              />
              <button test-data-id={`AddShareCollabWithBtn`} className="add-btn" onClick={()=>handleCollaborationShare()}></button>
            </div>
        {SharedInfo && SharedInfo.map(attach=>(
            <div className={"attach-list-item"} key={attach.UserEmail}>
              <label>{attach.UserEmail}</label>
              <button test-data-id={`DelShareCollabWithBtn`} className="closeBtn" onClick={()=>handleShareCollaborationDelete(attach)}></button>
            </div>
          ))} 
        </div>}
      </div>
    );
  }
}

export default CollaborationDetail;
