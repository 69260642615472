import { ActionTypes } from "../../../actions";

export const onStateChangeAction = ({
  searchList_rolesList,
  searchList_rolesFetching,
  searchList_skillsList,
  searchList_skillsFetching,
  searchList_keywordsList,
  searchList_keywordsFetching,
  searchList_industriesList,
  searchList_industriesFetching,
  searchList_certificationsList,
  searchList_certificationsFetching,
  searchList_countriesList,
  searchList_countriesFetching,
  searchList_languagesList,
  searchList_languagesFetching,
  searchList_searchList,
  searchList_availibility,
  searchList_dialogMessage,
  actionName = window.actionName
}) => dispatch => {
  searchList_rolesList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_ROLES,
      payload: searchList_rolesList,
      actionName
    });
  searchList_rolesFetching !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_ROLES_FETCHING,
      payload: searchList_rolesFetching,
      actionName
    });
  searchList_skillsList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_SKILLS,
      payload: searchList_skillsList,
      actionName
    });
  searchList_skillsFetching !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_SKILLS_FETCHING,
      payload: searchList_skillsFetching,
      actionName
    });
  searchList_keywordsList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_KEYWORDS,
      payload: searchList_keywordsList,
      actionName
    });
  searchList_keywordsFetching !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_KEYWORDS_FETCHING,
      payload: searchList_keywordsFetching,
      actionName
    });
  searchList_industriesList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_INDUSTRIES,
      payload: searchList_industriesList,
      actionName
    });
  searchList_industriesFetching !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_INDUSTRIES_FETCHING,
      payload: searchList_industriesFetching,
      actionName
    });
  searchList_certificationsList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_CERTIFICATIONS,
      payload: searchList_certificationsList,
      actionName
    });
  searchList_certificationsFetching !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_CERTIFICATIONS_FETCHING,
      payload: searchList_certificationsFetching,
      actionName
    });
  searchList_countriesList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_COUNTRIES,
      payload: searchList_countriesList,
      actionName
    });
  searchList_countriesFetching !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_COUNTRIES_FETCHING,
      payload: searchList_countriesFetching,
      actionName
    });
  searchList_languagesList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_LANGUAGES,
      payload: searchList_languagesList,
      actionName
    });
  searchList_languagesFetching !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_LANGUAGES_FETCHING,
      payload: searchList_languagesFetching,
      actionName
    });
  searchList_searchList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_SEARCH_LIST,
      payload: searchList_searchList,
      actionName
    });
  searchList_availibility !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_AVAILIBILITY,
      payload: searchList_availibility,
      actionName
    });
  searchList_dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST_DIALOG_MESSAGE,
      payload: searchList_dialogMessage,
      actionName
    });
};
