import React, { Component } from "react";
import { PageWrapper } from "../../components";
import { connect } from "react-redux";
import find from "lodash/find";
import { Column, List, Button } from "../../common";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
import "./about.scss";
import { getFaqsApi, getAboutProdooListApi } from "./aboutApi";
import { Messages } from "../../utilities";
import ReactHtmlParser from "react-html-parser";

class About extends Component {
  state = {
    faqsList: [],
    aboutProdooList: [],
    selectedItem: {}
  };

  componentDidMount() {
    getFaqsApi({ type: 1 }).then(data => {
      this.setState({ faqsList: data.items });
    });
    getAboutProdooListApi().then(data => {
      this.setState({ aboutProdooList: data.items });
    });
  }
  handleSelectFaqList = id => {
    const { faqsList } = this.state;
    let selectedItem = find(faqsList, { QuestionId: id });
    selectedItem = {
      ...selectedItem,
      title: selectedItem.Title,
      description: selectedItem.Answer,
      id: selectedItem.QuestionId
    };
    this.setState({ selectedItem });
  };

  handleSelectAboutProdooList = id => {
    const { aboutProdooList } = this.state;
    let selectedItem = find(aboutProdooList, { ConfigurationId: id });
    selectedItem = {
      ...selectedItem,
      title: selectedItem.ConfigName,
      description: selectedItem.ConfigValue,
      id: selectedItem.ConfigurationId
    };
    this.setState({ selectedItem });
  };

  render() {
    const { labels, isHelpActive } = this.props;
    const {
      faqsList,
      selectedItem,
      aboutProdooList,
      isCollapsed1,
      isCollapsed2
    } = this.state;
    return (
      <PageWrapper className="about-page">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={labels.ViewTitleAboutListB}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ToolTipAboutExpandListB}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.HlpTooltipAboutExpandListButtonB}
            tooltipPlace="left"
          />
          <Column.Head className>
            <div className="heading">{labels.ViewTitleAboutListB}</div>
            <Button
              className="collapseBtn"
              tooltipButton={labels.ToolTipAboutCollaspeListB}
              tooltipHelp={labels.HlpTooltipAboutCollapseListButtonB}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              onClick={() => this.setState({ isCollapsed1: true })}
            />
          </Column.Head>
          <Column.Body>
            <Accordion>
              <AccordionItem>
                <AccordionItemTitle>
                  {Messages.about.aboutProdoo}
                  <div className="accordion__arrow" />
                </AccordionItemTitle>
                <AccordionItemBody>
                  <List>
                    {aboutProdooList.map(item => (
                      <List.ListItem
                        onClick={() =>
                          this.handleSelectAboutProdooList(item.ConfigurationId)
                        }
                        isSelected={
                          item.ConfigurationId === selectedItem.ConfigurationId
                        }
                        key={item.ConfigurationId}
                      >
                        {item.ConfigName}
                      </List.ListItem>
                    ))}
                  </List>
                </AccordionItemBody>
              </AccordionItem>
            </Accordion>
            <Accordion>
              <AccordionItem>
                <AccordionItemTitle>
                  {Messages.about.faqs}
                  <div className="accordion__arrow" />
                </AccordionItemTitle>
                <AccordionItemBody>
                  <List>
                    {faqsList.map(item => (
                      <List.ListItem
                        onClick={() =>
                          this.handleSelectFaqList(item.QuestionId)
                        }
                        isSelected={item.QuestionId === selectedItem.QuestionId}
                        key={item.QuestionId}
                      >
                        {item.Title}
                      </List.ListItem>
                    ))}
                  </List>
                </AccordionItemBody>
              </AccordionItem>
            </Accordion>
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed2} className="col-2">
          <Column.Collapsed
            onClick={() => this.setState({ isCollapsed2: false })}
            text={labels.ViewTitleAboutDetailB}
            tooltipButton={labels.ToolTipAboutExpandDetailB}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.HlpTooltipAboutExpandDetailButtonB}
            tooltipPlace="left"
          />
          <Column.Head>
            <div className="heading">{selectedItem.title}</div>
            <Button
              className="collapseBtn"
              tooltipButton={labels.ToolTipAboutCollaspeDetailB}
              tooltipHelp={labels.HlpTooltipAboutCollapseDetailButtonB}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              onClick={() => this.setState({ isCollapsed2: true })}
            />
          </Column.Head>
          <Column.Body className="description">
            {ReactHtmlParser(selectedItem.description)}
          </Column.Body>
        </Column>
      </PageWrapper>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive };
};
export default connect(mapStateToProps)(About);
