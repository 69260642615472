import React from "react";
import {
  Column,
  Button,
  Select,
  EmptyInfo,
  LoadingMaskRow,
} from "../../../../common";
import {
  assignFlowApi,
  getiProFlowsAndItemsApi,
  unAssignFlowApi,
  getAllAcceptedCollaborationsWithIProDetailApi,
  getAllSearcherCompaniesApi,
  getAllFlowsApi,
  getAllFlowGroupsApi,
  getAllUserCreatedFlowsAndFlowGroupsApi,
  getAllFlowItemsApi,
  getAllFlowGroupItemsApi,
  getCompanyUsersApi,
  getIProAssignedFlowApi,
  completeFlowItemApi,
  unCompleteFlowItemApi,
} from "../../embarkApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import "./my-work.scss";
import ImgSrc from "../../../../assets/images/present.svg";
import { CustomInputNew } from "../../../Tictell/views/components/CustomInput";

const FLOW = "FLOW";
const FLOWGROUP = "FLOWGROUP";
const USERFLOWANDFLOWGROUP = "USERFLOWANDFLOWGROUP";
const FLOWITEM = "FLOWITEM";
const FLOWGROUPITEM = "FLOWGROUPITEM";

const addOptions = {
  FLOW: 1,
  FLOWGROUP: 2,
  USERFLOW: 3,
  FLOWITEM: 4,
  FLOWGROUPITEM: 5,
};

class MyWork extends React.Component {
  state = {
    options: [
      { label: "", value: 1 },
      { label: "", value: 2 },
      { label: "", value: 3 },
    ],
    isCollapsed1: false,
    isCollapsed2: false,
    isCollapsed3: true,
    isCollapsed4: true,
    isCollapsed5: true,
    allFlows: [],
    allFlowGroups: [],
    allUserFlowsAndGroups: [],
    allWorkPlaces: [],
    currentAdd: 1,
    workplaceIpros: [],
    isWorkplaceIprosLoading: true,
  };
  componentDidMount() {
    const {
      EMBARK_ALL_FLOWS,
      EMBARK_ALL_FLOW_GROUPS,
      EMBARK_MYFLOWS_AND_GROUPFLOWS,
    } = this.props.labels;
    this.setState({
      options: [
        { label: EMBARK_ALL_FLOWS, value: 1 },
        { label: EMBARK_ALL_FLOW_GROUPS, value: 2 },
        { label: EMBARK_MYFLOWS_AND_GROUPFLOWS, value: 3 },
      ],
      selectedOption: { value: 1, label: EMBARK_ALL_FLOWS },
    });
    getAllSearcherCompaniesApi()
      .then((res) => {
        this.setState({
          allWorkPlaces: res.items,
        });
        this.handleSelectChange("selectedWorkplace", res.items[0]);
      })
      .catch((err) => console.log("Err ", err));
  }
  handleSelectChange = (name, selectedOption) => {
    if (name === "selectedWorkplace" && !selectedOption) {
      this.setState({
        isWorkplaceIprosLoading: false,
      });
      return;
    }
    this.setState({
      [`${name}`]: selectedOption,
      isWorkplaceIprosLoading: true,
    });
    const { UserCompanyId } = selectedOption;
    getAllAcceptedCollaborationsWithIProDetailApi(
      UserCompanyId,
      this.props.token
    )
      .then((res) => {
        this.setState({
          workplaceIpros: res.items.items,
          isWorkplaceIprosLoading: false,
        });
        this.handleIProClick(res.items.items[0]);
      })
      .catch((err) => console.log("Err ", err));
  };
  handleIProClick = (item) => {
    this.setState({
      selectedIpro: item,
      workplaceIpros: this.state.workplaceIpros.map((ipro) => ({
        ...ipro,
        isActive: ipro.CollaborationId == item.CollaborationId,
        isSelected: ipro.CollaborationId == item.CollaborationId,
      })),
    });
    const { token } = this.props;
    getiProFlowsAndItemsApi(item.IProId, token)
      .then((res) => {
        const AssignedFlows = res.items.map((a) => ({
          ...a,
          FlowStatus: `${a.ItemList.filter((x) => x.IsCompleted).length}/ ${
            a.ItemList.length
          }`,
        }));
        getAllFlowsApi(this.state.selectedWorkplace.UserCompanyId, token)
          .then((response) => {
            this.setState({
              selectedIpro: {
                ...this.state.selectedIpro,
                AssignedFlows,
              },
            });
            this.handleFlowClick(FLOW, AssignedFlows[0]);
          })
          .catch((err) => console.log("Err ", err));
      })
      .catch((err) => console.log("Err ", err));
  };
  handleFlowUnAssign = (item) => {
    unAssignFlowApi(item.IProAssignedFlowId, this.props.token)
      .then((res) => {
        if (res.success) {
          this.setState({
            selectedIpro: {
              ...this.state.selectedIpro,
              AssignedFlows: this.state.selectedIpro.AssignedFlows.filter(
                (a) => a.IProAssignedFlowId != item.IProAssignedFlowId
              ),
              WorkplaceOtherFlows: this.state.selectedIpro.WorkplaceOtherFlows.concat(
                {
                  ...item,
                  IProAssignedFlowId: 0,
                }
              ),
            },
          });
          const info = {
            status: "success",
            message: this.props.labels.EMBARK_FLOW_UNASSIGNED_SUCCESS,
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            status: "error",
            message: res.message,
          };
          this.props.notificationAction(info);
        }
      })
      .catch((err) => console.log("Err", err));
  };
  handleFlowAssign = (item) => {
    let assignModel = {
      ...item,
      IProId: this.state.selectedIpro.IProId,
      FlowId: item.Id || item.FlowId,
      IsCompleted: false,
    };
    assignFlowApi(assignModel, this.props.token).then((res) => {
      if (res.success) {
        assignModel = {
          ...assignModel,
          IProAssignedFlowId: res.items.IProAssignedFlowId,
        };
        this.setState({
          selectedIpro: {
            ...this.state.selectedIpro,
            AssignedFlows: this.state.selectedIpro.AssignedFlows.concat(
              assignModel
            ),
            WorkplaceOtherFlows: this.state.selectedIpro.WorkplaceOtherFlows.filter(
              (a) =>
                a.Id != assignModel.FlowId && a.FlowId != assignModel.FlowId
            ),
          },
        });
        const info = {
          status: "success",
          message: this.props.labels.EMBARK_FLOW_ASSIGNED_SUCCESS,
        };
        this.props.notificationAction(info);
      } else {
        const info = {
          status: "error",
          message: res.message,
        };
        this.props.notificationAction(info);
      }
    });
  };
  handleFlowClick = (type, selectedOption) => {
    this.setState({
      selectedIpro: {
        ...this.state.selectedIpro,
        selectedFlow: selectedOption,
        AssignedFlows: this.state.selectedIpro.AssignedFlows.map((item) => ({
          ...item,
          isActive: selectedOption.FlowId == item.FlowId,
          isSelected: selectedOption.FlowId == item.FlowId,
        })),
      },
    });
  };
  handleCheckBoxClick = (selectedItem) => {
    this.setState({
      selectedIpro: {
        ...this.state.selectedIpro,
        AssignedFlows: this.state.selectedIpro.AssignedFlows.map((k) => ({
          ...k,
          FlowStatus:
            selectedItem.FlowId == k.FlowId
              ? selectedItem.IsCompleted
                ? parseInt(
                    this.state.selectedIpro.selectedFlow.FlowStatus.split(
                      "/"
                    )[0]
                  ) -
                  1 +
                  `/${
                    this.state.selectedIpro.selectedFlow.FlowStatus.split(
                      "/"
                    )[1]
                  }`
                : parseInt(
                    this.state.selectedIpro.selectedFlow.FlowStatus.split(
                      "/"
                    )[0]
                  ) +
                  1 +
                  `/${
                    this.state.selectedIpro.selectedFlow.FlowStatus.split(
                      "/"
                    )[1]
                  }`
              : k.FlowStatus,
        })),
        selectedFlow: {
          ...this.state.selectedIpro.selectedFlow,
          FlowStatus: selectedItem.IsCompleted
            ? parseInt(
                this.state.selectedIpro.selectedFlow.FlowStatus.split("/")[0]
              ) -
              1 +
              `/${
                this.state.selectedIpro.selectedFlow.FlowStatus.split("/")[1]
              }`
            : parseInt(
                this.state.selectedIpro.selectedFlow.FlowStatus.split("/")[0]
              ) +
              1 +
              `/${
                this.state.selectedIpro.selectedFlow.FlowStatus.split("/")[1]
              }`,
          ItemList: this.state.selectedIpro.selectedFlow.ItemList.map((k) => ({
            ...k,
            IsCompleted:
              k.FlowItemId == selectedItem.FlowItemId
                ? !k.IsCompleted
                : k.IsCompleted,
          })),
        },
      },
    });
    const { selectedIpro } = this.state;
    const { token } = this.props;
    if (!selectedItem.IsCompleted) {
      completeFlowItemApi(
        selectedIpro.IProId,
        selectedItem.FlowId,
        selectedItem.FlowItemId,
        token
      )
        .then((res) => {
          if (res.success) {
            const info = {
              status: "success",
              message: this.props.labels.EMBARK_FLOW_ITEM_COMPLETED_SUCCESS,
            };
            this.props.notificationAction(info);
          } else {
            const info = {
              status: "error",
              message: res.message,
            };
            this.props.notificationAction(info);
          }
        })
        .catch((err) => console.log("Err ", err));
    } else {
      unCompleteFlowItemApi(
        selectedIpro.IProId,
        selectedItem.FlowId,
        selectedItem.FlowItemId,
        token
      )
        .then((res) => {
          if (res.success) {
            const info = {
              status: "success",
              message: this.props.EMBARK_FLOW_ITEM_UNCOMPLETED_SUCCESS,
            };
            this.props.notificationAction(info);
          } else {
            const info = {
              status: "error",
              message: res.message,
            };
            this.props.notificationAction(info);
          }
        })
        .catch((err) => console.log("Err ", err));
    }
  };
  render() {
    const {
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      isCollapsed4,
      isCollapsed5,
      allWorkPlaces,
      allFlows,
      allFlowGroups,
      allUserFlowsAndGroups,
      selectedOption,
      selectedWorkplace,
      selectedFlow,
      selectedFlowGroup,
      selectedIpro,
      isWorkplaceIprosLoading,
    } = this.state;

    const { labels, isHelpActive, UserId } = this.props;
    const {
      SNAPSHOT_COLLABORATIONS,
      SNAPSHOT_COLLABORATION_DETAIL,
      SNAPSHOT_COLLABORATION_TIME_AND_EXPENSES,
      SNAPSHOT_COLLABORATION_PRESENT,
      SNAPSHOT_COLLABORATION_RESUME,
    } = labels;
    return (
      <div className="mywork-view">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={labels.EMBARK_IPROS}
            onClick={() => this.setState({ isCollapsed1: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn1"
          />
          <Column.Head>
            <div className="heading">{labels.EMBARK_IPROS}</div>
            <Button
              className="collapseBtn"
              testId="collapsebtn1"
              onClick={() => this.setState({ isCollapsed1: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          {!isCollapsed1 && (
            <Select
              readonly={true}
              searchable={false}
              testId={"collaborationDropdown"}
              name="AllPresentation"
              className="select-input"
              value={
                selectedWorkplace && {
                  value: selectedWorkplace.UserCompanyId,
                  label: selectedWorkplace.CompanyName,
                }
              }
              className={`select-input`}
              placeholder={labels.embarkWorkplaceSelectPlaceholder}
              onChange={(selectedOption) =>
                this.handleSelectChange("selectedWorkplace", selectedOption)
              }
              options={allWorkPlaces.map((company) => ({
                ...company,
                value: company.UserCompanyId,
                label: company.CompanyName,
              }))}
              clearable={false}
            />
          )}
          <Column.Body>
            {isWorkplaceIprosLoading ? (
              <LoadingMaskRow />
            ) : (
              <React.Fragment>
                {this.state.workplaceIpros &&
                this.state.workplaceIpros.length > 0 ? (
                  <div className="tictell-list">
                    {this.state.workplaceIpros &&
                      this.state.workplaceIpros.map((item) => (
                        <div
                          key={item.CollaborationId}
                          test-data-id={`collaborations-list-item${
                            item.isActive ? "activeItem" : ""
                          }`}
                          className={`tictell-list-item-container  ${
                            item.isSelected ? "selected-item" : ""
                          } ${item.isActive ? "activeItem" : ""}`}
                          onClick={() => this.handleIProClick(item)}
                        >
                          <div
                            className={`dashItemImg ${
                              item.IProImage ? "" : "no-dp"
                            }`}
                          >
                            <img
                              className="roundImg"
                              src={item.IProImage ? item.IProImage : ImgSrc}
                              alt={"UserName"}
                            />
                          </div>
                          <div className="name-container">
                            <label test-data-id={`collabs-list-owner-username`}>
                              {item.IProName}
                            </label>
                            <label test-data-id={`collabs-list-owner-title`}>
                              {item.OpportunityName}
                            </label>
                          </div>
                          <label className="complete-label">
                            {item.CompletedItems}
                          </label>
                        </div>
                      ))}
                  </div>
                ) : (
                  <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                )}
              </React.Fragment>
            )}
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed2} className="col-2">
          <Column.Collapsed
            text={labels.EMBARK_ASSIGNED_FLOWS}
            onClick={() => this.setState({ isCollapsed2: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn2"
          />
          <Column.Head>
            <div className="heading">{labels.EMBARK_ASSIGNED_FLOWS}</div>
            <Button
              className="collapseBtn"
              testId="collapsebtn2"
              onClick={() => this.setState({ isCollapsed2: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          {!isCollapsed2 && selectedIpro && (
            <div
              className={`tictell-list-item-container activeItem collaboration-heading flow-detail-heading`}
            >
              <div
                className={`dashItemImg ${
                  selectedIpro.IProImage ? "" : "no-dp"
                }`}
              >
                <img
                  className="roundImg"
                  src={selectedIpro.IProImage ? selectedIpro.IProImage : ImgSrc}
                  alt={"UserName"}
                />
              </div>
              <div className="name-container">
                <label test-data-id={`collabs-details-owner-username`}>
                  {selectedIpro.IProName}
                </label>
                <label test-data-id={`collabs-details-owner-title`}>
                  {selectedIpro.OpportunityName}
                </label>
              </div>
              <label className="complete-label">
                {selectedIpro.CompletedItems}
              </label>
            </div>
          )}
          <Column.Body>
            <React.Fragment>
              {selectedWorkplace &&
              selectedIpro &&
              selectedIpro.AssignedFlows &&
              selectedIpro.AssignedFlows.length > 0 ? (
                <div className="tictell-list">
                  {selectedIpro.AssignedFlows &&
                    selectedIpro.AssignedFlows.length > 0 &&
                    selectedIpro.AssignedFlows.map((item) => (
                      <div
                        key={item.Id || item.FlowId}
                        test-data-id={`collaborations-list-item${
                          item.isActive ? "activeItem" : ""
                        }`}
                        className={`tictell-list-item-container  ${
                          item.isSelected ? "selected-item" : ""
                        } ${item.isActive ? "activeItem" : ""}`}
                        onClick={() => this.handleFlowClick(FLOW, item)}
                      >
                        <div className="name-container flow-container">
                          <label test-data-id={`collabs-list-owner-username`}>
                            {item.FlowName}
                          </label>
                          <label test-data-id={`collabs-list-owner-title`}>
                            {item.FlowDescription}
                          </label>
                        </div>
                        <label test-data-id={`collabs-list-owner-title`}>
                          {item.FlowStatus}
                        </label>
                      </div>
                    ))}
                </div>
              ) : (
                <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
              )}
            </React.Fragment>
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed1} className="col-2">
          <Column.Collapsed
            text={labels.EMBARK_MY_WORK_FLOW_ITEMS}
            onClick={() => this.setState({ isCollapsed1: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn3"
          />
          <Column.Head>
            <div className="heading">{labels.EMBARK_MY_WORK_FLOW_ITEMS}</div>
            <Button
              className="collapseBtn"
              testId="collapsebtn3"
              onClick={() => this.setState({ isCollapsed1: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          <Column.Body>
            <React.Fragment>
              {selectedIpro &&
              selectedIpro.selectedFlow &&
              selectedIpro.selectedFlow.ItemList &&
              selectedIpro.selectedFlow.ItemList.length > 0 ? (
                <div className="tictell-list">
                  {selectedIpro.selectedFlow.ItemList &&
                    selectedIpro.selectedFlow.ItemList.length > 0 &&
                    selectedIpro.selectedFlow.ItemList.map((item) => (
                      <div
                        key={item.Id}
                        test-data-id={`collaborations-list-item${
                          item.isActive ? "activeItem" : ""
                        }`}
                        className={`tictell-list-item-container  ${
                          item.isSelected ? "selected-item" : ""
                        } ${item.isActive ? "activeItem" : ""}`}
                      >
                        <span className="list-checkbox">
                          <input
                            type="checkbox"
                            checked={item.IsCompleted}
                            onChange={() => this.handleCheckBoxClick(item)}
                          ></input>
                        </span>
                        <div className="name-container flow-container">
                          <label test-data-id={`collabs-list-owner-username`}>
                            {item.Title}
                          </label>
                          <label test-data-id={`collabs-list-owner-title`}>
                            {item.Description}
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
              )}
            </React.Fragment>
          </Column.Body>
        </Column>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(MyWork);
