import React, { Component } from "react";
import "./list-group.scss";
const Item = ({ children, className, testId }) => (
  <li test-data-id={testId} className={`list-group-item ${className}`}>
    {children}
  </li>
);

const Column = ({ children, className, testId, size }) => (
  <div
    test-data-id={testId}
    className={`list-group-column ${className} ${size === 0 ? "no-flex" : ""}`}
  >
    {children}
  </div>
);
const Close = ({ onClick, testId }) => (
  <button onClick={onClick} className="closeBtn" test-data-id={testId} />
);
class ListGroup extends Component {
  static Item = Item;
  static Close = Close;
  static Column = Column;
  render() {
    const { children, className, testId, isFetching } = this.props;
    return (
      <div className={`list-group-wrapper ${className}`}>
        <ul
          className={`list-group-ul ${isFetching ? "loading-list-mask" : ""}`}
          test-data-id={testId}
        >
          {children}
        </ul>
      </div>
    );
  }
}
ListGroup.defaultProps = {
  className: ""
};
ListGroup.Item.defaultProps = {
  className: ""
};
ListGroup.Column.defaultProps = {
  className: ""
};

export default ListGroup;
