import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import {
  Modal,
  Button,
  Form,
  Alert,
  Input,
  DatePicker, Space,
  Checkbox,
} from "antd";
import classes from "./index.module.scss";
import FeatureCard from "../../components/FeatureCard/FeatureCard";
import DebounceSelect from "../../../../components/DebounceSelect/DebounceSelect";
import { getRolesApi, getIndustriesApi } from "../../dashboardApi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import closeIcon from '../../../../assets/images/Icons/close-new.png'
import LanguageImage from '../../../../assets/images/experience-popup.png'

function ExperiencePopup({
  isLoading,
  handleExperienceChange,
  handleStartDate,
  handleEndDate,
  SaveResumeDetails,
  handleDescriptionChange,
  errorMessage,
  resumeExperienceEdit,
  error,
  labels,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = () => {
    setIsModalVisible(false);
    SaveResumeDetails();
  };

  useEffect(() => { }, []);

  const {
    isEndDate,
    EndDate,
    CompanyWorked,
    StartDate,
    IndustryId,
    ProfileId,
  } = resumeExperienceEdit;

  return (
    <>
      <button className="add-profile"
        onClick={() => setIsModalVisible(true)}
      >{labels.ADD_RESUME_EDIT_EXPERIENCE_SECTION_TITLE}</button>

      <Modal
        visible={isModalVisible}
        centered
        style={{ top: 10 }}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        className={`new-design-modal ${classes.popUpForm}`}
      >
        <div className="text-center mb-3 header-modal">
          <h5>{labels.ADD_RESUME_EDIT_EXPERIENCE_SECTION_TITLE}</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="images-icon">
          <img src={LanguageImage} alt="" />
          <p className="paragraph">Please select your education, and your level of experience in this industry</p>
        </div>
        <Form
          name="expierience_form"
          className={`${classes.form}  form`}
          onFinish={onFinish}
          size="large"
        >
          <Form.Item
            name="CompanyWorked"
            className={classes.customSelect}
            rules={[
              {
                required: true,
                message: labels.INPUT_EXPERIENCE_VALIDATON_LBL,
              },
            ]}
          >
            <Input
              onChange={(e) =>
                handleExperienceChange("CompanyWorked", e.target.value)
              }
              placeholder="Company Name"
              value={CompanyWorked || ""}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Space>
              <Form.Item
                className={`${classes.experience_debounce_select}  ${classes.customSelect}`}
                rules={[
                  {
                    required: IndustryId ? false : true,
                    message: labels.SELECT_INDUSTRY_VAIDATION_LBL,
                  },
                ]}
                name="industry"
              >
                <DebounceSelect
                  mode="multiple"
                  selectName={"IndustryId"}
                  value={IndustryId || ""}
                  placeholder={labels.SELECT_INDUSTRY_LBL}
                  fetchOptions={getIndustriesApi}
                  handleChange={handleExperienceChange}
                />
              </Form.Item>
              <Form.Item
                className={`${classes.experience_debounce_select} ${classes.customSelect}`}
                rules={[
                  {
                    required: ProfileId ? false : true,
                    message: labels.SELECT_ROLE_VALIDATION_LBL,
                  },
                ]}
                name="Role"
              >
                <DebounceSelect
                  mode="multiple"
                  selectName={"ProfileId"}
                  value={ProfileId || ""}
                  placeholder={labels.SELECT_ROLE_LBL}
                  fetchOptions={getRolesApi}
                  handleChange={handleExperienceChange}
                />
              </Form.Item>
            </Space>
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Space>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: labels.collStartDateRequired,
                  },
                ]}
                name="StartDate"
                className={`${classes.date_input} ${classes.customSelect}`}
              >
                <DatePicker
                  value={StartDate || ""}
                  onChange={handleStartDate}
                  placeholder={labels.RESUME_EDIT_FROM_LABEL}
                />
              </Form.Item>
              <Form.Item
                name="EndDate"
                className={`${classes.date_input} ${classes.customSelect}`}
                rules={[
                  {
                    required: !isEndDate && !EndDate ? true : false,
                    message: labels.END_DATE_VALIDATION_LBL,
                  },
                ]}
              >
                <DatePicker
                  disabled={isEndDate}
                  value={EndDate || ""}
                  onChange={handleEndDate}
                  placeholder={labels.IProSentMessageDetailToLabel}
                />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item className={`${classes.checkbox_style} `}>
            <Checkbox
              checked={isEndDate}
              onChange={(e) => handleExperienceChange("isEndDate", isEndDate)}
            >
              {labels.RESUME_EDIT_WORKING_LABEL}
            </Checkbox>
          </Form.Item>
          <ReactQuill
            value={resumeExperienceEdit.Description}
            className={`${classes.experienceDescriptionInput}`}
            onChange={handleDescriptionChange}
            theme="snow"
          />

          {error && (
            <Alert
              message={errorMessage?.replace("<br />", "")}
              type="warning"
              closable
            />
          )}

          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-form-button mt-2"
            loading={isLoading}
          >
            {labels.Done_LBL}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default ExperiencePopup;
