import React, { Fragment } from 'react';

import {  NotificationCircle, Tooltip, HelpTooltip } from "../../common";
import { htmlParser } from '../../utilities';
import { NavLink } from "react-router-dom";
import './navigation-item.scss';

const HelpGuideIcon = props => {
    const { testId, disabled, tooltipHelp, isHelpActive } = props;
    return (
      <span className={`nav-help-icon-container`}>
        <HelpTooltip testId={testId} content={tooltipHelp} />
      </span>
    );
  };
  
  const NavigationToolTip = ({ children, label, isHelpActive }) => (
    <>
      {isHelpActive || window.innerWidth <= 1240 ? (
        <>{children}</>
      ) : (
        <Tooltip
          followCursor={true}
          content={label}
        >
          <div>{children}</div>
        </Tooltip>
      )}
    </>
  );
  const NavLinkButton = ({
        Id,
        children,
        classIcon,
        className,
        to,
        testId,
        onClick,
        disabled,
        IsActive
      }) =>(
        <>
        <NavLink
          activeClassName={`${!disabled && IsActive ? "activeBtn" : ""}`}
          to={!disabled ? to : to}
          className={`nav-button ${className}`}
          test-data-id={testId}
          disabled={disabled}
        >
          <i className={classIcon} />
          <span>{children}</span>
        </NavLink>
        </>
);
class NavigationItem extends React.Component{
    render(){
      const { 
        Id, 
        tooltipLabel, 
        isHelpActive,
        Disabled=false,
        CssClass,
        CssClassIcon,
        Url,
        testId,
        handleMenuClick,
        menuText,
        isNavShrink,
        tooltipHelp,
        NotificationBadge,
        NotificationCount,
        IsActive,
        mobileName
      } = this.props;
      return(
          <Fragment key={Id}>
            <NavigationToolTip
              label={tooltipLabel}
              isHelpActive={isHelpActive}
            >
              <NavLinkButton
                disabled={Disabled}
                className={CssClass}
                classIcon={CssClassIcon}
                to={Url}
                testId={testId}
                Id={Id}
                IsActive={IsActive}
              >
                {htmlParser(menuText)}
                {isHelpActive && (
                  <HelpGuideIcon
                    testId={`${testId}-help`}
                    isNavShrink={isNavShrink}
                    tooltipHelp={tooltipHelp}
                  />
                )}
                {NotificationBadge && NotificationCount > 0 && (
                  <NotificationCircle
                    testId={`${testId}-notification`}
                  >
                    {NotificationCount > 9 ? "9+" : NotificationCount}
                  </NotificationCircle>
                )}
              </NavLinkButton>
            </NavigationToolTip>
          </Fragment>
        );
    }
}

export default NavigationItem;