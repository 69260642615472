import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import { PageWrapper } from "../../../components";
import {
  Column,
  Button,
  SearchInput,
  LoadingMaskRow,
  LoadingMask,
  ConfirmDialog,
} from "../../../common";
import "./collaboration.scss";
import {
  getAllCollaborationApi,
  updateCollaborationApi,
  readCollaborationApi,
  getCurrenciesApi,
  getCollaborationReviewApi,
  getCollaborationReasonsApi,
  getReviewQuestions,
  submitCollaborationReview,
  deleteCollaborationApi,
  // collaborationReviewApi,
} from "./collaborationApi";
import { unreadCollaborationsAction } from "../../../actions/navigationActions";
import CollaborationDetail from "./components/CollaborationDetail";
import CollaborationList from "./components/CollaborationList";
import UserDetail from "./components/UserDetail";
import CompanyDetail from "../../Company/components/CompanyDetail";
import { onStateChangeAction } from "./collaborationAction";
import ReviewForm from "../../../common/ReviewForm/ReviewForm";
import toLower from "lodash/toLower";
import { isNullOrUndefined } from "util";
class Collaboration extends Component {
  state = {
    fetchingReviews: false,
    isDeleteCollaboration: false,
    CollborationCloseReasonId: null,
    options: [
      { value: "1", label: "Job completed successfully" },
      { value: "2", label: "Job cancelled due to freelancer performance" },
      { value: "3", label: "Job canceled for other reasons" },
      { value: "4", label: "Another Reason" },
    ],
  };

  componentDidMount() {
    this.props.onStateChangeAction({
      selectedCollaboration: {},
    });
    const {
      isIproActiveCollaboration,
      isIproInActiveCollaboration,
      isSentView,
      isAcceptedView,
      isDeclinedView,
      isInActiveView,
    } = this.props;
    window.actionName = isSentView
      ? "searcherPending"
      : isAcceptedView
      ? "searcherAccepted"
      : isInActiveView
      ? "searcherInActive"
      : isDeclinedView
      ? "searcherDeclined"
      : isIproActiveCollaboration
      ? "iproActive"
      : isIproInActiveCollaboration
      ? "iproInActive"
      : "iproNew";
    this.getCurrencies();
  }

  getCurrencies(access_token) {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const currencies = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          this.props.onStateChangeAction({
            currencies,
          });
          this.getAllCollaboration(currencies);
        }
      })
      .catch((err) => console.log("Err ", err));
  }

  getAllCollaboration = (CurrenciesParams) => {
    const {
      url,
      isAcceptedView,
      isInActiveView,
      isDeclinedView,
      isSentView,
      isIproCollaboration,
      isIproNewCollaboration,
    } = this.props;
    let currencies = CurrenciesParams || this.props.currencies;
    this.props.onStateChangeAction({ fetchingCollaborations: true });
    getAllCollaborationApi({ url }).then((data) => {
      if (data.success) {
        let collaborations = [];
        if (data.items.length) {
          collaborations = map(data.items, (collaboration) => {
            if (isSentView) {
              collaboration.IsRead = true;
            }
            if (isDeclinedView || isAcceptedView || isInActiveView) {
              collaboration.IsRead = collaboration.IsSearcherRead;
            }
            if (isIproCollaboration) {
              collaboration.IsRead = collaboration.IsIProRead;
            }
            if (!isNaN(collaboration.HourlyRateType)) {
              collaboration.HourlyRateType = currencies.find(
                (a) => a.CurrencyId == collaboration.HourlyRateType
              ).Name;
            }
            return collaboration;
          });
        } else {
          collaborations = data.items;
        }
        this.props.onStateChangeAction({
          collaborationList: collaborations,
          // filteredCollaborations: collaborations,
          fetchingCollaborations: false,
        });
        const CollaborationId = this.props.locationProp.state
          ? this.props.locationProp.state.CollaborationId
          : null;
        const spliturl = this.props.locationProp.pathname.match(/\d+/g);
        var CollabIDFromURL = spliturl ? parseInt(spliturl?.[0]) : null;

        if (CollaborationId != null || CollabIDFromURL != null) {
          let filteredCollab = data.items.filter(
            (coll) =>
              coll.CollaborationId == CollaborationId ||
              coll.CollaborationId == CollabIDFromURL
          );
          if (filteredCollab && filteredCollab.length > 0) {
            this.handleSelectCollaboration(filteredCollab[0]);
          } else {
            const info = {
              message: "Collaboration has already been deleted by Searcher",
              status: "error",
            };
            this.props.notificationAction(info);
          }
        }
        this.filterList(this.props.searchKey);
      }
    });
  };
  filterList = (searchKey) => {
    const { collaborationList } = this.props;
    const filteredCollaborations = filter(
      collaborationList,
      (collaboration) => {
        if (includes(toLower(collaboration.Title), toLower(searchKey))) {
          return collaboration;
        }
      }
    );
    this.props.onStateChangeAction({ filteredCollaborations });
  };
  handleSearchChange = (e) => {
    const searchKey = e.target.value;
    // const filteredCollaborations = filter(collaborationList, collaboration => {
    //   if (includes(collaboration.Title, searchKey)) {
    //     return collaboration;
    //   }
    // });
    this.props.onStateChangeAction({ searchKey });
    this.filterList(searchKey);
  };

  handleSelectCollaboration = (selectedCollaboration) => {
    const { CollaborationId } = selectedCollaboration;
    const { isInActiveView, isIproInActiveCollaboration } = this.props;
    if (selectedCollaboration.IsRead) {
    } else {
      if (!selectedCollaboration.IsRead) {
        const {
          acceptedCollaboration,
          declinedCollaboration,
          isAcceptedView,
          isInActiveView,
          isDeclinedView,
          isIproCollaboration,
          unreadproInactiveCollaborations,
          searcherInActiveCollaboration,
          isIproInActiveCollaboration,
          unreadCollaborations,
        } = this.props;
        readCollaborationApi({
          collaborationId: CollaborationId,
        })
          .then((response) => {
            let { collaborationList } = this.props;

            const unreadCollabaorations = filter(
              collaborationList,
              (item) => item.CollaborationId !== CollaborationId && !item.IsRead
            );

            if (isIproCollaboration) {
              this.props.unreadCollaborationsAction({
                unreadCollaborations: unreadCollabaorations.length,
                unreadproInactiveCollaborations: unreadproInactiveCollaborations,
              });
            }
            if (isIproInActiveCollaboration) {
              this.props.unreadCollaborationsAction({
                unreadproInactiveCollaborations: unreadCollabaorations.length,
                unreadCollaborations: unreadCollaborations,
              });
            }
            if (isInActiveView) {
              this.props.unreadCollaborationsAction({
                searcherInActiveCollaboration: unreadCollabaorations.length,
                declinedCollaboration: declinedCollaboration,
                acceptedCollaboration: acceptedCollaboration,
              });
            }
            if (isAcceptedView) {
              this.props.unreadCollaborationsAction({
                acceptedCollaboration: unreadCollabaorations.length,
                declinedCollaboration: declinedCollaboration,
                searcherInActiveCollaboration: searcherInActiveCollaboration,
              });
            }
            if (isDeclinedView) {
              this.props.unreadCollaborationsAction({
                acceptedCollaboration: acceptedCollaboration,
                declinedCollaboration: unreadCollabaorations.length,
                searcherInActiveCollaboration: searcherInActiveCollaboration,
              });
            }

            const filteredCollaborations = map(collaborationList, (item) => {
              if (item.CollaborationId === CollaborationId) {
                item.IsRead = true;
              }
              return item;
            });
            this.props.onStateChangeAction({
              filteredCollaborations,
              selectedCollaboration,
            });
          })
          .catch((res) => res);
      }
    }

    const selectedUser = {
      ...selectedCollaboration.company,
      ...selectedCollaboration.userdetail,
    };
    if (isIproInActiveCollaboration || isInActiveView) {
      this.getReviewDetails(CollaborationId);
    }
    this.props.onStateChangeAction({
      selectedCollaboration,
      selectedUser,
      selectedCompany: selectedCollaboration.company,
      dialogMessage: "",
      yesClickCount: 0,
    });
  };

  handleCollaborationDelete = () => {
    const { collaborationDeleteConfirmation } = this.props;
    this.props.onStateChangeAction({
      dialogMessage: collaborationDeleteConfirmation,
    });
    this.setState({ isDeleteCollaboration: true });
  };

  handleCollaborationClose = () => {
    const { collaborationCloseConfirmation } = this.props;
    this.props.onStateChangeAction({
      dialogMessage: collaborationCloseConfirmation,
    });
    if (this.props.isSentView) {
      this.closeCollaboration();
    }
  };

  handleYesClick = () => {
    const {
      yesClickCount,
      likeToRateCollaborationMessage,
      selectedCollaboration,
    } = this.props;
    const { isDeleteCollaboration } = this.state;
    if (isDeleteCollaboration) {
      this.deleteCollaboration();
      return;
    }
    if (!yesClickCount || yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: likeToRateCollaborationMessage,
        yesClickCount: 1,
        fetchingReviews: true,
      });
      return;
    }
    this.props.onStateChangeAction({ fetchingReviews: true });
    this.getReviewDetails(selectedCollaboration.CollaborationId);
    this.getCollaborationCloseReasons();
    this.props.onStateChangeAction({
      dialogMessage: "",
      yesClickCount: 2,
    });
  };

  deleteCollaboration = () => {
    const {
      selectedCollaboration: { CollaborationId },
      IsSearcher,
      collaborationDeletedMsg,
    } = this.props;
    this.setState({ isLoading: true, dialogMessage: "" });
    const apiParams = `collaborationId=${CollaborationId}&${
      IsSearcher ? "IsSearcher=true" : "IsSearcher=false"
    }`;
    this.props.onStateChangeAction({ isLoading: true, dialogMessage: "" });
    deleteCollaborationApi({ apiParams }).then((response) => {
      if (response.success) {
        const info = {
          message: collaborationDeletedMsg,
          status: "success",
        };
        this.props.notificationAction(info);
        this.getAllCollaboration();
        this.props.onStateChangeAction({
          isLoading: false,
          selectedCollaboration: {},
          yesClickCount: 0,
        });
      } else {
        const info = {
          message: "Something went wrong",
          status: "error",
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({
          isLoading: false,
        });
      }
    });
  };

  closeCollaboration = () => {
    const {
      selectedCollaboration: { CollaborationId },
      collaborationCloseMsg,
      IsSearcher,
    } = this.props;
    this.setState({ isLoading: true, dialogMessage: "" });
    const apiParams = `collaborationId=${CollaborationId}&IsClosed=true&${
      IsSearcher ? "IsSearcher=true" : "IsSearcher=false"
    }`;
    this.props.onStateChangeAction({ isLoading: true, dialogMessage: "" });
    updateCollaborationApi({ apiParams }).then((response) => {
      const info = {
        message: collaborationCloseMsg,
        status: "success",
      };
      this.props.notificationAction(info);
      this.getAllCollaboration();
      this.props.onStateChangeAction({
        isLoading: false,
        selectedCollaboration: {},
        yesClickCount: 0,
      });
      console.log("Collaboration Closed");
    });
  };

  handleNoClick = () => {
    const { yesClickCount } = this.props;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: "",
        selectedCompany: null,
      });
      return;
    }
    this.closeCollaboration();
  };

  handleCollaborationAccept = () => {
    const {
      selectedCollaboration: { CollaborationId },
    } = this.props;
    const { collaborationAcceptedMsg } = this.props;
    const apiParams = `collaborationId=${CollaborationId}&IsDeclined=false`;
    this.props.onStateChangeAction({ isLoading: true });
    updateCollaborationApi({ apiParams }).then((response) => {
      const info = {
        message: collaborationAcceptedMsg,
        status: "success",
      };
      this.props.notificationAction(info);
      this.getAllCollaboration();
      this.props.onStateChangeAction({
        isLoading: false,
        selectedCollaboration: {},
      });
    });
  };

  handleCollaborationDecline = () => {
    const {
      selectedCollaboration: { CollaborationId },
    } = this.props;
    const { collaborationDeclinedMsg } = this.props;
    const apiParams = `collaborationId=${CollaborationId}&IsDeclined=true`;
    this.props.onStateChangeAction({ isLoading: true });
    updateCollaborationApi({ apiParams }).then((response) => {
      const info = {
        message: collaborationDeclinedMsg,
        status: "success",
      };
      this.props.notificationAction(info);
      this.getAllCollaboration();
      this.props.onStateChangeAction({
        isLoading: false,
        selectedCollaboration: {},
      });
    });
  };

  getReviewDetails = (CollaborationId) => {
    this.setState({ fetchingReviews: true });
    const { IsSearcher } = this.props;
    getReviewQuestions({ questionType: 1 })
      .then((res) => {
        const reviewQuestions = res.items.map((r) => ({
          ...r,
          ReviewQuestionValue: this.props.labels[r.Question],
          Answer: r.Question !== "Rating_Question5" ? r.Answer || 0 : "",
          CollaborationId: CollaborationId,
        }));
        getCollaborationReviewApi({
          collaborationId: CollaborationId,
          IsIProReview: !IsSearcher,
        })
          .then((reviewRes) => {
            const reviews = reviewRes.items;
            let currentCollborationCloseReasonId = null;
            this.props.onStateChangeAction({
              reviewQuestions: reviewQuestions.map((a) => {
                const currentReview = reviews
                  ? reviews.find(
                      (b) => b.ReviewQuestionId == a.ReviewQuestionId
                    )
                  : null;
                if (
                  currentReview &&
                  a.ReviewQuestionId == currentReview.ReviewQuestionId &&
                  a.Question === "Rating_Question6"
                ) {
                  currentCollborationCloseReasonId =
                    currentReview.CollborationCloseReasonId;
                }
                this.setState({ currentReview: currentReview });
                return {
                  ...a,
                  Answer:
                    currentReview != null ? currentReview.Answer : a.Answer,
                  IsIProReview: !IsSearcher,
                };
              }),
              isAlreadyRated: reviews && reviews.length > 0 ? true : false,
            });
            this.setState({ fetchingReviews: false });
            this.onFormSelectChange("", {
              value: currentCollborationCloseReasonId,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ fetchingReviews: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ fetchingReviews: false });
      });
  };

  getCollaborationCloseReasons = () => {
    getCollaborationReasonsApi()
      .then((reasons) => {
        const reasonsList = reasons.items.map((item) => ({
          ...item,
          value: item.CollborationCloseReasonId,
          label: this.props.labels[item.ReasonLabel],
        }));
        this.setState({
          options: reasonsList,
        });
      })
      .catch((err) => console.log(err));
  };

  handleRatingChange = (rating, value) => {
    const { reviewQuestions } = this.props;
    this.props.onStateChangeAction({
      reviewQuestions: reviewQuestions.map((a) =>
        a.ReviewQuestionId == rating.ReviewQuestionId
          ? {
              ...a,
              Answer:
                rating.Question === "Rating_Question5"
                  ? value.target.value
                  : value,
            }
          : a
      ),
    });
  };

  onFormSelectChange = (answer, selectedOption) => {
    if (!selectedOption) return;
    const { reviewQuestions } = this.props;
    const { value } = selectedOption;
    this.setState({
      CollborationCloseReasonId: value,
    });
    this.props.onStateChangeAction({
      reviewQuestions: reviewQuestions.map((a) =>
        a.Question === "Rating_Question6"
          ? {
              ...a,
              Answer: null,
              CollborationCloseReasonId: value,
            }
          : a
      ),
    });

    return;
  };

  handleSubmit = () => {
    const { reviewQuestions } = this.props;
    submitCollaborationReview({ collaborationReview: reviewQuestions })
      .then((data) => {})
      .catch((err) => {});
    this.closeCollaboration();
  };

  componentWillUnmount = () => {};

  render() {
    const {
      isHelpActive,
      listColumnText,
      toolTipExpandList,
      helpTooltipExpandList,
      searchInputPlaceholder,
      toolTipCollapseList,
      helpTooltipCollapseList,
      detailsColumnText,
      toolTipExpandDetail,
      helpTooltipExpandDetail,
      detailHeadingText,
      toolTipCollapseDetail,
      helpTooltipCollapseDetail,

      userDetailHeadingText,
      toolTipExpandUserDetail,
      helpTooltipExpandUserDetail,
      toolTipCollapseUserDetail,
      helpTooltipCollapseUserDetail,
      userDetailsColumnText,

      emptyCollaorationInfo,
      startDateLabel,
      durationLabel,
      compensationLabel,
      companyLabel,
      descriptionLabel,
      acceptedCollaboration,
      selectInterest,

      companyAddressLabel,
      comapnyIndustryLabel,
      companyCountryLabel,
      companyPhoneLabel,
      companyWebUrlLabel,
      companyVatLabel,

      listCollapsedTestId,
      listCollapsedHelpTestId,
      listExpandTestId,
      listExpandHelpTestId,
      detailCollapsedTestId,
      detailCollapsedHelpTestId,
      detailExpandTestId,
      detailExpandHelpTestId,
      userDetailCollapsedTestId,
      userDetailCollapsedHelpTestId,
      userDetailExpandTestId,
      userDetailExpandHelpTestId,
      searchInputTestId,
      IsSearcher,
      popupNoText,
      popupYesText,
      acceptedStatus,
      listCollapsed,
      detailCollapsed,
      userCompanyDetailCollapsed,
      selectedCollaboration,
      filteredCollaborations,
      selectedUser,
      isLoading,
      selectedCompany,
      dialogMessage,
      searchKey,
      yesClickCount = 0,
      isInActiveView,
      isIproInActiveCollaboration,
      isDeclinedView,
      declinedStatus,
      fetchingCollaborations,
      isAcceptedView,
      isSentView,
      isIproActiveCollaboration,
      isIproNewCollaboration,
      iProOpportunityNewDetailBtnAccept,
      iProOpportunityNewDetailBtnDecline,
    } = this.props;
    const { fetchingReviews } = this.state;
    const isRatingView =
      ((isInActiveView || isIproInActiveCollaboration) &&
        !isNullOrUndefined(selectedCollaboration)) ||
      (yesClickCount &&
        yesClickCount === 2 &&
        !isNullOrUndefined(selectedCollaboration))
        ? true
        : false;
    return (
      <PageWrapper className="collaboration">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="company-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-yes"
                onClick={this.handleYesClick}
              >
                {popupYesText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-no"
                onClick={this.handleNoClick}
              >
                {popupNoText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={listCollapsed} className="col-1">
          <Column.Collapsed
            text={listColumnText}
            onClick={() =>
              this.props.onStateChangeAction({ listCollapsed: false })
            }
            tooltipButton={toolTipExpandList}
            isHelpActive={isHelpActive}
            tooltipHelp={helpTooltipExpandList}
            tooltipPlace="left"
            testId={listExpandTestId}
            testIdHelp={listExpandHelpTestId}
          />
          <Column.Head>
            <SearchInput
              placeholder={searchInputPlaceholder}
              onChange={this.handleSearchChange}
              testId={searchInputTestId}
              value={searchKey}
            />
            <Button
              className="collapseBtn"
              tooltipButton={toolTipCollapseList}
              tooltipHelp={helpTooltipCollapseList}
              tooltipPlace="left"
              onClick={() =>
                this.props.onStateChangeAction({ listCollapsed: true })
              }
              isHelpActive={isHelpActive}
              testId={listCollapsedTestId}
              testIdHelp={listCollapsedHelpTestId}
            />
          </Column.Head>
          <Column.Body>
            {fetchingCollaborations ? (
              <LoadingMaskRow />
            ) : (
              <CollaborationList
                declinedStatus={declinedStatus}
                acceptedStatus={acceptedStatus}
                IsSearcher={IsSearcher}
                collaborationList={filteredCollaborations}
                isDeclinedView={isDeclinedView}
                handleSelectCollaboration={this.handleSelectCollaboration}
                selectedCollaboration={selectedCollaboration}
                labels={this?.props?.labels}
              />
            )}
          </Column.Body>
        </Column>
        <Column collapse={detailCollapsed} className="col-2">
          <Column.Collapsed
            text={detailsColumnText}
            onClick={() =>
              this.props.onStateChangeAction({ detailCollapsed: false })
            }
            isHelpActive={isHelpActive}
            tooltipButton={toolTipExpandDetail}
            tooltipHelp={helpTooltipExpandDetail}
            tooltipPlace="left"
            testId={detailExpandTestId}
            testIdHelp={detailExpandHelpTestId}
          />
          <Column.Head>
            <Column.HeaderTitle isActive={!isEmpty(selectedCollaboration)}>
              {detailHeadingText}
            </Column.HeaderTitle>
            <Button
              className="collapseBtn"
              onClick={() =>
                this.props.onStateChangeAction({ detailCollapsed: true })
              }
              tooltipButton={toolTipCollapseDetail}
              tooltipHelp={helpTooltipCollapseDetail}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={detailCollapsedTestId}
              testIdHelp={detailCollapsedHelpTestId}
            />
          </Column.Head>
          <Column.Body className="flex collaboration-rating-detail">
            <React.Fragment>
              <CollaborationDetail
                selectedCollaboration={selectedCollaboration}
                iProOpportunityNewDetailBtnDecline={
                  iProOpportunityNewDetailBtnDecline
                }
                iProOpportunityNewDetailBtnAccept={
                  iProOpportunityNewDetailBtnAccept
                }
                emptyCollaorationInfo={emptyCollaorationInfo}
                startDateLabel={startDateLabel}
                durationLabel={durationLabel}
                isDeclinedView={isDeclinedView}
                compensationLabel={compensationLabel}
                companyLabel={companyLabel}
                descriptionLabel={descriptionLabel}
                acceptedCollaboration={acceptedCollaboration}
                selectInterest={selectInterest}
                onCollaborationAccept={this.handleCollaborationAccept}
                onCollaborationDecline={this.handleCollaborationDecline}
                onCollaborationDelete={this.handleCollaborationDelete}
                onCollaborationClose={this.handleCollaborationClose}
                IsSearcher={IsSearcher}
                isIproInActiveCollaboration={isIproInActiveCollaboration}
                isInActiveView={isInActiveView}
                isAcceptedView={isAcceptedView}
                isSentView={isSentView}
                isIproActiveCollaboration={isIproActiveCollaboration}
                isIproNewCollaboration={isIproNewCollaboration}
              />
            </React.Fragment>
          </Column.Body>
        </Column>
        {selectedCollaboration.company && !isRatingView && (
          <Column collapse={userCompanyDetailCollapsed} className="col-3">
            <Column.Collapsed
              text={userDetailsColumnText}
              onClick={() =>
                this.props.onStateChangeAction({
                  userCompanyDetailCollapsed: false,
                })
              }
              isHelpActive={isHelpActive}
              tooltipButton={toolTipExpandUserDetail}
              tooltipHelp={helpTooltipExpandUserDetail}
              tooltipPlace="left"
              testId={userDetailExpandTestId}
              testIdHelp={userDetailExpandHelpTestId}
            />
            <Column.Head>
              <Column.HeaderTitle isActive>
                {this.props?.labels?.searcher_collobration_inactive_detail_text}
              </Column.HeaderTitle>
              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({
                    userCompanyDetailCollapsed: true,
                  })
                }
                tooltipButton={toolTipCollapseUserDetail}
                tooltipHelp={helpTooltipCollapseUserDetail}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId={userDetailCollapsedTestId}
                testIdHelp={userDetailCollapsedHelpTestId}
              />
            </Column.Head>
            <Column.Body className="flex">
              {IsSearcher ? (
                <UserDetail
                  selectedUser={selectedUser}
                  labels={this.props.labels}
                />
              ) : (
                <CompanyDetail
                  selectedCompany={selectedCompany}
                  addressLabel={companyAddressLabel}
                  industryLabel={comapnyIndustryLabel}
                  countryLabel={companyCountryLabel}
                  phoneLabel={companyPhoneLabel}
                  webUrlLabel={companyWebUrlLabel}
                  vatLabel={companyVatLabel}
                />
              )}
            </Column.Body>
          </Column>
        )}
        {isRatingView && (
          <Column collapse={userCompanyDetailCollapsed} className="col-3">
            <Column.Collapsed
              text={"iPro Rating" || userDetailsColumnText}
              onClick={() =>
                this.props.onStateChangeAction({
                  userCompanyDetailCollapsed: false,
                })
              }
              isHelpActive={isHelpActive}
              tooltipButton={toolTipExpandUserDetail}
              tooltipHelp={helpTooltipExpandUserDetail}
              tooltipPlace="left"
              testId={userDetailExpandTestId}
              testIdHelp={userDetailExpandHelpTestId}
            />
            <Column.Head>
              <Column.HeaderTitle isActive>
                {"iPro Rating" || userDetailHeadingText}
              </Column.HeaderTitle>
              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({
                    userCompanyDetailCollapsed: true,
                  })
                }
                tooltipButton={toolTipCollapseUserDetail}
                tooltipHelp={helpTooltipCollapseUserDetail}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId={userDetailCollapsedTestId}
                testIdHelp={userDetailCollapsedHelpTestId}
              />
            </Column.Head>
            <Column.Body>
              {fetchingReviews ? (
                <LoadingMaskRow />
              ) : (
                !isEmpty(selectedCollaboration) && (
                  <ReviewForm
                    selectedCollaboration={selectedCollaboration}
                    onFormSelectChange={this.onFormSelectChange}
                    options={this.state.options}
                    onCloseCollaboration={this.closeCollaboration}
                    CollborationCloseReasonId={
                      this.state.CollborationCloseReasonId
                    }
                    reviewQuestions={this.props.reviewQuestions}
                    onSubmit={this.handleSubmit}
                    onRatingChange={this.handleRatingChange}
                    isAlreadyRated={this.props.isAlreadyRated}
                    isInActiveView={isInActiveView}
                  />
                )
              )}
            </Column.Body>
          </Column>
        )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = (
  {
    systemLabel,
    navigation,
    iproCollaborationNew,
    iproCollaborationInActive,
    iproCollaborationActive,
    searcherCollaborationPending,
    searcherCollaborationAccepted,
    searcherCollaborationDeclined,
    searcherCollaborationInActive,
  },
  {
    isIproActiveCollaboration,
    isIproInActiveCollaboration,
    isSentView,
    isAcceptedView,
    isDeclinedView,
    isInActiveView,
  }
) => {
  const {
    acceptedCollaboration,
    declinedCollaboration,
    unreadproInactiveCollaborations,
    searcherInActiveCollaboration,
    unreadCollaborations,
  } = navigation;
  const { labels } = systemLabel;
  const collaborationProps = isSentView
    ? searcherCollaborationPending
    : isAcceptedView
    ? searcherCollaborationAccepted
    : isDeclinedView
    ? searcherCollaborationDeclined
    : isInActiveView
    ? searcherCollaborationInActive
    : isIproActiveCollaboration
    ? iproCollaborationActive
    : isIproInActiveCollaboration
    ? iproCollaborationInActive
    : iproCollaborationNew;
  return {
    labels,
    acceptedCollaboration,
    declinedCollaboration,
    searcherInActiveCollaboration,
    unreadproInactiveCollaborations,
    unreadCollaborations,
    ...collaborationProps,
  };
};

const actions = {
  unreadCollaborationsAction,
  onStateChangeAction,
};
export default connect(mapStateToProps, actions)(Collaboration);
