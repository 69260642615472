import React, { Component } from "react";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { map } from "lodash";
import "./start-new-search-phillip.scss";
import { withRouter } from "react-router-dom";
import { getSearcherPhillipInfoForNewUserApi } from "./startNewSearchApi";
import "react-circular-progressbar/dist/styles.css";
import { publicRoutes } from "../../../../Routes/routing";
import { landingCandidateSearchAction } from "../../../../actions/landingActions";
import { Col, Row, Steps } from "antd";
import "../../components/phillip.scss";

const LOOKUPTYPES = {
  PROFILE: 1,
  SKILL: 2,
  KEYWORD: 3,
  CERTIFICATION: 4,
  COUNTRY: 5,
  LANGUAGE: 6,
  INDUSTRY: 7,
};

class StartNewSearch extends Component {
  state = {
    isSearched: false,
    isLoading: false,
    isCollapsed1: false,
    isCollapsed2: false,
    currentStep: 0,
  };

  componentDidMount = () => {};

  handleSearchClick = () => {
    const { keywords } = this.state;
    if (!keywords) {
      const info = {
        message: this?.props?.labels
          ?.searcher_phillips_start_new_search_input_error,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    this.setState({ isLoading: true });
    getSearcherPhillipInfoForNewUserApi({ keywords })
      .then((response) => {
        if (response.success) {
          let {
            Certifications = [],
            Countries = [],
            Industries = [],
            Keywords = [],
            Languages = [],
            Profiles = [],
            Skills = [],
          } = response.items;

          Certifications = map(Certifications, (certification) => ({
            CertificationId: certification.CertificationId,
            ExpLevel: 0,
            LookupValue: certification.CertificationValue,
            CertificationValue: certification.CertificationValue,
          }));
          Countries = map(Countries, (country) => ({
            CountryId: country.CountryId,
            ExpLevel: 0,
            LookupValue: country.CountryName,
            CountryName: country.CountryName,
          }));
          Industries = map(Industries, (industry) => ({
            IndustryId: industry.IndustryId,
            ExpLevel: 0,
            LookupValue: industry.IndustryValue,
            IndustryValue: industry.IndustryValue,
          }));
          Keywords = map(Keywords, (keyword) => ({
            KeywordId: keyword.KeywordId,
            ExpLevel: 0,
            LookupValue: keyword.KeywordValue,
            KeywordValue: keyword.KeywordValue,
          }));
          Languages = map(Languages, (language) => ({
            LanguageId: language.LanguageId,
            ExpLevel: 0,
            LookupValue: language.LanguageValue,
            LanguageValue: language.LanguageValue,
          }));
          Profiles = map(Profiles, (profile) => ({
            ProfileId: profile.ProfileId,
            ExpLevel: 0,
            LookupValue: profile.ProfileValue,
            ProfileValue: profile.ProfileValue,
          }));
          Skills = map(Skills, (skill) => ({
            SkillId: skill.SkillId,
            ExpLevel: 0,
            LookupValue: skill.SkillValue,
            SkillValue: skill.SkillValue,
          }));
          const extractedSearchSettings = {
            Profiles,
            Skills,
            Certifications,
            Keywords,
            Languages,
            Countries,
            Industries,
          };
          this.setState({
            extractedSearchSettings,
            isSearched: true,
            currentStep: 1,
          });
          this.props.landingCandidateSearchAction({
            searchCriteria: extractedSearchSettings,
          });
          this.setState({ isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleFindProfessionalsClick = () => {
    this.props.history.push(publicRoutes.visitorsearch.path);
  };

  render() {
    const { labels } = this.props;
    const {
      isSearched = false,
      isLoading,
    } = this.state;
    const { searchCriteria } = this.props;
    const { Profiles, Skills, Educations } = searchCriteria;
    return (
      <div
        className="reports-view-new-search"
        test-data-id="ipro-phillip-popup"
      >
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        <div className="phillips-search-steps-">
          <div
            className="phillip-component searcher-phillip marketing-page page-2"
            style={{
              padding: "20px 12px",
              background: "#fff!important",
              height: "-webkit-fill-available",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Steps
                current={this?.state?.currentStep}
                items={[
                  {
                    title: labels?.searcher_phillip_automate_step1_title,
                    description:
                      labels?.searcher_phillip_automate_step1_description,
                  },
                  {
                    title: labels?.searcher_phillip_automate_step2_title,
                    description:
                      labels?.searcher_phillip_automate_step2_description,
                  },
                ]}
              />
              <div
                style={{
                  flex: 1,
                  marginTop: "25px",
                }}
              >
                <Row
                  gutter={[24, 24]}
                  style={{
                    height: "100%",
                  }}
                >
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    className="steps"
                    style={{
                      margin: 0,
                    }}
                  >
                    <div
                      className="step"
                      style={{
                        background: "#F1EFFB",
                        borderRadius: "12px",
                        padding: "12px",
                        height: "100%",
                        border: this.state?.validation?.textareaError
                          ? "1px solid #f54949"
                          : null,
                      }}
                    >
                      <div
                        className="js_textarea_wrap body"
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <div className="text-area-wrapper">
                          <textarea
                            autoComplete="off"
                            className="js_textarea"
                            style={{
                              flex: 1,
                            }}
                            test-data-id="searcher-phillip-keyword-input"
                            placeholder={labels.landingWidgetPhillipSubTitle}
                            onChange={(e) => {
                              this.setState({
                                keywords: e.target.value,
                                validation: {
                                  textareaError: false,
                                },
                              });
                            }}
                            value={this.state.keywords}
                          >
                            {this.state.keywords}
                          </textarea>
                          <button
                            onClick={this.handleSearchClick}
                            className="action-btn"
                          >
                            {labels.searchForMe}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    className="steps"
                    style={{ margin: 0 }}
                  >
                    {
                      <div
                        id="step2"
                        className={`step ${!isSearched ? "disabled" : ""}`}
                        style={{
                          background: "#F1EFFB",
                          borderRadius: "12px",
                          padding: "12px",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className="left-div">
                          {isSearched && searchCriteria && (
                            <div className="first-div">
                              <div className="heading-div">
                                <span className="skillIcon"></span>
                                <label>{labels.Profiles}</label>
                              </div>
                              <div className="settings-container">
                                {Profiles && Profiles.length > 0 ? (
                                  Profiles.map((item) => (
                                    <label
                                      test-data-id="resume-edit-suggested-role-item"
                                      className="draggable"
                                      key={item.Id}
                                    >
                                      {item.LookupValue}
                                    </label>
                                  ))
                                ) : (
                                  <div>{labels.noDataFoundMessage}</div>
                                )}
                              </div>
                              <div className="heading-div">
                                <span className="profileIcon"></span>
                                <label>Skills</label>
                              </div>
                              <div className="settings-container">
                                {Skills && Skills.length > 0 ? (
                                  Skills.map((item) => (
                                    <label
                                      test-data-id="resume-edit-suggested-role-item"
                                      className="draggable"
                                      key={item.Id}
                                    >
                                      {item.LookupValue}
                                    </label>
                                  ))
                                ) : (
                                  <div>{labels.noDataFoundMessage}</div>
                                )}
                              </div>
                              <div className="heading-div">
                                <span className="educationIcon"></span>
                                <label>Educations</label>
                              </div>
                              <div className="settings-container">
                                {Educations && Educations.length > 0 ? (
                                  Educations.map((item) => (
                                    <label
                                      test-data-id="resume-edit-suggested-role-item"
                                      className="draggable"
                                      onClick={() =>
                                        this.extractedSettingUpdate(
                                          LOOKUPTYPES.SKILL,
                                          item
                                        )
                                      }
                                      key={item.Id}
                                    >
                                      {item.LookupValue}
                                    </label>
                                  ))
                                ) : (
                                  <div>{labels.noDataFoundMessage}</div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>

                        <button
                          onClick={this.handleFindProfessionalsClick}
                          className="action-btn"
                        >
                          {labels.findProfessionals}
                        </button>
                      </div>
                    }
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation, landing }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { searchCriteria } = landing;
  const { isHelpActive } = navigation;
  let { FirstName } = user;
  FirstName =
    FirstName !== undefined ? FirstName : sessionStorage.getItem("userName");
  return { labels, FirstName, searchCriteria, isHelpActive };
};
export default connect(mapStateToProps, {
  notificationAction,
  landingCandidateSearchAction,
})(withRouter(StartNewSearch));
