import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../components";
import { notificationAction } from "../../actions";
import "./philip.scss";
import { privateRoutes } from "../../Routes/routing";
import { StorageService } from "../../api";
import { updatePhillipPopupStatusApi } from "../../components/Phillip/phillipApi";
import  IproPhillip from "./components/IproPhillip";

class Snapshot extends Component {
  state = {
    UserFeatures:[],
    token: "",
    ExpenseCategories: [],
    Currencies: [],
    isLoading: true,
    UserId:-1,
    dialogMessage:"",
    showPhillip: true,
    facebookClientId: "",
    linkedInClientId: "",
  };
  componentDidUpdate(prevProps) {
    // if (this.props.location !== prevProps.location) {
    //   this.onRouteChanged();
    // }
  }

  onRouteChanged() {
    const firstUrl = this.state.UserFeatures[0];
      var queryString = window.location.href;
      queryString = queryString.toLowerCase().split('ipro-philip')[1];
      if(queryString==""){
        if(firstUrl)
        {
          const isApp = window.location.hash.toLowerCase().indexOf('apps') > - 1;
          this.props.history.push((isApp ? "/apps":"" ) + "/ipro-philip/"+firstUrl.Url)
        }
    }
  }
  componentDidMount() {
    // getSnapshotMenusApi({appTypeId:2,featureTypeId:23})
    // .then(res=>{
    //   this.setState({
    //     UserFeatures:res.items
    //   })
    //   const {items} = res;
    //   const firstUrl = items[0];
    //   var queryString = window.location.href;
    //   queryString = queryString.toLowerCase().split('ipro-philip')[1];
    //   if(queryString==""){
    //     if(firstUrl)
    //     {
    //       const isApp = window.location.hash.toLowerCase().indexOf('apps') > - 1;
    //       this.props.history.push((isApp ? "/apps":"" ) + "/ipro-philip/"+firstUrl.Url)
    //     }
    // }
    // })
    // .catch(err=>console.log("Err ",err))

    let { UserEmail } = this.props.User;
    let Email = UserEmail;
    if(!Email){
      const User = StorageService.getUser();
      Email = User.Email;
    }
    this.setState({
      UserId : this.props.User.UserId
    })
  }
  handleOkClick = ()=>{
    this.props.history.push(privateRoutes.dashboard.path);
  }
  handleClosePhillip = () => {
    const { IsFreelancer } = this.props;
    updatePhillipPopupStatusApi({ isFreelancer: true }).then(
      response => {}
    );
    this.setState({ showPhillip: false });
  };
  handleShowPhillip = () => {
    this.setState({ showPhillip: true });
  };
  handleSearcherPhillipSkip = () => {
    const { IsFreelancer } = this.props;
    updatePhillipPopupStatusApi({ isFreelancer: true }).then(
      response => {
        if (response.success) {
          const info = {
            message: this.props.searcherPhillipSkip,
            status: "success"
          };
          this.props.notificationAction(info);
        }
      }
    );
    this.setState({ showPhillip: false });
  };
  handleUpdateCompanyPresentationsWidget = () => {
    this.setState({
      isReloadCompanyPresentationsWidget: !this.state
        .isReloadCompanyPresentationsWidget
    });
  }
  handleUpdateResumeShortlistWidgetWidget = () => {
    this.setState({
      isReloadResumeShortlistWidget: !this.state.isReloadResumeShortlistWidget
    });
  };
  reloadResumeWidgetHandler = () => {
    this.setState({ isReloadResumeWidget: !this.state.isReloadResumeWidget });
  };
  render() {
    const { labels, isHelpActive,location } = this.props;
    const {
      showPhillip,
      facebookClientId,
      linkedInClientId,
      UserFeatures,token,Currencies,dialogMessage,ExpenseCategories,isLoading,UserId,pendingtimesheet,Rejectedtimesheet,Rejectedexpense,Pendingexpense} = this.state;
    const hashesList=[
      "#/apps/ipro-philip/",
      "#/ipro-philip/",
      "#/apps/ipro-philip",
      "#/ipro-philip"
    ];
    const currentViewHash = window.location.hash.toLowerCase().split('/')[1] == 'apps' ? window.location.hash.toLocaleLowerCase().split('/')[3] : window.location.hash.toLocaleLowerCase().split('/')[2];
    return (
      <PageWrapper className="snapshot-page">
        {/* {showPhillip && (
          <SearcherPhillip
            open={showPhillip}
            onCloseModal={this.handleClosePhillip}
            onSkip={this.handleSearcherPhillipSkip}
            facebookClientId={facebookClientId}
            linkedInClientId={linkedInClientId}
            onCompanyPresentationsUpdate={this.handleUpdateCompanyPresentationsWidget}
            onResumeShortlistUpdate={this.handleUpdateResumeShortlistWidgetWidget}
          />
        )} */}
        {showPhillip && (<IproPhillip
        open={showPhillip}
        onCloseModal={this.handleClosePhillip}
        onSkip={this.handleSearcherPhillipSkip}
        facebookClientId={facebookClientId}
        linkedInClientId={linkedInClientId}
        onResumeUpdate={this.reloadResumeWidgetHandler}
      />)}
        {/* {isLoading && <LoadingMask text="" />}
        <SnapshotNavigation
          labels={labels}
          isHelpActive={isHelpActive}
          UserFeatures={UserFeatures}
        />
        {dialogMessage &&(
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleOkClick}
              >
                {"Ok"}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {!isLoading && <React.Fragment>
          {currentViewHash == "home" && UserFeatures.findIndex(a=>a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash) > -1 &&  
          <Dashboard
          token={token}
          Readyforapproval={pendingtimesheet}
          Rejected={Rejectedtimesheet}
          asOwnerCount={"12"}
          Rejectedexpense={Rejectedexpense}
          Pendingexpense={Pendingexpense}
          />
        }
          {currentViewHash == "mywork" && UserFeatures.findIndex(a=>a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash) > -1 && <MyWork 
          token={token} 
          Currencies = {Currencies} 
          ExpenseCategories = {ExpenseCategories} 
        />}
        {currentViewHash == "manageipros" && UserFeatures.findIndex(a=>a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash) > -1 && <ManageIPros 
          token={token} 
          Currencies = {Currencies} 
          ExpenseCategories = {ExpenseCategories}
          UserId= {UserId} 
          locationProp={location}
        />}
        {currentViewHash == "manageflows" && UserFeatures.findIndex(a=>a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash) > -1 && <ManageFlows 
          token={token} 
          Currencies = {Currencies} 
          ExpenseCategories = {ExpenseCategories}
          UserId= {UserId} 
        />} 
        </React.Fragment>} */}

        </PageWrapper>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Snapshot);
