import React, { Children } from "react";
import moment from "moment";
import { DatePicker, Select } from "../../../../../common";
import { CustomInputNew } from "../../../../Tictell/views/components/CustomInput";

class TimestampDetail extends React.Component {
  render() {
    const{
      snapshotDate,
      currentTimelog,
      ReviewedBylabel,
      ReviewedDatelabel,
      StartTimeLabel,
      EndTimeLabel,
      DurationLabel,
      MemoLabel,
      CommentLabel,
      AmountLabel,
      Dateformatelabel,
      labels,
      Status
    } = this.props;
    const{
      TimeLogId,
      Date,
      Memo="",
      Amount,
      StartTime="",
      EndTime = "",
      Duration = "",
      Currency,
      ReviewedBy,
      ReviewedDate
    } = currentTimelog;
    return (
      <div className="timelog-detail">
         <CustomInputNew label={snapshotDate} >
          <label className="datepicker-wrapper">
              <DatePicker
                selected={Date && moment(Date)}
                test-data-id={"datepicker"}
                name="expenseDate"
                dateFormat="DD-MM-YYYY"
                placeholderText={Dateformatelabel}
                disabled
              />
            </label>
        </CustomInputNew>
        <div className="start-end">
          <CustomInputNew  label={labels.Timelog_StartTime}>
            <label
              className="datepicker-wrapper"
            >
            <DatePicker
              selected={StartTime && moment("2017-03-13" + ' ' + StartTime)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="End Time"
              dateFormat={'HH:mm'}
              disabled
              timeFormat={"HH:mm"}
              placeholderText={labels.Timelog_Time_Placeholder}
            />
            </label>
          </CustomInputNew>
          <label>to</label>
          <CustomInputNew label={labels.Timelog_EndTime}>
            <label
              className="datepicker-wrapper"
            >
            <DatePicker
              selected={EndTime && moment("2017-03-13" + ' ' + EndTime)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="End Time"
              dateFormat={'HH:mm'}
              timeFormat={"HH:mm"}
              disabled
              placeholderText={labels.Timelog_Time_Placeholder}
            />
            </label>
          </CustomInputNew>
        </div>
        <CustomInputNew label={DurationLabel} >
          <input
              type="text"
              autoComplete="off"
              value={Duration}
              name="Duration"
              className={`input-text `}
              placeholder={DurationLabel}
              test-data-id="input-text-opportunity-title"
              disabled
            />
        </CustomInputNew>
        <CustomInputNew label={AmountLabel} >
          <input
              type="text"
              autoComplete="off"
              value={!Amount || !Currency ? '' : `${Amount} ${Currency}`}
              name="Amount"
              className={`input-text `}
              placeholder={AmountLabel}
              test-data-id="input-text-opportunity-title"
              disabled
            />
        </CustomInputNew>
        <CustomInputNew label={MemoLabel}>
          <textarea
              type="text"
              value={Memo || ""}
              name="Memo"
              className="textarea-field"
              rows="9"
              rows="6"
              placeholder={CommentLabel}
              test-data-id="input-text-opportunity-description"
              disabled
            />
        </CustomInputNew>
        {(((Status===2 ||Status===3))&&(ReviewedBy!==null))&&
        <div>
        <CustomInputNew label={ReviewedBylabel} >
          <input
              type="text"
              autoComplete="off"
              value={ReviewedBy||""}
              name="ReviewedBy"
              className={`input-text `}
              placeholder={ReviewedBylabel}
              test-data-id="input-text-opportunity-title"
              disabled
            />
        </CustomInputNew>
        <CustomInputNew label={ReviewedDatelabel} >
          <input
              type="text"
              autoComplete="off"
              value={moment(ReviewedDate).format('DD-MM-YYYY')||""}
              name="ReviewedDate"
              className={`input-text `}
              placeholder={ReviewedDatelabel}
              test-data-id="input-text-opportunity-title"
              disabled
            />
        </CustomInputNew>
        </div>}
      </div>
    );
  }
}

export default TimestampDetail;
