import { Button, Input, Modal, Space } from "antd";
import React from "react";
import "./search.scss";
import { useSelector } from "react-redux";
import { useState } from "react";
import closeIcon from '../../assets/images/Icons/close-new.png'
import ModalImage from '../../assets/images/save-shortlist-popup.png'

const SaveSearchModal = ({ open, setOpen, handleCreateNew }) => {
  const labels = useSelector((state) => state?.systemLabel?.labels);
  const [error, setError] = useState(false);
  return (
    <>
      <Modal
        open={open?.open}
        onCancel={() => {
          setOpen((st) => ({
            ...st,
            open: false,
            id: "",
            value: "",
          }));
        }}
        closable={false}
        footer={null}
        className={`new-design-modal`}
      >
        <div className="text-center header-modal">
          <h5>Create Save Search</h5>
          <div
            onClick={() => {
              setOpen((st) => ({
                ...st,
                open: false,
                id: "",
                value: "",
              }));
            }}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="form">
          <div className="images-icon">
            <img className="mb-2" src={ModalImage} alt="" />
            <p className="paragraph">Please enter some title to save your search filter</p>
          </div>
          <Input
            placeholder="Enter search name"
            value={open?.value || ""}
            autoFocus={true}
            size="large"
            onChange={(e) => {
              e.persist();
              setOpen((St) => ({
                ...St,
                value: e.target.value,
              }));
              setError(false);
            }}
            style={
              error
                ? {
                  marginTop: "8px",
                  border: "1px solid red",
                }
                : { marginTop: "8px" }
            }
          />
          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-form-button mt-3"
            size="large"
            onClick={() => {
              if (open?.value == "") {
                setError(true);
                return;
              }
              handleCreateNew(0);
            }}
          >
            Create
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default SaveSearchModal;
