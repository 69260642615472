import React, { Component } from "react";
import { PageWrapper } from "../../../components";
import { connect } from "react-redux";
import { EmptyInfo } from "../../../common";
import { notificationAction } from "../../../actions";
import "./messagenew.scss";
import { getAllUserApi, sendMessage } from "../messageApi";
import CreateMessage from "./components/CreateMessage";
import EmptyCreateMessage from "./EmptyCreateMessage";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import map from "lodash/map";
import includes from "lodash/includes";
import { onStateChangeAction } from "./messageNewAction";
import toLower from "lodash/toLower";
import { Col, Row, Input, Space, Radio, Select, Typography, Checkbox } from "antd";
import ArrowLeft from "../../../assets/images/Icons/arrow-left.png";
import DeleteIcon from '../../../assets/images/Icons/delete-modal.png'
import SearchIcon from '../../../assets/images/secrch-icon.png'
const { Title } = Typography

class MessageNew extends Component {
  state = { searchListHover: false, windowWidth: window?.innerWidth, messageDetail: false };

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    var url = this.props.location.pathname;
    const lastSegment = url.split("/").pop();
    this.setState({
      assortAction: lastSegment,
    });
    this.getAllUsers();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getAllUsers = () => {
    const { IsFreelancer, notificationAction, labels } = this.props;
    this.props.onStateChangeAction({ isFetching: true });
    getAllUserApi({ IsFreelancer }).then((data) => {
      if (data.success) {
        if (isEmpty(data.items)) {
          notificationAction({
            status: "info",
            message: IsFreelancer
              ? labels.InfoIProCreateMsgRestriction
              : labels.searcherCreateMessageWithoutInvitationInfo,
          });
        }
        const filterData = data?.items?.map((single) => {
          return {
            ...single,
            label: single?.UserFirstname,
            value: single?.UserId,
          };
        });
        this.props.onStateChangeAction({
          users: filterData,
          filterUsers: filterData,
          isFetching: false,
        });
      } else {
        this.props.onStateChangeAction({ isFetching: false });
      }
    });
  };

  handleContentChange = (e) => {
    const { value, name } = e.target;
    const { messageForm } = this.props;
    const data = {
      ...messageForm,
      [name]: value,
    };
    this.props.onStateChangeAction({ messageForm: data });
  };

  handleSubmitMessage = () => {
    const {
      messageForm: { title, content },
      selectedUsers,
    } = this.props;
    const { notificationAction, labels } = this.props;
    if (isEmpty(title)) {
      notificationAction({
        status: "error",
        message: labels.InfoIProCreateMsgTitleError,
      });
      return;
    }
    if (isEmpty(content)) {
      notificationAction({
        status: "error",
        message: labels.InfoIProCreateMsgBodyError,
      });
      return;
    }
    if (isEmpty(selectedUsers)) {
      notificationAction({
        status: "error",
        message: labels.InfoIProCreateMsgEmptyReceiverError,
      });
      return;
    }
    const message = {
      IsRead: false,
      MessageBody: content,
      MessageId: 0,
      Subject: title,
      UserIds: map(selectedUsers, (user) => user.UserId),
      UserRequestMessageId: 0,
    };

    this.props.onStateChangeAction({ isFetching: true });
    sendMessage({ message })
      .then((response) => {
        this.props.onStateChangeAction({
          selectedUsers: [],
          messageForm: { title: "", content: "" },
          isFetching: false,
        });
        notificationAction({
          status: "success",
          message: labels.InfoIProCreateMsgAddSuccessfully,
        });
      })
      .catch((error) => {
        this.props.onStateChangeAction({ isFetching: false });
      });
  };

  handleSearchChange = (e) => {
    const searchKey = e.target.value;
    const { users } = this.props;
    const filterUsers = filter(users, (user) => {
      if (includes(toLower(user.UserFirstname), toLower(searchKey))) {
        return user;
      }
    });
    this.props.onStateChangeAction({ filterUsers });
  };

  handleSearchListSelected = ({ selectedUser }) => {
    const { notificationAction, labels, selectedUsers } = this.props;
    const alreadySelected = filter(
      selectedUsers,
      (user) => user.UserId === selectedUser.UserId
    );
    if (isEmpty(alreadySelected)) {
      this.props.onStateChangeAction({
        selectedUsers: [...selectedUsers, selectedUser],
        isSearchFocus: false,
      });
      return;
    } else {
      const duplicate = selectedUsers?.filter(
        (x) => x?.UserId != selectedUser?.UserId
      );
      this.props.onStateChangeAction({
        selectedUsers: duplicate,
        isSearchFocus: false,
      });
    }
  };

  handleSearchBlur = () => {
    const { searchListHover } = this.state;
    if (searchListHover) return;
    this.props.onStateChangeAction({ isSearchFocus: false });
  };

  handleRemoveSelectedUser = (item) => {
    let { selectedUsers } = this.props;
    selectedUsers = filter(
      selectedUsers,
      (user) => user.UserId !== item.UserId
    );
    this.props.onStateChangeAction({ selectedUsers });
  };

  render() {
    const {
      listCollapsed,
      formCollapsed,
      users,
      messageForm,
      isSearchFocus,
      selectedUsers,
      isFetching,
      filterUsers,
      labels,
      notificationAction,
      isHelpActive,
      createMessageCollapsed,
    } = this.props;

    return  (
      <PageWrapper className="collaboration">
        <div className="h-100 new-design-search w-100 d-flex flex-column" >
          <div className="tabs-header-col">

            <div className="d-flex align-items-center gap-8 cursor-pointer"
              onClick={() => {
                if (this?.state?.messageDetail) {
                  this.setState(st => ({ ...st, messageDetail: false }))
                } else {
                  this.props.history.goBack()
                }
              }}>
              <div>
                <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
              </div>
              <Title level={5} className="m-0">{this?.state?.messageDetail ? "New Message" : "Create Message"}</Title>
            </div>

          </div>
          <Row className=" h-100" style={{ overflow: "auto" }}>
            <Col xs={24} md={8} className="collboration-column1">
              {this?.state?.messageDetail ?
                <div className="new-collaboration-detail-component message-detail-new">
                  <CreateMessage
                    handleContentChange={this.handleContentChange}
                    messageForm={messageForm}
                    isFetching={isFetching}
                    onMessageSend={this.handleSubmitMessage}
                    windowWidth={this?.state?.windowWidth}
                  />
                </div>
                :
                <div className="h-100 d-flex flex-column">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "12px",
                      borderBottom: "1px solid #F3F3F3",
                      gap: "10px"
                    }}
                  >
                    <Input
                      placeholder={labels?.searchInputPlaceholder}
                      size="medium"
                      bordered={false}
                      style={{ border: "1px solid #F3F3F3" }}
                      onChange={this.handleSearchChange}
                    />
                    <div>
                      <img style={{ height: "30px" }} src={SearchIcon} alt="" />
                    </div>
                  </div>
                  <div
                    style={{
                      overflow: "auto",
                      padding: "12px",
                      flex: 1
                    }}
                  >
                    {filterUsers?.length > 0 ? (
                      <Space size={[6, 6]} wrap className="short-list-new">
                        {filterUsers?.map((item) => {
                          const selected = selectedUsers?.some(
                            (x) => x?.UserId == item?.UserId
                          );
                          return (
                            <div
                              onClick={() =>
                                this.handleSearchListSelected({
                                  selectedUser: item,
                                })
                              }
                              key={item?.UserCompanyId}
                              className={`d-flex gap-8 justify-content-between align-items-center pointer ${selected
                                ? "background-shortlist short-list-item "
                                : "short-list-item"
                                }`}>
                              <div className="d-flex  gap-8 w-100 justify-content-betwee align-items-center">
                                <Checkbox
                                  value={item?.ResumeId}
                                  checked={selected}
                                  style={{
                                    fontSize: "large",
                                  }}
                                />
                                <div className="text-clamp" style={{ flex: 1 }}>
                                  <label style={{ fontSize: "14px" }}>
                                    {item?.UserFirstname}
                                  </label>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </Space>
                    ) : (
                      <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                    )}
                  </div>
                  {(this?.state?.windowWidth < 767) && (
                    <div style={{ margin: "auto" }}>
                      <div className="tabs-header-col mt-3">
                        <button className="tab-btn-coll"
                          style={{ background: "#6C63FF", color: "#fff" }}
                          onClick={() => { this.setState(st => ({ ...st, messageDetail: true })) }}
                        >
                          Confirm & Send Message
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              }
            </Col>
            <Col md={16} xs={0} offset={0.5}
              className={"h-100"}
            >
              <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                <div className="new-collaboration-detail-component message-detail-new">
                  <CreateMessage
                    handleContentChange={this.handleContentChange}
                    messageForm={messageForm}
                    isFetching={isFetching}
                    onMessageSend={this.handleSubmitMessage}
                    windowWidth={this?.state?.windowWidth}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation, messageNew }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { IsFreelancer } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return { labels, IsFreelancer, isHelpActive, ...messageNew };
};
export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(MessageNew);
