import React, { Component } from "react";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { map } from "lodash";
import "./searcher-phillip.scss";
import { withRouter } from "react-router-dom";
import { getSearcherPhillipInfoForNewUserApi } from "./searcherPhllipApi";
import "react-circular-progressbar/dist/styles.css";
import { htmlParser } from "../../../../utilities";
import { publicRoutes } from "../../../../Routes/routing";
import { landingCandidateSearchAction } from "../../../../actions/landingActions";
import SliderSrc from "../../../../assets/images/phillip/search-for-me.svg";

const LOOKUPTYPES = {
  PROFILE: 1,
  SKILL: 2,
  KEYWORD: 3,
  CERTIFICATION: 4,
  COUNTRY: 5,
  LANGUAGE: 6,
  INDUSTRY: 7,
};

class MarketAnalyzer extends Component {
  state = {
    isSearched: false,
  };

  componentDidMount = () => {};

  handleSearchClick = () => {
    const { keywords } = this.state;
    getSearcherPhillipInfoForNewUserApi({ keywords }).then((response) => {
      if (response.success) {
        let {
          Certifications = [],
          Countries = [],
          Industries = [],
          Keywords = [],
          Languages = [],
          Profiles = [],
          Skills = [],
        } = response.items;

        Certifications = map(Certifications, (certification) => ({
          CertificationId: certification.CertificationId,
          ExpLevel: 0,
          LookupValue: certification.CertificationValue,
          CertificationValue: certification.CertificationValue,
        }));
        Countries = map(Countries, (country) => ({
          CountryId: country.CountryId,
          ExpLevel: 0,
          LookupValue: country.CountryName,
          CountryName: country.CountryName,
        }));
        Industries = map(Industries, (industry) => ({
          IndustryId: industry.IndustryId,
          ExpLevel: 0,
          LookupValue: industry.IndustryValue,
          IndustryValue: industry.IndustryValue,
        }));
        Keywords = map(Keywords, (keyword) => ({
          KeywordId: keyword.KeywordId,
          ExpLevel: 0,
          LookupValue: keyword.KeywordValue,
          KeywordValue: keyword.KeywordValue,
        }));
        Languages = map(Languages, (language) => ({
          LanguageId: language.LanguageId,
          ExpLevel: 0,
          LookupValue: language.LanguageValue,
          LanguageValue: language.LanguageValue,
        }));
        Profiles = map(Profiles, (profile) => ({
          ProfileId: profile.ProfileId,
          ExpLevel: 0,
          LookupValue: profile.ProfileValue,
          ProfileValue: profile.ProfileValue,
        }));
        Skills = map(Skills, (skill) => ({
          SkillId: skill.SkillId,
          ExpLevel: 0,
          LookupValue: skill.SkillValue,
          SkillValue: skill.SkillValue,
        }));
        const extractedSearchSettings = {
          Profiles,
          Skills,
          Certifications,
          Keywords,
          Languages,
          Countries,
          Industries,
        };
        this.setState({
          extractedSearchSettings,
          isSearched: true,
        });
        this.props.landingCandidateSearchAction({
          searchCriteria: extractedSearchSettings,
        });
      }
    });
  };

  handleFindProfessionalsClick = () => {
    this.props.history.push(publicRoutes.visitorsearch.path);
  };

  render() {
    const { labels } = this.props;
    const { isSearched = false } = this.state;
    const { searchCriteria } = this.props;
    const { Profiles, Skills, Educations } = searchCriteria;
    return (
      <div
        className="phillip-dashboard-searcher"
        test-data-id="ipro-phillip-popup"
      >
        <h2 className="proHeading">{labels.landingWidgetPhillipTitle}</h2>
        <div className="main-div">
          <div className="right-div">
            <React.Fragment>
              <div className="phil_textarea_wrap">
                <h6 className="subHeading">
                  {labels.landingWidgetPhillipSubTitle}
                </h6>
                <textarea
                  autoComplete="off"
                  className="phil_textarea"
                  test-data-id="searcher-phillip-keyword-input"
                  placeholder={htmlParser(
                    labels.searcherOnboardingSearchSlideTxtPlaceHolder
                  )}
                  onChange={(e) => this.setState({ keywords: e.target.value })}
                />
                <button
                  type="button"
                  className="continue-button"
                  onClick={this.handleSearchClick}
                  test-data-id="searcher-phillip-continue-button"
                >
                  {labels.searchForMe}
                </button>
              </div>
            </React.Fragment>
          </div>
          <div className="left-div">
            {!isSearched && (
              <React.Fragment>
                <div className="first-view">
                  <div className="sliderImageWrap">
                    <img className="sliderImg" src={SliderSrc} alt="slider" />
                  </div>
                </div>
              </React.Fragment>
            )}
            {isSearched && searchCriteria && (
              <div className="first-div">
                <div className="heading-div">
                  <span className="skillIcon"></span>
                  <label>{labels.Profiles}</label>
                </div>
                <div className="settings-container">
                  {Profiles && Profiles.length > 0 ? (
                    Profiles.map((item) => (
                      <label
                        test-data-id="resume-edit-suggested-role-item"
                        className="draggable"
                        key={item.Id}
                      >
                        {item.LookupValue}
                      </label>
                    ))
                  ) : (
                    <div>{labels.noDataFoundMessage}</div>
                  )}
                </div>
                <div className="heading-div">
                  <span className="profileIcon"></span>
                  <label>Skills</label>
                </div>
                <div className="settings-container">
                  {Skills && Skills.length > 0 ? (
                    Skills.map((item) => (
                      <label
                        test-data-id="resume-edit-suggested-role-item"
                        className="draggable"
                        key={item.Id}
                      >
                        {item.LookupValue}
                      </label>
                    ))
                  ) : (
                    <div>{labels.noDataFoundMessage}</div>
                  )}
                </div>
                <div className="heading-div">
                  <span className="educationIcon"></span>
                  <label>Educations</label>
                </div>
                <div className="settings-container">
                  {Educations && Educations.length > 0 ? (
                    Educations.map((item) => (
                      <label
                        test-data-id="resume-edit-suggested-role-item"
                        className="draggable"
                        onClick={() =>
                          this.extractedSettingUpdate(LOOKUPTYPES.SKILL, item)
                        }
                        key={item.Id}
                      >
                        {item.LookupValue}
                      </label>
                    ))
                  ) : (
                    <div>{labels.noDataFoundMessage}</div>
                  )}
                </div>
                <div className="button-wrapper">
                  <button
                    type="button"
                    className="continue-button"
                    onClick={this.handleFindProfessionalsClick}
                    test-data-id="searcher-phillip-continue-button"
                  >
                    {labels.findProfessionals}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, landing }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { searchCriteria } = landing;
  let { FirstName } = user;
  FirstName =
    FirstName !== undefined ? FirstName : sessionStorage.getItem("userName");
  return { labels, FirstName, searchCriteria };
};
export default connect(
  mapStateToProps,
  { notificationAction, landingCandidateSearchAction }
)(withRouter(MarketAnalyzer));
