import React, { useEffect, useState } from "react";
import {
  getEducationsApi,
  getResumeEducationsApi,
  addEducationApi,
  resumeItemDeleteApi,
} from "../../../Resume/ResumeEdit/resumeEditApi";
import { map, isEmpty } from "lodash";
import AsyncSelect from "react-select/lib/Async";
import { ConfirmDialog, Select } from "../../../../common";
import classes from "./education.module.scss";
import uuidv1 from "uuid/v1";
import EmptyResume from "../../../../common/emptyMessage/emptyResume";
import Trash from "../../../../assets/newResume/trash";
import { Col, Row } from "antd";
import DetailIcon from "../../../../assets/images/svg/detail.svg";
import { useDispatch } from "react-redux";
import { notificationAction } from "../../../../actions";

const EducationLevelsList = [
  { value: 1, label: "School" },
  { value: 3, label: "Diploma" },
  { value: 4, label: "Graduation" },
  { value: 6, label: "Master" },
  { value: 7, label: "Doctorate" },
  { value: 11, label: "Bachelor" },
];

export const Education = ({
  educationsList,
  onStateChangeAction,
  educationDefaultOptions,
  userDetails,
}) => {
  const [yearsList, setYearsList] = useState([]);
  const [selected, setSelected] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [educations, setEducations] = useState([]);
  const [deleteResume, setDeleteResume] = useState({
    show: false,
  });
  const [resumeCurentItemList, setResumeCurentItemList] = useState([]);
  useEffect(() => {
    getResumeEducations();
  }, []);
  useEffect(() => {
    handleYearsLoad();
  }, []);

  useEffect(() => {
    if (!educations.length) {
      setEducations([...educationsList]);
    }
  }, [educationsList]);

  const handleYearsLoad = () => {
    const currentYear = new Date().getFullYear();
    let startYear = 1970;
    const yrsList = [];
    while (startYear <= currentYear) {
      startYear++;
      const newYear = {
        value: startYear,
        label: startYear,
      };
      yrsList.push(newYear);
    }
    setYearsList(yrsList);
  };

  const getResumeEducations = () => {
    onStateChangeAction({ isLoading: true });

    getResumeEducationsApi().then((data) => {
      if (data.success) {
        setResumeCurentItemList(
          data.items.map((i) => {
            return { ...i, uniqueId: uuidv1() };
          })
        );
        onStateChangeAction({ isLoading: false });
      }
    });
  };
  const handleEducationsLoad = (searchKey, callback) => {
    if (isEmpty(searchKey)) {
      callback(null, {
        options: map(educationDefaultOptions, (Education) => {
          return {
            ...Education,
            label: Education.EducationValue,
            value: Education.EducationId,
          };
        }),
      });
      return;
    }
    if (searchKey.length < 3) {
      callback([]);
      return;
    }
    getEducationsApi({ searchKey }).then((response) => {
      if (response.success) {
        callback(null, {
          options: map(response.items, (Education) => {
            return {
              ...Education,
              label: Education.EducationValue,
              value: Education.EducationId,
            };
          }),
        });
      }
    });
  };
  const deleteExperience = (expr) => {
    onStateChangeAction({ isLoading: true });
    resumeItemDeleteApi({
      LookupTypeId: 8,
      ResumeId: userDetails.ResumeId,
      ObjectPK: expr.EducationId,
    })
      .then((res) => {
        if (res.success) {
          const tempExperience = resumeCurentItemList.filter(
            (item) => item.uniqueId !== expr.uniqueId
          );
          setResumeCurentItemList(tempExperience);
          // setExperience(tempExperience);
          // getResume();
          // getResumeExperiences();
          onStateChangeAction({ isLoading: false });
          setDeleteResume((st) => ({
            ...st,
            show: false,
            message: "",
            item: "",
          }));
        } else {
          onStateChangeAction({ isLoading: false });
        }
      })
      .catch(() => {
        onStateChangeAction({ isLoading: false });
      });
  };
  const handleEducationYearChange = (options, item, index) => {
    if (options?.value !== item?.EducationYear) {
      onStateChangeAction({ isLoading: true });
      const newFormValues = [...resumeCurentItemList];
      newFormValues[index]["EducationYear"] = options?.value;
      setResumeCurentItemList(newFormValues);
      addEducationApi(item)
        .then((res) => {
          if (res?.success) {
            dispatch(
              notificationAction({
                status: "success",
                message: "Resume education updated successfully",
              })
            );
            getResumeEducations();
          } else {
            getResumeEducations();
            dispatch(
              notificationAction({
                status: "error",
                message:
                  "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
              })
            );
          }
        })
        .catch(() => {
          getResumeEducations();
          dispatch(
            notificationAction({
              status: "success",
              message: "Resume education updated successfully",
            })
          );
        });
    }
  };
  const handleEducationLevelChange = (options, item, index) => {
    if (options?.value !== item?.EducationYear) {
      onStateChangeAction({ isLoading: true });
      const newFormValues = [...resumeCurentItemList];
      newFormValues[index]["EducationTypeId"] = options?.value;
      setResumeCurentItemList(newFormValues);
      addEducationApi(item)
        .then((res) => {
          if (res?.success) {
            dispatch(
              notificationAction({
                status: "success",
                message: "Resume education updated successfully",
              })
            );
            getResumeEducations();
          } else {
            getResumeEducations();
            dispatch(
              notificationAction({
                status: "error",
                message:
                  "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
              })
            );
          }
        })
        .catch(() => {
          getResumeEducations();
          dispatch(
            notificationAction({
              status: "error",
              message:
                "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
            })
          );
        });
    }
  };
  const handleChange = (value) => {
    setSelected(value);
  };

  return (
    <Row className={classes.education} justify="center">
      <Col xs={24} lg={18} md={22}>
        {/* <h1 className={classes.educationHead}>Education</h1> */}
        <Row className="align-items-center mb-3">
          <Col md={18} xs={24}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="EducationEditSelect">
                <AsyncSelect
                  placeholder="Select Education"
                  cacheOptions
                  defaultOptions
                  clearable={false}
                  searchable={true}
                  onBlurResetsInput={false}
                  onCloseResetsInput={false}
                  loadOptions={handleEducationsLoad}
                  onChange={handleChange}
                  value={
                    selected && {
                      value: selected.EducationId,
                      label: selected.EducationValue,
                    }
                  }
                />
              </div>
              <div className={classes.addEducation}>
                <button
                  className={classes.addEducationButton}
                  disabled={!selected}
                  onClick={() => {
                    onStateChangeAction({ isLoading: true });
                    addEducationApi({
                      EducationId: selected.EducationId,
                      EducationTypeId: 3,
                      EducationYear: "2018",
                      EducationValue: selected.EducationValue,
                    }).then((data) => {
                      if (data.success) {
                        getResumeEducations();
                        setSelected(false);
                      }
                    });
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </Col>
        </Row>
        {resumeCurentItemList.length > 0 ? (
          resumeCurentItemList.map((item, index) => (
            <Row
              gutter={[16, 16]}
              justify="space-between"
              className={classes.educationSelectMain}
              align="center"
              key={index}
            >
              <Col md={7} xs={21} className={classes.educationDetail}>
                <Row className={classes.detail}>
                  <Col md={0}>
                    <img
                      onClick={() => {
                        setShow(show === index ? "" : index);
                      }}
                      src={DetailIcon}
                      alt=""
                    />
                  </Col>
                  <Col className="w-100">
                    <p>{item?.EducationValue}</p>
                  </Col>
                </Row>
              </Col>
              <Col md={7} xs={0}>
                <div className="EducationEditSelect ">
                  <Select
                    className={`select-location select-input`}
                    placeholder="Select Education Level"
                    value={item.EducationTypeId}
                    onChange={(options) => {
                      handleEducationLevelChange(options, item, index);
                    }}
                    options={EducationLevelsList}
                    clearable={false}
                  />
                </div>
              </Col>
              <Col md={7} xs={0}>
                <div className="EducationEditSelect">
                  <Select
                    name={"Education Year"}
                    className={`select-location select-input`}
                    value={item.EducationYear}
                    onChange={(options) => {
                      handleEducationYearChange(options, item, index);
                    }}
                    options={yearsList}
                  />
                </div>
              </Col>
              <Col xs={3}>
                <div
                  className="pointer"
                  onClick={() => {
                    setDeleteResume((st) => ({
                      ...st,
                      show: true,
                      message: "Are you sure, you want to delete this?",
                      item: item,
                    }));
                  }}
                >
                  <Trash />
                </div>
              </Col>

              {show === index && (
                <Row gutter={[16, 16]} style={{ width: "100%" }}>
                  <Col xs={24} md={0}>
                    <div className="EducationEditSelect ">
                      <Select
                        className={`select-location select-input`}
                        placeholder="Select Education Level"
                        value={item?.EducationTypeId}
                        onChange={(options) =>
                          handleEducationLevelChange(options, item, index)
                        }
                        options={EducationLevelsList}
                        clearable={false}
                      />
                    </div>
                  </Col>
                  <Col md={0} xs={24}>
                    <div className="EducationEditSelect">
                      <Select
                        name={"Education Year"}
                        className={`select-location select-input`}
                        value={item.EducationYear}
                        onChange={(options) => {
                          handleEducationYearChange(options, item, index);
                        }}
                        options={yearsList}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Row>
          ))
        ) : (
          <EmptyResume text={`"No resume education added yet"`} />
        )}
        {deleteResume?.show && (
          <ConfirmDialog testId="company-confirm-diloag">
            <ConfirmDialog.Message>
              {deleteResume?.message}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-yes"
                onClick={() => {
                  deleteExperience(deleteResume?.item);
                }}
              >
                Yes
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-no"
                onClick={() => {
                  setDeleteResume((st) => ({
                    ...st,
                    show: false,
                    message: "",
                    item: "",
                  }));
                }}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
      </Col>
    </Row>
  );
};

export default Education;
