import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { filter, map, sortBy, replace } from "lodash";
import NavButton from "./components/NavButton";
import NavigationSkeleton from "./components/NavigationSkeleton";
import { Switch, NotificationCircle, Tooltip, HelpTooltip } from "../../common";
import { privateRoutes, publicRoutes } from "../../Routes/routing";
import { StorageService } from "../../api";
import { htmlParser } from "../../utilities/helpers";
import NavLinkButton from "./components/NavLinkButton";
import { loginAcionUpdate } from "../../store/initialConfig";
import {
  getNavigationMenusApi,
  getNavigationMenusForVisitorApi,
  changeProfileApi,
  getMessageCountApi,
  getCollaborationCountApi,
  getOpportunityCountApi,
  getUserApi,
  getIProCollaborationCountApi,
} from "./navigationApi";
import * as data from "../../../src/api/lefMenuForVisitor.json";
import {
  unreadMessagesAction,
  unreadCollaborationsAction,
  unreadOpportunitiesAction,
  resumeChangeAction,
} from "../../actions";
import "./navigation.scss";

const HelpGuideIcon = (props) => {
  const { testId, disabled, tooltipHelp, isHelpActive } = props;
  return (
    <span className={`nav-help-icon-container`}>
      <HelpTooltip testId={testId} content={tooltipHelp} />
    </span>
  );
};

const NavigationToolTip = ({ labels, children, Label, isHelpActive }) => (
  <>
    {isHelpActive || window.innerWidth <= 1240 ? (
      <>{children}</>
    ) : (
      <Tooltip
        followCursor={true}
        content={labels[replace(Label, "_LABEL", "_TOOLTIP")]}
      >
        <div>{children}</div>
      </Tooltip>
    )}
  </>
);

const SubNav = ({ children }) => (
  <div className="sub-nav-wrapper">{children}</div>
);
class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationMenu: [],
      isNavigationFetching: false,
      IsFreelancer: props.user.IsFreelancer,
    };
  }

  componentDidMount = () => {
    this.getCurrentUser();
  };

  changeProfile = () => {
    const {
      user: { IsFreelancer },
    } = this.props;
    changeProfileApi({ IsFreelancer }).then((response) => {
      if (response.success) {
        let resultUpdate = {
          ...response.items,
          isProfileChanged: true,
        };
        this.props.loginAcionUpdate({ user: resultUpdate });
        this.getNavigationMenus();
        this.props.history.push(privateRoutes.dashboard.path);
      }
    });
  };

  getNavigationMenus = () => {
    const {
      user: { IsFreelancer },
    } = this.props;
    const isVisitor = IsFreelancer === null;
    this.setState({ isNavigationFetching: true });
    if (isVisitor) {
      getNavigationMenusForVisitorApi({
        featureTypeId: 1,
        appTypeId: 1,
        isVisitor,
      }).then((response) => {
        if (response.success) {
          this.setState({
            navigationMenu: map(response.items, (item) => {
              if (item.Name === "NavHeader") {
                item.toggleDisabled = true;
                item.Url = publicRoutes.visitorsearch.path;
              }
              return item;
            }),
            isNavigationFetching: false,
          });
        }
      });
      return;
    }
    const featureTypeId = IsFreelancer ? 2 : 1;
    getNavigationMenusApi({ featureTypeId, appTypeId: 1 }).then((response) => {
      if (response.success) {
        const filteredNavigationMenu = filter(response.items, (item) => {
          if (
            item.FeatureType === 1 ||
            item.FeatureType === 2 ||
            item.FeatureType === 4 ||
            item.FeatureType === 5
          )
            return sortBy(item.SubNavigation, (subNavItem) => subNavItem.Order);
        });
        const navigationMenu = map(filteredNavigationMenu, (item, index) => {
          item.HelpIcon = false;
          item.testId = `navigation-item-${index + 1}`;
          item.SubNavigation = map(item.SubNavigation, (subNav, index) => {
            subNav.testId = `${item.testId}-sub-nav-item-${index + 1}`;
            return subNav;
          });
          return item;
        });
        this.setState({
          navigationMenu,
          IsFreelancer,
          isNavigationFetching: false,
        });
        const user = { ...this.props.user, userFeatures: navigationMenu };
        const UserSaved = StorageService.getUser();
        if (!UserSaved.isProfileChanged) {
          this.props.loginAcionUpdate({ user });
        }
        this.getMessageCount();
        this.getOpportunityCount();
        this.getCollaborationCount();
      }
    });
  };

  getMessageCount = () => {
    getMessageCountApi()
      .then((response) => {
        if (response.success) {
          let { navigationMenu } = this.state;
          this.props.unreadMessagesAction({ unreadMessages: response.total });
          navigationMenu = map(navigationMenu, (item) => {
            if (item.Name === "Message") {
              item.NotificationCount = this.props.unreadMessages;
              item.SubNavigation = map(item.SubNavigation, (subNav) => {
                if (subNav.Name === "MessageInbox") {
                  subNav.NotificationCount = this.props.unreadMessages;
                }
                return subNav;
              });
            }
            return item;
          });
          this.setState({ navigationMenu });
        }
      })
      .catch((response) => response);
  };

  getOpportunityCount = () => {
    const {
      user: { IsFreelancer },
    } = this.props;
    getOpportunityCountApi({ IsFreelancer })
      .then((response) => {
        if (response.success) {
          let { navigationMenu } = this.state;
          if (IsFreelancer) {
            this.props.unreadOpportunitiesAction({
              unreadOpportunities: {
                newOpportunities: response.items.NewRequests,
              },
            });
            navigationMenu = map(navigationMenu, (item) => {
              if (item.Name === "Opportunity") {
                if (IsFreelancer) {
                  const { newOpportunities } = this.props.unreadOpportunities;
                  item.NotificationCount = newOpportunities;
                  item.SubNavigation = map(item.SubNavigation, (subNav) => {
                    if (subNav.Name === "OpportunityCreate") {
                      subNav.NotificationCount = newOpportunities;
                    }
                    return subNav;
                  });
                }
              }
              return item;
            });
            this.setState({ navigationMenu });
          }
        }
      })
      .catch((response) => response);
  };

  getCollaborationCount = () => {
    const {
      user: { IsFreelancer },
    } = this.props;
    if (IsFreelancer) {
      getIProCollaborationCountApi()
        .then((response) => {
          if (response.success) {
            this.props.unreadCollaborationsAction({
              unreadCollaborations: response.items.newCollaborations,
              unreadproInactiveCollaborations: response.items.inActiveColl,
            });
            let { navigationMenu } = this.state;
            navigationMenu = map(navigationMenu, (item) => {
              if (item.Name === "Collaboration") {
                item.NotificationCount =
                  this.props.unreadCollaborations +
                  this.props.unreadproInactiveCollaborations;
                item.SubNavigation = map(item.SubNavigation, (subNav) => {
                  if (subNav.Name === "CollaborationNew") {
                    subNav.NotificationCount = this.props.unreadCollaborations;
                  }
                  if (subNav.Name === "CollaborationInActive") {
                    subNav.NotificationCount = this.props.unreadproInactiveCollaborations;
                  }
                  return subNav;
                });
              }
              return item;
            });
            this.setState({ navigationMenu });
          }
        })
        .catch((response) => response);
    } else {
      getCollaborationCountApi()
        .then((response) => {
          if (response.success) {
            let { navigationMenu } = this.state;
            this.props.unreadCollaborationsAction({
              acceptedCollaboration: response.items.acceptedColl,
              declinedCollaboration: response.items.declinedColl,
              searcherInActiveCollaboration: response.items.inActiveColl,
            });
            navigationMenu = map(navigationMenu, (item) => {
              if (item.Name === "CollaborationSearcher") {
                item.NotificationCount =
                  this.props.acceptedCollaboration +
                  this.props.searcherInActiveCollaboration +
                  this.props.declinedCollaboration;
                item.SubNavigation = map(item.SubNavigation, (subNav) => {
                  if (subNav.Name === "CollaborationSearcherAccepted") {
                    subNav.NotificationCount = this.props.acceptedCollaboration;
                  }
                  if (subNav.Name === "CollaborationSearcherDeclined") {
                    subNav.NotificationCount = this.props.declinedCollaboration;
                  }
                  if (subNav.Name === "CollaborationSearcherInActive") {
                    subNav.NotificationCount = this.props.searcherInActiveCollaboration;
                  }
                  return subNav;
                });
              }
              return item;
            });
            this.setState({ navigationMenu });
          }
        })
        .catch((response) => response);
    }
  };
  getCurrentUser = () => {
    this.setState({ isNavigationFetching: true });
    if (this.props.user.IsFreelancer) {
      getUserApi().then((response) => {
        if (response.success) {
          this.getNavigationMenus();
        }
      });
    } else {
      this.getNavigationMenus();
    }
  };

  handleNotificationsCount = (nextProps) => {
    const {
      unreadMessages,
      unreadOpportunities,
      unreadCollaborations,
      acceptedCollaboration,
      declinedCollaboration,
      unreadproInactiveCollaborations,
      searcherInActiveCollaboration,
    } = nextProps;
    const { newOpportunities } = unreadOpportunities;
    let { navigationMenu } = this.state;
    if (
      unreadMessages !== this.props.unreadMessages ||
      unreadCollaborations !== this.props.unreadCollaborations ||
      unreadOpportunities !== this.props.unreadOpportunities ||
      acceptedCollaboration !== this.props.acceptedCollaboration ||
      declinedCollaboration !== this.props.declinedCollaboration ||
      searcherInActiveCollaboration !== this.props.searcherInActiveCollaboration ||
      unreadproInactiveCollaborations !== this.props.unreadproInactiveCollaborations
    ) {
      navigationMenu = map(navigationMenu, (item) => {
        if (item.Name === "Message") {
          item.NotificationCount = unreadMessages;
          item.SubNavigation = map(item.SubNavigation, (subNav) => {
            if (subNav.Name === "MessageInbox") {
              subNav.NotificationCount = unreadMessages;
            }
            return subNav;
          });
        }
        if (item.Name === "Opportunity") {
          item.NotificationCount = newOpportunities;
          item.SubNavigation = map(item.SubNavigation, (subNav) => {
            if (subNav.Name === "OpportunityCreate") {
              subNav.NotificationCount = newOpportunities;
            }
            return subNav;
          });
        }
        if (item.Name === "Collaboration") {
          item.NotificationCount = unreadCollaborations + unreadproInactiveCollaborations;
          item.SubNavigation = map(item.SubNavigation, (subNav) => {
            if (subNav.Name === "CollaborationNew") {
              subNav.NotificationCount = unreadCollaborations;
            }
            if (subNav.Name === "CollaborationInActive") {
              subNav.NotificationCount = unreadproInactiveCollaborations;
            }
            return subNav;
          });
        }
        if (item.Name === "CollaborationSearcher") {
          item.NotificationCount =
            acceptedCollaboration +
            declinedCollaboration +
            searcherInActiveCollaboration;
          item.SubNavigation = map(item.SubNavigation, (subNav) => {
            if (subNav.Name === "CollaborationSearcherAccepted") {
              subNav.NotificationCount = acceptedCollaboration;
            }
            if (subNav.Name === "CollaborationSearcherDeclined") {
              subNav.NotificationCount = declinedCollaboration;
            }
            if (subNav.Name === "CollaborationSearcherInActive") {
              subNav.NotificationCount = searcherInActiveCollaboration;
            }
            return subNav;
          });
        }
        return item;
      });
      this.setState({ navigationMenu });
    }
  };

  toggleNavigation = (nextProps) => {
    let { navigationMenu } = this.state;
    if (window.innerWidth <= 1240 && nextProps.isNavShrink) {
      return;
    }
    if (
      nextProps.isNavShrink ||
      (window.innerWidth <= 1240 && !nextProps.isNavShrink)
    ) {
      navigationMenu = map(navigationMenu, (item) => {
        item.SubNavActive = false;
        return item;
      });
      this.setState({ navigationMenu });
    }
  };

  handleNavigationMenuActive = (nextProps) => {
    const { location, isNavShrink } = nextProps;
    let { navigationMenu } = this.state;
    navigationMenu = map(navigationMenu, (menu) => {
      if (menu.SubNavigation.length > 0) {
        const currentSubMenu = filter(
          menu.SubNavigation,
          (subNav) => subNav.Url === location.pathname
        );

        if (
          currentSubMenu.length > 0 &&
          currentSubMenu[0].ParentFeatureId === menu.Id
        ) {
          // for mobile devices selcted navigation will be selected and sub navigations will be shown
          if (isNavShrink && window.innerWidth <= 1240) {
            menu.SubNavActive = true;
            menu.isSubNavActive = true;
          } else if (
            // for mobile devices selcted navigation will be selected and sub navigations will not be shown
            // for desktop same behaviour will be implement but when navigation will be shrink
            isNavShrink ||
            (!isNavShrink && window.innerWidth <= 1240)
          ) {
            menu.SubNavActive = false;
            menu.isSubNavActive = true;
          } else {
            // for desktop selected navigation will be shown and active
            menu.SubNavActive = true;
            menu.isSubNavActive = true;
          }
        } else {
          //all sub menu will be deactive when click menu that dont have sub menu
          menu.isSubNavActive = false;
        }
      }
      return menu;
    });
    this.setState({ navigationMenu });
  };

  componentWillReceiveProps(nextProps) {
    this.handleNotificationsCount(nextProps);
    this.toggleNavigation(nextProps);
    this.handleNavigationMenuActive(nextProps);
    if (nextProps.isRoleSwitchToggle !== this.props.isRoleSwitchToggle) {
      this.props.resumeChangeAction({
        isRoleSwitchToggle: false,
      });
      this.changeProfile();
    }
  }

  handleForSubNavClick = (selectedItem) => {
    const { Name } = selectedItem;
    let { navigationMenu } = this.state;
    navigationMenu = map(navigationMenu, (item) => {
      if (item.Name === Name) {
        item.SubNavActive = !item.SubNavActive;
        return item;
      }
      return item;
    });
    this.props.onSubMenuOpen();
    this.props.onMobileNavShrink();
    this.setState({ navigationMenu });
  };

  handleSwitchChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { isResumeChange, resumeChangeAction, labels } = this.props;
    if (isResumeChange) {
      resumeChangeAction({
        isResumeChange,
        isRoleSwitchTrigger: true,
        message: labels.RESUME_EDIT_UNSAVED_CHANGES_MESSAGE,
      });
      return;
    }
    this.changeProfile();
  };

  handleSubMenuClick = ({ selectedMenuId, selectedSubMenuUrl }) => {
    this.setState(
      {
        selectedSubMenuUrl,
        selectedMenuId,
      },
      () => {
        let { selectedSubMenuUrl, selectedMenuId, navigationMenu } = this.state;

        if (this.props.location.pathname == selectedSubMenuUrl) {
          navigationMenu = map(navigationMenu, (item) => {
            item.isSubNavActive = item.Id === selectedMenuId;
            return item;
          });
          this.props.onNavShrink();
          this.setState({ navigationMenu });
        }
      }
    );
  };

  handleMenuClick = () => {
    let { navigationMenu } = this.state;
    navigationMenu = map(navigationMenu, (item) => {
      item.isSubNavActive = false;
      return item;
    });
    this.props.onNavShrink();
    this.setState({ navigationMenu });
  };

  render() {
    const {
      isNavShrink,
      isHelpActive,
      user: { IsFreelancer },
    } = this.props;
    const { navigationMenu, isNavigationFetching } = this.state;
    return (
      <div
        className={`navigation ${isNavShrink && "nav-shrink-container"}`}
        test-data-id={`${
          IsFreelancer ? "ipro-navigation-list" : "searcher-navigation-list"
        }`}
      >
        {isNavigationFetching ? (
          <NavigationSkeleton />
        ) : (
          <div>
            {map(navigationMenu, (menu) => {
              const {
                Id,
                Label,
                Url,
                CssClassIcon,
                CssClass,
                Name,
                IsActive,
                NotificationBadge,
                NotificationCount,
                SubNavActive,
                testId,
                SubNavigation,
                isSubNavActive,
                Disabled,
                toggleDisabled,
              } = menu;
              if (Name === "NavHeader") {
                return (
                  IsActive && (
                    <Fragment key={Id}>
                      <NavLinkButton
                        className={CssClass}
                        classIcon={CssClassIcon}
                        to={Url}
                        testId={testId}
                        onClick={this.handleMenuClick}
                      >
                        {htmlParser(this.props.labels[Label])}
                        {isHelpActive && (
                          <HelpGuideIcon
                            testId={`${testId}-help`}
                            isNavShrink={isNavShrink}
                            tooltipHelp={
                              this.props.labels[
                                replace(Label, "_LABEL", "_HELP_TOOLTIP")
                              ]
                            }
                          />
                        )}
                        {!toggleDisabled && (
                          <Switch
                            IsFreelancer={this.state.IsFreelancer}
                            onChange={this.handleSwitchChange}
                          />
                        )}
                      </NavLinkButton>
                    </Fragment>
                  )
                );
              }
              if (SubNavigation && !SubNavigation.length) {
                return (
                  IsActive && (
                    <Fragment key={Id}>
                      <NavigationToolTip
                        labels={this.props.labels}
                        Label={Label}
                        isHelpActive={isHelpActive}
                      >
                        <NavLinkButton
                          disabled={Disabled}
                          className={CssClass}
                          classIcon={CssClassIcon}
                          to={Url}
                          testId={testId}
                          onClick={this.handleMenuClick}
                        >
                          {htmlParser(this.props.labels[Label])}
                          {isHelpActive && (
                            <HelpGuideIcon
                              testId={`${testId}-help`}
                              isNavShrink={isNavShrink}
                              tooltipHelp={
                                this.props.labels[
                                  replace(Label, "_LABEL", "_HELP_TOOLTIP")
                                ]
                              }
                            />
                          )}
                          {NotificationBadge && NotificationCount > 0 && (
                            <NotificationCircle
                              testId={`${testId}-notification`}
                            >
                              {NotificationCount > 9 ? "9+" : NotificationCount}
                            </NotificationCircle>
                          )}
                        </NavLinkButton>
                      </NavigationToolTip>
                    </Fragment>
                  )
                );
              }

              if (SubNavigation && SubNavigation.length > 0) {
                return (
                  IsActive && (
                    <Fragment key={Id}>
                      <NavigationToolTip
                        labels={this.props.labels}
                        Label={Label}
                        isHelpActive={isHelpActive}
                      >
                        <NavButton
                          disabled={Disabled}
                          onClick={() => this.handleForSubNavClick(menu)}
                          className={`arrowCls ${CssClass} ${
                            isSubNavActive ? "activeBtn" : ""
                          }`}
                          classIcon={CssClassIcon}
                          name={Label}
                          testId={testId}
                        >
                          {htmlParser(this.props.labels[Label])}
                          {isHelpActive && (
                            <HelpGuideIcon
                              testId={`${testId}-help`}
                              isNavShrink={isNavShrink}
                              tooltipHelp={
                                this.props.labels[
                                  replace(Label, "_LABEL", "_HELP_TOOLTIP")
                                ]
                              }
                            />
                          )}
                          {NotificationBadge && NotificationCount > 0 && (
                            <NotificationCircle
                              testId={`${testId}-notification`}
                            >
                              {NotificationCount > 9 ? "9+" : NotificationCount}
                            </NotificationCircle>
                          )}
                        </NavButton>
                      </NavigationToolTip>
                      {SubNavActive && (
                        <SubNav>
                          {map(
                            SubNavigation,
                            (subNav) =>
                              subNav.IsActive && (
                                <NavigationToolTip
                                  key={subNav.Id}
                                  labels={this.props.labels}
                                  Label={subNav.Label}
                                  isHelpActive={isHelpActive}
                                >
                                  <NavLinkButton
                                    className={subNav.CssClass}
                                    classIcon={subNav.CssClassIcon}
                                    to={subNav.Url}
                                    testId={subNav.testId}
                                    onClick={() =>
                                      this.handleSubMenuClick({
                                        selectedMenuId: menu.Id,
                                        selectedSubMenuUrl: subNav.Url,
                                      })
                                    }
                                  >
                                    {htmlParser(
                                      this.props.labels[subNav.Label]
                                    )}
                                    {isHelpActive && (
                                      <HelpGuideIcon
                                        testId={`${subNav.testId}-help`}
                                        isNavShrink={isNavShrink}
                                        tooltipHelp={
                                          this.props.labels[
                                            replace(
                                              Label,
                                              "_LABEL",
                                              "_HELP_TOOLTIP"
                                            )
                                          ]
                                        }
                                      />
                                    )}
                                    {subNav.NotificationBadge &&
                                      subNav.NotificationCount > 0 && (
                                        <NotificationCircle
                                          testId={`${subNav.testId}-notification`}
                                        >
                                          {subNav.NotificationCount > 9
                                            ? "9+"
                                            : subNav.NotificationCount}
                                        </NotificationCircle>
                                      )}
                                  </NavLinkButton>
                                </NavigationToolTip>
                              )
                          )}
                        </SubNav>
                      )}
                    </Fragment>
                  )
                );
              }
            })}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  resume: { isResumeChange, isRoleSwitchToggle },
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const {
    isHelpActive,
    unreadMessages,
    unreadOpportunities,
    unreadCollaborations,
    acceptedCollaboration,
    declinedCollaboration,
    unreadproInactiveCollaborations,
    searcherInActiveCollaboration,
  } = navigation;
  return {
    labels,
    user,
    isHelpActive,
    unreadMessages,
    unreadOpportunities,
    unreadCollaborations,
    acceptedCollaboration,
    searcherInActiveCollaboration,
    declinedCollaboration,
    unreadproInactiveCollaborations,
    isResumeChange,
    isRoleSwitchToggle,
  };
};

const actions = {
  loginAcionUpdate,
  unreadMessagesAction,
  unreadCollaborationsAction,
  unreadOpportunitiesAction,
  resumeChangeAction,
};
export default connect(
  mapStateToProps,
  actions
)(withRouter(Navigation));
