import React, { Component } from "react";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "./benefits.scss";
import iproOffer from "../../../../assets/images/snapshot/ipro-offer.svg";
import searcherOffer from "../../../../assets/images/snapshot/manager-offer.svg";

import ib1 from "../../../../assets/images/snapshot/ib1.png";
import ib2 from "../../../../assets/images/snapshot/ib2.png";
import ib3 from "../../../../assets/images/snapshot/ib3.png";
import ib4 from "../../../../assets/images/snapshot/ib4.png";
import ib5 from "../../../../assets/images/snapshot/ib5.png";
import ib6 from "../../../../assets/images/snapshot/ib6.png";
import ib7 from "../../../../assets/images/snapshot/ib7.png";
import ib8 from "../../../../assets/images/snapshot/ib8.png";

import sb1 from "../../../../assets/images/snapshot/sb1.png";
import sb2 from "../../../../assets/images/snapshot/sb2.png";
import sb3 from "../../../../assets/images/snapshot/sb3.png";
import sb4 from "../../../../assets/images/snapshot/sb4.png";
import sb5 from "../../../../assets/images/snapshot/sb5.png";
import sb6 from "../../../../assets/images/snapshot/sb6.png";
import sb7 from "../../../../assets/images/snapshot/sb7.png";
import sb8 from "../../../../assets/images/snapshot/sb8.png";
import sb9 from "../../../../assets/images/snapshot/sb9.png";
import { compact, times } from "lodash";

const Slide = ({ className, children, testId }) => (
  <div className={`sliderJoin ${className}`} test-data-id={testId}>
    {children}
  </div>
);

class Professional extends Component {
  state = {
    isIProToggled: false,
    isSearcherToggled: false,
    currentSlide: 1,
  };
  onButtonClick = (name) => {
    this.setState({
      [`${name}`]: !this.state[`${name}`],
    });
  };
  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;
    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };
  generateCards = () => {
    const { labels } = this.props;
    const slideHtml = [
      <Slide key={1} className=" active">
        <ul className="tabList">
          <li className="tabItem">
            <h5>{labels.iproBenefitTitle1}</h5>
            <h6>{labels.iproBenefit1}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.iproBenefitTitle2}</h5>
            <h6>{labels.iproBenefit2}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.iproBenefitTitle3}</h5>
            <h6>{labels.iproBenefit3}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.iproBenefitTitle4}</h5>
            <h6>{labels.iproBenefit4}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.iproBenefitTitle5}</h5>
            <h6>{labels.iproBenefit5}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.iproBenefitTitle6}</h5>
            <h6>{labels.iproBenefit6}</h6>
          </li>
        </ul>
      </Slide>,
      <Slide key={2} className=" active">
        <ul className="tabList">
          <li className="tabItem">
            <h5>{labels.iproBenefitTitle7}</h5>
            <h6>{labels.iproBenefit7}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.iproBenefitTitle8}</h5>
            <h6>{labels.iproBenefit8}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.iproBenefitTitle9}</h5>
            <h6>{labels.iproBenefit9}</h6>
          </li>
        </ul>
      </Slide>,
    ];
    return slideHtml;
  };
  generateSearcherCards = () => {
    const { labels } = this.props;
    const slideHtml = [
      <Slide key={1} className=" active">
        <ul className="tabList">
          <li className="tabItem">
            <h5>{labels.searcherBenefitTitle1}</h5>
            <h6>{labels.searcherBenefit1}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.searcherBenefitTitle4}</h5>
            <h6>{labels.searcherBenefit4}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.searcherBenefitTitle6}</h5>
            <h6>{labels.searcherBenefit6}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.searcherBenefitTitle2}</h5>
            <h6>{labels.searcherBenefit2}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.searcherBenefitTitle3}</h5>
            <h6>{labels.searcherBenefit3}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.searcherBenefitTitle5}</h5>
            <h6>{labels.searcherBenefit5}</h6>
          </li>
        </ul>
      </Slide>,
      <Slide key={2} className=" active">
        <ul className="tabList">
          <li className="tabItem">
            <h5>{labels.searcherBenefitTitle7}</h5>
            <h6>{labels.searcherBenefit7}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.searcherBenefitTitle8}</h5>
            <h6>{labels.searcherBenefit8}</h6>
          </li>
          <li className="tabItem">
            <h5>{labels.searcherBenefitTitle9}</h5>
            <h6>{labels.searcherBenefit9}</h6>
          </li>
        </ul>
      </Slide>,
    ];
    return slideHtml;
  };
  render() {
    const { labels, isCommon } = this.props;
    return (
      <div className="benefit-page mainContent darkBg">
        <section className="contentContainer">
          <h6 className="proHeading">{labels.Section7PageTitle}</h6>
          <h6 className="subHeading">{labels.benefitPageTitle}</h6>
          <div className="benefitPanel">
            <h1 className="proHeading">{labels.benefitPageSubHeading1}</h1>
            <div className="inline-flex ipro-flex">
              <Carousel
                className="slideshow"
                swipeable
                showStatus={false}
                showIndicators={true}
                showArrows={false}
                infiniteLoop
                interval={5000}
                showThumbs={false}
              >
                {this.generateCards()}
              </Carousel>
              <img className="sideImg" src={iproOffer} alt="img" />
            </div>
          </div>
          {isCommon && <div className="benefitPanel">
            <h1 className="proHeading">{labels.benefitPageSubHeading2}</h1>
            <h6 className="subHeading">
              {labels.benefitPageSearcherTitle}
            </h6>
            <div className="inline-flex">
              <img className="sideImg" src={searcherOffer} alt="img" />
              <Carousel
                className="slideshow"
                swipeable
                showStatus={false}
                showIndicators={true}
                showArrows={false}
                infiniteLoop
                interval={5000}
                showThumbs={false}
              >
                {this.generateSearcherCards()}
              </Carousel>
            </div>
          </div>}
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(Professional);
