import React, { Component } from "react";
import { connect } from "react-redux";
import { htmlParser } from "../../../../utilities";

class Company extends Component {
  render() {
    const { labels } = this.props;
    return (
      <div className="companyPanel">
        <h6 className="subHeading">{labels.Section2HiringWorkplaceDescription}</h6>
        <ul className="tabList">
          <li className="tabItem">
            <i className="searchIcon" />
            <h5>{labels.Section2HiringSearchTitle}</h5>
            <h6>{htmlParser(labels.Section2HiringSearchDescription)}</h6>
          </li>
          <li className="tabItem">
            <i className="networkIcon" />
            <h5>{labels.Section2HiringNetworkTitle}</h5>
            <h6>{htmlParser(labels.Section2HiringNetworkDescription)}</h6>
          </li>
          <li className="tabItem">
            <i className="collaborationIcon" />
            <h5>{labels.Section2HiringCollaborationTitle}</h5>
            <h6>{htmlParser(labels.Section2HiringCollaborationDescription)}</h6>
          </li>
          <li className="tabItem">
            <i className="shortlistIcon" />
            <h5>{labels.Section2HiringShortlistTitle}</h5>
            <h6>{htmlParser(labels.Section2HiringShortlistDescription)}</h6>
          </li>
          <li className="tabItem">
            <i className="workplaceIcon" />
            <h5>{labels.Section2HiringWorkplaceTitle}</h5>
            <h6>{htmlParser(labels.Section2HiringWorkplaceeDescription)}</h6>
          </li>
          <li className="tabItem">
            <i className="opportunityIcon" />
            <h5>{labels.Section2HiringOpportunityTitle}</h5>
            <h6>{htmlParser(labels.Section2HiringOpportunityDescription)}</h6>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(Company);
