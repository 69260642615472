import React from "react";
import useWindowSize from "./useWindowSize";

const MultiLineContent = ({ text }) => {
    const {width} = useWindowSize();
    return(
	<>
		{text&&text.split('<br/>').map((elem, i) => (
			<React.Fragment key={i}>{elem} {i < text.length - 1 && width > 768  && <br/>}</React.Fragment>
		))}
	</>
)
        };

export default MultiLineContent