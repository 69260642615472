import React from "react";
import "./loadingMask.scss";
import Loader from "../../assets/images/loader2.gif";
const LoadingMask = ({ text = "Saving data..." }) => {
  return (
    <div id="loader-wrapper">
        <div test-data-id ={"loader"} id="loader"/>
        </div> 
  );
};
export default LoadingMask;
