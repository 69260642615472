import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert, Select } from "antd";
import classes from "./index.module.scss";
import closeIcon from '../../../../assets/images/Icons/close-new.png'
import { getAllLocationsApi } from "../../../Resume/ResumeEdit/resumeEditApi";
import LanguageImage from '../../../../assets/images/location-popup.png'

function EditLocationPopUp({
  isLoading,
  SaveResumeDetails,
  errorMessage,
  resumeLocation,
  handleLocationChange,
  labels,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [locationsList, setLocations] = useState(null);

  useEffect(() => {
    getAllLocations();
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = () => {
    setIsModalVisible(false);
    SaveResumeDetails();
  };

  const getAllLocations = () => {
    getAllLocationsApi().then((response) => {
      if (response.success) {
        const locations = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        setLocations(locations);
      }
    });
  };

  return (
    <>
      <button className="add-profile"
        onClick={() => setIsModalVisible(true)}
      >{labels.AddResumeHeadingLocation}</button>

      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        className={`new-design-modal ${classes.popUpForm}`}

      >
         <div className="text-center mb-3 header-modal">
          <h5>{labels.AddResumeHeadingLocation}</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="images-icon">
          <img src={LanguageImage} alt="" />
          <p className="paragraph">Please Select your country</p>
        </div>
        <Form
          name="normal_login"
          className={`${classes.form} form`}
          onFinish={onFinish}
          size="large"
        >
          <Form.Item
            rules={[
              {
                required: resumeLocation.CountryId ? false : true,
                message:
                  labels.companyCountryPlaceholder ||
                  "Please select Country",
              },
            ]}
            name="Country"
            className={classes.customSelect}
          >
            <Select
              placeholder={labels.companyCountryPlaceholder}
              onChange={(e) => handleLocationChange("CountryId", e)}
              allowClear
              autocomplete="new-password"
            >
              {locationsList &&
                locationsList.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          {error && (
            <Alert
              message={errorMessage?.replace("<br />", "")}
              type="warning"
              closable
            />
          )}

          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-form-button mt-2"
            loading={isLoading}
          >
            Done
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default EditLocationPopUp;
