import * as React from "react";

const Teacher = (props) => {
  return (
    <svg
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9008 3.68954L6.12168 9.42079C3.3071 11.2583 3.3071 15.3708 6.12168 17.2083L14.9008 22.9395C16.4758 23.975 19.0717 23.975 20.6467 22.9395L29.3821 17.2083C32.1821 15.3708 32.1821 11.2729 29.3821 9.43538L20.6467 3.70413C19.0717 2.65413 16.4758 2.65413 14.9008 3.68954Z"
        stroke={props.color || "#8F82F5"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.45501 19.075L8.44043 25.9145C8.44043 27.7666 9.8696 29.7499 11.6196 30.3333L16.2717 31.8791C17.0738 32.1416 18.4008 32.1416 19.2175 31.8791L23.8696 30.3333C25.6196 29.7499 27.0488 27.7666 27.0488 25.9145V19.1479"
        stroke={props.color || "#8F82F5"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.4531 21.875V13.125"
        stroke={props.color || "#8F82F5"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Teacher;
