import React from "react";
import ReactDOM from "react-dom";
import "rc-menu/assets/index.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-quill/dist/quill.snow.css";
import "react-select/dist/react-select.css";
import "rc-slider/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "pace-progressbar/themes/blue/pace-theme-minimal.css";
import "./assets/sass/style.scss";
import App from "./App";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "pace-progressbar";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
