import React from "react";
import Carousel from "react-multi-carousel";

import classes from "./Carousel.module.scss";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
  mobileXs: {
    breakpoint: { max: 300, min: 0 },
    items: 1,
  },
  
};

export default function CustomCarousel({ children }) {
  return (
    <div className={classes.carouselWrapper}>
      <Carousel
        containerClass="carousel-container"
        // itemClass='carousel-item-padding-40-px'
        responsive={responsive}
        itemClass={classes.carouselItem}
      >
        {children}
      </Carousel>
    </div>
  );
}
