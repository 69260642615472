import axios from "axios";
import { ApiUrl } from "../../api/apiUrls";

export const getShortlistApi = () =>
  axios.get(ApiUrl.Shortlists.Get).then(({ data }) => data);

  export const getSavedSearchesApi = () =>
  axios.get(ApiUrl.ResumeSearches.SavedSearchesBase).then(({ data }) => data);

  export const getSentOpportunitesApi = ({ isFreelancer }) => {
    return axios
      .get(ApiUrl.Opportunity.GetSentRequest({ isFreelancer }))
      .then(({ data }) => data)
      .catch(response => response);
  };