import axios from "axios";
import fromPairs from "lodash/fromPairs";
import map from "lodash/map";
import { ActionTypes } from ".";
import { StorageService, ApiUrl } from "../api";

export const systemLabelAction = () => (dispatch) => {
  axios
    .get(ApiUrl.SystemLabel.systemLabel)
    .then(({ data }) => {
      const labels = fromPairs(
        map(data.items, (item) => [item.Identifier, item.Message])
      );
      StorageService.saveSystemLabels(labels);
      dispatch({
        type: ActionTypes.SYSTEM_LABEL,
        payload: labels,
      });
    })
    .catch((error) => {
      console.log("error", error);
    });
};
