import React, { Component } from "react";
import "./shortlistResumes.scss";
import join from "lodash/join";
import { List, ConfirmDialog, LoadingMaskRow } from "../../../common";
import ClampLines from "react-clamp-lines";
import { notificationAction } from "./../../../actions";
import { Messages } from "../../../utilities";
import { deleteResumeApi } from "../shortlistApi";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  AvailablityGreenCriteria,
  AvailablityYellowCriteria,
} from "../../../utilities/constants";
import { CloseBtn } from "../../../common/CloseBtn";

class ShortlistResumes extends Component {
  state = {
    dialogMessage: "",
    scrollListHeight: "565px",
  };

  handleResize = () =>
    this.setState({
      scrollListHeight: this.resumeContainer.offsetHeight,
    });

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  renderProfiles = (resume) => {
    const profileValues = resume.Profiles.map((item) => item.ProfileValue);
    return join(profileValues, ", ");
  };
  renderSkills = (resume) => {
    const skillsValues = resume.Skills.map((item) => item.SkillValue);
    return join(skillsValues, ", ");
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedResume !== this.props.selectedResume) {
      this.forceUpdate();
    }
  }

  handleDeleteResume = (item) => {
    this.setState({
      deletedItem: item,
      dialogMessage: this.props.labels.oppRemoveResumeShortlistMessage,
    });
  };

  handleYesClick = () => {
    const { deletedItem } = this.state;
    this.setState({ dialogMessage: "" });
    deleteResumeApi(
      deletedItem.ResumeId,
      this.props.selectedShortlist.ShortlistId
    )
      .then((data) => {
        if (data) {
          const info = {
            message: data.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.setState({
            deletedItem: null,
          });
        }
        this.props.handleSelectShortlist(this.props.selectedShortlist);
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleNoClick = () => {
    this.setState({ dialogMessage: "", deletedId: null });
  };
  getAvailablity = (availableDate) => {
    const { searchedDate } = this.props;
    const ResumeBusyUntilDate = new Date(availableDate);
    const SearchAvailDate = new Date(searchedDate.date);

    const green = this.addDays(SearchAvailDate, AvailablityGreenCriteria); //SearchAvailDate.getDate() + 14;
    const yallow = this.addDays(SearchAvailDate, AvailablityYellowCriteria); //SearchAvailDate.getDate() + 60;

    if (ResumeBusyUntilDate <= green) {
      return "markedGreen";
    } else if (ResumeBusyUntilDate > green && ResumeBusyUntilDate < yallow) {
      return "markedYellow";
    } else if (ResumeBusyUntilDate >= yallow) {
      return "markedRed";
    }
  };
  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  render() {
    const {
      selectedResume,
      onResumeSelect,
      onShortlistActive,
      fetchingResumes,
      shortlistResumes,
      labels,
      showCorrelation,
      hideRemoveButton,
      onResumeDelete,
      isFetchMore,
      fetchMoreResumes,
    } = this.props;

 
    const { dialogMessage, scrollListHeight } = this.state;
    return (
      <div
        ref={(el) => (this.resumeContainer = el)}
        className="shortlist-resume-component"
      >
        {dialogMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <button className="dialog-btn" onClick={this.handleYesClick}>
                {labels.ShortlistResumeListDeletionCurtainYESBtnText}
              </button>
              <button className="dialog-btn" onClick={this.handleNoClick}>
                {labels.ShortlistResumeListDeletionCurtainNOBtnText}
              </button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}

        <InfiniteScroll
          dataLength={shortlistResumes.length}
          next={fetchMoreResumes}
          loadMore={fetchMoreResumes}
          hasMore={isFetchMore}
          loader={<LoadingMaskRow />}
          height={scrollListHeight || "560px"}
        >
          <List isFetching={fetchingResumes}>
            {shortlistResumes.map((item, index) => (
              <List.ListItem
                key={item.ResumeId}
                onClick={() => onResumeSelect(item)}
                className={`${
                  selectedResume.ResumeId === item.ResumeId
                    ? "selected-item"
                    : "listItems"
                }
                  ${showCorrelation ? "correlation-item" : ""}`}
              >
                {showCorrelation && (
                  <div
                    className={`SkillNum ${this.getAvailablity(
                      item.AvailabilityDate
                    )}`}
                  >
                    {item.CorrelationScore}
                  </div>
                )}
                <div className="profile">
                  <ClampLines
                    text={this.renderProfiles(item)}
                    lines={1}
                    buttons={false}
                  />
                </div>
                <div className="location">
                  <ClampLines text={item.Region} lines={1} buttons={false} />
                </div>
                <div className="skills">
                  {item.Skills && item.Skills.length && (
                    <ClampLines
                      text={this.renderSkills(item)}
                      lines={1}
                      buttons={false}
                      basedOn="letters"
                    />
                  )}
                </div>
                {!hideRemoveButton && (
                  <CloseBtn
                    classes="close_btn_purple for-list"
                    onClick={(e) => {
                      e.stopPropagation();
                      onResumeDelete(item);
                    }}
                  />
                )}
                {onShortlistActive && (
                  <div
                    className={`${
                      item.active ? "short-list-active" : ""
                    }  short-list`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onShortlistActive(item);
                    }}
                  />
                )}
              </List.ListItem>
            ))}
          </List>
        </InfiniteScroll>
        {/* 
        <List isFetching={fetchingResumes}>
          {shortlistResumes.map((item, index) => (
            <List.ListItem
              key={item.ResumeId}
              onClick={() => onResumeSelect(item)}
              className={`${
                selectedResume.ResumeId === item.ResumeId
                  ? "selected-item"
                  : "listItems"
              }
                  ${showCorrelation ? "correlation-item" : ""}`}
            >
              {showCorrelation && (
                <div
                  className={`SkillNum ${this.getAvailablity(
                    item.AvailabilityDate
                  )}`}
                >
                  {item.CorrelationScore}
                </div>
              )}
              <div className="profile">
                <ClampLines
                  text={this.renderProfiles(item)}
                  lines={1}
                  buttons={false}
                />
              </div>
              <div className="location">
                <ClampLines text={item.Region} lines={1} buttons={false} />
              </div>
              <div className="skills">
                {item.Skills && item.Skills.length && (
                  <ClampLines
                    text={this.renderSkills(item)}
                    lines={1}
                    buttons={false}
                    basedOn="letters"
                  />
                )}
              </div>
              {!hideRemoveButton && (
                <button
                  className="closeBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    onResumeDelete(item);
                  }}
                />
              )}
              {onShortlistActive && (
                <div
                  className={`${
                    item.active ? "short-list-active" : ""
                  }  short-list`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onShortlistActive(item);
                  }}
                />
              )}
            </List.ListItem>
          ))}
        </List> */}
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps, { notificationAction })(
  ShortlistResumes
);
