import React, { Component } from "react";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { isEmpty, map } from "lodash";
import "./market-analyzer.scss";
import { withRouter } from "react-router-dom";
import SliderSrc from "../../../../assets/images/phillip/upload-resume.svg";
import {
  UploadAndGetRolesAndSkillsFromRandomResumeApi,
  GetSuggestedProfilesForNewResumeApi,
  GetSuggestedSkillsForNewResumeApi,
  getCurrenciesApi,
  GetMarketSalaryApi,
  GetMarketTopSalariesBasedOnUserRolesApi,
} from "./nextstepApi";
import "react-circular-progressbar/dist/styles.css";
import ReactApexChart from "react-apexcharts";

const LOOKUPTYPES = {
  PROFILE: 1,
  SKILL: 2,
  KEYWORD: 3,
  CERTIFICATION: 4,
  COUNTRY: 5,
  LANGUAGE: 6,
  INDUSTRY: 7,
};

class MarketAnalyzer extends Component {
  state = {
    allCurrenciesList: [],
    selectedCurrency: {},
    selectedCountry: {
      value: 46,
      label: "Pakistan",
    },
    selectedDuration: {
      value: 1,
      label: "Yearly",
    },
    top3Salaries: [],
    currentSlide: 0,
    keywords: "",
    extractedSearchSettings: {
      Profiles: [],
      Skills: [],
    },
    resumeUploaded: false,
    progress: 0,
    popularityIndex: -1,
    salary: "",
    isLoading: true,
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function () {
          },
        },
      },
      colors: ["#04caa7", "#3e335e", "#3e335e"],
      bar: {
        columnWidth: "55%",
        distributed: true,
      },
      dataLabels: {
        enabled: true,
        align: "left",
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ["Entry", "Average", "Expert"],
      },
      labels: {
        style: {
          colors: ["#04caa7", "#04caa7", "#04caa7"],
          fontSize: "15px",
        },
      },
      title: {
        text: "",
      },
    },
  };

  componentDidMount = () => {
    this.getCurrencies();
  };

  getSuggestedRolesAndSkills = (roleIds) => {
    GetSuggestedProfilesForNewResumeApi(roleIds)
      .then((res) => {
        this.setState({
          extractedSearchSettings: {
            ...this.state.extractedSearchSettings,
            Profiles: res.items.map((a) => ({
              Id: a.CorrelatedProfiles.ProfileId,
              LookupValue: a.CorrelatedProfiles.ProfileValue,
            })),
            isLoading: false,
          },
        });
      })
      .catch(() => { });
    GetSuggestedSkillsForNewResumeApi(roleIds)
      .then((res) => {
        this.setState({
          extractedSearchSettings: {
            ...this.state.extractedSearchSettings,
            Skills: res.items.map((a) => ({
              Id: a.SkillId,
              LookupValue: a.SkillValue,
            })),
            isLoading: false,
          },
        });
      })
      .catch(() => { });
  };

  onFormSelectChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  getUserSpecficTopSalaries = (roleIds) => {
    const { selectedCountry, selectedCurrency, selectedDuration } = this.state;
    const body = {
      CountryId: selectedCountry.value,
      CurrencyId: selectedCurrency.value,
      DurationType: selectedDuration.value,
      RoleIds: roleIds,
    };
    this.setState({
      isLoading: true,
    });
    GetMarketTopSalariesBasedOnUserRolesApi(body)
      .then((res) => {
        let selectedSalary =
          res.items.Top3Salaries.length > 0 ? res.items.Top3Salaries[0] : {};
        const top3Salaries = res.items.Top3Salaries.map((a) => ({
          ...a,
          isSelected: a.Lookupvalue == selectedSalary.Lookupvalue,
        }));
        const graphData = [];
        graphData.push({
          name: `Salary in ${selectedSalary.Currency}`,
          data: [
            selectedSalary.Entry,
            selectedSalary.Average,
            selectedSalary.Senior,
          ],
        });
        this.setState({
          selectedSalary: { ...selectedSalary, isSelected: true },
          graphData,
          top3Salaries,
          isLoading: false,
        });
      })
      .catch((err) => console.log(err));
  };

  uploadLinkedinPdf = (e) => {
    var fileData = e.target.files[0];
    var types = "pdf";
    const { labels, notificationAction } = this.props;
    var fileType = fileData.name.split(".");
    this.setState({
      isLoading: true,
    });
    const me = this;
    if (types === fileType[1].toLowerCase()) {
      var reader = new FileReader();
      reader.onload = (function () {
        return function (e) {
          var fileDataInBytes = e.target.result.split(",")[1];
          me.setState({ isLoading: true });
          UploadAndGetRolesAndSkillsFromRandomResumeApi(fileDataInBytes)
            .then((response) => {
              if (response.success) {
                let {
                  Certifications = [],
                  Countries = [],
                  Industries = [],
                  Keywords = [],
                  Languages = [],
                  Profiles = [],
                  Skills = [],
                } = response.items;

                Certifications = map(Certifications, (certification) => ({
                  Id: certification.CertificationId,
                  ExpLevel: 0,
                  LookupValue: certification.CertificationValue,
                }));
                Countries = map(Countries, (country) => ({
                  Id: country.CountryId,
                  ExpLevel: 0,
                  LookupValue: country.CountryName,
                }));
                Industries = map(Industries, (industry) => ({
                  Id: industry.IndustryId,
                  ExpLevel: 0,
                  LookupValue: industry.IndustryValue,
                }));
                Keywords = map(Keywords, (keyword) => ({
                  Id: keyword.KeywordId,
                  ExpLevel: 0,
                  LookupValue: keyword.KeywordValue,
                }));
                Languages = map(Languages, (language) => ({
                  Id: language.LanguageId,
                  ExpLevel: 0,
                  LookupValue: language.LanguageValue,
                }));
                Profiles = map(Profiles, (profile) => ({
                  Id: profile.ProfileId,
                  ExpLevel: 0,
                  LookupValue: profile.ProfileValue,
                }));
                Skills = map(Skills, (skill) => ({
                  Id: skill.SkillId,
                  ExpLevel: 0,
                  LookupValue: skill.SkillValue,
                }));

                if (
                  isEmpty(Certifications) &&
                  isEmpty(Countries) &&
                  isEmpty(Industries) &&
                  isEmpty(Keywords) &&
                  isEmpty(Languages) &&
                  isEmpty(Profiles) &&
                  isEmpty(Skills)
                ) {
                  const info = {
                    message: labels.searcherOnboardingInvalidDescription,
                    status: "success",
                  };
                  notificationAction(info);
                  me.setState({
                    isLoading: false,
                  });
                  return;
                }
                const saveResumeParams = {
                  Certifications,
                  Countries,
                  Industries,
                  Keywords,
                  Languages,
                  Profiles,
                  Skills,
                };
                me.setState({
                  resumeUploaded: true,
                });
                const rolesIds = saveResumeParams.Profiles.map((x) => x.Id);
                me.getUserSpecficTopSalaries(rolesIds);
                me.getSuggestedRolesAndSkills(rolesIds);
              } else {
                this.setState({
                  isLoading: false,
                });
              }
            })
            .catch(() => {
              const info = {
                message: this.props.landindUploadErrorMessage,
                status: "error",
              };
              notificationAction(info);
              me.setState({ isLoading: false });
            });
        };
      })(fileData);

      reader.readAsDataURL(fileData);
    } else {
      const info = {
        message: this.props.landingSelectPdfMessage,
        status: "error",
      };
      notificationAction(info);
      this.setState({
        isLoading: false,
      });
    }
  };

  extractedSettingUpdate = () => {
    const info = {
      message: this.props.labels.landingSignupMessage,
      status: "info",
    };
    this.props.notificationAction(info);
    this.props.toggleSignupForm(true);
  };

  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          const selectedCurrency = allCurrenciesList[1];
          this.setState({
            allCurrenciesList: allCurrenciesList,
            selectedCurrency,
          });

          GetMarketSalaryApi(
            this.state.selectedCountry.value,
            selectedCurrency.value,
            this.state.selectedDuration.value
          )
            .then((res) => {
              let selectedSalary =
                res.items.Top3Salaries.length > 0
                  ? res.items.Top3Salaries[0]
                  : {};
              const top3Salaries = res.items.Top3Salaries.map((a) => ({
                ...a,
                isSelected: a.Lookupvalue == selectedSalary.Lookupvalue,
              }));
              const graphData = [];
              graphData.push({
                name: `Salary in ${selectedSalary.Currency}`,
                data: [
                  selectedSalary.Entry,
                  selectedSalary.Average,
                  selectedSalary.Senior,
                ],
              });
              this.setState({
                selectedSalary: { ...selectedSalary, isSelected: true },
                graphData,
                top3Salaries,
                isLoading: false,
              });
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log("Err ", err));
  }

  handleSalaryClick = (item) => {
    const graphData = [];
    graphData.push({
      name: `Salary in ${item.Currency}`,
      data: [item.Entry, item.Average, item.Senior],
    });
    this.setState({
      top3Salaries: this.state.top3Salaries.map((a) => ({
        ...a,
        isSelected: a.Lookupvalue == item.Lookupvalue,
      })),
      selectedSalary: item,
      graphData,
    });
  };

  render() {
    const {
      extractedSearchSettings,
      isLoading,
      selectedSalary,
      resumeUploaded = false,
    } = this.state;
    const { labels } = this.props;
    const { Profiles, Skills } = extractedSearchSettings;
    return (
      <div className="market-analyzer" test-data-id="ipro-phillip-popup">
        <h2 className="proHeading">{labels.landingWidgetNextstepTitle}</h2>
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        {!isLoading && (
          <div className="main-div">
            <div className="right-div">
              {!resumeUploaded && (
                <div className="first-view">
                  {" "}
                  <div className="sliderImageWrap">
                    <img className="sliderImg" src={SliderSrc} alt="slider" />
                  </div>
                  <div className="resume-upload-row">
                    <h6 className="subHeading">
                      {labels.landingWidgetNextstepSubTitle}
                    </h6>
                    <div
                      onChange={(e) =>
                        this.uploadLinkedinPdf(e, "OtherResumeSuccessMessage")
                      }
                      className="js_upload_btn"
                    >
                      {labels.iProOnboardingResumeOtherBtnText}
                      <input className="linkedInPdf" type="file" title=" " />
                    </div>
                  </div>
                </div>
              )}
              {resumeUploaded && (
                <React.Fragment>
                  <div className="heading-div">
                    <label>{labels.landingWidgetRoleSuggestion}</label>
                  </div>
                  <div className="settings-container">
                    {Profiles &&
                      Profiles.map((item) => (
                        <label
                          test-data-id="resume-edit-suggested-role-item"
                          className="draggable"
                          onClick={() =>
                            this.extractedSettingUpdate(
                              LOOKUPTYPES.PROFILE,
                              item
                            )
                          }
                          key={item.Id}
                        >
                          {item.LookupValue}
                        </label>
                      ))}
                  </div>
                  <div className="heading-div">
                    <label>{labels.landingWidgetSkillSuggestion}</label>
                  </div>
                  <div className="settings-container">
                    {Skills &&
                      Skills.map((item) => (
                        <label
                          test-data-id="resume-edit-suggested-role-item"
                          className="draggable"
                          onClick={() =>
                            this.extractedSettingUpdate(LOOKUPTYPES.SKILL, item)
                          }
                          key={item.Id}
                        >
                          {item.LookupValue}
                        </label>
                      ))}
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="left-div">
              {!isLoading && (
                <React.Fragment>
                  {selectedSalary && (
                    <h6>
                      {`Average Salary for ${selectedSalary.Lookupvalue} in  ${selectedSalary.Country} is ${selectedSalary.Average} ${selectedSalary.Currency}/${selectedSalary.DurationType}`}
                    </h6>
                  )}
                  {selectedSalary && (
                    <div className="mixed-chart">
                      <React.Fragment>
                        <div className="top-salaries">
                          {this.state.top3Salaries.map((item) => (
                            <div
                              className={`salary-item ${item.isSelected ? "selected" : ""
                                }`}
                              onClick={() => this.handleSalaryClick(item)}
                            >
                              <label>{item.Lookupvalue}</label>{" "}
                              <label>{`: ${item.Average} ${item.Currency}`}</label>
                            </div>
                          ))}
                        </div>
                      </React.Fragment>
                      <ReactApexChart
                        options={this.state.options}
                        series={this.state.graphData}
                        type="bar"
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  let { FirstName } = user;
  FirstName =
    FirstName !== undefined ? FirstName : sessionStorage.getItem("userName");
  return { labels, FirstName };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(withRouter(MarketAnalyzer));
