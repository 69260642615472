import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./pagewrapper.scss";
import { Notification } from "../../common";
class PageWrapper extends Component {
  constructor(props) {
    super(props);
    this.pageContainer = React.createRef();
    this.state = { scrollFromTop: 0 };
  }

  componentDidUpdate(nextProps) {
    if (this.pageContainer.current.scrollTo && this.state.scrollFromTop < 1) {
      this.pageContainer.current.scrollTo({
        top: 150,
        behavior: "smooth"
      });
      this.setState({ scrollFromTop: 150 });
      return;
    }
    this.pageContainer.current.scrollTop = 150;
  }

  render() {
    const { children, className, notification, disabled } = this.props;
    return (
      <div className={`page-wrapper ${className} `}>
        <div className={`${disabled} page-content dashHeadingBdr`}>
          {notification.message && (
            <Notification status={notification.status} className={`${notification.className}`}>
              {notification.message}
            </Notification>
          )}
          <div className="views-cnt" ref={this.pageContainer}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
PageWrapper.defaultProps = {
  className: ""
};
const mapStateToProps = ({ notification }) => {
  return { notification };
};
export default connect(mapStateToProps)(withRouter(PageWrapper));
