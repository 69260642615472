import React, { Component } from "react";
import { connect } from "react-redux";
import Network from "./Network.js";
import { notificationAction } from "../../actions/index.js";

class ConnectedNetwork extends Component {
    
    render() {
        const {labels}=this.props
        return (
            <Network
                isConnectedView={true}
                BtnAddOpportunity={labels?.searcher_network_connected_btn1_text}
                BtnAddCollaboration={labels?.searcher_network_connected_btn2_text}
            />
        );
    }
}

const mapStateToProps = ({ systemLabel }) => {
    const { labels } = systemLabel;
    return {
        labels,
    };
};

const actions = { notificationAction };
export default connect(
    mapStateToProps,
    actions
)(ConnectedNetwork);
