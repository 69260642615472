import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  searchKey: "",
  companyForm: {},
  companyList: [],
  filteredCompanies: [],
  selectedCompany: null,
  dialogMessage: "",
  countries: [],
  industries: [],
  userEmails: [],
  userPhoneNumbers: [],
  isLoading: false,
  isFetching: true,
  listCollapsed: false,
  formCollapsed: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.COMPANY.SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload
      };
    case ActionTypes.COMPANY.COMPANY_FORM:
      return {
        ...state,
        companyForm: action.payload
      };
    case ActionTypes.COMPANY.COMPANY_LIST:
      return {
        ...state,
        companyList: action.payload
      };
    case ActionTypes.COMPANY.FILTERED_COMPANIES:
      return {
        ...state,
        filteredCompanies: action.payload
      };
    case ActionTypes.COMPANY.SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload
      };
    case ActionTypes.COMPANY.DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: action.payload
      };
    case ActionTypes.COMPANY.COUNTRIES:
      return {
        ...state,
        countries: action.payload
      };
    case ActionTypes.COMPANY.INDUSTRIES:
      return {
        ...state,
        industries: action.payload
      };
    case ActionTypes.COMPANY.USER_EMAILS:
      return {
        ...state,
        userEmails: action.payload
      };
    case ActionTypes.COMPANY.USER_PHONE_NUMBERS:
      return {
        ...state,
        userPhoneNumbers: action.payload
      };
    case ActionTypes.COMPANY.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case ActionTypes.COMPANY.IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case ActionTypes.COMPANY.LIST_COLLAPSED:
      return {
        ...state,
        listCollapsed: action.payload
      };
    case ActionTypes.COMPANY.FORM_COLLAPSED:
      return {
        ...state,
        formCollapsed: action.payload
      };

    default:
      return state;
  }
};
