import React, { Component } from "react";
import { Button } from "../../../../../common";
import moment from "moment";
import "./opportunitydetail.scss";
import { CustomInputNew } from "../../../../Tictell/views/components/CustomInput";
class OpportunityDetail extends Component {
  renderDate = (date) => {
    return moment(date).format("MM/DD/YY");
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOpportunity !== this.props.selectedOpportunity) {
      this.props.onSelectedActionChange({ selectedAction: "" });
    }
  }
  render() {
    const {
      selectedOpportunity,
      onAcceptedResumes,
      onDeclinedResumes,
      onNoActionResumes,
      labels,
      selectedAction,
      onSelectedActionChange,
      declinedRequests,
      acceptedRequests,
      pendingRequests,
      onOpportunityChange,
      onOpprtunityNameUpdate,
      handleCollaborationNameEdit,
    } = this.props;
    const { isNameEdit, RequestName } = selectedOpportunity;
    return (
      <div className="opportunity-detail-component">
        <div className="MsgWrapper">
          <div className="start-end">
            <div className="time-expense-detail">
              <div className="start-end">
                <CustomInputNew label={"Opportunity Name"}>
                  <input
                    type="text"
                    autoComplete="off"
                    value={RequestName || ""}
                    name="Amount"
                    className={`input-text`}
                    test-data-id="input-opportunity-title"
                    onBlur={onOpprtunityNameUpdate}
                    onChange={onOpportunityChange}
                    disabled={isNameEdit && isNameEdit ? false : true}
                  />
                </CustomInputNew>
                <Button
                  testId={`opportunityUpdateBtn`}
                  className="add-btn"
                  isHelpActive={this.props.isHelpActive}
                  testIdHelp={"add-newLog-btn-help"}
                  tooltipHelp={
                    "Click on this button to edit the collaboration name"
                  }
                  tooltipButton={"Edit opportunity name"}
                  onClick={() => handleCollaborationNameEdit()}
                ></Button>
              </div>
              <CustomInputNew label={"Opportunity Details"}>
                <div className="headsup-opp-details">
                  <div>
                    <label>
                      {labels.SearcherOpportunitySentDetailStartDate}
                    </label>
                    <label>
                      {this.renderDate(selectedOpportunity.StartDate) || "N/A"}
                    </label>
                  </div>
                  <div>
                    <label>
                      {labels.SearcherOpportunitySentDetailDuration || "N/A"}
                    </label>
                    <label>
                      {selectedOpportunity.Duration || "N/A"}&nbsp;
                      {selectedOpportunity.DurationType || "N/A"}
                    </label>
                  </div>
                  <div>
                    <label>{labels.SearcherOpportunitySentDetailFee}</label>
                    <label>
                      {selectedOpportunity.HourlyFee || "N/A"} &nbsp;
                      {selectedOpportunity.FeeCurrencyType || "N/A"}
                    </label>
                  </div>
                  <div>
                    <label>
                      {labels.SearcherOpportunitySentDetailLocations}
                    </label>
                    <label> {selectedOpportunity.Countries || "N/A"}</label>
                  </div>
                  <div>
                    <label>
                      {labels.SearcherOpportunitySentDetailLanguages}
                    </label>
                    <label> {selectedOpportunity.Languages || "N/A"}</label>
                  </div>
                  <div>
                    <label>{labels.SearcherOpportunitySentDetailCompany}</label>
                    <label>{selectedOpportunity.CompanyName || "N/A"}</label>
                  </div>
                  <div>
                    <label>
                      {labels.SearcherOpportunitySentDetailSavedSearch}
                    </label>
                    <label>{selectedOpportunity.SearchName || "N/A"}</label>
                  </div>
                  <div>
                    <label>
                      {labels.SearcherOpportunitySentDetailDescription}
                    </label>
                    <label>{selectedOpportunity.Description || "N/A"}</label>
                  </div>
                </div>
              </CustomInputNew>
            </div>
          </div>
          <div className="feedbackWrap">
            <div className="action-buttons">
              <Button
                testId="sent-opportunity-detail-pending-label"
                className={`opp-pending ${
                  selectedAction === "pending" ? "active" : ""
                }`}
                onClick={() => {
                  onNoActionResumes();
                  onSelectedActionChange({ selectedAction: "pending" });
                }}
              >
                {labels.SearcherOpportunitySentDetailPending}&nbsp;
                {pendingRequests || 0}
              </Button>
              <Button
                testId="sent-opportunity-detail-accepted-label"
                className={`opp-accepted ${
                  selectedAction === "accepted" ? "active" : ""
                }`}
                onClick={() => {
                  onAcceptedResumes();
                  onSelectedActionChange({ selectedAction: "accepted" });
                }}
              >
                {labels.SearcherOpportunitySentDetailAccepted}&nbsp;
                {acceptedRequests || 0}
              </Button>
              <Button
                testId="sent-opportunity-detail-declined-label"
                onClick={() => {
                  onDeclinedResumes();
                  onSelectedActionChange({ selectedAction: "declined" });
                }}
                className={`opp-declined ${
                  selectedAction === "declined" ? "active" : ""
                }`}
              >
                {labels.SearcherOpportunitySentDetailDeclined} &nbsp;
                {declinedRequests || 0}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OpportunityDetail;
