import React, { Component } from "react";
import { BulletList, EmptyInfo, List } from "../../../common";
import ImgSrc from "../../../assets/images/present.svg";
import moment from "moment";
import "./networkList.scss";

class NetworkList extends Component {
  renderDate = (date) => {
    return moment(date).format("D/M/YYYY H:mm:ss");
  };
  render() {
    const {
      UsersList,
      handleNetworkClick,
      handleDeleteNetwork,
      selectedUser,
      isInvitedView,
      labels,
    } = this.props;
    return (
      <>
        {UsersList?.length > 0 ? (
          <List className="network-list-component">
            {UsersList.map((item) => {
              return (
                <List.ListItem
                  onClick={() => handleNetworkClick(item.UserId)}
                  key={item.UserId}
                  isSelected={selectedUser.UserId === item.UserId}
                >
                  <div
                    className={`dashItemImg ${
                      item.ProfilePicture ? "" : "no-dp"
                    }`}
                  >
                    <img
                      className="roundImg"
                      src={item.ProfilePicture ? item.ProfilePicture : ImgSrc}
                      alt="network"
                    />
                  </div>
                  <div className="CompNameCnt">
                    <div className="listLabel">
                      {item.UserFirstname +
                        " " +
                        (item.UserLastname ? item.UserLastname : "")}
                    </div>
                    <div>{item.UserEmail}</div>
                  </div>

                  {isInvitedView ? (
                    <BulletList.Close
                      testId="iProOpportunity-delete-item"
                      onClick={(e) => {
                        handleDeleteNetwork(item);
                        e.stopPropagation();
                      }}
                    />
                  ) : null}
                </List.ListItem>
              );
            })}
          </List>
        ) : (
          <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
        )}
      </>
    );
  }
}
export default NetworkList;
