import React, { Component } from "react";
import { EmptyInfo } from "../../../common";
import { connect } from "react-redux";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { Messages } from "../../../utilities";
import "./feedbackdetail.scss";

class FeedbackDetail extends Component {
  renderDetailDate = date => {
    return moment(date).format("ddd MMM DD YYYY H:mm:ss");
  };
  render() {
    const { labels, selectedFeedback } = this.props;
    return (
      <div className="feedback-detail-component">
        {!selectedFeedback.FeedbackId ? (
          <EmptyInfo>{labels.SplFeedback}</EmptyInfo>
        ) : (
          <div className="MsgWrapper">
            <div className="titleLabel">{selectedFeedback.Title}</div>
            <div className="feedbackWrap">
              <div className="msgItemDiv">
                <div className="msgItem">
                  <label className="labelName">
                    {labels.searcherFeedbackTime}:
                  </label>
                  <label className="answerLabel">
                    {this.renderDetailDate(selectedFeedback.user.DeteTime)}
                  </label>
                </div>
                <div className="msgItem">
                  <label className="labelName">
                    {Messages.feedback.labels.detail}
                  </label>
                  <label className="answerLabel">
                    {ReactHtmlParser(selectedFeedback.user.Content)}
                  </label>
                </div>
                {selectedFeedback.user.Img && (
                  <div className="msgItem">
                    <a href={selectedFeedback.user.Img} target="_blank">
                      <img
                        className="feedbackImage"
                        src={selectedFeedback.user.Img}
                        alt="img"
                      />
                    </a>
                  </div>
                )}
              </div>
              {selectedFeedback.admin && (
                <div className="msgItemDiv">
                  <div className="msgItem">
                    <label className="labelName">
                      {labels.searcherFeedbackReplyTime}:
                    </label>
                    <label className="answerLabel">
                      {this.renderDetailDate(selectedFeedback.admin.DeteTime)}
                    </label>
                  </div>
                  <div className="msgItem">
                    <label className="labelName">
                      {labels.searcherFeedbackAdminReply}
                    </label>
                    <label className="answerLabel">
                      {ReactHtmlParser(selectedFeedback.admin.Content)}
                    </label>
                  </div>
                  {selectedFeedback.admin.Img && (
                    <div className="msgItem">
                      <a href={selectedFeedback.admin.Img} target="_blank">
                        <img
                          className="feedbackImage "
                          src={selectedFeedback.admin.Img}
                          alt="img"
                        />
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(FeedbackDetail);
