import React, { Component } from "react";
import { Panel, Draggable } from "../../../../common";
import { connect } from "react-redux";
import { notificationAction } from "./../../../../actions";
import ReactHtmlParser from "react-html-parser";
import { ApiUrl } from "../../../../api";
import { Link } from "react-router-dom";

class SuggestedSkill extends Component {
  render() {
    const {
      suggestedSkillsList,
      onSuggesstedSkillAdd,
      suggestObject,
      labels
    } = this.props;
    let { RESUME_EDIT_SUGGESTED_SKILL_MESSAGE } = this.props.labels;
    return (
      <>
        {suggestedSkillsList.length > 0 && (
          <div
            test-data-id="resume-edit-skill-suggestion-availible-message"
            className="sub-heading"
          >
            {`Suggested Skill for ${suggestObject.Value}`}{" "}
          </div>
        )}
        {suggestedSkillsList.length === 0 &&
          Object.keys(suggestObject).length !== 0 &&
          suggestObject.Type === "Skill" && (
            <div className="add-correletion-text">
              <div
                test-data-id="resume-edit-skill-suggestion-not-availible-message"
                className="sub-heading"
              >
                {`Suggested Skill for ${suggestObject.Value}`}
              </div>
              <p>
                {ReactHtmlParser(
                  labels.RESUME_EDIT_SUGGESTED_SKILL_MESSAGE.replace(
                    /ddSKILLNAME/g,
                    suggestObject.Value
                  )
                )}
                <a
                  href={`${window.location.origin}/BI/#skill/${
                    suggestObject.Id
                  }`}
                  target="_blank"
                >
                  {ReactHtmlParser(
                    labels.RESUME_EDIT_SUGGESTED_SKILL_URL_MESSAGE
                  )}
                </a>
              </p>
            </div>
          )}
        {suggestedSkillsList.map(item => (
          <label
            test-data-id="resume-edit-suggested-skill-item"
            className="draggable"
            onClick={() => onSuggesstedSkillAdd(item.SkillId, item.SkillValue)}
            key={item.SkillId}
          >
            {item.SkillValue}
          </label>
        ))}
      </>
    );
  }
}

// export default SuggestedSkill;
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(SuggestedSkill);
