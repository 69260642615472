import {
  LandingPage,
  Dashboard,
  OpportunityAccepted,
  OpportunityNew,
  OpportunityDecline,
  MessageNew,
  MessageInbox,
  MessageSent,
  Present,
  Resume,
  ResumeEdit,
  NewResume,
  Statistics,
  Feedback,
  FeedbackB,
  About,
  AboutB,
  Company,
  SavedSearch,
  NewSavedSearch,
  Shortlist,
  SearcherCollaborationSent,
  SearcherCollaborationAccepted,
  SearcherCollaborationInActive,
  CreateCollaboration,
  SearcherCollaborationDeclined,
  SearcherOpportunityDraft,
  CreateNewOpportunity,
  SearcherOpportunitySent,
  IproNewCollaborations,
  IproActiveCollaborations,
  IproInActiveCollaborations,
  Search,
  NewSearch,
  NewShortlist,
  Settings,
  VisitorSearch,
  NoMatch,
  Snapshot,
  Tictell,
  Greetings,
  Sense,
  Network,
  NextStep,
  LeadPage,
  Cms,
  IproCollaborationDeclined,
  NewCreateOpportunity,
  CompanyNew,
  NewPresent,
  NewSearcherOpportunitySent,
  NewSearcherOpportunityDraft,
  NewCreateCollaboration,
  NewSearcherCollaborationSent,
  NewSearcherCollaborationAccepted,
  NewSearcherCollaborationDeclined,
  NewSearcherCollaborationInActive,
  OpportunityNewIpro,
  NewOpportunityAcceptedIpro,
  NewOpportunityDeclineIpro,
  NewIproNewCollaborations,
  NewIproActiveCollaborations,
  NewIproInActiveCollaborations,
  NewIproDeclinedCollaborations,
} from "../views";
import { SearcherPhillip, IproPhillip } from "../components";
import { roles } from "../utilities";
import Assort from "../views/Assort/Assort";
import Embark from "../views/Embark/Embark";
import Philip from "../views/Philip/SearcherPhilipModule";
import IProPhilip from "../views/Philip/IProPhilipModule";
import Headsup from "../views/Headsup/Headsup";
import ConnectedNetwork from "../views/Network/ConnectedNetwork";
import NewConnectedNetwork from "../views/NewNetwork/ConnectedNetwork";
import NewInvitationsNetwork from "../views/NewNetwork/InvitationsNetwork";
import InvitationsNetwork from "../views/Network/InvitationsNetwork";
import { MobileLogin, ResumeAnalyzer } from "../views/LeadPage/components";
import InvitedNetwork from "../views/Network/InvitedNetwork";
import NewInvitedNetwork from "../views/NewNetwork/InvitedNetwork";
import NewDashboard from "../views/Dashboard/NewDashboard";

export const privateRoutes = {
  dashboard: {
    id: 1,
    name: "dashboard",
    path: "/dashboard",
    component: NewDashboard,
    roles: [roles.ipro, roles.searcher],
  },
  newDashboard: {
    id: 1,
    name: "dashboard",
    path: "/new-dashboard",
    component: Dashboard,
    roles: [roles.ipro, roles.searcher],
  },
  createMessage: {
    id: 2,
    name: "createMessage",
    path: "/create-message",
    component: MessageNew,
    roles: [roles.ipro, roles.searcher],
  },
  inboxMessages: {
    id: 3,
    name: "inboxMessages",
    path: "/inbox-messages",
    component: MessageInbox,
    roles: [roles.ipro, roles.searcher],
  },
  sentMessages: {
    id: 4,
    name: "sentMessages",
    path: "/sent-messages",
    component: MessageSent,
    roles: [roles.ipro, roles.searcher],
  },
  iproNewOpportunity: {
    id: 5,
    name: "iproNewOpportunity",
    path: "/new-opportunity",
    component: OpportunityNewIpro,
    roles: [roles.ipro],
  },
  iproNewOpportunityWithId: {
    id: 51,
    name: "iproNewOpportunityWithId",
    path: "/new-opportunity/:id/:idd",
    component: OpportunityNewIpro,
    roles: [roles.ipro],
  },
  newIproNewOpportunity: {
    id: 51,
    name: "iproNewOpportunity",
    path: "/old-new-opportunity",
    component: OpportunityNew,
    roles: [roles.ipro],
  },
  newIproNewOpportunityWithId: {
    id: 5,
    name: "iproNewOpportunityWithId",
    path: "/old-new-opportunity/:id/:idd",
    component: OpportunityNew,
    roles: [roles.ipro],
  },
  iproAccpetedOpportunity: {
    id: 6,
    name: "iproAccpetedOpportunity",
    path: "/accepted-opportunities",
    component: NewOpportunityAcceptedIpro,
    roles: [roles.ipro],
  },
  NewIproAccpetedOpportunity: {
    id: 6,
    name: "iproAccpetedOpportunity",
    path: "/old-accepted-opportunities",
    component: OpportunityAccepted,
    roles: [roles.ipro],
  },
  iproDeclinedOpportunity: {
    id: 7,
    name: "iproDeclinedOpportunity",
    path: "/declined-opportunities",
    component: NewOpportunityDeclineIpro,
    roles: [roles.ipro],
  },
  NewIproDeclinedOpportunity: {
    id: 7,
    name: "iproDeclinedOpportunity",
    path: "/old-declined-opportunities",
    component: OpportunityDecline,
    roles: [roles.ipro],
  },
  searcherCreateCollaboration: {
    id: 8,
    name: "searcherCreateCollaboration",
    path: "/create-collaboration",
    component: NewCreateCollaboration,
    roles: [roles.searcher],
  },
  newSearcherCreateCollaboration: {
    id: 401,
    name: "searcherCreateCollaboration",
    path: "/old-create-collaboration",
    component: CreateCollaboration,
    roles: [roles.searcher],
  },
  searcherSentCollaborations: {
    id: 9,
    name: "searcherSentCollaborations",
    path: "/sent-collaborations",
    component: NewSearcherCollaborationSent,
    roles: [roles.searcher],
  },
  newSearcherSentCollaborations: {
    id: 91,
    name: "searcherSentCollaborations",
    path: "/old-sent-collaborations",
    component: SearcherCollaborationSent,
    roles: [roles.searcher],
  },
  searcherAcceptedCollaborations: {
    id: 10,
    name: "searcherAcceptedCollaborations",
    path: "/active-collaborations",
    component: NewSearcherCollaborationAccepted,
    roles: [roles.searcher],
  },
  newSearcherAcceptedCollaborations: {
    id: 92,
    name: "searcherAcceptedCollaborations",
    path: "/old-active-collaborations",
    component: SearcherCollaborationAccepted,
    roles: [roles.searcher],
  },
  searcherInActiveCollaborations: {
    id: 10,
    name: "searcherInActiveCollaborations",
    path: "/inactive-collaborations",
    component: NewSearcherCollaborationInActive,
    roles: [roles.searcher],
  },
  newSearcherInActiveCollaborations: {
    id: 93,
    name: "searcherInActiveCollaborations",
    path: "/old-inactive-collaborations",
    component: SearcherCollaborationInActive,
    roles: [roles.searcher],
  },
  searcherDeclinedCollaborations: {
    id: 11,
    name: "searcherDeclinedCollaborations",
    path: "/declined-collaborations",
    component: NewSearcherCollaborationDeclined,
    roles: [roles.searcher],
  },
  newSearcherDeclinedCollaborations: {
    id: 92,
    name: "searcherDeclinedCollaborations",
    path: "/old-declined-collaborations",
    component: SearcherCollaborationDeclined,
    roles: [roles.searcher],
  },
  iproNewCollaborations: {
    id: 12,
    name: "iproNewCollaborations",
    path: "/new-collaborations",
    component: NewIproNewCollaborations,
    roles: [roles.ipro],
  },
  NewIproNewCollaborations: {
    id: 12,
    name: "iproNewCollaborations",
    path: "/old-new-collaborations",
    component: IproNewCollaborations,
    roles: [roles.ipro],
  },
  iproActiveCollaborations: {
    id: 12,
    name: "CollaborationActive",
    path: "/ipro-active-collaborations",
    component: NewIproActiveCollaborations,
    roles: [roles.ipro],
  },
  NewIproActiveCollaborations: {
    id: 12,
    name: "CollaborationActive",
    path: "/old-ipro-active-collaborations",
    component: IproActiveCollaborations,
    roles: [roles.ipro],
  },
  iproInActiveCollaborations: {
    id: 12,
    name: "iproInActiveCollaborations",
    path: "/ipro-inactive-collaborations",
    component: NewIproInActiveCollaborations,
    roles: [roles.ipro],
  },
  NewIproInActiveCollaborations: {
    id: 12,
    name: "iproInActiveCollaborations",
    path: "/old-ipro-inactive-collaborations",
    component: IproInActiveCollaborations,
    roles: [roles.ipro],
  },
  IproCollaborationDeclined: {
    id: 11,
    name: "iProDeclinedCollaborations",
    path: "/ipro-declined-collaborations",
    component: NewIproDeclinedCollaborations,
    roles: [roles.ipro],
  },
  NewIproCollaborationDeclined: {
    id: 11,
    name: "iProDeclinedCollaborations",
    path: "/old-ipro-declined-collaborations",
    component: IproCollaborationDeclined,
    roles: [roles.ipro],
  },
  resume: {
    id: 13,
    name: "resume",
    path: "/resume",
    component: Resume,
    roles: [roles.ipro],
  },
  resumeEdit: {
    id: 14,
    name: "resumeEdit",
    path: "/resume-edit",
    component: NewResume,
    roles: [roles.ipro],
  },
  newResumeEdit: {
    id: 14,
    name: "newResumeEdit",
    path: "/old-resume-edit",
    component: ResumeEdit,
    roles: [roles.ipro],
  },
  presentations: {
    id: 15,
    name: "presentations",
    path: "/presentations",
    component: NewPresent,
    roles: [roles.ipro],
  },
  newPresentations: {
    id: 105,
    name: "new-presentations",
    path: "/old-presentations",
    component: Present,
    roles: [roles.ipro],
  },
  companies: {
    id: 16,
    name: "companies",
    path: "/companies",
    component: CompanyNew,
    roles: [roles.searcher],
  },
  NewCompanies: {
    id: 226,
    name: "companies",
    path: "/old-companies",
    component: Company,
    roles: [roles.searcher],
  },
  searcherCreateOpportunity: {
    id: 17,
    name: "searcherCreateOpportunity",
    path: "/create-opportunity",
    component: NewCreateOpportunity,
    roles: [roles.searcher],
  },
  newSearcherCreateOpportunity: {
    id: 117,
    name: "searcherCreateOpportunity",
    path: "/old-create-opportunity",
    component: CreateNewOpportunity,
    roles: [roles.searcher],
  },
  searcherSentOpportunity: {
    id: 18,
    name: "searcherSentOpportunity",
    path: "/sent-opportunities",
    component: NewSearcherOpportunitySent,
    roles: [roles.searcher],
  },
  newNearcherSentOpportunity: {
    id: 108,
    name: "searcherSentOpportunity",
    path: "/old-sent-opportunities",
    component: SearcherOpportunitySent,
    roles: [roles.searcher],
  },
  searcherDraftOpportunity: {
    id: 19,
    name: "searcherDraftOpportunity",
    path: "/draft-opportunities",
    component: NewSearcherOpportunityDraft,
    roles: [roles.searcher],
  },
  newSearcherDraftOpportunity: {
    id: 19,
    name: "searcherDraftOpportunity",
    path: "/old-draft-opportunities",
    component: SearcherOpportunityDraft,
    roles: [roles.searcher],
  },
  iproPhillip: {
    id: 20,
    name: "iproPhillip",
    path: "/ipro-phillip",
    component: IproPhillip,
    roles: [roles.ipro],
  },
  searcherPhillip: {
    id: 21,
    name: "searcherPhillip",
    path: "/searcher-phillip",
    component: SearcherPhillip,
    roles: [roles.searcher],
  },
  resumeSearch: {
    id: 22,
    name: "resumeSearch",
    path: "/resume-search",
    component: NewSearch,
    roles: [roles.searcher],
  },
  newResumeSearch: {
    id: 222,
    name: "resumeSearch",
    path: "/old-resume-search",
    component: Search,
    roles: [roles.searcher],
  },
  Network: {
    id: 33,
    name: "Network",
    path: "/network",
    component: Network,
    roles: [roles.ipro, roles.searcher],
  },
  connectedNetwork: {
    id: 33,
    name: "Connected",
    path: "/connected-networks",
    component: ConnectedNetwork,
    roles: [roles.ipro, roles.searcher],
  },
  newConnectedNetwork: {
    id: 33,
    name: "Connected",
    path: "/new-connected-networks",
    component: NewConnectedNetwork,
    roles: [roles.ipro, roles.searcher],
  },
  invitedNetwork: {
    id: 33,
    name: "Invited",
    path: "/invited-networks",
    component: InvitedNetwork,
    roles: [roles.ipro, roles.searcher],
  },
  newInvitedNetwork: {
    id: 33,
    name: "Invited",
    path: "/new-invited-networks",
    component: NewInvitedNetwork,
    roles: [roles.ipro, roles.searcher],
  },
  invitationNetwork: {
    id: 33,
    name: "Invitations",
    path: "/invitations-networks",
    component: InvitationsNetwork,
    roles: [roles.ipro, roles.searcher],
  },
  newInvitationNetwork: {
    id: 33,
    name: "Invitations",
    path: "/new-invitations-networks",
    component: NewInvitationsNetwork,
    roles: [roles.ipro, roles.searcher],
  },
  savedSearch: {
    id: 23,
    name: "savedSearch",
    path: "/saved-searches",
    component: NewSavedSearch,
    roles: [roles.searcher],
  },
  newSavedSearch: {
    id: 233,
    name: "savedSearch",
    path: "/old-saved-searches",
    component: SavedSearch,
    roles: [roles.searcher],
  },
  shortlists: {
    id: 24,
    name: "shortlists",
    path: "/shortlists",
    component: NewShortlist,
    roles: [roles.searcher],
  },
  newShortlist: {
    id: 224,
    name: "newShortlists",
    path: "/old-shortlists",
    component: Shortlist,
    roles: [roles.searcher],
  },
  settings: {
    id: 25,
    name: "settings",
    path: "/settings",
    changePasswordPath: "/settings?isPasswordChange=true",
    component: Settings,
    roles: [roles.ipro, roles.searcher],
  },
  statistics: {
    id: 26,
    name: "statistics",
    path: "/statistics",
    component: Statistics,
    roles: [roles.ipro, roles.searcher],
  },
  feedback: {
    id: 27,
    name: "feedback",
    path: "/feedback",
    component: Feedback,
    roles: [roles.ipro, roles.searcher],
  },
  feedbackB: {
    id: 28,
    name: "feedbackB",
    path: "/feedback-b",
    component: FeedbackB,
    roles: [roles.ipro, roles.searcher],
  },
  about: {
    id: 29,
    name: "about",
    path: "/about",
    component: About,
    roles: [roles.ipro, roles.searcher],
  },
  aboutB: {
    id: 30,
    name: "aboutB",
    path: "/about-b",
    component: AboutB,
    roles: [roles.ipro, roles.searcher],
  },
  snapshot: {
    id: 24,
    name: "snapshot",
    path: "/snapshot",
    component: Snapshot,
    roles: [roles.searcher],
  },
  tictell: {
    id: 28,
    name: "tictell",
    path: "/tictell",
    component: Tictell,
    roles: [roles.ipro],
  },
  assort: {
    id: 29,
    name: "asort",
    path: "/assort",
    component: Assort,
    roles: [roles.searcher],
  },
  embark: {
    id: 30,
    name: "embark",
    path: "/embark",
    component: Embark,
    roles: [roles.searcher],
  },
  headsup: {
    id: 30,
    name: "headsup",
    path: "/headsup",
    component: Headsup,
    roles: [roles.searcher],
  },
  philip: {
    id: 30,
    name: "searcherPhilip",
    path: "/searcher-philip",
    component: Philip,
    roles: [roles.searcher],
  },
  iproPhilip: {
    id: 30,
    name: "iproPhilip",
    path: "/ipro-philip",
    component: IProPhilip,
    roles: [roles.ipro],
  },
  sense: {
    id: 3,
    name: "Sense",
    path: "/sense",
    component: Sense,
    roles: [roles.searcher],
  },
  nextstep: {
    id: 3,
    name: "NextStep",
    path: "/next-step",
    component: NextStep,
    roles: [roles.ipro],
  },
};

export const publicRoutes = {
  default: {
    id: 1,
    name: "default",
    path: "/",
    component: LeadPage,
  },
  ipro: {
    id: 1,
    name: "ipro",
    path: "/ipro",
    component: LeadPage,
  },
  login: {
    id: 2,
    name: "login",
    path: "/login",
    component: LeadPage,
  },
  bottomFeature: {
    id: 33,
    name: "cms",
    path: "/cms",
    component: Cms,
  },
  mobileLogin: {
    id: 2,
    name: "mobile-login",
    path: "/mobile-login",
    component: MobileLogin,
  },
  redirectedfrom: {
    id: 3,
    name: "redirectedfrom",
    path: "/redirected-from",
    component: LeadPage,
  },
  redirectedLogin: {
    id: 3,
    name: "redirectedLogin",
    path: "/redirected-login",
    component: LeadPage,
  },
  emailverification: {
    id: 3,
    name: "emailverification",
    path: "/email-verification",
    component: LeadPage,
  },
  visitorsearch: {
    id: 4,
    name: "visitorsearch",
    path: "/visitor-search",
    component: VisitorSearch,
  },
  Greetings: {
    id: 31,
    name: "Greetings",
    path: "/greetings",
    component: Greetings,
  },
  resumeAnalyzer: {
    id: 31,
    name: "resumeAnalyzer",
    path: "/resume-analyzer",
    component: ResumeAnalyzer,
  },
  noMatch: {
    id: 5,
    name: "noMatch",
    path: "",
    component: NoMatch,
  },
};

export const appsRoutes = {
  default: {
    id: 1,
    name: "default",
    path: "/Apps/",
    component: LeadPage,
  },
  tictell: {
    id: 2,
    name: "tictell",
    path: "/Apps/Tictell",
    component: Tictell,
  },
  snapshot: {
    id: 3,
    name: "tictell",
    path: "/Apps/Snapshot",
    component: Snapshot,
  },
  assort: {
    id: 3,
    name: "assort",
    path: "/Apps/assort",
    component: Assort,
  },
  headsup: {
    id: 3,
    name: "headsup",
    path: "/Apps/headsup",
    component: Headsup,
  },
  embark: {
    id: 30,
    name: "embark",
    path: "/Apps/embark",
    component: Embark,
  },
  philip: {
    id: 30,
    name: "searcherPhilip",
    path: "/Apps/searcher-philip",
    component: Philip,
  },
  iproPhilip: {
    id: 30,
    name: "iproPhilip",
    path: "/Apps/ipro-philip",
    component: IProPhilip,
  },
  sense: {
    id: 3,
    name: "sense",
    path: "/Apps/Sense",
    component: Sense,
  },
  nextstep: {
    id: 3,
    name: "next-step",
    path: "/Apps/next-step",
    component: NextStep,
  },
};

export const privateRoutesArray = () => {
  return Object.keys(privateRoutes).map((key) => {
    if (window.location.hostname === "localhost") {
      privateRoutes[key].path = `/react${privateRoutes[key].path}`;
    }
    return privateRoutes[key];
  });
};

export const publicRoutesArray = () => {
  return Object.keys(publicRoutes).map((key) => publicRoutes[key]);
};
