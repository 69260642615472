import { ActionTypes } from "../../actions";
export const onStateChangeAction = ({
  faqsList,
  aboutProdooList,
  listCollapsed,
  detailCollapsed,
  isFetching,
  selectedItem,
  expanded
}) => dispatch => {
  faqsList !== undefined &&
    dispatch({
      type: ActionTypes.ABOUT.FAQS_LIST,
      payload: faqsList
    });
  aboutProdooList !== undefined &&
    dispatch({
      type: ActionTypes.ABOUT.ABOUT_PRODOO_LIST,
      payload: aboutProdooList
    });
  listCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.ABOUT.LIST_COLLAPSED,
      payload: listCollapsed
    });
  detailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.ABOUT.DETAIL_COLLAPSED,
      payload: detailCollapsed
    });
  selectedItem !== undefined &&
    dispatch({
      type: ActionTypes.ABOUT.SELECTED_ITEM,
      payload: selectedItem
    });
  isFetching !== undefined &&
    dispatch({
      type: ActionTypes.ABOUT.IS_FETCHING,
      payload: isFetching
    });
  expanded !== undefined &&
    dispatch({
      type: ActionTypes.ABOUT.EXPANDED,
      payload: expanded
    });
};
