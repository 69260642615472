import axios from "axios";
import { ApiUrl } from "../../api/apiUrls";

export const getOpportunitesApi = ({ isFreelancer }) => {
  return axios
    .get(ApiUrl.Opportunity.SearcherGetRequests({ isFreelancer }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const getShortlistsApi = () => {
  return axios
    .get(ApiUrl.Opportunity.GetShortlists)
    .then(({ data }) => data)
    .catch(response => response);
};

export const getOpportunityApi = ({ RequestId }) => {
  return axios
    .get(ApiUrl.Opportunity.GetOpportunity({ RequestId }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const getDraftOpportunityDetailApi = ({ RequestId }) => {
  return axios
    .get(ApiUrl.Opportunity.GetDraftOpportunityDetail({ RequestId }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const getSentOpportunityDetailApi = ({ RequestId }) => {
  return axios
    .get(ApiUrl.Opportunity.GetSentOpportunityDetail({ RequestId }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const getShortlistResumesApi = ({ ShortlistId }) => {
  return axios
    .get(ApiUrl.Opportunity.GetShortlistResumes({ ShortlistId }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const deleteOpportunityApi = ({ RequestId }) => {
  return axios
    .delete(ApiUrl.Opportunity.DeleteOpportunity({ RequestId }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const deleteSendOpportunityApi = ({ RequestId }) => {
  return axios
    .delete(ApiUrl.Opportunity.DeleteSentOpportunity({ RequestId }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const deleteOpportunityAndAssociationsApi = ({ RequestId }) => {
  return axios
    .get(ApiUrl.Opportunity.DeleteOpportunityAndAssociations({ RequestId }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const getSavedSearchesApi = () => {
  return axios
    .get(ApiUrl.Opportunity.SavedSearchesBase)
    .then(({ data }) => data)
    .catch(response => response);
};

export const getLanguagesApi = ({ searchKey }) => {
  return axios
    .get(ApiUrl.Opportunity.SearchLanguage({ searchKey }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const getLocationsApi = ({ searchKey }) => {
  return axios
    .get(ApiUrl.Opportunity.Locations({ searchKey }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const saveOpportunity = ({ opportunity }) => {
  return axios
    .put(ApiUrl.Opportunity.UpdateOpportunity, opportunity)
    .then(({ data }) => data)
    .catch(response => response);
};

export const sendOpportunityApi = ({ opportunity }) => {
  return axios
    .post(ApiUrl.Opportunity.SendOpportunity, opportunity)
    .then(({ data }) => data);
};

export const getOpportunityResumesApi = ({ requestId }) => {
  return axios
    .get(ApiUrl.Opportunity.GetOpportunityResumes({ requestId }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const getAllLocationsApi = () => {
  return axios
    .get(ApiUrl.Opportunity.AllLocationsLookup)
    .then(({ data }) => data)
    .catch(response => response);
};

export const getAllLanguagesApi = () => {
  return axios
    .get(ApiUrl.Opportunity.AllLanguagesLookup)
    .then(({ data }) => data)
    .catch(response => response);
};

export const getSentOpportunitesApi = ({ isFreelancer }) => {
  return axios
    .get(ApiUrl.Opportunity.GetSentRequest({ isFreelancer }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const getDraftOpportunitesApi = ({ isFreelancer }) => {
  return axios
    .get(ApiUrl.Opportunity.GetDraftRequest({ isFreelancer }))
    .then(({ data }) => data)
    .catch(response => response);
};
