import { ActionTypes } from "../actions";
import moment from "moment";
const INITIAL_STATE = {
  isLoading: true,
  IsAvailable: false,
  AvailablityType: -1,
  busyUntil: moment(),
  locationList: [],
  languageList: [],
  rolesList: [],
  keywordsList: [],
  otherAchivenmentsList: [],
  skillsList: [],
  certificationsList: [],
  educationsList: [],
  industriesList: [],
  experiencesList: [],
  resumes: [], /// need to fix
  suggestObject: {},
  suggestedRoles: [],
  roleDefaultOptions: [],
  suggestedCertifications: [],
  certificationDefaultOptions: [],
  suggestedSkills: [],
  skillDefaultOptions: [],
  educationDefaultOptions: [],
  keywordDefaultOptions: [],
  IndustryDefaultOptions: [],
  currentCandidate: {},
  dialogMessage: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.RESUME_EDIT.IS_LOADING:
      return { ...state, isLoading: action.payload };
    case ActionTypes.RESUME_EDIT.IS_AVAILABLE:
      return { ...state, IsAvailable: action.payload };
    case ActionTypes.RESUME_EDIT.AVAILABLITY_TYPE:
      return { ...state, AvailablityType: action.payload };
    case ActionTypes.RESUME_EDIT.BUSY_UNTIL:
      return { ...state, busyUntil: action.payload };
    case ActionTypes.RESUME_EDIT.LOCATION_LIST:
      return { ...state, locationList: action.payload };
    case ActionTypes.RESUME_EDIT.LANGUAGE_LIST:
      return { ...state, languageList: action.payload };
    case ActionTypes.RESUME_EDIT.ROLES_LIST:
      return { ...state, rolesList: action.payload };
    case ActionTypes.RESUME_EDIT.KEYWORDS_LIST:
      return { ...state, keywordsList: action.payload };
    case ActionTypes.RESUME_EDIT.OTHER_ACHIVENMENTS_LIST:
      return { ...state, otherAchivenmentsList: action.payload };
    case ActionTypes.RESUME_EDIT.SKILLS_LIST:
      return { ...state, skillsList: action.payload };
    case ActionTypes.RESUME_EDIT.CERTIFICATIONS_LIST:
      return { ...state, certificationsList: action.payload };
    case ActionTypes.RESUME_EDIT.EDUCATIONS_LIST:
      return { ...state, educationsList: action.payload };
    case ActionTypes.RESUME_EDIT.INDUSTRIES_LIST:
      return { ...state, industriesList: action.payload };
    case ActionTypes.RESUME_EDIT.EXPERIENCES_LIST:
      return { ...state, experiencesList: action.payload };
    case ActionTypes.RESUME_EDIT.RESUMES:
      return { ...state, resumes: action.payload };
    case ActionTypes.RESUME_EDIT.SUGGEST_OBJECT:
      return { ...state, suggestObject: action.payload };
    case ActionTypes.RESUME_EDIT.SUGGESTED_ROLES:
      return { ...state, suggestedRoles: action.payload };
    case ActionTypes.RESUME_EDIT.ROLE_DEFAULT_OPTIONS:
      return { ...state, roleDefaultOptions: action.payload };
    case ActionTypes.RESUME_EDIT.SUGGESTED_CERTIFICATIONS:
      return { ...state, suggestedCertifications: action.payload };
    case ActionTypes.RESUME_EDIT.CERTIFICATION_DEFAULT_OPTIONS:
      return { ...state, certificationDefaultOptions: action.payload };
    case ActionTypes.RESUME_EDIT.SUGGESTED_SKILLS:
      return { ...state, suggestedSkills: action.payload };
    case ActionTypes.RESUME_EDIT.SKILL_DEFAULT_OPTIONS:
      return { ...state, skillDefaultOptions: action.payload };
    case ActionTypes.RESUME_EDIT.EDUCATION_DEFAULT_OPTIONS:
      return { ...state, educationDefaultOptions: action.payload };
    case ActionTypes.RESUME_EDIT.KEYWORD_DEFAULT_OPTIONS:
      return { ...state, keywordDefaultOptions: action.payload };
    case ActionTypes.RESUME_EDIT.INDUSTRY_DEFAULT_OPTIONS:
      return { ...state, IndustryDefaultOptions: action.payload };
    case ActionTypes.RESUME_EDIT.CURRENT_CANDIDATE:
      return { ...state, currentCandidate: action.payload };
    case ActionTypes.RESUME_EDIT.DIALOG_MESSAGE:
      return { ...state, dialogMessage: action.payload };
    default:
      return state;
  }
};
