import { ActionTypes } from "../../../actions";

export const onStateChangeAction = ({
  listCollapsed,
  detailCollapsed,
  replyCollapsed,
  messageForm,
  dialogMessage,
  selectedMessage,
  filterMessages,
  isReply,
  isFetching,
  deletedId,
  messageList,
  searchKey
}) => dispatch => {
  listCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.LIST_COLLAPSED,
      payload: listCollapsed
    });
  detailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.DETAIL_COLLAPSED,
      payload: detailCollapsed
    });
  replyCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.REPLY_COLLAPSED,
      payload: replyCollapsed
    });
  messageForm !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.MESSAGE_FORM,
      payload: messageForm
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.DIALOG_MESSAGE,
      payload: dialogMessage
    });
  selectedMessage !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.SELECTED_MESSAGE,
      payload: selectedMessage
    });
  filterMessages !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.FILTER_MESSAGES,
      payload: filterMessages
    });
  isReply !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.IS_REPLY,
      payload: isReply
    });
  isFetching !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.IS_FETCHING,
      payload: isFetching
    });
  deletedId !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.DELETED_ID,
      payload: deletedId
    });
  messageList !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.MESSAGE_LIST,
      payload: messageList
    });
  searchKey !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_INBOX.SEARCH_KEY,
      payload: searchKey
    });
};
