import React, { Component } from "react";
import { EmptyInfo, LoadingMask, DatePicker, Button } from "../../../../../common";
import Select from "react-select";
import Slider from "rc-slider";
import moment from "moment";

import "./createnewcollaboration.scss";

const Handle = Slider.Handle;
class CreateNewCollaboration extends Component {
  viewRender = () => {
    const {
      collaborationForm,
      labels,
      onFormFieldChange,
      onFormSelectChange,
      onDateChange,
      onSliderChange,
      opportunityList,
      iprosList,
      onCollaborationSave,
      Currencies
    } = this.props;
    const handle = props => {
      const { value, dragging, index, ...restProps } = props;
      return (
        <Handle value={value} key={index} {...restProps}>
          {value}
        </Handle>
      );
    };
    const {
      Title,
      invalidTitle,
      StartDate,
      invalidStartDate,
      DurationType,
      invalidDurationType,
      HourlyRate,
      invalidHourlyRate,
      HourlyRateType,
      invalidHourlyRateType,
      RequestId,
      invalidRequestId,
      ResumeId,
      invalidResumeId,
      Description,
      Duration
    } = collaborationForm;

    const durationTypeList = [
      { value: "Days", label: "Days" },
      { value: "Weeks", label: "Weeks" },
      { value: "Months", label: "Months" },
      { value: "Years", label: "Years" }
    ];

    const HourlyRateTypeList = [
      { value: "USD", label: "USD" },
      { value: "GBP", label: "GBP" },
      { value: "DKK", label: "DKK" },
      { value: "EUR", label: "EUR" }
    ];

    return (
      <div className="Collaboration-form-editor-searcher">
        <div className="form-row">
          <label className="form-label">{labels.collTitle}</label>
          <input
            autoFocus={invalidTitle}
            type="text"
            name="Title"
            className={`input-text ${invalidTitle ? "inValid" : ""}`}
            value={Title ? Title : ""}
            placeholder={labels.collFormTitle}
            onChange={onFormFieldChange}
            onBlur={onFormFieldChange}
            test-data-id="input-text-collaboration-title"
          />
        </div>
        <div className="form-row">
          <label className="form-label">{labels.collStartDate}</label>
          <label className="datepicker-wrapper">
            <DatePicker
              name="DatePicker"
              dateFormat="DD-MM-YYYY"
              selected={StartDate || ""}
              onChange={onDateChange}
              todayButton="Today"
              placeholder={labels.collStartDate}
              className={`${invalidStartDate ? "inValid" : ""}`}
              minDate={moment()}
              placeholderText={"DD-MM-YYYY"}
              autoComplete="off"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="form-label">{labels.collDuration}</label>
          <Slider
            name="durationSlider"
            min={0}
            max={20}
            defaultValue={4}
            value={Duration}
            handle={handle}
            onChange={onSliderChange}
          />
          <Select
            name="DurationType"
            className={`select-input select-durationType ${
              invalidDurationType ? "inValid" : ""
            }`}
            value={DurationType || ""}
            onChange={selectedOption =>
              onFormSelectChange("DurationType", selectedOption)
            }
            options={durationTypeList}
            clearable={false}
          />
        </div>
        <div className="form-row">
          <label className="form-label">{labels.collHourlyFee}</label>
          <input
            autoFocus={invalidHourlyRate}
            type="text"
            name="HourlyRate"
            className={`input-text ${invalidHourlyRate ? "inValid" : ""}`}
            value={HourlyRate ? HourlyRate : ""}
            placeholder={labels.SearcherOpportunityDraftDetailHourlyFee}
            onChange={onFormFieldChange}
            onBlur={onFormFieldChange}
            test-data-id="input-txt-collaboration-hourlyRate"
          />
          <Select
            name="HourlyRateType"
            className={`select-input select-hourlyRate ${
              invalidHourlyRateType ? "inValid" : ""
            }`}
            value={HourlyRateType || ""}
            onChange={selectedOption =>
              onFormSelectChange("HourlyRateType", selectedOption)
            }
            clearable={false}
            options={Currencies}
          />
        </div>
        <div className="form-row">
          <label className="form-label">{labels.collOpportunity}</label>
          <Select
            name="OpprtunityId"
            className={`select-input select-opportunity ${
              invalidRequestId ? "inValid" : ""
            }`}
            value={RequestId || ""}
            onChange={selectedOption =>
              onFormSelectChange("OpprtunityId", selectedOption)
            }
            autoBlur={true}
            options={opportunityList}
            clearable={false}
          />
        </div>
        <div className="form-row">
          <label className="form-label">{labels.colliPro}</label>
          <Select
            name="ResumeId"
            className={`select-input select-ipro ${
              invalidResumeId ? "inValid" : ""
            }`}
            value={ResumeId || ""}
            onChange={selectedOption =>
              onFormSelectChange("ResumeId", selectedOption)
            }
            options={iprosList}
            clearable={false}
          />
        </div>
        <textarea
          name="Description"
          className="textarea-field"
          rows="9"
          placeholder={labels.collDescription}
          value={Description || ""}
          onChange={onFormFieldChange}
          test-data-id="input-text-collaboration-description"
        />
        <Button
            className="SendButton"
            text={labels.collSendButton}
            onClick={onCollaborationSave}
            tooltipButton={labels.collSendButton}
            tooltipHelp={labels.collSendButton}
            tooltipPlace="left"
            testId="input-button-collaboration-sendBtn"
          />
        {/* <button
          className="SendButton"
          onClick={onCollaborationSave}
          test-data-id="input-button-collaboration-sendBtn"
        >
          {labels.collSendButton}
        </button> */}
      </div>
    );
  };

  render() {
    const { isLoading } = this.props;
    return (
      <>
        {isLoading && <LoadingMask />}
        {this.viewRender()}
      </>
    );
  }
}

export default CreateNewCollaboration;
