import React, { Component } from "react";
import Dropzone from "react-dropzone";
import "./dragpdf.scss";
import { notificationAction } from "./../../actions";
import { connect } from "react-redux";
import { onStateChangeAction } from "../../views/Resume/resumeAction";
class DragPDF extends Component {
  componentDidMount() {
    !this.props.PDFFileName &&
      this.props.onStateChangeAction({
        PDFFileName: this.props.labels.iproResumeFileFormate
      });
  }
  handleDropAccepted = files => {
    let types = ["pdf"];
    const file = files[0];
    const filename = file.name.split(".")[0];
    let fileType = file.name.split(".");
    if (types.includes(fileType[fileType.length - 1].toLowerCase())) {
      let reader = new FileReader();
      let me = this;
      reader.onload = (function(theFile) {
        return function(e) {
          let resumeFile = e.target.result.split(",")[1];
          if (resumeFile) {
            me.props.getPDF(resumeFile, false);
            me.props.onStateChangeAction({
              isPDFFileUploaded: true,
              PDFFileName: filename
            });
          }
        };
      })(file);
      reader.readAsDataURL(file);
    } else {
      const info = {
        message: this.props.labels.resumeImportPDFFileInfo,
        status: "error"
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isPDFFileUploaded: false,
        PDFFileName: this.props.labels.iproResumeFileFormate
      });
      this.props.getPDF(null, true);
    }
  };
  handleCloseClick = () => {
    this.props.onStateChangeAction({
      isPDFFileUploaded: false,
      PDFFileName: this.props.labels.iproResumeFileFormate
    });
    this.props.getPDF(null, true);
  };
  render() {
    const { imgSrc, labels, isPDFFileUploaded, PDFFileName } = this.props;
    return (
      <div className="drag-pdf-component">
        {isPDFFileUploaded && (
          <div>
            <button
              className="closeBtn"
              onClick={this.handleCloseClick}
              test-data-id="resumeDropZoneCloseButton"
            />
          </div>
        )}
        <Dropzone
          className="feedDrag demo-droppable"
          acceptClassName="drag-accepted"
          rejectClassName="drag-rejected"
          onDropAccepted={this.handleDropAccepted}
        >
          <p className="pdfFormatText" test-data-id="resumeDropZonepdffromate">
            {PDFFileName}
          </p>
        </Dropzone>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel, resume }) => {
  const { labels } = systemLabel;
  return { labels, ...resume };
};
export default connect(
  mapStateToProps,
  { notificationAction, onStateChangeAction }
)(DragPDF);
