import axios from "axios";
import { ApiUrl, StorageService } from "../../api";

export const presentApi = () => {
  return axios.get(ApiUrl.UserDetail.Get).then(({ data }) => data);
};

export const getNetworkPresentApi = (userId) => {
  return axios.get(ApiUrl.UserDetail.GetNetworkUserPresentation(userId)).then(({ data }) => data);
};

export const presentDeleteApi = ({ id }) => {
  return axios
    .delete(ApiUrl.UserDetail.Delete, { data: { UserDetailId: id } })
    .then(({ data }) => {
      return data;
    });
};
export const getResumesApi = () => {
  return axios.get(ApiUrl.Resumes.GetMyResumeProfiles).then(({ data }) => data);
};
export const getUserEmailsApi = () => {
  return axios.get(ApiUrl.UserEmails.Get).then(({ data }) => data);
};
export const getUserPhonenumbersApi = () => {
  return axios.get(ApiUrl.UserPhonenumbers.Get).then(({ data }) => data);
};
export const getSocialMediaApi = () => {
  return axios
    .get(`${ApiUrl.SocialMedia}?page=1&start=0&limit=25`)
    .then(({ data }) => data);
};

export const presentSubmitApi = info => {
  return axios
    .post(ApiUrl.UserDetail.PresentSubmit, info)
    .then(({ data }) => data);
};

export const updatePresentApi = info => {
  return axios
    .put(ApiUrl.UserDetail.updatePresent, info)
    .then(({ data }) => data);
};
