import React from "react";
import { Input, Modal, Space } from "antd";
import { Messages } from "../../../../utilities";
const { TextArea } = Input;

const CreateMessageModal = ({
  open,
  handleContentChange,
  messageForm,
  onSubmitMessage,
  handleReplyModalClose,
}) => {
  const { content, isInvalidTitle } = messageForm;

  return (
    <>
      <Modal
        title="Type Message"
        className="shortlist-modal "
        open={open}
        onCancel={() => {
          handleReplyModalClose();
        }}
        footer={
          <Space size={[12, 8]}>
            <button
              className="create-button background"
              onClick={() => {
                onSubmitMessage();
              }}
            >
              {Messages.message.button.send}
            </button>
          </Space>
        }
      >
        <div className="title-main-resumes">
          <TextArea
            name="content"
            value={content ? content : ""}
            className={`textarea-field ${isInvalidTitle ? "inValid" : ""}`}
            onChange={handleContentChange}
            placeholder={Messages.message.placeholder.content}
            rows="10"
            test-data-id="inbox-message-replytextArea"
            style={{ resize: "none" }}
          />
        </div>
      </Modal>
    </>
  );
};
export default CreateMessageModal;
