import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Alert,
  Input,
  Typography,
  Tooltip,
  Upload,
} from "antd";
import classes from "./index.module.scss";
import "./style.scss";
import ImgSrc from "../../../../assets/images/present.svg";
import {
  FacebookFilled,
  GooglePlusCircleFilled,
  TwitterCircleFilled,
  InstagramFilled,
  EditFilled,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import ImageCropDemo from "./ImageCrop";

const { Title } = Typography;

function EditProfilePopUp({
  userFormDetail = {},
  socialLinks,
  handleSocialMediaActive,
  isLoading,
  handleUserForm,
  onSocialMediaChange,
  SaveUserDetails,
  errorMessage,
  labels,
  onAvatarChange,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="edit_profile_modal">
      <Tooltip title="Edit Profile">
        <Button
          size="small"
          shape="circle"
          className={` border-0 bg-light ${classes.editProfileButton}`}
          icon={<EditFilled />}
          onClick={() => setIsModalVisible(true)}
        />
      </Tooltip>
      <Modal
        open={isModalVisible}
        // onOk={handleOk}
        centered
        onCancel={handleCancel}
        className="edit-profile-modal"
        footer={null}
      >
        {userFormDetail && (
          <Form
            name="normal_login"
            className={`${classes.form}`}
            onFinish={onFinish}
            initialValues={{
              ["UserFirstname"]: userFormDetail.UserFirstname,
              ["UserLastname"]: userFormDetail.UserLastname,
              ["Email"]: userFormDetail.UserEmail,
              ["phone"]: userFormDetail.UserPhonenumberValue,
            }}
          >
            <div className="text-center mb-3">
              <Title level={4}>{labels.CONTACT_INFO_LABEL}</Title>
            </div>
            <Form.Item>
              <ImageCropDemo
                userFormDetail={userFormDetail?.ProfilePicture}
                onAvatarChange={onAvatarChange}
              />
              {/* <div className="profileBigIcon">
                <div
                  className={`dashItemImg ${
                    userFormDetail.ProfilePicture ? "" : "no-dp"
                  }`}
                >
                  <img
                    className="roundeImg"
                    src={
                      userFormDetail.ProfilePicture
                        ? userFormDetail.ProfilePicture
                        : ImgSrc
                    }
                    alt="avatar"
                  />
                </div> */}

              {/* <div className="uploadBtn" testid="profile-avatar-btn">
                  <input
                    testid="profile-avatar-input"
                    type="file"
                    name="profileAvatar"
                    className="input-upload-file"
                    onChange={onAvatarChange}
                    accept="image/*"
                  />
                </div> */}
              {/* </div> */}
            </Form.Item>
            <Form.Item
              label={labels.EMAIL_LABEL}
              name="Email"
              className={classes.labelEditProfile}
            >
              <Input
                placeholder={labels.EMAIL_LABEL}
                size="large"
                className={classes.editInput}
                name={"UserEmail"}
                autoComplete="new-password"
                value={userFormDetail.UserEmail || ""}
                disabled
              />
            </Form.Item>
            <Form.Item label={labels.FIRSTNAME_LABEL} name="UserFirstname">
              <Input
                placeholder={labels.FIRSTNAME_LABEL}
                size="large"
                name={"UserFirstname"}
                autoComplete="new-password"
                value={userFormDetail.UserFirstname || ""}
                onChange={handleUserForm}
              />
            </Form.Item>

            <Form.Item label={labels.LASTNAME_LABEL} name="UserLastname">
              <Input
                placeholder={labels.LASTNAME_LABEL}
                size="large"
                name={"UserLastname"}
                autoComplete="new-password"
                value={userFormDetail.UserLastname || ""}
                onChange={handleUserForm}
              />
            </Form.Item>

            {userFormDetail.isPhoneAvailable && (
              <Form.Item
                label={labels.PHONE_LABEL}
                name="phone"
                rules={[
                  {
                    // required: true,
                    message: labels.INPUT_PHONE_VALIDATION_LABEL,
                  },
                ]}
              >
                <Input
                  placeholder={labels.PHONE_LABEL}
                  size="large"
                  autoComplete="new-password"
                  value={userFormDetail.UserPhonenumberValue || ""}
                  name={"UserPhonenumberValue"}
                  onChange={handleUserForm}
                />
              </Form.Item>
            )}

            <div className="d-flex flex-column align-items-center">
              <Title level={5}>{labels.OTHER_SOCIAL_LINKS_LBL}</Title>
              <div
                className={classes.socialLinks}
                style={{
                  marginBottom: "24px",
                }}
              >
                {socialLinks &&
                  socialLinks.map((item, index) => (
                    <>
                      {item.Name == "twitter" && (
                        <TwitterCircleFilled
                          onClick={() => handleSocialMediaActive(item)}
                          key={index}
                          className={`${classes.socialLink} ${item.isActive &&
                            classes.activeSocialLink}`}
                        />
                      )}
                      {item.Name == "facebook" && (
                        <FacebookFilled
                          onClick={() => handleSocialMediaActive(item)}
                          key={index}
                          className={`${classes.socialLink}  ${item.isActive &&
                            classes.activeSocialLink}`}
                        />
                      )}
                      {item.Name == "googleplus" && (
                        <GooglePlusCircleFilled
                          onClick={() => handleSocialMediaActive(item)}
                          key={index}
                          className={`${classes.socialLink}  ${item.isActive &&
                            classes.activeSocialLink}`}
                        />
                      )}
                      {item.Name == "instagram" && (
                        <InstagramFilled
                          onClick={() => handleSocialMediaActive(item)}
                          key={index}
                          className={`${classes.socialLink}  ${item.isActive &&
                            classes.activeSocialLink}`}
                        />
                      )}
                    </>
                  ))}
              </div>
              {socialLinks &&
                socialLinks.map(
                  (item, index) =>
                    item.isActive && (
                      <Form.Item
                        label={`${item.Name} link`}
                        key={index}
                        name={item.name}
                        rules={[
                          {
                            // required: true,
                            message: labels.INPUT_LINK_VALIDATION_LABEL,
                          },
                        ]}
                      >
                        <Input
                          placeholder={item.Name}
                          size="large"
                          name={item.name}
                          autoComplete="new-password"
                          value={item.URL ? item.URL : ""}
                          defaultValue={item.URL ? item.URL : ""}
                          onChange={(e) =>
                            onSocialMediaChange(item, e.target.value)
                          }
                        />
                      </Form.Item>
                    )
                )}
            </div>

            {error && (
              <Alert
                message={errorMessage?.replace("<br />", "")}
                type="warning"
                closable
              />
            )}

            <Button
              type="primary"
              block
              htmlType="submit"
              className="login-form-button mb-3"
              loading={isLoading}
              onClick={SaveUserDetails}
            >
              Done
            </Button>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default EditProfilePopUp;
