import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { notificationAction } from "../../actions";
import { map, find, compact, isEmpty } from "lodash";

import { Modal } from "../../components";
import "./phillip.scss";
import {
  getSearcherPhillipSocialMediaStatusApi,
  uploadLinkedinPDF,
  updateResumeAvailabilityApi,
  getLoginFeaturesApi,
  saveParsedResume,
} from "./phillipApi";
import Logo from "../../assets/images/logo.png";
import { htmlParser } from "../../utilities/helpers";
import { LoadingMask, DatePicker } from "../../common";
import { withRouter } from "react-router-dom";
import { privateRoutes } from "../../Routes/routing";
import { Button, Col, Input, Row, Typography, Upload } from "antd";
const { Title, Text } = Typography;
const { TextArea } = Input;

const Header = ({ labels, onCloseModal }) => (
  <div className="phillip-header">
    {/* <img className="onboard-logo" src={Logo} alt="prodoo-logo" /> */}
    <button onClick={onCloseModal} className="skip-btn">
      <Text className="description-text">
        {labels.iProOnboardingSkipButton}
      </Text>
    </button>
  </div>
);
const Slide = ({ className, children, testId }) => (
  <div className={`js_slide ${className}`} test-data-id={testId}>
    {children}
  </div>
);
const CustomInput = ({ onClick, value }) => (
  <button className="custom-date-picker" onClick={onClick}>
    {value}
  </button>
);
const ResumeValues = [
  {
    id: 0,
    name: "Resume",
    notFound: "iProOnboardingResumeCreated",
    found: "iProOnboardingResumeCreated",
    active: true,
  },
  {
    id: 1,
    name: "Keywords",
    notFound: "iProOnboardingKeywordNotFound",
    found: "iProOnboardingKeywordFound",
  },
  {
    id: 2,
    name: "Certifications",
    notFound: "iProOnboardingCertificationNotFound",
    found: "iProOnboardingCertificationFound",
  },
  {
    id: 3,
    name: "Skills",
    notFound: "iProOnboardingSkillNotFound",
    found: "iProOnboardingSkillFound",
  },
  {
    id: 4,
    name: "Profiles",
    notFound: "iProOnboardingRoleNotFound",
    found: "iProOnboardingRoleFound",
  },
  {
    id: 5,
    name: "Educations",
    notFound: "iProOnboardingEducationNotFound",
    found: "iProOnboardingEducationFound",
  },
  {
    id: 6,
    name: "Experiences",
    notFound: "iProOnboardingExperienceNotFound",
    found: "iProOnboardingExperienceFound",
  },
  {
    id: 7,
    name: "Countries",
    notFound: "iProOnboardingCountryNotFound",
    found: "iProOnboardingCountryFound",
  },
  {
    id: 8,
    name: "Languages",
    notFound: "iProOnboardingLanguageNotFound",
    found: "iProOnboardingLanguageFound",
  },
  // {
  //   id: 9,
  //   name: "Present",
  //   notFound: "iProOnboardingPresentNotFound",
  //   found: "iProOnboardingPresentFound"
  // }
];
class IproPhillip extends Component {
  state = {
    currentSlide: 0,
    date: "",
    companyUrl: "",
    pageUrl: "",
    linkedinUrl: "",
    isFacebookConnected: false,
    isLinkedInConnected: false,
    isCompanyConnected: false,
    isOpportunityCreated: false,
    isShortlistCreated: false,
    isSavedSearchCreated: false,
    keywords: "",
    resumeData: [],
    resumeValues: ResumeValues,
    foundResumeValues: {},
    dateValue: "select a date",
    isDatePickerOpen: false,
    isLoading: false,
    LoginWithFb: false,
    LoginWithLinkedin: false,
    availabilities: [
      {
        id: 1,
        name: "now",
        isActive: false,
        value: this.props.labels.iProOnboardingAvailabilityGig,
      },
      {
        id: 2,
        name: "notice",
        isActive: false,
        value: this.props.labels.iProOnboardingAvailabilityOnNotice,
      },
      {
        id: 3,
        name: "date",
        isActive: false,
        value: this.props.labels.iProOnboardingAvailabilityUntil,
      },
    ],
    selectedAvailability: "notice",
    showDatePicker: false,
  };
  componentDidMount() {
    this.getLoginFeatures();
    getSearcherPhillipSocialMediaStatusApi()
      .then((response) => {
        if (response.success) {
          const { FacebookEnabled, LinkedInEnabled } = response.items;
          this.setState({
            isFacebookConnected: FacebookEnabled,
            isLinkedInConnected: LinkedInEnabled,
          });
        }
      })
      .catch(() => {});
    window.addEventListener("keydown", this.handleTabPress);
  }

  handleTabPress = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      this.handleContinueClick();
    }
  };

  handleFinishClick = () => {
    const { onCloseModal, notificationAction } = this.props;
    const { availabilities, date = new moment(), resumeData } = this.state;

    if (resumeData) {
      saveParsedResume(resumeData)
        .then(() => {
          const selectedAvailability = find(availabilities, "isActive");
          if (selectedAvailability) {
            let data = {
              IsAvailable: false,
              AvailabilityDate: moment(date).format("MM/DD/YYYY"),
            };
            if (selectedAvailability.name === "notice") {
              data = {
                IsAvailable: false,
                AvailabilityDate: new moment().add(1, "M").format("MM/DD/YYYY"),
                AvailablityType: 2,
              };
            } else if (selectedAvailability.name === "date") {
              data = {
                IsAvailable: false,
                AvailabilityDate: moment(date).format("MM/DD/YYYY"),
                AvailablityType: 3,
              };
            } else if (selectedAvailability.name === "now") {
              data = {
                IsAvailable: true,
                AvailabilityDate: new moment().format("MM/DD/YYYY"),
                AvailablityType: 1,
              };
            }
            updateResumeAvailabilityApi(data).then(({ data }) => {
              const info = {
                message: data.message,
                status: "success",
              };
              notificationAction(info);
            });
          }
          const info = {
            message: "Resume saved successfully",
            status: "success",
          };
          notificationAction(info);
          onCloseModal();
          this.props.onResumeUpdate();
          if (this.state.otherResumePdfName || this.state.linkedinPdfName) {
            this.props.history.push(privateRoutes.resumeEdit.path);
          }
        })
        .catch((err) => console.log("Err", err));
    } else {
      onCloseModal();
    }
  };
  handleContinueClick = () => {
    if (this.state.currentSlide == 1 && isEmpty(this.state.resumeData)) {
      this.setState((st) => ({ ...st, emptyResume: true }));

      return null;
    }
    const { currentSlide } = this.state;
    this.setState({ currentSlide: currentSlide + 1 });
  };

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;
    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };

  handlAvalibalitySelect = (availability) => {
    const { id, name } = availability;
    const availabilities = map(this.state.availabilities, (item) => {
      item.isActive = item.id === id;
      return item;
    });
    this.setState({
      availabilities,
      selectedAvailability: name,
      isDatePickerOpen: name === "date",
    });
  };

  getLoginFeatures = () => {
    getLoginFeaturesApi().then((response) => {
      this.setState({
        LoginWithFb: response.items.LoginWithFb,
        LoginWithLinkedin: response.items.LoginWithLinkedin,
      });
    });
  };
  renderResumeValues = (item) => {
    const { foundResumeValues } = this.state;
    if (foundResumeValues[item.name] > 0) {
      const value = this.props.labels[item.found];
      return value.replace("@count", foundResumeValues[item.name]);
    }
    return this.props.labels[item.notFound];
  };
  uploadLinkedinPdf = (e, successName) => {
    var fileData = e;
    var types = "pdf";
    const { labels, notificationAction } = this.props;
    let { resumeValues } = this.state;
    var fileType = fileData.name.split(".");
    const me = this;
    if (types === fileType[1].toLowerCase()) {
      var reader = new FileReader();
      reader.onload = (function() {
        return function(e) {
          var fileDataInBytes = e.target.result.split(",")[1];
          me.setState({ isLoading: true });
          uploadLinkedinPDF(fileDataInBytes)
            .then((res) => {
              const info = {
                message: htmlParser(labels[successName]),
                status: "success",
              };
              notificationAction(info);

              const foundResumeValues = {
                Profiles: res.items.Profiles && res.items.Profiles.length,
                Skills: res.items.Skills && res.items.Skills.length,
                Certifications:
                  res.items.Certifications && res.items.Certifications.length,
                Keywords: res.items.Keywords && res.items.Keywords.length,
                Countries: res.items.Countries && res.items.Countries.length,
                Languages: res.items.Languages && res.items.Languages.length,
                Industries: res.items.Industries && res.items.Industries.length,
              };
              resumeValues = resumeValues.map((item) => {
                return {
                  ...item,
                  active:
                    (foundResumeValues[item.name] &&
                      foundResumeValues[item.name] > 0) ||
                    item.name === "Resume",
                };
              });
              me.setState({
                foundResumeValues,
                resumeData: res.items,
                emptyResume: false,
                resumeValues,
                isLoading: false,
                otherResumePdfName:
                  successName === "OtherResumeSuccessMessage"
                    ? fileData.name
                    : me.state.otherResumePdfName,
                linkedinPdfName:
                  successName === "LinkedInResumeSuccessMessage"
                    ? fileData.name
                    : me.state.linkedinPdfName,
              });
              me.handleContinueClick();
            })
            .catch(() => {
              const info = {
                message: "Unable to upload to file.",
                status: "error",
              };
              notificationAction(info);
              me.setState({ isLoading: false });
            });
        };
      })(fileData);

      reader.readAsDataURL(fileData);
    } else {
      const info = {
        message: "Please select pdf file.",
        status: "error",
      };
      this.props.notificationAction(info);
      console.log('else')
    }
  };

  generateCards = () => {
    const { labels, FirstName } = this.props;
    const {
      date,
      resumeValues,
      dateValue,
      isDatePickerOpen,
      availabilities,
      otherResumePdfName,
      currentSlide,
    } = this.state;
    const uploadProps = {
      beforeUpload: (file) => {
        this.uploadLinkedinPdf(file, "OtherResumeSuccessMessage");
        return false;
      },
      fileList: [],
      maxCount: 1,
    };
    const isMobileView = window.innerWidth < 767;
    const slideHtml = [
      <Slide className="js_slide_1" testId="ipro-phillip-popup-slide-1">
        {(currentSlide == 0 || !isMobileView) && (
          <>
            <Title level={3}>
              {htmlParser(labels.SEARCHER_PHILLIP_WELCOME_LABEL)}&nbsp;
              {FirstName}!
            </Title>
            <Text className="description-text bold-primary only-first">
              {htmlParser(labels.iProOnboardingWelcomeMessage)}
            </Text>
          </>
        )}
      </Slide>,
      <Slide className="js_slide_3 slide-2">
        {(currentSlide == 1 || !isMobileView) && (
          <>
            <Title level={3}>
              {htmlParser(labels.iProOnboardingResumeUploadTitle)}
            </Title>
            <Text className="description-text">
              {htmlParser(labels.iProOnboardingResumeUploadMessage)}
            </Text>
            <br />

            {/* <Text className="description-text pb-4">
          {labels.iProOnboardingResumeOtherBtnText}
        </Text> */}
            <Upload {...uploadProps}>
              <Button
                className="upload-btn"
                size="middle"
                type="primary"
                style={
                  this?.state?.emptyResume
                    ? {
                        border: "1px solid red",
                        background: "red",
                      }
                    : null
                }
              >
                Upload PDF CV/Resume
              </Button>
            </Upload>

            {otherResumePdfName && (
              <Fragment>
                <br />
                <Text className="description-text">{otherResumePdfName}</Text>
              </Fragment>
            )}
          </>
        )}
      </Slide>,
      <Slide className="js_slide_4 slide-3">
        {(currentSlide === 2 || !isMobileView) && (
          <>
            <Row gutter={4} className="posRelative">
              {resumeValues.map((item) => (
                <Col
                  key={item.id}
                  md={12}
                  xs={24}
                  className={`js_item ${item.active ? "active" : ""}`}
                >
                  <Text className="description-text">
                    {this.renderResumeValues(item)}
                  </Text>
                </Col>
              ))}
            </Row>
            <div className="js_query mt-2">
              <Text className="description-text" style={{fontWeight:600}}>
                {labels.iProOnboardingAvailabilityQuestion}
              </Text>
              <br />
              <div className="js_selection">
                {map(availabilities, (item) => (
                  <div
                    key={item.id}
                    className={`js_sel_item ${item.isActive && "active"}`}
                    onClick={() => this.handlAvalibalitySelect(item)}
                    test-data-id={`ipro-phillip-avalibility-${item.name}`}
                  >
                    <Text className="description-text">
                      {htmlParser(item.value)}
                    </Text>
                  </div>
                ))}

                <DatePicker
                  name="DatePicker"
                  minDate={moment()}
                  todayButton="Today"
                  dateFormat="MM/DD/YYYY"
                  selected={date}
                  value={dateValue ? dateValue : date.format("L").toString()}
                  onChange={(date, e) => {
                    e.stopPropagation();
                    this.setState({
                      date,
                      dateValue: "",
                      isDatePickerOpen: false,
                    });
                  }}
                  onBlur={() => {
                    this.setState({
                      isDatePickerOpen: false,
                    });
                  }}
                  onInputClick={() => {
                    this.setState({
                      isDatePickerOpen: true,
                    });
                  }}
                  customInput={<CustomInput />}
                  open={isDatePickerOpen}
                  stop
                />
              </div>
            </div>
          </>
        )}
      </Slide>,
    ];
    return compact(slideHtml);
  };

  getSlideImage = () => {
    switch (this.state.currentSlide) {
      case 0:
        return (
          <img
            className="phillip-img"
            src={require(`../../assets/images/onboarding/01.svg`)}
          />
        );
      case 1:
        return (
          <img
            className="phillip-img"
            src={require(`../../assets/images/onboarding/02.svg`)}
          />
        );
      case 2:
        return (
          <img
            className="phillip-img"
            src={require(`../../assets/images/onboarding/03.svg`)}
          />
        );
      case 3:
        return (
          <img
            className="phillip-img"
            src={require(`../../assets/images/onboarding/04.svg`)}
          />
        );
      default:
        return (
          <img
            className="phillip-img"
            src={require(`../../assets/images/onboarding/01.svg`)}
          />
        );
    }
  };

  render() {
    const { labels, open, onSkip } = this.props;
    const { currentSlide, isLoading } = this.state;

    return (
      <div
        className="phillip-component ipro-phillip"
      >
        <Modal
          className={`phillip-popup onboard-${currentSlide + 1}`}
          open={open}
        >
          {isLoading && <LoadingMask text="" />}
          <Header onCloseModal={onSkip} labels={labels} />
          {this.getSlideImage()}
          <Modal.Body>
            <Carousel
              swipeable
              showStatus={false}
              showArrows={false}
              // infiniteLoop
              showThumbs={false}
              selectedItem={currentSlide}
              onChange={this.updateCurrentSlide}
            >
              {this.generateCards()}
            </Carousel>
            <div className="phillip-footer">
              {currentSlide !== 2 && (
                <button
                  htmlType="button"
                  type="primary"
                  test-data-id="ipro-phillip-continue-button"
                  className="continue-button"
                  onClick={this.handleContinueClick}
                >
                  {labels.searcherOnboardingContinue}
                </button>
              )}
              {currentSlide === 2 && (
                <button
                  htmlType="button"
                  type="primary"
                  test-data-id="ipro-phillip-finish-button"
                  className="finish-button"
                  onClick={this.handleFinishClick}
                >
                  {labels.searcherOnboardingFinished}
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  let { FirstName } = user;
  FirstName =
    FirstName !== undefined ? FirstName : sessionStorage.getItem("userName");
  return { labels, FirstName };
};
export default connect(mapStateToProps, { notificationAction })(
  withRouter(IproPhillip)
);
