import { ActionTypes } from "../actions";
import { UserNotificationList } from "../views/Settings/components/UserNotifications";

const INITIAL_STATE = {
  userPhoneList: [],
  userEmailList: [],
  selectedProfile: null,
  countries: null,
  dialogMessage: "",
  dialogMessagePass: "",
  isInvalidUserFirstname: false,
  isInvalidUserLastname: false,
  isInvalidPhone: false,
  isInvalidEmail: false,
  isUserNameSelected: true,
  isPhoneNumberSelected: false,
  addItemMessage: "",
  isEmailSelected: false,
  isSocialMediaSelected: false,
  socialMedia: [],
  UserSocialMediaLinks: [],
  isDeleteEmail: false,
  isDeletePhoneNumber: false,
  emailIdToDelete: 0,
  phoneIdToDelete: 0,
  isChangePassSelected: false,
  isEmailNotificationSelected: false,
  rightcoloumHeading: "",
  isLoading: false,
  isFetching: true,
  passwordEmailsList: [],
  settingsList: [],
  listCollapsed: false,
  formCollapsed: false,
  invalidOldPassword: "",
  expanded: {
    basic: true,
  },
  selectedItem: {
    Id: 1,
  },
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  UserId: 0,
  UserNotificationId: 0,
  notificationList: UserNotificationList,
  isFetchNotification: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SETTING.USER_PHONE_LIST:
      return {
        ...state,
        userPhoneList: action.payload,
      };
    case ActionTypes.SETTING.USER_EMAIL_LIST:
      return {
        ...state,
        userEmailList: action.payload,
      };
    case ActionTypes.SETTING.SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: action.payload,
      };
    case ActionTypes.SETTING.SOCIAL_MEDIA:
      return {
        ...state,
        socialMedia: action.payload,
      };
    case ActionTypes.SETTING.USER_SOCIAL_MEDIA:
      return {
        ...state,
        UserSocialMediaLinks: action.payload,
      };
    case ActionTypes.SETTING.COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case ActionTypes.SETTING.DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: action.payload,
      };
    case ActionTypes.SETTING.DIALOG_MESSAGE_PASS:
      return {
        ...state,
        dialogMessagePass: action.payload,
      };
    case ActionTypes.SETTING.IS_INVALIDUSER_FIRST_NAME:
      return {
        ...state,
        isInvalidUserFirstname: action.payload,
      };
    case ActionTypes.SETTING.IS_INVALIDUSER_LAST_NAME:
      return {
        ...state,
        isInvalidUserLastname: action.payload,
      };
    case ActionTypes.SETTING.IS_INVALID_PHONE:
      return {
        ...state,
        isInvalidPhone: action.payload,
      };
    case ActionTypes.SETTING.IS_INVALID_EMAIL:
      return {
        ...state,
        isInvalidEmail: action.payload,
      };
    case ActionTypes.SETTING.IS_USER_NAME_SELECTED:
      return {
        ...state,
        isUserNameSelected: action.payload,
      };
    case ActionTypes.SETTING.IS_PHONE_NUMBER_SELECTED:
      return {
        ...state,
        isPhoneNumberSelected: action.payload,
      };
    case ActionTypes.SETTING.ADD_ITEM_MESSAGE:
      return {
        ...state,
        addItemMessage: action.payload,
      };
    case ActionTypes.SETTING.IS_EMAIL_SELECTED:
      return {
        ...state,
        isEmailSelected: action.payload,
      };
    case ActionTypes.SETTING.IS_SOCIALMEDIA_SELECTED:
      return {
        ...state,
        isSocialMediaSelected: action.payload,
      };
    case ActionTypes.SETTING.IS_DELETE_EMAIL:
      return {
        ...state,
        isDeleteEmail: action.payload,
      };
    case ActionTypes.SETTING.IS_DELETE_PHONE_NUMBER:
      return {
        ...state,
        isDeletePhoneNumber: action.payload,
      };
    case ActionTypes.SETTING.EMAILID_TO_DELETE:
      return {
        ...state,
        emailIdToDelete: action.payload,
      };
    case ActionTypes.SETTING.PHONE_ID_TO_DELETE:
      return {
        ...state,
        phoneIdToDelete: action.payload,
      };
    case ActionTypes.SETTING.IS_CHANGE_PASS_SELECTED:
      return {
        ...state,
        isChangePassSelected: action.payload,
      };
    case ActionTypes.SETTING.IS_EMAIL_NOTIFICATION_SELECTED:
      return {
        ...state,
        isEmailNotificationSelected: action.payload,
      };
    case ActionTypes.SETTING.RIGHT_COLOUM_HEADING:
      return {
        ...state,
        rightcoloumHeading: action.payload,
      };
    case ActionTypes.SETTING.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionTypes.SETTING.IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case ActionTypes.SETTING.PASSWORD_EMAILS_LIST:
      return {
        ...state,
        passwordEmailsList: action.payload,
      };
    case ActionTypes.SETTING.SETTINGS_LIST:
      return {
        ...state,
        settingsList: action.payload,
      };
    case ActionTypes.SETTING.EXPANDED:
      return {
        ...state,
        expanded: action.payload,
      };
    case ActionTypes.SETTING.LIST_COLLAPSED:
      return {
        ...state,
        listCollapsed: action.payload,
      };
    case ActionTypes.SETTING.FORM_COLLAPSED:
      return {
        ...state,
        formCollapsed: action.payload,
      };
    case ActionTypes.SETTING.INVALID_OLD_PASSWORD:
      return {
        ...state,
        invalidOldPassword: action.payload,
      };
    case ActionTypes.SETTING.SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };
    case ActionTypes.SETTING.OLD_PASSWORD:
      return {
        ...state,
        oldPassword: action.payload,
      };
    case ActionTypes.SETTING.NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.payload,
      };
    case ActionTypes.SETTING.CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: action.payload,
      };
    case ActionTypes.SETTING.USER_ID:
      return {
        ...state,
        UserId: action.payload,
      };
    case ActionTypes.SETTING.USER_NOTIFICATION_ID:
      return {
        ...state,
        UserNotificationId: action.payload,
      };
    case ActionTypes.SETTING.NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };
    case ActionTypes.SETTING.IS_FETCH_NOTIFICATION:
      return {
        ...state,
        isFetchNotification: action.payload,
      };

    default:
      return state;
  }
};
