import { ActionTypes } from "../actions";
const INITIAL_STATE = {
  searchCriteria: {},
  resumeData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LANDINGUPLOADRESUME:
      return { ...state, resumeData: action.payload };
    case ActionTypes.LANDINGFINDCANDIDATE:
      {
        //console.log({uuu:action.payload})
        return { ...state, searchCriteria: action.payload };
      }
    default:
      return state;
  }
};
