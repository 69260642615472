import axios from "axios";
import { ApiUrl } from "../../api";

export const getCompaniesApi = () => {
  return axios.get(ApiUrl.UserCompany.Get).then(({ data }) => data);
};

export const getNetworkUserCompanyApi = (userId) => {
  return axios.get(ApiUrl.UserCompany.GetNetworkUserCompany(userId)).then(({ data }) => data);
};

export const addCompanyApi = ({ company }) => {
  return axios.post(ApiUrl.UserCompany.Add, company).then(({ data }) => data);
};

export const updateCompanyApi = ({ company }) => {
  return axios.put(ApiUrl.UserCompany.Update, company).then(({ data }) => data);
};
export const deleteCompanyApi = ({ id }) => {
  return axios
    .delete(ApiUrl.UserCompany.Delete, { data: { UserCompanyId: id } })
    .then(({ data }) => {
      return data;
    });
};
export const DeleteUserCompanyandCompanyUsers = ({ id }) => {
  return axios
    .delete(ApiUrl.UserCompany.DeleteCompanyAndCompanyUsers, { data: { UserCompanyId: id } })
    .then(({ data }) => {
      return data;
    });
};

export const getCountriesApi = () => {
  return axios.get(ApiUrl.Country.AllLocationsLookup).then(({ data }) => data);
};
export const getUserEmailsApi = () => {
  return axios.get(ApiUrl.UserEmails.Get).then(({ data }) => data);
};
export const getUserPhoneNumbersApi = () => {
  return axios.get(ApiUrl.UserPhonenumbers.Get).then(({ data }) => data);
};
export const getIndustriesApi = () => {
  return axios
    .get(ApiUrl.Industries.AllIndustriesLookup)
    .then(({ data }) => data);
};
