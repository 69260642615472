import React from "react";
import { Col, Input, Row, Select, Space, Modal } from "antd";
import ImgSrc from "../../../../assets/images/company.svg";

const { TextArea } = Input;

const MobileFilter = (props) => {
  const {
    handleModalClick,
    resumeList,
    setIsModalOpen,
    isModalOpen,
    handleClose,
    companyForm,
    labels,
    onFormFieldChange,
    onFormSelectChange,
    industries,
    countries,
    userPhoneNumbers,
    onCompanySave,
    onAvatarChange,
    getImgSrc,
    onFormFieldBlur,
  } = props;

  const {
    CompanyName,
    invalidCompanyName,
    CompanyAddress,
    IndustryId,
    UserPhonenumberId,
    CountryId,
    WebUrl,
    Detail,
    Vat,
    IsDefaultCompany,
  } = companyForm;

  const avatar = companyForm && companyForm.Logo;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    handleClose();
  };

  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        style={{ top: 20 }}
        onOk={handleOk}
        zIndex={100}
        onCancel={handleCancel}
        footer={false}
        className="new-search-resume-main for-mobile-modal-shortlist"
      >
        <div style={{ marginTop: "5px" }} className="new-search-resume-main">
          <div className="h-100 flex flex-column w-100">
            <div
              style={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ overflow: "auto" }} className="pr-2">
                <div className="companyBigIcon">
                  {avatar && (
                    <button className="closeBtn" onClick={getImgSrc} />
                  )}
                  <div className={`dashItemImg ${avatar ? "" : "no-dp"}`}>
                    <img
                      className="roundeImg"
                      src={avatar ? avatar : ImgSrc}
                      alt="company"
                    />
                  </div>
                  <div className="uploadBtn" test-data-id="company-avatar-btn">
                    <input
                      test-data-id="company-avatar-input"
                      type="file"
                      name="companyAvatar"
                      className="input-upload-file"
                      onChange={onAvatarChange}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="container-collapse">
                  <div className="new-collapse-main">
                    <p className="collapse-title"> {labels.NAME_LABEL}</p>
                  </div>
                  <Input
                    autoFocus={invalidCompanyName}
                    type="text"
                    name="CompanyName"
                    placeholder={labels.companyNamePlaceholder}
                    onChange={onFormFieldChange}
                    onBlur={onFormFieldChange}
                    test-data-id="input-text-company-name"
                    maxLength="50"
                    size="large"
                    autoComplete="off"
                    className={`bg-color-for-opportunity  ${
                      invalidCompanyName ? "inValid" : ""
                    }`}
                    value={CompanyName ? CompanyName : ""}
                    bordered={false}
                  />
                </div>
                <Row gutter={[12, 8]} className="container-collapse">
                  <Col xs={24} md={12}>
                    <div className="">
                      <div className="new-collapse-main">
                        <p className="collapse-title">
                          {" "}
                          {labels.ADDRESS_LABEL}
                        </p>
                      </div>
                      <Input
                        type="text"
                        name="CompanyAddress"
                        className="bg-color-for-opportunity"
                        value={CompanyAddress ? CompanyAddress : ""}
                        placeholder={labels.companyAddressPlaceholder}
                        onChange={onFormFieldChange}
                        test-data-id="input-text-company-Address"
                        maxLength="100"
                        size="large"
                        autoComplete="off"
                        bordered={false}
                      />
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="">
                      <div className="new-collapse-main">
                        <p className="collapse-title"> {labels.PHONE_LABEL}</p>
                      </div>
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        showArrow
                        className="bg-color-for-opportunity"
                        name="UserPhonenumberId"
                        value={UserPhonenumberId && UserPhonenumberId}
                        placeholder={labels.companyPhoneNumberPlaceholder}
                        promptTextCreator={(value) =>
                          `Create New Number ${value}`
                        }
                        onChange={(e, selectedOption) =>
                          onFormSelectChange(
                            "UserPhonenumberId",
                            selectedOption
                          )
                        }
                        autoBlur={true}
                        options={userPhoneNumbers}
                        bordered={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                      ></Select>
                    </div>
                  </Col>
                </Row>
                <Row gutter={[12, 8]} className="container-collapse">
                  <Col xs={24} md={12}>
                    <div className=" ">
                      <div className="new-collapse-main">
                        <p className="collapse-title">
                          {" "}
                          {labels.INDUSTRY_LABEL}
                        </p>
                      </div>
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        showArrow
                        className="bg-color-for-opportunity"
                        bordered={false}
                        value={IndustryId && IndustryId}
                        placeholder={labels.companyIndustryPlaceholder}
                        onChange={(e, selectedOption) =>
                          onFormSelectChange("IndustryId", selectedOption)
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                        options={industries}
                      ></Select>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className=" ">
                      <div className="new-collapse-main">
                        <p className="collapse-title">
                          {" "}
                          {labels.COUNTRY_LABEL}
                        </p>
                      </div>
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        showArrow
                        className="bg-color-for-opportunity"
                        bordered={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                        name="CountryId"
                        value={CountryId && CountryId}
                        placeholder={labels.companyCountryPlaceholder}
                        onChange={(e, selectedOption) =>
                          onFormSelectChange("CountryId", selectedOption)
                        }
                        options={countries}
                      ></Select>
                    </div>
                  </Col>
                </Row>

                <Row gutter={[12, 8]} className="container-collapse">
                  <Col xs={24} md={12}>
                    <div className="">
                      <div className="new-collapse-main">
                        <p className="collapse-title">
                          {" "}
                          {labels.WEB_URL_LABEL}
                        </p>
                      </div>
                      <Input
                        type="text"
                        name="WebUrl"
                        value={WebUrl ? WebUrl : ""}
                        placeholder={labels.companyURLPlaceholder}
                        onChange={onFormFieldChange}
                        onBlur={onFormFieldBlur}
                        test-data-id="input-text-company-weburl"
                        maxLength="100"
                        className="bg-color-for-opportunity"
                        size="large"
                        autoComplete="off"
                        bordered={false}
                      />
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className=" ">
                      <div className="new-collapse-main">
                        <p className="collapse-title"> {labels.VAT_LABEL}</p>
                      </div>
                      <Input
                        type="text"
                        name="Vat"
                        value={Vat ? Vat : ""}
                        placeholder={labels.companyVATPlaceholder}
                        onChange={onFormFieldChange}
                        test-data-id="input-text-company-Vat"
                        maxLength="50"
                        className="bg-color-for-opportunity"
                        size="large"
                        autoComplete="off"
                        bordered={false}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="container-collapse">
                  <div className="new-collapse-main">
                    <label className="companyCheckboxLabel">
                      <Input
                        type="checkbox"
                        name="IsDefaultCompany"
                        className={
                          IsDefaultCompany ? "checked" : "input-checkbox"
                        }
                        value={IsDefaultCompany}
                        checked={IsDefaultCompany ? IsDefaultCompany : false}
                        onChange={onFormFieldChange}
                        test-data-id="input-checkbox-company-defaultCompany"
                      />
                      {labels.companyCheckboxMakeDefault}
                    </label>
                  </div>
                </div>

                <div className="container-collapse">
                  <div className="new-collapse-main">
                    <TextArea
                      name="Detail"
                      value={Detail ? Detail : ""}
                      placeholder={labels.companyDescriptionPlaceholder}
                      onChange={onFormFieldChange}
                      test-data-id="input-button-company-detail"
                      rows={6}
                      className="bg-color-for-opportunity"
                      bordered={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Row className="">
              <Space
                size={[8, 8]}
                style={{
                  margin: "auto",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <button
                  className="button-footer-opportunity pl-4 pr-4"
                  onClick={onCompanySave}
                >
                  Save
                </button>
              </Space>
            </Row>
          </div>{" "}
        </div>
      </Modal>
    </>
  );
};
export default MobileFilter;
