import React, { Component } from "react";
import Routes from "./Routes/Routes";
import { AuthService } from "./api";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { initialConfig } from "./store/initialConfig";
import { ErrorBoundary } from "./components";

class App extends Component {
  constructor() {
    super();
    this.initializeApp();
  }
  initializeApp() {
    AuthService.setDefaults();
    initialConfig();
  }
  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
