import axios from "axios";
import { ApiUrl } from "../../../api/apiUrls";

export const getAllCollaborationApi = ({ url }) => {
  return axios
    .get(ApiUrl.Collaboration.GetAllCollaboration({ url }))
    .then(({ data }) => data);
};

export const getCurrenciesApi = () => {
  return axios.get(ApiUrl.Currency.AllCurrenciesLookup).then(({ data }) => {
    return data;
  });
};

export const updateCollaborationApi = ({ apiParams }) => {
  return axios
    .put(ApiUrl.Collaboration.UpdateCollaboration({ apiParams }))
    .then(({ data }) => data);
};
export const deleteCollaborationApi = ({ apiParams }) => {
  return axios
    .put(ApiUrl.Collaboration.DeleteCollaborationApi({ apiParams }))
    .then(({ data }) => data);
};
export const getAllOpportunitiesApi = userId => {
  return axios
    .get(
      `${ApiUrl.Collaboration.GetAllOpportunities
      }?userId=${userId}&_dc=1539179765266&que`
    )
    .then(({ data }) => {
      return data;
    });
};

export const getOpportunityIprosResumeApi = requestId => {
  return axios
    .get(
      `${ApiUrl.Collaboration.GetOpportunityIProsResume
      }?_dc=1539248937219&requestId=${requestId}`
    )
    .then(({ data }) => {
      return data;
    });
};

export const addCollaborationApi = ({ collaboration }) => {
  return axios
    .post(ApiUrl.Collaboration.Add, collaboration)
    .then(({ data }) => data);
};

export const readCollaborationApi = ({ collaborationId }) => {
  return axios
    .put(ApiUrl.Collaboration.ReadCollaboration({ collaborationId }))
    .then(({ data }) => data);
};

export const getCollaborationReviewApi = ({ collaborationId, IsIProReview }) => {
  return axios
    .get(ApiUrl.Collaboration.CollaborationReviews({ collaborationId, IsIProReview }))
    .then(({ data }) => data);
};

export const submitCollaborationReview = ({ collaborationReview }) => {
  return axios
    .post(ApiUrl.Collaboration.SubmitCollaborationReview, { collaborationReviews: collaborationReview })
    .then(({ data }) => data);
};

export const getReviewQuestions = ({ questionType }) => {
  return axios
    .get(ApiUrl.Reviews.ReviewQuestions({ questionType }))
    .then(({ data }) => data);
};

export const getCollaborationReasonsApi = () => {
  return axios
    .get(ApiUrl.Collaboration.GetAllCollaborationCloseReasons)
    .then(({ data }) => data);
};