import { ActionTypes } from ".";

export const helpActiveAction = ({ isHelpActive }) => dispatch => {
  dispatch({
    type: ActionTypes.HELP_ACTIVE,
    payload: isHelpActive
  });
};

export const unreadMessagesAction = ({ unreadMessages }) => dispatch => {
  dispatch({
    type: ActionTypes.UNREAD_MESSAGES,
    payload: unreadMessages
  });
};

export const unreadCollaborationsAction = ({
  acceptedCollaboration,
  declinedCollaboration,
  unreadCollaborations, 
  searcherInActiveCollaboration,
  unreadproInactiveCollaborations
}) => dispatch => {
  dispatch({
    type: ActionTypes.UNREAD_COLLABORATION,
    payload: {
      acceptedCollaboration,
      declinedCollaboration,
      unreadCollaborations,
      unreadproInactiveCollaborations,
      searcherInActiveCollaboration
    }
  });
};

export const unreadOpportunitiesAction = ({
  unreadOpportunities
}) => dispatch => {
  dispatch({
    type: ActionTypes.UNREAD_OPPORTUNITIES,
    payload: unreadOpportunities
  });
};
