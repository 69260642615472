import React, { Component } from "react";
import {
  Panel,
  ListGroup,
  Input,
  Select,
  Draggable,
  Button,
} from "../../../../common";
import AsyncSelect from "react-select/lib/Async";
import { map, isEmpty } from "lodash";
import { getRolesApi } from "../resumeEditApi";
import SuggestedRole from "./SuggestedRole";
import { RoleProficiencyList } from "../../../../utilities";

class Role extends Component {
  state = {
    isFirstime: true,
  };

  handleRolesLoad = (searchKey, callback) => {
    if (isEmpty(searchKey)) {
      const { roleDefaultOptions } = this.props;
      callback(null, {
        options: map(roleDefaultOptions, (profile) => {
          return {
            ...profile,
            label: profile.ProfileValue,
            value: profile.ProfileId,
          };
        }),
      });
      return;
    }
    if (searchKey.length < 3) {
      callback([]);
      return;
    }
    getRolesApi({ searchKey }).then((response) => {
      if (response.success) {
        callback(null, {
          options: map(response.items, (profile) => {
            return {
              ...profile,
              label: profile.ProfileValue,
              value: profile.ProfileId,
            };
          }),
        });
      }
    });
  };
  render() {
    const {
      isHelpActive,
      labels,
      rolesList,
      onRoleNameChange,
      onRoleLevelChange,
      onRoleDelete,
      onRoleAdd,
      onRoleBlur,
      onRoleSuggestionButtonClick,
      suggestedRoles,
      onSuggesstedRoleAdd,
      suggestObject,
      roleDefaultOptions,
    } = this.props;
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>{labels.RESUME_EDIT_ROLE_SECTION_TITLE}</Panel.Title>
          <Panel.Add
            onClick={onRoleAdd}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={labels.RESUME_EDIT_ROLE_ADD_BUTTON_TOOLTIP}
            tooltipHelp={labels.RESUME_EDIT_ROLE_ADD_BUTTON_HELP}
            testId="resume-edit-role-add-btn"
            testIdHelp="resume-edit-role-add-help-btn"
          />
        </Panel.Heading>
        <Panel.Body>
          {suggestedRoles && !isEmpty(suggestObject) && (
            <SuggestedRole
              onSuggesstedRoleAdd={onSuggesstedRoleAdd}
              suggestedRolesList={suggestedRoles}
              suggestObject={suggestObject}
              labels={labels}
            />
          )}
          <ListGroup>
            {rolesList.map((item) => (
              <ListGroup.Item key={item.uniqueId}>
                <ListGroup.Column size={0}>
                  <Button
                    onClick={() =>
                      onRoleSuggestionButtonClick(
                        item.ProfileId,
                        "Profile",
                        item.ProfileValue,
                        item.uniqueId
                      )
                    }
                    className={`draggable-btn ${
                      item.isCandidate ? "candidate" : ""
                    } ${item.isActive ? "active" : ""}`}
                    isHelpActive={isHelpActive}
                    tooltipPlace="left"
                    tooltipButton={
                      item.isCandidate
                        ? `${item.ProfileValue} ${labels.RESUME_EDIT_CANDIDATE_SUGGESTION_BUTTON_TOOLTIP}`
                        : `${labels.RESUME_EDIT_SUGGESTION_BUTTON_TOOLTIP} ${item.ProfileValue}`
                    }
                    tooltipHelp={
                      item.isCandidate
                        ? `${item.ProfileValue} ${labels.RESUME_EDIT_CANDIDATE_SUGGESTION_BUTTON_HELP}`
                        : `${labels.RESUME_EDIT_SUGGESTION_BUTTON_HELP} ${item.ProfileValue}`
                    }
                    testId="resume-edit-role-suggestion-btn"
                    testIdHelp="resume-edit-role-suggestion-help-btn"
                    disabled={item.isCandidate || item.ProfileValue === ""}
                  />
                </ListGroup.Column>
                <ListGroup.Column>
                  <AsyncSelect
                    className={`select-role-name select-input ${
                      item.isInvalid ? "inValid" : ""
                    }`}
                    placeholder={labels.RESUME_EDIT_ROLE_NAME_PLACEHOLDER}
                    cacheOptions
                    defaultOptions
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    loadOptions={this.handleRolesLoad}
                    onChange={(options) =>
                      onRoleNameChange(options, item.uniqueId)
                    }
                    value={{
                      value: item.ProfileId,
                      label: item.ProfileValue,
                    }}
                    onBlur={(options) =>
                      onRoleBlur(
                        item.ProfileId,
                        item.uniqueId,
                        options,
                        item.ExperienceLevel
                      )
                    }
                    disabled={item.isCandidate}
                    clearable
                  />
                </ListGroup.Column>
                <ListGroup.Column>
                  <Select
                    name={"Language"}
                    className={`select-role-level select-input`}
                    value={item.ExperienceLevel}
                    onChange={(options) =>
                      onRoleLevelChange(options, item.uniqueId)
                    }
                    options={RoleProficiencyList}
                  />
                </ListGroup.Column>
                <ListGroup.Column size={0}>
                  <div
                    // disabled={isDisabled}
                    onClick={() => onRoleDelete(item)}
                    className="close_btn_purple"
                  >
                    <i className="fa fa-times-circle" />
                  </div>
                </ListGroup.Column>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Panel.Body>
      </Panel>
    );
  }
}

export default Role;
