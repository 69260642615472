import { ActionTypes } from "../../actions";

export const onStateChangeAction = ({
  shortlist,
  searchCollapsed,
  resumeCollapsed,
  resumeDetailCollapsed,
  selectedShortlist,
  selectedResume,
  shortlistResumes,
  dialogMessage,
  filteredShortlist,
  newShortlistValue,
  createNewShortlist,
  deleteResumeApi,
  isLoading,
  createCollapse,
  fetchingShortlist,
  fetchingResumes,
  dialogOpportunityMessage,
  opportunityList,
  showOpportunities,
  fetchingOpportunities,
  opportunitiesCollapse,
  searchKey,
  deletedId
}) => dispatch => {
  shortlist !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.SHORTLIST,
      payload: shortlist
    });
  searchCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.SEARCH_COLLAPSED,
      payload: searchCollapsed
    });
  resumeCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.RESUME_COLLAPSED,
      payload: resumeCollapsed
    });
  resumeDetailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.RESUME_DETAIL_COLLAPSED,
      payload: resumeDetailCollapsed
    });
  selectedShortlist !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.SELECTED_SHORTLIST,
      payload: selectedShortlist
    });
  selectedResume !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.SELECTED_RESUME,
      payload: selectedResume
    });
  shortlistResumes !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.SHORTLIST_RESUMES,
      payload: shortlistResumes
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.DIALOG_MESSAGE,
      payload: dialogMessage
    });
  filteredShortlist !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.FILTERED_SHORTLIST,
      payload: filteredShortlist
    });
  newShortlistValue !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.NEW_SHORTLIST_VALUE,
      payload: newShortlistValue
    });
  createNewShortlist !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.CREATE_NEW_SHORTLIST,
      payload: createNewShortlist
    });
  isLoading !== undefined &&
    dispatch({ type: ActionTypes.SHORTLIST.IS_LOADING, payload: isLoading });
  createCollapse !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.CREATE_COLLAPSE,
      payload: createCollapse
    });
  fetchingShortlist !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.FETCHING_SHORTLIST,
      payload: fetchingShortlist
    });
  fetchingResumes !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.FETCHING_RESUMES,
      payload: fetchingResumes
    });
  deleteResumeApi !== undefined && 
    dispatch({
      type:ActionTypes.SHORTLIST.RESUME_DELETE_ID,
      payload : deleteResumeApi
    })
  dialogOpportunityMessage !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.DIALOG_OPPORTUNITY_MESSAGE,
      payload: dialogOpportunityMessage
    });
  opportunityList !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.OPPORTUNITY_LIST,
      payload: opportunityList
    });
  showOpportunities !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.SHOW_OPPORTUNITIES,
      payload: showOpportunities
    });
  fetchingOpportunities !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.FETCHING_OPPORTUNITIES,
      payload: fetchingOpportunities
    });
  opportunitiesCollapse !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.OPPORTUNITIES_COLLAPSE,
      payload: opportunitiesCollapse
    });
  searchKey !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.SEARCH_KEY,
      payload: searchKey
    });
  deletedId !== undefined &&
    dispatch({
      type: ActionTypes.SHORTLIST.DELETED_ID,
      payload: deletedId
    });
};
