import axios from "axios";
import { ApiUrl } from "../../api/apiUrls";

export const tictellLogin = email => {
  return axios.get(ApiUrl.Tictell.Login(email)).then(({ data }) => {
    return data;
  });
};
export const tictellAppLoginApi = (email) => {
  return axios.get(ApiUrl.Tictell.AppLogin(email))
  .then(({ data }) => data)
  .catch(response => response);
};

export const getAllCollaborationApi = () => {
  return axios
    .get(ApiUrl.Tictell.GetAllCollaboration)
    .then(({ data }) => data);
};

export const GetWeekAllTimeLogs = (collaborationId, startDate,endDate, token) => {
  return axios
    .get(ApiUrl.Tictell.GetWeekAllTimeLogs(collaborationId, startDate,endDate,token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const getCurrenciesApi = () => {
  return axios.get(ApiUrl.Currency.AllCurrenciesLookup).then(({ data }) => {
    return data;
  });
};

export const deleteAnExpenseApi = (id, token) => {
  return axios
    .get(ApiUrl.Tictell.DeleteAnExpense(id, token))
    .then(({ data }) => {
      return data;
    });
};

export const getExpensesCategoriesApi = token => {
  return axios
    .get(ApiUrl.Tictell.GetExpenseCategories(token))
    .then(({ data }) => {
      return data;
    });
};

export const getWeekExpensesLogsApi = (collaborationId,  startDate,endDate, token) => {
  return axios
    .get(ApiUrl.Tictell.GetWeekExpensesLogs(collaborationId,  startDate,endDate, token))
    .then(({ data }) => {
      return data;
    });
};

export const addTimeLogApi = (currentTimelog, token) => {
  return axios
    .post(ApiUrl.Tictell.AddTimelog(token), currentTimelog)
    .then(({ data }) => data)
    .catch(response => response);
};
export const getAcceptedcollaborationapi = () => {
  return axios
    .get(ApiUrl.Tictell.GetAcceptedCollaborations)
    .then(({ data }) => {
      return data;
    });
};
export const addExpenseLogApi = (currentExpense, token) => {
  return axios
    .post(ApiUrl.Tictell.AddExpense(token), currentExpense)
    .then(({ data }) => data)
    .catch(response => response);
};

export const updateExpenseLogApi = (currentExpense, token) => {
  return axios
    .put(ApiUrl.Tictell.UpdateExpense(token), currentExpense)
    .then(({ data }) => data)
    .catch(response => response);
};
export const DeleteATimeLogs=(timeLogId,token)=>{
  return axios
  .get(ApiUrl.Tictell.DeleteATimeLogs(timeLogId,token))
  .then(({ data }) => {
    return data;
  });
}
export const deleteExpenseFileApi = (id, token) => {
  return axios
    .get(ApiUrl.Tictell.DeleteExpenseFile(id, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const addTimesheetApi = (collaborationId, startDate,endDate, token) => {
  return axios
    .get(ApiUrl.Tictell.AddTimeSheet(collaborationId, startDate,endDate, token))
    .then(({ data }) => data)
    .catch(response => response);
};

export const addExpensesheetApi = (collaborationId,  startDate,endDate, token) => {
  return axios
    .get(ApiUrl.Tictell.AddExpenseSheet(collaborationId,  startDate,endDate, token))
    .then(({ data }) => data)
    .catch(response => response);
};
export const GetCollaborationsEndsInMonthCount = () => {
  return axios
    .get(ApiUrl.Tictell.GetCollabsEndsInMonthCount)
    .then(({ data }) => {
      return data;
    });
};
export const getTictellMenusApi = ({featureId}) => {
  return axios
  .get(ApiUrl.SideNavigation.GetChildFeatures({featureId}))
    .then(({ data }) => data);
};