import React, { Component } from "react";
import "./bulletlist.scss";
const ListItem = ({
  children,
  onClick,
  className,
  isSelected,
  isActive,
  testId,
}) => (
  <li
    test-data-id={testId}
    onClick={onClick}
    className={`list-item animated fadeIn ${className} ${
      isSelected ? "selected-item" : ""
    } ${isActive ? "activeItem" : ""}`}
  >
    {children}
  </li>
);
const Close = ({ onClick, testId }) => (
  <button onClick={onClick} className="closeBtn" test-data-id={testId} />
);
class BulletList extends Component {
  static ListItem = ListItem;
  static Close = Close;
  render() {
    const { children, className, testId, isFetching } = this.props;
    return (
      <div className={`bullet-list-wrapper ${className}`}>
        <ul
          className={`bullet-list-ul ${isFetching ? "loading-list-mask" : ""}`}
          test-data-id={testId}
        >
          {children}
        </ul>
      </div>
    );
  }
}
BulletList.defaultProps = {
  className: "",
};
BulletList.ListItem.defaultProps = {
  className: "",
};

export default BulletList;
