import { ActionTypes } from "../../../../actions";
window.actionName = "new";
export const onStateChangeAction = ({
  selectedOpportunity,
  opportunityList,
  filteredOpportunity,
  selectedCompany,
  fetchingOpportunity,
  isLoading,
  showPresentationDetail,
  showCompanyDetails,
  socialMedia,
  deletedId,
  requestName,
  dialogMessage,
  restoreDialogMessage,
  companyPresentationHeadingText,
  listCollapsed,
  detailCollapsed,
  companyPresentDetailCollapsed,
  searchKey,
  actionName = window.actionName
}) => dispatch => {
  selectedOpportunity !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.SELECTED_OPPORTUNITY,
      payload: selectedOpportunity,
      actionName
    });
  opportunityList !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.OPPORTUNITY_LIST,
      payload: opportunityList,
      actionName
    });
  filteredOpportunity !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.FILTERED_OPPORTUNITY,
      payload: filteredOpportunity,
      actionName
    });
  selectedCompany !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.SELECTED_COMPANY,
      payload: selectedCompany,
      actionName
    });
  fetchingOpportunity !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.FETCHING_OPPORTUNITY,
      payload: fetchingOpportunity,
      actionName
    });
  isLoading !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.IS_LOADING,
      payload: isLoading,
      actionName
    });
  showPresentationDetail !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.SHOW_PRESENTATION_DETAIL,
      payload: showPresentationDetail,
      actionName
    });
  showCompanyDetails !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.SHOW_COMPANY_DETAILS,
      payload: showCompanyDetails,
      actionName
    });
  socialMedia !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.SOCIAL_MEDIA,
      payload: socialMedia,
      actionName
    });
  deletedId !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.DELETED_ID,
      payload: deletedId,
      actionName
    });
  requestName !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.REQUEST_NAME,
      payload: requestName,
      actionName
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.DIALOG_MESSAGE,
      payload: dialogMessage,
      actionName
    });
  restoreDialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.RESTORE_DIALOG_MESSAGE,
      payload: restoreDialogMessage,
      actionName
    });
  companyPresentationHeadingText !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.COMPANY_PRESENTATION_HEADING_TEXT,
      payload: companyPresentationHeadingText,
      actionName
    });
  listCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.LIST_COLLAPSED,
      payload: listCollapsed,
      actionName
    });
  detailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.DETAIL_COLLAPSED,
      payload: detailCollapsed,
      actionName
    });
  companyPresentDetailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.COMPANY_PRESENT_DETAIL_COLLAPSED,
      payload: companyPresentDetailCollapsed,
      actionName
    });
  searchKey !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITY_IPRO.SEARCH_KEY,
      payload: searchKey,
      actionName
    });
};
