import React, { useEffect } from "react";
import { LoadingMask } from "../../common";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { notificationAction } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import {
  CalendarOutlined,
  DownOutlined,
  FilterOutlined,
  PlusOutlined,
  UpOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  getShortlistApi,
  postShortlistApi,
  getOppuntinitiesApi,
  searchResumesApi,
  postSearchApi,
  getSavedSearchesApi,
  updateSearchApi,
  getCompanyApi,
} from "./searchApi";
import "./search.scss";
import './newDesignSearch.scss'
import { privateRoutes } from "../../Routes/routing";
import { withRouter } from "react-router-dom";
import { onStateChangeAction } from "./searchAction";
import ArrowLeft from "../../assets/images/Icons/arrow-left.png";
import {
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Grid,
  Menu,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import DetailModal from "./component/detailModal";
import { useState } from "react";
import {
  getAllLanguagesApi,
  getAllLocationsApi,
  getCertificationsApi,
  getIndustriesApi,
  getKeywordApi,
  getRolesApi,
  getSkillsApi,
} from "../Resume/ResumeEdit/resumeEditApi";
import MobileFilter from "./component/mobileFilter.js";
import ShortListModal from "./createShortListModal";
import SaveSearchModal from "./createSaveSearchModal";
import ResumeListComponent from "../../common/resumeResumeList/resumeList";
import ArrowUp from '../../assets/images/Icons/arrow-up.png'
import ArrowDown from '../../assets/images/Icons/arrow-down.png'
import FilterSquareIcon from '../../assets/images/Icons/filter-square.png'
import StarIcon from '../../assets/images/Icons/star.png'
import SelectedIcon from '../../assets/images/Icons/selected.png'
import LocationIcon from '../../assets/images/location.png'
import ResumeListComponentNewDesign from "../../common/resumeListComponentNewDesign/index.js";
import CommonSelect from "../../common/selectFilter/selectFilter.js";

const { useBreakpoint } = Grid;
const { Title, Text } = Typography

const Search = ({ history }) => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const labels = useSelector((state) => state?.systemLabel?.labels);
  const globalState = useSelector((state) => state?.newSearch);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [collapse, setCollapse] = useState(screens?.md || false)
  const [open, setOpen] = useState({
    opportunity: false,
    shortList: false,
    saveSearch: false,
  });
  const [createModal, setCreateModal] = useState({
    open: false,
    value: "",
    id: "",
  });
  const [createSaveSearchModal, setCreateSaveSearchModal] = useState({
    open: false,
    value: "",
    id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState({ show: false, data: "" });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState({
    role: false,
    skill: false,
    industry: false,
    certificate: false,
    keyword: false,
  });
  const [filter, setFilter] = useState({
    skills: [],
    roles: [],
    location: [],
    language: [],
    keywords: [],
    industry: [],
    certification: [],
  });
  const [state, setState] = useState({
    collapsedLocation: true,
    resumeList: [],
    hasMore: false,
    scrollListHeight: "565px",
  });
  const [selected, setSelected] = useState({
    CertificationIds: [],
    Countries: [],
    IndusteryIds: [],
    KeywordIds: [],
    Languages: [],
    ProfileIds: [],
    SkillIds: [],
    checked: [],
    Type: "Only20",
    limit: 20,
    page: 1,
  });
  const handleCloseModal = () => {
    setShowModal((st) => ({
      ...st,
      show: false,
      data: "",
    }));
  };
  const getSkills = (searchKey) => {
    setLoading((st) => ({
      ...st,
      skill: true,
    }));
    getSkillsApi({ searchKey })
      .then((response) => {
        if (response.success) {
          const skills = response.items.map((item) => ({
            ...item,
            label: item.SkillValue,
            value: item.SkillId,
          }));
          const combinedArray = skills.concat(
            globalState?.defaultList?.SkillIds
          );
          const uniqueObjects = new Set();
          const uniqueArray = combinedArray.filter((obj) => {
            const isDuplicate = uniqueObjects.has(obj.value);
            uniqueObjects.add(obj.value);
            return !isDuplicate;
          });
          setFilter((st) => ({ ...st, skills: uniqueArray }));
          setLoading((st) => ({ ...st, skill: false }));
        } else {
        }
      })
      .catch(() => { });
  };
  const getRole = (searchKey) => {
    setLoading((st) => ({ ...st, role: true }));
    getRolesApi({ searchKey })
      .then((response) => {
        if (response.success) {
          const roles = response.items.map((item) => ({
            ...item,
            label: item.ProfileValue,
            value: item.ProfileId,
          }));

          const combinedArray = roles.concat(
            globalState?.defaultList?.ProfileIds
          );
          const uniqueObjects = new Set();
          const uniqueArray = combinedArray.filter((obj) => {
            const isDuplicate = uniqueObjects.has(obj.value);
            uniqueObjects.add(obj.value);
            return !isDuplicate;
          });

          setFilter((st) => ({ ...st, roles: uniqueArray }));
        }
        setLoading((st) => ({ ...st, role: false }));
      })
      .catch(() => {
        setLoading((st) => ({
          ...st,
          role: false,
        }));
      });
  };
  const getLocation = (searchKey) => {
    getAllLocationsApi({ searchKey })
      .then((response) => {
        if (response.success) {
          const countries = response.items.map((item) => ({
            ...item,
            label: item.CountryName,
            value: item.CountryId,
          }));
          setFilter((st) => ({
            ...st,
            location: countries,
          }));
        }
      })
      .catch(() => { });
  };
  const getLanguages = () => {
    getAllLanguagesApi()
      .then((response) => {
        if (response.success) {
          const languagesList = response.items.map((item) => ({
            ...item,
            label: item.LanguageValue,
            value: item.LanguageId,
          }));

          setFilter((st) => ({
            ...st,
            language: languagesList,
          }));
        }
      })
      .catch(() => { });
  };
  const getKeywords = (searchKey) => {
    setLoading((st) => ({
      ...st,
      keyword: true,
    }));
    getKeywordApi({ searchKey })
      .then((response) => {
        if (response.success) {
          const keywords = response.items.map((item) => ({
            ...item,
            label: item.KeywordValue,
            value: item.KeywordId,
          }));
          const combinedArray = keywords.concat(
            globalState?.defaultList?.KeywordIds
          );
          const uniqueObjects = new Set();
          const uniqueArray = combinedArray.filter((obj) => {
            const isDuplicate = uniqueObjects.has(obj.value);
            uniqueObjects.add(obj.value);
            return !isDuplicate;
          });
          setFilter((st) => ({ ...st, keywords: uniqueArray }));
          setLoading((st) => ({ ...st, keyword: false }));
        }
      })
      .catch(() => { });
  };
  const getIndustry = (searchKey) => {
    setLoading((st) => ({ ...st, industry: true }));
    getIndustriesApi({ searchKey }).then((response) => {
      if (response.success) {
        const industries = response.items.map((item) => ({
          ...item,
          label: item.IndustryValue,
          value: item.IndustryId,
        }));
        const combinedArray = industries.concat(
          globalState?.defaultList?.IndusteryIds
        );
        const uniqueObjects = new Set();
        const uniqueArray = combinedArray.filter((obj) => {
          const isDuplicate = uniqueObjects.has(obj.value);
          uniqueObjects.add(obj.value);
          return !isDuplicate;
        });
        setFilter((st) => ({ ...st, industry: uniqueArray }));
        setLoading((st) => ({ ...st, industry: false }));
      }
    });
  };
  const getCertification = (searchKey) => {
    setLoading((st) => ({ ...st, certificate: true }));
    getCertificationsApi({ searchKey })
      .then((response) => {
        if (response.success) {
          const certifications = response.items.map((item) => ({
            ...item,
            label: item.CertificationValue,
            value: item.CertificationId,
          }));
          const combinedArray = certifications.concat(
            globalState?.defaultList?.CertificationIds
          );
          const uniqueObjects = new Set();
          const uniqueArray = combinedArray.filter((obj) => {
            const isDuplicate = uniqueObjects.has(obj.value);
            uniqueObjects.add(obj.value);
            return !isDuplicate;
          });
          setFilter((st) => ({ ...st, certification: uniqueArray }));
        }
        setLoading((st) => ({ ...st, certificate: false }));
      })
      .catch(() => { });
  };
  const getShortlist = () => {
    getShortlistApi()
      .then((data) => {
        setState((st) => ({ ...st, shortListResume: data?.items }));
      })
      .catch(() => { });
  };
  const getSaveSearch = () => {
    getSavedSearchesApi()
      .then((data) => {
        if (data.success) {
          if (data.items.length > 0) {
            setState((st) => ({
              ...st,
              saveSearch: data?.items,
            }));
          }
        }
      })
      .catch(() => { });
  };
  const getOpportunities = () => {
    getOppuntinitiesApi()
      .then((data) => {
        setState((st) => ({
          ...st,
          opportunitiesList: data?.items,
        }));
      })
      .catch((err) => { });
  };

  const getCompany = () => {
    getCompanyApi().then((data) => {
      if (globalState?.selected?.Countries?.length == 0) {
        searchResume({
          ...globalState?.selected,
          Countries: [data?.items?.CountryId],
        });
        dispatch(
          onStateChangeAction({
            selected: {
              ...globalState?.selected,
              Countries: [data?.items?.CountryId],
            },
          })
        );
      } else {
        searchResume(globalState.selected);
      }
    });
  };

  const createNewShortlist = (id) => {
    const selectedResumesIds =
      selected?.checked?.length == 0 ? [id] : selected?.checked;
    const info = {
      ShortlistName: `New shortlist for ${selectedResumesIds.length
        } resume(s) at ${moment(new Date()).format("D MMMM YYYY")} at ${moment(
          new Date()
        ).format("LTS")}`,
      ResumeIdsList: selectedResumesIds,
    };
    return postShortlistApi(info)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  const handleExistingShortlistClick = (item, id) => {
    postShortlistApi({
      ShortlistId: item.ShortlistId,
      ShortlistName: item.ShortlistName,
      ResumeIdsList: selected?.checked?.length == 0 ? [id] : selected?.checked,
    })
      .then((data) => {
        if (data.success) {
          const info = {
            message: labels.SEARCH_RESUME_ADDED_SHORTLIST_SUCESSS_MESSAGE,
            status: "success",
          };
          dispatch(notificationAction(info));
          return;
        }
        const info = {
          message: data.message,
          status: "error",
        };
        dispatch(notificationAction(info));
      })
      .catch((err) => { });
  };

  const getFilterData = (name) => {
    const filterObj = { ...globalState?.selected };
    switch (name) {
      case "role":
        return filter?.roles?.filter((single) => {
          return filterObj?.ProfileIds?.some((row) => single.ProfileId == row);
        });
      case "skill":
        return filter?.skills?.filter((single) => {
          return filterObj?.SkillIds?.some((row) => single.SkillId == row);
        });
      case "language":
        return filter?.language?.filter((single) => {
          return filterObj?.Languages?.some((row) => single.LanguageId == row);
        });
      case "keyword":
        return filter?.keywords?.filter((single) => {
          return filterObj?.KeywordIds?.some((row) => single.KeywordId == row);
        });
      case "industry":
        return filter?.industry?.filter((single) => {
          return filterObj?.IndusteryIds?.some(
            (row) => single.IndustryId == row
          );
        });
      case "country":
        return filter?.location?.filter((single) => {
          return filterObj?.Countries?.some((row) => single.CountryId == row);
        });
      case "certificate":
        return filter?.certification?.filter((single) => {
          return filterObj?.CertificationIds?.some(
            (row) => single.CertificationId == row
          );
        });
    }
  };

  const handleExistingSaveSearchClick = (id) => {
    setIsLoading(true);
    const saveSearch = {
      SavedSearchId: id,
      SearchName: createSaveSearchModal?.value,
      Certifications: getFilterData("certificate")?.map((item) => {
        return {
          Id: item.CertificationId,
          ExpLevel: 0,
          LookupValue: item.CertificationValue,
        };
      }),
      Countries: getFilterData("country")?.map((item) => {
        return {
          Id: item.CountryId,
          ExpLevel: 0,
          LookupValue: item.CountryName,
        };
      }),
      Industries: getFilterData("industry").map((item) => {
        return {
          Id: item.IndustryId,
          ExpLevel: item.ExperienceLevel,
          LookupValue: item.IndustryValue,
        };
      }),
      Keywords: getFilterData("keyword")?.map((item) => {
        return {
          Id: item.KeywordId,
          ExpLevel: 0,
          LookupValue: item.KeywordValue,
        };
      }),
      Languages: getFilterData("language")?.map((item) => {
        return {
          Id: item.LanguageId,
          ExpLevel: item.ExperienceLevel,
          LookupValue: item.LanguageValue,
        };
      }),
      Profiles: getFilterData("role")?.map((item) => {
        return {
          Id: item?.ProfileId,
          ExpLevel: item.ExperienceLevel,
          LookupValue: item?.ProfileValue,
        };
      }),

      Skills: getFilterData("skill")?.map((item) => {
        return {
          Id: item.SkillId,
          ExpLevel: item.ExperienceLevel,
          LookupValue: item.SkillValue,
        };
      }),
    };
    if (id == 0) {
      postSearchApi(saveSearch)
        .then((data) => {
          if (data.success) {
            setIsLoading(false);
            const info = {
              message: labels.InfoISearchSavedSuccessfully,
              status: "success",
            };
            getSaveSearch();
            setCreateSaveSearchModal((st) => ({
              ...st,
              open: false,
              id: "",
              value: "",
            }));
            dispatch(notificationAction(info));
            return;
          }
          const info = {
            message: data.message,
            status: "error",
          };
          dispatch(notificationAction(info));
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      updateSearchApi(saveSearch)
        .then((data) => {
          if (data.success) {
            const info = {
              message: labels.InfoISearchSavedSuccessfully,
              status: "success",
            };
            setOpen((st) => ({
              ...st,
              saveSearch: !st.saveSearch,
            }));
            dispatch(notificationAction(info));
            getSaveSearch();
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const handleCreateNewShorlistClick = () => {
    setIsLoading(true);
    postShortlistApi({
      ShortlistId: -1,
      ShortlistName: createModal?.value,
      ResumeIdsList:
        selected?.checked?.length == 0 ? [createModal?.id] : selected?.checked,
    })
      .then((data) => {
        if (data.success) {
          getShortlist();
          setCreateModal((st) => ({
            ...st,
            open: !st.open,
            id: "",
            value: "",
          }));
          const info = {
            message: labels.shortlistSuccessAdded,
            status: "success",
          };
          dispatch(notificationAction(info));
          setIsLoading(false);
          return;
        }
        const info = {
          message: data.message,
          status: "error",
        };
        dispatch(notificationAction(info));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const searchResume = (values) => {
    setSelected((st) => ({ ...st, checked: [] }));
    setIsLoading(true);
    searchResumesApi(values)
      .then((res) => {
        if (res.success) {
          setState((st) => ({
            ...st,
            hasMore: res.items.length < 20 ? false : true,
            resumePage: 1,
          }));
          if (
            values?.IndusteryIds.length == 0 &&
            values?.KeywordIds.length == 0 &&
            values?.ProfileIds.length == 0 &&
            values?.SkillIds.length == 0 &&
            values?.CertificationIds.length == 0 &&
            values?.Languages.length == 0 &&
            values?.Countries.length == 0
          ) {
            dispatch(
              onStateChangeAction({
                resumeList: [],
                selectedResume: false,
              })
            );
            setState((st) => ({ ...st, resumeList: [] }));
          } else {
            dispatch(
              onStateChangeAction({
                resumeList: res?.items,
                selectedResume: false,
              })
            );
            setState((st) => ({ ...st, resumeList: res?.items }));
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const defaultOption = { ...globalState?.defaultList };
    if (defaultOption?.SkillIds?.length) {
      setFilter((st) => ({
        ...st,
        skills: defaultOption?.SkillIds,
      }));
    } else {
      getSkills("a");
    }
    if (defaultOption?.ProfileIds?.length) {
      setFilter((st) => ({
        ...st,
        roles: defaultOption?.ProfileIds,
      }));
    } else {
      getRole("a");
    }
    if (defaultOption?.KeywordIds?.length) {
      setFilter((st) => ({
        ...st,
        keywords: defaultOption?.KeywordIds,
      }));
    } else {
      getKeywords("a");
    }
    if (defaultOption?.IndusteryIds?.length) {
      setFilter((st) => ({
        ...st,
        industry: defaultOption?.IndusteryIds,
      }));
    } else {
      getIndustry("a");
    }
    if (defaultOption?.CertificationIds?.length) {
      setFilter((st) => ({
        ...st,
        certification: defaultOption?.CertificationIds,
      }));
    } else {
      getCertification("a");
    }
    getLocation("");
    getLanguages();
    getShortlist();
    getOpportunities();
    getCompany();
    getSaveSearch();
    window.view = "NEWSEARCH";
    window.actionName = "newSearch";
    setActive(window?.location?.hash?.slice(1))
    window.addEventListener('resize', handleResize);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menu = (resume) => {
    return (
      <Menu
        style={{
          height: "300px",
          overflow: "auto",
          width: "290px",
        }}
        className="dropdown-shortlists"
      >
        <Menu.Item
          key="Newest"
          className="first-child-dropdown"
          onClick={() => {
            setCreateModal((st) => ({
              ...st,
              open: !st.open,
              id: resume?.ResumeId,
            }));
          }}
        >
          <span className="span-">Create New Shortlist</span>
          &nbsp;
          <PlusOutlined />
        </Menu.Item>
        <Menu.Divider />
        {state?.shortListResume?.map((single, index) => (
          <Menu.Item
            key={index}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            onClick={() => {
              handleExistingShortlistClick(single, resume?.ResumeId);
            }}
          >
            {single?.ShortlistName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const createNewSaveSearch = (resume) => {
    return (
      <Menu
        style={{
          height: "300px",
          overflow: "auto",
          width: "290px",
        }}
        className="dropdown-shortlists"
      >
        <Menu.Item
          key="Newest"
          className="first-child-dropdown"
          onClick={(e) => {
            setCreateSaveSearchModal((st) => ({
              ...st,
              open: true,
              id: 0,
            }));
            // setOpen((st) => ({
            //   ...st,
            //   saveSearch: !st.saveSearch,
            // }));
          }}
        >
          <span className="span-">Save the search filter</span>
          <PlusOutlined />
        </Menu.Item>
        <Menu.Divider />
        {state?.saveSearch?.map((single, index) => (
          <Menu.Item
            key={index}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            onClick={() => {
              handleExistingSaveSearchClick(single?.SavedSearchId);
            }}
          >
            {single?.SearchName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const menuOpportunities = (single) => {
    return (
      <Menu
        style={{
          height: "300px",
          overflow: "auto",
          width: "290px",
        }}
        className="dropdown-shortlists"
      >
        <Menu.Item
          key="Newest"
          onClick={() => {
            createNewShortlist(single?.ResumeId).then((data) => {
              sessionStorage.setItem("opportunity-new", true);
              history.push(privateRoutes.searcherCreateOpportunity.path);
            });
          }}
          className="first-child-dropdown"
        >
          <span className="span-">Create New Opportunity</span>
          <PlusOutlined />
        </Menu.Item>
        <Menu.Divider />
        {state?.opportunitiesList?.map((single, index) => (
          <Menu.Item
            key={index}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            onClick={() => {
              sessionStorage.setItem("opportunity", JSON.stringify(single));
              history.push(privateRoutes.searcherCreateOpportunity.path);
            }}
          >
            {single?.RequestName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const handleChange = (value, name, option) => {
    const duplicate = { ...globalState?.selected };
    duplicate[name] = value;
    const duplicateDefaultList = { ...globalState?.defaultList };
    duplicateDefaultList[name] = option;
    dispatch(
      onStateChangeAction({
        selected: duplicate,
        defaultList: duplicateDefaultList,
      })
    );
    searchResume(duplicate);
  };

  const handleSearch = (e, name) => {
    if (e) {
      if (name == "role") {
        getRole(e);
      }
      if (name == "Skill") {
        getSkills(e);
      }
      if (name == "keyword") {
        getKeywords(e);
      }
      if (name == "industry") {
        getIndustry(e);
      }
      if (name == "certificate") {
        getCertification(e);
      }
    }
  };

  const handleClear = () => {
    dispatch(
      onStateChangeAction({
        selected: {
          CertificationIds: [],
          Countries: [],
          IndusteryIds: [],
          KeywordIds: [],
          Languages: [],
          ProfileIds: [],
          SkillIds: [],
          checked: [],
          Type: "Only20",
          limit: 20,
          page: 1,
        },
        resumeList: [],
      })
    );
  };

  const fetchMoreResumes = () => {
    if (!state?.hasMore) {
      return;
    }

    const infos = { ...globalState?.selected };
    onStateChangeAction({
      selected: infos,
    });
    infos.page = state?.resumePage + 1;
    setState((st) => ({
      ...st,
      resumePage: st.resumePage + 1,
    }));
    searchResumesApi(infos).then((data) => {
      if (data.success) {
        setState((st) => ({
          ...st,
          hasMore: data.items.length < 20 ? false : true,
        }));
        const currentResumesList = [...globalState?.resumeList];
        const allSearchedResumesList = currentResumesList?.concat(data?.items);
        dispatch(
          onStateChangeAction({
            resumeList: allSearchedResumesList,
          })
        );
      }
    });
  };

  const getTabs = () => {
    return [
      {
        id: 1,
        label: labels?.find_professionals,
        active: window?.location?.hash == "#/resume-search",
        value: "/resume-search"
      },
      {
        id: 2,
        label: labels?.save_professionals,
        active: window?.location?.hash == "#/saved-searches",
        value: "/saved-searches"
      },
      {
        id: 2,
        label: labels?.SEARCHER_NAVIGATION_SHORTLIST_LABEL,
        active: window?.location?.hash == "#/shortlists",
        value: "/shortlists"
      }
    ]
  }

  return (
    <div className="search-page-new-design">
      <PageWrapper className="">

        {isLoading && <LoadingMask text="" />}
        {showModal?.show == true && (
          <DetailModal
            setOpen={handleCloseModal}
            open={showModal}
            selected={globalState?.selected}
            menuOpportunities={menuOpportunities}
            menu={menu}
          />
        )}
        <ShortListModal
          open={createModal}
          setOpen={setCreateModal}
          handleCreateNew={handleCreateNewShorlistClick}
        />
        <SaveSearchModal
          open={createSaveSearchModal}
          setOpen={setCreateSaveSearchModal}
          handleCreateNew={(id) => handleExistingSaveSearchClick(id)}
        />
        <MobileFilter
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          filter={filter}
          handleChange={handleChange}
          handleSearch={handleSearch}
          globalState={globalState}
          loading={loading}
          setCreateSaveSearchModal={setCreateSaveSearchModal}
          labels={labels}
          state={state}
          handleExistingSaveSearchClick={handleExistingSaveSearchClick}
          handleClear={handleClear}
        />
        <div className="new-design-search w-100 d-flex flex-column gap-16">
          <div className="tabs-header-col mb-0">

            <div className="d-flex gap-16 justify-content-between align-items-center">
              <div className="d-flex gap-16 align-items-center main-header">
                {windowWidth < 767 ?
                  <Select
                    className="dropdown-callooration"
                    size="medium"
                    bordered={false}
                    onChange={(e) => {
                      setActive(e)
                      history.push(`${e}`)
                    }}
                    value={active}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.props?.label
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                    options={getTabs() || []}
                  />
                  :
                  <>
                    {getTabs()?.map(single =>
                      <button className="tab-btn-coll"
                        key={single?.id}
                        style={{ background: single?.active ? "#8E81F5" : "", color: single?.active ? "#fff" : "#000" }}
                        onClick={() => {
                          history.push(single?.value)
                        }}
                      >
                        {single?.label}
                      </button>
                    )}
                  </>
                }
              </div>
            </div>
          </div>
          <div className="cards-new">
            <Row gutter={[16, 8]}>
              <Col xs={0} md={24}>
                <div className="d-flex justify-content-between pointer align-items-center"
                  onClick={() => setCollapse(!collapse)}
                >
                  <Title level={4} className="m-0">Search by</Title>
                  <div>
                    {collapse ?
                      <img className="icon" src={ArrowUp} alt="icon" />
                      :
                      <img className="icon" src={ArrowDown} alt="icon" />
                    }
                  </div>
                </div>
              </Col>
              <Col xs={24} md={0}>
                <div className="d-flex justify-content-between pointer align-items-center"
                >
                  <Title level={4} className="m-0">Filter By</Title>
                  <div
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    <img className="icon" src={FilterSquareIcon} alt="icon" />
                  </div>
                </div>
              </Col>
              {collapse &&
                < React.Fragment className="mt-2 ">
                  <Col xs={6}>
                    <CommonSelect
                      loading={loading?.role}
                      options={filter?.roles}
                      label={"Role"}
                      value={globalState?.selected?.ProfileIds}
                      placeholder={"Select role"}
                      handleChange={handleChange}
                      handleSearch={handleSearch}
                      searchParams={"role"}
                      changesParams={"ProfileIds"}
                    />
                  </Col>
                  <Col xs={6}>
                    <CommonSelect
                      options={filter?.location}
                      label={"Location"}
                      placeholder={"Select location"}
                      value={globalState?.selected?.Countries}
                      handleChange={handleChange}
                      searchParams={"role"}
                      changesParams={"Countries"}
                      search={false}
                    />
                  </Col>
                  <Col xs={6}>
                    <CommonSelect
                      options={filter?.language}
                      label={"Language"}
                      value={globalState?.selected?.Languages}
                      placeholder={"Select Language"}
                      search={false}
                      handleChange={handleChange}
                      changesParams={"Languages"}
                    />
                  </Col>
                  <Col xs={6}>
                    <CommonSelect
                      options={filter?.skills}
                      label={"Skill"}
                      loading={loading?.skill}
                      value={globalState?.selected?.SkillIds}
                      placeholder={"Select skills"}
                      handleChange={handleChange}
                      changesParams={"SkillIds"}
                      handleSearch={handleSearch}
                      searchParams={"Skill"}
                    />
                  </Col>
                  <Col xs={6}>
                    <CommonSelect
                      options={filter?.keywords}
                      label={"Keyword"}
                      loading={loading?.keyword}
                      value={globalState?.selected?.KeywordIds}
                      placeholder={"Select keywords"}
                      handleChange={handleChange}
                      changesParams={"KeywordIds"}
                      handleSearch={handleSearch}
                      searchParams={"keyword"}
                    />
                  </Col>
                  <Col xs={6}>
                    <CommonSelect
                      options={filter?.industry}
                      label={"Industry"}
                      loading={loading?.industry}
                      value={globalState?.selected?.IndusteryIds}
                      placeholder={"Select industry"}
                      handleChange={handleChange}
                      changesParams={"IndusteryIds"}
                      handleSearch={handleSearch}
                      searchParams={"industry"}
                    />
                  </Col>
                  <Col xs={6}>
                    <CommonSelect
                      options={filter?.certification}
                      label={"Certification"}
                      loading={loading?.certificate}
                      value={globalState?.selected?.CertificationIds}
                      placeholder={"Select certifications"}
                      handleChange={handleChange}
                      changesParams={"CertificationIds"}
                      handleSearch={handleSearch}
                      searchParams={"certificate"}
                    />
                  </Col>
                  <Col xs={6}>
                    <Text className="label m-0">Availability Date</Text>
                    <div className="animated">
                      <DatePicker
                        style={{ width: "100%", marginTop: "6px", border: "1px solid #f3f3f3" }}
                        value={globalState?.selected?.AvailabilityDate}
                        onChange={(e) => handleChange(e, "AvailabilityDate")}
                        bordered={false}
                      />
                    </div>
                  </Col>

                  <Col xs={24} className="mt-2">
                    <div className="search-btn">
                      <button className="btn1"
                        onClick={() => {
                          handleClear();
                        }}
                      >Clear Search</button>
                      <Dropdown
                        overlay={createNewSaveSearch()}
                        trigger={["click"]}
                        open={open?.saveSearch}
                        onOpenChange={() => {
                          if (
                            !(
                              globalState?.selected?.IndusteryIds.length ||
                              globalState?.selected?.KeywordIds.length ||
                              globalState?.selected?.ProfileIds.length ||
                              globalState?.selected?.SkillIds.length ||
                              globalState?.selected?.CertificationIds.length ||
                              globalState?.selected?.Languages.length ||
                              globalState?.selected?.Countries.length
                            )
                          ) {
                            const info = {
                              message: labels.SEARCH_NO_CRITERIA_VALIDATION,
                              status: "error",
                            };
                            dispatch(notificationAction(info));
                            return;
                          }
                          setOpen((st) => ({
                            ...st,
                            saveSearch: !st.saveSearch,
                          }));
                        }}
                      >
                        <button className="btn2">Save Search Filter(s)</button>
                      </Dropdown>
                    </div>
                  </Col>
                </React.Fragment>
              }
            </Row>
          </div>
          <div className="resume-list" style={{ background: "#fff", borderRadius: "12px" }}>
            <div style={{ padding: "16px", borderBottom: "1px solid #f4f2fe" }}>
              <Row gutter={[24, 4]}>
                <Col xs={24}>
                  <div className="d-flex justify-content-between align-items-center">
                    <Title level={4} className="m-0">Search Results</Title>
                    <div>
                      <Title level={5} className="m-0">{selected?.checked?.length || 0} Selected</Title>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <ResumeListComponentNewDesign
              resumeList={globalState?.resumeList}
              score={
                globalState?.selected?.ProfileIds?.length * 100 +
                globalState?.selected?.SkillIds?.length * 10 +
                globalState?.selected?.CertificationIds?.length * 20 +
                globalState?.selected?.IndusteryIds?.length * 10 +
                globalState?.selected?.KeywordIds?.length * 5
              }
              state={state}
              fetchMoreResumes={fetchMoreResumes}
              selected={selected}
              setSelected={setSelected}
              setShowModal={setShowModal}
              shortListDropdown={menu}
              opportunityDropdown={menuOpportunities}
              name="search"
            />
            <Row className="footer-button-">
              {globalState?.resumeList?.length > 0 && (
                <Row className="w-100" style={{ justifyContent: "flex-end" }}>
                  <Space size={[12, 8]}>
                    <Dropdown
                      overlay={menu()}
                      trigger={["click"]}
                      open={open?.shortList}
                      onOpenChange={() => {
                        if (selected?.checked?.length == 0) {
                          const info = {
                            message:
                              labels.InfoIShortlistResumeListSelectionError,
                            status: "error",
                          };
                          dispatch(notificationAction(info));
                          return;
                        }
                        setOpen((st) => ({
                          ...st,
                          shortList: !st.shortList,
                        }));
                      }}
                    >
                      <button className="button-last-footer w-100">
                        {`Bulk Shortlist (${selected?.checked?.length || 0})`}
                      </button>

                    </Dropdown>
                    <Dropdown
                      overlay={menuOpportunities()}
                      trigger={["click"]}
                      open={open?.opportunity}
                      onOpenChange={() => {
                        if (selected?.checked?.length == 0) {
                          const info = {
                            message:
                              labels.InfoIShortlistResumeListSelectionError,
                            status: "error",
                          };
                          dispatch(notificationAction(info));
                          return;
                        }
                        setOpen((st) => ({
                          ...st,
                          opportunity: !st.opportunity,
                        }));
                      }}
                    >
                      <button className="button-footer-opportunity w-100">
                        {`Bulk Invite (${selected?.checked?.length || 0})`}
                      </button>
                    </Dropdown>
                  </Space>
                </Row>
              )}
            </Row>
          </div>
        </div >
      </PageWrapper>
    </div >
  );
};

Search.defaultProps = {
  isSaveSearch: false,
};

export default withRouter(Search);
