import * as React from "react";

const location = (props) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5002 19.5855C20.0131 19.5855 22.0502 17.5484 22.0502 15.0355C22.0502 12.5226 20.0131 10.4855 17.5002 10.4855C14.9873 10.4855 12.9502 12.5226 12.9502 15.0355C12.9502 17.5484 14.9873 19.5855 17.5002 19.5855Z"
        stroke={props.color || "#8F82F5"}
        stroke-width="1.5"
      />
      <path
        d="M5.27931 12.3812C8.15223 -0.247954 26.8626 -0.23337 29.721 12.3958C31.3981 19.8041 26.7897 26.075 22.7501 29.9541C19.8189 32.7833 15.1814 32.7833 12.2356 29.9541C8.21056 26.075 3.60223 19.7895 5.27931 12.3812Z"
        stroke={props.color || "#8F82F5"}
        stroke-width="1.5"
      />
    </svg>
  );
};

export default location;
