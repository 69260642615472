import React, { Component } from "react";
import "./presentation.scss";
import ImgSrc from "../../../assets/images/company.svg";
import { Button } from "../../../common";

function getBtnClass(url) {
  if (url.indexOf("facebook") > 0) return "facebook";
  else if (url.indexOf("twitter") > 0) return "twitter";
  else if (url.indexOf("google") > 0) return "googleplus";
  else if (url.indexOf("instagram") > 0) return "instagram";
  else if (url.indexOf("linkedin") > 0) return "linkedin";
  else if (url.indexOf("pinterest") > 0) return "pinterest";
  else if (url.indexOf("driblle") > 0) return "driblle";
  else if (url.indexOf("behance") > 0) return "behance";
  else return "";
}
class Presentations extends Component {
  render() {
    const {
      selectedUser,
      labels,
      handleSendMessageEmail,
      onAssortOpportnity,
    } = this.props;

    return (
      <div
        className="user-detail-component"
        test-data-id="collaboation-presentation-detail"
      >
        <div className="MsgWrapper">
          <div className="MsgSubject">
            <div className="presentaion-image-wrap">
              <img
                src={(selectedUser && selectedUser.Logo) || ImgSrc}
                className={
                  selectedUser && selectedUser.Logo
                    ? "roundImg"
                    : "emptyRoundImag"
                }
              />
            </div>
            <label className="presentaion-name">
              {selectedUser && selectedUser.Title}
            </label>
          </div>
          <div className="msgItemDiv">
            {selectedUser && selectedUser.UserName && (
              <div className="msgItem">
                <label className="labelName">{labels.NAME_LABEL}:</label>
                <label
                  test-data-id={`collabs-present-name`}
                  className="answerLabel"
                >
                  {selectedUser.UserName}
                </label>
              </div>
            )}
            {selectedUser.UserProfile && selectedUser.UserProfile && (
              <div className="msgItem">
                <label className="labelName">
                  {labels.SearcherSentCollaborationPositionLabel}:
                </label>
                <label className="answerLabel">
                  {selectedUser.UserProfile}
                </label>
              </div>
            )}
            {selectedUser.email && selectedUser.email.UserEmailValue && (
              <div className="msgItem">
                <label className="labelName">
                  {labels.SearcherSentCollaborationEmailLabel}:
                </label>
                <label className="answerLabel">
                  {selectedUser.email.UserEmailValue}
                </label>
              </div>
            )}
            {selectedUser && selectedUser.phone && (
              <div className="msgItem">
                <label className="labelName">
                  {labels.SearcherSentCollaborationPhoneLabel}:
                </label>
                <label className="answerLabel">
                  {selectedUser.phone.UserPhonenumberValue}
                </label>
              </div>
            )}
            {selectedUser && selectedUser.WebUrl && (
              <div className="msgItem">
                <label className="labelName">{labels.WEB_URL_LABEL}:</label>
                <label className="answerLabel">
                  <a target="_blank" href={selectedUser.WebUrl}>
                    {selectedUser.WebUrl}
                  </a>
                </label>
              </div>
            )}
            {selectedUser && selectedUser.Detail && (
              <div className="msgItem">
                <label className="labelName">{labels.DETAILS_LABEL}:</label>
                <label className="answerLabel">{selectedUser.Detail}</label>
              </div>
            )}
            <div className="socialCnt">
              {selectedUser.UserDetailSocialLink &&
                selectedUser.UserDetailSocialLink.map((item, index) => (
                  <button
                    key={index}
                    className={`socialBtn ${getBtnClass(item.URL)}Btn ${
                      item.Id ? "activeBtn" : ""
                    }`}
                    onClick={() => {
                      window.open(item.URL, "_blank");
                    }}
                  />
                ))}
            </div>
            {onAssortOpportnity && selectedUser.UserId && (
              <div className="form-row action-buttons">
                <Button
                  className="SendButton"
                  text={labels.SEND_EMAIL_LABEL || "Send Email"}
                  onClick={() => handleSendMessageEmail(1)}
                  tooltipButton={labels.SEND_EMAIL_LABEL || "Send Email"}
                  tooltipHelp={labels.SEND_EMAIL_LABEL}
                  tooltipPlace="left"
                  testId="collapaseBtn3"
                />
                <Button
                  className="SendButton"
                  text={labels.TooltipIProCreateMessageSendButton}
                  onClick={() => handleSendMessageEmail(2)}
                  tooltipButton={labels.TooltipIProCreateMessageSendButton}
                  tooltipHelp={labels.TooltipIProCreateMessageSendButton}
                  tooltipPlace="left"
                  testId="collapaseBtn3"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Presentations;
