import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  searchKey: "",
  presentForm: {},
  presentList: [],
  filteredList: [],
  deletedId: null,
  dialogMessage: "",
  socialMedia: [],
  profiles: [],
  userEmails: [],
  userPhonenumbers: [],
  selectedProfileId: null,
  selectedPhoneId: null,
  selectedEmailId: null,
  isLoading: false,
  isFetching: true,
  listCollapse: false,
  formCollapse: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.PRESENT.SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload
      };
    case ActionTypes.PRESENT.PROFILES:
      return {
        ...state,
        profiles: action.payload
      };
    case ActionTypes.PRESENT.PRESENT_FORM:
      return {
        ...state,
        presentForm: action.payload
      };

    case ActionTypes.PRESENT.PRESENT_LIST:
      return {
        ...state,
        presentList: action.payload
      };
    case ActionTypes.PRESENT.FILTERED_LIST:
      return {
        ...state,
        filteredList: action.payload
      };
    case ActionTypes.PRESENT.DELETED_ID:
      return {
        ...state,
        deletedId: action.payload
      };
    case ActionTypes.PRESENT.DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: action.payload
      };
    case ActionTypes.PRESENT.SOCIAL_MEDIA:
      return {
        ...state,
        socialMedia: action.payload
      };
    case ActionTypes.PRESENT.USER_EMAILS:
      return {
        ...state,
        userEmails: action.payload
      };
    case ActionTypes.PRESENT.USER_PHONE_NUMBERS:
      return {
        ...state,
        userPhonenumbers: action.payload
      };
    case ActionTypes.PRESENT.SELECTED_PROFILE_ID:
      return {
        ...state,
        selectedProfileId: action.payload
      };
    case ActionTypes.PRESENT.SELECTED_PHONE_ID:
      return {
        ...state,
        selectedPhoneId: action.payload
      };
    case ActionTypes.PRESENT.SELECTED_EMAIL_ID:
      return {
        ...state,
        selectedEmailId: action.payload
      };
    case ActionTypes.PRESENT.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case ActionTypes.PRESENT.IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case ActionTypes.PRESENT.LIST_COLLAPSE:
      return {
        ...state,
        listCollapse: action.payload
      };
    case ActionTypes.PRESENT.FORM_COLLAPSE:
      return {
        ...state,
        formCollapse: action.payload
      };

    default:
      return state;
  }
};
