import { ActionTypes } from "../actions";
const INITIAL_STATE = {
  isLoading: false,
  isFetching: false,
  opportunities: [],
  filterOpportunires: [],
  selectedOpportunity: null,
  isEmptyCollapsed: false,
  listCollapsed: false,
  formCollapsed: false,
  shortlistCollapsed: false,
  resumesCollapsed: false,
  resumeDetailCollapsed: false,
  shortlists: [],
  shortlistResumes: [],
  selectedResume: {},
  selectedShortlist: null,
  selectedShortlists: [],
  dialogMessage: "",
  yesClickCount: 0,
  selectedSaveSearch: {},
  selectedCompany: null,
  savedSearches: [],
  userCompanies: [],
  languages: [],
  locations: [],
  isFetchingShortlist: false,
  isFetchingShortlistResume: false,
  opportunitySaveLoading: false,
  filterShortlists: [],
  searchKey: "",
  opportunityToDelete:{},
  allCurrenciesList:[]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.IS_LOADING:
      return { ...state, isLoading: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.IS_FETCHING:
      return { ...state, isFetching: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.OPPORTUNITIES:
      return { ...state, opportunities: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.FILTER_OPPORTUNIRES:
      return { ...state, filterOpportunires: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_OPPORTUNITY:
      return { ...state, selectedOpportunity: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.IS_EMPTY_COLLAPSED:
      return { ...state, isEmptyCollapsed: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.LIST_COLLAPSED:
      return { ...state, listCollapsed: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.FORM_COLLAPSED:
      return { ...state, formCollapsed: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SHORTLIST_COLLAPSED:
      return { ...state, shortlistCollapsed: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.RESUMES_COLLAPSED:
      return { ...state, resumesCollapsed: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.RESUME_DETAIL_COLLAPSED:
      return { ...state, resumeDetailCollapsed: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SHORTLISTS:
      return { ...state, shortlists: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SHORTLIST_RESUMES:
      return { ...state, shortlistResumes: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_RESUME:
      return { ...state, selectedResume: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_SHORTLIST:
      return { ...state, selectedShortlist: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_SHORTLISTS:
      return { ...state, selectedShortlists: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.DIALOG_MESSAGE:
      return { ...state, dialogMessage: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.YES_CLICK_COUNT:
      return { ...state, yesClickCount: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_SAVE_SEARCH:
      return { ...state, selectedSaveSearch: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_COMPANY:
      return { ...state, selectedCompany: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SAVED_SEARCHES:
      return { ...state, savedSearches: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.USER_COMPANIES:
      return { ...state, userCompanies: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.LANGUAGES:
      return { ...state, languages: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.LOCATIONS:
      return { ...state, locations: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.IS_FETCHING_SHORTLIST:
      return { ...state, isFetchingShortlist: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.IS_FETCHING_SHORTLIST_RESUME:
      return { ...state, isFetchingShortlistResume: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.OPPORTUNITY_SAVE_LOADING:
      return { ...state, opportunitySaveLoading: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.FILTER_SHORTLISTS:
      return { ...state, filterShortlists: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SEARCH_KEY:
      return { ...state, searchKey: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.OPPORTUNITY_TO_DELETE:
        return { ...state, opportunityToDelete: action.payload };
    case ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.ALL_CURRENCIES_LIST:
      return {
        ...state,
        allCurrenciesList: action.payload
      }; 
    default:
      return state;
  }
};
