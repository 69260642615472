import React, { Component } from "react";
import { PageWrapper } from "../../../components";
import { Column, EmptyInfo, Button } from "../../../common";

class EmptyCreateMessage extends Component {
  render() {
    const {
      labels,
      isHelpActive,
      testId,
      createMessageCollapsed,
      onStateChangeAction,
      IsFreelancer
    } = this.props;
    return (
      <PageWrapper className="message-new">
        <Column
          collapse={createMessageCollapsed}
          className="col-1"
          testId={testId}
        >
          <Column.Collapsed
            text={labels.ViewTitleIProMessageList}
            onClick={() =>
              onStateChangeAction({ createMessageCollapsed: false })
            }
            tooltipButton={labels.TooltipIProCreateMessageEmptyListExpand}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.HlpTooltipIProCreateMessageEmptyListExpand}
            tooltipPlace="left"
            testId="create-message-empty-expand-btn"
            testIdHelp="create-message-empty-expand-help-btn"
          />
          <Column.Head>
          <div className="heading">Message List</div>
            <Button
              onClick={() =>
                onStateChangeAction({ createMessageCollapsed: true })
              }
              className="collapseBtn"
              tooltipButton={labels.TooltipIProCreateMessageEmptyListCollaspe}
              tooltipHelp={labels.HlpTooltipIProCreateMessageEmptyListCollaspe}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId="create-message-empty-collapse-btn"
              testIdHelp="create-message-empty-collapse-help-btn"
            />
          </Column.Head>
          <Column.Body className="flex">
            <EmptyInfo>
              {IsFreelancer ? labels.iProCreateMessageWithoutInvitationInfo : labels.searcherCreateMessageWithoutInvitationInfo}
            </EmptyInfo>
          </Column.Body>
        </Column>
      </PageWrapper>
    );
  }
}

export default EmptyCreateMessage;
