import React, { Component } from "react";
import moment from "moment";
import { EmptyInfo, Button } from "../../../../common";
import { ListItem } from "../components/ListItem";

class ExpenseList extends React.Component {
  renderStatusName(id) {
    switch (id) {
      case 1:
        return this.props.labels.TictellStatusNamePending;
      case 2:
        return this.props.labels.tictellStatusNameApproved;
      case 3:
        return this.props.labels.tictellStatusNameRejected;
      case 4:
        return "";
    }
  }
  render() {
    const {
      expenseList,
      onItemClick,
      handleExpenseDelete,
      handleAddNewExpense,
      ExpenseSheetStatusId,
      handleSendExpenseSheet,
      labels,
      isHelpActive,
      startDate,
      endDate,
      selectedCollaboration,
    } = this.props;
    return (
      <React.Fragment>
        {expenseList && expenseList.length > 0 ? (
          <div className="expense-list">
            {expenseList.map((item) => (
              <ListItem
                onClick={onItemClick}
                item={item}
                onDelete={handleExpenseDelete}
                key={item.ExpenseId}
                expenseSheetStatusId={ExpenseSheetStatusId}
              >
                <div className="name-container">
                  <div>
                    <label>
                      {item.SpentOn &&
                        moment(item.SpentOn).format("ddd, MMM Do YYYY")}
                    </label>
                  </div>
                  <label>{item.CategoryName.Title}</label>
                </div>
                <div className="d-flex align-items-center">
                  <label className="currency-label">{`${item.Amount} ${
                    item.CurrencyName.Name
                  } ${
                    item.CurrencyName.Name != item.CollaborationCurrency
                      ? `(${item.CollaborationCurrencyAmount ||
                          ""} ${item.CollaborationCurrency || "NA "})`
                      : ""
                  }`}</label>
                  {(ExpenseSheetStatusId == 3 || ExpenseSheetStatusId == 4) && (
                    <button
                      onClick={() => handleExpenseDelete(item)}
                      className="close_btn"
                    >
                      <i className="fa fa-times-circle" />
                    </button>
                  )}
                </div>
              </ListItem>
            ))}
          </div>
        ) : (
          <EmptyInfo>{labels.Tictell_Empty_Message}</EmptyInfo>
        )}
        <Button
          test-data-id={`approval-expense-btn`}
          mainClassName={"action-button-wrapper"}
          className={`send-btn status-${ExpenseSheetStatusId} ${
            ExpenseSheetStatusId == 4 && expenseList && expenseList.length < 1
              ? "disabled-class"
              : ""
          }`}
          onClick={handleSendExpenseSheet}
          isHelpActive={isHelpActive}
          tooltipHelp={labels.TictellSendExpenseSheet}
          tooltipButton={labels.ToolTipSendExpenseSheet}
        >
          <i className="fa fa-thumbs-up"></i>
        </Button>
        {!!selectedCollaboration &&
        moment(startDate).isSameOrAfter(
          moment(selectedCollaboration.StartDate).startOf("w")
        ) &&
        moment(endDate).isSameOrBefore(
          moment(selectedCollaboration.EndDate).endOf("w")
        ) ? (
          <Button
            testId={`add-expense-btn`}
            mainClassName={"action-button-wrapper"}
            className={`add-btn ${
              ExpenseSheetStatusId == 1 || ExpenseSheetStatusId == 2
                ? "disabled-class"
                : ""
            }`}
            onClick={handleAddNewExpense}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.tictellAddExpenseBtn}
            tooltipButton={labels.ToolTipAddExpenseLog}
          >
            <i class="fa fa-plus" />
          </Button>
        ) : (
          <Button
            mainClassName={"action-button-wrapper"}
            testid={`add-newLog-btn`}
            className={`help-btn ${
              ExpenseSheetStatusId == 1 || ExpenseSheetStatusId == 2
                ? "disabled-class"
                : ""
            }`}
            isHelpActive={isHelpActive}
            testIdHelp={"add-newLog-btn-help"}
            tooltipHelp={
              "This collaboration is expired you can not add timelog here"
            }
            tooltipButton={
              "This collaboration is expired you can not add timelog here"
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default ExpenseList;
