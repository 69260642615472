import React from "react";
import moment from "moment";
import { DatePicker, Button } from "../../../../../common";
import { CustomInputNew } from "../../../../Tictell/views/components/CustomInput";

class CollaborationDetail extends React.Component {
  render() {
    const {
      selectedCollaboration,
      labels,
      handleCollaborationEndDateChange,
      handleCollaborationNameEdit,
      isEndDateCalendarOpen,
      handleDateOnBlur,
      handleCollborationNameBlur,
      handleCollborationNameChange,
      onCollaborationClose,
    } = this.props;
    const {
      Dateformatelabel,
      SNAPSHOT_COLLABORATION_OWNER,
      SNAPSHOT_COLLABORATION_START,
      SNAPSHOT_COLLABORATION_END,
      SNAPSHOT_COLLABORATION_COMPENSATION,
      SNAPSHOT_COLLABORATION_WORKPLACE,
      SNAPSHOT_COLLABORATION_DESC,
      CollaborationNameLabel,
    } = labels;
    const {
      CollaborationOwner,
      StartDate,
      EndDate,
      HourlyRate,
      HourlyRateType,
      CompanyName,
      Decription,
      Title,
      isNameEdit,
    } = selectedCollaboration;
    return (
      <div className="snapshot-collaboration-detail">
        <div className="start-end">
          <CustomInputNew label={CollaborationNameLabel}>
            <input
              type="text"
              autoComplete="off"
              value={Title || ""}
              name="Amount"
              className={`input-text `}
              placeholder={SNAPSHOT_COLLABORATION_OWNER}
              test-data-id="input-collab-title"
              onBlur={handleCollborationNameBlur}
              onChange={handleCollborationNameChange}
              disabled={isNameEdit && isNameEdit ? false : true}
            />
          </CustomInputNew>
          <Button
            testId={`collabUpdateBtn`}
            className="add-btn"
            onClick={this.handleAddNewCollaboration}
            isHelpActive={this.props.isHelpActive}
            testIdHelp={"add-newLog-btn-help"}
            tooltipHelp={"Click on this button to edit the collaboration name"}
            tooltipButton={"Edit collaboration name"}
            onClick={() => handleCollaborationNameEdit()}
          ></Button>
        </div>
        <CustomInputNew label={SNAPSHOT_COLLABORATION_OWNER}>
          <input
            type="text"
            autoComplete="off"
            value={CollaborationOwner || ""}
            name="Amount"
            className={`input-text `}
            placeholder={SNAPSHOT_COLLABORATION_OWNER}
            test-data-id="input-expanse-amount"
            disabled
          />
        </CustomInputNew>
        <div className="start-end">
          <CustomInputNew label={SNAPSHOT_COLLABORATION_START}>
            <label className="datepicker-wrapper">
              <DatePicker
                selected={StartDate && moment(StartDate)}
                name="expenseDate"
                dateFormat="MMM Do YYYY"
                placeholderText={Dateformatelabel}
                disabled
              />
            </label>
          </CustomInputNew>
          <span>-</span>
          <CustomInputNew label={SNAPSHOT_COLLABORATION_END}>
            <label className="datepicker-wrapper">
              <DatePicker
                selected={EndDate && moment(EndDate)}
                name="expenseDate"
                dateFormat="MMM Do YYYY"
                placeholderText={Dateformatelabel}
                onChange={handleCollaborationEndDateChange}
                minDate={moment()}
                onBlur={handleDateOnBlur}
                open={isEndDateCalendarOpen}
                disabled
              />
            </label>
          </CustomInputNew>
        </div>
        <CustomInputNew label={SNAPSHOT_COLLABORATION_COMPENSATION}>
          <input
            type="text"
            autoComplete="off"
            value={`${HourlyRate || ""} ${HourlyRateType || ""} per Hour`}
            name="Amount"
            className={`input-text `}
            placeholder={SNAPSHOT_COLLABORATION_COMPENSATION}
            test-data-id="input-expanse-amount"
            disabled
          />
        </CustomInputNew>
        <CustomInputNew label={SNAPSHOT_COLLABORATION_WORKPLACE}>
          <input
            type="text"
            autoComplete="off"
            value={CompanyName || ""}
            name="Amount"
            className={`input-text `}
            placeholder={SNAPSHOT_COLLABORATION_WORKPLACE}
            test-data-id="input-expanse-amount"
            disabled
          />
        </CustomInputNew>
        <CustomInputNew label={SNAPSHOT_COLLABORATION_DESC}>
          <textarea
            type="text"
            value={Decription || ""}
            name={"Description"}
            className="textarea-field"
            rows="9"
            rows="6"
            placeholder={SNAPSHOT_COLLABORATION_DESC}
            test-data-id="input-comment"
            disabled
          />
        </CustomInputNew>
        <div className="invRequest">
          <button
            className="invDecline SendButton"
            // onClick={onCollaborationDelete}
            onClick={onCollaborationClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

export default CollaborationDetail;
