import React from "react";
import { Select } from "antd";
const { Option } = Select;

const CustomSelect = ({
  options,
  width,
  background,
  padding,
  margin,
  value,
  handleChange,
  className,
}) => {
  return (
    <Select
      defaultValue={options[0]}
      style={{
        width: width ? width : 317,
        background: background ? background : "#F7F6FE",
        borderRadius: "8px",
        padding: padding ? padding : 10,
        margin: margin ? margin : "10px 16px 10px 0px",
      }}
      dropdownStyle={{
        background: background ? background : "#F7F6FE",
        padding:padding ? padding : 10,
      }}
      bordered={false}
      onChange={handleChange}
      className={className}
      value={value}
    >
      {options.map((item, ind) => (
        <Option value={item} key={ind}>
          {item}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
