import React, { Component } from "react";
import { DragPDF, PageWrapper } from "../../components";
import {
  Column,
  Button,
  LoadingMaskRow,
  EmptyInfo,
  ConfirmDialog,
} from "../../common";
import { connect } from "react-redux";
import { notificationAction } from "./../../actions";
import { privateRoutes } from "../../Routes/routing";
import { getResumesApi, getMyPDFResumeApi } from "./resumeApi";
import ResumeDetail from "./ResumeDetail/ResumeDetail";
import phillipImg from "../../../src/assets/images/svg/phillip-12.svg";
import { htmlParser } from "../../utilities/helpers";
import "./resume.scss";
import { onStateChangeAction } from "./resumeAction";
import {
  saveParsedResume,
  uploadLinkedinPDF,
} from "../../components/Phillip/phillipApi";
const ResumeValues = [
  {
    id: 0,
    name: "Resume",
    notFound: "iProOnboardingResumeCreated",
    found: "iProOnboardingResumeCreated",
    active: true,
  },
  {
    id: 1,
    name: "Keywords",
    notFound: "iProOnboardingKeywordNotFound",
    found: "iProOnboardingKeywordFound",
  },
  {
    id: 2,
    name: "Certifications",
    notFound: "iProOnboardingCertificationNotFound",
    found: "iProOnboardingCertificationFound",
  },
  {
    id: 3,
    name: "Skills",
    notFound: "iProOnboardingSkillNotFound",
    found: "iProOnboardingSkillFound",
  },
  {
    id: 4,
    name: "Profiles",
    notFound: "iProOnboardingRoleNotFound",
    found: "iProOnboardingRoleFound",
  },
  {
    id: 5,
    name: "Educations",
    notFound: "iProOnboardingEducationNotFound",
    found: "iProOnboardingEducationFound",
  },
  {
    id: 6,
    name: "Experiences",
    notFound: "iProOnboardingExperienceNotFound",
    found: "iProOnboardingExperienceFound",
  },
  {
    id: 7,
    name: "Countries",
    notFound: "iProOnboardingCountryNotFound",
    found: "iProOnboardingCountryFound",
  },
  {
    id: 8,
    name: "Languages",
    notFound: "iProOnboardingLanguageNotFound",
    found: "iProOnboardingLanguageFound",
  },
  // {
  //   id: 9,
  //   name: "Present",
  //   notFound: "iProOnboardingPresentNotFound",
  //   found: "iProOnboardingPresentFound"
  // }
];
class Resume extends Component {
  state = {};

  componentDidMount() {
    this.getResume();
  }

  getPDFResumeCallback = (pdfData, uploadPDFButtonStatus) => {
    if (pdfData) {
      this.props.onStateChangeAction({
        selectedPDFResume: pdfData,
        uploadPDFButtonDisable: uploadPDFButtonStatus,
        dialogResumeUpload: this.props.labels.RESUME_UPLOAD_PDF_FILE_INFO,
      });
      return;
    }
    this.props.onStateChangeAction({
      selectedPDFResume: null,
      uploadPDFButtonDisable: true,
    });
  };

  handleUploadPDFResume = () => {
    this.props.onStateChangeAction({
      dialogMessage: this.props.labels.resumeImportUserParsingFeedback,
    });
  };

  getResume = () => {
    this.props.onStateChangeAction({
      fetchingResumes: true,
    });
    getResumesApi()
      .then((data) => {
        if (data.success) {
          this.props.onStateChangeAction({
            selectedResume: data.items,
            fetchingResumes: false,
          });
        }
      })
      .catch((response) => {
        console.log("error in get resume request ");
      });
  };

  handleYesClick = () => {
    const info = {
      message: this.props.labels.resumeImportResumeParsingInfo,
      status: "success",
    };
    this.props.notificationAction(info);
    const { selectedPDFResume } = this.props;
    uploadLinkedinPDF(selectedPDFResume).then((res) => {
      this.getResume();
      this.props.onStateChangeAction({
        dialogMessage: "",
        PDFFileName: this.props.labels.iproResumeFileFormate,
        isPDFFileUploaded: false,
        uploadPDFButtonDisable: true,
      });
    });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  handleResumeUploadYesClick = () => {
    const { selectedPDFResume } = this.props;
    uploadLinkedinPDF(selectedPDFResume).then((res) => {
      if (res.items) {
        saveParsedResume(res.items)
          .then(() => {
            const info = {
              message: "Resume saved successfully",
              status: "success",
            };
            notificationAction(info);
            this.getResume();
          })
          .catch((err) => console.log("Err", err));
      }
      this.props.onStateChangeAction({
        dialogResumeUpload: "",
        isPDFFileUploaded: false,
        uploadPDFButtonDisable: true,
        PDFFileName: this.props.labels.iproResumeFileFormate,
        dialogResumeEdit: this.props.labels.resumeImportResumeParsingInfo,
      });
    });
  };

  handleResumeUploadNoClick = () => {
    this.props.onStateChangeAction({ dialogResumeUpload: "" });
  };

  handleResumeEditYesClick = () => {
    this.props.onStateChangeAction({
      dialogResumeEdit: "",
    });
    this.props.history.push(privateRoutes.resumeEdit.path);
  };

  render() {
    const {
      isResumeImportCollapsed,
      fetchingResumes,
      selectedResume,
      showLinkedInImportView,
      uploadPDFButtonDisable,
      detailCollapsed,
      importCollapsed,
      dialogMessage,
      dialogResumeUpload,
      dialogResumeEdit,
    } = this.props;
    const { labels, history, imgSrc, getImgSrc, isHelpActive } = this.props;
    return (
      <PageWrapper className="resume-page">
        {dialogResumeEdit && (
          <ConfirmDialog testId="resume-import-confirm-diloag">
            <ConfirmDialog.Message>{dialogResumeEdit}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="resume-import-confirm-yes"
                onClick={this.handleResumeEditYesClick}
              >
                {labels.presentDeleteCurtainYESBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="resume-import-confirm-no"
                onClick={() =>
                  this.props.onStateChangeAction({
                    dialogResumeEdit: "",
                  })
                }
              >
                {labels.presentDeleteCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}

        {dialogMessage && (
          <ConfirmDialog testId="resume-import-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="resume-import-confirm-yes"
                onClick={this.handleYesClick}
              >
                {labels.presentDeleteCurtainYESBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="resume-import-confirm-no"
                onClick={this.handleNoClick}
              >
                {labels.presentDeleteCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}

        {dialogResumeUpload && (
          <ConfirmDialog testId="resume-import-confirm-diloag">
            <ConfirmDialog.Message>{dialogResumeUpload}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="resume-import-confirm-yes"
                onClick={this.handleResumeUploadYesClick}
              >
                {labels.presentDeleteCurtainYESBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="resume-import-confirm-no"
                onClick={this.handleResumeUploadNoClick}
              >
                {labels.presentDeleteCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}

        <Column collapse={detailCollapsed} className="col-1">
          <Column.Collapsed
            tooltipButton={labels.ToolTipResumeListExpand}
            text={labels.ViewTitleResumeDetails}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.HelpTooltipResumeListExpand}
            onClick={() =>
              this.props.onStateChangeAction({ detailCollapsed: false })
            }
            testId="resumeImportListExpandBtn"
            testIdHelp="resumeImportListExpandHlpBtn"
          />
          <Column.Head>
            <div className="heading">
              {labels.ResumeHeading}
              </div>
            <Button
              onClick={() => history.push(privateRoutes.resumeEdit.path)}
              className="EditBtn"
              tooltipButton={labels.TooltipResumeEdit}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              tooltipHelp={labels.TooltipResumeEdit}
              testId="resumeImportListEditBtn"
              testIdHelp="resumeImportListEditHlpBtn"
            />
            <Button
              onClick={() =>
                this.props.onStateChangeAction({ detailCollapsed: true })
              }
              className="collapseBtn"
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={labels.ToolTipResumeListCollaspe}
              tooltipHelp={labels.HelpTooltipResumeListCollaspe}
              testId="resumeImportListCollapseBtn"
              testIdHelp="resumeImportListCollapseHlpBtn"
            />
          </Column.Head>
          <Column.Body>
            {fetchingResumes ? (
              <LoadingMaskRow />
            ) : (
              <ResumeDetail resume={selectedResume} />
            )}
          </Column.Body>
        </Column>

        {!showLinkedInImportView && (
          <Column collapse={isResumeImportCollapsed} className="col-2">
            <Column.Collapsed
              tooltipButton={labels.ToolTipResumeImportExpand}
              text={labels.ViewTitleResumeImportDetail}
              onClick={() =>
                this.props.onStateChangeAction({
                  isResumeImportCollapsed: false,
                })
              }
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipResumeImportExpand}
              testId="resumeImportDetailExpandBtn"
              testIdHelp="resumeImportListExpandHlpBtn"
            />
            <Column.Head>
              <div className="heading"> {labels?.ViewTitleResumeImportDetail} </div>
              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({
                    isResumeImportCollapsed: true,
                  })
                }
                tooltipButton={labels.ToolTipResumeImportCollaspe}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipResumeImportCollaspe}
                testId="resumeImportCollaspeBtn"
                testIdHelp="resumeImportCollaspeHlpBtn"
              />
            </Column.Head>
            <Column.Body className="flex">
              <EmptyInfo
                child={
                  <Button
                    className="importBtn"
                    testId="resumeImportBtn"
                    testIdHelp="resumeImportHlpBtn"
                    tooltipButton={labels.resumeImportImportFromLinkedIn}
                    onClick={() =>
                      this.props.onStateChangeAction({
                        showLinkedInImportView: true,
                      })
                    }
                  >
                    {labels.resumeImportImportFromLinkedIn}
                  </Button>
                }
              >
                {labels.Freelance_Resume}
              </EmptyInfo>
            </Column.Body>
          </Column>
        )}

        {showLinkedInImportView && (
          <Column collapse={importCollapsed} className="col-2">
            <Column.Collapsed
              tooltipButton={labels.ToolTipResumeImportUploadExpand}
              text={labels.ViewTitleResumeImportUpload}
              onClick={() =>
                this.props.onStateChangeAction({ importCollapsed: false })
              }
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipResumeImportUploadExpand}
              testId="resumeImportUploadExpandBtn"
              testIdHelp="resumeImportUploadExpandHlpBtn"
            />
            <Column.Head>
              <div className="heading">
                {labels.resumeImportImportFromLinkedIn}
              </div>
              <Button
                className="SaveCloudBtn"
                onClick={this.handleUploadPDFResume}
                tooltipButton={labels.ToolTipResumeSave}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.ToolTipResumeSave}
                disabled={uploadPDFButtonDisable}
                testId="resumeImportUploadSaveBtn"
                testIdHelp="resumeImportUploadSaveHlpBtn"
              />
              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ importCollapsed: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={labels.ToolTipResumeImportUploadCollaspe}
                tooltipHelp={labels.HelpTooltipResumeImportUploadCollaspe}
                testId="resumeImportUploadCollapseBtn"
                testIdHelp="resumeImportUploadCollapseHlpBtn"
              />
            </Column.Head>
            <Column.Body className="import-resume-body">
              <div className="center-content">
                <div className="companyBigIcon">
                  <div className="dashItemImg">
                    <img
                      className="roundeImg"
                      src={phillipImg}
                      alt="phillip"
                      test-data-id="resumeImportPhillipImg"
                    />
                  </div>
                </div>
                <div className="resumeImportText">
                  <b>{labels.iProOnboardingResumeUploadTitle}</b>
                  <br />
                  {htmlParser(labels.iProOnboardingResumeUploadMessage)}
                </div>
              </div>

              <DragPDF
                getImgSrc={getImgSrc}
                imgSrc={imgSrc}
                labels={labels}
                getPDF={this.getPDFResumeCallback}
                test-data-id="resumeImportDropZone"
              />
            </Column.Body>
          </Column>
        )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, resume }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, ...resume };
};
export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(Resume);
