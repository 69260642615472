import React, { Component } from "react";
import { Panel, ListGroup, Input, Select } from "../../../../common";
import AsyncSelect from "react-select/lib/Async";
import { map, isEmpty } from "lodash";
import { RichTextEditor } from "../../../../components";
import { getIndustriesApi, getRolesApi } from "../resumeEditApi";
import moment from "moment";
import isNullOrUndefined from "util";

let yearsList = [];

class ProfessionalExperience extends Component {
  state = {};
  componentDidMount() {
    this.handleYearsLoad();
  }
  handleYearsLoad = () => {
    const currentYear = new Date().getFullYear();
    var startYear = 1970;
    var newYearsList = [];
    while (startYear <= currentYear) {
      startYear++;
      const newYear = {
        value: startYear,
        label: startYear,
      };
      newYearsList.push(newYear);
    }
    yearsList = newYearsList;
  };
  MonthsList = [
    { value: "0", label: "January" },
    { value: "1", label: "Feburary" },
    { value: "2", label: "March" },
    { value: "3", label: "April" },
    { value: "4", label: "May" },
    { value: "5", label: "June" },
    { value: "6", label: "July" },
    { value: "7", label: "August" },
    { value: "8", label: "September" },
    { value: "9", label: "October" },
    { value: "10", label: "November" },
    { value: "11", label: "December" },
  ];
  handleIndustriesLoad = (searchKey, callback) => {
    if (isEmpty(searchKey)) {
      const { industryDefaultOptions } = this.props;
      callback(null, {
        options: map(industryDefaultOptions, (Industry) => {
          return {
            ...Industry,
            label: Industry.IndustryValue,
            value: Industry.IndustryId,
          };
        }),
      });
      return;
    }
    if (searchKey.length < 3) {
      callback([]);
      return;
    }
    getIndustriesApi({ searchKey }).then((response) => {
      if (response.success) {
        callback(null, {
          options: map(response.items, (Industry) => {
            return {
              ...Industry,
              label: Industry.IndustryValue,
              value: Industry.IndustryId,
            };
          }),
        });
      }
    });
  };
  handleRolesLoad = (searchKey, callback) => {
    if (isEmpty(searchKey)) {
      const { roleDefaultOptions } = this.props;
      callback(null, {
        options: map(roleDefaultOptions, (profile) => {
          return {
            ...profile,
            label: profile.ProfileValue,
            value: profile.ProfileId,
          };
        }),
      });
      return;
    }
    if (searchKey.length < 3) {
      callback([]);
      return;
    }
    getRolesApi({ searchKey }).then((response) => {
      if (response.success) {
        callback(null, {
          options: map(response.items, (profile) => {
            return {
              ...profile,
              label: profile.ProfileValue,
              value: profile.ProfileId,
            };
          }),
        });
      }
    });
  };
  render() {
    const {
      isHelpActive,
      labels,
      experiencesList,
      onExpierenceCompanyNameChange,
      onExpierenceIndustryNameChange,
      onExpierenceRoleNameChange,
      onExpierenceDescriptionChange,
      onExpierenceFromMonthChange,
      onExpierenceFromYearChange,
      onExpierenceToMonthChange,
      onExpierenceToYearChange,
      onExperienceDelete,
      onExperienceAdd,
      onExpierenceIndustryBlur,
      onExperienceRoleBlur,
      onIsWorkingInputChange,
    } = this.props;

    console.log(experiencesList);

    return (
      <Panel className="professional-experience-component">
        <Panel.Heading>
          <Panel.Title>
            {labels.RESUME_EDIT_EXPERIENCE_SECTION_TITLE}
          </Panel.Title>
          <Panel.Add
            onClick={onExperienceAdd}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={labels.RESUME_EDIT_EXPERIENCE_ADD_BUTTON_TOOLTIP}
            tooltipHelp={labels.RESUME_EDIT_EXPERIENCE_ADD_BUTTON_HELP}
            testId="resume-edit-experience-add-btn"
            testIdHelp="resume-edit-experience-add-help-btn"
          />
        </Panel.Heading>
        <Panel.Body>
          {experiencesList.map((item) => (
            <ListGroup key={item.uniqueId}>
              <ListGroup.Item>
                <ListGroup.Column>
                  <input
                    type="text"
                    name="Title"
                    className={`input-text ${item.isInvalid ? "inValid" : ""}`}
                    value={item.CompanyWorked}
                    placeholder={
                      labels.RESUME_EDIT_EXPERIENCE_COMPANY_NAME_PLACEHOLDER
                    }
                    onChange={(e) =>
                      onExpierenceCompanyNameChange(item.uniqueId, e)
                    }
                    onBlur={(e) =>
                      onExpierenceCompanyNameChange(item.uniqueId, e)
                    }
                    test-data-id="resume-edit-experience-comapany-name-input"
                  />
                </ListGroup.Column>
                <ListGroup.Column size={0}>
                  <div
                    // disabled={isDisabled}
                    onClick={() => onExperienceDelete(item)}
                    className="close_btn_purple"
                  >
                    <i className="fa fa-times-circle" />
                  </div>
                </ListGroup.Column>
              </ListGroup.Item>
              <ListGroup.Item>
                <ListGroup.Column>
                  <AsyncSelect
                    className={`select-experience-industry select-input ${
                      item.isIndustryInvalid ? "inValid" : ""
                    }`}
                    placeholder={labels.RESUME_EDIT_INDUSTRY_NAME_PLACEHOLDER}
                    cacheOptions
                    defaultOptions
                    searchable={true}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    loadOptions={this.handleIndustriesLoad}
                    onChange={(options) =>
                      onExpierenceIndustryNameChange(options, item.uniqueId)
                    }
                    value={{
                      value: item.IndustryId,
                      label: item.IndustryValue,
                    }}
                    onBlur={() =>
                      onExpierenceIndustryBlur(item.IndustryId, item.uniqueId)
                    }
                  />
                </ListGroup.Column>
                <ListGroup.Column>
                  <AsyncSelect
                    className={`select-experience-role select-input ${
                      item.isRoleInvalid ? "inValid" : ""
                    }`}
                    placeholder={labels.RESUME_EDIT_ROLE_NAME_PLACEHOLDER}
                    cacheOptions
                    defaultOptions
                    searchable={true}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    loadOptions={this.handleRolesLoad}
                    onChange={(options) =>
                      onExpierenceRoleNameChange(options, item.uniqueId)
                    }
                    value={{
                      value: item.ProfileId,
                      label: item.ProfileValue,
                    }}
                    onBlur={() =>
                      onExperienceRoleBlur(item.ProfileId, item.uniqueId)
                    }
                  />
                </ListGroup.Column>
              </ListGroup.Item>
              <ListGroup.Item className="select-experience-item">
                <div className="experience-row">
                  <ListGroup.Column className="to-column">
                    <div className="experience-to-label">
                      {labels.RESUME_EDIT_FROM_LABEL}
                    </div>
                  </ListGroup.Column>
                  <ListGroup.Column>
                    <Select
                      name={"Month"}
                      className={`select-experience-from-month select-input`}
                      value={
                        moment(item.StartDate, "MM/DD/YYYY").format("M") - 1 ||
                        moment(new Date()).format("M")
                      }
                      onChange={(options) =>
                        onExpierenceFromMonthChange(options, item.uniqueId)
                      }
                      options={this.MonthsList}
                    />
                  </ListGroup.Column>
                  <ListGroup.Column>
                    <Select
                      name={"Yeara"}
                      className={`select-experience-from-year select-input`}
                      value={moment(item.StartDate, "MM/DD/YYYY").format(
                        "YYYY"
                      )}
                      onChange={(options) =>
                        onExpierenceFromYearChange(options, item.uniqueId)
                      }
                      options={yearsList}
                    />
                  </ListGroup.Column>
                </div>
                <div className="experience-row">
                  <ListGroup.Column className="to-column">
                    <div className="experience-to-label">
                      {labels.RESUME_EDIT_TO_LABEL}
                    </div>
                  </ListGroup.Column>
                  <ListGroup.Column>
                    <Select
                      name={"Month"}
                      className={`select-experience-to-month select-input`}
                      value={
                        moment(item.EndDate, "MM/DD/YYYY").format("M") - 1 ||
                        moment(new Date()).format("M")
                      }
                      onChange={(options) =>
                        onExpierenceToMonthChange(options, item.uniqueId)
                      }
                      options={this.MonthsList}
                      disabled={item.isWorking}
                    />
                  </ListGroup.Column>
                  <ListGroup.Column>
                    <Select
                      name={"Year"}
                      className={`select-experience-to-month select-input`}
                      value={
                        moment(item.EndDate, "MM/DD/YYYY").format("YYYY") ||
                        moment(new Date()).format("YYYY")
                      }
                      onChange={(options) =>
                        onExpierenceToYearChange(options, item.uniqueId)
                      }
                      options={yearsList}
                      disabled={item.isWorking}
                    />
                  </ListGroup.Column>
                  <ListGroup.Column className="is-working">
                    <div className="experienceCheckbox">
                      <label className="experienceCheckboxLabel">
                        <input
                          type="checkbox"
                          name="IsDefaultCompany"
                          className={
                            item.isWorking ? "checked" : "input-checkbox"
                          }
                          value={item.isWorking}
                          checked={item.isWorking ? item.isWorking : false}
                          onChange={(e) =>
                            onIsWorkingInputChange(item.uniqueId, e)
                          }
                          test-data-id="resume-edit-experience-isworking-input-checkbox"
                        />
                        {labels.RESUME_EDIT_WORKING_LABEL}
                      </label>
                    </div>
                  </ListGroup.Column>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <RichTextEditor
                  id="resume-edit-experience-description"
                  name="Description"
                  value={item.Description || ""}
                  handleChange={(e) =>
                    onExpierenceDescriptionChange(item.uniqueId, e)
                  }
                  placeholder={labels.RESUME_EDIT_EXPERIENCE_DESCRIPTION_LABEL}
                />
              </ListGroup.Item>
            </ListGroup>
          ))}
        </Panel.Body>
      </Panel>
    );
  }
}

export default ProfessionalExperience;
