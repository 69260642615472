import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import classes from "./styles.module.scss";
import { Button, Input } from "antd";

function CButton({
	title = "Submit",
	type = "primary",
	size="default",
	borders=true,
	whiteBackground=false,
	onclick,
	className = "",
	style={},
	...props
}) {
	return (
		<Button
			type={type}
			size={size}
			style={style}
			onClick ={onclick}
			className={`${classes.CButton} ${classes[`CButton_${size}`]} ${!borders?classes.noBorder:''} ${whiteBackground?classes.whiteBackground:''} ${className} `}
			{...props}
		>
			{title}
		</Button>
	);
}

export default CButton;
