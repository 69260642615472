import axios from "axios";
import { ApiUrl } from "../../api";

export const getSearcherPhillipInfoApi = ({ keywords }) => {
  return axios
    .post(ApiUrl.Dashboard.GetSearcherPhillipInfo, { keywords })
    .then(({ data }) => data);
};

export const updatePhillipPopupStatusApi = ({ isFreelancer }) => {
  return axios
    .put(ApiUrl.Dashboard.UpdatePhillipPopupStatus({ isFreelancer }))
    .then(({ data }) => data);
};

export const getSearcherPhillipSocialMediaStatusApi = () => {
  return axios
    .get(ApiUrl.Dashboard.GetSearcherPhillipSocialMediaStatus)
    .then(({ data }) => data);
};

export const saveResumeSearchApi = params => {
  return axios
    .post(ApiUrl.Dashboard.SaveResumeSearch, params)
    .then(({ data }) => data);
};

export const resumeSearchesApi = params => {
  return axios
    .post(ApiUrl.Dashboard.ResumeSearches, params)
    .then(({ data }) => data);
};

export const addUserPhoneNumberApi = ({ UserId, UserPhonenumberValue }) => {
  return axios
    .post(ApiUrl.UserPhonenumbers.Post, {
      UserId,
      UserPhonenumberValue
    })
    .then(({ data }) => data);
};

export const connectSocialMedia = ({ user }) => {
  return axios
    .post(ApiUrl.Dashboard.SocialMedia.Connect, user)
    .then(({ data }) => data)
    .catch(response => response);
};

export const disconnectSocialMedia = ({ socialMediaType }) => {
  return axios
    .delete(ApiUrl.Dashboard.SocialMedia.Disconnect, {
      data: socialMediaType
    })
    .then(({ data }) => data)
    .catch(response => response);
};

export const uploadLinkedinPDF = fileData => {
  return axios
    .post(ApiUrl.Resume.GetMyPDFResume, {
      fileData
    })
    .then(({ data }) => data);
};

export const uploadResumePDF = fileData => {
  return axios
    .post(ApiUrl.Resume.GetMyPDFResume, {
      fileData
    })
    .then(({ data }) => data);
};

export const saveParsedResume = parsedData => {
  return axios
    .post("Resumes/SaveParsedResume", {
      parsedData
    })
    .then(({ data }) => data);
};

export const updateResumeAvailabilityApi = info => {
  return axios.put("Resumes/UpdateResumeAvailability", info).then(data => data);
};

export const getLoginFeaturesApi = () => {
  return axios.get("Account/GetLoginFeatures").then(({ data }) => data);
};
