import { ActionTypes } from "../../../actions";

export const onStateChangeAction = ({
  listCollapsed,
  formCollapsed,
  users,
  messageForm,
  isSearchFocus,
  selectedUsers,
  isFetching,
  filterUsers,
  createMessageCollapsed
}) => dispatch => {
  listCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_NEW.LIST_COLLAPSED,
      payload: listCollapsed
    });
  formCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_NEW.FORM_COLLAPSED,
      payload: formCollapsed
    });
  users !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_NEW.USERS,
      payload: users
    });
  messageForm !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_NEW.MESSAGE_FORM,
      payload: messageForm
    });
  isSearchFocus !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_NEW.IS_SEARCH_FOCUS,
      payload: isSearchFocus
    });
  selectedUsers !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_NEW.SELECTED_USERS,
      payload: selectedUsers
    });
  isFetching !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_NEW.IS_FETCHING,
      payload: isFetching
    });
  filterUsers !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_NEW.FILTER_USERS,
      payload: filterUsers
    });
  createMessageCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_NEW.CREATE_MESSAGE_COLLAPSED,
      payload: createMessageCollapsed
    });
};
