import React from "react";

const USERFLOWANDFLOWGROUP = "USERFLOWANDFLOWGROUP";

export default function UserFlowAndFlowGroups(props) {
  return (
    <React.Fragment>
      <div className="tictell-list">
        {props.flowOrFlowGroups &&
          props.flowOrFlowGroups.length > 0 &&
          props.flowOrFlowGroups.map((item) => (
            <div
              key={item.Id}
              test-data-id={`user_flows_groups_list${
                item.isActive ? "activeItem" : ""
              }`}
              className={`tictell-list-item-container  ${
                item.isSelected ? "selected-item" : ""
              } ${item.isActive ? "activeItem" : ""}`}
              onClick={() => props.handleItemClick(USERFLOWANDFLOWGROUP, item)}
            >
              <span
                className={`${item.IsGroup ? "flowgroup-icon" : "flow-icon"}`}
              />
              <div className="name-container flow-container">
                <label test-data-id={`collabs-list-owner-username`}>
                  {item.Title}
                </label>
                <label test-data-id={`collabs-list-owner-title`}>
                  {item.Description}
                </label>
              </div>
            </div>
          ))}
      </div>
    </React.Fragment>
  );
}
