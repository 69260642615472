import React from "react";
import classes from "./style.module.scss";
import { Typography } from "antd";
import MultiLineContent from "../../utils/MultiLineContent";
const { Title, Text } = Typography;

function FeatureCard({
  heading,
  image = "",
  description,
  isActive = false,
  type = "vertical",
  handleCardClick,
}) {
  if (type === "horizontal") {
    return (
      <div
        className={`${classes.featureCard} ${classes.featureCardHorizontal} ${
          isActive ? classes.activeHorizontal : ""
        }`}
        onClick={handleCardClick}
      >
        <img className={classes.logo} src={image} width={60} height={60} />
        <div>
          <Title className={classes.heading}>
            <MultiLineContent text={heading} />
          </Title>
          <Text className={classes.description}>
            <MultiLineContent text={description} />
          </Text>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`${classes.featureCard} ${isActive ? classes.active : ""}`}
      onClick={handleCardClick}
    >
      <img className={classes.logo} src={image} width={80} height={80} />
      <Title className={classes.heading}>
        <MultiLineContent text={heading} />
      </Title>
      <Text className={classes.description}>{description}</Text>
    </div>
  );
}

export default FeatureCard;
