import React from "react";
import "./textarea.scss";
const Textarea = ({
  name,
  className = "",
  value,
  testId,
  onChange,
  rows,
  placeholder,
  autoComplete = "off",
  ...rest
}) => (
  <textarea
    name={name}
    className={`textarea-field ${className}`}
    rows={rows}
    placeholder={placeholder}
    value={value || ""}
    onChange={onChange}
    autoComplete={autoComplete}
    test-data-id={testId}
    {...rest}
  />
);

export default Textarea;
