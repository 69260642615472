import axios from "axios";
import { ApiUrl } from "../../api";

export const getNavigationMenusApi = ({ appTypeId, featureTypeId }) => {
  return axios
    .get(ApiUrl.SideNavigation.GetUserFeatures({ appTypeId, featureTypeId }))
    .then(({ data }) => data);
};

export const getNavigationMenusForVisitorApi = ({
  appTypeId,
  featureTypeId,
  isVisitor
}) => {
  return axios
    .get(
      ApiUrl.SideNavigation.GetUserFeaturesForVisitor({
        appTypeId,
        featureTypeId,
        isVisitor
      })
    )
    .then(({ data }) => data);
};

export const changeProfileApi = ({ IsFreelancer }) => {
  return axios
    .get(ApiUrl.User.ChangeProfile({ IsFreelancer }))
    .then(({ data }) => data);
};

export const getUserApi = () => {
  return axios.get(ApiUrl.User.GetUser).then(({ data }) => data);
};

export const logoutApi = () => {
  return axios.get(ApiUrl.Account.Logout).then(({ data }) => data);
};

export const getIporOpportunityCountsApi = () => {
  return axios
    .get(ApiUrl.User.GetIporOpportunityCounts)
    .then(({ data }) => data);
};

export const getMessageCountApi = () => {
  return axios
    .get(ApiUrl.SideNavigation.GetMessageCount)
    .then(({ data }) => data);
};

export const getCollaborationCountApi = () => {
  return axios
    .get(ApiUrl.SideNavigation.GetCollaborationCount)
    .then(({ data }) => data);
};

export const getIProCollaborationCountApi = () => {
  return axios
    .get(ApiUrl.SideNavigation.GetIProCollaborationCount)
    .then(({ data }) => {
      return data;
    });
};

export const getOpportunityCountApi = ({ IsFreelancer }) => {
  return axios
    .get(ApiUrl.SideNavigation.GetOpportunityCount({ IsFreelancer }))
    .then(({ data }) => data);
};
