import React, { Component, Fragment } from "react";
import Loader from "../../assets/images/loader.gif";

class AppLoader extends Component {
  render() {
    return (
      <Fragment>
        <div className="overlay" />
        <div className="LoadingMsg">
          <img className="loaderImage" src={Loader} alt="loader" />
        </div>
      </Fragment>
    );
  }
}

export default AppLoader;
