import React from "react";
import "./resumeList.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Checkbox, Col, Dropdown, Grid, Row, Space } from "antd";
import { EmptyInfo, LoadingMaskRow } from "..";
import { EnvironmentOutlined } from "@ant-design/icons";
import BookmarkColor from "../../assets/images/saved.png";
import BookMarkBlack from "../../assets/images/saved-black.png";
import { join } from "lodash";
import { Tooltip } from "../../common";
const { useBreakpoint } = Grid;

const ResumeListComponent = ({
  resumeList,
  score,
  state,
  fetchMoreResumes,
  selected,
  setSelected,
  setShowModal,
  shortListDropdown,
  opportunityDropdown,
  label,
  name,
}) => {
  const screens = useBreakpoint();
  const renderValue = (resume) => {
    const ProfileValue = resume.map((item) => item.ProfileValue);
    return join(ProfileValue, ", ");
  };
  return (
    <div className="column-secend-main">
      {resumeList?.length > 0 && (
        <InfiniteScroll
          dataLength={resumeList?.length}
          hasMore={state?.hasMore}
          next={fetchMoreResumes}
          loadMore={fetchMoreResumes}
          loader={<LoadingMaskRow />}
          scrollThreshold="100px"
          style={{
            width: "100%",
          }}
          height={"100%"}
        >
          {resumeList?.map((single, index) => (
            <Col xs={24} key={index}>
              <Row
                wrap={false}
                style={{
                  width: "100%",
                  margin: "10px 0px",
                  alignItems: "center",
                }}
              >
                <Col flex="20px" className="column-checkbox">
                  <Checkbox
                    value={single?.ResumeId}
                    checked={selected?.checked?.includes(single?.ResumeId)}
                    onChange={(e) => {
                      if (e.target.checked == true) {
                        setSelected((st) => ({
                          ...st,
                          checked: [...st.checked, e.target.value],
                        }));
                      } else {
                        const filter = selected?.checked?.filter(
                          (x) => x != e.target.value
                        );
                        setSelected((st) => ({
                          ...st,
                          checked: filter,
                        }));
                      }
                    }}
                    style={{
                      fontSize: "large",
                    }}
                  />
                </Col>
                <Col flex="auto">
                  <div className="column-resume-list">
                    <Row
                      gutter={[8, 8]}
                      className="align-items-center justify-content-between"
                    >
                      <Col
                        xs={{ order: 1, span: 24 }}
                        lg={{ span: 16 }}
                        md={{ span: 12 }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <p
                            className="list-item-title"
                            onClick={() => {
                              setShowModal((st) => ({
                                ...st,
                                data: single,
                                show: true,
                              }));
                            }}
                          >
                            {renderValue(single?.Profiles)}
                          </p>
                          <div className="d-md-none d-sm-block">
                            <Checkbox
                              value={single?.ResumeId}
                              checked={selected?.checked?.includes(
                                single?.ResumeId
                              )}
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  setSelected((st) => ({
                                    ...st,
                                    checked: [...st.checked, e.target.value],
                                  }));
                                } else {
                                  const filter = selected?.checked?.filter(
                                    (x) => x != e.target.value
                                  );
                                  setSelected((st) => ({
                                    ...st,
                                    checked: filter,
                                  }));
                                }
                              }}
                              style={{
                                fontSize: "large",
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col
                        xs={{ order: 4 }}
                        md={{ span: 12, order: 2 }}
                        lg={{ span: 8, order: 2 }}
                        className="dropdown-menus"
                      >
                        <Space size={[8, 8]}>
                          {name !== "shortlist" && (
                            <Tooltip followCursor={true} content={"Create shortlist"}>
                              <div>
                                <Dropdown
                                  overlay={shortListDropdown(single)}
                                  trigger={["click"]}
                                >
                                  <button
                                    style={{
                                      background: "#F0EEFF",
                                      borderRadius: "8px",
                                    }}
                                    className="button-top"
                                  >
                                    <img
                                      src={
                                        selected?.checked?.includes(
                                          single?.ResumeId
                                        )
                                          ? BookmarkColor
                                          : BookMarkBlack
                                      }
                                      alt=""
                                    />
                                  </button>
                                </Dropdown>
                              </div>
                            </Tooltip>
                          )}
                          <Dropdown
                            overlay={opportunityDropdown(single)}
                            trigger={["click"]}
                            className="dropdown-opportunity"
                          >
                            <button className="button-top ">
                              {screens?.xs ? "Invite" : "Invite to opportunity"}
                            </button>
                          </Dropdown>
                        </Space>
                      </Col>
                      <Col
                        xs={{ order: 2 }}
                        md={{ order: 3, span: 10 }}
                        onClick={() => {
                          setShowModal((st) => ({
                            ...st,
                            data: single,
                            show: true,
                          }));
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <EnvironmentOutlined
                            size={"large"}
                            style={{
                              fontSize: "large",
                            }}
                          />
                          <p
                            className="location-"
                            style={{
                              marginLeft: "12px",
                            }}
                          >
                            {single?.Region}
                          </p>
                        </div>
                      </Col>
                      {name != "shortlist" && (
                        <Col
                          xs={{ order: 3 }}
                          md={{ order: 4, span: 14 }}
                          onClick={() => {
                            setShowModal((st) => ({
                              ...st,
                              data: single,
                              show: true,
                            }));
                          }}
                        >
                          <div>
                            <span
                              className="location-"
                              style={{
                                borderBottom: "2px solid #8F82F5",
                                whiteSpace: "break-spaces",
                              }}
                            >
                              <span
                                style={{
                                  color: "#8F82F5",
                                  fontWeight: 600,
                                }}
                              >
                                {single?.CorrelationScore}
                              </span>{" "}
                              <span>out of</span>{" "}
                              <span
                                style={{
                                  color: "#8F82F5",
                                  fontWeight: 600,
                                }}
                              >
                                {score}
                              </span>{" "}
                              <span>score</span>
                            </span>
                          </div>
                        </Col>
                      )}
                    </Row>

                    <Row
                      className="mt-3"
                      gutter={[8, 8]}
                      onClick={() => {
                        setShowModal((st) => ({
                          ...st,
                          data: single,
                          show: true,
                        }));
                      }}
                    >
                      <Col xs={24} md={22} className="row-role">
                        {single?.Skills?.slice(0, 3)?.map((row, index) => (
                          <div key={index} className="role-btn">
                            <button className="btn-action w-100">
                              {row?.SkillValue}
                            </button>
                          </div>
                        ))}
                        {single?.Skills?.length > 3 && (
                          <button className="btn-more">
                            {`+ ${single?.Skills?.length - 3}`}
                          </button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          ))}
        </InfiniteScroll>
      )}
      {resumeList?.length == 0 && (
        <EmptyInfo>{label || "No Resume found"}</EmptyInfo>
      )}
    </div>
  );
};

export default ResumeListComponent;
