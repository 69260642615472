import React from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import {
  CalendarOutlined,
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { onStateChangeAction } from "../../searchAction";
import { notificationAction } from "../../../../actions";
import closeIcon from '../../../../assets/images/Icons/close-new.png'
import CommonSelect from "../../../../common/selectFilter/selectFilter";
import '../../newDesignSearch.scss'
const { Text, Title } = Typography
const MobileFilter = ({
  isModalOpen,
  setIsModalOpen,
  filter,
  handleChange,
  handleSearch,
  globalState,
  loading,
  setCreateSaveSearchModal,
  labels,
  state,
  handleExistingSaveSearchClick,
  handleClear,
}) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const saveSearchDropdown = (resume) => {
    return (
      <Menu
        style={{
          height: "300px",
          overflow: "auto",
          width: "290px",
        }}
        className="dropdown-shortlists"
      >
        <Menu.Item
          key="Newest"
          className="first-child-dropdown"
          onClick={(e) => {
            setCreateSaveSearchModal((st) => ({
              ...st,
              open: true,
              id: 0,
            }));
            setShowDropdown(false);
          }}
        >
          <span className="span-">Create New Search</span>
          <PlusOutlined />
        </Menu.Item>

        <Menu.Divider />
        {state?.saveSearch?.map((single, index) => (
          <Menu.Item
            key={index}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            onClick={() => {
              handleExistingSaveSearchClick(single?.SavedSearchId);
              setShowDropdown(!showDropdown);
            }}
          >
            {single?.SearchName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        style={{ maxHeight: "calc(100% - 32px)", overflow: "auto", height: "100%" }}
        centered
        onCancel={handleCancel}
        zIndex={100}
        footer={false}
        closable={false}
        className={`new-design-modal modal-for-save-search`}
      >
        <div className="main-modal">
          <div className="text-center header-modal" style={{ alignItems: "start" }}>
            <div className="w-100 text-clamp">
              <Title level={5} className="m-0 text-clamp flex-1">Search Filters
              </Title>

            </div>
            <div
              onClick={() => {
                handleCancel()
              }}
              className="pointer"
            >
              <img className="close-icon" src={closeIcon} alt="" />
            </div>
          </div>
          <div className="new-search-resume-main form" style={{ flex: 1, overflow: "auto" }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              borderBottom: "1px solid #F3F3F3",
            }}>
              <CommonSelect
                loading={loading?.role}
                options={filter?.roles}
                label={"Role"}
                value={globalState?.selected?.ProfileIds}
                placeholder={"Select role"}
                handleChange={handleChange}
                handleSearch={handleSearch}
                searchParams={"role"}
                changesParams={"ProfileIds"}
              />

              <CommonSelect
                options={filter?.location}
                label={"Location"}
                placeholder={"Select location"}
                value={globalState?.selected?.Countries}
                handleChange={handleChange}
                searchParams={"role"}
                changesParams={"Countries"}
                search={false}
              />

              <CommonSelect
                options={filter?.language}
                label={"Language"}
                value={globalState?.selected?.Languages}
                placeholder={"Select Language"}
                search={false}
                handleChange={handleChange}
                changesParams={"Languages"}
              />

              <CommonSelect
                options={filter?.skills}
                label={"Skill"}
                loading={loading?.skill}
                value={globalState?.selected?.SkillIds}
                placeholder={"Select skills"}
                handleChange={handleChange}
                changesParams={"SkillIds"}
                handleSearch={handleSearch}
                searchParams={"Skill"}
              />

              <CommonSelect
                options={filter?.keywords}
                label={"Keyword"}
                loading={loading?.keyword}
                value={globalState?.selected?.KeywordIds}
                placeholder={"Select keywords"}
                handleChange={handleChange}
                changesParams={"KeywordIds"}
                handleSearch={handleSearch}
                searchParams={"keyword"}
              />

              <CommonSelect
                options={filter?.industry}
                label={"Industry"}
                loading={loading?.industry}
                value={globalState?.selected?.IndusteryIds}
                placeholder={"Select industry"}
                handleChange={handleChange}
                changesParams={"IndusteryIds"}
                handleSearch={handleSearch}
                searchParams={"industry"}
              />

              <CommonSelect
                options={filter?.certification}
                label={"Certification"}
                loading={loading?.certificate}
                value={globalState?.selected?.CertificationIds}
                placeholder={"Select certifications"}
                handleChange={handleChange}
                changesParams={"CertificationIds"}
                handleSearch={handleSearch}
                searchParams={"certificate"}
              />

              <Text className="label m-0">Availability Date</Text>
              <div className="animated">
                <DatePicker
                  style={{ width: "100%", marginTop: "6px" }}
                  value={globalState?.selected?.AvailabilityDate}
                  onChange={(e) => handleChange(e, "AvailabilityDate")}
                  bordered={false}
                />
              </div>
            </div>
          </div>
          <Row className="" style={{
            padding: "20px", borderTop: "1px solid #F3F3F3",
          }}>
            <Space
              size={[12, 8]}
              style={{
                margin: "auto",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              <button
                className="button-last-footer w-100"
                onClick={() => {
                  handleClear();
                }}
              >
                Clear Search
              </button>
              <Dropdown
                overlay={saveSearchDropdown()}
                trigger={["click"]}
                open={showDropdown}
                onOpenChange={() => {
                  if (
                    !(
                      globalState?.selected?.IndusteryIds.length ||
                      globalState?.selected?.KeywordIds.length ||
                      globalState?.selected?.ProfileIds.length ||
                      globalState?.selected?.SkillIds.length ||
                      globalState?.selected?.CertificationIds.length ||
                      globalState?.selected?.Languages.length ||
                      globalState?.selected?.Countries.length
                    )
                  ) {
                    const info = {
                      message: labels.SEARCH_NO_CRITERIA_VALIDATION,
                      status: "error",
                    };
                    dispatch(notificationAction(info));
                    return;
                  }
                  setShowDropdown(!showDropdown);
                }}
              >
                <button className="button-footer-opportunity w-100">Save Search Filter(s)</button>
              </Dropdown>
            </Space>
          </Row>
        </div>
      </Modal>
    </>
  );
};
export default MobileFilter;
