import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { Modal, Button, Form, Alert, Select, Input } from "antd";
import classes from "./index.module.scss";
import closeIcon from '../../../../assets/images/Icons/close-new.png'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LanguageImage from '../../../../assets/images/keywork-popup.png'

function OtherAchievementPopup({
  isLoading,
  handleOtherAchievementChange,
  SaveResumeDetails,
  errorMessage,
  otherAchievementForm,
  error,
  labels,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [yearsList, setYearsList] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = () => {
    setIsModalVisible(false);
    SaveResumeDetails();
  };

  useEffect(() => {
    handleYearsLoad();
  }, []);

  const handleYearsLoad = () => {
    const currentYear = new Date().getFullYear();
    var startYear = 1970;
    var yearsList = [];
    while (startYear <= currentYear) {
      startYear++;
      const newYear = {
        value: startYear,
        label: startYear,
      };
      yearsList.push(newYear);
    }
    yearsList = yearsList.sort(function (a, b) {
      return b.value - a.value;
    });
    setYearsList(yearsList);
  };

  let TypeList = [
    { value: 1, label: "Workshop" },
    { value: 2, label: "Training" },
    { value: 3, label: "Course" },
    { value: 4, label: "Research" },
    { value: 5, label: "Volunteering" },
    { value: 6, label: "Publication" },
    { value: 7, label: "Public speaking" },
  ];

  const { AchivenmentId, Title, Year } = otherAchievementForm;

  return (
    <>
      <button className="add-profile"
        onClick={() => setIsModalVisible(true)}
      >{labels.OTHER_ACHIEVEMENTS_LBL}</button>
      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: 0 }}
        centered
        closable={false}
        footer={null}
        className={`new-design-modal ${classes.popUpForm}`}
      >
        <div className="text-center mb-3 header-modal">
          <h5>{labels.RESUME_EDIT_OTHER_ACHIEVEMENT_SECTION_TITLE}</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="images-icon">
          <img src={LanguageImage} alt="" />
          <p className="paragraph">Please select your achievement</p>
        </div>
        <Form
          name="expierience_form"
          className={`${classes.form} form`}
          onFinish={onFinish}
          size="large"
        >
          <Form.Item
            name="CompanyWorked"
            className={classes.customSelect}
            rules={[
              {
                required: Title ? false : true,
                message: labels.collTitleRequired,
              },
            ]}
          >
            <Input
              onChange={(e) =>
                handleOtherAchievementChange("Title", e.target.value)
              }
              value={Title || ""}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              rules={[
                {
                  required: Year ? false : true,
                  message: labels.SELECT_YEAR_VALIDATION_LBL,
                },
              ]}
              name="Year"
              className={`${classes.date_input} ${classes.customSelect}`}
            >
              <Select
                placeholder={labels.SELECT_YEAR_LBL}
                onChange={(e) => handleOtherAchievementChange("Year", e)}
                allowClear
                name={"Year"}
                autoComplete="new-password"
              >
                {yearsList &&
                  yearsList.map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="AchivenmentType"
              rules={[
                {
                  required: AchivenmentId ? false : true,
                  message: labels.SELECT_ACHIEVENT_TYPE_VALIDATION_LBL,
                },
              ]}
              className={`${classes.date_input} ${classes.customSelect}`}
              style={{ marginLeft: "14px" }}
            >
              <Select
                placeholder={labels.SELECT_ACHIEVENT_TYPE_VALIDATION_LBL}
                onChange={(e) =>
                  handleOtherAchievementChange("AchivenmentId", e)
                }
                allowClear
                name={"AchivenmentType"}
                autoComplete="new-password"
              >
                {TypeList &&
                  TypeList.map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form.Item>

          <ReactQuill
            value={otherAchievementForm.Description || ""}
            className={`${classes.experienceDescriptionInput}`}
            onChange={(e) => handleOtherAchievementChange("Description", e)}
            theme="snow"
          />

          {error && (
            <Alert
              message={errorMessage?.replace("<br />", "")}
              type="warning"
              closable
            />
          )}

          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-form-button mt-2"
            loading={isLoading}
          >
            {labels.Done_LBL}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default OtherAchievementPopup;
