export const appMetaData = {
	'/resume': {
		title: 'ProDoo – ProDoo: Connecting Freelancers with Freelancing Jobs Worldwide | Your Global Freelancer Hub.',
		description: 'Discover freelance opportunities in Pakistan! Join ProDoo and thrive as an independent professional. Find your dream freelancing jobs today.',
	},
	'prodoo': {
		title: 'Prodoo - Enabling independent workforce',
		description: 'Prodoo helps recruiters meet the freelancers throughout the world and get them best job offers',
	},
	'/presentations': {
		title: 'ProDoo: Connecting Freelancers with Freelancing Jobs Worldwide | Find Freelancing Jobs in Pakistan',
		description: 'Discover Freelancing Jobs in Pakistan with ProDoo - Your Gateway to Thriving as an Independent Professional. Join the Freelancer Community today!',
	},
	'/feedback': {
		title: `Connecting Freelancers with Lucrative Freelancing Jobs in Pakistan | Independent Professionals' Marketplace`,
		description: 'Discover Freelancing Jobs in Pakistan with ProDoo - Your Gateway to Thriving as an Independent Professional. Join the Freelancer Community today!',
	},
	'/about': {
		title: `ProDoo: Your Go-To Platform for Freelancing Jobs in Pakistan | Connecting Freelancers and Independent Professionals`,
		description: 'Discover Freelancing Jobs in Pakistan for Independent Professionals | Find Freelancer Jobs - Join the Independent Professional Community in Pakistan Today!',
	},
	'/next-step/home': {
		title: `Empowering Freelancers with Freelancing Jobs in Pakistan for Independent Professionals.`,
		description: 'Discover Freelancing Jobs in Pakistan for Independent Professionals. Join the community of Freelancers in Pakistan and find rewarding opportunities.',
	},

	'/tictell/home': {
		title: `ProDoo: Your Freelancers Hub for Freelancing Jobs in Pakistan with Time Tracking`,
		description: 'Empowering Independent Professionals in Pakistan with Time Tracking and freelance Jobs. Join us today.',
	},

	'/connected-networks': {
		title: `Your Gateway to Freelancing Jobs in Pakistan | Join the Connected Network of Freelancers.`,
		description: 'Connecting Independent Professionals in Pakistan | Find Freelancer Jobs - Join the Network of Independent Professionals Today.',
	},

	'/resume-search': {
		title: `ProDoo: Connecting Freelancers in Pakistan to Freelancing Jobs | Find the Best Resumes.`,
		description: 'Fine the best resumes from Independent professionals and Freelancers in Pakistan. ProDoo connects you with top talent. Find your perfect match today!',
	},

	'/saved-searches': {
		title: `Your Gateway to Freelancing Jobs in Pakistan | Connecting Freelancers in Pakistan.`,
		description: 'Discover the top Independent professionals and Freelancers in Pakistan. Find the best talent for your projects with ProDoo!',
	},

	'/shortlists': {
		title: `ProDoo: Find the Best Talent and Freelancing Jobs in Pakistan!`,
		description: 'Discover the best independent professional talent and freelancers in Pakistan with ProDoo. Connect with top Independent professionals today',
	},

	'/companies': {
		title: `ProDoo: Your Source for Freelancing Jobs in Pakistan - Find the Best Talent Today!`,
		description: 'Find the top independent professionals and freelancers in Pakistan. Find the best talent for your projects with ProDoo.',
	},

	// '/feedback': {
	// 	title: `ProDoo: Find the Best Talent for Freelancing Jobs in Pakistan.`,
	// 	description: 'Discover the top Independent Professionals and Freelancers in Pakistan. Find the best talent for your projects.',
	// },

	// '/about': {
	// 	title: `ProDoo: Your Gateway to Finding the Best Talent and Freelancing Jobs in Pakistan.`,
	// 	description: 'Discover the best talent among Independent professionals and Freelancers in Pakistan with ProDoo. Connect with top Independent professionals today.',
	// },

}
