import axios from "axios";
import { ApiUrl } from "../../../../api/apiUrls";

export const postMonitorShortlistApi = (data) => {
  return axios
    .post(ApiUrl.Monitoring.PostMonitorShortlist, data)
    .then(({ data }) => data)
    .catch(response => response);
};

export const putMonitorShortlistApi = (data) => {
  return axios
    .post(ApiUrl.Monitoring.PutMonitorShortlist, data)
    .then(({ data }) => data)
    .catch(response => response);
};

export const postMonitorOpportunityApi = (data) => {
  return axios
    .post(ApiUrl.Monitoring.PostMonitorOpportunity, data)
    .then(({ data }) => data)
    .catch(response => response);
};

export const postMonitorSavedSearchApi = (data) => {
  return axios
    .post(ApiUrl.Monitoring.PostMonitorSavedSearch, data)
    .then(({ data }) => data)
    .catch(response => response);
};

export const putMonitorOpportunityApi = (data) => {
  return axios
    .post(ApiUrl.Monitoring.PostMonitorOpportunity, data)
    .then(({ data }) => data)
    .catch(response => response);
};