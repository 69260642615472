import React, { Component } from "react";
import { getEmailConfirmationTokenApi } from "../../leadPageApi";
import { htmlParser, isValidEmail } from "../../../../utilities/helpers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificationAction } from "../../../../actions";

class ResendEmailTokenForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      invalidemail: false,
    };
  }
  handleResendToken = () => {
    const { email } = this.state;
    if (!email || !isValidEmail(email)) {
      const info = {
        message: this.props.labels.LOGIN_FIX_VALIDATION_MESSAGE,
        status: "error",
      };
      this.props.notificationAction(info);
      this.setState({
        invalidemail: true,
      });
      return;
    }
    getEmailConfirmationTokenApi(email)
      .then((response) => {
        if (response.success) {
          const info = {
            message: this.props.labels.EMAIL_TOKEN_RESEND_MESSAGE,
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: this.props.labels.EMAIL_NOT_RECOGNIZE_MESSAGE,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      })
      .catch((error) => console.log(error));
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    if (!isValidEmail(value)) {
      this.setState({
        [`invalid${name}`]: true,
        [name]: value,
      });
      return;
    } else {
      this.setState({
        [`invalid${name}`]: false,
        [name]: value,
      });
    }
  };
  render() {
    const { onClose, onSignInClick, labels } = this.props;
    const { email, invalidemail } = this.state;
    return (
      <div className="LoginForm">
        <form>
          <div className="login">
            <button className="close-btn" onClick={onClose} />
            <input
              type="text"
              name="email"
              className={`LoginInput ${
                this.state.invalidemail ? "inValid" : ""
              }`}
              placeholder="Email"
              onChange={this.handleChange}
              onBlur={this.handleChange}
              value={email}
            />
            <div className="LoginBtn">
              <input
                type="button"
                onClick={this.handleResendToken}
                className="LoginSubmit"
                value="Resend"
              />
            </div>
            {/* <label className="noAccount">
              {labels.EMAIL_ALREADY_VERIFIED_MESSAGE}
              <br />
              <br />
              <a className="SignUp" onClick={onSignInClick}>
                {labels.EMAIL_VERIFICATION_FORM_SIGN_BUTTON_TXT}
              </a>
            </label> */}
            <a className="Forgot" onClick={onSignInClick}>
              {htmlParser(labels.EMAIL_ALREADY_VERIFIED_MESSAGE)}
            </a>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;

  return { labels };
};
const mapActionToProps = { notificationAction };
export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(ResendEmailTokenForm));
