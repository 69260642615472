import React, { Component } from "react";
import { connect } from "react-redux";
import { LoadingMask } from "../../common";
import { PageWrapper } from "../../components";
import { htmlParser } from "../../utilities";
import { Footer } from "../LeadPage/components";
import { getDashboardMenusApi, getFooterMenusApi } from "../LeadPage/leadPageApi";
import "./cms.scss";
import LogoSrc from "../../assets/images/logo.png";
import WhiteLogoSrc from "../../assets/images/logoWhite.png";
import TopbarSrc from "../../assets/images/topbar.png";
import { getFooterMenuDetailApi } from "./cmsApi";
import { RESET_LANDINGPAGE_URL } from "../../utilities/enviroments";
import { StorageService } from "../../api";
class Cms extends Component {
  state = {
    isLoading: true,
    pageId: -1,
    data: {},
    footerMenu: {},
  };
  componentDidMount() {
    const { pageId } = this.props.match.params;
    getDashboardMenusApi().then((res) => {
      const items = res.items;
      const footer = items && items.find((x) => x.Name == "FooterMenus");
      this.setState({
        footerMenu: footer,
        pageId,
      });
    });
    getFooterMenuDetailApi(pageId).then((res) => {
      this.setState({
        data: res.items,
        isLoading: false,
      });
    }).catch((err) => {
      console.log({ err });
      this.onError();
    });
  }
  componentDidUpdate(prevProps) {
    if (this.state.pageId !== this.props.match.params.pageId) {
      const { isLoading } = this.state;
      if (isLoading) return;
      this.setState({
        isLoading: true,
      });
      getFooterMenuDetailApi(this.props.match.params.pageId)
        .then((res) => {
          this.setState({
            data: {
              ...res.items,
              content: res.items.content.indexOf('<p>') > -1 ? res.items.content : "<p>Content is not available for your country! It will be uploaded soon<p>"
            },
            isLoading: false,
            pageId: this.props.match.params.pageId,
          });
        })
        .catch((err) => {
          console.log({ err });
          this.onError();
        });
    }
  }
  onError = () => {
    this.setState({
      data: {
        heading: "Not Found",
        content: "<p>Content is not available for your country! It will be uploaded soon<p>",
      },
      isLoading: false,
      pageId: this.props.match.params.pageId,
    });
  }
  onBackClick = () => {
    StorageService.clearAll();
    window.location.href = RESET_LANDINGPAGE_URL;
  };
  render() {
    const { labels } = this.props;
    const { isLoading, data, footerMenu } = this.state;
    return (
      <PageWrapper className="cms-page">
        <div className="cms-page mainContent lightBg">
          {isLoading && <LoadingMask />}
          <header className="landing-header">
            <div className="TopBar">
              <img className="topBarImg" src={TopbarSrc} alt="img" />
            </div>
            <div className="Logo">
              <img className="loginScreen" src={WhiteLogoSrc} alt="logo" />
              <img className="otherScreen" src={LogoSrc} alt="logo" />
            </div>
            <div className="btnWrap">
              <a
                onClick={this.onBackClick}
                id="showLogin"
                className="loginBtnClick"
              >
                {"Home"}
              </a>
            </div>
          </header>
          {data && (
            <section className="contentContainer">
              <h1 className="proHeading">{data.heading}</h1>
              <div className="aboutPanel">
                <h6 className="subHeading">{htmlParser(data.content)}</h6>
              </div>
            </section>
          )}
          <Footer footerMenu={footerMenu} />
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(Cms);
