import axios from "axios";
import { ApiUrl } from "../../api";
export const getFaqsApi = ({ type }) => {
  return axios.get(`${ApiUrl.Faq.GetFaqs}?type=${type}`).then(({ data }) => {
    return data;
  });
};

export const getAboutProdooListApi = () => {
  return axios.get(ApiUrl.Configuration.ConfigurationList).then(({ data }) => {
    return data;
  });
};
