import axios from "axios";
import { ApiUrl } from "../../../../api/apiUrls";


export const getSearcherPhillipInfoForNewUserApi = ({ keywords }) => {
  return axios
    .post(ApiUrl.Dashboard.GetSearcherPhillipInfoForNewUser, { keywords })
    .then(({ data }) => data);
};

export const UploadAndGetRolesAndSkillsFromRandomResumeApi = (fileData) => {
  return axios
    .post("Resumes/UploadAndGetRolesAndSkillsFromRandomResume", {
      fileData,
    })
    .then(({ data }) => data);
};

export const UpdateResumeApi = (resume) => {
  return axios
    .put(ApiUrl.NextStep.UpdateResume, resume)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const GetSuggestedProfilesForNewResumeApi = (roleIds) => {
  return axios
    .post(`${ApiUrl.NextStep.GetSuggestedProfilesForNewResume}`, roleIds)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const GetSuggestedSkillsForNewResumeApi = (roleIds) => {
  return axios
    .post(ApiUrl.NextStep.GetSuggestedSkillsForNewResume,roleIds)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const GetMarketPopularityIndexApi = () => {
  return axios
    .get(ApiUrl.NextStep.GetMarketPopularityIndex)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getCurrenciesApi = () => {
  return axios.get(ApiUrl.Currency.AllCurrenciesLookup).then(({ data }) => {
    return data;
  });
};

export const GetUserExpectedSalaryApi = (countryId, currencyId, durationType) => {
  return axios
    .get(ApiUrl.NextStep.GetUserExpectedSalary(countryId, currencyId, durationType))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const GetMarketTopSalariesBasedOnUserRolesApi = (body) => {
  return axios
    .post(ApiUrl.NextStep.GetMarketTopSalariesBasedOnUserRoles, body)
    .then(({ data }) => data)
    .catch((response) => response);
};


export const GetMarketSalaryApi = (countryId, currencyId, durationType) => {
  return axios
    .get(ApiUrl.NextStep.GetMarketTopSalaries(countryId, currencyId, durationType))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addResumeProfileApi = (profileId) => {
  return axios
    .get(ApiUrl.NextStep.AddResumeProfile(profileId ))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addResumeSkillApi = (skillId) => {
  return axios
    .get(ApiUrl.NextStep.AddResumeSkill(skillId ))
    .then(({ data }) => data)
    .catch((response) => response);
};
