import React, { Component } from "react";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { isEmpty, map } from "lodash";
import "./resume-analyzer.scss";
import { withRouter } from "react-router-dom";
import SliderSrc from "../../../../assets/images/phillip/resume-analyzer.png";
import SliderSrc1 from "../../../../assets/images/phillip/resume-analyzer-1.png";
import TopbarSrc from "../../../../assets/images/topbar.png";

import facebook from "../../../../assets/images/landing-Icons/facebook.png";
import linkedin from "../../../../assets/images/landing-Icons/linkedin.png";
import twitter from "../../../../assets/images/landing-Icons/twitter.png";
import google from "../../../../assets/images/landing-Icons/google.png";

import {
  UploadAndGetRolesAndSkillsFromRandomResumeApi,
  GetSuggestedProfilesForNewResumeApi,
  GetSuggestedSkillsForNewResumeApi,
  getCurrenciesApi,
  GetMarketSalaryApi,
  GetMarketTopSalariesBasedOnUserRolesApi,
} from "./resumeAnalyzerApi";
import "react-circular-progressbar/dist/styles.css";
import ReactApexChart from "react-apexcharts";
import { htmlParser } from "../../../../utilities/helpers";
import { HashLink as Link } from "react-router-hash-link";
import WhiteLogoSrc from "../../../../assets/images/logoWhite.png";

const LOOKUPTYPES = {
  PROFILE: 1,
  SKILL: 2,
  KEYWORD: 3,
  CERTIFICATION: 4,
  COUNTRY: 5,
  LANGUAGE: 6,
  INDUSTRY: 7,
};

class ResumeAnalyzer extends Component {
  state = {
    subNav: false,
    navigation: false,
    allCurrenciesList: [],
    selectedCurrency: {},
    selectedCountry: {
      value: 46,
      label: "Pakistan",
    },
    selectedDuration: {
      value: 1,
      label: "Yearly",
    },
    top3Salaries: [],
    currentSlide: 0,
    keywords: "",
    extractedSearchSettings: {
      Profiles: [],
      Skills: [],
    },
    resumeUploaded: false,
    progress: 0,
    popularityIndex: -1,
    salary: "",
    isLoading: true,
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function () {
          },
        },
      },
      colors: ["#9500FF", "#BC2FFC", "#9500FF"],
      bar: {
        columnWidth: "55%",
        distributed: true,
      },
      dataLabels: {
        enabled: true,
        align: "left",
        textAnchor: "top",
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ["Entry", "Average", "Expert"],
        labels: {
          style: {
            colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-label",
          },
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#FFFFFF"],
            fontSize: "16px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-label",
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return value;
          },
        },
        tooltip: {
          enabled: true,
          offsetX: 0,
        },
      },
      labels: {
        style: {
          colors: ["#9500FF", "#BC2FFC", "#9500FF"],
          fontSize: "100px",
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: "#fff",
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val, opts) {
          return val;
        },
        textAnchor: "middle",
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: undefined,
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          padding: 4,
          borderRadius: 2,
          borderWidth: 0,
          borderColor: "#fff",
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      title: {
        text: "",
      },
    },
  };

  toggleSubNav = (value) => {
    this.setState({
      subNav: value === false ? value : !this.state.subNav,
    });
  };

  componentDidMount = () => {
    this.getCurrencies();
    const isSepratePage = window.location.hash.indexOf("resume-analyzer") > -1;
    this.setState({
      navigation: isSepratePage,
    });
  };

  getSuggestedRolesAndSkills = (roleIds) => {
    GetSuggestedProfilesForNewResumeApi(roleIds)
      .then((res) => {
        this.setState({
          extractedSearchSettings: {
            ...this.state.extractedSearchSettings,
            Profiles: res.items.map((a) => ({
              Id: a.CorrelatedProfiles.ProfileId,
              LookupValue: a.CorrelatedProfiles.ProfileValue,
            })),
            isLoading: false,
          },
        });
      })
      .catch(() => { });
    GetSuggestedSkillsForNewResumeApi(roleIds)
      .then((res) => {
        this.setState({
          extractedSearchSettings: {
            ...this.state.extractedSearchSettings,
            Skills: res.items.map((a) => ({
              Id: a.SkillId,
              LookupValue: a.SkillValue,
            })),
            isLoading: false,
          },
        });
      })
      .catch(() => { });
  };

  onFormSelectChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  getUserSpecficTopSalaries = (roleIds) => {
    const { selectedCountry, selectedCurrency, selectedDuration } = this.state;
    const body = {
      CountryId: selectedCountry.value,
      CurrencyId: selectedCurrency.value,
      DurationType: selectedDuration.value,
      RoleIds: roleIds,
    };
    this.setState({
      isLoading: true,
    });
    GetMarketTopSalariesBasedOnUserRolesApi(body)
      .then((res) => {
        let selectedSalary =
          res.items.Top3Salaries.length > 0 ? res.items.Top3Salaries[0] : {};
        const top3Salaries = res.items.Top3Salaries.map((a) => ({
          ...a,
          isSelected: a.Lookupvalue == selectedSalary.Lookupvalue,
        }));
        const graphData = [];
        graphData.push({
          name: `Salary in ${selectedSalary.Currency}`,
          data: [
            selectedSalary.Entry,
            selectedSalary.Average,
            selectedSalary.Senior,
          ],
        });
        this.setState({
          selectedSalary: { ...selectedSalary, isSelected: true },
          graphData,
          top3Salaries,
          isLoading: false,
        });
      })
      .catch((err) => console.log(err));
  };

  uploadLinkedinPdf = (e) => {
    var fileData = e.target.files[0];
    var types = "pdf";
    const { labels, notificationAction } = this.props;
    var fileType = fileData.name.split(".");
    this.setState({
      isLoading: true,
    });
    const me = this;
    if (types === fileType[1].toLowerCase()) {
      var reader = new FileReader();
      reader.onload = (function () {
        return function (e) {
          var fileDataInBytes = e.target.result.split(",")[1];
          me.setState({ isLoading: true });
          UploadAndGetRolesAndSkillsFromRandomResumeApi(fileDataInBytes)
            .then((response) => {
              if (response.success) {
                let {
                  Certifications = [],
                  Countries = [],
                  Industries = [],
                  Keywords = [],
                  Languages = [],
                  Profiles = [],
                  Skills = [],
                } = response.items;

                Certifications = map(Certifications, (certification) => ({
                  Id: certification.CertificationId,
                  ExpLevel: 0,
                  LookupValue: certification.CertificationValue,
                }));
                Countries = map(Countries, (country) => ({
                  Id: country.CountryId,
                  ExpLevel: 0,
                  LookupValue: country.CountryName,
                }));
                Industries = map(Industries, (industry) => ({
                  Id: industry.IndustryId,
                  ExpLevel: 0,
                  LookupValue: industry.IndustryValue,
                }));
                Keywords = map(Keywords, (keyword) => ({
                  Id: keyword.KeywordId,
                  ExpLevel: 0,
                  LookupValue: keyword.KeywordValue,
                }));
                Languages = map(Languages, (language) => ({
                  Id: language.LanguageId,
                  ExpLevel: 0,
                  LookupValue: language.LanguageValue,
                }));
                Profiles = map(Profiles, (profile) => ({
                  Id: profile.ProfileId,
                  ExpLevel: 0,
                  LookupValue: profile.ProfileValue,
                }));
                Skills = map(Skills, (skill) => ({
                  Id: skill.SkillId,
                  ExpLevel: 0,
                  LookupValue: skill.SkillValue,
                }));

                if (
                  isEmpty(Certifications) &&
                  isEmpty(Countries) &&
                  isEmpty(Industries) &&
                  isEmpty(Keywords) &&
                  isEmpty(Languages) &&
                  isEmpty(Profiles) &&
                  isEmpty(Skills)
                ) {
                  const info = {
                    message: labels.searcherOnboardingInvalidDescription,
                    status: "success",
                  };
                  notificationAction(info);
                  me.setState({
                    isLoading: false,
                  });
                  return;
                }
                const saveResumeParams = {
                  Certifications,
                  Countries,
                  Industries,
                  Keywords,
                  Languages,
                  Profiles,
                  Skills,
                };
                me.setState({
                  resumeUploaded: true,
                });
                const rolesIds = saveResumeParams.Profiles.map((x) => x.Id);
                me.getUserSpecficTopSalaries(rolesIds);
                me.getSuggestedRolesAndSkills(rolesIds);
              } else {
                this.setState({
                  isLoading: false,
                });
              }
            })
            .catch(() => {
              const info = {
                message: this.props.landindUploadErrorMessage,
                status: "error",
              };
              notificationAction(info);
              me.setState({ isLoading: false });
            });
        };
      })(fileData);

      reader.readAsDataURL(fileData);
    } else {
      const info = {
        message: this.props.landingSelectPdfMessage,
        status: "error",
      };
      notificationAction(info);
      this.setState({
        isLoading: false,
      });
    }
  };

  extractedSettingUpdate = () => {
    const info = {
      message: this.props.labels.landingSignupMessage,
      status: "info",
    };
    this.props.notificationAction(info);
    this.props.toggleSignupForm(true);
  };

  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          const selectedCurrency = allCurrenciesList[1];
          this.setState({
            allCurrenciesList: allCurrenciesList,
            selectedCurrency,
          });

          GetMarketSalaryApi(
            this.state.selectedCountry.value,
            selectedCurrency.value,
            this.state.selectedDuration.value
          )
            .then((res) => {
              let selectedSalary =
                res.items.Top3Salaries.length > 0
                  ? res.items.Top3Salaries[0]
                  : {};
              const top3Salaries = res.items.Top3Salaries.map((a) => ({
                ...a,
                isSelected: a.Lookupvalue == selectedSalary.Lookupvalue,
              }));
              const graphData = [];
              graphData.push({
                name: `Salary in ${selectedSalary.Currency}`,
                data: [
                  selectedSalary.Entry,
                  selectedSalary.Average,
                  selectedSalary.Senior,
                ],
              });
              this.setState({
                selectedSalary: { ...selectedSalary, isSelected: true },
                graphData,
                top3Salaries,
                isLoading: false,
              });
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log("Err ", err));
  }

  handleSalaryClick = (item) => {
    const graphData = [];
    graphData.push({
      name: `Salary in ${item.Currency}`,
      data: [item.Entry, item.Average, item.Senior],
    });
    this.setState({
      top3Salaries: this.state.top3Salaries.map((a) => ({
        ...a,
        isSelected: a.Lookupvalue == item.Lookupvalue,
      })),
      selectedSalary: item,
      graphData,
    });
  };

  onNavigationToggle = () => {
    this.setState({
      navigationToggle: !this.state.navigationToggle,
      subNav: !this.state.navigationToggle ? true : false,
    });
  };

  render() {
    const {
      extractedSearchSettings,
      isLoading,
      selectedSalary,
      resumeUploaded = false,
      subNav,
      aboutUsMenu,
      navigation,
      navigationToggle = false,
    } = this.state;
    const { labels } = this.props;
    const { Profiles, Skills } = extractedSearchSettings;
    const {
      whyProdoo,
      howDoesItWork,
      navigationLabel1,
      navigationSubLabel2,
      navigationSubLabel3,
      navigationSubLabel4,
      navigationLabel2,
      navigationLabel3,
      navigationLabel4,
      loaderText,
      landingWidgetNextstepSubTitle1,
      landingWidgetNextstepSubTitle2,
      uploadButtonText,
      landingWidgetNextstepSubTitle3,
    } = labels;
    return (
      !isLoading && (<div className="resume-analyzer" test-data-id="ipro-phillip-popup">
        {navigation && (
          <React.Fragment>
            <header className="head">
              <div className={`top-navigation ${subNav ? "toggled" : ""}`}>
                <div>
                  <span onClick={this.toggleSubNav}>{navigationLabel1}</span>
                  {subNav && (
                    <div className="sub-nav">
                      <Link to="/cms/162">{howDoesItWork}</Link>
                      <Link to="/cms/154">{navigationSubLabel2}</Link>
                      <Link to="/cms/152">{navigationSubLabel3}</Link>
                      <Link to="/cms/156">{navigationSubLabel4}</Link>
                    </div>
                  )}
                </div>
                <hr />
                {aboutUsMenu && (
                  <>
                    <Link smooth to="/#aboutUs">
                      {navigationLabel2}
                    </Link>
                    <hr />
                  </>
                )}
                <Link smooth to="/#blog">
                  {navigationLabel3}
                </Link>
                <hr />
                <Link smooth to="/#contactUs">
                  {navigationLabel4}
                </Link>
              </div>
              <div className="Logo">
                <img className="loginScreen" src={WhiteLogoSrc} alt="logo" />
              </div>
            </header>
            <header className="mobile-head">
              <div className="top-wrapper">
                <div className="top-row">
                  <img className="loginScreen" src={WhiteLogoSrc} alt="logo" />
                  <button
                    className="ToggleMenu"
                    onClick={this.onNavigationToggle}
                  />
                </div>
                {navigation && navigationToggle && (
                  <div className={`top-navigation ${!subNav ? "toggled" : ""}`}>
                    <div>
                      <span onClick={this.toggleSubNav}>Why ProDoo</span>
                      {subNav && (
                        <div className="sub-nav">
                          <Link to="/cms/162">{"How does it work?"}</Link>
                          <Link to="/cms/154">{"Work as an iPro"}</Link>
                          <Link to="/cms/152">{"Work as a sercher"}</Link>
                          <Link to="/cms/156">
                            {"Concepts and terminologies"}
                          </Link>
                        </div>
                      )}
                    </div>
                    {aboutUsMenu && (
                      <Link smooth to="/#aboutUs">
                        About Us
                      </Link>
                    )}
                    <Link smooth to="/#blog">
                      Blog
                    </Link>
                    <Link smooth to="/#contactUs">
                      Contact Us
                    </Link>
                  </div>
                )}
              </div>
            </header>
          </React.Fragment>
        )}

        {(
          <div className={`main-div ${subNav ? "sub-nav" : ""}`}>
            <div className="right-div">
              {!resumeUploaded && (
                <div className="first-view">
                  {" "}
                  <div className="resume-upload-row">
                    <h2 className="proHeading">
                      {labels.landingWidgetNextstepTitle1}
                    </h2>
                    <h2 className="proHeading">
                      {labels.landingWidgetNextstepTitle2}
                    </h2>
                    <h6 className="subHeading1">
                      {htmlParser(landingWidgetNextstepSubTitle1)}
                    </h6>
                    <h6 className="subHeading2">
                      {landingWidgetNextstepSubTitle2}
                    </h6>
                    <div
                      onChange={(e) =>
                        this.uploadLinkedinPdf(e, "OtherResumeSuccessMessage")
                      }
                      className="js_upload_btn"
                    >
                      {htmlParser(uploadButtonText)}
                      {/* {labels.iProOnboardingResumeOtherBtnText} */}
                      <input className="linkedInPdf" type="file" title=" " />
                    </div>
                  </div>
                </div>
              )}
              {resumeUploaded && (
                <React.Fragment>
                  <div className="first-view">
                    {" "}
                    {/* <div className="sliderImageWrap">
                    <img className="sliderImg" src={SliderSrc} alt="slider" />
                  </div> */}
                    <div className="resume-upload-row">
                      <h2 className="proHeading">
                        {labels.landingWidgetNextstepTitle1}
                      </h2>
                      <h2 className="proHeading">
                        {labels.landingWidgetNextstepTitle2}
                      </h2>
                      <h6 className="subHeading1">
                        {htmlParser(landingWidgetNextstepSubTitle3)}
                      </h6>
                    </div>
                  </div>
                  <div className="heading-div">
                    <label>{labels.landingWidgetRoleSuggestion}</label>
                  </div>
                  <div className="settings-container">
                    {Profiles &&
                      Profiles.map((item) => (
                        <label
                          test-data-id="resume-edit-suggested-role-item"
                          className="draggable"
                          onClick={() =>
                            this.extractedSettingUpdate(
                              LOOKUPTYPES.PROFILE,
                              item
                            )
                          }
                          key={item.Id}
                        >
                          {item.LookupValue}
                        </label>
                      ))}
                  </div>
                  <div className="heading-div">
                    <label>{labels.landingWidgetSkillSuggestion}</label>
                  </div>
                  <div className="settings-container">
                    {Skills &&
                      Skills.map((item) => (
                        <label
                          test-data-id="resume-edit-suggested-role-item"
                          className="draggable"
                          onClick={() =>
                            this.extractedSettingUpdate(LOOKUPTYPES.SKILL, item)
                          }
                          key={item.Id}
                        >
                          {item.LookupValue}
                        </label>
                      ))}
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="left-div">
              {!isLoading && (
                <React.Fragment>
                  {selectedSalary && (
                    <div className="mixed-chart">
                      <React.Fragment>
                        <div className="top-salaries">
                          {this.state.top3Salaries.map((item) => (
                            <div
                              className={`salary-item ${item.isSelected ? "selected" : ""
                                }`}
                              onClick={() => this.handleSalaryClick(item)}
                            >
                              <label>{item.Lookupvalue}</label>{" "}
                              <label>{`: ${item.Average} ${item.Currency}`}</label>
                            </div>
                          ))}
                        </div>
                      </React.Fragment>
                      <ReactApexChart
                        options={this.state.options}
                        series={this.state.graphData}
                        type="bar"
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
            <div className="sliderImageWrap">
              <img
                className="sliderImg"
                src={!resumeUploaded ? SliderSrc : SliderSrc1}
                alt="slider"
              />
            </div>
          </div>
        )}
        <div className="TopBar">
          <img className="topBarImg" src={TopbarSrc} alt="img" />
        </div>
        {/* <div className="footer">
          <a href="https://facebook.com/" target="_blank">
            <img className="social-img" src={facebook} alt="img" />
          </a>
          <a href="https://twitter.com/" target="_blank">
            <img className="social-img" src={twitter} alt="img" />
          </a>
          <a href="https://google.com/" target="_blank">
            <img className="social-img" src={google} alt="img" />
          </a>
          <a href="https://linkedin.com/" target="_blank">
            <img className="social-img" src={linkedin} alt="img" />
          </a>
        </div>*/}
      </div>)
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  let { FirstName } = user;
  FirstName =
    FirstName !== undefined ? FirstName : sessionStorage.getItem("userName");
  return { labels, FirstName };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(withRouter(ResumeAnalyzer));
