export default {
  localStorage: {
    isLoggedIn: "isLoggedIn",
    userId: "userId",
    bearerToken: "access_token",
    tokenExpiry: "tokenExpiry",
    fullName: "fullName"
  },
  app: {
    isLoggedIn: "isLoggedIn",
    userId: "userId",
    bearerToken: "access_token",
    tokenExpiry: "tokenExpiry",
    message: "message",
    fullName: "fullName"
  },
  footage: {
    userFootageList: "userFootageList"
  },
  reducer: {
    app: "app",
    footage: "footage"
  }
};
