import { StorageService } from "../api";
import { store } from "./store";
import axios from "axios";
import { systemLabelAction, ActionTypes, cookieAction } from "../actions";

export const initialConfig = () => {
  setGlobaleErrorHandling();
  getCurrenciesApi();
  store.dispatch(systemLabelAction());
  const labels = StorageService.getSystemLabels();
  if (labels) store.dispatch(systemLabelActionUpdate({ labels }));
  const user = StorageService.getUser();
  if (user) store.dispatch(loginAcionUpdate({ user }));
};

const setGlobaleErrorHandling = () => {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error && error.response && error.response.status === 401) {
        StorageService.clearToken();
        StorageService.clearUser();
        store.dispatch(logoutAction());
      }
      return Promise.reject(error.response);
    }
  );
};
const systemLabelActionUpdate = ({ labels }) => dispatch => {
  dispatch({
    type: ActionTypes.SYSTEM_LABEL,
    payload: labels
  });
};

const setCurrencies = ({ allCurrenciesList }) => dispatch => {
  dispatch({
    type: ActionTypes.CURRENCIES,
    payload: allCurrenciesList||[]
});
};

export const getCurrenciesApi = () => {
   axios.get("Currency/AllCurrenciesLookup").then(({ data }) => {
    if (data.success) {
      const allCurrenciesList = data.items.map((item) => ({
        ...item,
        value: item.CurrencyId,
        label: item.Name,
      }));
      store.dispatch(setCurrencies({allCurrenciesList}));
    }
  });
};
export const loginAcionUpdate = ({ user }) => dispatch => {
  StorageService.setUser({ user });
  dispatch({
    type: ActionTypes.LOGIN,
    payload: user
  });
};

export const logoutAction = () => dispatch => {
  StorageService.clearAll();
  dispatch({
    type: ActionTypes.LOGOUT
  });
};
