import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { PickerContainer } from "./PickerContainer";

class DatePickerField extends Component {
  render() {
    const {
      name,
      testId,
      todayButton,
      dateFormat,
      selected,
      onChange,
      onBlur,
      className,
      placeholderText,
      minDate,
      maxDate,
      picketId,
      ...rest
    } = this.props;
    return (
      <DatePicker
        test-data-id={testId}
        id={picketId}
        name={name}
        todayButton={todayButton}
        dateFormat={dateFormat}
        selected={selected}
        onChange={onChange}
        className={`datepicker-input ${className}`}
        placeholderText={placeholderText}
        minDate={minDate}
        maxDate={maxDate}
        popperContainer={PickerContainer}
        onBlur={onBlur}
        {...rest}
      />
    );
  }
}
export default DatePickerField;
