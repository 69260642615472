import { ActionTypes } from "../../actions";
window.view = "NEWSEARCH";
window.actionName = "newSearch";
export const onStateChangeAction = ({
  resumeList,
  selected,
  defaultList,
  collapse,
  actionName = window.actionName,
}) => (dispatch) => {
  resumeList !== undefined &&
    dispatch({
      type: ActionTypes.NEWSEARCH.RESUME_LIST,
      payload: resumeList,
      actionName,
    });
  selected !== undefined &&
    dispatch({
      type: ActionTypes.NEWSEARCH.SELECTED_RESUME,
      payload: selected,
      actionName,
    });
  defaultList !== undefined &&
    dispatch({
      type: ActionTypes.NEWSEARCH.DEFAULT_LIST,
      payload: defaultList,
      actionName,
    });
  collapse !== undefined &&
    dispatch({
      type: ActionTypes.NEWSEARCH.SEARCH_COLLAPSED,
      payload: collapse,
      actionName,
    });
};
