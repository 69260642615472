import React, { Children } from "react";
import moment from "moment";
import { DatePicker, Select } from "../../../../../common";
import { CustomInputNew } from "../../../../Tictell/views/components/CustomInput";

class CollaborationDetail extends React.Component {
  render() {
    const{
      selectedCollaboration,
      labels,
      handleShareCollaborationDelete,
      handleShareCollaborationInputChange,
      handleCollaborationShare,
      handleCollaborationEndDateChange,
      handleCollaborationExtend,
      handleCollabCurrencyChange,
      isEndDateCalendarOpen,
      handleDateOnBlur,
      collaborationDataChange1,
      allCurrencies,
      handleCollabHourlyFeeChange,
      handleBlur
    } = this.props;
    const{
      Dateformatelabel,
      SNAPSHOT_COLLABORATION_OWNER,
      SNAPSHOT_COLLABORATION_START,
      SNAPSHOT_COLLABORATION_END,
      SNAPSHOT_COLLABORATION_COMPENSATION,
      SNAPSHOT_COLLABORATION_WORKPLACE,
      SNAPSHOT_COLLABORATION_DESC,
      SNAPSHOT_COLLABORATION_SHAREWITH,
    } = labels;
    const{
      CollaborationId,
      CollaborationOwner,
      StartDate,
      EndDate,
      HourlyRate,
      HourlyRateType,
      CompanyName,
      Decription,
      SharedInfo,
      IsShared,
      newEmail,
      PreviousEndDate,
      selectedCurrency
    } = selectedCollaboration;
    return (
      <div className="snapshot-collaboration-detail">
          <CustomInputNew label={SNAPSHOT_COLLABORATION_OWNER} >
            <input
                type="text"
                autoComplete="off"
                value={CollaborationOwner || ""}
                name="Amount"
                className={`input-text `}
                placeholder={SNAPSHOT_COLLABORATION_OWNER}
                test-data-id="input-expanse-amount"
                disabled
              />
          </CustomInputNew>
        <div className="start-end">
          <CustomInputNew label={SNAPSHOT_COLLABORATION_START} >
            <label className="datepicker-wrapper">
                <DatePicker
                  selected={StartDate && moment(StartDate)}
                  name="expenseDate"
                  dateFormat="MMM Do YYYY"
                  placeholderText={Dateformatelabel}
                  disabled
                />
              </label>
          </CustomInputNew>
          <span>-</span>
          <CustomInputNew label={SNAPSHOT_COLLABORATION_END} >
            <label className="datepicker-wrapper">
                <DatePicker
                  selected={EndDate && moment(EndDate)}
                  name="expenseDate"
                  dateFormat="MMM Do YYYY"
                  placeholderText={Dateformatelabel}
                  onChange={handleCollaborationEndDateChange}
                  minDate = {moment()}
                  onBlur={handleDateOnBlur}
                  open={isEndDateCalendarOpen}
                />
              </label>
          </CustomInputNew>
          <button test-data-id={`AddShareCollabWithBtn`} className="add-btn" onClick={()=>handleCollaborationExtend()}></button>
        </div>

        <div className="start-end">
        <CustomInputNew label={"Hourly Fee"} >
            <input
                type="text"
                autoComplete="off"
                value={HourlyRate || ""}
                name="HourlyRate"
                className={`input-text `}
                placeholder={SNAPSHOT_COLLABORATION_COMPENSATION}
                test-data-id="input-expanse-amount"
                disabled={!collaborationDataChange1}
                onChange={e => handleCollabHourlyFeeChange(e)}
                onBlur={() => handleBlur()}
              />
          </CustomInputNew>
          <span>-</span>
          <CustomInputNew label={"Currency"} >
          <Select
              name="Category"
              className={`select-input select-category`}
              value={selectedCurrency}
              clearable={false}
              searchable={false}
              options={allCurrencies}
              disabled={!collaborationDataChange1}
              onChange={selectedOption =>
                handleCollabCurrencyChange(selectedOption)
              }
            />
        </CustomInputNew>
          <button test-data-id={`AddShareCollabWithBtn`} className="add-btn" onClick={()=>handleCollaborationExtend(1)}></button>
        </div>
          <CustomInputNew label={SNAPSHOT_COLLABORATION_WORKPLACE} >
            <input
                type="text"
                autoComplete="off"
                value={CompanyName || ""}
                name="Amount"
                className={`input-text `}
                placeholder={SNAPSHOT_COLLABORATION_WORKPLACE}
                test-data-id="input-expanse-amount"
                disabled
              />
          </CustomInputNew>
        <CustomInputNew label={SNAPSHOT_COLLABORATION_DESC} >
        <textarea
            type="text"
            value={Decription || ""}
            name={"Description"}
            className="textarea-field"
            rows="9"
            rows="6"
            placeholder={SNAPSHOT_COLLABORATION_DESC}
            test-data-id="input-comment"
            disabled
            />
        </CustomInputNew>
        {!IsShared &&  
        <CustomInputNew label={SNAPSHOT_COLLABORATION_SHAREWITH} >
          <div className="new-share">
            <input 
              test-data-id={`shareCollabsWithInput`}
              type="text" 
              onChange={handleShareCollaborationInputChange}
              onBlur={handleShareCollaborationInputChange} 
              value={newEmail} 
              />
              <button test-data-id={`AddShareCollabWithBtn`} className="add-btn" onClick={()=>handleCollaborationShare()}></button>
            </div>
        {SharedInfo && SharedInfo.map(attach=>(
            <div className={"attach-list-item"} key={attach.UserEmail}>
              <label>{attach.UserEmail}</label>
              <button test-data-id={`DelShareCollabWithBtn`} className="closeBtn" onClick={()=>handleShareCollaborationDelete(attach)}></button>
            </div>
          ))} 
        </CustomInputNew>}
      </div>
    );
  }
}

export default CollaborationDetail;
