import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificationAction } from "../../../../actions";
// import Select from "react-select";
import StarRatings from "react-star-ratings";
import "./newRatingView.scss";
import { Button } from "../../../../common";
import { Input, Select } from "antd";
const { TextArea } = Input;

class NewRatingView extends React.Component {
  render() {
    const {
      reviewQuestions,
      onRatingChange,
      onSubmit,
      isAlreadyRated,
      starRatedColor = "#9783cf",
      starHoverColor = "#dfd9f7",
      options,
      selectedCollaboration,
      CollborationCloseReasonId,
      labels,
      onFormSelectChange,
      isInActiveView,
      location,
    } = this.props;

    let isIproInactive =
      location.pathname === "/ipro-inactive-collaborations" ? true : false;
    return (
      <div
        className={`new-rating-view-component ${
          isAlreadyRated ? "disabled" : ""
        }`}
      >
        <div className="inner-user">
          {reviewQuestions &&
            reviewQuestions.map((r, i) => {
              return (
                <React.Fragment key={i}>
                  {r.Question === "Rating_Question6" && (
                    <Select
                      name="DurationType"
                      className={`select-input bg-color-for-opportunity  `}
                      placeholder={"Select a reason"}
                      value={CollborationCloseReasonId}
                      onChange={(e, selectedOption) =>
                        onFormSelectChange(r.Answer, selectedOption)
                      }
                      options={options}
                      size="large"
                      showArrow
                      bordered={false}
                      style={{ width: "100%" }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                    ></Select>
                  )}
                  <p> {r.ReviewQuestionValue} </p>
                  {r.Question !== "Rating_Question5" &&
                    r.Question !== "Rating_Question6" && (
                      <StarRatings
                        rating={Number.parseInt(r.Answer)}
                        starRatedColor={starRatedColor}
                        starHoverColor={starHoverColor}
                        starWidthAndHeight="10px"
                        changeRating={(value) => onRatingChange(r, value)}
                        numberOfStars={5}
                        name="star0rating"
                        starDimension="24px"
                        starSpacing="5px"
                        className="rattingsitems"
                      />
                    )}
                  {r.Question === "Rating_Question5" && (
                    <TextArea
                      rows={6}
                      name="Description"
                      placeholder={labels?.collDescription}
                      value={r.Answer || ""}
                      onChange={(value) => onRatingChange(r, value)}
                      className="bg-color-for-opportunity"
                      bordered={false}
                    />
                  )}
                </React.Fragment>
              );
            })}
          {selectedCollaboration.CollaborationId > 0 && !isAlreadyRated && (
            <div className="btn-footer">
              <button className="btn-delete" onClick={onSubmit}>
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};

const mapActionToProps = {
  notificationAction,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(NewRatingView));
