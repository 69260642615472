import { ActionTypes } from ".";

export const resumeChangeAction = ({
  isResumeChange = false,
  message = "",
  isLogout = false,
  isLogoutTrigger = false,
  isRoleSwitchTrigger = false,
  isRoleSwitchToggle = false
}) => dispatch => {
  dispatch({
    type: ActionTypes.RESUME_ANY_CHANGE,
    isResumeChange,
    message,
    isLogout,
    isLogoutTrigger,
    isRoleSwitchTrigger,
    isRoleSwitchToggle
  });
};
