import React, { Component } from "react";
import { PageWrapper } from "../../components";
import "./statitics.scss";
class Statistics extends Component {
  render() {
    return (
      <PageWrapper className="statistics-page">statistics page</PageWrapper>
    );
  }
}

export default Statistics;
