import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../components";
import { notificationAction } from "../../actions";
import "./nextstep.scss";
import { privateRoutes } from "../../Routes/routing";
import { StorageService } from "../../api";
import { updatePhillipPopupStatusApi } from "../../components/Phillip/phillipApi";
import ResumeParser from "./components/ResumeParser/ResumeParser";
import { getSnapshotMenusApi } from "../Snapshot/snapshotApi";
import ResumeSuggestor from "./components/ResumeSuggestor/ResumeSuggestor";
import MarketAnalyzer from "./components/Market Analyzer/MarketAnalyzer";
import { GetUserExpectedSalaryApi } from "./nextstepApi";
import { ConfirmDialog } from "../../common";

class NextStep extends Component {
  state = {
    UserFeatures: [],
    token: "",
    ExpenseCategories: [],
    Currencies: [],
    UserId: -1,
    dialogMessage: "",
    showPhillip: true,
    facebookClientId: "",
    linkedInClientId: "",
    isLoading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const firstUrl = this.state.UserFeatures[0];
    var queryString = window.location.href;
    queryString = queryString.toLowerCase().split("next-step")[1];
    if (queryString == "") {
      if (firstUrl) {
        const isApp = window.location.hash.toLowerCase().indexOf("apps") > -1;
        this.props.history.push(
          (isApp ? "/apps" : "") + "/next-step/" + firstUrl.Url
        );
      }
    }
  }
  componentDidMount() {
    // this.GetUserExpectedSalary();
    getSnapshotMenusApi({ featureId: 218 })
      .then((res) => {
        this.setState({
          UserFeatures: res.items,
        });
        const { items } = res;
        const firstUrl = items[0];
        var queryString = window.location.href;
        queryString = queryString.toLowerCase().split("next-step")[1];
        if (queryString == "") {
          if (firstUrl) {
            const isApp =
              window.location.hash.toLowerCase().indexOf("apps") > -1;
            this.props.history.push(
              (isApp ? "/apps" : "") + "/next-step/" + firstUrl.Url
            );
          }
        }
      })
      .catch((err) => console.log("Err ", err));

    let { UserEmail } = this.props.User;
    let Email = UserEmail;
    if (!Email) {
      const User = StorageService.getUser();
      Email = User.Email;
    }
    this.setState({
      UserId: this.props.User.UserId,
    });
  }
  handleOkClick = () => {
    this.props.history.push(privateRoutes.resumeEdit.path);
  };
  handleClosePhillip = () => {
    const { IsFreelancer } = this.props;
    updatePhillipPopupStatusApi({ isFreelancer: true }).then((response) => {});
    this.setState({ showPhillip: false });
  };
  handleShowPhillip = () => {
    this.setState({ showPhillip: true });
  };
  handleSearcherPhillipSkip = () => {
    const { IsFreelancer } = this.props;
    updatePhillipPopupStatusApi({ isFreelancer: true }).then((response) => {
      if (response.success) {
        const info = {
          message: this.props.searcherPhillipSkip,
          status: "success",
        };
        this.props.notificationAction(info);
      }
    });
    this.setState({ showPhillip: false });
  };
  handleUpdateCompanyPresentationsWidget = () => {
    this.setState({
      isReloadCompanyPresentationsWidget: !this.state
        .isReloadCompanyPresentationsWidget,
    });
  };
  handleUpdateResumeShortlistWidgetWidget = () => {
    this.setState({
      isReloadResumeShortlistWidget: !this.state.isReloadResumeShortlistWidget,
    });
  };
  reloadResumeWidgetHandler = () => {
    this.setState({ isReloadResumeWidget: !this.state.isReloadResumeWidget });
  };

  GetUserExpectedSalary() {
    this.setState({
      isLoading: true,
    });
    GetUserExpectedSalaryApi(46, 22, 1)
      .then((res) => {
        if (res.success) {
          if (res.items.Top3Salaries.length < 1) {
            this.setState({
              dialogMessage: this.props.labels.NextStep_No_Role_Message,
              isLoading: false,
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {console.log(err)
        this.setState({
          isLoading: false,
        });
      
      });
  }

  render() {
    const { showPhillip, dialogMessage } = this.state;
    const { UserFeatures, isLoading } = this.state;
    const { labels, isHelpActive } = this.props;
    const currentViewHash =
      window.location.hash.toLowerCase().split("/")[1] == "apps"
        ? window.location.hash.toLocaleLowerCase().split("/")[3]
        : window.location.hash.toLocaleLowerCase().split("/")[2];
    return (
      <PageWrapper>
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleOkClick}
              >
                {"Ok"}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {!isLoading &&
          !dialogMessage &&
          currentViewHash == "home" &&
          UserFeatures.findIndex(
            (a) => a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
          ) > -1 && (
            <MarketAnalyzer
              open={showPhillip}
              onCloseModal={this.handleClosePhillip}
              onSkip={this.handleSearcherPhillipSkip}
              onResumeUpdate={this.reloadResumeWidgetHandler}
            />
          )}
        {currentViewHash == "resume-parser" &&
          UserFeatures.findIndex(
            (a) => a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
          ) > -1 && (
            <ResumeParser
              open={showPhillip}
              onCloseModal={this.handleClosePhillip}
              onSkip={this.handleSearcherPhillipSkip}
              onResumeUpdate={this.reloadResumeWidgetHandler}
            />
          )}
        {currentViewHash == "resume-suggestor" &&
          UserFeatures.findIndex(
            (a) => a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
          ) > -1 && (
            <ResumeSuggestor
              open={showPhillip}
              onCloseModal={this.handleClosePhillip}
              onSkip={this.handleSearcherPhillipSkip}
              onResumeUpdate={this.reloadResumeWidgetHandler}
            />
          )}
      </PageWrapper>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(mapStateToProps, { notificationAction })(NextStep);
