import React, { Component } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../components";
import ImgSrc from "../../assets/images/company.svg";
import { ConfirmDialog, EmptyInfo } from "../../common";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import filter from "lodash/filter";
import includes from "lodash/includes";
import "../NewResumeSearch/search.scss";
import {
  isValidURL,
  isValidPhoneNumber,
  loadImageOrientation,
} from "../../utilities/helpers";
import {
  getCompaniesApi,
  addCompanyApi,
  updateCompanyApi,
  deleteCompanyApi,
  getCountriesApi,
  getIndustriesApi,
  getUserEmailsApi,
  getUserPhoneNumbersApi,
  DeleteUserCompanyandCompanyUsers,
} from "./companyApi";
import { notificationAction } from "../../actions";
import "./company.scss";
import { onStateChangeAction } from "./companyAction";
import toLower from "lodash/toLower";
import { Col, Input, Row, Space, Typography } from "antd";
import CompanyFormEditorNew from "./components/newForm";
import MobileFilter from "./components/mobileFilter.js";
import ArrowLeft from "../../assets/images/Icons/arrow-left.png";
import DeleteIcon from '../../assets/images/Icons/delete-modal.png'
import DeleteModal from "../../common/deleteModal/index.js";
const { Title } = Typography;
class Company extends Component {
  state = {
    delCompanywithUserDM: "",
    isMobileOpen: false,
    delete: {
      show: false
    }
  };

  componentDidMount() {
    this.props.isFetching && this.getCompanyList();
    this.getCountries();
    this.getIndustries();
    this.getUserEmails();
    this.getUserPhoneNumbers();
    if (this?.props?.history?.location?.state?.new) {
      this.handleCreateNewClick()
    }
  }

  getIndustries = () => {
    getIndustriesApi().then((data) => {
      const industries = data.items.map((item) => ({
        ...item,
        value: item.IndustryId,
        label: item.IndustryValue,
      }));
      this.props.onStateChangeAction({ industries });
    });
  };

  getCountries = () => {
    getCountriesApi().then((data) => {
      const filterCountries = data.items.filter((item) => item.CountryName);
      const countries = filterCountries.map((item) => ({
        ...item,
        value: item.CountryId,
        label: item.CountryName,
      }));
      this.props.onStateChangeAction({ countries });
    });
  };

  getUserEmails = () => {
    getUserEmailsApi().then((data) => {
      const filterEmail = data.items.filter((item) => item.UserEmailValue);
      const userEmails = filterEmail.map((item) => ({
        ...item,
        value: item.UserEmailValue,
        label: item.UserEmailValue,
      }));
      this.props.onStateChangeAction({ userEmails });
    });
  };

  handleFormFieldBlur = (e) => {
    let { name, value } = e.target;
    const { companyForm } = this.props;
    if (name === "WebUrl") {
      if (
        !isEmpty(value) &&
        value.indexOf("http") < 0 &&
        value.indexOf("https") < 0 &&
        value.indexOf("ftp") < 0
      ) {
        value = "http://" + value;
        this.props.onStateChangeAction({
          companyForm: {
            ...companyForm,
            WebUrl: value,
          },
        });
      }
      this.validateUrl({ name, value });
      return;
    }
  };

  getUserPhoneNumbers = () => {
    getUserPhoneNumbersApi().then((data) => {
      const filterPhone = data.items.filter(
        (item) => item.UserPhonenumberValue
      );
      const userPhoneNumbers = filterPhone.map((item) => ({
        ...item,
        value: item.UserPhonenumberId,
        label: item.UserPhonenumberValue,
      }));
      this.props.onStateChangeAction({ userPhoneNumbers });
    });
  };

  getCompanyList = () => {
    this.props.onStateChangeAction({ isFetching: true });
    getCompaniesApi()
      .then((data) => {
        let companies = data.items;

        const currentCompany =
          this.props.location.state &&
          companies.find(
            (item) =>
              item.UserCompanyId === this.props.location.state.com.UserCompanyId
          );
        const defaultCompany = companies.find((item) => item.IsDefaultCompany);
        this.props.onStateChangeAction({
          companyList: companies,
          filteredCompanies: companies,
          companyForm: (currentCompany && currentCompany) || defaultCompany,
          isLoading: false,
          isFetching: false,
        });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false, isFetching: false });
      });
  };

  handleCreateNewClick = () => {
    const companyForm = { UserCompanyId: -1 };
    this.props.onStateChangeAction({
      companyForm,
    });
    if (
      window?.innerWidth < 767
    ) {
      this.setState(st => ({ ...st, isMobileOpen: true }))
    }
  };

  handleCompanyClick = (id) => {
    const { filteredCompanies, userPhoneNumbers } = this.props;

    const companyForm = find(filteredCompanies, { UserCompanyId: id });

    let companyPhoneNumbers = [];
    if (companyForm.UserPhonenumberId) {
      companyPhoneNumbers = filter(userPhoneNumbers, (number) =>
        number.value === companyForm.UserPhonenumberId
      );
    }

    if (isEmpty(companyPhoneNumbers)) {
      companyForm.UserPhonenumberId = "";
      companyForm.UserPhonenumberValue = "";
    }

    this.props.onStateChangeAction({
      companyForm,
      userPhoneNumbers: companyPhoneNumbers.length > 0 ? companyPhoneNumbers : userPhoneNumbers,
    });
    if (
      window?.innerWidth < 767
    ) {
      this.setState(st => ({ ...st, isMobileOpen: true }))
    }
  };



  handleDeleteCompany = ({ company }) => {
    const { companyDeleteConfirmation } = this.props.labels;
    this.props.onStateChangeAction({
      selectedCompany: company,
      dialogMessage: companyDeleteConfirmation,
    });
    this.setState(st => ({
      ...st,
      delete: {
        ...st.delete,
        show: true
      }
    }))
  };

  handleYesClick = () => {
    const {
      selectedCompany: { UserCompanyId, IsDefaultCompany },
    } = this.props;

    const {
      companyDeleteSuccessfully,
      defaultCompanyNotDeletedMessage,
      companyAssociatedWithCompanyUsersMessage,
      companyWithCompanyUsersDeleteConfirmation,
    } = this.props.labels;
    this.props.onStateChangeAction({ dialogMessage: "" });
    if (IsDefaultCompany) {
      const info = {
        message: defaultCompanyNotDeletedMessage,
        status: "info",
      };
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({ isLoading: true });
    deleteCompanyApi({ id: UserCompanyId })
      .then((data) => {
        if (data.success) {
          this.props.notificationAction({
            message: companyDeleteSuccessfully,
            status: "success",
          });
          this.getCompanyList();
          return;
        }
        if (
          !data.success &&
          data.message == companyAssociatedWithCompanyUsersMessage
        ) {
          this.setState({
            delCompanywithUserDM: companyWithCompanyUsersDeleteConfirmation,
          });
          this.props.onStateChangeAction({ isLoading: false });
          return;
        }
        this.props.notificationAction({
          message: data.message,
          status: "info",
        });
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      }).finally(() => {
        this.setState(st => ({
          ...st,
          delete: {
            ...st.delete,
            show: false
          }
        }))
      })
      ;
  };
  handleCompanyDelWithusersYesClick = () => {
    const {
      selectedCompany: { UserCompanyId, IsDefaultCompany },
    } = this.props;

    const { companyDeleteSuccessfully } = this.props.labels;
    this.setState({ delCompanywithUserDM: "" });
    this.props.onStateChangeAction({ isLoading: true });
    DeleteUserCompanyandCompanyUsers({ id: UserCompanyId })
      .then((data) => {
        if (data.success) {
          this.props.notificationAction({
            message: companyDeleteSuccessfully,
            status: "success",
          });
          this.getCompanyList();
          return;
        }
        this.props.notificationAction({
          message: data.message,
          status: "info",
        });
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({
      dialogMessage: "",
      selectedCompany: null,
    });
  };

  handleFormSelectChange = (name, selectedOption) => {
    if (!selectedOption) return;
    const { companyForm, userPhoneNumbers } = this.props;
    const { value, UserPhonenumberId, UserPhonenumberValue } = selectedOption;

    if (name === "UserPhonenumberId") {
      if (UserPhonenumberId) {
        this.props.onStateChangeAction({
          companyForm: {
            ...companyForm,
            Phone: UserPhonenumberId,
            UserPhonenumberId,
            UserPhonenumberValue
          },
        });
        return;
      }
      const newPhoneNumber = head(userPhoneNumbers);
      if (
        newPhoneNumber &&
        newPhoneNumber.value &&
        !isValidPhoneNumber(newPhoneNumber.value)
      ) {
        userPhoneNumbers.splice(0, 1);
        this.props.onStateChangeAction({
          userPhoneNumbers: [...userPhoneNumbers],
        });
        return;
      } else {
        this.props.onStateChangeAction({
          companyForm: {
            ...companyForm,
            [name]: value,
            newPhoneCreated: true,
            Phone: value,
            UserPhonenumberValue: value,
          },
        });
        return;
      }
    }
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        [name]: value,
      },
    });
  };

  validateField = ({ name, value }) => {
    const { companyForm } = this.props;
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        [name]: value,
        [`invalid${name}`]: !value,
      },
    });
  };

  validateUrl = ({ name, value }) => {
    const { companyForm } = this.props;
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        [name]: value,
        [`invalid${name}`]: !(value && isValidURL(value)),
      },
    });
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "CompanyName") {
      this.validateField({ name, value });
      return;
    }
    if (name === "WebUrl") {
      this.validateUrl({ name, value });
      return;
    }
    const { companyForm } = this.props;
    if (name === "IsDefaultCompany") {
      this.props.onStateChangeAction({
        companyForm: {
          ...companyForm,
          [name]: e.target.checked,
        },
      });
      return;
    }
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        [name]: value,
      },
    });
  };

  updateAvatar = (image) => {
    const { companyForm } = this.props;
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        Logo: image,
      },
    });
  };

  handleAvatarChange = (file) => {
    if (file) {
      loadImageOrientation(file, this.updateAvatar);
      file = "";
    }
  };

  handleCompanySave = () => {
    const { companyForm } = this.props;
    const {
      companyFormValidation,
      companySuccessAdded,
      companySuccessEdited,
    } = this.props.labels;
    const {
      CompanyAddress = "",
      CompanyName = "",
      CountryId = "",
      Detail = "",
      IndustryId = "",
      IsDefaultCompany = false,
      Logo = "resources/images/spacer.png",
      Phone = null,
      UserCompanyId = 0,
      UserPhonenumberId = "",
      newPhoneCreated,
      UserPhonenumberValue = "",
      Vat = "",
    } = companyForm;
    let { WebUrl } = companyForm;
    const company = {
      CompanyAddress,
      CompanyName,
      CountryId,
      Detail,
      IndustryId,
      IsDefaultCompany,
      Logo,
      Phone,
      UserCompanyId,
      UserPhonenumberId: newPhoneCreated ? null : UserPhonenumberId,
      UserPhonenumberValue,
      Vat,
      WebUrl,
    };
    if (isEmpty(CompanyName)) {
      const info = {
        message: companyFormValidation,
        status: "error",
      };
      this.validateField({ name: "CompanyName", value: CompanyName });
      this.props.notificationAction(info);
      return;
    }


    //WebURL Validation
    if (
      !isEmpty(WebUrl) &&
      WebUrl.indexOf("http") < 0 &&
      WebUrl.indexOf("https") < 0 &&
      WebUrl.indexOf("ftp") < 0
    ) {
      WebUrl = "http://" + WebUrl;
      this.props.onStateChangeAction({
        companyForm: {
          ...companyForm,
          WebUrl: WebUrl,
        },
      });
      company.WebUrl = WebUrl;
    }
    if (!isEmpty(WebUrl) && !isValidURL(WebUrl)) {
      const info = {
        message: this.props.labels.companyURLInvalid,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    //End WebURL Validation
    this.props.onStateChangeAction({ isLoading: true });
    if (UserCompanyId < 0) {
      addCompanyApi({ company })
        .then(() => {
          this.getUserPhoneNumbers();
          this.getCompanyList();
          const info = {
            message: companySuccessAdded,
            status: "success",
          };
          this.props.notificationAction(info);
          this.setState((st) => ({ ...st, isMobileOpen: false }));
        })
        .catch(() => {
          this.props.onStateChangeAction({ isLoading: false });
        });
      return;
    }
    updateCompanyApi({ company })
      .then(() => {
        this.getUserPhoneNumbers();
        this.getCompanyList();
        this.setState((st) => ({ ...st, isMobileOpen: false }));
        const info = {
          message: companySuccessEdited,
          status: "success",
        };
        this.props.notificationAction(info);
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleCompanySearch = (e) => {
    const searchKey = e.target.value;
    this.props.onStateChangeAction({ searchKey });
    const { companyList } = this.props;
    const filteredCompanies = filter(companyList, (company) => {
      if (includes(toLower(company.CompanyName), toLower(searchKey))) {
        return company;
      }
    });
    this.props.onStateChangeAction({ filteredCompanies });
  };

  handleGetImgSrc = () => {
    const { companyForm } = this.props;
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        Logo: null,
      },
    });
  };

  handleCancelDeleteModal = () => {
    this.setState(st => ({
      ...st,
      delete: { show: false }
    }))
  }

  render() {
    const {
      labels,
      isHelpActive,
      filteredCompanies,
      companyForm,
      dialogMessage,
      countries,
      industries,
      userEmails,
      userPhoneNumbers,
      isLoading,
      isFetching,
      searchKey,
      listCollapsed,
      formCollapsed,
    } = this.props;
    const { delCompanywithUserDM, isMobileOpen } = this.state;

    return (
      <div className="company-page-main h-100">

        <PageWrapper className="company-page">
          <DeleteModal open={this.state?.delete} setOpen={this.handleCancelDeleteModal} handleOk={this.handleYesClick}
            title="Delete Workplace"
            heading="Are you sure you want to delete this Workplace?"
          />

          {/* {dialogMessage && (
            <ConfirmDialog testId="company-confirm-diloag">
              <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
              <ConfirmDialog.ActionButtons>
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="company-delete-yes"
                  onClick={this.handleYesClick}
                >
                  {labels.companyDeleteCurtainYESBtnText}
                </ConfirmDialog.Button>
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="company-delete-no"
                  onClick={this.handleNoClick}
                >
                  {labels.companyDeleteCurtainNOBtnText}
                </ConfirmDialog.Button>
              </ConfirmDialog.ActionButtons>
            </ConfirmDialog>
          )} */}
          {delCompanywithUserDM && (
            <ConfirmDialog testId="company-confirm-diloag">
              <ConfirmDialog.Message>
                {delCompanywithUserDM}
              </ConfirmDialog.Message>
              <ConfirmDialog.ActionButtons>
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="company-delete-yes"
                  onClick={this.handleCompanyDelWithusersYesClick}
                >
                  {labels.companyDeleteCurtainYESBtnText}
                </ConfirmDialog.Button>
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="company-delete-no"
                  onClick={this.handleNoClick}
                >
                  {labels.companyDeleteCurtainNOBtnText}
                </ConfirmDialog.Button>
              </ConfirmDialog.ActionButtons>
            </ConfirmDialog>
          )}
          {isMobileOpen ?
            <div className="d-flex w-100 h-100" style={{ flexDirection: "column", overflow: "auto" }}>
              <div className="d-flex align-items-center gap-8 cursor-pointer"
                onClick={() => { this.setState(st => ({ ...st, isMobileOpen: false })) }}
              >
                <div>
                  <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                </div>
                <Title level={5} className="m-0">Workplace</Title>
              </div>
              <div className="h-100 mt-3" style={{ overflow: "auto", background: "#fff", borderRadius: "12px" }}>
                <CompanyFormEditorNew
                  onFormSelectChange={this.handleFormSelectChange}
                  industries={industries}
                  userEmails={userEmails}
                  userPhoneNumbers={userPhoneNumbers}
                  companyForm={companyForm}
                  countries={countries}
                  onFormFieldChange={this.handleFormFieldChange}
                  onFormFieldBlur={this.handleFormFieldBlur}
                  onCompanySave={this.handleCompanySave}
                  onAvatarChange={this.handleAvatarChange}
                  labels={labels}
                  isLoading={isLoading}
                  getImgSrc={this.handleGetImgSrc}
                  handleDelete={this.handleDeleteCompany}
                  getNumber={this.getUserPhoneNumbers}
                />
              </div>
            </div>
            :

            <Row className="h-100 new-design-search w-100" >
              <Col xs={24} md={8} className="shortlist-lsit-section">
                <div className="h-100 d-flex flex-column">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "12px",
                      borderBottom: "1px solid #F3F3F3"
                    }}
                  >
                    <Input
                      placeholder={labels?.companySearchFieldPlaceholder}
                      size="medium"
                      bordered={false}
                      style={{ border: "1px solid #F3F3F3" }}
                      onChange={this.handleCompanySearch}
                    />
                  </div>
                  <div
                    style={{
                      overflow: "auto",
                      padding: "12px",
                      flex: 1
                    }}
                  >
                    {filteredCompanies?.length > 0 ? (
                      <Space size={[6, 6]} wrap className="short-list-new">
                        {filteredCompanies?.map((item) => (
                          <div
                            onClick={() => this.handleCompanyClick(item?.UserCompanyId)}
                            key={item?.UserCompanyId}
                            className={`d-flex gap-8 justify-content-between align-items-center pointer ${companyForm?.UserCompanyId === item?.UserCompanyId
                              ? "background-shortlist short-list-item "
                              : "short-list-item"
                              }`}>
                            <div className="d-flex gap-8">
                              <div className={`${item?.Logo ? "" : "no-dp"}`}>
                                <img
                                  className="roundImg"
                                  src={item?.Logo ? item?.Logo : ImgSrc}
                                  alt={item?.UserName}
                                  style={{ width: "48px", height: "48px", borderRadius: "50%" }}
                                />
                              </div>
                              <div>
                                <label style={{ fontSize: "14px" }}>
                                  {item?.CompanyName}
                                </label>
                                <p style={{ fontSize: "12px", marginBottom: 0 }}>{item?.CompanyAddress}</p>
                              </div>
                            </div>
                            {companyForm?.UserCompanyId === item?.UserCompanyId &&
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleDeleteCompany({ company: item });
                                }}
                              >
                                <img src={DeleteIcon} alt="" style={{ height: "20px" }} />
                              </div>
                            }
                          </div>
                        ))}
                      </Space>
                    ) : (
                      <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                    )}
                  </div>
                  <div style={{ padding: "12px", borderTop: "1px solid #F4F2FE" }}>
                    <button className="button-last-footer w-100"
                      onClick={() => {
                        this.handleCreateNewClick()
                      }}
                    >
                      {`Add Workplace`}
                    </button>
                  </div>
                </div>
              </Col>

              <Col md={16} xs={0} offset={0.5} className="h-100">
                <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                  <CompanyFormEditorNew
                    onFormSelectChange={this.handleFormSelectChange}
                    industries={industries}
                    userEmails={userEmails}
                    userPhoneNumbers={userPhoneNumbers}
                    companyForm={companyForm}
                    countries={countries}
                    onFormFieldChange={this.handleFormFieldChange}
                    onFormFieldBlur={this.handleFormFieldBlur}
                    onCompanySave={this.handleCompanySave}
                    onAvatarChange={this.handleAvatarChange}
                    labels={labels}
                    isLoading={isLoading}
                    getImgSrc={this.handleGetImgSrc}
                    handleDelete={this.handleDeleteCompany}
                    getNumber={this.getUserPhoneNumbers}
                  />
                </div>
              </Col>
            </Row>
          }
        </PageWrapper>
      </div >
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, company }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, ...company };
};
const mapActionToProps = {
  notificationAction,
  onStateChangeAction,
};
export default connect(mapStateToProps, mapActionToProps)(Company);
