import React from "react";
import { Column, Button, Select, EmptyInfo } from "../../../../common";
import {
  GetResumesByResumeId,
  GetUserDetailByResumeAndRequestId,
  getAllCollaborationsApi,
  GetSharedCollaborationInfo,
  DeleteShareCollaborationApi,
  ShareCollaborationApi,
  GetCollaborationExpenseDetailApi,
  GetCollaborationTimeDetailApi,
  UpdateCollaborationEndDate,
  UpdateCollaborationHourlyRate,
} from "../../snapshotApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import "./collaborations.scss";
import ImgSrc from "../../../../assets/images/present.svg";
import CollaborationDetail from "./components/CollaborationDetail";
import { isValidEmail } from "../../../../utilities";
import TimeAndExpenses from "./components/TimeAndExpenses";
import ResumeDetail from "../../components/ResumeDetail";
import Presentations from "../../components/PresentationDetails";
import moment from "moment";

class Collaborations extends React.Component {
  state = {
    options: [{ label: "", value: 1 }, { label: "", value: 2 }],
    acceptedCollaborations: [],
    selectedUser: [],
    selectedResume: [],
    collaborationDataChange1: false,
    allCollaborations: [],
    isCollapsed1: false,
    isCollapsed2: false,
    isCollapsed3: true,
    isCollapsed4: true,
    isCollapsed5: true,
    allTimeSheets: [],
    weekTimelogsList: [],
    weekExpenselogsList: [],
    currentExpenselog: {},
    currentTimeReport: {
      StatusId: 1,
      isEmpty: true,
      Amount: "",
      Time: "",
    },
    currentTimelog: {},
    TimeSheetStatusId: 2,
    ExpenseSheetStatusId: 2,
    isLoading: false,
    selectedCollaboration: {},
    durationOptions: [
      { label: "Current Month", value: 1 },
      { label: "Last Month", value: 2 },
      { label: "Year to date", value: 3 },
    ],
    StartDate: moment().startOf("months"),
    EndDate: moment(),
    TimeDetail: {},
    ExpenseDetail: {},
    selectedDuration: {},
    isEndDateCalendarOpen: false,
  };
  componentDidMount() {
    const {
      MY_ACTIVE_COLLAB,
      SHARED_ACTIVE_COLLAB,
      MY_INACTIVE_COLLAB,
      ALL_ACTIVE_COLLAB,
    } = this.props.labels;
    this.setState({
      options: [
        { label: ALL_ACTIVE_COLLAB, value: 1 },
        { label: MY_INACTIVE_COLLAB, value: 2 },
      ],
      selectedOption: { value: 1, label: ALL_ACTIVE_COLLAB },
    });
    this.GetAllColaborations(1);
    this.handleDurationOptionChange({ value: 1, label: "Current Month" });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedCollaboration.CollaborationId &&
      prevState.selectedCollaboration.CollaborationId !=
        this.state.selectedCollaboration.CollaborationId
    ) {
      this.GetCollaborationTimeAndExpenseDetails(
        this.state.selectedCollaboration,
        this.state.StartDate,
        this.state.EndDate
      );
    }
  }
  GetAllColaborations = (val) => {
    getAllCollaborationsApi(val, this.props.token).then((res) => {
      if (res.items.length > 0) {
        const allCollaborations = res.items.map((coll) => {
          return {
            ...coll,
            IsShared: coll.UserId != this.props.UserId,
            HourlyRateType: isNaN(coll.HourlyRateType)
              ? this.props.Currencies.find((a) => a.Name == coll.HourlyRateType)
                  .CurrencyId
              : coll.HourlyRateType,
          };
        });
        this.setState({
          allCollaborations,
        });
        const spliturl = this.props.locationProp.pathname.match(/\d+/g);
        if (spliturl != null) {
          let filteredCollab = allCollaborations.filter(
            (coll) => coll.CollaborationId == spliturl[0]
          );
          this.handleCollaborationClick(filteredCollab[0]);
        } else {
          this.handleCollaborationClick(allCollaborations[0]);
        }
      }
    });
  };
  handleOptionChange = (option) => {
    this.setState({
      selectedOption: option,
    });
    this.GetAllColaborations(option.value);
  };
  renderOptionClass = (option) => {
    return "";
    if (!option) {
      return "";
    }
    switch (option.value) {
      case 1:
        return "yellow";
      case 2:
        return "green";
      case 3:
        return "red";
      default:
        return "";
    }
  };
  GetCollaborationTimeAndExpenseDetails = (collab, StartDate, EndDate) => {
    this.GetCollaborationTimeDetail(
      moment(StartDate).format("DD/MM/YYYY"),
      moment(EndDate).format("DD/MM/YYYY"),
      collab
    );
    this.GetCollaborationExpenseDetail(
      moment(StartDate).format("DD/MM/YYYY"),
      moment(EndDate).format("DD/MM/YYYY"),
      collab
    );
  };
  handleCollabHourlyFeeChange = (e) => {
    const { selectedCollaboration } = this.state;
    const { name, value, type } = e.target;
    this.setState({
      selectedCollaboration: {
        ...selectedCollaboration,
        HourlyRate: value,
      },
    });
  };
  handleCollabCurrencyChange = (option) => {
    const { selectedCollaboration } = this.state;
    this.setState({
      selectedCollaboration: {
        ...this.state.selectedCollaboration,
        selectedCurrency: option,
        HourlyRateType: option.value,
      },
    });
    this.updateHourlyRate(
      selectedCollaboration.CollaborationId,
      selectedCollaboration.HourlyRate,
      option.value
    );
  };
  handleBlur = () => {
    const { selectedCollaboration } = this.state;
    this.updateHourlyRate(
      selectedCollaboration.CollaborationId,
      selectedCollaboration.HourlyRate,
      selectedCollaboration.HourlyRateType
    );
  };
  updateHourlyRate = (colaborationId, HourlyRate, currencyName) => {
    this.setState({isLoading: true})
    UpdateCollaborationHourlyRate(
      colaborationId,
      HourlyRate,
      currencyName
    ).then((res) => {
      if (res.success) {
        this.GetAllColaborations(this.state.selectedOption.value);
        const info = {
          status: "success",
          message: "Collaboration updated Successfully",
        };
        this.setState({isLoading: false});
        this.props.notificationAction(info);
      } else {
        const info = {
          status: "error",
          message: "some error occured while updating collaboration",
        };
        this.setState({isLoading: false});
        this.props.notificationAction(info);
        return;
      }
    });
  };
  handleDurationOptionChange = (option) => {
    this.setState({
      selectedDuration: option,
    });
    const { value } = option;
    let StartDate, EndDate;
    if (value == 3) {
      StartDate = moment().startOf("year");
      EndDate = moment();
    } else if (value == 2) {
      StartDate = moment()
        .subtract(1, "month")
        .startOf("month");
      EndDate = moment()
        .subtract(1, "month")
        .endOf("month");
    } else {
      StartDate = moment().startOf("month");
      EndDate = moment();
    }
    this.setState({
      StartDate: StartDate,
      EndDate: EndDate,
    });
    if (this.state.selectedCollaboration.CollaborationId)
      this.GetCollaborationTimeAndExpenseDetails(
        this.state.selectedCollaboration,
        StartDate,
        EndDate
      );
  };
  GetCollaborationTimeDetail(StartDate, EndDate, Collaboration) {
    const { token } = this.props;
    GetCollaborationTimeDetailApi(
      Collaboration.CollaborationId,
      StartDate,
      EndDate,
      token
    )
      .then((res) => {
        this.setState({
          TimeDetail: res.items,
        });
      })
      .catch((err) => console.log("Err ", err));
  }
  GetCollaborationExpenseDetail(StartDate, EndDate, Collaboration) {
    const { token } = this.props;
    GetCollaborationExpenseDetailApi(
      Collaboration.CollaborationId,
      StartDate,
      EndDate,
      token
    )
      .then((res) => {
        this.setState({
          ExpenseDetail: res.items,
        });
      })
      .catch((err) => console.log("Err ", err));
  }
  handleStartDateChange = (d) => {
    if (moment(d) >= moment(this.state.EndDate)) {
      const info = {
        status: "error",
        message: this.props.labels
          .Collaboration_Time_And_Expense_Start_Date_Validation_Message,
      };
      this.props.notificationAction(info);
      return;
    }
    this.setState({
      StartDate: d,
    });
    this.GetCollaborationTimeDetail(
      moment(d).format("DD/MM/YYYY"),
      moment(this.state.EndDate).format("DD/MM/YYYY"),
      this.state.selectedCollaboration
    );
    this.GetCollaborationExpenseDetail(
      moment(d).format("DD/MM/YYYY"),
      moment(this.state.EndDate).format("DD/MM/YYYY"),
      this.state.selectedCollaboration
    );
  };
  handleEndDateChange = (d) => {
    if (moment(d) <= moment(this.state.StartDate)) {
      const info = {
        status: "error",
        message: this.props.labels
          .Collaboration_Time_And_Expense_Date_Validation_Message,
      };
      this.props.notificationAction(info);
      return;
    }
    this.setState({
      EndDate: d,
    });
    this.GetCollaborationTimeDetail(
      moment(this.state.StartDate).format("DD/MM/YYYY"),
      moment(d).format("DD/MM/YYYY"),
      this.state.selectedCollaboration
    );
    this.GetCollaborationExpenseDetail(
      moment(this.state.StartDate).format("DD/MM/YYYY"),
      moment(d).format("DD/MM/YYYY"),
      this.state.selectedCollaboration
    );
  };
  handleCollaborationClick = (item, collaborations = null) => {
    if (!item) {
      this.setState({
        selectedCollaboration: {},
      });
      return;
    }
    let Currency = this.props.Currencies.find(
      (a) => a.CurrencyId == item.HourlyRateType
    );
    if (!item.IsShared) {
      GetSharedCollaborationInfo(item.CollaborationId, this.props.token)
        .then((res) => {
          item = {
            ...item,
            SharedInfo: res.items.map((a) => ({
              ...a,
              CollaborationId: item.CollaborationId,
            })),
          };
          const updatedCollaborations =
            collaborations != null
              ? collaborations
              : this.state.allCollaborations;
          const allCollaborations = updatedCollaborations.map((a) => ({
            ...a,
            isSelected: a.CollaborationId == item.CollaborationId,
            isActive: a.CollaborationId == item.CollaborationId,
            IsShared: a.UserId != this.props.UserId,
          }));
          this.setState({
            selectedCollaboration: {
              ...item,
              newEmail: "",
              PreviousEndDate: item.EndDate,
              selectedCurrency: Currency,
            },
            allCollaborations: allCollaborations,
            isCollapsed2: false,
            isCollapsed3: false,
          });
        })
        .catch((err) => console.log("Err ", err));
    } else {
      const updatedCollaborations =
        collaborations != null ? collaborations : this.state.allCollaborations;
      const allCollaborations = updatedCollaborations.map((a) =>
        a.CollaborationId == item.CollaborationId
          ? { ...a, isSelected: true, isActive: true }
          : { ...a, isSelected: false, isActive: false }
      );
      this.setState({
        selectedCollaboration: {
          ...item,
          newEmail: "",
          PreviousEndDate: item.EndDate,
          selectedCurrency: Currency,
        },
        allCollaborations: allCollaborations,
        isCollapsed2: false,
      });
    }
    GetUserDetailByResumeAndRequestId(item.ResumeId, item.RequestId).then(
      (res) => {
        if (res.success) {
          this.setState({
            selectedUser: res.items,
          });
        }
      }
    );
    GetResumesByResumeId(item.ResumeId)
      .then((res) => {
        if (res.success) {
          this.setState({
            selectedResume: res.items,
          });
        }
      })
      .catch((response) => {});
  };
  handleShareCollaborationDelete = (item) => {
    DeleteShareCollaborationApi(
      item.CollaborationId,
      item.UserId,
      this.props.token
    ).then((res) => {
      if (res.success) {
        this.setState({
          selectedCollaboration: {
            ...this.state.selectedCollaboration,
            SharedInfo: this.state.selectedCollaboration.SharedInfo.filter(
              (a) => a.UserId != item.UserId
            ),
          },
        });
        const info = {
          status: "success",
          message: this.props.labels.SNAPSHOT_COLLABORATIONS_UN_SHARE_SUCCESS,
        };
        this.props.notificationAction(info);
      }
    });
  };
  handleShareCollaborationInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      selectedCollaboration: {
        ...this.state.selectedCollaboration,
        newEmail: value,
      },
    });
  };
  handleShareCollaboration = () => {
    const {
      CollaborationId,
      newEmail,
      SharedInfo,
    } = this.state.selectedCollaboration;
    if (!isValidEmail(newEmail)) {
      const info = {
        status: "error",
        message: this.props.labels.VALID_EMAIL,
      };
      this.props.notificationAction(info);
      return;
    }
    ShareCollaborationApi(CollaborationId, newEmail, this.props.token).then(
      (res) => {
        if (res.success) {
          const sharedInfo = SharedInfo.concat({
            CollaborationId: CollaborationId,
            UserEmail: newEmail,
            UserId: res.items.UserId,
          });
          this.setState({
            selectedCollaboration: {
              ...this.state.selectedCollaboration,
              SharedInfo: sharedInfo,
              newEmail: "",
              PreviousEndDate: sharedInfo.EndDate,
            },
          });
          const info = {
            status: "success",
            message: this.props.labels.SNAPSHOT_COLLABORATIONS_SHARE_SUCCESS,
          };
          this.props.notificationAction(info);
        } else {
          let info = {};
          if (res.message == "Collaboration already shared with that user.") {
            info = {
              status: "error",
              message: this.props.labels.USER_COLLABORATION_SHARE_MSG,
            };
          } else if (res.message == "Email Address Not exist in PDCore") {
            info = {
              status: "error",
              message: this.props.labels.USER_EMAIL_DOESNOT_EXIST,
            };
          } else {
            info = {
              status: "error",
              message: this.props.labels.COLLABORATION_SHARE_FAILED,
            };
          }
          this.props.notificationAction(info);
        }
      }
    );
  };
  handleCollaborationEndDateChange = (d) => {
    this.setState({
      selectedCollaboration: {
        ...this.state.selectedCollaboration,
        EndDate: d,
      },
    });
    const EndDate = moment(d);
    const { CollaborationId } = this.state.selectedCollaboration;
    UpdateCollaborationEndDate(
      CollaborationId,
      moment(EndDate).format("DD/MM/YYYY")
    ).then((res) => {
      if (res.success) {
        const info = {
          status: "success",
          message: this.props.labels.Collaboration_Extend_Success_Message,
        };
        this.props.notificationAction(info);
        let allCollaborations = this.state.allCollaborations.map((a) =>
          a.CollaborationId == CollaborationId
            ? { ...a, EndDate: EndDate, PreviousEndDate: EndDate }
            : a
        );
        if (this.state.selectedOption.value == "2") {
          allCollaborations = allCollaborations.filter(
            (a) => a.CollaborationId != CollaborationId
          );
        }
        this.setState({
          allCollaborations,
          selectedCollaboration: {
            ...this.state.selectedCollaboration,
            PreviousEndDate: EndDate,
          },
          isEndDateCalendarOpen: false,
        });
        this.handleCollaborationClick(allCollaborations[0]);
        return;
      } else {
        const info = {
          status: "error",
          message: this.props.labels.Collaboration_Extend_Error_Message,
        };
        this.props.notificationAction(info);
        return;
      }
    });
  };
  handleCollaborationExtend = (t) => {
    if (t) {
      this.setState({ collaborationDataChange1: true });
      return false;
    }
    this.setState({
      isEndDateCalendarOpen: true,
    });
  };
  handleDateOnBlur = () => {
    this.setState({
      isEndDateCalendarOpen: false,
    });
  };
  render() {
    const {
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      isCollapsed4,
      isCollapsed5,
      selectedOption,
      selectedCollaboration,
      selectedUser,
      selectedResume,
      isEndDateCalendarOpen,
      isLoading
    } = this.state;
    const { labels, isHelpActive, UserId } = this.props;
    const {
      SNAPSHOT_COLLABORATIONS,
      SNAPSHOT_COLLABORATION_DETAIL,
      SNAPSHOT_COLLABORATION_TIME_AND_EXPENSES,
      SNAPSHOT_COLLABORATION_PRESENT,
      SNAPSHOT_COLLABORATION_RESUME,
    } = labels;
    return (
      <div className="collaborations-view">
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={SNAPSHOT_COLLABORATIONS}
            onClick={() => this.setState({ isCollapsed1: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn1"
          />
          <Column.Head>
            <div className="heading">{SNAPSHOT_COLLABORATIONS}</div>
            <Button
              className="collapseBtn"
              testId="Collapasebtn1"
              onClick={() => this.setState({ isCollapsed1: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          {!isCollapsed1 && (
            <Select
              readonly={true}
              searchable={false}
              testId={"collaborationDropdown"}
              name="AllPresentation"
              value={selectedOption}
              className={`select-input ${this.renderOptionClass(
                selectedOption
              )}`}
              placeholder={labels.presentPrimaryRolePlaceholder}
              onChange={(selectedOption) =>
                this.handleOptionChange(selectedOption)
              }
              options={this.state.options}
              clearable={false}
            />
          )}
          <Column.Body>
            <div className="tictell-list">
              {this.state.allCollaborations &&
                this.state.allCollaborations.map((item) => (
                  <div
                    key={item.CollaborationId}
                    test-data-id={`collaborations-list-item${
                      item.isActive ? "activeItem" : ""
                    }`}
                    className={`tictell-list-item-container  ${
                      item.isSelected ? "selected-item" : ""
                    } ${item.isActive ? "activeItem" : ""}`}
                    onClick={() => this.handleCollaborationClick(item)}
                  >
                    <div
                      className={`dashItemImg ${
                        item.PresenatationLogo ? "" : "no-dp"
                      }`}
                    >
                      <img
                        className="roundImg"
                        src={
                          item.PresenatationLogo
                            ? item.PresenatationLogo
                            : ImgSrc
                        }
                        alt={"UserName"}
                      />
                    </div>
                    <div className="name-container">
                      <label test-data-id={`collabs-list-owner-username`}>
                        {item.UserName}
                      </label>
                      <label test-data-id={`collabs-list-owner-title`}>
                        {item.Title}
                      </label>
                    </div>
                    <span className={`${item.IsShared ? "shared-icon" : ""}`} />
                  </div>
                ))}
            </div>
          </Column.Body>
        </Column>
        {this.state.allCollaborations &&
          this.state.allCollaborations.length > 0 &&
          selectedCollaboration && (
            <Column collapse={isCollapsed2} className="col-2">
              <Column.Collapsed
                text={SNAPSHOT_COLLABORATION_DETAIL}
                onClick={() => this.setState({ isCollapsed2: false })}
                isHelpActive={isHelpActive}
                tooltipButton={labels.ToolTipFeedBackExpandCreate}
                tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
                tooltipPlace="left"
                testId="expandbtn2"
              />
              <Column.Head>
                <div className="heading">{SNAPSHOT_COLLABORATION_DETAIL}</div>
                <Button
                  className="collapseBtn"
                  onClick={() => this.setState({ isCollapsed2: true })}
                  tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                  tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                  tooltipPlace="left"
                  isHelpActive={isHelpActive}
                  testId="collapsebtn2"
                />
              </Column.Head>
              {!isCollapsed2 && (
                <div
                  className={`tictell-list-item-container activeItem collaboration-heading`}
                >
                  <div
                    className={`dashItemImg ${
                      selectedCollaboration.PresenatationLogo ? "" : "no-dp"
                    }`}
                  >
                    <img
                      className="roundImg"
                      src={
                        selectedCollaboration.PresenatationLogo
                          ? selectedCollaboration.PresenatationLogo
                          : ImgSrc
                      }
                      alt={"UserName"}
                    />
                  </div>
                  <div className="name-container">
                    <label test-data-id={`collabs-details-owner-username`}>
                      {selectedCollaboration.UserName}
                    </label>
                    <label test-data-id={`collabs-details-owner-title`}>
                      {selectedCollaboration.Title}
                    </label>
                  </div>
                  <span
                    className={`${
                      selectedCollaboration.IsShared ? "shared-icon" : ""
                    }`}
                  />
                </div>
              )}
              <Column.Body>
                <CollaborationDetail
                  labels={labels}
                  handleBlur={this.handleBlur}
                  selectedCollaboration={selectedCollaboration}
                  handleShareCollaborationDelete={
                    this.handleShareCollaborationDelete
                  }
                  handleShareCollaborationInputChange={
                    this.handleShareCollaborationInputChange
                  }
                  handleCollaborationShare={this.handleShareCollaboration}
                  handleCollaborationEndDateChange={
                    this.handleCollaborationEndDateChange
                  }
                  handleCollaborationExtend={this.handleCollaborationExtend}
                  isEndDateCalendarOpen={isEndDateCalendarOpen}
                  collaborationDataChange1={this.state.collaborationDataChange1}
                  allCurrencies={this.props.Currencies}
                  handleDateOnBlur={this.handleDateOnBlur}
                  handleCollabCurrencyChange={this.handleCollabCurrencyChange}
                  handleCollabHourlyFeeChange={this.handleCollabHourlyFeeChange}
                ></CollaborationDetail>
              </Column.Body>
            </Column>
          )}
        {
          <Column collapse={isCollapsed3} className="col-3">
            <Column.Collapsed
              text={SNAPSHOT_COLLABORATION_TIME_AND_EXPENSES}
              onClick={() => this.setState({ isCollapsed3: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandbtn3"
            />
            <Column.Head>
              <div className="heading">
                {SNAPSHOT_COLLABORATION_TIME_AND_EXPENSES}
              </div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed3: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapsebtn3"
              />
            </Column.Head>
            <Column.Body>
              <TimeAndExpenses
                CollaborationId={selectedCollaboration.CollaborationId}
                labels={labels}
                token={this.props.token}
                options={this.state.durationOptions}
                selectedOption={this.state.selectedDuration}
                StartDate={this.state.StartDate}
                EndDate={this.state.EndDate}
                TimeDetail={this.state.TimeDetail}
                ExpenseDetail={this.state.ExpenseDetail}
                handleOptionChange={this.handleDurationOptionChange}
                handleStartDateChange={this.handleStartDateChange}
                handleEndDateChange={this.handleEndDateChange}
              />
            </Column.Body>
          </Column>
        }
        {
          <Column collapse={isCollapsed4} className="col-4">
            <Column.Collapsed
              text={SNAPSHOT_COLLABORATION_PRESENT}
              onClick={() => this.setState({ isCollapsed4: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandbtn4"
            />
            <Column.Head>
              <div className="heading">{SNAPSHOT_COLLABORATION_PRESENT}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed4: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapsebtn4"
              />
            </Column.Head>
            <Column.Body>
              <Presentations
                selectedUser={selectedUser}
                labels={this.props.labels}
              />
            </Column.Body>
          </Column>
        }
        {
          <Column collapse={isCollapsed5} className="col-5">
            <Column.Collapsed
              text={SNAPSHOT_COLLABORATION_RESUME}
              onClick={() => this.setState({ isCollapsed5: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandbtn5"
            />
            <Column.Head>
              <div className="heading">{SNAPSHOT_COLLABORATION_RESUME}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed5: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapsebtn5"
              />
            </Column.Head>
            <Column.Body className="ios-height-fix">
              <ResumeDetail resume={selectedResume} />
            </Column.Body>
          </Column>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Collaborations);
