import React, { useState, useEffect } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
  } from "react-accessible-accordion";

const SuggestedLookupsAccordian = ({
    suggestObject,
    componentName,
    localSuggestedSkills,
    onAddSuggestions,
    propId,
    classes,
    propValue

}) => {
  return (
    <Accordion>
              <AccordionItem expanded={true}>
                <AccordionItemTitle>
                  Suggested {componentName} for{" "}
                  {suggestObject && suggestObject.Value}
                  <div className="accordion__arrow" />
                </AccordionItemTitle>
                <AccordionItemBody>
                  {localSuggestedSkills&&localSuggestedSkills.length > 0 ? (
                    <div className={classes.suggestedSkills}>
                      <div className={classes.suggestions} id="suggested-skill">
                        {localSuggestedSkills.map((item) => (
                          <div
                            key={item[propId]}
                            className={classes.suggestedKeyword}
                          >
                            <p>{item[propValue]}</p>
                            <button
                              className={classes.addSkillButton}
                              onClick={() =>
                                onAddSuggestions(item[propId], item, componentName)
                              }
                            >
                              +
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <p className={classes.noSuggestionFoundMessage}>No Suggestions</p>
                  )}
                </AccordionItemBody>
              </AccordionItem>
            </Accordion>
  );
};

export default SuggestedLookupsAccordian;
