import "./dashboard.scss";
import React from "react";

const Widget = ({ children, testId }) => (
  <div className="snapshot_widget" test-data-id={testId}>
    {children}
  </div>
);

const Header = ({ children, testId }) => (
  <div className="header" test-data-id={testId}>
    {children}
  </div>
);

const Item = ({ children, className, onClick, testId }) => (
  <div className={`item ${className}`} onClick={onClick} test-data-id={testId}>
    {children}
  </div>
);

const Icon = (props) => <img {...props} className="image" />;

const Content = ({ children, testId, className }) => (
  <div className={`content ${className}`} test-data-id={testId}>
    {children}
  </div>
);
Widget.Header = Header;
Widget.Item = Item;
Widget.Image = Icon;
Widget.Content = Content;

Item.defaultProps = {
  className: "",
};
export default Widget;
