import React, { Children } from "react";
import moment from "moment";
import { DatePicker, Select } from "../../../../common";
import "rc-time-picker/assets/index.css";
import "moment/locale/en-gb";
import { CustomInputNew } from "../components/CustomInput";

const showSecond = true;
const str = showSecond ? "HH:mm:ss" : "HH:mm";

class TimelogDetail extends React.Component {
  state = {
    isStartTimeFocus: false,
    isEndTimeFocus: false,
  };
  toggleTimePicker = (type, value) => {
    if (type == 1) {
      this.setState({
        isStartTimeFocus: !this.state.isStartTimeFocus,
        isEndTimeFocus: false,
      });
    } else if (type == 2) {
      this.setState({
        isStartTimeFocus: false,
        isEndTimeFocus: !this.state.isEndTimeFocus,
      });
    } else {
      this.setState({
        isStartTimeFocus: false,
        isEndTimeFocus: false,
      });
    }
  };
  render() {
    const {
      invalidStartDate = false,
      handleTimelogDateChange,
      handleTimelogInputChange,
      currentTimelog,
      handleTimelogInputBlur,
      handleTimeChange,
      selectedMainDate,
      labels,
      isDisabled,
      startDate,
      endDate,
      status,
      selectedCollaboration,
      isTimerOn
    } = this.props;
    const {
      TimeLogId,
      Comment = "",
      Date,
      Memo = "",
      Amount = "",
      StartTime,
      EndTime,
      ReviewedBy,
      ReviewedDate,
    } = currentTimelog;
    return (
      <div className="expense-detail">
        <CustomInputNew
          label={labels.Timelog_DateField}
          validate={true}
          value={Date}
        >
          <label
            className="datepicker-wrapper"
            onClick={() => this.toggleTimePicker(3)}
          >
            <DatePicker
              selected={Date && moment(Date)}
              onChange={handleTimelogDateChange}
              name="expenseDate"
              dateFormat="DD-MM-YYYY"
              className={`${!Date ? "inValid" : ""}`}
              placeholderText={labels.Timelog_Date_Placeholder}
              maxDate={
                moment(endDate).isoWeek() ===
                moment(selectedCollaboration.EndDate).isoWeek()
                  ? moment(selectedCollaboration.EndDate)
                  : moment(endDate)
              }
              minDate={
                moment(startDate).isoWeek() ===
                moment(selectedCollaboration.StartDate).isoWeek()
                  ? moment(selectedCollaboration.StartDate)
                  : moment(startDate)
              }
              disabled={isTimerOn}
              locale="en-gb"
              autoComplete="off"
            />
          </label>
        </CustomInputNew>
        {!isTimerOn && <div className="time-range-picker">
          <CustomInputNew
            label={labels.Timelog_StartTime}
            validate={true}
            value={StartTime}
          >
            <label
              test-data-id={"StartTimeInput"}
              className="datepicker-wrapper"
            >
              <DatePicker
                selected={StartTime}
                onChange={(time) => handleTimeChange(time, "StartTime")}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Start Time"
                dateFormat={"HH:mm"}
                disabled={isDisabled}
                timeFormat={"HH:mm"}
                placeholderText={labels.Timelog_Time_Placeholder}
              />
            </label>
          </CustomInputNew>
          <label>to</label>
          <CustomInputNew
            label={labels.Timelog_EndTime}
            validate={true}
            value={EndTime}
          >
            <label className="datepicker-wrapper" test-data-id="EndTimeInput">
              <DatePicker
                selected={EndTime}
                onChange={(time) => handleTimeChange(time, "EndTime")}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="End Time"
                dateFormat={"HH:mm"}
                timeFormat={"HH:mm"}
                disabled={isDisabled}
                placeholderText={labels.Timelog_Time_Placeholder}
              />
            </label>
          </CustomInputNew>
        </div>}
        <CustomInputNew label={labels.Timelog_Detail}>
          <textarea
            type="text"
            value={!Memo ? "" : Memo}
            onChange={handleTimelogInputChange}
            onBlur={handleTimelogInputBlur}
            onClick={() => this.toggleTimePicker(3)}
            name="Memo"
            className="textarea-field"
            rows="9"
            rows="6"
            placeholder={labels.Timelog_Detail_Placeholder}
            test-data-id="input-details"
            disabled={isDisabled}
          />
        </CustomInputNew>
        {(status === 2 || status === 3) && ReviewedBy !== null && (
          <div>
            <CustomInputNew label={labels.ReviewedBy_Label}>
              <input
                type="text"
                autoComplete="off"
                value={ReviewedBy || ""}
                name="ReviewedBy"
                className={`input-text `}
                placeholder={labels.ReviewedBy_Label}
                test-data-id="input-text-opportunity-title"
                disabled
              />
            </CustomInputNew>
            <CustomInputNew label={labels.ReviewedDate_Label}>
              <input
                type="text"
                autoComplete="off"
                value={moment(ReviewedDate).format("DD-MM-YYYY") || ""}
                name="ReviewedDate"
                className={`input-text `}
                placeholder={labels.ReviewedDate_Label}
                test-data-id="input-text-opportunity-title"
                disabled
              />
            </CustomInputNew>
          </div>
        )}
      </div>
    );
  }
}

export default TimelogDetail;
