import { ActionTypes } from "../../actions";
export const onStateChangeAction = ({
  feedbackList,
  selectedFeedback,
  isCreateNew,
  feedbackForm,
  dialogMessage,
  deletedId,
  filteredList,
  listCollapsed,
  detailCollapsed,
  formCollapsed,
  searchKey,
  isFetching
}) => dispatch => {
  feedbackList !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.FEEDBACK_LIST,
      payload: feedbackList
    });
  selectedFeedback !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.SELECTED_FEEDBACK,
      payload: selectedFeedback
    });
  isCreateNew !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.IS_CREATENEW,
      payload: isCreateNew
    });
  feedbackForm !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.FEEDBACK_FORM,
      payload: feedbackForm
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.DIALOG_MESSAGE,
      payload: dialogMessage
    });
  deletedId !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.DELETED_ID,
      payload: deletedId
    });
  filteredList !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.FILTERED_LIST,
      payload: filteredList
    });
  listCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.LIST_COLLAPSED,
      payload: listCollapsed
    });
  detailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.DETAIL_COLLAPSED,
      payload: detailCollapsed
    });
  formCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.FORM_COLLAPSED,
      payload: formCollapsed
    });
  searchKey !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.SEARCH_KEY,
      payload: searchKey
    });
  isFetching !== undefined &&
    dispatch({
      type: ActionTypes.FEEDBACK.IS_FETCHING,
      payload: isFetching
    });
};
