import { ActionTypes } from "../actions";
import moment from "moment";
import { EmptySearchList } from "../utilities";
const INITIAL_STATE = {
  searchCollapsed: false,
  resumeCollapsed: false,
  resumeDetailCollapsed: false,
  createCollapse: false,
  shortlist: [],
  selectedResume: {},
  selectedShortlist: {},
  shortlistResumes: [],
  filteredShortlist: [],
  isLoading: false,
  fetchingShortlist: true,
  fetchingResumes: false,
  opportunityList: [],
  fetchingOpportunities: false,
  dialogMessage: "",
  newShortlistValue: "",
  createNewShortlist: false,
  dialogOpportunityMessage: "",
  showOpportunities: false,
  opportunitiesCollapse: false,
  searchKey: "",
  deletedId: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SHORTLIST.SHORTLIST:
      return { ...state, shortlist: action.payload };
    case ActionTypes.SHORTLIST.SEARCH_COLLAPSED:
      return { ...state, searchCollapsed: action.payload };
    case ActionTypes.SHORTLIST.RESUME_COLLAPSED:
      return { ...state, resumeCollapsed: action.payload };
    case ActionTypes.SHORTLIST.RESUME_DETAIL_COLLAPSED:
      return { ...state, resumeDetailCollapsed: action.payload };
    case ActionTypes.SHORTLIST.SELECTED_SHORTLIST:
      return { ...state, selectedShortlist: action.payload };
    case ActionTypes.SHORTLIST.SELECTED_RESUME:
      return { ...state, selectedResume: action.payload };
    case ActionTypes.SHORTLIST.SHORTLIST_RESUMES:
      return { ...state, shortlistResumes: action.payload };
    case ActionTypes.SHORTLIST.DIALOG_MESSAGE:
      return { ...state, dialogMessage: action.payload };
    case ActionTypes.SHORTLIST.FILTERED_SHORTLIST:
      return { ...state, filteredShortlist: action.payload };
    case ActionTypes.SHORTLIST.NEW_SHORTLIST_VALUE:
      return { ...state, newShortlistValue: action.payload };
    case ActionTypes.SHORTLIST.CREATE_NEW_SHORTLIST:
      return { ...state, createNewShortlist: action.payload };
    case ActionTypes.SHORTLIST.IS_LOADING:
      return { ...state, isLoading: action.payload };
    case ActionTypes.SHORTLIST.CREATE_COLLAPSE:
      return { ...state, createCollapse: action.payload };
    case ActionTypes.SHORTLIST.FETCHING_SHORTLIST:
      return { ...state, fetchingShortlist: action.payload };
    case ActionTypes.SHORTLIST.FETCHING_RESUMES:
      return { ...state, fetchingResumes: action.payload };
    case ActionTypes.SHORTLIST.DIALOG_OPPORTUNITY_MESSAGE:
      return { ...state, dialogOpportunityMessage: action.payload };
    case ActionTypes.SHORTLIST.OPPORTUNITY_LIST:
      return { ...state, opportunityList: action.payload };
    case ActionTypes.SHORTLIST.SHOW_OPPORTUNITIES:
      return { ...state, showOpportunities: action.payload };
    case ActionTypes.SHORTLIST.FETCHING_OPPORTUNITIES:
      return { ...state, fetchingOpportunities: action.payload };
    case ActionTypes.SHORTLIST.OPPORTUNITIES_COLLAPSE:
      return { ...state, opportunitiesCollapse: action.payload };
    case ActionTypes.SHORTLIST.SEARCH_KEY:
      return { ...state, searchKey: action.payload };
    case ActionTypes.SHORTLIST.DELETED_ID:
      return { ...state, deletedId: action.payload };
    default:
      return state;
  }
};
