import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Column, Button, EmptyInfo, Input } from "../../../../common";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import Select from "react-select";
import {
  addTimeLogApi,
  GetWeekAllTimeLogs,
  DeleteATimeLogs,
  addTimesheetApi,
} from "../../tictellApi";
import { getUserWidgets } from "../../../Dashboard/dashboardApi.js";
import TimelogDetail from "./TimelogDetail";
import TimelogList from "./TimelogList";
import TimerComponent from "./TimerComponent";

let isAddingNew = false;
class TimeLogView extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    TictellTimer: false,
    IsCollapsed2: false,
    isTimerOn: false,
    isLoading: false,
    StatusId: "",
    CurrentTimelog: {
      StartTime: moment()
        .set("hour", 9)
        .set("minute", 0),
      EndTime: moment()
        .set("hour", 17)
        .set("minute", 0),
    },
    TimeSheetStatusId: 4,
    TimeDetail: {
      Amount: "",
      Time: "",
      HourlyRateType: "USD",
    },
    timelogsList: [],
  };
  componentDidMount() {
    let tictellTimerLocalStorage = JSON.parse(
      sessionStorage.getItem("tictellTimer")
    );
    if (tictellTimerLocalStorage) {
      this.setState({ isTimerOn: tictellTimerLocalStorage.isTimerOn });
    }
    this.getTimeLogs(
      this.props.selectedCollaboration,
      this.props.startDate,
      this.props.endDate
    );
    this.getIproFeatures();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedCollaboration != this.props.selectedCollaboration ||
      prevProps.startDate != this.props.startDate
    ) {
      this.getTimeLogs(
        this.props.selectedCollaboration,
        this.props.startDate,
        this.props.endDate
      );
    }
  }
  getIproFeatures = () => {
    getUserWidgets(["TictellTimer"])
      .then((response) => {
        if (response.success) {
          let iproWidgets = {};
          response.items.map((item) => {
            iproWidgets[item.Name] = item.IsActive;
          });
          this.setState({ ...iproWidgets });
        }
      })
      .catch((response) => {});
  };
  handleTimelogInputBlur = (e) => {
    const { CurrentTimelog } = this.state;
    this.saveTimelog(CurrentTimelog);
  };
  handleTimelogDateChange = (d) => {
    if (
      moment(d) <= moment(this.props.endDate) &&
      moment(d) >= moment(this.props.startDate)
    ) {
      const CurrentTimelog = {
        ...this.state.CurrentTimelog,
        Date: moment(d).format("YYYY-MM-DDTHH:mm:ss"),
      };
      this.setState({
        CurrentTimelog,
      });
      this.saveTimelog(CurrentTimelog);
    } else {
      const info = {
        status: this.props.labels.Error_Message,
        message: this.props.labels.Timelog_Wrong_Date_Error_Message,
      };
      this.props.notificationAction(info);
      return;
    }
  };
  handleTimelogInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      CurrentTimelog: {
        ...this.state.CurrentTimelog,
        [name]: value,
      },
    });
  };
  handleTimelogTimeChange = (time, name) => {
    var newTime = moment(time).toDate();
    newTime.setSeconds(0);
    newTime.setMilliseconds(0);
    const CurrentTimelog = {
      ...this.state.CurrentTimelog,
      [name]: moment(newTime),
    };
    this.setState({
      CurrentTimelog,
    });
    this.saveTimelog(CurrentTimelog);
  };
  calculateTime(timelogList) {
    var totalHrs = 0,
      totalMins = 0;
    totalHrs = timelogList.reduce(
      (a, expense) => a + parseInt(expense.Duration.split(" ")[0]),
      0
    );
    totalMins = timelogList.reduce(
      (a, expense) => a + parseInt(expense.Duration.split(" ")[3]),
      0
    );
    while (totalMins >= 60) {
      totalHrs = totalHrs + 1;
      totalMins = totalMins - 60;
    }
    return totalHrs + " h and " + totalMins + " m";
  }

  saveTimelog(CurrentTimelog) {
    isAddingNew = false;
    const { TimeSheetStatusId, isTimerOn } = this.state;
    if (TimeSheetStatusId == 1 || TimeSheetStatusId == 2) return;
    const { Date, StartTime, EndTime, Memo } = CurrentTimelog;
    const { startDate, endDate } = this.props;
    var beginningTime = moment(StartTime, "h:mm a").valueOf();
    var endTime = moment(EndTime, "h:mm a").valueOf();
    var secindsremn = moment(EndTime)
      .set("second", 0)
      .format("HH:mm:ss");

    if (!Date || !StartTime || !EndTime) {
      return;
    } else if (beginningTime >= endTime && isTimerOn) {
      const info = {
        status: this.props.labels.Error_Message,
        message: this.props.labels.TIMER_MINUTE_VALIDATION_LABEL,
      };
      this.props.notificationAction(info);
    } else if (beginningTime >= endTime) {
      const info = {
        status: this.props.labels.Error_Message,
        message: this.props.labels.Timelog_Time_Error_Message,
      };
      this.props.notificationAction(info);
    } else {
      const { selectedCollaboration } = this.props;
      CurrentTimelog = {
        ...CurrentTimelog,
        CollaborationId: this.props.selectedCollaboration.value,
        StartTime: moment(StartTime)
          .set("second", 0)
          .format("HH:mm:ss"),
        EndTime: moment(EndTime)
          .set("second", 0)
          .format("HH:mm:ss"),
        StartDate: moment(startDate).format("DD/MM/YYYY"),
        EndDate: moment(endDate).format("DD/MM/YYYY"),
        HourlyRate:
          CurrentTimelog.TimeLogId > 0
            ? CurrentTimelog.HourlyRate
            : selectedCollaboration.HourlyRate,
        HourlyRateType:
          CurrentTimelog.TimeLogId > 0
            ? CurrentTimelog.HourlyRateType
            : selectedCollaboration.HourlyRateType,
      };
      this.setState({ isLoading: true });
      addTimeLogApi(CurrentTimelog, this.props.token)
        .then((res) => {
          if (res.success) {
            const newTimeLog = {
              ...res.items,
              isSelected: true,
              isActive: true,
              HourlyRateType: !isNaN(
                this.props.selectedCollaboration.HourlyRateType
              )
                ? this.props.Currencies.find(
                    (a) =>
                      a.CurrencyId ==
                      this.props.selectedCollaboration.HourlyRateType
                  ).Name
                : this.props.selectedCollaboration.HourlyRateType,
            };
            if (CurrentTimelog.TimeLogId < 1 || isTimerOn) {
              let newTimeList = this.state.timelogsList.concat(newTimeLog);
              newTimeList.sort(
                (a, b) => moment(b.Date).valueOf() - moment(a.Date).valueOf()
              );
              newTimeList = newTimeList.map((a) =>
                a.TimeLogId == newTimeLog.TimeLogId
                  ? {
                      ...a,
                      isSelected: true,
                      isActive: true,
                      Currency: !isNaN(a.Currency)
                        ? this.props.Currencies.find(
                            (b) => b.CurrencyId == a.Currency
                          ).Name
                        : a.Currency,
                    }
                  : {
                      ...a,
                      isSelected: false,
                      isActive: false,
                      Currency: !isNaN(a.Currency)
                        ? this.props.Currencies.find(
                            (b) => b.CurrencyId == a.Currency
                          ).Name
                        : a.Currency,
                    }
              );
              this.setState({
                isLoading: false,
                CurrentTimelog: !isAddingNew
                  ? {
                      ...newTimeLog,
                      StartTime: moment(
                        "2017-03-13" + " " + res.items.StartTime
                      ),
                      EndTime: moment("2017-03-13" + " " + res.items.EndTime),
                    }
                  : this.state.CurrentTimelog,
                timelogsList: newTimeList,
                TimeDetail: {
                  Amount: newTimeList.reduce(
                    (totalAmount, expense) => totalAmount + expense.Amount,
                    0
                  ),
                  Time: this.calculateTime(newTimeList),
                  HourlyRateType: !isNaN(
                    this.props.selectedCollaboration.HourlyRateType
                  )
                    ? this.props.Currencies.find(
                        (a) =>
                          a.CurrencyId ==
                          this.props.selectedCollaboration.HourlyRateType
                      ).Name
                    : this.props.selectedCollaboration.HourlyRateType,
                },
              });
              const info = {
                status: "success",
                message: this.props.labels.Timelog_Add_Message,
              };
              this.props.notificationAction(info);
            } else {
              let newTimeList = this.state.timelogsList.map((a) =>
                a.TimeLogId == newTimeLog.TimeLogId
                  ? newTimeLog
                  : { ...a, isSelected: false, isActive: false }
              );
              newTimeList = newTimeList.sort(
                (a, b) => moment(b.Date).valueOf() - moment(a.Date).valueOf()
              );
              this.setState({
                isLoading: false,
                timelogsList: newTimeList,
                TimeDetail: {
                  Amount: newTimeList.reduce(
                    (totalAmount, expense) => totalAmount + expense.Amount,
                    0
                  ),
                  Time: this.calculateTime(newTimeList),
                  HourlyRateType: !isNaN(
                    this.props.selectedCollaboration.HourlyRateType
                  )
                    ? this.props.Currencies.find(
                        (a) =>
                          a.CurrencyId ==
                          this.props.selectedCollaboration.HourlyRateType
                      ).Name
                    : this.props.selectedCollaboration.HourlyRateType,
                },
              });
              const info = {
                status: "success",
                message: this.props.labels.Timelog_Updated_Message,
              };
              this.props.notificationAction(info);
            }
          } else {
            const info = {
              status: this.props.labels.Error_Message,
              message:
                res.message == "Timelog already submitted against this time."
                  ? this.props.labels.Timelog_Already_Message
                  : res.message,
            };
            this.setState({ isLoading: false });
            this.props.notificationAction(info);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log("Err ", err);
        });
    }
  }

  getTimeLogs(selectedCollaboration, startDate, endDate) {
    this.setState({ isLoading: true });
    GetWeekAllTimeLogs(
      selectedCollaboration.value,
      new moment(startDate).format("DD/MM/YYYY"),
      new moment(endDate).format("DD/MM/YYYY"),
      this.props.token
    )
      .then((res) => {
        if (res.success) {
          this.setState({
            StatusId: res.items.StatusId,
            timelogsList: res.items.TimeLogs.map((a) => ({
              ...a,
              Currency: a.Currency,
            })),
            TimeSheetStatusId: res.items.StatusId,
            TimeDetail: {
              Amount: res.items.Amount.split(" ")[0],
              Time: this.calculateTime(res.items.TimeLogs),
              HourlyRateType: !isNaN(selectedCollaboration.HourlyRateType)
                ? this.props.Currencies.find(
                    selectedCollaboration.HourlyRateType
                  ).Name
                : selectedCollaboration.HourlyRateType,
            },
          });
          this.handleTimelogItemClick(res.items.TimeLogs[0]);
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }
  handleTimelogItemClick = (item) => {
    if (!item) {
      this.setState({
        CurrentTimelog: {
          TimeLogId: 0,
          StartTime: moment()
            .set("hour", 9)
            .set("minute", 0),
          EndTime: moment()
            .set("hour", 17)
            .set("minute", 0),
        },
        // IsCollapsed2:true
      });
      return;
    }
    this.setState({
      CurrentTimelog: {
        ...item,
        StartTime: moment("2017-03-13" + " " + item.StartTime),
        EndTime: moment("2017-03-13" + " " + item.EndTime),
      },
      timelogsList: this.state.timelogsList.map((a) =>
        a.TimeLogId == item.TimeLogId
          ? { ...a, isSelected: true, isActive: true }
          : { ...a, isSelected: false, isActive: false }
      ),
      IsCollapsed2: false,
    });
  };
  handleTimelogDelete = (item) => {
    DeleteATimeLogs(item.TimeLogId, this.props.token)
      .then((res) => {
        if (res.success) {
          const currentTimeLogId = this.state.CurrentTimelog.TimeLogId;
          const itemIndex =
            this.state.timelogsList.indexOf(item) + 1 ==
            this.state.timelogsList.length
              ? 0
              : this.state.timelogsList.indexOf(item);
          const newTimelogsList = this.state.timelogsList.filter(
            (a) => a.TimeLogId != item.TimeLogId
          );
          if (newTimelogsList.length === 0) {
            this.setState({
              TimeSheetStatusId: 4,
            });
          }
          this.setState({
            CurrentTimelog:
              this.state.CurrentTimelog.TimeLogId == item.TimeLogId
                ? {}
                : this.state.CurrentTimelog,
            timelogsList: newTimelogsList,
            TimeDetail: {
              Amount: newTimelogsList.reduce(
                (totalAmount, expense) => totalAmount + expense.Amount,
                0
              ),
              Time: this.calculateTime(newTimelogsList),
              HourlyRateType: !isNaN(
                this.props.selectedCollaboration.HourlyRateType
              )
                ? this.props.Currencies.find(
                    (a) =>
                      a.CurrencyId ==
                      this.props.selectedCollaboration.HourlyRateType
                  ).Name
                : this.props.selectedCollaboration.HourlyRateType,
            },
          });
          if (currentTimeLogId == item.TimeLogId) {
            this.handleTimelogItemClick(this.state.timelogsList[itemIndex]);
          }
          if (this.state.timelogsList.length == 0) {
            this.setState({ TimeSheetStatusId: 4 });
          }
        }
      })
      .catch((err) => console.log("Err ", err));
  };

  handleAddNewTimelog = () => {
    isAddingNew = true;
    this.setState({
      CurrentTimelog: {
        TimeLogId: 0,
        CollaborationId: this.props.selectedCollaboration.value,
        StartTime: moment()
          .set("hour", 9)
          .set("minute", 0),
        EndTime: moment()
          .set("hour", 17)
          .set("minute", 0),
      },
      IsCollapsed2: false,
    });
  };
  handleSendTimesheet = () => {
    this.setState({
      isLoading: true,
    });
    const { selectedCollaboration, startDate, endDate, token } = this.props;
    addTimesheetApi(
      selectedCollaboration.value,
      new moment(startDate).format("DD/MM/YYYY"),
      new moment(endDate).format("DD/MM/YYYY"),
      token
    ).then((res) => {
      if (res.success) {
        const info = {
          status: this.props.labels.Success_Message,
          message: this.props.labels.Timesheet_Add_Message,
        };
        this.props.notificationAction(info);
        this.setState({
          TimeSheetStatusId: 1,
        });
      } else {
        const info = {
          status: this.props.labels.Error_Message,
          message: res.message,
        };
        this.props.notificationAction(info);
      }
      this.setState({
        isLoading: false,
      });
    });
  };

  handleWeekChange = (type) => {
    this.props.handleSelectedDateChange(type);
  };

  handleFormFieldChange = (e) => {
    const { isTimerOn, currentTimelog } = this.state;
    const CurrentTimelog = {
      ...this.state.CurrentTimelog,
      Date: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };
    this.setState({
      CurrentTimelog,
      isTimerOn: !isTimerOn,
    });
  };

  render() {
    const {
      startDate,
      endDate,
      labels,
      isHelpActive,
      collaborationsList,
      selectedCollaboration,
      handleCollabSelectChange,
      handleSelectedDateChange,
      hours,
      minutes,
      seconds,
      stopTimer,
      pauseTimer,
      startTimer,
    } = this.props;

    const {
      timelogsList,
      IsCollapsed2,
      TimeDetail,
      isLoading,
      isTimerOn,
      TictellTimer,
    } = this.state;
    let currentDate = new Date();
    return (
      <div className="timelog-view">
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        <Column collapse={false} className="col-1 expense-list-col">
          <Column.Collapsed
            text={labels.timeStampDetails}
            onClick={() =>
              this.props.onStateChangeAction({ formCollapsed: false })
            }
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipFeedBackExpandCreate}
            tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
            tooltipPlace="left"
          />
          <Column.Head>
            <Select
              name="Category"
              className={`select-input select-hourlyRate`}
              value={selectedCollaboration}
              onChange={handleCollabSelectChange}
              clearable={false}
              searchable={false}
              options={collaborationsList}
            />
          </Column.Head>
          <div className={`date-change-wrapper`}>
            <div
              className={`date-header status-${this.state.TimeSheetStatusId}`}
            >
              <Button
                className="prevBtn"
                tesId={`prev-date`}
                onClick={() => this.handleWeekChange(1)}
                tooltipplace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.TictellPreviousLogBtn}
                tooltipButton={labels.ToolTipPreviousWeek}
              />
              <div className="date-heading">
                <div>
                  <label>{`${startDate.format("MMM Do")} - ${endDate.format(
                    "MMM Do"
                  )}`}</label>
                  <label>{`${moment(startDate).format("YYYY")} Week ${moment(
                    startDate
                  ).format("W")}`}</label>
                </div>
                <div>
                  <label>
                    {`${TimeDetail.Amount}  ${selectedCollaboration.HourlyRateType}`}
                  </label>
                  <label>{TimeDetail.Time}</label>
                </div>
              </div>
              <Button
                tesId={`next-date`}
                className={`nextBtn ${
                  moment(startDate).format("DD/MM/YYYY") ==
                  new moment().format("DD/MM/YYYY")
                    ? "disabled"
                    : ""
                }`}
                onClick={() => this.handleWeekChange(2)}
                tooltipplace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.TictellNextlogbtn}
                tooltipButton={labels.ToolTipNextWeek}
              />
            </div>
            <Button
              className="homeBtn"
              tesId={`current-week-btn`}
              onClick={() => this.handleWeekChange(3)}
              tooltipplace="left"
              isHelpActive={isHelpActive}
              tooltipHelp={labels.tictellCurrentWeeklogbtn}
              tooltipButton={labels.ToolTipCurrentWeek}
            >
              <i className="fa fa-home"></i>
            </Button>
          </div>
          <Column.Body>
            {this.state.timelogsList && (
              <TimelogList
                labels={labels}
                timelogList={this.state.timelogsList}
                onItemClick={this.handleTimelogItemClick}
                handleTimelogDelete={this.handleTimelogDelete}
                handleAddNewTimelog={this.handleAddNewTimelog}
                startDate={startDate}
                handleSelectedDateChange={handleSelectedDateChange}
                handleSendTimesheet={this.handleSendTimesheet}
                TimeSheetStatusId={this.state.TimeSheetStatusId}
                isHelpActive={isHelpActive}
                selectedCollaboration={selectedCollaboration}
                endDate={endDate}
              />
            )}
          </Column.Body>
        </Column>
        <Column collapse={IsCollapsed2} className="col-2">
          <Column.Collapsed
            text={labels.timeStampDetails}
            onClick={() => this.setState({ IsCollapsed2: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.HlpTooltipTimestampExpandCreate}
            tooltipHelp={labels.HlpTooltipTimestampExpandCreatebutton}
            tooltipPlace="left"
            testId={"column-2-expand"}
          />
          <Column.Head>
            <div className="heading">{labels.timeStampDetails}</div>
            <Button
              className={`collapseBtn ${
                (this.state.TimeSheetStatusId == 1 ||
                  this.state.TimeSheetStatusId == 2) &&
                !(this.state.CurrentTimelog.TimeLogId > 0)
                  ? "disabled"
                  : "gggg"
              }`}
              onClick={() => this.setState({ IsCollapsed2: true })}
              tooltipButton={labels.ToolTipTimestampCollaspeCreate}
              tooltipHelp={labels.HlpTooltipTimestampCollapseCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-2-collapse"}
            />
          </Column.Head>
          <Column.Body>
            {!(
              (!!selectedCollaboration &&
                moment(startDate).isSameOrAfter(
                  moment(selectedCollaboration.StartDate).startOf("w")
                ) &&
                moment(endDate).isSameOrBefore(
                  moment(selectedCollaboration.EndDate).endOf("w")
                )) ||
              this.state.CurrentTimelog.TimeLogId > 0
            ) ? (
              
              <EmptyInfo>
                {labels.TICTELL_TIMELOG_ADD_BETWEEN_START_END_DATE_LABEL}
              </EmptyInfo>
            ) : (
              <TimelogDetail
                labels={labels}
                isTimerOn={isTimerOn}
                TictellTimer={TictellTimer}
                currentTimelog={this.state.CurrentTimelog}
                handleTimelogDateChange={this.handleTimelogDateChange}
                handleTimelogInputChange={this.handleTimelogInputChange}
                handleTimelogInputBlur={this.handleTimelogInputBlur}
                handleTimeChange={this.handleTimelogTimeChange}
                startDate={startDate}
                endDate={endDate}
                status={this.state.StatusId}
                isDisabled={
                  this.state.TimeSheetStatusId == 1 ||
                  this.state.TimeSheetStatusId == 2
                    ? true
                    : false
                }
                isHelpActive
                selectedCollaboration={selectedCollaboration}
              ></TimelogDetail>
            )}
            {TictellTimer &&
              currentDate >= startDate &&
              currentDate <= endDate && (
                <div className={`input-field `}>
                  <h6 className="timerHeader">{labels.PRODOO_TIMER_LABEL}</h6>
                  <div className={"prodooTImerCheck"}>
                    <Input
                      type="checkbox"
                      name={"prodooTimer"}
                      value={isTimerOn}
                      checked={isTimerOn}
                      onChange={this.handleFormFieldChange}
                      test-data-id={"input-label-text"}
                    />
                    <span>{"Prodoo Time Tracker"}</span>
                  </div>
                </div>
              )}
            {isTimerOn &&
              TictellTimer &&
              currentDate >= startDate &&
              currentDate <= endDate && (
                <TimerComponent
                  saveTimelog={this.saveTimelog.bind(this)}
                  isTimerOn={isTimerOn}
                  start={startTimer}
                  pause={pauseTimer}
                  reset={stopTimer}
                  seconds={seconds}
                  minutes={minutes}
                  hours={hours}
                  handleTimelogDateChange={this.handleTimelogDateChange}
                />
              )}
          </Column.Body>
        </Column>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, feedback, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(mapStateToProps, { notificationAction })(TimeLogView);
