import axios from "axios";
import { ApiUrl } from "../../../../api/apiUrls";

export const getAllOpportunityApi = ({ url }) => {
  return axios
    .get(`${ApiUrl.Opportunity.GetRequestsByType}${url}`)
    .then(({ data }) => data)
    .catch(response => response);
};

export const updateCollaborationApi = ({ apiParams }) => {
  return axios
    .put(ApiUrl.Collaboration.UpdateCollaboration({ apiParams }))
    .then(({ data }) => data);
};

export const retrieveResumeSearchApi = savedSearchId => {
  return axios
    .get(
      `${
        ApiUrl.Opportunity.RetrieveResumeSearch
      }?savedSearchId=${savedSearchId}`
    )
    .then(({ data }) => data);
};

export const getOpportunitiesNewApi = (isFreelancer, userId) => {
  return axios
    .get(
      `${
        ApiUrl.Opportunity.GetRequests
      }?isFreelancer=${isFreelancer}&userId=${userId}&_dc=1538801130477&page=1&start=0&limit=25`
    )
    .then(({ data }) => {
      return data;
    });
};

export const getAllPresenationApi = () => {
  return axios.get(ApiUrl.Opportunity.GetAllPresentation).then(({ data }) => {
    return data;
  });
};

export const getSocialMediaApi = () => {
  return axios
    .get(`${ApiUrl.SocialMedia}?page=1&start=0&limit=25`)
    .then(({ data }) => data);
};

export const acceptOpportunityApi = info => {
  return axios
    .put(ApiUrl.Opportunity.AcceptOpportunity, info)
    .then(({ data }) => data);
};

export const declinedOpportunityApi = info => {
  return axios
    .put(ApiUrl.Opportunity.DeclinedOpportunity, info)
    .then(({ data }) => data);
};

export const deleteOpportunityApi = info => {
  return axios
    .put(ApiUrl.Opportunity.DeleteOpportunityByModel, info)
    .then(({ data }) => data);
};

export const opportunityRestoreApi = requestResumeId => {
  return axios
    .put(
      `${ApiUrl.Opportunity.RestoreRequest}?requestResumeId=${requestResumeId}`
    )
    .then(({ data }) => data);
};

export const readOpportunityApi = ({ requestResumeId }) => {
  return axios
    .put(ApiUrl.Opportunity.ReadOpportunity({ requestResumeId }))
    .then(({ data }) => data)
    .catch(response => response);
};
export const getCurrenciesApi = () => {
  return axios.get(ApiUrl.Currency.AllCurrenciesLookup).then(({ data }) => {
    return data;
  });
};
