import React, { Component } from "react";
import { Column, ConfirmDialog, Button } from "../../common";
import {
  feedbackApi,
  feedbackSubmitApi,
  feedbackDeleteApi
} from "./feedbackApi";
import { connect } from "react-redux";
import { find } from "lodash";
import { Messages } from "../../utilities";
import FeedbackList from "./components/FeedbackList";
import FeedbackDetail from "./components/FeedbackDetail";
import CreateFeedback from "./components/CreateFeedback";
import { PageWrapper } from "../../components";
import { notificationAction } from "../../actions";
import toLower from "lodash/toLower";
import filter from "lodash/filter";
import includes from "lodash/includes";

import fromPairs from "lodash/fromPairs";
import map from "lodash/map";

import "./feedback.scss";

class Feedback extends Component {
  state = {
    feedbackList: [],
    systemLabels: null,
    selectedFeedback: {},
    isCreateNew: false,
    feedbackForm: {},
    dialogMessage: "",
    deletedId: null,
    filteredList: []
  };
  componentDidMount() {
    this.getFeedbacks();
  }

  handleAddBtnHelp = e => {
    e.stopPropagation();
  };
  getFeedbacks = () => {
    feedbackApi().then(data => {
      if (data.success) {
        const feedbackList = data.items.map(item => {
          const [user, admin] = item.FeedbackDetails;
          return {
            ...item,
            admin,
            user: {
              ...user,
              Title: item.Title
            }
          };
        });
        this.setState({ feedbackList, filteredList: feedbackList });
      }
    });
  };

  handleFeedbackClick = id => {
    const { feedbackList } = this.state;
    const selectedFeedback = find(feedbackList, { FeedbackId: id });
    this.setState({ selectedFeedback, isCreateNew: false });
  };
  handleCreateNewClick = () => {
    this.setState({
      isCreateNew: true,
      selectedFeedback: {},
      feedbackForm: {}
    });
  };
  handleContentChange = (content, delta, source, editor) => {
    const { feedbackForm } = this.state;
    const data = {
      ...feedbackForm,
      content,
      isInvalidContent: !editor.getText().trim()
    };
    this.setState({ feedbackForm: data });
  };
  handleTitleChange = value => {
    const { feedbackForm } = this.state;
    const data = {
      ...feedbackForm,
      title: value,
      isInvalidTitle: !value
    };
    this.setState({ feedbackForm: data });
  };
  handleSubmitFeedback = () => {
    const { feedbackForm } = this.state;
    const {
      title,
      content,
      imgSrc,
      isInvalidContent,
      isInvalidTitle
    } = feedbackForm;
    if (!title || !content || isInvalidContent || isInvalidTitle) {
      const info = {
        message: this.props.labels.feedbackAddTitleB,
        status: "error"
      };
      this.props.notificationAction(info);
      this.setState({
        feedbackForm: {
          ...feedbackForm,
          isInvalidTitle: !title,
          isInvalidContent: isInvalidContent || !content
        }
      });
      return;
    }
    this.setState({
      feedbackForm: {
        ...feedbackForm,
        isSubmitting: true
      }
    });
    feedbackSubmitApi({ title, content, imgSrc }).then(data => {
      if (data.success) {
        this.getFeedbacks();
        this.setState({ feedbackForm: {}, isCreateNew: false });
        const info = {
          message: this.props.labels.feedbackSuccessfullySent,
          status: "success"
        };
        this.props.notificationAction(info);
      }
    });
  };
  handleDeleteFeedback = id => {
    this.setState({
      deletedId: id,
      dialogMessage: this.props.labels.AlrtDelMsg
    });
  };
  handleYesClick = () => {
    const { deletedId } = this.state;
    this.setState({ dialogMessage: "" });
    feedbackDeleteApi({ id: deletedId }).then(data => {
      if (data.success) {
        const info = {
          message: this.props.labels.feedbackSuccessfullyDeleted,
          status: "success"
        };
        this.props.notificationAction(info);
        this.getFeedbacks();
        this.setState({
          feedbackForm: {},
          selectedFeedback: {},
          deletedId: null
        });
      }
    });
  };
  handleNoClick = () => {
    this.setState({ dialogMessage: "", deletedId: null });
  };
  getImgSrc = src => {
    const { feedbackForm } = this.state;
    this.setState({
      feedbackForm: {
        ...feedbackForm,
        imgSrc: src
      }
    });
  };
  handleSearchChange = e => {
    const { value } = e.target;
    const { feedbackList } = this.props;

    const filteredList = filter(feedbackList, sl =>
      includes(toLower(sl.Title), toLower(value))
    );
    this.props.onStateChangeAction({ filteredList, searchKey: value });
  };

  render() {
    const {
      selectedFeedback,
      isCreateNew,
      feedbackForm,
      dialogMessage,
      filteredList,
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      systemLabels
    } = this.state;
    const { labels, isHelpActive } = this.props;
    const sytemLabel = systemLabels || labels;
    return (
      <PageWrapper className="feedback-page">
        {dialogMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <button className="dialog-btn" onClick={this.handleYesClick}>
                {Messages.feedback.confirm.Yes}
              </button>
              <button className="dialog-btn" onClick={this.handleNoClick}>
                {Messages.feedback.confirm.No}
              </button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={sytemLabel.ViewTitleFeedBackListB}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={sytemLabel.ToolTipFeedBackExpandListB}
            isHelpActive={isHelpActive}
            tooltipHelp={sytemLabel.HlpTooltipFeedbackExpandListButtonB}
            tooltipPlace="left"
          />
          <Column.Head>
            <input
              className="round-search"
              type="text"
              onChange={this.handleSearchChange}
              placeholder={sytemLabel.searcherFeedbackSearchB}
            />
            <Button
              tooltipButton={sytemLabel.TooltipFeedbackCreateButtonB}
              tooltipHelp={sytemLabel.HlpTooltipFeedbackCreateButtonB}
              tooltipPlace="left"
              className="AddBtn"
              onClick={this.handleCreateNewClick}
              isHelpActive={isHelpActive}
            />
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={sytemLabel.ToolTipFeedBackCollaspeListB}
              tooltipHelp={sytemLabel.HlpTooltipFeedbackCollapseListButtonB}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          <Column.Body>
            <FeedbackList
              feedbackList={filteredList}
              handleFeedbackClick={this.handleFeedbackClick}
              selectedFeedback={selectedFeedback}
              handleDeleteFeedback={this.handleDeleteFeedback}
            />
          </Column.Body>
        </Column>
        {!isCreateNew && (
          <Column collapse={isCollapsed2} className="col-2">
            <Column.Collapsed
              text={sytemLabel.ViewTitleFeedBackDetailB}
              onClick={() => this.setState({ isCollapsed2: false })}
              isHelpActive={isHelpActive}
              tooltipButton={sytemLabel.ToolTipFeedBackExpandDetailB}
              tooltipHelp={sytemLabel.HlpTooltipFeedbackExpandDetailButtonB}
              tooltipPlace="left"
            />
            <Column.Head>
              <div className="heading">
                {selectedFeedback.FeedbackId
                  ? sytemLabel.searcherFeedbackDetailB
                  : ""}
              </div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed2: true })}
                tooltipButton={sytemLabel.ToolTipFeedBackCollaspeDetailB}
                tooltipHelp={sytemLabel.HlpTooltipFeedbackCollapseDetailButtonB}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
              />
            </Column.Head>
            <Column.Body className="flex">
              <FeedbackDetail selectedFeedback={selectedFeedback} />
            </Column.Body>
          </Column>
        )}
        {isCreateNew && (
          <Column collapse={isCollapsed3} className="col-2">
            <Column.Collapsed
              text={sytemLabel.ViewTitleFeedBackCreateB}
              onClick={() => this.setState({ isCollapsed3: false })}
              isHelpActive={isHelpActive}
              tooltipButton={sytemLabel.ToolTipFeedBackExpandCreateB}
              tooltipHelp={sytemLabel.HlpTooltipFeedbackExpandCreateButtonB}
              tooltipPlace="left"
            />
            <Column.Head>
              <div className="heading">
                {sytemLabel.searcherFeedbackCreateB}
              </div>
              <Button
                onClick={this.handleSubmitFeedback}
                className="SendBtn"
                disabled={feedbackForm.isSubmitting}
                tooltipButton={sytemLabel.TooltipFeedbackSendButtonB}
                tooltipHelp={sytemLabel.HlpTooltipFeedbackSendButtonB}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
              />
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed3: true })}
                tooltipButton={sytemLabel.ToolTipFeedBackCollaspeCreateB}
                tooltipHelp={sytemLabel.HlpTooltipFeedbackCollapseCreateButtonB}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
              />
            </Column.Head>
            <Column.Body>
              <CreateFeedback
                handleContentChange={this.handleContentChange}
                handleTitleChange={this.handleTitleChange}
                feedbackForm={feedbackForm}
                getImgSrc={this.getImgSrc}
              />
            </Column.Body>
          </Column>
        )}
      </PageWrapper>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Feedback);
