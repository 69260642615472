import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, find, map, filter, includes, toLower, head } from "lodash";
import { PageWrapper } from "../../../../components";
import last from "lodash/last";
import {
  Column,
  ConfirmDialog,
  Button,
  EmptyInfo,
  SearchInput,
  LoadingMaskRow,
  LoadingMask,
} from "../../../../common";
import ShortlistResumeDetail from "../../../Shortlist/component/ShortlistResumeDetail";
import ShortlistResumes from "./components/ShortlistResumes";
import { notificationAction } from "../../../../actions";
import "./opportunity.scss";
import {
  getShortlistResumesApi,
  getSentOpportunityDetailApi,
  deleteSendOpportunityApi,
  deleteOpportunityAndAssociationsApi,
  getOpportunityResumesApi,
  getSentOpportunitesApi,
  getSavedSearchesApi,
  getAllLocationsApi,
} from "../../../Opportunity/opportunityApi";
import { updateOpportunity, updateRequestName } from "../../headsupApi";
import OpportunityDetail from "./components/OpportunityDetail";
import {
  getShortlistResumeApi,
  GetShortlistResumeCount,
} from "../../../Shortlist/shortlistApi";
import UserDetail from "../../../Collaboration/common/components/UserDetail";
import {
  getOpportunityIprosResumeApi,
  getCurrenciesApi,
} from "../../../Collaboration/common/collaborationApi";
import { onStateChangeAction } from "../../../Opportunity/Searcher/searcherOpportunitySentAction";
class Opportunity extends Component {
  state = {
    declinedRequests: "",
    acceptedRequests: "",
    pendingRequests: "",
    searchkey: "",
    isSearchFocus: false,
    selectedOpportunity: [],
    allCurrenciesList: [],
    filterOpportunities: [],
    selectedShortlists: [],
    locations: [],
    iprosList: [],
    searchListHover: false,
  };

  getOpportunities = () => {
    const { isFreelancer } = this.props;
    this.props.onStateChangeAction({ isFetching: true });
    getSentOpportunitesApi({ isFreelancer }).then((response) => {
      const opportubitiesList = response.items.Sent.map((item) => ({
        ...item,
        isActive: false,
        isSelected: false,
      }));
      if (response.success) {
        this.setState({
          allSentOpportunities: opportubitiesList,
          filterOpportunities: opportubitiesList,
        });
        this.props.onStateChangeAction({ isFetching: false });
        this.handleOpportunityClick({
          selectedOpportunity: opportubitiesList[0],
        });
      }
    });
  };
  getSavedSearches = () => {
    getSavedSearchesApi().then((response) => {
      if (response.success) {
        const { items } = response;
        const lastItem = last(response.items);
        this.setState({
          savedSearches: map(items, ({ SearchName, SavedSearchId }) => {
            return { label: SearchName, value: SavedSearchId };
          }),
          selectedSaveSearch:
            items.length > 0
              ? {
                  label: lastItem.SearchName,
                  value: lastItem.SavedSearchId,
                }
              : {},
        });
      }
    });
  };
  componentDidMount() {
    this.getOpportunities();
    this.getCurrencies();
    this.getSavedSearches();
    this.getLocations();
  }

  replaceObjectKeys = ({ oldObject, mappingObject }) => {
    return Object.keys(oldObject).reduce((acc, key) => {
      acc[mappingObject[key]] = oldObject[key];
      return acc;
    }, {});
  };
  getOpportunityIProsResume = (requestId) => {
    getOpportunityIprosResumeApi(requestId).then((data) => {
      if (data.success) {
        const iprosList = map(data.items.user, (item) => {
          const newItem = {
            ...item,
            value: item.ResumeId,
            label: item.UserName,
          };

          return newItem;
        });
        this.setState({
          iprosList,
        });
      }
    });
  };
  handleOpportunityClick = ({ selectedOpportunity }) => {
    if (!selectedOpportunity) return;
    const { allSentOpportunities } = this.state;
    selectedOpportunity.isNameEdit = false;
    let listItems = [];
    listItems =
      allSentOpportunities && allSentOpportunities.length > 0
        ? allSentOpportunities.map((item) => {
            if (
              (item.RequestId != selectedOpportunity.RequestId) &
              (item.isActive == true)
            ) {
              item.isActive = false;
              item.isSelected = false;
            } else if (item.RequestId == selectedOpportunity.RequestId) {
              item.isActive = true;
              item.isSelected = true;
            }
            return item;
          })
        : "";
    this.setState({
      allSentOpportunities: listItems,
      selectedOpportunity: selectedOpportunity,
    });
    const { RequestId } = selectedOpportunity;
    this.getOpportunityIProsResume(RequestId);
    let selectedShortlist = null;
    this.props.onStateChangeAction({
      currentOpportunityId: RequestId,
      isFetchingShortlist: true,
      isFetchingShortlistResume: true,
      selectedShortlists: [],
      selectedOpportunity,
      shortlistResumes: [],
      selectedResume: {},
      selectedIpro: {},
      hideShortList: false,
    });

    getSentOpportunityDetailApi({ RequestId })
      .then((response) => {
        if (response.success) {
          let { items } = response;
          this.setState({
            declinedRequests: items.Declined + items.Deleted,
            acceptedRequests: items.Accepted,
            pendingRequests:
              items.Total - (items.Declined + items.Deleted) - items.Accepted,
          });
          let { ShortLists } = items;
          items.FeeCurrencyType = this.state.allCurrenciesList.find(
            (x) => x.CurrencyId == items.FeeCurrencyType
          ).Name;
          const returnedTarget = Object.assign(items, selectedOpportunity);
          this.setState({
            selectedOpportunity: returnedTarget,
          });
          if (isEmpty(ShortLists)) {
            this.props.onStateChangeAction({
              selectedShortlists: [],
              selectedShortlist: null,
              shortlistResumes: [],
              selectedResume: {},
              selectedIpro: {},
              isFetchingShortlist: false,
            });
            return;
          }
          selectedShortlist = head(ShortLists);
          this.props.onStateChangeAction({
            selectedShortlists: ShortLists,
            selectedShortlist,
            isFetchingShortlist: false,
          });
          GetShortlistResumeCount(RequestId).then((data) => {
            if (data.success) {
              this.setState({
                declinedRequests: data.items.declinedReq,
                acceptedRequests: data.items.acceptedReq,
                pendingRequests: data.items.pendingReq,
              });
            }
          });
          getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
            .then((response) => {
              if (response.success) {
                const { items } = response;
                this.props.onStateChangeAction({
                  shortlistResumes: items,
                  selectedResume: {},
                  selectedIpro: {},
                  isFetchingShortlistResume: false,
                });
              }
            })
            .catch(() => {});
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({
          isFetchingShortlist: false,
          isFetchingShortlistResume: false,
        });
      });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { allSentOpportunities } = this.state;
    const filterOpportunities = filter(allSentOpportunities, (opportunity) =>
      includes(toLower(opportunity.RequestName), toLower(value))
    );
    this.setState({
      filterOpportunities: filterOpportunities,
      searchkey: value,
    });
  };

  handleOpportunityDelete = ({ selectedOpportunity, e }) => {
    e.stopPropagation();
    this.handleOpportunityClick({ selectedOpportunity });
    this.props.onStateChangeAction({
      selectedOpportunity,
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftOpportunityDeleteConformationMsg,
    });
  };
  handleYesClick = () => {
    const { yesClickCount, currentOpportunityId } = this.props;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: this.props.labels
          .InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg,
        yesClickCount: yesClickCount + 1,
      });
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: "",
      yesClickCount: 0,
    });

    this.props.onStateChangeAction({ isLoading: true });
    deleteOpportunityAndAssociationsApi({
      RequestId: currentOpportunityId,
    })
      .then((response) => {
        if (!response.success) {
          this.props.onStateChangeAction({
            isLoading: false,
            selectedOpportunity: null,
          });
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        } else {
          this.props.onStateChangeAction({ isLoading: false });
          const info = {
            message: response.message,
            status: "succes",
          };
          this.props.notificationAction(info);
        }
        this.props.onStateChangeAction({ isLoading: false });
        setTimeout(() => {
          this.deleteOpportunity({ RequestId: currentOpportunityId });
        }, 5000);
      })
      .catch(() => {
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
        });
      });
  };
  deleteOpportunity = ({ RequestId }) => {
    deleteSendOpportunityApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const info = {
            message: this.props.labels.oppDeletedSuccessfully,
            status: "success",
          };
          this.props.notificationAction(info);
          this.getOpportunities();
          this.props.onStateChangeAction({
            isLoading: false,
            selectedOpportunity: null,
            opportunity: null,
          });
        }
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      })
      .catch(() => {
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      });
  };
  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          this.setState({ allCurrenciesList: allCurrenciesList });
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  handleNoClick = () => {
    const { dialogMessage, currentOpportunityId } = this.props;
    const { labels } = this.props;
    if (
      dialogMessage ===
      labels.InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg
    ) {
      this.props.onStateChangeAction({ isLoading: true });
      this.deleteOpportunity({ RequestId: currentOpportunityId });
    }
    this.props.onStateChangeAction({ dialogMessage: "", yesClickCount: 0 });
  };

  handleSearchBlur = () => {
    const { searchListHover } = this.state;
    if (searchListHover) return;
    this.setState({ isSearchFocus: false });
  };

  handleShortListSelect = ({ selectedShortlist }) => {
    const { selectedShortlists } = this.props;
    const alreadySelected = filter(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId === selectedShortlist.ShortlistId
    );
    if (isEmpty(alreadySelected)) {
      this.props.onStateChangeAction({
        selectedShortlists: [...selectedShortlists, selectedShortlist],
      });
      this.setState({
        isSearchFocus: false,
      });
      return;
    }
    this.setState({ isSearchFocus: false });
  };
  handleSelectShortlist = (item) => {
    this.props.onStateChangeAction({
      selectedShortlist: item,
      shortlistResumes: [],
      selectedResume: {},
    });
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        if (data.success) {
          const selectedResume = head(data.items);
          const selectedIpro =
            this.state.iprosList.find(
              (ipro) => ipro.ResumeId === selectedResume.ResumeId
            ) || {};
          this.props.onStateChangeAction({
            shortlistResumes: data.items,
            fetchingResumes: false,
            isFetchingShortlistResume: false,
            selectedResume,
            selectedIpro,
          });
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({ fetchingResumes: false });
      });
  };

  handleSelectedShortlistClick = ({ selectedShortlist }) => {
    getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const selectedResume = head(items);
          const selectedIpro =
            this.state.iprosList.find(
              (ipro) => ipro.ResumeId == selectedResume.ResumeId
            ) || {};
          if (isEmpty(items)) {
            const info = {
              message: this.props.labels
                .InfoSearcherOpportunityDraftEmptyShortlist,
              status: "error",
            };
            this.props.notificationAction(info);
          }
          this.props.onStateChangeAction({
            selectedShortlist,
            shortlistResumes: items,
            selectedResume,
            selectedIpro,
            fetchingResumes: false,
            isFetchingShortlistResume: false,
          });
        }
      })
      .catch(() => {});
  };

  setFieldValue = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [name]: value,
      },
    });
  };

  handleResumeSelect = (selectedResume) => {
    const { shortlistResumes } = this.props;
    selectedResume = find(shortlistResumes, {
      ResumeId: selectedResume.ResumeId,
    });
    const selectedIpro =
      this.state.iprosList.find(
        (ipro) => ipro.ResumeId == selectedResume.ResumeId
      ) || {};
    this.props.onStateChangeAction({ selectedResume, selectedIpro });
  };

  handleOkClick = () => {
    this.handleOpportunityUpdate({ IsSent: true });
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  getOpportunityResumes = (searchkey) => {
    const { currentOpportunityId } = this.props;

    getOpportunityResumesApi({ requestId: currentOpportunityId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          this.props.onStateChangeAction({
            shortlistResumes: items,
            isFetchingShortlistResume: false,
          });
          let filteredList;
          if (searchkey == 1) {
            filteredList = items.filter((item) => item.IsConfirmed == true);
          } else if (searchkey == 2) {
            filteredList = items.filter(
              (item) => item.IsDeleted == true || item.IsDeclined == true
            );
          } else {
            filteredList = items.filter(
              (item) =>
                item.IsDeleted == false &&
                item.IsDeclined == false &&
                item.IsConfirmed == false
            );
          }
          const selectedResume = head(filteredList) || {};
          const selectedIpro =
            this.state.iprosList.find(
              (ipro) => ipro.ResumeId == selectedResume.ResumeId
            ) || {};
          this.props.onStateChangeAction({
            hideShortList: true,
            showResumeList: true,
            shortlistResumes: filteredList,
            isFetchingShortlistResume: false,
            selectedResume,
            selectedIpro,
          });
        }
      })
      .catch(() => {});
  };
  handleAcceptedResumes = () => {
    this.getOpportunityResumes(1);
  };
  handleDeclinedResumes = () => {
    this.getOpportunityResumes(2);
  };
  handleNoActionResumes = () => {
    this.getOpportunityResumes(3);
  };
  getLocations = () => {
    getAllLocationsApi().then((response) => {
      if (response.success) {
        const locations = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        this.setState({ locations: locations });
      }
    });
  };
  onOpprtunityNameUpdate = () => {
    let { selectedOpportunity } = this.state;
    updateRequestName(
      selectedOpportunity.RequestId,
      selectedOpportunity.RequestName
    )
      .then((res) => {
        if (res.success) {
          const info = {
            status: "success",
            message: this.props.labels.collaborationTitleUpdatedLabel,
          };
          this.props.notificationAction(info);
          const {
            allSentOpportunities,
            selectedOpportunity,
            filterOpportunities,
          } = this.state;
          const oppos = allSentOpportunities.map((item) =>
            item.RequestId == selectedOpportunity.RequestId
              ? { ...selectedOpportunity, isActive: true, isSelected: true }
              : item
          );
          this.setState({
            allSentOpportunities: oppos,
            filterOpportunities: filterOpportunities.map((item) =>
              item.RequestId == selectedOpportunity.RequestId
                ? { ...selectedOpportunity, isActive: true, isSelected: true }
                : item
            ),
            selectedOpportunity,
          });
        }
      })
      .catch((err) => console.log("Err ", err));
  };

  handleCollaborationNameEdit = () => {
    this.setState({
      selectedOpportunity: {
        ...this.state.selectedOpportunity,
        isNameEdit: !this.state.selectedOpportunity.isNameEdit,
      },
    });
  };
  onOpportunityChange = (e) => {
    const { value } = e.target;
    this.setState({
      selectedOpportunity: {
        ...this.state.selectedOpportunity,
        RequestName: value,
      },
    });
  };
  render() {
    const { labels, isHelpActive } = this.props;
    const {
      declinedRequests,
      acceptedRequests,
      pendingRequests,
      selectedOpportunity,
      filterOpportunities,
      searchkey,
    } = this.state;
    const {
      isEmptyCollapsed,
      listCollapse,
      detailCollapse,
      shortlistCollapse,
      resumeListCollapse,
      shortlistResumeCollapse,
      dialogMessage,
      isLoading,
      isFetching,
      isFetchingShortlist,
      isFetchingShortlistResume,
      selectedShortlists,
      selectedShortlist,
      shortlistResumes,
      selectedResume,
      hideShortList,
      showResumeList,
      selectedIpro,
      selectedAction,
    } = this.props;
    return (
      <PageWrapper className="headsup-opportunity-page">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              {dialogMessage ===
              this.props.labels
                .InfoSearcherOpportunityDraftSendConformationMsg ? (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-sent-ok"
                  onClick={this.handleOkClick}
                >
                  Ok
                </ConfirmDialog.Button>
              ) : (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-delete-yes"
                  onClick={this.handleYesClick}
                >
                  Yes
                </ConfirmDialog.Button>
              )}
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={listCollapse} className="col-1">
          <Column.Collapsed
            collapse={listCollapse}
            text={labels.SearcherOpportunityDraftViewTitleList}
            onClick={() =>
              this.props.onStateChangeAction({ listCollapse: false })
            }
            isHelpActive={isHelpActive}
            tooltipButton={
              labels.TooltipSearcherOpportunityDraftOpportunityListExpand
            }
            tooltipHelp={
              labels.HlpTooltipSearcherOpportunityDraftOpportunityListExpand
            }
            tooltipPlace="left"
            testId="opportunity-sent-list-expand-btn"
            testIdHelp="opportunity-sent-list-expand-help-btn"
          />
          <Column.Head>
            <SearchInput
              placeholder={labels.SearcherOpportunitySentSearchPlaceholder}
              onChange={this.handleSearchChange}
              testId="sent-opportunity-search-input"
              value={searchkey}
            />
            <Button
              onClick={() =>
                this.props.onStateChangeAction({ listCollapse: true })
              }
              className="collapseBtn"
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityListCollapse
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityListCollapse
              }
              testId="opportunity-sent-list-collapse-btn"
              testIdHelp="opportunity-sent-list-collapse-help-btn"
            />
          </Column.Head>
          <Column.Body>
            <React.Fragment>
              {isFetching ? (
                <LoadingMaskRow />
              ) : (
                <div className="tictell-list">
                  {filterOpportunities &&
                    filterOpportunities.map((item) => (
                      <div
                        key={item.RequestId}
                        test-data-id={`pools-list-item${
                          item.isActive ? "activeItem" : ""
                        }`}
                        className={`tictell-list-item-container  ${
                          item.isSelected ? "selected-item" : ""
                        } ${item.isActive ? "activeItem" : ""}`}
                      >
                        <div
                          onClick={() =>
                            this.handleOpportunityClick({
                              selectedOpportunity: item,
                            })
                          }
                          className="name-container"
                        >
                          <label
                            className="pool-label"
                            test-data-id={`collabs-list-owner-title`}
                          >
                            {item.RequestName}
                          </label>
                        </div>
                        <Button
                          onClick={(e) =>
                            this.handleOpportunityDelete({
                              selectedOpportunity: item,
                              e,
                            })
                          }
                          className="closeBtn"
                          tooltipButton={"Delete Opportumity"}
                          tooltipHelp={"Delete Shortlists"}
                          tooltipPlace="left"
                          isHelpActive={isHelpActive}
                          testId="opportunity-delete-btn"
                        />
                      </div>
                    ))}
                </div>
              )}
            </React.Fragment>
          </Column.Body>
        </Column>
        {isEmpty(selectedOpportunity) && (
          <Column collapse={isEmptyCollapsed} className="col-2">
            <Column.Collapsed
              collapse={isEmptyCollapsed}
              onClick={() =>
                this.props.onStateChangeAction({ isEmptyCollapsed: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleEmpty}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityEmptyExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityEmptyExpand
              }
              testId="opportunity-sent-detail-expand-btn"
              testIdHelp="opportunity-sent-detail-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive={false} />
              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({ isEmptyCollapsed: true })
                }
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunityEmptyCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunityEmptyCollapse
                }
                testId="opportunity-sent-detail-collapse-btn"
                testIdHelp="opportunity-sent-detail-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body className="flex">
              <EmptyInfo>{`${labels.SearcherOpportunityEmptyInfo}`}</EmptyInfo>
            </Column.Body>
          </Column>
        )}
        {!isEmpty(selectedOpportunity) && (
          <Column collapse={detailCollapse} className="col-3">
            <Column.Collapsed
              collapse={detailCollapse}
              onClick={() =>
                this.props.onStateChangeAction({ detailCollapse: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleDetail}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityDetailExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityDetailExpand
              }
              testId="opportunity-sent-detail-expand-btn"
              testIdHelp="opportunity-sent-detail-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive>
                {labels.SearcherOpportunityDraftDetailHeader}
              </Column.HeaderTitle>

              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ detailCollapse: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunityDetailCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunityDetailCollapse
                }
                testId="opportunity-sent-detail-collapse-btn"
                testIdHelp="opportunity-sent-detail-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body>
              {
                <OpportunityDetail
                  isNameEdit={this.state.isNameEdit}
                  handleCollaborationNameEdit={this.handleCollaborationNameEdit}
                  onOpportunityChange={this.onOpportunityChange}
                  onOpprtunityNameUpdate={this.onOpprtunityNameUpdate}
                  declinedRequests={declinedRequests}
                  acceptedRequests={acceptedRequests}
                  pendingRequests={pendingRequests}
                  selectedOpportunity={selectedOpportunity}
                  labels={labels}
                  onAcceptedResumes={this.handleAcceptedResumes}
                  onDeclinedResumes={this.handleDeclinedResumes}
                  onNoActionResumes={this.handleNoActionResumes}
                  selectedAction={selectedAction}
                  onSelectedActionChange={({ selectedAction }) =>
                    this.props.onStateChangeAction({
                      selectedAction,
                    })
                  }
                />
              }
            </Column.Body>
          </Column>
        )}
        {!hideShortList && !isEmpty(selectedOpportunity) && (
          <Column collapse={shortlistCollapse} className="col-4">
            <Column.Collapsed
              collapse={shortlistCollapse}
              onClick={() =>
                this.props.onStateChangeAction({ shortlistCollapse: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleShortlist}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityShortlistExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityShortlistExpand
              }
              testId="opportunity-sent-shortlist-expand-btn"
              testIdHelp="opportunity-sent-shortlist-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive>
                {labels.SearcherOpportunitySentSelectedShortlists}
              </Column.HeaderTitle>
              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ shortlistCollapse: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunityShortlistCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunityShortlistCollapse
                }
                testId="opportunity-sent-shortlist-collapse-btn"
                testIdHelp="opportunity-sent-shortlist-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body>
              <React.Fragment>
                {isFetchingShortlist ? (
                  <LoadingMaskRow />
                ) : (
                  <div className="tictell-list headsup-shortlists">
                    {selectedShortlists &&
                      selectedShortlists.map((item) => (
                        <div
                          key={item.ShortlistId}
                          test-data-id={`pools-list-item${
                            item.isActive ? "activeItem" : ""
                          }`}
                          className={`tictell-list-item-container  ${
                            selectedShortlist &&
                            item.ShortlistId == selectedShortlist.ShortlistId
                              ? "selected-item"
                              : ""
                          } ${
                            selectedShortlist &&
                            item.ShortlistId == selectedShortlist.ShortlistId
                              ? "activeItem"
                              : ""
                          }`}
                        >
                          <div
                            onClick={() =>
                              this.handleSelectedShortlistClick({
                                selectedShortlist: item,
                              })
                            }
                            className="name-container"
                          >
                            <label
                              className="pool-label"
                              test-data-id={`collabs-list-owner-title`}
                            >
                              {item.ShortlistName}
                            </label>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </React.Fragment>
            </Column.Body>
          </Column>
        )}
        {(showResumeList ||
          (!isEmpty(selectedOpportunity) && !isEmpty(shortlistResumes))) && (
          <Column collapse={resumeListCollapse} className="col-5">
            <Column.Collapsed
              collapse={resumeListCollapse}
              onClick={() =>
                this.props.onStateChangeAction({ resumeListCollapse: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleShortlistResume}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistExpand
              }
              testId="opportunity-sent-resumelist-expand-btn"
              testIdHelp="opportunity-sent-resumelist-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive>{`${shortlistResumes &&
                shortlistResumes.length} ${
                labels.SearcherOpportunityDraftShortlistResumeHeading
              }`}</Column.HeaderTitle>

              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ resumeListCollapse: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistCollapse
                }
                testId="opportunity-sent-resumelist-collapse-btn"
                testIdHelp="opportunity-sent-resumelist-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body>
              {isFetchingShortlistResume ? (
                <LoadingMaskRow />
              ) : !isEmpty(shortlistResumes) ? (
                <ShortlistResumes
                  selectedResume={selectedResume}
                  selectedShortlist={selectedShortlist}
                  onResumeSelect={this.handleResumeSelect}
                  shortlistResumes={shortlistResumes}
                  handleSelectShortlist={this.handleSelectShortlist}
                />
              ) : (
                <div className="empty-shortlist" />
              )}
            </Column.Body>
          </Column>
        )}
        {shortlistResumes.length > 0 &&
          !isEmpty(selectedOpportunity) &&
          !isEmpty(selectedResume) && (
            <Column collapse={shortlistResumeCollapse} className="col-6">
              <Column.Collapsed
                collapse={shortlistResumeCollapse}
                onClick={() =>
                  this.props.onStateChangeAction({
                    shortlistResumeCollapse: false,
                  })
                }
                text={labels.SearcherOpportunityDraftViewTitleResumeDetail}
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailExpand
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailExpand
                }
                testId="opportunity-sent-resumedetail-expand-btn"
                testIdHelp="opportunity-sent-resumedetail-expand-help-btn"
              />
              <Column.Head>
                <Column.HeaderTitle isActive>Resume Detail</Column.HeaderTitle>

                <Button
                  className="collapseBtn"
                  onClick={() =>
                    this.props.onStateChangeAction({
                      shortlistResumeCollapse: true,
                    })
                  }
                  isHelpActive={isHelpActive}
                  tooltipPlace="left"
                  tooltipButton={
                    labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailCollapse
                  }
                  tooltipHelp={
                    labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailCollapse
                  }
                  testId="opportunity-sent-resumedetail-collapse-btn"
                  testIdHelp="opportunity-sent-resumedetail-collapse-help-btn"
                />
              </Column.Head>
              <Column.Body>
                {isFetchingShortlistResume ? (
                  <LoadingMaskRow />
                ) : (
                  !isEmpty(selectedResume) && (
                    <div>
                      {!isEmpty(selectedIpro) && (
                        <UserDetail
                          selectedUser={selectedIpro}
                          labels={this.props.labels}
                        />
                      )}
                      <ShortlistResumeDetail
                        resume={selectedResume}
                        showAvailableTitle={isEmpty(selectedIpro)}
                      />
                    </div>
                  )
                )}
              </Column.Body>
            </Column>
          )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  searcherOpportunitySent,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { IsFreelancer } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    isFreelancer: IsFreelancer,
    isHelpActive,
    ...searcherOpportunitySent,
  };
};

export default connect(
  mapStateToProps,
  { notificationAction, onStateChangeAction }
)(Opportunity);
