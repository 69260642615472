import React from "react";
import { Col, Input, Row, Select, Space, Modal } from "antd";
import ImgSrc from "../../../../assets/images/company.svg";

const { TextArea } = Input;

const MobileFilter = (props) => {
  const {
    isLoading,
    isModalOpen,
    handleClose,
    presentForm,
    labels,
    profiles,
    onFormFieldChange,
    userEmails,
    userPhonenumbers,
    onFormSelectChange,
    onSelectBlur,
    onFileChange,
    getImgSrc,
    onFormFieldBlur,
    handleSave,
  } = props;
  const {
    Title,
    invalidTitle,
    UserName,
    invalidUserName,
    Profiles,
    invalidRole,
    UserEmails,
    WebUrl,
    invalidWebUrl,
    Detail,
    UserDetailId,
    selectedPhoneNumber,
    IsChecked,
    IsDefault,
  } = presentForm;
  const avatar = presentForm && presentForm.Logo;

  const handleOk = () => {
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
  };

  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        style={{ top: 20 }}
        onOk={handleOk}
        zIndex={100}
        onCancel={handleCancel}
        footer={false}
        className="new-search-resume-main for-mobile-modal-shortlist"
      >
        <div style={{ marginTop: "5px" }} className="new-search-resume-main">
          <div className="h-100 flex flex-column w-100">
            <div
              style={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ overflow: "auto" }} className="pr-2">
                <div className="companyBigIcon">
                  {avatar && (
                    <button className="closeBtn" onClick={getImgSrc} />
                  )}
                  <div className={`dashItemImg ${avatar ? "" : "no-dp"}`}>
                    <img
                      className="roundeImg"
                      src={avatar ? avatar : ImgSrc}
                      alt="company"
                    />
                  </div>
                  <div className="uploadBtn" test-data-id="company-avatar-btn">
                    <input
                      test-data-id="company-avatar-input"
                      type="file"
                      name="companyAvatar"
                      className="input-upload-file"
                      onChange={onFileChange}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="container-collapse">
                  <div className="new-collapse-main">
                    <p className="collapse-title"> {labels?.TITLE_LABEL}</p>
                  </div>
                  <Input
                    name="Title"
                    value={Title ? Title : ""}
                    placeholder={labels?.presentTitlePlaceholder}
                    onChange={onFormFieldChange}
                    onBlur={onFormFieldChange}
                    testId="input-text-present-title"
                    maxlength={"100"}
                    test-data-id="input-text-company-name"
                    maxLength="50"
                    size="large"
                    autoComplete="off"
                    className={`bg-color-for-opportunity  ${
                      invalidTitle ? "inValid" : ""
                    }`}
                    bordered={false}
                  />
                </div>
                <Row gutter={[12, 8]} className="container-collapse">
                  <Col xs={24} md={12}>
                    <div className="">
                      <div className="new-collapse-main">
                        <p className="collapse-title">
                          {" "}
                          {labels.USER_NAME_LABEL}
                        </p>
                      </div>
                      <Input
                        name="UserName"
                        className={`bg-color-for-opportunity ${
                          invalidUserName ? "inValid" : ""
                        }`}
                        value={UserName ? UserName : ""}
                        placeholder={labels.presentNamePlaceholder}
                        onChange={onFormFieldChange}
                        onBlur={onFormFieldChange}
                        testId="input-text-present-username"
                        maxlength={"50"}
                        size="large"
                        autoComplete="off"
                        bordered={false}
                      />
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="">
                      <div className="new-collapse-main">
                        <p className="collapse-title"> {labels.ROLE_LABEL}</p>
                      </div>
                      <Select
                        name="Profiles"
                        className={`bg-color-for-opportunity ${
                          invalidRole ? "inValid" : ""
                        }`}
                        value={Profiles && Profiles.ProfileValue}
                        placeholder={labels.presentPrimaryRolePlaceholder}
                        onChange={(e, selectedOption) =>
                          onFormSelectChange(
                            "Profiles",
                            "ProfileValue",
                            selectedOption
                          )
                        }
                        onBlur={onSelectBlur}
                        options={profiles}
                        clearable={false}
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        showArrow
                        bordered={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                      ></Select>
                    </div>
                  </Col>
                </Row>
                <Row gutter={[12, 8]} className="container-collapse">
                  <Col xs={24} md={12}>
                    <div className=" ">
                      <div className="new-collapse-main">
                        <p className="collapse-title"> {labels.PHONE_LABEL}</p>
                      </div>
                      <Select
                        name="UserPhonenumbers"
                        value={selectedPhoneNumber && selectedPhoneNumber}
                        placeholder={labels.presentPhoneNumberPlaceholder}
                        onChange={(e, selectedOption) =>
                          onFormSelectChange(
                            "UserPhonenumbers",
                            "UserPhonenumberValue",
                            selectedOption
                          )
                        }
                        autoBlur={true}
                        options={userPhonenumbers}
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        showArrow
                        className="bg-color-for-opportunity"
                        bordered={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                      ></Select>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className=" ">
                      <div className="new-collapse-main">
                        <p className="collapse-title"> {labels.EMAIL_LABEL}</p>
                      </div>
                      <Select
                        name="UserEmails"
                        value={UserEmails && UserEmails.UserEmailValue}
                        placeholder={labels.presentEmailPlaceholder}
                        onChange={(e, selectedOption) =>
                          onFormSelectChange(
                            "UserEmails",
                            "UserEmailValue",
                            selectedOption
                          )
                        }
                        options={userEmails}
                        clearable={false}
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        showArrow
                        className="bg-color-for-opportunity"
                        bordered={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                      ></Select>
                    </div>
                  </Col>
                </Row>

                <Row gutter={[12, 8]} className="container-collapse">
                  <Col xs={24} md={24}>
                    <div className="">
                      <div className="new-collapse-main">
                        <p className="collapse-title">
                          {" "}
                          {labels.WEB_URL_LABEL}
                        </p>
                      </div>
                      <Input
                        name="WebUrl"
                        className={`bg-color-for-opportunity ${
                          invalidWebUrl ? "inValid" : ""
                        }`}
                        value={WebUrl ? WebUrl : ""}
                        placeholder={labels.presentURLPlaceholder}
                        onChange={onFormFieldChange}
                        onBlur={onFormFieldBlur}
                        testId="input-text-present-weburl"
                        maxlength={"100"}
                        size="large"
                        autoComplete="off"
                        bordered={false}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="container-collapse">
                  <div className="new-collapse-main">
                    <TextArea
                      name="Detail"
                      value={Detail ? Detail : ""}
                      placeholder={labels.companyDescriptionPlaceholder}
                      onChange={onFormFieldChange}
                      test-data-id="input-button-company-detail"
                      rows={6}
                      className="bg-color-for-opportunity"
                      bordered={false}
                    />
                  </div>
                </div>
                <div className="container-collapse">
                  <div className="new-collapse-main">
                    <label className="companyCheckboxLabel">
                      <Input
                        type="checkbox"
                        name="IsDefault"
                        className={IsDefault ? "checked" : "input-checkbox"}
                        value={IsDefault}
                        checked={IsDefault ? IsDefault : false}
                        onChange={onFormFieldChange}
                        test-data-id="input-checkbox-company-defaultCompany"
                        disabled={IsChecked}
                      />
                      {labels.PresentationCheckboxMakeDefault}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <Row className="">
              <Space
                size={[8, 8]}
                style={{
                  margin: "auto",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <button
                  className="button-footer-opportunity pl-4 pr-4"
                  onClick={handleSave}
                >
                  Save
                </button>
              </Space>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default MobileFilter;
