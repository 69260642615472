import React from "react";
import ReactHtmlParser from "react-html-parser";
import "./emptyinfo.scss";

const EmptyInfo = ({ children, testId, child }) => {
  return (
    <div className="emptySkeletonResult" test-data-id={testId}>
      {ReactHtmlParser(children)}
      {child}
    </div>
  );
};

export default EmptyInfo;
