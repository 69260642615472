import axios from "axios";
import { ApiUrl } from "../../api";

export const getCompaniesApi = () => {
  return axios.get(ApiUrl.Dashboard.GetCompanies).then(({ data }) => data);
};

export const getResumeShortlistApi = () => {
  return axios
    .get(ApiUrl.Dashboard.GetResumeShortlist)
    .then(({ data }) => data);
};

export const getMessagesApi = () => {
  return axios.get(ApiUrl.Dashboard.GetMessages).then(({ data }) => data);
};

export const getOpportunitiesApi = () => {
  return axios.get(ApiUrl.Dashboard.GetOpportunities).then(({ data }) => data);
};

export const getPresentationApi = () => {
  return axios.get(ApiUrl.Dashboard.GetPresentation).then(({ data }) => data);
};

export const getResumeDetailApi = () => {
  return axios
    .get(ApiUrl.Dashboard.GetResumeDetailApi)
    .then(({ data }) => data);
};

export const getUserWidgets = (requiredFeaturesNames) => {
  return axios.post(ApiUrl.Dashboard.GetUserWidgets, requiredFeaturesNames).then(({ data }) => data);
};


export const getUserDataForDashboard = (isSearcher) => {
  return axios
    .get(ApiUrl.Dashboard.GetUserDataForDashboard(isSearcher))
    .then(({ data }) => data);
};
export const GetProfilesbyResumeId = () => {
  return axios
    .get(ApiUrl.Dashboard.GetProfilesbyResumeId)
    .then(({ data }) => data);
};

export const getUserDefaultPresentation = () => {
  return axios
    .get(ApiUrl.Dashboard.GetUserDefaultPresentation)
    .then(({ data }) => data);
};
export const getDefaultUserCompany = () => {
  return axios
    .get(ApiUrl.Dashboard.GetDefaultUserCompany)
    .then(({ data }) => data);
};

export const saveUpdatedResumeApi = ({ resume }) => {
  return axios
    .post(ApiUrl.ResumeEdit.SaveEditedResume, resume)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getEducationsApi = (searchKey) => {
  return axios
    .get(ApiUrl.ResumeEdit.EductionsLookup({ searchKey }))
    .then(({ data }) => {
      if (data.success && data.items.length > 0) {
        return data.items.map((Education) => ({
          label: Education.EducationValue,
          value: Education.EducationId,
        }));
      }
    })
    .catch((response) => response);
};
export const getRolesApi = (searchKey) => {
  return axios
    .get(ApiUrl.ResumeEdit.RolesLookup({ searchKey }))
    .then(({ data }) => {
      if (data.success && data.items.length > 0) {
        return data.items.map((profile) => ({
          label: profile.ProfileValue,
          value: profile.ProfileId,
        }));
      }
    })
    .catch((response) => response);
};

export const getIndustriesApi = (searchKey) => {
  return axios
    .get(ApiUrl.ResumeEdit.IndustriesLookup({ searchKey }))
    .then(({ data }) => {
      if (data.success && data.items.length > 0) {
        return data.items.map((industry) => ({
          label: industry.IndustryValue,
          value: industry.IndustryId,
        }));
      }
    })
    .catch((response) => response);
};

export const getKeywordApi = (searchKey) => {
  return axios
    .get(ApiUrl.ResumeEdit.KeywordLookup({ searchKey }))
    .then(({ data }) => {
      if (data.success && data.items.length > 0) {
        return data.items.map((keyword) => ({
          label: keyword.KeywordValue,
          value: keyword.KeywordId,
        }));
      }
    })
    .catch((response) => response);
};

export const getCertificationsApi = (searchKey) => {
  return axios
    .get(ApiUrl.ResumeEdit.CertificationsLookup({ searchKey }))
    .then(({ data }) => {
      if (data.success && data.items.length > 0) {
        return data.items.map((Certification) => ({
          label: Certification.CertificationValue,
          value: Certification.CertificationId,
        }));
      }
    })
    .catch((response) => response);
};

export const getSkillsApi = (searchKey) => {
  return axios
    .get(ApiUrl.ResumeEdit.SkillsLookup({ searchKey }))
    .then(({ data }) => {
      if (data.success && data.items.length > 0) {
        return data.items.map((Skill) => ({
          label: Skill.SkillValue,
          value: Skill.SkillId,
        }));
      }
    })
    .catch((response) => response);
};

export const getResumesApi = () => {
  return axios.get(ApiUrl.ResumeEdit.GetMyResume).then(({ data }) => data);
};

export const getSocialMediaApi = () => {
  return axios
    .get(`${ApiUrl.SocialMedia}?page=1&start=0&limit=25`)
    .then(({ data }) => data);
};

export const getMyResumeDetails = () => {
  return axios
    .get(ApiUrl.Dashboard.GetMyResumeDetails)
    .then(({ data }) => data);
};

export const SaveUserDashboardDetails = (info) => {
  return axios
    .post(ApiUrl.UserDetail.SaveUserDashboardDetails, info)
    .then(({ data }) => data);
};

export const getUserSocialMediaLinks = () => {
  return axios.get(ApiUrl.Settings.UserSocialLink).then(({ data }) => {
    return data;
  });
};
