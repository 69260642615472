import React from 'react';
import { Column, Button, Select, EmptyInfo } from "../../../../common";
import {
  GetResumesByResumeId,
  GetUserDetailByResumeAndRequestId,
  getAllCollaborationsApi,
  GetSharedCollaborationInfo,
  DeleteShareCollaborationApi,
  ShareCollaborationApi,
} from "../../senseApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import './collaborations.scss';
import ImgSrc from "../../../../assets/images/present.svg";
import CollaborationDetail from './components/CollaborationDetail';
import { isValidEmail } from '../../../../utilities';
import ResumeDetail from '../../components/ResumeDetail';
import Presentations from '../../components/PresentationDetails';
import moment from "moment";

class Collaborations extends React.Component {
  state = {
    options: [
      { label: "", value: 1 },
      { label: "", value: 2 }
    ],
    acceptedCollaborations: [],
    selectedUser: [],
    selectedResume: [],
    allCollaborations: [],
    isCollapsed1: false,
    isCollapsed2: false,
    isCollapsed3: true,
    isCollapsed4: true,
    isCollapsed5: true,
    allTimeSheets: [],
    weekTimelogsList: [],
    weekExpenselogsList: [],
    currentExpenselog: {},
    currentTimeReport: {
      StatusId: 1,
      isEmpty: true,
      Amount: "",
      Time: ""
    },
    currentTimelog: {},
    TimeSheetStatusId: 2,
    ExpenseSheetStatusId: 2,
    isLoading: true,
    selectedCollaboration: {},
    durationOptions: [
      { label: "Current Month", value: 1 },
      { label: "Last Month", value: 2 },
      { label: "Year to date", value: 3 }],
    StartDate: moment().startOf('months'),
    EndDate: moment(),
    TimeDetail: {},
    ExpenseDetail: {},
    selectedDuration: {},
    isEndDateCalendarOpen: false
  };
  componentDidMount() {
    const {
      MY_ACTIVE_COLLAB,
      SHARED_ACTIVE_COLLAB,
      MY_INACTIVE_COLLAB,
      ALL_ACTIVE_COLLAB,
    } = this.props.labels;
    this.setState({
      options: [
        { label: ALL_ACTIVE_COLLAB, value: 1 },
        { label: MY_INACTIVE_COLLAB, value: 2 }
      ],
      selectedOption: { value: 1, label: ALL_ACTIVE_COLLAB }
    });
    getAllCollaborationsApi(1, this.props.token).then(res => {
      if (res.items.length > 0) {
        const allCollaborations = res.items.map(coll => {
          return {
            ...coll,
            IsShared: coll.UserId != this.props.UserId,
            HourlyRateType: !isNaN(coll.HourlyRateType) ? (this.props.Currencies.find(a => a.CurrencyId == coll.HourlyRateType).Name) : coll.HourlyRateType
          }
        });
        this.setState({
          allCollaborations
        });
        const spliturl = this.props.locationProp.pathname.match(/\d+/g);
        if (spliturl != null) {
          let filteredCollab = allCollaborations.filter(coll => coll.CollaborationId == spliturl[0]);
          this.handleCollaborationClick(filteredCollab[0])
        }
        else {
          this.handleCollaborationClick(allCollaborations[0]);
        }
      }
      this.handleCollaborationClick(undefined);
    });
  }
  handleOptionChange = option => {
    this.setState({
      selectedOption: option
    });
    getAllCollaborationsApi(option.value, this.props.token).then(res => {
      if (res.items.length > 0) {
        const allCollaborations = res.items.map(coll => {
          return {
            ...coll,
            IsShared: coll.UserId != this.props.UserId,
            HourlyRateType: !isNaN(coll.HourlyRateType) ? (this.props.Currencies.find(a => a.CurrencyId == coll.HourlyRateType).Name) : coll.HourlyRateType
          }
        });
        this.setState({
          allCollaborations
        });
        this.handleCollaborationClick(allCollaborations[0], allCollaborations);
      }
      else {
        this.setState({
          allCollaborations: []
        })
        this.handleCollaborationClick(null);
      }
    });
  };
  renderOptionClass = option => {
    return "";
    if (!option) {
      return "";
    }
    switch (option.value) {
      case 1:
        return "yellow";
      case 2:
        return "green";
      case 3:
        return "red";
      default:
        return "";
    }
  };
  handleCollaborationClick = (item, collaborations = null) => {
    if (!item) {
      this.setState({
        selectedCollaboration: {},
        selectedResume: {},
        selectedUser: {}
      })
      return;
    }
    if (!item.IsShared) {
      GetSharedCollaborationInfo(item.CollaborationId, this.props.token)
        .then(res => {
          item = {
            ...item,
            SharedInfo: res.items.map(a => ({
              ...a,
              CollaborationId: item.CollaborationId
            }))
          }
          const updatedCollaborations = collaborations != null ? collaborations : this.state.allCollaborations;
          const allCollaborations = updatedCollaborations.map(a => ({
            ...a,
            isSelected: a.CollaborationId == item.CollaborationId,
            isActive: a.CollaborationId == item.CollaborationId,
            IsShared: a.UserId != this.props.UserId
          }));
          this.setState({
            selectedCollaboration: {
              ...item,
              newEmail: "",
              PreviousEndDate: item.EndDate
            },
            allCollaborations: allCollaborations,
            isCollapsed2: false,
            isCollapsed3: false
          })
        })
        .catch(err => console.log("Err ", err))
    } else {
      const updatedCollaborations = collaborations != null ? collaborations : this.state.allCollaborations;
      const allCollaborations = updatedCollaborations.map(a =>
        a.CollaborationId == item.CollaborationId
          ? { ...a, isSelected: true, isActive: true }
          : { ...a, isSelected: false, isActive: false }
      );
      this.setState({
        selectedCollaboration: {
          ...item,
          newEmail: "",
          PreviousEndDate: item.EndDate
        },
        allCollaborations: allCollaborations,
        isCollapsed2: false
      })
    }
    GetUserDetailByResumeAndRequestId(item.ResumeId, item.RequestId)
      .then(res => {
        if (res.success) {
          this.setState({
            selectedUser: res.items
          });
        }
      });
    GetResumesByResumeId(item.ResumeId)
      .then(res => {
        if (res.success) {
          this.setState({
            selectedResume: res.items
          });
        }
      })
      .catch(response => {
      });
  }
  handleShareCollaborationDelete = (item) => {
    DeleteShareCollaborationApi(item.CollaborationId, item.UserId, this.props.token)
      .then(res => {
        if (res.success) {
          this.setState({
            selectedCollaboration: {
              ...this.state.selectedCollaboration,
              SharedInfo: this.state.selectedCollaboration.SharedInfo.filter(a => a.UserId != item.UserId)
            }
          })
          const info = {
            status: "success",
            message: this.props.labels.SNAPSHOT_COLLABORATIONS_UN_SHARE_SUCCESS
          };
          this.props.notificationAction(info);
        }
      })
  }
  handleShareCollaborationInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      selectedCollaboration: {
        ...this.state.selectedCollaboration,
        newEmail: value
      }
    });
  }
  handleShareCollaboration = () => {
    const { CollaborationId, newEmail, SharedInfo } = this.state.selectedCollaboration;
    if (!isValidEmail(newEmail)) {
      const info = {
        status: "error",
        message: this.props.labels.VALID_EMAIL
      };
      this.props.notificationAction(info);
      return;
    }
    ShareCollaborationApi(CollaborationId, newEmail, this.props.token)
      .then(res => {
        if (res.success) {
          const sharedInfo = SharedInfo.concat({ CollaborationId: CollaborationId, UserEmail: newEmail, UserId: res.items.UserId })
          this.setState({
            selectedCollaboration: {
              ...this.state.selectedCollaboration,
              SharedInfo: sharedInfo,
              newEmail: "",
              PreviousEndDate: sharedInfo.EndDate
            }
          })
          const info = {
            status: "success",
            message: this.props.labels.SNAPSHOT_COLLABORATIONS_SHARE_SUCCESS
          };
          this.props.notificationAction(info);
        } else {
          let info = {};
          if (res.message == "Collaboration already shared with that user.") {
            info = {
              status: "error",
              message: this.props.labels.USER_COLLABORATION_SHARE_MSG
            };
          } else if (res.message == "Email Address Not exist in PDCore") {
            info = {
              status: "error",
              message: this.props.labels.USER_EMAIL_DOESNOT_EXIST
            };
          } else {
            info = {
              status: "error",
              message: this.props.labels.COLLABORATION_SHARE_FAILED
            };
          }
          this.props.notificationAction(info);
        }
      })
  }
  render() {
    const {
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      isCollapsed4,
      isCollapsed5,
      selectedOption,
      selectedCollaboration,
      selectedUser,
      selectedResume,
      isEndDateCalendarOpen
    } = this.state;
    const { labels, isHelpActive, UserId } = this.props;
    const {
      SNAPSHOT_COLLABORATIONS,
      SNAPSHOT_COLLABORATION_PRESENT,
      SNAPSHOT_COLLABORATION_RESUME
    } = labels;
    return (
      <div className="collaborations-view">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={SNAPSHOT_COLLABORATIONS}
            onClick={() => this.setState({ isCollapsed1: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn1"
          />
          <Column.Head>
            <div className="heading">{SNAPSHOT_COLLABORATIONS}</div>
            <Button
              className="collapseBtn"
              testId="Collapasebtn1"
              onClick={() => this.setState({ isCollapsed1: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          {!isCollapsed1 && (
            <Select
              readonly={true}
              searchable={false}
              testId={"collaborationDropdown"}
              name="AllPresentation"
               value={selectedOption}
              className={`select-input ${this.renderOptionClass(
                selectedOption
              )}`}
              placeholder={labels.presentPrimaryRolePlaceholder}
              onChange={selectedOption =>
                this.handleOptionChange(selectedOption)
              }
              options={this.state.options}
              clearable={false}
            />
          )}
          <Column.Body>
            <div className="tictell-list">
              {this.state.allCollaborations &&
                this.state.allCollaborations.map(item => (
                  <div
                    key={item.CollaborationId}
                    test-data-id={`collaborations-list-item${item.isActive ? "activeItem" : ""}`}
                    className={`tictell-list-item-container  ${item.isSelected ? "selected-item" : ""
                      } ${item.isActive ? "activeItem" : ""}`}
                    onClick={() => this.handleCollaborationClick(item)}
                  >
                    <div className={`dashItemImg ${item.PresenatationLogo ? "" : "no-dp"}`}>
                      <img
                        className="roundImg"
                        src={item.PresenatationLogo ? item.PresenatationLogo : ImgSrc}
                        alt={"UserName"}
                      />
                    </div>
                    <div className="name-container">
                      <label test-data-id={`collabs-list-owner-username`}>{item.UserName}</label>
                      <label test-data-id={`collabs-list-owner-title`}>{item.Title}</label>
                    </div>
                    <span className={`${item.IsShared ? "shared-icon" : ""}`} />
                  </div>
                ))}
            </div>
          </Column.Body>
        </Column>
        {!!selectedCollaboration && <Column collapse={isCollapsed2} className="col-2">
          <Column.Collapsed
            text={labels?.searcher_sense_home_column2_label}
            onClick={() => this.setState({ isCollapsed2: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipFeedBackExpandCreate}
            tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
            tooltipPlace="left"
            testId="expandbtn2"
          />
          <Column.Head>
            <div className="heading">{labels?.searcher_sense_home_column2_label}</div>
            <Button
              className="collapseBtn"
              onClick={() => this.setState({ isCollapsed2: true })}
              tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
              tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId="collapsebtn2"
            />
          </Column.Head>
          <Column.Body>
            <CollaborationDetail
              labels={labels}
              selectedCollaboration={selectedCollaboration}
              handleShareCollaborationDelete={this.handleShareCollaborationDelete}
              handleShareCollaborationInputChange={this.handleShareCollaborationInputChange}
              handleCollaborationShare={this.handleShareCollaboration}
              handleCollaborationEndDateChange={this.handleCollaborationEndDateChange}
              handleCollaborationExtend={this.handleCollaborationExtend}
              isEndDateCalendarOpen={isEndDateCalendarOpen}
              handleDateOnBlur={this.handleDateOnBlur}
            ></CollaborationDetail>
          </Column.Body>
        </Column>}
        {!!selectedCollaboration && <Column collapse={isCollapsed4} className="col-4">
          <Column.Collapsed
            text={SNAPSHOT_COLLABORATION_PRESENT}
            onClick={() => this.setState({ isCollapsed4: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipFeedBackExpandCreate}
            tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
            tooltipPlace="left"
            testId="expandbtn4"
          />
          <Column.Head>
            <div className="heading">{SNAPSHOT_COLLABORATION_PRESENT}</div>
            <Button
              className="collapseBtn"
              onClick={() => this.setState({ isCollapsed4: true })}
              tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
              tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId="collapsebtn4"
            />
          </Column.Head>
          <Column.Body>
            <Presentations
              selectedUser={selectedUser}
              labels={this.props.labels}
            />

          </Column.Body>
        </Column>}
        {!!selectedCollaboration && <Column collapse={isCollapsed5} className="col-5">
          <Column.Collapsed
            text={SNAPSHOT_COLLABORATION_RESUME}
            onClick={() => this.setState({ isCollapsed5: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipFeedBackExpandCreate}
            tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
            tooltipPlace="left"
            testId="expandbtn5"
          />
          <Column.Head>
            <div className="heading">{SNAPSHOT_COLLABORATION_RESUME}</div>
            <Button
              className="collapseBtn"
              onClick={() => this.setState({ isCollapsed5: true })}
              tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
              tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId="collapsebtn5"
            />
          </Column.Head>
          <Column.Body className="ios-height-fix">
            <ResumeDetail resume={selectedResume} />
          </Column.Body>
        </Column>}
      </div>
    )
  }
}


const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Collaborations);