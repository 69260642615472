import { ActionTypes } from "../../actions";
export const onStateChangeAction = ({
  companyList,
  filteredCompanies,
  companyForm,
  isLoading,
  isFetching,
  listCollapsed,
  formCollapsed,
  industries,
  countries,
  userEmails,
  userPhoneNumbers,
  selectedCompany,
  searchKey,
  dialogMessage
}) => dispatch => {
  companyList !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.COMPANY_LIST,
      payload: companyList
    });
  filteredCompanies !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.FILTERED_COMPANIES,
      payload: filteredCompanies
    });
  companyForm !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.COMPANY_FORM,
      payload: companyForm
    });
  isLoading !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.IS_LOADING,
      payload: isLoading
    });
  isFetching !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.IS_FETCHING,
      payload: isFetching
    });
  listCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.LIST_COLLAPSED,
      payload: listCollapsed
    });
  formCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.FORM_COLLAPSED,
      payload: formCollapsed
    });
  industries !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.INDUSTRIES,
      payload: industries
    });
  countries !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.COUNTRIES,
      payload: countries
    });
  userEmails !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.USER_EMAILS,
      payload: userEmails
    });
  userPhoneNumbers !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.USER_PHONE_NUMBERS,
      payload: userPhoneNumbers
    });
  selectedCompany !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.SELECTED_COMPANY,
      payload: selectedCompany
    });
  searchKey !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.SEARCH_KEY,
      payload: searchKey
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.COMPANY.DIALOG_MESSAGE,
      payload: dialogMessage
    });
};
