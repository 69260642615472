import React, { Component } from "react";
import { connect } from "react-redux";
import { htmlParser } from "../../../../utilities";
import MarketAnalyzer from "../NextStep/MarketAnalyzer";

class Professional extends Component {
  render() {
    const { labels } = this.props;
    return (
      <div className="professionalPanel">
        <h6 className="subHeading">{labels.Section2IndependentProfessionalDescription}</h6>
        <ul className="tabList">
          <li className="tabItem">
            <i className="resumeIcon" />
            <h5>{labels.Section2ProfessionalResumeTitle}</h5>
            <h6>{htmlParser(labels.Section2ProfessionalResumeDescription)}</h6>
          </li>
          <li className="tabItem">
            <i className="presentIcon" />
            <h5>{labels.Section2ProfessionalPresentTitle}</h5>
            <h6>{htmlParser(labels.Section2ProfessionalPresentDescription)}</h6>
          </li>
          <li className="tabItem">
            <i className="feedbackIcon" />
            <h5>{labels.Section2ProfessionalFeedbackTitle}</h5>
            <h6>{htmlParser(labels.Section2ProfessionalFeedbackDescription)}</h6>
          </li>
          <li className="tabItem">
            <i className="messageIcon" />
            <h5>{labels.Section2ProfessionalMessageTitle}</h5>
            <h6>{htmlParser(labels.Section2ProfessionalMessageDescription)}</h6>
          </li>
          <li className="tabItem">
            <i className="opportunityIcon" />
            <h5>{labels.Section2ProfessionalOpportunityTitle}</h5>
            <h6>{htmlParser(labels.Section2ProfessionalOpportunityDescription)}</h6>
          </li>
          <li className="tabItem">
            <i className="collaborationIcon" />
            <h5>{labels.Section2ProfessionalCollaborationTitle}</h5>
            <h6>{htmlParser(labels.Section2ProfessionalCollaborationDescription)}</h6>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(Professional);
