import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, find, map, filter, includes, toLower, head } from "lodash";
import moment from "moment";
import { PageWrapper } from "../../../components";
import {
  Column,
  ConfirmDialog,
  Button,
  EmptyInfo,
  SearchInput,
  LoadingMaskRow,
  BulletList,
  List,
  LoadingMask,
} from "../../../common";
import SearcherOpportunityForm from "./common/SearcherOpportunityForm";
import ShortlistResumeDetail from "../../Shortlist/component/ShortlistResumeDetail";
import ShortlistResumes from "../../Shortlist/component/ShortlistResumes";
import { notificationAction } from "../../../actions";
import "./searcherOpportunityDraft.scss";
import {
  getShortlistsApi,
  getShortlistResumesApi,
  getDraftOpportunityDetailApi,
  deleteOpportunityApi,
  deleteOpportunityAndAssociationsApi,
  getSavedSearchesApi,
  saveOpportunity,
  getAllLocationsApi,
  getAllLanguagesApi,
  getDraftOpportunitesApi,
} from "../../Opportunity/opportunityApi";
import { getCompaniesApi } from "../../Company/companyApi";
import {
  deleteResumeApi,
  getShortlistResumeApi,
} from "../../Shortlist/shortlistApi";
import { isNullOrUndefined } from "util";
import { onStateChangeAction } from "./searcherOpportunityDraftAction";
import { getCurrenciesApi } from "../../Collaboration/common/collaborationApi";

class SearcherOpportunityDraft extends Component {
  constructor(props) {
    super(props);

    this.selectedOpportunity = {
      invalidSelectedCompany: false,
      invalidRequestName: false,
      invalidFeeCurrencyType: false,
      invalidStartDate: false,
      invalidDurationType: false,
      invalidSelectedSavedSearch: false,
      Countries: [],
      Description: "",
      Duration: 4,
      DurationType: "",
      FeeCurrencyType: "",
      HourlyFee: "",
      Language: [],
      RequestName: "",
      StartDate: moment(),
    };
    this.state = {
      isSearchFocus: false,
      searchListHover: false,
    };
  }

  getOpportunities = () => {
    const { isFreelancer } = this.props;
    this.props.onStateChangeAction({ isFetching: true });
    getDraftOpportunitesApi({ isFreelancer }).then((response) => {
      const { success, items } = response;
      if (success) {
        const opportunities = !isEmpty(items) && items.Draft ? items.Draft : [];
        this.props.onStateChangeAction({
          opportunities,
          filterOpportunires: opportunities,
          isFetching: false,
        });
        const { onBoardOpportunityId } = this.props;
        if (onBoardOpportunityId) {
          this.props.onStateChangeAction({
            opportunities,
            filteredOpportunity: opportunities.filter(
              (a) => a.RequestId == onBoardOpportunityId
            ),
          });
          this.handleOpportunityClick({
            selectedOpportunity: opportunities.find(
              (a) => a.RequestId == onBoardOpportunityId
            ),
          });
        }
      }
    });
  };

  getShotlists = () => {
    getShortlistsApi()
      .then((response) => {
        if (response.success) {
          this.props.onStateChangeAction({
            shortlists: response.items,
            filterShortlists: response.items,
          });
        }
      })
      .catch((response) => response);
  };

  getLocations = () => {
    getAllLocationsApi().then((response) => {
      if (response.success) {
        const locations = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        this.props.onStateChangeAction({ locations });
      }
    });
  };
  getLanguages = () => {
    getAllLanguagesApi().then((response) => {
      if (response.success) {
        const languages = response.items.map((item) => ({
          ...item,
          value: item.LanguageId,
          label: item.LanguageValue,
        }));
        this.props.onStateChangeAction({ languages });
      }
    });
  };
  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          this.props.onStateChangeAction({ allCurrenciesList });
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  componentDidMount() {
    this.getOpportunities();
    this.getShotlists();
    this.getLanguages();
    this.getLocations();
    this.getCurrencies();
    getCompaniesApi().then((response) => {
      if (response.success) {
        const { items } = response;
        this.props.onStateChangeAction({
          userCompanies: map(items, ({ CompanyName, UserCompanyId }) => {
            return { label: CompanyName, value: UserCompanyId };
          }),
        });
      }
    });
    getSavedSearchesApi().then((response) => {
      if (response.success) {
        const { items } = response;
        this.props.onStateChangeAction({
          savedSearches: map(items, ({ SearchName, SavedSearchId }) => {
            return { label: SearchName, value: SavedSearchId };
          }),
        });
      }
    });
    const { onBoardOpportunityId } = this.props;
    if (onBoardOpportunityId) {
      this.getOpportunities();
    }
  }

  handleOpportunityClick = ({ selectedOpportunity }) => {
    const { RequestId } = selectedOpportunity;
    let selectedShortlist = null;
    this.props.onStateChangeAction({
      isFetchingShortlist: true,
      isFetchingShortlistResume: true,
      selectedShortlists: [],
      selectedOpportunity: {
        ...this.selectedOpportunity,
        ...selectedOpportunity,
      },
      shortlistResumes: [],
      selectedResume: {},
    });
    getDraftOpportunityDetailApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const {
            ShortLists,
            UserCompanyId,
            SavedSearchedId,
            Languages,
            Countries,
            ...rest
          } = items;
          const UserCompany = filter(
            this.props.userCompanies,
            (company) => company.value === UserCompanyId
          )[0];
          const SavedSearches = filter(
            this.props.savedSearches,
            (search) => search.value === SavedSearchedId
          )[0];
          if (isEmpty(ShortLists)) {
            selectedOpportunity = {
              ...this.props.selectedOpportunity,
              Language: Languages,
              Countries,
              FeeCurrencyType: this.props.allCurrenciesList.find(
                (x) => x.CurrencyId == items.FeeCurrencyType
              ),
              ...rest,
            };
            this.props.onStateChangeAction({
              selectedOpportunity,
              selectedShortlists: [],
              selectedShortlist: null,
              shortlistResumes: [],
              selectedResume: {},
              selectedCompany: UserCompany ? UserCompany : {},
              selectedSaveSearch: SavedSearches ? SavedSearches : {},
              isFetchingShortlist: false,
            });
            return;
          }
          selectedShortlist = head(ShortLists);

          this.props.onStateChangeAction({
            selectedOpportunity: {
              ...this.props.selectedOpportunity,
              Language: Languages,
              Countries,
              FeeCurrencyType: this.props.allCurrenciesList.find(
                (x) => x.CurrencyId == items.FeeCurrencyType
              ),
              ...rest,
            },
            selectedShortlists: ShortLists,
            selectedShortlist,
            selectedCompany: UserCompany ? UserCompany : {},
            selectedSaveSearch: SavedSearches ? SavedSearches : {},
            isFetchingShortlist: false,
          });
          getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
            .then((response) => {
              if (response.success) {
                const { items } = response;
                if (isEmpty(items)) {
                  const info = {
                    message: this.props.labels
                      .InfoSearcherOpportunityDraftEmptyShortlist,
                    status: "info",
                  };
                  this.props.notificationAction(info);
                  return;
                }
                this.props.onStateChangeAction({
                  shortlistResumes: items,
                  selectedResume: head(items),
                  isFetchingShortlistResume: false,
                });
              }
            })
            .catch((response) => {});
        }
      })
      .catch((response) => {
        this.props.onStateChangeAction({
          isFetchingShortlist: false,
          isFetchingShortlistResume: false,
        });
      });
  };

  handleDateChange = (date) => {
    const { selectedOpportunity } = this.props;
    try {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          invalidStartDate: false,
          StartDate: date,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { opportunities } = this.props;
    const filterOpportunires = filter(opportunities, (opportunity) =>
      includes(toLower(opportunity.RequestName), toLower(value))
    );
    this.props.onStateChangeAction({ filterOpportunires, searchKey: value });
  };

  handleOpportunityDelete = ({ selectedOpportunity, e }) => {
    e.stopPropagation();
    this.props.onStateChangeAction({
      opportunityToDelete: selectedOpportunity,
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftOpportunityDeleteConformationMsg,
    });
  };

  handleYesClick = () => {
    const {
      yesClickCount,
      opportunityToDelete,
      selectedSaveSearch,
    } = this.props;
    const { RequestId } = opportunityToDelete;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: this.props.labels
          .InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg,
        yesClickCount: yesClickCount + 1,
      });
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: "",
      yesClickCount: 0,
    });

    this.props.onStateChangeAction({ isLoading: true });
    deleteOpportunityAndAssociationsApi({
      RequestId,
    })
      .then((response) => {
        if (!response.success) {
          this.props.onStateChangeAction({
            isLoading: false,
            selectedOpportunity: null,
            opportunity: null,
          });
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        } else {
          this.props.onStateChangeAction({
            isLoading: false,
          });
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
        }
        setTimeout(() => {
          this.deleteOpportunity({ RequestId });
        }, 5000);
      })
      .catch((response) => {
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      });
  };

  deleteOpportunity = ({ RequestId }) => {
    deleteOpportunityApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const info = {
            message: this.props.labels.oppDeletedSuccessfully,
            status: "success",
          };
          this.props.notificationAction(info);
          this.getOpportunities();
          this.props.onStateChangeAction({
            isLoading: false,
            selectedOpportunity: null,
            opportunity: null,
          });
        }
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      })
      .catch((response) => {
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      });
  };
  handleNoClick = () => {
    const { labels } = this.props;
    const { dialogMessage } = this.props;
    if (
      dialogMessage ===
      labels.InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg
    ) {
      const {
        opportunityToDelete: { RequestId },
      } = this.props;
      this.props.onStateChangeAction({ isLoading: true });
      this.deleteOpportunity({ RequestId });
    }
    this.props.onStateChangeAction({ dialogMessage: "", yesClickCount: 0 });
  };

  handleSearchBlur = () => {
    const { searchListHover } = this.state;
    if (searchListHover) return;
    this.setState({ isSearchFocus: false });
  };

  handleShortListSelect = ({ selectedShortlist }) => {
    const { selectedShortlists } = this.props;
    const alreadySelected = filter(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId === selectedShortlist.ShortlistId
    );
    if (isEmpty(alreadySelected)) {
      this.props.onStateChangeAction({
        selectedShortlists: [...selectedShortlists, selectedShortlist],
      });
      this.setState({
        isSearchFocus: false,
      });
      return;
    }
    this.setState({ isSearchFocus: false });
  };

  handleFormSelectChange = (name, selectedOption) => {
    if (name === "selectedSavedSearch") {
      this.props.onStateChangeAction({
        selectedSaveSearch: isNullOrUndefined(selectedOption)
          ? null
          : selectedOption,
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidSelectedSavedSearch: false,
        },
      });
      return;
    }
    if (!selectedOption) return;
    if (name === "SelectedCompany") {
      this.props.onStateChangeAction({
        selectedCompany: selectedOption,
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidSelectedCompany: false,
        },
      });
      return;
    }
    if (name === "FeeCurrencyType") {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidFeeCurrencyType: false,
          FeeCurrencyType: selectedOption,
        },
      });
      return;
    }
    if (name === "DurationType") {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidDurationType: false,
          DurationType: selectedOption,
        },
      });
      return;
    }
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        [name]: selectedOption,
      },
    });
  };

  handleLanguageSelect = ({ label, value }) => {
    const { selectedOpportunity } = this.props;
    const alreadySelcted = filter(
      selectedOpportunity.Language,
      (language) => language.LanguageId === value
    );
    if (!isEmpty(alreadySelcted)) return;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        Language: [
          ...selectedOpportunity.Language,
          { LanguageId: value, LanguageValue: label },
        ],
      },
    });
  };

  handleLocationSelect = ({ label, value }) => {
    const { selectedOpportunity } = this.props;
    const alreadySelcted = filter(
      selectedOpportunity.Countries,
      (country) => country.CountryId === value
    );
    if (!isEmpty(alreadySelcted)) return;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        Countries: [
          ...selectedOpportunity.Countries,
          { CountryId: value, CountryName: label },
        ],
      },
    });
  };

  handleShortlistSearchChange = (e) => {
    const { value } = e.target;
    const { shortlists } = this.props;
    const filterShortlists = filter(shortlists, (shortlist) =>
      includes(toLower(shortlist.ShortlistName), toLower(value))
    );
    this.props.onStateChangeAction({ filterShortlists });
  };

  handleSelectedShortlistClick = ({ selectedShortlist }) => {
    getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const selectedResume = head(items);
          if (isEmpty(items)) {
            const info = {
              message: this.props.labels
                .InfoSearcherOpportunityDraftEmptyShortlist,
              status: "error",
            };
            this.props.notificationAction(info);
          }
          this.props.onStateChangeAction({
            selectedShortlist,
            shortlistResumes: items,
            selectedResume,
            isFetchingShortlistResume: false,
          });
        }
      })
      .catch((response) => {});
  };

  handleSelectedShortlistDelete = ({ shortlist, e }) => {
    e.stopPropagation();
    let { selectedShortlists } = this.props;
    selectedShortlists = filter(
      selectedShortlists,
      (item) => item.ShortlistId !== shortlist.ShortlistId
    );
    this.props.onStateChangeAction({
      selectedShortlists,
      shortlistResumes: [],
      selectedResume: {},
    });
  };

  validateField = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [`invalid${name}`]: !value,
        [name]: value,
      },
    });
  };

  setFieldValue = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [name]: value,
      },
    });
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "RequestName") {
      this.validateField({ name, value });
      return;
    }
    if (name === "HourlyFee") {
      if (isNaN(value)) {
        return;
      }
    }
    this.setFieldValue({ name, value });
  };

  handleResumeSelect = (selectedResume) => {
    const { shortlistResumes } = this.props;
    selectedResume = find(shortlistResumes, {
      ResumeId: selectedResume.ResumeId,
    });
    this.props.onStateChangeAction({ selectedResume });
  };

  handleResumeDelete = (item) => {
    let { shortlistResumes, selectedResume, selectedShortlist } = this.props;
    deleteResumeApi(item.ResumeId, selectedShortlist.ShortlistId)
      .then((data) => {
        if (data.success) {
          shortlistResumes = shortlistResumes.filter(
            (x) => x.ResumeId !== item.ResumeId
          );
          this.props.onStateChangeAction({
            shortlistResumes,
            selectedResume:
              item.ResumeId == selectedResume.ResumeId ? {} : selectedResume,
          });
        }
      })
      .catch((err) => console.log("Error ", err));
  };

  handleSliderChange = (value) => {
    const { selectedOpportunity } = this.props;
    if (value) {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          Duration: value,
        },
      });
    }
  };

  handleCountryDelete = ({ selectedCountry }) => {
    const { selectedOpportunity } = this.props;
    const filterCountries = filter(
      selectedOpportunity.Countries,
      (country) => country.CountryId !== selectedCountry.CountryId
    );
    this.props.onStateChangeAction({
      ...this.props,
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        Countries: filterCountries,
      },
    });
  };

  handleLanguageDelete = ({ selectedLanguage }) => {
    const { selectedOpportunity } = this.props;
    const filterLanguages = filter(
      selectedOpportunity.Language,
      (country) => country.LanguageId !== selectedLanguage.LanguageId
    );
    this.props.onStateChangeAction({
      ...this.props,
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        Language: filterLanguages,
      },
    });
  };

  handleOkClick = () => {
    this.handleOpportunityUpdate({ IsSent: true });
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  handleSelectShortlist = (item) => {
    this.props.onStateChangeAction({
      selectedShortlist: item,
      shortlistResumes: [],
      selectedResume: {},
    });
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        this.props.onStateChangeAction({
          shortlistResumes: data.items,
          fetchingResumes: false,
          selectedResume: head(data.items) || {},
        });
      })
      .catch((err) => {
        this.props.onStateChangeAction({ fetchingResumes: false });
      });
  };

  handleOpportunitySend = () => {
    const {
      selectedShortlists,
      selectedOpportunity,
      selectedCompany,
      selectedSaveSearch,
    } = this.props;
    const {
      RequestName,
      StartDate,
      DurationType,
      HourlyFee,
      FeeCurrencyType,
    } = selectedOpportunity;
    if (!RequestName) {
      const info = {
        message: this.props.labels.InfoSearcherOpportunityDraftTitleRequiredMsg,
        status: "error",
      };
      this.validateField({ name: "RequestName", value: RequestName });
      this.props.notificationAction(info);
      return;
    }
    if (!StartDate) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftStartDateRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      this.validateField({ name: "StartDate", value: StartDate });
      return;
    }
    if (!DurationType) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftDurationTypeRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      this.validateField({ name: "DurationType", value: DurationType });
      return;
    }
    if (HourlyFee && !FeeCurrencyType) {
      const info = {
        message: this.props.labels.collCurrancyTypeRequired,
        status: "error",
      };
      this.props.notificationAction(info);
      const { selectedOpportunity } = this.props;
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidFeeCurrencyType`]: !FeeCurrencyType,
          FeeCurrencyType: FeeCurrencyType,
        },
      });
      return;
    }
    if (isEmpty(selectedCompany)) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftUserCompanyRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      const { selectedOpportunity } = this.props;
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidSelectedCompany`]: true,
          selectedCompany: selectedCompany,
        },
      });
      return;
    }

    if (isEmpty(selectedShortlists)) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftSendEmptyShortlistMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftSendConformationMsg,
    });
  };

  handleOpportunityUpdate = ({ IsSent = false }) => {
    const {
      selectedOpportunity,
      selectedShortlists,
      selectedCompany,
      selectedSaveSearch,
    } = this.props;
    const { invalidRequestName, RequestName } = selectedOpportunity;

    if (!RequestName) {
      this.validateField({ name: "RequestName", value: RequestName });
      const info = {
        message: this.props.labels.InfoSearcherOpportunityDraftTitleRequiredMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }

    if (IsSent) {
      if (selectedCompany && !selectedCompany.value) {
        const info = {
          message: this.props.labels
            .InfoSearcherOpportunityDraftCompanyRequiredMsg,
          status: "error",
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({
          selectedOpportunity: {
            ...selectedOpportunity,
            invalidSelectedCompany: true,
          },
        });
        return;
      }
    }

    const LanguageIds = map(
      selectedOpportunity.Language,
      (language) => language.LanguageId
    );
    const LocationsIds = map(
      selectedOpportunity.Countries,
      (country) => country.CountryId
    );

    const ShortListIds = map(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId
    );

    const opportunity = {
      Countries: [],
      CreateDate: selectedOpportunity.CreateDate,
      Description: selectedOpportunity.Description,
      Duration: selectedOpportunity.Duration,
      FeeCurrencyType: selectedOpportunity.FeeCurrencyType
        ? typeof selectedOpportunity.FeeCurrencyType === "string"
          ? selectedOpportunity.FeeCurrencyType
          : selectedOpportunity.FeeCurrencyType.value
        : null,
      DurationType: selectedOpportunity.DurationType
        ? typeof selectedOpportunity.DurationType === "string"
          ? selectedOpportunity.DurationType
          : selectedOpportunity.DurationType.value
        : null,
      EndDate: selectedOpportunity.EndDate,
      HourlyFee: selectedOpportunity.HourlyFee,
      IsSent,
      Language: [],
      LanguageIds,
      LocationsIds,
      RequestCountries: null,
      RequestId: selectedOpportunity.RequestId,
      RequestName: selectedOpportunity.RequestName,
      SavedSearchedId: selectedSaveSearch ? selectedSaveSearch.value : null,
      ShortListIds,
      StartDate: selectedOpportunity.StartDate,
      UserCompanyId: selectedCompany ? selectedCompany.value : null,
    };
    this.props.onStateChangeAction({ opportunitySaveLoading: true });
    saveOpportunity({ opportunity }).then((response) => {
      if (response.success) {
        if (IsSent) {
          this.props.onStateChangeAction({ selectedOpportunity: null });
          const info = {
            message: this.props.labels
              .InfoSearcherOpportunityDraftSendSuccessfullyMsg,
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: this.props.labels
              .InfoSearcherOpportunityDraftSavedSuccessfullyMsg,
            status: "success",
          };
          this.props.notificationAction(info);
        }
        this.getOpportunities();
        this.props.onStateChangeAction({ opportunitySaveLoading: false });
        return;
      }

      const info = {
        message: response.message,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({ opportunitySaveLoading: false });
    });
  };
  render() {
    const { labels, isHelpActive } = this.props;
    const {
      isEmptyCollapsed,
      listCollapsed,
      formCollapsed,
      shortlistCollapsed,
      resumesCollapsed,
      resumeDetailCollapsed,
      dialogMessage,
      isLoading,
      isFetching,
      isFetchingShortlist,
      isFetchingShortlistResume,
      filterOpportunires,
      selectedOpportunity,
      filterShortlists,
      selectedShortlists,
      selectedShortlist,
      shortlistResumes,
      selectedResume,
      selectedSaveSearch,
      selectedCompany,
      savedSearches,
      userCompanies,
      languages,
      locations,
      opportunitySaveLoading,
      searchKey,
      allCurrenciesList,
    } = this.props;
    const { isSearchFocus } = this.state;
    return (
      <PageWrapper className="searcher-draft-opportunity-page">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              {dialogMessage ===
              labels.InfoSearcherOpportunityDraftSendConformationMsg ? (
                <ConfirmDialog.Button
                  testId="searcher-opportunity-draft-ok-btn"
                  onClick={this.handleOkClick}
                >
                  Ok
                </ConfirmDialog.Button>
              ) : (
                <ConfirmDialog.Button
                  testId="searcher-opportunity-draft-yes-btn"
                  onClick={this.handleYesClick}
                >
                  Yes
                </ConfirmDialog.Button>
              )}
              <ConfirmDialog.Button
                testId="searcher-opportunity-draft-no-btn"
                onClick={this.handleNoClick}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {!this.props.onBoardOpportunityId && (
          <Column collapse={listCollapsed} className="col-1">
            <Column.Collapsed
              collapse={listCollapsed}
              text={labels.SearcherOpportunityDraftViewTitleList}
              onClick={() =>
                this.props.onStateChangeAction({ listCollapsed: false })
              }
              isHelpActive={isHelpActive}
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityListExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityListExpand
              }
              tooltipPlace="left"
            />
            <Column.Head>
              <SearchInput
                placeholder={labels.SearcherOpportunityDraftSearchPlaceholder}
                onChange={this.handleSearchChange}
                value={searchKey}
                testId="searcher-opportunity-draft-search-input"
              />
              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ listCollapsed: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunityListCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunityListCollapse
                }
              />
            </Column.Head>
            <Column.Body>
              {isFetching ? (
                <LoadingMaskRow />
              ) : (
                <>
                  {filterOpportunires?.length > 0 ? (
                    <BulletList testId="searcher-opportunity-draft-opportunity-list">
                      {map(filterOpportunires, (opportunity) => (
                        <BulletList.ListItem
                          isActive={false}
                          isSelected={
                            selectedOpportunity &&
                            opportunity.RequestId ===
                              selectedOpportunity.RequestId
                          }
                          onClick={() =>
                            this.handleOpportunityClick({
                              selectedOpportunity: opportunity,
                            })
                          }
                          key={opportunity.RequestId}
                          testId="draft-opportunity-list-item"
                        >
                          <label className="listLabel">
                            {opportunity.RequestName}
                          </label>
                          <BulletList.Close
                            testId="searcher-draft-opportunity-delete-btn"
                            onClick={(e) =>
                              this.handleOpportunityDelete({
                                selectedOpportunity: opportunity,
                                e,
                              })
                            }
                          />
                        </BulletList.ListItem>
                      ))}
                    </BulletList>
                  ) : (
                    <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                  )}
                </>
              )}
            </Column.Body>
          </Column>
        )}
        {isEmpty(selectedOpportunity) && (
          <Column collapse={isEmptyCollapsed} className="col-2">
            <Column.Collapsed
              collapse={isEmptyCollapsed}
              onClick={() =>
                this.props.onStateChangeAction({ isEmptyCollapsed: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleEmpty}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityEmptyExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityEmptyExpand
              }
            />
            <Column.Head>
              <Column.HeaderTitle isActive={false} />
              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({ isEmptyCollapsed: true })
                }
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunityEmptyCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunityEmptyCollapse
                }
              />
            </Column.Head>
            <Column.Body className="flex">
              <EmptyInfo>{labels.SearcherOpportunityEmptyInfo}</EmptyInfo>
            </Column.Body>
          </Column>
        )}
        {!isEmpty(selectedOpportunity) && (
          <Column collapse={formCollapsed} className="col-1">
            <Column.Collapsed
              collapse={formCollapsed}
              onClick={() =>
                this.props.onStateChangeAction({ formCollapsed: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleDetail}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityDetailCollapse
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityDetailCollapse
              }
            />
            <Column.Head>
              <Column.HeaderTitle isActive>
                {labels.SearcherOpportunityDraftDetailHeader}
              </Column.HeaderTitle>

              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ formCollapsed: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunityDetailExpand
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunityDetailExpand
                }
              />
            </Column.Head>
            <Column.Body>
              <SearcherOpportunityForm
                selectedOpportunity={selectedOpportunity}
                selectedCompany={selectedCompany}
                selectedSaveSearch={selectedSaveSearch}
                userCompanies={userCompanies}
                savedSearches={savedSearches}
                allLanguages={languages}
                allLocations={locations}
                isLoading={opportunitySaveLoading}
                onFormSelectChange={this.handleFormSelectChange}
                onCollaborationSave={this.handleCollaborationSave}
                onFormFieldChange={this.handleFormFieldChange}
                onDateChange={this.handleDateChange}
                onSliderChange={this.handleSliderChange}
                onLanguageDelete={this.handleLanguageDelete}
                onCountryDelete={this.handleCountryDelete}
                onLanguageSelect={this.handleLanguageSelect}
                onLocationSelect={this.handleLocationSelect}
                onOpportunityUpdate={this.handleOpportunityUpdate}
                onOpportunitySend={this.handleOpportunitySend}
                titlePlaceholder={
                  labels.SearcherOpportunityDraftDetailTitlePlaceholder
                }
                startDateLabel={labels.SearcherOpportunityDraftDetailStartDate}
                durationLabel={labels.SearcherOpportunityDraftDetailDuration}
                durationPlaceholder={
                  labels.SearcherOpportunityDraftDetailDurationPlaceholder
                }
                hourlyFeeLabel={labels.SearcherOpportunityDraftDetailHourlyFee}
                hourlyFeePlaceholder={
                  labels.SearcherOpportunityDraftDetailHourlyFeePlaceholder
                }
                hourlyFeeDropDownPlaceholder={
                  labels.SearcherOpportunityDraftDetailHourlyFeeDropDownPlaceholder
                }
                companyLabel={labels.SearcherOpportunityDraftDetailCompany}
                companyPlaceholder={
                  labels.SearcherOpportunityDraftDetailCompanyPlaceholder
                }
                locationLabel={labels.SearcherOpportunityDraftDetailLocation}
                locationPlaceholder={
                  labels.SearcherOpportunityDraftDetailLocationPlaceholder
                }
                languageLabel={labels.SearcherOpportunityDraftDetailLanguage}
                languagePlaceholder={
                  labels.SearcherOpportunityDraftDetailLanguagePlaceholder
                }
                savedSearchLabel={
                  labels.SearcherOpportunityDraftDetailSavedSearch
                }
                savedSearchPlaceholder={
                  labels.SearcherOpportunityDraftDetailSavedSearchPlaceholder
                }
                descriptionPlaceholder={
                  labels.SearcherOpportunityDraftDetailDescriptionPlaceholder
                }
                saveButtonText={labels.SearcherOpportunityDraftDetailSave}
                sendButtonText={labels.SearcherOpportunityDraftDetailSend}
                allCurrencies={allCurrenciesList}
              />
            </Column.Body>
          </Column>
        )}
        {!isEmpty(selectedOpportunity) && (
          <Column collapse={shortlistCollapsed} className="col-1">
            <Column.Collapsed
              collapse={shortlistCollapsed}
              onClick={() =>
                this.props.onStateChangeAction({ shortlistCollapsed: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleShortlist}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityShortlistCollapse
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityShortlistCollapse
              }
            />
            <Column.Head>
              <div className="search-list-wrapper">
                <SearchInput
                  placeholder={
                    labels.SearcherOpportunityDraftShortlistSearchPlaceholder
                  }
                  onChange={this.handleShortlistSearchChange}
                  onFocus={() => this.setState({ isSearchFocus: true })}
                  onBlur={this.handleSearchBlur}
                  testId="searcher-opportunity-draft-shortlist-search-input"
                />
                {isSearchFocus && (
                  <List
                    testId="searcher-opportunity-draft-shortlist"
                    className="searched-shortlists"
                  >
                    {map(filterShortlists, (shortlist) => (
                      <List.ListItem
                        key={shortlist.ShortlistId}
                        onClick={(e) =>
                          this.handleShortListSelect({
                            selectedShortlist: shortlist,
                          })
                        }
                        onMouseEnter={() =>
                          this.setState({
                            searchListHover: true,
                          })
                        }
                        onMouseLeave={() =>
                          this.setState({
                            searchListHover: false,
                          })
                        }
                        testId="searcher-opportunity-draft-shortlist-item"
                      >
                        {shortlist.ShortlistName}
                      </List.ListItem>
                    ))}
                  </List>
                )}
              </div>
              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ shortlistCollapsed: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunityShortlistExpand
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunityShortlistExpand
                }
              />
            </Column.Head>
            <Column.Body>
              {isFetchingShortlist ? (
                <LoadingMaskRow />
              ) : (
                <div>
                  <div className="shortlists-header">
                    {labels.SearcherOpportunityDraftShortlistHeading}
                  </div>
                  <BulletList
                    testId="searcher-draft-opportunity-selected-shortlist"
                    className="selected-shorlist"
                  >
                    {map(selectedShortlists, (shortlist) => (
                      <BulletList.ListItem
                        key={shortlist.ShortlistId}
                        isActive={false}
                        isSelected={
                          selectedShortlist &&
                          shortlist.ShortlistId ===
                            selectedShortlist.ShortlistId
                        }
                        onClick={() =>
                          this.handleSelectedShortlistClick({
                            selectedShortlist: shortlist,
                          })
                        }
                        testId="searcher-draft-opportunity-selected-shortlist-item"
                      >
                        <label className="listLabel">
                          {shortlist.ShortlistName}
                        </label>
                        <BulletList.Close
                          testId="searcher-draft-opportunity-selected-shortlist-delete-btn"
                          onClick={(e) =>
                            this.handleSelectedShortlistDelete({ shortlist, e })
                          }
                        />
                      </BulletList.ListItem>
                    ))}
                  </BulletList>
                </div>
              )}
            </Column.Body>
          </Column>
        )}
        {!isEmpty(selectedOpportunity) && !isEmpty(shortlistResumes) && (
          <Column collapse={resumesCollapsed} className="col-2">
            <Column.Collapsed
              collapse={resumesCollapsed}
              onClick={() =>
                this.props.onStateChangeAction({ resumesCollapsed: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleShortlistResume}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistCollapse
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistCollapse
              }
            />
            <Column.Head>
              <Column.HeaderTitle isActive>{`${shortlistResumes &&
                shortlistResumes.length} ${
                labels.SearcherOpportunityDraftShortlistResumeHeading
              }`}</Column.HeaderTitle>

              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ resumesCollapsed: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistExpand
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistExpand
                }
              />
            </Column.Head>
            <Column.Body>
              {!isEmpty(shortlistResumes) && (
                <ShortlistResumes
                  selectedResume={selectedResume}
                  selectedShortlist={selectedShortlist}
                  fetchingResumes={isFetchingShortlistResume}
                  shortlistResumes={shortlistResumes}
                  onResumeSelect={this.handleResumeSelect}
                  handleSelectShortlist={this.handleSelectShortlist}
                  onResumeDelete={this.handleResumeDelete}
                />
              )}
            </Column.Body>
          </Column>
        )}
        {!isEmpty(selectedOpportunity) && !isEmpty(selectedResume) && (
          <Column collapse={resumeDetailCollapsed} className="col-2">
            <Column.Collapsed
              collapse={resumeDetailCollapsed}
              onClick={() =>
                this.props.onStateChangeAction({ resumeDetailCollapsed: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleResumeDetail}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailExpand
              }
            />
            <Column.Head>
              <Column.HeaderTitle isActive>Resume Detail</Column.HeaderTitle>

              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({
                    resumeDetailCollapsed: true,
                  })
                }
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailCollapse
                }
              />
            </Column.Head>
            <Column.Body>
              {isFetchingShortlistResume ? (
                <LoadingMaskRow />
              ) : (
                !isEmpty(selectedResume) && (
                  <ShortlistResumeDetail resume={selectedResume} />
                )
              )}
            </Column.Body>
          </Column>
        )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  searcherOpportunityDraft,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { IsFreelancer, UserId } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  const userId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  return {
    labels,
    isFreelancer: IsFreelancer,
    userId,
    isHelpActive,
    ...searcherOpportunityDraft,
  };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(SearcherOpportunityDraft);
