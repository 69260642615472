import React, { Component } from "react";
import { Panel, ListGroup, Select } from "../../../../common";
import { getAllLanguagesApi } from "../resumeEditApi";
import { connect } from "react-redux";
import { notificationAction } from "./../../../../actions";
import AsyncSelect from "react-select/lib/Async";
import { map, isEmpty } from "lodash";

class LanguagePanel extends Component {
  state = {
    languages: [],
  };
  componentDidMount = () => {
    this.getAllLanguages();
  };
  handleRolesLoad = (searchKey, callback) => {
    const { languages } = this.state;
    callback(null, {
      options: map(languages, (language) => {
        return {
          ...language,
          label: language.LanguageValue,
          value: language.LanguageId,
        };
      }),
    });
    return;
  };
  getAllLanguages = () => {
    getAllLanguagesApi().then((response) => {
      if (response.success) {
        const languages = response.items.map((item) => ({
          ...item,
          value: item.LanguageId,
          label: item.LanguageValue,
        }));
        this.setState({ languages });
      }
    });
  };
  LanguageLevelList = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Elementary" },
    { value: 3, label: "Intermediate" },
    { value: 4, label: "Advanced" },
    { value: 5, label: "Fluent" },
  ];
  render() {
    const {
      isHelpActive,
      languageList,
      onLanguageNameChange,
      onLanguageLevelChange,
      onLanguageDelete,
      onLanguageAdd,
      onLanguageBlur,
      labels,
    } = this.props;
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>{labels.RESUME_EDIT_LANGUAGE_SECTION_TITLE}</Panel.Title>
          <Panel.Add
            onClick={onLanguageAdd}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={labels.RESUME_EDIT_LANGUAGE_ADD_BUTTON_TOOLTIP}
            tooltipHelp={labels.RESUME_EDIT_LANGUAGE_ADD_BUTTON_HELP}
            testId="resume-edit-language-add-btn"
            testIdHelp="resume-edit-language-add-help-btn"
          />
        </Panel.Heading>
        <Panel.Body>
          <ListGroup>
            {languageList.map((item) => (
              <ListGroup.Item key={item.uniqueId}>
                <ListGroup.Column>
                  <AsyncSelect
                    className={`select-language-name select-input ${
                      item.isInvalid ? "inValid" : ""
                    }`}
                    placeholder={labels.RESUME_EDIT_LANGUAGE_NAME_PLACEHOLDER}
                    cacheOptions
                    defaultOptions
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    loadOptions={this.handleRolesLoad}
                    onChange={(options) =>
                      onLanguageNameChange(options, item.uniqueId)
                    }
                    value={{
                      value: item.LanguageId,
                      label: item.LanguageValue,
                    }}
                    onBlur={(options) =>
                      onLanguageBlur(
                        item.LanguageId,
                        item.uniqueId,
                        options,
                        item.ExperienceLevel
                      )
                    }
                    disabled={item.isCandidate}
                    clearable
                  />
                </ListGroup.Column>
                <ListGroup.Column>
                  <Select
                    name={"Language"}
                    className={`select-language-level select-input`}
                    value={item.ExperienceLevel}
                    onChange={(options) =>
                      onLanguageLevelChange(options, item.uniqueId)
                    }
                    options={this.LanguageLevelList}
                  />
                </ListGroup.Column>

                <ListGroup.Column size={0}>
                  <div
                    // disabled={isDisabled}
                    onClick={() => onLanguageDelete(item)}
                    className="close_btn_purple"
                  >
                    <i className="fa fa-times-circle" />
                  </div>
                </ListGroup.Column>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Panel.Body>
      </Panel>
    );
  }
}

export default LanguagePanel;
