import React, { Component } from "react";
import { Panel, ListGroup, Select } from "../../../../common";
import AsyncSelect from "react-select/lib/Async";
import { map, isEmpty } from "lodash";
import { getEducationsApi } from "../resumeEditApi";

class Education extends Component {
  state = {
    yearsList: [],
  };
  componentDidMount() {
    this.handleYearsLoad();
  }
  handleYearsLoad = () => {
    const currentYear = new Date().getFullYear();
    var startYear = 1970;
    var yearsList = [];
    while (startYear <= currentYear) {
      startYear++;
      const newYear = {
        value: startYear,
        label: startYear,
      };
      yearsList.push(newYear);
    }
    this.setState({
      yearsList,
    });
  };
  EducationLevelsList = [
    { value: 1, label: "School" },
    { value: 2, label: "Diploma" },
    { value: 3, label: "Graduation" },
    { value: 4, label: "Master" },
    { value: 5, label: "Doctorate" },
    { value: 6, label: "Bachelor" },
  ];
  handleEducationsLoad = (searchKey, callback) => {
    if (isEmpty(searchKey)) {
      const { educationDefaultOptions } = this.props;
      callback(null, {
        options: map(educationDefaultOptions, (Education) => {
          return {
            ...Education,
            label: Education.EducationValue,
            value: Education.EducationId,
          };
        }),
      });
      return;
    }
    if (searchKey.length < 3) {
      callback([]);
      return;
    }
    getEducationsApi({ searchKey }).then((response) => {
      if (response.success) {
        callback(null, {
          options: map(response.items, (Education) => {
            return {
              ...Education,
              label: Education.EducationValue,
              value: Education.EducationId,
            };
          }),
        });
      }
    });
  };

  render() {
    const {
      isHelpActive,
      labels,
      educationsList,
      onEducationNameChange,
      onEducationLevelChange,
      onEducationYearChange,
      onEducationDelete,
      onEducationAdd,
      onEducationBlur,
    } = this.props;
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>
            {labels.RESUME_EDIT_EDUCATION_SECTION_TITLE}
          </Panel.Title>
          <Panel.Add
            onClick={onEducationAdd}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={labels.RESUME_EDIT_EDUCATION_ADD_BUTTON_TOOLTIP}
            tooltipHelp={labels.RESUME_EDIT_EDUCATION_ADD_BUTTON_HELP}
            testId="resume-edit-education-add-btn"
            testIdHelp="resume-edit-education-add-help-btn"
          />
        </Panel.Heading>
        <Panel.Body>
          <ListGroup>
            {educationsList.map((item) => (
              <ListGroup.Item key={item.uniqueId}>
                <div className="select-education-item">
                  <div className="education-row row-flex-2">
                    <ListGroup.Column>
                      <Select
                        className="select-input select-education-type"
                        value={item.EducationTypeId}
                        onChange={(options) =>
                          onEducationLevelChange(options, item.uniqueId)
                        }
                        options={this.EducationLevelsList}
                      />
                    </ListGroup.Column>

                    <ListGroup.Column>
                      <AsyncSelect
                        className={`select-education-name select-input ${
                          item.isInvalid ? "inValid" : ""
                        }`}
                        placeholder={
                          labels.RESUME_EDIT_EDUCATION_NAME_PLACEHOLDER
                        }
                        cacheOptions
                        defaultOptions
                        searchable={true}
                        onBlurResetsInput={false}
                        onCloseResetsInput={false}
                        loadOptions={this.handleEducationsLoad}
                        onChange={(options) =>
                          onEducationNameChange(options, item.uniqueId)
                        }
                        value={{
                          value: item.EducationId,
                          label: item.EducationValue,
                        }}
                        disabled={item.isCandidate}
                        onBlur={(options) =>
                          onEducationBlur(
                            item.EducationId,
                            item.uniqueId,
                            options
                          )
                        }
                      />
                    </ListGroup.Column>
                  </div>
                  <div className="education-row row-flex-1">
                    <ListGroup.Column>
                      <Select
                        name={"Education Year"}
                        className={`select-education-level select-input`}
                        value={item.EducationYear}
                        onChange={(options) =>
                          onEducationYearChange(options, item.uniqueId)
                        }
                        options={this.state.yearsList}
                      />
                    </ListGroup.Column>
                  </div>
                </div>
                <ListGroup.Column size={0}>
                  <div
                    // disabled={isDisabled}
                    onClick={() => onEducationDelete(item)}
                    className="close_btn_purple"
                  >
                    <i className="fa fa-times-circle" />
                  </div>
                </ListGroup.Column>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Panel.Body>
      </Panel>
    );
  }
}

export default Education;
