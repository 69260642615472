import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { getResumeShortlistApi } from "../dashboardApi";
import Checklist from '../../../assets/images/checklist.png'
import ShortlistIcon from '../../../assets/images/dashboard-resume-shortlist.png'
const { Title, Text } = Typography;

function ShortlistWidget({ labels, history }) {
  const [shortlists, setShortlists] = useState([]);
  const [total, setTotal] = useState(0)
  const [isFetching, setFetching] = useState(false);
  useEffect(() => {
    getResumeShortlist();
  }, []);

  const getResumeShortlist = () => {
    setFetching(true);
    getResumeShortlistApi()
      .then((response) => {
        if (response.success) {
          setShortlists(response.items.Shortlist);
          setTotal(response?.items?.TotalShortlist)
        }
        setFetching(false);
      })
      .catch((response) => {
        setFetching(false);
      });
  };

  return (
    <div className="cards-new  d-flex flex-column gap-16">
      <div className="d-flex gap-16">
        <div>
          <img src={Checklist} style={{ height: "90px" }} alt="search" />
        </div>
        <div>
          <Title level={3}>Resume Shortlists</Title>
          <Text>{total || 0} shortlists added</Text>
        </div>
      </div>
      {shortlists.length > 0 &&
        shortlists?.map((item, index) => (
          <div className="inner-card" key={index}
            style={{ opacity: index == 3 ? .6 : 1 }}
          >
            <div>
              <img style={{height:"40px"}} src={ShortlistIcon} alt="search" />
            </div>
            <div style={{ width: "100%", overflow: "auto" }}>
              <Title ellipsis level={5}>{item.ShortlistName}</Title>
              <Text>{item.Resume} {" Resumes"}</Text>
            </div>
          </div>
        ))}
      <p className="view-all" onClick={() => history.push("/shortlists")}><span>View All</span></p>
    </div>
    // <div className="do-for-you mt-4">
    //   <div className="w-100">
    //     <Title className="mb-0" level={4}>
    //       {labels.DASHBOARD_SHORTLIST_RESUME_LBL}
    //     </Title>
    //     <Title className="mb-0 mt-2" level={5}>
    //       {shortlists?.slice(0,3)?.length} {labels.DASHBOARD_SHORTLIST_COUNT_LBL}
    //     </Title>
    //     {shortlists.length > 0 &&
    //       shortlists?.slice(0,3)?.map((item, index) => (
    //         <div className="d-flex w-100  justify-content-between">
    //           <Text className={classes.shortlistName}>
    //             {item.ShortlistName}
    //           </Text>
    //           <Text className={`text-muted ${classes.shortlistResumes}`}>
    //             {item.Resume} {" Resumes"}
    //           </Text>
    //         </div>
    //       ))}
    //   </div>
    // </div>
  );
}

export default ShortlistWidget;
