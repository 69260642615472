import React, { Component } from "react";
import { Column, ConfirmDialog, Button } from "../../common";
import {
  feedbackApi,
  feedbackSubmitApi,
  feedbackDeleteApi
} from "./feedbackApi";
import { connect } from "react-redux";
import { find } from "lodash";
import { Messages } from "../../utilities";
import FeedbackList from "./components/FeedbackList";
import FeedbackDetail from "./components/FeedbackDetail";
import CreateFeedback from "./components/CreateFeedback";
import { PageWrapper } from "../../components";
import { notificationAction } from "../../actions";

import "./feedback.scss";
import { onStateChangeAction } from "./feedbackAction";
import toLower from "lodash/toLower";
import filter from "lodash/filter";
import includes from "lodash/includes";

class Feedback extends Component {
  state = {
    isLoading: false
  };
  componentDidMount() {
    this.props.isFetching && this.getFeedbacks();
  }

  handleAddBtnHelp = e => {
    e.stopPropagation();
  };
  getFeedbacks = () => {
    feedbackApi().then(data => {
      if (data.success) {
        const feedbackList = data.items.map(item => {
          const [user] = item.FeedbackDetails;
          let admin = user.Status != 1 && item.FeedbackDetails.filter(item => item.Status == 1);

          return {
            ...item,
            admin,
            user: {
              ...user,
              Title: item.Title
            }
          };
        });
        this.props.onStateChangeAction({
          feedbackList,
          filteredList: feedbackList,
          isFetching: false
        });
        const spliturl = this.props.location.pathname.match(/\d+/g);
        if (spliturl != null) {
          let feedback = data.items.filter(a => a.FeedbackId == spliturl[0]);
          this.handleFeedbackClick(feedback[0].FeedbackId)
        }
      }
    });
  };

  handleFeedbackClick = id => {
    const { feedbackList } = this.props;
    const selectedFeedback = find(feedbackList, { FeedbackId: id });
    this.props.onStateChangeAction({ selectedFeedback, isCreateNew: false });
  };
  handleCreateNewClick = () => {
    this.props.onStateChangeAction({
      isCreateNew: true,
      selectedFeedback: {},
      feedbackForm: {}
    });
  };
  handleContentChange = (content, delta, source, editor) => {
    const { feedbackForm } = this.props;
    const data = {
      ...feedbackForm,
      content,
      isInvalidContent: !editor.getText().trim()
    };
    this.props.onStateChangeAction({ feedbackForm: data });
  };
  handleTitleChange = value => {
    const { feedbackForm } = this.props;
    const data = {
      ...feedbackForm,
      title: value,
      isInvalidTitle: !value
    };
    this.props.onStateChangeAction({ feedbackForm: data });
  };
  handleSubmitFeedback = () => {
    const { feedbackForm } = this.props;
    const {
      title,
      content,
      imgSrc,
      isInvalidContent,
      isInvalidTitle
    } = feedbackForm;
    if (!title || !content || isInvalidContent || isInvalidTitle) {
      const info = {
        message: this.props.labels.feedbackAddTitle,
        status: "error"
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        feedbackForm: {
          ...feedbackForm,
          isInvalidTitle: !title,
          isInvalidContent: isInvalidContent || !content
        }
      });
      return;
    }
    this.props.onStateChangeAction({
      feedbackForm: {
        ...feedbackForm,
        isSubmitting: true
      }
    });
    this.setState({ isLoading: true });
    feedbackSubmitApi({ title, content, imgSrc }).then(data => {
      if (data.success) {
        this.getFeedbacks();
        this.props.onStateChangeAction({
          feedbackForm: {},
          isCreateNew: false
        });
        const info = {
          message: this.props.labels.feedbackSuccessfullySent,
          status: "success"
        };
        this.setState({ isLoading: false });
        this.props.notificationAction(info);
      }
    });
  };

  handleDeleteFeedback = id => {
    this.props.onStateChangeAction({
      deletedId: id,
      dialogMessage: this.props.labels.AlrtDelMsg
    });
  };
  handleYesClick = () => {
    const { deletedId } = this.props;
    this.setState({ isLoading: true });
    this.props.onStateChangeAction({ dialogMessage: "" });
    feedbackDeleteApi({ id: deletedId }).then(data => {
      if (data.success) {
        const info = {
          message: this.props.labels.feedbackSuccessfullyDeleted,
          status: "success"
        };
        this.props.notificationAction(info);
        this.getFeedbacks();
        this.props.onStateChangeAction({
          feedbackForm: {},
          selectedFeedback: {},
          deletedId: null
        });
        this.setState({ isLoading: false });
      }
    });
  };
  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "", deletedId: null });
  };
  getImgSrc = src => {
    const { feedbackForm } = this.props;
    this.props.onStateChangeAction({
      feedbackForm: {
        ...feedbackForm,
        imgSrc: src
      }
    });
  };
  // handleSearchChange = e => {
  //   const { feedbackList } = this.props;
  //   const { value } = e.target;
  //   const filteredList = feedbackList.filter(
  //     item => item.Title.indexOf(value) >= 0
  //   );
  //   this.props.onStateChangeAction({ filteredList, searchKey: value });
  // };

  handleSearchChange = e => {
    const { value } = e.target;
    const { feedbackList } = this.props;

    const filteredList = filter(feedbackList, sl =>
      includes(toLower(sl.Title), toLower(value))
    );
    this.props.onStateChangeAction({ filteredList, searchKey: value });
  };

  render() {
    const {
      selectedFeedback,
      isCreateNew,
      feedbackForm,
      dialogMessage,
      filteredList,
      listCollapsed,
      detailCollapsed,
      formCollapsed,
      searchKey,
      isHelpActive,
      labels
    } = this.props;
    const { isLoading } = this.state;
    return (
      <PageWrapper className="feedback-page">
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        {dialogMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <button className="dialog-btn" onClick={this.handleYesClick}>
                {Messages.feedback.confirm.Yes}
              </button>
              <button className="dialog-btn" onClick={this.handleNoClick}>
                {Messages.feedback.confirm.No}
              </button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={listCollapsed} className="col-1">
          <Column.Collapsed
            text={labels.ViewTitleFeedBackList}
            onClick={() =>
              this.props.onStateChangeAction({ listCollapsed: false })
            }
            tooltipButton={labels.ToolTipFeedBackExpandList}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.HlpTooltipFeedbackExpandListButton}
            tooltipPlace="left"
          />
          <Column.Head>
            <input
              className="round-search"
              type="text"
              onChange={this.handleSearchChange}
              placeholder={labels.searcherFeedbackSearch}
              value={searchKey}
            />
            <Button
              tooltipButton={labels.TooltipFeedbackCreateButton}
              tooltipHelp={labels.HlpTooltipFeedbackCreateButton}
              tooltipPlace="left"
              className="AddBtn"
              onClick={this.handleCreateNewClick}
              isHelpActive={isHelpActive}
            />
            <Button
              onClick={() =>
                this.props.onStateChangeAction({ listCollapsed: true })
              }
              className="collapseBtn"
              tooltipButton={labels.ToolTipFeedBackCollaspeList}
              tooltipHelp={labels.HlpTooltipFeedbackCollapseListButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          <Column.Body>
            <FeedbackList
              feedbackList={filteredList}
              handleFeedbackClick={this.handleFeedbackClick}
              selectedFeedback={selectedFeedback}
              handleDeleteFeedback={this.handleDeleteFeedback}
              labels={this.props?.labels}
            />
          </Column.Body>
        </Column>
        {!isCreateNew && (
          <Column collapse={detailCollapsed} className="col-2">
            <Column.Collapsed
              text={labels.ViewTitleFeedBackDetail}
              onClick={() =>
                this.props.onStateChangeAction({ detailCollapsed: false })
              }
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandDetail}
              tooltipHelp={labels.HlpTooltipFeedbackExpandDetailButton}
              tooltipPlace="left"
            />
            <Column.Head>
              <div className="heading">
                {selectedFeedback.FeedbackId
                  ? labels.searcherFeedbackDetail
                  : ""}
              </div>
              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({ detailCollapsed: true })
                }
                tooltipButton={labels.ToolTipFeedBackCollaspeDetail}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseDetailButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
              />
            </Column.Head>
            <Column.Body className="flex">
              <FeedbackDetail selectedFeedback={selectedFeedback} />
            </Column.Body>
          </Column>
        )}
        {isCreateNew && (
          <Column collapse={formCollapsed} className="col-2">
            <Column.Collapsed
              text={labels.ViewTitleFeedBackCreate}
              onClick={() =>
                this.props.onStateChangeAction({ formCollapsed: false })
              }
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
            />
            <Column.Head>
              <div className="heading">{labels.searcherFeedbackCreate}</div>
              <Button
                onClick={this.handleSubmitFeedback}
                className="SendBtn"
                disabled={feedbackForm.isSubmitting}
                tooltipButton={labels.TooltipFeedbackSendButton}
                tooltipHelp={labels.HlpTooltipFeedbackSendButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
              />
              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({ formCollapsed: true })
                }
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
              />
            </Column.Head>
            <Column.Body>
              <CreateFeedback
                handleContentChange={this.handleContentChange}
                handleTitleChange={this.handleTitleChange}
                feedbackForm={feedbackForm}
                getImgSrc={this.getImgSrc}
              />
            </Column.Body>
          </Column>
        )}
      </PageWrapper>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, feedback }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, ...feedback };
};
export default connect(
  mapStateToProps,
  { notificationAction, onStateChangeAction }
)(Feedback);
