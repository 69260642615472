import React, { Component, Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import { htmlParser } from "../../../utilities/helpers";
import "./ResumeDetail.scss";
import { connect } from "react-redux";
import moment from "moment";
import { isNullOrUndefined } from "util";

const AccordionHeading = ({ heading, onToggle, active }) => (
  <div className="ResumeHeadingCnt">
    <div className="ResumeHeading">{heading}</div>
    <div
      className={`DownArrow ${active ? "" : "UpArrow"}`}
      onClick={onToggle}
    />
  </div>
);

const AccordionDetail = ({ children }) => children;

class Accordion extends Component {
  state = {
    active: true,
  };
  handleToggle = () => {
    this.setState({ active: !this.state.active });
  };
  render() {
    const { active } = this.state;
    const { heading, children } = this.props;
    return (
      <div className="AvailableList">
        <AccordionHeading
          heading={heading}
          active={active}
          onToggle={this.handleToggle}
        />
        {active && <AccordionDetail>{children}</AccordionDetail>}
      </div>
    );
  }
}

const ChildAccordionHeading = ({ heading, onToggle, active }) => (
  <div className="ResumeHeadingCnt">
    {heading}
    <div
      className={`DownArrow ${active ? "" : "UpArrow"}`}
      onClick={onToggle}
    />
  </div>
);

class ChildAccordion extends Component {
  state = {
    active: true,
  };
  handleToggle = () => {
    this.setState({ active: !this.state.active });
  };
  render() {
    const { active } = this.state;
    const { heading, children } = this.props;
    return (
      <li className="AvailableList ResumeItem">
        <ChildAccordionHeading
          heading={heading}
          active={active}
          onToggle={this.handleToggle}
        />
        {active && <AccordionDetail>{children}</AccordionDetail>}
      </li>
    );
  }
}
class ResumeDetail extends Component {
  availability = (record) => {
    if (record.AvailablityType === 1) {
      return "markGreen";
    } else if (record.AvailablityType === 2) {
      return "markYellow";
    } else if (record.AvailablityType === 3) {
      return "markRed";
    }
  };

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  getAvailable = (value) => {
    const { labels } = this.props;
    if (value.AvailablityType === 1) {
      return labels.IPRO_DASHBOARD_RESUME_AVAILABLE_HEADER;
    }
    if (value.AvailablityType === 2) {
      return labels.IPRO_DASHBOARD_RESUME_ONE_MONTH_HEADER;
    }
    if (value.AvailablityType === 3) {
      var atypemessage = labels.IPRO_DASHBOARD_RESUME_BUSY_UNTIL_HEADER;
      return htmlParser(
        atypemessage.replace(
          "@@date",
          moment(value.AvailabilityDate).format("MM-DD-YYYY")
        )
      );
    }
  };
  GetExperianceLevel = (value) => {
    switch (value) {
      case 1:
        return "Beginner";
      case 2:
        return "Proficient";
      case 3:
        return "Expert";
    }
  };
  GetYear = (value) => {
    var d = new Date(value);
    return d.getFullYear();
  };

  allItemsWithCommaSeprated = (data) => {
    var item = "";
    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (i != data.length - 1) item += data[i].KeywordValue + ", ";
        else item += data[i].KeywordValue + ".";
      }

      item = item.substr(0, item.length - 1);
    }
    return item;
  };
  GetLanguageLevel = (value) => value;
  GetExperienceYears = (value) => value;

  render() {
    const { settings, labels } = this.props;
    let {
      countries,
      roles,
      languages,
      skills,
      keywords,
      certifications,
      industries,
    } = settings;
    return (
      <div className="resume-detail-component search-setting-detail">
        <div className="ResumeListWrap">
          <Accordion heading={labels.ResumeHeadingLocation}>
            <ul className="ResumeList">
              {countries &&
                countries.map((item) => (
                  <li key={item.CountryId} className="ResumeItem">
                    <div className="LocName">{item.CountryName}</div>
                  </li>
                ))}
            </ul>
          </Accordion>

          <Accordion heading={labels.ResumeHeadingLanguage}>
            <ul className="ResumeList">
              {languages &&
                languages.map((item) => (
                  <li key={item.LanguageId} className="ResumeItem">
                    <div className="SkillName">{item.LanguageValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperianceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion>

          <Accordion heading={labels.ResumeHeadingRole}>
            <ul className="ResumeList">
              {roles &&
                roles.map((item) => (
                  <li key={item.ProfileId} className="ResumeItem">
                    <div className="SkillName">{item.ProfileValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperianceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion>

          <Accordion heading={labels.ResumeHeadingSkill}>
            <ul className="ResumeList">
              {skills &&
                skills.map((item) => (
                  <li key={item.SkillId} className="ResumeItem">
                    <div className="SkillName">{item.SkillValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperianceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion>

          <Accordion heading={labels.ResumeHeadingKeyword}>
            <ul className="ResumeList">
              {keywords &&
                keywords.map((item) => (
                  <li key={item.KeywordId} className="ResumeItem">
                    <div className="SkillName">{item.KeywordValue}</div>
                  </li>
                ))}
            </ul>
          </Accordion>

          <Accordion heading={labels.ResumeHeadingCertification}>
            <ul className="ResumeList">
              {certifications &&
                certifications.map((item) => (
                  <li key={item.CertificationId} className="ResumeItem">
                    <div className="SkillName">{item.CertificationValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetYear(item.CertificationDate)}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion>
          <Accordion heading={labels.ResumeHeadingIndustry}>
            <ul className="ResumeList">
              {industries &&
                industries.map((item) => (
                  <li key={item.IndustryId} className="ResumeItem">
                    <div className="SkillName">{item.IndustryValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperianceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(ResumeDetail);
