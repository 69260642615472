import axios from "axios";
import { ApiUrl } from "../../../api/apiUrls";

export const getAllLocationsApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.LocationsLookup)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getResumeLocation = () => {
  return axios
    .get(ApiUrl.ResumeEdit.GetResumeLocation)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const addResumeLocation = (payload) => {
  return axios
    .post(ApiUrl.ResumeEdit.addLocation, payload)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getAllLanguagesApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.LanguagesLookup)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getRolesApi = ({ searchKey }) => {
  return axios
    .get(ApiUrl.ResumeEdit.RolesLookup({ searchKey }))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getSkillsApi = ({ searchKey }) => {
  return axios
    .get(ApiUrl.ResumeEdit.SkillsLookup({ searchKey }))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getCertificationsApi = ({ searchKey }) => {
  return axios
    .get(ApiUrl.ResumeEdit.CertificationsLookup({ searchKey }))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getEducationsApi = ({ searchKey }) => {
  return axios
    .get(ApiUrl.ResumeEdit.EductionsLookup({ searchKey }))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getIndustriesApi = ({ searchKey }) => {
  return axios
    .get(ApiUrl.ResumeEdit.IndustriesLookup({ searchKey }))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getKeywordApi = ({ searchKey }) => {
  return axios
    .get(ApiUrl.ResumeEdit.KeywordLookup({ searchKey }))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getResumesApi = () => {
  return axios.get(ApiUrl.ResumeEdit.GetMyResume).then(({ data }) => data);
};
export const saveUpdatedResumeApi = ({ resume }) => {
  return axios
    .post(ApiUrl.ResumeEdit.SaveEditedResume, resume)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getRoleSuggestionsApi = ({ Id, type }) => {
  return axios
    .get(ApiUrl.ResumeEdit.RoleSuggestionsLookup({ Id, type }))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getSkillSuggestionsApi = ({ Id, type }) => {
  return axios
    .get(ApiUrl.ResumeEdit.SkillSuggestionsLookup({ Id, type }))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getCertificationSuggestionsApi = ({ Id, type }) => {
  return axios
    .get(ApiUrl.ResumeEdit.CertificationSuggestionsLookup({ Id, type }))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const resumeAddRoleApi = (ResumeProfiles) => {
  return axios
    .post(ApiUrl.ResumeEdit.resumeAddRole, ResumeProfiles)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const resumeAddSkillApi = (payload) => {
  return axios
    .post(ApiUrl.ResumeEdit.resumeAddSkill, payload)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addCertificationApi = (payload) => {
  return axios
    .post(ApiUrl.ResumeEdit.AddCertification, payload)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const addEducationApi = (payload) => {
  return axios
    .post(ApiUrl.ResumeEdit.AddEducation, payload)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const deleteEducationApi = (payload) => {
  return axios
    .post(ApiUrl.ResumeEdit.deleteEducaion, payload)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addExperienceApi = (payload) => {
  return axios
    .post(ApiUrl.ResumeEdit.AddExperience, payload)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addIndustryApi = (payload) => {
  return axios
    .post(ApiUrl.ResumeEdit.AddIndustry, payload)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addKeywordApi = (payload) => {
  return axios
    .post(ApiUrl.ResumeEdit.AddKeyword, payload)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addLanguageApi = (payload) => {
  return axios
    .post(ApiUrl.ResumeEdit.AddLanguage, payload)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const resumeItemDeleteApi = (lookup) => {
  return axios
    .post(ApiUrl.ResumeEdit.DeleteResumeDetail, lookup)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getResumeCertificationsApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.GetResumeCertifications)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getResumeEducationsApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.GetResumeEducations)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getResumeExperiencesApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.GetResumeExperiences)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getResumeIndustriesApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.GetResumeIndustries)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getResumeKeywordsApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.GetResumeKeywords)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getResumeLanguagesApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.GetResumeLanguages)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getResumeProfilesApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.GetResumeProfiles)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getResumeSkillsApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.GetResumeSkills)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const updateResumeAvailabilityApi = (info) => {
  return axios
    .put(ApiUrl.ResumeEdit.UpdateResumeAvailability, info)
    .then((data) => data);
};

export const getAllAcheivementsApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.AllResumeAchivementLookup)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const AddResumeAchivenment = (payload) => {
  return axios
    .post(ApiUrl.ResumeEdit.AddResumeAchivenment, payload)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getResumeAchivenmentsApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.GetResumeAchivenments)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getAllKeyWordsApi = () => {
  return axios
    .get(ApiUrl.ResumeEdit.GetAllKeywords)
    .then(({ data }) => data)
    .catch((response) => response);
};