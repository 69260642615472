import React, { Component } from "react";
import "./usernotifications.scss";
import { connect } from "react-redux";
import { Button, List } from "../../../common";
import { notificationAction } from "../../../actions";
import {
  getUserNotificationsApi,
  getUserNotificationsUpdateApi
} from "./../settingsApi";
import find from "lodash/find";
import { onStateChangeAction } from "../settingAction";
export const UserNotificationList = [
  {
    checked: false,
    name: "IsOpportunityRecieved",
    IsFreelancer: true,
    label: "EmailOpportunityInvitation"
  },
  { 
    checked: false,
    name: "IsCollaborationRecieved",
    IsFreelancer: true,
    label: "EmailCollaborationInvitation"
  },
  {
    checked: false,
    name: "IsReceivedDirectMsg",
    label: "headerMenuEmailNotificationsMessage"
  },
  {
    checked: false,
    name: "IsFeedbackRecieved",
    label: "EmailFeedbackReplyAdminNotification"
  },
  {
    checked: false,
    name: "IsOpportunityInvitationAccepted",
    IsSearcher:true,
    label: "EmailAcceptedOpportunityInvitation"
  },
  {
    checked: false,
    name: "IsOpportunityInvitationDeclined",
    IsSearcher:true,
    label: "EmailDeclinedOpportunityInvitation"
  },
  {
    checked: false,
    name: "IsAcceptedCollaborationInvitation",
    IsSearcher:true,
    label: "EmailAcceptedCollaborationInvitation"
  },
  {
    checked: false,
    name: "IsDeclinedCollaborationInvitation",
    IsSearcher:true,
    label: "EmailDeclinedCollaborationInvitation"
  },
  {
    checked: false,
    name: "IsSharedCollaboration",
    IsSearcher:true,
    label: "EmailSharedCollaboration"
  },
  {
    checked: false,
    name: "IsCandidateLookupAccepted",
    IsFreelancer:true,
    label: "headerMenuEmailNotificationsCLAccepted"
  },
  {
    checked: false,
    name: "IsCandidateLookupRejected",
    IsFreelancer:true,
    label: "headerMenuEmailNotificationsCLRejected"
  }
];
class UserNotifications extends Component {
  // state = {
  //   UserId: 0,
  //   UserNotificationId: 0,
  //   notificationList: UserNotificationList
  // };

  componentDidMount = () => {
    this.props.isFetchNotification && this.getUserNotifications();
  };

  getUserNotifications = () => {
    getUserNotificationsApi().then(response => {
      const { IsFreelancer } = this.props;
      if (response.success) {
        const { UserNotificationId, UserId } = response.items;
        const notificationList = this.props.notificationList.map(item => ({
          ...item,
          checked: response.items[item.name]
        }));

        this.props.onStateChangeAction({
          UserId,
          IsFreelancer,
          notificationList,
          UserNotificationId,
          isFetchNotification: false
        });
      }
    });
  };

  handleUpdateBtnClick = () => {
    const { notificationList, UserId, UserNotificationId } = this.props;

    const receivedDirectMsg = find(notificationList, {
      name: "IsReceivedDirectMsg"
    });
    const OpportunityRecieved = find(notificationList, {
      name: "IsOpportunityRecieved"
    });
    const CollaborationRecieved = find(notificationList, {
      name: "IsCollaborationRecieved"
    });
    const FeedbackRecieved = find(notificationList, {
      name: "IsFeedbackRecieved"
    });
    const OpportunityInvitationAccepted = find(notificationList, {
      name: "IsOpportunityInvitationAccepted"
    });
    const OpportunityInvitationDeclined = find(notificationList, {
      name: "IsOpportunityInvitationDeclined"
    });
    const CollaborationInvitationAccepted = find(notificationList, {
      name: "IsAcceptedCollaborationInvitation"
    });
    const CollaborationInvitationDeclined = find(notificationList, {
      name: "IsDeclinedCollaborationInvitation"
    });
    const SharedCollaboration = find(notificationList, {
      name: "IsSharedCollaboration"
    });
    const candidateLookupAccepted = find(notificationList, {
      name: "IsCandidateLookupAccepted"
    });
    const candidateLookupRejected = find(notificationList, {
      name: "IsCandidateLookupRejected"
    });

    var obj = {
      UserNotificationId: UserNotificationId,
      UserId: UserId,
      IsReceivedDirectMsg: receivedDirectMsg && receivedDirectMsg.checked,
      IsOpportunityRecieved: OpportunityRecieved && OpportunityRecieved.checked,
      IsCollaborationRecieved: CollaborationRecieved && CollaborationRecieved.checked,
      IsFeedbackRecieved: FeedbackRecieved && FeedbackRecieved.checked,
      IsOpportunityInvitationAccepted: OpportunityInvitationAccepted && OpportunityInvitationAccepted.checked,
      IsOpportunityInvitationDeclined: OpportunityInvitationDeclined && OpportunityInvitationDeclined.checked,
      IsAcceptedCollaborationInvitation: CollaborationInvitationAccepted && CollaborationInvitationAccepted.checked,
      IsDeclinedCollaborationInvitation: CollaborationInvitationDeclined && CollaborationInvitationDeclined.checked,
      IsSharedCollaboration:SharedCollaboration && SharedCollaboration.checked,
      IsCandidateLookupAccepted:
        candidateLookupAccepted && candidateLookupAccepted.checked,
      IsCandidateLookupRejected:
        candidateLookupRejected && candidateLookupRejected.checked
    };
    getUserNotificationsUpdateApi(obj).then(response => {
      if (response.success) {
        const info = {
          message: "Email notification setting is successfully updated.",
          status: "success"
        };
        this.props.notificationAction(info);
      }
    });
  };

  handleItemClick = selectedItem => {
    const notificationList = this.props.notificationList.map(item => ({
      ...item,
      checked: item.name === selectedItem.name ? !item.checked : item.checked
    }));
    this.props.onStateChangeAction({
      notificationList
    });
  };

  render() {
    const { IsFreelancer } = this.props;
    const { notificationList } = this.props;
    return (
      <div className="userNotification">
        <div className="form-row">
          <List className="notyList">
            {notificationList.map((item, index) => {
              if (item.IsFreelancer && !IsFreelancer) {
                return null;
              }
              else if(item.IsSearcher&& IsFreelancer)
              {
                return null;
              }
              return (
                <List.ListItem
                  key={index}
                  className={item.checked ? "notiActive" : ""}
                  onClick={() => this.handleItemClick(item)}
                  testId={`email-notification-list-item-${item.label}`}
                >
                  <label className="listLabel">
                    {this.props.labels[item.label]}
                  </label>
                </List.ListItem>
              );
            })}
          </List>
        </div>

        <Button
          className="SendButton"
          onClick={this.handleUpdateBtnClick}
          testId="btnsettingupdate"
          tooltipHelp="Update"
          tooltipButton="Update"
        >
          Update
        </Button>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel, userInfo, setting }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  let { IsFreelancer } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    IsFreelancer,
    ...setting
  };
};
export default connect(
  mapStateToProps,
  { notificationAction, onStateChangeAction }
)(UserNotifications);
