import React from "react";

const Input = ({
  name,
  className = "",
  value,
  placeholder,
  testId,
  onChange,
  onBlur,
  autoFocus,
  maxlength,
  ...rest
}) => (
  <input
    autoFocus={autoFocus}
    type="text"
    name={name}
    className={`input-text ${className}`}
    value={value}
    placeholder={placeholder}
    test-data-id={testId}
    autoComplete="off"
    onChange={onChange}
    onBlur={onBlur}
    maxLength={maxlength}
    {...rest}
  />
);

export default Input;
