import { Button, Input, Modal, Space } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import closeIcon from '../../assets/images/Icons/close-new.png'
import ImageModal from '../../assets/images/delete-popup.png'

const DeleteModal = ({ open, setOpen, handleOk,title,heading }) => {
    const label = useSelector((state) => state?.systemLabel?.labels);
    const [error, setError] = useState(false);

    const handleCancel = () => {
        setOpen({ show: false });
    }

    return (
        <>
            <Modal
                zIndex={105}
                open={open?.show}
                onCancel={() => {
                    handleCancel()
                }}
                centered
                footer={null}
                closable={false}
                className={`new-design-modal shortlist-modal `}
            >
                <div className="text-center header-modal">
                    <h5>{title}</h5>
                    <div
                        onClick={() => handleCancel()}
                        className="pointer"
                    >
                        <img className="close-icon" src={closeIcon} alt="" />
                    </div>
                </div>
                <div className="form">
                    <div className="images-icon">
                        <img src={ImageModal} alt="" />
                        <p className="paragraph">{heading}</p>
                    </div>
                    <div className="d-flex gap-16 justify-content-between">
                        <Button
                            block
                            size="large"
                            htmlType="submit"
                            className="login-form-button button-last-footer mt-4"
                            onClick={() => { handleCancel() }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            block
                            size="large"
                            htmlType="submit"
                            className="login-form-button  mt-4"
                            onClick={() => { handleOk() }}
                        >
                            Delete
                        </Button>
                    </div>
                </div>

            </Modal>
        </>
    );
};
export default DeleteModal;
