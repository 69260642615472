import axios from "axios";
import { ApiUrl } from "../../api/apiUrls";

export const embarkLogin = (email) => {
  return axios.get(ApiUrl.Embark.Login(email)).then(({ data }) => {
    return data;
  });
};

export const embarkAppLoginApi = (email) => {
  return axios
    .get(ApiUrl.Embark.AppLogin(email))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getAllSearcherCompaniesApi = () => {
  return axios
    .get(ApiUrl.Embark.GetAllSearcherCompanies)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getAllFlowsApi = (companyId, token) => {
  return axios
    .get(ApiUrl.Embark.GetAllFlows(companyId, token))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getAllFlowGroupsApi = (companyId, token) => {
  return axios
    .get(ApiUrl.Embark.GetAllFlowGroups(companyId, token))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getAllUserCreatedFlowsAndFlowGroupsApi = (companyId, token) => {
  return axios
    .get(ApiUrl.Embark.GetAllUserCreatedFlowsAndFlowGroups(companyId, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getAllFlowItemsApi = (flowId, token) => {
  return axios
    .get(ApiUrl.Embark.GetFlowItems(flowId, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getAllFlowGroupItemsApi = (flowGroupId, token) => {
  return axios
    .get(ApiUrl.Embark.GetFlowGroupItems(flowGroupId, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getCompanyUsersApi = (companyId) => {
  return axios
    .get(ApiUrl.Embark.GetCompanyUser(companyId))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getAllAcceptedCollaborationsWithIProDetailApi = (
  companyId,
  token
) => {
  return axios
    .get(
      ApiUrl.Embark.GetAllAcceptedCollaborationsWithIProDetail(companyId, token)
    )
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getIProAssignedFlowApi = (iproId, companyId, token) => {
  return axios
    .get(ApiUrl.Embark.GetIProAssignedFlow(iproId, companyId, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const GetFlowForAssignmentAllApi = (companyId, token) => {
  return axios
    .get(ApiUrl.Embark.GetFlowForAssignmentAll(companyId, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addFlowApi = (flow, token) => {
  return axios
    .post(ApiUrl.Embark.AddFlow(token), flow)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateFlowApi = (flow, token) => {
  return axios
    .put(ApiUrl.Embark.UpdateFlow(token), flow)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const deleteFlowApi = (Id, token) => {
  return axios
    .delete(ApiUrl.Embark.DeleteFlow(Id, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addFlowGroupApi = (flowGroup, token) => {
  return axios
    .post(ApiUrl.Embark.AddFlowGroup(token), flowGroup)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateFlowGroupApi = (flowGroup, token) => {
  return axios
    .put(ApiUrl.Embark.UpdateFlowGroup(token), flowGroup)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const deleteFlowGroupApi = (Id, token) => {
  return axios
    .delete(ApiUrl.Embark.DeleteFlowGroup(Id, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addFlowItemApi = (flowItem, token) => {
  return axios
    .post(ApiUrl.Embark.AddFlowItem(token), flowItem)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateFlowItemApi = (flowItem, token) => {
  return axios
    .put(ApiUrl.Embark.UpdateFlowItem(token), flowItem)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const deleteFlowItemApi = (Id, token) => {
  return axios
    .delete(ApiUrl.Embark.DeleteFlowItem(Id, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addFlowGroupItemApi = (flowGroupItem, token) => {
  return axios
    .post(ApiUrl.Embark.AddFlowGroupItem(token), flowGroupItem)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateFlowGroupItemApi = (flowGroupItem, token) => {
  return axios
    .put(ApiUrl.Embark.UpdateFlowGroupItem(token), flowGroupItem)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const deleteFlowGroupItemApi = (Id, token) => {
  return axios
    .delete(ApiUrl.Embark.DeleteFlowGroupItem(Id, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const assignFlowApi = (flow, token) => {
  return axios
    .post(ApiUrl.Embark.AssignFlow(token), flow)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const unAssignFlowApi = (Id, token) => {
  return axios
    .delete(ApiUrl.Embark.UnAssignFlow(Id, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getiProFlowsAndItemsApi = (iproId, token) => {
  return axios
    .get(ApiUrl.Embark.GetiProFlowsAndItems(iproId, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const completeFlowItemApi = (iproId, flowId, flowItemId, token) => {
  return axios
    .post(ApiUrl.Embark.CompleteFlowItem(iproId, flowId, flowItemId, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const unCompleteFlowItemApi = (iproId, flowId, flowItemId, token) => {
  return axios
    .delete(ApiUrl.Embark.UnCompleteFlowItem(iproId, flowId, flowItemId, token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getAllWorkplacesWithIProsCountApi = () => {
  return axios
    .get(ApiUrl.Embark.GetAllWorkplacesWithIProsCount)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getFlowsCountApi = (token) => {
  return axios
    .get(ApiUrl.Embark.GetFlowsCount(token))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getFlowItemsCountApi = (token) => {
  return axios
    .get(ApiUrl.Embark.GetFlowItemsCount(token))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getFlowGroupsCountApi = (token) => {
  return axios
    .get(ApiUrl.Embark.GetFlowGroupsCount(token))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getFlowGroupItemsCountApi = (token) => {
  return axios
    .get(ApiUrl.Embark.GetFlowGroupItemsCount(token))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getSearcherCompletedPercentagetApi = (token) => {
  return axios
    .get(ApiUrl.Embark.GetSearcherCompletedPercentage(token))
    .then(({ data }) => data)
    .catch((response) => response);
};
