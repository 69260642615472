import React, { Component } from "react";
import Dropzone from "react-dropzone";
import "./dragimage.scss";
class DragImage extends Component {
  handleDropAccepted = files => {
    const file = files[0];
    var reader = new FileReader();
    reader.onload = (theFile => {
      return e => {
        this.props.getImgSrc(e.target.result);
      };
    })(file);
    reader.readAsDataURL(file);
  };
  handleCloseClick = () => {
    this.props.getImgSrc();
  };
  render() {
    const { imgSrc, labels } = this.props;
    return (
      <div className="drag-image-component">
        <div className={`drag-img-box ${imgSrc ? "active" : ""}`}>
          {!imgSrc && <p>{labels.searcherFeedbackNoImage}</p>}
          {imgSrc && (
            <div>
              <button className="closeBtn" onClick={this.handleCloseClick} />
              <img className="droped-image" src={imgSrc} alt="dropped" />
            </div>
          )}
        </div>
        <Dropzone
          className="feedDrag demo-droppable"
          acceptClassName="drag-accepted"
          rejectClassName="drag-rejected"
          accept="image/*"
          multiple={false}
          onDropAccepted={this.handleDropAccepted}
        >
          <p>{labels.searcherFeedbackImageFormate}</p>
        </Dropzone>
      </div>
    );
  }
}

export default DragImage;
