import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert, Select } from "antd";
import classes from "./index.module.scss";
import { getAllLanguagesApi } from "../../../Resume/ResumeEdit/resumeEditApi";
import closeIcon from '../../../../assets/images/Icons/close-new.png'
import LanguageImage from '../../../../assets/images/language-popup.png'

function EditLanguagePopUp({
  isLoading,
  SaveResumeDetails,
  errorMessage,
  labels,
  handleLanguageChange,
  resumeLanguage,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [languages, setLanguages] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getAllLanguages();
  }, []);

  const getAllLanguages = () => {
    getAllLanguagesApi().then((response) => {
      if (response.success) {
        const languages = response.items.map((item) => ({
          ...item,
          value: item.LanguageId,
          label: item.LanguageValue,
        }));
        setLanguages(languages);
      }
    });
  };

  const onFinish = () => {
    setIsModalVisible(false);
    SaveResumeDetails();
  };
  const LanguageLevelList = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Elementary" },
    { value: 3, label: "Intermediate" },
    { value: 4, label: "Advanced" },
    { value: 5, label: "Fluent" },
  ];

  return (
    <>
      <button className="add-profile"
        onClick={() => setIsModalVisible(true)}
      >{labels.AddResumeEditHeadingLanguage}</button>

      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={null}
        closable={false}
        className={`new-design-modal ${classes.popUpForm}`}
      >
        <div className="text-center mb-3 header-modal">
          <h5>{labels.AddResumeEditHeadingLanguage}</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="images-icon">
          <img src={LanguageImage} alt="" />
          <p className="paragraph">Please select your language and speaking level</p>
        </div>
        <Form
          name="normal_login"
          className={`${classes.form}  form`}
          onFinish={onFinish}
          size="large"
          initialValues={{ level: LanguageLevelList[0] }}
        >

          <Form.Item
            rules={[
              {
                required: resumeLanguage.LanguageId ? false : true,
                message: labels.SELECT_LANGUAGE_LBL || "Please select Language",
              },
            ]}
            className={`${classes.customSelect}`}
            name="langague"
          >
            <Select
              placeholder={labels.SELECT_LANGUAGE_LBL}
              onChange={(e) => handleLanguageChange("LanguageId", e)}
              allowClear
              autoComplete="new-password"
            >
              {languages &&
                languages.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: resumeLanguage.ExperienceLevel ? false : true,
                message: labels.LANGUAGE_PROFICIENCY_VALIDATION_LBL || "Please select language proficiency",
              },
            ]}
            className={`${classes.customSelect}`}
            name="level"
          >
            <Select
              placeholder={labels.IPRO_DASHBOARD_RESUME_LANGUAGE_LEVEL_LABEL}
              onChange={(e) => handleLanguageChange("ExperienceLevel", e)}
              allowClear
              name={"level"}
              autoComplete="new-password"
            >
              {LanguageLevelList &&
                LanguageLevelList.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          {error && (
            <Alert
              message={errorMessage?.replace("<br />", "")}
              type="warning"
              closable
            />
          )}

          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-form-button mt-2"
            loading={isLoading}
          >
            {labels.Done_LBL}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default EditLanguagePopUp;
