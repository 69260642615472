import React, { Component } from "react";
import ReactDOM from "react-dom";
// import "./dashboard.scss";
import Widget from "./Widget";
import { Column, LoadingMaskRow, Tooltip, HelpTooltip, EmptyInfo } from "../../../../common";
import {
  getAllAcceptedRequestsApi,
  getRequestsCountsApi,
  getCollaborationsCountsApi
} from "../../../Assort/assortApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
class Dashboard extends React.Component {
  state = {
    allOpportunites: []
  };
  componentDidMount() {
    getAllAcceptedRequestsApi()
      .then(res => {
        this.setState({
          allOpportunites: res.items
        });
      })
      .catch(err => console.log("Error"))

    getRequestsCountsApi()
      .then(res => {
        this.setState({
          requestCounts: res.items
        });
      })
      .catch(err => console.log("Error"))
      
    getCollaborationsCountsApi()
      .then(res => {
        this.setState({
          collaborationsCount: res.items
        });
      })
      .catch(err => console.log("Error"))
  }

  render() {
    const { widgetTestId, isHelpActive, labels, UserFeatures } = this.props;
    const {
      requestCounts,
      collaborationsCount
    } = this.state;
    return (
      <div className="dashboard-view statistic-">
        <React.Fragment>
          <Column className="column-1">
            {UserFeatures && UserFeatures.find(x => x.Name == "SEARCHER_NAVIGATION_SEARCHER_PHILLIP_STATISTIC" && x.IsActive) &&
              <Widget testId={widgetTestId}>
                <Widget.Header>
                  {isHelpActive && (
                    <HelpTooltip content={"Opportunites Dashboard"} />
                  )}   <i className="request-icon" />
                  <label>{labels?.searcher_phillips_statistic_column1_label}</label>
                </Widget.Header>
                <Widget.Content>
                  <div className="widget-content">
                    <div>
                      <label>{requestCounts && requestCounts.length || 0}</label>
                      <label>{"Accepted"}</label>
                    </div>
                  </div>
                </Widget.Content>
              </Widget>
            }
          </Column>
          <Column className="column-2">
            {UserFeatures && UserFeatures.find(x => x.Name == "SEARCHER_NAVIGATION_SEARCHER_PHILLIP_STATISTIC" && x.IsActive) &&
              <Widget>
                <Widget.Header>
                  {isHelpActive && (
                    <HelpTooltip content={"Accepted Opportunities"} />
                  )}
                  <i className="request-icon" />
                  <label>{labels?.searcher_phillips_statistic_column2_label}</label>
                </Widget.Header>
                <Widget.Content>
                  <div className="collab-widget-content">
                    {requestCounts && requestCounts.length > 0 ? requestCounts.map(item => (
                      <div>
                        <label>{`${item.TotalResumes}`}</label>
                        <label>{item.RequestName}</label>
                      </div>
                    )) :
                      <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>}
                  </div>
                </Widget.Content>
              </Widget>
            }
          </Column>
        </React.Fragment>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Dashboard);
