import { Collapse } from "antd";
import React from "react";

export default ({
  onShortlists,
  shortlistItemClicked,
  labels,
  onSavedSearches,
  savedSearchItemClicked,
  onOpportunities,
  opportunityItemClicked,
  Col3ItemMobile,
  onChange2,
}) => {
  return (
    <div className="mo-col-3">
      {onShortlists && shortlistItemClicked ? (
        <div>
          {Col3ItemMobile(
            "availabilityIcon",
            labels.MONITORING_NEW_AVAILABILITY_LABEL,
            labels.MONITORING_SHORTLIS_NEWAVAILABILITY_DETAILS,
            "newAvailibility",
            "1"
          )}
        </div>
      ) : (
        ""
      )}
      {onSavedSearches && savedSearchItemClicked ? (
        <div>
          {Col3ItemMobile(
            "scorePlusIcon",
            labels.MONITORING_TOPSCORE_LABEL,
            labels.MONITORING_SAVEDSEACH_TOPSCORE_DETAILS,
            "topScoreChange",
            "1"
          )}
          {Col3ItemMobile(
            "newIproIcon",
            labels.MONITORING_ANEWIPRO_LABEL,
            labels.MONITORING_SAVEDSEARCH_ANEWIPRO_DETAILS,
            "aNewIpro",
            "2"
          )}
        </div>
      ) : (
        ""
      )}

      {onOpportunities && opportunityItemClicked ? (
        <div>
          {Col3ItemMobile(
            "availabilityIcon",
            labels.MONITORING_NEW_AVAILABILITY_LABEL,
            labels.MONITORING_OPPORTUNITY_NEWAVAILABILITY_DETAILS,
            "newAvailibility",
            "1"
          )}
          {Col3ItemMobile(
            "scorePlusIcon",
            labels.MONITORING_TOPSCORE_LABEL,
            labels.MONITORING_OPPORTUNITY_TOPSCORE_DETAILS,
            "topScoreChange",
            "2"
          )}
          {Col3ItemMobile(
            "newIproIcon",
            labels.MONITORING_ANEWIPRO_LABEL,
            labels.MONITORING_OPPORTUNITY_NEWIPRO_DETAILS,
            "aNewIpro",
            "3"
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
