import React from "react";
import "./resumeList.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Checkbox, Col, Dropdown, Grid, Row, Typography } from "antd";
import { join } from "lodash";
// import StarIcon from '../../assets/images/Icons/star.png'
import SelectedIcon from '../../../../../../assets/images/Icons/resume-select.svg'
import LocationIcon from '../../../../../../assets/images/location.png'
import ClampLines from "react-clamp-lines";
import { EmptyInfo, LoadingMaskRow, Tooltip } from "../../../../../../common";

const { useBreakpoint } = Grid;
const { Title } = Typography

const ResumeListComponentNewDesign = ({
  resumeList,
  state,
  fetchMoreResumes,
  handleModalClick,
  label,
}) => {
  const screens = useBreakpoint();
  const renderValue = (resume) => {
    const ProfileValue = resume.map((item) => item.ProfileValue);
    return join(ProfileValue, ", ");
  };

  return (
    <div className="resume-list-component-opportunity">
      {resumeList?.length > 0 && (
        <InfiniteScroll
          dataLength={resumeList?.length}
          hasMore={state?.hasMore}
          next={fetchMoreResumes}
          loadMore={fetchMoreResumes}
          loader={<LoadingMaskRow />}
          scrollThreshold="100px"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
          height={"100%"}
        >
          {resumeList?.map((single, index) => (
            <div
              className="card-resume pointer p-0 d-flex"
            >
              <div style={{ padding: "14px", flex: 1 }}
              >
                <Row gutter={16}>
                  <Col md={20} sm={18} xs={24}
                    onClick={() => {
                      handleModalClick(single)
                    }}
                  >
                    <div className="d-flex align-iltems-center gap-8">
                      <Title level={5} className="m-0">
                        <ClampLines
                          text={renderValue(single?.Profiles)}
                          lines={1}
                          buttons={false}
                        />
                      </Title>

                    </div>
                  </Col>
                </Row>
                {single?.Region && (
                  <div className="location-main mt-1"
                    onClick={() => {
                      handleModalClick(single)

                    }}
                  >
                    <div>
                      <img src={LocationIcon} alt="" />
                    </div>
                    <p className="location">{single?.Region || ""}</p>
                  </div>
                )}
                <div gutter={[8, 8]} className="mt-2 d-flex gap-8 justify-content-between align-items-center"
                  style={{ flexWrap: screens?.xs ? "wrap" : "unset" }}
                >
                  <div xs={24} sm={24} md={20}
                    onClick={() => {
                      handleModalClick(single)

                    }}
                    style={{ width: screens?.xs ? "100%" : "unset" }}
                  >
                    <div className="resume-btn">
                      {single?.Skills?.slice(0, 3)?.map((row, index) => (
                        <button className="btn"> {row?.SkillValue}</button>
                      ))}
                      {single?.Skills?.length > 3 && (
                        <button className="btn">
                          {`+ ${single?.Skills?.length - 3}`}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      )}
      {resumeList?.length == 0 && (
        <EmptyInfo>{label || "No Resume found"}</EmptyInfo>
      )}
    </div>
  );
};

export default ResumeListComponentNewDesign;
