import React from "react";
import { Button } from "../../../../common";

const FLOWGROUP = "FLOWGROUP";

export default function FlowGroups(props) {
  const { labels } = props;
  return (
    <React.Fragment>
      <div className="tictell-list">
        {props.flowGroups &&
          props.flowGroups.length > 0 &&
          props.flowGroups.map((item) => (
            <div
              key={item.FlowId}
              test-data-id={`flow-groups-list-item${
                item.isActive ? "activeItem" : ""
              }`}
              className={`tictell-list-item-container  ${
                item.isSelected ? "selected-item" : ""
              } ${item.isActive ? "activeItem" : ""}`}
            >
              <span
                onClick={() => props.handleItemClick(FLOWGROUP, item)}
                className={"flowgroup-icon"}
                onClick={() => props.handleItemClick(FLOWGROUP, item)}
              />
              <div
                onClick={() => props.handleItemClick(FLOWGROUP, item)}
                className="name-container flow-container"
                onClick={() => props.handleItemClick(FLOWGROUP, item)}
              >
                <label test-data-id={`collabs-list-owner-username`}>
                  {item.Title}
                </label>
                <label test-data-id={`collabs-list-owner-title`}>
                  {item.Description}
                </label>
              </div>
              {props.isDelete && (
                <Button
                  onClick={() => props.handleFlowGroupDelete(item)}
                  className="closeBtn"
                  tooltipButton={labels.EMBARK_DELETE_FLOW_GROUP}
                  tooltipHelp={labels.EMBARK_BTN_DELETE_FLOW_GROUP}
                  tooltipPlace="left"
                  isHelpActive={props.isHelpActive}
                  testId="delete-flow-group-btn"
                />
              )}
            </div>
          ))}
      </div>
    </React.Fragment>
  );
}
