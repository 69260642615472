import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  message: "",
  status: "",
  className: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.NOTIFICATION:
      return {
        ...state,
        message: action.message,
        status: action.status,
        className: action.className,
      };

    default:
      return state;
  }
};
