import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  feedbackList: [],
  selectedFeedback: {},
  isCreateNew: false,
  feedbackForm: {},
  dialogMessage: "",
  deletedId: null,
  filteredList: [],
  listCollapsed: false,
  detailCollapsed: false,
  formCollapsed: false,
  searchKey: "",
  isFetching: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.FEEDBACK.FEEDBACK_LIST:
      return {
        ...state,
        feedbackList: action.payload
      };

    case ActionTypes.FEEDBACK.SELECTED_FEEDBACK:
      return {
        ...state,
        selectedFeedback: action.payload
      };

    case ActionTypes.FEEDBACK.IS_CREATENEW:
      return {
        ...state,
        isCreateNew: action.payload
      };

    case ActionTypes.FEEDBACK.FEEDBACK_FORM:
      return {
        ...state,
        feedbackForm: action.payload
      };

    case ActionTypes.FEEDBACK.DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: action.payload
      };

    case ActionTypes.FEEDBACK.DELETED_ID:
      return {
        ...state,
        deletedId: action.payload
      };

    case ActionTypes.FEEDBACK.FILTERED_LIST:
      return {
        ...state,
        filteredList: action.payload
      };

    case ActionTypes.FEEDBACK.LIST_COLLAPSED:
      return {
        ...state,
        listCollapsed: action.payload
      };

    case ActionTypes.FEEDBACK.DETAIL_COLLAPSED:
      return {
        ...state,
        detailCollapsed: action.payload
      };

    case ActionTypes.FEEDBACK.FORM_COLLAPSED:
      return {
        ...state,
        formCollapsed: action.payload
      };

    case ActionTypes.FEEDBACK.SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload
      };
    case ActionTypes.FEEDBACK.IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };

    default:
      return state;
  }
};
