import { ActionTypes } from "../../actions";
export const onStateChangeAction = ({
  presentList,
  filteredList,
  isFetching,
  presentForm,
  profiles,
  searchKey,
  userEmails,
  userPhonenumbers,
  socialMedia,
  deletedId,
  dialogMessage,
  isLoading,
  listCollapse,
  formCollapse
}) => dispatch => {
  presentList !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.PRESENT_LIST,
      payload: presentList
    });
  filteredList !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.FILTERED_LIST,
      payload: filteredList
    });
  isFetching !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.IS_FETCHING,
      payload: isFetching
    });
  presentForm !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.PRESENT_FORM,
      payload: presentForm
    });
  profiles !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.PROFILES,
      payload: profiles
    });
  searchKey !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.SEARCH_KEY,
      payload: searchKey
    });
  userEmails !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.USER_EMAILS,
      payload: userEmails
    });
  userPhonenumbers !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.USER_PHONE_NUMBERS,
      payload: userPhonenumbers
    });
  socialMedia !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.SOCIAL_MEDIA,
      payload: socialMedia
    });
  deletedId !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.DELETED_ID,
      payload: deletedId
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.DIALOG_MESSAGE,
      payload: dialogMessage
    });
  isLoading !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.IS_LOADING,
      payload: isLoading
    });
  listCollapse !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.LIST_COLLAPSE,
      payload: listCollapse
    });
  formCollapse !== undefined &&
    dispatch({
      type: ActionTypes.PRESENT.FORM_COLLAPSE,
      payload: formCollapse
    });
};
