import React, { Component } from "react";
import { connect } from "react-redux";
import { loginAuth } from "./loginAction";
import { notificationAction } from "../../../../actions";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import {
  getAllCountriesApi,
  signUpApi,
  getSignUpConfigsApi,
} from "../../leadPageApi";
import {
  isValidEmail,
  htmlParser,
  isValidPassword,
} from "../../../../utilities";
import { ConfirmDialog } from "../../../../common";
import Terms from "./Terms";
import GoogleSignup from "./GoogleSignup";

class RegisterForm extends Component {
  state = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    IsFreelancer: false,
    Country: 0,
    Usage: 0,
    UsageTypeList: [],
    CountriesList: [],
    isAgreed: false,
    isTerms: false,
    Configs: [],
    selectedTerm: {},
    isIamRobot: true,
    invalidpasswordMessage: "",
    isGoogleSignInActive: false,
  };

  handleHideMainForm = (value) => {
    this.setState({
      isGoogleSignInActive: value,
    });
  };

  getAllCountries = () => {
    getAllCountriesApi().then((response) => {
      if (response.success) {
        const CountriesList = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        this.setState({ CountriesList });
      }
    });
  };
  componentDidMount = () => {
    this.getAllCountries();
    getSignUpConfigsApi().then((response) => {
      this.setState({
        Configs: response.items,
      });
    });
    this.setState({
      UsageTypeList: [
        { value: "1", label: this.props.labels.SIGNUP_IPRO_LABEL },
        { value: "2", label: this.props.labels.SIGNUP_SEARCHER_LABEL },
      ],
    });
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    const { password } = this.state;
    const { labels } = this.props;
    if (!value || (name === "email" && !isValidEmail(value))) {
      this.setState({
        [`invalid${name}`]: true,
        [name]: value,
      });
      return;
    } else if (!value || (name === "password" && !isValidPassword(value))) {
      this.setState({
        [`invalid${name}`]: true,
        [name]: value,
        invalidpasswordMessage: labels.invalidPasswordForRegisteration,
      });
      const info = {
        message: labels.invalidPasswordForRegisteration,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    } else if (!value || (name === "confirmPassword" && value !== password)) {
      this.setState({
        [`invalid${name}`]: true,
        [name]: value,
      });
      return;
    } else {
      this.setState({
        [`invalid${name}`]: false,
        [name]: value,
        invalidpasswordMessage: "",
      });
    }
  };
  handleFormSelectChange = (name, selectedOption) => {
    if (!selectedOption) return;
    const { value } = selectedOption;
    this.setState({
      [name]: value,
      [`invalid${name}`]: false,
    });
  };
  handleAgreeToggle = () => {
    const { isAgreed } = this.state;
    this.setState({
      isAgreed: !isAgreed,
    });
  };
  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    }
  };
  handleSubmit = () => {
    const {
      firstname,
      lastname,
      email,
      password,
      Country,
      Usage,
      isAgreed,
      isIamRobot,
      confirmPassword,
    } = this.state;
    const { labels } = this.props;
    let isError = false;
    if (!firstname) {
      isError = true;
      this.setState({
        invalidfirstname: true,
      });
    }
    if (!lastname) {
      isError = true;
      this.setState({
        invalidlastname: true,
      });
    }
    if (!isValidEmail(email)) {
      isError = true;
      const info = {
        message: labels.invalidEmailAddressForRegisteration,
        status: "error",
      };
      this.props.notificationAction(info);
      this.setState({
        invalidemail: true,
      });
      return;
    }
    if (!password || !isValidPassword(password)) {
      isError = true;
      const info = {
        message: labels.invalidPasswordForRegisteration,
        status: "error",
      };
      this.props.notificationAction(info);
      this.setState({
        invalidpassword: true,
        invalidpasswordMessage: labels.invalidPasswordForRegisteration,
      });
      return;
    }
    if (!confirmPassword || password !== confirmPassword) {
      isError = true;
      const info = {
        message: labels.confirmPasswordNotMatching,
        status: "error",
      };
      this.props.notificationAction(info);
      this.setState({
        invalidconfirmPassword: true,
      });
      return;
    }
    if (!Usage) {
      isError = true;
      this.setState({
        invalidUsage: true,
      });
    }
    if (!Country) {
      isError = true;
      this.setState({
        invalidCountry: true,
      });
    }
    if (isError) {
      const info = {
        message: this.props.SIGNUP_VALIDATION_ERROR_MESSAGE,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!isAgreed) {
      const info = {
        message: this.props.labels.SIGNUP_AGREEMENT_VALIDATION_ERROR_MESSAGE,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (this.props.isSignUpRecaptcha && isIamRobot) {
      const info = {
        message: this.props.labels.SIGNUP_ROBOT_VALIDATION_ERROR_MESSAGE,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    this.setState({
      isLoading: true,
    });
    const user = {
      CountryId: Country,
      Email: email,
      FirstName: firstname,
      LastName: lastname,
      IsFreelancer: Usage == 1 ? true : false,
      Password: password,
    };
    signUpApi(user)
      .then((response) => {
        if (response.success) {
          this.props.showResendPopUp(this.props.labels.SIGNUP_EMAIL_RESEND);
          this.props.onClose();
        } else {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  };
  handleTermsClick = (type) => {
    let selectedTerm;
    const { Configs } = this.state;
    switch (type) {
      case 1: {
        selectedTerm = Configs.find(
          (item) => item.ConfigName === "Terms and Condition"
        );
        break;
      }
      case 2: {
        selectedTerm = Configs.find(
          (item) => item.ConfigName === "Privacy policy"
        );
        break;
      }
      case 3: {
        selectedTerm = Configs.find(
          (item) => item.ConfigName === "Cookie agreement"
        );
        break;
      }
    }
    this.setState({
      selectedTerm: {
        heading: selectedTerm.ConfigName,
        content: selectedTerm.ConfigValue,
      },
      isTerms: true,
    });
  };
  handleRecapctchaChange = (value) => {
    this.setState({
      isIamRobot: false,
    });
  };
  render() {
    const {
      firstname,
      lastname,
      invalidfirstname,
      invalidlastname,
      email,
      invalidemail,
      password,
      invalidpassword,
      UsageTypeList,
      Usage,
      invalidUsage,
      CountriesList,
      Country,
      invalidCountry,
      isAgreed,
      isTerms,
      selectedTerm,
      isLoading = false,
      invalidpasswordMessage,
      confirmPassword,
      invalidconfirmPassword,
      isGoogleSignInActive,
    } = this.state;
    const {
      onClose,
      labels,
      onLoginClick,
      showResendPopUp,
      isSignUpRecaptcha,
    } = this.props;
    return isTerms ? (
      <Terms
        onClose={() => this.setState({ isTerms: false })}
        content={selectedTerm.content}
        heading={selectedTerm.heading}
      />
    ) : (
      <React.Fragment>
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        <div
          className={`LoginForm RegisterForm ${this.state.isGoogleSignInActive ? "small" : ""
            }`}
          test-data-id="login-register-form"
        >
          <form onKeyPress={this.handleKeyPress}>
            <div className="login">
              <button onClick={onClose} className="close-btn" />
              {!this.state.isGoogleSignInActive && (
                <React.Fragment>
                  {" "}
                  <input
                    id="firstname"
                    name="firstname"
                    type="text"
                    test-data-id="register-user-first-name"
                    placeholder={"First Name"}
                    className={`LoginInput ${invalidfirstname ? "inValid" : ""
                      }`}
                    value={firstname}
                    onChange={this.handleFormFieldChange}
                    tabIndex={1}
                    onKeyPress={this.handleKeyPress}
                  />
                  <input
                    id="lastname"
                    name="lastname"
                    type="text"
                    test-data-id="register-user-last-name"
                    placeholder={"Last Name"}
                    className={`LoginInput ${invalidlastname ? "inValid" : ""}`}
                    value={lastname}
                    onChange={this.handleFormFieldChange}
                    tabIndex={2}
                    onKeyPress={this.handleKeyPress}
                  />
                  <input
                    id="Email"
                    type="text"
                    name="email"
                    test-data-id="register-user-email"
                    placeholder={labels.loginEmail}
                    className={`LoginInput ${invalidemail ? "inValid" : ""}`}
                    value={email}
                    onChange={this.handleFormFieldChange}
                    onKeyPress={this.handleKeyPress}
                    autoComplete="new-email"
                    tabIndex={3}
                  />
                  <input
                    id="Password"
                    type="password"
                    name="password"
                    test-data-id="register-user-password"
                    placeholder={labels.loginPassword}
                    className={`LoginInput ${invalidpassword ? "inValid" : ""}`}
                    value={password}
                    onChange={this.handleFormFieldChange}
                    onKeyPress={this.handleKeyPress}
                    autoComplete="new-password"
                    tabIndex={4}
                  />
                  <input
                    id="Password"
                    type="password"
                    name="confirmPassword"
                    test-data-id="register-user-password"
                    placeholder={labels.loginConfirmPassword}
                    className={`LoginInput ${invalidconfirmPassword ? "inValid" : ""
                      }`}
                    value={confirmPassword}
                    onChange={this.handleFormFieldChange}
                    onKeyPress={this.handleKeyPress}
                    autoComplete="new-password"
                    tabIndex={4}
                  />
                  <Select
                    name="Usage"
                    test-data-id="register-user-type"
                    className="select-input"
                    placeholder="Choose preffered usage"
                    className={`select-input select-usage ${invalidUsage ? "inValid" : ""
                      }`}
                    value={Usage || ""}
                    onChange={(selectedOption) =>
                      this.handleFormSelectChange("Usage", selectedOption)
                    }
                    options={UsageTypeList}
                    clearable={false}
                    tabIndex={5}
                  />
                  <Select
                    name="DurationType"
                    className="select-input"
                    test-data-id="register-user-country"
                    placeholder="Choose a country"
                    className={`select-input select-durationType ${invalidCountry ? "inValid" : ""
                      }`}
                    value={Country || ""}
                    onChange={(selectedOption) =>
                      this.handleFormSelectChange("Country", selectedOption)
                    }
                    options={CountriesList}
                    clearable={false}
                    tabIndex={6}
                  />
                  {/* <label className="validationMsg">
              Invalid Username and password
            </label> */}
                  <input
                    type="checkbox"
                    name="isAgreed"
                    className={isAgreed ? "checked" : "input-checkbox"}
                    value={isAgreed}
                    checked={isAgreed ? isAgreed : false}
                    onChange={this.handleAgreeToggle}
                    test-data-id="register-policy"
                    tabIndex={7}
                  />
                  <label className="signup-agreement">
                    {labels.AGREEMENT_LABEL}{" "}
                    <a onClick={() => this.handleTermsClick(1)}>
                      {labels.TERM_OF_USE_LABEL}
                    </a>
                    ,{" "}
                    <a onClick={() => this.handleTermsClick(2)}>
                      {labels.PRIVACY_POLICY_LABEL}{" "}
                    </a>
                    {"and "}
                    <a onClick={() => this.handleTermsClick(3)}>
                      {labels.COOKIE_AGREEMENT_LABEL}
                    </a>
                  </label>
                  {isSignUpRecaptcha && (
                    <div className="signup-captcha">
                      <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        sitekey="6Lfrp5IUAAAAABIWqChZ3nHm2ZqnPjjg89d0WcBu"
                        onChange={this.handleRecapctchaChange}
                      />
                    </div>
                  )}
                  <div className="LoginBtn">
                    <input
                      test-data-id="register-submit"
                      type="button"
                      value="Sign Up"
                      className="LoginSubmit"
                      onClick={this.handleSubmit}
                      tabIndex={8}
                    />
                  </div>
                </React.Fragment>
              )}
              <GoogleSignup
                onClose={this.handleGoogleClose}
                className="LoginSubmitGoogle"
                onHideMainForm={this.handleHideMainForm}
              />
              <a className="Forgot" onClick={onLoginClick}>
                {htmlParser(labels.ALREADY_HAVE_AN_ACCOUNT_LABEL)}
              </a>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;

  return { labels };
};
const mapActionToProps = { loginAuth, notificationAction };
export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(RegisterForm));
