import React, { useEffect, useState } from "react";
import PresentationPopup from "../popups/PresentationPopup/PresentationPopup";
import { Typography, Row, Col } from "antd";
import { getUserDefaultPresentation } from "../dashboardApi";
import { LoadingMaskRow } from "../../../common";
const { Title, Text } = Typography;

const style = {
  fontWeight: 500
}

function PresentationWidget({ labels, history }) {
  const [userPresentation, serUserPresentation] = useState({
    Detail: "",
    ProfileValue: "",
    presentationTitle: "",
  });
  const [fetchingPresentation, setFetchingPresentation] = useState(false);
  const { Detail, ProfileValue, presentationTitle } = userPresentation;
  useEffect(() => {
    getUserPresentationDetails();
  }, []);

  const getUserPresentationDetails = () => {
    setFetchingPresentation(true);
    getUserDefaultPresentation()
      .then((res) => {
        if (res.success) {
          serUserPresentation(
            res.items
              ? {
                ...res.items,
                presentationTitle: res.items.Title,
                isAvailable: true,
              }
              : userPresentation
          );
          setFetchingPresentation(false);
        }
      })
      .catch((err) => {
        setFetchingPresentation(false);
        console.log("Err ", err);
      });
  };

  return (
    <>
      {fetchingPresentation ? (
        <LoadingMaskRow />
      ) : (
        <div className="cards-new">
          <div className="d-flex justify-content-between items-center">
            <Title level={3}>              {labels.IPRO_NAVIGATION_PRESENTATION_LABEL}
            </Title>
            <button className="btn-add" onClick={() => history?.push("/presentations")}>Add Presentation +</button>
          </div>
          {userPresentation?.isAvailable ?
            <Row gutter={[6, 12]}>
              <Col span={10}>
                <Text className="fnt-s-12" style={{ color: "#00000066" }}>{labels.TITLE_LABEL}</Text>
                <div className="mt-1">
                  <Text className="fnt-s-16 " style={style}>{presentationTitle || labels.NOT_AVAILABLE_LABEL}</Text>
                </div>
              </Col>
              <Col span={14}>
                <Text className="fnt-s-12" style={{ color: "#00000066" }}>{labels.ROLE_LABEL}</Text>
                <div className="mt-1">
                  <Text className="fnt-s-16 " style={style}>{ProfileValue || labels.NOT_AVAILABLE_LABEL}</Text>
                </div>
              </Col>
              <Col span={24}>
                <Text className="fnt-s-12" style={{ color: "#00000066" }}>Description</Text>
                <div className="mt-1">
                  <Text className="fnt-s-16" style={style}>{Detail || ""}</Text>
                </div>
              </Col>
            </Row>
            :
            <Text className="fnt-s-16">Looks like you haven’t added any presentation yet</Text>
          }
          {userPresentation.isAvailable && (
            <PresentationPopup
              labels={labels}
              userPresentation={userPresentation}
            />
          )}
        </div>
      )}
    </>
  );
}

export default PresentationWidget;
