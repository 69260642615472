import axios from "axios";
import { ApiUrl } from "../../api";

let source;

export const getShortlistApi = () =>
  axios.get(ApiUrl.Shortlists.Get).then(({ data }) => data);

export const getOppuntinitiesApi = () =>
  axios.get(ApiUrl.Requests.GetAllRequests).then(({ data }) => data);

export const postShortlistApi = info =>
  axios.post(ApiUrl.Shortlists.Post, info).then(({ data }) => data);

export const deleteShortlistApi = shortlistId =>
  axios
    .get(
      `${
        ApiUrl.Shortlists.GetAllRequestByShortlistId
      }?shortlistId=${shortlistId}`
    )
    .then(({ data }) => data);

export const deleteResumeApi = (resumeId, shortlistId) =>
  axios
    .delete(
      `${
        ApiUrl.Shortlistresumes.DeleteShortlist
      }?resumeId=${resumeId}&shortlistId=${shortlistId}`
    )
    .then(({ data }) => data);

export const getShortlistResumeApi = ShortlistId => {
  if (source) source.cancel("Operation canceled by the user.");
  source = axios.CancelToken.source();
  return axios
    .get(`${ApiUrl.Shortlistresumes.Get}/?shortlistid=${ShortlistId}`, {
      cancelToken: source.token
    })
    .then(({ data }) => data);
};

export const GetShortlistResumeCount = (requestId) => {
  return axios
    .get(ApiUrl.Shortlistresumes.GetShortlistResumeCount(requestId))
    .then(({ data }) => data);
};
