import React from "react";
import Select from "react-select";
import "./dashboard.scss";
import Widget from "./Widget";
import {
  Column,
  HelpTooltip,
} from "../../../../common";
import {
  DownloadColllabTimeLogReport,
} from "../../../Snapshot/snapshotApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
class Dashboard extends React.Component {
  state = {};

  componentDidMount() { }

  handleDownloadCollabReport = () => {
    DownloadColllabTimeLogReport(
      parseInt(this.state.selectedCollaboration.value),
      this.props.token
    )
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Collaboration TimeLog report.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const { UserFeatures, collaborationsList, isHelpActive, labels } = this.props;
    const {
      selectedCollaboration,
    } = this.state;
    return (
      <div className="dashboard-view">
        <React.Fragment>
          <Column className="column-1">
            {UserFeatures && UserFeatures.find(x => x.Name == "Tictell-Home-CollaborationReporting" && x.IsActive) &&
              <Widget className="report-widgets">
                <Widget.Header>
                  {isHelpActive && (
                    <HelpTooltip
                      content={labels.Collaboration_Dashboard_Message}
                    />
                  )}
                  <i className="collab-icon" />
                  <label>{"Collaboration reports"}</label>
                </Widget.Header>
                <Widget.Content>
                  <div
                    className={"collab-widget-content"}
                    style={{ margin: "0px 5px auto" }}
                  >
                    <Select
                      name="Collaboration"
                      className={`select-input`}
                      placeholder={"Select collaboration"}
                      value={selectedCollaboration || ""}
                      onChange={(selectedOption) =>
                        this.setState({ selectedCollaboration: selectedOption })
                      }
                      clearable={false}
                      searchable={true}
                      options={collaborationsList}
                    />
                    <button
                      className="SendButton"
                      onClick={() => this.handleDownloadCollabReport()}
                      test-data-id="input-button-opportunity-saveBtn"
                    >
                      {"Previous Month Report"}
                    </button>
                  </div>
                </Widget.Content>
              </Widget>
            }
          </Column>
        </React.Fragment>
      </div >
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Dashboard);
