import React, { useEffect, useState } from "react";
import CompanyPopup from "../popups/PresentationPopup/CompanyPopup";
import { Typography, Button, Tooltip, Row, Col } from "antd";
import { EditFilled } from "@ant-design/icons";
import { LoadingMaskRow } from "../../../common";
import { getDefaultUserCompany } from "../dashboardApi";
import { Link } from "react-router-dom";
const { Title, Text } = Typography;

const style = {
  fontWeight: 500
}

function UserCompanyWidget({ labels, history }) {
  const [userCompany, setUserCompany] = useState({
    CompanyAddress: "",
    CompanyName: "",
    Country: { CountryId: 0, CountryName: "", ISOCode: "" },
    Detail: "",
    Industries: { IndustryId: 0, IndustryValue: "" },
    UserPhonenumberValue: "",
    Vat: "",
    WebUrl: "",
  });
  const [isFetching, setFetching] = useState(false);

  const { CompanyName, Detail, Industries } = userCompany;

  useEffect(() => {
    getUserCompanyDetails();
  }, []);

  const getUserCompanyDetails = () => {
    setFetching(true);
    getDefaultUserCompany()
      .then((res) => {
        if (res.success) {
          setUserCompany(
            res.items
              ? {
                ...res.items,
                isAvailable: true,
              }
              : userCompany
          );
          setFetching(false);
        }
      })
      .catch((err) => {
        setFetching(false);

        console.log("Err ", err);
      });
  };

  return (
    <>
      {isFetching ? (
        <LoadingMaskRow />
      ) : (
        <div className="cards-new">
          <div className="d-flex justify-content-between items-center">
            <Title level={3}>             {labels.SEARCHER_NAVIGATION_COMPANY_LABEL}
            </Title>
            <button className="btn-add" onClick={() => history?.push({
              pathname: '/companies',
              state: { new: true }
            })}>Add +</button>
          </div>
          {userCompany?.isAvailable ?
            <Row gutter={[6, 12]}>
              <Col span={10}>
                <Text className="fnt-s-12" style={{ color: "#00000066" }}>{labels.NAME_LABEL}</Text>
                <div className="mt-1">
                  <Text className="fnt-s-16 " style={style}>{CompanyName || labels.NOT_AVAILABLE_LABEL}</Text>
                </div>
              </Col>
              <Col span={14}>
                <Text className="fnt-s-12" style={{ color: "#00000066" }}>{labels.INDUSTRY_LABEL}</Text>
                <div className="mt-1">
                  <Text className="fnt-s-16 " style={style}>{Industries && Industries.IndustryValue || labels.NOT_AVAILABLE_LABEL}</Text>
                </div>
              </Col>
              <Col span={24}>
                <Text className="fnt-s-12" style={{ color: "#00000066" }}>Description</Text>
                <div className="mt-1">
                  <Text className="fnt-s-16" style={style}>{Detail || ""}</Text>
                </div>
              </Col>
            </Row>
            :
            <Text className="fnt-s-16">Looks like you haven’t added any workplace yet</Text>
          }
          {userCompany.isAvailable && (
            <CompanyPopup labels={labels} userCompany={userCompany} />
          )}
        </div>
        // <div className="presentation">
        //   <div className="d-flex align-items-center">
        //     <Title className="mb-0" level={4}>
        //       {labels.SEARCHER_NAVIGATION_COMPANY_LABEL}
        //     </Title>{" "}
        //     <Link to="/companies">
        //       <Tooltip title="Edit">
        //         <Button
        //           size="small"
        //           type="default"
        //           shape="circle"
        //           className="m-2 mb-0 mt-0"
        //           icon={<EditFilled />}
        //         />
        //       </Tooltip>
        //     </Link>
        //   </div>
        //   <div className="d-flex flex-column">
        //     <Text>
        //       <Text className="text-muted">{labels.NAME_LABEL}: </Text>
        //       <strong>{CompanyName || labels.NOT_AVAILABLE_LABEL}</strong>
        //     </Text>
        //     <Text className=" ">
        //       <Text className="text-muted">{labels.INDUSTRY_LABEL}: </Text>{" "}
        //       <strong>
        //         {Industries&& Industries.IndustryValue || labels.NOT_AVAILABLE_LABEL}
        //       </strong>
        //     </Text>
        //     <Text className="text-muted mt-2">{Detail || ""}</Text>
        //   </div>
        //   {userCompany.isAvailable && (
        //     <CompanyPopup labels={labels} userCompany={userCompany} />
        //   )}
        // </div>
      )}
    </>
  );
}

export default UserCompanyWidget;
