import React from "react";
import { Column, Button, Select, EmptyInfo } from "../../../../common";
import {
  getAllTimeSheetsApi,
  GetWeekAllTimeLogs,
  GetWeekAllExpenseLogs,
  UpdateTimeSheetStatusApi,
  UpdateExpenseSheetStatusApi,
} from "../../snapshotApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import moment from "moment";
import TimelogDetail from "./components/TimestampDetail";
import TimelogList from "./components/TimelogList";
import ExpenseList from "./components/ExpenseList";
import ExpenseDetail from "./components/ExpenseDetail";
import "./reports.scss";
import { ListItem } from "../../../Tictell/views/components/ListItem";
class Reports extends React.Component {
  state = {
    options: [
      { label: "", value: 1 },
      { label: "", value: 2 },
      { label: "", value: 3 },
    ],
    acceptedCollaborations: [],
    isCollapsed1: false,
    isCollapsed2: false,
    isCollapsed3: true,
    isCollapsed4: false,
    isCollapsed5: true,
    allTimeSheets: [],
    weekTimelogsList: [],
    weekExpenselogsList: [],
    currentExpenselog: {},
    currentTimeReport: {
      StatusId: 1,
      isEmpty: true,
      Amount: "",
      Time: "",
    },
    currentTimelog: {},
    TimeSheetStatusId: 2,
    ExpenseSheetStatusId: 2,
    isLoading: false,
    TimelogIds: {},
    ExpenseIds: {},
    StatusId: "",
  };
  componentDidMount() {
    this.setState({
      options: [
        { label: this.props.labels.pendingForApproval, value: 1 },
        { label: this.props.labels.CollaborationsApproved, value: 2 },
        { label: this.props.labels.collaborationsRejected, value: 3 },
      ],
      selectedOption: { value: 1, label: this.props.labels.pendingForApproval },
    });
    getAllTimeSheetsApi(1, this.props.token).then((res) => {
      if (res.success) {
        this.setState({
          allTimeSheets: res.items,
        });
        this.handleTimeReportClick(res.items[0]);
      }
    });
  }
  handleOptionChange = (option) => {
    this.setState({
      selectedOption: option,
    });
    getAllTimeSheetsApi(option.value, this.props.token).then((res) => {
      if (res.success) {
        this.setState({
          allTimeSheets: res.items,
        });
        this.handleTimeReportClick(res.items[0]);
      }
    });
  };
  renderOptionClass = (option) => {
    if (!option) {
      return "";
    }
    switch (option.value) {
      case 1:
        return "yellow";
      case 2:
        return "green";
      case 3:
        return "red";
      default:
        return "";
    }
  };
  handleTimeReportClick = (item) => {
    if (!item) {
      this.setState({
        weekTimelogsList: [],
        currentTimeReport: {
          isEmpty: true,
        },
        currentTimelog: {},
      });
      return;
    }
    if (item.WeeklyTimeSheetId > 0) {
      GetWeekAllTimeLogs(
        item.CollaborationId,
        moment(item.StartDate).format("DD/MM/YYYY"),
        moment(item.EndDate).format("DD/MM/YYYY"),
        this.props.token
      ).then((res) => {
        if (res.success) {
          const weekTimelogsList = res.items.TimeLogs.map((a) => ({
            ...a,
            Currency: !isNaN(a.Currency)
              ? `${
                  this.props.Currencies.find((b) => b.CurrencyId == a.Currency)
                    .Name
                }`
              : a.Currency,
          }));
          this.setState({
            TimelogIds: res.items.TimeLogs.map((id) => id.TimeLogId),
            weekTimelogsList,
            StatusId: res.items.StatusId,
            allTimeSheets: this.state.allTimeSheets.map((a) =>
              a.WeeklyTimeSheetId == item.WeeklyTimeSheetId
                ? { ...a, isSelected: true, isActive: true }
                : { ...a, isSelected: false, isActive: false }
            ),
            IsCollapsed2: false,
            currentTimeReport: {
              ...item,
              Amount: !isNaN(res.items.Amount.split(" ")[1])
                ? `${res.items.Amount.split(" ")[0]} ${
                    this.props.Currencies.find(
                      (b) => b.CurrencyId == res.items.Amount.split(" ")[1]
                    ).Name
                  }`
                : res.items.Amount,
              Time: res.items.Time,
            },
            TimeSheetStatusId: res.items.StatusId,
          });
          this.handleTimelogItemClick(res.items.TimeLogs[0]);
        }
      });
    } else {
      GetWeekAllExpenseLogs(
        item.CollaborationId,
        moment(item.StartDate).format("DD/MM/YYYY"),
        moment(item.EndDate).format("DD/MM/YYYY"),
        this.props.token
      ).then((res) => {
        if (res.success) {
          this.setState({
            ExpenseIds: res.items.ExpenseLogs.map((e) => e.ExpenseId),
            weekExpenselogsList: res.items.ExpenseLogs.map((ex) => ({
              ...ex,
              CategoryName: this.props.ExpenseCategories.find(
                (a) => a.ExpenseCategoryId == ex.ExpenseCategoryId
              ),
              CurrencyName: this.props.Currencies.find(
                (a) => a.CurrencyId == ex.CurrencyId
              ),
            })),
            currentTimeReport: {
              ...item,
              Amount: res.items.Amount,
              Time: "",
            },
            allTimeSheets: this.state.allTimeSheets.map((a) =>
              a.ExpenseSheetId == item.ExpenseSheetId
                ? { ...a, isSelected: true, isActive: true }
                : { ...a, isSelected: false, isActive: false }
            ),
            ExpenseSheetStatusId: res.items.StatusId,
          });
          this.handleExpenselogItemClick(res.items.ExpenseLogs[0]);
        }
      });
    }
  };
  handleTimelogItemClick = (item) => {
    if (!item) {
      this.setState({
        currentTimelog: {},
      });
    }
    this.setState({
      currentTimelog: {
        ...item,
        StartTime: `${item.StartTime.split(":")[0]}:${
          item.StartTime.split(":")[1]
        }`,
        EndTime: `${item.EndTime.split(":")[0]}:${item.EndTime.split(":")[1]}`,
      },
      weekTimelogsList: this.state.weekTimelogsList.map((a) =>
        a.TimeLogId == item.TimeLogId
          ? { ...a, isSelected: true, isActive: true }
          : { ...a, isSelected: false, isActive: false }
      ),
      isCollapsed3: false,
    });
  };

  handleExpenselogItemClick = (item) => {
    if (!item) {
      this.setState({
        currentExpenselog: {},
      });
      return;
    }
    this.setState({
      currentExpenselog: item,
      weekExpenselogsList: this.state.weekExpenselogsList.map((a) =>
        a.ExpenseId == item.ExpenseId
          ? { ...a, isSelected: true, isActive: true }
          : { ...a, isSelected: false, isActive: false }
      ),
      isCollapsed5: false,
    });
  };
  updateTimeSheetStatus = (status) => {
    this.setState({ isLoading: true });
    UpdateTimeSheetStatusApi(
      this.state.currentTimeReport.WeeklyTimeSheetId,
      status,
      this.state.TimelogIds,
      this.props.token
    )
      .then((res) => {
        if (res.success) {
          const allTimeSheets = this.state.allTimeSheets.filter(
            (a) =>
              a.WeeklyTimeSheetId !=
              this.state.currentTimeReport.WeeklyTimeSheetId
          );
          this.setState({
            TimeSheetStatusId: status,
            allTimeSheets,
            currentTimeReport: {
              isEmpty: true,
            },
            weekTimelogsList: [],
            currentTimelog: {},
          });
          this.setState({ isLoading: false });
          const info = {
            status: "success",
            message:
              status == 3
                ? this.props.labels.timesheetRejectedMsg
                : this.props.labels.timesheetApprovedMsg,
          };
          this.props.notificationAction(info);
        } else {
          this.setState({ isLoading: false });
          const info = {
            status: "error",
            message: res.message,
          };
          this.props.notificationAction(info);
        }
      })
      .catch((Err) => console.log("Err ", Err));
  };
  updateExpenseSheetStatus = (status) => {
    this.setState({ isLoading: true });
    UpdateExpenseSheetStatusApi(
      this.state.currentTimeReport.ExpenseSheetId,
      status,
      this.state.ExpenseIds,
      this.props.token
    )
      .then((res) => {
        if (res.success) {
          const allTimeSheets = this.state.allTimeSheets.filter(
            (a) =>
              a.ExpenseSheetId != this.state.currentTimeReport.ExpenseSheetId
          );
          this.setState({
            ExpenseSheetStatusId: status,
            allTimeSheets,
            currentTimeReport: {
              isEmpty: true,
            },
            currentExpenselog: {},
            expenseList: [],
            weekTimelogsList: [],
            currentTimelog: {},
          });
          this.setState({ isLoading: false });
          const info = {
            status: "success",
            message:
              status == 3
                ? this.props.labels.expensesheetRejectmsg
                : this.props.labels.expenseSheetApprovedMsg,
          };
          this.props.notificationAction(info);
        } else {
          this.setState({ isLoading: true });
          const info = {
            status: "error",
            message: res.message,
          };
          this.props.notificationAction(info);
        }
      })
      .catch((Err) => console.log("Err ", Err));
  };
  handleMenuClick = (currentView) => {
    this.setState({
      currentView,
    });
  };
  render() {
    const {
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      isCollapsed4,
      isCollapsed5,
      selectedOption,
      currentTimeReport,
      isLoading
    } = this.state;
    const { labels, isHelpActive } = this.props;
    return (
      <div className="reports-view">
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={labels.snapshot_tictell_reports_title}
            onClick={() => this.setState({ isCollapsed1: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn1"
          />
          <Column.Head>
            <div className="heading">
              {labels.snapshot_tictell_reports_title}
            </div>
            <Button
              className="collapseBtn"
              testId="Collapasebtn1"
              onClick={() => this.setState({ isCollapsed1: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          {!isCollapsed1 && (
            <Select
              readonly={true}
              searchable={false}
              name="AllPresentation"
              value={selectedOption}
              className={`select-input ${this.renderOptionClass(
                selectedOption
              )}`}
              // value={(Profiles && Profiles.ProfileValue) || ""}
              placeholder={labels.presentPrimaryRolePlaceholder}
              onChange={(selectedOption) =>
                this.handleOptionChange(selectedOption)
              }
              options={this.state.options}
              clearable={false}
            />
          )}
          <Column.Body className="reports-body">
            <div className="tictell-list">
              {this.state.allTimeSheets &&
                this.state.allTimeSheets.map((item) => (
                  <ListItem
                    onClick={() => this.handleTimeReportClick(item)}
                    item={item}
                    key={item.ExpenseSheetId || item.WeeklyTimeSheetId}
                  >
                    <div className="duration-container">
                      <label>{moment(item.StartDate).format("MMM Do")}</label>
                      <label>{moment(item.EndDate).format("MMM Do")}</label>
                      <label>{`${moment(item.StartDate).format(
                        "YYYY"
                      )}  Week ${moment(item.StartDate).format("W")}`}</label>
                    </div>
                    <div className="name-container">
                      <label>{item.IProName}</label>
                      <label>{item.CollaborationTitle}</label>
                    </div>
                    <span
                      className={`${
                        item.WeeklyTimeSheetId > 0
                          ? "timesheet"
                          : "expensesheet"
                      }-icon`}
                    />
                  </ListItem>
                ))}
            </div>
          </Column.Body>
        </Column>
        {(this.state.currentTimeReport.isEmpty ||
          this.state.currentTimeReport.WeeklyTimeSheetId > 0) && (
          <Column collapse={isCollapsed2} className="col-2">
            <Column.Collapsed
              text={labels.SnapshotTimesheetTitle}
              onClick={() => this.setState({ isCollapsed2: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipSnapshotExpand}
              tooltipHelp={labels.hlptxtSnapshotExpand}
              tooltipPlace="left"
              testId="expandbtn2"
            />
            <Column.Head>
              <div className="heading">{labels.SnapshotTimesheetTitle}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed2: true })}
                tooltipButton={labels.ToolTipSnapshotCollapse}
                tooltipHelp={labels.hlptxtSnapshotCollapse}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapsebtn2"
              />
            </Column.Head>
            {!isCollapsed2 && (
              <div
                className={`tictell-list-item-container timesheet-summary status-${currentTimeReport.StatusId}`}
              >
                {(currentTimeReport.ExpenseSheetId > 0 ||
                  currentTimeReport.WeeklyTimeSheetId > 0) && (
                  <div className="duration-container">
                    <label>
                      {moment(currentTimeReport.StartDate).format("MMM Do")}
                    </label>
                    <label>
                      {moment(currentTimeReport.EndDate).format("MMM Do")}
                    </label>
                    <label>{`${moment(currentTimeReport.StartDate).format(
                      "YYYY"
                    )}  Week ${moment(currentTimeReport.StartDate).format(
                      "W"
                    )}`}</label>
                  </div>
                )}
                <div className="name-container">
                  <label>{currentTimeReport.IProName}</label>
                  <label>{currentTimeReport.CollaborationTitle}</label>
                </div>
                <div className="amount-time">
                  <label>{currentTimeReport.Amount}</label>
                  <label>{currentTimeReport.Time}</label>
                </div>
              </div>
            )}
            <Column.Body>
              <div className="timelog-list-container">
                {this.state.weekTimelogsList &&
                this.state.weekTimelogsList.length > 0 ? (
                  <TimelogList
                    timelogList={this.state.weekTimelogsList}
                    onItemClick={this.handleTimelogItemClick}
                    handleAddNewTimelog={this.handleAddNewTimelog}
                    TimeSheetStatusId={this.state.TimeSheetStatusId}
                    updateTimeSheetStatus={this.updateTimeSheetStatus}
                    isHelpActive={isHelpActive}
                  />
                ) : (
                  <EmptyInfo>
                    {this.state.allTimeSheets.length < 1
                      ? this.props.labels.snapshot_no_pending_timesheets_msg
                      : this.props.labels.snapshot_select_timelog_msg}
                  </EmptyInfo>
                )}
              </div>
            </Column.Body>
          </Column>
        )}
        {(this.state.currentTimeReport.isEmpty ||
          this.state.currentTimeReport.WeeklyTimeSheetId > 0) && (
          <Column collapse={isCollapsed3} className="col-3">
            <Column.Collapsed
              text={labels.snapshotTimestampDetails}
              onClick={() => this.setState({ isCollapsed3: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipSnapshotExpand}
              tooltipHelp={labels.hlptxtSnapshotExpand}
              tooltipPlace="left"
              testId="expandbtn3"
            />
            <Column.Head>
              <div className="heading">{labels.snapshotTimestampDetails}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed3: true })}
                tooltipButton={labels.ToolTipSnapshotCollapse}
                tooltipHelp={labels.hlptxtSnapshotCollapse}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapsebtn3"
              />
            </Column.Head>
            <Column.Body>
              <TimelogDetail
                currentTimelog={this.state.currentTimelog}
                snapshotDate={labels.snapshotDate}
                StartTimeLabel={labels.snapshotStartTime}
                EndTimeLabel={labels.snapshotEndtime}
                DurationLabel={labels.snapshotDuration}
                AmountLabel={labels.snapshotAmount}
                MemoLabel={labels.snapshotMemo}
                CommentLabel={labels.snapshotComment}
                Dateformatelabel={labels.snapshotDateFormate}
                ReviewedBylabel={labels.ReviewedBy_Label}
                ReviewedDatelabel={labels.ReviewedDate_Label}
                Status={this.state.StatusId}
                labels={labels}
              ></TimelogDetail>
            </Column.Body>
          </Column>
        )}
        {this.state.currentTimeReport.ExpenseSheetId > 0 && (
          <Column collapse={isCollapsed4} className="col-2">
            <Column.Collapsed
              text={labels.snapshot_expense_title}
              onClick={() => this.setState({ isCollapsed4: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandbtn4"
            />
            <Column.Head>
              <div className="heading">{labels.snapshot_expense_title}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed4: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapsebtn4"
              />
            </Column.Head>
            {!isCollapsed4 && (
              <div
                className={`tictell-list-item-container timesheet-summary status-${currentTimeReport.StatusId}`}
              >
                <div className="duration-container">
                  <label>
                    {moment(currentTimeReport.StartDate).format("MMM Do")}
                  </label>
                  <label>
                    {moment(currentTimeReport.EndDate).format("MMM Do")}
                  </label>
                  <label>{`${moment(currentTimeReport.StartDate).format(
                    "YYYY"
                  )}  Week ${moment(currentTimeReport.StartDate).format(
                    "W"
                  )}`}</label>
                </div>
                <div className="name-container">
                  <label>{currentTimeReport.IProName}</label>
                  <label>{currentTimeReport.CollaborationTitle}</label>
                </div>
                <div className="amount-time">
                  <label>{currentTimeReport.Amount}</label>
                </div>
              </div>
            )}
            <Column.Body>
              <ExpenseList
                labels={labels}
                expenseList={this.state.weekExpenselogsList}
                onItemClick={this.handleExpenselogItemClick}
                handleExpenseApprove={this.handleExpenseApprove}
                ExpenseSheetStatusId={this.state.ExpenseSheetStatusId}
                updateExpenseSheetStatus={this.updateExpenseSheetStatus}
                isHelpActive={isHelpActive}
              />
            </Column.Body>
          </Column>
        )}
        {this.state.currentTimeReport.ExpenseSheetId > 0 && (
          <Column collapse={isCollapsed5} className="col-2">
            <Column.Collapsed
              text={labels.snapshot_expense_details}
              onClick={() => this.setState({ isCollapsed5: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandbtn5"
            />
            <Column.Head>
              <div className="heading">{labels.snapshot_expense_details}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed5: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapsebtn5"
              />
            </Column.Head>
            <Column.Body>
              <ExpenseDetail
                snapshotExpenseDate={labels.snapshotExpenseDate}
                Dateformatelabel={labels.snapshotDateFormate}
                CategoryLabel={labels.snapshot_category_label}
                AmountLabel={labels.snapshotAmount}
                CurrencyLabel={labels.snapshotCurrencyLabel}
                DetailsLabel={labels.snapshotDetailsLabel}
                CommentLabel={labels.snapshotComment}
                ReviewedBylabel={labels.ReviewedBy_Label}
                ReviewedDatelabel={labels.ReviewedDate_Label}
                AttachmentsLabel={labels.snapshotAttachmentsLabel}
                currentExpense={this.state.currentExpenselog}
                currenciesList={this.props.Currencies}
                Status={this.state.StatusId}
                expenseCategoriesList={this.props.ExpenseCategories}
              ></ExpenseDetail>
            </Column.Body>
          </Column>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Reports);
