import { ActionTypes } from "../../../actions";

export const onStateChangeAction = ({
  listCollapsed,
  detailCollapsed,
  selectedMessage,
  filterMessages,
  messageList,
  isFetching,
  dialogMessage,
  searchKey
}) => dispatch => {
  listCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_SENT.LIST_COLLAPSED,
      payload: listCollapsed
    });
  detailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_SENT.DETAIL_COLLAPSED,
      payload: detailCollapsed
    });
  selectedMessage !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_SENT.SELECTED_MESSAGE,
      payload: selectedMessage
    });
  filterMessages !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_SENT.FILTER_MESSAGES,
      payload: filterMessages
    });
  messageList !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_SENT.MESSAGE_LIST,
      payload: messageList
    });
  isFetching !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_SENT.IS_FETCHING,
      payload: isFetching
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_SENT.DIALOG_MESSAGE,
      payload: dialogMessage
    });
  searchKey !== undefined &&
    dispatch({
      type: ActionTypes.MESSAGE_SENT.SEARCH_KEY,
      payload: searchKey
    });
};
