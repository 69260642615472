import React from "react";
import { loginAuth, redirectLoginAuth } from "./components/Auth/loginAction";
import { notificationAction } from "../../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PageWrapper } from "../../components";
import { StorageService } from "../../api";
import { MobileLogin } from "./components";
import { logoutAction } from "../../store/initialConfig";
import { logoutApi } from "../../components/Navigation/navigationApi";
import { RESET_LANDINGPAGE_URL } from "../../utilities/enviroments";
import {
  emailTokenConfirmationApi,
  UserNotificationsUpdate,
  NetworkInvitationActionApi,
  getDashboardMenusApi,
  RegisterThirdPartyApi,
  igeLoginApi,
  genetrateTokenApi,
} from "./leadPageApi";
import { privateRoutes, publicRoutes } from "../../Routes/routing";
import "./lead-page.scss";
import { isValidEmail } from "../../utilities";

class LeadPage extends React.Component {
  constructor(props) {
    super(props);
    this.facebookClientId = "";
    this.linkedInUrl = "";
    this.setFacebookClientId();
    this.setLinkedInUrl();
    this.state = {
      email: "",
      password: "",
      isFacebookConnected: false,
      IgeDomains: [],
      dialogMessage: "",
      code: "",
      errorMessage: "",
      showLogin: false,
      showSignup: false,
      isLoading: true,
      showForgotPassword: false,
      showTokenResend: false,
      systemLabelData: {},
      dialogMessage: "",
      signInConfigs: [],
      footerMenu: null,
      resumeAnalyzerMenu: null,
      mobileAppsMenu: null,
      benefitsMenu: null,
      articlesMenu: null,
      prodooVideoMenu: null,
      searcherPhillipMenu: null,
      contactUsMenu: null,
      howItWorksMenu: null,
      activePage: {
        slider: true,
      },
      isMobileApp: false,
      aboutUsMenu: {
        IsActive: true,
      },
      showGoogleLogin: false,
    };
    this.makeTimer();
  }
  makeTimer() {
    setInterval(() => {
      this.setState({ showGoogleLogin: true });
    }, 5000);
  }
  componentDidMount() {
    const isMobileApp =
      this.props.location.state &&
      this.props.location.state.from &&
      this.props.location.state.from.pathname.toLowerCase().indexOf("/apps/") >
        -1;
    const isCommon = window.location.href.toLowerCase().indexOf("/ipro") < 0;
    this.setState({
      isMobileApp,
      isCommon,
    });

    getDashboardMenusApi().then((res) => {
      const items = res.items;
      const footer = items && items.find((x) => x.Name == "FooterMenus");
      const aboutUs = items && items.find((x) => x.Name == "AboutUs");
      const howItWorks = items && items.find((x) => x.Name == "HowItWorksMenu");
      const resumeAnalyser =
        items && items.find((x) => x.Name == "ResumeAnalyser");
      const mobileApps = items && items.find((x) => x.Name == "MobileAppsMenu");
      const benefits = items && items.find((x) => x.Name == "BenefitsMenu");
      const articles = items && items.find((x) => x.Name == "ArticlesMenu");
      const prodooVideo =
        items && items.find((x) => x.Name == "ProdooVideoMenu");
      const searcherPhillip =
        items && items.find((x) => x.Name == "SearcherPhillipMenu");
      const contactUs = items && items.find((x) => x.Name == "ContactUsMenu");
      this.setState({
        footerMenu: footer,
        aboutUsMenu: aboutUs,
        howItWorksMenu: howItWorks,
        resumeAnalyzerMenu: resumeAnalyser,
        mobileAppsMenu: mobileApps,
        benefitsMenu: benefits,
        articlesMenu: articles,
        prodooVideoMenu: prodooVideo,
        searcherPhillipMenu: searcherPhillip,
        contactUsMenu: contactUs,
      });
    });
    // getFooterMenuDetailApi(162)
    //   .then((res) => {})
    //   .catch((err) => console.log("Err ", err));
    var loggedUser = this.props.user;
    const query = new URLSearchParams(this.props.location.search);
    const password = query.get("password");
    const email = query.get("email");
    const uId = query.get("uId");
    const niid = query.get("networkInvitationId");
    const status1 = query.get("status");
    const unsubNotificationToken = query.get("unsubNotificationToken");
    const showSignup = query.get("showRegistrationForm");
    if (showSignup) {
      this.setState({
        showSignup: true,
      });
    }
    const loginModel = {
      email,
      password,
    };

    if (niid != null && status1 != null) {
      if (loggedUser.UserId && loggedUser.UserId == uId) {
        NetworkInvitationActionApi(niid, status1)
          .then((response) => {
            if (response.success) {
              const info = {
                message: response.message,
                status: "success",
              };
              this.props.notificationAction(info);
            } else {
              const info = {
                message: response.message,
                status: "error",
              };
              this.props.notificationAction(info);
            }
          })
          .catch((err) => {
            const info = {
              message: "Error occured while accepting invitation",
              status: "error",
            };
            this.props.notificationAction(info);
          });
      } else {
        const info = {
          message:
            "Please login to the Account which have recieved the invitation",
          status: "error",
        };
        this.props.notificationAction(info);
      }
    }
    if (email != null && password != null) {
      this.props.loginAuth(loginModel).then((response) => {
        this.props.history.push(privateRoutes.settings.changePasswordPath);
      });
    }
    if (
      ((this.props.history.location.state || {}).from || {}).pathname ===
      "/settings/e"
    ) {
      if (!this.props.isAuthenticated) {
        const info = {
          message: "Please login first to edit settings",
          status: "error",
        };
        this.props.notificationAction(info);
      }
    }
    if (unsubNotificationToken != null) {
      this.unsubNotifications(unsubNotificationToken);
    }
    if (
      this.props.location.pathname.indexOf(
        publicRoutes.emailverification.path
      ) >= 0
    ) {
      const { token } = this.props.match.params;
      const user = JSON.parse(localStorage.getItem("User"));
      user
        ? logoutApi().then((response) => {
            if (response.success) {
              StorageService.clearAll();
              this.props.logoutAction();
              this.props.history.push(publicRoutes.default.path);
              this.emailConfirmation(token);
            }
          })
        : this.emailConfirmation(token);
    }
    if (
      this.props.location.pathname.indexOf(
        publicRoutes.emailverification.path
      ) >= 0
    ) {
      const { token } = this.props.match.params;
      const user = JSON.parse(localStorage.getItem("User"));
      user
        ? logoutApi().then((response) => {
            if (response.success) {
              this.props.logoutAction();
              StorageService.clearAll();
              window.location.href = RESET_LANDINGPAGE_URL;
              this.emailConfirmation(token); //not sure why its here? it will never call
            }
          })
        : this.emailConfirmation(token);
    }
    if (
      this.props.location.pathname.indexOf(
        publicRoutes.emailverification.path
      ) >= 0
    ) {
      const { token } = this.props.match.params;
      const user = JSON.parse(localStorage.getItem("USER_INFO"));
      user
        ? logoutApi().then((response) => {
            if (response.success) {
              StorageService.clearAll();
              this.props.logoutAction();
              window.location.href = RESET_LANDINGPAGE_URL;
              this.emailConfirmation(token);
            }
          })
        : this.emailConfirmation(token);
    }
    if (
      this.props.location.pathname.indexOf(publicRoutes.redirectedfrom.path) >=
      0
    ) {
      const redirectedFrom = this.props.location.search.split("=")[1];
      const loginModel = {
        email: redirectedFrom + "dsa5d54sa6d21xc",
        password: "21s242sdassd54" + redirectedFrom + "a34s6dasa22sd",
        token: redirectedFrom,
      };
      if (redirectedFrom) {
        this.props.loginAuth(loginModel).then((response) => {
          this.props.history.push(privateRoutes.dashboard.path);
        });
      } else {
        const info = {
          message:
            "There was not an error while redirecting Please try to login manually",
          status: "error",
        };
        this.props.notificationAction(info);
      }
    }
  }
  unsubNotifications = (unsubNotificationToken) => {
    UserNotificationsUpdate(unsubNotificationToken).then((response) => {
      if (response.success) {
        this.props.history.push(privateRoutes.settings.emailnotification);
      }
    });
  };
  emailConfirmation = (token) => {
    const { labels } = this.props;
    setTimeout(() => {
      emailTokenConfirmationApi(token)
        .then((response) => {
          if (response.success) {
            const info = {
              message:
                labels.EMAIL_VERIFICATION_ACCOUNT_SUCCESS ||
                "Your account has been verified, Thank you.",
              status: "success",
            };
            this.props.notificationAction(info);
          } else {
            const info = {
              message:
                response.message || labels.EMAIL_VERIFICATION_ACCONT_FAIL,
              status: "error",
            };
            this.props.notificationAction(info);
          }
          this.props.history.push(publicRoutes.login.path);
        })
        .catch((err) => {
          const info = {
            message: labels.EMAIL_VERIFICATION_ACCONT_FAIL,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    }, 1500);
  };

  componentWillMount() {
    if (
      this.props.location.pathname.indexOf(publicRoutes.redirectedLogin.path) >=
      0
    ) {
      this.redirectLoginAuthCHeck();
    } else {
      this.checkUser();
    }
  }
  redirectLoginAuthCHeck = () => {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("token");
    this.props.redirectLoginAuth(token).then((response) => {
      this.props.history.push(privateRoutes.dashboard.path);
    });
  };

  checkUser = () => {
    const { history } = this.props;
    const user = StorageService.getUser();
    if (user) {
      history.push(privateRoutes.dashboard.path);
    } else {
      if (
        history.location.state?.from?.pathname
          ?.toLowerCase()
          ?.includes("/apps/")
      ) {
        this.setState({ isLoading: false });
        const statePathName = history.location.state.from.pathname.toLowerCase();
        let appType = "";
        switch (true) {
          case statePathName.includes("snapshot"):
            appType = "snapshot";
            break;
          case statePathName.includes("tictell"):
            appType = "tictell";
            break;
          case statePathName.includes("searcher-philip"):
            appType = "searcherPhilip";
            break;
            case statePathName.includes("ipro-philip"):
              appType = "iproPhilip";
              break;
          case statePathName.includes("sense"):
            appType = "sense";
            break;
          case statePathName.includes("headsup"):
            appType = "headsup";
            break;
          case statePathName.includes("embark"):
            appType = "embark";
            break;
          case statePathName.includes("assort"):
            appType = "assort";
            break;
          case statePathName.includes("next-step"):
            appType = "nextStep";
            break;
          default:
            // handle default case
        }
        
        StorageService.saveAppType(appType);
        history.push("/app-login");
      } else {
        StorageService.clearAll();
        window.location.href = RESET_LANDINGPAGE_URL;
      }
    }
  };

  //Showing and Hiding Signup and Login
  handleLogin = () => {
    this.setState({
      showLogin: true,
      showSignup: false,
      showForgotPassword: false,
      showTokenResend: false,
    });
  };
  handleSignup = () => {
    this.setState({
      showSignup: true,
      showLogin: false,
      showForgotPassword: false,
      showTokenResend: false,
    });
  };
  handleForgotPassword = () => {
    this.setState({
      showSignup: false,
      showLogin: false,
      showForgotPassword: true,
      showTokenResend: false,
    });
  };

  handleTokenResend = () => {
    this.setState({
      showSignup: false,
      showLogin: false,
      showForgotPassword: false,
      showTokenResend: true,
    });
  };
  handleModalClose = () => {
    this.setState({
      showLogin: false,
      showSignup: false,
      showForgotPassword: false,
      showTokenResend: false,
    });
  };

  showResendPopUp = (message) => {
    this.setState({ dialogMessage: message });
  };

  handleResendClick = () => {
    this.setState({
      showLogin: false,
      showSignup: false,
      showForgotPassword: false,
      showTokenResend: true,
      dialogMessage: "",
    });
  };
  handleEmailConfirmationOkClick = () => {
    this.setState({
      showLogin: false,
      showSignup: false,
      showForgotPassword: false,
      showTokenResend: false,
      dialogMessage: "",
    });
  };
  handleSearchClick = () => {
    const { searchCriteria } = this.props;
    if (searchCriteria && searchCriteria.Profiles.length > 0) {
      this.props.history.push(publicRoutes.visitorsearch.path);
    } else {
      const info = {
        message: this.props.labels.VisitorSearchSelectProfile,
        status: "info",
      };
      this.props.notificationAction(info);
    }
  };
  handleScrollerChange = (name) => {
    this.setState({
      activePage: { [name]: true },
    });
  };
  toggleSignupForm = () => {
    this.setState({
      showSignup: true,
    });
  };
  handleGoogleClose = () => {
    this.setState({
      showGoogleLogin: false,
    });
  };
  //Login related
  setFacebookClientId = () => {
    let facebookClientId = "256608669239786"; //old key "359778238088735";
    if (window.location.hostname.indexOf("dev.prodoo") > -1) {
      facebookClientId = "256608669239786";
    } else if (window.location.hostname.indexOf("stage.prodoo") > -1) {
      facebookClientId = "703294370335312";
    } else if (window.location.hostname.indexOf("prodoo.com") > -1) {
      facebookClientId = "558501255070600";
    }
    this.facebookClientId = facebookClientId;
  };
  setLinkedInUrl = () => {
    let linkedInUrl = "http://localhost:3000/linkedin"; //old key "359778238088735";
    if (window.location.hostname.indexOf("dev.prodoo") > -1) {
      this.linkedInUrl = "https://dev.prodoo.com/";
    } else if (window.location.hostname.indexOf("stage.prodoo") > -1) {
      this.linkedInUrl = "https://stage.prodoo.com/";
    } else if (window.location.hostname.indexOf("prodoo.com") > -1) {
      this.linkedInUrl = "https://prodoo.com/";
    }
    this.linkedInUrl = linkedInUrl;
  };
  handleFacebookLoginSuccess = (response) => {
    const { id, name, email, first_name, last_name } = response.profile;
    const user = {
      Name: name,
      FirstName: first_name,
      LastName: last_name,
      ExternalId: id,
      Email: email,
      RegistrationTypeId: 2,
      OAuthProviderId: id,
      IsLoginByMobile: false,
    };
    RegisterThirdPartyApi(user)
      .then((response) => {
        if (response.success) {
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      })
      .catch((error) => console.log("Error in third party api ", error));
  };
  handleFacebookFailure = (err) => {};
  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    }
  };
  handleIgeActionClicks = (domain) => {
    const { email, password } = this.state;
    const {
      notificationAction,
      loginAuth,
      showResendPopUp,
      location,
      history,
    } = this.props;
    if (domain == "Dismiss") {
      this.setState({
        igeDialogMessage: "",
        IgeDomains: [],
        isLoading: false,
      });
      return;
    }
    if (domain == "Current") {
      this.setState({
        igeDialogMessage: "",
        IgeDomains: [],
      });
      this.loginToCore(
        loginAuth,
        email,
        password,
        location,
        history,
        showResendPopUp
      );
      return;
    }
    genetrateTokenApi(domain, email).then((res) => {
      if (res.success) {
        var token = res.items;
        if (token) {
          window.location.replace(
            domain +
              "/#" +
              publicRoutes.redirectedfrom.path +
              `?redirectedFrom=${token}`
          );
        }
      }
    });
  };
  handleSubmit = () => {
    const { email, password } = this.state;
    const {
      notificationAction,
      loginAuth,
      showResendPopUp,
      location,
      history,
    } = this.props;
    let isError = false;
    if (!isValidEmail(email)) {
      isError = true;
      this.setState({
        invalidemail: true,
      });
    }
    if (!password) {
      isError = true;
      this.setState({
        invalidpassword: true,
      });
    }
    if (isError) {
      const info = {
        message: this.props.LOGIN_VALIDATION_ERROR_MESSAGE,
        status: "error",
      };
      notificationAction(info);
      return;
    }
    this.setState({
      isLoading: true,
    });
    igeLoginApi({ email, password })
      .then((res) => {
        if (res.success) {
          this.setState({
            IgeDomains: res.items.map((a) => a.IgeDomain),
            igeDialogMessage:
              "This user also exists with same credentials in different domains click on a domain where you want to login",
          });
          return;
        } else {
          this.loginToCore(
            loginAuth,
            email,
            password,
            location,
            history,
            showResendPopUp
          );
        }
      })
      .catch((err) => console.log("Err", err));
    return;
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (!value || (name === "email" && !isValidEmail(value))) {
      this.setState({
        [`invalid${name}`]: true,
        [name]: value,
      });
      return;
    } else {
      this.setState({
        [`invalid${name}`]: false,
        [name]: value,
      });
    }
  };
  handleFacebookClick = () => {
    this.setState({ isFacebookConnected: !this.state.isFacebookConnected });
  };
  loginToCore(loginAuth, email, password, location, history, showResendPopUp) {
    const loginModel = {
      email,
      password,
    };
    loginAuth(loginModel).then((response) => {
      if (response.success) {
        const { state } = location;
        if (state && state.from && state.from.pathname) {
          history.push(state.from.pathname);
          return;
        }
        history.push(privateRoutes.dashboard.path);
      } else {
        if (response.items && response.items.IsEmailAvailable) {
          this.showResendPopUp(response.message);
        } else {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      }
      this.setState({
        isLoading: false,
      });
    });
  }

  handleSuccess = (data) => {
    this.setState({
      code: data.code,
      errorMessage: "",
    });
  };

  handleFailure = (error) => {
    this.setState({
      code: "",
      errorMessage: error.errorMessage,
    });
  };

  setEmail = (email) => {
    this.setState({
      email,
    });
  };
  //End
  render() {
    const { isLoading } = this.state;
    return (
      <PageWrapper className="lead-page">
        {isLoading ? (
          <div id="root">
            <div id="preLoader" class="preLoader">
              <div>
                <img
                  class="loaderImage"
                  src="../../assets/images/logo1.png"
                  alt="logo"
                />
              </div>
              <div>
                <div id="loader-wrapper">
                  <div id="loader" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <MobileLogin />
        )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel: { labels },
  userInfo: { user, isAuthenticated },
  landing: { searchCriteria },
}) => {
  return {
    labels,
    user,
    isAuthenticated,
    searchCriteria,
  };
};

export default connect(
  mapStateToProps,
  {
    notificationAction,
    logoutAction,
    loginAuth,
    redirectLoginAuth,
  }
)(withRouter(LeadPage));
