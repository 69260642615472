import React, { Component } from "react";
import { connect } from "react-redux";
import Opportunity from "./common/Opportunity";
import { notificationAction } from "../../../actions";

class OpportunityDeclined extends Component {
  render() {
    const {
      userId,
      labels,
      IsSearcher,
      notificationAction,
      location,
      isHelpActive
    } = this.props;
    const {
      Invitation_Empty,
      iproOpportunityDeclinedSearchField,
      TooltipIproOpportunityNewListCollapse,
      HlpTooltipIproOpportunityNewListCollapse,
      ViewTitleInvitationList,
      iproOpportunityNewDetailsHeading,
      ToolTipInvitationExpandDetail,
      HlpTooltipIproOpportunityNewDetailExpand,
      ToolTipInvitationCollaspeDetail,
      HlpTooltipIproOpportunityNewDetailCollapse,
      ViewTitleInvitationDetail,
      iproOpportunityNewCompanyDetailHeading,
      ToolTipCompanyExpandDetail,
      HlpTooltipIproOpportunityNewUserDetailExpand,
      ToolTipCompanyCollaspeDetail,
      HlpTooltipIproOpportunityNewUserDetailCollapse,
      ViewTitleCompanyDetail,
      ToolTipInvitationExpandList,
      HlpTooltipIproOpportunityNewListExpand,
      iProOpportunityNewDetailLblHeading,
      iProOpportunityNewDetailLblLocation,
      iProOpportunityNewDetailLblStartDate,
      iProOpportunityNewDetailLblDuration,
      iProOpportunityNewDetailLblCompany,
      iProOpportunityNewDetailLblDescription,
      iProOpportunityNewDetailLblSearchParameterHeading,
      iProOpportunityNewDetailLblProfile,
      iProOpportunityNewDetailLblSkills,
      iProOpportunityNewDetailLblKeyword,
      iProOpportunityNewDetailLblIndustry,
      iProOpportunityNewDetailLblCertification,
      iProOpportunityNewDetailLblCountry,
      iProOpportunityNewDetailLblInterest,
      iProOpportunityNewDetailBtnAccept,
      iProOpportunityNewDetailBtnDecline,
      iProOpportunityDeclinedDetailBtnRestore,
      iProOpportunityNewDetailLblLanguage,
      iProOpportunityNewDetailLblTitle,
      IproOpportunityNewCompanyAddressLabel,
      IproOpportunityNewCompanyIndustryLabel,
      IproOpportunityNewCompanyCountryLabel,
      IproOpportunityNewCompanyPhoneLabel,
      IproOpportunityNewCompanyWebUrlLabel,
      IproOpportunityNewCompanyVatLabel,
      IproOpportunityDeclinedRestoreYourInvitationLbl,
      iproInvitationSuccessfullyDeleted,
      IproOpportunityDeclinedRestoreMessage
    } = labels;
    return (
      <Opportunity
        url={`?type=2`}
        isAccepted={true}
        isDeclinedView
        opportunityType="DeclineRequests"
        IsSearcher={false}
        isHelpActive={isHelpActive}
        notificationAction={notificationAction}
        // list section system label
        Lbltitle={iProOpportunityNewDetailLblTitle}
        locationsLabel={iProOpportunityNewDetailLblLocation}
        startDateLabel={iProOpportunityNewDetailLblStartDate}
        durationLabel={iProOpportunityNewDetailLblDuration}
        companyLabel={iProOpportunityNewDetailLblCompany}
        descriptionLabel={iProOpportunityNewDetailLblDescription}
        countryLabel={iProOpportunityNewDetailLblCountry}
        SearchParameterHeading={
          iProOpportunityNewDetailLblSearchParameterHeading
        }
        LblProfile={iProOpportunityNewDetailLblProfile}
        LblSkills={iProOpportunityNewDetailLblSkills}
        LblKeyword={iProOpportunityNewDetailLblKeyword}
        LblIndustry={iProOpportunityNewDetailLblIndustry}
        LblCertification={iProOpportunityNewDetailLblCertification}
        LblCountry={iProOpportunityNewDetailLblCountry}
        LblLanguage={iProOpportunityNewDetailLblLanguage}
        LblInterest={iProOpportunityNewDetailLblInterest}
        LblRestore={IproOpportunityDeclinedRestoreYourInvitationLbl}
        BtnAccept={iProOpportunityNewDetailBtnAccept}
        BtnDecline={iProOpportunityNewDetailBtnDecline}
        BtnRestore={iProOpportunityDeclinedDetailBtnRestore}
        companyAddressLabel={IproOpportunityNewCompanyAddressLabel}
        companyIndustryLabel={IproOpportunityNewCompanyIndustryLabel}
        companyCountryLabel={IproOpportunityNewCompanyCountryLabel}
        companyPhoneLabel={IproOpportunityNewCompanyPhoneLabel}
        companyWebUrlLabel={IproOpportunityNewCompanyWebUrlLabel}
        companyVatLabel={IproOpportunityNewCompanyVatLabel}
        searchInputPlaceholder={iproOpportunityDeclinedSearchField}
        toolTipExpandList={ToolTipInvitationExpandList}
        helpTooltipExpandList={HlpTooltipIproOpportunityNewListExpand}
        toolTipCollapseList={TooltipIproOpportunityNewListCollapse}
        helpTooltipCollapseList={HlpTooltipIproOpportunityNewListCollapse}
        listColumnText={ViewTitleInvitationList}
        // detail section system label
        detailHeadingText={iproOpportunityNewDetailsHeading}
        toolTipExpandDetail={ToolTipInvitationExpandDetail}
        helpTooltipExpandDetail={HlpTooltipIproOpportunityNewDetailExpand}
        toolTipCollapseDetail={ToolTipInvitationCollaspeDetail}
        helpTooltipCollapseDetail={HlpTooltipIproOpportunityNewDetailCollapse}
        detailsColumnText={ViewTitleInvitationDetail}
        // selected user detail and company section system label
        userDetailHeadingText={iproOpportunityNewCompanyDetailHeading}
        toolTipExpandUserDetail={ToolTipCompanyExpandDetail}
        helpTooltipExpandUserDetail={
          HlpTooltipIproOpportunityNewUserDetailExpand
        }
        toolTipCollapseUserDetail={ToolTipCompanyCollaspeDetail}
        helpTooltipCollapseUserDetail={
          HlpTooltipIproOpportunityNewUserDetailCollapse
        }
        userDetailsColumnText={ViewTitleCompanyDetail}
        emptyOpportunityInfo={Invitation_Empty}
        listCollapsedTestId={"listCollapsedTestId1"}
        listCollapsedHelpTestId={"listCollapsedTestId2"}
        listExpandTestId={"listCollapsedTestId3"}
        listExpandHelpTestId={"listCollapsedTestId4"}
        detailCollapsedTestId={"listCollapsedTestId5"}
        detailCollapsedHelpTestId={"listCollapsedTestId6"}
        detailExpandTestId={"listCollapsedTestId7"}
        detailExpandHelpTestId={"listCollapsedTestId8"}
        userDetailCollapsedTestId={"listCollapsedTestId9"}
        userDetailCollapsedHelpTestId={"listCollapsedTestId10"}
        userDetailExpandTestId={"listCollapsedTestId11"}
        userDetailExpandHelpTestId={"listCollapsedTestId12"}
        searchInputTestId="opportunity-declined-search-input"
        iproOpportunityListItem="iproOpportunityListItem-declined"
        iproInvitationSuccessfullyDeleted={iproInvitationSuccessfullyDeleted}
        locationProp={location}
      />
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { UserId, IsFreelancer } = user;
  UserId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    userId: UserId,
    IsSearcher: !IsFreelancer,
    IsFreelancer,
    isHelpActive
  };
};

const actions = { notificationAction };
export default connect(
  mapStateToProps,
  actions
)(OpportunityDeclined);
