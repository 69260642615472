import React, { Component } from "react";
import LoactionPanel from "./Location";
import LanguagePanel from "./Language";
import "./grid.scss";
import SuggestedRole from "./SuggestedRole";
import Role from "./Role";
import Skill from "./Skill";
import Certification from "./Certification";
import Keyword from "./Keyword";
import Education from "./Education";
import Industry from "./Industry";
import ProfessionalExperience from "./ProfessionalExperience";
import OtherAcheivement from "./OtherAcheivement";
const LocationLanguage = () => (
  <Grid>
    <Grid.Row>
      <Grid.Column columns={2}>
        <LoactionPanel />
      </Grid.Column>
      <Grid.Column>
        <LanguagePanel />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
const RolesSkills = ({ children }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <Role />
      </Grid.Column>
      <Grid.Column>
        <Skill />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
// const Skills = ({ children }) => (
//   <Grid>
//     <Grid.Row>
//       {/* <Grid.Column columns={2}>
//         <SuggestedSkill />
//       </Grid.Column> */}
//       {/* <Grid.Column>
//         <Skill />
//       </Grid.Column> */}
//     </Grid.Row>
//   </Grid>
// );
const CertificationsEducations = ({ children }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <Certification />
      </Grid.Column>
      <Grid.Column>
        <Education />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
const KeywordIndustry = ({ children }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <Keyword />
      </Grid.Column>
      <Grid.Column>
        <Industry />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
const ExperienceAcheivement = ({ children }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>
        <ProfessionalExperience />
      </Grid.Column>
      <Grid.Column columns={2}>
        <OtherAcheivement />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
// const OtherAcheivements = ({ children }) => (
//   <Grid>
//     <Grid.Row>
//       {/* <Grid.Column columns={2}>
//         <OtherAcheivement />
//       </Grid.Column> */}
//     </Grid.Row>
//   </Grid>
// );
const Row = ({ children }) => <div className="grid-row">{children}</div>;
const Column = ({ children }) => <div className="grid-column">{children}</div>;

class Grid extends Component {
  static LocationLanguage = LocationLanguage;
  static RolesSkills = RolesSkills;
  // static Skills = Skills;
  static CertificationsEducations = CertificationsEducations;
  static KeywordIndustry = KeywordIndustry;
  static ExperienceAcheivement = ExperienceAcheivement;
  // static OtherAcheivements = OtherAcheivements;
  static Row = Row;
  static Column = Column;
  render() {
    const { children } = this.props;
    return <div className="resume-grid">{children}</div>;
  }
}
export default Grid;
