import React from "react";

import { RichTextEditor } from "../../../../../components";
import classes from "../experience.module.scss";
import { Col, Row } from "antd";

const ExperienceDetail = ({ detail }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <div className={classes.resumeDetail}>
          <input
            type="text"
            placeholder="Type your working place where you work"
            value={detail?.item?.CompanyWorked}
          />
        </div>
      </Col>
      <Col xs={24} lg={12} md={12}>
        <div className={classes.resumeDetail}>
          <input
            type="text"
            placeholder=""
            value={detail?.item?.IndustryValue}
          />
        </div>
      </Col>
      <Col xs={24} lg={12} md={12}>
        <div className={classes.resumeDetail}>
          <input
            type="text"
            placeholder=""
            value={detail?.item?.ProfileValue}
          />
        </div>
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 6 }} md={6} className={classes.dates}>
        {/* <div className={classes.fromDate}> */}
        <Row justify="space-between" className="align-items-center">
          <Col xs={4}>
            <p>From</p>
          </Col>
          <Col xs={19} className="pl-2">
            <input
              className={classes.dateInput}
              value={detail?.item?.StartDate?.toString()?.split("T")[0]}
              readOnly
            />
          </Col>
        </Row>
        {/* </div> */}
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 6 }} md={6} className={classes.dates}>
        <Row justify="space-between" className="align-items-center">
          <Col xs={4}>
            <p>To</p>
          </Col>
          <Col xs={19} className="pl-2">
            <input
              className={classes.dateInput}
              value={detail?.item?.EndDate?.toString()?.split("T")[0]}
              readOnly
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} lg={8} md={8} className={classes.dates}>
        <div className={classes.Working}>
          <p className={classes.dateLabel}>Ongoing</p>
          <input
            className={classes.workingCheck}
            value={detail?.item?.EndDate}
            checked={!detail?.item?.EndDate}
            readOnly
            type="checkbox"
          />
        </div>
      </Col>
      <Col xs={24}>
        <div className="experience-edit-rich-text">
          <RichTextEditor
            id="resume-edit-experience-description"
            name="Description"
            value={detail?.item?.Description}
            readOnly
            // placeholder={labels.RESUME_EDIT_EXPERIENCE_DESCRIPTION_LABEL}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ExperienceDetail;
