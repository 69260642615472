import React, { Component } from "react";
import { Panel } from "../../../../common";
import ReactHtmlParser from "react-html-parser";
import { ApiUrl } from "../../../../api";
import { Link } from "react-router-dom";

class SuggestedRole extends Component {
  render() {
    const {
      suggestedRolesList,
      onSuggesstedRoleAdd,
      suggestObject,
      labels
    } = this.props;
    let { RESUME_EDIT_SUGGESTED_ROLE_MESSAGE } = this.props.labels;
    return (
      <>
        {suggestedRolesList.length > 0 && (
          <div
            test-data-id="resume-edit-role-suggestion-availible-message"
            className="sub-heading"
          >
            {`Suggested Role for ${suggestObject.Value}`}{" "}
          </div>
        )}
        {suggestedRolesList.length === 0 &&
          Object.keys(suggestObject).length !== 0 &&
          suggestObject.Type === "Profile" && (
            <div
              test-data-id="resume-edit-role-suggestion-not-availible-message"
              className="add-correletion-text"
            >
              {/* <div className="sub-heading">
                {`Suggested Role for ${suggestObject.Value}`}
              </div> */}
              {
                <p>
                  {ReactHtmlParser(
                    labels.RESUME_EDIT_SUGGESTED_ROLE_MESSAGE.replace(
                      /ddROLENAME/g,
                      suggestObject.Value
                    )
                  )}
                  <a
                    href={`${window.location.origin}/BI/#profile/${
                      suggestObject.Id
                    }`}
                    target="_blank"
                  >
                    {ReactHtmlParser(
                      labels.RESUME_EDIT_SUGGESTED_ROLE_URL_MESSAGE
                    )}
                  </a>
                </p>
              }
            </div>
          )}
        {suggestedRolesList.map(item => (
          <label
            test-data-id="resume-edit-suggested-role-item"
            className="draggable"
            onClick={() =>
              onSuggesstedRoleAdd(item.ProfileId, item.ProfileValue)
            }
            key={item.ProfileId}
          >
            {item.ProfileValue}
          </label>
        ))}
      </>
    );
  }
}

export default SuggestedRole;
