import React, { Component } from "react";
import { EmptyInfo, Button } from "../../../../common";
import moment from "moment";
import "./collaborationDetail.scss";

class CollaborationDetail extends Component {
  renderDate = (date) => {
    return moment(date).format("MM/DD/YY");
  };

  render() {
    const {
      selectedCollaboration,
      emptyCollaorationInfo,
      startDateLabel,
      durationLabel,
      compensationLabel,
      companyLabel,
      descriptionLabel,
      isIproActiveCollaboration,
      selectInterest,
      onCollaborationAccept,
      onCollaborationDecline,
      onCollaborationDelete,
      onCollaborationClose,
      IsSearcher,
      isInActiveView,
      isIproNewCollaboration,
      isSentView,
      isIproInActiveCollaboration,
      isAcceptedView,
      iProOpportunityNewDetailBtnAccept,
      iProOpportunityNewDetailBtnDecline,
    } = this.props;
    return (
      <div className="collaboration-detail-component">
        {!selectedCollaboration.CollaborationId ? (
          <EmptyInfo>{emptyCollaorationInfo}</EmptyInfo>
        ) : (
          <div className="MsgWrapper" test-data-id="collaboation-detail">
            <div className="titleLabel">{selectedCollaboration.Title}</div>
            <div className="feedbackWrap">
              <div className="msgItemDiv">
                {selectedCollaboration.StartDate && (
                  <div className="msgItem">
                    <label className="labelName">{startDateLabel}:</label>
                    <label className="answerLabel">
                      {this.renderDate(selectedCollaboration.StartDate)}
                    </label>
                  </div>
                )}

                {selectedCollaboration.Duration && (
                  <div className="msgItem">
                    <label className="labelName">{durationLabel}:</label>
                    <label className="answerLabel">
                      {selectedCollaboration.Duration}
                    </label>
                  </div>
                )}

                {selectedCollaboration.HourlyRate && (
                  <div className="msgItem">
                    <label className="labelName">{compensationLabel}:</label>
                    <label className="answerLabel">
                      {selectedCollaboration.HourlyRate} &nbsp;
                      {selectedCollaboration.HourlyRateType}
                    </label>
                  </div>
                )}

                {selectedCollaboration.company.CompanyName && (
                  <div className="msgItem">
                    <label className="labelName">{companyLabel}:</label>
                    <label className="answerLabel invCompValue">
                      {selectedCollaboration.company.CompanyName}
                    </label>
                  </div>
                )}

                {selectedCollaboration.Decription && (
                  <div className="msgItem">
                    <label className="labelName">{descriptionLabel}:</label>
                    <label className="answerLabel">
                      {selectedCollaboration.Decription}
                    </label>
                  </div>
                )}

                <div className="MsgDetailWrap">
                  {((IsSearcher && isAcceptedView) ||
                    (!IsSearcher && isIproActiveCollaboration)) && (
                    <div className="closeBtnSection">
                      <Button
                        className="SendButton"
                        text={"Close"}
                        onClick={onCollaborationClose}
                        tooltipButton={"Close"}
                        tooltipHelp={"Close"}
                        tooltipPlace="left"
                      />
                      {/* <button
                        className="invDecline SendButton !isDeclinedView"
                        className="invDecline SendButton searcherClose"
                        // onClick={onCollaborationDelete}
                        onClick={onCollaborationClose}
                      >
                        Close
                      </button> */}
                    </div>
                  )}
                  {IsSearcher && isSentView && (
                    <div className="closeBtnSection">
                      <Button
                        className="SendButton"
                        text={"Delete"}
                        onClick={onCollaborationDelete}
                        tooltipButton={"Delete"}
                        tooltipHelp={"Delete"}
                        tooltipPlace="left"
                      />
                    </div>
                  )}
                 
                  {!IsSearcher && isIproNewCollaboration && (
                    <div>
                      {selectInterest}
                      <div className="invRequest">
                        <Button
                          className="invAccept SendButton"
                          text={iProOpportunityNewDetailBtnAccept}
                          onClick={onCollaborationAccept}
                          tooltipButton={iProOpportunityNewDetailBtnAccept}
                          tooltipHelp={iProOpportunityNewDetailBtnAccept}
                          tooltipPlace="left"
                          testId="collaboation-detail-accept"
                        />
                        <Button
                          className="invDecline SendButton"
                          text={iProOpportunityNewDetailBtnDecline}
                          onClick={onCollaborationDecline}
                          tooltipButton={iProOpportunityNewDetailBtnDecline}
                          tooltipHelp={iProOpportunityNewDetailBtnDecline}
                          tooltipPlace="left"
                          testId="collaboation-detail-decline"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CollaborationDetail;
