import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import CButton from "../../components/CButton/CButton";
import { Modal, Button } from "antd";
import { Resume } from "./Resume";
import './ResumeDownload.scss'
import classes from "./index.module.scss";
import closeIcon from '../../../../assets/images/Icons/close-new.png'
 
function ResumePopup({ resumes, labels, userDetails }) {
  const componentRef = useRef();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { Profiles, Skills, Educations } = resumes;

  return (
    <>
      {Profiles.length > 0 || Skills.length > 0 || Educations.length > 0 ? (
        <button className="read-more"
          onClick={() => setIsModalVisible(true)}
        >{labels.DASHBOARD_READ_MORE_LABEL}</button>
      ) : (
        labels.COMPLETE_RESUME_LABEL
      )}

      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        style={{ top: 0 }}
        width="800px"
        closable={false}
        className={`new-design-modal resumePopUp`}
      >
        <div className="text-center header-modal">
          <h5>Your Resume</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
       
        <Resume
          labels={labels}
          resumes={resumes}
          userDetails={userDetails}
          ref={componentRef}
        />
      </Modal>
    </>
  );
}

export default ResumePopup;
