// todo: added some generic messages, will update according to user stories
export default {
  feedback: {
    labels: {
      detail: "Detail:"
    },
    validation: {
      emptyForm: "Please add title and content to proceed."
    },
    sendSuccess: "Feedback successfully sent.",
    deletedSuccess: "Feedback successfully deleted.",
    confirm: {
      Yes: "Yes",
      No: "No"
    }
  },
  about: {
    aboutProdoo: "About PRODOO",
    faqs: "FAQS",
    collapse: {
      about: "About",
      detail: "Details"
    }
  },
  present: {
    placeholder: {
      facebook: "facebook link",
      twitter: "twitter link",
      googleplus: "googleplus link",
      linkedin: "linkedin link",
      instagram: "instagram link",
      pinterest: "pinterest link",
      behance: "behance link",
      driblle: "driblle link"
    }
  },
  message: {
    placeholder: {
      title: "Message subject",
      content: "Type message here",
      search: "People you know",
      inboxSearch: "Search your inbox",
      sentSearch: "Search for your sent message",
      inboxSearch: "Search for your inbox message"
    },
    button: {
      send: "Send Message"
    },
    heading: {
      newMessage: "New Message",
      detail: "Message detail",
      reply: "Reply"
    },
    labels: {
      date: "Date",
      from: "From",
      time: "Time"
    }
  },
  collaboration: {
    ipro: {
      labels: {
        company: "Company",
        compensation: "Compensation"
      },
      acceptedCollaboration: "Collaboration already accepted",
      selectInterest: "Interested Or Not"
    }
  },
  company: {
    labels: {
      address: "Address",
      industry: "Industry",
      country: "Country",
      phone: "Phone",
      weburl: "Web URL",
      vat: "VAT"
    }
  }
};
