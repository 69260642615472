import { ActionTypes } from "../../../actions";
window.actionName = "iproNew";
export const onStateChangeAction = ({
  selectedCollaboration,
  collaborationList,
  filteredCollaborations,
  selectedUser,
  selectedCompany,
  fetchingCollaborations,
  isLoading,
  isHelpActive,
  systemLabels,
  dialogMessage,
  listCollapsed,
  detailCollapsed,
  userCompanyDetailCollapsed,
  searchKey,
  actionName = window.actionName,
  yesClickCount,
  reviewQuestions,
  currencies,
  isAlreadyRated,
}) => dispatch => {
  selectedCollaboration !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.SELECTED_COLLABORATION,
      payload: selectedCollaboration,
      actionName
    });
  collaborationList !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.COLLABORATION_LIST,
      payload: collaborationList,
      actionName
    });
  filteredCollaborations !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.FILTERED_COLLABORATIONS,
      payload: filteredCollaborations,
      actionName
    });
  selectedUser !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.SELECTED_USER,
      payload: selectedUser,
      actionName
    });
  selectedCompany !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.SELECTED_COMPANY,
      payload: selectedCompany,
      actionName
    });
  fetchingCollaborations !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.FETCHING_COLLABORATIONS,
      payload: fetchingCollaborations,
      actionName
    });
  isLoading !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.IS_LOADING,
      payload: isLoading,
      actionName
    });
  isHelpActive !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.IS_HELP_ACTIVE,
      payload: isHelpActive,
      actionName
    });
  systemLabels !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.SYSTEM_LABELS,
      payload: systemLabels,
      actionName
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.DIALOG_MESSAGE,
      payload: dialogMessage,
      actionName
    });
  listCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.LIST_COLLAPSED,
      payload: listCollapsed,
      actionName
    });
  detailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.DETAIL_COLLAPSED,
      payload: detailCollapsed,
      actionName
    });
  userCompanyDetailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.USER_COMPANY_DETAIL_COLLAPSED,
      payload: userCompanyDetailCollapsed,
      actionName
    });
  searchKey !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.SEARCH_KEY,
      payload: searchKey,
      actionName
    });
  yesClickCount !== undefined &&
    dispatch({
      type: ActionTypes.COLLABORATION.YES_CLICK_COUNT,
      payload: yesClickCount,
      actionName
    });
  currencies !== undefined &&
  dispatch({
    type: ActionTypes.COLLABORATION.CURRENCIES,
    payload: currencies,
    actionName
  });
  reviewQuestions !== undefined &&
  dispatch({
    type: ActionTypes.COLLABORATION.REVIEW_QUESTIONS,
    payload: reviewQuestions,
    actionName
  });
  isAlreadyRated !== undefined &&
  dispatch({
    type: ActionTypes.COLLABORATION.IS_ALREADY_RATED,
    payload: isAlreadyRated,
    actionName
  });
};
