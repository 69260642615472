import React, { Component } from "react";
import "./newUserDetail.scss";
import ImgSrc from "../../../../assets/images/company.svg";
import { Avatar, Col, Row, Typography } from "antd";

const { Title, Text } = Typography

function getBtnClass(url) {
  if (url.indexOf("facebook") > 0) return "facebook";
  else if (url.indexOf("twitter") > 0) return "twitter";
  else if (url.indexOf("google") > 0) return "googleplus";
  else if (url.indexOf("instagram") > 0) return "instagram";
  else if (url.indexOf("linkedin") > 0) return "linkedin";
  else if (url.indexOf("pinterest") > 0) return "pinterest";
  else if (url.indexOf("driblle") > 0) return "driblle";
  else if (url.indexOf("behance") > 0) return "behance";
  else return "";
}
class NewUserDetail extends Component {
  render() {
    const { selectedUser, labels } = this.props;
    return (
      <div className="new-user-detail-component">
        <div
          className="d-flex justify-content-between gap-8 align-items-center"
          style={{ padding: "12px", borderBottom: "1px solid #f4f2fe" }}
        >
          <Title level={5} className="m-0 text-clamp flex-1">
            {labels?.ipro_details}
          </Title>

        </div>
        <div className="inner-user">
          <div className=" d-flex align-items-center gap-16">
            <div>
              <Avatar
                size={86}
                src={
                  selectedUser?.Logo ||
                  labels.NOT_AVAILABLE_LABEL
                }
              />
            </div>
            <p className="user-title">{selectedUser?.Title}</p>
          </div>
          <div className="mt-3 details">
            <div className=" flex-1">
              <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                {labels.SearcherSentCollaborationUserNameLabel}:
              </Text>
              <div className="mt-1">
                <Text className="fnt-s-14 ">{selectedUser?.UserName || labels.NOT_AVAILABLE_LABEL}</Text>
              </div>
            </div>
            <div className=" flex-1">
              <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                {labels?.SearcherSentCollaborationPositionLabel}:
              </Text>
              <div className="mt-1">
                <Text className="fnt-s-14 ">{selectedUser?.UserProfile || labels.NOT_AVAILABLE_LABEL}</Text>
              </div>
            </div>
            <div className=" flex-1">
              <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                {labels?.SearcherSentCollaborationEmailLabel}:
              </Text>
              <div className="mt-1">
                <Text className="fnt-s-14 ">{selectedUser?.UserEmail?.UserEmailValue || labels.NOT_AVAILABLE_LABEL}</Text>
              </div>
            </div>
            <div className=" flex-1">
              <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                {labels?.SearcherSentCollaborationWebUrlLabel}:
              </Text>
              <div className="mt-1">
                <a className="fnt-s-14 " target="_blank" href={selectedUser?.WebUrl}>
                  {selectedUser?.WebUrl}
                </a>
              </div>
            </div>
            <div className=" flex-1">
              <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                {labels?.SearcherSentCollaborationDetailLabel}:
              </Text>
              <div className="mt-1">

                <Text className="fnt-s-14 ">{selectedUser?.Detail || labels.NOT_AVAILABLE_LABEL}</Text>
              </div>
            </div>
            {selectedUser.UserDetailSocialLink && (
              <div className="mt-1">
                {selectedUser.UserDetailSocialLink.map((item, index) => (
                  <button
                    key={index}
                    className={`socialBtn ${getBtnClass(item.URL)}Btn ${item.Id ? "activeBtn" : ""
                      }`}
                    onClick={() => {
                      window.open(item.URL, "_blank");
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NewUserDetail;
