import React, { Component } from "react";
import "./search-list.scss";
import {
  ConfirmDialog,
  CapsuleList,
  Button,
  DatePicker,
  CustomSelect,
} from "../../../common";
import Slider from "rc-slider";
import { getCompanyApi } from "../searchApi";
import {
  getRolesApi,
  getSkillsApi,
  getKeywordApi,
  getIndustriesApi,
  getCertificationsApi,
  getAllLanguagesApi,
  getAllLocationsApi,
} from "../../Resume/ResumeEdit/resumeEditApi";
import moment from "moment";
import { EmptySearchList } from "../../../utilities";
import { filter, toLower, isEqual, uniqBy, isEmpty } from "lodash";
import { notificationAction } from "../../../actions";
import { connect } from "react-redux";
import { onStateChangeAction } from "./searchListAction";
import { CloseBtn } from "../../../common/CloseBtn";

const Handle = Slider.Handle;

class SearchList extends Component {
  constructor(props) {
    super(props);
    this.firstSearch = props.isVisitor;
  }

  state = {
    typingTimeout: null,
    languagesList: [],
    // roles: [],
    // searchList_rolesFetching: false,
    // skills: [],
    // searchList_skillsFetching: false,
    // keywords: [],
    // searchList_keywordsFetching: false,
    // industries: [],
    // searchList_industriesFetching: false,
    // certifications: [],
    // searchList_certificationsFetching: false,
    // countries: [],
    // searchList_countriesFetching: false,
    // languages: [],
    // searchList_languagesFetching: false,
    // availibility: {
    //   value: 1,
    //   label: this.props.labels.RESUME_SEARCH_AVAILABILITY_TODAY_LABEL,
    //   date: moment().format("MM/DD/YYYY")
    // },
    // searchList_searchList: EmptySearchList
  };
  AvailibilityTypes = [
    {
      value: 1,
      label: this.props.labels.RESUME_SEARCH_AVAILABILITY_TODAY_LABEL,
      date: moment().format("MM/DD/YYYY"),
    },
    {
      value: 2,
      label: this.props.labels.RESUME_SEARCH_AVAILABILITY_TWO_WEEK_LABEL,
      date: moment()
        .add(2, "weeks")
        .format("MM/DD/YYYY"),
    },
    {
      value: 3,
      label: this.props.labels.RESUME_SEARCH_AVAILABILITY_ONE_MONTH_LABEL,
      date: moment()
        .add(1, "month")
        .format("MM/DD/YYYY"),
    },
    {
      value: 4,
      label: this.props.labels.RESUME_SEARCH_AVAILABILITY_THREE_MONTH_LABEL,
      date: moment()
        .add(3, "months")
        .format("MM/DD/YYYY"),
    },
    {
      value: 5,
      label: this.props.labels.RESUME_SEARCH_AVAILABILITY_CUSTOM_LABEL,
      date: moment().format("MM/DD/YYYY"),
    },
  ];
  handleClearSearch = () => {
    this.props.onStateChangeAction({ searchList_searchList: EmptySearchList });
  };

  handleDeleteResume = (item) => {};

  handleYesClick = () => {};

  handleNoClick = () => {};
  onRoleBlur = () => {};

  getCompany = () => {
    getCompanyApi().then((data) => {
      const { searchList_searchList } = this.props;
      this.props.onStateChangeAction({
        searchList_searchList: {
          ...searchList_searchList,
          countries: [data.items],
        },
      });
    });
  };
  componentDidMount() {
    window.actionName = this.props.isSaveSearch ? "savedSearch" : "search";
    if (!this.props.isVisitor) {
      this.props.searchList_countriesFetching && this.getCompany();
    }
    this.handleRolesLoad();
    this.handleSkillsLoad();
    this.handleKeywordsLoad();
    this.handleIndustriesLoad();
    this.handleCertificationsLoad();
    this.handleCountriesLoad();
    this.handleLanguageDefaultOptionsLoad();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(
        prevProps.searchList_searchList,
        this.props.searchList_searchList
      )
    ) {
      this.props.onSearchListChange(this.props.searchList_searchList);
    }
    if (!isEqual(prevProps.availibility, this.props.availibility)) {
      this.props.onSearchAvailablityChange(this.props.availibility);
    }
    if (
      !isEqual(this.props.savedSearchSettings, prevProps.savedSearchSettings)
    ) {
      this.props.onStateChangeAction({
        searchList_searchList: this.props.savedSearchSettings,
      });
    }
  }

  /////////////ROLES SEARCH CODE START////////////

  handleRolesLoad = (searchKey) => {
    if (isEmpty(searchKey)) {
      this.handleRoleDefaultOptionsLoad("a");
      return;
    }
    this.handleRoleDefaultOptionsLoad(searchKey);
  };

  handleRoleDefaultOptionsLoad = (searchKey) => {
    this.props.onStateChangeAction({ searchList_rolesFetching: true });
    getRolesApi({ searchKey }).then((response) => {
      if (response.success) {
        const roles = response.items.map((item) => ({
          ...item,
          label: item.ProfileValue,
          value: item.ProfileId,
        }));
        this.props.onStateChangeAction({
          searchList_rolesList: roles,
          searchList_rolesFetching: false,
        });
        if (this.props.isVisitor) {
          if (this.props.ProfileId && this.props.ProfileValue) {
            this.handleRoleChange({
              ProfileId: this.props.ProfileId,
              ProfileValue: this.props.ProfileValue,
            });
          } else if (!isEmpty(this.props.searchCriteria)) {
            const { searchCriteria, searchList_searchList } = this.props;
            const {
              Profiles,
              Skills,
              Educations,
              Certifications,
              Industries,
              Languages,
              Keywords,
              Countries,
            } = searchCriteria;
            const updateSearchList = {
              ...searchList_searchList,
              roles: Profiles,
              skills: Skills,
              educations: Educations,
              certifications: Certifications,
              industries: Industries,
              languages: Languages,
              keywords: Keywords,
              countries: Countries,
            };
            this.props.onStateChangeAction({
              searchList_searchList: updateSearchList,
            });
            this.props.onSearchClick(updateSearchList, {
              searchList_availibility: { date: moment() },
            });
          }
        }
      }
    });
  };

  handleRoleChange = (options) => {
    const { searchList_searchList, searchList_availibility } = this.props;
    const roles = uniqBy(
      [options, ...searchList_searchList.roles],
      "ProfileId"
    );
    if (!options) return;
    const updateSearchList = {
      ...searchList_searchList,
      roles,
    };
    this.props.onStateChangeAction({
      searchList_searchList: updateSearchList,
    });

    if (this.firstSearch) {
      this.props.onSearchClick(updateSearchList, searchList_availibility);
      this.firstSearch = false;
    }
  };

  handleRemoveRole = (selectedItem) => {
    const { searchList_searchList } = this.props;
    const filterRoleList = searchList_searchList.roles.filter(
      (item) => item.ProfileId !== selectedItem.ProfileId
    );
    this.props.onStateChangeAction({
      searchList_searchList: {
        ...searchList_searchList,
        roles: filterRoleList,
      },
    });
  };
  handleRoleExpierenceLevelChange = (value, item) => {
    if (value) {
      const { searchList_searchList } = this.props;
      const searchList_rolesList = this.props.searchList_searchList.roles.map(
        (role) => ({
          ...role,
          ExperienceLevel:
            role.ProfileId === item.ProfileId ? value : role.ExperienceLevel,
        })
      );
      this.props.onStateChangeAction({
        searchList_searchList: {
          ...searchList_searchList,
          roles: searchList_rolesList,
        },
      });
    }
  };
  handleSkillExpierenceLevelChange = (value, item) => {
    if (value) {
      const { searchList_searchList } = this.props;
      const searchList_skillsList = this.props.searchList_searchList.skills.map(
        (role) => ({
          ...role,
          ExperienceLevel:
            role.SkillId === item.SkillId ? value : role.ExperienceLevel,
        })
      );
      this.props.onStateChangeAction({
        searchList_searchList: {
          ...searchList_searchList,
          skills: searchList_skillsList,
        },
      });
    }
  };
  handleIndustryExpierenceLevelChange = (value, item) => {
    if (value) {
      const { searchList_searchList } = this.props;
      const searchList_industriesList = this.props.searchList_searchList.industries.map(
        (role) => ({
          ...role,
          ExperienceLevel:
            role.IndustryId === item.IndustryId ? value : role.ExperienceLevel,
        })
      );
      this.props.onStateChangeAction({
        searchList_searchList: {
          ...searchList_searchList,
          industries: searchList_industriesList,
        },
      });
    }
  };
  handleLanguageExpierenceLevelChange = (value, item) => {
    if (value) {
      const { searchList_searchList } = this.props;
      const searchList_languagesList = this.props.searchList_searchList.languages.map(
        (role) => ({
          ...role,
          ExperienceLevel:
            role.LanguageId === item.LanguageId ? value : role.ExperienceLevel,
        })
      );
      this.props.onStateChangeAction({
        searchList_searchList: {
          ...searchList_searchList,
          languages: searchList_languagesList,
        },
      });
    }
  };

  /////////////ROLES SEARCH CODE END/////////////

  /////////////SKILLS SEARCH CODE START/////////////

  handleSkillChange = (options) => {
    const { searchList_searchList } = this.props;
    const skills = uniqBy(
      [options, ...searchList_searchList.skills],
      "SkillId"
    );
    if (!options) return;
    const updateSearchList = {
      ...searchList_searchList,
      skills,
    };
    this.props.onStateChangeAction({
      searchList_searchList: updateSearchList,
    });
  };

  handleSkillsLoad = (searchKey) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      typing: false,
      typingTimeout: setTimeout(() => {
        if (isEmpty(searchKey)) {
          this.handleSkillDefaultOptionsLoad("a");
          return;
        }
        this.handleSkillDefaultOptionsLoad(searchKey);
      }, 500),
    });
  };

  handleSkillDefaultOptionsLoad = (searchKey) => {
    this.props.onStateChangeAction({ searchList_skillsFetching: true });
    getSkillsApi({ searchKey }).then((response) => {
      if (response.success) {
        const skills = response.items.map((item) => ({
          ...item,
          label: item.SkillValue,
          value: item.SkillId,
        }));
        this.props.onStateChangeAction({
          searchList_skillsList: skills,
          searchList_skillsFetching: false,
        });
      }
    });
  };

  handleRemoveSkill = (selectedItem) => {
    const { searchList_searchList } = this.props;
    const filterSkillList = searchList_searchList.skills.filter(
      (item) => item.SkillId !== selectedItem.SkillId
    );
    this.props.onStateChangeAction({
      searchList_searchList: {
        ...searchList_searchList,
        skills: filterSkillList,
      },
    });
  };

  /////////////SKILLS SEARCH CODE END/////////////

  /////////////KEYWORD SEARCH CODE START/////////////
  handleKeywordChange = (options) => {
    const { searchList_searchList } = this.props;
    const keywords = uniqBy(
      [options, ...searchList_searchList.keywords],
      "KeywordId"
    );
    if (!options) return;
    const updateSearchList = {
      ...searchList_searchList,
      keywords,
    };
    this.props.onStateChangeAction({
      searchList_searchList: updateSearchList,
    });
  };
  handleKeywordsLoad = (searchKey) => {
    if (isEmpty(searchKey)) {
      this.handleKeywordDefaultOptionsLoad("a");
      return;
    }
    this.handleKeywordDefaultOptionsLoad(searchKey);
  };
  handleKeywordDefaultOptionsLoad = (searchKey) => {
    this.props.onStateChangeAction({ searchList_keywordsFetching: true });
    getKeywordApi({ searchKey }).then((response) => {
      if (response.success) {
        const keywords = response.items.map((item) => ({
          ...item,
          label: item.KeywordValue,
          value: item.KeywordId,
        }));
        this.props.onStateChangeAction({
          searchList_keywordsList: keywords,
          searchList_keywordsFetching: false,
        });
      }
    });
  };
  handleRemoveKeyword = (selectedItem) => {
    const { searchList_searchList } = this.props;
    const filterKeywordList = searchList_searchList.keywords.filter(
      (item) => item.KeywordId !== selectedItem.KeywordId
    );
    this.props.onStateChangeAction({
      searchList_searchList: {
        ...searchList_searchList,
        keywords: filterKeywordList,
      },
    });
  };

  /////////////KEYWORD SEARCH CODE END/////////////

  /////////////INDUSTRIES SEARCH CODE START/////////////

  handleIndustryChange = (options) => {
    const { searchList_searchList } = this.props;
    const industries = uniqBy(
      [options, ...searchList_searchList.industries],
      "IndustryId"
    );
    if (!options) return;
    const updateSearchList = {
      ...searchList_searchList,
      industries,
    };
    this.props.onStateChangeAction({
      searchList_searchList: updateSearchList,
    });
  };
  handleIndustriesLoad = (searchKey) => {
    if (isEmpty(searchKey)) {
      this.handleIndustryDefaultOptionsLoad("a");
      return;
    }
    this.handleIndustryDefaultOptionsLoad(searchKey);
  };
  handleIndustryDefaultOptionsLoad = (searchKey) => {
    this.props.onStateChangeAction({ searchList_industriesFetching: true });
    getIndustriesApi({ searchKey }).then((response) => {
      if (response.success) {
        const industries = response.items.map((item) => ({
          ...item,
          label: item.IndustryValue,
          value: item.IndustryId,
        }));
        this.props.onStateChangeAction({
          searchList_industriesList: industries,
          searchList_industriesFetching: false,
        });
      }
    });
  };
  handleRemoveIndustry = (selectedItem) => {
    const { searchList_searchList } = this.props;
    const filterIndustryList = searchList_searchList.industries.filter(
      (item) => item.IndustryId !== selectedItem.IndustryId
    );
    this.props.onStateChangeAction({
      searchList_searchList: {
        ...searchList_searchList,
        industries: filterIndustryList,
      },
    });
  };
  /////////////INDUSTRIES SEARCH CODE END/////////////

  /////////////CERTIFICATIONS SEARCH CODE START/////////////
  handleCertificationChange = (options) => {
    const { searchList_searchList } = this.props;
    const certifications = uniqBy(
      [...searchList_searchList.certifications, options],
      "CertificationId"
    );
    if (!options) return;
    const updateSearchList = {
      ...searchList_searchList,
      certifications,
    };
    this.props.onStateChangeAction({
      searchList_searchList: updateSearchList,
    });
  };
  handleCertificationsLoad = (searchKey) => {
    if (isEmpty(searchKey)) {
      this.handleCertificationDefaultOptionsLoad("a");
      return;
    }
    this.handleCertificationDefaultOptionsLoad(searchKey);
  };
  handleCertificationDefaultOptionsLoad = (searchKey) => {
    this.props.onStateChangeAction({ searchList_certificationsFetching: true });
    getCertificationsApi({ searchKey }).then((response) => {
      if (response.success) {
        const certifications = response.items.map((item) => ({
          ...item,
          label: item.CertificationValue,
          value: item.CertificationId,
        }));
        this.props.onStateChangeAction({
          searchList_certificationsList: certifications,
          searchList_certificationsFetching: false,
        });
      }
    });
  };
  handleRemoveCertification = (selectedItem) => {
    const { searchList_searchList } = this.props;
    const filterCertificationList = searchList_searchList.certifications.filter(
      (item) => item.CertificationId !== selectedItem.CertificationId
    );
    this.props.onStateChangeAction({
      searchList_searchList: {
        ...searchList_searchList,
        certifications: filterCertificationList,
      },
    });
  };
  /////////////CERTIFICATIONS SEARCH CODE END/////////////

  /////////////COUNTRIES SEARCH CODE START/////////////
  handleCountryChange = (options) => {
    const { searchList_searchList } = this.props;
    if (
      searchList_searchList.countries.findIndex(
        (item) => item.CountryId === options.CountryId
      ) > -1
    ) {
      return;
    }
    const countries = uniqBy(
      [options, ...searchList_searchList.countries],
      "CountryId"
    );
    if (!options) return;
    const updateSearchList = {
      ...searchList_searchList,
      countries: countries,
    };
    this.props.onStateChangeAction({
      searchList_searchList: updateSearchList,
    });
  };
  handleCountriesLoad = (searchKey) => {
    if (isEmpty(searchKey)) {
      this.handleCountryDefaultOptionsLoad(null);
      return;
    }
    this.handleCountryDefaultOptionsLoad(searchKey);
  };
  handleCountryDefaultOptionsLoad = (searchKey) => {
    this.props.onStateChangeAction({ searchList_countriesFetching: true });
    getAllLocationsApi({ searchKey }).then((response) => {
      if (response.success) {
        const countries = response.items.map((item) => ({
          ...item,
          label: item.CountryName,
          value: item.CountryId,
        }));
        if (searchKey) {
          const filterCountries = this.filterItemsForLookups({
            searchKey,
            items: countries,
          });
          this.props.onStateChangeAction({
            searchList_countriesList: filterCountries,
            searchList_countriesFetching: false,
          });
        } else {
          this.props.onStateChangeAction({
            searchList_countriesList: countries,
            searchList_countriesFetching: false,
          });
        }
      }
    });
  };

  filterItemsForLookups = ({ searchKey, items }) => {
    if (!searchKey || !items || items.length == 0) {
      return;
    }
    const searchKeys = filter(searchKey.split(" "), (item) => item.length > 0);
    const filterItems = filter(items, (item) => {
      searchKeys.forEach((element) => {
        if (!item.isSearched) {
          item.isSearched = toLower(item.label).includes(toLower(element));
        }
      });
      return item.isSearched;
    });
    return filterItems;
  };

  handleRemoveCountry = (selectedItem) => {
    const { searchList_searchList } = this.props;
    const filterCountryList = searchList_searchList.countries.filter(
      (item) => item.CountryId !== selectedItem.CountryId
    );
    this.props.onStateChangeAction({
      searchList_searchList: {
        ...searchList_searchList,
        countries: filterCountryList,
      },
    });
  };
  /////////////COUNTRIES SEARCH CODE END/////////////

  ///////////LANGUAGES SEARCH CODE START/////////////
  handleLanguageChange = (options) => {
    const { searchList_searchList } = this.props;
    const languages = uniqBy(
      [options, ...searchList_searchList.languages],
      "LanguageId"
    );
    if (!options) return;
    const updateSearchList = {
      ...searchList_searchList,
      languages,
    };
    this.props.onStateChangeAction({
      searchList_searchList: updateSearchList,
    });
  };
  handleLanguagesLoad = (searchKey) => {
    const { languagesList } = this.state;

    const allLanguagesList = languagesList.map((item) => ({
      ...item,
      label: item.LanguageValue,
      value: item.LanguageId,
    }));
    const filterLanguages = this.filterItemsForLookups({
      searchKey,
      items: allLanguagesList,
    });
    this.props.onStateChangeAction({
      searchList_languagesList: searchKey ? filterLanguages : allLanguagesList,
      searchList_languagesFetching: false,
    });
  };
  handleLanguageDefaultOptionsLoad = () => {
    this.props.onStateChangeAction({ searchList_languagesFetching: true });
    getAllLanguagesApi().then((response) => {
      if (response.success) {
        const languagesList = response.items.map((item) => ({
          ...item,
          label: item.LanguageValue,
          value: item.LanguageId,
        }));

        this.setState({
          languagesList,
        });
        this.props.onStateChangeAction({
          searchList_languagesList: languagesList,
          searchList_languagesFetching: false,
        });
      }
    });
  };
  handleRemoveLanguage = (selectedItem) => {
    const { searchList_searchList } = this.props;
    const filterLanguageList = searchList_searchList.languages.filter(
      (item) => item.LanguageId !== selectedItem.LanguageId
    );
    this.props.onStateChangeAction({
      searchList_searchList: {
        ...searchList_searchList,
        languages: filterLanguageList,
      },
    });
  };
  ///////////LANGUAGES SEARCH CODE END/////////////

  handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Handle value={value} key={index} {...restProps}>
        {value}
      </Handle>
    );
  };
  handleDateChange = (d) => {
    this.props.onStateChangeAction({
      searchList_availibility: {
        date: moment(d),
      },
    });
  };

  render() {
    const {
      labels,
      onSearchClick,
      isHelpActive,
      selectedSearchTab,
      onSearchTabChange,
      isVisitor,
    } = this.props;

    const {
      dialogMessage,
      searchList_searchList,
      searchList_availibility,
      searchList_rolesFetching,
      searchList_rolesList,
      searchList_skillsFetching,
      searchList_skillsList,
      searchList_keywordsFetching,
      searchList_keywordsList,
      searchList_industriesFetching,
      searchList_industriesList,
      searchList_certificationsFetching,
      searchList_certificationsList,
      searchList_countriesFetching,
      searchList_countriesList,
      searchList_languagesFetching,
      searchList_languagesList,
    } = this.props;
    const {
      roles,
      skills,
      keywords,
      industries,
      certifications,
      countries,
      languages,
    } = searchList_searchList;
    return (
      <div className="search-list-component">
        {dialogMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <button className="dialog-btn" onClick={this.handleYesClick}>
                {labels.ShortlistResumeListDeletionCurtainYESBtnText}
              </button>
              <button className="dialog-btn" onClick={this.handleNoClick}>
                {labels.ShortlistResumeListDeletionCurtainNOBtnText}
              </button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <div className="action-buttons">
          <Button
            className="clearSearch closeBtn"
            tooltipPlace="left"
            tooltipButton={labels.ToolTipSearchClear}
            onClick={this.handleClearSearch}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.SEARCH_CLEAR_SEARCH_BTN_HELP}
            testId={"resume-search-clear-btn"}
            testIdHelp={"resume-search-clear-help-btn"}
          />
          <Button
            mainClassName="search-button"
            className="SearchBtn"
            tooltipPlace="left"
            tooltipButton={labels.ToolTipSearchSearch}
            onClick={() =>
              onSearchClick(searchList_searchList, searchList_availibility)
            }
            isHelpActive={isHelpActive}
            tooltipHelp={labels.SEARCH_SEARCH_BTN_HELP}
            testId={"resume-search-lookup-btn"}
            testIdHelp={"resume-search-lookup-help-btn"}
          />
          {/* <Button
            className="menuBtn"
            tooltipPlace="left"
            tooltipButton={labels.ToolTipSearchMenu}
            onClick={() => {}}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.SEARCH_DRAGABLE_BTN_HELP}
          /> */}
        </div>
        <div className="search-list-item">
          <label
            className={`lblHeading ${
              selectedSearchTab === "availablity" ? "activeSearch" : ""
            }`}
            onClick={() => onSearchTabChange("availablity")}
          >
            {labels.RESUME_SEARCH_AVAILABILITY_DATE_LABEL}
          </label>
          <label
            className="availablity-date-picker   seach-datepicker"
            onClick={() => onSearchTabChange("availablity")}
          >
            <DatePicker
              name="DatePicker"
              todayButton="Today"
              className="searchlist_datepicker"
              selected={moment(searchList_availibility.date) || ""}
              dateFormat="DD-MM-YYYY"
              onChange={(d) =>
                this.props.onStateChangeAction({
                  searchList_availibility: {
                    date: d,
                    value: 5,
                    label: "Custom",
                  },
                })
              }
              minDate={moment()}
            />
          </label>
        </div>
        <div className="search-list-item">
          <label
            className={`lblHeading ${
              selectedSearchTab === "role" ? "activeSearch" : ""
            }`}
            onClick={() => onSearchTabChange("role")}
          >
            {labels.ResumeEditHeadingRole}
          </label>
          <CustomSelect
            isFetching={searchList_rolesFetching}
            inputTestId="search-role-test-id"
            placeholder={labels.RESUME_EDIT_ROLE_NAME_PLACEHOLDER}
            options={searchList_rolesList}
            onItemSelect={this.handleRoleChange}
            onSearchTabChange={() => onSearchTabChange("role")}
            onChange={this.handleRolesLoad}
          />
          <CapsuleList>
            {roles &&
              roles.map((item) => (
                <CapsuleList.Capsule key={item.ProfileId}>
                  <CapsuleList.Label testId={`role-${item.ProfileValue}-item`}>
                    {item.ProfileValue}
                  </CapsuleList.Label>
                  <CloseBtn
                    classes="close_btn_purple for-capsule"
                    onClick={() => this.handleRemoveRole(item)}
                    testId={"resume-search-role-delete-btn"}
                    onFocus={() => onSearchTabChange("role")}
                  />
                </CapsuleList.Capsule>
              ))}
          </CapsuleList>
        </div>
        <div className="search-list-item">
          <label
            className={`lblHeading ${
              selectedSearchTab === "skill" ? "activeSearch" : ""
            }`}
            onClick={() => onSearchTabChange("skill")}
          >
            {labels.ResumeEditHeadingSkill}
          </label>
          <CustomSelect
            className="select-skill-name select-input"
            isFetching={searchList_skillsFetching}
            inputTestId="search-skill-test-id"
            placeholder={labels.RESUME_EDIT_SKILL_NAME_PLACEHOLDER}
            options={searchList_skillsList}
            onItemSelect={this.handleSkillChange}
            onSearchTabChange={() => onSearchTabChange("skill")}
            onChange={this.handleSkillsLoad}
          />
          <CapsuleList>
            {skills &&
              skills.map((item) => (
                <CapsuleList.Capsule key={item.SkillId}>
                  <CapsuleList.Label testId={`skill-${item.SkillValue}-item`}>
                    {item.SkillValue}
                  </CapsuleList.Label>
                  <CloseBtn
                    classes="close_btn_purple for-capsule"
                    onClick={() => this.handleRemoveSkill(item)}
                    testId={"resume-search-skill-delete-btn"}
                    onFocus={() => onSearchTabChange("skill")}
                  />
                </CapsuleList.Capsule>
              ))}
          </CapsuleList>
        </div>
        <div className="search-list-item">
          <label
            className={`lblHeading ${
              selectedSearchTab === "keyword" ? "activeSearch" : ""
            }`}
            onClick={() => onSearchTabChange("keyword")}
          >
            {labels.ResumeHeadingKeyword}
          </label>
          <CustomSelect
            className={`select-keyword-name select-input`}
            isFetching={searchList_keywordsFetching}
            inputTestId="search-keyword-test-id"
            placeholder={labels.RESUME_EDIT_KEYWORD_NAME_PLACEHOLDER}
            options={searchList_keywordsList}
            onItemSelect={this.handleKeywordChange}
            onSearchTabChange={() => onSearchTabChange("keyword")}
            onChange={this.handleKeywordsLoad}
          />

          <CapsuleList>
            {keywords &&
              keywords.map((item) => (
                <CapsuleList.Capsule key={item.KeywordId}>
                  <CapsuleList.Label
                    testId={`keyword-${item.KeywordValue}-item`}
                  >
                    {item.KeywordValue}
                  </CapsuleList.Label>
                  <CloseBtn
                    classes="close_btn_purple for-capsule"
                    onClick={() => this.handleRemoveKeyword(item)}
                    testId={"resume-search-keyword-delete-btn"}
                    onFocus={() => onSearchTabChange("keyword")}
                  />
                </CapsuleList.Capsule>
              ))}
          </CapsuleList>
        </div>
        <div className="search-list-item">
          <label
            className={`lblHeading ${
              selectedSearchTab === "industry" ? "activeSearch" : ""
            }`}
            onClick={() => onSearchTabChange("industry")}
          >
            {labels.ResumeHeadingIndustry}
          </label>
          <CustomSelect
            className={`select-industry-name select-input`}
            placeholder={labels.RESUME_EDIT_INDUSTRY_NAME_PLACEHOLDER}
            isFetching={searchList_industriesFetching}
            inputTestId="search-industry-test-id"
            options={searchList_industriesList}
            onItemSelect={this.handleIndustryChange}
            onSearchTabChange={() => onSearchTabChange("industry")}
            onChange={this.handleIndustriesLoad}
          />
          <CapsuleList>
            {industries &&
              industries.map((item) => (
                <CapsuleList.Capsule key={item.IndustryId}>
                  <CapsuleList.Label
                    testId={`industry-${item.IndustryValue}-item`}
                  >
                    {item.IndustryValue}
                  </CapsuleList.Label>
                  <CloseBtn
                    classes="close_btn_purple for-capsule"
                    onClick={() => this.handleRemoveIndustry(item)}
                    testId={"resume-search-industry-delete-btn"}
                    onFocus={() => onSearchTabChange("industry")}
                  />
                </CapsuleList.Capsule>
              ))}
          </CapsuleList>
        </div>
        <div className="search-list-item">
          <label
            className={`lblHeading ${
              selectedSearchTab === "certification" ? "activeSearch" : ""
            }`}
            onClick={() => onSearchTabChange("certification")}
          >
            {labels.ResumeHeadingCertification}
          </label>
          <CustomSelect
            className={`select-certification-name select-input`}
            placeholder={labels.RESUME_EDIT_CERTIFICATION_NAME_PLACEHOLDER}
            isFetching={searchList_certificationsFetching}
            inputTestId="search-certification-test-id"
            options={searchList_certificationsList}
            onItemSelect={this.handleCertificationChange}
            onSearchTabChange={() => onSearchTabChange("certification")}
            onChange={this.handleCertificationsLoad}
          />
          <CapsuleList>
            {certifications &&
              certifications.map((item) => (
                <CapsuleList.Capsule key={item.CertificationId}>
                  <CapsuleList.Label
                    testId={`certification-${item.CertificationValue}-item`}
                  >
                    {item.CertificationValue}
                  </CapsuleList.Label>
                  <CloseBtn
                    classes="close_btn_purple for-capsule"
                    onClick={() => this.handleRemoveCertification(item)}
                    testId={"resume-search-certification-delete-btn"}
                    onFocus={() => onSearchTabChange("certification")}
                  />
                </CapsuleList.Capsule>
              ))}
          </CapsuleList>
        </div>
        <div className="search-list-item">
          <label
            className={`lblHeading ${
              selectedSearchTab === "country" ? "activeSearch" : ""
            }`}
            onClick={() => onSearchTabChange("country")}
          >
            {labels.COUNTRY_LABEL}
          </label>
          <CustomSelect
            className={`select-country-name select-input`}
            placeholder={labels.RESUME_EDIT_LOCATION_NAME_PLACEHOLDER}
            isFetching={searchList_countriesFetching}
            inputTestId="search-country-test-id"
            options={searchList_countriesList}
            onItemSelect={this.handleCountryChange}
            onSearchTabChange={() => onSearchTabChange("country")}
            onChange={this.handleCountriesLoad}
          />
          <CapsuleList>
            {countries &&
              countries.map((item) => (
                <CapsuleList.Capsule key={item.CountryId}>
                  <CapsuleList.Label
                    testId={`country-${item.CountryName}-item`}
                  >
                    {item.CountryName}
                  </CapsuleList.Label>
                  <CloseBtn
                    classes="close_btn_purple for-capsule"
                    onClick={() => this.handleRemoveCountry(item)}
                    testId={"resume-search-country-delete-btn"}
                    onFocus={() => onSearchTabChange("country")}
                  />
                </CapsuleList.Capsule>
              ))}
          </CapsuleList>
        </div>
        <div className="search-list-item">
          <label
            className={`lblHeading ${
              selectedSearchTab === "language" ? "activeSearch" : ""
            }`}
            onClick={() => onSearchTabChange("language")}
          >
            {labels.ResumeEditHeadingLanguage}
          </label>
          <CustomSelect
            className={`select-language-name select-input`}
            placeholder={labels.RESUME_EDIT_LANGUAGE_NAME_PLACEHOLDER}
            isFetching={searchList_languagesFetching}
            inputTestId="search-language-test-id"
            options={searchList_languagesList}
            onItemSelect={this.handleLanguageChange}
            onSearchTabChange={() => onSearchTabChange("language")}
            onChange={this.handleLanguagesLoad}
          />
          <CapsuleList>
            {languages &&
              languages.map((item) => (
                <CapsuleList.Capsule key={item.LanguageId}>
                  <CapsuleList.Label
                    testId={`language-${item.LanguageValue}-item`}
                  >
                    {item.LanguageValue}
                  </CapsuleList.Label>
                  <CloseBtn
                    classes="close_btn_purple for-capsule"
                    onClick={() => this.handleRemoveLanguage(item)}
                    testId={"resume-search-language-delete-btn"}
                    onFocus={() => onSearchTabChange("language")}
                  />
                </CapsuleList.Capsule>
              ))}
          </CapsuleList>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  { systemLabel, userInfo, search, savedSearch, landing },
  { isSaveSearch }
) => {
  const { labels } = systemLabel;
  const { searchCriteria } = landing;
  const { user } = userInfo;
  const { ProfileId, ProfileValue } = user;
  let { IsFreelancer } = user;
  const searchProps = isSaveSearch ? savedSearch : search;
  return {
    labels,
    IsFreelancer,
    ProfileId,
    ProfileValue,
    searchCriteria,
    ...searchProps,
  };
};
export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(SearchList);
