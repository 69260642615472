import React from "react";
import "./navigation.scss";
import { NavigationItem } from "../../../common";

class AssortNavigation extends React.Component {
  render() {
    const hash = window.location.hash.toLowerCase();
    const app = hash.indexOf("apps") > -1 ? "/Apps/Assort/" : "/Assort/";
    const { isHelpActive, labels, UserFeatures, disabled } = this.props;
    const hashesList = [
      "#/apps/assort/",
      "#/assort/",
      "#/apps/assort",
      "#/assort",
    ];
    return (
      <div className={`${disabled ? "disabled" : ""} product-menus`}>
        {UserFeatures.map((item,i) => (
          <NavigationItem
            Id={3}
            key = {i}
            tooltipLabel={`${labels[`${item.Name}-Tooltip`]}`}
            isHelpActive={isHelpActive}
            IsActive={
              item.Url && window.location.href.indexOf(item.Url) > -1
                ? true
                : hashesList.indexOf(window.location.hash.toLowerCase()) > -1
                ? true
                : false
            }
            CssClass={"approve-menu"}
            CssClassIcon={item.CssClassIcon}
            Url={`${app}${item.Url}`}
            testId={`${item.Name}-button`}
            menuText={""}
            isNavShrink={false}
            tooltipHelp={`${labels[`${item.Name}-Help`]}`}
            NotificationBadge={false}
            NotificationCount={5}
          />
        ))}
      </div>
    );
  }
}

export default AssortNavigation;
