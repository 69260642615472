import React from "react";
import { Select } from "antd";
const { Option } = Select;

export default ({ data, name, handleClick }) => {
  return (
    <div className="mo-col-2">
      <Select
        style={{ width: "100%", marginTop: "10px" }}
        placeholder="select"
        defaultValue={`Select ${name}`}
        onSelect={(input, option) => {
          handleClick(name, option);
        }}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option?.props?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
        options={data}
      ></Select>
      {/* <ul className="mo-list-item">
        {data?.slice(0, value)?.map((item) => (
          <li
            className={`${item.isSelected ? "active " : " "}`}
            onClick={() => handleClick(name, item)}
          >
            {name == "shortlists"
              ? item.ShortlistName
              : name == "savedSearches"
              ? item.SearchName
              : item.RequestName}
          </li>
        ))}
        {data?.length > value && (
          <div
            onClick={() => {
              setValue(data?.length);
            }}
            style={{ textAlign: "center", marginTop: "5px" }}
          >
            <img src={Icon} alt="" />
          </div>
        )}
        {data?.length == value && (
          <div
            onClick={() => {
              setValue(6);
            }}
            style={{ textAlign: "center", marginTop: "5px" }}
          >
            <img src={Icon} alt="" style={{ transform: "rotate(180deg)" }} />
          </div>
        )}
      </ul> */}
    </div>
  );
};
