import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, find, map, filter, includes, toLower, head } from "lodash";
import { PageWrapper } from "../../../components";
import {
  Column,
  ConfirmDialog,
  Button,
  EmptyInfo,
  SearchInput,
  LoadingMaskRow,
  BulletList,
  LoadingMask,
} from "../../../common";
import ShortlistResumeDetail from "../../Shortlist/component/ShortlistResumeDetail";
import ShortlistResumes from "../../Shortlist/component/ShortlistResumes";
import { notificationAction } from "../../../actions";
import "./searcherOpportunitySent.scss";
import {
  getShortlistResumesApi,
  getSentOpportunityDetailApi,
  deleteSendOpportunityApi,
  deleteOpportunityAndAssociationsApi,
  getOpportunityResumesApi,
  getSentOpportunitesApi,
} from "../opportunityApi";
import OpportunityDetail from "../Searcher/common/OpportunityDetail";
import {
  deleteResumeApi,
  getShortlistResumeApi,
  GetShortlistResumeCount,
} from "../../Shortlist/shortlistApi";
import UserDetail from "../../Collaboration/common/components/UserDetail";
import {
  getOpportunityIprosResumeApi,
  GetCollCountByRequestId,
  getCurrenciesApi,
} from "../../Collaboration/common/collaborationApi";
import { onStateChangeAction } from "./searcherOpportunitySentAction";
import { CloseBtn } from "../../../common/CloseBtn";

class SearcherOpportunitySent extends Component {
  state = {
    declinedRequests: "",
    acceptedRequests: "",
    pendingRequests: "",
    isSearchFocus: false,
    searchListHover: false,
  };

  getOpportunities = () => {
    const { isFreelancer } = this.props;
    this.props.onStateChangeAction({ isFetching: true });
    getSentOpportunitesApi({ isFreelancer }).then((response) => {
      const {
        success,
        items: { Sent },
      } = response;
      if (success) {
        this.props.onStateChangeAction({
          opportunities: Sent,
          filterOpportunires: Sent,
          isFetching: false,
        });
        const spliturl = this.props.location.pathname.match(/\d+/g);
        if (Sent.length > 0) {
          if (spliturl != null) {
            let filteredOppertunity = Sent.filter(
              (res) => res.RequestId == spliturl[0]
            );
            this.handleOpportunityClick({
              selectedOpportunity: filteredOppertunity[0],
            });
          } else {
            this.handleOpportunityClick({ selectedOpportunity: Sent[0] });
          }
        }
      }
    });
  };

  componentDidMount() {
    this.props.onStateChangeAction({
      opportunities: [],
      filterOpportunires: [],
      selectedOpportunity: {},
    });
    this.getOpportunities();
    this.getCurrencies();
  }

  replaceObjectKeys = ({ oldObject, mappingObject }) => {
    return Object.keys(oldObject).reduce((acc, key) => {
      acc[mappingObject[key]] = oldObject[key];
      return acc;
    }, {});
  };
  getOpportunityIProsResume = (requestId) => {
    getOpportunityIprosResumeApi(requestId).then((data) => {
      if (data.success) {
        const iprosList = map(data.items.user, (item) => {
          const newItem = {
            ...item,
            value: item.ResumeId,
            label: item.UserName,
          };

          return newItem;
        });
        this.props.onStateChangeAction({
          iprosList,
        });
      }
    });
  };
  handleOpportunityClick = ({ selectedOpportunity }) => {
    const { RequestId } = selectedOpportunity;
    this.getOpportunityIProsResume(RequestId);
    let selectedShortlist = null;
    this.props.onStateChangeAction({
      currentOpportunityId: RequestId,
      isFetchingShortlist: true,
      isFetchingShortlistResume: true,
      selectedShortlists: [],
      selectedOpportunity,
      shortlistResumes: [],
      selectedResume: {},
      selectedIpro: {},
      hideShortList: false,
    });
    getSentOpportunityDetailApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const { ShortLists } = items;
          const selectedOpportunity = {
            ...items,
            FeeCurrencyType: this.props.allCurrenciesList.find(
              (x) => x.CurrencyId == items.FeeCurrencyType
            ).Name,
          };
          this.props.onStateChangeAction({
            selectedOpportunity,
          });
          if (isEmpty(ShortLists)) {
            this.props.onStateChangeAction({
              selectedShortlists: [],
              selectedShortlist: null,
              shortlistResumes: [],
              selectedResume: {},
              selectedIpro: {},
              isFetchingShortlist: false,
            });
            return;
          }
          selectedShortlist = head(ShortLists);
          this.props.onStateChangeAction({
            selectedShortlists: ShortLists,
            selectedShortlist,
            isFetchingShortlist: false,
          });
          GetShortlistResumeCount(RequestId).then((data) => {
            if (data.success) {
              this.setState({
                declinedRequests: data.items.declinedReq,
                acceptedRequests: data.items.acceptedReq,
                pendingRequests: data.items.pendingReq,
              });
            }
          });
          getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
            .then((response) => {
              if (response.success) {
                const { items } = response;
                this.props.onStateChangeAction({
                  shortlistResumes: items,
                  selectedResume: {},
                  selectedIpro: {},
                  isFetchingShortlistResume: false,
                });
              }
            })
            .catch((response) => {});
        }
      })
      .catch((response) => {
        this.props.onStateChangeAction({
          isFetchingShortlist: false,
          isFetchingShortlistResume: false,
        });
      });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { opportunities } = this.props;
    const filterOpportunires = filter(opportunities, (opportunity) =>
      includes(toLower(opportunity.RequestName), toLower(value))
    );
    this.props.onStateChangeAction({ filterOpportunires, searchkey: value });
  };

  handleOpportunityDelete = ({ selectedOpportunity, e }) => {
    e.stopPropagation();
    this.handleOpportunityClick({ selectedOpportunity });
    this.props.onStateChangeAction({
      selectedOpportunity,
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftOpportunityDeleteConformationMsg,
    });
  };
  handleYesClick = () => {
    const { yesClickCount, currentOpportunityId } = this.props;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: this.props.labels
          .InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg,
        yesClickCount: yesClickCount + 1,
      });
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: "",
      yesClickCount: 0,
    });

    this.props.onStateChangeAction({ isLoading: true });
    deleteOpportunityAndAssociationsApi({
      RequestId: currentOpportunityId,
    })
      .then((response) => {
        if (!response.success) {
          this.props.onStateChangeAction({
            isLoading: false,
            selectedOpportunity: null,
          });
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        } else {
          this.props.onStateChangeAction({ isLoading: false });
          const info = {
            message: response.message,
            status: "succes",
          };
          this.props.notificationAction(info);
        }
        this.props.onStateChangeAction({ isLoading: false });
        setTimeout(() => {
          this.deleteOpportunity({ RequestId: currentOpportunityId });
        }, 5000);
      })
      .catch((response) => {
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
        });
      });
  };
  deleteOpportunity = ({ RequestId }) => {
    deleteSendOpportunityApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const info = {
            message: this.props.labels.oppDeletedSuccessfully,
            status: "success",
          };
          this.props.notificationAction(info);
          this.getOpportunities();
          this.props.onStateChangeAction({
            isLoading: false,
            selectedOpportunity: null,
            opportunity: null,
          });
        }
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      })
      .catch((response) => {
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      });
  };
  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          this.props.onStateChangeAction({ allCurrenciesList });
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  handleNoClick = () => {
    const { dialogMessage, currentOpportunityId, yesClickCount } = this.props;
    const { labels } = this.props;
    if (
      dialogMessage ===
      labels.InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg
    ) {
      this.props.onStateChangeAction({ isLoading: true });
      this.deleteOpportunity({ RequestId: currentOpportunityId });
    }
    this.props.onStateChangeAction({ dialogMessage: "", yesClickCount: 0 });
  };

  handleSearchBlur = () => {
    const { searchListHover } = this.state;
    if (searchListHover) return;
    this.setState({ isSearchFocus: false });
  };

  handleShortListSelect = ({ selectedShortlist }) => {
    const { selectedShortlists } = this.props;
    const alreadySelected = filter(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId === selectedShortlist.ShortlistId
    );
    if (isEmpty(alreadySelected)) {
      this.props.onStateChangeAction({
        selectedShortlists: [...selectedShortlists, selectedShortlist],
      });
      this.setState({
        isSearchFocus: false,
      });
      return;
    }
    this.setState({ isSearchFocus: false });
  };
  handleSelectShortlist = (item) => {
    this.props.onStateChangeAction({
      selectedShortlist: item,
      shortlistResumes: [],
      selectedResume: {},
    });
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        if (data.success) {
          const selectedResume = head(data.items);
          const selectedIpro =
            this.iprosList.find(
              (ipro) => ipro.ResumeId === selectedResume.ResumeId
            ) || {};
          this.props.onStateChangeAction({
            shortlistResumes: data.items,
            fetchingResumes: false,
            isFetchingShortlistResume: false,
            selectedResume,
            selectedIpro,
          });
        }
      })
      .catch((err) => {
        this.props.onStateChangeAction({ fetchingResumes: false });
      });
  };

  handleSelectedShortlistClick = ({ selectedShortlist }) => {
    getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const selectedResume = head(items);
          const selectedIpro =
            this.props.iprosList.find(
              (ipro) => ipro.ResumeId == selectedResume.ResumeId
            ) || {};
          if (isEmpty(items)) {
            const info = {
              message: this.props.labels
                .InfoSearcherOpportunityDraftEmptyShortlist,
              status: "error",
            };
            this.props.notificationAction(info);
          }
          this.props.onStateChangeAction({
            selectedShortlist,
            shortlistResumes: items,
            selectedResume,
            selectedIpro,
            fetchingResumes: false,
            isFetchingShortlistResume: false,
          });
        }
      })
      .catch((response) => {});
  };

  setFieldValue = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [name]: value,
      },
    });
  };

  handleResumeSelect = (selectedResume) => {
    const { shortlistResumes } = this.props;
    selectedResume = find(shortlistResumes, {
      ResumeId: selectedResume.ResumeId,
    });
    const selectedIpro =
      this.props.iprosList.find(
        (ipro) => ipro.ResumeId == selectedResume.ResumeId
      ) || {};
    this.props.onStateChangeAction({ selectedResume, selectedIpro });
  };

  handleResumeDelete = (item) => {
    let { shortlistResumes, selectedResume, selectedShortlist } = this.props;
    deleteResumeApi(item.ResumeId, selectedShortlist.ShortlistId)
      .then((data) => {
        if (data.success) {
          shortlistResumes = shortlistResumes.filter(
            (x) => x.ResumeId !== item.ResumeId
          );
          this.props.onStateChangeAction({
            shortlistResumes,
            selectedResume:
              item.ResumeId == selectedResume.ResumeId ? {} : selectedResume,
          });
        }
      })
      .catch((err) => console.log("Error ", err));
  };

  handleOkClick = () => {
    this.handleOpportunityUpdate({ IsSent: true });
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  getOpportunityResumes = (searchkey) => {
    const { currentOpportunityId } = this.props;

    getOpportunityResumesApi({ requestId: currentOpportunityId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          this.props.onStateChangeAction({
            shortlistResumes: items,
            // selectedResume: head(items),
            isFetchingShortlistResume: false,
          });
          const { shortlistResumes } = this.props;
          let filteredList;
          if (searchkey == 1) {
            filteredList = items.filter(
              (item) => item.IsConfirmed == true && !item.IsDeleted
            );
          } else if (searchkey == 2) {
            filteredList = items.filter(
              (item) => item.IsDeleted == true || item.IsDeclined == true
            );
          } else {
            filteredList = items.filter(
              (item) =>
                item.IsDeleted == false &&
                item.IsDeclined == false &&
                item.IsConfirmed == false
            );
          }
          const selectedResume = head(filteredList) || {};
          const selectedIpro =
            this.props.iprosList.find(
              (ipro) => ipro.ResumeId == selectedResume.ResumeId
            ) || {};
          this.props.onStateChangeAction({
            hideShortList: true,
            showResumeList: true,
            shortlistResumes: filteredList,
            isFetchingShortlistResume: false,
            selectedResume,
            selectedIpro,
          });
        }
      })
      .catch((response) => {});
  };
  handleAcceptedResumes = () => {
    this.getOpportunityResumes(1);
  };
  handleDeclinedResumes = () => {
    this.getOpportunityResumes(2);
  };
  handleNoActionResumes = () => {
    this.getOpportunityResumes(3);
  };
  render() {
    const { labels, isHelpActive } = this.props;
    const { declinedRequests, acceptedRequests, pendingRequests } = this.state;
    const {
      isEmptyCollapsed,
      listCollapse,
      detailCollapse,
      shortlistCollapse,
      resumeListCollapse,
      shortlistResumeCollapse,
      dialogMessage,
      isLoading,
      isFetching,
      isFetchingShortlist,
      isFetchingShortlistResume,
      filterOpportunires,
      selectedOpportunity,
      selectedShortlists,
      selectedShortlist,
      shortlistResumes,
      selectedResume,
      currentOpportunityId,
      searchkey,
      hideShortList,
      showResumeList,
      selectedIpro,
      selectedAction,
    } = this.props;
    return (
      <PageWrapper className="searcher-draft-opportunity-page ">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              {dialogMessage ===
              this.props.labels
                .InfoSearcherOpportunityDraftSendConformationMsg ? (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-sent-ok"
                  onClick={this.handleOkClick}
                >
                  Ok
                </ConfirmDialog.Button>
              ) : (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-sent-yes"
                  onClick={this.handleYesClick}
                >
                  Yes
                </ConfirmDialog.Button>
              )}
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={listCollapse} className="col-1">
          <Column.Collapsed
            collapse={listCollapse}
            text={labels.SearcherOpportunityDraftViewTitleList}
            onClick={() =>
              this.props.onStateChangeAction({ listCollapse: false })
            }
            isHelpActive={isHelpActive}
            tooltipButton={
              labels.TooltipSearcherOpportunityDraftOpportunityListExpand
            }
            tooltipHelp={
              labels.HlpTooltipSearcherOpportunityDraftOpportunityListExpand
            }
            tooltipPlace="left"
            testId="opportunity-sent-list-expand-btn"
            testIdHelp="opportunity-sent-list-expand-help-btn"
          />
          <Column.Head>
            <SearchInput
              placeholder={labels.SearcherOpportunitySentSearchPlaceholder}
              onChange={this.handleSearchChange}
              testId="sent-opportunity-search-input"
              value={searchkey}
            />
            <Button
              onClick={() =>
                this.props.onStateChangeAction({ listCollapse: true })
              }
              className="collapseBtn"
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityListCollapse
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityListCollapse
              }
              testId="opportunity-sent-list-collapse-btn"
              testIdHelp="opportunity-sent-list-collapse-help-btn"
            />
          </Column.Head>
          <Column.Body>
            {isFetching ? (
              <LoadingMaskRow />
            ) : (
              <>
                {filterOpportunires?.length > 0 ? (
                  <BulletList testId="sent-opportunity-list">
                    {map(filterOpportunires, (opportunity) => (
                      <BulletList.ListItem
                        isActive={false}
                        isSelected={
                          selectedOpportunity &&
                          opportunity.RequestId === currentOpportunityId
                        }
                        onClick={() =>
                          this.handleOpportunityClick({
                            selectedOpportunity: opportunity,
                          })
                        }
                        key={opportunity.RequestId}
                        testId="sent-opportunity-list-item"
                      >
                        <label className="listLabel">
                          {opportunity.RequestName}
                        </label>
                        <CloseBtn
                          classes="close_btn_purple for-list"
                          testId="sent-opportunity-delete-btn"
                          onClick={(e) =>
                            this.handleOpportunityDelete({
                              selectedOpportunity: opportunity,
                              e,
                            })
                          }
                        />
                      </BulletList.ListItem>
                    ))}
                  </BulletList>
                ) : (
                  <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                )}
              </>
            )}
          </Column.Body>
        </Column>
        {isEmpty(selectedOpportunity) && (
          <Column collapse={isEmptyCollapsed} className="col-2">
            <Column.Collapsed
              collapse={isEmptyCollapsed}
              onClick={() =>
                this.props.onStateChangeAction({ isEmptyCollapsed: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleEmpty}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityEmptyExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityEmptyExpand
              }
              testId="opportunity-sent-detail-expand-btn"
              testIdHelp="opportunity-sent-detail-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive={false} />
              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({ isEmptyCollapsed: true })
                }
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunityEmptyCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunityEmptyCollapse
                }
                testId="opportunity-sent-detail-collapse-btn"
                testIdHelp="opportunity-sent-detail-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body className="flex">
              <EmptyInfo>{`${labels.SearcherOpportunityEmptyInfo}`}</EmptyInfo>
            </Column.Body>
          </Column>
        )}
        {!isEmpty(selectedOpportunity) && (
          <Column collapse={detailCollapse} className="col-1">
            <Column.Collapsed
              collapse={detailCollapse}
              onClick={() =>
                this.props.onStateChangeAction({ detailCollapse: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleDetail}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityDetailExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityDetailExpand
              }
              testId="opportunity-sent-detail-expand-btn"
              testIdHelp="opportunity-sent-detail-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive>
                {labels.SearcherOpportunityDraftDetailHeader}
              </Column.HeaderTitle>

              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ detailCollapse: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunityDetailCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunityDetailCollapse
                }
                testId="opportunity-sent-detail-collapse-btn"
                testIdHelp="opportunity-sent-detail-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body>
              {
                <OpportunityDetail
                  declinedRequests={declinedRequests}
                  acceptedRequests={acceptedRequests}
                  pendingRequests={pendingRequests}
                  selectedOpportunity={selectedOpportunity}
                  labels={labels}
                  onAcceptedResumes={this.handleAcceptedResumes}
                  onDeclinedResumes={this.handleDeclinedResumes}
                  onNoActionResumes={this.handleNoActionResumes}
                  selectedAction={selectedAction}
                  onSelectedActionChange={({ selectedAction }) =>
                    this.props.onStateChangeAction({
                      selectedAction,
                    })
                  }
                />
              }
            </Column.Body>
          </Column>
        )}
        {!hideShortList && !isEmpty(selectedOpportunity) && (
          <Column collapse={shortlistCollapse} className="col-1">
            <Column.Collapsed
              collapse={shortlistCollapse}
              onClick={() =>
                this.props.onStateChangeAction({ shortlistCollapse: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleShortlist}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityShortlistExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityShortlistExpand
              }
              testId="opportunity-sent-shortlist-expand-btn"
              testIdHelp="opportunity-sent-shortlist-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive>
                {labels.SearcherOpportunitySentSelectedShortlists}
              </Column.HeaderTitle>
              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ shortlistCollapse: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunityShortlistCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunityShortlistCollapse
                }
                testId="opportunity-sent-shortlist-collapse-btn"
                testIdHelp="opportunity-sent-shortlist-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body>
              {isFetchingShortlist ? (
                <LoadingMaskRow />
              ) : (
                <div>
                  <BulletList testId="searcher-sent-opportunity-short-list">
                    {map(selectedShortlists, (shortlist) => (
                      <BulletList.ListItem
                        isActive={false}
                        isSelected={
                          selectedShortlist &&
                          shortlist.ShortlistId ===
                            selectedShortlist.ShortlistId
                        }
                        onClick={() =>
                          this.handleSelectedShortlistClick({
                            selectedShortlist: shortlist,
                          })
                        }
                        key={shortlist.ShortlistId}
                        testId="selected-shortlist-item"
                      >
                        <label className="listLabel">
                          {shortlist.ShortlistName}
                        </label>
                      </BulletList.ListItem>
                    ))}
                  </BulletList>
                </div>
              )}
            </Column.Body>
          </Column>
        )}
        {(showResumeList ||
          (!isEmpty(selectedOpportunity) && !isEmpty(shortlistResumes))) && (
          <Column collapse={resumeListCollapse} className="col-2">
            <Column.Collapsed
              collapse={resumeListCollapse}
              onClick={() =>
                this.props.onStateChangeAction({ resumeListCollapse: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleShortlistResume}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistExpand
              }
              testId="opportunity-sent-resumelist-expand-btn"
              testIdHelp="opportunity-sent-resumelist-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive>{`${shortlistResumes &&
                shortlistResumes.length} ${
                labels.SearcherOpportunityDraftShortlistResumeHeading
              }`}</Column.HeaderTitle>

              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ resumeListCollapse: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistCollapse
                }
                testId="opportunity-sent-resumelist-collapse-btn"
                testIdHelp="opportunity-sent-resumelist-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body>
              {isFetchingShortlistResume ? (
                <LoadingMaskRow />
              ) : !isEmpty(shortlistResumes) ? (
                <ShortlistResumes
                  selectedResume={selectedResume}
                  selectedShortlist={selectedShortlist}
                  onResumeSelect={this.handleResumeSelect}
                  onResumeDelete={this.handleResumeDelete}
                  shortlistResumes={shortlistResumes}
                  handleSelectShortlist={this.handleSelectShortlist}
                />
              ) : (
                <div className="empty-shortlist" />
              )}
            </Column.Body>
          </Column>
        )}
        {shortlistResumes.length > 0 &&
          !isEmpty(selectedOpportunity) &&
          !isEmpty(selectedResume) && (
            <Column collapse={shortlistResumeCollapse} className="col-2">
              <Column.Collapsed
                collapse={shortlistResumeCollapse}
                onClick={() =>
                  this.props.onStateChangeAction({
                    shortlistResumeCollapse: false,
                  })
                }
                text={labels.SearcherOpportunityDraftViewTitleResumeDetail}
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailExpand
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailExpand
                }
                testId="opportunity-sent-resumedetail-expand-btn"
                testIdHelp="opportunity-sent-resumedetail-expand-help-btn"
              />
              <Column.Head>
                <Column.HeaderTitle isActive>Resume Detail</Column.HeaderTitle>

                <Button
                  className="collapseBtn"
                  onClick={() =>
                    this.props.onStateChangeAction({
                      shortlistResumeCollapse: true,
                    })
                  }
                  isHelpActive={isHelpActive}
                  tooltipPlace="left"
                  tooltipButton={
                    labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailCollapse
                  }
                  tooltipHelp={
                    labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailCollapse
                  }
                  testId="opportunity-sent-resumedetail-collapse-btn"
                  testIdHelp="opportunity-sent-resumedetail-collapse-help-btn"
                />
              </Column.Head>
              <Column.Body>
                {isFetchingShortlistResume ? (
                  <LoadingMaskRow />
                ) : (
                  !isEmpty(selectedResume) && (
                    <div>
                      {!isEmpty(selectedIpro) && (
                        <UserDetail
                          selectedUser={selectedIpro}
                          labels={this.props.labels}
                        />
                      )}
                      <ShortlistResumeDetail
                        resume={selectedResume}
                        showAvailableTitle={isEmpty(selectedIpro)}
                      />
                    </div>
                  )
                )}
              </Column.Body>
            </Column>
          )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  searcherOpportunitySent,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { IsFreelancer } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    isFreelancer: IsFreelancer,
    isHelpActive,
    ...searcherOpportunitySent,
  };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(SearcherOpportunitySent);
