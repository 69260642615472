import { ActionTypes } from "../../actions";

export const onStateChangeAction = ({
  showLinkedInImportView,
  selectedResume,
  selectedPDFResume,
  dialogMessage,
  getImgSrc,
  uploadPDFButtonDisable,
  dialogResumeUpload,
  isResumeImportCollapsed,
  fetchingResumes,
  detailCollapsed,
  importCollapsed,
  dialogResumeEdit,
  isPDFFileUploaded,
  PDFFileName
}) => dispatch => {
 
  showLinkedInImportView !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.SHOW_LINKEDIN_IMPORT_VIEW,
      payload: showLinkedInImportView
    });
  selectedResume !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.SELECTED_RESUME,
      payload: selectedResume
    });
  selectedPDFResume !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.SELECTED_PDF_RESUME,
      payload: selectedPDFResume
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.DIALOG_MESSAGE,
      payload: dialogMessage
    });
  getImgSrc !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.GET_IMG_SRC,
      payload: getImgSrc
    });
  uploadPDFButtonDisable !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.UPLOAD_PDF_BUTTON_DISABLE,
      payload: uploadPDFButtonDisable
    });
  dialogResumeUpload !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.DIALOG_RESUME_UPLOAD,
      payload: dialogResumeUpload
    });
  isResumeImportCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.IS_RESUME_IMPORT_COLLAPSED,
      payload: isResumeImportCollapsed
    });
  fetchingResumes !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.FETCHING_RESUMES,
      payload: fetchingResumes
    });
  detailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.DETAIL_COLLAPSED,
      payload: detailCollapsed
    });
  importCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.IMPORT_COLLAPSED,
      payload: importCollapsed
    });
  dialogResumeEdit !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.DIALOG_RESUME_EDIT,
      payload: dialogResumeEdit
    });
  isPDFFileUploaded !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.IS_PDF_FILE_UPLOADED,
      payload: isPDFFileUploaded
    });
  PDFFileName !== undefined &&
    dispatch({
      type: ActionTypes.RESUME.PDF_FILE_NAME,
      payload: PDFFileName
    });
};
