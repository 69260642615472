import { ActionTypes } from ".";
export const landingCandidateSearchAction = ({ searchCriteria }) => dispatch => {
  dispatch({
    type: ActionTypes.LANDINGFINDCANDIDATE,
    payload: searchCriteria
  });
};

export const landingResumeAction = ({ resumeData }) => dispatch => {
  dispatch({
    type: ActionTypes.LANDINGUPLOADRESUME,
    payload: resumeData
  });
};
