import { ActionTypes } from "../../../actions";
export const onStateChangeAction = ({
  isLoading,
  opportunityList,
  iprosList,
  selectedIpro,
  formCollapsed,
  detailCollapsed,
  dialogMessage,
  collaborationForm
}) => dispatch => {
  isLoading !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_COLLABORATION.IS_LOADING,
      payload: isLoading
    });
  opportunityList !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_COLLABORATION.OPPORTUNITY_LIST,
      payload: opportunityList
    });
  iprosList !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_COLLABORATION.IPROS_LIST,
      payload: iprosList
    });
  selectedIpro !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_COLLABORATION.SELECTED_IPRO,
      payload: selectedIpro
    });
  formCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_COLLABORATION.FORM_COLLAPSED,
      payload: formCollapsed
    });
  detailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_COLLABORATION.DETAIL_COLLAPSED,
      payload: detailCollapsed
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_COLLABORATION.DIALOG_MESSAGE,
      payload: dialogMessage
    });
  collaborationForm !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_COLLABORATION.COLLABORATION_FORM,
      payload: collaborationForm
    });
};
