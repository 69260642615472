import React, { Component } from "react";
import { connect } from "react-redux";
import { Messages } from "../../../utilities";
import { Button } from "../../../common";
import InputPassword from "../../../common/Input/InputPassword";
import { notificationAction } from "../../../actions";
import { changePasswordApi } from "./../settingsApi";
import { onStateChangeAction } from "../settingAction";

class ChangePassword extends Component {
  // state = {
  //   oldPassword: "",
  //   newPassword: "",
  //   confirmPassword: ""
  // };

  componentDidMount = () => {};

  handleFormFieldChange = e => {
    const { name, value } = e.target;
    if (name === "OldPassword") {
      this.props.OldPassCallback(false);
      this.props.onStateChangeAction({ oldPassword: value });
    }
    if (name === "NewPassword") {
      this.props.onStateChangeAction({ newPassword: value });
    }
    if (name === "ConfirmPassword") {
      this.props.onStateChangeAction({ confirmPassword: value });
    }
  };

  handleSubmitBtnClick = () => {
    const { oldPassword, newPassword, confirmPassword } = this.props;

    if (oldPassword.trim() === null || oldPassword.trim() === "") {
      const info = {
        message: this.props.labels.changePasswordOldPasswordRequried,
        status: "error"
      };
      this.props.notificationAction(info);
      return;
    }

    if (
      newPassword.trim() === null ||
      newPassword === "" ||
      newPassword.length < 6
    ) {
      const info = {
        message: this.props.labels.changePasswordNewPasswordInvalid,
        status: "error"
      };
      this.props.notificationAction(info);
    }

    if (confirmPassword.trim() === null || confirmPassword.trim() === "") {
      const info = {
        message: this.props.labels.changePasswordConfirmPasswordRequried,
        status: "error"
      };
      this.props.notificationAction(info);
      return;
    }

    if (newPassword !== confirmPassword) {
      const info = {
        message: this.props.labels.changePasswordMissMatchPassword,
        status: "error"
      };
      this.props.notificationAction(info);
      return;
    }

    var passwordObj = {
      OldPassword: oldPassword,
      NewPassword: newPassword,
      RepeatPassword: confirmPassword
    };

    changePasswordApi(passwordObj).then(result => {
      if (result.success) {
        const info = {
          message: this.props.labels.Password_Changed_Success,
          status: "success"
        };
        this.props.onStateChangeAction({ 
          newPassword: "",
          oldPassword: "",
          confirmPassword: "",
         });
        this.props.notificationAction(info);
        
      } else {
        this.props.OldPassCallback(true);
        const info = {
          message: this.props.labels
            .USER_SETTING_OLDPASSWORD_VALIDATION_MESSAGE,
          status: "error"
        };
        this.props.notificationAction(info);
        return;
      }
    });
  };

  render() {
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      invalidOldPassword,
      invalidNewPassword,
      invalidConfirmPassword,
      labels
    } = this.props;
    return (
      <div className="userSettings">
        <div className="form-row">
          <label className="form-label">
            {labels.USER_SETTING_CHANGE_PASSWORD_OLDPASS_LABEL}
          </label>
          <InputPassword
            name="OldPassword"
            className={`input-text ${invalidOldPassword ? "inValid" : ""}`}
            value={oldPassword}
            placeholder={
              labels.USER_SETTING_CHANGE_PASSWORD_OLDPASS_PLACEHOLDER
            }
            onChange={this.handleFormFieldChange}
            testId="input-pass-old-settings"
          />
        </div>

        <div className="form-row">
          <label className="form-label">
            {labels.USER_SETTING_CHANGE_PASSWORD_NEWPASS_LABEL}
          </label>
          <InputPassword
            name="NewPassword"
            className={`input-text ${invalidNewPassword ? "inValid" : ""}`}
            value={newPassword}
            placeholder={
              labels.USER_SETTING_CHANGE_PASSWORD_NEWPASS_PLACEHOLDER
            }
            onChange={this.handleFormFieldChange}
            testId="input-pass-new-settings"
          />
        </div>

        <div className="form-row">
          <label className="form-label">
            {labels.USER_SETTING_CHANGE_PASSWORD_CONFIRMPASS_LABEL}
          </label>
          <InputPassword
            name="ConfirmPassword"
            className={`input-text ${invalidConfirmPassword ? "inValid" : ""}`}
            value={confirmPassword}
            placeholder={
              labels.USER_SETTING_CHANGE_PASSWORD_CONFIRMPASS_PLACEHOLDER
            }
            onChange={this.handleFormFieldChange}
            testId="input-pass-confirm-settings"
          />
        </div>

        <Button
          className="SendButton"
          onClick={this.handleSubmitBtnClick}
          testId="btnsettingupdate"
          tooltipHelp="Submit"
          tooltipButton="Submit"
        >
          {labels.USER_SETTING_CHANGE_PASSWORD_SUBMIT_BUTTON}
        </Button>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel, setting }) => {
  const { labels } = systemLabel;
  return { labels, ...setting };
};
export default connect(
  mapStateToProps,
  { notificationAction, onStateChangeAction }
)(ChangePassword);
