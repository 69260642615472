import React from "react";
import { Typography, Avatar, Button } from "antd";
import classes from "./profile.module.scss";
import { Link } from "react-router-dom";
const { Text, Title } = Typography;

const Profile = ({ userDetails, labels, handlePrint }) => {
  const {
    CountryName,
    UserEmail,
    UserFirstname,
    ProfilePicture,
    UserLastname,
    UserPhonenumberValue,
  } = userDetails;
  return (
    <div className={` pt-3 main-profile`}>
      <div className="d-flex align-items-center gap-8">
        <Avatar
          className={`${classes.userAvatar} `}
          size={86}
          src={ProfilePicture || labels.NOT_AVAILABLE_LABEL}
        />
        <Title className={`mb-0 ${classes.resumeTitle}`} level={4}>
          {UserFirstname
            ? `${UserFirstname} ${UserLastname || ""}`
            : labels.NOT_AVAILABLE_LABEL}
        </Title>
      </div>
      <div className={`${classes.userInfo} mt-4`}>
        <div className={`${classes.userDetailItem}`}>
          <img width={20} src="assets/images/location.png" />{" "}
          <Text>{CountryName || labels.NOT_AVAILABLE_LABEL} </Text>
        </div>
        <div className={`${classes.userDetailItem}`}>
          <img width={20} src="assets/images/sms.png" />{" "}
          <Text className="ml-2">
            {UserEmail || labels.NOT_AVAILABLE_LABEL}
          </Text>
        </div>
        <div className={`${classes.userDetailItem}`}>
          <img width={20} src="assets/images/call.png" />{" "}
          <Text className="ml-2">
            {UserPhonenumberValue || labels.NOT_AVAILABLE_LABEL}
          </Text>
        </div>
      </div>
      <div className={`${classes.resumeButtons} mt-4`}>
        <Link to="/resume-edit">
          <Button className={`${classes.actionBtns} w-100`} type="primary">
            {labels?.ipro_dashboard_edit_resume_btn1_text}
          </Button>
        </Link>

        <Button className={classes.actionBtns} onClick={handlePrint}>
          {labels?.ipro_dashboard_edit_resume_btn2_text}
        </Button>
      </div>
    </div>
  );
};

export default Profile;
