import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import "./button.scss";
import { HelpTooltip, Tooltip } from "../Tooltip/Tooltip";

class Button extends Component {
  renderButton = () => {
    const { className, children, text, onClick, disabled, testId } = this.props;
    return (
      <button
        onClick={onClick}
        className={`button-primary ${className}`}
        disabled={disabled}
        test-data-id={testId}
      >
        {text}
        {children}
      </button>
    );
  };
  render() {
    const {
      isHelpActive,
      followCursor,
      tooltipButton,
      tooltipHelp,
      testIdHelp,
      mainClassName
    } = this.props;
    return (
      <div className={`button-wrapper ${mainClassName}`}>
        {isHelpActive ? (
          <>
            {this.renderButton()}
            <HelpTooltip content={tooltipHelp} testId={testIdHelp} />
          </>
        ) : tooltipButton ? (
          <Tooltip
            content={tooltipButton}
            trigger={!isMobile ? "mouseenter" : "focus"}
            followCursor={followCursor}
          >
            {this.renderButton()}
          </Tooltip>
        ) : (
          this.renderButton()
        )}
      </div>
    );
  }
}

Button.defaultProps = {
  className: "",
  mainClassName: "",
  followCursor: true
};

export default Button;
