import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  listCollapsed: false,
  detailCollapsed: false,
  replyCollapsed: false,
  isReply: false,
  messageForm: {},
  selectedMessage: {},
  messageList: [],
  filterMessages: [],
  dialogMessage: "",
  deletedId: null,
  isFetching: true,
  messageList: [],
  searchKey: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.MESSAGE_INBOX.LIST_COLLAPSED:
      return {
        ...state,
        listCollapsed: action.payload
      };
    case ActionTypes.MESSAGE_INBOX.DETAIL_COLLAPSED:
      return {
        ...state,
        detailCollapsed: action.payload
      };
    case ActionTypes.MESSAGE_INBOX.REPLY_COLLAPSED:
      return {
        ...state,
        replyCollapsed: action.payload
      };
    case ActionTypes.MESSAGE_INBOX.MESSAGE_FORM:
      return {
        ...state,
        messageForm: action.payload
      };
    case ActionTypes.MESSAGE_INBOX.DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: action.payload
      };
    case ActionTypes.MESSAGE_INBOX.SELECTED_MESSAGE:
      return {
        ...state,
        selectedMessage: action.payload
      };
    case ActionTypes.MESSAGE_INBOX.FILTER_MESSAGES:
      return {
        ...state,
        filterMessages: action.payload
      };
    case ActionTypes.MESSAGE_INBOX.IS_REPLY:
      return {
        ...state,
        isReply: action.payload
      };
    case ActionTypes.MESSAGE_INBOX.IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case ActionTypes.MESSAGE_INBOX.DELETED_ID:
      return {
        ...state,
        deletedId: action.payload
      };
    case ActionTypes.MESSAGE_INBOX.MESSAGE_LIST:
      return {
        ...state,
        messageList: action.payload
      };
    case ActionTypes.MESSAGE_INBOX.SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload
      };
    default:
      return state;
  }
};
