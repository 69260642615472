import React, { Component } from "react";
import { getForgotPasswordApi } from "../../leadPageApi";
import { isValidEmail, htmlParser } from "../../../../utilities/helpers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificationAction } from "../../../../actions";

class ForgotForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      invalidemail: false
    };
  }
  handleForgotPassword = () => {
    const { email } = this.state;
    const { labels } = this.props;
    if (!email || !isValidEmail(email)) {
      const info = {
        message: labels.FORGOT_PASSWORD_VALIDATION_ERROR_MESSAGE,
        status: "error"
      };
      this.props.notificationAction(info);
      this.setState({
        invalidemail: true
      });
      return;
    }
    getForgotPasswordApi(email)
      .then(response => {
        const status = response.success ? "success" : "error";
        const info = {
          message: response.message,
          status
        };
        this.props.notificationAction(info);
        return;
      })
      .catch(error => console.log(error));
  };
  handleChange = e => {
    const { name, value } = e.target;
    if (!isValidEmail(value)) {
      this.setState({
        [`invalid${name}`]: true,
        [name]: value
      });
      return;
    } else {
      this.setState({
        [`invalid${name}`]: false,
        [name]: value
      });
    }
  };
  render() {
    const { onClose, onSignInClick, labels } = this.props;
    const { email, invalidemail } = this.state;
    return (
      <div className="LoginForm">
        <form>
          <div className="login">
            <button className="close-btn" onClick={onClose} />
            <input
              type="text"
              name="email"
              className={`LoginInput ${
                this.state.invalidemail ? "inValid" : ""
              }`}
              placeholder="Email"
              onChange={this.handleChange}
              onBlur={this.handleChange}
              value={email}
            />
            <div className="LoginBtn">
              <input
                type="button"
                onClick={this.handleForgotPassword}
                className="LoginSubmit"
                value="Submit"
              />
            </div>
            <a className="Forgot" onClick={onSignInClick}>
              {htmlParser(labels.ALREADY_HAVE_AN_ACCOUNT_LABEL)}
            </a>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;

  return { labels };
};
const mapActionToProps = { notificationAction };
export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(ForgotForm));
