import React, { Component } from "react";
import "./companyDetail.scss";
import ImgSrc from "../../assets/images/company.svg";
import { Col, Row } from "antd";
import EmptyInfo from "../EmptyInfo/EmptyInfo";

class CompanyDetailNew extends Component {
  render() {
    const {
      selectedCompany,
      addressLabel,
      industryLabel,
      countryLabel,
      phoneLabel,
      webUrlLabel,
      vatLabel,
    } = this.props;
    return (
      <div className="new-company-detail-component">
        <div className="inner-company">
          {/* {!selectedCompany.UserCompanyId ? (
            <EmptyInfo>
              {"Select the opportunity from the list to see its details"}
            </EmptyInfo>
          ) : (
            <> */}
          <div className="image-user">
            {selectedCompany && selectedCompany?.Logo && (
              <img
                src={selectedCompany?.Logo || ImgSrc}
                className={
                  selectedCompany?.Logo ? "roundImg" : "emptyRoundImag"
                }
              />
            )}
          </div>
          {selectedCompany && selectedCompany?.CompanyName && (
            <p className="user-title"> {selectedCompany?.CompanyName}</p>
          )}

          {selectedCompany && selectedCompany?.CompanyAddress && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col flex="100px">
                <p className="user-detail"> {addressLabel} :</p>
              </Col>
              <Col flex="auto">
                <p className="user-detail">{selectedCompany?.CompanyAddress}</p>
              </Col>
            </Row>
          )}

          {selectedCompany && selectedCompany?.Industry && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col flex="100px">
                <p className="user-detail"> {industryLabel} :</p>
              </Col>
              <Col flex="auto">
                <p className="user-detail">
                  {selectedCompany?.Industry?.IndustryValue}
                </p>
              </Col>
            </Row>
          )}
          {selectedCompany && selectedCompany?.CompanyCountry && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col flex="100px">
                <p className="user-detail"> {countryLabel} :</p>
              </Col>
              <Col flex="auto">
                <p className="user-detail">
                  {selectedCompany?.CompanyCountry?.CountryName}
                </p>
              </Col>
            </Row>
          )}
          {selectedCompany &&
            selectedCompany?.UserPhonenumber &&
            selectedCompany?.UserPhonenumber?.UserPhonenumberValue && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="100px">
                  <p className="user-detail"> {phoneLabel} :</p>
                </Col>
                <Col flex="auto">
                  <p className="user-detail">
                    {selectedCompany?.UserPhonenumber?.UserPhonenumberValue}
                  </p>
                </Col>
              </Row>
            )}
          {selectedCompany && selectedCompany?.WebUrl && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col flex="100px">
                <p className="user-detail"> {webUrlLabel} :</p>
              </Col>
              <Col flex="auto">
                <p className="user-detail">
                  <a target="_blank" href={selectedCompany?.WebUrl}>
                    {selectedCompany?.WebUrl}
                  </a>
                </p>
              </Col>
            </Row>
          )}
          {selectedCompany && selectedCompany?.Vat && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col flex="100px">
                <p className="user-detail"> {vatLabel} :</p>
              </Col>
              <Col flex="auto">
                <p className="user-detail">{selectedCompany?.Vat}</p>
              </Col>
            </Row>
          )}
          {selectedCompany && selectedCompany?.Detail && (
            <Row
              gutter={[8, 12]}
              style={{ marginTop: "10px", flexFlow: "unset" }}
            >
              <Col flex="auto">
                <p className="user-detail">{selectedCompany?.Detail}</p>
              </Col>
            </Row>
          )}
          {/* </>
          )} */}
        </div>
      </div>
    );
  }
}

export default CompanyDetailNew;
