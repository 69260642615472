import React from "react";
import "./notification-circle.scss";

const NotificationCircle = ({ children, testId, className }) => {
  return (
    <div className={`newMessage ${className}`} test-data-id={testId}>
      {children}
    </div>
  );
};

NotificationCircle.defaultProps = {
  className: ""
};
export default NotificationCircle;
