import axios from "axios";
import { ApiUrl } from "../../api";
import { StorageService } from "../../../src/api/storage";

export const getUserProfileApi = () => {
  return axios.get(ApiUrl.Settings.GetUserProfile).then(({ data }) => {
    return data;
  });
};
export const getCountriesApi = () => {
  return axios.get(ApiUrl.Country.AllLocationsLookup).then(({ data }) => data);
};

export const updateUserProfileApi = fileData => {
  return axios
    .post(ApiUrl.Settings.UpdateUserProfile, fileData)
    .then(({ data }) => {
      return data;
    });
};

export const getUserPhoneNumberApi = () => {
  return axios.get(ApiUrl.Settings.GetUserPhoneNumbers).then(({ data }) => {
    return data;
  });
};

export const getUserEmailAddressApi = () => {
  return axios.get(ApiUrl.Settings.GetUserEmailAddress).then(({ data }) => {
    return data;
  });
};

export const updateUserProfileSettingApi = fileData => {
  return axios
    .post(ApiUrl.Settings.UpdateUserSettings, fileData)
    .then(({ data }) => {
      return data;
    });
};

export const updatePhoneNumberSettingApi = fileData => {
  return axios
    .post(ApiUrl.Settings.UpdatePhoneSettings, fileData)
    .then(({ data }) => {
      return data;
    });
};

export const updateEmailSettingApi = fileData => {
  return axios
    .post(ApiUrl.Settings.UpdateEmailSettings, fileData)
    .then(({ data }) => {
      return data;
    });
};

export const SaveSocialMediaLink = fileData => {
  return axios
    .post(ApiUrl.Settings.SaveSocialMediaLink, fileData)
    .then(({ data }) => {
      return data;
    });
};

export const deleteUserEmailApi = ({ emailId }) => {
  return axios
    .delete(ApiUrl.Settings.DeleteUserEmail({ emailId }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const deleteUserPhoneApi = ({ phoneId }) => {
  return axios
    .delete(ApiUrl.Settings.DeleteUserPhoneNumber({ phoneId }))
    .then(({ data }) => data)
    .catch(response => response);
};

export const changePasswordApi = fileData => {
  return axios
    .post(ApiUrl.Settings.ChangePassword, fileData)
    .then(({ data }) => {
      return data;
    });
};

export const logOutApi = () => {
  return axios.get(ApiUrl.Settings.Logout).then(({ data }) => {
    StorageService.clearAll();
    return data;
  });
};

export const getUserNotificationsApi = () => {
  return axios.get(ApiUrl.Settings.UserNotifications).then(({ data }) => {
    return data;
  });
};

export const getUserNotificationsUpdateApi = info => {
  return axios
    .put(ApiUrl.Settings.UserNotificationUpdate, info)
    .then(({ data }) => {
      return data;
    });
};

export const getSocialMediaApi = () => {
  return axios
    .get(`${ApiUrl.SocialMedia}?page=1&start=0&limit=25`)
    .then(({ data }) => data);
};
export const getUserSocialMediaLinks = () => {
  return axios.get(ApiUrl.Settings.UserSocialLink).then(({ data }) => {
    return data;
  });
};
export const GetUserDataForDashboard = () => {
  return axios.get(ApiUrl.Settings.GetUserDefaultPresentation).then(({ data }) => {
    return data;
  });
};