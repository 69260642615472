import React from "react";
import "./capsule-list.scss";

const CapsuleList = ({ children, className, testId }) => (
  <ul className={`round-circle-list ${className}`} test-data-id={testId}>
    {children}
  </ul>
);

const Capsule = ({ children, testId }) => (
  <li test-data-id={testId} className="round-circle-item">
    <div className="item-inner-wrapper">{children}</div>
  </li>
);

const Label = ({ children, testId }) => (
  <label className="form-label" test-data-id={testId}>{children}</label>
);

const Button = ({ testId, onClick, onFocus }) => (
  <button
    onClick={onClick}
    className="closeBtn"
    test-data-id={testId}
    onFocus={onFocus}
  />
);

CapsuleList.propType = {
  className: ""
};

CapsuleList.Capsule = Capsule;
CapsuleList.Label = Label;
CapsuleList.Button = Button;

export default CapsuleList;