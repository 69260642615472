import React from "react";
import { Typography } from "antd";
import Checklist from '../../../assets/images/checklist.png'
import ResumePopup from "../popups/ResumePopup/ResumePopup";

const { Title, Text } = Typography;

function ResumeWidget({ resumes, labels, userDetails, history, ...props }) {
  const { Profiles, Skills, Educations } = resumes;
  return (
    <div className="cards-new d-flex flex-column gap-16">
      <div className="d-flex flex-column align-items-center">
        <div>
          <img src={Checklist} alt="search" />
        </div>
        <Title level={3} style={{ margin: "10px 0px 0px 0px" }}>Your Resume</Title>
      </div>
      {Profiles && Profiles.length > 0 && (
        <div>
          <Text className="fnt-s-16">Current Role</Text>
          <div className="d-flex gap-8 mt-2 align-items-center" style={{ flexWrap: "wrap" }}>
            {Profiles.slice(0, 5).map((item, index) => (
              <button className="add-resume" key={index}>{item.ProfileValue}</button>
            ))}
          </div>
        </div>
      )}
      {Skills && Skills.length > 0 && (
        <div>
          <Text className="fnt-s-16">Your Skills</Text>
          <div className="d-flex gap-8 mt-2 align-items-center" style={{ flexWrap: "wrap" }}>
            {Skills.slice(0, 5).map((item, index) => (
              <button className="add-resume" key={index}>{item.SkillValue}</button>
            ))}
          </div>

        </div>
      )}
      {Educations && Educations.length > 0 && (
        <div>
          <Text className="fnt-s-16">Your Educations</Text>
          <div className="d-flex gap-8 mt-2 align-items-center" style={{ flexWrap: "wrap" }}>
            {Educations.map((item, index) => (
              <button className="add-resume" key={index}>{item.EducationValue}</button>
            ))}
          </div>

        </div>
      )}
      <div>
        <ResumePopup
          labels={labels}
          resumes={resumes}
          userDetails={userDetails}
        />
      </div>
    </div>

  );
}

export default ResumeWidget;
