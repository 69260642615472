import React, { Component } from "react";
import "./companydetail.scss";
import ImgSrc from "../../../assets/images/company.svg";

class CompanyDetail extends Component {
  render() {
    const {
      selectedCompany,
      addressLabel,
      industryLabel,
      countryLabel,
      phoneLabel,
      webUrlLabel,
      vatLabel
    } = this.props;
    return (
      <div className="company-detail-component">
        <div className="MsgWrapper">
          <div className="MsgSubject">
            <div className="company-image-wrap">
              {selectedCompany &&
                selectedCompany.Logo && (
                  <img
                    src={selectedCompany.Logo || ImgSrc}
                    className={
                      selectedCompany.Logo ? "roundImg" : "emptyRoundImag"
                    }
                  />
                )}
            </div>
            {selectedCompany &&
              selectedCompany.CompanyName && (
                <label className="company-name">
                  {selectedCompany.CompanyName}
                </label>
              )}
          </div>
          <div className="msgItemDiv">
            {selectedCompany &&
              selectedCompany.CompanyAddress && (
                <div className="msgItem">
                  <label className="labelName">{addressLabel}:</label>
                  <label className="answerLabel">
                    {selectedCompany.CompanyAddress}
                  </label>
                </div>
              )}

            {selectedCompany && selectedCompany.Industry &&
              <div className="msgItem">
                <label className="labelName">{industryLabel}:</label>
                <label className="answerLabel">
                  {selectedCompany.Industry.IndustryValue}
                </label>
              </div>}
            {selectedCompany && selectedCompany.CompanyCountry &&
              <div className="msgItem">
                <label className="labelName">{countryLabel}:</label>
                <label className="answerLabel">
                  {selectedCompany.CompanyCountry.CountryName}
                </label>
              </div>}
            {selectedCompany && selectedCompany.UserPhonenumber && selectedCompany.UserPhonenumber.UserPhonenumberValue &&
              <div className="msgItem">
                <label className="labelName">{phoneLabel}:</label>
                <label className="answerLabel">
                  {selectedCompany.UserPhonenumber.UserPhonenumberValue}
                </label>
              </div>}
            {selectedCompany && selectedCompany.WebUrl &&
              <div className="msgItem">
                <label className="labelName">{webUrlLabel}:</label>
                < label className="answerLabel">
                  <a target="_blank" href={selectedCompany.WebUrl}>
                    {selectedCompany.WebUrl}
                  </a>
                </label>
              </div>}
            {selectedCompany && selectedCompany.Vat && <div className="msgItem">
              <label className="labelName">{vatLabel}:</label>
              <label className="answerLabel">
                {selectedCompany.Vat}
              </label>
            </div>}
            {selectedCompany && selectedCompany.Detail && <div className="MsgDetailWrap">{selectedCompany.Detail}</div>}
          </div>
        </div>
      </div >
    );
  }
}

export default CompanyDetail;
