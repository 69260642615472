import React from "react";
import { Select, Typography } from "antd";
import '../../views/NewResumeSearch/newDesignSearch.scss'
import './style.scss'
const { Text } = Typography

const CommonSelect = ({ loading, options, value, placeholder, label, handleSearch, handleChange, searchParams, changesParams, search = true }) => {
    return (
        <div className="select-new">
            <Text className="label m-0">{label}</Text>
            <div className="animated">
                <Select
                    style={{ width: "100%", marginTop: "6px" }}
                    autoFocus
                    placeholder={placeholder}
                    onChange={(e, option) =>
                        handleChange(e, changesParams, option)
                    }
                    onSearch={(e) => {
                        if (search) {
                            handleSearch(e, searchParams);
                        }
                    }}
                    showSearch
                    bordered={false}
                    mode="multiple"
                    loading={loading}
                    value={value || []}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                    }
                    options={options || []}
                ></Select>
            </div>
        </div >
    )
}
export default CommonSelect