import React, { Component } from "react";
import { connect } from "react-redux";
import Collaboration from "../common/Collaboration";
import { notificationAction } from "../../../actions";
import NewCollaboration from "../common/newCollaboration";

class NewSearcherCollaborationInActive extends Component {
  render() {
    const {
      userId,
      labels,
      IsSearcher,
      notificationAction,
      isHelpActive,
      location
    } = this.props;
    const {
      SearcherAcceptedCollaborationSearchPlaceholder,
      SearcherAcceptedCollaborationDetailHeadingText,
      TooltipSearcherAcceptedCollaborationListExpand,
      TooltipSearcherAcceptedCollaborationListCollapse,
      HlpTooltipSearcherAcceptedCollaborationListExpand,
      HlpTooltipSearcherAcceptedCollaborationListCollapse,
      SearcherAcceptedCollaborationListColumnText,

      TooltipSearcherAcceptedCollaborationDetailExpand,
      TooltipSearcherAcceptedCollaborationDetailCollapse,
      HlpTooltipSearcherAcceptedCollaborationDetailExpand,
      HlpTooltipSearcherAcceptedCollaborationDetailCollapse,
      SearcherAcceptedCollaborationDetailColumnText,

      SearcherAcceptedCollaborationUserDetailHeaderText,
      TooltipSearcherAcceptedCollaborationUserDetailExpand,
      TooltipSearcherAcceptedCollaborationUserDetailCollapse,
      HlpTooltipSearcherAcceptedCollaborationUserDetailExpand,
      HlpTooltipSearcherAcceptedCollaborationUserDetailCollapse,
      SearcherAcceptedCollaborationUserDetailColumnText,

      SearcherAcceptedCollaborationUserNameLabel,
      SearcherAcceptedCollaborationPositionLabel,
      SearcherAcceptedCollaborationEmailLabel,
      SearcherAcceptedCollaborationPhoneLabel,
      SearcherAcceptedCollaborationWebUrlLabel,
      SearcherSentCollaborationStartDateLabel,
      SearcherSentCollaborationDurationLabel,
      SearcherSentCollaborationCompensationLabel,
      SearcherSentCollaborationCompanyLabel,
      SearcherSentCollaborationDescriptionLabel,
      SearcherSentCollaborationAcceptedLabel,
      SearcherSentCollaborationInterestOrNotLabel,
      SearcherSentCollaborationEmptyInfo,
      InfoSearcherCollaborationDeleted,
      InfoSearcherCollaborationClosed,
      InfoSearcherSentCollaborationDeleteConfirmationMsg,
      InfoSearcherSentCollaborationCloseConfirmationMsg,
      likeToRateCollaborationMessage,
    } = labels;
    return (
      <NewCollaboration
        IsSearcher={IsSearcher}
        isHelpActive={isHelpActive}
        notificationAction={notificationAction}
        isInActiveView
        url={`GetAllCollaborationByUserId?IsClosed=true`}
        // list section system label
        searchInputPlaceholder={SearcherAcceptedCollaborationSearchPlaceholder}
        toolTipExpandList={TooltipSearcherAcceptedCollaborationListExpand}
        helpTooltipExpandList={
          HlpTooltipSearcherAcceptedCollaborationListExpand
        }
        toolTipCollapseList={TooltipSearcherAcceptedCollaborationListCollapse}
        helpTooltipCollapseList={
          HlpTooltipSearcherAcceptedCollaborationListCollapse
        }
        listColumnText={SearcherAcceptedCollaborationListColumnText}
        // detail section system label
        detailHeadingText={SearcherAcceptedCollaborationDetailHeadingText}
        toolTipExpandDetail={TooltipSearcherAcceptedCollaborationDetailExpand}
        helpTooltipExpandDetail={
          HlpTooltipSearcherAcceptedCollaborationDetailExpand
        }
        toolTipCollapseDetail={
          TooltipSearcherAcceptedCollaborationDetailCollapse
        }
        helpTooltipCollapseDetail={
          HlpTooltipSearcherAcceptedCollaborationDetailCollapse
        }
        detailsColumnText={SearcherAcceptedCollaborationDetailColumnText}
        // selected user section system label
        userDetailHeadingText={
          SearcherAcceptedCollaborationUserDetailHeaderText
        }
        toolTipExpandUserDetail={
          TooltipSearcherAcceptedCollaborationUserDetailExpand
        }
        helpTooltipExpandUserDetail={
          HlpTooltipSearcherAcceptedCollaborationUserDetailExpand
        }
        toolTipCollapseUserDetail={
          TooltipSearcherAcceptedCollaborationUserDetailCollapse
        }
        helpTooltipCollapseUserDetail={
          HlpTooltipSearcherAcceptedCollaborationUserDetailCollapse
        }
        userDetailsColumnText={
          SearcherAcceptedCollaborationUserDetailColumnText
        }
        emptyCollaorationInfo={labels?.emptyCollaborationInfo}
        startDateLabel={SearcherSentCollaborationStartDateLabel}
        durationLabel={SearcherSentCollaborationDurationLabel}
        compensationLabel={SearcherSentCollaborationCompensationLabel}
        companyLabel={SearcherSentCollaborationCompanyLabel}
        descriptionLabel={SearcherSentCollaborationDescriptionLabel}
        acceptedCollaboration={SearcherSentCollaborationAcceptedLabel}
        selectInterest={SearcherSentCollaborationInterestOrNotLabel}
        onCollaborationDelete={this.handleCollaborationDelete}
        UserNameLabel={SearcherAcceptedCollaborationUserNameLabel}
        UserPositionLabel={SearcherAcceptedCollaborationPositionLabel}
        UserEmailLabel={SearcherAcceptedCollaborationEmailLabel}
        UserPhoneLabel={SearcherAcceptedCollaborationPhoneLabel}
        UserWebUrlLabel={SearcherAcceptedCollaborationWebUrlLabel}
        collaborationDeletedMsg={InfoSearcherCollaborationDeleted}
        collaborationClosedMsg={InfoSearcherCollaborationClosed}
        likeToRateCollaborationMessage={likeToRateCollaborationMessage}
        listCollapsedTestId={"listCollapsedTestId1"}
        listCollapsedHelpTestId={"listCollapsedTestId2"}
        listExpandTestId={"listCollapsedTestId3"}
        listExpandHelpTestId={"listCollapsedTestId4"}
        detailCollapsedTestId={"listCollapsedTestId5"}
        detailCollapsedHelpTestId={"listCollapsedTestId6"}
        detailExpandTestId={"listCollapsedTestId7"}
        detailExpandHelpTestId={"listCollapsedTestId8"}
        userDetailCollapsedTestId={"listCollapsedTestId9"}
        userDetailCollapsedHelpTestId={"listCollapsedTestId10"}
        userDetailExpandTestId={"listCollapsedTestId11"}
        userDetailExpandHelpTestId={"listCollapsedTestId12"}
        searchInputTestId="search-input"
        collaborationDeleteConfirmation={
          InfoSearcherSentCollaborationDeleteConfirmationMsg
        }
        collaborationCloseConfirmation={
          InfoSearcherSentCollaborationCloseConfirmationMsg
        }
        popupYesText={"Yes"}
        popupNoText={"No"}
        locationProp={location}
      />
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { UserId, IsFreelancer } = user;
  UserId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    userId: UserId,
    IsSearcher: !IsFreelancer,
    IsFreelancer,
    isHelpActive
  };
};

const actions = { notificationAction };
export default connect(
  mapStateToProps,
  actions
)(NewSearcherCollaborationInActive);
