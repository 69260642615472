import React, { Component } from "react";
import moment from "moment";
import { Button } from "../../../../../common";
import { ListItem } from "../../../../Tictell/views/components/ListItem";


class ExpenseList extends React.Component {
  render() {
    const { expenseList, onItemClick,updateExpenseSheetStatus,ExpenseSheetStatusId,isHelpActive,labels } = this.props;
    return (
      <React.Fragment>
        <div className="timelog-list">
          {expenseList &&
            expenseList.map(item => (
              <ListItem
                onClick={onItemClick}
                item={item}
                key={item.ExpenseId}
              >
                <div className="name-container">
                  <div>
                    <label>
                      {item.SpentOn && moment(item.SpentOn).format("ddd, MMM Do YYYY")}
                    </label>
                    <span className={`${item.ExpenseFiles.length > 0 ? "attachment-icon":""}`} test-data-id={"testId"} />
                    <label>{`${item.Amount} ${item.CurrencyName.Name} ${item.CurrencyName.Name != item.CollaborationCurrency ? `(${item.CollaborationCurrencyAmount} ${item.CollaborationCurrency})`:''}`}</label>
                  </div>
                  <label>{item.CategoryName.Title}</label>
                </div>
              </ListItem>
            ))}
        </div>
        {ExpenseSheetStatusId == 1  && 
            <Button
              testId={`reject-expense-btn`}
              mainClassName={'action-button-wrapper'}
              className={`reject-btn ${ExpenseSheetStatusId == 3 || ExpenseSheetStatusId == 2 ? "hidden":""}`}
              onClick={()=>updateExpenseSheetStatus(3)}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.snapshotRejectTimesheetBtn}
              testIdHelp={"reject-expense-btn-help"}
        />  }     
        {ExpenseSheetStatusId == 1  && 
          <Button
              testId={`approve-expense-btn`}
              mainClassName={'action-button-wrapper'}
              className={`approve-btn ${ExpenseSheetStatusId == 3 || ExpenseSheetStatusId == 2 ? "hidden":""}`}
              onClick={()=>updateExpenseSheetStatus(2)}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.snapshotAcceptTimesheetBtn}
              testIdHelp={"approve-expense-btn-help"}
            />
        }
        </React.Fragment>
    );
  }
}

export default ExpenseList;
