import { Button, Input, Modal, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import closeIcon from '../../../assets/images/Icons/close-new.png'
import ImageModal from '../../../assets/images/Icons/phone.png'
import { addUserPhoneNumberApi } from "../../../components/Phillip/phillipApi";
import { notificationAction } from "../../../actions";

const AddNewPhoneNumber = ({ open, setOpen, hideAddNumberModel, title, heading, getNumber }) => {
    const [error, setError] = useState(false);
    const [value, setValue] = useState("")
    const dispatch = useDispatch()

    const handleCancel = () => {
        setOpen({ show: false });
    }

    const handleAddNewNumber = () => {
        if (!value) {
            return setError(true)
        }
        addUserPhoneNumberApi({
            UserId: 0,
            UserPhonenumberValue: value,
        })
            .then((response) => {
                hideAddNumberModel()
                const info = {
                    message: response?.message,
                    status: "info",
                };
                setValue("")
                getNumber()
                dispatch(notificationAction(info))
            })
            .catch((response) => response);

    }

    return (
        <>
            <Modal
                zIndex={105}
                open={open}
                onCancel={() => {
                    hideAddNumberModel()
                }}
                centered
                footer={null}
                closable={false}
                className={`new-design-modal shortlist-modal `}
            >
                <div className="text-center header-modal">
                    <h5>{title}</h5>
                    <div
                        onClick={() => hideAddNumberModel()}
                        className="pointer"
                    >
                        <img className="close-icon" src={closeIcon} alt="" />
                    </div>
                </div>
                <div className="form">
                    <div className="images-icon">
                        <img src={ImageModal} alt="" />
                        <p className="paragraph">{heading}</p>
                    </div>
                    <div>
                        <Input
                            placeholder="000 000 000"
                            value={value || ""}
                            type="number"
                            autoFocus={true}
                            size="large"
                            onChange={(e) => {
                                e.persist();
                                setValue(e.target.value)
                                setError(false);
                            }}
                            style={
                                error
                                    ? {
                                        marginTop: "8px",
                                        border: "1px solid red",
                                    }
                                    : {
                                        marginTop: "8px",
                                    }
                            }
                        />
                    </div>
                    <div className="d-flex gap-16 justify-content-between">

                        <Button
                            type="primary"
                            block
                            size="large"
                            htmlType="submit"
                            className="login-form-button w-100  mt-4"
                            onClick={() => { handleAddNewNumber() }}
                        >
                            Add Number
                        </Button>
                    </div>
                </div>

            </Modal>
        </>
    );
};
export default AddNewPhoneNumber;
