import axios from "axios";
import { ApiUrl, StorageService } from "../../../../api";
import { ActionTypes } from "../../../../actions/ActionsTypes";

export const loginAuth = (loginModel) => (dispatch) => {
  return axios.post(ApiUrl.Account.Login, loginModel).then((response) => {
    const { data } = response;
    const { success } = data;
    if (success) {
      const user = data.items;
      const token = response.headers[".aspxauth"];
      StorageService.saveTokenAndUser({ token, user });
      dispatch({
        type: ActionTypes.LOGIN,
        payload: user,
      });
    }
    return data;
  });
};

export const redirectLoginAuth = (token) => (dispatch) => {
  let config = {
    headers: {
      ['.ASPXAUTH']: token,
    }
  }
  return axios.get(ApiUrl.User.GetUser, config ).then((response) => {
    const { data } = response;
    const { success } = data;
    if (success) {
      const user = data.items;
      StorageService.saveTokenAndUser({ token, user });
      dispatch({
        type: ActionTypes.LOGIN,
        payload: user,
      });
    }
    return data;
  });
};
