import React, { Component } from "react";
import { connect } from "react-redux";
import Search from "../Search/Search";
import { privateRoutes } from "../../Routes/routing";

class VisitorSearch extends Component {
  componentWillMount() {
    const { history, isAuthenticated } = this.props;
    //if (isAuthenticated) history.push(privateRoutes.dashboard.path);
  }

  render() {
    return (
      <div test-data-id="visitor-search-page">
        <Search
          isSaveSearchBtnDisable={false}
          isQuickOppBtnDisable={false}
          isCreateShortlistBtnDisable={false}
          isVisitor={true}
        />
      </div>
    );
  }
}
const mapStateToProps = ({
  systemLabel,
  navigation,
  userInfo: { isAuthenticated }
}) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, isAuthenticated };
};
export default connect(mapStateToProps)(VisitorSearch);
