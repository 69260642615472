import React, { useEffect } from "react";
import { EmptyInfo, LoadingMask, ConfirmDialog } from "../../common";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { notificationAction } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  getShortlistApi,
  postShortlistApi,
  getOppuntinitiesApi,
  getShortlistResumeApi,
  updateShortListName,
  removeResumesFromShortlistApi
} from "./newShortlistApi";
import "../NewResumeSearch/search.scss";
import { privateRoutes } from "../../Routes/routing";
import { withRouter } from "react-router-dom";
import { onStateChangeAction } from "./newShortlistAction";
import { Col, Dropdown, Grid, Input, Menu, Row, Select, Space, Typography } from "antd";
import DetailModal from "./component/detailModal";
import { useState } from "react";
import MobileFilter from "./component/mobileFilter.js/index.js";
import ShortListModal from "./createShortListModal";
import { deleteShortlistApi } from "./newShortlistApi";
import { includes, toLower, filter as filterArray } from "lodash";
import ResumeListComponent from "../../common/resumeResumeList/resumeList";
import ArrowLeft from "../../assets/images/Icons/arrow-left.png";
import UpdateTitleModal from "../../components/updateTitleModal/updateTitleModal";
import ResumeListComponentNewDesign from "../../common/resumeListComponentNewDesign/index.js";
import DeleteShortListModal from "./deleteShortListModal.js";
import { isMobile } from "react-device-detect";
const { useBreakpoint } = Grid;

const { Title } = Typography

const ShortList = ({ history }) => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const labels = useSelector((state) => state?.systemLabel?.labels);
  const globalStateShortlist = useSelector((state) => state?.newShortlist);
  const [deleteShortlist, setDeleteShortlist] = useState({ show: false });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [open, setOpen] = useState({ opportunity: false, shortList: false, saveSearch: false, });
  const [createModal, setCreateModal] = useState({ open: false, value: "", id: "", });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [active, setActive] = useState("");
  const [updateTitleModal, setUpdateTitleModal] = useState({ open: false });
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState({ show: false, data: "" });

  const [state, setState] = useState({
    checked: [],
    hasMore: false,
    scrollListHeight: "565px",
  });

  const handleCloseModal = () => {
    setShowModal((st) => ({
      ...st,
      show: false,
      data: "",
    }));
  };

  const getShortlist = () => {
    setIsLoading(true);
    getShortlistApi()
      .then((data) => {
        setUpdateTitleModal((st) => ({
          ...st,
          id: data?.items?.[0]?.ShortlistId,
          value: data?.items?.[0]?.ShortlistName,
        }));
        dispatch(
          onStateChangeAction({
            shortListResumeList: data?.items,
            filteredShortlist: data.items,
          })
        );
        if (globalStateShortlist?.selected == "") {
          handleSelectShortlist(data?.items?.[0]);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getOpportunities = () => {
    getOppuntinitiesApi()
      .then((data) => {
        setState((st) => ({
          ...st,
          opportunitiesList: data?.items,
        }));
      })
      .catch((err) => { });
  };

  const createNewShortlist = (id) => {
    const selectedResumesIds =
      state?.checked?.length == 0 ? [id] : state?.checked;
    const info = {
      ShortlistName: `New shortlist for ${selectedResumesIds.length
        } resume(s) at ${moment(new Date()).format("D MMMM YYYY")} at ${moment(
          new Date()
        ).format("LTS")}`,
      ResumeIdsList: selectedResumesIds,
    };
    return postShortlistApi(info)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  const handleRename = () => {
    updateShortListName({
      shortListId: globalStateShortlist?.selected?.ShortlistId,
      shortListName: createModal?.value,
    })
      .then((res) => {
        getShortlist();
        setCreateModal((st) => ({
          ...st,
          open: !st.open,
          id: "",
          value: "",
          title: "",
        }));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleUpdateTitle = () => {
    updateShortListName({
      shortListId: updateTitleModal?.id,
      shortListName: updateTitleModal?.value,
    })
      .then((res) => {
        getShortlist();
        setUpdateTitleModal({ open: false });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleCreateNewShorlistClick = () => {
    setIsLoading(true);
    postShortlistApi({
      ShortlistName: createModal?.value,
    })
      .then((data) => {
        if (data.success) {
          getShortlist();
          setCreateModal((st) => ({
            ...st,
            open: !st.open,
            id: "",
            value: "",
            title: "",
          }));
          const info = {
            message: labels.shortlistSuccessAdded,
            status: "success",
          };
          dispatch(notificationAction(info));
          setIsLoading(false);
          return;
        }
        const info = {
          message: data.message,
          status: "error",
        };
        dispatch(notificationAction(info));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    getShortlist();
    getOpportunities();
    window.view = "NEWSEARCH";
    window.actionName = "newSearch";
    if (history?.location?.pathname?.split("/")?.length > 2) {
      setUpdateTitleModal((st) => ({
        ...st,
        open: true,
        title: "Shortlist",
      }));
    }
    setActive(window?.location?.hash?.slice(1))
    window.addEventListener('resize', handleResize);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menuOpportunities = (single) => {
    return (
      <Menu
        style={{
          height: "300px",
          overflow: "auto",
          width: "290px",
        }}
        className="dropdown-shortlists"
      >
        <Menu.Item
          key="Newest"
          className="first-child-dropdown"
          onClick={() => {
            createNewShortlist(single?.ResumeId).then((data) => {
              sessionStorage.setItem("opportunity-new", true);
              history.push(privateRoutes.searcherCreateOpportunity.path);
            });
          }}
        >
          <span className="span-">Create New Opportunity</span>
          <PlusOutlined />
        </Menu.Item>
        <Menu.Divider />
        {state?.opportunitiesList?.map((single, index) => (
          <Menu.Item
            key={index}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
            onClick={() => {
              sessionStorage.setItem("opportunity", JSON.stringify(single));
              history.push(privateRoutes.searcherCreateOpportunity.path);
            }}
          >
            {single?.RequestName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const handleSelectShortlist = (item) => {
    setState((st) => ({ ...st, checked: [] }));
    setIsLoading(true);
    sessionStorage.setItem("shortList", JSON.stringify(item));
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        dispatch(
          onStateChangeAction({ shortListResume: data?.items, selected: item })
        );
        setIsLoading(false);
        if (
          screens?.xs == true ||
          (screens?.sm == true && screens?.md == false)
        ) {
          // setIsModalOpen(true);
          setIsMobileView(true)
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    const filteredShortlist = filterArray(
      globalStateShortlist.shortListResumeList,
      (opp) => includes(toLower(opp.ShortlistName), toLower(value))
    );
    dispatch(onStateChangeAction({ filteredShortlist: filteredShortlist }));
  };

  const handleYesClick = () => {
    deleteShortlistApi(deleteShortlist?.id)
      .then((data) => {
        setDeleteShortlist((st) => ({
          ...st,
          show: false,
          id: "",
        }));
        if (data.success) {
          if (
            deleteShortlist?.id == globalStateShortlist?.selected?.ShortlistId
          ) {
            dispatch(
              onStateChangeAction({ shortListResume: [], selected: null })
            );
          }
          getShortlist();
          const info = {
            message: labels.shortlistSuccessfullDeletion,
            status: "success",
          };
          dispatch(notificationAction(info));
        } else {
          console.log({ data })
          const info = {
            message: data.message,
            status: "error",
          };
          dispatch(notificationAction(info));
        }
      })
      .catch(() => {
        setDeleteShortlist((st) => ({
          ...st,
          show: false,
          id: "",
        }));
      });
  };

  const handleNoClick = () => {
    setDeleteShortlist((st) => ({
      ...st,
      show: false,
      id: "",
    }));
  };

  const handleMobileMenuClick = (e) => {
    if (e.key == 1) {
      const filterId = globalStateShortlist?.filteredShortlist?.find(
        (x) => x?.ShortlistId == globalStateShortlist?.selected?.ShortlistId
      );
      setCreateModal((st) => ({
        ...st,
        open: true,
        title: "Edit",
        value: filterId?.ShortlistName,
      }));
    }
    if (e.key == 2) {
      setDeleteShortlist((st) => ({
        ...st,
        show: true,
        id: globalStateShortlist?.selected?.ShortlistId,
      }));
    }
    if (e.key == 3) {
      if (state?.checked?.length > 0) {
        removeResumesFromShortlistApi(globalStateShortlist?.selected?.ShortlistId, state.checked)
          .then((data) => {
            if (data.success) {
              // Remove the deleted resumes from the state
              const updatedResumeList = globalStateShortlist?.shortListResume.filter(
                (resume) => !state.checked.includes(resume.ResumeId)
              );

              dispatch(onStateChangeAction({ shortListResume: updatedResumeList }));
              const info = {
                message: labels.resumesSuccessfullyRemoved,
                status: "success",
              };
              dispatch(notificationAction(info));
            } else {
              const info = {
                message: data.message,
                status: "error",
              };
              dispatch(notificationAction(info));
            }
          })
          .catch((err) => {
            const info = {
              message: labels.resumesRemovedErrorMessage,
              status: "error",
            };
            dispatch(notificationAction(info));
          });
      } else {
        const info = {
          message: labels.noResumesProvided,
          status: "error",
        };
        dispatch(notificationAction(info));
      }
    }
  };

  const getTabs = () => {
    return [
      {
        id: 1,
        label: labels?.find_professionals,
        active: window?.location?.hash == "#/resume-search",
        value: "/resume-search"
      },
      {
        id: 2,
        label: labels?.save_professionals,
        active: window?.location?.hash == "#/saved-searches",
        value: "/saved-searches"
      },
      {
        id: 2,
        label: labels?.SEARCHER_NAVIGATION_SHORTLIST_LABEL,
        active: window?.location?.hash == "#/shortlists",
        value: "/shortlists"
      }
    ]
  }


  return (
    <div className="search-page-new-design h-100">
      <PageWrapper className="">
        {isLoading && <LoadingMask text="" />}
        {showModal?.show == true && (
          <DetailModal
            setOpen={handleCloseModal}
            open={showModal}
            selected={state?.selected}
            menuOpportunities={menuOpportunities}
            component="shortlist"
          />
        )}
        <UpdateTitleModal
          open={updateTitleModal}
          setOpen={setUpdateTitleModal}
          handleUpdateTitle={handleUpdateTitle}
        />
        <ShortListModal
          open={createModal}
          setOpen={setCreateModal}
          handleCreateNew={handleCreateNewShorlistClick}
          handleRename={handleRename}
        />
        <DeleteShortListModal
          open={deleteShortlist}
          setOpen={setDeleteShortlist}
          handleOk={handleYesClick}
        />

        {isMobileView ?
          <div className="d-flex w-100 h-100" style={{ flexDirection: "column", overflow: "auto" }}>
            <div className="d-flex align-items-center gap-8 cursor-pointer"
              onClick={() => { setIsMobileView(false) }}
            >
              <div>
                <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
              </div>
              <Title level={5} className="m-0"> Shortlist Details</Title>
            </div>
            <div className="h-100 mt-3" style={{ overflow: "auto", background: "#fff", borderRadius: "12px" }}>
              <div className="resume-list h-100" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Title level={5} className="m-0 text-clamp" style={{ padding: "12px", borderBottom: "1px solid #f4f2fe" }}>{globalStateShortlist?.selected?.ShortlistName}
                </Title>
                <ResumeListComponentNewDesign
                  resumeList={globalStateShortlist?.shortListResume}
                  state={state}
                  selected={state}
                  setSelected={setState}
                  setShowModal={setShowModal}
                  opportunityDropdown={menuOpportunities}
                  name="shortlist"
                />
                <Row className="mt-3">
                  <div className="w-100 d-flex gap-16" style={{ padding: "12px", borderTop: "1px solid #f4f2fe" }}>
                    <div className="w-100">
                      <Dropdown
                        placement="bottomLeft"
                        menu={{
                          items: [
                            {
                              label: "Rename",
                              key: 1,
                              disabled: !globalStateShortlist?.selected?.ShortlistId,
                            },
                            {
                              label: "Delete",
                              key: 2,
                              disabled: !globalStateShortlist?.selected?.ShortlistId,
                            },
                            {
                              label: "Remove selected resumes",
                              key: 3,
                              disabled: !globalStateShortlist?.selected?.ShortlistId,
                            },
                          ],
                          onClick: handleMobileMenuClick,
                        }}
                        trigger={["click"]}
                      >
                        <button className="button-last-footer w-100"
                        >
                          {`Edit Shortlist`}
                        </button>
                      </Dropdown>
                    </div>
                    {globalStateShortlist?.shortListResume?.length > 0 && (
                      <div className="w-100">
                        <Dropdown
                          overlay={menuOpportunities()}
                          trigger={["click"]}
                          open={open?.opportunity}
                          onOpenChange={() => {
                            if (state?.checked?.length == 0) {
                              const info = {
                                message:
                                  labels.InfoIShortlistResumeListSelectionError,
                                status: "error",
                              };
                              dispatch(notificationAction(info));
                              return;
                            }
                            setOpen((st) => ({
                              ...st,
                              opportunity: !st.opportunity,
                            }));
                          }}
                        >
                          <button className="button-footer-opportunity w-100">
                            {`Bulk Invite (${state?.checked?.length || 0})`}
                          </button>
                        </Dropdown>
                      </div>
                    )}
                  </div>

                </Row>
              </div>
            </div>
          </div>
          :
          <div className="new-design-search w-100 d-flex flex-column gap-16">
            <div className="tabs-header-col mb-0">
              <div className="d-flex gap-16 justify-content-between align-items-center">
                <div className="d-flex gap-16 align-items-center main-header">
                  {windowWidth < 767 ?
                    <Select
                      className="dropdown-callooration"
                      size="medium"
                      bordered={false}
                      onChange={(e) => {
                        setActive(e)
                        history.push(`${e}`)
                      }}
                      value={active}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={getTabs() || []}
                    />
                    :
                    <>
                      {getTabs()?.map(single =>
                        <button className="tab-btn-coll"
                          key={single?.id}
                          style={{ background: single?.active ? "#8E81F5" : "", color: single?.active ? "#fff" : "#000" }}
                          onClick={() => {
                            history.push(single?.value)
                          }}
                        >
                          {single?.label}
                        </button>
                      )}
                    </>
                  }
                </div>
              </div>
            </div>
            <Row className="h-100 new-design-search w-100" >
              <Col xs={24} md={7} className="shortlist-lsit-section">
                <div className="h-100 d-flex flex-column">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "12px",
                      borderBottom: "1px solid #F3F3F3"
                    }}
                  >
                    <Input
                      placeholder={labels.ShortlistSearchPlacehoder}
                      size="medium"
                      bordered={false}
                      style={{ border: "1px solid #F3F3F3" }}
                      onChange={(e) => {
                        handleSearchChange(e);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      overflow: "auto",
                      padding: "12px",
                      flex: 1
                    }}
                  >
                    {globalStateShortlist?.filteredShortlist?.length > 0 ? (
                      <Space size={[6, 6]} wrap className="short-list-new">
                        {globalStateShortlist?.filteredShortlist.map((item) => (
                          <div
                            onClick={() => handleSelectShortlist(item)}
                            key={item.ShortlistId}
                            className={`${globalStateShortlist?.selected?.ShortlistId ==
                              item?.ShortlistId
                              ? "background-shortlist short-list-item pointer"
                              : "short-list-item pointer"
                              }`}

                          >
                            <div>
                              <label style={{ fontSize: "14px" }}>
                                {item.ShortlistName}
                              </label>
                              {item?.UpdatedOn &&
                                <p style={{ fontSize: "12px", marginBottom: 0, marginTop: '5px' }}>{moment(item?.UpdatedOn)?.format("DD-MM-YYYY hh:mm a")}</p>
                              }
                            </div>
                          </div>
                        ))}
                      </Space>
                    ) : (
                      <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                    )}
                  </div>
                  <div style={{ padding: "12px", borderTop: "1px solid #F4F2FE" }}>
                    <button className="button-last-footer w-100"
                      onClick={() => {
                        setCreateModal((st) => ({ ...st, open: true, title: "Create" }));
                      }}
                    >
                      {`Create New Shortlist`}
                    </button>
                  </div>
                </div>
              </Col>

              <Col md={17} xs={0} offset={0.5} className="h-100">
                <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                  <div style={{ padding: "16px", borderBottom: "1px solid #f4f2fe" }}>
                    <Row gutter={[24, 4]}>
                      <Col xs={24} >
                        <div className="d-flex justify-content-between gap-8 align-items-center">
                          <Title level={5} className="m-0 text-clamp flex-1">{globalStateShortlist?.selected?.ShortlistName}
                            {globalStateShortlist?.selected?.UpdatedOn &&
                              <span style={{ color: "#00000080" }}> {`(Created ${moment(globalStateShortlist?.selected?.UpdatedOn)?.format("DD-MM-YYYY hh:mm a")})`}
                              </span>}
                          </Title>
                          <div>
                            <Title level={5} className="m-0">{state?.checked?.length || 0} Selected</Title>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <ResumeListComponentNewDesign
                    resumeList={globalStateShortlist?.shortListResume}
                    state={state}
                    selected={state}
                    setSelected={setState}
                    setShowModal={setShowModal}
                    opportunityDropdown={menuOpportunities}
                    name="shortlist"
                  />
                  <Row className="footer-button-">
                    <Row className="w-100" style={{ justifyContent: "flex-end" }}>
                      <Space size={[12, 8]}>
                        <Dropdown
                          placement="bottomLeft"
                          menu={{
                            items: [
                              {
                                label: "Rename",
                                key: 1,
                                disabled: !globalStateShortlist?.selected?.ShortlistId,
                              },
                              {
                                label: "Delete",
                                key: 2,
                                disabled: !globalStateShortlist?.selected?.ShortlistId,
                              },
                              {
                                label: "Remove selected resumes",
                                key: 3,
                                disabled: !globalStateShortlist?.selected?.ShortlistId,
                              },
                            ],
                            onClick: handleMobileMenuClick,
                          }}
                          trigger={["click"]}
                        >
                          <button className="button-last-footer w-100"
                          >
                            {`Edit Shortlist`}
                          </button>
                        </Dropdown>
                        {globalStateShortlist?.shortListResume?.length > 0 && (

                          <Dropdown
                            overlay={menuOpportunities()}
                            trigger={["click"]}
                            open={open?.opportunity}
                            onOpenChange={() => {
                              if (state?.checked?.length == 0) {
                                const info = {
                                  message:
                                    labels.InfoIShortlistResumeListSelectionError,
                                  status: "error",
                                };
                                dispatch(notificationAction(info));
                                return;
                              }
                              setOpen((st) => ({
                                ...st, opportunity: !st.opportunity,
                              }));
                            }}
                          >
                            <button className="button-footer-opportunity">
                              {`Bulk Invite (${state?.checked?.length || 0})`}
                            </button>
                          </Dropdown>
                        )}
                      </Space>
                    </Row>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        }
      </PageWrapper>
    </div>

  );
};

export default withRouter(ShortList);
