import React, { Component } from "react";
import { connect } from "react-redux";
import { htmlParser } from "../../../../utilities";
import "./mobile-apps.scss";
import Img1 from "../../../../assets/images/snapshot/sc1.png";
import Img2 from "../../../../assets/images/snapshot/sc2.png";
import Img3 from "../../../../assets/images/snapshot/sc3.png";
import Img4 from "../../../../assets/images/snapshot/sc4.png";

import headsup from "../../../../assets/images/svg/headsup.svg";
import phiilip from "../../../../assets/images/svg/phillip.svg";
import nextstep from "../../../../assets/images/svg/nextstep.png";
import tictell from "../../../../assets/images/svg/13.png";
import snapshot from "../../../../assets/images/svg/snapshot.svg";

class Professional extends Component {
  render() {
    const { labels } = this.props;
    return (
      <div className="mobile-apps mainContent lightBg">
        <section className="contentContainer">
          <h1 className="proHeading">{labels.Section6MobileAppTitle}</h1>
          <div className="mobileAppsPanel">
            <ul className="tabList">
              <li className="tabItem">
                <img className="tabImg" src={headsup} alt="img" />
                <div className="tabContent">
                  <h5>{labels.Section6MobileAppHeadsupTitle}</h5>
                  <h6>{htmlParser(labels.Section6MobileAppHeadsupDescription)}</h6>
                </div>
              </li>
              <li className="tabItem">
                <img className="tabImg tictell" src={nextstep} alt="img" />
                <div className="tabContent">
                  <h5>{labels.Section6MobileAppNextStepTitle}</h5>
                  <h6>{htmlParser(labels.Section6MobileAppNextStepDescription)}</h6>
                </div>
              </li>
              <li className="tabItem">
                <img className="tabImg" src={snapshot} alt="img" />
                <div className="tabContent">
                  <h5>{labels.Section6MobileAppSnapshotTitle}</h5>
                  <h6>{htmlParser(labels.Section6MobileAppSnapshotDescription)}</h6>
                </div>
              </li>
              <li className="tabItem">
                <img className="tabImg tictell" src={tictell} alt="img" />
                <div className="tabContent">
                  <h5>{labels.Section6MobileTictellTitle}</h5>
                  <h6>{htmlParser(labels.Section6MobileAppTictellDescription)}</h6>
                </div>
              </li>
              <li className="tabItem">
                <img className="tabImg" src={phiilip} alt="img" />
                <div className="tabContent">
                  <h5>{labels.Section6MobileAppPhillipRecruiterTitle}</h5>
                  <h6>{htmlParser(labels.Section6MobileAppPhillipRecruiterDescription)}</h6>
                </div>
              </li>
              <li className="tabItem">
                <img className="tabImg" src={phiilip} alt="img" />
                <div className="tabContent">
                  <h5>{labels.Section6MobileAppPhillipIproTitle}</h5>
                  <h6>{htmlParser(labels.Section6MobileAppPhillipIproDescription)}</h6>
                </div>
              </li>
            </ul>
          </div>
          <div className="colored-circles">
            <div className="item blue"></div>
            <div className="item green"></div>
            <div className="item lightPurple"></div>
            <div className="item yellow"></div>
            <div className="item orange"></div>
            <div className="item red"></div>
            <div className="item lightPurple"></div>
            <div className="item mediumPurple"></div>
            <div className="item lightGreen"></div>
          </div>
          <div className="mobileAppsSCPanel">
            <ul className="tabList">
              <li className="tabItem">
                <img className="tabImg" src={Img1} alt="img" />
              </li>
              <li className="tabItem">
                <img className="tabImg" src={Img2} alt="img" />
              </li>
              <li className="tabItem">
                <img className="tabImg" src={Img3} alt="img" />
              </li>
              <li className="tabItem">
                <img className="tabImg" src={Img4} alt="img" />
              </li>
            </ul>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(Professional);
