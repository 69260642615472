import React, { Component } from "react";
import LogoSrc from "../../../../assets/images/logo.png";
import WhiteLogoSrc from "../../../../assets/images/logoWhite.png";
import TopbarSrc from "../../../../assets/images/topbar.png";
import { connect } from "react-redux";
import "./Footer.scss";
import { Link } from "react-router-dom";
class Footer extends Component {
  render() {
    const {
      footerMenu,
      labels
    } = this.props;
    return (
      <footer className="landing-footer" id="footer">
        {footerMenu && footerMenu.IsActive && (
          <div className="footerMenuContainer">
            <div className="footer-item">
              <h1 className="footerMenuHeading">{labels.footerMenuHeading1}</h1>
              <ul className="footerMenuList">
                <li className="tabItem">
                  <Link to="/cms/162">{labels.footerMenuSubHeading1}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/154">{labels.footerMenuSubHeading2}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/152">{labels.footerMenuSubHeading3}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/156">{labels.footerMenuSubHeading4}</Link>
                </li>
              </ul>
            </div>
            <div className="footer-item">
              <h1 className="footerMenuHeading">{labels.footerMenuHeading2}</h1>
              <ul className="footerMenuList">
                <li className="tabItem">
                  <Link to="/cms/150">{labels.footerMenuSubHeading5}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/40">{labels.footerMenuSubHeading6}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/8">{labels.footerMenuSubHeading7}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading8}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading9}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading10}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading11}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading12}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading13}</Link>
                </li>
              </ul>
            </div>
            <div className="footer-item">
              <h1 className="footerMenuHeading">{labels.footerMenuHeading3}</h1>
              <ul className="footerMenuList">
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading14}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading15}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading16}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading17}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading18}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading19}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading20}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading21}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading22}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading23}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading24}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading25}</Link>
                </li>
              </ul>
            </div>
            <div className="footer-item">
              <h1 className="footerMenuHeading">{labels.footerMenuHeading4}</h1>
              <ul className="footerMenuList">
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading26}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading27}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading28}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading29}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading30}</Link>
                </li>
              </ul>
            </div>
            <div className="footer-item">
              <h1 className="footerMenuHeading">{labels.footerMenuHeading5}</h1>
              <ul className="footerMenuList">
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading31}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading32}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading33}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading34}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading35}</Link>
                </li>
              </ul>
            </div>
            <div className="footer-item">
              <h1 className="footerMenuHeading">{labels.footerMenuHeading6}</h1>
              <ul className="footerMenuList">
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading36}</Link>
                </li>
                <li className="tabItem">
                  <Link to="/cms/163">{labels.footerMenuSubHeading37}</Link>
                </li>
              </ul>
            </div>
          </div>
        )}
        <div className="Logo">
          <img className="loginScreen" src={WhiteLogoSrc} alt="logo" />
          <img className="otherScreen" src={LogoSrc} alt="logo" />
        </div>
        <div className="rights">&copy; All rights reserved to Prodoo 2021 </div>
        <div className="BottomBar">
          <img className="bottomBarImg" src={TopbarSrc} alt="img" />
        </div>
      </footer>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return {
    labels,
  };
};
export default connect(mapStateToProps)(Footer);
