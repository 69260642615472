import React, { Component } from "react";
import { connect } from "react-redux";
import "./Search.scss";
import {
  getUserProfileApi,
} from "../../../Settings/settingsApi";
import { getAllProfileLookupApi } from "../../../VisitorSearch/visitorApi";
import Select from "react-select";
import {
  landingCandidateSearchAction
} from "../../../../actions/landingActions";

class Search extends Component {
  state = {
    selectedProfile: null,
  };

  getUserProfile = () => {
    this.setState({ isFetching: true });
    getUserProfileApi().then((response) => {
      if (response.success) {
        this.setState({
          selectedProfile: response.items,
          isFetching: false,
        });
      }
    });
  };

  handleFormSelectChange = (event) => {
    if (!event) return;
    const { selectedProfile } = this.state;
    const updated =  {
      ...selectedProfile,
      ProfileId: event.ProfileId,
      ProfileValue: event.ProfileValue
    }; 
    this.setState({
      selectedProfile:updated
    });
    this.props.landingCandidateSearchAction({
      searchCriteria: {
        Profiles: [updated],
        Skills:[],
        Certifications:[],
        Keywords:[],
        Countries:[],
        Languages:[],
        Industries:[],
        Educations:[],
      },
    });
  };

  render() {
    const { labels, onSearchClick, countries } = this.props;
    const { selectedProfile } = this.state;
    return (
      <div className="landing-search-component">
        <Select
          name="CountryId"
          className="select-input"
          value={(selectedProfile && selectedProfile.ProfileId) || ""}
          placeholder={labels.SearchBar}
          onChange={this.handleFormSelectChange}
          options={countries}
          clearable={false}
        />

        <a className="SearchIcon" onClick={onSearchClick}>
          &nbsp;
        </a>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  let { ProfileIdbyVisitor } = user;
  return {
    labels,
    ProfileIdbyVisitor,
  };
};
export default connect(
  mapStateToProps,
  { landingCandidateSearchAction }
)(Search);
