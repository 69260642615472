import React, { Component } from "react";
import moment from "moment";
import { Button, EmptyInfo } from "../../../../common";
import { ListItem } from "../components/ListItem";

class TimelogList extends React.Component {
  renderStatusName(id) {
    switch (id) {
      case 1:
        return this.props.labels.TictellStatusNamePending;
      case 2:
        return this.props.labels.tictellStatusNameApproved;
      case 3:
        return this.props.labels.tictellStatusNameRejected;
      case 4:
        return "";
    }
  }
  render() {
    const {
      timelogList,
      onItemClick,
      handleTimelogDelete,
      handleAddNewTimelog,
      selectedDate,
      handleSelectedDateChange,
      handleSendTimesheet,
      TimeSheetStatusId,
      labels,
      isHelpActive,
      selectedCollaboration,
      startDate,
      endDate,
    } = this.props;
    return (
      <React.Fragment>
        <div className="tictell-list-wrapper">
          {timelogList && timelogList.length > 0 ? (
            <div className="timelogs-list">
              {timelogList &&
                timelogList.map((item) => (
                  <ListItem
                    onClick={onItemClick}
                    item={item}
                    onDelete={handleTimelogDelete}
                    key={item.TimeLogId}
                    timeSheetStatusId={TimeSheetStatusId}
                  >
                    <span></span>
                    <div className="name-container">
                      <div>
                        <label>
                          {item.Date &&
                            moment(item.Date).format("ddd, MMM Do YYYY")}
                        </label>
                      </div>
                      <label>{item.Duration}</label>
                    </div>
                    <label className="currency-label">{`${item.Amount.toFixed(
                      2
                    )} ${item.Currency}`}</label>
                    {(TimeSheetStatusId == 3 || TimeSheetStatusId == 4) && (
                      <div
                        test-data-id={`timelog-delete-btn`}
                        onClick={() => handleTimelogDelete(item)}
                        className="close_btn"
                      >
                        <i className="fa fa-times-circle" />
                      </div>
                    )}
                  </ListItem>
                ))}
            </div>
          ) : (
            <EmptyInfo>{labels.Tictell_Empty_Message}</EmptyInfo>
          )}
        </div>
        <Button
          mainClassName={"action-button-wrapper"}
          testId={`sendTimesheetBtn`}
          className={`send-btn status-${TimeSheetStatusId} ${
            TimeSheetStatusId == 4 && (!timelogList || timelogList.length < 1)
              ? "disabled-class"
              : ""
          }`}
          onClick={handleSendTimesheet}
          isHelpActive={isHelpActive}
          tooltipHelp={labels.tictell_send_timesheet}
          tooltipButton={labels.ToolTipSendTimesheet}
          testIdHelp={"sendTimesheetBtn-help"}
        >
          <i className="fa fa-thumbs-up"></i>
        </Button>
        {!!selectedCollaboration &&
        moment(startDate).isSameOrAfter(
          moment(selectedCollaboration.StartDate).startOf("w")
        ) &&
        moment(endDate).isSameOrBefore(
          moment(selectedCollaboration.EndDate).endOf("w")
        ) ? (
          <Button
            mainClassName={"action-button-wrapper"}
            testid={`add-newLog-btn`}
            className={`add-btn ${
              TimeSheetStatusId == 1 || TimeSheetStatusId == 2
                ? "disabled-class"
                : ""
            }`}
            onClick={handleAddNewTimelog}
            isHelpActive={isHelpActive}
            testIdHelp={"add-newLog-btn-help"}
            tooltipHelp={labels.tictell_add_timelog}
            tooltipButton={labels.ToolTipAddTimelog}
          >
            <i className="fa fa-plus" />
          </Button>
        ) : (
          <Button
            mainClassName={"action-button-wrapper"}
            testid={`add-newLog-btn`}
            className={`help-btn ${
              TimeSheetStatusId == 1 || TimeSheetStatusId == 2
                ? "disabled-class"
                : ""
            }`}
            onClick={handleAddNewTimelog}
            isHelpActive={isHelpActive}
            testIdHelp={"add-newLog-btn-help"}
            tooltipHelp={
              "This collaboration is expired you can not add timelog here"
            }
            tooltipButton={
              "This collaboration is expired you can not add timelog here"
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default TimelogList;
