import React, { Component } from "react";
import { Button } from "../../common";
import "./panel.scss";
const Title = ({ children }) => <div className="heading">{children}</div>;
const Heading = ({ children }) => (
  <div className="panel-heading">{children}</div>
);
const Body = ({ children }) => <div className="panel-body">{children}</div>;
const Add = ({
  children,
  onClick,
  isHelpActive,
  tooltipPlace,
  tooltipButton,
  tooltipHelp,
  testId,
  testIdHelp
}) => (
  <Button
    className="panel-add AddBtn"
    onClick={onClick}
    isHelpActive={isHelpActive}
    tooltipPlace={tooltipPlace}
    tooltipButton={tooltipButton}
    tooltipHelp={tooltipHelp}
    testId={testId}
    testIdHelp={testIdHelp}
  >
    {children}
  </Button>
);

class Panel extends Component {
  static Heading = Heading;
  static Body = Body;
  static Add = Add;
  static Title = Title;
  render() {
    const { children, theme, className } = this.props;
    return (
      <div className={`panel theme-${theme} ${className}`}>{children}</div>
    );
  }
}
Panel.defaultProps = {
  theme: "",
  className: ""
};
export default Panel;
