import React, { Component } from "react";
import { BulletList } from "../../../common";
import moment from "moment";
import "./feedbacklist.scss";
class FeedbackList extends Component {
  renderDate = date => {
    return moment(date).format("D/M/YYYY H:mm:ss");
  };
  render() {
    const {
      feedbackList,
      selectedFeedback,
      handleDeleteFeedback,
      handleFeedbackClick
    } = this.props;
    return (
      <BulletList className="feedback-list-component">
        {feedbackList.map(item => (
          <BulletList.ListItem
            onClick={() => handleFeedbackClick(item.FeedbackId)}
            key={item.FeedbackId}
            isSelected={selectedFeedback.FeedbackId === item.FeedbackId}
          >
            <div className="listLabel">{item.user.Title}</div>
            <div className="dateItem">
              {this.renderDate(item.user.DeteTime)}
            </div>
            <BulletList.Close
              onClick={e => {
                handleDeleteFeedback(item.FeedbackId);
                e.stopPropagation();
              }}
            />
          </BulletList.ListItem>
        ))}
      </BulletList>
    );
  }
}
export default FeedbackList;
