import React, { Component } from "react";
import { connect } from "react-redux";
import { List, SearchInput } from "../../common";
import { map, isEmpty } from "lodash";
import "./custom-select.scss";
import { htmlParser } from "../../utilities";

const replaceSubString = ({ textValue = "", searchText = "", value }) => {
  searchText = searchText.trim().toLowerCase();
  if(typeof(textValue)  == "object")
    return;
  let searchTextIndex = textValue.toLowerCase().indexOf(searchText);
  if (searchTextIndex > -1) {
    // if complete word, highlight all
    return (
      "<span>" +
      textValue.slice(0, searchTextIndex) +
      "</span>" +
      "<span class='bold-text'>" +
      textValue.slice(searchTextIndex, searchTextIndex + searchText.length) +
      "</span>" +
      "<span>" +
      textValue.slice(searchTextIndex + searchText.length) +
      "</span>"
    );
  } else {
    // if some words, highlight all

    let searchTextArray = searchText.split(" ");

    let textValueArray = textValue.split(" ");
    let textToReturn = "";

    // if highlight each word, one by one
    for (let i = 0; i < textValueArray.length; i++) {
      let matchFound = false;
      let matchArray = new Array();

      // if one word contains multiple search words
      for (let j = 0; j < searchTextArray.length; j++) {
        let searchTextWordIndex = textValueArray[i]
          .toLowerCase()
          .indexOf(searchTextArray[j]);
        if (searchTextWordIndex > -1) {
          if (!isEmpty(searchTextArray[j])) {
            matchArray.push(searchTextArray[j]);
            matchFound = true;
          }
        }
      }

      if (matchFound) {
        if (matchArray.length == 1) {
          let searchTextWordIndex = textValueArray[i]
            .toLowerCase()
            .indexOf(matchArray[0]);
          textToReturn +=
            "<span>" +
            textValueArray[i].slice(0, searchTextWordIndex) +
            "</span>" +
            "<span class='bold-text'>" +
            textValueArray[i].slice(
              searchTextWordIndex,
              searchTextWordIndex + matchArray[0].length
            ) +
            "</span>" +
            "<span>" +
            textValueArray[i].slice(
              searchTextWordIndex + matchArray[0].length
            ) +
            "</span>";
        } else {
          // if one word contains multiple search words like, website -> webs site
          matchArray.sort(function(a, b) {
            // ASC on basis of first start
            return textValueArray[i].indexOf(a) - textValueArray[i].indexOf(b);
          });

          let textValueArrayWord = textValueArray[i];

          for (let m = 0; m < matchArray.length; m++) {
            let searchTextMatchIndex = textValueArray[i]
              .toLowerCase()
              .indexOf(matchArray[m]);
            if (searchTextMatchIndex > -1) {
              let matchLength = matchArray[m].length;

              if (textValueArrayWord != textValueArray[i]) {
                let excludeLength = textValueArray[i].indexOf(
                  textValueArrayWord
                );

                if (searchTextMatchIndex < excludeLength) {
                  matchLength =
                    matchLength - (excludeLength - searchTextMatchIndex);
                  searchTextMatchIndex = 0;
                } else {
                  searchTextMatchIndex = searchTextMatchIndex - excludeLength;
                }
              }

              textToReturn +=
                "<span>" +
                textValueArrayWord.slice(0, searchTextMatchIndex) +
                "</span>" +
                "<span class='bold-text'>" +
                textValueArrayWord.slice(
                  searchTextMatchIndex,
                  searchTextMatchIndex + matchLength
                ) +
                "</span>";

              textValueArrayWord = textValueArrayWord.substr(
                searchTextMatchIndex -
                  0 +
                  (searchTextMatchIndex + matchLength - searchTextMatchIndex)
              );

              if (m == matchArray.length - 1) {
                // if array ends, append remaining text in start
                textToReturn += "<span>" + textValueArrayWord + "</span>";
              }
            }
          }
        }
      } else {
        textToReturn += "<span>" + textValueArray[i] + "</span>";
      }

      if (i < textValueArray.length - 1) {
        textToReturn += "<span> </span>";
      } // add space at end if not last word
    }
    return textToReturn;
  }
};

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchListHover: false,
      isSearchFocus: false,
      options: props.options,
      searchText: ""
    };
  }

  handleItemClick = ({ item }) => {
    const { onItemSelect } = this.props;
    onItemSelect(item);
  };

  handleSearchBlur = () => {
    const { searchListHover } = this.state;
    if (searchListHover) return;
    this.setState({ isSearchFocus: false });
  };

  handleFocus = () => {
    this.setState({ isSearchFocus: true });
  };

  handleChange = e => {
    const { value = "" } = e.target;
    this.setState(
      { searchText: value, options: [] },
      this.props.onChange(value)
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      const { searchText } = this.state;
      let { options } = this.props;
      options = options.map(item => {
        const { label, value } = item;
        const newItem = replaceSubString({
          textValue: label,
          searchText,
          value
        });
        item.label = <span>{htmlParser(newItem)}</span>;
        return item;
      });
      this.setState({ options });
    }
  }
  render() {
    const { isSearchFocus, options } = this.state;
    const {
      isFetching,
      placeholder,
      onSearchTabChange,
      className,
      labels,
      inputTestId
    } = this.props;
    return (
      <label
        onClick={onSearchTabChange}
        className={`custom-select ${className}`}
      >
        <SearchInput
          placeholder={placeholder}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleSearchBlur}
          testId={inputTestId}
        />
        <span className={isFetching ? "select-loading" : ""} />
        <span
          onClick={isSearchFocus ? this.handleSearchBlur : this.handleFocus}
          className={isSearchFocus ? "carrot-up" : "carrot-down"}
        />
        {isSearchFocus && (
          <List className="custom-select-list">
            {options.length > 0 ? (
              map(options, (item, index) => (
                <List.ListItem
                  isEffect={false}
                  key={item.value}
                  onClick={() => this.handleItemClick({ item })}
                  onMouseEnter={() => this.setState({ searchListHover: true })}
                  onMouseLeave={() => this.setState({ searchListHover: false })}
                  testId={`custom-select-${index}-value`}
                >
                  {item.label}
                </List.ListItem>
              ))
            ) : (
              <List.ListItem>
                {isFetching
                  ? labels.CUSTOM_SELECT_LOAGING
                  : labels.CUSTOM_SELECT_SEARCHING_LABEL}
              </List.ListItem>
            )}
          </List>
        )}
      </label>
    );
  }
}

CustomSelect.defaultProps = {
  className: ""
};

const mapStateToProps = ({ systemLabel }) => ({
  labels: systemLabel.labels
});
export default connect(
  mapStateToProps,
  {}
)(CustomSelect);
