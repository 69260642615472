import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  faqsList: [],
  aboutProdooList: [],
  listCollapsed: false,
  detailCollapsed: false,
  isFetching: true,
  selectedItem: {
    ConfigurationId: 1,
  },
  expanded: { about: true },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.ABOUT.FAQS_LIST:
      return {
        ...state,
        faqsList: action.payload,
      };
    case ActionTypes.ABOUT.ABOUT_PRODOO_LIST:
      return {
        ...state,
        aboutProdooList: action.payload,
      };
    case ActionTypes.ABOUT.LIST_COLLAPSED:
      return {
        ...state,
        listCollapsed: action.payload,
      };
    case ActionTypes.ABOUT.DETAIL_COLLAPSED:
      return {
        ...state,
        detailCollapsed: action.payload,
      };
    case ActionTypes.ABOUT.SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };
    case ActionTypes.ABOUT.IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case ActionTypes.ABOUT.EXPANDED:
      return {
        ...state,
        expanded: action.payload,
      };

    default:
      return state;
  }
};
