import React, { Component } from "react";
import { connect } from "react-redux";
import Network from "./Network.js";
import { notificationAction } from "../../../src/actions";

class InvitationsNetwork extends Component {
    render() {
        const {
            labels,
        } = this.props;
        const {
            iProOpportunityNewDetailBtnAccept,
            iProOpportunityNewDetailBtnDecline
        } = labels;
        return (
            <Network
                isInvitationsView = {true}
                BtnAccept={iProOpportunityNewDetailBtnAccept}
                BtnDecline={iProOpportunityNewDetailBtnDecline}
            />
        );
    }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation }) => {
    const { labels } = systemLabel;
    return {
        labels,
    };
};

const actions = { notificationAction };
export default connect(
    mapStateToProps,
    actions
)(InvitationsNetwork);
