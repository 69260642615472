import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  isFetching: false,
  isLoading: false,
  opportunities: [],
  filterOpportunires: [],
  formCollapsed: false,
  shortlistCollapsed: false,
  resumeListCollapsed: false,
  resumeDetailCollapsed: false,
  selectedResume: {},
  isSearchFocus: false,
  searchListHover: false,
  selectedShortlist: null,
  selectedShortlists: [],
  filterShortlists: [],
  shortlists: [],
  dialogMessage: "",
  yesClickCount: 0,
  savedSearches: [],
  userCompanies: [],
  selectedSaveSearch: null,
  selectedCompany: null,
  languages: [],
  isFetchingShortlist: false,
  isFetchingShortlistResume: false,
  opportunitySaveLoading: false,
  allLocationsList: [],
  allLocationsList: [],
  allCurrenciesList: [],
  searchKey: "",
  shortlistResumes: [],
  selectedOpportunity: {
    invalidRequestName: "",
    invalidStartDate: "",
    Countries: [],
    Language: [],
    selectedSavedSearch: "",
    FeeCurrencyType: "",
    DurationType: "Weeks",
    Duration: "4",
    invalidDurationType: "",
    invalidCompanyId: "",
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SHORTLIST_RESUMES:
      return {
        ...state,
        shortlistResumes: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.OPPORTUNITIES:
      return {
        ...state,
        opportunities: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.FILTER_OPPORTUNIRES:
      return {
        ...state,
        filterOpportunires: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.FORM_COLLAPSED:
      return {
        ...state,
        formCollapsed: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SHORTLIST_COLLAPSED:
      return {
        ...state,
        shortlistCollapsed: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.RESUME_LIST_COLLAPSED:
      return {
        ...state,
        resumeListCollapsed: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.RESUME_DETAIL_COLLAPSED:
      return {
        ...state,
        resumeDetailCollapsed: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_RESUME:
      return {
        ...state,
        selectedResume: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.IS_SEARCH_FOCUS:
      return {
        ...state,
        isSearchFocus: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SEARCH_LIST_HOVER:
      return {
        ...state,
        searchListHover: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_SHORTLIST:
      return {
        ...state,
        selectedShortlist: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_SHORTLISTS:
      return {
        ...state,
        selectedShortlists: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.FILTER_SHORTLISTS:
      return {
        ...state,
        filterShortlists: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SHORTLISTS:
      return {
        ...state,
        shortlists: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.YES_CLICK_COUNT:
      return {
        ...state,
        yesClickCount: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SAVED_SEARCHES:
      return {
        ...state,
        savedSearches: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.USER_COMPANIES:
      return {
        ...state,
        userCompanies: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_SAVE_SEARCH:
      return {
        ...state,
        selectedSaveSearch: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.IS_FETCHING_SHORTLIST:
      return {
        ...state,
        isFetchingShortlist: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER
      .IS_FETCHING_SHORTLIST_RESUME:
      return {
        ...state,
        isFetchingShortlistResume: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.OPPORTUNITY_SAVE_LOADING:
      return {
        ...state,
        opportunitySaveLoading: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.ALL_LOCATIONS_LIST:
      return {
        ...state,
        allLocationsList: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.ALL_LANGUAGES_LIST:
      return {
        ...state,
        allLanguagesList: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.ALL_CURRENCIES_LIST:
      return {
        ...state,
        allCurrenciesList: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload,
      };
    case ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_OPPORTUNITY:
      return {
        ...state,
        selectedOpportunity: action.payload,
      };

    default:
      return state;
  }
};
