import React, {Component} from "react";
import {PageWrapper} from "../../components";
import {connect} from "react-redux";
import find from "lodash/find";
import {Column, List, Button} from "../../common";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody
} from "react-accessible-accordion";
import "./about.scss";
import {getFaqsApi, getAboutProdooListApi} from "./aboutApi";
import {Messages} from "../../utilities";
import ReactHtmlParser from "react-html-parser";
import {onStateChangeAction} from "./aboutAction";

class About extends Component {
    // state = {
    //   faqsList: [],
    //   aboutProdooList: [],
    //   selectedItem: {
    //     ConfigurationId: 1
    //   }
    // };

    componentDidMount() {
        this.props.isFetching && this.getAboutList();
    }

    getAboutList = () => {
        getFaqsApi({type: 1}).then(data => {
            this.props.onStateChangeAction({
                faqsList: data.items,
                isFetching: false
            });
        });
        getAboutProdooListApi().then(data => {
            this.props.onStateChangeAction({aboutProdooList: data.items});
            this.handleSelectAboutProdooList(1);
        });
    };
    handleSelectFaqList = id => {
        const {faqsList} = this.props;
        let selectedItem = find(faqsList, {QuestionId: id});
        selectedItem = {
            ...selectedItem,
            title: selectedItem.Title,
            description: selectedItem.Answer,
            id: selectedItem.QuestionId
        };
        this.props.onStateChangeAction({selectedItem});
    };

    handleSelectAboutProdooList = id => {
        const {aboutProdooList} = this.props;
        let selectedItem = find(aboutProdooList, {ConfigurationId: id});
        selectedItem = {
            ...selectedItem,
            title: selectedItem.ConfigName,
            description: selectedItem.ConfigValue,
            id: selectedItem.ConfigurationId
        };
        this.props.onStateChangeAction({selectedItem});
    };
    handleExpanded = name => {
        const {expanded} = this.props;
        this.props.onStateChangeAction({
            expanded: {
                ...expanded,
                [name]: !expanded[name]
            }
        });
    };

    render() {
        const {labels, isHelpActive} = this.props;
        const {
            faqsList,
            selectedItem,
            aboutProdooList,
            listCollapsed,
            detailCollapsed,
            expanded
        } = this.props;
        return (
            <PageWrapper className="about-page">
                <Column collapse={listCollapsed} className="col-1 w-100">
                    <Column.Collapsed
                        text={labels.ViewTitleAboutList}
                        onClick={() =>
                            this.props.onStateChangeAction({listCollapsed: false})
                        }
                        tooltipButton={labels.ToolTipAboutExpandList}
                        isHelpActive={isHelpActive}
                        tooltipHelp={labels.HlpTooltipAboutExpandListButton}
                        tooltipPlace="left"
                    />
                    <Column.Head className>
                        <div className="heading">{labels.ViewTitleAboutList}</div>
                        <Button
                            className="collapseBtn"
                            tooltipButton={labels.ToolTipAboutCollaspeList}
                            tooltipHelp={labels.HlpTooltipAboutCollapseListButton}
                            tooltipPlace="left"
                            isHelpActive={isHelpActive}
                            onClick={() =>
                                this.props.onStateChangeAction({listCollapsed: true})
                            }
                        />
                    </Column.Head>
                    <Column.Body>
                        <Accordion>
                            <AccordionItem expanded={expanded.about}>
                                <AccordionItemTitle
                                    onClick={() => {
                                        this.handleExpanded("about");
                                    }}
                                >
                                    {labels.ABOUT_ABOUTPRODOO_HEADING}
                                    <div className="accordion__arrow"/>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <List>
                                        {aboutProdooList.map(item => (
                                            <List.ListItem
                                                onClick={() =>
                                                    this.handleSelectAboutProdooList(item.ConfigurationId)
                                                }
                                                isSelected={
                                                    item.ConfigurationId === selectedItem.ConfigurationId
                                                }
                                                key={item.ConfigurationId}
                                            >
                                                {item.ConfigName}
                                            </List.ListItem>
                                        ))}
                                    </List>
                                </AccordionItemBody>
                            </AccordionItem>
                        </Accordion>
                        <Accordion>
                            <AccordionItem expanded={expanded.faq}>
                                <AccordionItemTitle
                                    onClick={() => {
                                        this.handleExpanded("faq");
                                    }}
                                >
                                    {labels.ABOUT_FAQ_HEADING}
                                    <div className="accordion__arrow"/>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <List>
                                        {faqsList.map(item => (
                                            <List.ListItem
                                                onClick={() =>
                                                    this.handleSelectFaqList(item.QuestionId)
                                                }
                                                isSelected={item.QuestionId === selectedItem.QuestionId}
                                                key={item.QuestionId}
                                            >
                                                {item.Title}
                                            </List.ListItem>
                                        ))}
                                    </List>
                                </AccordionItemBody>
                            </AccordionItem>
                        </Accordion>
                    </Column.Body>
                </Column>
                <Column collapse={detailCollapsed} className="col-2 w-100">
                    <Column.Collapsed
                        onClick={() =>
                            this.props.onStateChangeAction({detailCollapsed: false})
                        }
                        text={labels.ViewTitleAboutDetail}
                        tooltipButton={labels.ToolTipAboutExpandDetail}
                        isHelpActive={isHelpActive}
                        tooltipHelp={labels.HlpTooltipAboutExpandDetailButton}
                        tooltipPlace="left"
                    />
                    <Column.Head>
                        <div className="heading">{selectedItem.title}</div>
                        <Button
                            className="collapseBtn"
                            tooltipButton={labels.ToolTipAboutCollaspeDetail}
                            tooltipHelp={labels.HlpTooltipAboutCollapseDetailButton}
                            tooltipPlace="left"
                            isHelpActive={isHelpActive}
                            onClick={() =>
                                this.props.onStateChangeAction({detailCollapsed: true})
                            }
                        />
                    </Column.Head>
                    <Column.Body className="description">
                        {ReactHtmlParser(selectedItem.description)}
                    </Column.Body>
                </Column>
            </PageWrapper>
        );
    }
}

const mapStateToProps = ({systemLabel, navigation, about}) => {
    const {labels} = systemLabel;
    const {isHelpActive} = navigation;
    return {labels, isHelpActive, ...about};
};
export default connect(
    mapStateToProps,
    {onStateChangeAction}
)(About);
