import React, { forwardRef } from "react";
import moment from "moment";
import { Divider, Typography, Avatar, Row, Col, Button } from "antd";
import classes from "./index.module.scss";
import "./ResumeDownload.scss";
import Profile from "../../components/Profile";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";

const { Text, Title } = Typography;

const NoItems = () => <p>No items available</p>;

const getColorAccordingToLevelId = (levelId) => {
  switch (levelId) {
    case 1:
      return {
        background: "#fff6e4",
        border: "1px solid #F6AA3A",
      };
    case 2:
      return {
        background: "#cee9ff",
        border: "1px solid #3BA2F6",
      };
    case 3:
      return {
        background: "#e0fff9",
        border: "1px solid #04CAA7",
      };
    default:
      break;
  }
};

const levels = { "1": "Beginner", "2": "Proficient", "3": "Expert" };

const educationTypes = {
  "1": "School",
  "3": "Diploma",
  "4": "Graduation",
  "6": "Master",
  "7": "Doctorate",
  "11": "Bachelor",
};

export const Resume = forwardRef(({ labels, userDetails, resumes }, ref) => {
  const {
    CountryName,
    UserEmail,
    UserFirstname,
    ProfilePicture,
    UserLastname,
    UserPhonenumberValue,
  } = userDetails;
  const {
    Profiles,
    Skills,
    Educations,
    Certifications,
    ResumeExperience,
  } = resumes;

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: "Ipro-Resume",
  });

  return (
    <div ref={ref} className={`${classes.cvRoot} printPadding form`}>
      {/* <div className={`d-flex justify-content-between ${classes.resumeHeader}`}>
        <Profile labels={labels} userDetails={userDetails} widthclass={false} />
        <div className={`${classes.logoLight} logoPrint`}>
          <img
            src="/assets/images/light-logo.png"
            alt="LogoWhite"
            width={200}
            height={50}
          />
        </div>
      </div> */}
      {/* <Divider /> */}
      <Row className="resume-print-main">
        <Col md={8} className="column-first"> <Profile labels={labels} userDetails={userDetails} widthclass={false}
          handlePrint={handlePrint}
        /></Col>
        <Col md={16} className="column-second">
          <div className=" d-flex gap-16 ">
            <div className=" d-flex align-items-center ">
              <div className="legends" style={{ background: "#F7B149" }}></div>
              <p>Beginner</p>
            </div>
            <div className=" d-flex align-items-center">
              <div className="legends" style={{ background: "#3BA2F6" }}></div>
              <p>Proficient</p>
            </div>
            <div className=" d-flex align-items-center">
              <div className="legends" style={{ background: "#00CFAA" }}></div>
              <p>Expert</p>
            </div>
          </div>
          <Divider />
          <div>
            <Text className={classes.title}>Role</Text>
            {Profiles.length ? (
              <div className="d-flex flex-wrap flex-direction-row align-items-center mt-3 gaps">
                {Profiles.map((role, i) => (
                  <div
                    key={i}
                    className={`${classes.badge} widthNOne`}
                    style={getColorAccordingToLevelId(role.ExperienceLevel)}
                  >
                    <Text className={classes.badgeText}> {role.ProfileValue}</Text>

                    {/* <Text
                      className={classes.subText}
                      style={{
                        color: getColorAccordingToLevelId(
                          role.ExperienceLevel
                        )?.border.split(" ")[2],
                        display: "block",
                      }}
                    >
                      {levels[role.ExperienceLevel]}
                    </Text> */}
                  </div>
                ))}
              </div>
            ) : (
              <NoItems />
            )}
          </div>

          <Divider />

          <div>
            <Text className={classes.title}>Skills</Text>
            {Skills.length ? (
              <div className="d-flex flex-wrap align-items-center mt-3 gaps">
                {Skills.map((skill, i) => (
                  <div
                    key={i}
                    className={`${classes.badge} widthNOne`}
                    style={getColorAccordingToLevelId(skill.ExperienceLevel)}
                  >
                    <Text className={classes.badgeText}>{skill.SkillValue}</Text>
                    {/* <Text
                      className={classes.badge1Text}
                      style={{
                        color: getColorAccordingToLevelId(
                          skill.ExperienceLevel
                        )?.border.split(" ")[2],
                        display: "block",
                      }}
                    >
                      {levels[skill.ExperienceLevel]}
                    </Text> */}
                  </div>
                ))}
              </div>
            ) : (
              <NoItems />
            )}
          </div>

          <Divider />

          <Text className={classes.title}>Education</Text>
          {Educations.length ? (
            <div
              className={`${classes.propertiesBox} d-flex flex-wrap align-items-center`}
            >
              {Educations.map((education, i) => (
                <div key={i} className={classes.otherDetails}>
                  <div className="align-items-center">
                    <p className={classes.heading}>
                      {educationTypes[education.EducationTypeId]}
                    </p>
                    <Divider type="vertical" className="verticalDivider" />
                    <p className={classes.heading}> {education.EducationYear}</p>
                  </div>
                  <p>{education.EducationValue}</p>
                </div>
              ))}
            </div>
          ) : (
            <NoItems />
          )}

          <Divider />

          <Text className={classes.title}>Certification</Text>
          {Certifications.length ? (
            <div
              className={`${classes.propertiesBox} d-flex flex-wrap align-items-center`}
            >
              {Certifications.map((certification, i) => (
                <div key={i} className={classes.otherDetails}>
                  <p className={classes.heading}>
                    {certification.CertificationValue}
                  </p>
                  <p className={classes.text}>
                    {moment(certification.CertificationDate).format("YYYY")}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <NoItems />
          )}
          <Divider />

          <Text className={classes.title}>Experience</Text>
          {ResumeExperience.length ? (
            <div
              className={`${classes.propertiesBox} d-flex flex-wrap align-items-center`}
            >
              {ResumeExperience.map((re, i) => (
                <div key={i} className={classes.otherDetails}>
                  <div className="align-items-center">
                    <p className={classes.heading}>{re.ProfileValue}</p>
                    <Divider type="vertical" className="verticalDivider" />
                    <p className={classes.heading}>
                      {moment(re.StartDate).format("YYYY")}-
                      {re.EndDate ? moment(re.EndDate).format("YYYY") : "Present"}
                    </p>
                  </div>
                  <p>{re.CompanyWorked}</p>
                </div>
              ))}
              <div className={`${classes.resumeButtons} mt-2 w-100`}>
                <Link to="/resume-edit" className="w-100">
                  <Button className={`${classes.actionBtns} w-100`} type="primary">
                    {labels?.ipro_dashboard_edit_resume_btn1_text}
                  </Button>
                </Link>

                <Button className={`${classes.actionBtns} w-100`} onClick={handlePrint}>
                  {labels?.ipro_dashboard_edit_resume_btn2_text}
                </Button>
              </div>
            </div>
          ) : (
            <NoItems />
          )}</Col>
      </Row>

    </div>
  );
});
