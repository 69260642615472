import axios from "axios";
import { ApiUrl } from "../../api/apiUrls";

export const feedbackApi = () => {
  return axios.get(ApiUrl.Feedback.GetAllFeedbacks).then(({ data }) => {
    return data;
  });
};

export const feedbackSubmitApi = ({ content, title, imgSrc }) => {
  return axios
    .post(ApiUrl.Feedback.FeedbackSubmit, {
      Content: content,
      Title: title,
      Img: imgSrc
    })
    .then(({ data }) => {
      return data;
    });
};

export const feedbackDeleteApi = ({ id }) => {
  return axios
    .delete(ApiUrl.Feedback.DeleteFeedback, { data: { FeedbackId: id } })
    .then(({ data }) => {
      return data;
    });
};
