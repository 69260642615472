import React, { Component } from "react";
import "./companylist.scss";
import { EmptyInfo, List } from "../../../common";
import ImgSrc from "../../../assets/images/company.svg";
import { CloseBtn } from "../../../common/CloseBtn";
class CompanyList extends Component {
  render() {
    const {
      companyForm,
      handleDeleteCompany,
      handleCompanyClick,
      companyList,
      isFetching,
      labels,
    } = this.props;
    return (
      <>
        {companyList?.length > 0 ? (
          <List className="company-list" isFetching={isFetching}>
            {companyList.map((item, index) => (
              <List.ListItem
                onClick={() => handleCompanyClick(item.UserCompanyId)}
                key={item.UserCompanyId}
                isSelected={companyForm.UserCompanyId === item.UserCompanyId}
                testId={
                  item.IsDefaultCompany ? "default-company" : "simple-company"
                }
              >
                <div className={`dashItemImg ${item.Logo ? "" : "no-dp"}`}>
                  <img
                    className="roundImg"
                    src={item.Logo ? item.Logo : ImgSrc}
                    alt={item.UserName}
                  />
                </div>
                <div className="CompNameCnt">
                  <div
                    className={
                      item.IsDefaultCompany ? "defaultCompanyName" : "listLabel"
                    }
                  >
                    {item.CompanyName}
                  </div>
                  <div className="CompAddress">{item.CompanyAddress}</div>
                </div>
                <CloseBtn
                  className="close_btn_purple for-list"
                  testId={
                    item.IsDefaultCompany
                      ? "company-default-delete"
                      : `company-delete-btn`
                  }
                  onClick={(e) => {
                    handleDeleteCompany({ company: item });
                    e.stopPropagation();
                  }}
                />
              </List.ListItem>
            ))}
          </List>
        ) : (
          <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
        )}
      </>
    );
  }
}
export default CompanyList;
