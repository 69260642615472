export const ActionTypes = {
  SYSTEM_LABEL: "SYSTEM_LABEL",
  CURRENCIES: "CURRENCIES",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  NOTIFICATION: "NOTIFICATION",
  HELP_ACTIVE: "HELP_ACTIVE",
  UNREAD_MESSAGES: "UNREAD_MESSAGES",
  UNREAD_COLLABORATION: "UNREAD_COLLABORATION",
  UNREAD_OPPORTUNITIES: "UNREAD_OPPORTUNITIES",
  COOKIE: "COOKIE",
  PROFILEIDBYVISITOR: "PROFILEIDBYVISITOR",
  LANDINGFINDCANDIDATE: "LANDINGFINDCANDIDATE",
  LANDINGUPLOADRESUME: "LANDINGUPLOADRESUME",
  PROFILEVALUEBYVISITOR: "PROFILEVALUEBYVISITOR",
  RESUME_ANY_CHANGE: "RESUME_ANY_CHANGE",
  PRESENT: {
    SEARCH_KEY: "PRESENT_SEARCH_KEY",
    PRESENT_FORM: "PRESENT_FORM",
    SOCIAL_MEDIA: "PRESENT_SOCIAL_MEDIA",
    PRESENT_LIST: "PRESENT_LIST",
    FILTERED_LIST: "PRESENT_FILTERED_LIST",
    DELETED_ID: "PRESENT_DELETED_ID",
    DIALOG_MESSAGE: "PRESENT_DIALOG_MESSAGE",
    PROFILES: "PRESENT_PROFILES",
    USER_EMAILS: "PRESENT_USER_EMAILS",
    USER_PHONE_NUMBERS: "PRESENT_USER_PHONE_NUMBERS",
    SELECTED_PROFILE_ID: "PRESENT_SELECTED_PROFILE_ID",
    SELECTED_PHONE_ID: "PRESENT_SELECTED_PHONE_ID",
    SELECTED_EMAIL_ID: "PRESENT_SELECTED_EMAIL_ID",
    SYSTEM_LABELS: "PRESENT_SYSTEM_LABELS",
    IS_LOADING: "PRESENT_IS_LOADING",
    IS_FETCHING: "PRESENT_IS_FETCHING",
    LIST_COLLAPSE: "PRESENT_LIST_COLLAPSE",
    FORM_COLLAPSE: "PRESENT_FORM_COLLAPSE",
  },
  COMPANY: {
    SEARCH_KEY: "COMPANY_SEARCH_KEY",
    COMPANY_FORM: "COMPANY_FORM",
    COMPANY_LIST: "COMPANY_LIST",
    FILTERED_COMPANIES: "COMPANY_FILTERED_COMPANIES",
    SELECTED_COMPANY: "COMPANY_SELECTED_COMPANY",
    DIALOG_MESSAGE: "COMPANY_DIALOG_MESSAGE",
    COUNTRIES: "COMPANY_COUNTRIES",
    INDUSTRIES: "COMPANY_INDUSTRIES",
    USER_EMAILS: "COMPANY_USER_EMAILS",
    USER_PHONE_NUMBERS: "COMPANY_USER_PHONE_NUMBERS",
    IS_LOADING: "COMPANY_IS_LOADING",
    IS_FETCHING: "COMPANY_IS_FETCHING",
    LIST_COLLAPSED: "COMPANY_LIST_COLLAPSED",
    FORM_COLLAPSED: "COMPANY_FORM_COLLAPSED",
  },
  SEARCH: {
    SHORTLIST: "SEARCH_SHORTLIST",
    SELECTED_RESUME: "SEARCH_SELECTED_RESUME",
    SELECTED_SHORTLIST: "SEARCH_SELECTED_SHORTLIST",
    SHORTLIST_RESUMES: "SEARCH_SHORTLIST_RESUMES",
    IS_LOADING: "SEARCH_IS_LOADING",
    FETCHING_RESUMES: "SEARCH_FETCHING_RESUMES",
    OPPORTUNITY_LIST: "SEARCH_OPPORTUNITY_LIST",
    FETCHING_OPPORTUNITIES: "SEARCH_FETCHING_OPPORTUNITIES",
    SEARCH_LIST: "SEARCH_LIST",
    SELECTED_SEARCH_TAB: "SEARCH_SELECTED_SEARCH_TAB",
    AVAILIBILITY: "SEARCH_AVAILIBILITY",
    SAVED_SEARCHES_LIST: "SEARCH_SAVED_SEARCHES_LIST",
    DIALOG_SAVE_SEARCH_MESSAGE: "SEARCH_DIALOG_SAVE_SEARCH_MESSAGE",
    NEW_SHORTLIST_VALUE: "SEARCH_NEW_SHORTLIST_VALUE",
    NEW_SAVE_SEARCH_VALUE: "SEARCH_NEW_SAVE_SEARCH_VALUE",
    DIALOG_MESSAGE: "SEARCH_DIALOG_MESSAGE",
    DELETED_ID: "SEARCH_DELETED_ID",
    DIALOG_OPPORTUNITY_MESSAGE: "SEARCH_DIALOG_OPPORTUNITY_MESSAGE",
    DIALOG_SHORTLIST_MESSAGE: "SEARCH_DIALOG_SHORTLIST_MESSAGE",
    SHOW_RESUME_LIST: "SHOW_RESUME_LIST",
    CREATE_NEW_SAVE_SEARCH: "SEARCH_CREATE_NEW_SAVE_SEARCH",
    SHOW_EXISTING_SAVE_SEARCHES: "SEARCH_SHOW_EXISTING_SAVE_SEARCHES",
    SHOW_OPPORTUNITIES: "SEARCH_SHOW_OPPORTUNITIES",
    SHOW_EXISTING_SHORTLISTS: "SEARCH_SHOW_EXISTING_SHORTLISTS",
    CREATE_NEW_SHORTLIST: "SEARCH_CREATE_NEW_SHORTLIST",
    SHOW_RESUME_DETAIL: "SEARCH_SHOW_RESUME_DETAIL",
    SEARCH_COLLAPSED: "SEARCH_SEARCH_COLLAPSED",
    SAVE_SEARCH_COLLAPSE: "SEARCH_SAVE_SEARCH_COLLAPSE",
    RESUME_COLLAPSED: "SEARCH_RESUME_COLLAPSED",
    RESUME_DETAIL_COLLAPSED: "SEARCH_RESUME_DETAIL_COLLAPSED",
    OPPORTUNITIES_COLLAPSE: "SEARCH_OPPORTUNITIES_COLLAPSE",
    SHORTLIST_COLLAPSE: "SEARCH_SHORTLIST_COLLAPSE",
    CREATE_COLLAPSE: "SEARCH_CREATE_COLLAPSE",
    //search list component
    SEARCH_LIST_ROLES: "SEARCH_SEARCH_LIST_ROLES",
    SEARCH_LIST_ROLES_FETCHING: "SEARCH_SEARCH_LIST_ROLES_FETCHING",
    SEARCH_LIST_SKILLS: "SEARCH_SEARCH_LIST_SKILLS",
    SEARCH_LIST_SKILLS_FETCHING: "SEARCH_SEARCH_LIST_SKILLS_FETCHING",
    SEARCH_LIST_KEYWORDS: "SEARCH_SEARCH_LIST_KEYWORDS",
    SEARCH_LIST_KEYWORDS_FETCHING: "SEARCH_SEARCH_LIST_KEYWORDS_FETCHING",
    SEARCH_LIST_INDUSTRIES: "SEARCH_SEARCH_LIST_INDUSTRIES",
    SEARCH_LIST_INDUSTRIES_FETCHING: "SEARCH_SEARCH_LIST_INDUSTRIES_FETCHING",
    SEARCH_LIST_CERTIFICATIONS: "SEARCH_SEARCH_LIST_CERTIFICATIONS",
    SEARCH_LIST_CERTIFICATIONS_FETCHING:
      "SEARCH_SEARCH_LIST_CERTIFICATIONS_FETCHING",
    SEARCH_LIST_COUNTRIES: "SEARCH_SEARCH_LIST_COUNTRIES",
    SEARCH_LIST_COUNTRIES_FETCHING: "SEARCH_SEARCH_LIST_COUNTRIES_FETCHING",
    SEARCH_LIST_LANGUAGES: "SEARCH_SEARCH_LIST_LANGUAGES",
    SEARCH_LIST_LANGUAGES_FETCHING: "SEARCH_SEARCH_LIST_LANGUAGES_FETCHING",
    SEARCH_LIST_SEARCH_LIST: "SEARCH_SEARCH_LIST_SEARCH_LIST",
    SEARCH_LIST_AVAILIBILITY: "SEARCH_SEARCH_LIST_AVAILIBILITY",
    SEARCH_LIST_DIALOG_MESSAGE: "SEARCH_SEARCH_LIST_DIALOG_MESSAGE",
  },
  NEWSEARCH: {
    RESUME_LIST: "RESUME_LIST",
    DEFAULT_LIST: "DEFAULT_LIST",
    SELECTED_RESUME: "SEARCH_SELECTED_RESUME",
    SEARCH_COLLAPSED: "SEARCH_SEARCH_COLLAPSED",
    SAVED_SEARCH: "SAVED_SEARCH",
    SHORTLIST: {
      SHORTLIST_LIST: "SHORTLIST_LIST",
      SHORTLIST_RESUME: "SHORTLIST_RESUME",
      SELECTED: "SELECTED",
      FILTERED_SHORTLIST: "FILTERED_SHORTLIST",
    },
  },
  NEWSAVEDSEARCH: {
    RESUME_LIST_SAVED_SEARCH: "RESUME_LIST_SAVED_SEARCH",
    DEFAULT_LIST_SAVED_SEARCH: "DEFAULT_LIST_SAVED_SEARCH",
    SELECTED_RESUME_SAVED_SEARCH: "SELECTED_RESUME_SAVED_SEARCH",
    SEARCH_COLLAPSED_SAVED_SEARCH: "SEARCH_COLLAPSED_SAVED_SEARCH",
    SAVED_SEARCH_SAVED_SEARCH: "SAVED_SEARCH_SAVED_SEARCH",
    FILTER_SELECTED_SAVE_SEARCH:"FILTER_SELECTED_SAVE_SEARCH"
  },
  SAVED_SEARCH: {
    FILTER_SAVED_SEARCH: "SAVED_SEARCH_FILTER_SAVED_SEARCH",
    SAVED_SEARCHES: "SAVED_SEARCH_SAVED_SEARCHES",
    SELECTED_SAVED_SEARCH: "SAVED_SEARCH_SELECTED_SAVED_SEARCH",
    SAVED_SEARCH_SETTINGS: "SAVED_SEARCH_SETTINGS",
    SELECTED_SAVED_SEARCH_NAME: "SAVED_SEARCH_SELECTED_SAVED_SEARCH_NAME",
    SHORTLIST: "SAVED_SEARCH_SHORTLIST",
    SELECTED_RESUME: "SAVED_SEARCH_SELECTED_RESUME",
    SELECTED_SHORTLIST: "SAVED_SEARCH_SELECTED_SHORTLIST",
    SHORTLIST_RESUMES: "SAVED_SEARCH_SHORTLIST_RESUMES",
    IS_LOADING: "SAVED_SEARCH_IS_LOADING",
    FETCHING_RESUMES: "SAVED_SEARCH_FETCHING_RESUMES",
    OPPORTUNITY_LIST: "SAVED_SEARCH_OPPORTUNITY_LIST",
    FETCHING_OPPORTUNITIES: "SAVED_SEARCH_FETCHING_OPPORTUNITIES",
    SEARCH_LIST: "SAVED_SEARCH_LIST",
    SELECTED_SEARCH_TAB: "SAVED_SEARCH_SELECTED_SEARCH_TAB",
    AVAILIBILITY: "SAVED_SEARCH_AVAILIBILITY",
    SAVED_SEARCHES_LIST: "SAVED_SEARCH_SAVED_SEARCHES_LIST",
    DIALOG_SAVE_SEARCH_MESSAGE: "SAVED_SEARCH_DIALOG_SAVE_SEARCH_MESSAGE",
    NEW_SHORTLIST_VALUE: "SAVED_SEARCH_NEW_SHORTLIST_VALUE",
    NEW_SAVE_SEARCH_VALUE: "SAVED_SEARCH_NEW_SAVE_SEARCH_VALUE",
    DIALOG_MESSAGE: "SAVED_SEARCH_DIALOG_MESSAGE",
    DELETED_ID: "SAVED_SEARCH_DELETED_ID",
    DIALOG_OPPORTUNITY_MESSAGE: "SAVED_SEARCH_DIALOG_OPPORTUNITY_MESSAGE",
    DIALOG_SHORTLIST_MESSAGE: "SAVED_SEARCH_DIALOG_SHORTLIST_MESSAGE",
    SHOW_RESUME_LIST: "SAVED_SEARCH_SHOW_RESUME_LIST",
    CREATE_NEW_SAVE_SEARCH: "SAVED_SEARCH_CREATE_NEW_SAVE_SEARCH",
    SHOW_EXISTING_SAVE_SEARCHES: "SAVED_SEARCH_SHOW_EXISTING_SAVE_SEARCHES",
    SHOW_OPPORTUNITIES: "SAVED_SEARCH_SHOW_OPPORTUNITIES",
    SHOW_EXISTING_SHORTLISTS: "SAVED_SEARCH_SHOW_EXISTING_SHORTLISTS",
    CREATE_NEW_SHORTLIST: "SAVED_SEARCH_CREATE_NEW_SHORTLIST",
    SHOW_RESUME_DETAIL: "SAVED_SEARCH_SHOW_RESUME_DETAIL",
    SEARCH_COLLAPSED: "SAVED_SEARCH_SEARCH_COLLAPSED",
    SAVE_SEARCH_COLLAPSE: "SAVED_SEARCH_SAVE_SEARCH_COLLAPSE",
    RESUME_COLLAPSED: "SAVED_SEARCH_RESUME_COLLAPSED",
    RESUME_DETAIL_COLLAPSED: "SAVED_SEARCH_RESUME_DETAIL_COLLAPSED",
    OPPORTUNITIES_COLLAPSE: "SAVED_SEARCH_OPPORTUNITIES_COLLAPSE",
    SHORTLIST_COLLAPSE: "SAVED_SEARCH_SHORTLIST_COLLAPSE",
    CREATE_COLLAPSE: "SAVED_SEARCH_CREATE_COLLAPSE",
    //search list component
    SEARCH_LIST_ROLES: "SAVED_SEARCH_SEARCH_LIST_ROLES",
    SEARCH_LIST_ROLES_FETCHING: "SAVED_SEARCH_SEARCH_LIST_ROLES_FETCHING",
    SEARCH_LIST_SKILLS: "SAVED_SEARCH_SEARCH_LIST_SKILLS",
    SEARCH_LIST_SKILLS_FETCHING: "SAVED_SEARCH_SEARCH_LIST_SKILLS_FETCHING",
    SEARCH_LIST_KEYWORDS: "SAVED_SEARCH_SEARCH_LIST_KEYWORDS",
    SEARCH_LIST_KEYWORDS_FETCHING: "SAVED_SEARCH_SEARCH_LIST_KEYWORDS_FETCHING",
    SEARCH_LIST_INDUSTRIES: "SAVED_SEARCH_SEARCH_LIST_INDUSTRIES",
    SEARCH_LIST_INDUSTRIES_FETCHING:
      "SAVED_SEARCH_SEARCH_LIST_INDUSTRIES_FETCHING",
    SEARCH_LIST_CERTIFICATIONS: "SAVED_SEARCH_SEARCH_LIST_CERTIFICATIONS",
    SEARCH_LIST_CERTIFICATIONS_FETCHING:
      "SAVED_SEARCH_SEARCH_LIST_CERTIFICATIONS_FETCHING",
    SEARCH_LIST_COUNTRIES: "SAVED_SEARCH_SEARCH_LIST_COUNTRIES",
    SEARCH_LIST_COUNTRIES_FETCHING:
      "SAVED_SEARCH_SEARCH_LIST_COUNTRIES_FETCHING",
    SEARCH_LIST_LANGUAGES: "SAVED_SEARCH_SEARCH_LIST_LANGUAGES",
    SEARCH_LIST_LANGUAGES_FETCHING:
      "SAVED_SEARCH_SEARCH_LIST_LANGUAGES_FETCHING",
    SEARCH_LIST_SEARCH_LIST: "SAVED_SEARCH_SEARCH_LIST_SEARCH_LIST",
    SEARCH_LIST_AVAILIBILITY: "SAVED_SEARCH_SEARCH_LIST_AVAILIBILITY",
    SEARCH_LIST_DIALOG_MESSAGE: "SAVED_SEARCH_SEARCH_LIST_DIALOG_MESSAGE",
  },
  SHORTLIST: {
    SHORTLIST: "SHORTLIST_SHORTLISTS",
    SEARCH_COLLAPSED: "SHORTLIST_SEARCH_COLLAPSED",
    RESUME_COLLAPSED: "SHORTLIST_RESUME_COLLAPSED",
    RESUME_DETAIL_COLLAPSED: "SHORTLIST_RESUME_DETAIL_COLLAPSED",
    SELECTED_SHORTLIST: "SHORTLIST_SELECTED_SHORTLIST",
    SELECTED_RESUME: "SHORTLIST_SELECTED_RESUME",
    SHORTLIST_RESUMES: "SHORTLIST_SHORTLIST_RESUMES",
    DIALOG_MESSAGE: "SHORTLIST_DIALOG_MESSAGE",
    FILTERED_SHORTLIST: "SHORTLIST_FILTERED_SHORTLIST",
    NEW_SHORTLIST_VALUE: "SHORTLIST_NEW_SHORTLIST_VALUE",
    CREATE_NEW_SHORTLIST: "SHORTLIST_CREATE_NEW_SHORTLIST",
    IS_LOADING: "SHORTLIST_IS_LOADING",
    CREATE_COLLAPSE: "SHORTLIST_CREATE_COLLAPSE",
    FETCHING_SHORTLIST: "SHORTLIST_FETCHING_SHORTLIST",
    FETCHING_RESUMES: "SHORTLIST_FETCHING_RESUMES",
    DIALOG_OPPORTUNITY_MESSAGE: "SHORTLIST_DIALOG_OPPORTUNITY_MESSAGE",
    OPPORTUNITY_LIST: "SHORTLIST_OPPORTUNITY_LIST",
    SHOW_OPPORTUNITIES: "SHORTLIST_SHOW_OPPORTUNITIES",
    FETCHING_OPPORTUNITIES: "SHORTLIST_FETCHING_OPPORTUNITIES",
    OPPORTUNITIES_COLLAPSE: "SHORTLIST_OPPORTUNITIES_COLLAPSE",
    SEARCH_KEY: "SHORTLIST_SEARCH_KEY",
    DELETED_ID: "SHORTLIST_DELETED_ID",
    RESUME_DELETE_ID: "SHORTLIST_RESUME_DELETED_ID",
  },
  MESSAGE_NEW: {
    LIST_COLLAPSED: "MESSAGE_NEW_LIST_COLLAPSED",
    FORM_COLLAPSED: "MESSAGE_NEW_FORM_COLLAPSED",
    USERS: "MESSAGE_NEW_USERS",
    MESSAGE_FORM: "MESSAGE_NEW_MESSAGE_FORM",
    IS_SEARCH_FOCUS: "MESSAGE_NEW_IS_SEARCH_FOCUS",
    SELECTED_USERS: "MESSAGE_NEW_SELECTED_USERS",
    IS_FETCHING: "MESSAGE_NEW_IS_FETCHING",
    FILTER_USERS: "MESSAGE_NEW_FILTER_USERS",
    CREATE_MESSAGE_COLLAPSED: "MESSAGE_CREATE_MESSAGE_COLLAPSED",
  },
  MESSAGE_INBOX: {
    LIST_COLLAPSED: "MESSAGE_INBOX_LIST_COLLAPSED",
    DETAIL_COLLAPSED: "MESSAGE_INBOX_DETAIL_COLLAPSED",
    REPLY_COLLAPSED: "MESSAGE_INBOX_REPLY_COLLAPSED",
    MESSAGE_FORM: "MESSAGE_INBOX_MESSAGE_FORM",
    DIALOG_MESSAGE: "MESSAGE_INBOX_DIALOG_MESSAGE",
    SELECTED_MESSAGE: "MESSAGE_INBOX_SELECTED_MESSAGE",
    FILTER_MESSAGES: "MESSAGE_INBOX_FILTER_MESSAGES",
    IS_REPLY: "MESSAGE_INBOX_IS_REPLY",
    IS_FETCHING: "MESSAGE_INBOX_IS_FETCHING",
    IS_FETCHING: "MESSAGE_INBOX_IS_FETCHING",
    DELETED_ID: "MESSAGE_INBOX_DELETED_ID",
    MESSAGE_LIST: "MESSAGE_INBOX_MESSAGE_LIST",
    SEARCH_KEY: "MESSAGE_INBOX_SEARCH_KEY",
  },
  MESSAGE_SENT: {
    LIST_COLLAPSED: "MESSAGE_SENT_LIST_COLLAPSED",
    DETAIL_COLLAPSED: "MESSAGE_SENT_DETAIL_COLLAPSED",
    SELECTED_MESSAGE: "MESSAGE_SENT_SELECTED_MESSAGE",
    FILTER_MESSAGES: "MESSAGE_SENT_FILTER_MESSAGES",
    MESSAGE_LIST: "MESSAGE_SENT_MESSAGE_LIST",
    IS_FETCHING: "MESSAGE_SENT_IS_FETCHING",
    DIALOG_MESSAGE: "MESSAGE_SENT_DIALOG_MESSAGE",
    SEARCH_KEY: "MESSAGE_SENT_SEARCH_KEY",
  },
  CREATE_NEW_OPPORTUNITY_SEARCHER: {
    NEW_DESIGN_CREATE_NEW: {
      SELECTED_FORM_VALUES: "SELECTED_FORM_VALUES",
      SELECT_DROPDOWNLIST: "SELECT_DROPDOWNLIST",
    },
    IS_FETCHING: "CREATE_NEW_OPPORTUNITY_SEARCHER_IS_FETCHING",
    IS_LOADING: "CREATE_NEW_OPPORTUNITY_SEARCHER_IS_LOADING",
    OPPORTUNITIES: "CREATE_NEW_OPPORTUNITY_SEARCHER_OPPORTUNITIES",
    FILTER_OPPORTUNIRES: "CREATE_NEW_OPPORTUNITY_SEARCHER_FILTER_OPPORTUNIRES",
    SHORTLIST_RESUMES: "CREATE_NEW_OPPORTUNITY_SEARCHER_SHORTLIST_RESUMES",
    FORM_COLLAPSED: "CREATE_NEW_OPPORTUNITY_SEARCHER_FORM_COLLAPSED",
    SHORTLIST_COLLAPSED: "CREATE_NEW_OPPORTUNITY_SEARCHER_SHORTLIST_COLLAPSED",
    RESUME_LIST_COLLAPSED:
      "CREATE_NEW_OPPORTUNITY_SEARCHER_RESUME_LIST_COLLAPSED",
    RESUME_DETAIL_COLLAPSED:
      "CREATE_NEW_OPPORTUNITY_SEARCHER_RESUME_DETAIL_COLLAPSED",
    SELECTED_RESUME: "CREATE_NEW_OPPORTUNITY_SEARCHER_SELECTED_RESUME",
    IS_SEARCH_FOCUS: "CREATE_NEW_OPPORTUNITY_SEARCHER_IS_SEARCH_FOCUS",
    SEARCH_LIST_HOVER: "CREATE_NEW_OPPORTUNITY_SEARCHER_SEARCH_LIST_HOVER",
    SELECTED_SHORTLIST: "CREATE_NEW_OPPORTUNITY_SEARCHER_SELECTED_SHORTLIST",
    SELECTED_SHORTLISTS: "CREATE_NEW_OPPORTUNITY_SEARCHER_SELECTED_SHORTLISTS",
    SHORTLISTS: "CREATE_NEW_OPPORTUNITY_SEARCHER_SHORTLISTS",
    FILTER_SHORTLISTS: "CREATE_NEW_OPPORTUNITY_SEARCHER_FILTER_SHORTLISTS",
    DIALOG_MESSAGE: "CREATE_NEW_OPPORTUNITY_SEARCHER_DIALOG_MESSAGE",
    YES_CLICK_COUNT: "CREATE_NEW_OPPORTUNITY_SEARCHER_YES_CLICK_COUNT",
    SAVED_SEARCHES: "CREATE_NEW_OPPORTUNITY_SEARCHER_SAVED_SEARCHES",
    USER_COMPANIES: "CREATE_NEW_OPPORTUNITY_SEARCHER_USER_COMPANIES",
    SELECTED_SAVE_SEARCH:
      "CREATE_NEW_OPPORTUNITY_SEARCHER_SELECTED_SAVE_SEARCH",
    SELECTED_COMPANY: "CREATE_NEW_OPPORTUNITY_SEARCHER_SELECTED_COMPANY",
    LANGUAGES: "CREATE_NEW_OPPORTUNITY_SEARCHER_LANGUAGES",
    IS_FETCHING_SHORTLIST:
      "CREATE_NEW_OPPORTUNITY_SEARCHER_IS_FETCHING_SHORTLIST",
    IS_FETCHING_SHORTLIST_RESUME:
      "CREATE_NEW_OPPORTUNITY_SEARCHER_IS_FETCHING_SHORTLIST_RESUME",
    OPPORTUNITY_SAVE_LOADING:
      "CREATE_NEW_OPPORTUNITY_SEARCHER_OPPORTUNITY_SAVE_LOADING",
    ALL_LOCATIONS_LIST: "CREATE_NEW_OPPORTUNITY_SEARCHER_ALL_LOCATIONS_LIST",
    ALL_LANGUAGES_LIST: "CREATE_NEW_OPPORTUNITY_SEARCHER_ALL_LANGUAGES_LIST",
    ALL_CURRENCIES_LIST: "CREATE_NEW_OPPORTUNITY_SEARCHER_ALL_CURRENCIES_LIST",
    SEARCH_KEY: "CREATE_NEW_OPPORTUNITY_SEARCHER_SEARCH_KEY",
    SELECTED_OPPORTUNITY:
      "CREATE_NEW_OPPORTUNITY_SEARCHER_SELECTED_OPPORTUNITY",
  },
  OPPORTUNITIES_SENT_SEARCHER: {
    IS_EMPTY_COLLAPSED: "OPPORTUNITIES_SENT_SEARCHER_IS_EMPTY_COLLAPSED",
    LIST_COLLAPSE: "OPPORTUNITIES_SENT_SEARCHER_LIST_COLLAPSE",
    DETAIL_COLLAPSE: "OPPORTUNITIES_SENT_SEARCHER_DETAIL_COLLAPSE",
    SHORTLIST_COLLAPSE: "OPPORTUNITIES_SENT_SEARCHER_SHORTLIST_COLLAPSE",
    RESUME_LIST_COLLAPSE: "OPPORTUNITIES_SENT_SEARCHER_RESUME_LIST_COLLAPSE",
    SHORTLIST_RESUME_COLLAPSE:
      "OPPORTUNITIES_SENT_SEARCHER_SHORTLIST_RESUME_COLLAPSE",
    DIALOG_MESSAGE: "OPPORTUNITIES_SENT_SEARCHER_DIALOG_MESSAGE",
    IS_LOADING: "OPPORTUNITIES_SENT_SEARCHER_IS_LOADING",
    IS_FETCHING: "OPPORTUNITIES_SENT_SEARCHER_IS_FETCHING",
    IS_FETCHING_SHORTLIST: "OPPORTUNITIES_SENT_SEARCHER_IS_FETCHING_SHORTLIST",
    IS_FETCHING_SHORTLIST_RESUME:
      "OPPORTUNITIES_SENT_SEARCHER_IS_FETCHING_SHORTLIST_RESUME",
    FILTER_OPPORTUNIRES: "OPPORTUNITIES_SENT_SEARCHER_FILTER_OPPORTUNIRES",
    SELECTED_OPPORTUNITY: "OPPORTUNITIES_SENT_SEARCHER_SELECTED_OPPORTUNITY",
    SELECTED_SHORTLISTS: "OPPORTUNITIES_SENT_SEARCHER_SELECTED_SHORTLISTS",
    SELECTED_SHORTLIST: "OPPORTUNITIES_SENT_SEARCHER_SELECTED_SHORTLIST",
    SHORTLIST_RESUMES: "OPPORTUNITIES_SENT_SEARCHER_SHORTLIST_RESUMES",
    SELECTED_RESUME: "OPPORTUNITIES_SENT_SEARCHER_SELECTED_RESUME",
    SELECTED_COMPANY: "OPPORTUNITIES_SENT_SEARCHER_SELECTED_COMPANY",
    CURRENT_OPPORTUNITY_ID:
      "OPPORTUNITIES_SENT_SEARCHER_CURRENT_OPPORTUNITY_ID",
    SEARCH_KEY: "OPPORTUNITIES_SENT_SEARCHER_SEARCH_KEY",
    OPPORTUNITY_ACTIONS_COUNTS:
      "OPPORTUNITIES_SENT_SEARCHER_OPPORTUNITY_ACTIONS_COUNTS",
    HIDE_SHORTLIST: "OPPORTUNITIES_SENT_SEARCHER_HIDE_SHORTLIST",
    SHOW_RESUME_LIST: "OPPORTUNITIES_SENT_SEARCHER_SHOW_RESUME_LIST",
    SELECTED_IPRO: "OPPORTUNITIES_SENT_SEARCHER_SELECTED_IPRO",
    OPPORTUNITIES: "OPPORTUNITIES_SENT_SEARCHER_OPPORTUNITIES",
    YES_CLICK_COUNT: "OPPORTUNITIES_SENT_SEARCHER_YES_CLICK_COUNT",
    IPROS_LIST: "OPPORTUNITIES_SENT_SEARCHER_IPROS_LIST",
    SELECTED_ACTION: "OPPORTUNITIES_SENT_SEARCHER_SELECTED_ACTION",
    ALL_CURRENCIES_LIST: "OPPORTUNITIES_SENT_SEARCHER_ALL_CURRENCIES_LIST",
  },
  OPPORTUNITIES_DRAFT_SEARCHER: {
    IS_LOADING: "OPPORTUNITIES_DRAFT_SEARCHER_IS_LOADING",
    IS_FETCHING: "OPPORTUNITIES_DRAFT_SEARCHER_IS_FETCHING",
    OPPORTUNITIES: "OPPORTUNITIES_DRAFT_SEARCHER_OPPORTUNITIES",
    FILTER_OPPORTUNIRES: "OPPORTUNITIES_DRAFT_SEARCHER_FILTER_OPPORTUNIRES",
    SELECTED_OPPORTUNITY: "OPPORTUNITIES_DRAFT_SEARCHER_SELECTED_OPPORTUNITY",
    IS_EMPTY_COLLAPSED: "OPPORTUNITIES_DRAFT_SEARCHER_IS_EMPTY_COLLAPSED",
    LIST_COLLAPSED: "OPPORTUNITIES_DRAFT_SEARCHER_LIST_COLLAPSED",
    FORM_COLLAPSED: "OPPORTUNITIES_DRAFT_SEARCHER_FORM_COLLAPSED",
    SHORTLIST_COLLAPSED: "OPPORTUNITIES_DRAFT_SEARCHER_SHORTLIST_COLLAPSED",
    RESUMES_COLLAPSED: "OPPORTUNITIES_DRAFT_SEARCHER_RESUMES_COLLAPSED",
    RESUME_DETAIL_COLLAPSED:
      "OPPORTUNITIES_DRAFT_SEARCHER_RESUME_DETAIL_COLLAPSED",
    SHORTLISTS: "OPPORTUNITIES_DRAFT_SEARCHER_SHORTLISTS",
    SHORTLIST_RESUMES: "OPPORTUNITIES_DRAFT_SEARCHER_SHORTLIST_RESUMES",
    SELECTED_RESUME: "OPPORTUNITIES_DRAFT_SEARCHER_SELECTED_RESUME",
    SELECTED_SHORTLIST: "OPPORTUNITIES_DRAFT_SEARCHER_SELECTED_SHORTLIST",
    SELECTED_SHORTLISTS: "OPPORTUNITIES_DRAFT_SEARCHER_SELECTED_SHORTLISTS",
    DIALOG_MESSAGE: "OPPORTUNITIES_DRAFT_SEARCHER_DIALOG_MESSAGE",
    YES_CLICK_COUNT: "OPPORTUNITIES_DRAFT_SEARCHER_YES_CLICK_COUNT",
    SELECTED_SAVE_SEARCH: "OPPORTUNITIES_DRAFT_SEARCHER_SELECTED_SAVE_SEARCH",
    SELECTED_COMPANY: "OPPORTUNITIES_DRAFT_SEARCHER_SELECTED_COMPANY",
    SAVED_SEARCHES: "OPPORTUNITIES_DRAFT_SEARCHER_SAVED_SEARCHES",
    USER_COMPANIES: "OPPORTUNITIES_DRAFT_SEARCHER_USER_COMPANIES",
    LANGUAGES: "OPPORTUNITIES_DRAFT_SEARCHER_LANGUAGES",
    LOCATIONS: "OPPORTUNITIES_DRAFT_SEARCHER_LOCATIONS",
    IS_FETCHING_SHORTLIST: "OPPORTUNITIES_DRAFT_SEARCHER_IS_FETCHING_SHORTLIST",
    IS_FETCHING_SHORTLIST_RESUME:
      "OPPORTUNITIES_DRAFT_SEARCHER_IS_FETCHING_SHORTLIST_RESUME",
    OPPORTUNITY_SAVE_LOADING:
      "OPPORTUNITIES_DRAFT_SEARCHER_OPPORTUNITY_SAVE_LOADING",
    FILTER_SHORTLISTS: "OPPORTUNITIES_DRAFT_SEARCHER_FILTER_SHORTLISTS",
    SEARCH_KEY: "OPPORTUNITIES_DRAFT_SEARCHER_SEARCH_KEY",
    OPPORTUNITY_TO_DELETE: "OPPORTUNITIES_DRAFT_SEARCHER_OPPORTUNITY_TO_DELETE",
    ALL_CURRENCIES_LIST: "OPPORTUNITIES_DRAFT__SEARCHER_ALL_CURRENCIES_LIST",
  },
  RESUME: {
    SHOW_LINKEDIN_IMPORT_VIEW: "RESUME_SHOW_LINKEDIN_IMPORT_VIEW",
    SELECTED_RESUME: "RESUME_SELECTED_RESUME",
    SELECTED_PDF_RESUME: "RESUME_SELECTED_PDF_RESUME",
    DIALOG_MESSAGE: "RESUME_DIALOG_MESSAGE",
    GET_IMG_SRC: "RESUME_GET_IMG_SRC",
    UPLOAD_PDF_BUTTON_DISABLE: "RESUME_UPLOAD_PDF_BUTTON_DISABLE",
    DIALOG_RESUME_UPLOAD: "RESUME_DIALOG_RESUME_UPLOAD",
    IS_RESUME_IMPORT_COLLAPSED: "RESUME_IS_RESUME_IMPORT_COLLAPSED",
    FETCHING_RESUMES: "RESUME_FETCHING_RESUMES",
    DETAIL_COLLAPSED: "RESUME_DETAIL_COLLAPSED",
    IMPORT_COLLAPSED: "RESUME_IMPORT_COLLAPSED",
    DIALOG_RESUME_EDIT: "RESUME_DIALOG_RESUME_EDIT",
    IS_PDF_FILE_UPLOADED: "RESUME_IS_PDF_FILE_UPLOADED",
    PDF_FILE_NAME: "RESUME_PDF_FILE_NAME",
  },
  RESUME_EDIT: {
    IS_LOADING: "RESUME_EDIT_IS_LOADING",
    IS_AVAILABLE: "RESUME_EDIT_IS_AVAILABLE",
    AVAILABLITY_TYPE: "RESUME_EDIT_AVAILABLITY_TYPE",
    BUSY_UNTIL: "RESUME_EDIT_BUSY_UNTIL",
    LOCATION_LIST: "RESUME_EDIT_LOCATION_LIST",
    LANGUAGE_LIST: "RESUME_EDIT_LANGUAGE_LIST",
    ROLES_LIST: "RESUME_EDIT_ROLES_LIST",
    KEYWORDS_LIST: "RESUME_EDIT_KEYWORDS_LIST",
    OTHER_ACHIVENMENTS_LIST: "RESUME_EDIT_OTHER_ACHIVENMENTS_LIST",
    SKILLS_LIST: "RESUME_EDIT_SKILLS_LIST",
    CERTIFICATIONS_LIST: "RESUME_EDIT_CERTIFICATIONS_LIST",
    EDUCATIONS_LIST: "RESUME_EDIT_EDUCATIONS_LIST",
    INDUSTRIES_LIST: "RESUME_EDIT_INDUSTRIES_LIST",
    EXPERIENCES_LIST: "RESUME_EDIT_EXPERIENCES_LIST",
    RESUMES: "RESUME_EDIT_RESUMES",
    SUGGEST_OBJECT: "RESUME_EDIT_SUGGEST_OBJECT",
    SUGGESTED_ROLES: "RESUME_EDIT_SUGGESTED_ROLES",
    ROLE_DEFAULT_OPTIONS: "RESUME_EDIT_ROLE_DEFAULT_OPTIONS",
    SUGGESTED_CERTIFICATIONS: "RESUME_EDIT_SUGGESTED_CERTIFICATIONS",
    CERTIFICATION_DEFAULT_OPTIONS: "RESUME_EDIT_CERTIFICATION_DEFAULT_OPTIONS",
    SUGGESTED_SKILLS: "RESUME_EDIT_SUGGESTED_SKILLS",
    SKILL_DEFAULT_OPTIONS: "RESUME_EDIT_SKILL_DEFAULT_OPTIONS",
    EDUCATION_DEFAULT_OPTIONS: "RESUME_EDIT_EDUCATION_DEFAULT_OPTIONS",
    KEYWORD_DEFAULT_OPTIONS: "RESUME_EDIT_KEYWORD_DEFAULT_OPTIONS",
    INDUSTRY_DEFAULT_OPTIONS: "RESUME_EDIT_INDUSTRY_DEFAULT_OPTIONS",
    CURRENT_CANDIDATE: "RESUME_EDIT_CURRENT_CANDIDATE",
    DIALOG_MESSAGE: "RESUME_EDIT_DIALOG_MESSAGE",
  },
  OPPORTUNITY_IPRO: {
    SELECTED_OPPORTUNITY: "OPPORTUNITY_IPRO_SELECTED_OPPORTUNITY",
    OPPORTUNITY_LIST: "OPPORTUNITY_IPRO_OPPORTUNITY_LIST",
    FILTERED_OPPORTUNITY: "OPPORTUNITY_IPRO_FILTERED_OPPORTUNITY",
    SELECTED_COMPANY: "OPPORTUNITY_IPRO_SELECTED_COMPANY",
    FETCHING_OPPORTUNITY: "OPPORTUNITY_IPRO_FETCHING_OPPORTUNITY",
    IS_LOADING: "OPPORTUNITY_IPRO_IS_LOADING",
    SHOW_PRESENTATION_DETAIL: "OPPORTUNITY_IPRO_SHOW_PRESENTATION_DETAIL",
    SHOW_COMPANY_DETAILS: "OPPORTUNITY_IPRO_SHOW_COMPANY_DETAILS",
    SOCIAL_MEDIA: "OPPORTUNITY_IPRO_SOCIAL_MEDIA",
    DELETED_ID: "OPPORTUNITY_IPRO_DELETED_ID",
    REQUEST_NAME: "OPPORTUNITY_IPRO_REQUEST_NAME",
    DIALOG_MESSAGE: "OPPORTUNITY_IPRO_DIALOG_MESSAGE",
    RESTORE_DIALOG_MESSAGE: "OPPORTUNITY_IPRO_RESTORE_DIALOG_MESSAGE",
    COMPANY_PRESENTATION_HEADING_TEXT:
      "OPPORTUNITY_IPRO_COMPANY_PRESENTATION_HEADING_TEXT",
    LIST_COLLAPSED: "OPPORTUNITY_IPRO_LIST_COLLAPSED",
    DETAIL_COLLAPSED: "OPPORTUNITY_IPRO_DETAIL_COLLAPSED",
    COMPANY_PRESENT_DETAIL_COLLAPSED:
      "OPPORTUNITY_IPRO_COMPANY_PRESENT_DETAIL_COLLAPSED",
    SEARCH_KEY: "OPPORTUNITY_IPRO_SEARCH_KEY",
  },
  COLLABORATION: {
    SELECTED_COLLABORATION: "COLLABORATION_SELECTED_COLLABORATION",
    COLLABORATION_LIST: "COLLABORATION_COLLABORATION_LIST",
    FILTERED_COLLABORATIONS: "COLLABORATION_FILTERED_COLLABORATIONS",
    SELECTED_USER: "COLLABORATION_SELECTED_USER",
    SELECTED_COMPANY: "COLLABORATION_SELECTED_COMPANY",
    FETCHING_COLLABORATIONS: "COLLABORATION_FETCHING_COLLABORATIONS",
    IS_LOADING: "COLLABORATION_IS_LOADING",
    IS_HELP_ACTIVE: "COLLABORATION_IS_HELP_ACTIVE",
    SYSTEM_LABELS: "COLLABORATION_SYSTEM_LABELS",
    DIALOG_MESSAGE: "COLLABORATION_DIALOG_MESSAGE",
    LIST_COLLAPSED: "COLLABORATION_LIST_COLLAPSED",
    DETAIL_COLLAPSED: "COLLABORATION_DETAIL_COLLAPSED",
    USER_COMPANY_DETAIL_COLLAPSED:
      "COLLABORATION_USER_COMPANY_DETAIL_COLLAPSED",
    SEARCH_KEY: "COLLABORATION_SEARCH_KEY",
    YES_CLICK_COUNT: "COLLABORATION_YES_CLICK_COUNT",
    CURRENCIES: "COLLABORATION_CURRENCIES",
    REVIEW_QUESTIONS: "COLLABORATION_REVIEW_QUESTIONS",
    IS_ALREADY_RATED: "COLLABORATION_IS_ALREADY_RATED",
  },
  CREATE_COLLABORATION: {
    IS_LOADING: "CREATE_COLLABORATION_IS_LOADING",
    OPPORTUNITY_LIST: "CREATE_COLLABORATION_OPPORTUNITY_LIST",
    IPROS_LIST: "CREATE_COLLABORATION_IPROS_LIST",
    SELECTED_IPRO: "CREATE_COLLABORATION_SELECTED_IPRO",
    FORM_COLLAPSED: "CREATE_COLLABORATION_FORM_COLLAPSED",
    DETAIL_COLLAPSED: "CREATE_COLLABORATION_DETAIL_COLLAPSED",
    DIALOG_MESSAGE: "CREATE_COLLABORATION_DIALOG_MESSAGE",
    COLLABORATION_FORM: "CREATE_COLLABORATION_COLLABORATION_FORM",
  },
  FEEDBACK: {
    FEEDBACK_LIST: "FEEDBACK_FEEDBACK_LIST",
    SELECTED_FEEDBACK: "FEEDBACK_SELECTED_FEEDBACK",
    IS_CREATENEW: "FEEDBACK_IS_CREATENEW",
    FEEDBACK_FORM: "FEEDBACK_FEEDBACK_FORM",
    DIALOG_MESSAGE: "FEEDBACK_DIALOG_MESSAGE",
    DELETED_ID: "FEEDBACK_DELETED_ID",
    FILTERED_LIST: "FEEDBACK_FILTERED_LIST",
    LIST_COLLAPSED: "FEEDBACK_LIST_COLLAPSED",
    DETAIL_COLLAPSED: "FEEDBACK_DETAIL_COLLAPSED",
    FORM_COLLAPSED: "FEEDBACK_FORM_COLLAPSED",
    SEARCH_KEY: "FEEDBACK_SEARCH_KEY",
    IS_FETCHING: "FEEDBACK_IS_FETCHING",
  },
  ABOUT: {
    FAQS_LIST: "ABOUT_FAQS_LIST",
    ABOUT_PRODOO_LIST: "ABOUT_PRODOO_LIST",
    LIST_COLLAPSED: "ABOUT_LIST_COLLAPSED",
    DETAIL_COLLAPSED: "ABOUT_DETAIL_COLLAPSED",
    SELECTED_ITEM: "ABOUT_SELECTED_ITEM",
    IS_FETCHING: "ABOUT_IS_FETCHING",
    EXPANDED: "ABOUT_EXPANDED",
  },
  SETTING: {
    USER_PHONE_LIST: "SETTING_USER_PHONE_LIST",
    USER_EMAIL_LIST: "SETTING_USER_EMAIL_LIST",
    SELECTED_PROFILE: "SETTING_SELECTED_PROFILE",
    COUNTRIES: "SETTING_COUNTRIES",
    USER_SOCIAL_MEDIA: "USER_SOCIAL_MEDIA",
    SOCIAL_MEDIA: "PRESENT_SOCIAL_MEDIA",
    DIALOG_MESSAGE: "SETTING_DIALOG_MESSAGE",
    DIALOG_MESSAGE_PASS: "SETTING_DIALOG_MESSAGE_PASS",
    IS_INVALIDUSER_FIRST_NAME: "SETTING_IS_INVALIDUSER_FIRST_NAME",
    IS_INVALIDUSER_LAST_NAME: "SETTING_IS_INVALIDUSER_LAST_NAME",
    IS_INVALID_PHONE: "SETTING_IS_INVALID_PHONE",
    IS_INVALID_EMAIL: "SETTING_IS_INVALID_EMAIL",
    IS_USER_NAME_SELECTED: "SETTING_IS_USER_NAME_SELECTED",
    IS_PHONE_NUMBER_SELECTED: "SETTING_IS_PHONE_NUMBER_SELECTED",
    ADD_ITEM_MESSAGE: "SETTING_ADD_ITEM_MESSAGE",
    IS_EMAIL_SELECTED: "SETTING_IS_EMAIL_SELECTED",
    IS_SOCIALMEDIA_SELECTED: "IS_SOCIALMEDIA_SELECTED",
    IS_DELETE_EMAIL: "SETTING_IS_DELETE_EMAIL",
    IS_DELETE_PHONE_NUMBER: "SETTING_IS_DELETE_PHONE_NUMBER",
    EMAILID_TO_DELETE: "SETTING_EMAILID_TO_DELETE",
    PHONE_ID_TO_DELETE: "SETTING_PHONE_ID_TO_DELETE",
    IS_CHANGE_PASS_SELECTED: "SETTING_IS_CHANGE_PASS_SELECTED",
    IS_EMAIL_NOTIFICATION_SELECTED: "SETTING_IS_EMAIL_NOTIFICATION_SELECTED",
    RIGHT_COLOUM_HEADING: "SETTING_RIGHT_COLOUM_HEADING",
    IS_LOADING: "SETTING_IS_LOADING",
    IS_FETCHING: "SETTING_IS_FETCHING",
    PASSWORD_EMAILS_LIST: "SETTING_PASSWORD_EMAILS_LIST",
    SETTINGS_LIST: "SETTING_SETTINGS_LIST",
    EXPANDED: "SETTING_EXPANDED",
    LIST_COLLAPSED: "SETTING_LIST_COLLAPSED",
    FORM_COLLAPSED: "SETTING_FORM_COLLAPSED",
    INVALID_OLD_PASSWORD: "SETTING_INVALID_OLD_PASSWORD",
    SELECTED_ITEM: "SETTING_SELECTED_ITEM",
    OLD_PASSWORD: "SETTING_OLD_PASSWORD",
    NEW_PASSWORD: "SETTING_NEW_PASSWORD",
    CONFIRM_PASSWORD: "SETTING_CONFIRM_PASSWORD",
    USER_ID: "SETTING_USER_ID",
    USER_NOTIFICATION_ID: "SETTING_USER_NOTIFICATION_ID",
    NOTIFICATION_LIST: "SETTING_NOTIFICATION_LIST",
    IS_FETCH_NOTIFICATION: "SETTING_IS_FETCH_NOTIFICATION",
  },
  phillipSearcherModal:{
    SHOW_MODAL: "SHOW_MODAL",

  }
};
