import { ActionTypes } from "../../actions";
export const onStateChangeAction = ({
  filterSavedSearch,
  savedSearches,
  selectedSavedSearch,
  savedSearchSettings,
  selectedSavedSearchName
}) => dispatch => {
  filterSavedSearch !== undefined &&
    dispatch(filterSavedSearchAction({ filterSavedSearch }));
  savedSearches !== undefined &&
    dispatch(savedSearchesAction({ savedSearches }));
  selectedSavedSearch !== undefined &&
    dispatch(selectedSavedSearchAction({ selectedSavedSearch }));
  savedSearchSettings !== undefined &&
    dispatch(savedSearchSettingsAction({ savedSearchSettings }));
  selectedSavedSearchName !== undefined &&
    dispatch(selectedSavedSearchNameAction({ selectedSavedSearchName }));
};

const filterSavedSearchAction = ({ filterSavedSearch }) => dispatch => {
  dispatch({
    type: ActionTypes.SAVED_SEARCH.FILTER_SAVED_SEARCH,
    payload: filterSavedSearch
  });
};
const savedSearchesAction = ({ savedSearches }) => dispatch => {
  dispatch({
    type: ActionTypes.SAVED_SEARCH.SAVED_SEARCHES,
    payload: savedSearches
  });
};
const selectedSavedSearchAction = ({ selectedSavedSearch }) => dispatch => {
  dispatch({
    type: ActionTypes.SAVED_SEARCH.SELECTED_SAVED_SEARCH,
    payload: selectedSavedSearch
  });
};
const savedSearchSettingsAction = ({ savedSearchSettings }) => dispatch => {
  dispatch({
    type: ActionTypes.SAVED_SEARCH.SAVED_SEARCH_SETTINGS,
    payload: savedSearchSettings
  });
};
const selectedSavedSearchNameAction = ({
  selectedSavedSearchName
}) => dispatch => {
  dispatch({
    type: ActionTypes.SAVED_SEARCH.SELECTED_SAVED_SEARCH_NAME,
    payload: selectedSavedSearchName
  });
};
