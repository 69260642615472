import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Checkbox } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import Input from "antd/lib/input";
import { loginAuth } from "./loginAction";
import {
  publicRoutes,
  appsRoutes,
} from "../../../../Routes/routing";
import { isValidEmail } from "../../../../utilities";
import { notificationAction } from "../../../../actions";
import {
  RegisterThirdPartyApi,
  igeLoginApi,
  genetrateTokenApi,
  getForgotPasswordApi,
} from "../../leadPageApi";
import { Button, Notification } from "../../../../common";
import classes from "./mobile-login.module.scss";
export function CustomInput({
  suffix,
  placeholder,
  Password,
  type,
  invalid,
  ...rest
}) {
  return (
    <div className={`${classes.customInput} tictell-input`}>
      {!Password ? (
        <Input
          placeholder={placeholder}
          suffix={suffix}
          type={type}
          bordered={false}
          className={`${classes.input} ${invalid ? classes.inValid : ""}`}
          {...rest}
        />
      ) : (
        <Input.Password
          placeholder="Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          bordered={false}
          className={`${classes.input} ${invalid ? classes.inValid : ""}`}
          {...rest}
        />
      )}
    </div>
  );
}

class MobileLoginForm extends Component {
  constructor(props) {
    super(props);
    this.facebookClientId = "";
    this.setFacebookClientId();
    this.state = {
      email: "",
      password: "",
      appType: "snapshot",
      isLoading: false,
      isFacebookConnected: false,
      IgeDomains: [],
      dialogMessage: "",
      showSignup: false,
      isRemembered: false,
      errorMessage: "",
      onForgot: false,
    };
  }

  componentDidMount() {
    let appType = JSON.parse(sessionStorage.getItem("appType"));
    let loginCreds = JSON.parse(window.sessionStorage.getItem("loginCreds"));
    if (loginCreds !== null) {
      this.setState({
        email: loginCreds.email,
        password: loginCreds.password,
        isRemembered: true,
      });
    }

    this.setState({
      appType: appType
    });
  }

  onRememberChange = () => {
    this.setState({
      isRemembered: !this.state.isRemembered,
    });
  };

  setFacebookClientId = () => {
    let facebookClientId = "353782061888429"; //old key "359778238088735";
    if (window.location.hostname.indexOf("devapp.prodoo") > -1) {
      facebookClientId = "2222598844473308";
    } else if (window.location.hostname.indexOf("stageapp.prodoo") > -1) {
      facebookClientId = "305866216786160";
    } else if (window.location.hostname.indexOf("prodoo.com") > -1) {
      facebookClientId = "2074014402635590";
    }
    this.facebookClientId = facebookClientId;
  };
  handleFacebookLoginSuccess = (response) => {
    const { id, name, email, first_name, last_name } = response.profile;
    const user = {
      Name: name,
      FirstName: first_name,
      LastName: last_name,
      ExternalId: id,
      Email: email,
      RegistrationTypeId: 2,
      OAuthProviderId: id,
      IsLoginByMobile: false,
    };
    RegisterThirdPartyApi(user)
      .then((response) => {
        if (response.success) {
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      })
      .catch((error) => console.log("Error in third party api ", error));
  };
  handleFacebookFailure = (err) => {};
  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.handleSubmit();
    }
  };
  handleIgeActionClicks = (domain) => {
    const { email, password } = this.state;
    const {
      notificationAction,
      loginAuth,
      showResendPopUp,
      location,
      history,
    } = this.props;
    if (domain == "Dismiss") {
      this.setState({
        dialogMessage: "",
        IgeDomains: [],
      });
      return;
    }
    if (domain == "Current") {
      this.setState({
        dialogMessage: "",
        IgeDomains: [],
      });
      this.loginToCore(
        loginAuth,
        email,
        password,
        location,
        history,
        showResendPopUp
      );
      return;
    }
    genetrateTokenApi(domain, email).then((res) => {
      if (res.success) {
        var token = res.items;
        if (token) {
          window.location.replace(
            domain +
              "/#" +
              publicRoutes.redirectedfrom.path +
              `?redirectedFrom=${token}`
          );
        }
      }
    });
  };

  handleForgetPasswordChange = (value) => {
    this.setState({
      onForgot: value,
    });
  };

  handleSubmit = () => {
    const { email, password } = this.state;
    const {
      notificationAction,
      loginAuth,
      showResendPopUp,
      location,
      history,
    } = this.props;
    let isError = false;
    if (!isValidEmail(email)) {
      isError = true;
      this.setState({
        invalidemail: true,
      });
    }
    if (!password) {
      isError = true;
      this.setState({
        invalidpassword: true,
      });
    }
    if (isError) {
      const info = {
        message: this.props.LOGIN_VALIDATION_ERROR_MESSAGE,
        status: "error",
      };
      notificationAction(info);
      return;
    }
    this.loginToCore(
      loginAuth,
      email,
      password,
      location,
      history,
      showResendPopUp
    );
    return;
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (!value || (name === "email" && !isValidEmail(value))) {
      this.setState({
        [`invalid${name}`]: true,
        [name]: value,
      });
      return;
    } else {
      this.setState({
        [`invalid${name}`]: false,
        [name]: value,
      });
    }
  };
  handleFacebookClick = () => {
    this.setState({ isFacebookConnected: !this.state.isFacebookConnected });
  };
  loginToCore(loginAuth, email, password, location, history, showResendPopUp) {
    const loginModel = {
      email,
      password,
    };
    this.setState({isLoading: true});
    loginAuth(loginModel).then((response) => {
      if (response.success) {
        const { appType } = this.state;
        let redirectAppPath = "";

        switch (appType) {
          case "snapshot":
            redirectAppPath = appsRoutes.snapshot.path;
            break;
          case "tictell":
            redirectAppPath = appsRoutes.tictell.path;
            break;
          case "searcherPhilip":
            redirectAppPath = appsRoutes.philip.path;
            break;
          case "iproPhilip":
            redirectAppPath = appsRoutes.iproPhilip.path;
            break;
          case "sense":
            redirectAppPath = appsRoutes.sense.path;
            break;
          case "headsup":
            redirectAppPath = appsRoutes.headsup.path;
            break;
          case "embark":
            redirectAppPath = appsRoutes.embark.path;
            break;
          case "assort":
            redirectAppPath = appsRoutes.assort.path;
            break;
          case "nextStep":
            redirectAppPath = appsRoutes.nextstep.path;
            break;
          default:
            break;
        }
        this.setState({isLoading: false});
        if (this.state.isRemembered) {
          sessionStorage.setItem("loginCreds", JSON.stringify(loginModel));
        } else {
          sessionStorage.removeItem("loginCreds");
        }
        history.push(redirectAppPath);
      } else {
        this.setState({isLoading: false});
        if (response.items && response.items.IsEmailAvailable) {
          this.setState({
            errorMessage: response.message,
          });
          showResendPopUp(response.message);
        } else {
          const info = {
            message: response.message,
            status: "error",
          };
          this.setState({
            errorMessage: response.message,
          });
          this.props.notificationAction(info);
        }
      }
    });
  }

  handleForgotPassword = () => {
    const { email } = this.state;
    const { labels } = this.props;
    if (!email || !isValidEmail(email)) {
      const info = {
        message: labels.FORGOT_PASSWORD_VALIDATION_ERROR_MESSAGE,
        status: "error",
      };
      this.props.notificationAction(info);
      this.setState({
        invalidemail: true,
      });
      return;
    }
    getForgotPasswordApi(email)
      .then((response) => {
        const status = response.success ? "success" : "error";
        const info = {
          message: response.message,
          status,
        };
        this.props.notificationAction(info);
        return;
      })
      .catch((error) => console.log(error));
  };

  onSignupClick = () => {
    this.setState({
      showSignup: !this.state.showSignup,
    });
    return;
  };

  renderImage() {
    const { appType } = this.state;
    let imgData = null;
    switch (appType) {
      case "snapshot":
        imgData = (
          <img
            src={require("../../../../assets/images/products/6.png")}
            alt={appType}
            className="TickTel"
          />
        );
        break;
      case "tictell":
        imgData = (
          <img
            src={require("../../../../assets/images/tictell/tictell.png")}
            alt={appType}
            className="TickTel"
          />
        );
        break;
      case "nextStep":
        imgData = (
          <img
            src={require("../../../../assets/images/apps-icons/NextStep.png")}
            alt={appType}
            className="TickTel"
          />
        );
        break;
      case "assort":
        imgData = (
          <img
          src={require("../../../../assets/images/apps-icons/assort.png")}
            alt={appType}
            className="TickTel"
          />
        );
        break;
      case "embark":
        imgData = (
          <img
          src={require("../../../../assets/images/apps-icons/embark.png")}
            alt={appType}
            className="TickTel"
          />
        );
        break;
      case "headsup":
        imgData = (
          <img
          src={require("../../../../assets/images/apps-icons/headsup.png")}
            alt={appType}
            className="TickTel"
          />
        );
        break;
      case "sense":
      case "iproPhilip":
        imgData = (
          <img
          src={require("../../../../assets/images/apps-icons/phillipSearcher.png")}
            alt={appType}
            className="TickTel"
          />
        );
        break;
      case "searcherPhilip":
        imgData = (
          <img
          src={require("../../../../assets/images/apps-icons/phillipSearcher.png")}
            alt={appType}
            className="TickTel"
          />
        );
        break;
      default:
        break;
    }
    return imgData;
  }

  render() {
    const {
      email,
      password,
      invalidemail,
      invalidpassword,
      onForgot,
      isLoading,
      isRemembered,
    } = this.state;
    const { labels, notification } = this.props;
    return (
      <div className={classes.tictellWrapper}>
         {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        {notification.message && (
          <Notification
            status={notification.status}
            className={`${notification.className}`}
          >
            {notification.message}
          </Notification>
        )}
        <div className={classes.wrapper}>
          <div className={classes.logo}>
            
            {this.renderImage()}
          </div>
          {onForgot ? (
            <>
              <h1>{labels.FORGOT_PASS_FORM_HEAD}</h1>
              {this.state.errorMessage && (
                <div className={`text-danger ${classes.errorBadge}`}>
                  <span>{this.state.errorMessage}</span>
                </div>
              )}
              <CustomInput
                type="email"
                suffix={
                  <img
                    src={require(`../../../../assets/images/svg/Message.svg`)}
                  />
                }
                name="email"
                invalid={invalidemail}
                id="Email"
                placeholder={labels.loginEmail}
                value={email}
                onChange={this.handleFormFieldChange}
                onKeyPress={this.handleKeyPress}
                autoComplete="new-email"
              />
              <p></p>
              <Button onClick={this.handleForgotPassword}>Submit</Button>
              <p></p>
              <Button onClick={(e) => this.handleForgetPasswordChange(false)}>
                {labels.loginLoginButton}
              </Button>
            </>
          ) : (
            <>
              <h1>Log in</h1>
              {this.state.errorMessage && (
                <div className={`text-danger ${classes.errorBadge}`}>
                  <span>{this.state.errorMessage}</span>
                </div>
              )}
              <CustomInput
                type="email"
                suffix={
                  <img
                    src={require(`../../../../assets/images/svg/Message.svg`)}
                  />
                }
                name="email"
                invalid={invalidemail}
                id="Email"
                placeholder={labels.loginEmail}
                value={email}
                onChange={this.handleFormFieldChange}
                onKeyPress={this.handleKeyPress}
                autoComplete="new-email"
              />

              <div className={classes.inputPass}>
                <CustomInput
                  Password={true}
                  id="Password"
                  name="password"
                  type="password"
                  placeholder={labels.loginPassword}
                  value={password}
                  onChange={this.handleFormFieldChange}
                  onKeyPress={this.handleKeyPress}
                  autoComplete="new-password"
                  invalid={invalidpassword}
                />
              </div>
              <div className={classes.forget}>
                <Checkbox
                  onChange={this.onRememberChange}
                  checked={isRemembered}
                  className="tictell-login-checkbox"
                >
                  <span className={classes.rememberMeTxt}>Remember Me</span>
                </Checkbox>
                <div>
                  <a onClick={(e) => this.handleForgetPasswordChange(true)}>
                    Forget Password?
                  </a>
                </div>
              </div>
              <Button onClick={this.handleSubmit}>Log in</Button>
              <p></p>
              {/* <p>OR</p>
              <Button onClick={this.onSignupClick}>Sign Up</Button> */}
              {this.state.showSignup && (
                <div className={`${classes.infoBadge}`}>
                  <span>
                    This app requires a ProDoo account, please visit
                    <a href="https://prodoo.com/" target="_blank">
                      prodoo.com
                    </a>
                    to register.
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        <div>
          <div className={classes.powered}>Powered By</div>
          <div className={classes.footer}>
            <img
              className="TickTel"
              src={require(`../../../../assets/images/logo.png`)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, notification }) => {
  const { labels } = systemLabel;
  return { labels, notification };
};

const mapActionToProps = { loginAuth, notificationAction };
export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(MobileLoginForm));
