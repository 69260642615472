import React, { Component } from "react";
import { BulletList, EmptyInfo } from "../../../../../common";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import "./opportunityList.scss";
class OpportunityList extends Component {
  renderDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  getReadUnReadClass = (item) => {
    return !item.IsRead ? "listLabel activeItem" : "listLabel";
  };

  isActiveCollaboration = ({ collaboration }) => {
    const { IsSearcher } = this.props;
    const { IsAccepted } = collaboration;
    if (IsSearcher) {
      return false;
    }
    return !IsAccepted;
  };

  render() {
    const {
      opportunityList,
      selectedOpportunity,
      onOpportunitySelect,
      handleDeleteOpportunityNew,
      iproOpportunityListItem,
      labels
    } = this.props;
    return (
      <>
        {opportunityList?.length > 0 ? (
          <BulletList
            className="opportunity-list-component"
            testId="ipro-Opportunity-list"
          >
            {opportunityList.map((item, index) => (
              <BulletList.ListItem
                isActive={!item.IsRead}
                isSelected={item.RequestId === selectedOpportunity.RequestId}
                onClick={() => onOpportunitySelect(item)}
                key={index}
                testId={iproOpportunityListItem}
              >
                <div
                  //className="listLabel"
                  className={this.getReadUnReadClass(item)}
                >
                  {item.RequestName}
                </div>
                <div
                  testId="iProOpportunity-delete-item"
                  onClick={(e) => {
                    handleDeleteOpportunityNew(item);
                    e.stopPropagation();
                  }}
                  className="close_btn_purple for-list"
                >
                  <i className="fa fa-times-circle" />
                </div>
              </BulletList.ListItem>
            ))}
          </BulletList>
        ) : (
          <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
        )}
      </>
    );
  }
}
export default OpportunityList;
