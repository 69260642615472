import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  shortListResume: [],
  shortListResumeList: [],
  filteredShortlist: [],
  selected: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.NEWSEARCH.SHORTLIST.SHORTLIST_LIST:
      return {
        ...state,
        shortListResumeList: action.payload,
      };
    case ActionTypes.NEWSEARCH.SHORTLIST.FILTERED_SHORTLIST:
      return {
        ...state,
        filteredShortlist: action.payload,
      };
    case ActionTypes.NEWSEARCH.SHORTLIST.SHORTLIST_RESUME:
      return { ...state, shortListResume: action.payload };
    case ActionTypes.NEWSEARCH.SHORTLIST.SELECTED:
      return { ...state, selected: action.payload };
    case ActionTypes.NEWSEARCH.DEFAULT_LIST:
      return { ...state, defaultList: action.payload };
    default:
      return state;
  }
};
