import React, { Component } from "react";
import { Panel, ListGroup, Input, Select } from "../../../../common";
import { getAllLocationsApi } from "../resumeEditApi";

class LoactionPanel extends Component {
  state = {
    locations: [],
  };
  getAllLocations = () => {
    getAllLocationsApi().then((response) => {
      if (response.success) {
        const locations = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        this.setState({ locations });
      }
    });
  };
  componentDidMount = () => {
    this.getAllLocations();
  };

  render() {
    const {
      isHelpActive,
      locationList,
      onLocationChange,
      onLocationBlur,
      onLocationAdd,
      onLocationDelete,
      labels,
    } = this.props;
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>{labels.RESUME_EDIT_LOCATION_SECTION_TITLE}</Panel.Title>
          <Panel.Add
            onClick={onLocationAdd}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={labels.RESUME_EDIT_LOCATION_ADD_BUTTON_TOOLTIP}
            tooltipHelp={labels.RESUME_EDIT_LOCATION_ADD_BUTTON_HELP}
            testId="resume-edit-location-add-btn"
            testIdHelp="resume-edit-location-add-help-btn"
          />
        </Panel.Heading>
        <Panel.Body>
          <ListGroup>
            {locationList.map((item) => (
              <ListGroup.Item key={item.uniqueId}>
                <ListGroup.Column>
                  <Select
                    name={"Locations"}
                    className={`select-location select-input ${
                      item.isInvalid ? "inValid" : ""
                    }`}
                    value={{
                      value: item.CountryId,
                      label: item.CountryName,
                    }}
                    onChange={(options) =>
                      onLocationChange(options, item.uniqueId)
                    }
                    placeholder={labels.RESUME_EDIT_LOCATION_NAME_PLACEHOLDER}
                    options={this.state.locations}
                    clearable
                    onBlur={() => onLocationBlur(item.CountryId, item.uniqueId)}
                  />
                </ListGroup.Column>
                <ListGroup.Column size={0}>
                  <div
                    // disabled={isDisabled}
                    onClick={() => onLocationDelete(item)}
                    className="close_btn_purple"
                  >
                    <i className="fa fa-times-circle" />
                  </div>
                </ListGroup.Column>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Panel.Body>
      </Panel>
    );
  }
}

export default LoactionPanel;
