import React, { useState, useEffect } from "react";
import DisplayComponent from "./Components/DisplayComponent";
import BtnComponent from "./Components/BtnComponent";
import "./timerComponent.scss";
import moment from "moment";
import { useStopwatch, StopwatchSettings } from "react-timer-hook";

function TimerComponent({ saveTimelog, isTimerOn, seconds, minutes, hours, reset, pause, start }) {
  const [storedTime, setTime] = useState({ s: 0, m: 0, h: 0 });
  const [status, setStatus] = useState(0);
  const [startTime, setStartTime] = useState(0);
  // window.onbeforeunload = (event) => {
  //   const e = event || window.event;
  //   setStatus(2);
  //   let timerDetails = {
  //     time: { hours, minutes, seconds },
  //     status: 2,
  //     isTimerOn,
  //   };
  //   localStorage.setItem("tictellTimer", JSON.stringify(timerDetails));
  // };

  // window.addEventListener("beforeunload", (ev) => {
  //   if (parseInt(seconds) > 0 || parseInt(minutes) > 0 || parseInt(hours) > 0){
  //     ev.preventDefault();
  //     return (ev.returnValue = "Are you sure you want to close?");
  //   }
  // });


  const startTimer = () => {
    start();
    setStatus(1);
    setStartTime(new Date().toTimeString().slice(0, 8));
    let timer = {
      status: 1,
      isTimerOn: true
    }
    sessionStorage.setItem("tictellTimer", JSON.stringify(timer));
  };

  useEffect(() => {
    let tictellTimerLocalStorage = JSON.parse(
      sessionStorage.getItem("tictellTimer")
    );
    if (tictellTimerLocalStorage) {
      setStatus(tictellTimerLocalStorage.status);
    }
  }, []);

  const pauseTimer = () => {
    setStatus(2);
    pause();
    let timer = {
      status: 2,
      isTimerOn: true
    }
    sessionStorage.setItem("tictellTimer", JSON.stringify(timer));
  };

  const stopTimer = () => {
    // this.props.handleTimelogDateChange(new Date())
    let startTimeParsed = moment(startTime, "h:mm:ss").format();
    let endTimeParsed = moment(startTime, "h:mm:ss")
      .add(hours, "hours")
      .add(minutes, "minutes")
      .format();
    let timerModel = {
      Date: new Date(),
      StartTime: startTimeParsed,
      EndTime: endTimeParsed,
    };
    saveTimelog(timerModel);
    // clearInterval(interv);
    setStatus(0);
    pause();
    reset(null, false);
    sessionStorage.removeItem("tictellTimer");
  };

  const resumeTimer = () => startTimer();

  return (
    <div className="main-section">
      {/* <div className="clock-holder">
        <div className="stopwatch">
          <DisplayComponent time={time} />
          <BtnComponent status={status} resume={resume} stop={stop} pause={pause} start={start} />
        </div>
      </div> */}
      <div className="clock-holder">
        <div className="stopwatch">
          <DisplayComponent time={{ h: hours, m: minutes, s: seconds }} />
          <BtnComponent
            status={status}
            resume={resumeTimer}
            stop={stopTimer}
            pause={pauseTimer}
            start={startTimer}
          />
        </div>
      </div>
    </div>
  );
}

export default TimerComponent;
