import React, { Component } from "react";
import { Button } from "../../../../common";
import moment from "moment";
import "./opportunitydetail.scss";
import { Col, Row, Select } from "antd";
import MenuDots from "../../../../assets/newResume/menuDots";
class OpportunityDetail extends Component {
  renderDate = (date) => {
    return moment(date).format("MM/DD/YY");
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOpportunity !== this.props.selectedOpportunity) {
      this.props.onSelectedActionChange({ selectedAction: "" });
    }
  }
  render() {
    const {
      selectedOpportunity,
      onAcceptedResumes,
      onDeclinedResumes,
      onNoActionResumes,
      labels,
      selectedAction,
      onSelectedActionChange,
      declinedRequests,
      acceptedRequests,
      pendingRequests,
      selectedShortlists,
      handleListOpenMobile,
    } = this.props;

    return (
      <div className="opportunity-detail-component">
        <div className="MsgWrapper">
          <div className="titleLabel">{selectedOpportunity?.RequestName}</div>
          <div className="feedbackWrap">
            <div className="msgItemDiv">
              <div className="msgItem">
                <label className="labelName">
                  {labels.SearcherOpportunitySentDetailStartDate}
                </label>
                <label className="answerLabel">
                  {this.renderDate(selectedOpportunity?.StartDate)}
                </label>
              </div>
              <div className="msgItem">
                <label className="labelName">
                  {labels.SearcherOpportunitySentDetailDuration}
                </label>
                <label className="answerLabel">
                  {selectedOpportunity?.Duration}&nbsp;
                  {selectedOpportunity?.DurationType}
                </label>
              </div>
              <div className="msgItem">
                <label className="labelName">
                  {labels?.SearcherOpportunitySentDetailFee}
                </label>
                <label className="answerLabel">
                  {selectedOpportunity?.HourlyFee} &nbsp;
                  {selectedOpportunity?.FeeCurrencyType}
                </label>
              </div>
              <div className="msgItem">
                <label className="labelName">
                  {labels?.SearcherOpportunitySentDetailLocations}
                </label>
                <label className="answerLabel">
                  {selectedOpportunity?.Countries}
                </label>
              </div>
              <div className="msgItem">
                <label className="labelName">
                  {labels?.SearcherOpportunitySentDetailLanguages}
                </label>
                <label className="answerLabel">
                  {selectedOpportunity?.Languages}
                </label>
              </div>
              <div className="msgItem">
                <label className="labelName">
                  {labels?.SearcherOpportunitySentDetailCompany}
                </label>
                <label className="answerLabel">
                  {selectedOpportunity?.CompanyName}
                </label>
              </div>
              <div className="msgItem">
                <label className="labelName">
                  {labels?.SearcherOpportunitySentDetailSavedSearch}
                </label>
                <label className="answerLabel">
                  {selectedOpportunity?.SearchName}
                </label>
              </div>

              <div className="msgItem">
                <label className="labelName">
                  {labels?.SearcherOpportunitySentDetailDescription}
                </label>
                <label className="answerLabel">
                  {selectedOpportunity?.Description} &nbsp;
                </label>
              </div>
              <Row
                className="msgItem"
                style={{ alignItems: "center", paddingBottom: "10px" }}
              >
                <Col xs={22} md={24}>
                  <div>
                    <Select
                      size="large"
                      className={`bg-color-for-opportunity  ${
                        selectedOpportunity?.invalidShortList ? "inValid" : ""
                      }`}
                      bordered={false}
                      style={{ width: "100%" }}
                      value={selectedShortlists && selectedShortlists}
                      placeholder="Search and add your shortlist"
                      // onChange={(e, option) => handleSelectedShortlistClick(option)}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={selectedShortlists || []}
                    />
                  </div>
                </Col>
                <Col md={0} xs={2} onClick={() => handleListOpenMobile()}>
                  <MenuDots style={{ float: "right" }} />
                </Col>
              </Row>
            </div>
            <div className="action-buttons">
              <Button
                testId="sent-opportunity-detail-pending-label"
                className={`opp-pending ${
                  selectedAction === "pending" ? "active" : ""
                }`}
                onClick={() => {
                  onNoActionResumes();
                  onSelectedActionChange({ selectedAction: "pending" });
                }}
              >
                {labels?.SearcherOpportunitySentDetailPending}&nbsp;
                {pendingRequests || 0}
              </Button>
              <Button
                testId="sent-opportunity-detail-accepted-label"
                className={`opp-accepted ${
                  selectedAction === "accepted" ? "active" : ""
                }`}
                onClick={() => {
                  onAcceptedResumes();
                  onSelectedActionChange({ selectedAction: "accepted" });
                }}
              >
                {labels?.SearcherOpportunitySentDetailAccepted}&nbsp;
                {acceptedRequests || 0}
              </Button>
              <Button
                testId="sent-opportunity-detail-declined-label"
                onClick={() => {
                  onDeclinedResumes();
                  onSelectedActionChange({ selectedAction: "declined" });
                }}
                className={`opp-declined ${
                  selectedAction === "declined" ? "active" : ""
                }`}
              >
                {labels?.SearcherOpportunitySentDetailDeclined} &nbsp;
                {declinedRequests || 0}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OpportunityDetail;
