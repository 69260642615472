import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert, Select } from "antd";
import classes from "./index.module.scss";
import { getCertificationsApi } from "../../dashboardApi";
import FeatureCard from "../../components/FeatureCard/FeatureCard";
import DebounceSelect from "../../../../components/DebounceSelect/DebounceSelect";
import closeIcon from '../../../../assets/images/Icons/close-new.png'
import LanguageImage from '../../../../assets/images/certification-popup.png'


function EditCertificationPopup({
  isLoading,
  SaveResumeDetails,
  errorMessage,
  resumeCertificationEdit,
  handleCertificationChange,
  labels,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [yearsList, setYears] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    handleYearsLoad();
  }, []);

  const onFinish = () => {
    setIsModalVisible(false);
    SaveResumeDetails();
  };
  const handleYearsLoad = () => {
    const currentYear = new Date().getFullYear();
    var startYear = 1970;
    var yearsList = [];
    while (startYear <= currentYear) {
      startYear++;
      const newYear = {
        value: startYear,
        label: startYear,
      };
      yearsList.push(newYear);
    }
    yearsList = yearsList.sort(function (a, b) {
      return b.value - a.value;
    });
    setYears(yearsList);
  };

  return (
    <>
      <button className="add-profile"
        onClick={() => setIsModalVisible(true)}
      >{labels.AddResumeHeadingCertification}</button>

      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        className={`new-design-modal ${classes.popUpForm}`}
      >
        <div className="text-center mb-3 header-modal">
          <h5>{labels.AddResumeEditHeadingLanguage}</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="images-icon">
          <img src={LanguageImage} alt="" />
          <p className="paragraph">Please select your certification and date of achievement</p>
        </div>
        <Form
          name="normal_login"
          className={`${classes.form} form`}
          onFinish={onFinish}
          size="large"
        // initialValues={{ date: yearsList[10] }}
        >
          <Form.Item
            rules={[
              {
                required: resumeCertificationEdit.CertificationId
                  ? false
                  : true,
                message:
                  labels.SELECT_CERTIFICATION_LABEL ||
                  "Please select Certification",
              },
            ]}
            className={`${classes.customSelect}`}
            name="certification"
          >
            <DebounceSelect
              mode="multiple"
              selectName={"CertificationId"}
              placeholder={labels.SELECT_CERTIFICATION_LABEL}
              fetchOptions={getCertificationsApi}
              handleChange={handleCertificationChange}
            />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: resumeCertificationEdit.CertificationDate
                  ? false
                  : true,
                message:
                  labels.SELECT_YEAR_VALIDATION_LBL || "Please select Year",
              },
            ]}
            className={`${classes.customSelect}`}
            name="date"
          >
            <Select
              placeholder={labels.snapshotDate}
              onChange={(e) =>
                handleCertificationChange("CertificationDate", e)
              }
              allowClear
              name={"date"}
              autoComplete="new-password"
            >
              {yearsList &&
                yearsList.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          {error && (
            <Alert
              message={errorMessage?.replace("<br />", "")}
              type="warning"
              closable
            />
          )}

          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-form-button mt-2"
            loading={isLoading}
          >
            {labels.Done_LBL}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default EditCertificationPopup;
