import React, { Component, Fragment } from "react";
import Loader from "../../assets/images/loader.gif";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Fragment>
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
            <h1 className="error-msg">Something went wrong! Please try again</h1>
          </div>
        </Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
