import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import { PageWrapper } from "../../../components";
import { LoadingMaskRow, LoadingMask, ConfirmDialog, EmptyInfo } from "../../../common";
import "./collaboration.scss";
import {
  getAllCollaborationApi,
  updateCollaborationApi,
  readCollaborationApi,
  getCurrenciesApi,
  getCollaborationReviewApi,
  getCollaborationReasonsApi,
  getReviewQuestions,
  submitCollaborationReview,
  deleteCollaborationApi,
  // collaborationReviewApi,
} from "./collaborationApi";
import { unreadCollaborationsAction } from "../../../actions/navigationActions";
import CompanyDetail from "../../Company/components/CompanyDetail";
import { onStateChangeAction } from "./collaborationAction";
import toLower from "lodash/toLower";
import { isNullOrUndefined } from "util";
import { Col, Input, Row, Select, Space } from "antd";
import NewCollaborationDetail from "../NewSearcher/components/common/NewCollaborationDetail";
import NewUserDetail from "./components/newUserDetail";
import MenuDots from "../../../assets/newResume/menuDots";
import moment from "moment";
import NewRatingView from "./components/newRatingView";
import UserDetailModal from "./components/mobileModal";
import CompanyDetailNew from "../../../common/companyDetail/companyDetail";
import ArrowLeft from "../../../assets/images/Icons/arrow-left.png";
import { withRouter } from "react-router-dom";
import { isMobileDevice } from "rc-menu/lib/util";
import { formatDate } from "../../../utilities";
import SearchIcon from '../../../assets/images/secrch-icon.png'

const { Option } = Select;
class NewCollaboration extends Component {
  state = {
    fetchingReviews: false,
    isDeleteCollaboration: false,
    CollborationCloseReasonId: null,
    windowWidth: window.innerWidth,
    isMobileDevice: false,
    active: "",
    mobileModal: false,
    options: [
      { value: 1, label: "Job completed successfully" },
      { value: 2, label: "Job cancelled due to freelancer performance" },
      { value: 3, label: "Job canceled for other reasons" },
      { value: 4, label: "Another Reason" },
    ],
  };

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    this.props.onStateChangeAction({
      selectedCollaboration: {},
    });
    const {
      isIproActiveCollaboration,
      isIproInActiveCollaboration,
      isSentView,
      isAcceptedView,
      isDeclinedView,
      isInActiveView,
    } = this.props;
    window.actionName = isSentView
      ? "searcherPending"
      : isAcceptedView
        ? "searcherAccepted"
        : isInActiveView
          ? "searcherInActive"
          : isDeclinedView
            ? "searcherDeclined"
            : isIproActiveCollaboration
              ? "iproActive"
              : isIproInActiveCollaboration
                ? "iproInActive"
                : "iproNew";
    this.getCurrencies();
    window.addEventListener("resize", this.handleResize);
    this.setState(st => ({ ...st, active: window.location.hash?.slice(1) }))
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  getCurrencies(access_token) {
    this.props.onStateChangeAction({ isLoading: true });
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const currencies = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          this.props.onStateChangeAction({
            currencies,
          });
          this.getAllCollaboration(currencies);
        } else {
          this.props.onStateChangeAction({ isLoading: false });
        }
      })
      .catch((err) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  }

  getAllCollaboration = (CurrenciesParams) => {
    const {
      url,
      isAcceptedView,
      isInActiveView,
      isDeclinedView,
      isSentView,
      isIproCollaboration,
      isIproNewCollaboration,
    } = this.props;
    let currencies = CurrenciesParams || this.props.currencies;
    this.props.onStateChangeAction({ fetchingCollaborations: true });
    getAllCollaborationApi({ url })
      .then((data) => {
        if (data.success) {
          let collaborations = [];
          if (data.items.length) {
            if (this.state?.windowWidth > 767) {
              this.handleSelectCollaboration(data?.items?.[0]);
            }
            collaborations = map(data.items, (collaboration) => {
              if (isSentView) {
                collaboration.IsRead = true;
              }
              if (isDeclinedView || isAcceptedView || isInActiveView) {
                collaboration.IsRead = collaboration.IsSearcherRead;
              }
              if (isIproCollaboration) {
                collaboration.IsRead = collaboration.IsIProRead;
              }
              if (!isNaN(collaboration.HourlyRateType)) {
                collaboration.HourlyRateType = currencies.find(
                  (a) => a.CurrencyId == collaboration.HourlyRateType
                ).Name;
              }
              return {
                ...collaboration,
                label: collaboration?.Title,
                value: collaboration?.CollaborationId,
              };
            });
          } else {
            collaborations = data.items;
          }
          this.props.onStateChangeAction({
            collaborationList: collaborations,
            // filteredCollaborations: collaborations,
            fetchingCollaborations: false,
          });
          const CollaborationId = this.props.locationProp.state
            ? this.props.locationProp.state.CollaborationId
            : null;
          const spliturl = this.props.locationProp.pathname.match(/\d+/g);
          var CollabIDFromURL = spliturl ? parseInt(spliturl?.[0]) : null;

          if (CollaborationId != null || CollabIDFromURL != null) {
            let filteredCollab = data.items.filter(
              (coll) =>
                coll.CollaborationId == CollaborationId ||
                coll.CollaborationId == CollabIDFromURL
            );
            if (filteredCollab && filteredCollab.length > 0) {
              if (this.state?.windowWidth > 767) {
                this.handleSelectCollaboration(filteredCollab[0]);
              }
            } else {
              const info = {
                message: "Collaboration has already been deleted by Searcher",
                status: "error",
              };
              this.props.notificationAction(info);
            }
          }
          this.filterList(this.props.searchKey);
          this.props.onStateChangeAction({ isLoading: false });
        } else {
          this.props.onStateChangeAction({ isLoading: false });
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  filterList = (searchKey) => {
    const { collaborationList } = this.props;
    const filteredCollaborations = filter(
      collaborationList,
      (collaboration) => {
        if (includes(toLower(collaboration.Title), toLower(searchKey))) {
          return collaboration;
        }
      }
    );
    this.props.onStateChangeAction({ filteredCollaborations });
  };
  handleSearchChange = (e) => {
    const searchKey = e.target.value;
    // const filteredCollaborations = filter(collaborationList, collaboration => {
    //   if (includes(collaboration.Title, searchKey)) {
    //     return collaboration;
    //   }
    // });
    this.props.onStateChangeAction({ searchKey });
    this.filterList(searchKey);
  };

  handleSelectCollaboration = (selectedCollaboration) => {
    const { CollaborationId } = selectedCollaboration;
    const { isInActiveView, isIproInActiveCollaboration } = this.props;
    if (selectedCollaboration.IsRead) {
    } else {
      if (!selectedCollaboration.IsRead) {
        const {
          acceptedCollaboration,
          declinedCollaboration,
          isAcceptedView,
          isInActiveView,
          isDeclinedView,
          isIproCollaboration,
          unreadproInactiveCollaborations,
          searcherInActiveCollaboration,
          isIproInActiveCollaboration,
          unreadCollaborations,
        } = this.props;
        readCollaborationApi({
          collaborationId: CollaborationId,
        })
          .then((response) => {
            let { collaborationList } = this.props;

            const unreadCollabaorations = filter(
              collaborationList,
              (item) => item.CollaborationId !== CollaborationId && !item.IsRead
            );

            if (isIproCollaboration) {
              this.props.unreadCollaborationsAction({
                unreadCollaborations: unreadCollabaorations.length,
                unreadproInactiveCollaborations: unreadproInactiveCollaborations,
              });
            }
            if (isIproInActiveCollaboration) {
              this.props.unreadCollaborationsAction({
                unreadproInactiveCollaborations: unreadCollabaorations.length,
                unreadCollaborations: unreadCollaborations,
              });
            }
            if (isInActiveView) {
              this.props.unreadCollaborationsAction({
                searcherInActiveCollaboration: unreadCollabaorations.length,
                declinedCollaboration: declinedCollaboration,
                acceptedCollaboration: acceptedCollaboration,
              });
            }
            if (isAcceptedView) {
              this.props.unreadCollaborationsAction({
                acceptedCollaboration: unreadCollabaorations.length,
                declinedCollaboration: declinedCollaboration,
                searcherInActiveCollaboration: searcherInActiveCollaboration,
              });
            }
            if (isDeclinedView) {
              this.props.unreadCollaborationsAction({
                acceptedCollaboration: acceptedCollaboration,
                declinedCollaboration: unreadCollabaorations.length,
                searcherInActiveCollaboration: searcherInActiveCollaboration,
              });
            }

            const filteredCollaborations = map(collaborationList, (item) => {
              if (item.CollaborationId === CollaborationId) {
                item.IsRead = true;
              }
              return item;
            });
            this.props.onStateChangeAction({
              filteredCollaborations,
              selectedCollaboration,
            });
          })
          .catch((res) => res);
      }
    }

    const selectedUser = {
      ...selectedCollaboration.company,
      ...selectedCollaboration.userdetail,
    };
    if (isIproInActiveCollaboration || isInActiveView) {
      this.getReviewDetails(CollaborationId);
    }
    this.props.onStateChangeAction({
      selectedCollaboration,
      selectedUser,
      selectedCompany: selectedCollaboration.company,
      dialogMessage: "",
      yesClickCount: 0,
    });
    if (this.state.windowWidth < 767) {
      this.setState(st => ({ ...st, isMobileDevice: true }))
    }
  };

  handleCollaborationDelete = () => {
    const { collaborationDeleteConfirmation } = this.props;
    this.props.onStateChangeAction({
      dialogMessage: collaborationDeleteConfirmation,
    });
    this.setState({ isDeleteCollaboration: true });
  };

  handleCollaborationClose = () => {
    const { collaborationCloseConfirmation } = this.props;
    this.props.onStateChangeAction({
      dialogMessage: collaborationCloseConfirmation,
    });
    if (this.props.isSentView) {
      this.closeCollaboration();
    }
  };

  handleYesClick = () => {
    const {
      yesClickCount,
      likeToRateCollaborationMessage,
      selectedCollaboration,
    } = this.props;
    const { isDeleteCollaboration } = this.state;
    if (isDeleteCollaboration) {
      this.deleteCollaboration();
      return;
    }
    if (!yesClickCount || yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: likeToRateCollaborationMessage,
        yesClickCount: 1,
        fetchingReviews: true,
      });
      return;
    }
    this.props.onStateChangeAction({ fetchingReviews: true });
    this.getReviewDetails(selectedCollaboration.CollaborationId);
    this.getCollaborationCloseReasons();
    this.props.onStateChangeAction({
      dialogMessage: "",
      yesClickCount: 2,
    });
    if (this?.state?.windowWidth < 768) {
      this.handleListOpenMobile();
    }
  };

  deleteCollaboration = () => {
    const {
      selectedCollaboration: { CollaborationId },
      IsSearcher,
      collaborationDeletedMsg,
    } = this.props;
    this.setState({ isLoading: true, dialogMessage: "" });
    const apiParams = `collaborationId=${CollaborationId}&${IsSearcher ? "IsSearcher=true" : "IsSearcher=false"
      }`;
    this.props.onStateChangeAction({ isLoading: true, dialogMessage: "" });
    deleteCollaborationApi({ apiParams }).then((response) => {
      if (response.success) {
        const info = {
          message: collaborationDeletedMsg,
          status: "success",
        };
        this.props.notificationAction(info);
        this.getAllCollaboration();
        this.props.onStateChangeAction({
          isLoading: false,
          selectedCollaboration: {},
          yesClickCount: 0,
        });
      } else {
        const info = {
          message: "Something went wrong",
          status: "error",
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({
          isLoading: false,
        });
      }
    });
  };

  closeCollaboration = () => {
    const {
      selectedCollaboration: { CollaborationId },
      collaborationCloseMsg,
      IsSearcher,
    } = this.props;
    this.setState({ isLoading: true, dialogMessage: "" });
    const apiParams = `collaborationId=${CollaborationId}&IsClosed=true&${IsSearcher ? "IsSearcher=true" : "IsSearcher=false"
      }`;
    this.props.onStateChangeAction({ isLoading: true, dialogMessage: "" });
    updateCollaborationApi({ apiParams }).then((response) => {
      const info = {
        message: collaborationCloseMsg,
        status: "success",
      };
      this.props.notificationAction(info);
      this.getAllCollaboration();
      this.props.onStateChangeAction({
        isLoading: false,
        selectedCollaboration: {},
        yesClickCount: 0,
      });
    });
  };

  handleNoClick = () => {
    const { yesClickCount } = this.props;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: "",
        selectedCompany: null,
      });
      return;
    }
    this.closeCollaboration();
  };

  handleCollaborationAccept = () => {
    const {
      selectedCollaboration: { CollaborationId },
    } = this.props;
    const { collaborationAcceptedMsg } = this.props;
    const apiParams = `collaborationId=${CollaborationId}&IsDeclined=false`;
    this.props.onStateChangeAction({ isLoading: true });
    updateCollaborationApi({ apiParams }).then((response) => {
      const info = {
        message: collaborationAcceptedMsg,
        status: "success",
      };
      this.props.notificationAction(info);
      this.getAllCollaboration();
      this.props.onStateChangeAction({
        isLoading: false,
        selectedCollaboration: {},
      });
    });
  };

  handleCollaborationDecline = () => {
    const {
      selectedCollaboration: { CollaborationId },
    } = this.props;
    const { collaborationDeclinedMsg } = this.props;
    const apiParams = `collaborationId=${CollaborationId}&IsDeclined=true`;
    this.props.onStateChangeAction({ isLoading: true });
    updateCollaborationApi({ apiParams }).then((response) => {
      const info = {
        message: collaborationDeclinedMsg,
        status: "success",
      };
      this.props.notificationAction(info);
      this.getAllCollaboration();
      this.props.onStateChangeAction({
        isLoading: false,
        selectedCollaboration: {},
      });
    });
  };

  getReviewDetails = (CollaborationId) => {
    this.setState({ fetchingReviews: true });
    const { IsSearcher } = this.props;
    getReviewQuestions({ questionType: 1 })
      .then((res) => {
        const reviewQuestions = res.items.map((r) => ({
          ...r,
          ReviewQuestionValue: this.props.labels[r.Question],
          Answer: r.Question !== "Rating_Question5" ? r.Answer || 0 : "",
          CollaborationId: CollaborationId,
        }));
        getCollaborationReviewApi({
          collaborationId: CollaborationId,
          IsIProReview: !IsSearcher,
        })
          .then((reviewRes) => {
            const reviews = reviewRes.items;
            let currentCollborationCloseReasonId = null;
            this.props.onStateChangeAction({
              reviewQuestions: reviewQuestions.map((a) => {
                const currentReview = reviews
                  ? reviews.find(
                    (b) => b.ReviewQuestionId == a.ReviewQuestionId
                  )
                  : null;
                if (
                  currentReview &&
                  a.ReviewQuestionId == currentReview.ReviewQuestionId &&
                  a.Question === "Rating_Question6"
                ) {
                  currentCollborationCloseReasonId =
                    currentReview.CollborationCloseReasonId;
                }
                this.setState({ currentReview: currentReview });
                return {
                  ...a,
                  Answer:
                    currentReview != null ? currentReview.Answer : a.Answer,
                  IsIProReview: !IsSearcher,
                };
              }),
              isAlreadyRated: reviews && reviews.length > 0 ? true : false,
            });
            this.setState({ fetchingReviews: false });
            this.onFormSelectChange("", {
              value: currentCollborationCloseReasonId,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ fetchingReviews: false });
          });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ fetchingReviews: false });
      });
  };

  getCollaborationCloseReasons = () => {
    getCollaborationReasonsApi()
      .then((reasons) => {
        const reasonsList = reasons.items.map((item) => ({
          ...item,
          value: item.CollborationCloseReasonId,
          label: this.props.labels[item.ReasonLabel],
        }));
        this.setState({
          options: reasonsList,
        });
      })
      .catch((err) => console.log(err));
  };

  handleRatingChange = (rating, value) => {
    const { reviewQuestions } = this.props;
    this.props.onStateChangeAction({
      reviewQuestions: reviewQuestions.map((a) =>
        a.ReviewQuestionId == rating.ReviewQuestionId
          ? {
            ...a,
            Answer:
              rating.Question === "Rating_Question5"
                ? value.target.value
                : value,
          }
          : a
      ),
    });
  };

  onFormSelectChange = (answer, selectedOption) => {
    if (!selectedOption) return;
    const { reviewQuestions } = this.props;
    const { value } = selectedOption;
    this.setState({
      CollborationCloseReasonId: value,
    });
    this.props.onStateChangeAction({
      reviewQuestions: reviewQuestions.map((a) =>
        a.Question === "Rating_Question6"
          ? {
            ...a,
            Answer: null,
            CollborationCloseReasonId: value,
          }
          : a
      ),
    });

    return;
  };

  handleSubmit = () => {
    const { reviewQuestions } = this.props;
    submitCollaborationReview({ collaborationReview: reviewQuestions })
      .then((data) => {
        this.setState((st) => ({ ...st, mobileModal: false }));
      })
      .catch((err) => { });
    this.closeCollaboration();
  };
  renderDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  componentWillUnmount = () => { };

  handleMobileModalClose = () => {
    this.setState((st) => ({ ...st, mobileModal: false }));
  };

  handleListOpenMobile = () => {
    this.setState((st) => ({ ...st, mobileModal: true }));
  };

  render() {
    const {
      searchInputPlaceholder,
      emptyCollaorationInfo,
      startDateLabel,
      durationLabel,
      compensationLabel,
      companyLabel,
      descriptionLabel,
      acceptedCollaboration,
      selectInterest,
      companyAddressLabel,
      comapnyIndustryLabel,
      companyCountryLabel,
      companyPhoneLabel,
      companyWebUrlLabel,
      companyVatLabel,
      IsSearcher,
      popupNoText,
      popupYesText,
      selectedCollaboration,
      filteredCollaborations,
      selectedUser,
      isLoading,
      selectedCompany,
      dialogMessage,
      yesClickCount = 0,
      isInActiveView,
      isIproInActiveCollaboration,
      isDeclinedView,
      isAcceptedView,
      isSentView,
      isIproActiveCollaboration,
      isIproNewCollaboration,
      iProOpportunityNewDetailBtnAccept,
      iProOpportunityNewDetailBtnDecline,
      labels
    } = this.props;
    const { fetchingReviews, windowWidth } = this.state;
    const isRatingView =
      ((isInActiveView || isIproInActiveCollaboration) &&
        !isNullOrUndefined(selectedCollaboration)) ||
        (yesClickCount &&
          yesClickCount === 2 &&
          !isNullOrUndefined(selectedCollaboration))
        ? true
        : false;

    const getTabs = () => {
      if (IsSearcher) {
        return [
          {
            id: 1,
            label: labels?.IPRO_NAVIGATION_COLLABORATION_NEW_LABEL,
            active: window?.location?.hash == "#/sent-collaborations",
            value: "/sent-collaborations"
          },
          {
            id: 2,
            label: labels?.SEARCHER_NAVIGATION_COLLABORATION_ACCEPTED_LABEL,
            active: window?.location?.hash == "#/active-collaborations",
            value: "/active-collaborations"
          },
          {
            id: 3,
            label: labels?.SEARCHER_NAVIGATION_COLLABORATION_DECLINED_LABEL,
            active: window?.location?.hash == "#/declined-collaborations",
            value: "/declined-collaborations"
          },
          {
            id: 4,
            label: labels?.IPRO_NAVIGATION_COLLABORATION_INACTIVE_LABEL,
            active: window?.location?.hash == "#/inactive-collaborations",
            value: "/inactive-collaborations"
          }
        ]
      } else {
        return [
          {
            id: 1,
            label: labels?.IPRO_NAVIGATION_COLLABORATION_NEW_LABEL,
            active: window?.location?.hash == "#/new-collaborations",
            value: "/new-collaborations"
          },
          {
            id: 2,
            label: labels?.IPRO_NAVIGATION_COLLABORATION_ACTIVE_LABEL,
            active: window?.location?.hash == "#/ipro-active-collaborations",
            value: "/ipro-active-collaborations"
          },
          {
            id: 4,
            label: labels?.IPRO_NAVIGATION_COLLABORATION_INACTIVE_LABEL,
            active: window?.location?.hash == "#/ipro-inactive-collaborations",
            value: "/ipro-inactive-collaborations"
          },
          {
            id: 3,
            label: labels?.SEARCHER_NAVIGATION_COLLABORATION_DECLINED_LABEL,
            active: window?.location?.hash == "#/ipro-declined-collaborations",
            value: "/ipro-declined-collaborations"
          },

        ]
      }
    }
    return (
      <>
        <PageWrapper className="collaboration">
          {isLoading && <LoadingMask />}
          {dialogMessage && (
            <ConfirmDialog testId="company-confirm-diloag">
              <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
              <ConfirmDialog.ActionButtons>
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="company-delete-yes"
                  onClick={this.handleYesClick}
                >
                  {popupYesText}
                </ConfirmDialog.Button>
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="company-delete-no"
                  onClick={this.handleNoClick}
                >
                  {popupNoText}
                </ConfirmDialog.Button>
              </ConfirmDialog.ActionButtons>
            </ConfirmDialog>
          )}

          <UserDetailModal
            isModalOpen={this?.state?.mobileModal}
            setIsModalOpen={this.handleMobileModalClose}
            selectedUser={selectedUser}
            labels={this?.props?.labels}
            selectedCollaboration={selectedCollaboration}
            onFormSelectChange={this.onFormSelectChange}
            options={this.state.options}
            onCloseCollaboration={this.closeCollaboration}
            CollborationCloseReasonId={this.state.CollborationCloseReasonId}
            reviewQuestions={this.props.reviewQuestions}
            onSubmit={this.handleSubmit}
            onRatingChange={this.handleRatingChange}
            isAlreadyRated={this.props.isAlreadyRated}
            isInActiveView={isInActiveView}
            isRatingView={isRatingView}
            IsSearcher={IsSearcher}
            selectedCompany={selectedCompany}
            addressLabel={companyAddressLabel}
            industryLabel={comapnyIndustryLabel}
            countryLabel={companyCountryLabel}
            phoneLabel={companyPhoneLabel}
            webUrlLabel={companyWebUrlLabel}
            vatLabel={companyVatLabel}
          />
          <div className="h-100 new-design-search w-100 d-flex flex-column">
            <div className="tabs-header-col">
              {this?.state?.isMobileDevice ? <div
                onClick={() => { this.setState(st => ({ ...st, isMobileDevice: false })) }}
              >
                <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
              </div> :
                <div className="d-flex gap-16 justify-content-between align-items-center">
                  <div className="d-flex gap-16 align-items-center main-header">
                    {(IsSearcher && windowWidth > 480) && (
                      <button className={`tab-btn-coll w-100 ${window?.location?.hash == "#/create-collaboration" ? "active-url" : "inactive-url"}`}
                        onClick={() => { this.props.history.push("/create-collaboration") }}
                      >
                        {labels?.SEARCHER_NAVIGATION_COLLABORATION_CREATE_LABEL}
                      </button>
                    )}
                    {this.state.windowWidth < 767 ?
                      <Select
                        className="dropdown-callooration"
                        size="medium"
                        bordered={false}
                        onChange={(e) => {
                          this.setState(st => ({ ...st, active: e }))
                          this.props.history.push(`${e}`)
                        }}
                        value={this?.state?.active}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                        options={getTabs() || []}
                      />
                      :
                      <>
                        {getTabs()?.map(single =>
                          <button className="tab-btn-coll"
                            key={single?.id}
                            style={{ background: single?.active ? "#8E81F5" : "", color: single?.active ? "#fff" : "#000" }}
                            onClick={() => {
                              this.props.history.push(single?.value)
                            }}
                          >
                            {single?.label}
                          </button>
                        )}
                      </>
                    }
                  </div>
                </div>
              }
            </div>
            <Row className=" h-100" style={{ overflow: "auto" }}>
              <Col xs={24} md={Object.keys(selectedCollaboration).length == 0 ? 24 : 8} className="collboration-column1">
                {this?.state?.isMobileDevice ?
                  <div className=" resume-list h-100" style={{ marginLeft: this?.state?.windowWidth > 767 ? "16px" : 0, background: "#fff", borderRadius: "12px" }}>
                    {selectedCollaboration.company && !isRatingView && (
                      <NewCollaborationDetail
                        selectedCollaboration={selectedCollaboration}
                        iProOpportunityNewDetailBtnDecline={
                          iProOpportunityNewDetailBtnDecline
                        }
                        iProOpportunityNewDetailBtnAccept={
                          iProOpportunityNewDetailBtnAccept
                        }
                        labels={this.props.labels}
                        emptyCollaorationInfo={emptyCollaorationInfo}
                        startDateLabel={startDateLabel}
                        durationLabel={durationLabel}
                        isDeclinedView={isDeclinedView}
                        compensationLabel={compensationLabel}
                        companyLabel={companyLabel}
                        descriptionLabel={descriptionLabel}
                        acceptedCollaboration={acceptedCollaboration}
                        selectInterest={selectInterest}
                        onCollaborationAccept={this.handleCollaborationAccept}
                        onCollaborationDecline={this.handleCollaborationDecline}
                        onCollaborationDelete={this.handleCollaborationDelete}
                        onCollaborationClose={this.handleCollaborationClose}
                        IsSearcher={IsSearcher}
                        selectedCompany={selectedCompany}
                        isIproInActiveCollaboration={isIproInActiveCollaboration}
                        isInActiveView={isInActiveView}
                        isAcceptedView={isAcceptedView}
                        isSentView={isSentView}
                        isIproActiveCollaboration={isIproActiveCollaboration}
                        isIproNewCollaboration={isIproNewCollaboration}
                        addressLabel={companyAddressLabel}
                        industryLabel={comapnyIndustryLabel}
                        countryLabel={companyCountryLabel}
                        phoneLabel={companyPhoneLabel}
                        webUrlLabel={companyWebUrlLabel}
                        vatLabel={companyVatLabel}
                      />
                    )}
                    {isRatingView && (
                      <div className="h-100">
                        {fetchingReviews ? (
                          <LoadingMaskRow />
                        ) : (
                          <>
                            {!isEmpty(selectedCollaboration) && (
                              <NewRatingView
                                selectedCollaboration={selectedCollaboration}
                                onFormSelectChange={this.onFormSelectChange}
                                options={this.state.options}
                                onCloseCollaboration={this.closeCollaboration}
                                CollborationCloseReasonId={
                                  this.state.CollborationCloseReasonId
                                }
                                reviewQuestions={this.props.reviewQuestions}
                                onSubmit={this.handleSubmit}
                                onRatingChange={this.handleRatingChange}
                                isAlreadyRated={this.props.isAlreadyRated}
                                isInActiveView={isInActiveView}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  :
                  <div className="h-100 d-flex flex-column">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "12px",
                        borderBottom: "1px solid #F3F3F3",
                        gap: "10px"
                      }}
                    >
                      <Input
                        placeholder={searchInputPlaceholder}
                        size="medium"
                        bordered={false}
                        style={{ border: "1px solid #F3F3F3" }}
                        onChange={this.handleSearchChange}
                      />
                      <div>
                        <img style={{ height: "30px" }} src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <div
                      style={{
                        overflow: "auto",
                        padding: "12px",
                        flex: 1
                      }}
                    >
                      {filteredCollaborations?.length > 0 ? (
                        <Space size={[6, 6]} wrap className="short-list-new">
                          {filteredCollaborations?.map((item) => (
                            <div
                              onClick={() => this.handleSelectCollaboration(item)}
                              key={item?.UserCompanyId}
                              className={`d-flex gap-8 justify-content-between align-items-center pointer ${selectedCollaboration?.CollaborationId === item?.CollaborationId
                                ? "background-shortlist short-list-item "
                                : "short-list-item"
                                }`}>
                              <div className="d-flex gap-8">
                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    {item.label}
                                  </label>
                                  <p style={{ fontSize: "12px", marginBottom: 0 }}>{formatDate(item?.StartDate)}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Space>
                      ) : (
                        <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                      )}
                    </div>
                    {(IsSearcher && windowWidth < 480) && (
                      <div style={{ margin: "auto" }}>
                        <div className="tabs-header-col mt-3">
                          <button className="tab-btn-coll"
                            style={{ background: "#6C63FF", color: "#fff" }}
                            onClick={() => { this.props.history.push("/create-collaboration") }}
                          >
                            {labels?.SEARCHER_NAVIGATION_COLLABORATION_CREATE_LABEL}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                }
              </Col>
              <Col md={16} xs={0} offset={0.5}
                className={
                  Object.keys(selectedCollaboration).length == 0 ? null : "h-100"
                }
              >
                <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                  {selectedCollaboration.company && !isRatingView && (
                    <NewCollaborationDetail
                      selectedCollaboration={selectedCollaboration}
                      iProOpportunityNewDetailBtnDecline={
                        iProOpportunityNewDetailBtnDecline
                      }
                      iProOpportunityNewDetailBtnAccept={
                        iProOpportunityNewDetailBtnAccept
                      }
                      labels={this.props.labels}
                      emptyCollaorationInfo={emptyCollaorationInfo}
                      startDateLabel={startDateLabel}
                      durationLabel={durationLabel}
                      isDeclinedView={isDeclinedView}
                      compensationLabel={compensationLabel}
                      companyLabel={companyLabel}
                      descriptionLabel={descriptionLabel}
                      acceptedCollaboration={acceptedCollaboration}
                      selectInterest={selectInterest}
                      onCollaborationAccept={this.handleCollaborationAccept}
                      onCollaborationDecline={this.handleCollaborationDecline}
                      onCollaborationDelete={this.handleCollaborationDelete}
                      onCollaborationClose={this.handleCollaborationClose}
                      IsSearcher={IsSearcher}
                      selectedCompany={selectedCompany}
                      isIproInActiveCollaboration={isIproInActiveCollaboration}
                      isInActiveView={isInActiveView}
                      isAcceptedView={isAcceptedView}
                      isSentView={isSentView}
                      isIproActiveCollaboration={isIproActiveCollaboration}
                      isIproNewCollaboration={isIproNewCollaboration}
                      addressLabel={companyAddressLabel}
                      industryLabel={comapnyIndustryLabel}
                      countryLabel={companyCountryLabel}
                      phoneLabel={companyPhoneLabel}
                      webUrlLabel={companyWebUrlLabel}
                      vatLabel={companyVatLabel}
                    />
                  )}
                  {isRatingView && (
                    <div className="h-100">
                      {fetchingReviews ? (
                        <LoadingMaskRow />
                      ) : (
                        <>
                          {!isEmpty(selectedCollaboration) && (
                            <NewRatingView
                              selectedCollaboration={selectedCollaboration}
                              onFormSelectChange={this.onFormSelectChange}
                              options={this.state.options}
                              onCloseCollaboration={this.closeCollaboration}
                              CollborationCloseReasonId={
                                this.state.CollborationCloseReasonId
                              }
                              reviewQuestions={this.props.reviewQuestions}
                              onSubmit={this.handleSubmit}
                              onRatingChange={this.handleRatingChange}
                              isAlreadyRated={this.props.isAlreadyRated}
                              isInActiveView={isInActiveView}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (
  {
    systemLabel,
    navigation,
    iproCollaborationNew,
    iproCollaborationInActive,
    iproCollaborationActive,
    searcherCollaborationPending,
    searcherCollaborationAccepted,
    searcherCollaborationDeclined,
    searcherCollaborationInActive,
  },
  {
    isIproActiveCollaboration,
    isIproInActiveCollaboration,
    isSentView,
    isAcceptedView,
    isDeclinedView,
    isInActiveView,
  }
) => {
  const {
    acceptedCollaboration,
    declinedCollaboration,
    unreadproInactiveCollaborations,
    searcherInActiveCollaboration,
    unreadCollaborations,
  } = navigation;
  const { labels } = systemLabel;
  const collaborationProps = isSentView
    ? searcherCollaborationPending
    : isAcceptedView
      ? searcherCollaborationAccepted
      : isDeclinedView
        ? searcherCollaborationDeclined
        : isInActiveView
          ? searcherCollaborationInActive
          : isIproActiveCollaboration
            ? iproCollaborationActive
            : isIproInActiveCollaboration
              ? iproCollaborationInActive
              : iproCollaborationNew;
  return {
    labels,
    acceptedCollaboration,
    declinedCollaboration,
    searcherInActiveCollaboration,
    unreadproInactiveCollaborations,
    unreadCollaborations,
    ...collaborationProps,
  };
};

const actions = {
  unreadCollaborationsAction,
  onStateChangeAction,
};
export default connect(mapStateToProps, actions)(withRouter(NewCollaboration));
