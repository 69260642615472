import React from 'react';
import './navigation.scss';
import { NavigationItem } from '../../../common';

class SnapshotNavigation extends React.Component {
    render() {
        const hash = window.location.hash.toLowerCase();
        const app = hash.indexOf('apps') > -1 ? "/Apps/Snapshot/" : "/Snapshot/";
        const { isHelpActive, labels, UserFeatures, disabled } = this.props;
        let features = [];
        UserFeatures.forEach(element => {
            if (element.Url)
                features.push(element);
        });
        const hashesList = [
            "#/apps/snapshot/",
            "#/snapshot/",
            "#/apps/snapshot",
            "#/snapshot"
        ];
        return (
            <div className={`${disabled ? "disabled" : ""} product-menus`}>
                {features.map(item => (
                    <NavigationItem
                        Id={3}
                        tooltipLabel={`${labels[`${item.Name}-Tooltip`]}`}
                        isHelpActive={isHelpActive}
                        IsActive={item.Url && window.location.href.indexOf(item.Url) > -1 ? true : hashesList.indexOf(window.location.hash.toLowerCase()) > -1 ? true : false}
                        CssClass={"approve-menu"}
                        CssClassIcon={item.CssClassIcon}
                        Url={`${app}${item.Url}`}
                        testId={`${item.Name}-button`}
                        menuText={""}
                        isNavShrink={false}
                        tooltipHelp={`${labels[`${item.Name}-Help`]}`}
                        NotificationBadge={false}
                        NotificationCount={5}
                        key={item.Id}
                    />
                ))}
            </div>
        );
    }
}

export default SnapshotNavigation;