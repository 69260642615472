import * as React from "react";

const MenuDots = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2058_4445)">
        <path
          d="M11.6663 9.99996C11.6663 9.07948 10.9201 8.33329 9.99967 8.33329C9.0792 8.33329 8.33301 9.07948 8.33301 9.99996C8.33301 10.9204 9.0792 11.6666 9.99967 11.6666C10.9201 11.6666 11.6663 10.9204 11.6663 9.99996Z"
          fill={props.color || "#22294C"}
        />
        <path
          d="M11.6663 17.5C11.6663 16.5795 10.9201 15.8333 9.99967 15.8333C9.0792 15.8333 8.33301 16.5795 8.33301 17.5C8.33301 18.4204 9.0792 19.1666 9.99967 19.1666C10.9201 19.1666 11.6663 18.4204 11.6663 17.5Z"
          fill={props.color || "#22294C"}
        />
        <path
          d="M11.6663 2.49996C11.6663 1.57948 10.9201 0.833293 9.99967 0.833293C9.0792 0.833293 8.33301 1.57948 8.33301 2.49996C8.33301 3.42043 9.0792 4.16663 9.99967 4.16663C10.9201 4.16663 11.6663 3.42043 11.6663 2.49996Z"
          fill={props.color || "#22294C"}
        />
      </g>
      <defs>
        <clipPath id="clip0_2058_4445">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 20) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuDots;
