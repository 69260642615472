import React, { Component } from "react";
import "./confirmdialog.scss";
const Message = ({ children, testId, className }) => (
  <div className={`confirm-message ${className}`} test-data-id={testId}>
    {children}
  </div>
);
const ActionButtons = ({ children, testId }) => (
  <div className="confirm-action-buttons" test-data-id={testId}>
    {children}
  </div>
);
const Button = ({ children, className, testId, onClick }) => (
  <button
    className={`dialog-btn ${className}`}
    test-data-id={testId}
    onClick={onClick}
  >
    {children}
  </button>
);
class ConfirmDialog extends Component {
  static Message = Message;
  static ActionButtons = ActionButtons;
  static Button = Button;
  render() {
    const { children, testId, className } = this.props;
    return (
      <div
        className={`${className} confirm-dialog animated fadeInDown`}
        test-data-id={testId}
      >
        {children}
      </div>
    );
  }
}

ConfirmDialog.Button.defaultProps = {
  className: ""
};

export default ConfirmDialog;
