import React, { Component } from "react";
import { connect } from "react-redux";
import Collaboration from "../common/Collaboration";
import { notificationAction } from "../../../actions";

class SearcherCollaborationDeclined extends Component {
  render() {
    const {
      userId,
      labels,
      IsSearcher,
      notificationAction,
      isHelpActive,
      location
    } = this.props;
    const {
      SearcherDeclinedCollaborationSearchPlaceholder,
      SearcheDeclinedCollaborationDetailHeadingText,
      TooltipSearcherDeclinedCollaborationListExpand,
      TooltipSearcherDeclinedCollaborationListCollapse,
      HlpTooltipSearcherDeclinedCollaborationListExpand,
      HlpTooltipSearcherDeclinedCollaborationListCollapse,
      SearcherDeclinedCollaborationListColumnText,

      TooltipSearcherDeclinedCollaborationDetailExpand,
      TooltipSearcherDeclinedCollaborationDetailCollapse,
      HlpTooltipSearcherDeclinedCollaborationDetailExpand,
      HlpTooltipSearcherDeclinedCollaborationDetailCollapse,
      SearcherDeclinedCollaborationDetailColumnText,

      SearcherDeclinedCollaborationUserDetailHeaderText,
      TooltipSearcherDeclinedCollaborationUserDetailExpand,
      TooltipSearcherDeclinedCollaborationUserDetailCollapse,
      HlpTooltipSearcherDeclinedCollaborationUserDetailExpand,
      HlpTooltipSearcherDeclinedCollaborationUserDetailCollapse,
      SearcherDeclinedCollaborationUserDetailColumnText,

      SearcherDeclinedCollaborationUserNameLabel,
      SearcherDeclinedCollaborationPositionLabel,
      SearcherDeclinedCollaborationEmailLabel,
      SearcherDeclinedCollaborationPhoneLabel,
      SearcherDeclinedCollaborationWebUrlLabel,
      SearcherDeclinedCollaborationStartDateLabel,
      SearcherDeclinedCollaborationDurationLabel,
      SearcherDeclinedCollaborationCompensationLabel,
      SearcherDeclinedCollaborationCompanyLabel,
      SearcherDeclinedCollaborationDescriptionLabel,
      SearcherDeclinedCollaborationAcceptedLabel,
      SearcherDeclinedCollaborationInterestOrNotLabel,
      SearcherDeclinedCollaborationEmptyInfo,
      InfoSearcherCollaborationDeleted,
      InfoSearcherSentCollaborationDeleteConfirmationMsg
    } = labels;
    return (
      <Collaboration
        IsSearcher={IsSearcher}
        isHelpActive={isHelpActive}
        notificationAction={notificationAction}
        isDeclinedView
        url={`GetAllCollaborationByUserId?IsAccepted=false&isDeclined=true`}
        // list section system label
        searchInputPlaceholder={SearcherDeclinedCollaborationSearchPlaceholder}
        toolTipExpandList={TooltipSearcherDeclinedCollaborationListExpand}
        helpTooltipExpandList={
          HlpTooltipSearcherDeclinedCollaborationListExpand
        }
        toolTipCollapseList={TooltipSearcherDeclinedCollaborationListCollapse}
        helpTooltipCollapseList={
          HlpTooltipSearcherDeclinedCollaborationListCollapse
        }
        listColumnText={SearcherDeclinedCollaborationListColumnText}
        // detail section system label
        detailHeadingText={SearcheDeclinedCollaborationDetailHeadingText}
        toolTipExpandDetail={TooltipSearcherDeclinedCollaborationDetailExpand}
        helpTooltipExpandDetail={
          HlpTooltipSearcherDeclinedCollaborationDetailExpand
        }
        toolTipCollapseDetail={
          TooltipSearcherDeclinedCollaborationDetailCollapse
        }
        helpTooltipCollapseDetail={
          HlpTooltipSearcherDeclinedCollaborationDetailCollapse
        }
        detailsColumnText={SearcherDeclinedCollaborationDetailColumnText}
        // selected user section system label
        userDetailHeadingText={
          SearcherDeclinedCollaborationUserDetailHeaderText
        }
        toolTipExpandUserDetail={
          TooltipSearcherDeclinedCollaborationUserDetailExpand
        }
        helpTooltipExpandUserDetail={
          HlpTooltipSearcherDeclinedCollaborationUserDetailExpand
        }
        toolTipCollapseUserDetail={
          TooltipSearcherDeclinedCollaborationUserDetailCollapse
        }
        helpTooltipCollapseUserDetail={
          HlpTooltipSearcherDeclinedCollaborationUserDetailCollapse
        }
        userDetailsColumnText={
          SearcherDeclinedCollaborationUserDetailColumnText
        }
        emptyCollaorationInfo={SearcherDeclinedCollaborationEmptyInfo}
        startDateLabel={SearcherDeclinedCollaborationStartDateLabel}
        durationLabel={SearcherDeclinedCollaborationDurationLabel}
        compensationLabel={SearcherDeclinedCollaborationCompensationLabel}
        companyLabel={SearcherDeclinedCollaborationCompanyLabel}
        descriptionLabel={SearcherDeclinedCollaborationDescriptionLabel}
        acceptedCollaboration={SearcherDeclinedCollaborationAcceptedLabel}
        selectInterest={SearcherDeclinedCollaborationInterestOrNotLabel}
        onCollaborationDelete={this.handleCollaborationDelete}
        UserNameLabel={SearcherDeclinedCollaborationUserNameLabel}
        UserPositionLabel={SearcherDeclinedCollaborationPositionLabel}
        UserEmailLabel={SearcherDeclinedCollaborationEmailLabel}
        UserPhoneLabel={SearcherDeclinedCollaborationPhoneLabel}
        UserWebUrlLabel={SearcherDeclinedCollaborationWebUrlLabel}
        collaborationDeletedMsg={InfoSearcherCollaborationDeleted}
        listCollapsedTestId={"listCollapsedTestId1"}
        listCollapsedHelpTestId={"listCollapsedTestId2"}
        listExpandTestId={"listCollapsedTestId3"}
        listExpandHelpTestId={"listCollapsedTestId4"}
        detailCollapsedTestId={"listCollapsedTestId5"}
        detailCollapsedHelpTestId={"listCollapsedTestId6"}
        detailExpandTestId={"listCollapsedTestId7"}
        detailExpandHelpTestId={"listCollapsedTestId8"}
        userDetailCollapsedTestId={"listCollapsedTestId9"}
        userDetailCollapsedHelpTestId={"listCollapsedTestId10"}
        userDetailExpandTestId={"listCollapsedTestId11"}
        userDetailExpandHelpTestId={"listCollapsedTestId12"}
        searchInputTestId="search-input"
        collaborationDeleteConfirmation={
          InfoSearcherSentCollaborationDeleteConfirmationMsg
        }
        popupYesText={"Yes"}
        popupNoText={"No"}
        locationProp={location}
      />
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { UserId, IsFreelancer } = user;
  UserId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    userId: UserId,
    IsSearcher: !IsFreelancer,
    IsFreelancer,
    isHelpActive
  };
};

const actions = { notificationAction };
export default connect(
  mapStateToProps,
  actions
)(SearcherCollaborationDeclined);
