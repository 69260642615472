import React from "react";
import "./navigation.scss";
import { NavigationItem } from "../../../common";
import { Col, Input, Row, Select, Space } from "antd";
import { withRouter } from "react-router-dom";
const { Option } = Select;

class PhilipNavigation extends React.Component {
  render() {
    const hash = window.location.hash.toLowerCase();
    const app =
      hash.indexOf("apps") > -1
        ? "/Apps/searcher-philip/"
        : "/searcher-philip/";
    const {
      isHelpActive,
      labels,
      UserFeatures,
      disabled,
      location,
    } = this.props;
    let features = [];
    const url = location?.pathname?.split("/");
    UserFeatures.forEach((element) => {
      if (element.Url) features.push(element);
    });
    const hashesList = [
      "#/apps/searcher-philip/",
      "#/searcher-philip/",
      "#/apps/searcher-philip",
      "#/searcher-philip",
    ];

    return (
      <Row>
        <Col xs={0} sm={24}>
          <div className={`${disabled ? "disabled" : ""} product-menus`}>
            {features.map((item) => (
              <NavigationItem
                Id={3}
                tooltipLabel={`${labels[`${item.Name}_TOOLTIP`]}`}
                // tooltipLabel={`${item?.Label}`}
                isHelpActive={isHelpActive}
                IsActive={
                  item.Url && window.location.href.indexOf(item.Url) > -1
                    ? true
                    : hashesList.indexOf(window.location.hash.toLowerCase()) >
                      -1
                    ? true
                    : false
                }
                CssClass={"approve-menu"}
                CssClassIcon={item.CssClassIcon}
                Url={`${app}${item.Url}`}
                testId={`${item.Name}-button`}
                key={item.Name}
                menuText={""}
                isNavShrink={false}
                tooltipHelp={`${labels[`${item.Name}_HELP_TOOLTIP`]}`}
                NotificationBadge={false}
                NotificationCount={5}
                mobileName={item?.mobileName}
              />
            ))}
          </div>
        </Col>
        <Col xs={24} sm={0} className="aant-section-column-mobile">
          <Select
            style={{ width: "100%" }}
            defaultValue={`${app}automate`}
            onChange={(e) => {
              this.props.history.push(e);
            }}
          >
            {features.map((item) => (
              <Option value={`${app}${item.Url}`}>
                <div className="product-menus-mobile">
                  {" "}
                  <i className={item?.mobileIcon}></i>
                  <p>{`${labels[`${item.Name}_TOOLTIP`]}`}</p>
                </div>
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    );
  }
}

export default withRouter(PhilipNavigation);
