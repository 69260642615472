import React from "react";
import { connect } from "react-redux";
import { cookieAction } from "../../actions";
import { updateCookie } from "./cookieApi";
import { StorageService } from "../../api";
import "./cookie.scss";

const Cookie = (props) => {
  return props.cookie ? (
    ""
  ) : (
    <>
      <div className="cookie-overlay"></div>
      <div className="cookie-wrapper">
        <div className="cookie">
          <label className="cookie-text">{props.cookieText}</label>
          <button
            className="cookie-button button"
            onClick={() => {
              updateCookie().then(() => {
                StorageService.setCookie();
                props.cookieAction({ cookie: true });
              });
            }}
          >
            {props.cookieButtonText}
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ systemLabel, userInfo }) => ({
  cookie: userInfo.user.IsNotificationRead,
  cookieText: systemLabel.labels.COOKIE_LABEL,
  cookieButtonText: systemLabel.labels.COOKIE_BUTTON_LABEL,
});

export default connect(
  mapStateToProps,
  { cookieAction }
)(Cookie);
