import { ActionTypes } from ".";

export const notificationAction = ({ message, status, className }) => dispatch => {
  dispatch({
    type: ActionTypes.NOTIFICATION,
    message,
    status,
    className,
  });
};
