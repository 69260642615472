import { ActionTypes } from "../../../actions";

export const onStateChangeAction = ({
  isEmptyCollapsed,
  listCollapse,
  detailCollapse,
  shortlistCollapse,
  resumeListCollapse,
  shortlistResumeCollapse,
  dialogMessage,
  isLoading,
  isFetching,
  isFetchingShortlist,
  isFetchingShortlistResume,
  filterOpportunires,
  selectedOpportunity,
  selectedShortlists,
  selectedShortlist,
  shortlistResumes,
  selectedResume,
  currentOpportunityId,
  searchkey,
  hideShortList,
  showResumeList,
  selectedIpro,
  opportunities,
  yesClickCount,
  iprosList,
  selectedAction,
  allCurrenciesList
}) => dispatch => {
  isEmptyCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IS_EMPTY_COLLAPSED,
      payload: isEmptyCollapsed
    });
  listCollapse !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.LIST_COLLAPSE,
      payload: listCollapse
    });
  detailCollapse !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.DETAIL_COLLAPSE,
      payload: detailCollapse
    });
  shortlistCollapse !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SHORTLIST_COLLAPSE,
      payload: shortlistCollapse
    });
  resumeListCollapse !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.RESUME_LIST_COLLAPSE,
      payload: resumeListCollapse
    });
  shortlistResumeCollapse !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SHORTLIST_RESUME_COLLAPSE,
      payload: shortlistResumeCollapse
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.DIALOG_MESSAGE,
      payload: dialogMessage
    });
  isLoading !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IS_LOADING,
      payload: isLoading
    });
  isFetching !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IS_FETCHING,
      payload: isFetching
    });
  isFetchingShortlist !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IS_FETCHING_SHORTLIST,
      payload: isFetchingShortlist
    });
  isFetchingShortlistResume !== undefined &&
    dispatch({
      type:
        ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IS_FETCHING_SHORTLIST_RESUME,
      payload: isFetchingShortlistResume
    });
  filterOpportunires !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.FILTER_OPPORTUNIRES,
      payload: filterOpportunires
    });
  selectedOpportunity !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_OPPORTUNITY,
      payload: selectedOpportunity
    });
  selectedShortlists !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_SHORTLISTS,
      payload: selectedShortlists
    });
  selectedShortlist !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_SHORTLIST,
      payload: selectedShortlist
    });
  shortlistResumes !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SHORTLIST_RESUMES,
      payload: shortlistResumes
    });
  selectedResume !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_RESUME,
      payload: selectedResume
    });
  currentOpportunityId !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.CURRENT_OPPORTUNITY_ID,
      payload: currentOpportunityId
    });
  searchkey !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SEARCH_KEY,
      payload: searchkey
    });
  hideShortList !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.HIDE_SHORTLIST,
      payload: hideShortList
    });
  showResumeList !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SHOW_RESUME_LIST,
      payload: showResumeList
    });
  selectedIpro !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_IPRO,
      payload: selectedIpro
    });
  opportunities !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.OPPORTUNITIES,
      payload: opportunities
    });
  yesClickCount !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.YES_CLICK_COUNT,
      payload: yesClickCount
    });
  iprosList !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IPROS_LIST,
      payload: iprosList
    });
  selectedAction !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_ACTION,
      payload: selectedAction
    });
  allCurrenciesList !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_SENT_SEARCHER.ALL_CURRENCIES_LIST,
      payload: allCurrenciesList
  });
};
