import { ActionTypes } from "../../../actions";

export const onStateChangeAction = ({
  isLoading,
  IsAvailable,
  AvailablityType,
  busyUntil,
  locationList,
  languageList,
  rolesList,
  keywordsList,
  otherAchivenmentsList,
  skillsList,
  certificationsList,
  educationsList,
  industriesList,
  experiencesList,
  resumes,
  suggestObject,
  suggestedRoles,
  roleDefaultOptions,
  suggestedCertifications,
  certificationDefaultOptions,
  suggestedSkills,
  skillDefaultOptions,
  educationDefaultOptions,
  keywordDefaultOptions,
  IndustryDefaultOptions,
  currentCandidate,
  dialogMessage
}) => dispatch => {
  isLoading !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.IS_LOADING,
      payload: isLoading
    });
  IsAvailable !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.IS_AVAILABLE,
      payload: IsAvailable
    });
  AvailablityType !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.AVAILABLITY_TYPE,
      payload: AvailablityType
    });
  busyUntil !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.BUSY_UNTIL,
      payload: busyUntil
    });
  locationList !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.LOCATION_LIST,
      payload: locationList
    });
  languageList !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.LANGUAGE_LIST,
      payload: languageList
    });
  rolesList !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.ROLES_LIST,
      payload: rolesList
    });
  keywordsList !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.KEYWORDS_LIST,
      payload: keywordsList
    });
  otherAchivenmentsList !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.OTHER_ACHIVENMENTS_LIST,
      payload: otherAchivenmentsList
    });
  skillsList !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.SKILLS_LIST,
      payload: skillsList
    });
  certificationsList !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.CERTIFICATIONS_LIST,
      payload: certificationsList
    });
  educationsList !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.EDUCATIONS_LIST,
      payload: educationsList
    });
  industriesList !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.INDUSTRIES_LIST,
      payload: industriesList
    });
  experiencesList !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.EXPERIENCES_LIST,
      payload: experiencesList
    });
  resumes !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.RESUMES,
      payload: resumes
    });
  suggestObject !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.SUGGEST_OBJECT,
      payload: suggestObject
    });
  suggestedRoles !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.SUGGESTED_ROLES,
      payload: suggestedRoles
    });
  roleDefaultOptions !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.ROLE_DEFAULT_OPTIONS,
      payload: roleDefaultOptions
    });
  suggestedCertifications !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.SUGGESTED_CERTIFICATIONS,
      payload: suggestedCertifications
    });
  certificationDefaultOptions !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.CERTIFICATION_DEFAULT_OPTIONS,
      payload: certificationDefaultOptions
    });
  suggestedSkills !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.SUGGESTED_SKILLS,
      payload: suggestedSkills
    });
  skillDefaultOptions !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.SKILL_DEFAULT_OPTIONS,
      payload: skillDefaultOptions
    });
  educationDefaultOptions !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.EDUCATION_DEFAULT_OPTIONS,
      payload: educationDefaultOptions
    });
  keywordDefaultOptions !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.KEYWORD_DEFAULT_OPTIONS,
      payload: keywordDefaultOptions
    });
  IndustryDefaultOptions !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.INDUSTRY_DEFAULT_OPTIONS,
      payload: IndustryDefaultOptions
    });
  currentCandidate !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.CURRENT_CANDIDATE,
      payload: currentCandidate
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.RESUME_EDIT.DIALOG_MESSAGE,
      payload: dialogMessage
    });
};
