import React from "react";

import { RichTextEditor } from "../../../../../components";
import classes from "../others.module.scss";
import { Col, Row } from "antd";

const AchievementDetails = ({ detail }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={18}>
        <div className={classes.resumeDetail}>
          <input
            type="text"
            placeholder="Please enter other achievement name"
            value={detail?.item?.Title}
          />
        </div>
      </Col>
      <Col xs={6}>
        <div className={classes.resumeDetail}>
          <input
            type="text"
            placeholder="Please enter other achievement name"
            value={detail?.item?.Year}
          />
        </div>
      </Col>
      <Col xs={24}>
        <div className="experience-edit-rich-text">
          <RichTextEditor
            id="resume-edit-experience-description"
            name="Description"
            value={detail?.item?.Description}
            readOnly
            // placeholder={labels.RESUME_EDIT_EXPERIENCE_DESCRIPTION_LABEL}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AchievementDetails;