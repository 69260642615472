export const oldRoutes = [
  {
    Id: 1,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/new-dashboard",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 77,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-resume-search",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 224,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-shortlists",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 224,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-saved-searches",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 224,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-create-opportunity",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 226,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-companies",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 105,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-presentations",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 108,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-sent-opportunities",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 108,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-draft-opportunities",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 401,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-create-collaboration",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 90,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-sent-collaborations",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 90,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-active-collaborations",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 90,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-declined-collaborations",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 93,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-inactive-collaborations",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 51,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-new-opportunity/:id/:idd",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 51,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-new-opportunity",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 6,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-accepted-opportunities",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 6,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "SEARCHER_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-declined-opportunities",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 12,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "IPRO_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-new-collaborations",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 12,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "IPRO_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-ipro-active-collaborations",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 12,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "IPRO_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-ipro-inactive-collaborations",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 11,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "IPRO_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/old-ipro-declined-collaborations",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 33,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "IPRO_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/new-connected-networks",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 33,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "IPRO_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/new-invited-networks",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
  {
    Id: 33,
    Name: "NavHeader",
    FeatureType: 1,
    IsActive: true,
    Label: "IPRO_NAVIGATION_ROLE_LABEL",
    CssClass: "DashboardBtn",
    CssClassIcon: "dashboardIcon",
    NotificationBadge: false,
    SubNavActive: false,
    Url: "/new-invitations-networks",
    Order: 1,
    Disabled: false,
    SubNavigation: [],
    HelpIcon: false,
    testId: "navigation-item-1",
  },
];
