import React, { Component } from "react";
import moment from "moment";
import "./newOpportunityDetail.scss";
import { Col, Row, Select } from "antd";
import { formatDate } from "../../../../utilities";

class NewOpportunityDetail extends Component {
  renderDate = (date) => {
    return date ? moment(date).format("MM/DD/YY") : "N/A";
  };

  renderDuration = (duration, durationType) => {
    return duration ? `${duration} ${durationType}` : "N/A";
  };

  renderHourlyFee = (fee, currencyType) => {
    return fee ? `${fee} ${currencyType}` : "N/A";
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOpportunity !== this.props.selectedOpportunity) {
      this.props.onSelectedActionChange({ selectedAction: "" });
    }
  }

  render() {
    const {
      selectedOpportunity,
      onAcceptedResumes,
      onDeclinedResumes,
      onNoActionResumes,
      labels,
      onSelectedActionChange,
      declinedRequests,
      acceptedRequests,
      pendingRequests,
      selectedShortlists,
      handleListOpenMobile,
      handleStatusChange,
      status
    } = this.props;

    return (
      <>
        <div className="new-opportunity-detail">
          <div className="feedbackWrap">
            <div className="msgItemDiv">
              <Row
                gutter={[8, 24]}
                className="w-100"
              >
                <Col xs={12}>
                  <p className="title-label">
                    {labels?.SearcherOpportunitySentDetailStartDate || "N/A"}
                  </p>
                  <p className="title">
                    {formatDate(selectedOpportunity?.StartDate)}
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="title-label">
                    {labels?.SearcherOpportunitySentDetailDuration || "N/A"}
                  </p>
                  <p className="title">
                    {this.renderDuration(
                      selectedOpportunity?.Duration,
                      selectedOpportunity?.DurationType
                    )}
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="title-label">
                    {labels?.SearcherOpportunitySentDetailFee || "N/A"}
                  </p>
                  <p className="title">
                    {this.renderHourlyFee(
                      selectedOpportunity?.HourlyFee,
                      selectedOpportunity?.FeeCurrencyType
                    )}
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="title-label">
                    {labels?.SearcherOpportunitySentDetailLocations || "N/A"}
                  </p>
                  <p className="title">
                    {selectedOpportunity?.Countries || "N/A"}

                  </p>
                </Col>
                <Col xs={12}>
                  <p className="title-label">
                    {labels?.SearcherOpportunitySentDetailLanguages || "N/A"}
                  </p>
                  <p className="title">
                    {selectedOpportunity?.Languages || "N/A"}

                  </p>
                </Col>
                <Col xs={12}>
                  <p className="title-label">
                    {labels?.SearcherOpportunitySentDetailCompany || "N/A"}
                  </p>
                  <p className="title">
                    {selectedOpportunity?.CompanyName || "N/A"}
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="title-label">
                    {labels?.SearcherOpportunitySentDetailSavedSearch || "N/A"}
                  </p>
                  <p className="title">
                    {selectedOpportunity?.SearchName || "N/A"}
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="title-label">
                    {labels?.SearcherOpportunitySentDetailDescription || "N/A"}
                  </p>
                  <p className="title">
                    {selectedOpportunity?.Description || "N/A"}
                  </p>
                </Col>
              </Row>

              <Row
                className=""
                style={{
                  alignItems: "center",
                  paddingBottom: "12px",
                  marginTop: "20px",
                }}
              >
                <Col xs={24}>
                  <div>
                    <Select
                      size="large"
                      className={`bg-color-for-opportunity  ${selectedOpportunity?.invalidShortList ? "inValid" : ""
                        }`}
                      bordered={false}
                      style={{ width: "100%" }}
                      value={selectedShortlists && selectedShortlists}
                      placeholder="Search and add your shortlist"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={selectedShortlists || []}
                    />
                  </div>
                </Col>
              </Row>
            </div>

          </div>
        </div>
        <div className="footer-send-opportunutiy">
          <Row className="w-100" gutter={[{ xs: 0, sm: 12, md: 12, lg: 12 }, 4]}>
            <Col xs={24} sm={12} md={24}>
              <Select
                className="dropdown-callooration"
                size="medium"
                bordered={false}
                placeholder="Status"
                value={status && status}
                onChange={(e) => {
                  if (e == "pending") {
                    onNoActionResumes();
                    onSelectedActionChange({ selectedAction: "pending" });
                    handleStatusChange(e)
                  }
                  if (e == "accepted") {
                    onAcceptedResumes();
                    onSelectedActionChange({ selectedAction: "accepted" });
                    handleStatusChange(e)
                  }
                  if (e == "declined") {
                    onDeclinedResumes();
                    onSelectedActionChange({ selectedAction: "declined" });
                    handleStatusChange(e)
                  }
                }}

              >
                <Select.Option value={"pending"}>
                  <div style={{ color: "#F6AA3A" }}>
                    {`${labels?.SearcherOpportunitySentDetailPending} (${pendingRequests || 0})`}
                  </div>
                </Select.Option>

                <Select.Option value={"accepted"}>
                  <div style={{ color: "#04CAA7" }}>
                    {`${labels?.SearcherOpportunitySentDetailAccepted} (${acceptedRequests || 0})`}&nbsp;
                  </div>
                </Select.Option>
                <Select.Option value={"declined"}>
                  <div style={{ color: "#F54949" }}>
                    {`${labels?.SearcherOpportunitySentDetailDeclined} (${declinedRequests || 0})`}&nbsp;
                  </div>
                </Select.Option>
              </Select>
            </Col>
            <Col xs={24} sm={12} md={0}>
              <div className="tabs-header-col my-0 w-100">
                <button className="tab-btn-coll w-100"
                  style={{ background: "#6C63FF", color: "#fff" }}
                  onClick={() => { handleListOpenMobile() }}
                >
                  {labels?.view_selected_resume}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default NewOpportunityDetail;
