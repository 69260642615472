import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  isResumeChange: false,
  message: "",
  isLogout: false,
  isRoleSwitchToggle: false,
  isLogoutTrigger: false,
  isRoleSwitchTrigger: false,

  showLinkedInImportView: false,
  selectedResume: {},
  selectedPDFResume: null,
  dialogMessage: "",
  getImgSrc: null,
  uploadPDFButtonDisable: true,
  dialogResumeUpload: "",
  isResumeImportCollapsed: false,
  fetchingResumes: true,
  detailCollapsed: false,
  importCollapsed: false,
  dialogResumeEdit: "",
  isPDFFileUploaded: false,
  PDFFileName: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.RESUME_ANY_CHANGE:
      return {
        ...state,
        isResumeChange: action.isResumeChange,
        message: action.message,
        isLogoutTrigger: action.isLogoutTrigger,
        isLogout: action.isLogout,
        isRoleSwitchTrigger: action.isRoleSwitchTrigger,
        isRoleSwitchToggle: action.isRoleSwitchToggle
      };
    case ActionTypes.RESUME.SHOW_LINKEDIN_IMPORT_VIEW:
      return { ...state, showLinkedInImportView: action.payload };
    case ActionTypes.RESUME.SELECTED_RESUME:
      return { ...state, selectedResume: action.payload };
    case ActionTypes.RESUME.SELECTED_PDF_RESUME:
      return { ...state, selectedPDFResume: action.payload };
    case ActionTypes.RESUME.DIALOG_MESSAGE:
      return { ...state, dialogMessage: action.payload };
    case ActionTypes.RESUME.GET_IMG_SRC:
      return { ...state, getImgSrc: action.payload };
    case ActionTypes.RESUME.UPLOAD_PDF_BUTTON_DISABLE:
      return { ...state, uploadPDFButtonDisable: action.payload };
    case ActionTypes.RESUME.DIALOG_RESUME_UPLOAD:
      return { ...state, dialogResumeUpload: action.payload };
    case ActionTypes.RESUME.IS_RESUME_IMPORT_COLLAPSED:
      return { ...state, isResumeImportCollapsed: action.payload };
    case ActionTypes.RESUME.FETCHING_RESUMES:
      return { ...state, fetchingResumes: action.payload };
    case ActionTypes.RESUME.DETAIL_COLLAPSED:
      return { ...state, detailCollapsed: action.payload };
    case ActionTypes.RESUME.IMPORT_COLLAPSED:
      return { ...state, importCollapsed: action.payload };
    case ActionTypes.RESUME.DIALOG_RESUME_EDIT:
      return { ...state, dialogResumeEdit: action.payload };
    case ActionTypes.RESUME.IS_PDF_FILE_UPLOADED:
      return { ...state, isPDFFileUploaded: action.payload };
    case ActionTypes.RESUME.PDF_FILE_NAME:
      return { ...state, PDFFileName: action.payload };

    default:
      return state;
  }
};
