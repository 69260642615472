import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  user: {
    IsNotificationRead: false,
    IsPhilipFreelancerViewed: true,
    IsPhilipSearcherViewed: true,
    IsFreelancer: null,
    ProfileId: null,
    ProfileValue: null
  },
  isAuthenticated: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        isAuthenticated: true
      };
    case ActionTypes.COOKIE:
      return {
        ...state,
        user: { ...state.user, IsNotificationRead: action.payload }
      };
    case ActionTypes.PROFILEIDBYVISITOR:
      return {
        ...state,
        user: { ...state.user, ProfileId: action.payload }
      };
    case ActionTypes.PROFILEVALUEBYVISITOR:
      return {
        ...state,
        user: { ...state.user, ProfileValue: action.payload }
      };
    default:
      return state;
  }
};
