import React, { Component } from "react";
import {
  Column,
  Button,
  SearchInput,
  EmptyInfo,
  ConfirmDialog,
  Input,
  LoadingMaskRow,
  List,
} from "../../../../common";
import PageWrapper from "../../../../components/PageWrapper/PageWrapper";
import ShortlistResumes from "./component/ShortlistResumes";
import { notificationAction } from "../../../../actions";
import toLower from "lodash/toLower";
import ShortlistResumeDetail from "./component/ShortlistResumeDetail";
import filter from "lodash/filter";
import { connect } from "react-redux";
import find from "lodash/find";
import first from "lodash/first";
import moment from "moment";
import includes from "lodash/includes";
import {
  getSavedSearchesApi,
  getShortlistResumeApi,
  postShortlistApi,
  deleteSavedSearchApi,
  getSaveSearchDetailApi,
  searchResumesApi,
  updateSavedSearchName,
} from "./savedSearchApi";
import { privateRoutes } from "../../../../Routes/routing";

import "./saved-search.scss";
import { isEmpty } from "lodash";
import SettingDetail from "../../components/SettingDetail";

class SavedSearch extends Component {
  state = {
    searchCollapsed: false,
    resumeCollapsed: false,
    resumeDetailCollapsed: false,
    createCollapse: false,
    savedSearches: [],
    selectedResume: {},
    selectedSavedSearch: {},
    shortlistResumes: [],
    filteredSavedSearches: [],
    isLoading: false,
    fetchingSavedSearches: true,
    fetchingResumes: false,
    opportunityList: [],
    fetchingOpportunities: false,
    dialogMessage: "",
    newShortlistValue: "",
    createNewShortlist: false,
    dialogOpportunityMessage: "",
    showOpportunities: false,
    opportunitiesCollapse: false,
    searchKey: "",
    deletedId: null,
  };

  componentDidMount() {
    this.getSavedSearches().then(() => {
      const { history } = this.props;
      if (history) {
        const {
          location: { state },
        } = history;
        if (state) {
          const { savedSearches } = state;
          this.handleSelectShortlist(savedSearches);
        }
        const { onBoardShortlistId } = this.state;
        if (onBoardShortlistId) {
          this.getSavedSearches();
        }
      }
    });
  }

  getSavedSearches = (selectedId) => {
    this.setState({ fetchingSavedSearches: true });
    return getSavedSearchesApi()
      .then((data) => {
        this.setState({
          savedSearches: data.items,
          filteredSavedSearches: data.items,
          fetchingSavedSearches: false,
        });
        const { onBoardShortlistId } = this.props;
        if (selectedId) {
          this.setState({
            savedSearches: data.items,
            filteredSavedSearches: data.items,
          });
          this.handleSelectShortlist(
            data.items.find((a) => a.SavedSearchId == selectedId)
          );
        }
      })
      .catch(() => {
        this.setState({
          fetchingSavedSearches: false,
        });
      });
  };

  handleSelectShortlist = (item) => {
    this.setState({
      selectedSavedSearch: item,
      createNewShortlist: false,
      fetchingResumes: true,
      showOpportunities: false,
      clonedShortlist: {},
    });
    getSaveSearchDetailApi(item.SavedSearchId)
      .then((response) => {
        let newSetting = {
          roles: [],
          skills: [],
          keywords: [],
          certifications: [],
          industries: [],
          countries: [],
          languages: [],
        };
        if (response.success) {
          const savedSearchSettings = response.items.SavedSearchSettings.reduce(
            function(filtered, option) {
              switch (option.LookupTypeId) {
                case 1: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    ProfileId: option.SearchedPK,
                    ProfileValue: option.SearchedValue,
                  };
                  newSetting.roles.push(someNewValue);
                  break;
                }
                case 2: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    SkillId: option.SearchedPK,
                    SkillValue: option.SearchedValue,
                  };
                  newSetting.skills.push(someNewValue);
                  break;
                }
                case 3: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    KeywordId: option.SearchedPK,
                    KeywordValue: option.SearchedValue,
                  };
                  newSetting.keywords.push(someNewValue);
                  break;
                }
                case 4: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    IndustryId: option.SearchedPK,
                    IndustryValue: option.SearchedValue,
                  };
                  newSetting.industries.push(someNewValue);
                  break;
                }
                case 5: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    CertificationId: option.SearchedPK,
                    CertificationValue: option.SearchedValue,
                  };
                  newSetting.certifications.push(someNewValue);
                  break;
                }
                case 6: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    LanguageId: option.SearchedPK,
                    LanguageValue: option.SearchedValue,
                  };
                  newSetting.languages.push(someNewValue);
                  break;
                }
                case 7: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    CountryId: option.SearchedPK,
                    CountryName: option.SearchedValue,
                  };
                  newSetting.countries.push(someNewValue);
                  break;
                }
              }
              filtered = newSetting;
              return filtered;
            },
            []
          );
          const newSavedSearchSettings = savedSearchSettings.length
            ? savedSearchSettings
            : newSetting;
          this.setState({
            selectedSavedSearch: {
              ...this.state.selectedSavedSearch,
              savedSearchSettings: newSavedSearchSettings,
            },
          });
          const info = {
            CertificationIds: savedSearchSettings.certifications.map(
              (item) => item.CertificationId
            ),
            Countries: savedSearchSettings.countries.map(
              (item) => item.CountryId
            ),
            IndusteryIds: savedSearchSettings.industries.map(
              (item) => item.IndustryId
            ),
            KeywordIds: savedSearchSettings.keywords.map(
              (item) => item.KeywordId
            ),
            Languages: savedSearchSettings.languages.map(
              (item) => item.LanguageId
            ),
            ProfileIds: savedSearchSettings.roles.map((item) => item.ProfileId),
            SkillIds: savedSearchSettings.skills.map((item) => item.SkillId),
            Type: "Only20",
            limit: 20,
            page: 1,
          };
          searchResumesApi(info)
            .then((data) => {
              this.setState({
                shortlistResumes: data.items.map((item, index) => {
                  return {
                    ...item,
                    active: index == 0 ? true : false,
                  };
                }),
                fetchingResumes: false,
                selectedResume: first(data.items) || {},
              });
            })
            .catch(() => {
              this.setState({ fetchingResumes: false });
            });
        }
      })
      .catch((res) => console.log("Error ", res));
  };

  handleResumeSelect = (item) => {
    const { shortlistResumes } = this.state;
    const selectedResume = find(shortlistResumes, { ResumeId: item.ResumeId });
    this.setState({ selectedResume });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { savedSearches } = this.state;

    const filteredSavedSearches = filter(savedSearches, (opp) =>
      includes(toLower(opp.ShortlistName), toLower(value))
    );
    this.setState({ filteredSavedSearches, searchKey: value });
  };
  handleShortlistActive = (activeItem) => {
    const shortlistResumes = this.state.shortlistResumes.map((item) => ({
      ...item,
      active:
        activeItem.ResumeId === item.ResumeId ? !item.active : item.active,
    }));
    this.setState({
      shortlistResumes,
    });
  };
  handleCreateNewShorlistClick = () => {
    const { selectedSavedSearch } = this.state;
    this.setState({ isLoading: true });
    updateSavedSearchName(
      selectedSavedSearch.SavedSearchId,
      selectedSavedSearch.SearchName
    )
      .then((data) => {
        if (data.success) {
          this.getSavedSearches(data.items);
          const info = {
            message: "Saved Search Name successfully updated",
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: "Error while updating saved search name",
            status: "info",
          };
          this.props.notificationAction(info);
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };
  handleNewShortList = () => {
    this.setState({
      createNewShortlist: true,
      newShortlistValue: "",
      selectedResume: {},
      selectedSavedSearch: {},
    });
  };
  handleDeleteSavedSearch = (id) => {
    this.setState({
      deletedId: id,
      dialogMessage: "Are you sure you want to delete this saved search",
    });
  };
  handleYesClick = () => {
    const { deletedId } = this.state;
    this.setState({ dialogMessage: "" });
    this.setState({ isLoading: true });
    deleteSavedSearchApi({ id: deletedId })
      .then((data) => {
        if (data.success) {
          this.getSavedSearches();
          const info = {
            message: "Saved search successfully deleted",
            status: "success",
          };
          this.props.notificationAction(info);
          this.setState({
            selectedSavedSearch: {},
            selectedResume: {},
            deletedId: null,
          });
        } else {
          const info = {
            message: data.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };
  handleNoClick = () => {
    this.setState({ dialogMessage: "", deletedId: null });
  };
  handleCreateNewClick = (item) => {
    this.setState({
      clonedShortlist: item,
      selectedSavedSearch: {},
    });
    getShortlistResumeApi(item.SavedSearchId)
      .then((data) => {
        const resumes = data.items.map((item, index) => {
          return {
            ...item,
            active: index == 0 ? true : false,
          };
        });
        this.setState({
          clonedShortlist: {
            ...item,
            ShortlistResumes: resumes,
          },
          shortlistResumes: resumes,
        });
      })
      .catch(() => {
        this.setState({ fetchingResumes: false });
      });
  };
  createNewShortlist = () => {
    const { shortlistResumes } = this.state;
    const selectedResumesIds = shortlistResumes
      .filter((item) => item.active)
      .map((item) => item.ResumeId);
    this.setState({ isLoading: true });
    const info = {
      ShortlistName: `New savedSearches for ${
        selectedResumesIds.length
      } resume(s) at ${moment(new Date()).format("D MMMM YYYY")} at ${moment(
        new Date()
      ).format("LTS")}`,
      ResumeIdsList: selectedResumesIds,
    };
    return postShortlistApi(info)
      .then((data) => {
        return data;
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };
  handleCancelClick = () => {
    this.setState({ dialogOpportunityMessage: "" });
  };
  handleSelectOpportunity = (item) => {
    this.createNewShortlist().then(() => {
      sessionStorage.setItem("opportunity", JSON.stringify(item));
      this.props.history.push(privateRoutes.searcherCreateOpportunity.path);
    });
  };
  onResumeDelete = (item) => {
    this.setState({
      clonedShortlist: {
        ...this.state.clonedShortlist,
        ShortlistResumes: this.state.clonedShortlist.ShortlistResumes.filter(
          (x) => x.ResumeId != item.ResumeId
        ),
      },
    });
  };
  render() {
    const {
      searchCollapsed,
      resumeCollapsed,
      resumeDetailCollapsed,
      selectedSavedSearch,
      selectedResume,
      shortlistResumes,
      dialogMessage,
      filteredSavedSearches,
      createCollapse,
      fetchingResumes,
      dialogOpportunityMessage,
      opportunityList,
      showOpportunities,
      fetchingOpportunities,
      opportunitiesCollapse,
      searchKey,
      clonedShortlist = {},
    } = this.state;
    const { labels, isHelpActive } = this.props;
    return (
      <PageWrapper className="saved-search-page">
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleYesClick}
                testId="delete-yes"
              >
                {labels.shortlistDeletionCurtainYESBtnText}
              </ConfirmDialog.Button>

              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              >
                {labels.shortlistDeletionCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {dialogOpportunityMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>
              {dialogOpportunityMessage}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleExistingClick}
              >
                {labels.ShortlistResumeListCurtainExistingBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleCreateNewClick}
              >
                {labels.ShortlistResumeListCurtainCreateNewBtnText}
              </ConfirmDialog.Button>

              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleCancelClick}
              >
                {labels.ShortlistResumeListCurtainCancelBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {!this.props.onBoardShortlistId && (
          <Column collapse={searchCollapsed} className="col-1">
            <Column.Collapsed
              onClick={() => this.setState({ searchCollapsed: false })}
              testId={"expand-btn-1"}
              tooltipPlace="left"
              text={labels.ViewTitleOpportunityShortlistSection}
              tooltipButton={labels.ToolTipShortlistExpandList}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpToolTipShortlistExpand}
            />
            <Column.Head>
              <SearchInput
                testId="search-input"
                value={searchKey}
                placeholder={"Search for your saved searches"}
                onChange={this.handleSearchChange}
              />
              <Button
                testId={"collapse-btn-1"}
                className="collapseBtn"
                tooltipPlace="left"
                tooltipButton={labels.ToolTipShortlistCollapse}
                onClick={() => this.setState({ searchCollapsed: true })}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistCollapse}
              />
            </Column.Head>
            <Column.Body>
              {filteredSavedSearches.map((item) => (
                <div
                  key={item.SavedSearchId}
                  test-data-id={`savedsearches-list-item${
                    item.isActive ? "-activeItem" : ""
                  }`}
                  className={`tictell-list-item-container  ${
                    item.SavedSearchId === selectedSavedSearch.SavedSearchId
                      ? "selected-item"
                      : ""
                  } ${
                    item.SavedSearchId === selectedSavedSearch.SavedSearchId
                      ? "activeItem"
                      : ""
                  }`}
                >
                  <div
                    onClick={() => this.handleSelectShortlist(item)}
                    className="name-container flow-container"
                  >
                    <label
                      className="pool-label"
                      test-data-id={`collabs-list-owner-username`}
                    >
                      {item.SearchName}
                    </label>
                  </div>
                  {
                    <Button
                      onClick={() =>
                        this.handleDeleteSavedSearch(item.SavedSearchId)
                      }
                      className="closeBtn"
                      tooltipButton={"Delete saved search"}
                      tooltipHelp={"Delete saved search"}
                      tooltipPlace="left"
                      isHelpActive={isHelpActive}
                      testId="delete-flow-btn"
                    />
                  }
                </div>
              ))}
            </Column.Body>
          </Column>
        )}
        {selectedSavedSearch &&
          !isEmpty(selectedSavedSearch.savedSearchSettings) && (
            <Column collapse={createCollapse} className="col-2">
              <Column.Collapsed
                onClick={() => this.setState({ createCollapse: false })}
                testId={"expand-btn-2"}
                tooltipButton={labels.TooltipShortlistCreateShortlistExpand}
                tooltipPlace="left"
                text={labels.ViewTitleShortlistCreateShortlist}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistCreateShortlistExpand}
              />
              <Column.Head>
                <Column.HeaderTitle isActive={true}>
                  {"Search Settings"}
                </Column.HeaderTitle>
                <Button
                  className="SaveCloudBtn"
                  onClick={this.handleCreateNewShorlistClick}
                  tooltipPlace="left"
                  testId="button-savedSearches-save"
                  disabled={!selectedSavedSearch.SearchName}
                  isHelpActive={isHelpActive}
                  tooltipButton={"Update saved search name"}
                  tooltipHelp={"Update saved search name"}
                />
                <Button
                  className="collapseBtn"
                  testId={"collapse-btn-2"}
                  tooltipButton={labels.TooltipShortlistCreateShortlistCollapse}
                  isHelpActive={isHelpActive}
                  tooltipHelp={
                    labels.HelpTooltipShortlistCreateShortlistCollapse
                  }
                  tooltipPlace="left"
                  onClick={() => this.setState({ createCollapse: true })}
                />
              </Column.Head>
              <Column.Body className="form-editor">
                <label className="form-label">{"Saved Search Name"}</label>
                <Input
                  autoFocus
                  type="text"
                  testId="input-text-savedSearches-name"
                  className="input-text"
                  value={selectedSavedSearch.SearchName || ""}
                  onChange={(e) =>
                    this.setState({
                      selectedSavedSearch: {
                        ...selectedSavedSearch,
                        SearchName: e.target.value,
                      },
                    })
                  }
                />
                <SettingDetail
                  labels={labels}
                  settings={selectedSavedSearch.savedSearchSettings}
                />
              </Column.Body>
            </Column>
          )}
        {isEmpty(clonedShortlist) && (
          <Column collapse={resumeCollapsed} className="col-2">
            <Column.Collapsed
              onClick={() => this.setState({ resumeCollapsed: false })}
              testId={"expand-btn-3"}
              tooltipButton={labels.TooltipShortlistResumeListExpand}
              tooltipPlace="left"
              text={labels.ViewTitleShortlistResult}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipShortlistResumeListExpand}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={selectedSavedSearch}>
                {fetchingResumes || !selectedSavedSearch.SavedSearchId
                  ? ""
                  : shortlistResumes.length > 0
                  ? shortlistResumes.length +
                    " " +
                    labels.HeadingShorlistResumeListResult
                  : labels.HeadingShorlistResumeListNoResume}
              </Column.HeaderTitle>
              <Button
                className="collapseBtn"
                testId={"collapse-btn-3"}
                tooltipButton={labels.TooltipShortlistResumeListCollapse}
                onClick={() => this.setState({ resumeCollapsed: true })}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistResumeListCollapse}
              />
            </Column.Head>
            <Column.Body className="flex">
              {selectedSavedSearch.SavedSearchId ? (
                <ShortlistResumes
                  fetchingResumes={fetchingResumes}
                  handleSelectShortlist={this.handleSelectShortlist}
                  selectedShortlist={selectedSavedSearch}
                  onResumeSelect={this.handleResumeSelect}
                  selectedResume={selectedResume}
                  shortlistResumes={shortlistResumes}
                  hideRemoveButton={true}
                />
              ) : (
                <EmptyInfo>{labels.emptySavedSearchHeadsUp}</EmptyInfo>
              )}
            </Column.Body>
          </Column>
        )}
        {showOpportunities && (
          <Column collapse={opportunitiesCollapse} className="col-3">
            <Column.Collapsed
              onClick={() => this.setState({ opportunitiesCollapse: false })}
              testId={"expand-btn-4"}
              tooltipPlace="left"
              text={labels.ViewTitleShortlistOpportunityList}
              isHelpActive={isHelpActive}
              tooltipButton={labels.TooltipShortlistOpportunityListExpand}
              tooltipHelp={labels.HelpTooltipShortlistOpportunityListExpand}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={true}>
                {labels.HeadingShortlistOpportunityList}
              </Column.HeaderTitle>

              <Button
                className="collapseBtn"
                testId={"collapse-btn-4"}
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                onClick={() =>
                  this.setState({
                    opportunitiesCollapse: true,
                  })
                }
                tooltipButton={labels.TooltipShortlistOpportunityListCollapse}
                tooltipHelp={labels.HelpTooltipShortlistOpportunityListCollapse}
              />
            </Column.Head>
            <Column.Body>
              <List isFetching={fetchingOpportunities}>
                {opportunityList.map((item) => (
                  <List.ListItem
                    onClick={() => this.handleSelectOpportunity(item)}
                    key={item.RequestId}
                  >
                    {item.RequestName}
                  </List.ListItem>
                ))}
              </List>
            </Column.Body>
          </Column>
        )}
        {!showOpportunities && selectedResume.ResumeId && (
          <Column collapse={resumeDetailCollapsed} className="col-3">
            <Column.Collapsed
              tooltipButton={labels.TooltipShortlistResumeDetailExpand}
              text={labels.ViewTitleShortlistDetail}
              onClick={() => this.setState({ resumeDetailCollapsed: false })}
              testId={"expand-btn-5"}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipShortlistResumeDetailExpand}
            />
            <Column.Head>
              <div className="heading">Resume Detail</div>
              <Button
                className="collapseBtn"
                onClick={() =>
                  this.setState({
                    resumeDetailCollapsed: true,
                  })
                }
                testId={"collapse-btn-5"}
                tooltipButton={labels.ToolTipShortlistCollaspeResumeDetail}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistResumeDetailCollapse}
              />
            </Column.Head>
            <Column.Body>
              {fetchingResumes ? (
                <LoadingMaskRow />
              ) : (
                <ShortlistResumeDetail resume={selectedResume} />
              )}
            </Column.Body>
          </Column>
        )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive };
};

export default connect(
  mapStateToProps,
  { notificationAction }
)(SavedSearch);
