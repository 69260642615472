import React, { Component } from "react";
import "./monitoring.scss";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import { Button, Column, Select } from "../../../../common";
import {
  getShortlistApi,
  getSentOpportunitesApi,
  getSavedSearchesApi,
} from "../../philipApi";
import {
  postMonitorOpportunityApi,
  postMonitorSavedSearchApi,
  postMonitorShortlistApi,
} from "./monitoringApi";
import { Col, Row } from "antd";
import { Collapse, Divider, Select as Select2 } from "antd";
import Col2 from "./mobileView/col2";
import { useState } from "react";
import Col3 from "./mobileView/col3";
import { withRouter } from "react-router-dom";
const { Panel } = Collapse;
const { Option } = Select2;

class Monitoring extends React.Component {
  state = {
    allOpportunites: [],
    isLoading: false,
    value: "shortlist",
    savedSearches: [],
    shortlist: [],
    opportunitiesList: [],
    selectedOpportunity: [],
    onShortlists: false,
    onSavedSearches: false,
    onOpportunities: false,
    shortlistItemClicked: false,
    opportunityItemClicked: false,
    savedSearchItemClicked: false,
    aNewIpro: false,
    topScoreChange: false,
    newAvailibility: false,
    ShortlistEmail: false,
    OpportunityEmail: false,
    WatchPending: false,
    WatchDeclined: false,
    collapse: false,
    currentSearch: {
      sendEmailOnScoreChange: false,
      sendOpportunityToScoreChanger: false,
      sendEmailOnNewIpro: false,
      sendOpportunityToNewIpro: false,
    },
  };
  componentDidMount() {
    this.getShortlist();
    this.getSavedSearches();
    this.getOpportunities();
    this.col1ItemClick("shortlists");
  }
  col1ItemClick = (type) => {
    this.setState({
      shortlistItemClicked: false,
      opportunityItemClicked: false,
      savedSearchItemClicked: false,
    });
    if (type == "shortlists") {
      this.setState({
        onShortlists: true,
        onOpportunities: false,
        onSavedSearches: false,
        newAvailibility: false,
        topScoreChange: false,
        aNewIpro: false,
      });
    } else if (type == "savedSearches") {
      this.setState({
        onShortlists: false,
        onOpportunities: false,
        onSavedSearches: true,
        newAvailibility: false,
        topScoreChange: false,
        aNewIpro: false,
      });
    }
    if (type == "opportunities") {
      this.setState({
        onShortlists: false,
        onOpportunities: true,
        onSavedSearches: false,
        newAvailibility: false,
        topScoreChange: false,
        aNewIpro: false,
      });
    }
  };

  onFormSelectChange = (type, selectedItem) => {
    const { currentSearch } = this.state;
    if (type == "topScoreChangeSelectedOpportunity") {
      this.setState({
        currentSearch: {
          ...currentSearch,
          topScoreChangeSelectedOpportunity: selectedItem,
        },
      });
    } else if (type == "newIproSelectedOpportunity") {
      this.setState({
        currentSearch: {
          ...currentSearch,
          newIproSelectedOpportunity: selectedItem,
        },
      });
    } else {
      this.setState({
        currentSearch: {
          ...currentSearch,
          selectedOpportunity: selectedItem,
        },
      });
      this.setState({
        selectedOpportunity: selectedItem,
      });
    }
  };
  col2ItemClick = (type, items) => {
    const { opportunitiesList, shortlist, savedSearches } = this.state;
    if (type == "shortlists") {
      const listItems = shortlist.map((item) => ({
        ...item,
        isSelected: item.ShortlistId == items?.value ? true : false,
      }));

      this.setState({
        shortlist: listItems,
        shortlistItemClicked: true,
        selectedShortlist: items?.value,
        opportunityItemClicked: false,
        savedSearchItemClicked: false,
      });
    } else if (type == "savedSearches") {
      const listItems = savedSearches.map((item) => ({
        ...item,
        isSelected: item.SavedSearchId == items?.value ? true : false,
      }));
      this.setState({
        shortlistItemClicked: false,
        opportunityItemClicked: false,
        savedSearchItemClicked: true,
        savedSearches: listItems,
        currentSearch: {
          SavedSearchId: items?.value,
          sendEmailOnScoreChange: false,
          sendOpportunityToScoreChanger: false,
          sendEmailOnNewIpro: false,
          sendOpportunityToNewIpro: false,
        },
      });
    }
    if (type == "opportunities") {
      const listItems = opportunitiesList.map((item) => ({
        ...item,
        isSelected: item.RequestId == items?.value ? true : false,
      }));
      this.setState({
        shortlistItemClicked: false,
        opportunityItemClicked: true,
        savedSearchItemClicked: false,
        opportunitiesList: listItems,
        selectedOpportunity: items?.value,
      });
    }
  };
  col3ItemClick = (type) => {
    if (type == "newAvailibility") {
      this.setState({
        newAvailibility: true,
        topScoreChange: false,
        aNewIpro: false,
      });
    } else if (type == "topScoreChange") {
      this.setState({
        aNewIpro: false,
        newAvailibility: false,
        topScoreChange: true,
      });
    }
    if (type == "aNewIpro") {
      this.setState({
        topScoreChange: false,
        aNewIpro: true,
        newAvailibility: false,
      });
    }
  };
  getShortlist = () => {
    return getShortlistApi()
      .then((data) => {
        if (data.success) {
          const shortlist = data.items.map((item) => ({
            ...item,
            isSelected: false,
            label: item?.ShortlistName,
            value: item?.ShortlistId,
          }));
          this.setState({
            shortlist: shortlist,
            fetchingShortlist: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          fetchingShortlist: false,
        });
      });
  };
  getOpportunities = () => {
    let isFreelancer = false;
    getSentOpportunitesApi({ isFreelancer }).then((response) => {
      if (response.success) {
        const opportubitiesList = response.items.Sent.map((item) => ({
          ...item,
          value: item.RequestId,
          label: item.RequestName,
          isSelected: false,
        }));
        this.onFormSelectChange(opportubitiesList[0]);
        this.setState({
          opportunitiesList: opportubitiesList,
        });
      }
    });
  };
  getSavedSearches = () => {
    return getSavedSearchesApi()
      .then((data) => {
        if (data.success) {
          const savedSearchesList = data.items.map((item) => ({
            ...item,
            isSelected: false,
            label: item?.SearchName,
            value: item?.SavedSearchId,
          }));
          this.setState({
            savedSearches: savedSearchesList,
          });
        }
      })
      .catch(() => {});
  };

  Col3Item = (IconClass, label, description, type) => {
    let isActive = this.state[type];
    return (
      <div
        onClick={() => this.col3ItemClick(type)}
        className={`${isActive ? "onBorders " : "transparentBorder"} mo-nav `}
      >
        <div>
          <i className={IconClass} />
          <label className="count-label">{label}</label>
        </div>
        <p>{description}</p>
      </div>
    );
  };

  onChange2 = (key) => {
    this.col3ItemClick(key);
  };

  Col3ItemMobile = (IconClass, label, description, type, key) => {
    let isActive = this.state[type];
    return (
      <Column collapse={key != this.state.collapse} className="col-1 ">
        <Column.Collapsed
          text={label}
          onClick={() => {
            this.onChange2(type);
            this.setState({ collapse: this.state.collapse == key ? "" : key });
          }}
          // isHelpActive={isHelpActive}
          // tooltipButton={labels.ToolTipSnapshotExpand}
          // tooltipHelp={labels.hlptxtSnapshotExpand}
          tooltipPlace="left"
          testId="expandbtn1"
        />
        <Column.Head>
          <div className="heading">{label}</div>
          <Button
            className="collapseBtn"
            testId="Collapasebtn1"
            onClick={() => {
              this.onChange2(type);
              this.setState({
                collapse: this.state.collapse == key ? "" : key,
              });
            }}
            // tooltipButton={labels.ToolTipSnapshotCollapse}
            // tooltipHelp={labels.hlptxtSnapshotCollapse}
            // tooltipPlace="left"
            // isHelpActive={isHelpActive}
          />
        </Column.Head>
        <Column.Body className="reports-body">
          <div style={{ padding: "10px" }}>
            <p className="paragraph">{description}</p>
            <div className="mo-col-4">
              {this.state.newAvailibility && this.state.onShortlists ? (
                <div>
                  <div className="check-items">
                    <input
                      name="ShortlistEmail"
                      onChange={this.onShortlistItemOptionChange}
                      type={"checkbox"}
                      value={this.state.ShortlistEmail}
                    ></input>
                    <label>
                      {
                        this.props.labels
                          .MONITORING_SHORTLIST_NEWAVAILIBILITY_CHECKBOX_DETAILS
                      }
                    </label>
                  </div>
                  <div className="check-items">
                    <input
                      name="OpportunityEmail"
                      onChange={this.onShortlistItemOptionChange}
                      type={"checkbox"}
                      value={this.state.OpportunityEmail}
                    ></input>
                    <label>
                      {
                        this.props.labels
                          .MONITORING_SHORTLIST_NEWAVAILIBILITY_CHECKBOX2_DETAILS
                      }
                    </label>
                  </div>
                  <Select
                    name="opportunities"
                    className={`select-input select-Workplace`}
                    placeholder={"select opportunity"}
                    value={
                      (this.state.currentSearch &&
                        this.state.currentSearch.selectedOpportunity) ||
                      ""
                    }
                    onChange={(selectedOption) =>
                      this.onFormSelectChange("", selectedOption)
                    }
                    clearable={false}
                    searchable={false}
                    options={this.state.opportunitiesList}
                  />
                  <div className="text-center-">
                    <button
                      onClick={() =>
                        this.handleMonitorClick("ShortlistAvailibility")
                      }
                      className="action-btn"
                    >
                      {this.props.labels.MONITOR_FOR_ME_LABEL}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.topScoreChange && this.state.onSavedSearches ? (
                <div>
                  <label>
                    {this.props.labels.MONITOR_TOP_TEN_SCORE_SAVEDSEARCH}
                  </label>
                  <ul>
                    <li>263</li>
                    <li>243</li>
                    <li>231</li>
                    <li>212</li>
                    <li>199</li>
                    <li>199</li>
                    <li>193</li>
                    <li>189</li>
                    <li>172</li>
                    <li>169</li>
                  </ul>
                  <div className="check-items">
                    <input
                      onChange={(value) =>
                        this.onCheckBoxChange("sendEmailOnScoreChange", value)
                      }
                      type={"checkbox"}
                      value={
                        this.state.currentSearch &&
                        this.state.currentSearch.sendEmailOnScoreChange
                      }
                    ></input>
                    <label>
                      {
                        this.props.labels
                          .MONITOR_SAVEDSEARCH_TOPSCORECHANGE_CHECKBOX1_DETAILS
                      }
                    </label>
                  </div>
                  <div className="check-items">
                    <input
                      onChange={(value) =>
                        this.onCheckBoxChange(
                          "sendOpportunityToScoreChanger",
                          value
                        )
                      }
                      type={"checkbox"}
                      value={
                        this.state.currentSearch &&
                        this.state.currentSearch.sendOpportunityToScoreChanger
                      }
                    ></input>
                    <label>
                      {
                        this.props.labels
                          .MONITOR_SAVEDSEARCH_TOPSCORECHANGE_CHECKBOX2_DETAILS
                      }
                    </label>
                  </div>
                  <Select
                    name="WorkplaceId"
                    className={`select-input select-Workplace`}
                    placeholder={"Select Opportunity"}
                    value={
                      (this.state.currentSearch &&
                        this.state.currentSearch
                          .topScoreChangeSelectedOpportunity) ||
                      ""
                    }
                    onChange={(selectedOption) =>
                      this.onFormSelectChange(
                        "topScoreChangeSelectedOpportunity",
                        selectedOption
                      )
                    }
                    clearable={false}
                    searchable={false}
                    options={this.state.opportunitiesList}
                  />
                  <div className="text-center-">
                    <button
                      onClick={() =>
                        this.handleSavedSearchMonitoringClick(
                          "scoreChangeMonitor"
                        )
                      }
                      className="action-btn"
                    >
                      Monitor this for me
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.aNewIpro && this.state.onSavedSearches ? (
                <div>
                  <label>
                    {
                      this.props.labels
                        .MONITOR_SAVEDSEARCH_ANEWIPRO_ASSOONAS_LABEL
                    }
                  </label>
                  <ul>
                    <li>{this.props.labels.MONITOR_TOP_5_LABEL} </li>
                    <li>{this.props.labels.MONITOR_TOP_10_LABEL}</li>
                    <li>{this.props.labels.MONITOR_TOP_20_LABEL}</li>
                    <li>{this.props.labels.MONITOR_TOP_100_LABEL}</li>
                  </ul>
                  <div className="check-items">
                    <input
                      onChange={(value) =>
                        this.onCheckBoxChange("sendEmailOnNewIpro", value)
                      }
                      type={"checkbox"}
                      value={
                        this.state.currentSearch &&
                        this.state.currentSearch.sendEmailOnNewIpro
                      }
                    ></input>
                    <label>
                      {
                        this.props.labels
                          .MONITOR_SAVEDSEARCH_ANEWIPRO_CHECKBOX1_DETAILS
                      }
                    </label>
                  </div>
                  <div className="check-items">
                    <input
                      onChange={(value) =>
                        this.onCheckBoxChange("sendOpportunityToNewIpro", value)
                      }
                      type={"checkbox"}
                      value={
                        this.state.currentSearch &&
                        this.state.currentSearch.sendOpportunityToNewIpro
                      }
                    ></input>
                    <label>
                      {
                        this.props.labels
                          .MONITOR_SAVEDSEARCH_ANEWIPRO_CHECKBOX2_DETAILS
                      }
                    </label>
                  </div>
                  <Select
                    name="opportunities"
                    className={`select-input select-Workplace`}
                    placeholder={"select opportunity"}
                    value={
                      (this.state.currentSearch &&
                        this.state.currentSearch.newIproSelectedOpportunity) ||
                      ""
                    }
                    onChange={(selectedOption) =>
                      this.onFormSelectChange(
                        "newIproSelectedOpportunity",
                        selectedOption
                      )
                    }
                    clearable={false}
                    searchable={false}
                    options={this.state.opportunitiesList}
                  />
                  <div className="text-center-">
                    <button
                      onClick={() =>
                        this.handleSavedSearchMonitoringClick(
                          "newScorerMonitor"
                        )
                      }
                      className="action-btn"
                    >
                      {this.props.labels.MONITOR_FOR_ME_LABEL}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.newAvailibility && this.state.onOpportunities ? (
                <div>
                  <div className="check-items">
                    <input
                      name="WatchDeclined"
                      onChange={this.onShortlistItemOptionChange}
                      type={"checkbox"}
                      value={this.state.WatchDeclined}
                    ></input>
                    <label>
                      Send the email if anyone who declined or haven't responded
                      becomes available
                    </label>
                  </div>
                  <div className="check-items">
                    <input
                      name="WatchPending"
                      onChange={this.onShortlistItemOptionChange}
                      type={"checkbox"}
                      value={this.state.WatchPending}
                    ></input>
                    <label>
                      {
                        this.props.labels
                          .MONITOR_OPPORTUNITY_NEWAVAIL_CHECKBOX1_DETAILS
                      }
                    </label>
                  </div>
                  <Select
                    name="WorkplaceId"
                    className={`select-input select-Workplace`}
                    placeholder={"Select Opportunity"}
                    value={
                      (this.state.currentSearch &&
                        this.state.currentSearch.selectedOpportunity) ||
                      ""
                    }
                    onChange={(selectedOption) =>
                      this.onFormSelectChange("", selectedOption)
                    }
                    clearable={false}
                    searchable={false}
                    options={this.state.opportunitiesList}
                  />
                  <div className="text-center-">
                    <button
                      onClick={() =>
                        this.handleSavedSearchMonitoringClick(
                          "newScorerMonitor"
                        )
                      }
                      className="action-btn"
                    >
                      {this.props.labels.MONITOR_FOR_ME_LABEL}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.topScoreChange && this.state.onOpportunities ? (
                <div>
                  <label>
                    {
                      this.props.labels
                        .MONITOR_OPPORTUNITY_TOPSCORE_TOP_TEN_DETAILS
                    }
                  </label>
                  <ul>
                    <li>263</li>
                    <li>243</li>
                    <li>231</li>
                    <li>212</li>
                    <li>199</li>
                    <li>199</li>
                    <li>193</li>
                    <li>189</li>
                    <li>172</li>
                    <li>169</li>
                  </ul>
                  <div className="check-items">
                    <input type={"checkbox"}></input>
                    <label>
                      {
                        this.props.labels
                          .MONITOR_OPPORTUNITY_TOPSCORE_CHECKBOX1_DETAILS
                      }
                    </label>
                  </div>
                  <div className="check-items">
                    <input type={"checkbox"}></input>
                    <label>
                      {
                        this.props.labels
                          .MONITOR_OPPORTUNITY_TOPSCORE_CHECKBOX2_DETAILS
                      }
                    </label>
                  </div>
                  <Select
                    name="WorkplaceId"
                    className={`select-input select-Workplace`}
                    placeholder={"Select Opportunity"}
                    value={
                      (this.state.currentSearch &&
                        this.state.currentSearch
                          .topScoreChangeSelectedOpportunity) ||
                      ""
                    }
                    onChange={(selectedOption) =>
                      this.onFormSelectChange(
                        "topScoreChangeSelectedOpportunity",
                        selectedOption
                      )
                    }
                    clearable={false}
                    searchable={false}
                    options={this.state.opportunitiesList}
                  />
                  <div className="text-center-">
                    <button
                      className="action-btn"
                      onClick={() =>
                        this.handleSavedSearchMonitoringClick(
                          "scoreChangeMonitor"
                        )
                      }
                    >
                      {this.props.labels.MONITOR_FOR_ME_LABEL}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.aNewIpro && this.state.onOpportunities ? (
                <div>
                  <label>
                    {
                      this.props.labels
                        .MONITOR_OPPORTUNITY_ANEWIPRO_ASSOONAS_LABEL
                    }
                  </label>
                  <ul>
                    <li>{this.props.labels.MONITOR_TOP_5_LABEL}</li>
                    <li>{this.props.labels.MONITOR_TOP_10_LABEL}</li>
                    <li>{this.props.labels.MONITOR_TOP_20_LABEL}</li>
                    <li>{this.props.labels.MONITOR_TOP_100_LABEL}</li>
                  </ul>
                  <label>
                    {
                      this.props.labels
                        .MONITOR_OPPORTUNITY_ANEWIPRO_FOR_THE_SAVED_SEARCHLABEL
                    }
                  </label>
                  <div className="check-items">
                    <input type={"checkbox"}></input>
                    <label>
                      {
                        this.props.labels
                          .MONITOR_SAVEDSEARCH_ANEWIPRO_CHECKBOX1_DETAILS
                      }
                    </label>
                  </div>
                  <div className="check-items">
                    <input type={"checkbox"}></input>
                    <label>
                      {
                        this.props.labels
                          .MONITOR_OPPORTUNITY_ANEWIPRO_CHECKBOX1_DETAILS
                      }
                    </label>
                  </div>
                  <div className="check-items">
                    <input type={"checkbox"}></input>
                    <label>
                      {
                        this.props.labels
                          .MONITOR_OPPORTUNITY_ANEWIPRO_CHECKBOX2_DETAILS
                      }
                    </label>
                  </div>
                  <Select
                    name="WorkplaceId"
                    className={`select-input select-Workplace`}
                    placeholder={"Select Opportunity"}
                    value={
                      (this.state.currentSearch &&
                        this.state.currentSearch.newIproSelectedOpportunity) ||
                      ""
                    }
                    onChange={(selectedOption) =>
                      this.onFormSelectChange(
                        "newIproSelectedOpportunity",
                        selectedOption
                      )
                    }
                    clearable={false}
                    searchable={false}
                    options={this.state.opportunitiesList}
                  />
                  <div className="text-center-">
                    <button
                      className="action-btn"
                      onClick={() =>
                        this.handleSavedSearchMonitoringClick(
                          "newScorerMonitor"
                        )
                      }
                    >
                      {this.props.labels.MONITOR_FOR_ME_LABEL}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Column.Body>
      </Column>
    );
  };

  onShortlistItemOptionChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleMonitorClick = (type) => {
    this.setState({ isLoading: true });
    if (type == "ShortlistAvailibility") {
      const {
        OpportunityEmail,
        ShortlistEmail,
        selectedOpportunity,
        selectedShortlist,
      } = this.state;
      if (!OpportunityEmail && !ShortlistEmail) {
        const info = {
          message: "Please select at least one option to monitor",
          status: "error",
        };
        this.setState({ isLoading: false });
        this.props.notificationAction(info);
        return;
      }
      // const { WatchDeclined, WatchPending } = this.state;
      if (!selectedOpportunity) {
        const info = {
          message: "Please select at least one option to monitor",
          status: "error",
        };
        this.setState({ isLoading: false });
        this.props.notificationAction(info);
        return;
      }
      const data = {
        ShortlistId: selectedShortlist.ShortlistId,
        ...(selectedOpportunity &&
          OpportunityEmail && { OpportunityId: selectedOpportunity.RequestId }),
      };
      postMonitorShortlistApi(data).then((res) => {
        if (res.success) {
          const info = {
            message: "Shortlist successfully added into monitoring list",
            status: "success",
          };
          this.setState({ isLoading: false });
          this.props.notificationAction(info);
          return;
        } else {
          const info = {
            message: res.message,
            status: "error",
          };
          this.setState({ isLoading: false });
          this.props.notificationAction(info);
          return;
        }
      });
    }
    if (type == "OpportunityAvailibility") {
      const { WatchDeclined, WatchPending, selectedOpportunity } = this.state;
      if (!WatchDeclined && !WatchPending) {
        const info = {
          message: "Please select at least one option to monitor",
          status: "error",
        };
        this.setState({ isLoading: false });
        this.props.notificationAction(info);
        return;
      }
      const data = {
        WatchDeclined,
        WatchPending,
        OpportunityId: selectedOpportunity.RequestId,
      };

      postMonitorOpportunityApi(data).then((res) => {
        if (res.success) {
          const info = {
            message: "Shortlist successfully added into monitoring list",
            status: "success",
          };
          this.setState({ isLoading: false });
          this.props.notificationAction(info);
          return;
        } else {
          const info = {
            message: res.message,
            status: "error",
          };
          this.setState({ isLoading: false });
          this.props.notificationAction(info);
          return;
        }
      });
    }
  };

  onCheckBoxChange = (name, value) => {
    this.setState({
      currentSearch: {
        ...this.state.currentSearch,
        [`${name}`]: !this.state.currentSearch[`${name}`],
      },
    });
  };

  handleSavedSearchMonitoringClick = (type) => {
    this.setState({ isLoading: true });
    const { currentSearch } = this.state;
    if (type == "scoreChangeMonitor") {
      if (
        !currentSearch.sendEmailOnScoreChange &&
        !currentSearch.sendOpportunityToScoreChanger
      ) {
        const info = {
          message: "Please select at least one option to monitor",
          status: "error",
        };
        this.props.notificationAction(info);
        this.setState({ isLoading: false });
        return;
      }
      if (
        currentSearch.sendOpportunityToScoreChanger &&
        !currentSearch.topScoreChangeSelectedOpportunity
      ) {
        const info = {
          message: "Please select opportunity to send",
          status: "error",
        };
        this.props.notificationAction(info);
        this.setState({ isLoading: false });
        return;
      }
      const obj = {
        SavedSearchId: currentSearch.SavedSearchId,
        WatchScoreChange: currentSearch.sendEmailOnScoreChange,
        SendOpportunityOnScoreChange:
          currentSearch.sendOpportunityToScoreChanger,
        ...(currentSearch.sendOpportunityToScoreChanger && {
          ScoreChangeOpportunityId:
            currentSearch.topScoreChangeSelectedOpportunity.RequestId,
        }),
      };

      postMonitorSavedSearchApi(obj)
        .then((res) => {
          if (res.success) {
            const info = {
              message: "Saved Search successfully added into monitoring list",
              status: "success",
            };
            this.setState({ isLoading: false });
            this.props.notificationAction(info);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    }
    if (type == "newScorerMonitor") {
      if (
        !currentSearch.sendEmailOnNewIpro &&
        !currentSearch.sendOpportunityToNewIpro
      ) {
        const info = {
          message: "Please select at least one option to monitor",
          status: "error",
        };
        this.setState({ isLoading: false });
        this.props.notificationAction(info);
        return;
      }
      if (
        currentSearch.sendOpportunityToNewIpro &&
        !currentSearch.newIproSelectedOpportunity
      ) {
        const info = {
          message: "Please select opportunity to send",
          status: "error",
        };
        this.setState({ isLoading: false });
        this.props.notificationAction(info);
        return;
      }
      const obj = {
        SavedSearchId: currentSearch.SavedSearchId,
        WatchNewTopScrorer: currentSearch.sendEmailOnNewIpro,
        SendOpportunityToNewTopScorer: currentSearch.sendOpportunityToNewIpro,
        ...(currentSearch.sendOpportunityToNewIpro && {
          NewScorerOpportunityId:
            currentSearch.newIproSelectedOpportunity.RequestId,
        }),
      };

      postMonitorSavedSearchApi(obj)
        .then((res) => {
          if (res.success) {
            const info = {
              message: "Saved Search successfully added into monitoring list",
              status: "success",
            };
            this.setState({ isLoading: false });
            this.props.notificationAction(info);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    }
  };
  onChange = (key) => {
    if (key == "shortlist") {
      this.col1ItemClick("shortlists");
      this.setState({
        value: key,
        collapse: false,
      });
    }
    if (key == "saved") {
      this.col1ItemClick("savedSearches");
      this.setState({
        value: key,
        collapse: false,
      });
    }
    if (key == "opportunities") {
      this.col1ItemClick("opportunities");
      this.setState({
        value: key,
        collapse: false,
      });
    }
  };

  render() {
    const {
      onOpportunities,
      onSavedSearches,
      onShortlists,
      shortlist,
      savedSearches,
      opportunitiesList,
      savedSearchItemClicked,
      opportunityItemClicked,
      shortlistItemClicked,
      selectedOpportunity,
      aNewIpro,
      newAvailibility,
      topScoreChange,
      ShortlistEmail,
      OpportunityEmail,
      WatchPending,
      WatchDeclined,
      currentSearch,
      value,
      isLoading,
    } = this.state;
    const { widgetTestId, isHelpActive, labels } = this.props;
    return (
      <>
        <Row className="monitoring-view-mobile  mb-3">
          {isLoading && (
            <div id="loader-wrapper">
              <div test-data-id={"loader"} id="loader" />
            </div>
          )}
          <Col xs={24} md={0} className="aant-section-column-mobile ">
            <Select2
              style={{ width: "100%" }}
              placeholder="select"
              defaultValue={"shortlist"}
              onChange={(e) => this.onChange(e)}
            >
              <Option value="shortlist">
                <div className="product-menus-mobile">
                  <p>Shortlist</p>
                </div>
              </Option>
              <Option value="saved">
                <div className="product-menus-mobile">
                  <p>Saved Searches</p>
                </div>
              </Option>
              <Option value="opportunities">
                <div className="product-menus-mobile">
                  <p>Opportunities</p>
                </div>
              </Option>
            </Select2>

            {value === "shortlist" && (
              <div className="main-inner-mobile">
                <div className="paragraph">
                  A shortlist is a list of iPros we have bundeled together for a
                  reason we can monitor and see if anything changes within
                  shortlist.
                </div>
                <div className="mo-col-2">
                  {onShortlists && shortlist && shortlist.length > 0 ? (
                    <Col2
                      data={shortlist}
                      name="shortlists"
                      handleClick={this.col2ItemClick}
                    />
                  ) : (
                    <div className="empty-message-search">
                      You have no shortlist yet, please add atleast one
                      shortlist first
                    </div>
                  )}
                </div>
                <Col3
                  onShortlists={onShortlists}
                  shortlistItemClicked={shortlistItemClicked}
                  labels={labels}
                  onSavedSearches={onSavedSearches}
                  savedSearchItemClicked={savedSearchItemClicked}
                  onOpportunities={onOpportunities}
                  opportunityItemClicked={opportunityItemClicked}
                  Col3ItemMobile={this.Col3ItemMobile}
                  onChange2={this.onChange2}
                />
              </div>
            )}

            {value === "saved" && (
              <div className="main-inner-mobile">
                <div className="paragraph">
                  Saved Search is a set of search criteria we have saved to
                  reuse for interesting roles and skills. We can monitor a saved
                  search to see if we get new result.
                </div>
                <div className="mo-col-2">
                  {onSavedSearches &&
                  savedSearches &&
                  savedSearches.length > 0 ? (
                    <Col2
                      data={savedSearches}
                      name="savedSearches"
                      handleClick={this.col2ItemClick}
                    />
                  ) : (
                    <div className="empty-message-search">
                      You have no saved searches yet, please save atleast one
                      search first
                    </div>
                  )}
                </div>
                <Col3
                  onShortlists={onShortlists}
                  shortlistItemClicked={shortlistItemClicked}
                  labels={labels}
                  onSavedSearches={onSavedSearches}
                  savedSearchItemClicked={savedSearchItemClicked}
                  onOpportunities={onOpportunities}
                  opportunityItemClicked={opportunityItemClicked}
                  Col3ItemMobile={this.Col3ItemMobile}
                  onChange2={this.onChange2}
                />
              </div>
            )}
            {value === "opportunities" && (
              <div className="main-inner-mobile">
                <div className="paragraph">
                  An opportunity is a description of position or a job we need
                  to staff. We can monitor your Opportunities to see if we can
                  find new iPros that are a good match.
                </div>
                <div className="mo-col-2">
                  {onOpportunities &&
                  opportunitiesList &&
                  opportunitiesList.length > 0 ? (
                    <Col2
                      data={opportunitiesList}
                      name="opportunities"
                      handleClick={this.col2ItemClick}
                    />
                  ) : (
                    <div className="empty-message-search">
                      You have no opportunities yet, please add atleast one
                      opportunities first
                    </div>
                  )}
                </div>
                <Col3
                  onShortlists={onShortlists}
                  shortlistItemClicked={shortlistItemClicked}
                  labels={labels}
                  onSavedSearches={onSavedSearches}
                  savedSearchItemClicked={savedSearchItemClicked}
                  onOpportunities={onOpportunities}
                  opportunityItemClicked={opportunityItemClicked}
                  Col3ItemMobile={this.Col3ItemMobile}
                  onChange2={this.onChange2}
                />
              </div>
            )}
          </Col>
          <Col xs={0} md={24}>
            <div className="monitoring-view">
              <div className="mo-col-1">
                <div
                  onClick={() => this.col1ItemClick("shortlists")}
                  className={`${onShortlists ? "onBorders " : " "} mo-nav `}
                >
                  <div>
                    <i className="timesheet-icon" />
                    <label>{labels.MONITORING_SHORTLISTS_LABEL}</label>
                  </div>
                  <p>{labels.MONITORING_SHORTLIST_DETAILS}</p>
                </div>
                <div
                  onClick={() => this.col1ItemClick("savedSearches")}
                  className={`${onSavedSearches ? "onBorders " : " "} mo-nav `}
                >
                  <div>
                    <i className="timesheet-icon" />
                    <label>{labels.MONITORING_SAVEDSEARCH_LABEL}</label>
                  </div>
                  <p>{labels.MONITORING_SAVEDSEARCHES_DETAILS}</p>
                </div>
                <div
                  onClick={() => this.col1ItemClick("opportunities")}
                  className={`${onOpportunities ? "onBorders " : " "} mo-nav `}
                >
                  <div>
                    <i className="timesheet-icon" />
                    <label>
                      {labels?.searcher_phillips_monitoring_opportunities_label}
                    </label>
                  </div>
                  <p>{labels.MONITORING_OPPORTUNITIES_DETAILS}</p>
                </div>
              </div>
              <div className="mo-col-2">
                {onShortlists && shortlist ? (
                  <>
                    <ul
                      className={
                        shortlist?.length > 0 ? "mo-list-item" : "no-data"
                      }
                    >
                      {shortlist?.length > 0 ? (
                        shortlist?.map((item) => (
                          <li
                            className={`${item.isSelected ? "active " : " "}`}
                            onClick={() =>
                              this.col2ItemClick("shortlists", item)
                            }
                          >
                            {item.ShortlistName}
                          </li>
                        ))
                      ) : (
                        <div className="empty-message-search">
                          <div>
                            You have no shortlist yet, please add atleast one
                            shortlist first
                          </div>
                          <button
                            className="action-btn"
                            onClick={() => {
                              this.props.history.push("/shortlists");
                            }}
                          >
                            Add Shortlist
                          </button>
                        </div>
                      )}
                    </ul>
                  </>
                ) : (
                  ""
                )}

                {onSavedSearches && savedSearches ? (
                  <ul
                    className={
                      savedSearches?.length > 0 ? "mo-list-item" : "no-data"
                    }
                  >
                    {savedSearches?.length > 0 ? (
                      savedSearches.map((item) => (
                        <li
                          className={`${item.isSelected ? "active " : " "}`}
                          onClick={() =>
                            this.col2ItemClick("savedSearches", item)
                          }
                        >
                          {item.SearchName}
                        </li>
                      ))
                    ) : (
                      <div className="empty-message-search">
                        <div>
                          You have no shortlist yet, please add atleast one
                          shortlist first
                        </div>
                        <button
                          className="action-btn"
                          onClick={() => {
                            this.props.history.push("/saved-searches");
                          }}
                        >
                          Add Shortlist
                        </button>
                      </div>
                    )}
                  </ul>
                ) : (
                  ""
                )}

                {onOpportunities && opportunitiesList ? (
                  <ul
                    className={
                      opportunitiesList?.length > 0 ? "mo-list-item" : "no-data"
                    }
                  >
                    {opportunitiesList?.length > 0 ? (
                      opportunitiesList.map((item) => (
                        <li
                          className={`${item.isSelected ? "active " : " "}`}
                          onClick={() =>
                            this.col2ItemClick("opportunities", item)
                          }
                        >
                          {item.RequestName}
                        </li>
                      ))
                    ) : (
                      <div className="empty-message-search">
                        <div>
                          You have no opportunities yet, please add atleast one
                          opportunity first
                        </div>
                        <button
                          className="action-btn"
                          onClick={() => {
                            this.props.history.push("/create-opportunity");
                          }}
                        >
                          Add opportunity
                        </button>
                      </div>
                    )}
                  </ul>
                ) : (
                  ""
                )}
              </div>
              <div className="mo-col-3">
                {onShortlists && shortlistItemClicked ? (
                  <div>
                    {this.Col3Item(
                      "availabilityIcon",
                      labels.MONITORING_NEW_AVAILABILITY_LABEL,
                      labels.MONITORING_SHORTLIS_NEWAVAILABILITY_DETAILS,
                      "newAvailibility"
                    )}
                  </div>
                ) : (
                  ""
                )}
                {onSavedSearches && savedSearchItemClicked ? (
                  <div>
                    {this.Col3Item(
                      "scorePlusIcon",
                      labels.MONITORING_TOPSCORE_LABEL,
                      labels.MONITORING_SAVEDSEACH_TOPSCORE_DETAILS,
                      "topScoreChange"
                    )}
                    {this.Col3Item(
                      "newIproIcon",
                      labels.MONITORING_ANEWIPRO_LABEL,
                      labels.MONITORING_SAVEDSEARCH_ANEWIPRO_DETAILS,
                      "aNewIpro"
                    )}
                  </div>
                ) : (
                  ""
                )}

                {onOpportunities && opportunityItemClicked ? (
                  <div>
                    {this.Col3Item(
                      "availabilityIcon",
                      labels.MONITORING_NEW_AVAILABILITY_LABEL,
                      labels.MONITORING_OPPORTUNITY_NEWAVAILABILITY_DETAILS,
                      "newAvailibility"
                    )}
                    {this.Col3Item(
                      "scorePlusIcon",
                      labels.MONITORING_TOPSCORE_LABEL,
                      labels.MONITORING_OPPORTUNITY_TOPSCORE_DETAILS,
                      "topScoreChange"
                    )}
                    {this.Col3Item(
                      "newIproIcon",
                      labels.MONITORING_ANEWIPRO_LABEL,
                      labels.MONITORING_OPPORTUNITY_NEWIPRO_DETAILS,
                      "aNewIpro"
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="mo-col-4">
                {newAvailibility && onShortlists ? (
                  <div>
                    <div className="check-items">
                      <input
                        name="ShortlistEmail"
                        onChange={this.onShortlistItemOptionChange}
                        type={"checkbox"}
                        value={ShortlistEmail}
                      ></input>
                      <label>
                        {
                          labels.MONITORING_SHORTLIST_NEWAVAILIBILITY_CHECKBOX_DETAILS
                        }
                      </label>
                    </div>
                    <div className="check-items">
                      <input
                        name="OpportunityEmail"
                        onChange={this.onShortlistItemOptionChange}
                        type={"checkbox"}
                        value={OpportunityEmail}
                      ></input>
                      <label>
                        {
                          labels.MONITORING_SHORTLIST_NEWAVAILIBILITY_CHECKBOX2_DETAILS
                        }
                      </label>
                    </div>
                    <Select
                      name="opportunities"
                      className={`select-input select-Workplace`}
                      placeholder={"select opportunity"}
                      value={
                        (this.state.currentSearch &&
                          this.state.currentSearch.selectedOpportunity) ||
                        ""
                      }
                      onChange={(selectedOption) =>
                        this.onFormSelectChange("", selectedOption)
                      }
                      clearable={false}
                      searchable={false}
                      options={this.state.opportunitiesList}
                    />
                    <button
                      onClick={() =>
                        this.handleMonitorClick("ShortlistAvailibility")
                      }
                      className="action-btn"
                    >
                      {labels.MONITOR_FOR_ME_LABEL}
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {topScoreChange && onSavedSearches ? (
                  <div>
                    <label>{labels.MONITOR_TOP_TEN_SCORE_SAVEDSEARCH}</label>
                    <ul>
                      <li>263</li>
                      <li>243</li>
                      <li>231</li>
                      <li>212</li>
                      <li>199</li>
                      <li>199</li>
                      <li>193</li>
                      <li>189</li>
                      <li>172</li>
                      <li>169</li>
                    </ul>
                    <div className="check-items">
                      <input
                        onChange={(value) =>
                          this.onCheckBoxChange("sendEmailOnScoreChange", value)
                        }
                        type={"checkbox"}
                        value={
                          currentSearch && currentSearch.sendEmailOnScoreChange
                        }
                      ></input>
                      <label>
                        {
                          labels.MONITOR_SAVEDSEARCH_TOPSCORECHANGE_CHECKBOX1_DETAILS
                        }
                      </label>
                    </div>
                    <div className="check-items">
                      <input
                        onChange={(value) =>
                          this.onCheckBoxChange(
                            "sendOpportunityToScoreChanger",
                            value
                          )
                        }
                        type={"checkbox"}
                        value={
                          currentSearch &&
                          currentSearch.sendOpportunityToScoreChanger
                        }
                      ></input>
                      <label>
                        {
                          labels.MONITOR_SAVEDSEARCH_TOPSCORECHANGE_CHECKBOX2_DETAILS
                        }
                      </label>
                    </div>
                    <Select
                      name="WorkplaceId"
                      className={`select-input select-Workplace`}
                      placeholder={"Select Opportunity"}
                      value={
                        (currentSearch &&
                          currentSearch.topScoreChangeSelectedOpportunity) ||
                        ""
                      }
                      onChange={(selectedOption) =>
                        this.onFormSelectChange(
                          "topScoreChangeSelectedOpportunity",
                          selectedOption
                        )
                      }
                      clearable={false}
                      searchable={false}
                      options={opportunitiesList}
                    />
                    <button
                      onClick={() =>
                        this.handleSavedSearchMonitoringClick(
                          "scoreChangeMonitor"
                        )
                      }
                      className="action-btn"
                    >
                      Monitor this for me
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {aNewIpro && onSavedSearches ? (
                  <div>
                    <label>
                      {labels.MONITOR_SAVEDSEARCH_ANEWIPRO_ASSOONAS_LABEL}
                    </label>
                    <ul>
                      <li>{labels.MONITOR_TOP_5_LABEL} </li>
                      <li>{labels.MONITOR_TOP_10_LABEL}</li>
                      <li>{labels.MONITOR_TOP_20_LABEL}</li>
                      <li>{labels.MONITOR_TOP_100_LABEL}</li>
                    </ul>
                    <div className="check-items">
                      <input
                        onChange={(value) =>
                          this.onCheckBoxChange("sendEmailOnNewIpro", value)
                        }
                        type={"checkbox"}
                        value={
                          currentSearch && currentSearch.sendEmailOnNewIpro
                        }
                      ></input>
                      <label>
                        {labels.MONITOR_SAVEDSEARCH_ANEWIPRO_CHECKBOX1_DETAILS}
                      </label>
                    </div>
                    <div className="check-items">
                      <input
                        onChange={(value) =>
                          this.onCheckBoxChange(
                            "sendOpportunityToNewIpro",
                            value
                          )
                        }
                        type={"checkbox"}
                        value={
                          currentSearch &&
                          currentSearch.sendOpportunityToNewIpro
                        }
                      ></input>
                      <label>
                        {labels.MONITOR_SAVEDSEARCH_ANEWIPRO_CHECKBOX2_DETAILS}
                      </label>
                    </div>
                    <Select
                      name="opportunities"
                      className={`select-input select-Workplace`}
                      placeholder={"select opportunity"}
                      value={
                        (currentSearch &&
                          currentSearch.newIproSelectedOpportunity) ||
                        ""
                      }
                      onChange={(selectedOption) =>
                        this.onFormSelectChange(
                          "newIproSelectedOpportunity",
                          selectedOption
                        )
                      }
                      clearable={false}
                      searchable={false}
                      options={this.state.opportunitiesList}
                    />
                    <button
                      onClick={() =>
                        this.handleSavedSearchMonitoringClick(
                          "newScorerMonitor"
                        )
                      }
                      className="action-btn"
                    >
                      {labels.MONITOR_FOR_ME_LABEL}
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {newAvailibility && onOpportunities ? (
                  <div>
                    <div className="check-items">
                      <input
                        name="WatchDeclined"
                        onChange={this.onShortlistItemOptionChange}
                        type={"checkbox"}
                        value={WatchDeclined}
                      ></input>
                      <label>
                        Send the email if anyone who declined or haven't
                        responded becomes available
                      </label>
                    </div>
                    <div className="check-items">
                      <input
                        name="WatchPending"
                        onChange={this.onShortlistItemOptionChange}
                        type={"checkbox"}
                        value={WatchPending}
                      ></input>
                      <label>
                        {labels.MONITOR_OPPORTUNITY_NEWAVAIL_CHECKBOX1_DETAILS}
                      </label>
                    </div>
                    <Select
                      name="WorkplaceId"
                      className={`select-input select-Workplace`}
                      placeholder={"Select Opportunity"}
                      value={
                        (this.state.currentSearch &&
                          this.state.currentSearch.selectedOpportunity) ||
                        ""
                      }
                      onChange={(selectedOption) =>
                        this.onFormSelectChange(
                          "selectedOpportunity",
                          selectedOption
                        )
                      }
                      clearable={false}
                      searchable={false}
                      options={opportunitiesList}
                    />
                    <button
                      onClick={() =>
                        this.handleMonitorClick("OpportunityAvailibility")
                      }
                      className="action-btn"
                    >
                      {labels.MONITOR_FOR_ME_LABEL}
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {topScoreChange && onOpportunities ? (
                  <div>
                    <label>
                      {labels.MONITOR_OPPORTUNITY_TOPSCORE_TOP_TEN_DETAILS}
                    </label>
                    <ul>
                      <li>263</li>
                      <li>243</li>
                      <li>231</li>
                      <li>212</li>
                      <li>199</li>
                      <li>199</li>
                      <li>193</li>
                      <li>189</li>
                      <li>172</li>
                      <li>169</li>
                    </ul>
                    <div className="check-items">
                      <input
                        name="WatchDeclined"
                        onChange={this.onShortlistItemOptionChange}
                        type={"checkbox"}
                        value={WatchDeclined}
                      ></input>
                      <label>
                        {labels.MONITOR_OPPORTUNITY_TOPSCORE_CHECKBOX1_DETAILS}
                      </label>
                    </div>
                    <div className="check-items">
                      <input
                        name="WatchPending"
                        onChange={this.onShortlistItemOptionChange}
                        type={"checkbox"}
                        value={WatchPending}
                      ></input>
                      <label>
                        {labels.MONITOR_OPPORTUNITY_TOPSCORE_CHECKBOX2_DETAILS}
                      </label>
                    </div>
                    <Select
                      name="WorkplaceId"
                      className={`select-input select-Workplace`}
                      placeholder={"Select Opportunity"}
                      value={
                        (this.state.currentSearch &&
                          this.state.currentSearch.selectedOpportunity) ||
                        ""
                      }
                      onChange={(selectedOption) =>
                        this.onFormSelectChange(
                          "selectedOpportunity",
                          selectedOption
                        )
                      }
                      clearable={false}
                      searchable={false}
                      options={opportunitiesList}
                    />
                    <button
                      onClick={() =>
                        this.handleMonitorClick("OpportunityAvailibility")
                      }
                      className="action-btn"
                    >
                      {labels.MONITOR_FOR_ME_LABEL}
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {aNewIpro && onOpportunities ? (
                  <div>
                    <label>
                      {labels.MONITOR_OPPORTUNITY_ANEWIPRO_ASSOONAS_LABEL}
                    </label>
                    <ul>
                      <li>{labels.MONITOR_TOP_5_LABEL}</li>
                      <li>{labels.MONITOR_TOP_10_LABEL}</li>
                      <li>{labels.MONITOR_TOP_20_LABEL}</li>
                      <li>{labels.MONITOR_TOP_100_LABEL}</li>
                    </ul>
                    <label>
                      {
                        labels.MONITOR_OPPORTUNITY_ANEWIPRO_FOR_THE_SAVED_SEARCHLABEL
                      }
                    </label>
                    <div className="check-items">
                      <input type={"checkbox"}></input>
                      <label>
                        {labels.MONITOR_SAVEDSEARCH_ANEWIPRO_CHECKBOX1_DETAILS}
                      </label>
                    </div>
                    <div className="check-items">
                      <input
                        name="WatchDeclined"
                        onChange={this.onShortlistItemOptionChange}
                        type={"checkbox"}
                        value={WatchDeclined}
                      ></input>
                      <label>
                        {labels.MONITOR_OPPORTUNITY_ANEWIPRO_CHECKBOX1_DETAILS}
                      </label>
                    </div>
                    <div className="check-items">
                      <input
                        name="WatchPending"
                        onChange={this.onShortlistItemOptionChange}
                        type={"checkbox"}
                        value={WatchPending}
                      ></input>
                      <label>
                        {labels.MONITOR_OPPORTUNITY_ANEWIPRO_CHECKBOX2_DETAILS}
                      </label>
                    </div>
                    <Select
                      name="WorkplaceId"
                      className={`select-input select-Workplace`}
                      placeholder={"Select Opportunity"}
                      value={
                        (this.state.currentSearch &&
                          this.state.currentSearch.selectedOpportunity) ||
                        ""
                      }
                      onChange={(selectedOption) =>
                        this.onFormSelectChange(
                          "selectedOpportunity",
                          selectedOption
                        )
                      }
                      clearable={false}
                      searchable={false}
                      options={opportunitiesList}
                    />
                    <button
                      onClick={() =>
                        this.handleMonitorClick("OpportunityAvailibility")
                      }
                      className="action-btn"
                    >
                      {labels.MONITOR_FOR_ME_LABEL}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(withRouter(Monitoring));
