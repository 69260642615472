import React, { Component } from "react";
import { FacebookProvider, LoginButton } from "react-facebook";
import {
  connectSocialMedia,
  disconnectSocialMedia
} from "../Phillip/phillipApi";
import { htmlParser } from "../../utilities/helpers";

class ExternalFacebookLogin extends Component {
  constructor(props) {
    super(props);
    this.facebookClientId = "";
    this.setFacebookClientId();
  }

  setFacebookClientId = () => {
    let facebookClientId = "256608669239786"; //old key "359778238088735";
    if (window.location.hostname.indexOf("dev.prodoo") > -1) {
      facebookClientId = "256608669239786";
    } else if (window.location.hostname.indexOf("stage.prodoo") > -1) {
      facebookClientId = "703294370335312";
    } else if (window.location.hostname.indexOf("prodoo.com") > -1) {
      facebookClientId = "558501255070600";
    }
    this.facebookClientId = facebookClientId;
  };

  handleFacebookLoginSuccess = response => {
    const { id, name, email, first_name, last_name } = response.profile;
    const user = {
      type: 2, // type 2 is facebook in enum
      ExternalId: id,
      ExternalEmail: email,
      ExternalName: name,
      ExternalFirstName: first_name,
      ExternalLastName: last_name
    };
    connectSocialMedia({ user }).then(response => {
      if (response.success) {
        const { labels, onFacebookClick, notificationAction } = this.props;
        const info = {
          status: "success",
          message: labels.SEARCHER_PHILLIP_FACEBOOK_CONNECT_MESSAGE
        };
        notificationAction(info);
        onFacebookClick();
      }
    });
  };

  handleFacebookDisconnectClick = () => {
    const { isFacebookConnected } = this.props;
    if (isFacebookConnected) {
      const socialMediaType = { Type: 2 };
      disconnectSocialMedia({ socialMediaType }).then(response => {
        if (response.success) {
          const { labels, onFacebookClick, notificationAction } = this.props;
          const info = {
            status: "success",
            message: labels.SEARCHER_PHILLIP_FACEBOOK_DISCONNECT_MESSAGE
          };
          notificationAction(info);
          onFacebookClick();
        }
      });
    }
  };

  handleFacebookFailure = err => {
  };

  render() {
    const { labels, isFacebookConnected, testId, textTestId } = this.props;
    return (
      <div className="js_social_wrap">
        {isFacebookConnected ? (
          <button
            className="loginBtn loginBtn--facebook"
            onClick={this.handleFacebookDisconnectClick}
          >
            Login with Facebook
          </button>
        ) : (
          <FacebookProvider appId={this.facebookClientId}>
            <LoginButton
              scope="email"
              onCompleted={this.handleFacebookLoginSuccess}
              onError={this.handleFacebookFailure}
              className="social-btn"
              spinner={false}
            >
              <div className="loginBtn loginBtn--facebook">
                Login with Facebook
              </div>
            </LoginButton>
          </FacebookProvider>
        )}
        <p test-data-id={textTestId}>
          {isFacebookConnected
            ? htmlParser(labels.SearcherConnectedFacebook)
            : htmlParser(labels.SearcherNotConnectedFacebook)}
        </p>
      </div>
    );
  }
}

export default ExternalFacebookLogin;
