import React, { Component } from "react";
import SliderSrc from "../../../../assets/images/slider/img-bg1.png";
import Slider2Src from "../../../../assets/images/snapshot/slider2.svg";
import { Carousel } from "react-responsive-carousel";
import "./slider.scss";
import { getSliderContent } from "../../leadPageApi";
import { htmlParser } from "../../../../utilities";

const Slide = ({ className, children, testId }) => (
  <div className={`sliderJoin ${className}`} test-data-id={testId}>
    {children}
  </div>
);
class LoginForm extends Component {
  state = {
    currentSlide: 0,
    slides: [
      {
        SliderContentId: 1,
        Heading: "The best way to be a freelancer.",
        SubHeading:
          "And probably the best way to see what needs to be done to be awesome at it. Period again.",
        BtnText: "Join",
        BtnUrl: "",
        Icon: "joinBtn",
        IsActive: true,
      },
      {
        SliderContentId: 2,
        Heading: "Get an IT freelance job without the middleman.",
        SubHeading:
          "No hidden agency fees\r\n <br/> Skill development guides\r\n <br/> Direct contact with hiring manager\r\n <br/> Skill statistics and trends\r\n <br/> Completely free!",
        BtnText: "Join",
        BtnUrl: "",
        Icon: "joinBtn",
        IsActive: true,
      },
      {
        SliderContentId: 3,
        Heading: "Get expert opinion on your resume",
        SubHeading:
          "Automated recruitment expert Phillip will review your resume and gives important tips",
        BtnText: "Join",
        BtnUrl: "",
        Icon: "joinBtn",
        IsActive: true,
      },
    ],
  };
  componentDidMount() {
    getSliderContent().then((data) => {
      if (data.success) {
        this.setState({ slides: data.items });
        window.scrollTo(0, 0);
      }
    });
  }
  updateCurrentSlide = (currentSlide) => {
    // let { currentSlide } = this.state;
    this.setState({
      currentSlide: currentSlide == 2 ? 0 : currentSlide + 1,
    });
  };
  render() {
    const { currentSlide, slides } = this.state;
    const { onSignupClick } = this.props;
    return (
      <div className="contentContainer slides-component">
        <div className="MainView">
          {slides.length > 0 && (
            <Carousel
              className="slideshow"
              swipeable
              showStatus={false}
              showIndicators={false}
              showArrows={false}
              infiniteLoop
              autoPlay
              interval={5000}
              showThumbs={false}
              selectedItem={currentSlide}
              onChange={() => this.updateCurrentSlide(currentSlide)}
            >
              {slides.map((item) => (
                <Slide key={item.SliderContentId} className=" active">
                  <div className="text1">
                    <h1>{item.Heading}</h1>
                    <span className="SliderText">
                      {htmlParser(item.SubHeading)}
                    </span>
                    <div className="joinWrap">
                      {/* <button
                        onClick={() =>
                          window.open("https://prodoo.com/blog/", "_blank")
                        }
                        className="blogBtn"
                      >
                        {"Article"}
                      </button> */}
                      <button onClick={onSignupClick} className="joinBtn">
                        {item.BtnText}
                      </button>
                    </div>
                  </div>
                </Slide>
              ))}
            </Carousel>
          )}

          {(currentSlide == 0 || currentSlide == 2) && (
            <div className="sliderImageWrap">
              <img className="sliderImg" src={SliderSrc} alt="slider" />
            </div>
          )}
          {currentSlide == 1 && (
            <div className="sliderImageWrap">
              <img className="sliderImg" src={Slider2Src} alt="slider" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LoginForm;
