import { combineReducers } from "redux";
import systemLabelReducer from "./systemLabelReducer";
import currenciesReducer from "./currenciesReducer";
import loginReducer from "./loginReducer";
import notificationReducer from "./notificationReducer";
import navigationReducer from "./navigationReducer";
import resumeReducer from "./resumeReducer";
import { ActionTypes } from "../actions";
import presentReducer from "./presentReducer";
import companyReducer from "./companyReducer";
import searchReducer, { searchWrapperReducer } from "./searchReducer";
import savedSearchReducer from "./savedSearchReducer";
import shortlistReducer from "./shortlistReducer";
import messageNewReducer from "./messageNewReducer";
import messageInboxReducer from "./messageInboxReducer";
import messageSentReducer from "./messageSentReducer";
import createNewOpportunityReducer from "./createNewOpportunityReducer";
import searcherOpportunitiesSentReducer from "./searcherOpportunitiesSentReducer";
import searcherOpportunitiesDraftReducer from "./searcherOpportunitiesDraftReducer";
import resumeEditReducer from "./resumeEditReducer";
import iproOpportunityReducer, {
  createOpportunityWrapperReducer,
} from "./iproOpportunityReducer";
import collaborationReducer, {
  createCollaborationWrapperReducer,
} from "./collaborationReducer";
import createCollaborationReducer from "./createCollaborationReducer";
import feedbackReducer from "./feedbackReducer";
import aboutReducer from "./aboutReducer";
import settingReducer from "./settingReducer";
import landingReducer from "./landingReducer";
import newSearchReducer from "./newSearchReducer";
import newSavedSearchReducers from "./newSavedSearchReducers";
import newShortListReducer from "./newShortlsitReducer";
import phillipSearcherModalReducer from "./phillipSearcherModalReducer";

const appReducer = combineReducers({
  systemLabel: systemLabelReducer,
  currency: currenciesReducer,
  searcherModal:phillipSearcherModalReducer,
  userInfo: loginReducer,
  notification: notificationReducer,
  navigation: navigationReducer,
  resume: resumeReducer,
  present: presentReducer,
  company: companyReducer,
  newSearch: newSearchReducer,
  newSavedSearch: newSavedSearchReducers,
  newShortlist: newShortListReducer,
  search: searchWrapperReducer(searchReducer, "search"),
  savedSearchRed: savedSearchReducer,
  savedSearch: searchWrapperReducer(searchReducer, "savedSearch"),
  shortlist: shortlistReducer,
  messageNew: messageNewReducer,
  messageInbox: messageInboxReducer,
  messageSent: messageSentReducer,
  createNewOpportunity: createNewOpportunityReducer,
  searcherOpportunitySent: searcherOpportunitiesSentReducer,
  searcherOpportunityDraft: searcherOpportunitiesDraftReducer,
  resumeEdit: resumeEditReducer,
  landing: landingReducer,
  iproOpportunityAccepted: createOpportunityWrapperReducer(
    iproOpportunityReducer,
    "accepted"
  ),
  iproOpportunityDeclined: createOpportunityWrapperReducer(
    iproOpportunityReducer,
    "declined"
  ),
  iproOpportunityNew: createOpportunityWrapperReducer(
    iproOpportunityReducer,
    "new"
  ),
  iproCollaborationNew: createCollaborationWrapperReducer(
    collaborationReducer,
    "iproNew"
  ),
  iproCollaborationInActive: createCollaborationWrapperReducer(
    collaborationReducer,
    "iproInActive"
  ),
  iproCollaborationActive: createCollaborationWrapperReducer(
    collaborationReducer,
    "iproActive"
  ),
  searcherCollaborationPending: createCollaborationWrapperReducer(
    collaborationReducer,
    "searcherPending"
  ),
  searcherCollaborationAccepted: createCollaborationWrapperReducer(
    collaborationReducer,
    "searcherAccepted"
  ),
  searcherCollaborationInActive: createCollaborationWrapperReducer(
    collaborationReducer,
    "searcherInActive"
  ),
  searcherCollaborationDeclined: createCollaborationWrapperReducer(
    collaborationReducer,
    "searcherDeclined"
  ),
  createCollaboration: createCollaborationReducer,
  feedback: feedbackReducer,
  about: aboutReducer,
  setting: settingReducer,
});

const rootReducer = (state, action) => {
  if (action.type === ActionTypes.LOGOUT) {
    state = { systemLabel: state.systemLabel, landing: state.landing };
  }
  return appReducer(state, action);
};

export default rootReducer;
