import axios from "axios";
import { ApiUrl } from "../../../../api";

let source;

export const getSavedSearchesApi = () =>
  axios.get(ApiUrl.ResumeSearches.SavedSearchesBase).then(({ data }) => data);

export const getSaveSearchDetailApi = (savedSearchId) =>
  axios
    .get(
      `${ApiUrl.ResumeSearches.SavedSearchDetail}?savedSearchId=${savedSearchId}`
    )
    .then(({ data }) => data);

export const deleteSavedSearchApi = ({ id }) => {
  return axios
    .delete(ApiUrl.ResumeSearches.DeleteSavedSearch({ id }))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const searchResumesApi = (info) =>
  axios.post(ApiUrl.ResumeSearches.SearchResume, info).then(({ data }) => data);

export const postShortlistApi = (info) =>
  axios.post(ApiUrl.Shortlists.Post, info).then(({ data }) => data);

export const updateSavedSearchName = (savedSearchId, newName) =>
  axios
    .get(ApiUrl.ResumeSearches.UpdateSavedSearchName(savedSearchId, newName))
    .then(({ data }) => data);

export const deleteResumeApi = (resumeId, shortlistId) =>
  axios
    .delete(
      `${ApiUrl.Shortlistresumes.DeleteShortlist}?resumeId=${resumeId}&shortlistId=${shortlistId}`
    )
    .then(({ data }) => data);

export const getShortlistResumeApi = (ShortlistId) => {
  if (source) source.cancel("Operation canceled by the user.");
  source = axios.CancelToken.source();
  return axios
    .get(`${ApiUrl.Shortlistresumes.Get}/?shortlistid=${ShortlistId}`, {
      cancelToken: source.token,
    })
    .then(({ data }) => data);
};

export const GetShortlistResumeCount = (requestId) => {
  return axios
    .get(ApiUrl.Shortlistresumes.GetShortlistResumeCount(requestId))
    .then(({ data }) => data);
};
