const REDIRECT_URLS = {
  localhost: "http://localhost:3000/",
  development: "https://dev.prodoo.com/",
  staging: "https://stage.prodoo.com/",
  production: "https://prodoo.com/",
};

function getEnvironment() {
  if (typeof window !== "undefined") {
    if (window.location.host.indexOf("localhost") > -1) return "localhost";
    else if (window.location.host.indexOf("dev") > -1) return "development";
    else if (window.location.host.indexOf("stage") > -1) return "staging";
    else return "production";
  }
}

const CURRENT_ENV = getEnvironment();

const REDIRECT_URL = REDIRECT_URLS[CURRENT_ENV];

const RESET_LANDINGPAGE_URL = REDIRECT_URL + "?reset=true";

export { CURRENT_ENV, REDIRECT_URL, RESET_LANDINGPAGE_URL };
