import React from "react";
import "./navigation.scss";
import { NavigationItem } from "../../../../common";

class TictellNavigation extends React.Component {
  render() {
    const { UserFeatures, isHelpActive, labels, disabled } = this.props;
    let features = [];
    UserFeatures.forEach((element) => {
      if (element.Url) features.push(element);
    });
    const hash = window.location.hash.toLowerCase();
    const app = hash.indexOf("apps") > -1 ? "/Apps/Tictell/" : "/Tictell/";
    const hashesList = [
      "#/apps/tictell/",
      "#/tictell/",
      "#/apps/tictell",
      "#/tictell",
    ];
    return (
      <div
        className={`${disabled ? "disabled" : ""} product-menus`}
        style={disabled ? { pointerEvents: "none" } : null}
      >
        {features.map((item) => (
          <NavigationItem
            Id={3}
            tooltipLabel={`${labels[`${item.Name}-Tooltip`]}`}
            isHelpActive={isHelpActive}
            IsActive={
              item.Url && window.location.href.indexOf(item.Url) > -1
                ? true
                : hashesList.indexOf(window.location.hash.toLowerCase()) > -1
                ? true
                : false
            }
            CssClass={"approve-menu"}
            CssClassIcon={item.CssClassIcon}
            Url={`${app}${item.Url}`}
            testId={`${item.Name}-button`}
            menuText={""}
            isNavShrink={false}
            tooltipHelp={`${labels[`${item.Name}-Help`]}`}
            NotificationBadge={false}
            NotificationCount={5}
          />
        ))}
      </div>
    );
  }
}

export default TictellNavigation;
