import React from "react";
import { Col, Input, Modal } from "antd";
import NetworkDetail from "./NetworkDetail";

const MobileModal = (props) => {
  const {
    currentPresent,
    selectedUser,
    Emails,
    Profiles,
    companyList,
    handleCompanyClick,
    isInvitationsView,
    isConnectedView,
    isFetching,
    handleCreateNewShortlistClick,
    handleCreateNewOpportunityClick,
    handleDeleteNetwork,
    handleAcceptNetwork,
    BtnAccept,
    BtnDecline,
    BtnAddOpportunity,
    BtnAddCollaboration,
    isCreateNew,
    onFormFieldChange,
    networkData,
    handleNetworkInvitationSend,
    mobileModal,
    handleClose,
  } = props;

  const handleOk = () => {
    handleClose();
  };
  const handleCancel = () => {
    handleClose();
  };

  return (
    <>
      <Modal
        title=""
        open={mobileModal}
        style={{ top: 20 }}
        onOk={handleOk}
        zIndex={100}
        onCancel={handleCancel}
        footer={false}
        className="new-search-resume-main for-mobile-modal-shortlist"
      >
        <div style={{ marginTop: "5px" }} className="new-search-resume-main">
          <NetworkDetail
            currentPresent={currentPresent}
            selectedUser={selectedUser}
            Emails={Emails}
            Profiles={Profiles}
            companyList={companyList}
            handleCompanyClick={handleCompanyClick}
            isInvitationsView={isInvitationsView}
            isConnectedView={isConnectedView}
            isFetching={isFetching}
            handleCreateNewShortlistClick={handleCreateNewShortlistClick}
            handleCreateNewOpportunityClick={handleCreateNewOpportunityClick}
            handleDeleteNetwork={handleDeleteNetwork}
            handleAcceptNetwork={handleAcceptNetwork}
            BtnAccept={BtnAccept}
            BtnDecline={BtnDecline}
            BtnAddOpportunity={BtnAddOpportunity}
            BtnAddCollaboration={BtnAddCollaboration}
            isCreateNew={isCreateNew}
            onFormFieldChange={onFormFieldChange}
            networkData={networkData}
            handleNetworkInvitationSend={handleNetworkInvitationSend}
          />
        </div>
      </Modal>
    </>
  );
};
export default MobileModal;
