import React, { Component } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../../components";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import map from "lodash/map";
import includes from "lodash/includes";
import { ConfirmDialog, LoadingMaskRow, EmptyInfo } from "../../../common";
import { notificationAction, unreadMessagesAction } from "../../../actions";
import "./messageinbox.scss";
import {
  getInboxMessagesApi,
  updateMessageApi,
  sendMessage,
  getRequestStatus,
  getCollabStatus,
} from "../messageApi";
import { privateRoutes } from "../../../Routes/routing";
import { formatDate, Messages } from "../../../utilities";
import MessageDetail from "./components/MessageDetail";
import { onStateChangeAction } from "./messageInboxAction";
import { Col, Input, Row, Select, Space, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import MobileInboxDetailModal from "./components/mobileModal";
import CreateMessageModal from "./components/createMessageModal";
import DeleteIcon from '../../../assets/images/Icons/delete-modal.png'
import ArrowLeft from "../../../assets/images/Icons/arrow-left.png";

import SearchIcon from '../../../assets/images/secrch-icon.png'
import moment from "moment";
const { Title, Text } = Typography

class MessageInbox extends Component {
  state = {
    selectedmessage: [],
    CollabStatus: "",
    RequestStatus: "",
    open: false,
    windowWidth: window.innerWidth,
    isMobileDevice: false,
    active: "",
  };

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    this.getAllMessages();
    window.addEventListener("resize", this.handleResize);
    this.setState(st => ({ ...st, active: window.location.hash?.slice(1) }))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getAllMessages = () => {
    this.props.onStateChangeAction({ isFetching: true });

    getInboxMessagesApi().then((data) => {
      if (data.success) {
        const messageList = map(data.items, (item) => ({
          ...item,
          userRequest: first(item.UserRequestMessage),
        }));

        this.props.onStateChangeAction({
          messageList,
          filterMessages: messageList,
          isFetching: false,
        });
        const spliturl = this.props.location.pathname.match(/\d+/g);
        if (spliturl != null) {
          let filterrequestid = data.items.filter(
            (a) => a.MessageId == spliturl[0]
          );
          this.handleMessageSelect(filterrequestid[0]);
        }
      }
    });
  };

  handleContentChange = (e) => {
    const { value, name } = e.target;
    const { messageForm } = this.props;
    const data = {
      ...messageForm,
      [name]: value,
    };
    this.props.onStateChangeAction({ messageForm: data });
  };

  handleReply = () => {
    this.props.onStateChangeAction({ isReply: true });
  };

  handleVisitRequest = () => {
    const { selectedMessage } = this.props;
    var reqType = 1;
    var reqId = selectedMessage.RequestId;
    var IsCollaboration = selectedMessage.IsCollaboration;
    if (selectedMessage.IsCollaboration) {
      const {
        CollaborationIdm,
        IsAccepted,
        IsDeclined,
        IsIProClosed,
        IsIProDeleted,
        IsIProRead,
        IsSearcherClosed,
        IsSearcherDeleted,
        IsSearcherRead,
      } = this.state.CollabStatus;

      if (!IsAccepted && !IsDeclined) {
        this.props.history.push({
          pathname: privateRoutes.iproNewCollaborations.path,
          search: "",
          hash: "",
          state: { CollaborationId: selectedMessage.CollaborationId },
        });
      } else if (
        IsAccepted &&
        !IsSearcherClosed &&
        !IsIProClosed &&
        !IsSearcherDeleted &&
        !IsIProDeleted
      ) {
        this.props.history.push({
          pathname: privateRoutes.iproActiveCollaborations.path,
          search: "",
          hash: "",
          state: { CollaborationId: selectedMessage.CollaborationId },
        });
      } else if (IsSearcherClosed || IsIProClosed) {
        this.props.history.push({
          pathname: privateRoutes.iproNewCollaborations.path,
          search: "",
          hash: "",
          state: { CollaborationId: selectedMessage.CollaborationId },
        });
      } else if (IsDeclined) {
        this.props.history.push({
          pathname: privateRoutes.IproCollaborationDeclined.path,
          search: "",
          hash: "",
          state: { CollaborationId: selectedMessage.CollaborationId },
        });
      }
    } else {
      const { IsConfirmed, IsDeclined, IsDeleted } = this.state.RequestStatus;
      if (!IsConfirmed && !IsDeclined && !IsDeleted) {
        this.props.history.push({
          pathname: privateRoutes.iproNewOpportunity.path,
          search: "",
          hash: "",
          state: { visitRequestId: reqId },
        });
      } else if (IsConfirmed) {
        this.props.history.push({
          pathname: privateRoutes.iproAccpetedOpportunity.path,
          search: "",
          hash: "",
          state: { visitRequestId: reqId },
        });
      } else if (IsDeclined) {
        this.props.history.push({
          pathname: privateRoutes.iproDeclinedOpportunity.path,
          search: "",
          hash: "",
          state: { visitRequestId: reqId },
        });
      }
    }
  };

  handleSubmitMessage = () => {
    const {
      messageForm: { content, title },
    } = this.props;
    const { notificationAction } = this.props;
    if (isEmpty(content)) {
      notificationAction({
        status: "error",
        message: "Please add content to proceed",
      });
      return;
    }

    const message = {
      IsRead: false,
      MessageBody: content,
      MessageId: 0,
      Subject: this.props.selectedMessage.Subject,
      UserIds: [this.props.selectedMessage.SenderId],
      UserRequestMessageId: 0,
    };
    this.props.onStateChangeAction({ isFetching: true });
    sendMessage({ message })
      .then((response) => {
        this.props.onStateChangeAction({ messageForm: {} });
        this.getAllMessages();
        this.setState((st) => ({ ...st, open: false }));
        notificationAction({
          status: "success",
          message: this.props.labels.InfoIProCreateMsgAddSuccessfully,
        });
      })
      .catch((error) => {
        this.props.onStateChangeAction({ isFetching: false });
      });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { messageList } = this.props;
    const filterMessages = filter(messageList, (message) =>
      includes(toLower(message.Subject), toLower(value))
    );
    this.props.onStateChangeAction({ filterMessages, searchKey: value });
  };

  handleDeleteMessage = (message) => {
    const {
      UserRequestMessageId: { UserRequestMessageId },
    } = message;
    this.props.onStateChangeAction({
      deletedId: message.UserRequestMessageId
        ? message.UserRequestMessageId
        : null,
      dialogMessage: this.props.labels.AlrtDelMsg,
    });
  };

  handleYesClick = () => {
    const { deletedId } = this.props;
    const message = {
      MessageId: deletedId,
      isDeleteInboxMessage: true,
    };
    this.props.onStateChangeAction({ dialogMessage: "" });
    updateMessageApi({ message }).then((data) => {
      if (data.success) {
        const info = {
          message: this.props.labels.InfoIProInboxMsgDeleteSuccessfully,
          status: "success",
        };
        this.props.notificationAction(info);
        this.getAllMessages();
        this.props.onStateChangeAction({
          selectedMessage: {},
          deletedId: null,
        });
      }
    });
  };
  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "", deletedId: null });
  };

  handleMessageSelect = async (selectedMessage) => {
    const {
      messageList,
      unreadMessagesAction,
      onStateChangeAction,
    } = this.props;
    if (this.state.windowWidth < 767) {
      this.setState(st => ({ ...st, isMobileDevice: true }))
    }

    if (selectedMessage.IsCollaboration) {
      const response = await getCollabStatus({
        colId: selectedMessage.CollaborationId,
      });

      if (response?.success) {
        this.setState({ CollabStatus: response.items });
      }
    } else {
      if (selectedMessage.RequestId) {
        const response = await getRequestStatus({
          reqId: selectedMessage.RequestId,
        });

        if (response?.success) {
          this.setState({ RequestStatus: response.items });
        }
      }
    }

    if (selectedMessage?.IsRead) {
      onStateChangeAction({ selectedMessage, isReply: false });
      return;
    }

    const message = {
      IsRead: true,
      MessageId: selectedMessage.UserRequestMessageId,
    };

    const updateMessageResult = await updateMessageApi({ message });

    if (updateMessageResult?.success) {
      const unreadMessages = filter(
        messageList,
        (message) =>
          message.MessageId !== selectedMessage.MessageId && !message.IsRead
      );

      unreadMessagesAction({ unreadMessages: unreadMessages.length });

      const filterMessages = map(messageList, (message) => {
        if (message.MessageId === selectedMessage.MessageId) {
          message.IsRead = true;
        }
        return message;
      });

      onStateChangeAction({ filterMessages });
    }

    onStateChangeAction({ selectedMessage, isReply: false });
  };

  handleClose = () => {
    this.setState((st) => ({ ...st, mobileModal: false }));
  };

  handleReplyModal = () => {
    this.setState((st) => ({ ...st, open: true }));
  };

  handleReplyModalClose = () => {
    this.setState((st) => ({ ...st, open: false }));
  };

  render() {
    const { labels, isHelpActive, IsFreelancer } = this.props;
    const {
      messageForm,
      dialogMessage,
      selectedMessage,
      filterMessages,
      isReply,
      isFetching,
      searchKey,
    } = this.props;
    const getTabs = () => {
      return [
        {
          id: 1,
          label: "Inbox",
          active: window?.location?.hash == "#/inbox-messages",
          value: "/inbox-messages"
        },
        {
          id: 2,
          label: "Send",
          active: window?.location?.hash == "#/sent-messages",
          value: "/sent-messages"
        }
      ]
    }

    return (
      <PageWrapper className="collaboration" >
        {/* {isLoading && <LoadingMask />} */}
        {dialogMessage && (
          <ConfirmDialog testId="company-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-yes"
                onClick={this.handleYesClick}
              >
                {labels?.companyDeleteCurtainYESBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-no"
                onClick={this.handleNoClick}
              >
                {labels?.companyDeleteCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <CreateMessageModal
          open={this?.state?.open}
          handleContentChange={this?.handleContentChange}
          messageForm={messageForm}
          onSubmitMessage={this?.handleSubmitMessage}
          handleReplyModalClose={this?.handleReplyModalClose}
        />

        <div className="h-100 new-design-search w-100 d-flex flex-column" >
          <div className="tabs-header-col">
            {this?.state?.isMobileDevice ?
              <div className="d-flex align-items-center gap-8 cursor-pointer"
                onClick={() => { this.setState(st => ({ ...st, isMobileDevice: false })) }}>
                <div>
                  <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                </div>
                <Title level={5} className="m-0"> Messages</Title>
              </div>
              :
              <div className="d-flex gap-16 justify-content-between align-items-center">
                <div className="d-flex gap-16 align-items-center main-header">
                  {(this?.state?.windowWidth > 480) && (
                    <button className={`tab-btn-coll w-100 ${window?.location?.hash == "#/create-message" ? "active-url" : "inactive-url"}`}
                      onClick={() => { this.props.history.push("/create-message") }}
                    >
                      + New Message
                    </button>
                  )}
                  {this.state.windowWidth < 767 ?
                    <Select
                      className="dropdown-callooration"
                      size="medium"
                      bordered={false}
                      onChange={(e) => {
                        this.setState(st => ({ ...st, active: e }))
                        this.props.history.push(`${e}`)
                      }}
                      value={this?.state?.active}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={getTabs() || []}
                    />
                    :
                    <>
                      {getTabs()?.map(single =>
                        <button className={`tab-btn-coll ${single?.active ? "active-url" : "inactive-url"}`}
                          key={single?.id}
                          style={{ background: single?.active ? "#8E81F5" : "", color: single?.active ? "#fff" : "#000" }}
                          onClick={() => {
                            this.props.history.push(single?.value)
                          }}
                        >
                          {single?.label}
                        </button>
                      )}
                    </>
                  }
                </div>

              </div>
            }
          </div>
          <Row className=" h-100" style={{ overflow: "auto" }}>
            <Col xs={24} md={8} className="collboration-column1">
              {this.state.isMobileDevice ?
                <div className=" resume-list h-100" style={{ background: "#fff", borderRadius: "12px" }}>
                  <div className="new-collaboration-detail-component message-detail-new">
                    {isEmpty(selectedMessage) ? (
                      <EmptyInfo>{labels.Message_Sent}</EmptyInfo>
                    ) : (
                      <MessageDetail
                        selectedMessage={selectedMessage}
                        labels={labels}
                        handleVisitRequest={this.handleVisitRequest}
                        IsFreelancer={IsFreelancer}
                        isReply={isReply}
                        handleOpen={this.handleReplyModal}
                        deleteMessage={this.handleDeleteMessage}
                      />
                    )}
                  </div>
                </div>
                :
                <div className="h-100 d-flex flex-column">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "12px",
                      borderBottom: "1px solid #F3F3F3",
                      gap: "10px"
                    }}
                  >
                    <Input
                      placeholder={labels?.searchInputPlaceholderMessage}
                      size="medium"
                      bordered={false}
                      style={{ border: "1px solid #F3F3F3" }}
                      onChange={this.handleSearchChange}
                    />
                    <div>
                      <img style={{ height: "30px" }} src={SearchIcon} alt="" />
                    </div>
                  </div>
                  <div
                    style={{
                      overflow: "auto",
                      padding: "12px",
                      flex: 1
                    }}
                  >
                    {filterMessages?.length > 0 ? (
                      <Space size={[6, 6]} wrap className="short-list-new">
                        {filterMessages?.map((item) => (
                          <div
                            onClick={() =>
                              this.handleMessageSelect(item)}
                            key={item?.UserCompanyId}
                            className={`d-flex gap-8 justify-content-between align-items-center pointer ${item.MessageId === selectedMessage.MessageId
                              ? "background-shortlist short-list-item "
                              : "short-list-item"
                              }`}>
                            <div className="d-flex  gap-8 w-100 justify-content-betwee align-items-center">

                              <div className="text-clamp" style={{ flex: 1 }}>
                                <label style={{ fontSize: "14px" }}>
                                  {item?.SenderName}
                                </label>
                                <p className="text-clamp" style={{ fontSize: "12px", marginBottom: 0 }}>{item?.Subject}</p>
                              </div>
                              {item.IsRead == false &&
                                <div className="">
                                  <div className="chip-new-msg">
                                    New
                                  </div>
                                  <p className="time-message">{moment(item?.DateCreated)?.format("hh:mm A")}</p>
                                </div>
                              }
                              {item.MessageId === selectedMessage.MessageId &&
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleDeleteMessage(item);
                                  }}
                                >
                                  <img src={DeleteIcon} alt="" style={{ height: "20px" }} />
                                </div>
                              }
                            </div>

                          </div>
                        ))}
                      </Space>
                    ) : (
                      <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                    )}
                  </div>
                  {(this?.state?.windowWidth < 480) && (
                    <div style={{ margin: "auto" }}>
                      <div className="tabs-header-col mt-3">
                        <button className="tab-btn-coll"
                          style={{ background: "#6C63FF", color: "#fff" }}
                          onClick={() => { this.props.history.push("/create-message") }}
                        >
                          + New Message
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              }
            </Col>
            <Col md={16} xs={0} offset={0.5}
              className={"h-100"}
            >
              <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                <div className="new-collaboration-detail-component message-detail-new">
                  {isEmpty(selectedMessage) ? (
                    <EmptyInfo>{labels.Message_Sent}</EmptyInfo>
                  ) : (
                    <MessageDetail
                      selectedMessage={selectedMessage}
                      labels={labels}
                      handleVisitRequest={this.handleVisitRequest}
                      IsFreelancer={IsFreelancer}
                      isReply={isReply}
                      handleOpen={this.handleReplyModal}
                      deleteMessage={this.handleDeleteMessage}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper >
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  messageInbox,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { UserId, IsFreelancer } = user;
  UserId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));

  return { labels, user, isHelpActive, IsFreelancer, ...messageInbox };
};

export default connect(mapStateToProps, {
  notificationAction,
  unreadMessagesAction,
  onStateChangeAction,
})(MessageInbox);
