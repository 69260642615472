import { sendOpportunityApi } from "../views/Opportunity/opportunityApi";

export const ApiUrl = {
  SystemLabel: {
    systemLabel: `../FileContainer/syslabels.json`,
  },

  Account: {
    Login: `Account/Login`,
    Logout: "Account/Logoff",
    Register: `Account/Register`,
    RegisterThirdParty: `Account/RegisterThirdParty`,
    ForgotPassword: `Account/SendEmail`,
    IgeLogins: `Account/IgeLogins`,
    GenerateToken: (email, domain) =>
      `IgeUser/GenerateRedirectionToken?domain=${domain}&email=${email}`,
  },
  LandingPage: {
    submitVisitorContact: "ContactUs/SubmitVisitorContact",
    AllLocations: "Country/AllLocationsLookup",
    SignUpConfigs: "Configuration/GetSignUpConfigs",
    SignInConfigs: "Account/GetLoginFeatures",
    GetEmailConfirmationToken: "Account/GetEmailConfirmationToken",
    GetDashboardFeatures: "UserFeatures/GetDashboardFeatures",
    GetFooterFeaturesDetail: (pageId) =>
      `UserFeatures/GetFooterFeaturesDetail?pageId=${pageId}`,
  },
  SideNavigation: {
    GetChildFeatures: ({ featureId }) =>
      `UserFeatures/GetChildfeatures?featureId=${featureId}`,
    GetUserFeatures: ({ featureTypeId, appTypeId }) =>
      `UserFeatures/GetUserFeaturesByFeatureType?featureTypeId=${featureTypeId}&appTypeId=${appTypeId}`,
    GetUserFeaturesForVisitor: ({ featureTypeId, appTypeId, isVisitor }) =>
      `UserFeatures/GetUserFeaturesByFeatureType?featureTypeId=${featureTypeId}&appTypeId=${appTypeId}&isVisitor=${isVisitor}`,
    GetIporOpportunityCounts: () => `requests/GetiproRequests?type=3&userId=0`,
    GetMessageCount: "Message/GetTotalUnReadMessages",
    GetCollaborationCount: "Collaboration/GetCollCount",
    GetIProCollaborationCount: "Collaboration/GetTotalUnReadCollaboration",
    GetOpportunityCount: ({ IsFreelancer }) =>
      `Requests/GetRequestsCount/?isFreelancer=${IsFreelancer}`,
  },
  Dashboard: {
    GetMessages: `User/GetDashboardMessages`,
    GetCompanies: `User/GetDashboardSearchCompanies`,
    GetResumeShortlist: `User/GetDashboardSearchResumeShortlist`,
    GetOpportunities: "User/GetIProOpportunities",
    GetPresentation: "User/GetIProPresentations",
    GetResumeDetailApi: "User/GetDashboardResumeDetails",
    GetUserWidgets: "User/GetDashboardWidgets",
    GetUserDataForDashboard: (isSearcher) =>
      `UserDetail/GetUserDataForDashboard?isSearcher=${isSearcher}`,
    GetProfilesbyResumeId: "Profiles/GetProfilesbyResumeId",
    GetUserDefaultPresentation: "UserDetail/GetUserDefaultPresentation",
    GetDefaultUserCompany: "UserCompany/GetDefaultUserCompany",
    GetMyResumeDetails: "resumes/GetMyResumeDetails",
    GetSearcherPhillipInfo: "ResumeSearches/GetSearchDataForPhilips",
    GetSearcherPhillipInfoForNewUser:
      "ResumeSearches/GetSearcherPhillipInfoForNewUser",
    UpdatePhillipPopupStatus: ({ isFreelancer }) =>
      `User/UpdatePhilipShow?IsFreelancer=${isFreelancer}`,
    GetSearcherPhillipSocialMediaStatus: "SocialMediaTokens/GetUserSocialMedia",
    SaveResumeSearch: "SavedSearches/SaveResumeSearch",
    ResumeSearches: "ResumeSearches/SearcherOnboardingSearchResume",
    SocialMedia: {
      Connect: "SocialMediaTokens/AddSocialMediaToken",
      Disconnect: "SocialMediaTokens/RemoveSocialMediaToken",
    },
  },
  ResumeSearches: {
    SearchResume: "ResumeSearches/SearchResume",
    SaveSearch: "SavedSearches/SaveResumeSearch",
    ResumeClicked: `ResumeActivities/AddClickedActivity`,
    SavedSearchesBase: "SavedSearchesBase",
    SavedSearchDetail: "SavedSearchesBase/GetSavedSearchDetail",
    UpdateSaveSearch: "SavedSearches/UpdateResumeSearch",
    UpdateSavedSearchName: (savedSearchId, savedSearchNewName) =>
      `SavedSearchesBase/UpdateSavedSearchName?savedSearchId=${savedSearchId}&savedSearchNewName=${savedSearchNewName}`,
    DeleteSavedSearch: ({ id }) =>
      `SavedSearches/RemoveSearch?savedSearchId=${id}`,
  },
  Feedback: {
    GetAllFeedbacks: "Feedback/GetAllFeedBacks",
    FeedbackSubmit: "Feedback/FeedbackSubmit",
    DeleteFeedback: "Feedback/DeleteFeedback",
  },
  Configuration: { ConfigurationList: "Configuration/ConfigurationList" },
  Faq: { GetFaqs: "Faq/GetFaqs" },
  EmailConfirmation: { EmailConfirmation: "Account/EmailConfirmation" },
  UserDetail: {
    Get: "UserDetail/Get",
    GetNetworkUserPresentation: (userId) =>
      `UserDetail/GetNetworkUserPresentation?userId=${userId}`,
    Delete: "UserDetail/DeleteUserDetail",
    PresentSubmit: "UserDetail/AddUserDetail",
    SaveUserDashboardDetails: "UserDetail/SaveUserDashboardDetails",
    updatePresent: "UserDetail/UpdateUserDetail",
  },
  Resumes: { GetMyResumeProfiles: "Resumes/GetMyResumeProfiles" },
  UserEmails: { Get: "UserEmails/Get" },
  UserPhonenumbers: {
    Get: "UserPhonenumbers/Get",
    Post: "UserPhonenumbers/Post",
  },
  SocialMedia: "SocialMedia",
  Country: { AllLocationsLookup: "Country/AllLocationsLookup" },
  Industries: { AllIndustriesLookup: "Industries/AllIndustriesLookup" },
  User: {
    GetUserCountry: "User/GetUserCountry",
    GetAllUsersForMsgs: "User/GetAllUsersForMsgs",
    ChangeProfile: ({ IsFreelancer }) =>
      `User/ChangeProfileMode?isFreelancer=${IsFreelancer}`,

    GetUser: "User/GetUserDetail",
  },
  UserNetwork: {
    GetUserDetailId: (userId) => `UserDetail/GetUserDetailId?userId=${userId}`,
    getOpportunity: "Requests/GetLatestOpportunity",
    GetNetworkUsers: "Network/GetLoggedUserNetwork",
    getInvitedNetworkusers: "Network/GetLoggedUserNetworkStatusInvited",
    getInvitationsNetworkusers: "Network/GetLoggedUserNetworkStatusInvitations",
    sendNetworkInvitation: (email) =>
      `Network/SendNetworkInvitation?email=${email}`,
    sendNetworkSignupInvitation: (email) =>
      `Network/SendNetworkSignupInvitation?email=${email}`,
    NetworkInvitationAction: (NIId, status) =>
      `Network/NetworkInvitationAction?networkInvitationId=${NIId}&status=${status}`,
    acceptNetworkInvitation: (userId) =>
      `Network/AcceptNetworkInvite?userId=${userId}`,
    declineNetworkInvite: (userId) =>
      `Network/DeclineNetworkInvite?userId=${userId}`,
    declineNetworkInvitation: (userId) =>
      `Network/DeclineNetworkInvitation?userId=${userId}`,
  },
  UserCompany: {
    Get: "UserCompany/Get",
    GetNetworkUserCompany: (userId) =>
      `User/GetNetworkUserCompany?userId=${userId}`,
    Add: "UserCompany/AddUserCompany",
    Update: "UserCompany/UpdateUserCompany",
    Delete: "UserCompany/DeleteUserCompany",
    DeleteCompanyAndCompanyUsers:
      "UserCompany/DeleteUserCompanyandCompanyUsers",
  },
  Message: {
    SendMessage: "Message/Post",
    SendEmailToUser: "Email/SendEmailToUser",
    GetMessage: "Message/Get",
    GetInboxMessage: "Message/GetInboxMessages",
    GetSentMessage: "Message/GetSentMessages",
    UpdateMessage: "Message/UpdateMessage",
    UnReadMessageCount: "Message/GetTotalUnReadMessages",
    RequestType: "Requests/GetRequestType",
    GetRequestStatus: "Requests/GetRequestStatus",
    GetCollabStatus: "Collaboration/GetCollabrationStatus",
  },
  Requests: {
    GetAllRequests: "Requests/GetAllRequests",
    GetSentRequstDetail: ({ requestId }) =>
      `Requests/GetSentRequstDetail?requestId=${requestId}`,
  },
  Opportunity: {
    GetRequests: "requests/GetRequests",
    GetRequestsByType: "requests/GetiproRequests",
    ReadOpportunity: ({ requestResumeId }) =>
      `requests/ReadOpportunity?requestResumeId=${requestResumeId}`,
    RetrieveResumeSearch: "SavedSearches/RetrieveResumeSearch",
    GetAllPresentation: "UserDetail/Get",
    AcceptOpportunity: "RequestsResumes/AcceptResumeRequest",
    AcceptResumeRequestThroughNetwork:
      "RequestsResumes/AcceptResumeRequestThroughNetwork",
    DeclinedOpportunity: "RequestsResumes/DeclinedResumeRequest",
    DeleteOpportunityByModel: "RequestsResumes/DeleteResumeRequest",
    RestoreRequest: "requests/RestoreRequest",
    SearcherGetRequests: ({ isFreelancer }) =>
      `Requests/GetRequests?isFreelancer=${isFreelancer}&userId=0`,
    GetOpportunity: ({ RequestId }) => `Requests/Get/?RequestId=${RequestId}`,
    GetSentOpportunityDetail: ({ RequestId }) =>
      `requests/GetSentRequstDetail/?requestid=${RequestId}`,
    GetDraftOpportunityDetail: ({ RequestId }) =>
      `requests/GetDraftRequstDetail/?requestid=${RequestId}`,
    GetShortlists: "Shortlists/Get",
    GetShortlistResumes: ({ ShortlistId }) =>
      `Shortlistresumes/get?shortlistid=${ShortlistId}`,
    DeleteOpportunity: ({ RequestId }) =>
      `Requests/DeleteRequest?requestId=${RequestId}&type=1`,
    DeleteSentOpportunity: ({ RequestId }) =>
      `Requests/DeleteRequest?requestId=${RequestId}&type=2`,
    DeleteOpportunityAndAssociations: ({ RequestId }) =>
      `Requests/DeleteRequestAndAssociations?requestId=${RequestId}`,
    SavedSearchesBase: "SavedSearchesBase",
    SearchLanguage: ({ searchKey }) =>
      `Language/LanguagesLookup?filter=${searchKey}`,
    Locations: ({ searchKey }) => `Country/LocationsLookup?filter=${searchKey}`,
    UpdateOpportunity: "Requests/EditRequest",
    UpdateOpportunity2: "Requests/updateRequest",
    SendOpportunity: "Requests/AddRequest",
    GetOpportunityResumes: ({ requestId }) =>
      `RequestsResumes/GetRequestedResumes?requestId=${requestId}`,
    AllLocationsLookup: "Country/get",
    AllLanguagesLookup: "Language/get",
    GetSentRequest: ({ isFreelancer }) =>
      `Requests/GetSentRequests?isFreelancer=${isFreelancer}`,
    GetDraftRequest: ({ isFreelancer }) =>
      `Requests/GetDraftRequests?isFreelancer=${isFreelancer}`,
    UpdateRequestName: (requestId, requestNewName) =>
      `Requests/UpdateRequestName?requestId=${requestId}&requestNewName=${requestNewName}`,
  },

  Collaboration: {
    GetAllCollaboration: ({ url }) => `Collaboration/${url}`,
    UpdateCollaboration: ({ apiParams }) =>
      `Collaboration/UpdateCollaboration?${apiParams}`,
    DeleteCollaborationApi: ({ apiParams }) =>
      `Collaboration/DeleteCollaboration?${apiParams}`,
    GetAllOpportunities: "Requests/GetCollaborationRequest",
    GetOpportunityIProsResume: "Requests/GetCollaborationResume",
    Add: "Collaboration/Post",
    GetAllOpportunities: "Requests/GetCollaborationRequest",
    GetAllCollaborationCloseReasons:
      "CollaborationReview/GetCollborationCloseReasons",
    ReadCollaboration: ({ collaborationId }) =>
      `Collaboration/ReadCollaboration?collaborationId=${collaborationId}`,
    CollaborationReviews: ({ collaborationId, IsIProReview }) =>
      `CollaborationReview/GetCollaborationReviews?collaborationId=${collaborationId}&IsIProReview=${IsIProReview}`,
    SubmitCollaborationReview: "CollaborationReview/PostReviews",
  },
  Reviews: {
    ReviewQuestions: ({ questionType }) =>
      `ReviewQuestion/GetReviewQuestions?questionType=${questionType}`,
  },
  Shortlists: {
    Get: "Shortlists/Get",
    Post: "ShortLists/Post",
    GetAllRequestByShortlistId: "Shortlists/GetAllRequestByShortlistId",
    UpdateRequestName: (requestId, requestNewName) =>
      `Requests/UpdateRequestName?requestId=${requestId}&requestNewName=${requestNewName}`,
    updateShortListName: `Shortlists/RenameShortlist`,
    RemoveResumesFromShortlist: `Shortlists/RemoveResumesFromShortlist`
  },
  Shortlistresumes: {
    GetShortlistResumeCount: (requestId) =>
      `Shortlistresumes/GetShortlistResumeCount?requestId=${requestId}`,
    Get: "Shortlistresumes/get",
    DeleteShortlist: "ShortlistResumes/DeleteShortlist",
    DeleteASavedSearch: (savedSearchId) =>
      `SavedSearches/RemoveSearch?savedSearchId=${savedSearchId}`,
  },
  Resume: {
    GetMyResume: "Resumes/GetResumeDetail",
    GetMyPDFResume: "Resumes/GetMyOtherResume",
    GetResumeByUserId: (userId) => `Resumes/GetResumeByUserId?userId=${userId}`,
  },
  Visit: {
    AllProfileLookup: "Profiles/AllProfileLookup",
  },
  NextStep: {
    UpdateResume: "Resumes/Put",
    GetSuggestedProfilesForNewResume: "Profiles/GetSuggestedProfilesForNewUser",
    GetSuggestedSkillsForNewResume: "Skills/GetSuggestedSkillsForNewUser",
    GetSuggestedProfilesforResume: "Profiles/GetSuggestedProfilesforUser",
    GetSuggestedSkillsforResume: "Skills/GetSuggestedSkillsforUser",
    AddResumeProfile: (profileId) =>
      `ResumeProfiles/AddResumeProfile?profileId=${profileId}`,
    AddResumeSkill: (skillId) =>
      `ResumeSkills/AddResumeSkill?skillId=${skillId}`,
    GetMarketPopularityIndex: "LookupOccurrence/GetMarketPopularityIndex",
    GetUserExpectedSalary: (countryId, currencyId, durationType) =>
      `LookupSalary/GetUserExpectedSalary?countryId=${countryId}&currencyId=${currencyId}&durationType=${durationType}`,
    GetMarketTopSalaries: (countryId, currencyId, durationType) =>
      `LookupSalary/GetMarketTopSalaries?countryId=${countryId}&currencyId=${currencyId}&durationType=${durationType}`,
    GetMarketTopSalariesBasedOnUserRoles: `LookupSalary/GetMarketTopSalariesBasedOnUserRoles`,
  },
  ResumeEdit: {
    LocationsLookup: "Country/AllLocationsLookup",
    LanguagesLookup: "Language/AllLanguagesLookup",
    SkillsLookup: ({ searchKey }) => `Skills/SkillsLookup?filter=${searchKey}`,
    RolesLookup: ({ searchKey }) =>
      `Profiles/ProfileLookup?filter=${searchKey}`,
    CertificationsLookup: ({ searchKey }) =>
      `Certifications/CertificationsLookup?filter=${searchKey}`,
    EductionsLookup: ({ searchKey }) =>
      `Educations/EducationLookup?filter=${searchKey}`,
    IndustriesLookup: ({ searchKey }) =>
      `Industries/IndustriesLookup?filter=${searchKey}`,
    KeywordLookup: ({ searchKey }) =>
      `Keywords/KeywordsLookup?filter=${searchKey}`,
    GetMyResume: "Resumes/GetResumeDetail",
    GetAllKeywords: "Keywords/AllKeywordsLookup",
    addLocation: "ResumeCountry/SaveResumeLocation",
    GetResumeLocation: "ResumeCountry/GetResumeLocations",
    SaveEditedResume: "Resumes/Post",
    DeleteResumeDetail: "Resumes/DeleteResumeDetail",
    RoleSuggestionsLookup: ({ Id, type }) =>
      `Profiles/SuggestedProfiles?Id=${Id}&type=${type}`,
    SkillSuggestionsLookup: ({ Id, type }) =>
      `Skills/SuggestedSkills?Id=${Id}&type=${type}`,
    CertificationSuggestionsLookup: ({ Id, type }) =>
      `Certifications/SuggestedCertifications?Id=${Id}&type=${type}`,
    resumeAddRole: "ResumeProfiles/AddProfile",
    resumeAddSkill: "ResumeSkills/AddSkill",
    AddCertification: "ResumeCertifications/AddCertification",
    AddEducation: "ResumeEducations/AddEducation",
    AddExperience: "ResumeExperiences/AddExperience",
    AddIndustry: "ResumeIndustries/AddIndustry",
    AddKeyword: "ResumeKeywords/AddKeyword",
    AddLanguage: "ResumeLanguage/AddLanguage",
    GetResumeCertifications: "ResumeCertifications/GetResumeCertifications",
    GetResumeEducations: "ResumeEducations/GetResumeEducations",
    GetResumeExperiences: "ResumeExperiences/GetResumeExperiences",
    GetResumeIndustries: "ResumeIndustries/GetResumeIndustries",
    GetResumeKeywords: "ResumeKeywords/GetResumeKeywords",
    GetResumeLanguages: "ResumeLanguage/GetResumeLanguages",
    GetResumeProfiles: "ResumeProfiles/GetResumeProfiles",
    GetResumeSkills: "ResumeSkills/GetResumeSkills",
    UpdateResumeAvailability: "Resumes/UpdateResumeAvailability",
    AllResumeAchivementLookup: "ResumeAchivenment/AllResumeAchivementLookup",
    AddResumeAchivenment: "ResumeOtherAchivenment/AddResumeAchivenment",
    GetResumeAchivenments: "ResumeOtherAchivenment/GetResumeAchivenments",
  },

  Settings: {
    UserSettings: "UserSettings/GetUserSettings",
    GetUserProfile: "UserSettings/GetUserProfile",
    GetUserPhoneNumbers: "UserSettings/GetUserPhoneNumbers",
    GetUserEmailAddress: "UserSettings/GetUserEmailAddress",
    UpdateUserSettings: "UserSettings/UpdateAllSettings",
    UpdatePhoneSettings: "UserSettings/UpdateUserPhoneNumbers",
    UpdateEmailSettings: "UserSettings/UpdateUserEmailSettings",
    UpdateUserProfile: "UserSettings/UpdateUserProfile",
    ChangePassword: "Account/ChangePassword",
    Logout: "Account/Logoff",
    UserNotifications: "UserNotifications/GetNotifications",
    UserSocialLink: "UserSocialLink/GetUserSocialLinks",
    SaveSocialMediaLink: "UserSocialLink/SaveSocialMediaLink",
    UserNotificationUpdate: "UserNotifications/PUT",
    UserNotificationsUpdate: (token) =>
      `UserNotifications/UnsubNotificationsByToken?unsubNotificationToken=${token}`,
    DeleteUserEmail: ({ emailId }) =>
      `UserSettings/DeleteUserEmailAddress?emailId=${emailId}`,
    DeleteUserPhoneNumber: ({ phoneId }) =>
      `UserSettings/DeleteUserPhoneNumber?phoneId=${phoneId}`,
  },
  BI: {
    //Business Intellegence
    Profile: ({ Id }) => `BI/profile/${Id}`,
    Skill: ({ Id }) => `BI/skill/${Id}`,
    Certification: ({ Id }) => `BI/certification/${Id}`,
  },

  Cookie: {
    MarkHelpNotificationRead: "user/MarkHelpNotificationRead",
  },
  SliderContent: {
    Get: "SliderContent/Get",
  },
  Snapshot: {
    GetResumesByResumeId: (ResumeId) =>
      `Snapshot/GetResumesByResumeId?ResumeId=${ResumeId}`,
    GetUserDetailByResumeAndRequestId: (ResumeId, RequestId) =>
      `Snapshot/GetUserDetailByResumeAndRequestId?ResumeId=${ResumeId}&RequestId=${RequestId}`,
    GetCollaborationsAsOwnerCount: "Snapshot/GetMyCollaborationsAsOwnerCount",
    GetAllAcceptedCollaborations:
      "Snapshot/GetAllAcceptedCollaborationsWithPresentationLogo",
    GetAcceptedCollaborationCount: (token) =>
      `Snapshot/GetAcceptedCollaborationCount?token=${token}`,
    GetAllTimeSheets: (statusId, token) =>
      `Snapshot/GetAllTimeSheets?statusId=${statusId}&token=${token}`,
    GetAllExpenseSheets: (statusId, token) =>
      `Snapshot/GetAllExpenseSheets?statusId=${statusId}&token=${token}`,
    GetWeekAllExpenseLogs: (collaborationId, startDate, endDate, token) =>
      `Tictell/GetWeekExpensesLogs?collaborationId=${collaborationId}&startDate=${startDate}&endDate=${endDate}&token=${token}`,
    UpdateExpenseSheetStatus: (expenseSheetId, statusId, ExpenseIds, token) =>
      `Snapshot/UpdateExpenseSheetStatus?expenseSheetId=${expenseSheetId}&status=${statusId}&ExpenseIds=${ExpenseIds}&token=${token}`,
    UpdateTimeSheetStatus: (expenseSheetId, statusId, TimelogIds, token) =>
      `Snapshot/UpdateTimeSheetStatus?timeSheetId=${expenseSheetId}&status=${statusId}&TimelogIds=${TimelogIds}&token=${token}`,
    GetAllCollaborations: (statusId, token) =>
      `Snapshot/GetAllCollaborations?statusId=${statusId}&token=${token}`,
    GetAllTimeSheetCount: (token) =>
      `Snapshot/GetAllTimeSheetCount?token=${token}`,
    GetSearcherAllExpenseSheetsCount: (token) =>
      `Snapshot/GetSearcherAllExpenseSheetsCount?token=${token}`,
    GetAllActiveCollaborationsCount: (token) =>
      `Snapshot/GetAllActiveCollaborationsCount?token=${token}`,
    GetSharedCollaborationInfo: (collaborationId, token) =>
      `Snapshot/GetSharedCollaborationInfo?collaborationId=${collaborationId}&token=${token}`,
    ShareCollaboration: (collaborationId, email, token) =>
      `Snapshot/ShareCollaboration?collaborationId=${collaborationId}&email=${email}&token=${token}`,
    DeleteShareCollaboration: (collaborationId, prodooId, token) =>
      `Snapshot/DeleteShareCollaboration?collaborationId=${collaborationId}&prodooId=${prodooId}&token=${token}`,
    GetSnapshotUserFeatures: ({ featureTypeId, appTypeId }) =>
      `UserFeatures/GetUserFeaturesByFeatureType?featureTypeId=${featureTypeId}&appTypeId=${appTypeId}`,
    GetCollaborationTimeDetail: (collaborationId, startDate, endDate, token) =>
      `Snapshot/GetCollaborationTimeDetail?collaborationId=${collaborationId}&startDate=${startDate}&endDate=${endDate}&token=${token}`,
    GetCollaborationExpenseDetail: (
      collaborationId,
      startDate,
      endDate,
      token
    ) =>
      `Snapshot/GetCollaborationExpenseDetail?collaborationId=${collaborationId}&startDate=${startDate}&endDate=${endDate}&token=${token}`,
    DownloadCollaborationReport: (collaborationId, token) =>
      `Snapshot/DownloadCollaborationTimeLogReport?collaborationId=${collaborationId}&token=${token}`,
    DownloadPoolReport: (poolId, token) =>
      `Snapshot/DownloadPoolTimeLogReport?poolId=${poolId}&token=${token}`,
    GetPools: (token) => `Sense/GetPools?token=${token}`,
    GetPoolCollaborations: (poolId, token) =>
      `Sense/GetPoolCollaborations?poolId=${poolId}&token=${token}`,
    GetPoolCollaborationEarnings: (poolId, token) =>
      `Sense/GetPoolCollaborationEarnings?poolId=${poolId}&token=${token}`,
    DeletePool: (poolId, token) =>
      `Sense/DeletePool?poolId=${poolId}&token=${token}`,
    AddPool: (token) => `Sense/AddPool?token=${token}`,
    UpdatePool: (token) => `Sense/UpdatePool?token=${token}`,
    GetPoolTimeDetail: (PoolId, startDate, endDate, token) =>
      `Snapshot/GetPoolTimeDetail?poolId=${PoolId}&startDate=${startDate}&endDate=${endDate}&token=${token}`,
    GetPoolExpenseDetail: (PoolId, startDate, endDate, token) =>
      `Snapshot/GetPoolExpenseDetail?poolId=${PoolId}&startDate=${startDate}&endDate=${endDate}&token=${token}`,
    UpdateCollaborationEndDate: (collaborationId, endDate) =>
      `Collaboration/UpdateCollaborationEndDate?collaborationId=${collaborationId}&endDate=${endDate}`,
    UpdateCollaborationHourlyRate: (
      collaborationId,
      HourlyRate,
      HourlyRateType
    ) =>
      `Collaboration/UpdateCollaborationHourlyRate?collaborationId=${collaborationId}&HourlyRate=${HourlyRate}&HourlyRateType=${HourlyRateType}`,
    SharePool: (poolId, email, token) =>
      `Sense/SharePool?poolId=${poolId}&email=${email}&token=${token}`,
    GetSharedPoolInfo: (poolId, token) =>
      `Sense/GetSharedPoolInfo?poolId=${poolId}&token=${token}`,
  },
  //Third Party URL's
  Tictell: {
    GetCollabsEndsInMonthCount: "Tictell/getAllCollaborationsEndsNextMonth",
    GetAcceptedCollaborations:
      "Tictell/GetAllAcceptedCollaborationsWithCompanyLogo",
    Login: (email) => `Tictell/Login?email=${email}`,
    GetWeekAllTimeLogs: (collaborationId, startDate, endDate, token) =>
      `Tictell/GetWeekAllTimeLogs?collaborationId=${collaborationId}&startDate=${startDate}&endDate=${endDate}&token=${token}`,
    GetTimeSheet: (collaborationId, startDate, endDate, token) =>
      `Tictell/GetTimeSheet?collaborationId=${collaborationId}&startDate=${startDate}&endDate=${endDate}&token=${token}`,
    DeleteAnExpense: (id, token) =>
      `Tictell/DeleteAnExpense?id=${id}&token=${token}`,
    GetExpenseCategories: (token) =>
      `Tictell/GetExpenseCategories?token=${token}`,
    GetWeekExpensesLogs: (collaborationId, startDate, endDate, token) =>
      `Tictell/GetWeekExpensesLogs?collaborationId=${collaborationId}&startDate=${startDate}&endDate=${endDate}&token=${token}`,
    AddTimelog: (token) => `Tictell/AddTimeLog?token=${token}`,
    AddExpense: (token) => `Tictell/AddExpense?token=${token}`,
    UpdateExpense: (token) => `Tictell/UpdateExpense?token=${token}`,
    GetAllCollaboration: `Collaboration/GetAllCollaborationByUserId?IsAccepted=true`,
    DeleteATimeLogs: (timeLogId, token) =>
      `Tictell/DeleteATimeLogs?timeLogId=${timeLogId}&token=${token}`,
    DeleteExpenseFile: (id, token) =>
      `Tictell/DeleteExpenseFile?expenseFileId=${id}&token=${token}`,
    AddTimeSheet: (collaborationId, startDate, endDate, token) =>
      `Tictell/AddTimeSheet?collaborationId=${collaborationId}&startDate=${startDate}&endDate=${endDate}&token=${token}`,
    AddExpenseSheet: (collaborationId, startDate, endDate, token) =>
      `Tictell/AddExpenseSheet?collaborationId=${collaborationId}&startDate=${startDate}&endDate=${endDate}&token=${token}`,
    AppLogin: (email) => `Tictell/AppLogin?email=${email}`,
    GetTictellUserFeatures: ({ featureTypeId, appTypeId }) =>
      `UserFeatures/GetUserFeaturesByFeatureType?featureTypeId=${featureTypeId}&appTypeId=${appTypeId}`,
  },
  Monitoring: {
    PostMonitorShortlist: "MonitoringShortlist/Post",
    PostMonitorOpportunity: "MonitoringOpportunity/Post",
    PostMonitorSavedSearch: "MonitoringSavedSearch/Post",
    PutMonitorShortlist: "MonitoringShortlist/Put",
    PutMonitorOpportunity: "MonitoringOpportunity/Put",
  },
  Assort: {
    GetAcceptedRequests: "Assort/GetAcceptedRequests",
    AppLogin: (email) => `Assort/AppLogin?email=${email}`,
    GetCollaborationsByStatus: (statusId) =>
      `Assort/GetCollaborationsByStatus?status=${statusId}`,
    RenameCollabration: (collId, name) =>
      `Collaboration/RenameCollabration?collId=${collId}&name=${name}`,
    GetResumeReviews: (userId, oppId, resumeId, token) =>
      `Assort/GetAllResumeReviews?userId=${userId}&oppId=${oppId}&resumeId=${resumeId}&token=${token}`,
    AddReview: (token) => `Assort/PostReview?token=${token}`,
    UpdateReview: (token) => `Assort/UpdateReview?token=${token}`,
    DeleteReview: (id, token) => `Assort/DeleteReview?id=${id}&token=${token}`,
    UpdateResumeOrder: (token) => `Assort/UpdateResumeOrder?token=${token}`,
    GetRequestsCounts: "Assort/GetRequestsCounts",
    GetAllResumeOrders: (oppId, token) =>
      `Assort/GetAllResumeOrders?oppId=${oppId}&token=${token}`,
    GetCollaborationsCounts: "Assort/GetCollaborationsCounts",
    GetAllResumeReviewsByOpportunity: (oppId, token) =>
      `Assort/GetAllResumeReviewsByOpportunity?oppId=${oppId}&token=${token}`,
  },
  Headsup: {
    AppLogin: (email) => `Headsup/AppLogin?email=${email}`,
    HeadsUpSavedSearchDetails: (savedSearchId, savedSearchNewName) =>
      `Headsup/HeadsUpSavedSearchDetails?savedSearchId=${savedSearchId}`,
  },
  Embark: {
    Login: (email) => `Embark/Login?email=${email}`,
    AppLogin: (email) => `Embark/AppLogin?email=${email}`,
    GetAllSearcherCompanies: "Embark/GetAllSearcherCompanies",
    GetAllFlows: (companyId, token) =>
      `Embark/GetAllFlows?companyId=${companyId}&token=${token}`,
    GetAllFlowGroups: (companyId, token) =>
      `Embark/GetAllFlowGroups?companyId=${companyId}&token=${token}`,
    GetAllUserCreatedFlowsAndFlowGroups: (companyId, token) =>
      `Embark/GetAllUserCreatedFlowsAndFlowGroups?companyId=${companyId}&token=${token}`,
    GetFlowItems: (flowId, token) =>
      `Embark/GetFlowItems?flowId=${flowId}&token=${token}`,
    GetFlowGroupItems: (flowGroupId, token) =>
      `Embark/GetFlowGroupItems?flowGroupId=${flowGroupId}&token=${token}`,
    GetCompanyUser: (companyId) =>
      `Embark/GetCompanyUser?companyId=${companyId}`,
    GetAllAcceptedCollaborationsWithIProDetail: (companyId, token) =>
      `Embark/GetAllAcceptedCollaborationsWithIProDetail?companyId=${companyId}&token=${token}`,
    GetIProAssignedFlow: (iproId, companyId, token) =>
      `Embark/GetIProAssignedFlow?iproId=${iproId}&companyId=${companyId}&token=${token}`,
    GetFlowForAssignmentAll: (companyId, token) =>
      `Embark/GetFlowForAssignmentAll?companyId=${companyId}&token=${token}`,
    AddFlow: (token) => `Embark/AddFlow?token=${token}`,
    UpdateFlow: (token) => `Embark/UpdateFlow?token=${token}`,
    DeleteFlow: (Id, token) => `Embark/DeleteFlow?Id=${Id}&token=${token}`,
    AddFlowGroup: (token) => `Embark/AddFlowGroup?token=${token}`,
    UpdateFlowGroup: (token) => `Embark/UpdateFlowGroup?token=${token}`,
    DeleteFlowGroup: (Id, token) =>
      `Embark/DeleteFlowGroup?Id=${Id}&token=${token}`,
    AddFlowItem: (token) => `Embark/AddFlowItem?token=${token}`,
    UpdateFlowItem: (token) => `Embark/UpdateFlowItem?token=${token}`,
    DeleteFlowItem: (Id, token) =>
      `Embark/DeleteFlowItem?Id=${Id}&token=${token}`,
    AddFlowGroupItem: (token) => `Embark/AddFlowGroupItem?token=${token}`,
    UpdateFlowGroupItem: (token) => `Embark/UpdateFlowGroupItem?token=${token}`,
    DeleteFlowGroupItem: (Id, token) =>
      `Embark/DeleteFlowGroupItem?Id=${Id}&token=${token}`,
    AssignFlow: (token) => `Embark/AssignFlow?token=${token}`,
    UnAssignFlow: (Id, token) => `Embark/UnAssignFlow?Id=${Id}&token=${token}`,
    GetiProFlowsAndItems: (iProId, token) =>
      `Embark/GetiProFlowsAndItems?iProId=${iProId}&token=${token}`,
    CompleteFlowItem: (iProId, FlowId, FlowItemId, token) =>
      `Embark/CompleteFlowItem?iProId=${iProId}&FlowId=${FlowId}&FlowItemId=${FlowItemId}&token=${token}`,
    UnCompleteFlowItem: (iProId, FlowId, FlowItemId, token) =>
      `Embark/UnCompleteFlowItem?iProId=${iProId}&FlowId=${FlowId}&FlowItemId=${FlowItemId}&token=${token}`,
    GetAllWorkplacesWithIProsCount: "Embark/GetAllWorkplacesWithIProsCount",
    GetFlowsCount: (token) => `Embark/GetFlowsCount?token=${token}`,
    GetFlowItemsCount: (token) => `Embark/GetFlowItemsCount?token=${token}`,
    GetFlowGroupsCount: (token) => `Embark/GetFlowGroupsCount?token=${token}`,
    GetFlowGroupItemsCount: (token) =>
      `Embark/GetFlowGroupItemsCount?token=${token}`,
    GetSearcherCompletedPercentage: (token) =>
      `Embark/GetSearcherCompletedPercentage?token=${token}`,
  },
  Currency: {
    AllCurrenciesLookup: "Currency/AllCurrenciesLookup",
  },
};
