import React, { Component } from "react";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { isEmpty, map, compact } from "lodash";
import { Modal } from "../../components";
import { LoadingMask, DatePicker } from "../../common";
import { notificationAction } from "../../actions";
import "./phillip.scss";
import {
  htmlParser,
  trimSpaces,
  isValidURL,
  dateFormate,
  isValidPhoneNumber,
} from "../../utilities/helpers";
import Logo from "../../assets/images/logo.png";
import {
  getSearcherPhillipSocialMediaStatusApi,
  getSearcherPhillipInfoApi,
  saveResumeSearchApi,
  resumeSearchesApi,
  addUserPhoneNumberApi,
  getLoginFeaturesApi,
  updatePhillipPopupStatusApi,
} from "./phillipApi";
import { addCompanyApi, getCompaniesApi } from "../../views/Company/companyApi";
import { onStateChangeAction } from "../../views/NewResumeSearch/searchAction";
import { onStateChangeActions } from "../../Routes/phillipModalAction";

import { Button, Col, Input, Row, Typography } from "antd";
import { withRouter } from "react-router-dom";
import { privateRoutes } from "../../Routes/routing";
import { FullscreenOutlined } from "@ant-design/icons";
import { StorageService } from "../../api";
const { Title, Text } = Typography;
const { TextArea } = Input;
const Header = ({ labels, onCloseModal }) => (
  <div className="phillip-header">
    {/* <img className="onboard-logo" src={Logo} alt="prodoo-logo" /> */}
    <Text onClick={onCloseModal} className="skip-btn">
      {labels.searcherOnboardingSkip}
    </Text>
  </div>
);
const Slide = ({ className, children, testId }) => (
  <div className={`js_slide ${className}`} test-data-id={testId}>
    {children}
  </div>
);

class CustomInput extends Component {
  render() {
    const { onClick, value } = this.props;
    return (
      <button className="custom-date-picker" onClick={onClick}>
        {value}
      </button>
    );
  }
}

class SearcherPhillip extends Component {
  state = {
    currentSlide: 0,
    date: moment().add(2, "M"),
    isDatePickerOpen: false,
    companyUrl: "",
    pageUrl: "",
    linkedinUrl: "",
    isFacebookConnected: false,
    isLinkedInConnected: false,
    isFacebookConnectedNow: false,
    isLinkedInConnectedNow: false,
    isCompanyExist: false,
    isNewCompanyCreate: false,
    isOpportunityCreated: false,
    isShortlistCreated: false,
    isSavedSearchCreated: false,
    isLoading: false,
    keywords: "",
    availabilities: [
      {
        id: 1,
        name: "now",
        isActive: false,
        value: this.props.labels.searcherOnboardingStartDateRightNow,
      },
      {
        id: 2,
        name: "notice",
        isActive: true,
        value: this.props.labels.searcherOnboardingStartDateMonthNotice,
      },
      {
        id: 3,
        name: "date",
        isActive: false,
        value: this.props.labels.searcherOnboardingSelectDate,
      },
    ],
    selectedAvailability: "notice",
    LoginWithFb: false,
    LoginWithLinkedin: false,
  };

  componentDidMount() {
    this.getLoginFeatures();
    getSearcherPhillipSocialMediaStatusApi()
      .then((response) => {
        if (response.success) {
          const { FacebookEnabled, LinkedInEnabled } = response.items;
          this.setState({
            isFacebookConnected: FacebookEnabled,
            isLinkedInConnected: LinkedInEnabled,
          });
        }
      })
      .catch((respons) => { });
    window.addEventListener("keydown", this.handleTabPress);
  }
  componentWillUnmount = () => { };

  handleTabPress = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      this.handleContinueClick();
    }
  };

  getLoginFeatures = () => {
    getLoginFeaturesApi().then((response) => {
      this.setState({
        LoginWithFb: response.items.LoginWithFb,
        LoginWithLinkedin: response.items.LoginWithLinkedin,
      });
    });
  };

  extractHostname = (url) => {
    var hostname;

    if (url.indexOf("//") > -1) {
      hostname = url.split("/")[2];
    } else {
      hostname = url.split("/")[0];
    }
    hostname = hostname.split(":")[0];
    hostname = hostname.split("?")[0];

    return hostname;
  };

  extractRootDomain = (url) => {
    var domain = this.extractHostname(url),
      splitArr = domain.split("."),
      arrLen = splitArr.length;
    if (arrLen > 2) {
      domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
      if (
        splitArr[arrLen - 2].length == 2 &&
        splitArr[arrLen - 1].length == 2
      ) {
        domain = splitArr[arrLen - 3] + "." + domain;
      }
    }
    domain = domain.substring(
      domain.lastIndexOf("/") + 1,
      domain.lastIndexOf(".")
    );
    return domain;
  };

  handleFinishClick = () => {
    const { onCloseModal, notificationAction, labels } = this.props;
    const { userPhone } = this.state;
    if (!isEmpty(userPhone) && !isValidPhoneNumber(userPhone)) {
      const info = {
        message: htmlParser(labels.SearcherOnboardingInvalidPhoneInfo),
        status: "error",
      };
      notificationAction(info);
      return;
    }
    if (isValidPhoneNumber(userPhone)) {
      addUserPhoneNumberApi({
        UserId: 0,
        UserPhonenumberValue: userPhone,
      })
        .then((response) => response)
        .catch((response) => response);
    }
    this.handleIproPhillipSkip();
  };
  handleContinueClick = () => {
    let { companyUrl } = this.state;
    const {
      currentSlide,
      pageUrl,
      linkedinUrl,
      date,
      selectedAvailability,
      keywords,
      dateValue,
    } = this.state;
    const { labels, notificationAction } = this.props;

    if (currentSlide == 1) {
      const message = {
        companyUrl: htmlParser(labels.SEARCHER_PHILLIP_COMPANY_URL_MESSAGE),
        pageUrl: htmlParser(labels.SEARCHER_PHILLIP_PAGE_URL_MESSAGE),
        linkedinUrl: htmlParser(labels.SEARCHER_PHILLIP_LINKEDIN_URL_MESSAGE),
      };
      getCompaniesApi().then((data) => {
        if (data.items.length > 0) {
          this.setState({ isCompanyExist: true });
        }
      });
      if (
        !isEmpty(companyUrl) &&
        companyUrl.indexOf("http") < 0 &&
        companyUrl.indexOf("https") < 0 &&
        companyUrl.indexOf("ftp") < 0
      ) {
        companyUrl = "http://" + companyUrl;
        this.setState({
          companyUrl: companyUrl,
        });
      }
      if (!isEmpty(companyUrl) && !isValidURL(companyUrl)) {
        const info = {
          message: message["companyUrl"],
          status: "error",
        };
        notificationAction(info);
        return;
      }
      if (!isEmpty(companyUrl) && isValidURL(companyUrl)) {
        let companyName = this.extractRootDomain(companyUrl);
        const company = {
          CompanyName: companyName + " " + moment().format("L"),
          IsDefaultCompany: true,
          IsFromSearcherOnboarding: true,
          WebUrl: companyUrl,
        };
        addCompanyApi({ company })
          .then((response) => {
            if (response.success) {
              this.setState({ isNewCompanyCreate: true });
              this.props.onCompanyPresentationsUpdate();
            }
            if (response.message === "Default Company is available.") {
              this.setState({ isCompanyExist: true });
            }
          })
          .catch((response) => response);
      }
      if (!isEmpty(pageUrl) && !isValidURL(pageUrl)) {
        const info = {
          message: message["pageUrl"],
          status: "error",
        };
        notificationAction(info);
        return;
      }
      if (!isEmpty(linkedinUrl) && !isValidURL(linkedinUrl)) {
        const info = {
          message: message["linkedinUrl"],
          status: "error",
        };
        notificationAction(info);
        return;
      }
    }
    if (currentSlide === 1) {
      if (isEmpty(keywords)) {
        this.setState((st) => ({ ...st, emptyKeyword: true }));
        return;
      }

      if (!isEmpty(keywords)) {
        this.setState({ isLoading: true });
        getSearcherPhillipInfoApi({ keywords })
          .then((response) => {
            if (response.success) {
              let {
                Certifications = [],
                Countries = [],
                Industries = [],
                Keywords = [],
                Languages = [],
                Profiles = [],
                Skills = [],
              } = response.items;

              Certifications = map(Certifications, (certification) => ({
                Id: certification.CertificationId,
                ExpLevel: 0,
                LookupValue: certification.CertificationValue,
              }));
              Countries = map(Countries, (country) => ({
                Id: country.CountryId,
                ExpLevel: 0,
                LookupValue: country.CountryName,
              }));
              Industries = map(Industries, (industry) => ({
                Id: industry.IndustryId,
                ExpLevel: 0,
                LookupValue: industry.IndustryValue,
              }));
              Keywords = map(Keywords, (keyword) => ({
                Id: keyword.KeywordId,
                ExpLevel: 0,
                LookupValue: keyword.KeywordValue,
              }));
              Languages = map(Languages, (language) => ({
                Id: language.LanguageId,
                ExpLevel: 0,
                LookupValue: language.LanguageValue,
              }));
              Profiles = map(Profiles, (profile) => ({
                Id: profile.ProfileId,
                ExpLevel: 0,
                LookupValue: profile.ProfileValue,
              }));
              Skills = map(Skills, (skill) => ({
                Id: skill.SkillId,
                ExpLevel: 0,
                LookupValue: skill.SkillValue,
              }));

              this.setState((st) => ({ ...st, data: response?.items }));

              if (
                isEmpty(Certifications) &&
                isEmpty(Countries) &&
                isEmpty(Industries) &&
                isEmpty(Keywords) &&
                isEmpty(Languages) &&
                isEmpty(Profiles) &&
                isEmpty(Skills)
              ) {
                const info = {
                  message: labels.searcherOnboardingInvalidDescription,
                  status: "success",
                };
                notificationAction(info);
                this.setState({
                  isLoading: false,
                });
                return;
              }
              const saveResumeParams = {
                Certifications,
                Countries,
                Industries,
                Keywords,
                Languages,
                Profiles,
                Skills,
                SavedSearchId: 0,
                SearchName: `${moment(new Date()).format(
                  "D MMMM YYYY - HH:mm"
                )}`,
              };
              this.setState({ isLoading: false });

              saveResumeSearchApi(saveResumeParams)
                .then((response) => {
                  this.setState({
                    isSavedSearchCreated: true,
                  });
                })
                .catch((response) =>
                  this.setState({
                    isLoading: false,
                  })
                );
            } else {
              this.setState({
                isLoading: false,
              });
            }
          })
          .catch((respons) =>
            this.setState({
              isLoading: false,
            })
          );
      }
    }
    this.setState({ currentSlide: currentSlide + 1 });
  };

  handleSearchContinue = () => {
    this.setState((st) => ({ ...st, isLoading: true }));
    const { date, selectedAvailability } = this.state;
    let opportunityStartDate = "";
    if (selectedAvailability === "now") {
      opportunityStartDate = moment().format("YYYY-MM-DDTHH:mm:ss");
    } else if (selectedAvailability === "notice") {
      opportunityStartDate = moment()
        .add(1, "M")
        .format("YYYY-MM-DDTHH:mm:ss");
    } else {
      opportunityStartDate = date.format("YYYY-MM-DDTHH:mm:ss");
    }
    const parmas = {
      CertificationIds: this?.state?.data?.Certifications?.map(
        (single) => single?.Id
      ),
      Countries: this?.state?.data?.Certifications?.map(
        (single) => single?.CertificationId
      ),
      IndusteryIds: this?.state?.data?.Industries?.map(
        (single) => single?.IndustryId
      ),
      KeywordIds: this?.state?.data?.Keywords?.map(
        (single) => single?.KeywordId
      ),
      Languages: this?.state?.data?.Languages?.map(
        (single) => single?.LanguageId
      ),
      ProfileIds: this?.state?.data?.Profiles?.map(
        (single) => single?.ProfileId
      ),
      SkillIds: this?.state?.data?.Skills?.map((single) => single?.SkillId),
      Type: "Only20",
      AvailabilityType: 1,
      AvailabilityDate: "05/09/2018",
      page: 1,
      limit: 10,
      OpportunityStartDate: opportunityStartDate,
      prodooStartDateTime: `${moment(new Date()).format(
        "D MMMM YYYY - HH:mm"
      )}`,
    };
    resumeSearchesApi(parmas)
      .then((response) => {
        if (response.success) {
          this.setState({
            isShortlistCreated: true,
            isOpportunityCreated: true,
            isLoading: false,
          });
        }

        this.props.onResumeShortlistUpdate();
        this.setState({ currentSlide: this.state.currentSlide + 1 });
        this.setState((st) => ({ ...st, isLoading: false }));
      })
      .catch(() => {
        this.setState((st) => ({ ...st, isLoading: false }));
        this.setState({ currentSlide: this.state.currentSlide + 1 });
      });
  };

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;
    if (currentSlide !== index) {
      this.setState({
        currentSlide: 1,
      });
    }
  };

  handleFacebookClick = () => {
    this.setState({
      isFacebookConnected: !this.state.isFacebookConnected,
      isFacebookConnectedNow: !this.state.isFacebookConnectedNow,
    });
  };

  handleLinkedInClick = () => {
    this.setState({
      isLinkedInConnected: !this.state.isLinkedInConnected,
      isLinkedInConnectedNow: !this.state.isLinkedInConnectedNow,
    });
  };

  handlAvalibalitySelect = (availability) => {
    const { id, name } = availability;
    const availabilities = map(this.state.availabilities, (item) => {
      item.isActive = item.id === id;
      return item;
    });
    this.setState({
      availabilities,
      selectedAvailability: name,
      isDatePickerOpen: name === "date",
    });
  };

  generateFilterTitle = () => {
    const { data } = this.state;
    const criteriaExist =
      data?.Certifications?.length > 0 ||
      data?.Countries?.length > 0 ||
      data?.Industries?.length > 0 ||
      data?.Keywords?.length > 0 ||
      data?.Languages?.length > 0 ||
      data?.Profiles?.length > 0 ||
      data?.Skills?.length > 0;

    if (criteriaExist) {
      let message = "AI Phillip filtered";
      if (data?.Certifications?.length > 0) {
        const certificateString = data?.Certifications?.map(
          (single) =>
            `<span class="found-skill">${single?.CertificationValue}</span>`
        ).join(", ");

        message += ` certification as "${certificateString}"`;
      }

      if (data?.Profiles?.length > 0) {
        if (data?.Certifications?.length > 0) {
          message += " and";
        }

        const roleString = data?.Profiles?.map(
          (single) => `<span class="found-skill">${single?.ProfileValue}</span>`
        ).join(", ");

        message += ` roles as "${roleString}"`;
      }

      if (data?.Skills?.length > 0) {
        if (data?.Certifications?.length > 0 || data?.Profiles?.length > 0) {
          message += " and";
        }
        const skillsString = data?.Skills.map(
          (single) => `<span class="found-skill">${single?.SkillValue}</span>`
        ).join(", ");

        message += ` skills as "${skillsString}"`;
      }

      if (data?.Countries?.length > 0) {
        if (criteriaExist) {
          message += " and";
        }

        const countryString = data?.Countries?.map(
          (single) => `<span class="found-skill">${single?.CountryName}</span>`
        ).join(", ");

        message += ` countries as "${countryString}"`;
      }

      if (data?.Industries?.length > 0) {
        if (criteriaExist) {
          message += " and";
        }

        const industrytring = data?.Industries?.map(
          (single) =>
            `<span class="found-skill">${single?.IndustryValue}</span>`
        ).join(", ");

        message += ` industries as "${industrytring}"`;
      }

      if (data?.Languages?.length > 0) {
        if (criteriaExist) {
          message += " and";
        }

        const languageString = data?.Languages?.map(
          (single) =>
            `<span class="found-skill">${single?.LanguageValue}</span>`
        ).join(", ");

        message += ` language as "${languageString}"`;
      }
      if (data?.Keywords?.length > 0) {
        if (criteriaExist) {
          message += " and";
        }

        const keywordString = data?.Keywords?.map(
          (single) => `<span class="found-skill">${single?.KeywordValue}</span>`
        ).join(", ");

        message += ` keywords as "${keywordString}"`;
      }

      message +=
        " from the job description and now getting a perfect match for you.";
      return message;
    } else {
      console.log("No filtering criteria found.");
    }
  };
  handleChangeSearchClick = () => {
    const { data } = this.state;
    const defaultOption = {
      CertificationIds:
        data?.CertificationIds?.length > 0
          ? data?.CertificationIds?.map((single) => {
            return {
              ...single,
              value: single?.CertificationId,
              label: single?.CertificationValue,
            };
          })
          : [],
      IndusteryIds:
        data?.Industries?.length > 0
          ? data?.Industries?.map((single) => {
            return {
              ...single,
              value: single?.IndustryId,
              label: single?.IndustryValue,
            };
          })
          : [],
      KeywordIds:
        data?.Keywords?.length > 0
          ? data?.Keywords?.map((single) => {
            return {
              ...single,
              value: single?.KeywordId,
              label: single?.KeywordValue,
            };
          })
          : [],
      Languages: [],
      Countries: [],
      ProfileIds:
        data?.Profiles?.length > 0
          ? data?.Profiles?.map((single) => {
            return {
              ...single,
              value: single?.ProfileId,
              label: single?.ProfileValue,
            };
          })
          : [],
      SkillIds:
        data?.Skills?.length > 0
          ? data?.Skills?.map((single) => {
            return {
              ...single,
              value: single?.SkillId,
              label: single?.SkillValue,
            };
          })
          : [],
      Type: "Only20",
      page: 1,
      limit: 20,
    };
    const selected = {
      SkillIds: data?.Skills?.map((single) => single?.SkillId) || [],
      ProfileIds: data?.Profiles?.map((single) => single?.ProfileId) || [],
      KeywordIds: data?.Keywords?.map((single) => single?.KeywordId) || [],
      IndusteryIds: data?.Industries?.map((single) => single?.IndustryId) || [],
      CertificationIds:
        data?.Certifications?.map((single) => single?.CertificationId) || [],
      Languages: data?.Languages?.map((single) => single?.LanguageId) || [],
      Countries: data?.Countries?.map((single) => single?.CountryId) || [],
      Type: "Only20",
      page: 1,
      limit: 20,
    };

    this.props.onStateChangeAction({
      defaultList: defaultOption,
      selected: selected,
      collapse: {
        role: true,
        location: true,
        language: data?.Languages?.length > 0 ? true : false,
        skill: data?.Skills?.length > 0 ? true : false,
        keyword: data?.Keywords?.length > 0 ? true : false,
        industry: data?.Industries?.length > 0 ? true : false,
        certification: data?.Certifications?.length > 0 ? true : false,
        date: false,
      },
    });
    this.props.onStateChangeActions({ show: "" });
    this.props.history.push(privateRoutes.resumeSearch.path);
  };
  generateCards = () => {
    const {
      currentSlide,
      data,
      date,
      companyUrl,
      linkedinUrl,
      pageUrl,
      isFacebookConnected,
      isFacebookConnectedNow,
      isLinkedInConnected,
      isLinkedInConnectedNow,
      isCompanyExist,
      isNewCompanyCreate,
      availabilities,
      isOpportunityCreated,
      isSavedSearchCreated,
      isShortlistCreated,
      isDatePickerOpen,
      isLoading,
      LoginWithFb,
      LoginWithLinkedin,
    } = this.state;
    const { labels, open, onSkip, FirstName, notificationAction } = this.props;
    const isMobileView = window.innerWidth < 767;
    const slideHtml = [
      <Slide testId="searcher-phillip-popup-slide-1">
        {(currentSlide == 0 || !isMobileView) && (
          <>
            <Title level={2}>
              {htmlParser(labels.SEARCHER_PHILLIP_WELCOME_LABEL)} {FirstName}!
            </Title>
            <Text className="description-text" type="p">
              {htmlParser(trimSpaces(labels.searcherOnboardingWelcomeMessage))}
            </Text>
          </>
        )}
      </Slide>,

      <Slide className="slide-2" testId="searcher-phillip-popup-slide-3">
        {(currentSlide == 1 || !isMobileView) && (
          <>
            <Title level={5}>
              {htmlParser(labels.searcherOnboardingSearchSlideMessage)}
            </Title>
            <div className="js_textarea_wrap">
              <TextArea
                autoComplete="off"
                className="js_textarea"
                test-data-id="searcher-phillip-keyword-input"
                style={
                  this.state.emptyKeyword ? { border: "1px solid red" } : null
                }
                placeholder={htmlParser(
                  labels.searcherOnboardingSearchSlideTxtPlaceHolder
                )}
                rows={isMobileView ? 6 : 12}
                onChange={(e) =>
                  this.setState((st) => ({
                    ...st,
                    keywords: e.target.value,
                    emptyKeyword: false,
                  }))
                }
              />
            </div>
            {!isMobileView && <br />}
          </>
        )}
      </Slide>,
      <Slide
        className=" js_slide_3 slide-3"
        testId="searcher-phillip-popup-slide-4"
      >
        {(currentSlide == 2 || !isMobileView) && (
          <div>
            <Title level={5}>{htmlParser(this.generateFilterTitle())}</Title>
            <div className="mt-3">
              <Button
                type="primary"
                htmlType="button"
                onClick={this.handleSearchContinue}
                className="continue-button-resume mr-2 mt-1"
                test-data-id="searcher-phillip-continue-button"
              >
                {labels?.dashboard_getting_started_slide3_btn1}
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  this.handleChangeSearchClick();
                }}
                className="continue-button-resume mt-1"
                test-data-id="searcher-phillip-continue-button"
              >
                {labels?.dashboard_getting_started_slide3_btn2}
              </Button>
            </div>
          </div>
        )}
      </Slide>,
      <Slide className="slide-4" testId="searcher-phillip-popup-slide-5">
        {(currentSlide == 3 || !isMobileView) && (
          <>
            <Row gutter={4} className="posRelative align-items-start">
              <Col
                md={12} xs={24}
                className={`js_item ${isCompanyExist && "active"}`}
                test-data-id="searcher-phillip-company-status"
              >
                <Text className="description-text">
                  {isNewCompanyCreate
                    ? htmlParser(
                      labels.searcherOnboardingStatusCompanyPresentation
                    )
                    : isCompanyExist
                      ? htmlParser(labels.SEARCHER_PHILLIP_COMPANY_ALLREADY_EXIST)
                      : htmlParser(labels.SEARCHER_PHILLIP_COMPANY_NOT_EXIST)}
                </Text>
              </Col>
              <Col
               md={12} xs={24}
                className={`js_item ${isSavedSearchCreated && "active"}`}
                test-data-id="searcher-phillip-saved-search-status"
              >
                <Text className="description-text">
                  {isSavedSearchCreated
                    ? htmlParser(
                      labels.searcherOnboardingStatusSaveSearchCreated
                    )
                    : htmlParser(
                      labels.searcherOnboardingSavedSearchNotCreated
                    )}
                </Text>
              </Col>
              <Col
               md={12} xs={24}
                className={`js_item ${isShortlistCreated && "active"}`}
                test-data-id="searcher-phillip-shortlist-status"
              >
                <Text className="description-text">
                  {isShortlistCreated
                    ? htmlParser(
                      labels.searcherOnboardingStatusShortlistCreated
                    )
                    : htmlParser(labels.searcherOnboardingShortlistNotCreated)}
                </Text>
              </Col>
              <Col
               md={12} xs={24}
                className={`js_item ${isOpportunityCreated && "active"}`}
                test-data-id="searcher-phillip-opportunity-status"
              >
                <Text className="description-text">
                  {isOpportunityCreated
                    ? htmlParser(labels.searcherOnboardingStatusOppCreated)
                    : htmlParser(
                      labels.searcherOnboardingOpportunityNotCreated
                    )}
                </Text>
              </Col>
            </Row>

            <Text className="description-text mt-2">
              {htmlParser(
                trimSpaces(labels?.searcherOnboardingStatusFinishingMessage)
              )}
            </Text>
            <Text className="description-text">
              <div>
                <p>
                  Newly created draft opportunity available as{" "}
                  <span
                    class="draft-opp"
                    onClick={() => {
                      this.props.onStateChangeActions({ show: "small" });
                      this.props.history.push(
                        `${privateRoutes.searcherDraftOpportunity.path}/start`
                      );
                    }}
                  >
                    draft opportunity{" "}
                  </span>
                  with{" "}
                  <span
                    class="draft-opp"
                    onClick={() => {
                      this.props.onStateChangeActions({ show: "small" });
                      this.props.history.push(
                        `${privateRoutes.savedSearch.path}/start`
                      );
                    }}
                  >
                    saved search{" "}
                  </span>
                  and
                  <span
                    class="draft-opp"
                    onClick={() => {
                      this.props.onStateChangeActions({ show: "small" });
                      this.props.history.push(
                        `${privateRoutes.shortlists.path}/start`
                      );
                    }}
                  >
                    {" "}
                    shortlisted{" "}
                  </span>
                  resumes, please review and send it
                </p>
              </div>
            </Text>
          </>
        )}
      </Slide>,
    ];
    return compact(slideHtml);
  };

  getSlideImage = () => {
    switch (this.state.currentSlide) {
      case 0:
        return (
          <img
            className="phillip-img"
            src={require(`../../assets/images/onboarding/01.svg`)}
          />
        );
      case 1:
        return (
          <img
            className="phillip-img"
            src={require(`../../assets/images/onboarding/02.svg`)}
          />
        );
      case 2:
        return (
          <img
            className="phillip-img"
            src={require(`../../assets/images/onboarding/03.svg`)}
          />
        );
      case 3:
        return (
          <img
            className="phillip-img"
            src={require(`../../assets/images/onboarding/04.svg`)}
          />
        );
      default:
        return (
          <img
            className="phillip-img"
            src={require(`../../assets/images/onboarding/01.svg`)}
          />
        );
    }
  };

  handleClosePhillip = () => {
    const { IsFreelancer } = this.props.User;
    updatePhillipPopupStatusApi({
      isFreelancer: IsFreelancer,
    }).then((response) => { });
    onStateChangeActions({ show: "" });
  };

  handleIproPhillipSkip = () => {
    const { IsFreelancer } = this.props.User;
    updatePhillipPopupStatusApi({ isFreelancer: IsFreelancer }).then(
      (response) => {
        if (response.success) {
          const info = {
            message: this.props.labels.iProOnboardingSkipButtonInfo,
            status: "success",
          };
          this.props.notificationAction(info);
        }
      }
    );
    this.props.onStateChangeActions({ show: "" });
  };
  render() {
    const {
      labels,
      open,
      onSkip,
      FirstName,
      notificationAction,
      handleSmallModalClick,
      onChange,
    } = this.props;
    const {
      currentSlide,
      date,
      companyUrl,
      linkedinUrl,
      pageUrl,
      isFacebookConnected,
      isFacebookConnectedNow,
      isLinkedInConnected,
      isLinkedInConnectedNow,
      isCompanyExist,
      isNewCompanyCreate,
      availabilities,
      isOpportunityCreated,
      isSavedSearchCreated,
      isShortlistCreated,
      isDatePickerOpen,
      isLoading,
      LoginWithFb,
      LoginWithLinkedin,
      data,
    } = this.state;

    return (
      <>
        <div className="phillip-component searcher-phillip">
          <Modal
            className={`phillip-popup onboard-${currentSlide + 1}`}
            open={open == "large"}
          >
            {isLoading && <LoadingMask text="" />}
            <Header
              onCloseModal={() => {
                this.handleIproPhillipSkip();
                this.setState((st) => ({ ...st, currentSlide: 0 }));
              }}
              labels={labels}
            />

            {this.getSlideImage()}
            <Modal.Body>
              <Carousel
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                selectedItem={currentSlide}
                onChange={this.updateCurrentSlide}

              >
                {this.generateCards()}
              </Carousel>
              <div className="phillip-footer">
                {currentSlide !== 3 && currentSlide !== 2 && (
                  <button
                    type="primary"
                    htmlType="button"
                    onClick={this.handleContinueClick}
                    className="continue-button"
                    test-data-id="searcher-phillip-continue-button"
                  >
                    {labels.searcherOnboardingContinue}
                  </button>
                )}
                {currentSlide === 3 && (
                  <button
                    type="primary"
                    htmlType="button"
                    className="continue-button"
                    test-data-id="searcher-phillip-finish-button"
                    onClick={this.handleFinishClick}
                  >
                    {labels.searcherOnboardingFinished}
                  </button>
                )}
                {currentSlide === 2 && (
                  <button
                    type="primary"
                    htmlType="button"
                    className="continue-button"
                    test-data-id="searcher-phillip-finish-button"
                  ></button>
                )}
              </div>
            </Modal.Body>
          </Modal>
          {open == "small" && (
            <div
              className="small-modal-phillip-searcher rotate-small-modal"
              onClick={handleSmallModalClick}
            >
              <FullscreenOutlined style={{ fontSize: "20px", color: "#fff" }} />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, newSearch }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  let { FirstName } = user;
  const User = user ? user : StorageService.getUser();

  FirstName =
    FirstName !== undefined ? FirstName : sessionStorage.getItem("userName");
  return { labels, FirstName, newSearch, User };
};
const mapActionToProps = {
  notificationAction,
  onStateChangeAction,
  onStateChangeActions,
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(SearcherPhillip));
