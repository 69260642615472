import React, { Component } from "react";
import "./navbutton.scss";
class NavButton extends Component {
  render() {
    const {
      children,
      classIcon,
      className,
      onClick,
      name,
      testId,
      disabled
    } = this.props;
    return (
      <button
        onClick={onClick}
        className={`nav-button ${className}`}
        name={name}
        test-data-id={testId}
        disabled={disabled}
      >
        <i className={classIcon} />
        <span>{children}</span>
      </button>
    );
  }
}

NavButton.defaultProps = {
  className: ""
};
export default NavButton;
