import React from "react";
import "./video.scss";
class Video extends React.Component {
  render() {
    return (
      <div className="video-page darkBg">
        <iframe
          width="80%"
          height="480"
          src={`https://www.youtube.com/embed/${`VqDgZFr9GEA`}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
          title="Embedded youtube"
        />
    </div>
    );
  }
}

export default Video;
