import React, { Children } from "react";
import moment from "moment";
import { DatePicker, Select } from "../../../../../common";
import { CustomInputNew } from "../../../../Tictell/views/components/CustomInput";

class ExpenseDetail extends React.Component {
  render() {
    const{
      snapshotExpenseDate,
      Dateformatelabel,
      CategoryLabel,
      CurrencyLabel,
      CommentLabel,
      AmountLabel,
      AttachmentsLabel,
      currentExpense,
      DetailsLabel,
      currenciesList,
      expenseCategoriesList,
      ReviewedBylabel,
      ReviewedDatelabel,
      Status
    } = this.props;
    const{
      ExpenseId,
      Comment="",
      SpentOn,
      Amount="",
      CurrencyId,
      ReceiptPath,
      CollaborationId,
      UserId,
      ExpenseCategoryId,
      ExpenseFiles,
      ReviewedBy,
      ReviewedDate
    } = currentExpense;
    return (
      <div className="expense-detail">
        <CustomInputNew label={snapshotExpenseDate} >
          <label className="datepicker-wrapper">
              <DatePicker
                selected={SpentOn && moment(SpentOn)}
                name="expenseDate"
                dateFormat="DD-MM-YYYY"
                placeholderText={Dateformatelabel}
                disabled
              />
            </label>
        </CustomInputNew>
        <CustomInputNew label={CategoryLabel} >
          <Select
              name="Category"
              className={`select-input select-category`}
              value={ExpenseCategoryId}
              clearable={false}
              searchable={false}
              options={expenseCategoriesList}
              disabled
            />
        </CustomInputNew>
        <div className="start-end">
          <CustomInputNew label={AmountLabel} >
            <input
                type="text"
                autoComplete="off"
                value={Amount}
                name="Amount"
                className={`input-text `}
                placeholder={AmountLabel}
                test-data-id="input-expanse-amount"
                disabled
              />
          </CustomInputNew>
          <CustomInputNew label={CurrencyLabel} >
            <Select
                name="Currency"
                className={`select-input select-currency`}
                value={CurrencyId}
                clearable={false}
                searchable={false}
                options={currenciesList}
                disabled
              />
          </CustomInputNew>
        </div>
        <CustomInputNew label={DetailsLabel} >
        <textarea
            type="text"
            value={Comment || ""}
            name="Comment"
            className="textarea-field"
            rows="9"
            rows="6"
            placeholder={CommentLabel}
            test-data-id="input-comment"
            disabled
            />
        </CustomInputNew>
        {ExpenseFiles && ExpenseFiles.length > 0 && 
        <CustomInputNew label={"Reciept"}>
          <div className="attachments-list">
            {ExpenseFiles.map(attach=>(
                <div className={"attach-list-item"} key={attach.Name}>
                  <label><a target="_blank" href={`${window.location.origin.indexOf("stageapp") > -1 ? "https://tictell-stage.prodoo.com/TictellFileContainer/":"https://tictell.prodoo.com/TictellFileContainer/"}${attach.Name}`} download>{attach.Name}</a></label>
                </div>
           ))}
            </div> 
        </CustomInputNew>}
        {(((Status===2||Status===3))&&(ReviewedBy!==null))&&
        <div>
        <CustomInputNew label={ReviewedBylabel} >
          <input
              type="text"
              autoComplete="off"
              value={ReviewedBy||""}
              name="ReviewedBy"
              className={`input-text `}
              placeholder={ReviewedBylabel}
              test-data-id="input-text-opportunity-title"
              disabled
            />
        </CustomInputNew>
        <CustomInputNew label={ReviewedDatelabel} >
          <input
              type="text"
              autoComplete="off"
              value={moment(ReviewedDate).format('DD-MM-YYYY')||""}
              name="ReviewedDate"
              className={`input-text `}
              placeholder={ReviewedDatelabel}
              test-data-id="input-text-opportunity-title"
              disabled
            />
        </CustomInputNew>
        </div>}
      </div>
    );
  }
}

export default ExpenseDetail;
