import axios from "axios";
import { ApiUrl } from "../../api/apiUrls";

export const getNetworkusers = () => {
  return axios.get(ApiUrl.UserNetwork.GetNetworkUsers).then(({ data }) => {
    return data;
  });
};

export const getInvitedNetworkusers = () => {
  return axios.get(ApiUrl.UserNetwork.getInvitedNetworkusers).then(({ data }) => {
    return data;
  });
};

export const getInvitationsNetworkusers = () => {
  return axios.get(ApiUrl.UserNetwork.getInvitationsNetworkusers).then(({ data }) => {
    return data;
  });
};

export const networkInvitationSend = (email) => {
  return axios.post(ApiUrl.UserNetwork.sendNetworkInvitation(email))
  .then(({ data }) => {
    return data;
  });
};

export const acceptOpportunityApi = (userId) => {
  return axios.get(ApiUrl.UserNetwork.acceptNetworkInvitation(userId)).then(({ data }) => data);
};

export const acceptOpportunityApiThroughNetwork = info => {
  return axios
    .put(ApiUrl.Opportunity.AcceptResumeRequestThroughNetwork, info)
    .then(({ data }) => data);
};

export const declineNetworkInvitationApi = (userId) => {
  return axios.get(ApiUrl.UserNetwork.declineNetworkInvitation(userId)).then(({ data }) => data);
};
export const declineNetworkInviteApi = (userId) => {
  return axios.get(ApiUrl.UserNetwork.declineNetworkInvite(userId)).then(({ data }) => data);
};

export const sendNetworkSignupInvitation = (email) => {
  return axios.post(ApiUrl.UserNetwork.sendNetworkSignupInvitation(email))
  .then(({ data }) => {
    return data;
  });
};

export const getUserDetailApi = (userId) => {
  return axios.get(ApiUrl.UserNetwork.GetUserDetailId(userId)).then(({ data }) => data);
};

export const getOpportunity = () => {
  return axios.get(ApiUrl.UserNetwork.getOpportunity).then(({ data }) => data);
};

export const getResumeByUserId = (userId) => {
  return axios.get(ApiUrl.Resume.GetResumeByUserId(userId)).then(({ data }) => data);
};