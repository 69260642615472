import React, { useState } from "react";
import ImgCrop from "antd-img-crop";
import "./style.scss";
import { Avatar, Button, Upload, message } from "antd";
import { CameraOutlined } from "@ant-design/icons";

const ImageCropDemo = (props) => {
  const { userFormDetail, onAvatarChange } = props;
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      url:
        userFormDetail ||
        "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ]);

  const propss = {
    name: "file",
    // action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    maxCount: 1,
    showUploadList: false,
    onChange(info) {
      const reader = new FileReader();
      const file = info.file;
      if (file?.originFileObj) {
        reader.onload = () =>
          setFileList([
            {
              uid: "-1",
              url:
                reader?.result ||
                "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
            },
          ]);
        onAvatarChange(file?.originFileObj);
        reader.readAsDataURL(file?.originFileObj);
      }
    },
  };

  return (
    <div className="d-flex justify-content-center align-items-end">
      <Avatar
        className={"userAvatar"}
        size={120}
        src={fileList ? fileList?.[0]?.url : "assets/images/noImage.png"}
        alt={"labels.NOT_AVAILABLE_LABEL"}
      />
      <div style={{ marginLeft: "-30px" }}>
        <ImgCrop
          showGrid
          rotationSlider
          aspectSlider
          showReset
          modalClassName="modal-crop"
        >
          <Upload {...propss}>
            <Button icon={<CameraOutlined />}></Button>
          </Upload>
        </ImgCrop>
      </div>
    </div>
  );
};

export default ImageCropDemo;
