import { Button, Input, Modal, Space } from "antd";
import React from "react";
import "../NewResumeSearch/search.scss";
import { useSelector } from "react-redux";
import { useState } from "react";
import closeIcon from '../../assets/images/Icons/close-new.png'
import LanguageImage from '../../assets/images/shortlist-popup-edit.png'

const ShortListModal = ({ open, setOpen, handleCreateNew }) => {
  const label = useSelector((state) => state?.systemLabel?.labels);
  const [error, setError] = useState(false);
  
  return (
    <>
      <Modal
        open={open?.open}
        onCancel={() => {
          setOpen((st) => ({
            ...st,
            open: false,
            id: "",
            value: "",
          }));
        }}
        closable={false}
        centered
        footer={null}
        className={`new-design-modal shortlist-modal `}
        style={{ maxHeight: "calc(100% - 32px)", overflow: "auto" }}

      >
        <div className="text-center header-modal">
          <h5>Create Shortlist</h5>
          <div
            onClick={() => {
              setOpen((st) => ({
                ...st,
                open: false,
                id: "",
                value: "",
              }));
            }}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="form">
          <div className="images-icon">
            <img className="mb-2" src={LanguageImage} alt="" />
            <p className="paragraph">Please enter shortlist name to proceed.</p>
          </div>
          <Input
            placeholder="Enter shortlist name"
            value={open?.value || ""}
            autoFocus={true}
            size="large"
            onChange={(e) => {
              e.persist();
              setOpen((St) => ({
                ...St,
                value: e.target.value,
              }));
              setError(false);
            }}
            style={
              error
                ? {
                  marginTop: "8px",
                  border: "1px solid red",
                }
                : {
                  marginTop: "8px",
                }
            }
          />

          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-form-button mt-3"
            size="large"
            onClick={() => {
              if (open?.value == "") {
                setError(true);
              } else {
                handleCreateNew();
              }
            }}
          >
            Create
          </Button>
        </div>
      </Modal >
    </>
  );
};
export default ShortListModal;
