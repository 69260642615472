import { AuthService } from ".";

const TOKEN_KEY = "Token";
const SYSTEM_LABEL = "System_Label";
const USER_INFO = "User";
export class StorageService {
  static saveTokenAndUser = ({ token, user }) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USER_INFO, JSON.stringify(user));
    AuthService.setDefaultAuthHeaders();
  };

  static saveAppType = (appType ) => {
    sessionStorage.setItem("appType", JSON.stringify(appType));
    AuthService.setDefaultAuthHeaders();
  };

  static saveToken = ({ token }) => {
    localStorage.setItem(TOKEN_KEY, token);
    AuthService.setDefaultAuthHeaders();
  };

  static setUser = ({ user }) =>
    localStorage.setItem(USER_INFO, JSON.stringify(user));

  static getUser = () => {
    const user = localStorage.getItem(USER_INFO);
    return JSON.parse(user);
  };

  static tokenExists = () => !!StorageService.getToken();

  static getToken = () => localStorage.getItem(TOKEN_KEY);

  static clearToken = () => localStorage.removeItem(TOKEN_KEY);
  static clearUser = () => localStorage.removeItem(USER_INFO);

  static clearAll = () => localStorage.clear();

  static setCookie = () => localStorage.setItem("cookie", true);
  static getCookie = () => localStorage.getItem("cookie");

  static saveSystemLabels = labels =>
    localStorage.setItem(SYSTEM_LABEL, JSON.stringify(labels));

  static getSystemLabels = () => {
    const labels = localStorage.getItem(SYSTEM_LABEL);
    return labels ? JSON.parse(labels) : null;
  };
}
