import React from "react";
import "./dashboard.scss";
import Widget from "./Widget";
import { Column, HelpTooltip } from "../../../../common";
import {
  getSavedSearchesApi,
  getShortlistApi,
  getSentOpportunitesApi,
} from "../../headsupApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
class Dashboard extends React.Component {
  state = {
    allSentOpportunitiesCount: "",
    allSavedSearchesCount: "",
    allShortlistsCount: "",
  };
  componentDidMount() {
    const { isFreelancer } = this.props;
    getSentOpportunitesApi({ isFreelancer }).then((response) => {
      if (response.success) {
        this.setState({
          allSentOpportunitiesCount: response.items.Sent.length,
        });
      }
    });
    getSavedSearchesApi({ isFreelancer }).then((response) => {
      if (response.success) {
        this.setState({
          allSavedSearchesCount: response.items.length,
        });
      }
    });
    getShortlistApi().then((data) => {
      this.setState({
        allShortlistsCount: data.items.length,
      });
    });
  }

  render() {
    const { isHelpActive, labels, UserFeatures } = this.props;
    const {
      allShortlistsCount,
      allSavedSearchesCount,
      allSentOpportunitiesCount,
    } = this.state;
    return (
      <div className="dashboard-view">
        <React.Fragment>
          <Column className="column-1">
            {UserFeatures &&
              UserFeatures.find(
                (x) => x.Name == "Headsup-Home-Shortlists" && x.IsActive
              ) && (
                <Widget testId={"headsup-shortlist-count"}>
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip
                        content={labels.Timesheet_Dashboard_Message}
                      />
                    )}{" "}
                    <i className="shortlistIcon" />
                    <label>{"Shortlists"}</label>
                  </Widget.Header>
                  <Widget.Content>
                    <div className="widget-content">
                      <div>
                        <label>{allShortlistsCount || 0}</label>
                        <label>{"Shortlists"}</label>
                      </div>
                    </div>
                  </Widget.Content>
                </Widget>
              )}

            {UserFeatures &&
              UserFeatures.find(
                (x) => x.Name == "Headsup-Home-SentOpportunities" && x.IsActive
              ) && (
                <Widget testId="headsup-opportunity-count">
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip content={labels.Expense_Dashboard_Message} />
                    )}
                    <i className="opportunityIcon" />
                    <label>{"Sent Opportunities"}</label>
                  </Widget.Header>
                  <Widget.Content>
                    <div className="widget-content">
                      <div>
                        <label>{allSentOpportunitiesCount || 0}</label>
                        <label>{"Opportunities"}</label>
                      </div>
                    </div>
                  </Widget.Content>
                </Widget>
              )}
            {UserFeatures &&
              UserFeatures.find(
                (x) => x.Name == "Headsup-Home-SavedSearches" && x.IsActive
              ) && (
                <Widget testId="headsup-savedsearches-count">
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip
                        content={labels.Collaboration_Dashboard_Message}
                      />
                    )}
                    <i className="savedSearchIcon" />
                    <label>{"Saved Searches"}</label>
                  </Widget.Header>
                  <Widget.Content>
                    <div className="widget-content">
                      <div>
                        <label>{allSavedSearchesCount || 0}</label>
                        <label>{"Saved Searches"}</label>
                      </div>
                    </div>
                  </Widget.Content>
                </Widget>
              )}
          </Column>
        </React.Fragment>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Dashboard);
