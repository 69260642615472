import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, find, map, filter, includes, toLower, head } from "lodash";
import { PageWrapper } from "../../../components";
import { ConfirmDialog, EmptyInfo, LoadingMask } from "../../../common";
import { notificationAction } from "../../../actions";
import "./searcherOpportunitySent.scss";
import {
  getShortlistResumesApi,
  getSentOpportunityDetailApi,
  deleteSendOpportunityApi,
  deleteOpportunityAndAssociationsApi,
  getOpportunityResumesApi,
  getSentOpportunitesApi,
} from "../opportunityApi";
import {
  deleteResumeApi,
  getShortlistResumeApi,
  GetShortlistResumeCount,
} from "../../Shortlist/shortlistApi";
import {
  getOpportunityIprosResumeApi,
  getCurrenciesApi,
} from "../../Collaboration/common/collaborationApi";
import { onStateChangeAction } from "./searcherOpportunitySentAction";
import { Col, Row, Select, Typography, Input, Space } from "antd";
import ShortListResume from "../Searcher/NewCreateOpportunity/component/resumeResumeList";
import DetailModal from "../../newShortlist/component/detailModal";
import NewOpportunityDetail from "./common/NewOpportunityDetail";
import ArrowLeft from "../../../assets/images/Icons/arrow-left.png";
import DeleteIcon from '../../../assets/images/Icons/delete-modal.png'
import moment from "moment";
import SearchIcon from '../../../assets/images/secrch-icon.png'
import { formatDate } from "../../../utilities";

const { Option } = Select;
const { Title, Text } = Typography

class SearcherOpportunitySent extends Component {
  state = {
    declinedRequests: "",
    acceptedRequests: "",
    pendingRequests: "",
    isSearchFocus: false,
    searchListHover: false,
    showModal: { show: false },
    status: null,
    isResumeDetail: false,
    active: "",
    windowWidth: window?.innerWidth
  };

  getOpportunities = () => {
    const { isFreelancer } = this.props;
    this.props.onStateChangeAction({ isFetching: true, isLoading: true });
    getSentOpportunitesApi({ isFreelancer })
      .then((response) => {
        const {
          success,
          items: { Sent },
        } = response;
        if (success) {
          const filterData = response?.items?.Sent?.map((single) => {
            return {
              ...single,
              label: single?.RequestName,
              value: single?.RequestId,
            };
          });
          this.props.onStateChangeAction({
            opportunities: filterData,
            filterOpportunires: filterData,
            isFetching: false,
            isLoading: false,
          });
          const spliturl = this.props.location.pathname.match(/\d+/g);
          if (Sent.length > 0) {
            if (spliturl != null) {
              let filteredOppertunity = Sent.filter(
                (res) => res.RequestId == spliturl[0]
              );
              // this.handleOpportunityClick({
              //   selectedOpportunity: filteredOppertunity[0],
              // });
            }
            // else {
            //   this.handleOpportunityClick({ selectedOpportunity: Sent[0] });
            // }
          }
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    this.props.onStateChangeAction({
      opportunities: [],
      filterOpportunires: [],
      selectedOpportunity: {},
      shortlistResumes: []
    });
    this.getOpportunities();
    this.getCurrencies();
    this.setState(st => ({ ...st, active: window.location.hash?.slice(1) }))
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleResize);
  };

  replaceObjectKeys = ({ oldObject, mappingObject }) => {
    return Object.keys(oldObject).reduce((acc, key) => {
      acc[mappingObject[key]] = oldObject[key];
      return acc;
    }, {});
  };
  getOpportunityIProsResume = (requestId) => {
    getOpportunityIprosResumeApi(requestId).then((data) => {
      if (data.success) {
        const iprosList = map(data.items.user, (item) => {
          const newItem = {
            ...item,
            value: item.ResumeId,
            label: item.UserName,
          };

          return newItem;
        });
        this.props.onStateChangeAction({
          iprosList,
        });
      }
    });
  };
  handleOpportunityClick = ({ selectedOpportunity }) => {
    this.props.onStateChangeAction({ isLoading: true });
    const { RequestId } = selectedOpportunity;
    this.getOpportunityIProsResume(RequestId);

    this.props.onStateChangeAction({
      currentOpportunityId: RequestId,
      isFetchingShortlist: true,
      isFetchingShortlistResume: true,
      selectedShortlists: [],
      selectedOpportunity,
      shortlistResumes: [],
      selectedResume: {},
      selectedIpro: {},
      hideShortList: false,
    });

    getSentOpportunityDetailApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const { items } = response;

          const filterShortList = items?.ShortLists?.map((single) => ({
            ...single,
            label: single?.ShortlistName,
            value: single?.ShortlistId,
          }));

          const selectedOpportunity = {
            ...items,
            FeeCurrencyType: this.props.allCurrenciesList.find(
              (x) => x.CurrencyId == items.FeeCurrencyType
            )?.Name || items.FeeCurrencyType, // Ensure fallback in case of no match
          };

          this.props.onStateChangeAction({
            selectedOpportunity,
          });

          this.setState({
            declinedRequests: items.Declined || 0,
            acceptedRequests: items.Accepted || 0,
            pendingRequests: items.Total - (items.Accepted || 0) - (items.Declined || 0),
            showDetail: true
          });

          if (isEmpty(filterShortList)) {
            this.props.onStateChangeAction({
              selectedShortlists: [],
              selectedShortlist: null,
              shortlistResumes: [],
              selectedResume: {},
              selectedIpro: {},
              isFetchingShortlist: false,
            });
            return;
          }

          const selectedShortlist = head(filterShortList);
          this.props.onStateChangeAction({
            selectedShortlists: filterShortList,
            selectedShortlist,
            isFetchingShortlist: false,
          });

          getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
            .then((response) => {
              if (response.success) {
                const { items } = response;
                this.props.onStateChangeAction({
                  shortlistResumes: items,
                  selectedResume: {},
                  selectedIpro: {},
                  isFetchingShortlistResume: false,
                });
              }
              this.props.onStateChangeAction({ isLoading: false });
            })
            .catch(() => {
              this.props.onStateChangeAction({ isLoading: false });
            });
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({
          isFetchingShortlist: false,
          isFetchingShortlistResume: false,
          isLoading: false,
        });
      });
  };


  handleSearchChange = (e) => {
    const { value } = e.target;
    const { opportunities } = this.props;
    const filterOpportunires = filter(opportunities, (opportunity) =>
      includes(toLower(opportunity.RequestName), toLower(value))
    );
    this.props.onStateChangeAction({ filterOpportunires, searchkey: value });
  };

  handleOpportunityDelete = ({ selectedOpportunity, e }) => {
    e.stopPropagation()
    // this.handleOpportunityClick({ selectedOpportunity });
    this.props.onStateChangeAction({
      selectedOpportunity,
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftOpportunityDeleteConformationMsg,
    });
  };
  handleYesClick = () => {
    const { yesClickCount, currentOpportunityId } = this.props;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: this.props.labels
          .InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg,
        yesClickCount: yesClickCount + 1,
      });
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: "",
      yesClickCount: 0,
    });

    this.props.onStateChangeAction({ isLoading: true });
    deleteOpportunityAndAssociationsApi({
      RequestId: currentOpportunityId,
    })
      .then((response) => {
        if (!response.success) {
          this.props.onStateChangeAction({
            selectedOpportunity: null,
          });
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: response.message,
            status: "succes",
          };
          this.props.notificationAction(info);
        }
        this.deleteOpportunity({ RequestId: currentOpportunityId });
      })
      .catch((response) => {
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
        });
      });
  };
  deleteOpportunity = ({ RequestId }) => {
    deleteSendOpportunityApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const info = {
            message: this.props.labels.oppDeletedSuccessfully,
            status: "success",
          };
          this.props.notificationAction(info);
          this.getOpportunities();
          this.props.onStateChangeAction({
            selectedOpportunity: null,
            opportunity: null,
          });
        }
        this.props.onStateChangeAction({
          selectedOpportunity: null,
          opportunity: null,
        });
      })
      .catch((response) => {
        this.props.onStateChangeAction({
          isLoading: false,
          selectedOpportunity: null,
          opportunity: null,
        });
      });
  };
  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          this.props.onStateChangeAction({ allCurrenciesList });
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  handleNoClick = () => {
    const { dialogMessage, currentOpportunityId, yesClickCount } = this.props;
    const { labels } = this.props;
    if (
      dialogMessage ===
      labels.InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg
    ) {
      this.props.onStateChangeAction({ isLoading: true });
      this.deleteOpportunity({ RequestId: currentOpportunityId });
    }
    this.props.onStateChangeAction({ dialogMessage: "", yesClickCount: 0 });
  };

  handleSearchBlur = () => {
    const { searchListHover } = this.state;
    if (searchListHover) return;
    this.setState({ isSearchFocus: false });
  };

  handleShortListSelect = ({ selectedShortlist }) => {
    const { selectedShortlists } = this.props;
    const alreadySelected = filter(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId === selectedShortlist.ShortlistId
    );
    if (isEmpty(alreadySelected)) {
      this.props.onStateChangeAction({
        selectedShortlists: [...selectedShortlists, selectedShortlist],
      });
      this.setState({
        isSearchFocus: false,
      });
      return;
    }
    this.setState({ isSearchFocus: false });
  };
  handleSelectShortlist = (item) => {
    this.props.onStateChangeAction({
      selectedShortlist: item,
      shortlistResumes: [],
      selectedResume: {},
    });
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        if (data.success) {
          const selectedResume = head(data.items);
          const selectedIpro =
            this.iprosList.find(
              (ipro) => ipro.ResumeId === selectedResume.ResumeId
            ) || {};
          this.props.onStateChangeAction({
            shortlistResumes: data.items,
            fetchingResumes: false,
            isFetchingShortlistResume: false,
            selectedResume,
            selectedIpro,
          });
        }
      })
      .catch((err) => {
        this.props.onStateChangeAction({ fetchingResumes: false });
      });
  };

  handleSelectedShortlistClick = ({ selectedShortlist }) => {
    getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const selectedResume = head(items);
          const selectedIpro =
            this.props.iprosList.find(
              (ipro) => ipro.ResumeId == selectedResume.ResumeId
            ) || {};
          if (isEmpty(items)) {
            const info = {
              message: this.props.labels
                .InfoSearcherOpportunityDraftEmptyShortlist,
              status: "error",
            };
            this.props.notificationAction(info);
          }
          this.props.onStateChangeAction({
            selectedShortlist,
            shortlistResumes: items,
            selectedResume,
            selectedIpro,
            fetchingResumes: false,
            isFetchingShortlistResume: false,
          });
        }
      })
      .catch((response) => { });
  };

  setFieldValue = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [name]: value,
      },
    });
  };

  handleResumeSelect = (selectedResume) => {
    const { shortlistResumes } = this.props;
    selectedResume = find(shortlistResumes, {
      ResumeId: selectedResume.ResumeId,
    });
    const selectedIpro =
      this.props.iprosList.find(
        (ipro) => ipro.ResumeId == selectedResume.ResumeId
      ) || {};
    this.props.onStateChangeAction({ selectedResume, selectedIpro });
  };

  handleResumeDelete = (item) => {
    let { shortlistResumes, selectedResume, selectedShortlist } = this.props;
    deleteResumeApi(item.ResumeId, selectedShortlist.ShortlistId)
      .then((data) => {
        if (data.success) {
          shortlistResumes = shortlistResumes.filter(
            (x) => x.ResumeId !== item.ResumeId
          );
          this.props.onStateChangeAction({
            shortlistResumes,
            selectedResume:
              item.ResumeId == selectedResume.ResumeId ? {} : selectedResume,
          });
        }
      })
      .catch((err) => console.log("Error ", err));
  };

  handleOkClick = () => {
    this.handleOpportunityUpdate({ IsSent: true });
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  getOpportunityResumes = (searchkey) => {
    const { currentOpportunityId } = this.props;
    this.props.onStateChangeAction({ isLoading: true });
    getOpportunityResumesApi({ requestId: currentOpportunityId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          this.props.onStateChangeAction({
            shortlistResumes: items,
            // selectedResume: head(items),
            isFetchingShortlistResume: false,
          });
          const { shortlistResumes } = this.props;
          let filteredList;
          if (searchkey == 1) {
            filteredList = items.filter(
              (item) => item.IsConfirmed == true && !item.IsDeleted
            );
          } else if (searchkey == 2) {
            filteredList = items.filter(
              (item) => item.IsDeleted == true || item.IsDeclined == true
            );
          } else {
            filteredList = items.filter(
              (item) =>
                item.IsDeleted == false &&
                item.IsDeclined == false &&
                item.IsConfirmed == false
            );
          }
          const selectedResume = head(filteredList) || {};
          const selectedIpro =
            this.props.iprosList.find(
              (ipro) => ipro.ResumeId == selectedResume.ResumeId
            ) || {};
          this.props.onStateChangeAction({
            hideShortList: true,
            isLoading: false,
            showResumeList: true,
            shortlistResumes: filteredList,
            isFetchingShortlistResume: false,
            selectedResume,
            selectedIpro,
          });
        }
      })
      .catch((response) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  handleAcceptedResumes = () => {
    this.getOpportunityResumes(1);
  };
  handleDeclinedResumes = () => {
    this.getOpportunityResumes(2);
  };
  handleNoActionResumes = () => {
    this.getOpportunityResumes(3);
  };
  handleCloseModal = () => {
    this.setState((st) => ({ ...st, showModal: false }));
  };
  handleStatus = (e) => {
    this.setState(st => ({ ...st, status: e }))
  }
  handleModalClick = (data) => {
    this.setState((st) => ({ ...st, showModal: { show: true, data: data } }));
  };
  handleMobileModalClose = () => {
    this.setState((st) => ({
      ...st,
      isResumeDetail: false,
    }));
  };

  handleListOpenMobile = () => {
    this.setState((st) => ({ ...st, isResumeDetail: true }));
  };

  render() {
    const { labels, isHelpActive } = this.props;
    const { declinedRequests, acceptedRequests, pendingRequests } = this.state;
    const {
      dialogMessage,
      isLoading,
      filterOpportunires,
      selectedOpportunity,
      selectedShortlists,
      shortlistResumes,
      currentOpportunityId,
      selectedAction,
    } = this.props;
    const getTabs = () => {

      return [
        {
          id: 1,
          label: labels?.IPRO_NAVIGATION_MESSAGE_SENT_LABEL,
          active: window?.location?.hash == "#/sent-opportunities",
          value: "/sent-opportunities"
        },
        {
          id: 2,
          label: labels?.SEARCHER_NAVIGATION_OPPORTUNITY_DRAFT_LABEL,
          active: window?.location?.hash == "#/draft-opportunities",
          value: "/draft-opportunities"
        },
      ]
    }
    return (
      <PageWrapper className="searcher-draft-opportunity-page search-page-new-design">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              {dialogMessage ===
                this.props.labels
                  .InfoSearcherOpportunityDraftSendConformationMsg ? (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-sent-ok"
                  onClick={this.handleOkClick}
                >
                  Ok
                </ConfirmDialog.Button>
              ) : (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-sent-yes"
                  onClick={this.handleYesClick}
                >
                  Yes
                </ConfirmDialog.Button>
              )}
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {this.state?.showModal?.show == true && (
          <DetailModal
            setOpen={this.handleCloseModal}
            open={this.state.showModal}
            selected={[]}
            name="sent"
          />
        )}
        <div className="h-100 new-design-search w-100 d-flex flex-column">
          <div className="tabs-header-col">
            {this?.state?.isMobileDevice ? <div
              onClick={() => { this.setState(st => ({ ...st, isMobileDevice: false })) }}
            >
              <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
            </div> :
              <div className="d-flex gap-16 justify-content-between align-items-center">
                <div className="d-flex gap-16 align-items-center main-header">
                  {(this?.state?.windowWidth > 480) && (
                    <button className={`tab-btn-coll w-100 ${window?.location?.hash == "#/create-opportunity" ? "active-url" : "inactive-url"}`}
                      onClick={() => { this.props.history.push("/create-opportunity") }}
                    >
                      {labels?.create_opportunity_btn}
                    </button>
                  )}
                  {this.state.windowWidth < 767 ?
                    <Select
                      className="dropdown-callooration"
                      size="medium"
                      bordered={false}
                      onChange={(e) => {
                        this.setState(st => ({ ...st, active: e }))
                        this.props.history.push(`${e}`)
                      }}
                      value={this?.state?.active}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={getTabs() || []}
                    />
                    :
                    <>
                      {getTabs()?.map(single =>
                        <button className="tab-btn-coll"
                          key={single?.id}
                          style={{ background: single?.active ? "#8E81F5" : "", color: single?.active ? "#fff" : "#000" }}
                          onClick={() => {
                            this.props.history.push(single?.value)
                          }}
                        >
                          {single?.label}
                        </button>
                      )}
                    </>
                  }
                </div>

              </div>
            }
          </div>
          <Row className=" h-100 " style={{ overflow: "auto" }}>
            <Col xs={24} md={Object.keys(filterOpportunires).length == 0 ? 24 : 10} className="opportunity-column1">
              <div className="h-100 d-flex flex-column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px",
                    borderBottom: "1px solid #F3F3F3"
                  }}
                >
                  {this?.state?.showDetail ?
                    <>
                      {this?.state?.isResumeDetail ?
                        <div className="d-flex align-items-center justify-content-between w-100 gap-8 "
                        >
                          <div className="d-flex text-clamp align-items-center gap-8 pointer pointer"
                            onClick={() => { this.setState(st => ({ ...st, isResumeDetail: false })) }}
                          >
                            <div>
                              <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                            </div>
                            <Title level={5} className="m-0 text-clamp">{`Selected Resumes (${shortlistResumes?.length || 0})` || ""}</Title>

                          </div>
                        </div>
                        :
                        <div className="d-flex align-items-center justify-content-between w-100 gap-8 "
                        >
                          <div className="d-flex text-clamp align-items-center gap-8 pointer pointer"
                            onClick={() => { this.setState(st => ({ ...st, showDetail: false })) }}
                          >
                            <div>
                              <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                            </div>
                            <Title level={5} className="m-0 text-clamp">{selectedOpportunity?.RequestName || ""}</Title>
                          </div>
                          <Text level={5} className="m-0 pointer" style={{ color: "#FF6363", whiteSpace: "pre" }}
                            onClick={(e) => {
                              e.stopPropagation()
                              this.handleOpportunityDelete({
                                selectedOpportunity: selectedOpportunity, e
                              });
                            }}
                          >Delete Opportunity</Text>
                        </div>
                      }
                    </>
                    :
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        width: "100%"
                      }}
                    >

                      <Input
                        placeholder={labels?.SearcherOpportunitySentSearchPlaceholder}
                        size="medium"
                        bordered={false}
                        style={{ border: "1px solid #F3F3F3" }}
                        onChange={this.handleSearchChange}
                      />
                      <div>
                        <img style={{ height: "30px" }} src={SearchIcon} alt="" />
                      </div>
                    </div>
                  }
                </div>
                {this?.state?.showDetail ? (
                  <>
                    {this?.state?.isResumeDetail ?
                      <div className=" resume-list h-100" style={{ background: "#fff", borderRadius: "12px" }}>
                        <ShortListResume
                          resumeList={shortlistResumes}
                          score={0}
                          handleModalClick={this.handleModalClick}
                          name="opportunity"
                        />
                      </div>
                      :
                      <>
                        {selectedOpportunity?.RequestName ? (
                          <NewOpportunityDetail
                            declinedRequests={declinedRequests}
                            acceptedRequests={acceptedRequests}
                            pendingRequests={pendingRequests}
                            selectedOpportunity={selectedOpportunity}
                            labels={labels}
                            onAcceptedResumes={this.handleAcceptedResumes}
                            onDeclinedResumes={this.handleDeclinedResumes}
                            onNoActionResumes={this.handleNoActionResumes}
                            handleListOpenMobile={this.handleListOpenMobile}
                            selectedShortlists={selectedShortlists}
                            selectedAction={selectedAction}
                            status={this?.state?.status}
                            handleStatusChange={this.handleStatus}
                            onSelectedActionChange={({ selectedAction }) =>
                              this.props.onStateChangeAction({
                                selectedAction,
                              })
                            }
                          />
                        ) : (
                          <EmptyInfo>{labels?.emptyOpportunityInfo}</EmptyInfo>
                        )}
                      </>
                    }
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        overflow: "auto",
                        padding: "12px",
                        flex: 1
                      }}
                    >
                      {filterOpportunires?.length > 0 ? (
                        <Space size={[6, 6]} wrap className="short-list-new">
                          {filterOpportunires?.map((item) => (
                            <div
                              onClick={() => this.handleOpportunityClick({ selectedOpportunity: item })}
                              key={item?.UserCompanyId}
                              className={`d-flex gap-8 justify-content-between align-items-center pointer ${selectedOpportunity?.RequestName == item?.RequestName
                                ? "background-shortlist short-list-item "
                                : "short-list-item"
                                }`}>
                              <div className="d-flex gap-8">
                                <div>
                                  <label style={{ fontSize: "14px" }}>
                                    {item.label}
                                  </label>
                                  <p style={{ fontSize: "12px", marginBottom: 0 }}>{formatDate(item?.UpdatedOn)}</p>
                                </div>
                              </div>
                              {selectedOpportunity?.RequestName == item?.RequestName &&
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleOpportunityDelete({
                                      selectedOpportunity: item, e
                                    });
                                  }}
                                >
                                  <img src={DeleteIcon} alt="" style={{ height: "20px" }} />
                                </div>
                              }
                            </div>
                          ))}
                        </Space>
                      ) : (
                        <EmptyInfo>{labels?.emptyOpportunityInfo}</EmptyInfo>
                      )}
                    </div>
                  </>
                )}

                {(this?.state?.windowWidth < 480 && !this?.state?.showDetail) && (
                  <div style={{ margin: "auto", padding: "12px" }}>
                    <div className="tabs-header-col my-0">
                      <button className="tab-btn-coll"
                        style={{ background: "#6C63FF", color: "#fff" }}
                        onClick={() => { this.props.history.push("/create-opportunity") }}
                      >
                        {labels?.create_opportunity_btn}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col md={14} xs={0} offset={0.5}
              className={
                Object.keys(filterOpportunires).length == 0 ? null : "h-100 "
              }
            >
              <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                <Title level={5} className="m-0 text-clamp" style={{ padding: "12px", borderBottom: "1px solid #f4f2fe" }}>{selectedOpportunity?.RequestName ? `Selected Resumes (${shortlistResumes?.length || 0})` : "Select Opportunity"}
                </Title>
                <ShortListResume
                  resumeList={shortlistResumes}
                  score={0}
                  handleModalClick={this.handleModalClick}
                  name="opportunity"
                />
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  searcherOpportunitySent,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { IsFreelancer } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    isFreelancer: IsFreelancer,
    isHelpActive,
    ...searcherOpportunitySent,
  };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(SearcherOpportunitySent);
