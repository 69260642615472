import React, { Component } from "react";
import "./switch.scss";

class Switch extends Component {
  render() {
    const { IsFreelancer, onChange } = this.props;
    return (
      <div className="switch-container" onClick={onChange}>
        <button
          className={`DashboardCompanyToggle ${IsFreelancer &&
            `DashboardFreelanceToggle`}`}
        />
      </div>
    );
  }
}

export default Switch;
