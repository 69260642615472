import React, { useState } from "react";
import CButton from "../../components/CButton/CButton";
import { Modal, Button, Form, Alert, Input, Typography, Tooltip, Row, Col } from "antd";
import classes from "./index.module.scss";
import closeIcon from '../../../../assets/images/Icons/close-new.png'

const { Title, Text } = Typography;

const style = {
  fontWeight: 500
}

const LabelValue = ({ title, value }) => {
  return (
    <>
      <Text className="fnt-s-12" style={{ color: "#00000066" }}>{title}</Text>
      <div >
        <Text className="fnt-s-16 " style={style}>{value}
        </Text>
      </div></>
  )
}

function PresentationPopup({ userPresentation, labels }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <CButton
        onClick={() => setIsModalVisible(true)}
        className="mt-2 fnt-s-12"
        style={{ borderRadius: "25px" }}
        title={"View Presentation"}
      />

      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        closable={false}
        className={`new-design-modal  ${classes.popUpForm}`}
        bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 70px)' }}
      >
        <div className="text-center header-modal">
          <h5 className={classes.heading}>{labels.Presentation_Label}</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <Row gutter={[6, 12]} className="w-100" style={{ padding: "20px" }}>
          <Col span={12}>
            <LabelValue
              title={labels.TITLE_LABEL}
              value={userPresentation.presentationTitle || labels.NOT_AVAILABLE_LABEL}
            />
          </Col>
          <Col span={12}>
            <LabelValue
              title={labels.USER_NAME_LABEL}
              value={userPresentation.UserName || labels.NOT_AVAILABLE_LABEL}
            />
          </Col>
          <Col span={12}>
            <LabelValue
              title={labels.ROLE_LABEL}
              value={userPresentation.ProfileValue || labels.NOT_AVAILABLE_LABEL}
            />
          </Col>
          <Col span={12}>
            <LabelValue
              title={labels?.PHONE_LABEL}
              value={userPresentation.UserPhonenumberValue ||
                labels.NOT_AVAILABLE_LABEL}
            />
          </Col>
          <Col span={12}>
            <LabelValue
              title={labels.EMAIL_LABEL}
              value={userPresentation.UserEmailValue || labels.NOT_AVAILABLE_LABEL}
            />
          </Col>
          <Col span={12}>
            <LabelValue
              title={labels?.WEB_URL_LABEL}
              value={userPresentation.WebUrl || labels.NOT_AVAILABLE_LABEL}
            />
          </Col>
          <Col span={24}>
            <LabelValue
              title={"Description"}
              value={userPresentation.Detail}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default PresentationPopup;
