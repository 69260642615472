import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { notificationAction } from "../../actions";
import ReactHtmlParser from "react-html-parser";
import "./notification.scss";
const Message = ({ children }) => (
  <div className="notification-message">{children}</div>
);
class Notification extends Component {
  static Message = Message;
  componentDidMount() {
    setTimeout(() => {
      this.hideNotification();
    }, 4000);
  }

  hideNotification = () => {
    this.props.notificationAction({
      message: "",
      status: "success"
    });
  };

  render() {
    const { children, status, className } = this.props;
    return (
      <div
        className={`notification-dialog notification-${status} ${className} animated ${
          children ? "fadeInDown" : "fadeOutUp"
        }`}
        onClick={this.hideNotification}
        test-data-id={`notification-dialog-${status}`}
      >
        {ReactHtmlParser(children)}
      </div>
    );
  }
}
Notification.propTypes = {
  status: PropTypes.string.isRequired
};
export default connect(
  null,
  { notificationAction }
)(Notification);
