import React, { Component } from "react";
import "./presentformeditor.scss";
import { connect } from "react-redux";
import { EmptyInfo, LoadingMask, Input } from "../../../common";
import Select from "react-select";
import ImgSrc from "../../../assets/images/present.svg";
import { Messages } from "../../../utilities";

class PresentFormEditor extends Component {
  render() {
    const {
      isLoading,
      presentForm,
      labels,
      profiles,
      onFormFieldChange,
      userEmails,
      userPhonenumbers,
      onFormSelectChange,
      onSelectBlur,
      onFileChange,
      getImgSrc,
      onFormFieldBlur,
    } = this.props;
    const {
      Title,
      invalidTitle,
      UserName,
      invalidUserName,
      Profiles,
      invalidRole,
      UserEmails,
      WebUrl,
      invalidWebUrl,
      Detail,
      UserDetailId,
      selectedPhoneNumber,
      IsChecked,
      IsDefault,
    } = presentForm;
    const avatar = presentForm && presentForm.Logo;
    return (
      <div className="present-form-editor">
        {isLoading && <LoadingMask />}
        {!UserDetailId ? (
          <EmptyInfo>{labels.Freelance_Present}</EmptyInfo>
        ) : (
          <div className="present-form">
            <div className="companyBigIcon">
              {avatar && (
                <div
                  // disabled={isDisabled}
                  onClick={getImgSrc}
                  className="close_btn_purple"
                >
                  <i className="fa fa-times-circle" />
                </div>
              )}
              <div className={`dashItemImg ${avatar ? "" : "no-dp"}`}>
                <img
                  className="roundeImg"
                  src={avatar ? avatar : ImgSrc}
                  alt="present"
                />
              </div>
              <div className="uploadBtn">
                <input
                  type="file"
                  className="input-upload-file"
                  onChange={onFileChange}
                  accept="image/*"
                />
              </div>
            </div>
            <div className="form-row">
              <label className="form-label">{labels.TITLE_LABEL}:</label>
              <Input
                name="Title"
                className={`input-text ${invalidTitle ? "inValid" : ""}`}
                value={Title ? Title : ""}
                placeholder={labels.presentTitlePlaceholder}
                onChange={onFormFieldChange}
                onBlur={onFormFieldChange}
                testId="input-text-present-title"
                maxlength={"100"}
              />
            </div>
            <div className="form-row">
              <label className="form-label">{labels.USER_NAME_LABEL}:</label>
              <Input
                name="UserName"
                className={`input-text ${invalidUserName ? "inValid" : ""}`}
                value={UserName ? UserName : ""}
                placeholder={labels.presentNamePlaceholder}
                onChange={onFormFieldChange}
                onBlur={onFormFieldChange}
                testId="input-text-present-username"
                maxlength={"50"}
              />
            </div>

            <div className="form-row">
              <label className="form-label">{labels.ROLE_LABEL}:</label>

              <Select
                name="Profiles"
                className={`select-input ${invalidRole ? "inValid" : ""}`}
                value={(Profiles && Profiles.ProfileValue) || ""}
                placeholder={labels.presentPrimaryRolePlaceholder}
                onChange={(selectedOption) =>
                  onFormSelectChange("Profiles", "ProfileValue", selectedOption)
                }
                onBlur={onSelectBlur}
                options={profiles}
                clearable={false}
              />
            </div>

            <div className="form-row">
              <label className="form-label">{labels.PHONE_LABEL}:</label>

              <Select.Creatable
                name="UserPhonenumbers"
                className="select-input"
                value={(selectedPhoneNumber && selectedPhoneNumber) || ""}
                placeholder={labels.presentPhoneNumberPlaceholder}
                onChange={(selectedOption) =>
                  onFormSelectChange(
                    "UserPhonenumbers",
                    "UserPhonenumberValue",
                    selectedOption
                  )
                }
                autoBlur={true}
                options={userPhonenumbers}
                clearable={false}
              />
            </div>

            <div className="form-row">
              <label className="form-label">{labels.EMAIL_LABEL}:</label>

              <Select.Creatable
                name="UserEmails"
                className="select-input"
                value={(UserEmails && UserEmails.UserEmailValue) || ""}
                placeholder={labels.presentEmailPlaceholder}
                onChange={(selectedOption) =>
                  onFormSelectChange(
                    "UserEmails",
                    "UserEmailValue",
                    selectedOption
                  )
                }
                options={userEmails}
                clearable={false}
              />
            </div>

            <div className="form-row">
              <label className="form-label">{labels.WEB_URL_LABEL}:</label>
              <Input
                name="WebUrl"
                className={`input-text ${invalidWebUrl ? "inValid" : ""}`}
                value={WebUrl ? WebUrl : ""}
                placeholder={labels.presentURLPlaceholder}
                onChange={onFormFieldChange}
                onBlur={onFormFieldBlur}
                testId="input-text-present-weburl"
                maxlength={"100"}
              />
            </div>

            <textarea
              name="Detail"
              className="textarea-field"
              value={Detail ? Detail : ""}
              placeholder={labels.presentDescriptionPlaceholder}
              onChange={onFormFieldChange}
              test-data-id="input-textarea-present-detail"
            />
            <div className="form-row">
              <div className="defaultPresntCheck">
                <label className="defaultPresentCheckboxLabel">
                  <Input
                    type="checkbox"
                    name="IsDefault"
                    className={IsDefault ? "checked" : "input-checkbox"}
                    value={IsDefault}
                    checked={IsDefault ? IsDefault : false}
                    onChange={onFormFieldChange}
                    test-data-id="input-checkbox-company-defaultCompany"
                    disabled={IsChecked}
                  />
                  {labels.PresentationCheckboxMakeDefault}
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(PresentFormEditor);
