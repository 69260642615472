import React from "react";
import EditProfilePopUp from "../popups/EditProfilePopUp/EditProfilePopUp";
import { Avatar, Typography } from "antd";
import Profile from "./Profile";
const { Title, Text } = Typography;
function ProfileWidget({
  labels,
  userFormDetail,
  socialLinks,
  handleSocialMediaActive,
  handleAvatarChange,
  errorMessage,
  handleSocialMediaChange,
  isLoading,
  handleUserForm,
  SaveUserDetails,
  userDetails,
  ...props
}) {
  const {
    CountryName,
    UserFirstname,
    UserLastname,
    ProfilePicture,
    UserEmail,
    UserPhonenumberValue,
  } = userDetails;

  return (
    <div className="initial-info">
      <Avatar
        className={"userAvatar"}
        size={120}
        src={ProfilePicture || "assets/images/noImage.png"}
        alt = {labels.NOT_AVAILABLE_LABEL}
      />
      <div className="user-info">
        <Title className="mb-0" level={4}>
          {UserFirstname
            ? `${UserFirstname} ${UserLastname || ""}`
            : labels.NOT_AVAILABLE_LABEL}
        </Title>
        <div className="mt-2">
          <img width={12} src="assets/images/location.svg" />{" "}
          <Text>{CountryName || labels.NOT_AVAILABLE_LABEL} </Text>
        </div>
        <div>
          <img width={12} src="assets/images/message.svg" />{" "}
          <Text>{UserEmail || labels.NOT_AVAILABLE_LABEL}</Text>
        </div>
        <div>
          <img width={12} src="assets/images/phone.svg" />{" "}
          <Text>{UserPhonenumberValue || labels.NOT_AVAILABLE_LABEL}</Text>
        </div>
      </div>
      {userFormDetail.isAvailable && (
        <EditProfilePopUp
          userFormDetail={userFormDetail}
          socialLinks={socialLinks}
          handleSocialMediaActive={handleSocialMediaActive}
          isLoading={isLoading}
          SaveUserDetails={SaveUserDetails}
          handleUserForm={handleUserForm}
          onSocialMediaChange={handleSocialMediaChange}
          errorMessage={errorMessage}
          labels={labels}
          onAvatarChange={handleAvatarChange}
        />
      )}
    </div>
  );
}

export default ProfileWidget;
