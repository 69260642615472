import React, { Component } from "react";
import { Panel, ListGroup, Input, Select } from "../../../../common";
import AsyncSelect from "react-select/lib/Async";
import { map, isEmpty, filter } from "lodash";
import { getIndustriesApi } from "../resumeEditApi";

class Industry extends Component {
  IndustryProficiencyList = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Proficient" },
    { value: 3, label: "Expert" },
  ];

  handleIndustriesLoad = (searchKey, callback) => {
    if (isEmpty(searchKey)) {
      const { industryDefaultOptions } = this.props;
      callback(null, {
        options: map(industryDefaultOptions, (Industry) => {
          return {
            ...Industry,
            label: Industry.IndustryValue,
            value: Industry.IndustryId,
          };
        }),
      });
      return;
    }
    if (searchKey.length < 3) {
      const { industryDefaultOptions } = this.props;
      const filteredIndustries = filter(industryDefaultOptions, (item) => {
        if (item.IndustryValue.indexOf(searchKey) > -1) return item;
      });
      callback(null, {
        options: map(filteredIndustries, (Industry) => {
          return {
            ...Industry,
            label: Industry.IndustryValue,
            value: Industry.IndustryId,
          };
        }),
      });
      return;
    }
    getIndustriesApi({ searchKey }).then((response) => {
      if (response.success) {
        callback(null, {
          options: map(response.items, (Industry) => {
            return {
              ...Industry,
              label: Industry.IndustryValue,
              value: Industry.IndustryId,
            };
          }),
        });
      }
    });
  };

  render() {
    const {
      isHelpActive,
      labels,
      industriesList,
      onIndustryNameChange,
      onIndustryLevelChange,
      onIndustryDelete,
      onIndustryAdd,
      onIndustryBlur,
    } = this.props;
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>{labels.RESUME_EDIT_INDUSTRY_SECTION_TITLE}</Panel.Title>
          <Panel.Add
            onClick={onIndustryAdd}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={labels.RESUME_EDIT_INDUSTRY_ADD_BUTTON_TOOLTIP}
            tooltipHelp={labels.RESUME_EDIT_INDUSTRY_ADD_BUTTON_HELP}
            testId="resume-edit-industry-add-btn"
            testIdHelp="resume-edit-industry-add-help-btn"
          />
        </Panel.Heading>
        <Panel.Body>
          <ListGroup>
            {industriesList.map((item) => (
              <ListGroup.Item key={item.uniqueId}>
                <ListGroup.Column>
                  <AsyncSelect
                    className={`select-industry-name select-input ${
                      item.isInvalid ? "inValid" : ""
                    }`}
                    placeholder={labels.RESUME_EDIT_INDUSTRY_NAME_PLACEHOLDER}
                    cacheOptions
                    defaultOptions
                    searchable={true}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    loadOptions={this.handleIndustriesLoad}
                    onChange={(options) =>
                      onIndustryNameChange(options, item.uniqueId)
                    }
                    value={{
                      value: item.IndustryId,
                      label: item.IndustryValue,
                    }}
                    onBlur={(options) =>
                      onIndustryBlur(item.IndustryId, item.uniqueId, options)
                    }
                    disabled={item.isCandidate}
                  />
                </ListGroup.Column>
                <ListGroup.Column>
                  <Select
                    name={"Industry Level"}
                    className={`select-industry-level select-input`}
                    value={item.ExperienceLevel}
                    onChange={(options) =>
                      onIndustryLevelChange(options, item.uniqueId)
                    }
                    options={this.IndustryProficiencyList}
                  />
                </ListGroup.Column>
                <ListGroup.Column size={0}>
                  <div
                    // disabled={isDisabled}
                    onClick={() => onIndustryDelete(item)}
                    className="close_btn_purple"
                  >
                    <i className="fa fa-times-circle" />
                  </div>
                </ListGroup.Column>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Panel.Body>
      </Panel>
    );
  }
}

export default Industry;
