import React, { Component } from "react";
import { connect } from "react-redux";
import "./google-signup.scss";
import { GoogleLogin } from "react-google-login";
import {
  getAllCountriesApi,
  getSignUpConfigsApi,
  signUpApi,
} from "../../leadPageApi";
import { loginAuth } from "../Auth/loginAction";
import { notificationAction } from "../../../../actions";
import { privateRoutes } from "../../../../Routes/routing";
import { withRouter } from "react-router-dom";
import Select from "react-select/lib/Select";
import Terms from "./Terms";

class GoogleSignup extends Component {
  state = {
    showGoogleLogin: false,
    profileObj: {},
    UsageTypeList: [],
    CountriesList: [],
    isAgreed: false,
    isTerms: false,
    showSignupPop: false,
  };
  getAllCountries = () => {
    getAllCountriesApi().then((response) => {
      if (response.success) {
        const CountriesList = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        this.setState({ CountriesList });
        this.handleFormSelectChange("Country", CountriesList[0]);
      }
    });
  };
  componentDidMount = () => {
    const UsageTypeList = [
      { value: "1", label: this.props.labels.SIGNUP_IPRO_LABEL },
      { value: "2", label: this.props.labels.SIGNUP_SEARCHER_LABEL },
    ];
    this.setState({
      UsageTypeList,
    });
    this.handleFormSelectChange("Usage", UsageTypeList[0]);
  };

  loginToCore(
    loginAuth,
    email,
    password,
    location,
    history,
    isFirstTime = true
  ) {
    const loginModel = {
      email,
      password,
    };
    loginAuth(loginModel).then((response) => {
      if (response.success) {
        const { state } = location;
        if (state && state.from && state.from.pathname) {
          history.push(state.from.pathname);
          return;
        }
        history.push(privateRoutes.dashboard.path);
      } else {
        const isNormalUser =
          (response.message ===
            this.props.labels["LOGIN_INVALID_EMAIL_PASSWORD"] &&
            !response.items?.IsEmailAvailable) ||
          response.message ===
          this.props.labels["USER_EMAIL_NOT_VERIFIED_INFO"];
        if (isNormalUser) {
          const info = {
            message: `${email} already registered please login with your password`,
            status: "error",
          };
          this.props.notificationAction(info);
          this.props.onHideMainForm(false, email);
          this.setState({
            isLoading: false,
          });
          return;
        }

        this.setState({
          showSignupPop: isFirstTime && !isNormalUser,
        });
        this.getAllCountries();
        getSignUpConfigsApi().then((response) => {
          this.setState({
            Configs: response.items,
            isLoading: false,
          });
        });
        this.props.onHideMainForm(true);
      }
    });
  }

  responseGoogleSuccess = (response) => {
    const { loginAuth, location, history } = this.props;


    this.setState({
      isLoading: true,
    });

    const { profileObj } = response;
    this.setState({
      profileObj,
      isLoading: true,
    });

    this.loginToCore(
      loginAuth,
      profileObj.email,
      profileObj.googleId,
      location,
      history
    );
  };
  responseGoogleFailure = (response) => {
  };
  signup = () => {
    this.setState({
      isLoading: true,
    });
    const { profileObj, Usage } = this.state;
    const user = {
      CountryId: this.state.Country,
      Email: profileObj.email,
      FirstName: profileObj.givenName,
      LastName: profileObj.familyName,
      IsFreelancer: this.state.Usage == 1,
      Password: profileObj.googleId,
      RegistrationTypeId: 3,
    };
    signUpApi(user)
      .then((response) => {
        if (response.success) {
          const { loginAuth, location, history } = this.props;
          this.loginToCore(
            loginAuth,
            profileObj.email,
            profileObj.googleId,
            location,
            history,
            false
          );
        } else {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
        this.setState({
          isLoading: false,
          showResendPopUp: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  };
  handleFormSelectChange = (name, selectedOption) => {
    if (!selectedOption) return;
    const { value } = selectedOption;
    this.setState({
      [name]: value,
      [`invalid${name}`]: false,
    });
  };

  handleTermsClick = (type) => {
    let selectedTerm;
    const { Configs } = this.state;
    switch (type) {
      case 1: {
        selectedTerm = Configs.find(
          (item) => item.ConfigName === "Terms and Condition"
        );
        break;
      }
      case 2: {
        selectedTerm = Configs.find(
          (item) => item.ConfigName === "Privacy policy"
        );
        break;
      }
      case 3: {
        selectedTerm = Configs.find(
          (item) => item.ConfigName === "Cookie agreement"
        );
        break;
      }
    }
    this.setState({
      selectedTerm: {
        heading: selectedTerm.ConfigName,
        content: selectedTerm.ConfigValue,
      },
      isTerms: true,
    });
  };

  render() {
    const {
      UsageTypeList,
      Usage,
      invalidUsage,
      CountriesList,
      Country,
      invalidCountry,
      isAgreed,
      isTerms,
      selectedTerm,
      showSignupPop,
      isLoading,
    } = this.state;
    const { labels, onClose, onHideMainForm } = this.props;
    return isTerms ? (
      <Terms
        onClose={() => this.setState({ isTerms: false })}
        content={selectedTerm.content}
        heading={selectedTerm.heading}
      />
    ) : (
      <React.Fragment>
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        <div className={`google-login-wrap ${showSignupPop ? "signup" : ""}`}>
          <button onClick={onClose} className="close-btn" />
          {!showSignupPop && (
            <div className="google-login-button-wrapper">
              <GoogleLogin
                clientId="494163750284-n71immnp2m5tjm6fu08eeg3al5h2fcl9.apps.googleusercontent.com"
                buttonText="Login with google to continue"
                onSuccess={this.responseGoogleSuccess}
                onFailure={this.responseGoogleFailure}
                cookiePolicy={"single_host_origin"}
              />
            </div>
          )}
          {showSignupPop && (
            <div className="signup-popup">
              <Select
                name="Usage"
                test-data-id="register-user-type"
                className="select-input"
                placeholder="Choose preffered usage"
                className={`select-input select-usage ${invalidUsage ? "inValid" : ""
                  }`}
                value={Usage || ""}
                onChange={(selectedOption) =>
                  this.handleFormSelectChange("Usage", selectedOption)
                }
                options={UsageTypeList}
                clearable={false}
                tabIndex={5}
              />
              <Select
                name="DurationType"
                className="select-input"
                test-data-id="register-user-country"
                placeholder="Choose a country"
                className={`select-input select-durationType ${invalidCountry ? "inValid" : ""
                  }`}
                value={Country || ""}
                onChange={(selectedOption) =>
                  this.handleFormSelectChange("Country", selectedOption)
                }
                options={CountriesList}
                clearable={false}
                tabIndex={6}
              />
              {/* <input
              type="checkbox"
              name="isAgreed"
              className={isAgreed ? "checked" : "input-checkbox"}
              value={isAgreed}
              checked={isAgreed ? isAgreed : false}
              onChange={this.handleAgreeToggle}
              test-data-id="register-policy"
              tabIndex={7}
            />
            <label className="signup-agreement">
              {labels.AGREEMENT_LABEL}{" "}
              <a onClick={() => this.handleTermsClick(1)}>
                {labels.TERM_OF_USE_LABEL}
              </a>
              ,{" "}
              <a onClick={() => this.handleTermsClick(2)}>
                {labels.PRIVACY_POLICY_LABEL}{" "}
              </a>
              {"and "}
              <a onClick={() => this.handleTermsClick(3)}>
                {labels.COOKIE_AGREEMENT_LABEL}
              </a>
            </label> */}
              <div className="LoginBtn">
                <input
                  test-data-id="register-submit"
                  type="button"
                  value="Sign Up"
                  className="loginsubmitgoogle"
                  onClick={this.signup}
                  tabIndex={8}
                />
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
const mapActionToProps = { loginAuth, notificationAction };
export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(GoogleSignup));
