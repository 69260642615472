import { ActionTypes } from "../actions";
import moment from "moment";
import { EmptySearchList } from "../utilities";
const INITIAL_STATE = {
  shortlist: [],
  selectedResume: {},
  selectedShortlist: {},
  shortlistResumes: [],
  isLoading: false,
  fetchingResumes: false,
  opportunityList: [],
  fetchingOpportunities: false,
  searchList: [],
  selectedSearchTab: "role",
  availibility: moment(),
  savedSearchesList: [],
  dialogSaveSearchMessage: "",
  newSaveSearchValue: "",
  dialogMessage: "",
  deletedId: null,
  dialogOpportunityMessage: "",
  dialogShortlistMessage: "",
  showResumeList: true,
  createNewSaveSearch: false,
  showExistingSaveSearches: false,
  showOpportunities: false,
  showExistingShortlists: false,
  createNewShortlist: false,
  showResumeDetail: false,
  newShortlistValue: "",
  searchCollapsed: false,
  saveSearchCollapse: false,
  resumeCollapsed: false,
  resumeDetailCollapsed: false,
  opportunitiesCollapse: false,
  shortlistCollapse: false,
  createCollapse: false,
  ///search list
  searchList_rolesList: [],
  searchList_rolesFetching: false,
  searchList_skillsList: [],
  searchList_skillsFetching: false,
  searchList_keywordsList: [],
  searchList_keywordsFetching: false,
  searchList_industriesList: [],
  searchList_industriesFetching: false,
  searchList_certificationsList: [],
  searchList_certificationsFetching: false,
  searchList_countriesList: [],
  searchList_countriesFetching: true,
  searchList_languagesList: [],
  searchList_languagesFetching: false,
  searchList_searchList: EmptySearchList,
  searchList_availibility: {
    // value: 1,
    // label: this.props.labels.RESUME_SEARCH_AVAILABILITY_TODAY_LABEL,
    // date: moment().format("MM/DD/YYYY")
  },
  searchList_dialogMessage: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH.SHORTLIST:
      return {
        ...state,
        shortlist: action.payload
      };
    case ActionTypes.SEARCH.SELECTED_RESUME:
      return {
        ...state,
        selectedResume: action.payload
      };
    case ActionTypes.SEARCH.SELECTED_SHORTLIST:
      return {
        ...state,
        selectedShortlist: action.payload
      };
    case ActionTypes.SEARCH.SHORTLIST_RESUMES:
      return {
        ...state,
        shortlistResumes: action.payload
      };
    case ActionTypes.SEARCH.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case ActionTypes.SEARCH.FETCHING_RESUMES:
      return {
        ...state,
        fetchingResumes: action.payload
      };
    case ActionTypes.SEARCH.OPPORTUNITY_LIST:
      return {
        ...state,
        opportunityList: action.payload
      };
    case ActionTypes.SEARCH.FETCHING_OPPORTUNITIES:
      return {
        ...state,
        fetchingOpportunities: action.payload
      };
    case ActionTypes.SEARCH.SEARCH_LIST:
      return {
        ...state,
        searchList: action.payload
      };
    case ActionTypes.SEARCH.SELECTED_SEARCH_TAB:
      return {
        ...state,
        selectedSearchTab: action.payload
      };
    case ActionTypes.SEARCH.AVAILIBILITY:
      return {
        ...state,
        availibility: action.payload
      };
    case ActionTypes.SEARCH.SAVED_SEARCHES_LIST:
      return {
        ...state,
        savedSearchesList: action.payload
      };
    case ActionTypes.SEARCH.DIALOG_SAVE_SEARCH_MESSAGE:
      return {
        ...state,
        dialogSaveSearchMessage: action.payload
      };
    case ActionTypes.SEARCH.NEW_SAVE_SEARCH_VALUE:
      return {
        ...state,
        newSaveSearchValue: action.payload
      };
    case ActionTypes.SEARCH.DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: action.payload
      };
    case ActionTypes.SEARCH.DELETED_ID:
      return {
        ...state,
        deletedId: action.payload
      };
    case ActionTypes.SEARCH.DIALOG_OPPORTUNITY_MESSAGE:
      return {
        ...state,
        dialogOpportunityMessage: action.payload
      };
    case ActionTypes.SEARCH.DIALOG_SHORTLIST_MESSAGE:
      return {
        ...state,
        dialogShortlistMessage: action.payload
      };
    case ActionTypes.SEARCH.SHOW_RESUME_LIST:
      return {
        ...state,
        showResumeList: action.payload
      };
    case ActionTypes.SEARCH.CREATE_NEW_SAVE_SEARCH:
      return {
        ...state,
        createNewSaveSearch: action.payload
      };
    case ActionTypes.SEARCH.SHOW_EXISTING_SAVE_SEARCHES:
      return {
        ...state,
        showExistingSaveSearches: action.payload
      };
    case ActionTypes.SEARCH.SHOW_OPPORTUNITIES:
      return {
        ...state,
        showOpportunities: action.payload
      };
    case ActionTypes.SEARCH.SHOW_EXISTING_SHORTLISTS:
      return {
        ...state,
        showExistingShortlists: action.payload
      };
    case ActionTypes.SEARCH.CREATE_NEW_SHORTLIST:
      return {
        ...state,
        createNewShortlist: action.payload
      };
    case ActionTypes.SEARCH.SHOW_RESUME_DETAIL:
      return {
        ...state,
        showResumeDetail: action.payload
      };
    case ActionTypes.SEARCH.NEW_SHORTLIST_VALUE:
      return {
        ...state,
        newShortlistValue: action.payload
      };
    case ActionTypes.SEARCH.SEARCH_COLLAPSED:
      return { ...state, searchCollapsed: action.payload };
    case ActionTypes.SEARCH.SAVE_SEARCH_COLLAPSE:
      return { ...state, saveSearchCollapse: action.payload };
    case ActionTypes.SEARCH.RESUME_COLLAPSED:
      return { ...state, resumeCollapsed: action.payload };
    case ActionTypes.SEARCH.RESUME_DETAIL_COLLAPSED:
      return { ...state, resumeDetailCollapsed: action.payload };
    case ActionTypes.SEARCH.OPPORTUNITIES_COLLAPSE:
      return { ...state, opportunitiesCollapse: action.payload };
    case ActionTypes.SEARCH.SHORTLIST_COLLAPSE:
      return { ...state, shortlistCollapse: action.payload };
    case ActionTypes.SEARCH.CREATE_COLLAPSE:
      return { ...state, createCollapse: action.payload };

    //search list component
    case ActionTypes.SEARCH.SEARCH_LIST_ROLES:
      return { ...state, searchList_rolesList: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_ROLES_FETCHING:
      return { ...state, searchList_rolesFetching: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_SKILLS:
      return { ...state, searchList_skillsList: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_SKILLS_FETCHING:
      return { ...state, searchList_skillsFetching: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_KEYWORDS:
      return { ...state, searchList_keywordsList: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_KEYWORDS_FETCHING:
      return { ...state, searchList_keywordsFetching: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_INDUSTRIES:
      return { ...state, searchList_industriesList: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_INDUSTRIES_FETCHING:
      return { ...state, searchList_industriesFetching: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_CERTIFICATIONS:
      return { ...state, searchList_certificationsList: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_CERTIFICATIONS_FETCHING:
      return { ...state, searchList_certificationsFetching: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_COUNTRIES:
      return { ...state, searchList_countriesList: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_COUNTRIES_FETCHING:
      return { ...state, searchList_countriesFetching: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_LANGUAGES:
      return { ...state, searchList_languagesList: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_LANGUAGES_FETCHING:
      return { ...state, searchList_languagesFetching: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_SEARCH_LIST:
      return { ...state, searchList_searchList: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_AVAILIBILITY:
      return { ...state, searchList_availibility: action.payload };
    case ActionTypes.SEARCH.SEARCH_LIST_DIALOG_MESSAGE:
      return { ...state, searchList_dialogMessage: action.payload };

    default:
      return state;
  }
};
export const searchWrapperReducer = (reducerFunction, reducerName) => {
  return (state, action) => {
    const { actionName } = action;
    const isInitializationCall = state === undefined;
    if (actionName !== reducerName && !isInitializationCall) return state;

    return reducerFunction(state, action);
  };
};
