import React, { Component } from "react";
import { connect } from "react-redux";
import "./presentaionView.scss";
import { LoadingMask, Button } from "../../../../../common";
import { notificationAction } from "./../../../../../actions";
import ImgSrc from "../../../../../assets/images/company.svg";

import {
  getAllPresenationApi,
  acceptOpportunityApi,
} from "./../opportunityApi";
import { Col, Row, Select } from "antd";

class PresentationDetailsNew extends Component {
  state = {
    selectedOption: null,
    allpresentation: [],
    selectedPresentation: {},
    selectedUserDetail: null,
    isLoading: false,
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getAllPresentation();
  }

  getAllPresentation = () => {
    getAllPresenationApi().then((data) => {
      if (data.success) {
        const allpresentation = data.items.map((item) => ({
          ...item,
          Logo: item.Logo,
          value: item.UserDetailId,
          label: item.Title,
        }));

        this.setState({
          allpresentation,
          selectedPresentation: allpresentation[0],
        });
        this.setState({ isLoading: false });
      }
    });
  };
  renderSocialMedia = (links) => {
    var slinks = this.state.selectedPresentation.SocialLinks;
    var list = [];
    for (var i = 0; i < slinks.length; i++) {
      var name = this.props.socialMedia.find(
        (e) => e.Id === slinks[i].SocialMediaId
      );
      list.push(
        <label
          className={`social-links ${name.Name.toLowerCase()}`}
          key={slinks[i].SocialMediaId}
        >
          <a href={slinks.URL} target="_blank" key={slinks[i].SocialMediaId}>
            {name.Name}
          </a>
          <br />
        </label>
      );
    }
    return list;
  };

  handPresentSelectChange = (selectedOption) => {
    this.setState({ selectedOption, selectedPresentation: selectedOption });
  };

  handleSubmitPresentDetail = (data) => {
    this.setState({ userDetail: data });
    const { selectedOpportunityNew, userDetail } = this.state;
    const obj = new Object();
    obj.RequestId = this.props.selectedOpportunityNew.RequestId;
    obj.UserDetailId = this.state.selectedPresentation.UserDetailId;
    //obj.CoverLetter = this.state.selectedPresentation.Detail;
    obj.ResumeId = 0;
    //obj.RequestName = this.props.selectedOpportunityNew.RequestName;

    this.acceptOpportunity(obj);
  };

  acceptOpportunity = (info) => {
    acceptOpportunityApi(info).then((data) => {
      if (data.success) {
        const info = {
          message: this.props.labels.iproOppNewAcceptMessage,
          status: "success",
        };
        this.props.notificationAction(info);
        this.props.getAllOpportunity();
        this.props.handleOk();
      }
    });
  };

  render() {
    const {
      labels,
      selectedOpportunityNew,
      showCompanyDetails,
      showPresentationDetail,
      loading,
      socialMedia,
      getAllOpportunity,
    } = this.props;
    const {
      selectedOption,
      allpresentation,
      selectedPresentation,
    } = this.state;
    return (
      <div className="new-presentation-detail-component">
        {loading && <LoadingMask />}
        <div className="inner-company">
          <Select
            size="large"
            showArrow
            className="bg-color-for-opportunity"
            bordered={false}
            style={{ width: "100%" }}
            value={selectedPresentation && selectedPresentation}
            placeholder={labels.presentPrimaryRolePlaceholder}
            onChange={(e, selectedOption) =>
              this.handPresentSelectChange(selectedOption)
            }
            options={allpresentation}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.props?.label
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0
            }
          ></Select>

          <div className="MsgWrapper">
            <div className="image-user">
              {selectedPresentation && selectedPresentation.Logo && (
                <img
                  src={selectedPresentation.Logo || ImgSrc}
                  className={
                    selectedPresentation.Logo ? "roundImg" : "emptyRoundImag"
                  }
                />
              )}
            </div>
            <p className="user-title">{selectedPresentation.Title}</p>

            {selectedPresentation.UserName && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="100px">
                  <p className="user-detail">
                    {" "}
                    {this.props.labels.IproOpportunityNewUserNameLabel} :
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="user-detail">{selectedPresentation.UserName}</p>
                </Col>
              </Row>
            )}
            {selectedPresentation.Profiles && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="100px">
                  <p className="user-detail">
                    {this.props.labels.IproOpportunityNewUserPositionLabel} :
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="user-detail">
                    {selectedPresentation.Profiles &&
                      selectedPresentation.Profiles.ProfileValue}
                  </p>
                </Col>
              </Row>
            )}
            {selectedPresentation.UserEmails && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="100px">
                  <p className="user-detail">
                    {this.props.labels.IproOpportunityNewUserEmailLabel} :
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="user-detail">
                    {selectedPresentation.UserEmails &&
                      selectedPresentation.UserEmails.UserEmailValue}
                  </p>
                </Col>
              </Row>
            )}
            {selectedPresentation.UserPhonenumbers && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="100px">
                  <p className="user-detail">
                    {this.props.labels.IproOpportunityNewUserPhoneLabel} :
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="user-detail">
                    {selectedPresentation.UserPhonenumbers &&
                      selectedPresentation.UserPhonenumbers
                        .UserPhonenumberValue}
                  </p>
                </Col>
              </Row>
            )}
            {selectedPresentation.WebUrl && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="100px">
                  <p className="user-detail">
                    {this.props.labels.IproOpportunityNewUserWebURL} :
                  </p>
                </Col>
                <Col flex="auto">
                  <p className="user-detail">
                    <a target="_blank" href={selectedPresentation.WebUrl}>
                      {selectedPresentation.WebUrl}
                    </a>
                  </p>
                </Col>
              </Row>
            )}
            {selectedPresentation.Detail && (
              <Row
                gutter={[8, 12]}
                style={{ marginTop: "10px", flexFlow: "unset" }}
              >
                <Col flex="auto">
                  <p className="user-detail">{selectedPresentation.Detail}</p>
                </Col>
              </Row>
            )}
            {selectedPresentation.SocialLinks && (
              <div className="MsgDetailWrap">
                {selectedPresentation.Detail && (
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="auto">
                      <p className="user-detail">
                        {this.renderSocialMedia(
                          selectedPresentation.SocialLinks
                        )}{" "}
                      </p>
                    </Col>
                  </Row>
                )}
              </div>
            )}
            <div className="btn-footer">
              <button
                className="btn-delete clr-purple "
                onClick={this.handleSubmitPresentDetail}
              >
                {"Send"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps, { notificationAction })(
  PresentationDetailsNew
);
