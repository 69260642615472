import React, { Component } from "react";
import { EmptyInfo } from "../../../common";
import { connect } from "react-redux";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { Messages } from "../../../utilities";
import "./feedbackdetail.scss";
import ReactQuill from "react-quill";

class FeedbackDetail extends Component {
  renderDetailDate = (date) => {
    return moment(date).format("ddd MMM DD YYYY H:mm:ss");
  };
  render() {
    const toolbarOptions = [];
    const { labels, selectedFeedback } = this.props;
    return (
      <div className="feedback-detail-component">
        {!selectedFeedback.FeedbackId ? (
          <EmptyInfo>{labels.SplFeedback}</EmptyInfo>
        ) : (
          <div className="MsgWrapper">
            <div className="titleLabel">{selectedFeedback.Title}</div>
            <div className="feedbackWrap">
              <div className="msgItemDiv">
                <div className="msgItem">
                  <label className="labelName">
                    {Messages.feedback.labels.detail}
                  </label>
                  <label className="answerLabel">
                    <ReactQuill
                      className={`rich-text-editor`}
                      value={selectedFeedback.user.Content || ""}
                      readOnly={true}
                      modules={{
                        toolbar: toolbarOptions,
                      }}
                    />
                  </label>
                </div>

                <div className="msgItem">
                  <label className="labelName">
                    {labels.searcherFeedbackTime}
                  </label>
                  <label className="answerLabel">
                    {this.renderDetailDate(selectedFeedback.user.DeteTime)}
                  </label>
                </div>
                {selectedFeedback.user.Img && (
                  <div className="msgItem">
                    <a href={selectedFeedback.user.Img} target="_blank">
                      <img
                        className="feedbackImage"
                        src={selectedFeedback.user.Img}
                        alt="img"
                      />
                    </a>
                  </div>
                )}
              </div>
              {selectedFeedback.admin &&
                selectedFeedback.admin.map((item) => (
                  <div className="msgItemDiv">
                    <div className="msgItem">
                      <label className="labelName">
                        {labels.searcherFeedbackAdminReply}
                      </label>
                      <label className="answerLabel">
                        {ReactHtmlParser(item.Content)}
                      </label>
                    </div>
                    <div className="msgItem">
                      <label className="labelName">
                        {labels.searcherFeedbackReplyTime}
                      </label>
                      <label className="answerLabel">
                        {this.renderDetailDate(item.DeteTime)}
                      </label>
                    </div>
                    {item.Img && (
                      <div className="msgItem">
                        <a
                          href={`${selectedFeedback.admin.Img}`}
                          target="_blank"
                        >
                          <img
                            className="feedbackImage "
                            src={item.Img}
                            alt="img"
                          />
                        </a>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(FeedbackDetail);
