import React from "react";

const SearchInput = ({
  placeholder,
  onChange,
  onFocus,
  className,
  onBlur,
  testId,
  value
}) => (
  <input
    className={className || "round-search"}
    type="text"
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onBlur}
    test-data-id={testId}
  />
);

export default SearchInput;
