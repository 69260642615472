import React, { useEffect, useState } from "react";
import uuidv1 from "uuid/v1";
import {
  addachievementApi,
  resumeItemDeleteApi,
  getAllAcheivementsApi,
  AddResumeAchivenment,
  getResumeAchivenmentsApi,
} from "../../../Resume/ResumeEdit/resumeEditApi";
import { RichTextEditor } from "../../../../components";
import Trash from "../../../../assets/newResume/trash";
import Edit from "../../../../assets/newResume/edit";
import classes from "./others.module.scss";
import EmptyResume from "../../../../common/emptyMessage/emptyResume";
import { Col, Divider, Popover, Row } from "antd";
import MenuDots from "../../../../assets/newResume/menuDots";
import DetailIcon from "../../../../assets/images/svg/detail.svg";
import { ConfirmDialog, Select } from "../../../../common";
import AchievementDetails from "./components/achievementDetails";
import { notificationAction } from "../../../../actions";
import { useDispatch } from "react-redux";

const Others = ({ labels, onStateChangeAction, userDetails }) => {
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [detail, setDetail] = useState({
    show: false,
  });
  const [deleteResume, setDeleteResume] = useState({
    show: false,
  });
  const [yearsList, setYearsList] = useState([]);
  const [acheivementData, setAcheivementData] = useState({
    ResumeId: "",
    AchivenmentId: "",
    AchivenmentName: "",
    ResumeOtherAchivenmentId: "",
    Title: "",
    Description: "",
    Year: "",
    isWorking: false,
  });
  const [allAchievementsList, setAllAchievements] = useState([]);
  const [newachievement, setNewachievement] = useState({
    uniqueId: uuidv1(),
    CompanyWorked: "",
    IndustryId: "",
    IndustryValue: "",
    ProfileId: "",
    ProfileValue: "",
    StartDate: "",
    EndDate: "",
    isWorking: false,
    Description: "",
  });
  const [resumeCurentItemList, setResumeCurentItemList] = useState([]);
  const [resumeAchievemetnsList, setResumeAchievemetnsList] = useState([]);

  useEffect(() => {
    handleYearsLoad();
    getResumeAchivenments();
    getAllAchievements();
  }, []);

  const getAllAchievements = () => {
    onStateChangeAction({ isLoading: true });

    getAllAcheivementsApi()
      .then((data) => {
        if (data.success) {
          setAllAchievements(
            data.items.map((i) => {
              return {
                ...i,
                uniqueId: uuidv1(),
                value: i.AchivenmentId,
                label: i.AchivenmentName,
              };
            })
          );
          onStateChangeAction({ isLoading: false });
        }
      })
      .catch(() => {});
  };

  const getResumeAchivenments = () => {
    onStateChangeAction({ isLoading: true });

    getResumeAchivenmentsApi()
      .then((data) => {
        if (data.success) {
          setResumeAchievemetnsList(
            data.items.map((i) => {
              return { ...i, uniqueId: uuidv1() };
            })
          );
          onStateChangeAction({ isLoading: false });
        }
      })
      .catch(() => {});
  };

  const handleYearsLoad = () => {
    let currentYear = new Date().getFullYear();
    let startYear = 1970;
    const yrsList = [];
    while (currentYear >= startYear) {
      const newYear = {
        value: currentYear,
        label: currentYear,
      };
      yrsList.push(newYear);

      currentYear--;
    }
    setYearsList(yrsList);
  };

  const handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    setAcheivementData({
      ...acheivementData,
      [name]: value,
    });
    return;
  };

  const onFormSelectChange = (name, selectedOption) => {
    if (!selectedOption) return;
    setAcheivementData({
      ...acheivementData,
      [name]: selectedOption.value,
    });
  };

  const deleteAchievement = (achvmnt) => {
    onStateChangeAction({ isLoading: true });
    resumeItemDeleteApi({
      LookupTypeId: 10,
      ResumeId: userDetails.ResumeId,
      ObjectPK: achvmnt.ResumeOtherAchivenmentId,
    })
      .then((res) => {
        if (res.success) {
          var updatedAchievementList =resumeAchievemetnsList.filter(itm=>itm.uniqueId !== achvmnt.uniqueId);
          setResumeAchievemetnsList(
            updatedAchievementList
          );
          
          onStateChangeAction({ isLoading: false });
        } else {
          onStateChangeAction({ isLoading: false });
          dispatch(
            notificationAction({
              status: "error",
              message:
                "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
            })
          );
        }
      })
      .catch(() => {
        onStateChangeAction({ isLoading: false });
        dispatch(
          notificationAction({
            status: "error",
            message:
              "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
          })
        );
      });
      setDeleteResume((st) => ({
        ...st,
        show: false,
        message: "",
        item: "",
      }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (edit) {
      onStateChangeAction({ isLoading: true });
      AddResumeAchivenment(acheivementData)
        .then((data) => {
          if (data.success) {
            getResumeAchivenments();
            dispatch(
              notificationAction({
                status: "success",
                message: "Resume achievement updated successfully!",
              })
            );
          } else {
            getResumeAchivenments();
            dispatch(
              notificationAction({
                status: "error",
                message:
                  "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
              })
            );
          }
        })
        .catch(() => {
          getResumeAchivenments();
          dispatch(
            notificationAction({
              status: "error",
              message:
                "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
            })
          );
        });
    } else {
      onStateChangeAction({ isLoading: true });
      AddResumeAchivenment(acheivementData)
        .then((data) => {
          if (data.success) {
            onStateChangeAction({ isLoading: false });
            getResumeAchivenments();
          } else {
            getResumeAchivenments();
            dispatch(
              notificationAction({
                status: "error",
                message:
                  "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            notificationAction({
              status: "error",
              message:
                "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
            })
          );
        });
    }

    setAcheivementData({
      ResumeId: "",
    AchivenmentId: "",
    AchivenmentName: "",
    ResumeOtherAchivenmentId: "",
    Title: "",
    Description: "",
    Year: "",
    isWorking: false,
    });
    setAdd(false);
    setEdit(false);
  };

  const content = (item) => {
    return (
      <div className={classes.dropDownachievementContent}>
        <div
          onClick={() => {
            setEdit(true);
            setAcheivementData(item);
            setDetail((st) => ({
              ...st,
              show: false,
            }));
            setOpen("");
          }}
        >
          <p>Edit</p>
          <Edit />
        </div>

        <Divider />
        <div
          onClick={() => {
            setDeleteResume((st) => ({
              ...st,
              show: true,
              message: "Are you sure, you want to delete this?",
              item: item,
            }));
          }}
        >
          <p>Delete</p>
          <Trash />
        </div>
      </div>
    );
  };

  return (
    <Row className={classes.userResume} justify="center">
      <Col xs={24} lg={18} md={22}>
        {!add && !edit && resumeAchievemetnsList?.length > 0 ? (
          <>
            {resumeAchievemetnsList &&
              resumeAchievemetnsList.map((item, index) => (
                <Row
                  gutter={[16, 16]}
                  justify="space-between"
                  className={classes.resumeTitle}
                  align="center"
                  key={index}
                >
                  <Col
                    xs={22}
                    md={22}
                    className={classes.title}
                    onClick={() => {
                      setDetail((st) => ({
                        ...st,
                        show: st.show === index ? false : index,
                        item: item,
                      }));
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={DetailIcon}
                        alt=""
                        style={
                          detail?.show === index
                            ? {
                                transform: "rotate(180deg)",
                              }
                            : {}
                        }
                      />
                      <p
                        className={classes.paragraphResume}
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {`${item?.AchivenmentName}`}
                      </p>
                    </div>
                  </Col>
                  <Col xs={0} md={2}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="pointer"
                        onClick={() => {
                          setEdit(true);
                          setAcheivementData(item);
                          setDetail((st) => ({
                            ...st,
                            show: false,
                          }));
                        }}
                      >
                        <Edit />
                      </div>
                      <div
                        className="pointer"
                        onClick={() => {
                          setDeleteResume((st) => ({
                            ...st,
                            show: true,
                            message: "Are you sure, you want to delete this?",
                            item: item,
                          }));
                        }}
                      >
                        <Trash />
                      </div>
                    </div>
                  </Col>
                  <Col xs={2} md={0} className={classes.dotSection}>
                    <Popover
                      content={content(item)}
                      placement="leftTop"
                      trigger="click"
                      open={open === index}
                      onOpenChange={() => {
                        setOpen(open === index ? "" : index);
                      }}
                    >
                      <MenuDots style={{ cursor: "pointer" }} />
                    </Popover>
                  </Col>
                  {detail?.show === index && (
                    <Col
                      xs={24}
                      style={{
                        background: "#fff",
                        borderRadius: "8px",
                      }}
                    >
                      <AchievementDetails detail={detail} />
                    </Col>
                  )}
                </Row>
              ))}
          </>
        ) : (
          <>
            {!add && !edit && (
              <EmptyResume text={`"No resume achievement added yet"`} />
            )}
          </>
        )}
        {add == false && edit == false && (
          <div className="w-100 d-flex justify-content-center mt-4">
            <button
              className={classes.btn}
              style={{
                marginRight: 0,
              }}
              onClick={() => {
                setAdd(true);
              }}
            >
              Add Achievement
            </button>
          </div>
        )}
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {(add || edit) && (
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <div className={classes.workplace}>
                  <input
                    type="text"
                    placeholder="Please enter other achievement name"
                    name="Title"
                    value={acheivementData.Title}
                    required
                    onChange={handleFormFieldChange}
                  />
                </div>
              </Col>
              <Col xs={24} lg={12} md={12}>
                <div className=" newResumeEduValue newachievementEditSelect">
                  <Select
                    name={"Acheivement Year"}
                    placeholder={"Select Year"}
                    className={`select-location select-input`}
                    value={acheivementData.Year}
                    onChange={(selectedOption) =>
                      onFormSelectChange("Year", selectedOption)
                    }
                    options={yearsList}
                  />
                </div>
              </Col>
              <Col xs={24} lg={12} md={12}>
                <div className=" newResumeEduValue newachievementEditSelect">
                  <Select
                    name={"Acheivement Type"}
                    className={`select-location select-input`}
                    placeholder={"Select Achievement type"}
                    value={acheivementData.AchivenmentId}
                    onChange={(selectedOption) =>
                      onFormSelectChange("AchivenmentId", selectedOption)
                    }
                    options={allAchievementsList}
                  />
                </div>
              </Col>

              <Col xs={24}>
                <div className="achievement-edit-rich-text">
                  <RichTextEditor
                    id="resume-edit-achievement-description"
                    name="Description"
                    value={acheivementData.Description || ""}
                    handleChange={(e) => {
                      setAcheivementData((prev) => ({
                        ...prev,
                        Description: e,
                      }));
                    }}
                    placeholder={
                      labels.RESUME_EDIT_achievement_DESCRIPTION_LABEL
                    }
                  />
                </div>
              </Col>
            </Row>
          )}
          <div className={classes.buttons}>
            {(add || edit) && (
              <>
                <button className={classes.btn} type="submit">
                  SAVE
                </button>
                <button
                  className={classes.discard}
                  onClick={() => {
                    setAcheivementData({
                      ResumeId: "",
                      AchivenmentId: "",
                      AchivenmentName: "",
                      ResumeOtherAchivenmentId: "",
                      Title: "",
                      Description: "",
                      Year: "",
                      isWorking: false,
                    });
                    setAdd(false);
                    setEdit(false);
                  }}
                >
                  DISCARD
                </button>
              </>
            )}
          </div>
        </form>

        {deleteResume?.show && (
          <ConfirmDialog testId="company-confirm-diloag">
            <ConfirmDialog.Message>
              {deleteResume?.message}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-yes"
                onClick={() => {
                  deleteAchievement(deleteResume?.item);
                }}
              >
                Yes
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-no"
                onClick={() => {
                  setDeleteResume((st) => ({
                    ...st,
                    show: false,
                    message: "",
                    item: "",
                  }));
                }}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
      </Col>
    </Row>
  );
};

export default Others;
