import { ActionTypes } from "../actions";

export const onStateChangeActions = ({ show, isLoading }) => (
  dispatch
) => {
  show !== undefined &&
    dispatch({
      type: ActionTypes.phillipSearcherModal.SHOW_MODAL,
      payload: show,
    });
  isLoading !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.IS_LOADING,
      payload: isLoading,
    });
};
