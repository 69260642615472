import React, { Component } from "react";
import { Navigation, Header } from "..";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./view-wrapper.scss";

class ViewWrapper extends Component {
  state = { isNavShrink: false };

  handleNavigationToggle = () => {
    this.setState({ isNavShrink: !this.state.isNavShrink });
  };

  handleSubMenuOpen = () => {
    const { isNavShrink } = this.state;
    if (isNavShrink && window.innerWidth > 1240) {
      this.setState({ isNavShrink: false });
    }
  };
  removeNavShrink = () => {
    if (this.state.isNavShrink && window.innerWidth <= 1240)
      this.setState({ isNavShrink: false });
  };

  handleMobileNavShrink = () => {
    if (!this.state.isNavShrink && window.innerWidth <= 1240)
      this.setState({ isNavShrink: true });
  };

  render() {
    const { isNavShrink } = this.state;
    const { children, location } = this.props;
    return (
      <div className="app-container">
        <Navigation
          isNavShrink={isNavShrink}
          onSubMenuOpen={this.handleSubMenuOpen}
          onMobileNavShrink={this.handleMobileNavShrink}
          location={location}
          onNavShrink={this.removeNavShrink}
        />
        <div className="app-view-container" onClick={this.removeNavShrink}>
          <Header onNavigationToggle={this.handleNavigationToggle} />
          {children}
        </div>
      </div>
    );
  }
}
ViewWrapper.defaultProps = {
  className: "",
};
const mapStateToProps = ({ notification }) => {
  return { notification };
};
export default connect(mapStateToProps)(withRouter(ViewWrapper));
