import React, { Component } from "react";
import { connect } from "react-redux";
import Network from "./Network.js";
import { notificationAction } from "../../../src/actions";

class InvitedNetwork extends Component {
    render() {
        return (
            <Network
                isInvitedView={true}
            />
        );
    }
}

const mapStateToProps = ({ systemLabel }) => {
    const { labels } = systemLabel;

    return {
        labels,
    };
};

const actions = { notificationAction };
export default connect(
    mapStateToProps,
    actions
)(InvitedNetwork);
