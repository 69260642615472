import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert, Select } from "antd";
import classes from "./index.module.scss";
import FeatureCard from "../../components/FeatureCard/FeatureCard";
import DebounceSelect from "../../../../components/DebounceSelect/DebounceSelect";
import { getEducationsApi } from "../../dashboardApi";
import closeIcon from '../../../../assets/images/Icons/close-new.png'
import LanguageImage from '../../../../assets/images/education-popup.png'

function EditEducationPopUp({
  isLoading,
  handleEducationChange,
  SaveResumeDetails,
  resumeEducation,
  errorMessage,
  labels,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [yearsList, setYearsList] = useState(null);
  const [value, setValue] = useState([]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = () => {
    setIsModalVisible(false);
    SaveResumeDetails();
  };

  useEffect(() => {
    handleYearsLoad();
  }, []);

  const handleYearsLoad = () => {
    const currentYear = new Date().getFullYear();
    var startYear = 1970;
    var yearsList = [];
    while (startYear <= currentYear) {
      startYear++;
      const newYear = {
        value: startYear,
        label: startYear,
      };
      yearsList.push(newYear);
    }
    yearsList = yearsList.sort(function (a, b) {
      return b.value - a.value;
    });
    setYearsList(yearsList);
  };

  let EducationTypesList = [
    { value: 1, label: "School" },
    { value: 2, label: "Diploma" },
    { value: 3, label: "Graduation" },
    { value: 4, label: "Master" },
    { value: 5, label: "Doctorate" },
    { value: 6, label: "Bachelor" },
  ];

  const { EducationTypeId, EducationId, EducationYear } = resumeEducation;
  return (
    <>
      <button className="add-profile"
        onClick={() => setIsModalVisible(true)}
      >{labels.RESUME_ADD_EDUCATION_SUBHEAD}</button>

      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        className={`new-design-modal ${classes.popUpForm}`}
      >
          <div className="text-center mb-3 header-modal">
          <h5>{labels.ResumeHeadingEducation}</h5>
          <div
            onClick={() => handleCancel()}
            className="pointer"
          >
            <img className="close-icon" src={closeIcon} alt="" />
          </div>
        </div>
        <div className="images-icon">
          <img src={LanguageImage} alt="" />
          <p className="paragraph">Please select your education, diploma or certificate, and completion date</p>
        </div>
        <Form
          name="normal_login"
          className={`${classes.form} form`}
          onFinish={onFinish}
          size="large"
        >
        
          <Form.Item
            rules={[
              {
                required: EducationTypeId ? false : true,
                message:
                  labels.EDUCAION_TYPE_VALIDATION_LBL ||
                  "Please select education type",
              },
            ]}
             name="school"
            className={classes.customSelect}
          >
            <Select
              name={"EducationTypeId"}
              placeholder={labels.EDUCAION_TYPE_LBL}
              onChange={(e) => handleEducationChange("EducationTypeId", e)}
              allowClear
              autocomplete="new-password"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {EducationTypesList &&
                EducationTypesList.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: EducationId ? false : true,
                message:
                  labels.EDUCAION_VALIDATION_LBL || "Please select education",
              },
            ]}
             name="deploma"
            className={classes.customSelect}
          >
            <DebounceSelect
              mode="multiple"
              selectName={"EducationId"}
              value={value}
              placeholder={labels.DIPLOMA_OR_CERTIFICATE_LBL}
              fetchOptions={getEducationsApi}
              handleChange={handleEducationChange}
            />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: EducationYear ? false : true,
                message:
                  labels.EDUCAION_YEAR_VALIDATION_LBL || "Please select education year",
              },
            ]}
             name="skills"
            className={classes.customSelect}

          >
            <Select
              placeholder={"Date"}
              onChange={(e) => handleEducationChange("EducationYear", e)}
              allowClear
              autocomplete="new-password"
            >
              {yearsList &&
                yearsList.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.value}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          {error && (
            <Alert
              message={errorMessage?.replace("<br />", "")}
              type="warning"
              closable
            />
          )}

          <Button
            type="primary"
            block
            htmlType="submit"
            className="login-form-button mt-2"
            loading={isLoading}
          >
            {labels.Done_LBL}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default EditEducationPopUp;
