import React, { Component } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../components";
import { Column, ConfirmDialog, Button, SearchInput } from "../../common";
import CompanyList from "./components/CompanyList";
import CompanyFormEditor from "./components/CompanyFormEditor";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import filter from "lodash/filter";
import map from "lodash/map";
import includes from "lodash/includes";
import {
  isValidURL,
  isValidPhoneNumber,
  loadImageOrientation
} from "../../utilities/helpers";
import {
  getCompaniesApi,
  addCompanyApi,
  updateCompanyApi,
  deleteCompanyApi,
  getCountriesApi,
  getIndustriesApi,
  getUserEmailsApi,
  getUserPhoneNumbersApi,
  DeleteUserCompanyandCompanyUsers
} from "./companyApi";
import { notificationAction } from "../../actions";
import "./company.scss";
import { onStateChangeAction } from "./companyAction";
import toLower from "lodash/toLower";
class Company extends Component {
  state = {
    delCompanywithUserDM:""
  };

  componentDidMount() {
    this.props.isFetching && this.getCompanyList();
    this.getCountries();
    this.getIndustries();
    this.getUserEmails();
    this.getUserPhoneNumbers();
  }

  getIndustries = () => {
    getIndustriesApi().then(data => {
      const industries = data.items.map(item => ({
        ...item,
        value: item.IndustryId,
        label: item.IndustryValue
      }));
      this.props.onStateChangeAction({ industries });
    });
  };

  getCountries = () => {
    getCountriesApi().then(data => {
      const filterCountries = data.items.filter(item => item.CountryName);
      const countries = filterCountries.map(item => ({
        ...item,
        value: item.CountryId,
        label: item.CountryName
      }));
      this.props.onStateChangeAction({ countries });
    });
  };

  getUserEmails = () => {
    getUserEmailsApi().then(data => {
      const filterEmail = data.items.filter(item => item.UserEmailValue);
      const userEmails = filterEmail.map(item => ({
        ...item,
        value: item.UserEmailValue,
        label: item.UserEmailValue
      }));
      this.props.onStateChangeAction({ userEmails });
    });
  };

  handleFormFieldBlur = e => {
    let { name, value } = e.target;
    const { companyForm } = this.props;
    if (name === "WebUrl") {
      if (
        !isEmpty(value) &&
        value.indexOf("http") < 0 &&
        value.indexOf("https") < 0 &&
        value.indexOf("ftp") < 0
      ) {
        value = "http://" + value;
        this.props.onStateChangeAction({
          companyForm: {
            ...companyForm,
            WebUrl: value
          }
        });
      }
      this.validateUrl({ name, value });
      return;
    }
  };

  getUserPhoneNumbers = () => {
    getUserPhoneNumbersApi().then(data => {
      const filterPhone = data.items.filter(item => item.UserPhonenumberValue);
      const userPhoneNumbers = filterPhone.map(item => ({
        ...item,
        value: item.UserPhonenumberId,
        label: item.UserPhonenumberValue
      }));
      this.props.onStateChangeAction({ userPhoneNumbers });
    });
  };

  getCompanyList = () => {
    this.props.onStateChangeAction({ isFetching: true });
    getCompaniesApi()
      .then(data => {
        let companies = data.items;

        const currentCompany =
          this.props.location.state &&
          companies.find(
            item =>
              item.UserCompanyId === this.props.location.state.com.UserCompanyId
          );
        const defaultCompany = companies.find(item => item.IsDefaultCompany);
        this.props.onStateChangeAction({
          companyList: companies,
          filteredCompanies: companies,
          companyForm: (currentCompany && currentCompany) || defaultCompany,
          isLoading: false,
          isFetching: false
        });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false, isFetching: false });
      });
  };

  handleCreateNewClick = () => {
    const companyForm = { UserCompanyId: -1 };
    this.props.onStateChangeAction({
      companyForm
    });
  };

  handleCompanyClick = id => {
    var { filteredCompanies, userPhoneNumbers } = this.props;
    const phoneNumbers = filter(userPhoneNumbers, number => !number.className);
    const companyForm = find(filteredCompanies, { UserCompanyId: id });
    this.props.onStateChangeAction({
      companyForm,
      userPhoneNumbers: phoneNumbers
    });
  };

  handleDeleteCompany = ({ company }) => {
    const { companyDeleteConfirmation } = this.props.labels;
    this.props.onStateChangeAction({
      selectedCompany: company,
      dialogMessage: companyDeleteConfirmation
    });
  };

  handleYesClick = () => {
    const {
      selectedCompany: { UserCompanyId, IsDefaultCompany }
    } = this.props;

    const {
      companyDeleteSuccessfully,
      defaultCompanyNotDeletedMessage,
      companyAssociatedWithCompanyUsersMessage,
      companyWithCompanyUsersDeleteConfirmation
    } = this.props.labels;
    this.props.onStateChangeAction({ dialogMessage: "" });
    if (IsDefaultCompany) {
      const info = {
        message: defaultCompanyNotDeletedMessage,
        status: "info"
      };
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({ isLoading: true });
    deleteCompanyApi({ id: UserCompanyId })
      .then(data => {
        if (data.success) {
          this.props.notificationAction({
            message: companyDeleteSuccessfully,
            status: "success"
          });
          this.getCompanyList();
          return;
        }
        if (!data.success && data.message == companyAssociatedWithCompanyUsersMessage) {
          this.setState({
            delCompanywithUserDM: companyWithCompanyUsersDeleteConfirmation
          });
          this.props.onStateChangeAction({ isLoading: false });
          return;
        }
        this.props.notificationAction({
          message: data.message,
          status: "info"
        });
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  handleCompanyDelWithusersYesClick = () => {
    const {
      selectedCompany: { UserCompanyId, IsDefaultCompany }
    } = this.props;

    const {
      companyDeleteSuccessfully
    } = this.props.labels;
    this.setState({ delCompanywithUserDM: "" });
    this.props.onStateChangeAction({ isLoading: true });
    DeleteUserCompanyandCompanyUsers({ id: UserCompanyId })
      .then(data => {
        if (data.success) {
          this.props.notificationAction({
            message: companyDeleteSuccessfully,
            status: "success"
          });
          this.getCompanyList();
          return;
        }
        this.props.notificationAction({
          message: data.message,
          status: "info"
        });
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({
      dialogMessage: "",
      selectedCompany: null
    });
  };

  handleFormSelectChange = (name, selectedOption) => {
    if (!selectedOption) return;
    const { companyForm, userPhoneNumbers } = this.props;
    const { value, UserPhonenumberId } = selectedOption;
    if (name === "UserPhonenumberId") {
      if (UserPhonenumberId) {
        this.props.onStateChangeAction({
          companyForm: {
            ...companyForm,
            Phone: UserPhonenumberId,
            UserPhonenumberId
          }
        });
        return;
      }
      const newPhoneNumber = head(userPhoneNumbers);
      if (
        newPhoneNumber &&
        newPhoneNumber.value &&
        !isValidPhoneNumber(newPhoneNumber.value)
      ) {
        userPhoneNumbers.splice(0, 1);
        this.props.onStateChangeAction({
          userPhoneNumbers: [...userPhoneNumbers]
        });
        return;
      } else {
        this.props.onStateChangeAction({
          companyForm: {
            ...companyForm,
            [name]: value,
            newPhoneCreated: true,
            Phone: value,
            UserPhonenumberValue: value
          }
        });
        return;
      }
    }
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        [name]: value
      }
    });
  };

  validateField = ({ name, value }) => {
    const { companyForm } = this.props;
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        [name]: value,
        [`invalid${name}`]: !value
      }
    });
  };

  validateUrl = ({ name, value }) => {
    const { companyForm } = this.props;
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        [name]: value,
        [`invalid${name}`]: !(value && isValidURL(value))
      }
    });
  };

  handleFormFieldChange = e => {
    const { name, value } = e.target;
    if (name === "CompanyName") {
      this.validateField({ name, value });
      return;
    }
    if (name === "WebUrl") {
      this.validateUrl({ name, value });
      return;
    }
    const { companyForm } = this.props;
    if (name === "IsDefaultCompany") {
      this.props.onStateChangeAction({
        companyForm: {
          ...companyForm,
          [name]: e.target.checked
        }
      });
      return;
    }
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        [name]: value
      }
    });
  };

  updateAvatar = image => {
    const { companyForm } = this.props;
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        Logo: image
      }
    });
  };

  handleAvatarChange = e => {
    if (e.target.files && e.target.files[0]) {
      loadImageOrientation(e.target.files[0], this.updateAvatar);
      e.target.value = "";
    }
  };

  handleCompanySave = () => {
    const { companyForm } = this.props;
    const {
      companyFormValidation,
      companySuccessAdded,
      companySuccessEdited
    } = this.props.labels;
    const {
      CompanyAddress = "",
      CompanyName = "",
      CountryId = "",
      Detail = "",
      IndustryId = "",
      IsDefaultCompany = false,
      Logo = "resources/images/spacer.png",
      Phone = null,
      UserCompanyId = 0,
      UserPhonenumberId = "",
      newPhoneCreated,
      UserPhonenumberValue = "",
      Vat = ""
    } = companyForm;
    let { WebUrl } = companyForm;
    const company = {
      CompanyAddress,
      CompanyName,
      CountryId,
      Detail,
      IndustryId,
      IsDefaultCompany,
      Logo,
      Phone,
      UserCompanyId,
      UserPhonenumberId: newPhoneCreated ? null : UserPhonenumberId,
      UserPhonenumberValue,
      Vat,
      WebUrl
    };
    if (isEmpty(CompanyName)) {
      const info = {
        message: companyFormValidation,
        status: "error"
      };
      this.validateField({ name: "CompanyName", value: CompanyName });
      this.props.notificationAction(info);
      return;
    }
    if (isEmpty(UserPhonenumberId)) {
      const info = {
        message: companyFormValidation,
        status: "error"
      };
      this.validateField({ name: "UserPhonenumberId", value: UserPhonenumberId });
      this.props.notificationAction(info);
      return;
    }

    //WebURL Validation
    if (
      !isEmpty(WebUrl) &&
      WebUrl.indexOf("http") < 0 &&
      WebUrl.indexOf("https") < 0 &&
      WebUrl.indexOf("ftp") < 0
    ) {
      WebUrl = "http://" + WebUrl;
      this.props.onStateChangeAction({
        companyForm: {
          ...companyForm,
          WebUrl: WebUrl
        }
      });
      company.WebUrl = WebUrl;
    }
    if (!isEmpty(WebUrl) && !isValidURL(WebUrl)) {
      const info = {
        message: this.props.labels.companyURLInvalid,
        status: "error"
      };
      this.props.notificationAction(info);
      return;
    }
    //End WebURL Validation
    this.props.onStateChangeAction({ isLoading: true });
    if (UserCompanyId < 0) {
      addCompanyApi({ company })
        .then(() => {
          this.getUserPhoneNumbers();
          this.getCompanyList();
          const info = {
            message: companySuccessAdded,
            status: "success"
          };
          this.props.notificationAction(info);
        })
        .catch(() => {
          this.props.onStateChangeAction({ isLoading: false });
        });
      return;
    }
    updateCompanyApi({ company })
      .then(() => {
        this.getUserPhoneNumbers();
        this.getCompanyList();
        const info = {
          message: companySuccessEdited,
          status: "success"
        };
        this.props.notificationAction(info);
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleCompanySearch = e => {
    const searchKey = e.target.value;
    this.props.onStateChangeAction({ searchKey });
    const { companyList } = this.props;
    const filteredCompanies = filter(companyList, company => {
      if (includes(toLower(company.CompanyName), toLower(searchKey))) {
        return company;
      }
    });
    this.props.onStateChangeAction({ filteredCompanies });
  };

  handleGetImgSrc = () => {
    const { companyForm } = this.props;
    this.props.onStateChangeAction({
      companyForm: {
        ...companyForm,
        Logo: null
      }
    });
  };

  render() {
    const {
      labels,
      isHelpActive,
      filteredCompanies,
      companyForm,
      dialogMessage,
      countries,
      industries,
      userEmails,
      userPhoneNumbers,
      isLoading,
      isFetching,
      searchKey,
      listCollapsed,
      formCollapsed
    } = this.props;
    const {delCompanywithUserDM} = this.state;
    return (
      <PageWrapper className="company-page">
        {dialogMessage && (
          <ConfirmDialog testId="company-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-yes"
                onClick={this.handleYesClick}
              >
                {labels.companyDeleteCurtainYESBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-no"
                onClick={this.handleNoClick}
              >
                {labels.companyDeleteCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {delCompanywithUserDM && (
          <ConfirmDialog testId="company-confirm-diloag">
            <ConfirmDialog.Message>{delCompanywithUserDM}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-yes"
                onClick={this.handleCompanyDelWithusersYesClick}
              >
                {labels.companyDeleteCurtainYESBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-no"
                onClick={this.handleNoClick}
              >
                {labels.companyDeleteCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={listCollapsed} className="col-1">
          <Column.Collapsed
            collapse={listCollapsed}
            text={labels.ViewTitleCompanyList}
            onClick={() =>
              this.props.onStateChangeAction({ listCollapsed: false })
            }
            tooltipButton={labels.ToolTipCompanyExpandCreate}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.HlpTooltipCompanyExpandListButton}
            tooltipPlace="left"
            testId="company-list-expand-btn"
            testIdHelp="company-list-expand-help-btn"
          />
          <Column.Head>
            <SearchInput
              className="round-search"
              placeholder={labels.companySearchFieldPlaceholder}
              onChange={this.handleCompanySearch}
              testId="company-search-input"
              value={searchKey}
            />
            <Button
              className="AddBtn"
              onClick={this.handleCreateNewClick}
              tooltipButton={labels.ToolTipCompanyCreateButton}
              tooltipHelp={labels.HlpTooltipCompanyCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId="add-new-company-btn"
              testIdHelp="add-new-company-help-btn"
            />
            <Button
              onClick={() =>
                this.props.onStateChangeAction({ listCollapsed: true })
              }
              className="collapseBtn"
              tooltipButton={labels.ToolTipCompanyCollaspeCreate}
              tooltipHelp={labels.HlpTooltipCompanyCollapseListButton}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              testId="company-list-collapse-btn"
              testIdHelp="company-list-collapse-help-btn"
            />
          </Column.Head>
          <Column.Body>
            <CompanyList
              companyList={filteredCompanies}
              handleCompanyClick={this.handleCompanyClick}
              companyForm={companyForm}
              handleDeleteCompany={this.handleDeleteCompany}
              isFetching={isFetching}
              labels={this.props?.labels}
            />
          </Column.Body>
        </Column>
        <Column collapse={formCollapsed} className="col-2">
          <Column.Collapsed
            collapse={formCollapsed}
            text={
              companyForm && companyForm.UserCompanyId < 0
                ? labels.COMPANY_ADD_NEW_TITLE
                : labels.ViewTitleCompanyCreate
            }
            onClick={() =>
              this.props.onStateChangeAction({ formCollapsed: false })
            }
            tooltipButton={labels.ToolTipCompanyExpandDetail}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.HlpTooltipCompanyExpandCreateButton}
            tooltipPlace="left"
            testId="company-detail-expand-btn"
            testIdHelp="company-detail-expand-help-btn"
          />
          <Column.Head>
            <Column.HeaderTitle
              testId="company-detail-header"
              isActive={companyForm && companyForm.UserCompanyId}
            >
              {companyForm && companyForm.UserCompanyId < 0
                ? labels.COMPANY_ADD_NEW_TITLE
                : labels.companyEditHeading}
            </Column.HeaderTitle>
            {companyForm && companyForm.UserCompanyId && (
              <Button
                className="SaveCloudBtn"
                onClick={this.handleCompanySave}
                tooltipButton={labels.ToolTipCompanySaveButton}
                tooltipHelp={labels.HlpTooltipCompanySaveButton}
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                testId="company-cloud-save-btn"
                testIdHelp="company-cloud-save-help-btn"
              />
            )}
            <Button
              className="collapseBtn"
              onClick={() =>
                this.props.onStateChangeAction({ formCollapsed: true })
              }
              tooltipButton={labels.ToolTipCompanyCollaspeDetail}
              tooltipHelp={labels.HlpTooltipCompanyCollapseCreateButton}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              testId="company-detail-collapse-btn"
              testIdHelp="company-detail-collapse-help-btn"
            />
          </Column.Head>
          <Column.Body
            className={`flex ${
              companyForm && companyForm.UserCompanyId !== undefined
                ? "alignStart"
                : ""
            }`}
          >
            <CompanyFormEditor
              onFormSelectChange={this.handleFormSelectChange}
              industries={industries}
              userEmails={userEmails}
              userPhoneNumbers={userPhoneNumbers}
              companyForm={companyForm}
              countries={countries}
              onFormFieldChange={this.handleFormFieldChange}
              onFormFieldBlur={this.handleFormFieldBlur}
              onCompanySave={this.handleCompanySave}
              onAvatarChange={this.handleAvatarChange}
              labels={labels}
              isLoading={isLoading}
              getImgSrc={this.handleGetImgSrc}
            />
          </Column.Body>
        </Column>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, company }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, ...company };
};
const mapActionToProps = {
  notificationAction,
  onStateChangeAction
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(Company);
