import React, { Component } from "react";
import { Column, Button, EmptyInfo } from "../../common";
import {
  getNetworkusers,
  networkInvitationSend,
  sendNetworkSignupInvitation,
} from "./networkApi";
import { connect } from "react-redux";
import moment from "moment";
import { find, uniqBy } from "lodash";  // Import uniqBy from lodash
import NetworkForm from "./components/NetworkForm";
import NetworkDetail from "./components/NetworkDetail";
import { PageWrapper } from "../../components";
import { notificationAction } from "../../actions";
import { privateRoutes, publicRoutes } from "../../Routes/routing";
import { withRouter } from "react-router-dom";
import ImgSrc from "../../assets/images/present.svg";

import "./network.scss";
import toLower from "lodash/toLower";
import filter from "lodash/filter";
import includes from "lodash/includes";
import { onStateChangeAction } from "../Present/presentAction";

import {
  getInvitedNetworkusers,
  getInvitationsNetworkusers,
  acceptOpportunityApi,
  declineNetworkInviteApi,
  declineNetworkInvitationApi,
  getResumeByUserId,
  acceptOpportunityApiThroughNetwork,
  getOpportunity,
  getUserDetailApi,
} from "./networkApi";

import { sendOpportunityApi } from "../Opportunity/opportunityApi";

import { postShortlistApi, getShortlistApi } from "../Search/searchApi";

import { getNetworkPresentApi } from "../Present/presentApi";

import { getNetworkUserCompanyApi } from "../Company/companyApi";
import UserDetail from "../Collaboration/common/components/UserDetail";
import { Col, Row, Input, Space } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import MobileModal from "./components/mobileModal";
const { Search } = Input;

class Network extends Component {
  state = {
    dialogMessage: "",
    networkUsers: [],
    selectedUser: [],
    filteredList: [],
    companyList: [],
    currentPresent: [],
    Profiles: [],
    Emails: [],
    isConnectedView: false,
    isInvitedView: false,
    isInvitationsView: false,
    networkData: {
      email: "",
    },
    detailCollapsed: false,
    isCreateNew: false,
    listCollapsed: false,
    searchKey: "",
    ShortListId: [],
  };
  
  componentDidMount() {
    this.getNetUsersApi();
  }

  getNetworkUserCompanyList = (selectedUser) => {
    this.props.onStateChangeAction({ isFetching: true, isLoading: true });
    getNetworkUserCompanyApi(selectedUser.UserId)
      .then((data) => {
        let companies = data.items;
        let defaultCompany = companies.Companies.find((item) => item.IsDefaultCompany) || companies[0];
        this.setState({
          companyList: defaultCompany,
        });
        this.props.onStateChangeAction({
          companyList: defaultCompany,
          isLoading: false,
          isFetching: false,
        });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false, isFetching: false });
      });
  };

  getNetworkUserPresent = (selectedUser) => {
    this.props.onStateChangeAction({ isFetching: true, isLoading: true });
    getNetworkPresentApi(selectedUser.UserId)
      .then((data) => {
        let presentation = data.items[0];
        let profile = presentation.Profiles;
        let email = presentation.UserEmails;
        this.setState({
          currentPresent: presentation,
          Profiles: profile,
          Emails: email,
        });
        this.props.onStateChangeAction({
          isLoading: false,
          isFetching: false,
        });
      })
      .catch(() => {
        this.props.onStateChangeAction({
          isFetching: false,
          isLoading: false,
        });
      });
  };

  getNetUsersApi = () => {
    const { isConnectedView, isInvitedView, isInvitationsView } = this.props;
    if (isConnectedView) {
      getNetworkusers().then((data) => {
        if (data.success) {
          this.setState({
            networkUsers: data.items,
            filteredList: data.items,
            isConnectedView: isConnectedView,
            isInvitedView: isInvitedView,
            isInvitationsView: isInvitationsView,
            isFetching: false,
          });
          if (data.items.length > 0) {
            this.handleItemClick(data.items[0].UserId);
          }
        }
      });
    } else if (isInvitedView) {
      getInvitedNetworkusers().then((data) => {
        if (data.success) {
          this.setState({
            networkUsers: data.items,
            filteredList: data.items,
            isConnectedView: isConnectedView,
            isInvitedView: isInvitedView,
            isInvitationsView: isInvitationsView,
            isFetching: false,
          });
          if (data.items.length > 0) {
            this.handleItemClick(data.items[0].UserId);
          }
        }
      });
    } else if (isInvitationsView) {
      getInvitationsNetworkusers().then((data) => {
        if (data.success) {
          this.setState({
            networkUsers: data.items,
            filteredList: data.items,
            isConnectedView: isConnectedView,
            isInvitedView: isInvitedView,
            isInvitationsView: isInvitationsView,
            isFetching: false,
          });
          if (data.items.length > 0) {
            this.handleItemClick(data.items[0].UserId);
          }
        }
      });
    }
  };

  handleAcceptNetwork = (obj) => {
    acceptOpportunityApi(obj.UserId).then((data) => {
      if (data.success) {
        let users = this.getNetUsersApi();
        if (users == null) {
          this.setState({
            selectedUser: [],
            companyList: [],
            currentPresent: [],
            Profiles: [],
            Emails: [],
          });
        }
        const info = {
          message: data.message,
          status: "success",
        };
        this.props.notificationAction(info);
      } else {
        const info = {
          message: data.message,
          status: "error",
        };
        this.props.notificationAction(info);
      }
    });
  };

  handleDeleteNetwork = (obj) => {
    const { isInvitedView, isInvitationsView } = this.props;
    if (isInvitedView) {
      declineNetworkInviteApi(obj.UserId).then((data) => {
        if (data.success) {
          let users = this.getNetUsersApi();
          if (users == null) {
            this.setState({
              selectedUser: [],
              companyList: [],
              currentPresent: [],
              Profiles: [],
              Emails: [],
            });
          }
          const info = {
            message: data.message,
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: data.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      });
    } else if (isInvitationsView) {
      declineNetworkInvitationApi(obj.UserId).then((data) => {
        if (data.success) {
          let users = this.getNetUsersApi();
          if (users == null) {
            this.setState({
              selectedUser: [],
              companyList: [],
              currentPresent: [],
              Profiles: [],
              Emails: [],
            });
          }
          const info = {
            message: data.message,
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: data.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      });
    }
  };

  handleSendSignUpInvitationClick = () => {
    const { Email } = this.state.networkData;
    sendNetworkSignupInvitation(Email).then((data) => {
      if (data.success) {
        const info = {
          message: data.message,
          status: "success",
        };
        this.setState({
          networkData: {
            ...this.state.networkData,
            Email: "",
          },
        });
        this.props.notificationAction(info);
      } else {
        const info = {
          message: data.message,
          status: "error",
        };
        this.props.notificationAction(info);
      }
    });
    this.setState({
      dialogMessage: "",
    });
  };

  handleItemClick = (id) => {
    const { networkUsers } = this.state;
    const selectedUser = find(networkUsers, { UserId: id });
    this.getNetworkUserCompanyList(selectedUser);
    this.getNetworkUserPresent(selectedUser);
    this.setState({
      selectedUser,
      isCreateNew: false,
    });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { networkUsers } = this.state;
    const filteredList = filter(networkUsers, (sl) =>
      includes(toLower(sl.UserFirstname), toLower(value))
    );
    this.setState({ filteredList, searchKey: value });
  };
  
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    const { networkData } = this.state;
    this.setState({
      networkData: {
        ...networkData,
        [name]: value,
      },
    });
  };

  handleNewNetwork = () => {
    this.setState({ isCreateNew: true });
  };
  
  handleNetworkInvitationSend = () => {
    const { Email } = this.state.networkData;
    if (!Email) {
      const info = {
        message: "Please Enter Email",
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    networkInvitationSend(Email).then((data) => {
      if (data.success) {
        const { isInvitedView } = this.props;
        if (isInvitedView) {
          getInvitedNetworkusers().then((data) => {
            if (data.success) {
              this.setState({
                networkUsers: data.items,
                filteredList: data.items,
                isInvitedView: isInvitedView,
                isFetching: false,
              });
              if (data.items.length > 0) {
                this.handleItemClick(data.items[0].UserId);
              }
            }
          });
        }
        const info = {
          message: data.message,
          status: "success",
        };
        this.setState({
          networkData: {
            ...this.state.networkData,
            Email: "",
          },
        });
        this.props.notificationAction(info);
      } else {
        const info = {
          message: data.message,
          status: "error",
        };
        if (
          info.message
            .toLowerCase()
            .includes("given email does not exist at prodoo")
        )
          this.handleSendSignUpInvitationClick();
        else this.props.notificationAction(info);
      }
    });
  };

  handleCreateNewOpportunityClick = (user) => {
    getResumeByUserId(user?.UserId).then((data) => {
      if (data.success) {
        let ResumeIds = [];
        ResumeIds.push(data.items.ResumeId);
        JSON.stringify(localStorage.setItem("ResumeId", data.items.ResumeId));
        const newShortlistValue = `Quick shortlist from Network for ${
          user.UserFirstname
        } ${user.UserLastname} at ${moment(new Date()).format(
          "D MMMM YYYY"
        )} at ${moment(new Date()).format("LTS")}`;
        const resumeDetail = {
          ShortlistName: newShortlistValue,
          ResumeIdsList: ResumeIds,
        };
        postShortlistApi(resumeDetail)
          .then((resume) => {
            let resumeShortlistId = [];
            resumeShortlistId.push(resume.items);
            this.getShortlist(user, ResumeIds, resumeShortlistId);
          })
          .catch((err) => {
            this.props.notificationAction(err);
          });
      }
    });
  };

  getShortlist(user, ResumeIds, resumeShortlistId) {
    let loggedUser = JSON.parse(localStorage.getItem("User"));
    getNetworkUserCompanyApi(loggedUser.UserId)
      .then((company) => {
        let companies = company.items;
        let companyList = companies.Companies.find((item) => item.IsDefaultCompany) || companies[0];
        const opportunity = {
          RequestName: `Quick Opportunity from Network for ${
            user.UserFirstname
          } ${user.UserLastname} at ${moment(new Date()).format(
            "D MMMM YYYY"
          )} at ${moment(new Date()).format("LTS")}`,
          ShortListIds: resumeShortlistId,
          UserCompanyId: companyList.UserCompanyId,
          IsSent: true,
        };
        sendOpportunityApi({ opportunity })
          .then((data) => {
            this.getOpportunity(ResumeIds, user);
          })
          .catch((err) => {
            this.props.notificationAction(err);
          });
      })
      .catch((err) => {
        this.props.notificationAction(err);
      });
  }

  getOpportunity = (ResumeIds, user) => {
    getOpportunity()
      .then((data) => {
        if (data.success) {
          JSON.stringify(
            localStorage.setItem("OpportunityId", data.items.RequestId)
          );
          getUserDetailApi(user.UserId)
            .then((UserDetail) => {
              if (!UserDetail.items) {
                const info = {
                  message: this.props.labels.presentationNotFound,
                  status: "error",
                };
                this.props.notificationAction(info);
                return;
              } else {
                const acceptOpportunity = {
                  RequestId: data.items.RequestId,
                  ResumeId: ResumeIds[0],
                  UserDetailId: UserDetail.items.UserDetailId,
                };
                acceptOpportunityApiThroughNetwork(acceptOpportunity)
                  .then((res) => {
                    this.props.history.push(
                      privateRoutes.searcherCreateCollaboration.path
                    );
                  })
                  .catch((err) => {
                    this.props.notificationAction(err);
                  });
              }
            })
            .catch((err) => {
              this.props.notificationAction(err);
            });
        }
      })
      .catch((err) => {
        this.props.notificationAction(err);
      });
  };

  handleCreateNewShortlistClick = (user) => {
    getResumeByUserId(user.UserId).then((data) => {
      if (data.success) {
        let ResumeIds = [];
        ResumeIds.push(data.items.ResumeId);
        const newShortlistValue = `Quick shortlist from Network for ${
          user.UserFirstname
        } ${user.UserLastname} at ${moment(new Date()).format(
          "D MMMM YYYY"
        )} at ${moment(new Date()).format("LTS")}`;
        const resumeDetail = {
          ShortlistName: newShortlistValue,
          ResumeIdsList: ResumeIds,
        };
        postShortlistApi(resumeDetail)
          .then((resume) => {
            sessionStorage.setItem("opportunity-new", true);
            this.props.history.push(
              privateRoutes.searcherCreateOpportunity.path
            );
          })
          .catch((err) => {
            this.props.notificationAction(err);
          });
      }
    });
  };

  render() {
    const {
      selectedUser,
      isFetching,
      companyList,
      currentPresent,
      Profiles,
      Emails,
      isCreateNew,
      filteredList,
      listCollapsed,
      detailCollapsed,
      searchKey,
      networkData,
    } = this.state;
    const {
      labels,
      isHelpActive,
      BtnAccept,
      BtnDecline,
      BtnAddOpportunity,
      BtnAddCollaboration,
    } = this.props;
    const handleClose = () => {
      this.setState((st) => ({ ...st, mobileModal: false }));
    };
    return (
      <PageWrapper className="network-page">
        <MobileModal
          mobileModal={this?.state?.mobileModal}
          handleClose={handleClose}
          currentPresent={currentPresent}
          selectedUser={selectedUser}
          Emails={Emails}
          Profiles={Profiles}
          companyList={companyList}
          handleCompanyClick={this.handleCompanyClick}
          isInvitationsView={this.state.isInvitationsView}
          isConnectedView={this.state.isConnectedView}
          isFetching={isFetching}
          handleCreateNewShortlistClick={this.handleCreateNewShortlistClick}
          handleCreateNewOpportunityClick={this.handleCreateNewOpportunityClick}
          handleDeleteNetwork={this.handleDeleteNetwork}
          handleAcceptNetwork={this.handleAcceptNetwork}
          BtnAccept={BtnAccept}
          BtnDecline={BtnDecline}
          BtnAddOpportunity={BtnAddOpportunity}
          BtnAddCollaboration={BtnAddCollaboration}
          isCreateNew={isCreateNew}
          onFormFieldChange={this.handleFormFieldChange}
          networkData={networkData}
          handleNetworkInvitationSend={this.handleNetworkInvitationSend}
        />
        <Row className="new-search-resume-main">
          <Col xs={24} md={10} className="shortlist-lsit-section">
            <div className="h-100">
              <div className="h-100 d-flex flex-column">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Search
                    enterButton="+"
                    onSearch={() => {
                      const mediaQuery = window.matchMedia(
                        "(max-width: 768px)"
                      );
                      if (mediaQuery?.matches == true) {
                        this.setState((st) => ({ ...st, mobileModal: true }));
                      }
                      this.handleNewNetwork();
                    }}
                    size="large"
                    placeholder={labels.SEARCH_USER_LABEL}
                    onChange={this.handleSearchChange}
                    value={searchKey}
                  />
                </div>
                <div
                  style={{
                    marginTop: "6px",
                    marginBottom: "6px",
                    paddingRight: "6px",
                    overflow: "auto",
                  }}
                >
                  {filteredList?.length > 0 ? (
                    <Space
                      size={[6, 6]}
                      wrap
                      className="short-list-new company-list"
                    >
                      {filteredList?.map((item) => (
                        <div
                          onClick={() => {
                            const mediaQuery = window.matchMedia(
                              "(max-width: 768px)"
                            );
                            if (mediaQuery?.matches == true) {
                              this.setState((st) => ({
                                ...st,
                                mobileModal: true,
                              }));
                            }
                            this.handleItemClick(item?.UserId);
                          }}
                          key={item.ShortlistId}
                          className={`${
                            selectedUser?.UserId === item?.UserId
                              ? "background-shortlist short-list-item"
                              : "short-list-item"
                          }`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                        >
                          <div className="d-flex w-100">
                            <div
                              className={`dashItemImg ${
                                item.ProfilePicture ? "" : "no-dp"
                              }`}
                            >
                              <img
                                className="roundImg"
                                src={
                                  item?.ProfilePicture
                                    ? item?.ProfilePicture
                                    : ImgSrc
                                }
                                alt={item?.UserFirstname}
                              />
                            </div>

                            <div className="CompNameCnt d-flex justify-content-between align-items-center">
                              <div>
                                <div
                                  className={
                                    item.IsDefaultCompany
                                      ? "defaultCompanyName"
                                      : "listLabel"
                                  }
                                >
                                  {item?.UserFirstname +
                                    " " +
                                    (item?.UserLastname
                                      ? item?.UserLastname
                                      : "")}
                                </div>
                                <div className="CompAddress">
                                  {item?.UserEmail || ""}
                                </div>
                              </div>
                              {this?.state?.isInvitedView && (
                                <CloseCircleOutlined
                                  style={{
                                    fontSize: "18px",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleDeleteNetwork(item);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Space>
                  ) : (
                    <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                  )}
                </div>
              </div>
            </div>
          </Col>

          <Col md={14} xs={0} offset={0.5} className="h-100">
            <div className="column-secend">
              <NetworkDetail
                currentPresent={currentPresent}
                selectedUser={selectedUser}
                Emails={uniqBy(Emails, 'UserEmailValue')}  // Ensure unique emails
                Profiles={Profiles}
                companyList={companyList}
                handleCompanyClick={this.handleCompanyClick}
                isInvitationsView={this.state.isInvitationsView}
                isConnectedView={this.state.isConnectedView}
                isFetching={isFetching}
                handleCreateNewShortlistClick={
                  this.handleCreateNewShortlistClick
                }
                handleCreateNewOpportunityClick={
                  this.handleCreateNewOpportunityClick
                }
                handleDeleteNetwork={this.handleDeleteNetwork}
                handleAcceptNetwork={this.handleAcceptNetwork}
                BtnAccept={BtnAccept}
                BtnDecline={BtnDecline}
                BtnAddOpportunity={BtnAddOpportunity}
                BtnAddCollaboration={BtnAddCollaboration}
                isCreateNew={isCreateNew}
                onFormFieldChange={this.handleFormFieldChange}
                networkData={networkData}
                handleNetworkInvitationSend={this.handleNetworkInvitationSend}
              />
            </div>
          </Col>
        </Row>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, feedback }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, ...feedback };
};

const mapActionToProps = {
  onStateChangeAction,
  notificationAction,
};

export default connect(mapStateToProps, mapActionToProps)(withRouter(Network));

