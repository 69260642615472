import React from "react";
import { Button } from "../../../../common";

const FLOW = "FLOW";

export default function Flows(props) {
  const { labels } = props;
  return (
    <React.Fragment>
      <div className="tictell-list">
        {props.flows &&
          props.flows.length > 0 &&
          props.flows.map((item) => (
            <div
              key={item.Id || item.FlowId}
              test-data-id={`flows-list${item.isActive ? "activeItem" : ""}`}
              className={`tictell-list-item-container  ${
                item.isSelected ? "selected-item" : ""
              } ${item.isActive ? "activeItem" : ""}`}
            >
              {props.isIcon && (
                <span
                  className={"flow-icon"}
                  onClick={() => props.handleItemClick(FLOW, item)}
                />
              )}
              {props.isCheckbox && (
                <label className="list-checkbox">
                  <input
                    class="flowCheckBox"
                    type="checkbox"
                    onChange={() => props.handleCheckBoxClick(item)}
                  ></input>
                </label>
              )}
              <div
                onClick={() => props.handleItemClick(FLOW, item)}
                className="name-container flow-container"
              >
                <label test-data-id={`collabs-list-owner-username`}>
                  {item.Title}
                </label>
                <label test-data-id={`collabs-list-owner-title`}>
                  {item.Description}
                </label>
              </div>
              {props.isDelete && (
                <Button
                  onClick={() => props.handleFlowDelete(item)}
                  className="closeBtn"
                  tooltipButton={labels.EMBARK_DELETE_FLOW}
                  tooltipHelp={labels.EMBARK_BTN_DELETE_FLOW}
                  tooltipPlace="left"
                  isHelpActive={props.isHelpActive}
                  testId="delete-flow-btn"
                />
              )}
            </div>
          ))}
      </div>
    </React.Fragment>
  );
}
