import React, { Component } from "react";
import {
  Column,
  Button,
  SearchInput,
  EmptyInfo,
  ConfirmDialog,
  Input,
  LoadingMaskRow,
  List,
} from "../../../../common";
import PageWrapper from "../../../../components/PageWrapper/PageWrapper";
import ShortlistResumes from "./component/ShortlistResumes";
import { notificationAction } from "../../../../actions";
import toLower from "lodash/toLower";
import ShortlistResumeDetail from "./component/ShortlistResumeDetail";
import filter from "lodash/filter";
import { connect } from "react-redux";
import find from "lodash/find";
import first from "lodash/first";
import moment from "moment";
import includes from "lodash/includes";
import {
  getShortlistApi,
  getShortlistResumeApi,
  postShortlistApi,
  deleteShortlistApi,
  getOppuntinitiesApi,
} from "./shortlistApi";
import { privateRoutes } from "../../../../Routes/routing";

import "./shortlist.scss";
import { isEmpty } from "lodash";
import { deleteResumeApi } from "../../../Shortlist/shortlistApi";

class Shortlist extends Component {
  state = {
    searchCollapsed: false,
    resumeCollapsed: false,
    resumeDetailCollapsed: false,
    createCollapse: false,
    shortlist: [],
    selectedResume: {},
    selectedShortlist: {},
    shortlistResumes: [],
    filteredShortlist: [],
    isLoading: false,
    fetchingShortlist: true,
    fetchingResumes: false,
    opportunityList: [],
    fetchingOpportunities: false,
    dialogMessage: "",
    newShortlistValue: "",
    createNewShortlist: false,
    dialogOpportunityMessage: "",
    showOpportunities: false,
    opportunitiesCollapse: false,
    searchKey: "",
    deletedId: null,
  };

  componentDidMount() {
    this.getShortlist().then(() => {
      const { history } = this.props;
      if (history) {
        const {
          location: { state },
        } = history;
        if (state) {
          const { shortlist } = state;
          this.handleSelectShortlist(shortlist);
        }
        const { onBoardShortlistId } = this.state;
        if (onBoardShortlistId) {
          this.getShortlist();
        }
      }
    });
  }

  getOpportunities = () => {
    this.setState({ fetchingOpportunities: true });
    getOppuntinitiesApi()
      .then((data) => {
        this.setState({
          opportunityList: data.items,
          fetchingOpportunities: false,
        });
        if (data.items.length > 0) {
          this.setState({
            showOpportunities: true,
          });
        } else {
          const info = {
            message: this.props.labels.InfoIShortlistNoQuickOpportunity,
            status: "info",
          };
          this.props.notificationAction(info);
        }
      })
      .then(() => {
        this.setState({
          fetchingOpportunities: false,
        });
      });
  };
  getShortlist = (selectedId) => {
    this.setState({ fetchingShortlist: true });
    return getShortlistApi()
      .then((data) => {
        this.setState({
          shortlist: data.items,
          filteredShortlist: data.items,
          fetchingShortlist: false,
        });
        const { onBoardShortlistId } = this.props;
        if (selectedId) {
          this.setState({
            shortlist: data.items,
            filteredShortlist: data.items,
          });
          this.handleSelectShortlist(
            data.items.find((a) => a.ShortlistId == selectedId)
          );
        }
      })
      .catch(() => {
        this.setState({
          fetchingShortlist: false,
        });
      });
  };

  handleSelectShortlist = (item) => {
    this.setState({
      selectedShortlist: item,
      createNewShortlist: false,
      fetchingResumes: true,
      // shortlistResumes: [],
      showOpportunities: false,
      clonedShortlist: {},
    });
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        this.setState({
          shortlistResumes: data.items.map((item, index) => {
            return {
              ...item,
              active: index == 0 ? true : false,
            };
          }),
          fetchingResumes: false,
          selectedResume: first(data.items) || {},
        });
      })
      .catch(() => {
        this.setState({ fetchingResumes: false });
      });
  };

  handleResumeSelect = (item) => {
    const { shortlistResumes } = this.state;
    const selectedResume = find(shortlistResumes, { ResumeId: item.ResumeId });
    this.setState({ selectedResume });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { shortlist } = this.state;

    const filteredShortlist = filter(shortlist, (opp) =>
      includes(toLower(opp.ShortlistName), toLower(value))
    );
    this.setState({ filteredShortlist, searchKey: value });
  };
  handleShortlistActive = (activeItem) => {
    const shortlistResumes = this.state.shortlistResumes.map((item) => ({
      ...item,
      active:
        activeItem.ResumeId === item.ResumeId ? !item.active : item.active,
    }));
    this.setState({
      shortlistResumes,
    });
  };
  handleCreateNewShorlistClick = () => {
    const { clonedShortlist } = this.state;
    this.setState({ isLoading: true });
    postShortlistApi({ ...clonedShortlist, ShortlistId: 0 })
      .then((data) => {
        if (data.success) {
          this.getShortlist(data.items);
          this.setState({
            createNewShortlist: false,
            isLoading: false,
            clonedShortlist: {},
            selectedResume: {},
          });
          const info = {
            message: this.props.labels.shortlistSuccessAdded,
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: this.props.labels.shortlistAlreadyExist,
            status: "info",
          };
          this.props.notificationAction(info);
          this.setState({
            isLoading: false,
            newShortlistValue: null,
          });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };
  handleNewShortList = () => {
    this.setState({
      createNewShortlist: true,
      newShortlistValue: "",
      selectedResume: {},
      selectedShortlist: {},
    });
  };
  handleDeleteShortlist = (id) => {
    this.setState({
      deletedId: id,
      dialogMessage: this.props.labels.shortlistDeletionConfirmation,
    });
  };
  handleYesClick = () => {
    const { deletedId } = this.state;
    this.setState({ dialogMessage: "" });
    this.setState({ isLoading: true });
    deleteShortlistApi(deletedId)
      .then((data) => {
        if (data.success) {
          this.getShortlist();
          const info = {
            message: this.props.labels.shortlistSuccessfullDeletion,
            status: "success",
          };
          this.props.notificationAction(info);
          this.setState({
            selectedShortlist: {},
            selectedResume: {},
            deletedId: null,
          });
        } else {
          const info = {
            message: data.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };
  handleNoClick = () => {
    this.setState({ dialogMessage: "", deletedId: null });
  };
  handleCreateNewClick = (item) => {
    const clonedShortlist = {
      ...item,
      ShortlistName: "Copy of " + item.ShortlistName,
    };
    this.setState({
      clonedShortlist,
      selectedShortlist: {},
    });
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        const resumes = data.items.map((item, index) => {
          return {
            ...item,
            active: index == 0 ? true : false,
          };
        });
        this.setState({
          clonedShortlist: {
            ...clonedShortlist,
            ShortlistResumes: resumes,
          },
          shortlistResumes: resumes,
        });
      })
      .catch(() => {
        this.setState({ fetchingResumes: false });
      });
  };
  createNewShortlist = () => {
    const { shortlistResumes } = this.state;
    const selectedResumesIds = shortlistResumes
      .filter((item) => item.active)
      .map((item) => item.ResumeId);
    this.setState({ isLoading: true });
    const info = {
      ShortlistName: `New shortlist for ${
        selectedResumesIds.length
      } resume(s) at ${moment(new Date()).format("D MMMM YYYY")} at ${moment(
        new Date()
      ).format("LTS")}`,
      ResumeIdsList: selectedResumesIds,
    };
    return postShortlistApi(info)
      .then((data) => {
        return data;
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };
  handleSelectOpportunity = (item) => {
    this.createNewShortlist().then(() => {
      sessionStorage.setItem("opportunity", JSON.stringify(item));
      this.props.history.push(privateRoutes.searcherCreateOpportunity.path);
    });
  };
  handleResumeDelete = (item) => {
    const { shortlistResumes, selectedShortlist, selectedResume } = this.state;
    deleteResumeApi(item.ResumeId, selectedShortlist.ShortlistId)
      .then((data) => {
        if (data.success) {
          const filteredShortlistResumes = shortlistResumes.filter(
            (x) => x.ResumeId !== item.ResumeId
          );
          this.setState({
            shortlistResumes: filteredShortlistResumes,
            selectedResume:
              item.ResumeId == selectedResume.ResumeId
                ? filteredShortlistResumes.length > 0
                  ? filteredShortlistResumes[0]
                  : {}
                : selectedResume,
          });
        }
      })
      .catch((err) => console.log("Error ", err));
  };

  onResumeDelete = (item) => {
    this.setState({
      clonedShortlist: {
        ...this.state.clonedShortlist,
        ShortlistResumes: this.state.clonedShortlist.ShortlistResumes.filter(
          (x) => x.ResumeId != item.ResumeId
        ),
      },
      selectedResume:
        this.state.selectedResume.ResumeId === item.ResumeId
          ? {}
          : this.state.selectedResume,
    });
  };
  render() {
    const {
      searchCollapsed,
      resumeCollapsed,
      resumeDetailCollapsed,
      selectedShortlist,
      selectedResume,
      shortlistResumes,
      dialogMessage,
      filteredShortlist,
      isLoading,
      createCollapse,
      fetchingResumes,
      showOpportunities,
      searchKey,
      clonedShortlist = {},
    } = this.state;
    const { labels, isHelpActive } = this.props;
    return (
      <PageWrapper className="short-list-page">
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleYesClick}
                testId="delete-yes"
              >
                {labels.shortlistDeletionCurtainYESBtnText}
              </ConfirmDialog.Button>

              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              >
                {labels.shortlistDeletionCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {!this.props.onBoardShortlistId && (
          <Column collapse={searchCollapsed} className="col-1">
            <Column.Collapsed
              onClick={() => this.setState({ searchCollapsed: false })}
              testId={"expand-btn1"}
              tooltipPlace="left"
              text={labels.ViewTitleOpportunityShortlistSection}
              tooltipButton={labels.ToolTipShortlistExpandList}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpToolTipShortlistExpand}
            />
            <Column.Head>
              <SearchInput
                testId="search-input"
                value={searchKey}
                placeholder={labels.ShortlistSearchPlacehoder}
                onChange={this.handleSearchChange}
              />
              <Button
                className="collapseBtn"
                testId={"collapse-btn1"}
                tooltipPlace="left"
                tooltipButton={labels.ToolTipShortlistCollapse}
                onClick={() => this.setState({ searchCollapsed: true })}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistCollapse}
              />
            </Column.Head>
            <Column.Body>
              {filteredShortlist.map((item) => (
                <div
                  key={item.Id || item.FlowId}
                  test-data-id={`shortlist-list-item${
                    item.isActive ? "-activeItem" : ""
                  }`}
                  className={`tictell-list-item-container  ${
                    item.ShortlistId === selectedShortlist.ShortlistId
                      ? "selected-item"
                      : ""
                  } ${
                    item.ShortlistId === selectedShortlist.ShortlistId
                      ? "activeItem"
                      : ""
                  }`}
                >
                  <div
                    onClick={() => this.handleSelectShortlist(item)}
                    className="name-container flow-container"
                  >
                    <label
                      className={"pool-label"}
                      test-data-id={`collabs-list-owner-username`}
                    >
                      {item.ShortlistName}
                    </label>
                  </div>
                  {
                    <Button
                      onClick={() => this.handleCreateNewClick(item)}
                      className="clone-icon"
                      tooltipButton={"Clone Shortlist"}
                      tooltipHelp={"Clone Shortlist"}
                      tooltipPlace="left"
                      isHelpActive={isHelpActive}
                      testId="clone-shortlist-btn"
                    />
                  }
                  {
                    <Button
                      onClick={() =>
                        this.handleDeleteShortlist(item.ShortlistId)
                      }
                      className="closeBtn"
                      tooltipButton={"Delete Shortlist"}
                      tooltipHelp={"Delete Shortlist"}
                      tooltipPlace="left"
                      isHelpActive={isHelpActive}
                      testId="delete-shortlist-btn"
                    />
                  }
                </div>
              ))}
            </Column.Body>
          </Column>
        )}
        {!isEmpty(clonedShortlist) && (
          <Column collapse={createCollapse} className="col-2">
            <Column.Collapsed
              onClick={() => this.setState({ createCollapse: false })}
              testId={"expand-btn2"}
              tooltipButton={labels.TooltipShortlistCreateShortlistExpand}
              tooltipPlace="left"
              text={labels.ViewTitleShortlistCreateShortlist}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipShortlistCreateShortlistExpand}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={true}>
                {"Clone Shortlist"}
              </Column.HeaderTitle>
              <Button
                className="SaveCloudBtn"
                onClick={this.handleCreateNewShorlistClick}
                tooltipPlace="left"
                testId="button-shortlist-save"
                disabled={isLoading || !clonedShortlist.ShortlistName}
                isHelpActive={isHelpActive}
                tooltipButton={labels.TooltipShortlistCreateShortlistSaveButton}
                tooltipHelp={
                  labels.HelpTooltipShortlistCreateShortlistSaveButton
                }
              />
              <Button
                className="collapseBtn"
                testId={"collapse-btn2"}
                tooltipButton={labels.TooltipShortlistCreateShortlistCollapse}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistCreateShortlistCollapse}
                tooltipPlace="left"
                onClick={() => this.setState({ createCollapse: true })}
              />
            </Column.Head>
            <Column.Body className="form-editor">
              <label className="form-label">
                {labels.LabelShortlistCreateShortlistName}
              </label>
              <Input
                autoFocus
                type="text"
                testId="input-text-shortlist-name"
                className="input-text"
                value={clonedShortlist.ShortlistName || ""}
                onChange={(e) =>
                  this.setState({
                    clonedShortlist: {
                      ...clonedShortlist,
                      ShortlistName: e.target.value,
                    },
                  })
                }
              />
              {clonedShortlist.ShortlistResumes &&
              clonedShortlist.ShortlistResumes.length > 0 ? (
                <ShortlistResumes
                  fetchingResumes={fetchingResumes}
                  handleSelectShortlist={this.handleSelectShortlist}
                  selectedShortlist={clonedShortlist}
                  onResumeSelect={this.handleResumeSelect}
                  selectedResume={selectedResume}
                  shortlistResumes={clonedShortlist.ShortlistResumes}
                  onResumeDelete={this.onResumeDelete}
                />
              ) : (
                <EmptyInfo>{labels.emptyShortlistHeadsUp}</EmptyInfo>
              )}
            </Column.Body>
          </Column>
        )}

        {isEmpty(clonedShortlist) && (
          <Column collapse={resumeCollapsed} className="col-2">
            <Column.Collapsed
              onClick={() => this.setState({ resumeCollapsed: false })}
              testId={"expand-btn3"}
              tooltipButton={labels.TooltipShortlistResumeListExpand}
              tooltipPlace="left"
              text={labels.ViewTitleShortlistResult}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipShortlistResumeListExpand}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={selectedShortlist}>
                {fetchingResumes || !selectedShortlist.ShortlistId
                  ? ""
                  : shortlistResumes.length > 0
                  ? shortlistResumes.length +
                    " " +
                    labels.HeadingShorlistResumeListResult
                  : labels.HeadingShorlistResumeListNoResume}
              </Column.HeaderTitle>
              <Button
                className="collapseBtn"
                testId={"collapse-btn3"}
                tooltipButton={labels.TooltipShortlistResumeListCollapse}
                onClick={() => this.setState({ resumeCollapsed: true })}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistResumeListCollapse}
              />
            </Column.Head>
            <Column.Body className="flex">
              {selectedShortlist.ShortlistId ? (
                <ShortlistResumes
                  fetchingResumes={fetchingResumes}
                  handleSelectShortlist={this.handleSelectShortlist}
                  selectedShortlist={selectedShortlist}
                  onResumeSelect={this.handleResumeSelect}
                  selectedResume={selectedResume}
                  shortlistResumes={shortlistResumes}
                  onResumeDelete={this.handleResumeDelete}
                />
              ) : (
                <EmptyInfo>{labels.Company_Shortlist}</EmptyInfo>
              )}
            </Column.Body>
          </Column>
        )}
        {!showOpportunities && selectedResume.ResumeId && (
          <Column collapse={resumeDetailCollapsed} className="col-3">
            <Column.Collapsed
              tooltipButton={labels.TooltipShortlistResumeDetailExpand}
              text={labels.ViewTitleShortlistDetail}
              testId={"expand-btn5"}
              onClick={() => this.setState({ resumeDetailCollapsed: false })}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipShortlistResumeDetailExpand}
            />
            <Column.Head>
              <div className="heading">Resume Detail</div>
              <Button
                className="collapseBtn"
                testId={"collapse-btn5"}
                onClick={() =>
                  this.setState({
                    resumeDetailCollapsed: true,
                  })
                }
                tooltipButton={labels.ToolTipShortlistCollaspeResumeDetail}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistResumeDetailCollapse}
              />
            </Column.Head>
            <Column.Body>
              {fetchingResumes ? (
                <LoadingMaskRow />
              ) : (
                <ShortlistResumeDetail resume={selectedResume} />
              )}
            </Column.Body>
          </Column>
        )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive };
};

export default connect(mapStateToProps, { notificationAction })(Shortlist);
