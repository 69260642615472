import React from "react";
import moment from "moment";
import { DatePicker, Select } from "../../../../../common";
import { CustomInputNew } from "../../../../Tictell/views/components/CustomInput";

class TimeAndExpenses extends React.Component {
  render() {
    const{
      labels
    } = this.props;
    const{
      Dateformatelabel,
      SNAPSHOT_COLLABORATION_START,
      SNAPSHOT_COLLABORATION_END,
      SNAPSHOT_THIS_COLLABORATION,
      SNAPSHOT_AVG_WEEK
    } = labels;
    const{
      options,
      selectedOption,
      StartDate,
      EndDate,
      TimeDetail,
      ExpenseDetail
    } = this.props;
    return (
      <div className="time-expense-detail">
        <CustomInputNew label={"Timeperiod"} >
          <Select
              name="Category"
              className={`select-input select-category`}
              value={selectedOption}
              clearable={false}
              searchable={false}
              options={options}
              onChange={this.props.handleOptionChange}
            />
        </CustomInputNew>
        <div className="start-end">
          <CustomInputNew label={SNAPSHOT_COLLABORATION_START} >
            <label className="datepicker-wrapper">
                <DatePicker
                  selected={StartDate && moment(StartDate)}
                  name="expenseDate"
                  picketId="timeAndExpenseStartDate"
                  dateFormat="MMM Do YYYY"
                  placeholderText={Dateformatelabel}
                  onChange={this.props.handleStartDateChange}
                />
              </label>
          </CustomInputNew>
          <span>-</span>
          <CustomInputNew label={SNAPSHOT_COLLABORATION_END} >
            <div className="datepicker-wrapper">
                <DatePicker
                  selected={EndDate && moment(EndDate)}
                  picketId="timeAndExpenseEndDate"
                  name="expenseDate"
                  dateFormat="MMM Do YYYY"
                  placeholderText={Dateformatelabel}
                  onChange={this.props.handleEndDateChange}
                />
              </div>
          </CustomInputNew>
        </div>
        <CustomInputNew label={"Time"} >
          <div className="coll-time">
                <div>
                  <label>{SNAPSHOT_THIS_COLLABORATION}</label>
                  <label>{TimeDetail && TimeDetail.TotalTime}</label>
                  <label>{TimeDetail && TimeDetail.TotalEarning}</label>
                </div>
                <div>
                  <label>{SNAPSHOT_AVG_WEEK}</label>
                  <label>{TimeDetail && TimeDetail.AvgWeekTime}</label>
                  <label>{TimeDetail && TimeDetail.AvgWeekEarning}</label>
                </div>
          </div>
        </CustomInputNew>
        <CustomInputNew label={"Expenses"} >
          <div className="coll-expenses">
          <div>
                  <label>{SNAPSHOT_THIS_COLLABORATION}</label>
                  <label>{ExpenseDetail && ExpenseDetail.TotalExpenses}</label>
                </div>
                <div>
                  <label>{SNAPSHOT_AVG_WEEK}</label>
                  <label>{ExpenseDetail && ExpenseDetail.AvgWeekExpenses}</label>
                </div>
          </div>
        </CustomInputNew>
      </div>
    );
  }
}

export default TimeAndExpenses;
