import React from "react";
import {
  Column,
  Button,
  Select,
  EmptyInfo,
  LoadingMaskRow,
} from "../../../../common";
import {
  assignFlowApi,
  unAssignFlowApi,
  getAllAcceptedCollaborationsWithIProDetailApi,
  getAllSearcherCompaniesApi,
  getAllFlowsApi,
  getIProAssignedFlowApi,
} from "../../embarkApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import "./manage-ipros.scss";
import ImgSrc from "../../../../assets/images/present.svg";
import Flows from "../Flows/Flows";
class ManageIPros extends React.Component {
  state = {
    options: [
      { label: "", value: 1 },
      { label: "", value: 2 },
      { label: "", value: 3 },
    ],
    isCollapsed1: false,
    isCollapsed2: false,
    isCollapsed3: false,
    isCollapsed4: true,
    isCollapsed5: true,
    allFlows: [],
    allFlowGroups: [],
    allUserFlowsAndGroups: [],
    allWorkPlaces: [],
    currentAdd: 1,
    workplaceIpros: [],
    isWorkplaceIprosLoading: true,
  };
  componentDidMount() {
    const {
      EMBARK_ALL_FLOWS,
      EMBARK_ALL_FLOW_GROUPS,
      EMBARK_MYFLOWS_AND_GROUPFLOWS,
    } = this.props.labels;
    this.setState({
      options: [
        { label: EMBARK_ALL_FLOWS, value: 1 },
        { label: EMBARK_ALL_FLOW_GROUPS, value: 2 },
        { label: EMBARK_MYFLOWS_AND_GROUPFLOWS, value: 3 },
      ],
      selectedOption: { value: 1, label: EMBARK_ALL_FLOWS },
    });
    getAllSearcherCompaniesApi()
      .then((res) => {
        this.setState({
          allWorkPlaces: res.items,
        });
        this.handleSelectChange("selectedWorkplace", res.items[0]);
      })
      .catch((err) => console.log("Err ", err));
  }
  handleSelectChange = (name, selectedOption) => {
    if (name === "selectedWorkplace" && !selectedOption) {
      this.setState({
        isWorkplaceIprosLoading: false,
      });
      return;
    }
    this.setState({
      [`${name}`]: selectedOption,
      isWorkplaceIprosLoading: true,
    });
    const { UserCompanyId } = selectedOption;
    getAllAcceptedCollaborationsWithIProDetailApi(
      UserCompanyId,
      this.props.token
    )
      .then((res) => {
        this.setState({
          workplaceIpros: res.items.items,
          isWorkplaceIprosLoading: false,
        });
        this.handleIProClick(res.items.items[0]);
      })
      .catch((err) => console.log("Err ", err));
  };
  handleIProClick = (item) => {
    this.setState({
      selectedIpro: item,
      workplaceIpros: this.state.workplaceIpros.map((ipro) => ({
        ...ipro,
        isActive: ipro.CollaborationId == item.CollaborationId,
        isSelected: ipro.CollaborationId == item.CollaborationId,
      })),
    });
    const { token } = this.props;
    getIProAssignedFlowApi(
      item.IProId,
      this.state.selectedWorkplace.UserCompanyId,
      token
    )
      .then((res) => {
        const AssignedFlows = res.items;
        getAllFlowsApi(this.state.selectedWorkplace.UserCompanyId, token)
          .then((response) => {
            const allFlows = response.items.filter(
              (x) => !AssignedFlows.find((k) => k.FlowId == x.Id)
            );
            this.setState({
              selectedIpro: {
                ...this.state.selectedIpro,
                AssignedFlows,
                WorkplaceOtherFlows: allFlows,
              },
            });
          })
          .catch((err) => console.log("Err ", err));
      })
      .catch((err) => console.log("Err ", err));
  };
  handleFlowUnAssign = (item) => {
    unAssignFlowApi(item.IProAssignedFlowId, this.props.token)
      .then((res) => {
        if (res.success) {
          this.setState({
            selectedIpro: {
              ...this.state.selectedIpro,
              AssignedFlows: this.state.selectedIpro.AssignedFlows.filter(
                (a) => a.IProAssignedFlowId != item.IProAssignedFlowId
              ),
              WorkplaceOtherFlows: this.state.selectedIpro.WorkplaceOtherFlows.concat(
                {
                  ...item,
                  IProAssignedFlowId: 0,
                }
              ),
            },
          });
          const info = {
            status: "success",
            message: this.props.labels.EMBARK_FLOW_UNASSIGNED_SUCCESS,
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            status: "error",
            message: res.message,
          };
          this.props.notificationAction(info);
        }
      })
      .catch((err) => console.log("Err", err));
  };
  handleFlowAssign = (item) => {
    let assignModel = {
      ...item,
      IProId: this.state.selectedIpro.IProId,
      FlowId: item.Id || item.FlowId,
      IsCompleted: false,
    };
    assignFlowApi(assignModel, this.props.token).then((res) => {
      if (res.success) {
        assignModel = {
          ...assignModel,
          IProAssignedFlowId: res.items.IProAssignedFlowId,
        };
        this.setState({
          selectedIpro: {
            ...this.state.selectedIpro,
            AssignedFlows: this.state.selectedIpro.AssignedFlows.concat(
              assignModel
            ),
            WorkplaceOtherFlows: this.state.selectedIpro.WorkplaceOtherFlows.filter(
              (a) =>
                a.Id != assignModel.FlowId && a.FlowId != assignModel.FlowId
            ),
          },
        });
        const info = {
          status: "success",
          message: this.props.labels.EMBARK_FLOW_ASSIGNED_SUCCESS,
        };
        this.props.notificationAction(info);
      } else {
        const info = {
          status: "error",
          message: res.message,
        };
        this.props.notificationAction(info);
      }
    });
  };
  render() {
    const {
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      allWorkPlaces,
      selectedWorkplace,
      selectedIpro,
      isWorkplaceIprosLoading,
    } = this.state;

    const { labels, isHelpActive } = this.props;
    return (
      <div className="manageipros-view">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={labels.EMBARK_IPROS}
            onClick={() => this.setState({ isCollapsed1: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn1"
          />
          <Column.Head>
            <div className="heading">{labels.EMBARK_IPROS}</div>
            <Button
              className="collapseBtn"
              testId="collapsebtn1"
              onClick={() => this.setState({ isCollapsed1: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          {!isCollapsed1 && (
            <Select
              readonly={true}
              searchable={false}
              testId={"collaborationDropdown"}
              name="AllPresentation"
              className="select-input"
              value={
                selectedWorkplace && {
                  value: selectedWorkplace.UserCompanyId,
                  label: selectedWorkplace.CompanyName,
                }
              }
              className={`select-input`}
              placeholder={labels.embarkWorkplaceSelectPlaceholder}
              onChange={(selectedOption) =>
                this.handleSelectChange("selectedWorkplace", selectedOption)
              }
              options={allWorkPlaces.map((company) => ({
                ...company,
                value: company.UserCompanyId,
                label: company.CompanyName,
              }))}
              clearable={false}
            />
          )}
          <Column.Body>
            <React.Fragment>
              {isWorkplaceIprosLoading ? (
                <LoadingMaskRow />
              ) : (
                <React.Fragment>
                  {this.state.workplaceIpros &&
                  this.state.workplaceIpros.length > 0 ? (
                    <div className="tictell-list">
                      {this.state.workplaceIpros &&
                        this.state.workplaceIpros.map((item) => (
                          <div
                            key={item.CollaborationId}
                            test-data-id={`collaborations-list-item${
                              item.isActive ? "activeItem" : ""
                            }`}
                            className={`tictell-list-item-container  ${
                              item.isSelected ? "selected-item" : ""
                            } ${item.isActive ? "activeItem" : ""}`}
                            onClick={() => this.handleIProClick(item)}
                          >
                            <div
                              className={`dashItemImg ${
                                item.IProImage ? "" : "no-dp"
                              }`}
                            >
                              <img
                                className="roundImg"
                                src={item.IProImage ? item.IProImage : ImgSrc}
                                alt={"UserName"}
                              />
                            </div>
                            <div className="name-container">
                              <label
                                test-data-id={`collabs-list-owner-username`}
                              >
                                {item.IProName}
                              </label>
                              <label test-data-id={`collabs-list-owner-title`}>
                                {item.OpportunityName}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed2} className="col-2">
          <Column.Collapsed
            text={labels.EMBARK_ASSIGNED_FLOWS}
            onClick={() => this.setState({ isCollapsed2: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn2"
          />
          <Column.Head>
            <div className="heading">{labels.EMBARK_ASSIGNED_FLOWS}</div>
            <Button
              className="collapseBtn"
              testId="collapsebtn2"
              onClick={() => this.setState({ isCollapsed2: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          {!isCollapsed2 && selectedIpro && (
            <div
              className={`tictell-list-item-container activeItem collaboration-heading flow-detail-heading`}
            >
              <div
                className={`dashItemImg ${
                  selectedIpro.IProImage ? "" : "no-dp"
                }`}
              >
                <img
                  className="roundImg"
                  src={selectedIpro.IProImage ? selectedIpro.IProImage : ImgSrc}
                  alt={"UserName"}
                />
              </div>
              <div className="name-container">
                <label test-data-id={`collabs-details-owner-username`}>
                  {selectedIpro.IProName}
                </label>
                <label test-data-id={`collabs-details-owner-title`}>
                  {selectedIpro.OpportunityName}
                </label>
              </div>
            </div>
          )}
          <Column.Body>
            {selectedWorkplace &&
            selectedIpro &&
            selectedIpro.AssignedFlows &&
            selectedIpro.AssignedFlows.length > 0 ? (
              <React.Fragment>
                <Flows
                  flows={selectedIpro.AssignedFlows}
                  handleItemClick={() => {}}
                  isDelete={true}
                  handleFlowDelete={this.handleFlowUnAssign}
                  labels={labels}
                />
              </React.Fragment>
            ) : (
              <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
            )}
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed3} className="col-2">
          <Column.Collapsed
            text={labels.EMBARK_FLOWS_TO_ASSIGN}
            onClick={() => this.setState({ isCollapsed3: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn3"
          />
          <Column.Head>
            <div className="heading">{labels.EMBARK_FLOWS_TO_ASSIGN}</div>
            <Button
              className="collapseBtn"
              testId="collapsebtn3"
              onClick={() => this.setState({ isCollapsed3: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          <Column.Body>
            {selectedIpro &&
            selectedIpro.WorkplaceOtherFlows &&
            selectedIpro.WorkplaceOtherFlows.length > 0 ? (
              <React.Fragment>
                <Flows
                  flows={selectedIpro && selectedIpro.WorkplaceOtherFlows}
                  handleItemClick={() => {}}
                  isCheckbox={true}
                  handleCheckBoxClick={this.handleFlowAssign}
                  labels={labels}
                />
              </React.Fragment>
            ) : (
              <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
            )}
            }
          </Column.Body>
        </Column>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(ManageIPros);
