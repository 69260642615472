import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import Select from "react-select";
import { Column, Button, EmptyInfo } from "../../../../common";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import {
  getWeekExpensesLogsApi,
  deleteAnExpenseApi,
  updateExpenseLogApi,
  addExpenseLogApi,
  deleteExpenseFileApi,
  addExpensesheetApi,
} from "../../tictellApi";
import ExpenseDetail from "./ExpenseDetail";
import ExpenseList from "./ExpenseList";

let isAddingNew = false;
class ExpenseLogView extends React.Component {
  state = {
    IsCollapsed3: false,
    IsCollapsed4: false,
    ExpensesList: [],
    ExpenseCategories: [],
    CurrentExpense: {},
    ExpenseSheetStatusId: 4,
    Amount: "",
    StatusId: "",
    isLoading: false,
  };
  componentDidMount() {
    this.getWeekExepnseLogs(
      this.props.selectedCollaboration,
      this.props.startDate,
      this.props.endDate
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedCollaboration != this.props.selectedCollaboration ||
      prevProps.startDate != this.props.startDate
    ) {
      this.getWeekExepnseLogs(
        this.props.selectedCollaboration,
        this.props.startDate,
        this.props.endDate
      );
    }
    if (
      prevState.CurrentExpense.ExpenseAttachments !=
      this.state.CurrentExpense.ExpenseAttachments
    ) {
      if (
        this.state.CurrentExpense.ExpenseAttachments &&
        this.state.CurrentExpense.ExpenseAttachments.length > 0
      ) {
        this.saveExpense(this.state.CurrentExpense);
      }
    }
  }
  handleExpenseItemClick = (item) => {
    if (!item) {
      this.setState({
        CurrentExpense: {
          ExpenseAttachments: [],
        },
      });
      return;
    }
    this.setState({
      CurrentExpense: {
        ...item,
        ExpenseAttachments: [],
      },
      ExpensesList: this.state.ExpensesList.map((a) =>
        a.ExpenseId == item.ExpenseId
          ? { ...a, isSelected: true, isActive: true }
          : { ...a, isSelected: false, isActive: false }
      ),
      IsCollapsed4: false,
    });
  };
  handleExpenseDelete = (item) => {
    deleteAnExpenseApi(item.ExpenseId, this.props.token)
      .then((res) => {
        if (res.success) {
          const currentExpenseLogId = this.state.CurrentExpense.ExpenseId;
          const itemIndex =
            this.state.ExpensesList.indexOf(item) + 1 ==
            this.state.ExpensesList.length
              ? 0
              : this.state.ExpensesList.indexOf(item);
          this.setState({
            CurrentExpense:
              this.state.CurrentExpense.ExpenseId == item.ExpenseId
                ? { ExpenseAttachments: [] }
                : this.state.CurrentExpense,
            ExpensesList: this.state.ExpensesList.filter(
              (a) => a.ExpenseId != item.ExpenseId
            ),
            Amount:
              parseFloat(this.state.Amount.split(" ")[0]) -
              parseFloat(item.CollaborationCurrencyAmount) +
              " " +
             this.state.Amount.split(' ')[1],
          });
          if (currentExpenseLogId == item.ExpenseId) {
            this.handleExpenseItemClick(this.state.ExpensesList[itemIndex]);
          }
          if (this.state.ExpensesList.length == 0) {
            this.setState({ ExpenseSheetStatusId: 4 });
          }
        }
      })
      .catch((err) => console.log("Err ", err));
  };

  getWeekExepnseLogs(selectedCollaboration, startDate, endDate) {
    this.setState({ isLoading: true });
    getWeekExpensesLogsApi(
      selectedCollaboration.value,
      new moment(startDate).format("DD/MM/YYYY"),
      new moment(endDate).format("DD/MM/YYYY"),
      this.props.token
    )
      .then((res) => {
        if (res.success) {
          const ExpensesListUpdated = res.items.ExpenseLogs.map((ex) => {
            return {
              ...ex,
              CategoryName: this.props.ExpenseCategories.find(
                (a) => a.ExpenseCategoryId == ex.ExpenseCategoryId
              ),
              CurrencyName: this.props.Currencies.find(
                (a) => a.CurrencyId == ex.CurrencyId
              ),
              CurrencyName: !isNaN(ex.CurrencyId)
                ? this.props.Currencies.find(
                    (b) => b.CurrencyId == ex.CurrencyId
                  )
                : ex.Currency,
              SpentOn: moment(ex.SpentOn, "MM/DD/YYYY"),
            };
          });
          this.setState({
            StatusId: res.items.StatusId,
            ExpensesList: ExpensesListUpdated,
            ExpenseSheetStatusId: res.items.StatusId,
            isLoading: false,
            Amount: `${res.items.Amount.split(" ")[0]} ${
              !isNaN(res.items.Amount.split(" ")[1])
                ? this.props.Currencies.find(
                    (b) => b.CurrencyId == res.items.Amount.split(" ")[1]
                  ).Name
                : res.items.Amount.split(" ")[1]
            }`,
          });
          if (res && res.items.ExpenseLogs[0])
            this.handleExpenseItemClick(ExpensesListUpdated[0]);
          else {
            this.setState({
              CurrentExpense: {
                ExpenseAttachments: [],
              },
              isLoading: false,
            });
          }
        } else {
          const info = {
            status: "error",
            message: this.props.labels.Error_Message,
          };
          this.props.notificationAction(info);
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        const info = {
          status: "error",
          message: this.props.labels.Error_Message,
        };
        this.props.notificationAction(info);
        this.setState({ isLoading: false });
      });
  }
  handleExpenseDateChange = (d) => {
    const CurrentExpense = {
      ...this.state.CurrentExpense,
      SpentOn: d,
    };
    this.setState({
      CurrentExpense,
    });
    this.saveExpense(CurrentExpense);
  };
  handleExpenseSelectChange = (selectedOption, name) => {
    const CurrentExpense = {
      ...this.state.CurrentExpense,
      [`${name}Id`]: selectedOption.value,
    };
    this.setState({
      CurrentExpense,
    });
    this.saveExpense(CurrentExpense);
  };
  handleExpenseInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "Amount" && isNaN(value)) return;
    this.setState({
      CurrentExpense: {
        ...this.state.CurrentExpense,
        [name]: name == "Amount" ? value.trim() : value,
      },
    });
  };

  handleAddNewExpense = () => {
    isAddingNew = true;
    this.setState({
      CurrentExpense: {
        ExpenseId: 0,
        CollaborationId: this.props.selectedCollaboration.value,
        ExpenseAttachments: [],
      },
      IsCollapsed4: false,
    });
  };

  handleExpenseInputBlur = (e) => {
    const { CurrentExpense } = this.state;
    this.saveExpense(CurrentExpense);
  };

  saveExpense(CurrentExpense) {
    isAddingNew = false;
    let { ExpenseSheetStatusId } = this.state;
    const { token } = this.props;
    if (ExpenseSheetStatusId == 1 || ExpenseSheetStatusId == 2) return;
    CurrentExpense = {
      ...CurrentExpense,
      SpentOn: moment(CurrentExpense.SpentOn).format("DD/MM/YYYY"),
      CollaborationId: this.props.selectedCollaboration.value,
      CreatedOnWeekDateString: moment(this.props.startDate).format(
        "DD/MM/YYYY"
      ),
      CategoryName: CurrentExpense?.CategoryName?.Title,
    };
    let {
      ExpenseId,
      Comment,
      SpentOn,
      Amount,
      CurrencyId,
      ReceiptPath,
      CollaborationId,
      UserId,
      ExpenseCategoryId,
    } = CurrentExpense;

    if (
      !SpentOn ||
      !Amount ||
      !CurrencyId ||
      !ExpenseCategoryId ||
      !CollaborationId
    ) {
      return;
    } else {
      if (ExpenseId > 0) {
        this.setState({
          isLoading: true,
        });
        updateExpenseLogApi(CurrentExpense, token)
          .then((res) => {
            if (res.success) {
              const NewCurrentExpense = {
                ...CurrentExpense,
                SpentOn: moment(res.items.SpentOn, "MM/DD/YYYY"),
                ExpenseId: res.items.ExpenseId,
                CategoryName: this.props.ExpenseCategories.find(
                  (a) => a.ExpenseCategoryId == res.items.ExpenseCategoryId
                ),
                CurrencyName: this.props.Currencies.find(
                  (a) => a.CurrencyId == res.items.CurrencyId
                ),
                isSelected:
                  this.state.CurrentExpense.ExpenseId == res.items.ExpenseId
                    ? true
                    : false,
                isActive:
                  this.state.CurrentExpense.ExpenseId == res.items.ExpenseId
                    ? true
                    : false,
                ExpenseAttachments: [],
                ExpenseFiles: res.items.ExpenseFiles,
              };
              const newExpensesList = this.state.ExpensesList.map((a) =>
                a.ExpenseId == res.items.ExpenseId ? NewCurrentExpense : a
              );
              newExpensesList.sort(
                (a, b) =>
                  moment(b.SpentOn).valueOf() - moment(a.SpentOn).valueOf()
              );
              this.setState({
                CurrentExpense:
                  this.state.CurrentExpense.ExpenseId ==
                  NewCurrentExpense.ExpenseId
                    ? NewCurrentExpense
                    : this.state.CurrentExpense,
                ExpensesList: newExpensesList,
                Amount:
                  newExpensesList.reduce(
                    (totalAmount, expense) =>
                      totalAmount + expense.CollaborationCurrencyAmount,
                    0
                  ) +
                  " " +
                  this.state.Amount.split(" ")[1],
                isLoading: false,
              });

              const info = {
                status: this.props.labels.Success_Message,
                message: this.props.labels.Expense_Update_Message,
              };
              this.props.notificationAction(info);
            } else {
              this.setState({ isLoading: false });
              const info = {
                status: this.props.labels.Error_Message,
                message: res.message,
              };
              this.props.notificationAction(info);
            }
          })
          .catch((err) => console.log("Err ", err));
      } else {
        this.setState({ isLoading: true });
        addExpenseLogApi(CurrentExpense, token)
          .then((res) => {
            if (res.success) {
              const NewCurrentExpense = {
                ...CurrentExpense,
                ExpenseId: res.items.ExpenseId,
                SpentOn: moment(res.items.SpentOnDate),
                SpentOnDate: moment(res.items.SpentOnDate),
                ExpenseId: res.items.ExpenseId,
                CategoryName: this.props.ExpenseCategories.find(
                  (a) => a.ExpenseCategoryId == res.items.ExpenseCategoryId
                ),
                CurrencyName: this.props.Currencies.find(
                  (a) => a.CurrencyId == res.items.CurrencyId
                ),
                isSelected: true,
                isActive: true,
                ExpenseAttachments: [],
                CollaborationCurrencyAmount:
                  res.items.CollaborationCurrencyAmount,
                CollaborationCurrency: !isNaN(res.items.CollaborationCurrency)
                  ? this.props.Currencies.find(
                      (b) => b.CurrencyId == res.items.CollaborationCurrency
                    ).Name
                  : res.items.Currency,
              };
              let NewExpenseList = this.state.ExpensesList.concat(
                NewCurrentExpense
              );
              NewExpenseList = NewExpenseList.map((a) =>
                a.ExpenseId == NewCurrentExpense.ExpenseId
                  ? { ...a, isSelected: true, isActive: true }
                  : { ...a, isSelected: false, isActive: false }
              );
              NewExpenseList.sort(
                (a, b) =>
                  moment(b.SpentOn).valueOf() - moment(a.SpentOn).valueOf()
              );
              this.setState({
                CurrentExpense: NewCurrentExpense,
                ExpensesList: NewExpenseList,
                isLoading: false,
                Amount:
                  parseFloat(this.state.Amount.split(" ")[0]) +
                  parseFloat(res.items.CollaborationCurrencyAmount) +
                  " " +
                  this.state.Amount.split(" ")[1],
              });
              const info = {
                status: this.props.labels.Success_Message,
                message: this.props.labels.Expense_Add_Message,
              };
              this.props.notificationAction(info);
            } else {
              this.setState({ isLoading: false });
              const info = {
                status: this.props.labels.Error_Message,
                message: res.message,
              };
              this.props.notificationAction(info);
            }
          })
          .catch((err) => console.log("Err ", err));
      }
    }
  }
  //Expense Chnages by Me End Here
  //

  handleSendExpenseSheet = () => {
    this.setState({
      isLoading: true,
    });
    const { selectedCollaboration, startDate, endDate, token } = this.props;
    addExpensesheetApi(
      selectedCollaboration.value,
      new moment(startDate).format("DD/MM/YYYY"),
      new moment(endDate).format("DD/MM/YYYY"),
      token
    ).then((res) => {
      if (res.success) {
        const info = {
          status: this.props.labels.Success_Message,
          message: this.props.labels.Expensesheet_Add_Message,
        };
        this.props.notificationAction(info);
        this.setState({
          ExpenseSheetStatusId: 1,
        });
      } else {
        const info = {
          status: this.props.labels.Error_Message,
          message: res.message,
        };
        this.props.notificationAction(info);
      }
      this.setState({
        isLoading: false,
      });
    });
  };

  //
  handleAddAttachment = (e) => {
    const types = ["pdf", "png", "jpg", "jpeg", "docx", "xlsx", "txt"];
    const { labels, notificationAction } = this.props;
    let UpdatedCurrentExpenseAttachments = this.state.CurrentExpense
      .ExpenseAttachments;
    var count = 0;
    var filesArrayLength = e.target.files.length;

    Object.values(e.target.files).forEach((element) => {
      var fileData = element;
      var fileTypeDetail = fileData.name.split(".");
      var fileName = fileTypeDetail[fileTypeDetail.length - 2];
      var fileType = fileTypeDetail[fileTypeDetail.length - 1];
      const me = this;
      if (types.indexOf(fileType.toLowerCase()) > -1) {
        var reader = new FileReader();
        reader.onload = (function(theFile) {
          return function(e) {
            var fileDataInBytes = e.target.result.split(",")[1];
            UpdatedCurrentExpenseAttachments = UpdatedCurrentExpenseAttachments?.concat(
              { Name: fileName, Type: fileType, Data: fileDataInBytes }
            );
            if (filesArrayLength == count + 1) {
              me.setState({
                CurrentExpense: {
                  ...me.state.CurrentExpense,
                  ExpenseAttachments: UpdatedCurrentExpenseAttachments,
                },
              });
            }
            count++;
          };
        })(fileData);
        reader.readAsDataURL(fileData);
      } else {
        const info = {
          message: this.props.labels.Valid_File_Message,
          status: this.props.labels.Error_Message,
        };
        notificationAction(info);
      }
    });
    e.target.value = null;
  };
  handleExpenseFileDelete = (item) => {
    this.setState({
      isLoading: true,
    });
    deleteExpenseFileApi(item.ExpenseFileId, this.props.token).then((res) => {
      if (res.success) {
        const info = {
          status: this.props.labels.Success_Message,
          message: this.props.labels.Expense_Delete_Message,
        };
        const expenseFromList = this.state.ExpensesList.find(
          (x) => x.ExpenseId == item.ExpenseId
        );
        const CurrentExpense = {
          ...expenseFromList,
          ExpenseFiles: expenseFromList.ExpenseFiles.filter(
            (x) => x.ExpenseFileId != item.ExpenseFileId
          ),
        };
        this.setState({
          isLoading: false,
          CurrentExpense:
            this.state.CurrentExpense.ExpenseId == item.ExpenseId
              ? CurrentExpense
              : this.state.CurrentExpense,
          ExpensesList: this.state.ExpensesList.map((x) =>
            x.ExpenseId == expenseFromList.ExpenseId ? CurrentExpense : x
          ),
          Amount:
            parseFloat(this.state.Amount) -
            parseFloat(item.CollaborationCurrencyAmount) +
            " " +
            item.Currency,
        });
        this.props.notificationAction(info);
      } else {
        const info = {
          status: this.props.labels.Error_Message,
          message: res.message,
        };
        this.props.notificationAction(info);
      }
    });
  };
  render() {
    const {
      startDate,
      endDate,
      labels,
      isHelpActive,
      Currencies,
      ExpenseCategories,
      collaborationsList,
      selectedCollaboration,
      handleCollabSelectChange,
      handleSelectedDateChange,
    } = this.props;
    const { IsCollapsed3, IsCollapsed4, Amount, isLoading } = this.state;
    return (
      <div className="expenselog-view">
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        <Column collapse={false} className="col-1 expense-list-col">
          <Column.Collapsed
            text={labels.timeStampDetails}
            onClick={() =>
              this.props.onStateChangeAction({ formCollapsed: false })
            }
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipFeedBackExpandCreate}
            tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
            tooltipPlace="left"
          />
          <Column.Head>
            <Select
              name="Category"
              className={`select-input select-hourlyRate`}
              value={selectedCollaboration}
              onChange={handleCollabSelectChange}
              clearable={false}
              searchable={false}
              options={collaborationsList}
            />
          </Column.Head>
          <div className={`date-change-wrapper`}>
            <div
              className={`date-header status-${this.state.ExpenseSheetStatusId}`}
            >
              <Button
                className="prevBtn"
                testId={`prev-date`}
                onClick={() => handleSelectedDateChange(1)}
                tooltipplace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.previousWeekExpenses}
                testIdHelp={"prev-date-help"}
                tooltipButton={labels.previousWeekExpenses}
              />
              <div className="date-heading">
                <div>
                  <label>{`${startDate.format("MMM Do")} - ${endDate.format(
                    "MMM Do"
                  )}`}</label>
                  <label>{`${moment(startDate).format("YYYY")} Week ${moment(
                    startDate
                  ).format("W")}`}</label>
                </div>
                <div className="expense-amount">
                  <label>{Amount}</label>
                </div>
              </div>
              <Button
                testId={"nextweekBtn"}
                className={`nextBtn ${
                  moment(startDate).format("DD/MM/YYYY") ==
                  new moment().format("DD/MM/YYYY")
                    ? "disabled"
                    : ""
                }`}
                onClick={() => handleSelectedDateChange(2)}
                tooltipplace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.nextWeekExpenseBtn}
                testIdHelp={"nextweekBtn-help"}
                tooltipButton={labels.nextWeekExpenseBtn}
              />
            </div>
            <Button
              testId={"CurrentWeekExpensebtn"}
              className="homeBtn"
              onClick={() => handleSelectedDateChange(3)}
              tooltipplace="left"
              isHelpActive={isHelpActive}
              tooltipHelp={labels.nextWeekExpenseBtn}
              testIdHelp={"CurrentWeekExpensebtn-help"}
              tooltipButton={labels.nextWeekExpenseBtn}
            >
              <i className="fa fa-home"></i>
            </Button>
          </div>
          <Column.Body>
            {this.state.ExpensesList && (
              <ExpenseList
                labels={labels}
                expenseList={this.state.ExpensesList}
                onItemClick={this.handleExpenseItemClick}
                handleExpenseDelete={this.handleExpenseDelete}
                handleAddNewExpense={this.handleAddNewExpense}
                ExpenseSheetStatusId={this.state.ExpenseSheetStatusId}
                handleSendExpenseSheet={this.handleSendExpenseSheet}
                selectedCollaboration={selectedCollaboration}
                startDate={startDate}
                endDate={endDate}
                isHelpActive={isHelpActive}
              />
            )}
          </Column.Body>
        </Column>
        <Column collapse={IsCollapsed4} className="col-2">
          <Column.Collapsed
            text={labels.IPRO_ExpenseDetails}
            onClick={() => this.setState({ IsCollapsed4: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipDetailsExpandCreate}
            tooltipHelp={labels.HlpTooltipDetailsExpandCreateButton}
            tooltipPlace="left"
            testId={"column-4-expand"}
          />
          <Column.Head>
            <div className="heading">{labels.IPRO_ExpenseDetails}</div>
            <Button
              className="collapseBtn"
              onClick={() => this.setState({ IsCollapsed4: true })}
              tooltipButton={labels.ToolTipDetailsCollaspeCreate}
              tooltipHelp={labels.HlpTooltipDetailsCollapseCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-4-collapse"}
            />
          </Column.Head>
          <Column.Body>
            {!(
              (!!selectedCollaboration &&
                moment(startDate).isSameOrAfter(
                  moment(selectedCollaboration.StartDate).startOf("w")
                ) &&
                moment(endDate).isSameOrBefore(
                  moment(selectedCollaboration.EndDate).endOf("w")
                )) ||
              this.state.CurrentExpense.ExpenseId > 0
            ) ? (
              <EmptyInfo>
                {
                  "You can only add expense between start and end date of you collaboration"
                }
              </EmptyInfo>
            ) : (
              <ExpenseDetail
                labels={labels}
                currentExpense={this.state.CurrentExpense}
                currenciesList={Currencies}
                expenseCategoriesList={ExpenseCategories}
                handleExpenseDateChange={this.handleExpenseDateChange}
                handleExpenseInputChange={this.handleExpenseInputChange}
                handleExpenseSelectChange={this.handleExpenseSelectChange}
                handleExpenseInputBlur={this.handleExpenseInputBlur}
                onAddAttachment={this.handleAddAttachment}
                handleExpenseFileDelete={this.handleExpenseFileDelete}
                status={this.state.StatusId}
                isDisabled={
                  this.state.ExpenseSheetStatusId == 1 ||
                  this.state.ExpenseSheetStatusId == 2
                    ? true
                    : false
                }
                startDate={startDate}
                endDate={endDate}
                selectedCollaboration={selectedCollaboration}
              ></ExpenseDetail>
            )}
          </Column.Body>
        </Column>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, feedback, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(ExpenseLogView);
