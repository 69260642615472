import axios from "axios";
import { ApiUrl } from "../../api/apiUrls";

export const getAllMessagesApi = ({ UserId, MessageType }) => {
  return axios
    .get(
      `${
        ApiUrl.Message.GetMessage
      }?page=1&start=0&limit=25&filter=[{"property":"MessageType","value":"${MessageType}"},{"property":"UserId","value":"${UserId}"}]`
    )
    .then(({ data }) => {
      return data;
    });
};

//New message
export const getInboxMessagesApi = () => {
  return axios.get(ApiUrl.Message.GetInboxMessage).then(({ data }) => data);
};

//IPro Message Sent
export const getSentMessagesApi = () => {
  return axios.get(ApiUrl.Message.GetSentMessage).then(({ data }) => data);
};

export const unreadMessageCount = () => {
  return axios.get(ApiUrl.Message.UnReadMessageCount).then(({ data }) => data);
};

export const updateMessageApi = ({ message }) => {
  return axios.put(ApiUrl.Message.UpdateMessage, message).then(({ data }) => {
    return data;
  });
};

export const sendMessage = ({ message }) => {
  return axios
    .post(ApiUrl.Message.SendMessage, message)
    .then(({ data }) => data);
};

export const SendEmailToUser = (email) => {
  return axios
    .post(ApiUrl.Message.SendEmailToUser, email)
    .then(({ data }) => data);
};

export const getAllUserApi = ({ IsFreelancer }) => {
  return axios
    .get(`${ApiUrl.User.GetAllUsersForMsgs}?IsFreelancer=${IsFreelancer}`)
    .then(({ data }) => data);
};

export const getRequestType = ({ reqId }) => {
  return axios
    .get(`${ApiUrl.Message.RequestType}?reqId=${reqId}`)
    .then(({ data }) => {
      return data;
    });
};

export const getRequestStatus = ({ reqId, resumeId }) => {
  return axios
    .get(`${ApiUrl.Message.GetRequestStatus}?requestId=${reqId}`)
    .then(({ data }) => {
      return data;
    });
};
export const getCollabStatus = ({ colId }) => {
  return axios
    .get(`${ApiUrl.Message.GetCollabStatus}?collId=${colId}`)
    .then(({ data }) => {
      return data;
    });
};