import React, { Component } from "react";
import "./companyformeditor.scss";
import {
  EmptyInfo,
  LoadingMask,
  Input,
  Textarea,
  DefaultButton,
  Select
} from "../../../common";
import ImgSrc from "../../../assets/images/company.svg";
// import Select from "react-select";

class CompanyFormEditor extends Component {
  viewRender = () => {
    const {
      companyForm,
      labels,
      onFormFieldChange,
      onFormSelectChange,
      industries,
      countries,
      userPhoneNumbers,
      onCompanySave,
      onAvatarChange,
      getImgSrc,
      onFormFieldBlur
    } = this.props;

    if (companyForm && companyForm.UserCompanyId) {
      const {
        CompanyName,
        invalidCompanyName,
        CompanyAddress,
        IndustryId,
        invalidRole,
        UserPhonenumberId,
        CountryId,
        invalidWebUrl,
        WebUrl,
        Detail,
        Vat,
        IsDefaultCompany,
        Logo,
      } = companyForm;
      const avatar = companyForm && companyForm.Logo;
      return (
        <div className="company-form">
          <div className="companyBigIcon">
            {avatar && <button className="closeBtn" onClick={getImgSrc} />}
            <div className={`dashItemImg ${avatar ? "" : "no-dp"}`}>
              <img
                className="roundeImg"
                src={avatar ? avatar : ImgSrc}
                alt="company"
              />
            </div>
            <div className="uploadBtn" test-data-id="company-avatar-btn">
              <input
                test-data-id="company-avatar-input"
                type="file"
                name="companyAvatar"
                className="input-upload-file"
                onChange={onAvatarChange}
                accept="image/*"
              />
            </div>
          </div>

          <div className="form-row">
            <label className="form-label">{labels.NAME_LABEL}:</label>
            <Input
              autoFocus={invalidCompanyName}
              type="text"
              name="CompanyName"
              className={`input-text ${invalidCompanyName ? "inValid" : ""}`}
              value={CompanyName ? CompanyName : ""}
              placeholder={labels.companyNamePlaceholder}
              onChange={onFormFieldChange}
              onBlur={onFormFieldChange}
              test-data-id="input-text-company-name"
              maxLength="50"
            />
          </div>

          <div className="form-row">
            <label className="form-label">{labels.ADDRESS_LABEL}:</label>
            <Input
              type="text"
              name="CompanyAddress"
              className="input-text"
              value={CompanyAddress ? CompanyAddress : ""}
              placeholder={labels.companyAddressPlaceholder}
              onChange={onFormFieldChange}
              test-data-id="input-text-company-Address"
              maxLength="100"
            />
          </div>

          <div className="form-row">
            <label className="form-label">{labels.INDUSTRY_LABEL}:</label>
            <Select
              name="IndustryId"
              className={`company-industry select-input ${
                invalidRole ? "inValid" : ""
              }`}
              value={IndustryId || ""}
              placeholder={labels.companyIndustryPlaceholder}
              onChange={selectedOption =>
                onFormSelectChange("IndustryId", selectedOption)
              }
              options={industries}
              clearable={false}
            />
          </div>

          <div className="form-row">
            <label className="form-label">{labels.COUNTRY_LABEL}:</label>
            <Select
              name="CountryId"
              className="company-country select-input"
              value={CountryId || ""}
              placeholder={labels.companyCountryPlaceholder}
              onChange={selectedOption =>
                onFormSelectChange("CountryId", selectedOption)
              }
              options={countries}
              clearable={false}
            />
          </div>

          <div className="form-row">
            <label className="form-label">{labels.PHONE_LABEL}:</label>

            <Select.Creatable
              name="UserPhonenumberId"
              className="company-phone select-input"
              value={UserPhonenumberId || ""}
              placeholder={labels.companyPhoneNumberPlaceholder}
              promptTextCreator={value => `Create New Number ${value}`}
              onChange={selectedOption =>
                onFormSelectChange("UserPhonenumberId", selectedOption)
              }
              autoBlur={true}
              options={userPhoneNumbers}
              clearable={false}
            />
          </div>

          <div className="form-row">
            <label className="form-label">{labels.WEB_URL_LABEL}:</label>
            <Input
              type="text"
              name="WebUrl"
              className={`input-text ${invalidWebUrl ? "inValid" : ""}`}
              value={WebUrl ? WebUrl : ""}
              placeholder={labels.companyURLPlaceholder}
              onChange={onFormFieldChange}
              onBlur={onFormFieldBlur}
              test-data-id="input-text-company-weburl"
              maxLength="100"
            />
          </div>

          <div className="form-row">
            <label className="form-label">{labels.VAT_LABEL}:</label>
            <Input
              type="text"
              name="Vat"
              className="input-text"
              value={Vat ? Vat : ""}
              placeholder={labels.companyVATPlaceholder}
              onChange={onFormFieldChange}
              test-data-id="input-text-company-Vat"
              maxLength="50"
            />
          </div>

          <div className="form-row">
            <div className="companyCheckbox">
              <label className="companyCheckboxLabel">
                <Input
                  type="checkbox"
                  name="IsDefaultCompany"
                  className={IsDefaultCompany ? "checked" : "input-checkbox"}
                  value={IsDefaultCompany}
                  checked={IsDefaultCompany ? IsDefaultCompany : false}
                  onChange={onFormFieldChange}
                  test-data-id="input-checkbox-company-defaultCompany"
                />
                {labels.companyCheckboxMakeDefault}
              </label>
            </div>
          </div>

          <Textarea
            name="Detail"
            className="textarea-field"
            value={Detail ? Detail : ""}
            placeholder={labels.companyDescriptionPlaceholder}
            onChange={onFormFieldChange}
            test-data-id="input-button-company-detail"
          />
        </div>
      );
    }
    return (
      <EmptyInfo testId="company-empty-detail">
        {labels.EMPTY_COMPANY_INFO}
      </EmptyInfo>
    );
  };

  render() {
    const { isLoading } = this.props;
    return (
      <div className="company-form-editor">
        {isLoading && <LoadingMask />}
        {this.viewRender()}
      </div>
    );
  }
}

export default CompanyFormEditor;
