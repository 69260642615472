import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./navbutton.scss";
class NavLinkButton extends Component {
  render() {
    const {
      children,
      classIcon,
      className,
      to,
      testId,
      onClick,
      disabled
    } = this.props;
    return (
      <NavLink
        activeClassName={`${!disabled && "activeBtn"}`}
        to={!disabled && to}
        onClick={onClick}
        className={`nav-button ${className}`}
        test-data-id={testId}
        disabled={disabled}
      >
        <i className={classIcon} />
        <span>{children}</span>
      </NavLink>
    );
  }
}

NavLinkButton.defaultProps = {
  className: "",
  to: "/dashboard"
};
export default NavLinkButton;
