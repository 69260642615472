import React from "react";

const InputPassword = ({
  name,
  className = "",
  value,
  placeholder,
  testId,
  onChange,
  onBlur,
  autoFocus
}) => (
  <input
    autoFocus={autoFocus}
    type="password"
    name={name}
    className={`input-text ${className}`}
    value={value}
    placeholder={placeholder}
    test-data-id={testId}
    autoComplete="off"
    onChange={onChange}
    onBlur={onBlur}
  />
);

export default InputPassword;
