import React from "react";
import ReactHtmlParser from "react-html-parser";
import "./emptyResume.scss";
import { Card, Row, Col } from "antd";

const EmptyResume = ({ text }) => {
  return (
    <Row justify="center" className="empty-card-main">
      <Col xs={24}>
        <Card className="empty-message">
          <p>{text}</p>
        </Card>
      </Col>
    </Row>
  );
};

export default EmptyResume;
