import React from "react";
import { Column, Button } from "../../../../common";
import {
  GetUserDetailByResumeAndRequestId,
  getAllAcceptedRequestsApi,
  GetAllResumeComments,
  AddReviewApi,
  UpdateReviewApi,
  DeleteReviewApi,
  UpdateOrderApi,
  GetAllResumeOrder,
  GetAllResumeReviewsByOpportunityApi,
} from "../../assortApi";
import { sendMessage, SendEmailToUser } from "../../../Message/messageApi";
import CreateMessage from "../../../Message/MessageNew/components/CreateMessage";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import "./opportuinty.scss";
import ResumeDetail from "../../components/ResumeDetail";
import Presentations from "../../components/PresentationDetails";
import moment from "moment";
import ClampLines from "react-clamp-lines";
import { CustomInputNew } from "../../../Tictell/views/components/CustomInput";
import { map, join, isEmpty } from "lodash";
const renderProfiles = (resume) => {
  const profileValues = resume.Profiles.map((item) => item.ProfileValue);
  return join(profileValues, ", ");
};
// const renderSkills = (resume) => {
//   const skillsValues = resume.Skills.map((item) => item.SkillValue);
//   return join(skillsValues, ", ")
// };

const SortableList = SortableContainer(({ items, handleResumeSelect }) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.ResumeId}`}
          index={index}
          item={value}
          handleResumeSelect={handleResumeSelect}
        />
      ))}
    </ul>
  );
});

const SortableItem = SortableElement(({ item, handleResumeSelect }) => {
  return (
    <div
      key={item.ResumeId}
      test-data-id={`collaborations-list-item${
        item.isActive ? "activeItem" : ""
      }`}
      className={`tictell-list-item-container  ${
        item.isSelected ? "selected-item" : ""
      } ${item.isActive ? "activeItem" : ""}`}
      onClick={() => handleResumeSelect(item)}
    >
      {item.Order ? (
        <Button
          className="reorderBtn"
          tooltipButton="Drag and drop resume to reorder"
        >
          {item.Order}
        </Button>
      ) : (
        ""
      )}
      <div className="name-container">
        <label
          className="resume-header"
          test-data-id={`collabs-list-owner-username`}
        >
          <div>{renderProfiles(item)}</div>
          <span
            className={`${
              item.Reviews && item.Reviews.length > 0 ? "comment-icon" : ""
            }`}
          />
        </label>
        <label test-data-id={`collabs-list-owner-title`}>
          <div className="location">
            <ClampLines text={item.Region} lines={1} buttons={false} />
          </div>
          {/* <div className="skills">{renderSkills(item)}</div> */}
        </label>
      </div>
    </div>
  );
});

class Collaborations extends React.Component {
  state = {
    options: [
      { label: "", value: 1 },
      { label: "", value: 2 },
      { label: "", value: 3 },
    ],
    acceptedCollaborations: [],
    selectedUser: [],
    selectedResume: [],
    allCollaborations: [],
    isCollapsed1: false,
    isCollapsed2: false,
    isCollapsed3: true,
    isCollapsed4: true,
    isCollapsed5: true,
    formCollapsed: false,
    allTimeSheets: [],
    weekTimelogsList: [],
    weekExpenselogsList: [],
    currentExpenselog: {},
    currentTimeReport: {
      StatusId: 1,
      isEmpty: true,
      Amount: "",
      Time: "",
    },
    currentTimelog: {},
    TimeSheetStatusId: 2,
    ExpenseSheetStatusId: 2,
    isLoading: false,
    selectedCollaboration: {},
    durationOptions: [
      { label: "Current Month", value: 1 },
      { label: "Last Month", value: 2 },
      { label: "Year to date", value: 3 },
    ],
    StartDate: moment().startOf("months"),
    EndDate: moment(),
    TimeDetail: {},
    ExpenseDetail: {},
    selectedDuration: {},
    isEndDateCalendarOpen: false,
    allOpportunites: [],
    messageForm: [],
    isSendMessage: false,
  };
  componentDidMount() {
    const {
      ASSORT_PENDING_LABEL,
      ASSORT_ACCEPTED_LABEL,
      ASSORT_DECLINED_LABEL,
    } = this.props.labels;
    this.setState({
      options: [
        { label: ASSORT_PENDING_LABEL, value: 1 },
        { label: ASSORT_ACCEPTED_LABEL, value: 2 },
        { label: ASSORT_DECLINED_LABEL, value: 3 },
      ],
    });
    getAllAcceptedRequestsApi()
      .then((res) => {
        this.setState({
          allOpportunites: res.items,
        });
        this.handleOpportunityClick(res.items[0], res.items);
      })
      .catch((err) => console.log("Error"));
  }
  sortByNum(a, b) {
    const diff = a.Order - b.Order;
    return diff;
  }
  handleOpportunityClick = (item, Opportunities = null) => {
    if (!item) {
      this.setState({
        selectedOpportunity: {},
        resumeListCollapse: true,
      });
      return;
    }
    GetAllResumeReviewsByOpportunityApi(item.RequestId, this.props.token)
      .then((res) => {
        const newList = item.Resumes.map((a) => ({
          ...a,
          Reviews: res.items.filter((k) => k.ResumeId == a.ResumeId),
        }));
        item = {
          ...item,
          Resumes: newList,
        };
        this.setState({
          selectedOpportunity: item,
          allOpportunites: this.state.allOpportunites.map((a) => ({
            ...a,
            isSelected: a.RequestId == item.RequestId,
            isActive: a.RequestId == item.RequestId,
          })),
          resumeListCollapse: false,
        });
        GetAllResumeOrder(item.RequestId, this.props.token)
          .then((res) => {
            const newList = item.Resumes.map((a) => ({
              ...a,
              Order:
                res.items.find((k) => k.ResumeId == a.ResumeId) &&
                res.items.find((k) => k.ResumeId == a.ResumeId).Order,
            }));
            item = {
              ...item,
              Resumes: newList.sort((a, b) => this.sortByNum(a, b)),
            };
            this.setState({
              selectedOpportunity: item,
              allOpportunites: this.state.allOpportunites.map((a) => ({
                ...a,
                isSelected: a.RequestId == item.RequestId,
                isActive: a.RequestId == item.RequestId,
              })),
              resumeListCollapse: false,
            });
            this.handleResumeSelect(item.Resumes[0], item);
          })
          .catch((err) => console.log("Err ", err));
      })
      .catch((err) => console.log("Err ", err));
  };
  handleResumeSelect = (item, selectedOpportunity) => {
    const currentOppo = selectedOpportunity
      ? selectedOpportunity
      : this.state.selectedOpportunity;
    this.setState({
      selectedResume: item,
      selectedOpportunity: {
        ...currentOppo,
        Resumes: currentOppo.Resumes.map((a) => ({
          ...a,
          isSelected: a.ResumeId == item.ResumeId,
          isActive: a.ResumeId == item.ResumeId,
        })),
      },
    });
    GetUserDetailByResumeAndRequestId(
      item.ResumeId,
      currentOppo.RequestId
    ).then((res) => {
      if (res.success) {
        this.setState({
          selectedUser: res.items,
        });
      }
    });
    const { selectedResume } = this.state;
    GetAllResumeComments(
      11,
      currentOppo.RequestId,
      item.ResumeId,
      this.props.token
    )
      .then((res) => {
        this.setState({
          selectedResume: {
            ...this.state.selectedResume,
            Reviews: res.items,
          },
        });
      })
      .catch((err) => console.log("Err", err));
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.handleResumeSelect(this.state.selectedOpportunity.Resumes[oldIndex]);
    const updatedArray = this.array_move(
      this.state.selectedOpportunity.Resumes,
      oldIndex,
      newIndex
    );
    let updatedOrder = [];
    let order = 1;
    updatedArray.forEach((element) => {
      const item = {
        ...element,
        OpportunityId: this.state.selectedOpportunity.RequestId,
        ResumeId: element.ResumeId,
        Order: order,
      };
      order = order + 1;
      updatedOrder = updatedOrder.concat(item);
    });
    this.setState(({ selectedOpportunity }) => ({
      selectedOpportunity: {
        ...this.state.selectedOpportunity,
        Resumes: updatedOrder,
      },
    }));
    const postObject = {
      resumeOrders: updatedOrder,
    };
    UpdateOrderApi(this.props.token, postObject)
      .then((res) => {})
      .catch((err) => console.log("Err ", err));
  };
  array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  handleResumeCommentSave = () => {
    const { selectedResume } = this.state;
    let { CurrentReview } = selectedResume;
    CurrentReview = {
      ...CurrentReview,
      OpportunityId: this.state.selectedOpportunity.RequestId,
      ResumeId: selectedResume.ResumeId,
      UserId: this.props.User.UserId,
    };
    if (!CurrentReview.Comments) {
      return;
    }
    if (CurrentReview.ResumeReviewId > 0) {
      UpdateReviewApi(this.props.token, CurrentReview)
        .then((res) => {
          this.setState({
            selectedResume: {
              CurrentReview: {},
              Reviews: this.state.selectedResume.Reviews.map((a) =>
                a.ResumeReviewId == res.items.ResumeReviewId ? res.items : a
              ),
            },
          });
        })
        .catch((err) => console.log("Err ", err));
    } else {
      AddReviewApi(this.props.token, CurrentReview)
        .then((res) => {
          const Reviews = this.state.selectedResume.Reviews.concat(res.items);
          this.setState({
            selectedOpportunity: {
              ...this.state.selectedOpportunity,
              Resumes: this.state.selectedOpportunity.Resumes.map((a) =>
                a.ResumeId == res.items.ResumeId ? { ...a, Reviews } : a
              ),
            },
            selectedResume: {
              ...this.state.selectedResume,
              CurrentReview: {},
              Reviews,
            },
          });
        })
        .catch((err) => console.log("Err ", err));
    }
  };
  handleResumeCommentChange = (e) => {
    const { value } = e.target;
    this.setState({
      selectedResume: {
        ...this.state.selectedResume,
        CurrentReview: {
          ...this.state.selectedResume.CurrentReview,
          Comments: value,
        },
      },
    });
  };
  hanldeResumeCommentSelect = (item) => {
    const Reviews = this.state.selectedResume.Reviews.map((a) => ({
      ...a,
      isSelected: a.ResumeReviewId == item.ResumeReviewId,
      isActive: a.ResumeReviewId == item.ResumeReviewId,
    }));
    this.setState({
      selectedResume: {
        ...this.state.selectedResume,
        CurrentReview: item,
        Reviews,
      },
    });
  };
  handleSendMessageEmail = (type) => {
    this.setState({
      onAssortSendType: type,
      isSendMessage: true,
      messageForm: { title: "", content: "" },
    });
  };

  handleContentEmailChange = (content) => {
    const { messageForm } = this.state;
    this.setState({
      messageForm: {
        ...messageForm,
        content: content,
      },
    });
  };

  handleMessageChange = (e) => {
    const { value, name } = e.target;
    const { messageForm } = this.state;
    const data = {
      ...messageForm,
      [name]: value,
    };
    this.setState({ messageForm: data });
  };

  handleSubmitMessage = () => {
    const {
      messageForm: { title, content },
      onAssortSendType,
      selectedUser,
    } = this.state;
    const { labels, notificationAction } = this.props;

    if (isEmpty(title)) {
      notificationAction({
        status: "error",
        message: labels.InfoIProCreateMsgTitleError,
      });
      return;
    }
    if (isEmpty(content)) {
      notificationAction({
        status: "error",
        message: labels.InfoIProCreateMsgBodyError,
      });
      return;
    }
    if (isEmpty(selectedUser)) {
      notificationAction({
        status: "error",
        message: "Ipro Not selected",
      });
      return;
    }
    this.setState({ isLoading: true });
    if (onAssortSendType == 2) {
      const message = {
        IsRead: false,
        MessageBody: content,
        MessageId: 0,
        Subject: title,
        UserIds: [selectedUser.UserId],
        UserRequestMessageId: 0,
      };

      sendMessage({ message })
        .then((response) => {
          if (response.success == true) {
            this.setState({
              messageForm: { title: "", content: "" },
            });
            this.setState({ isLoading: false });

            notificationAction({
              status: "success",
              message: labels.InfoIProCreateMsgAddSuccessfully,
            });
          } else {
            this.setState({ isLoading: false });

            notificationAction({
              status: "error",
              message: "Some thing went wrong",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    } else {
      let emailContent = {
        Subject: title,
        RecipientEmail: selectedUser.email.UserEmailValue,
        Body: content,
      };
      SendEmailToUser(emailContent)
        .then((res) => {
          if (res.success) {
            this.setState({ isLoading: false });

            notificationAction({
              status: "success",
              message: labels.EMAIL_SENT_SUCCESS | "Email sent successfully",
            });
          } else {
            this.setState({ isLoading: false });

            notificationAction({
              status: "error",
              message: "something went wrong",
            });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          this.setState({ isLoading: false });
        });
    }
  };

  handleReviewDelete = (item) => {
    DeleteReviewApi(item.ResumeReviewId, this.props.token).then((res) => {
      const Reviews = this.state.selectedResume.Reviews.filter(
        (a) => a.ResumeReviewId != item.ResumeReviewId
      );
      const Resumes = this.state.selectedOpportunity.Resumes.map((a) =>
        a.ResumeId == item.ResumeId ? { ...a, Reviews } : a
      );
      this.setState({
        selectedOpportunity: { ...this.state.selectedOpportunity, Resumes },
        selectedResume: {
          ...this.state.selectedResume,
          CurrentReview:
            this.state.CurrentReview &&
            this.state.CurrentReview.ResumeReviewId == item.ResumeReviewId
              ? {}
              : this.state.CurrentReview,
          Reviews,
        },
      });
    });
  };

  render() {
    const {
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      isCollapsed4,
      isCollapsed5,
      selectedUser,
      selectedResume,
      showResumeList = false,
      selectedOpportunity,
      resumeListCollapse = true,
      formCollapsed,
      onAssortSendType,
      isSendMessage,
      messageForm,
      isLoading,
    } = this.state;
    const { labels, isHelpActive } = this.props;
    const {
      SNAPSHOT_COLLABORATION_PRESENT,
      SNAPSHOT_COLLABORATION_RESUME,
    } = labels;
    return (
      <div className="assort-opportunity-view">
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        <Column collapse={isCollapsed1} className="col-1 opportunities-column">
          <Column.Collapsed
            text={"Accepted Opportunities"}
            onClick={() => this.setState({ isCollapsed1: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandBtn1"
          />
          <Column.Head>
            <div className="heading">{labels.acceptedOpportunitiesLabel}</div>
            <Button
              className="collapseBtn"
              testId="collapaseBtn1"
              onClick={() => this.setState({ isCollapsed1: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          <Column.Body>
            <div className="tictell-list">
              {this.state.allOpportunites &&
                this.state.allOpportunites.map((item) => (
                  <div
                    key={item.RequestId}
                    test-data-id={`collaborations-list-item${
                      item.isActive ? "activeItem" : ""
                    }`}
                    className={`tictell-list-item-container  ${
                      item.isSelected ? "selected-item" : ""
                    } ${item.isActive ? "activeItem" : ""}`}
                    onClick={() => this.handleOpportunityClick(item)}
                  >
                    <div className={`circle`}>{item.Resumes.length}</div>
                    <div
                      className="name-container"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <label test-data-id={`collabs-list-owner-username`}>
                        {item.RequestName}
                      </label>
                      <label
                        test-data-id={`collabs-list-owner-title`}
                      >{`${labels.acceptedByLabel} ${item.Resumes.length} ${labels.colliPro}`}</label>
                    </div>
                  </div>
                ))}
            </div>
          </Column.Body>
        </Column>
        {(showResumeList ||
          (!isEmpty(selectedOpportunity) &&
            !isEmpty(selectedOpportunity.Resumes))) && (
          <Column collapse={resumeListCollapse} className="col-2">
            <Column.Collapsed
              collapse={resumeListCollapse}
              onClick={() => this.setState({ resumeListCollapse: false })}
              text={labels.SearcherOpportunityDraftViewTitleShortlistResume}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistExpand
              }
              testId="expandBtn2"
              testIdHelp="opportunity-sent-resumelist-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive>{`${selectedOpportunity.Resumes &&
                selectedOpportunity.Resumes.length} ${
                labels.SearcherOpportunityDraftShortlistResumeHeading
              }`}</Column.HeaderTitle>

              <Button
                onClick={() => this.setState({ resumeListCollapse: true })}
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistCollapse
                }
                testId="collapaseBtn2"
                testIdHelp="opportunity-sent-resumelist-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body>
              <div className="tictell-list">
                {this.state.selectedOpportunity &&
                  this.state.selectedOpportunity.Resumes && (
                    <SortableList
                      items={
                        this.state.selectedOpportunity &&
                        this.state.selectedOpportunity.Resumes
                      }
                      handleResumeSelect={this.handleResumeSelect}
                      onSortEnd={this.onSortEnd}
                    />
                  )}
              </div>
            </Column.Body>
          </Column>
        )}
        {
          <Column collapse={isCollapsed3} className="comments-column">
            <Column.Collapsed
              text={"Comments"}
              onClick={() => this.setState({ isCollapsed3: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandBtn3"
            />
            <Column.Head>
              <div className="heading">{labels.commentsLabel}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed3: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapaseBtn3"
              />
            </Column.Head>
            <Column.Body>
              <div className="comments-wrapper">
                <div className="start-end">
                  <CustomInputNew label={"Comment"}>
                    <input
                      type="text"
                      autoComplete="off"
                      value={
                        (this.state.selectedResume &&
                          this.state.selectedResume.CurrentReview &&
                          this.state.selectedResume.CurrentReview.Comments) ||
                        ""
                      }
                      name="Amount"
                      className={`input-text `}
                      placeholder={"Please enter a comment"}
                      test-data-id="input-comment"
                      onChange={this.handleResumeCommentChange}
                    />
                  </CustomInputNew>
                  <button
                    test-data-id={`add-comment-btn`}
                    className="add-btn"
                    onClick={() => this.handleResumeCommentSave()}
                  ></button>
                </div>
                <div className="tictell-list">
                  {this.state.selectedResume &&
                    this.state.selectedResume.Reviews &&
                    this.state.selectedResume.Reviews.map((item) => (
                      <div
                        key={item.ResumeReviewId}
                        test-data-id={`collaborations-list-item${
                          item.isActive ? "activeItem" : ""
                        }`}
                        className={`tictell-list-item-container  ${
                          item.isSelected ? "selected-item" : ""
                        } ${item.isActive ? "activeItem" : ""}`}
                      >
                        <div
                          onClick={() => this.hanldeResumeCommentSelect(item)}
                          className="name-container"
                        >
                          <label
                            title={item.Comments}
                            className="comment-label"
                            test-data-id={`collabs-list-owner-username`}
                          >
                            {item.Comments}
                          </label>
                          <label test-data-id={`collabs-list-owner-title`}>
                            {moment(item.CreatedDate).format("DD/MM/YYYY")}
                          </label>
                        </div>
                        <button
                          onClick={() => this.handleReviewDelete(item)}
                          className="closeBtn"
                          test-data-id={`comment-delete-btn`}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </Column.Body>
          </Column>
        }
        {
          <Column collapse={isCollapsed4} className="col-4">
            <Column.Collapsed
              text={SNAPSHOT_COLLABORATION_PRESENT}
              onClick={() => this.setState({ isCollapsed4: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandBtn4"
            />
            <Column.Head>
              <div className="heading">{SNAPSHOT_COLLABORATION_PRESENT}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed4: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapaseBtn4"
              />
            </Column.Head>
            <Column.Body>
              <Presentations
                selectedUser={selectedUser}
                onAssortOpportnity
                handleSendMessageEmail={this.handleSendMessageEmail}
                labels={labels}
              />
            </Column.Body>
          </Column>
        }
        {isSendMessage ? (
          <Column collapse={formCollapsed} className="col-7">
            <Column.Collapsed
              text={labels.ViewTitleIProMessageCreate}
              onClick={() => this.setState({ formCollapsed: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.TooltipIProCreateMessageExpand}
              tooltipHelp={labels.HlpTooltipIProCreateMessageExpand}
              tooltipPlace="left"
              testId="create-message-detail-expand-btn"
              testIdHelp="create-message-detail-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle
                isActive
                testId="create-message-detail-heading"
              >
                {onAssortSendType == 2
                  ? labels.SEND_MESSAGE_LABEL
                  : labels.SEND_EMAIL_LABEL}
              </Column.HeaderTitle>
              <Button
                onClick={this.handleSubmitMessage}
                className="SendBtn"
                disabled={messageForm.isSubmitting}
                tooltipButton={
                  onAssortSendType == 1
                    ? labels.SEND_EMAIL_LABEL || "Send Email"
                    : labels.TooltipIProCreateMessageSendButton
                }
                tooltipHelp={
                  onAssortSendType == 1
                    ? labels.SEND_EMAIL_LABEL || "Send Email"
                    : labels.HlpTooltipIProCreateMessageSendButton
                }
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="create-message-header-send-btn"
                testIdHelp="create-message-header-send-help-btn"
              />
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ formCollapsed: true })}
                tooltipButton={labels.TooltipIProCreateMessageCollaspe}
                tooltipHelp={labels.HlpTooltipIProCreateMessageCollaspe}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="create-message-detail-collapse-btn"
                testIdHelp="create-message-detail-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body>
              <CreateMessage
                handleContentChange={this.handleMessageChange}
                handleContentEmailChange={this.handleContentEmailChange}
                messageForm={messageForm}
                onAssortSendType={onAssortSendType}
                location={this.props.location}
                onMessageSend={this.handleSubmitMessage}
                labels={labels}
              />
            </Column.Body>
          </Column>
        ) : (
          ""
        )}
        {
          <Column collapse={isCollapsed5} className="col-5">
            <Column.Collapsed
              text={SNAPSHOT_COLLABORATION_RESUME}
              onClick={() => this.setState({ isCollapsed5: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandBtn5"
            />
            <Column.Head>
              <div className="heading">{SNAPSHOT_COLLABORATION_RESUME}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed5: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapaseBtn5"
              />
            </Column.Head>
            <Column.Body>
              <ResumeDetail resume={selectedResume} />
            </Column.Body>
          </Column>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(mapStateToProps, { notificationAction })(Collaborations);
