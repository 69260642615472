import React from "react";
import { htmlParser } from "../../../../utilities";

const Terms = ({ heading, onClose, content }) => (
  <div className="TermForm">
    <div className="Term">
      <button onClick={onClose} className="close-btn" />
      <h1>{heading}</h1>
      <div>{htmlParser(content)}</div>
    </div>
  </div>
);

export default Terms;
