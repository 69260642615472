import React, { useEffect, useState } from "react";
import classes from "./location.module.scss";
import uuidv1 from "uuid/v1";
import {
  getAllLocationsApi,
  resumeItemDeleteApi,
  getResumeLocation,
  addResumeLocation,
} from "../../../Resume/ResumeEdit/resumeEditApi";
import { ConfirmDialog, Select } from "../../../../common";
import Trash from "../../../../assets/newResume/trash";
import { notificationAction } from "../../../../actions";
import { useDispatch } from "react-redux";
import EmptyResume from "../../../../common/emptyMessage/emptyResume";
import { Col, Row } from "antd";

const Location = ({ locationList, userDetails, onStateChangeAction }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selected, setSelected] = useState(false);
  const [deleteResume, setDeleteResume] = useState({
    show: false,
  });
  const getAllLocations = () => {
    getAllLocationsApi().then((response) => {
      if (response.success) {
        const locationOption = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        setOptions(locationOption);
      }
    });
  };
  const onLocationChange = (options) => {
    if (options) {
      setSelected({
        CountryId: options.CountryId,
        CountryName: options.CountryName,
      });
    }
  };
  const getResumeLocations = () => {
    onStateChangeAction({ isLoading: true });
    getResumeLocation()
      .then((res) => {
        if (res?.success) {
          setData(
            res?.items?.map((i) => {
              return { ...i, uniqueId: uuidv1() };
            })
          );
        }
        onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        onStateChangeAction({ isLoading: false });
      });
  };
  const handleAddLocation = () => {
    onStateChangeAction({ isLoading: true });
    addResumeLocation({
      CountryId: selected.CountryId,
      ResumeId: userDetails.ResumeId,
    })
      .then((res) => {
        if (res?.success) {
          getResumeLocations();
          setSelected(false);
        } else {
          onStateChangeAction({ isLoading: false });
          dispatch(
            notificationAction({
              status: "error",
              message:
                "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
            })
          );
        }
      })
      .catch(() => {
        onStateChangeAction({ isLoading: false });
        dispatch(
          notificationAction({
            status: "error",
            message:
              "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
          })
        );
      });
  };
  const onDeleteLocations = (item) => {
    onStateChangeAction({ isLoading: true });
    resumeItemDeleteApi({
      LookupTypeId: 7,
      ResumeId: userDetails.ResumeId,
      ObjectPK: item.ResumeCountryId,
    })
      .then((res) => {
        if (res?.success) {
          getResumeLocations();
          setDeleteResume((st) => ({
            ...st,
            show: false,
            message: "",
            item: "",
          }));
        } else {
          onStateChangeAction({ isLoading: false });
          dispatch(
            notificationAction({
              status: "error",
              message:
                "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
            })
          );
        }
      })
      .catch(() => {
        onStateChangeAction({ isLoading: false });
        dispatch(
          notificationAction({
            status: "error",
            message:
              "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
          })
        );
      });
  };

  useEffect(() => {
    getResumeLocations();
    getAllLocations();
  }, []);

  useEffect(() => {
    if (!locations.length) {
      setLocations([...locationList]);
    }
  }, [locationList]);

  return (
    <>
      <Row className={classes.location} justify="center">
        <Col xs={24} lg={18} md={22}>
          <div>
            <Row className="align-items-center mb-3">
              <Col md={18} xs={24}>
                {/* <h1 className={classes.locationHead}>Location</h1> */}
                <div className="EducationEditSelect">
                  <Select
                    name={"Locations"}
                    className={`select-location  `}
                    placeholder="Select Location"
                    value={
                      selected.CountryId
                        ? {
                            value: selected.CountryId,
                            label: selected.CountryName,
                          }
                        : ""
                    }
                    onChange={(options) => onLocationChange(options)}
                    options={options}
                    clearable={false}
                  />
                  <div className={classes.addLocation}>
                    <button
                      className={classes.addLocationButton}
                      disabled={!selected}
                      onClick={() => {
                        handleAddLocation();
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            {data?.length > 0 ? (
              data?.map((item, index) => (
                <Row className="mb-3">
                  <Col md={20} xs={24}>
                    <div key={index} className={classes.locationOptions}>
                      <div className={classes.textBox}>{item?.CountryName}</div>
                      <div
                        className={classes.icon}
                        onClick={() => {
                          setDeleteResume((st) => ({
                            ...st,
                            show: true,
                            message: "Are you sure, you want to delete this?",
                            item: item,
                          }));
                        }}
                      >
                        <Trash />
                      </div>
                    </div>
                  </Col>
                </Row>
              ))
            ) : (
              <EmptyResume text={`"No resume location added yet"`} />
            )}
          </div>

          {deleteResume?.show && (
            <ConfirmDialog testId="company-confirm-diloag">
              <ConfirmDialog.Message>
                {deleteResume?.message}
              </ConfirmDialog.Message>
              <ConfirmDialog.ActionButtons>
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="company-delete-yes"
                  onClick={() => {
                    onDeleteLocations(deleteResume?.item);
                  }}
                >
                  Yes
                </ConfirmDialog.Button>
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="company-delete-no"
                  onClick={() => {
                    setDeleteResume((st) => ({
                      ...st,
                      show: false,
                      message: "",
                      item: "",
                    }));
                  }}
                >
                  No
                </ConfirmDialog.Button>
              </ConfirmDialog.ActionButtons>
            </ConfirmDialog>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Location;
