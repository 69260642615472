import axios from "axios";
import { ApiUrl } from "../../api/apiUrls";

export const getDashboardMenusApi = () => {
  return axios.get(ApiUrl.LandingPage.GetDashboardFeatures).then(({ data }) => data);
};

export const getFooterMenuDetailApi = (pageId) => {
  return axios.get(ApiUrl.LandingPage.GetFooterFeaturesDetail(pageId)).then(({ data }) => data);
};

export const visitorContactSubmitApi = ({ firstname, lastname, email, description }) => {
  return axios
    .post(ApiUrl.LandingPage.submitVisitorContact, {
      FirstName: firstname,
      LastName: lastname,
      Email: email,
      Description: description,
      DateTime: null
    })
    .then(({ data }) => {
      return data;
    });
};

export const getAllCountriesApi = () => {
  return axios
    .get(ApiUrl.LandingPage.AllLocations)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const genetrateTokenApi = (domain, email) => {
  return axios
    .get(ApiUrl.Account.GenerateToken(email, domain))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const NetworkInvitationActionApi = (NIId, status) => {
  return axios
    .post(ApiUrl.UserNetwork.NetworkInvitationAction(NIId, status))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const signUpApi = (user) => {
  return axios
    .post(ApiUrl.Account.Register, user)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const RegisterThirdPartyApi = (user) => {
  return axios
    .post(ApiUrl.Account.RegisterThirdParty, user)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getForgotPasswordApi = (email) => {
  return axios
    .get(`${ApiUrl.Account.ForgotPassword}?email=${email}&GET`)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getSignUpConfigsApi = () => {
  return axios
    .get(ApiUrl.LandingPage.SignUpConfigs)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getSignInConfigsApi = () => {
  return axios
    .get(ApiUrl.LandingPage.SignInConfigs)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getEmailConfirmationTokenApi = (email) => {
  return axios
    .get(`${ApiUrl.LandingPage.GetEmailConfirmationToken}?email=${email}`)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getSliderContent = () => {
  return axios
    .get(ApiUrl.SliderContent.Get)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const emailTokenConfirmationApi = (token) => {
  return axios
    .post(
      `${ApiUrl.EmailConfirmation.EmailConfirmation}?confirmationToken=${token}`
    )
    .then(({ data }) => data);
};

export const UserNotificationsUpdate = (token) => {
  return axios
    .get(ApiUrl.Settings.UserNotificationsUpdate(token))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const igeLoginApi = (data) => {
  return axios
    .post(ApiUrl.Account.IgeLogins, data)
    .then(({ data }) => data)
    .catch((response) => response);
};
