import React, { Component } from "react";
import { connect } from "react-redux";
import { htmlParser } from "../../../../utilities";
import "./articles.scss";
import Img1 from "../../../../assets/images/snapshot/article1.jpeg";
import Img2 from "../../../../assets/images/snapshot/article2.jpeg";
import Img3 from "../../../../assets/images/snapshot/article3.jpeg";
import Img4 from "../../../../assets/images/snapshot/article4.jpeg";

const localLabels = {
  mobilePageTitle: "Prodoo Articles",
};
const articles = [
  {
    url:
      "https://prodoo.com/Blog/index.php/2021/04/26/why-prodoo-is-the-best-choice-for-recruiters/",
    date: "April 26, 2021",
    title: "Why Prodoo is the best choice for Recruiters",
    content:
      "One of the byproducts of the 21st century and the digital age is that the internet has become central to all endeavors. With the passage of time, more activities have become digitized. Case in point: recruitment. Basically, the present and future of recruitment will be internet based. Hiring is one of the best examples because recruiters use portals like Prodoo […]",
    author: "Saad Malik",
    comments: 0,
    readMin: 3,
    id: 1,
  },
  {
    url:
      "https://prodoo.com/Blog/index.php/2021/04/21/how-prodoo-differs-from-other-recruitment-portals/",
    date: "April 21, 2021",
    title: "How Prodoo differs from other recruitment portals",
    content:
      "The global pandemic has undoubtedly created a dramatic shift towards increased online activity. However, certain aspects of our lives became digital long before COVID-19 became apparent. For instance, if you examine the history of Ecommerce, it has been around since the 20th century. Amazon launched their website in 1994 and eBay followed a year later. Likewise, even recruitment is largely […]",
    author: "Saad Malik",
    comments: 0,
    readMin: 2,
    id: 2,
  },
  {
    url:
      "https://prodoo.com/Blog/index.php/2021/04/03/the-best-features-of-prodoo-for-freelancers/",
    date: "April 3, 2021",
    title: "The Best Features of Prodoo for Freelancers",
    content:
      "Prodoo is an outstanding recruitment portal that is tailored to serve freelancers. If you’re looking for the premier job, Prodoo is equipped with attributes that make it easier than ever for users to get the relevant information they need. So what are some of the foremost features of Prodoo that freelancers can avail? We’ll highlight some of the best ones […]",
    author: "Saad Malik",
    comments: 0,
    readMin: 2,
    id: 3,
  },
  {
    url:
      "https://prodoo.com/Blog/index.php/2021/04/03/prodoo-what-you-need-to-know/",
    date: "April 3, 2021",
    title: "Prodoo: What you need to know",
    content:
      "Are you looking for a freelance opportunity? Don’t know where to look? With Prodoo, you don’t need to be apprehensive about finding a role best suited for you. Prodoo is a comprehensive recruitment solution for freelancers and recruiters. What does Prodoo offer? What exactly does the platform provide? It has various practical benefits, some of which are listed below: If […]",
    author: "Saad Malik",
    comments: 0,
    readMin: 2,
    id: 4,
  },
];

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
class Articles extends Component {
  render() {
    const { labels } = this.props;
    return (
      <div className="mobile-apps mainContent lightBg" id="blog">
        <section className="contentContainer">
          <h1 className="proHeading">{localLabels.mobilePageTitle}</h1>
          <div className="mobileAppsSCPanel">
            <ul className="articleList">
              {articles.map((item) => (
                <li
                  className="articleItem"
                  onClick={() => openInNewTab(item.url)}
                >
                  <img
                    className="articleImg"
                    src={require(`../../../../assets/images/snapshot/article${item.id}.jpeg`)}
                    alt="img"
                  />
                  <div>
                    <h5>{item.date}</h5>
                    <h1>{item.title}</h1>
                    <p>{item.content}</p>
                    <div className="footer">
                      <span className={`user-icon`}></span>
                      <span>{item.author}</span>
                      <span className={`comment-icon`}></span>
                      <span>{item.comments}</span>
                      <span className={`time-icon`}></span>
                      <span>{`${item.readMin} min read`}</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <button
              type="button"
              className="seeMore"
              onClick={() => openInNewTab("https://prodoo.com/Blog/")}
            >See More Articles</button>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(Articles);
