import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import classes from "./index.module.scss";
import { Link } from "react-router-dom";

function MissingRoleSkill({
  labels
}) {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {}, []);


  return (
    <>
      <Modal
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="normal_login"
          className={`${classes.form}  ${classes.popUpForm}`}
         
          size="large"
          // initialValues={{ date: SkillProficiencyList[0] }}
        >
          <div className="text-center mb-4">
            <h3>{labels.MISSING_SKILLS_ROLES_MSG}</h3>
          </div>
          <Link to="/resume-edit">
            <Button
              type="primary"
              block
              htmlType="submit"
              className="login-form-button mt-2"
              >
              {labels.MISSING_SKILLS_ROLES_BTN}
            </Button>
          </Link>
        </Form>
      </Modal>
    </>
  );
}

export default MissingRoleSkill;
