import React, { Component } from "react";
import "./presentlist.scss";
import { EmptyInfo, List } from "../../../common";
import ImgSrc from "../../../assets/images/present.svg";
import EmptyData from "../../../common/EmptyData";
class PresentList extends Component {
  render() {
    const {
      presentForm,
      handleDeletePresent,
      handlePresentClick,
      presentList,
      isFetching,
      labels,
    } = this.props;
    return (
      <>
        {presentList?.length > 0 ? (
          <List isFetching={isFetching} className="present-list">
            {presentList.map((item) => {
              const { UserDetailId, Logo, UserName, Title, Profiles } = item;
              return (
                <List.ListItem
                  onClick={() => handlePresentClick(UserDetailId)}
                  key={UserDetailId}
                  isSelected={presentForm.UserDetailId === UserDetailId}
                >
                  <div className={`dashItemImg ${Logo ? "" : "no-dp"}`}>
                    <img
                      className="roundImg"
                      src={Logo ? Logo : ImgSrc}
                      alt={UserName}
                    />
                  </div>
                  <div className="CompNameCnt">
                    <div className="listLabel">{Title}</div>
                    <div className="CompAddress">{UserName}</div>
                    <div className="CompAddress">
                      {(Profiles && Profiles.ProfileValue) || ""}
                    </div>
                  </div>
                  {/* <List.Close
                testId="present-delete-btn"
                onClick={(e) => {
                  handleDeletePresent(UserDetailId);
                  e.stopPropagation();
                }}
              /> */}
                  <div
                    // disabled={isDisabled}
                    onClick={(e) => {
                      handleDeletePresent(UserDetailId);
                      e.stopPropagation();
                    }}
                    className="close_btn_purple"
                  >
                    <i className="fa fa-times-circle" />
                  </div>
                </List.ListItem>
              );
            })}
          </List>
        ) : (
          <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
        )}
      </>
    );
  }
}
export default PresentList;
