import React from "react";
import "./select.scss";
import SelectField from "react-select";

const Select = ({
  name,
  className = "",
  value,
  testId,
  onChange,
  options,
  clearable = false,
  onBlur,
  disabled,
  placeholder,
  ...rest
}) => (
  <SelectField
    name={name}
    test-data-id={testId}
    className={`select-input ${className}`}
    value={value || ""}
    onChange={onChange}
    options={options}
    clearable={clearable}
    onBlur={onBlur}
    disabled={disabled}
    placeholder={placeholder}
    {...rest}
  />
);

const Creatable = ({
  name,
  className,
  value,
  placeholder,
  promptTextCreator,
  onChange,
  autoBlur = false,
  options,
  clearable = false,
  autoComplete = false,
  ...rest
}) => (
  <SelectField.Creatable
    name={name}
    className={`select-input ${className}`}
    value={value}
    placeholder={placeholder}
    promptTextCreator={promptTextCreator}
    onChange={onChange}
    autoBlur={autoBlur}
    options={options}
    clearable={clearable}
    autoComplete={autoComplete}
    {...rest}
  />
);

Select.Creatable = Creatable;

Select.Creatable.defaultProps = {
  className: ""
};

Select.defaultProps = {
  className: ""
};

export default Select;
