import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import { PageWrapper } from "../../../../components";
import { notificationAction } from "../../../../actions";
import { Messages } from "../../../../utilities";
import toLower from "lodash/toLower";
import {
  Column,
  Button,
  SearchInput,
  LoadingMaskRow,
  LoadingMask,
  ConfirmDialog,
} from "../../../../common";
import "./opportunity.scss";
import {
  getAllOpportunityApi,
  updateCollaborationApi,
  retrieveResumeSearchApi,
  declinedOpportunityApi,
  deleteOpportunityApi,
  getSocialMediaApi,
  opportunityRestoreApi,
  getAllPresenationApi,
  readOpportunityApi,
  getCurrenciesApi,
} from "./opportunityApi";
import { unreadOpportunitiesAction } from "../../../../actions/navigationActions";
import OpportunityDetail from "./components/OpportunityDetail";
import OpportunityList from "./components/OpportunityList";
import CompanyDetail from "../../../Company/components/CompanyDetail";
import PresentationDetails from "./components/PresentationDetails";
import { onStateChangeAction } from "./opportunityAction";

class Opportunity extends Component {
  state = {
    opportunity: [],
  };

  componentDidMount() {
    this.props.onStateChangeAction({
      opportunityList: [],
      filteredOpportunity: [],
      fetchingOpportunity: false,

      deletedId: null,
      requestName: "",
      selectedOpportunity: {},
      showPresentationDetail: false,
    });
    const { isAcceptedView, isDeclinedView } = this.props;
    window.actionName = isAcceptedView
      ? "accepted"
      : isDeclinedView
      ? "declined"
      : "new";
    this.props.onStateChangeAction({
      companyPresentationHeadingText: this.props.labels
        .iproOpportunityNewCompanyDetailHeading,
    });
    this.getSocialMedia();
    this.getAllOpportunity();
  }

  getSocialMedia = () => {
    getSocialMediaApi().then((data) => {
      if (data.success) {
        this.props.onStateChangeAction({ socialMedia: data.items });
      }
    });
  };

  getAllOpportunity = () => {
    const { url, isAccepted, opportunityType, currencies } = this.props;
    let currenciesList = [];

    if (currencies && currencies.length && currencies.length > 0) {
      currenciesList = currencies;
    } else {
      getCurrenciesApi()
        .then((res) => {
          if (res.success) {
            currenciesList = res.items;
          }
        })
        .catch((err) => console.log("Err ", err));
    }

    this.props.onStateChangeAction({ fetchingOpportunity: true });
    getAllOpportunityApi({ url }).then((data) => {
      let allOpportunity = [];
      if (data.success) {
        allOpportunity = map(data.items[opportunityType], (opp) => {
          if (!isNaN(opp.FeeCurrencyType) && currenciesList.length > 0) {
            opp.FeeCurrencyType = currenciesList.find(
              (a) => a.CurrencyId == opp.FeeCurrencyType
            ).Name;
          }
          return opp;
        });
        this.props.onStateChangeAction({
          opportunityList: allOpportunity,
          filteredOpportunity: allOpportunity,
          fetchingOpportunity: false,

          deletedId: null,
          requestName: "",
          selectedOpportunity: {},
          showPresentationDetail: false,
        });
        if (
          opportunityType === "NewRequests" &&
          data.items.NewRequests.length > 0
        ) {
          const spliturl = this.props.locationProp.pathname.match(/\d+/g);
          if (spliturl != null) {
            let filterrequestid = data.items.NewRequests.filter(
              (opportunity) => opportunity.RequestId == spliturl[0]
            );
            this.setState({
              opportunity: filterrequestid[0],
            });
            this.handleOpportunitySelect(this.state.opportunity);
          }
        }
        if (
          this.props.locationProp.state &&
          this.props.locationProp.state.visitRequestId
        ) {
          const visitOpportunity = filter(this.props.filteredOpportunity, [
            "RequestId",
            this.props.locationProp.state.visitRequestId,
          ]);
          this.props.onStateChangeAction({
            selectedCompany: visitOpportunity[0].Company,
            selectedOpportunity: visitOpportunity[0],
          });
          this.retrieveResumeSearch(this.props.selectedOpportunity);
        }
      } else {
        this.props.onStateChangeAction({
          fetchingOpportunity: false,
        });
      }
    });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { opportunityList } = this.props;
    const filteredOpportunity = filter(opportunityList, (opp) =>
      includes(toLower(opp.RequestName), toLower(value))
    );
    this.props.onStateChangeAction({ filteredOpportunity, searchKey: value });
  };

  handleDeleteOpportunityNew = (obj) => {
    this.props.onStateChangeAction({
      deletedId: obj.RequestId,
      requestName: obj.RequestName,
      dialogMessage: this.props.labels.iproInvitationDeletionConfirmation,
    });
  };

  handleYesClick = (event) => {
    const { deletedId, requestName } = this.props;
    this.props.onStateChangeAction({ dialogMessage: "" });
    this.props.onStateChangeAction({ isLoading: true });

    const obj = new Object();
    obj.IsRequestToDelete = false;
    obj.RequestId = deletedId;
    obj.RequestName = requestName;
    obj.ResumeId = 0;
    deleteOpportunityApi(obj)
      .then((data) => {
        if (data) {
          this.getAllOpportunity();
          const info = {
            message: this.props.labels.iproInvitationSuccessfullyDeleted,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            deletedId: null,
            requestName: "",
            selectedOpportunity: {},
            showPresentationDetail: false,
            showCompanyDetails: false,
          });
        }
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "", deletedId: null });
  };

  handleOpportunitySelect = (selectedOpportunity) => {
    this.props.onStateChangeAction({
      selectedOpportunity,
      selectedCompany: selectedOpportunity.Company,
      showPresentationDetail: false,
      showCompanyDetails: true,
    });
    if (!selectedOpportunity.IsRead) {
      readOpportunityApi({
        requestResumeId: selectedOpportunity.RequestResumeId,
      }).then((response) => {
        if (response.success) {
          const { opportunityList } = this.props;
          const {
            isAcceptedView,
            isDeclinedView,
            isNewOpportunityView,
          } = this.props;
          const opportunities = filter(
            opportunityList,
            (item) =>
              item.RequestId !== selectedOpportunity.RequestId &&
              item.RequestResumeId !== selectedOpportunity.RequestResumeId &&
              !item.IsRead
          );
          if (isNewOpportunityView) {
            this.props.unreadOpportunitiesAction({
              unreadOpportunities: { newOpportunities: opportunities.length },
            });
          } else if (isAcceptedView) {
            this.props.unreadOpportunitiesAction({
              unreadOpportunities: {
                acceptedOpportunities: opportunities.length,
              },
            });
          } else if (isDeclinedView) {
            this.props.unreadOpportunitiesAction({
              unreadOpportunities: {
                declinedOpportunities: opportunities.length,
              },
            });
          }
          const filteredOpportunity = map(opportunityList, (oppo) => {
            if (oppo.RequestId === selectedOpportunity.RequestId) {
              oppo.IsRead = true;
              return oppo;
            }
            return oppo;
          });
          this.props.onStateChangeAction({ filteredOpportunity });
        }
      });
    }

    if (this.props.isAccepted && selectedOpportunity.SavedSearchedId) {
      //find
      this.retrieveResumeSearch(selectedOpportunity);
    }
  };

  retrieveResumeSearch = (selectedOpportunity) => {
    retrieveResumeSearchApi(selectedOpportunity.SavedSearchedId).then(
      (data) => {
        if (data.success) {
          this.props.onStateChangeAction({
            selectedOpportunity: {
              ...selectedOpportunity,
              retrieveResumeSearch: {
                Profiles: data.items.filter((a) => a.LookupTypeId == 1),
                Skills: data.items.filter((a) => a.LookupTypeId == 2),
              },
            },
          });
        }
      }
    );
  };

  //Restore POPUP -start
  handleOpportunityRestore = () => {
    this.props.onStateChangeAction({
      restoreDialogMessage: this.props.labels.oppRestorationConfiramtion,
    });
  };

  restoreHandleYesClick = () => {
    const { selectedOpportunity } = this.props;

    opportunityRestoreApi(selectedOpportunity.RequestResumeId)
      .then((data) => {
        if (data) {
          this.getAllOpportunity();
          const info = {
            message: this.props.labels.IproOpportunityDeclinedRestoreMessage,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            deletedId: null,
            requestName: "",
            restoreDialogMessage: "",
            showPresentationDetail: false,
            showCompanyDetails: false,
            selectedOpportunity: {},
          });
        }
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  restoreHandleNoClick = () => {
    this.props.onStateChangeAction({
      restoreDialogMessage: "",
      deletedId: null,
    });
  };

  //Restore POPUP -end
  handleDeleteOpportunity = (obj) => {
    this.props.onStateChangeAction({
      deletedId: obj.RequestId,
      requestName: obj.RequestName,
      dialogMessage: this.props.opportunityDeletedMsg,
    });
  };

  handleOpportunityDetailAcceptClick = () => {
    this.getAllPresentation();

    // this.props.onStateChangeAction({
    //   showPresentationDetail: true,
    //   showCompanyDetails: false,
    //   companyPresentationHeadingText: this.props.labels
    //     .IproOpportunityNewUserDetailHeaderText
    // });
  };
  handleOpportunityDetailDeclinedClick = () => {
    this.props.onStateChangeAction({
      showPresentationDetail: false,
      showCompanyDetails: true,
    });
    const obj = new Object();
    obj.IsRequestToDelete = false;
    obj.RequestId = this.props.selectedOpportunity.RequestId;
    obj.ResumeId = 0;
    obj.RequestName = this.props.selectedOpportunity.RequestName;
    this.declinedOpportunity(obj);
  };

  declinedOpportunity = (info) => {
    declinedOpportunityApi(info).then((data) => {
      if (data.success) {
        this.getAllOpportunity();
        const info = {
          message: this.props.labels.iproOppNewDeclinedMessage,
          status: "success",
        };
        this.props.notificationAction(info);

        this.props.onStateChangeAction({
          deletedId: null,
          requestName: "",
          selectedOpportunity: {},
          showPresentationDetail: false,
          showCompanyDetails: false,
        });
      }
    });
  };

  handleOpportunityDetailCompanyClick = () => {
    this.props.onStateChangeAction({
      showPresentationDetail: false,
      showCompanyDetails: true,
      companyPresentationHeadingText: this.props.labels
        .iproOpportunityNewCompanyDetailHeading,
    });
  };

  getAllPresentation = () => {
    getAllPresenationApi().then((data) => {
      if (data.success) {
        if (data.items.length > 0) {
          this.props.onStateChangeAction({
            showPresentationDetail: true,
            showCompanyDetails: false,
            companyPresentationHeadingText: this.props.labels
              .IproOpportunityNewUserDetailHeaderText,
          });
        } else {
          const info = {
            message: this.props.labels
              .OPPORTUNITY_PRESENT_NOT_AVAILABLE_MESSAGE,
            status: "info",
          };
          this.props.notificationAction(info);
        }
      }
    });
  };

  render() {
    const {
      isHelpActive,
      listColumnText,
      toolTipExpandList,
      helpTooltipExpandList,
      searchInputPlaceholder,
      toolTipCollapseList,
      helpTooltipCollapseList,
      detailsColumnText,
      toolTipExpandDetail,
      helpTooltipExpandDetail,
      detailHeadingText,
      toolTipCollapseDetail,
      helpTooltipCollapseDetail,

      toolTipExpandUserDetail,
      helpTooltipExpandUserDetail,
      toolTipCollapseUserDetail,
      helpTooltipCollapseUserDetail,

      emptyOpportunityInfo,
      selectInterest,

      companyAddressLabel,
      companyIndustryLabel,
      companyCountryLabel,
      companyPhoneLabel,
      companyWebUrlLabel,
      companyVatLabel,

      listCollapsedTestId,
      listCollapsedHelpTestId,
      listExpandTestId,
      listExpandHelpTestId,
      detailCollapsedTestId,
      detailCollapsedHelpTestId,
      detailExpandTestId,
      detailExpandHelpTestId,
      userDetailCollapsedTestId,
      userDetailCollapsedHelpTestId,
      userDetailExpandTestId,
      userDetailExpandHelpTestId,
      searchInputTestId,
      IsSearcher,
      locationsLabel,
      startDateLabel,
      durationLabel,
      companyLabel,
      descriptionLabel,
      countryLabel,

      SearchParameterHeading,
      Lbltitle,
      LblProfile,
      LblSkills,
      LblKeyword,
      LblIndustry,
      LblCertification,
      LblCountry,
      LblLanguage,
      LblInterest,
      LblRestore,
      BtnAccept,
      BtnDecline,
      BtnRestore,
      isAccepted,
      opportunityType,
      labels,
      iproOpportunityListItem,
      locationProp,
    } = this.props;
    const {
      listCollapsed,
      detailCollapsed,
      companyPresentDetailCollapsed,
      selectedOpportunity,
      filteredOpportunity,
      fetchingOpportunity,
      isLoading,
      selectedCompany,
      socialMedia,
      showPresentationDetail,
      showCompanyDetails,
      dialogMessage,
      companyPresentationHeadingText,
      restoreDialogMessage,
      searchKey,
    } = this.props;
    return (
      <PageWrapper className="opportunity">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="opportunityIPro-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <button
                className="dialog-btn"
                test-data-id="opportunityIPro-delete-yes"
                onClick={this.handleYesClick}
              >
                {Messages.feedback.confirm.Yes}
              </button>
              <button
                className="dialog-btn"
                test-data-id="opportunityIPro-delete-no"
                onClick={this.handleNoClick}
              >
                {Messages.feedback.confirm.No}
              </button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}

        {restoreDialogMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>
              {restoreDialogMessage}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <button
                className="dialog-btn"
                onClick={this.restoreHandleYesClick}
              >
                {Messages.feedback.confirm.Yes}
              </button>
              <button
                className="dialog-btn"
                onClick={this.restoreHandleNoClick}
              >
                {Messages.feedback.confirm.No}
              </button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={listCollapsed} className="col-1">
          <Column.Collapsed
            text={listColumnText}
            onClick={() =>
              this.props.onStateChangeAction({ listCollapsed: false })
            }
            tooltipButton={toolTipExpandList}
            isHelpActive={isHelpActive}
            tooltipHelp={helpTooltipExpandList}
            tooltipPlace="left"
            testId={listExpandTestId}
            testIdHelp={listExpandHelpTestId}
          />
          <Column.Head>
            <SearchInput
              placeholder={searchInputPlaceholder}
              onChange={this.handleSearchChange}
              testId={searchInputTestId}
              value={searchKey}
            />
            <Button
              className="collapseBtn"
              tooltipButton={toolTipCollapseList}
              tooltipHelp={helpTooltipCollapseList}
              tooltipPlace="left"
              onClick={() =>
                this.props.onStateChangeAction({ listCollapsed: true })
              }
              isHelpActive={isHelpActive}
              testId={listCollapsedTestId}
              testIdHelp={listCollapsedHelpTestId}
            />
          </Column.Head>
          <Column.Body>
            {fetchingOpportunity ? (
              <LoadingMaskRow />
            ) : (
              <OpportunityList
                IsSearcher={IsSearcher}
                opportunityList={filteredOpportunity}
                onOpportunitySelect={this.handleOpportunitySelect}
                handleDeleteOpportunityNew={this.handleDeleteOpportunityNew}
                selectedOpportunity={selectedOpportunity}
                handleDeleteOpportunity={this.handleDeleteOpportunity}
                iproOpportunityListItem={iproOpportunityListItem}
                labels={this.props?.labels}
              />
            )}
          </Column.Body>
        </Column>
        <Column collapse={detailCollapsed} className="col-2">
          <Column.Collapsed
            text={detailsColumnText}
            onClick={() =>
              this.props.onStateChangeAction({ detailCollapsed: false })
            }
            isHelpActive={isHelpActive}
            tooltipButton={toolTipExpandDetail}
            tooltipHelp={helpTooltipExpandDetail}
            tooltipPlace="left"
            testId={detailExpandTestId}
            testIdHelp={detailExpandHelpTestId}
          />
          <Column.Head>
            <Column.HeaderTitle isActive={!isEmpty(selectedOpportunity)}>
              {detailHeadingText}
            </Column.HeaderTitle>
            <Button
              className="collapseBtn"
              onClick={() =>
                this.props.onStateChangeAction({ detailCollapsed: true })
              }
              tooltipButton={toolTipCollapseDetail}
              tooltipHelp={helpTooltipCollapseDetail}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={detailCollapsedTestId}
              testIdHelp={detailCollapsedHelpTestId}
            />
          </Column.Head>
          <Column.Body className="flex">
            <OpportunityDetail
              selectedOpportunity={selectedOpportunity}
              emptyOpportunityInfo={emptyOpportunityInfo}
              locationsLabel={locationsLabel}
              startDateLabel={startDateLabel}
              SearcherOpportunitySentDetailFee={
                labels.SearcherOpportunitySentDetailFee
              }
              durationLabel={durationLabel}
              companyLabel={companyLabel}
              descriptionLabel={descriptionLabel}
              countryLabel={countryLabel}
              SearchParameterHeading={SearchParameterHeading}
              Lbltitle={Lbltitle}
              LblProfile={LblProfile}
              LblSkills={LblSkills}
              LblKeyword={LblKeyword}
              LblIndustry={LblIndustry}
              LblCertification={LblCertification}
              LblCountry={LblCountry}
              LblInterest={LblInterest}
              LblRestore={LblRestore}
              BtnAccept={BtnAccept}
              BtnDecline={BtnDecline}
              BtnRestore={BtnRestore}
              LblLanguage={LblLanguage}
              selectInterest={selectInterest}
              handleOpportunityDetailAcceptClick={
                this.handleOpportunityDetailAcceptClick
              }
              handleOpportunityDetailDeclinedClick={
                this.handleOpportunityDetailDeclinedClick
              }
              handleOpportunityDetailCompanyClick={
                this.handleOpportunityDetailCompanyClick
              }
              IsSearcher={IsSearcher}
              opportunityType={opportunityType}
              showPresentationDetail={showPresentationDetail}
              showCompanyDetails={showCompanyDetails}
              onOpportunityRestore={this.handleOpportunityRestore}
              isAccepted={isAccepted}
            />
          </Column.Body>
        </Column>
        {selectedOpportunity && selectedOpportunity.Company && (
          <Column collapse={companyPresentDetailCollapsed} className="col-3">
            <Column.Collapsed
              text={companyPresentationHeadingText}
              onClick={() =>
                this.props.onStateChangeAction({
                  companyPresentDetailCollapsed: false,
                })
              }
              isHelpActive={isHelpActive}
              tooltipButton={toolTipExpandUserDetail}
              tooltipHelp={helpTooltipExpandUserDetail}
              tooltipPlace="left"
              testId={userDetailExpandTestId}
              testIdHelp={userDetailExpandHelpTestId}
            />
            <Column.Head>
              <Column.HeaderTitle isActive>
                {companyPresentationHeadingText}
              </Column.HeaderTitle>
              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({
                    companyPresentDetailCollapsed: true,
                  })
                }
                tooltipButton={toolTipCollapseUserDetail}
                tooltipHelp={helpTooltipCollapseUserDetail}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId={userDetailCollapsedTestId}
                testIdHelp={userDetailCollapsedHelpTestId}
              />
            </Column.Head>
            <Column.Body className="flex">
              {showCompanyDetails && (
                <CompanyDetail
                  selectedCompany={selectedCompany}
                  addressLabel={companyAddressLabel}
                  industryLabel={companyIndustryLabel}
                  countryLabel={companyCountryLabel}
                  phoneLabel={companyPhoneLabel}
                  webUrlLabel={companyWebUrlLabel}
                  vatLabel={companyVatLabel}
                />
              )}
              {showPresentationDetail && (
                <PresentationDetails
                  selectedOpportunityNew={selectedOpportunity}
                  showCompanyDetails={showCompanyDetails}
                  showPresentationDetail={showPresentationDetail}
                  labels={labels}
                  socialMedia={socialMedia}
                  getAllOpportunity={this.getAllOpportunity}
                />
              )}
            </Column.Body>
          </Column>
        )}
      </PageWrapper>
    );
  }
}

//export default Opportunity;
const mapStateToProps = (
  {
    systemLabel,
    iproOpportunityNew,
    iproOpportunityAccepted,
    iproOpportunityDeclined,
    currency,
  },
  { isAcceptedView, isDeclinedView }
) => {
  const { labels } = systemLabel;
  const { currencies } = currency;
  const opportunityProps = isAcceptedView
    ? iproOpportunityAccepted
    : isDeclinedView
    ? iproOpportunityDeclined
    : iproOpportunityNew;
  return { labels, currencies, ...opportunityProps };
};

export default connect(mapStateToProps, {
  notificationAction,
  unreadOpportunitiesAction,
  onStateChangeAction,
})(Opportunity);
