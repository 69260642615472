import React, { Component } from "react";
import { EmptyInfo, Button } from "../../../common";
import { connect } from "react-redux";
import moment from "moment";
import ImgSrc from "../../../assets/images/present.svg";
import ReactHtmlParser from "react-html-parser";
import { Messages } from "../../../utilities";
import "./networkdetail.scss";

class NetworkDetail extends Component {
  renderDetailDate = (date) => {
    return moment(date).format("ddd MMM DD YYYY H:mm:ss");
  };

  render() {
    const {
      labels,
      selectedUser,
      currentPresent,
      companyList,
      Profiles,
      isInvitationsView,
      isConnectedView,
      BtnAccept,
      BtnDecline,
      handleDeleteNetwork,
      handleAcceptNetwork,
      BtnAddOpportunity,
      BtnAddCollaboration,
      handleCreateNewOpportunityClick,
      handleCreateNewShortlistClick,
    } = this.props;
    let loggedUser = JSON.parse(localStorage.getItem("User"));
    return (
      <div className="network-detail-component">
        {!selectedUser.UserId ? (
          <EmptyInfo>{labels.NO_ITEM_LABEL}</EmptyInfo>
        ) : (
          <div className="MsgWrapper">
            <div className="companyBigIcon">
              <div
                className={`dashItemImg ${
                  selectedUser.ProfilePicture ? "" : "no-dp"
                }`}
              >
                <img
                  className="roundeImg"
                  src={
                    selectedUser.ProfilePicture
                      ? selectedUser.ProfilePicture
                      : ImgSrc
                  }
                  alt="network"
                />
              </div>
            </div>
            <div className="titleLabel">
              {selectedUser.UserFirstname +
                " " +
                (selectedUser.UserLastname ? selectedUser.UserLastname : "")}
            </div>
            <div className="feedbackWrap">
              <div className="msgItemDiv">
                <div className="msgItem">
                  <label className="labelName">{labels.TYPE_LABEL}</label>
                  <label className="answerLabel">
                    {selectedUser.IsFreelancer
                      ? labels.IPRO_NAVIGATION_ROLE_LABEL
                      : labels.SEARCHER_NAVIGATION_ROLE_LABEL}
                  </label>
                </div>
                {selectedUser && selectedUser.UserEmail && (
                  <div className="msgItem">
                    <label className="labelName">{labels.EMAIL_LABEL}</label>
                    <label className="answerLabel">
                      {selectedUser.UserEmail}
                    </label>
                  </div>
                )}
                {selectedUser.IsFreelancer ? (
                  <React.Fragment>
                    {currentPresent && currentPresent.Title && (
                      <div className="msgItem">
                        <label className="labelName">
                          {labels.TITLE_LABEL}
                        </label>
                        <label className="answerLabel">
                          {currentPresent.Title}
                        </label>
                      </div>
                    )}
                    {currentPresent && currentPresent.UserName && (
                      <div className="msgItem">
                        <label className="labelName">
                          {labels.USER_NAME_LABEL}
                        </label>
                        <label className="answerLabel">
                          {currentPresent.UserName}
                        </label>
                      </div>
                    )}
                    {Profiles && Profiles.ProfileValue && (
                      <div className="msgItem">
                        <label className="labelName">{labels.ROLE_LABEL}</label>
                        <label className="answerLabel">
                          {Profiles.ProfileValue}
                        </label>
                      </div>
                    )}
                    {currentPresent && currentPresent.WebUrl && (
                      <div className="msgItem">
                        <label className="labelName">
                          {labels.WEB_URL_LABEL}
                        </label>
                        <label className="answerLabel">
                          {currentPresent.WebUrl}
                        </label>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {companyList && companyList.CompanyName && (
                      <div className="msgItem">
                        <label className="labelName">{labels.NAME_LABEL}</label>
                        <label className="answerLabel">
                          {companyList.CompanyName}
                        </label>
                      </div>
                    )}
                    {companyList && companyList.IndustryValue && (
                      <div className="msgItem">
                        <label className="labelName">
                          {labels.INDUSTRY_LABEL}
                        </label>
                        <label className="answerLabel">
                          {companyList.IndustryValue}
                        </label>
                      </div>
                    )}
                    {companyList && companyList.CountryName && (
                      <div className="msgItem">
                        <label className="labelName">
                          {labels.COUNTRY_LABEL}
                        </label>
                        <label className="answerLabel">
                          {companyList.CountryName}
                        </label>
                      </div>
                    )}
                    {companyList && companyList.CompanyAddress && (
                      <div className="msgItem">
                        <label className="labelName">
                          {labels.ADDRESS_LABEL}
                        </label>
                        <label className="answerLabel">
                          {companyList.CompanyAddress}
                        </label>
                      </div>
                    )}
                    {companyList && companyList.UserPhonenumberValue && (
                      <div className="msgItem">
                        <label className="labelName">
                          {labels.PHONE_LABEL}
                        </label>
                        <label className="answerLabel">
                          {companyList.UserPhonenumberValue}
                        </label>
                      </div>
                    )}
                    {companyList && companyList.WebUrl && (
                      <div className="msgItem">
                        <label className="labelName">
                          {labels.WEB_URL_LABEL}
                        </label>
                        <label className="answerLabel">
                          {companyList.WebUrl}
                        </label>
                      </div>
                    )}
                    {companyList && companyList.Vat && (
                      <div className="msgItem">
                        <label className="labelName">{labels.VAT_LABEL}</label>
                        <label className="answerLabel">{companyList.Vat}</label>
                      </div>
                    )}
                  </React.Fragment>
                )}
                <React.Fragment>
                  {isInvitationsView ? (
                    <div className="invRequest">
                      <Button
                        className="invAccept SendButton"
                        text={labels.iProOpportunityNewDetailBtnAccept}
                        onClick={() => handleAcceptNetwork(selectedUser)}
                        tooltipButton={labels.iProOpportunityNewDetailBtnAccept}
                        tooltipHelp={labels.iProOpportunityNewDetailBtnAccept}
                        tooltipPlace="left"
                      />
                      <Button
                        className="invDecline SendButton"
                        text={labels.iProOpportunityNewDetailBtnDecline}
                        onClick={() => handleDeleteNetwork(selectedUser)}
                        tooltipButton={labels.iProOpportunityNewDetailBtnDecline}
                        tooltipHelp={labels.iProOpportunityNewDetailBtnDecline}
                        tooltipPlace="left"
                      />
                    </div>
                  ) : null}
                </React.Fragment>
                <React.Fragment>
                  {isConnectedView && !loggedUser.IsFreelancer ? (
                    <div className="invRequest">
                      <Button
                        className="invAccept SendButton"
                        
                        text={BtnAddOpportunity}
                        onClick={() =>
                          handleCreateNewShortlistClick(selectedUser)
                        }
                        tooltipButton={BtnAddOpportunity}
                        tooltipHelp={BtnAddOpportunity}
                        tooltipPlace="left"
                        testId="accept-opportunitynew-btn"
                      />
                      <Button
                        className="invAccept SendButton"
                        text={BtnAddCollaboration}
                        onClick={() =>
                          handleCreateNewOpportunityClick(selectedUser)
                        }
                        tooltipButton={BtnAddCollaboration}
                        tooltipHelp={BtnAddCollaboration}
                        tooltipPlace="left"
                        testId="accept-opportunitynew-btn"
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(NetworkDetail);
