
import React from 'react';
import './custom-input.scss';
export const CustomInputNew = ({
  isValid=true,
  validate,
  value,
  label,
  isLoading,
  children
}) => (
  <div
    className={`${
      !isValid || (validate && !value) ? "inValid " : " "
    } input-field ${!label ? "no-label" : ""}`}
  >
    <h6>{label}</h6>
    <div>
      {children}
    </div>
  </div>
);