import React, { Component } from "react";
import { connect } from "react-redux";
import {
  isEmpty,
  find,
  map,
  filter,
  includes,
  toLower,
  head,
  isNull,
  first,
} from "lodash";
import { PageWrapper } from "../../../components";
import last from "lodash/last";
import {
  Column,
  ConfirmDialog,
  Button,
  SearchInput,
  LoadingMaskRow,
  BulletList,
  List,
  LoadingMask,
} from "../../../common";
import SearcherOpportunityForm from "./common/SearcherOpportunityForm";
import ShortlistResumeDetail from "../../Shortlist/component/ShortlistResumeDetail";
import ShortlistResumes from "../../Shortlist/component/ShortlistResumes";
import { notificationAction } from "../../../actions";
import "./searcherOpportunityDraft.scss";
import {
  getShortlistsApi,
  getShortlistResumesApi,
  getOpportunityApi,
  deleteOpportunityApi,
  deleteOpportunityAndAssociationsApi,
  getSavedSearchesApi,
  saveOpportunity,
  sendOpportunityApi,
  getAllLocationsApi,
  getAllLanguagesApi,
  getDraftOpportunityDetailApi,
} from "../../Opportunity/opportunityApi";
import moment from "moment";
import { getCompaniesApi } from "../../Company/companyApi";
import { isNullOrUndefined } from "util";
import { onStateChangeAction } from "./createNewOpportunityAction";
import { getCurrenciesApi } from "../../Collaboration/common/collaborationApi";
import { deleteResumeApi } from "../../Shortlist/shortlistApi";
class CreateNewOpportunity extends Component {
  state = {};

  getShortlists = () => {
    getShortlistsApi()
      .then((response) => {
        if (response.success) {
          this.props.onStateChangeAction({
            shortlists: response.items,
            filterShortlists: response.items,
          });
        }
        if (
          sessionStorage.getItem("opportunity-new") ||
          sessionStorage.getItem("opportunity")
        ) {
          let selectedShortlists = [];
          const selectedItem = first(response.items);
          selectedShortlists = [selectedItem];
          this.props.onStateChangeAction({ selectedShortlists });
        }
      })
      .catch((response) => response);
  };
  getCompanies = () => {
    getCompaniesApi().then((response) => {
      if (response.success) {
        const { items } = response;
        this.props.onStateChangeAction({
          userCompanies: map(items, ({ CompanyName, UserCompanyId }) => {
            return { label: CompanyName, value: UserCompanyId };
          }),
          selectedCompany:
            items.length > 0
              ? { label: items[0].CompanyName, value: items[0].UserCompanyId }
              : {},
        });
      }
    });
  };
  getSavedSearches = () => {
    getSavedSearchesApi().then((response) => {
      if (response.success) {
        const { items } = response;
        const lastItem = last(response.items);

        if (
          sessionStorage.getItem("opportunity-new") ||
          sessionStorage.getItem("opportunity")
        ) {
          this.props.onStateChangeAction({
            savedSearches: map(items, ({ SearchName, SavedSearchId }) => {
              return { label: SearchName, value: SavedSearchId };
            }),
            selectedSaveSearch:
              items.length > 0
                ? {
                    label: lastItem.SearchName,
                    value: lastItem.SavedSearchId,
                  }
                : {},
          });
        } else {
          this.props.onStateChangeAction({
            savedSearches: map(items, ({ SearchName, SavedSearchId }) => {
              return { label: SearchName, value: SavedSearchId };
            }),
            selectedSaveSearch:
              items.length > 0
                ? { label: items[0].SearchName, value: items[0].SavedSearchId }
                : {},
          });
        }
      }
    });
  };
  getAllLocations = () => {
    getAllLocationsApi().then((response) => {
      if (response.success) {
        const allLocationsList = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        this.props.onStateChangeAction({ allLocationsList });
      }
    });
  };
  getAllLanguages = () => {
    getAllLanguagesApi().then((response) => {
      if (response.success) {
        const allLanguagesList = response.items.map((item) => ({
          ...item,
          value: item.LanguageId,
          label: item.LanguageValue,
        }));
        this.props.onStateChangeAction({ allLanguagesList });
      }
    });
  };

  setRequestName = () => {
    if (
      sessionStorage.getItem("opportunity-new") ||
      sessionStorage.getItem("opportunity")
    ) {
      const { selectedOpportunity } = this.props;
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          RequestName: `Quick Opportunity at ${moment(new Date()).format(
            "D MMMM YYYY"
          )} at ${moment(new Date()).format("LTS")}`,
        },
      });
    }
  };

  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));

          this.props.onStateChangeAction({ allCurrenciesList });
        }
      })
      .catch((err) => console.log("Err ", err));
  }

  componentDidMount() {
    this.getCurrencies();
    this.setRequestName();
    this.getShortlists();
    this.getCompanies();
    this.getSavedSearches();
    this.getAllLocations();
    this.getAllLanguages();
    const selectedOpp = sessionStorage.getItem("opportunity");
    if (selectedOpp) {
      const selectedOpportunity = JSON.parse(selectedOpp);
      this.handleOpportunityClick({ selectedOpportunity });
    }
  }

  componentWillUnmount() {
    // sessionStorage.removeItem("opportunity-new");
    // sessionStorage.removeItem("opportunity");
    // sessionStorage.removeItem("shortList");
  }

  replaceObjectKeys = ({ oldObject, mappingObject }) => {
    return Object.keys(oldObject).reduce((acc, key) => {
      acc[mappingObject[key]] = oldObject[key];
      return acc;
    }, {});
  };
  handleOpportunityClick = ({ selectedOpportunity }) => {
    const { RequestId } = selectedOpportunity;
    this.props.onStateChangeAction({
      isFetchingShortlist: true,
    });
    getDraftOpportunityDetailApi({ RequestId }).then((response) => {
      if (response.success) {
        const { items } = response;
        const {
          ShortLists,
          UserCompanyId,
          SavedSearchedId,
          Languages,
          DurationType,
          FeeCurrencyType,
          ...rest
        } = items;
        const UserCompany = filter(
          this.props.userCompanies,
          (company) => company.value === UserCompanyId
        )[0];
        const SavedSearches = filter(
          this.props.savedSearches,
          (search) => search.value === SavedSearchedId
        )[0];
        this.props.onStateChangeAction({
          selectedOpportunity: {
            ...this.props.selectedOpportunity,
            Language: Languages,
            DurationType: { value: DurationType, label: DurationType },
            FeeCurrencyType: { value: FeeCurrencyType, label: FeeCurrencyType },
            ...rest,
          },

          selectedCompany: UserCompany ? UserCompany : {},
          selectedSaveSearch: SavedSearches ? SavedSearches : {},
          isFetchingShortlist: false,
        });
      }
    });
  };
  handleDateChange = (date) => {
    const { selectedOpportunity } = this.props;
    try {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          invalidStartDate: false,
          StartDate: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
        },
      });
    } catch (e) {}
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { opportunities } = this.props;
    const filterOpportunires = filter(opportunities, (opportunity) =>
      includes(toLower(opportunity.RequestName), toLower(value))
    );
    this.props.onStateChangeAction({ filterOpportunires });
  };

  handleOpportunityDelete = ({ selectedOpportunity, e }) => {
    e.stopPropagation();
    this.props.onStateChangeAction({
      selectedOpportunity,
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftOpportunityDeleteConformationMsg,
    });
  };

  handleYesClick = () => {
    const {
      yesClickCount,
      selectedOpportunity: { RequestId },
    } = this.props;
    if (yesClickCount === 0) {
      this.props.onStateChangeAction({
        dialogMessage: this.props.labels
          .InfoSearcherOpportunityDraftOpportunityDeleteAssociationsConformationMsg,
        yesClickCount: yesClickCount + 1,
      });
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: "",
    });

    this.props.onStateChangeAction({ isLoading: true });
    getOpportunityApi({ RequestId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const { ShortLists, SavedSearchedId } = items;
          if (isEmpty(ShortLists)) {
            deleteOpportunityApi({ RequestId })
              .then((response) => {
                if (response.success) {
                  this.props.onStateChangeAction({ isLoading: false });
                }
              })
              .catch((response) => {
                this.props.onStateChangeAction({ isLoading: false });
              });
            return;
          }
          deleteOpportunityAndAssociationsApi({
            RequestId,
          })
            .then((response) => {
              if (response.success) {
                this.props.onStateChangeAction({ isLoading: false });
              }
            })
            .catch((response) => {
              this.props.onStateChangeAction({ isLoading: false });
            });
        }
      })
      .catch((response) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  handleSearchBlur = () => {
    const { searchListHover } = this.props;
    if (searchListHover) return;
    this.props.onStateChangeAction({ isSearchFocus: false });
  };

  handleShortListSelect = ({ selectedShortlist }) => {
    const { selectedShortlists } = this.props;
    const alreadySelected = filter(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId === selectedShortlist.ShortlistId
    );
    if (isEmpty(alreadySelected)) {
      this.props.onStateChangeAction({
        selectedShortlists: [...selectedShortlists, selectedShortlist],
        isSearchFocus: false,
      });
      return;
    }
    this.props.onStateChangeAction({ isSearchFocus: false });
  };

  handleFormSelectChange = (name, selectedOption) => {
    if (name === "selectedSavedSearch") {
      this.props.onStateChangeAction({
        selectedSaveSearch: isNullOrUndefined(selectedOption)
          ? null
          : selectedOption,
      });
      return;
    }
    if (!selectedOption) return;
    if (name === "SelectedCompany") {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...this.props.selectedOpportunity,
          invalidSelectedCompany: false,
        },
        selectedCompany: selectedOption,
      });
      return;
    }
    const { selectedOpportunity } = this.props;
    if (name === "DurationType" || name === "FeeCurrencyType") {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalid${name}`]: false,
          [name]: selectedOption,
        },
      });
      return;
    }
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        [name]: selectedOption,
      },
    });
  };

  handleLanguageSelect = ({ label, value }) => {
    const { selectedOpportunity } = this.props;
    const alreadySelcted = filter(
      selectedOpportunity.Language,
      (language) => language.LanguageId === value
    );
    if (!isEmpty(alreadySelcted)) return;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        Language: [
          ...selectedOpportunity.Language,
          { LanguageId: value, LanguageValue: label },
        ],
      },
    });
  };

  handleLocationSelect = ({ label, value }) => {
    const { selectedOpportunity } = this.props;
    const alreadySelcted = filter(
      selectedOpportunity.Countries,
      (country) => country.CountryId === value
    );
    if (!isEmpty(alreadySelcted)) return;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        Countries: [
          ...selectedOpportunity.Countries,
          { CountryId: value, CountryName: label },
        ],
      },
    });
  };

  handleShortlistSearchChange = (e) => {
    const { value } = e.target;
    const { shortlists } = this.props;
    const filterShortlists = filter(shortlists, (shortlist) =>
      includes(toLower(shortlist.ShortlistName), toLower(value))
    );
    this.props.onStateChangeAction({ filterShortlists, searchKey: value });
  };

  handleSelectedShortlistClick = ({ selectedShortlist }) => {
    getShortlistResumesApi({ ShortlistId: selectedShortlist.ShortlistId })
      .then((response) => {
        if (response.success) {
          const { items } = response;
          const selectedResume = head(items);
          if (isEmpty(items)) {
            const info = {
              message: this.props.labels
                .InfoSearcherOpportunityDraftEmptyShortlist,
              status: "error",
            };
            this.props.notificationAction(info);
          }
          this.props.onStateChangeAction({
            selectedShortlist,
            shortlistResumes: items,
            selectedResume,
          });
        }
      })
      .catch((response) => {});
  };

  handleSelectedShortlistDelete = ({ shortlist, e }) => {
    e.stopPropagation();
    let { selectedShortlists } = this.props;
    selectedShortlists = filter(
      selectedShortlists,
      (item) => item.ShortlistId !== shortlist.ShortlistId
    );
    this.props.onStateChangeAction({
      selectedShortlists,
      isShorlistDelete: false,
    });
  };

  validateField = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [`invalid${name}`]: !value,
        [name]: value,
      },
    });
  };

  setFieldValue = ({ name, value }) => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        [name]: value,
      },
    });
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "RequestName") {
      this.validateField({ name, value });
      return;
    }
    if (name === "HourlyFee") {
      if (isNaN(value)) {
        return;
      }
    }
    this.setFieldValue({ name, value });
  };

  handleResumeSelect = (selectedResume) => {
    const { shortlistResumes } = this.props;
    selectedResume = find(shortlistResumes, {
      ResumeId: selectedResume.ResumeId,
    });
    this.props.onStateChangeAction({ selectedResume });
  };

  handleResumeDelete = (item) => {
    let { shortlistResumes, selectedResume, selectedShortlist } = this.props;
    deleteResumeApi(item.ResumeId, selectedShortlist.ShortlistId)
      .then((data) => {
        if (data.success) {
          shortlistResumes = shortlistResumes.filter(
            (x) => x.ResumeId !== item.ResumeId
          );
          this.props.onStateChangeAction({
            shortlistResumes,
            selectedResume:
              item.ResumeId == selectedResume.ResumeId ? {} : selectedResume,
          });
        }
      })
      .catch((err) => console.log("Error ", err));
  };

  handleSliderChange = (value) => {
    const { selectedOpportunity } = this.props;
    if (value) {
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          Duration: value,
        },
      });
    }
  };

  handleCountryDelete = ({ selectedCountry }) => {
    const { selectedOpportunity } = this.props;
    const filterCountries = filter(
      selectedOpportunity.Countries,
      (country) => country.CountryId !== selectedCountry.CountryId
    );
    this.props.onStateChangeAction({
      ...this.props,
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        Countries: filterCountries,
      },
    });
  };

  handleLanguageDelete = ({ selectedLanguage }) => {
    const { selectedOpportunity } = this.props;
    const filterLanguages = filter(
      selectedOpportunity.Language,
      (country) => country.LanguageId !== selectedLanguage.LanguageId
    );
    this.props.onStateChangeAction({
      ...this.props,
      selectedOpportunity: {
        ...this.props.selectedOpportunity,
        Language: filterLanguages,
      },
    });
  };
  makeMandatoryFieldRed = () => {
    const { selectedOpportunity } = this.props;
    this.props.onStateChangeAction({
      selectedOpportunity: {
        ...selectedOpportunity,
        invalidRequestName: !undefined,
        RequestName: undefined,
        invalidStartDate: !undefined,
        StartDate: undefined,
        // invalidDurationType: !undefined,
        // DurationType: undefined
      },
    });
  };
  handleOpportunitySend = ({ IsSent }) => {
    const {
      selectedOpportunity,
      selectedShortlists,
      selectedCompany,
    } = this.props;
    const {
      RequestName,
      StartDate,
      FeeCurrencyType,
      HourlyFee,
    } = selectedOpportunity;
    if (!RequestName && !StartDate) {
      const info = {
        message: this.props.labels.OPPORTUNITY_CREATE_REQURIED_FIELD_MESSAGE,
        status: "error",
      };
      this.makeMandatoryFieldRed();
      this.props.notificationAction(info);
      return;
    }

    if (!RequestName) {
      const info = {
        message: this.props.labels.InfoSearcherOpportunityDraftTitleRequiredMsg,
        status: "error",
      };
      this.validateField({ name: "RequestName", value: RequestName });
      this.props.notificationAction(info);
      return;
    }
    if (!StartDate) {
      const info = {
        message: this.props.labels.collStartDateRequired,
        status: "error",
      };
      this.props.notificationAction(info);
      this.validateField({ name: "StartDate", value: StartDate });
      return;
    }
    if (HourlyFee && !FeeCurrencyType) {
      const info = {
        message: this.props.labels.collCurrancyTypeRequired,
        status: "error",
      };
      this.props.notificationAction(info);
      const { selectedOpportunity } = this.props;
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidFeeCurrencyType`]: !FeeCurrencyType,
          FeeCurrencyType: FeeCurrencyType,
        },
      });
      return;
    }
    if (isEmpty(selectedCompany)) {
      const info = {
        message: this.props.labels.oppCompanyValidationMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      const { selectedOpportunity } = this.props;
      this.props.onStateChangeAction({
        selectedOpportunity: {
          ...selectedOpportunity,
          [`invalidSelectedCompany`]: true,
          selectedCompany: selectedCompany,
        },
      });
      return;
    }
    if (isEmpty(selectedShortlists)) {
      const info = {
        message: this.props.labels
          .InfoSearcherOpportunityDraftSendEmptyShortlistMsg,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({
      dialogMessage: this.props.labels
        .InfoSearcherOpportunityDraftSendConformationMsg,
    });
  };

  handleOkClick = () => {
    let IsSent = true;
    const {
      selectedOpportunity,
      selectedShortlists,
      selectedCompany,
      selectedSaveSearch,
    } = this.props;
    const {
      RequestName,
      Description,
      Duration,
      HourlyFee,
      StartDate,
      FeeCurrencyType,
      DurationType,
    } = selectedOpportunity;
    const LanguageIds = map(
      selectedOpportunity.Language,
      (language) => language.LanguageId
    );
    const LocationsIds = map(
      selectedOpportunity.Countries,
      (country) => country.CountryId
    );

    const ShortListIds = map(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId
    );
    const opportunity = {
      RequestName,
      Description,
      Duration,
      HourlyFee,
      StartDate: StartDate,
      FeeCurrencyType: FeeCurrencyType.value,
      DurationType: DurationType.value || "",
      IsSent,
      LanguageIds,
      LocationsIds,
      ShortListIds,
      UserCompanyId: selectedCompany.value,
      SavedSearchedId: selectedSaveSearch && selectedSaveSearch.value,
      EndDate: StartDate,
      IsSent,
      RequestCountries: null,
    };
    this.props.onStateChangeAction({ opportunitySaveLoading: true });
    sendOpportunityApi({ opportunity }).then((response) => {
      if (response.success) {
        const info = {
          message: this.props.labels.InfoSearcherOpportunityCreateSent,
          status: "success",
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({ opportunitySaveLoading: false });
        this.props.onStateChangeAction({
          selectedOpportunity: {
            invalidRequestName: "",
            invalidStartDate: "",
            Countries: [],
            Language: [],
            selectedSavedSearch: "",
            FeeCurrencyType: "",
            DurationType: { value: "Weeks", label: "Weeks" },
            Duration: "4",
            invalidDurationType: "",
            invalidCompanyId: "",
          },
          selectedShortlists: [],
          shortlistResumes: [],
          selectedResume: {},
          selectedSaveSearch: {},
        });
        return;
      }
      const info = {
        message: response.message,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({ opportunitySaveLoading: false });
    });
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  handleOpportunityUpdate = ({ IsSent = false }) => {
    const {
      selectedOpportunity,
      selectedShortlists,
      selectedCompany,
      selectedSaveSearch,
    } = this.props;
    const {
      RequestName,
      Description,
      Duration,
      HourlyFee,
      StartDate,
      FeeCurrencyType,
      DurationType,
    } = selectedOpportunity;

    if (!RequestName) {
      const info = {
        message: this.props.labels.InfoSearcherOpportunityDraftTitleRequiredMsg,
        status: "error",
      };
      this.validateField({ name: "RequestName", value: RequestName });
      this.props.notificationAction(info);
      return;
    }

    const LanguageIds = map(
      selectedOpportunity.Language,
      (language) => language.LanguageId
    );
    const LocationsIds = map(
      selectedOpportunity.Countries,
      (country) => country.CountryId
    );

    const ShortListIds = map(
      selectedShortlists,
      (shortlist) => shortlist.ShortlistId
    );

    const opportunity = {
      RequestName,
      Description,
      Duration,
      HourlyFee,
      StartDate: StartDate,
      FeeCurrencyType: FeeCurrencyType.value,
      DurationType: DurationType.value || "",
      IsSent,
      LanguageIds,
      LocationsIds,
      ShortListIds,
      UserCompanyId: selectedCompany && selectedCompany.value,
      SavedSearchedId: selectedSaveSearch && selectedSaveSearch.value,
      EndDate: StartDate,
      IsSent: false,
      RequestCountries: null,
    };
    this.props.onStateChangeAction({ opportunitySaveLoading: true });
    saveOpportunity({ opportunity }).then((response) => {
      if (response.success) {
        const info = {
          message: this.props.labels
            .InfoSearcherOpportunityDraftSavedSuccessfullyMsg,
          status: "success",
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({ opportunitySaveLoading: false });
        this.props.onStateChangeAction({
          selectedOpportunity: {
            invalidRequestName: "",
            invalidStartDate: "",
            Countries: [],
            Language: [],
            selectedSavedSearch: "",
            FeeCurrencyType: "",
            DurationType: "Weeks",
            Duration: "4",
            invalidDurationType: "",
            invalidCompanyId: "",
          },
          selectedSaveSearch: {},
          selectedShortlists: [],
          shortlistResumes: [],
          selectedResume: {},
        });
      }
    });
  };
  handleCreateNewClick = () => {
    this.props.onStateChangeAction({
      selectedOpportunity: {
        invalidRequestName: "",
        invalidStartDate: "",
        Countries: [],
        Language: [],
        selectedSavedSearch: "",
        FeeCurrencyType: "",
        DurationType: "Weeks",
        Duration: "4",
        invalidDurationType: "",
        invalidCompanyId: "",
      },
      selectedSaveSearch: {},
      selectedShortlists: [],
      shortlistResumes: [],
      selectedResume: {},
    });
  };

  render() {
    const { labels, isHelpActive } = this.props;
    const {
      formCollapsed,
      shortlistCollapsed,
      resumeListCollapsed,
      resumeDetailCollapsed,
      dialogMessage,
      isLoading,
      isFetchingShortlist,
      isFetchingShortlistResume,
      selectedOpportunity,
      filterShortlists,
      isSearchFocus,
      selectedShortlists,
      selectedShortlist,
      shortlistResumes,
      selectedResume,
      selectedSaveSearch,
      selectedCompany,
      savedSearches,
      userCompanies,
      languages,
      opportunitySaveLoading,
      allLocationsList,
      allLanguagesList,
      searchKey,
      allCurrenciesList,
    } = this.props;

    return (
      <PageWrapper className="searcher-draft-opportunity-page">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              {dialogMessage ===
              this.props.labels
                .InfoSearcherOpportunityDraftSendConformationMsg ? (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-sent-ok"
                  onClick={this.handleOkClick}
                >
                  Yes
                </ConfirmDialog.Button>
              ) : (
                <ConfirmDialog.Button
                  className="dialog-btn"
                  testId="opportunity-sent-yes"
                  onClick={this.handleYesClick}
                >
                  Yes
                </ConfirmDialog.Button>
              )}
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={formCollapsed} className="col-1">
          <Column.Collapsed
            collapse={formCollapsed}
            onClick={() =>
              this.props.onStateChangeAction({ formCollapsed: false })
            }
            text={labels.SearcherOpportunityDraftDetailHeader}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={
              labels.TooltipSearcherOpportunityDraftOpportunityDetailExpand
            }
            tooltipHelp={
              labels.HlpTooltipSearcherOpportunityDraftOpportunityDetailExpand
            }
            testId="opportunity-form-expand-btn"
            testIdHelp="opportunity-form-expand-help-btn"
          />
          <Column.Head>
            <Column.HeaderTitle isActive>
              {labels.SearcherOpportunityDraftDetailHeader}
            </Column.HeaderTitle>
            <Button
              className="AddBtn"
              onClick={this.handleCreateNewClick}
              tooltipButton={labels.ToolTipOpportunityCreateButton}
              tooltipHelp={labels.HlpTooltipOpportunityCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId="add-new-company-btn"
              testIdHelp="add-new-company-help-btn"
            />
            <Button
              onClick={() =>
                this.props.onStateChangeAction({ formCollapsed: true })
              }
              className="collapseBtn"
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityDetailCollapse
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityDetailCollapse
              }
              testId="opportunity-form-collapse-btn"
              testIdHelp="opportunity-form-collapse-help-btn"
            />
          </Column.Head>
          <Column.Body>
            <SearcherOpportunityForm
              selectedOpportunity={selectedOpportunity}
              selectedCompany={selectedCompany}
              selectedSaveSearch={selectedSaveSearch}
              userCompanies={userCompanies}
              savedSearches={savedSearches}
              languages={languages}
              labels={labels}
              isLoading={opportunitySaveLoading}
              allLocations={allLocationsList}
              allLanguages={allLanguagesList}
              onFormSelectChange={this.handleFormSelectChange}
              onCollaborationSave={this.handleCollaborationSave}
              onFormFieldChange={this.handleFormFieldChange}
              onDateChange={this.handleDateChange}
              onSliderChange={this.handleSliderChange}
              onLanguageDelete={this.handleLanguageDelete}
              onCountryDelete={this.handleCountryDelete}
              onLanguageSelect={this.handleLanguageSelect}
              onLocationSelect={this.handleLocationSelect}
              onOpportunityUpdate={this.handleOpportunityUpdate}
              onOpportunitySend={this.handleOpportunitySend}
              titlePlaceholder={
                labels.SearcherOpportunityDraftDetailTitlePlaceholder
              }
              startDateLabel={labels.SearcherOpportunityDraftDetailStartDate}
              durationLabel={labels.SearcherOpportunityDraftDetailDuration}
              durationPlaceholder={
                labels.SearcherOpportunityDraftDetailDurationPlaceholder
              }
              hourlyFeeLabel={labels.SearcherOpportunityDraftDetailHourlyFee}
              hourlyFeePlaceholder={
                labels.SearcherOpportunityDraftDetailHourlyFeePlaceholder
              }
              hourlyFeeDropDownPlaceholder={
                labels.SearcherOpportunityDraftDetailHourlyFeeDropDownPlaceholder
              }
              companyLabel={labels.SearcherOpportunityDraftDetailCompany}
              companyPlaceholder={
                labels.SearcherOpportunityDraftDetailCompanyPlaceholder
              }
              locationLabel={labels.SearcherOpportunityDraftDetailLocation}
              locationPlaceholder={
                labels.SearcherOpportunityDraftDetailLocationPlaceholder
              }
              languageLabel={labels.SearcherOpportunityDraftDetailLanguage}
              languagePlaceholder={
                labels.SearcherOpportunityDraftDetailLanguagePlaceholder
              }
              savedSearchLabel={
                labels.SearcherOpportunityDraftDetailSavedSearch
              }
              savedSearchPlaceholder={
                labels.SearcherOpportunityDraftDetailSavedSearchPlaceholder
              }
              descriptionPlaceholder={labels.collDescription}
              saveButtonText={labels.SearcherOpportunityDraftDetailSave}
              sendButtonText={labels.SearcherOpportunityDraftDetailSend}
              allCurrencies={allCurrenciesList}
            />
          </Column.Body>
        </Column>
        <Column collapse={shortlistCollapsed} className="col-1">
          <Column.Collapsed
            collapse={shortlistCollapsed}
            onClick={() =>
              this.props.onStateChangeAction({ shortlistCollapsed: false })
            }
            text={labels.SearcherOpportunityDraftViewTitleShortlist}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={
              labels.TooltipSearcherOpportunityDraftOpportunityShortlistExpand
            }
            tooltipHelp={
              labels.HlpTooltipSearcherOpportunityDraftOpportunityShortlistExpand
            }
            testId="opportunity-shortlist-expand-btn"
            testIdHelp="opportunity-shortlist-expand-help-btn"
          />
          <Column.Head>
            <div className="search-list-wrapper">
              <SearchInput
                placeholder={
                  labels.SearcherOpportunityDraftShortlistSearchPlaceholder
                }
                onChange={this.handleShortlistSearchChange}
                value={searchKey}
                onFocus={() =>
                  this.props.onStateChangeAction({ isSearchFocus: true })
                }
                onBlur={this.handleSearchBlur}
                testId="search-input"
              />
            </div>
            <Button
              onClick={() =>
                this.props.onStateChangeAction({ shortlistCollapsed: true })
              }
              className="collapseBtn"
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunityShortlistCollapse
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunityShortlistCollapse
              }
              testId="opportunity-shortlist-collapse-btn"
              testIdHelp="opportunity-shortlist-collapse-help-btn"
            />
          </Column.Head>
          <Column.Body>
            {isSearchFocus ? (
              <List className="searched-shortlists">
                {map(filterShortlists, (shortlist) => (
                  <List.ListItem
                    key={shortlist.ShortlistId}
                    testId="opportunity-shortlist-search-list-item"
                    onClick={(e) =>
                      this.handleShortListSelect({
                        selectedShortlist: shortlist,
                      })
                    }
                    onTap={(e) =>
                      this.handleShortListSelect({
                        selectedShortlist: shortlist,
                      })
                    }
                    onMouseEnter={() =>
                      this.props.onStateChangeAction({
                        searchListHover: true,
                      })
                    }
                    onMouseLeave={() =>
                      this.props.onStateChangeAction({
                        searchListHover: false,
                      })
                    }
                  >
                    <span>{shortlist.ShortlistName}</span>
                  </List.ListItem>
                ))}
              </List>
            ) : isFetchingShortlist ? (
              <LoadingMaskRow />
            ) : (
              <div>
                <div className="shortlists-header">
                  {labels.SearcherOpportunityDraftShortlistHeading}
                </div>
                {selectedShortlists && selectedShortlists.length == 0 && (
                  <span className="no-msg">
                    {labels?.searcher_create_opportunity_no_selected_text}
                  </span>
                )}
                <BulletList testId="searcher-draft-opportunity-short-list">
                  {map(selectedShortlists, (shortlist) => (
                    <BulletList.ListItem
                      testId="selected-shortlist-item"
                      isActive={false}
                      isSelected={
                        selectedShortlist &&
                        shortlist.ShortlistId === selectedShortlist.ShortlistId
                      }
                      onClick={() =>
                        this.handleSelectedShortlistClick({
                          selectedShortlist: shortlist,
                        })
                      }
                      key={shortlist.ShortlistId}
                    >
                      <label className="listLabel">
                        {shortlist.ShortlistName}
                      </label>
                      <BulletList.Close
                        testId="searcher-draft-opportunity-delete-btn"
                        onClick={(e) =>
                          this.handleSelectedShortlistDelete({ shortlist, e })
                        }
                      />
                    </BulletList.ListItem>
                  ))}
                </BulletList>
              </div>
            )}
          </Column.Body>
        </Column>
        {!isEmpty(shortlistResumes) && (
          <Column collapse={resumeListCollapsed} className="col-2">
            <Column.Collapsed
              collapse={resumeListCollapsed}
              onClick={() =>
                this.props.onStateChangeAction({ resumeListCollapsed: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleShortlistResume}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistCollapse
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistCollapse
              }
              testId="opportunity-resumelist-expand-btn"
              testIdHelp="opportunity-resumelist-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive>{`${shortlistResumes &&
                shortlistResumes.length} ${
                labels.SearcherOpportunityDraftShortlistResumeHeading
              }`}</Column.HeaderTitle>

              <Button
                onClick={() =>
                  this.props.onStateChangeAction({ resumeListCollapsed: true })
                }
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistExpand
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistExpand
                }
                testId="opportunity-resumelist-collapse-btn"
                testIdHelp="opportunity-resumelist-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body>
              {isFetchingShortlistResume ? (
                <LoadingMaskRow />
              ) : (
                !isEmpty(shortlistResumes) && (
                  <ShortlistResumes
                    selectedResume={selectedResume}
                    onResumeSelect={this.handleResumeSelect}
                    shortlistResumes={shortlistResumes}
                    onResumeDelete={this.handleResumeDelete}
                  />
                )
              )}
            </Column.Body>
          </Column>
        )}
        {!isEmpty(selectedResume) && (
          <Column collapse={resumeDetailCollapsed} className="col-2">
            <Column.Collapsed
              collapse={resumeDetailCollapsed}
              onClick={() =>
                this.props.onStateChangeAction({ resumeDetailCollapsed: false })
              }
              text={labels.SearcherOpportunityDraftViewTitleResumeDetail}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={
                labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailExpand
              }
              tooltipHelp={
                labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailExpand
              }
              testId="opportunity-resumedetail-expand-btn"
              testIdHelp="opportunity-resumedetail-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive>Resume Detail</Column.HeaderTitle>

              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({
                    resumeDetailCollapsed: true,
                  })
                }
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.TooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailCollapse
                }
                tooltipHelp={
                  labels.HlpTooltipSearcherOpportunityDraftOpportunitySelectedShortlistDetailCollapse
                }
                testId="opportunity-resumedetail-collapse-btn"
                testIdHelp="opportunity-resumedetail-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body>
              {isFetchingShortlistResume ? (
                <LoadingMaskRow />
              ) : (
                !isEmpty(selectedResume) && (
                  <ShortlistResumeDetail resume={selectedResume} />
                )
              )}
            </Column.Body>
          </Column>
        )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  createNewOpportunity,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { IsFreelancer } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    isFreelancer: IsFreelancer,
    isHelpActive,
    ...createNewOpportunity,
  };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(CreateNewOpportunity);
