import React from "react";
import "./navigation-skeleton.scss";

const NavigationSkeleton = () => (
  <div className="navigation-skeleton">
    <div className="post">
      <div className="avatar" />
      <div className="line" />
    </div>
    <div className="post">
      <div className="avatar" />
      <div className="line" />
    </div>
    <div className="post">
      <div className="avatar" />
      <div className="line" />
    </div>
    <div className="post">
      <div className="avatar" />
      <div className="line" />
    </div>
    <div className="post">
      <div className="avatar" />
      <div className="line" />
    </div>
    <div className="post">
      <div className="avatar" />
      <div className="line" />
    </div>
    <div className="post">
      <div className="avatar" />
      <div className="line" />
    </div>
    <div className="post">
      <div className="avatar" />
      <div className="line" />
    </div>
  </div>
);
export default NavigationSkeleton;
