import React, { Component } from "react";
import ReactQuill from "react-quill";
import "./richtexteditor.scss";
class RichTextEditor extends Component {
  render() {
    const modules = {
      toolbar: [
        // [{ font: [] }],
        ["bold", "italic", "underline"],
        [{ size: ["small", false, "large", "huge"] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [{ color: [] }, { background: [] }],
        ["link", "clean"],
      ],
    };
    const {
      handleChange,
      value,
      isInvalidValue,
      placeholder,
      readOnly,
    } = this.props;
    return (
      <ReactQuill
        className={`rich-text-editor ${isInvalidValue ? "inValid" : ""}`}
        value={value ? value : ""}
        onChange={handleChange}
        modules={modules}
        bounds=".rich-text-editor"
        placeholder={placeholder}
        readOnly={readOnly}
      />
    );
  }
}
export default RichTextEditor;
