import React, { Component } from "react";
import { Panel, ListGroup, Input, Select } from "../../../../common";
import { RichTextEditor } from "../../../../components";

class OtherAcheivement extends Component {
  state = {
    yearsList: [],
  };
  componentDidMount() {
    this.handleYearsLoad();
  }
  handleYearsLoad = () => {
    const currentYear = new Date().getFullYear();
    var startYear = 1970;
    var yearsList = [];
    while (startYear <= currentYear) {
      startYear++;
      const newYear = {
        value: startYear,
        label: startYear,
      };
      yearsList.push(newYear);
    }
    this.setState({
      yearsList,
    });
  };
  TypeList = [
    { value: 1, label: "Workshop" },
    { value: 2, label: "Training" },
    { value: 3, label: "Course" },
    { value: 4, label: "Research" },
    { value: 5, label: "Volunteering" },
    { value: 6, label: "Publication" },
    { value: 7, label: "Public speaking" },
  ];
  render() {
    const {
      isHelpActive,
      labels,
      onOtherAcheivementDelete,
      onOtherAcheivementDescriptionChange,
      onOtherAcheivementNameChange,
      onOtherAcheivementTypeChange,
      onOtherAcheivementYearChange,
      otherAchivenmentsList,
      onOtherAcheivementAdd,
    } = this.props;
    return (
      <Panel className="other-acheivement-component">
        <Panel.Heading>
          <Panel.Title>
            {labels.RESUME_EDIT_OTHER_ACHIEVEMENT_SECTION_TITLE}
          </Panel.Title>
          <Panel.Add
            onClick={onOtherAcheivementAdd}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={labels.RESUME_EDIT_ACHIEVEMENT_ADD_BUTTON_TOOLTIP}
            tooltipHelp={labels.RESUME_EDIT_ACHIEVEMENT_ADD_BUTTON_HELP}
            testId="resume-edit-acheivement-add-btn"
            testIdHelp="resume-edit-acheivement-add-help-btn"
          />
        </Panel.Heading>
        <Panel.Body>
          {otherAchivenmentsList.map((item) => (
            <ListGroup key={item.uniqueId}>
              <ListGroup.Item>
                <ListGroup.Column>
                  <input
                    type="text"
                    name="Title"
                    className={`input-text ${item.isInvalid ? "inValid" : ""}`}
                    value={item.Title}
                    placeholder={
                      labels.RESUME_EDIT_OTHER_ACHIEVEMENT_NAME_PLACEHOLDER
                    }
                    onChange={(e) =>
                      onOtherAcheivementNameChange(item.uniqueId, e)
                    }
                    onBlur={(e) =>
                      onOtherAcheivementNameChange(item.uniqueId, e)
                    }
                    test-data-id="resume-edit-acheivement-name"
                  />
                </ListGroup.Column>
                <ListGroup.Column size={0}>
                  <div
                    // disabled={isDisabled}
                    onClick={() => onOtherAcheivementDelete(item)}
                    className="close_btn_purple"
                  >
                    <i className="fa fa-times-circle" />
                  </div>
                </ListGroup.Column>
              </ListGroup.Item>
              <ListGroup.Item>
                <ListGroup.Column>
                  <Select
                    name={"Year"}
                    className={`select-acheivement-year select-input`}
                    value={item.Year}
                    onChange={(options) =>
                      onOtherAcheivementYearChange(options, item.uniqueId)
                    }
                    options={this.state.yearsList}
                  />
                </ListGroup.Column>
                <ListGroup.Column>
                  <Select
                    name={"Type"}
                    className={`select-acheivement-type select-input`}
                    value={item.AchivenmentId}
                    onChange={(options) =>
                      onOtherAcheivementTypeChange(options, item.uniqueId)
                    }
                    options={this.TypeList}
                  />
                </ListGroup.Column>
              </ListGroup.Item>
              <ListGroup.Item>
                <RichTextEditor
                  id="other-acheivement-description"
                  name="Description"
                  value={item.Description || ""}
                  handleChange={(e) =>
                    onOtherAcheivementDescriptionChange(item.uniqueId, e)
                  }
                  placeholder={labels.RESUME_EDIT_ACHEIVEMENT_DESCRIPTION_LABEL}
                />
              </ListGroup.Item>
            </ListGroup>
          ))}
        </Panel.Body>
      </Panel>
    );
  }
}

export default OtherAcheivement;
