import React, { Component, Fragment } from "react";
import {
  Column,
  Button,
  EmptyInfo,
  ConfirmDialog,
  Input,
  LoadingMaskRow,
  List,
  SearchInput,
} from "../../common";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { notificationAction } from "./../../actions";
import { connect } from "react-redux";
import find from "lodash/find";
import map from "lodash/map";
import first from "lodash/first";
import {
  ArrowUpOutlined,
  CalendarOutlined,
  DownOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  getShortlistApi,
  getShortlistResumeApi,
  postShortlistApi,
  deleteShortlistApi,
  getOppuntinitiesApi,
  searchResumesApi,
  postSearchApi,
  addResumeClickedApi,
  getSavedSearchesApi,
  updateSearchApi,
} from "./searchApi";
import "./search.scss";
import ShortlistResumes from "../Shortlist/component/ShortlistResumes";
import ShortlistResumeDetail from "../Shortlist/component/ShortlistResumeDetail";
import SearchList from "./component/SearchList";
import { isNullOrUndefined } from "util";
import { htmlParser } from "../../utilities";
import { privateRoutes, publicRoutes } from "../../Routes/routing";
import { withRouter } from "react-router-dom";
import { onStateChangeAction } from "./searchAction";
import { Checkbox, Col, Divider, Dropdown, Row, Select, Space } from "antd";
import { Collapse } from "antd";
import DetailModal from "./component/detailModal";
import ImageIcon from "../../assets/images/heart.png";

const { Panel } = Collapse;
class Search extends Component {
  state = {
    resumePage: 1,
    isFetchMore: false,
  };

  componentDidMount() {
    window.view = this.props.isSaveSearch ? "SAVED_SEARCH" : "SEARCH";
    window.actionName = this.props.isSaveSearch ? "savedSearch" : "search";
    if (!this.props.isVisitor) {
      this.getShortlist();
    }
  }
  handleSearchListChange = (list) => {
    this.props.onStateChangeAction({ searchList: list });
  };
  handleSearchAvailablityChange = (date) => {
    this.props.onStateChangeAction({ availibility: date });
  };
  handleSearchClick = (searchList, availibility) => {
    this.props.onStateChangeAction({
      fetchingResumes: true,
      newShortlistValue: "",
      newSaveSearchValue: "",
      availibility,
      createNewSaveSearch: false,
      showExistingSaveSearches: false,
      showOpportunities: false,
      showExistingShortlists: false,
      showResumeList: true,
      createNewShortlist: false,
    });
    const info = {
      AvailabilityDate: availibility.date,
      AvailabilityType: availibility.value,
      CertificationIds: searchList.certifications.map(
        (item) => item.CertificationId
      ),
      Countries: searchList.countries.map((item) => item.CountryId),
      IndusteryIds: searchList.industries.map((item) => item.IndustryId),
      KeywordIds: searchList.keywords.map((item) => item.KeywordId),
      Languages: searchList.languages.map((item) => item.LanguageId),
      ProfileIds: searchList.roles.map((item) => item.ProfileId),
      SkillIds: searchList.skills.map((item) => item.SkillId),
      Type: "Only20",
      limit: 20,
      page: 1,
      isVisitor: this.props.isVisitor,
    };

    if (
      !(
        info.IndusteryIds.length ||
        info.KeywordIds.length ||
        info.ProfileIds.length ||
        info.SkillIds.length ||
        info.CertificationIds.length ||
        info.Languages.length ||
        info.Countries.length
      )
    ) {
      const info = {
        message: this.props.labels.SEARCH_NO_CRITERIA_VALIDATION,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        fetchingResumes: false,
        showResumeDetail: false,
        shortlistResumes: [],
      });
      return;
    }
    searchResumesApi(info).then((data) => {
      if (data.success) {
        this.setState({
          isFetchMore: data.items.length < 20 ? false : true,
          resumePage: 1,
        });
        this.props.onStateChangeAction({
          showResumeDetail: true,
          shortlistResumes: data.items.map((item, index) => {
            return {
              ...item,
              active: index == 0 ? true : false,
            };
          }),
          selectedResume: first(data.items) || {},
          fetchingResumes: false,
        });
      }
    });
  };

  fetchMoreResumes = () => {
    const { searchList_availibility, searchList_searchList } = this.props;
    const { isFetchMore } = this.state;
    if (!isFetchMore) {
      return;
    }
    const info = {
      AvailabilityDate: searchList_availibility.date,
      AvailabilityType: searchList_availibility.value,
      CertificationIds: searchList_searchList.certifications.map(
        (item) => item.CertificationId
      ),
      Countries: searchList_searchList.countries.map((item) => item.CountryId),
      IndusteryIds: searchList_searchList.industries.map(
        (item) => item.IndustryId
      ),
      KeywordIds: searchList_searchList.keywords.map((item) => item.KeywordId),
      Languages: searchList_searchList.languages.map((item) => item.LanguageId),
      ProfileIds: searchList_searchList.roles.map((item) => item.ProfileId),
      SkillIds: searchList_searchList.skills.map((item) => item.SkillId),
      Type: "Only20",
      limit: 20,
      page: this.state.resumePage + 1,
      isVisitor: this.props.isVisitor,
    };
    this.setState({ resumePage: this.state.resumePage + 1 });

    searchResumesApi(info).then((data) => {
      if (data.success) {
        this.setState({ isFetchMore: data.items.length < 20 ? false : true });
        let currentResumesList = this.props.shortlistResumes;
        let allSearchedResumesList = currentResumesList.concat(data.items);
        this.props.onStateChangeAction({
          showResumeDetail: true,
          shortlistResumes: allSearchedResumesList.map((item, index) => {
            return {
              ...item,
              lastItemInSearch:
                index == allSearchedResumesList.length - 1 ? true : false,
            };
          }),
          fetchingResumes: false,
        });
      }
    });
  };

  handleSaveSearch = () => {
    const { labels } = this.props;
    const { availibility, searchList } = this.props;
    const info = {
      AvailabilityDate: availibility.date,
      AvailabilityType: availibility.value,
      CertificationIds: searchList.certifications.map(
        (item) => item.CertificationId
      ),
      Countries: searchList.countries.map((item) => item.CountryId),
      IndusteryIds: searchList.industries.map((item) => item.IndustryId),
      KeywordIds: searchList.keywords.map((item) => item.KeywordId),
      Languages: searchList.languages.map((item) => item.LanguageId),
      ProfileIds: searchList.roles.map((item) => item.ProfileId),
      SkillIds: searchList.skills.map((item) => item.SkillId),
      Type: "Only20",
      limit: 20,
      page: 1,
      isVisitor: this.props.isVisitor,
    };

    if (
      !(
        info.IndusteryIds.length ||
        info.KeywordIds.length ||
        info.ProfileIds.length ||
        info.SkillIds.length ||
        info.CertificationIds.length ||
        info.Languages.length ||
        info.Countries.length
      )
    ) {
      const info = {
        message: this.props.labels.SEARCH_NO_CRITERIA_VALIDATION,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({
      dialogSaveSearchMessage: labels.SAVE_SEARCH_DIALOG_MESSAGE,
      createNewSaveSearch: false,
      showExistingShortlists: false,
      showExistingSaveSearches: false,
      createNewShortlist: false,
      showOpportunities: false,
      newShortlistValue: "",
    });
  };

  handleSaveSearchClick = ({ existingSaveSearchId = 0 }) => {
    const { newSaveSearchValue } = this.props;
    const {
      certifications,
      countries,
      industries,
      keywords,
      languages,
      roles,
      skills,
    } = this.props.searchList;
    const saveSearch = {
      SavedSearchId: existingSaveSearchId,
      SearchName: newSaveSearchValue,
      Certifications:
        certifications &&
        certifications.map((item) => {
          return {
            Id: item.CertificationId,
            ExpLevel: 0,
            LookupValue: item.CertificationValue,
          };
        }),
      Countries:
        countries &&
        countries.map((item) => {
          return {
            Id: item.CountryId,
            ExpLevel: 0,
            LookupValue: item.CountryName,
          };
        }),
      Industries:
        industries &&
        industries.map((item) => {
          return {
            Id: item.IndustryId,
            ExpLevel: item.ExperienceLevel,
            LookupValue: item.IndustryValue,
          };
        }),
      Keywords:
        keywords &&
        keywords.map((item) => {
          return {
            Id: item.KeywordId,
            ExpLevel: 0,
            LookupValue: item.KeywordValue,
          };
        }),
      Languages:
        languages &&
        languages.map((item) => {
          return {
            Id: item.LanguageId,
            ExpLevel: item.ExperienceLevel,
            LookupValue: item.LanguageValue,
          };
        }),
      Profiles:
        roles &&
        roles.map((item) => {
          return {
            Id: item.ProfileId,
            ExpLevel: item.ExperienceLevel,
            LookupValue: item.ProfileValue,
          };
        }),
      Skills:
        skills &&
        skills.map((item) => {
          return {
            Id: item.SkillId,
            ExpLevel: item.ExperienceLevel,
            LookupValue: item.SkillValue,
          };
        }),
    };
    if (existingSaveSearchId === 0) {
      postSearchApi(saveSearch)
        .then((data) => {
          if (data.success) {
            this.props.onStateChangeAction({
              isLoading: false,
              newSaveSearchValue: "",
              showResumeList: true,
              showExistingShortlists: false,
              createNewShortlist: false,
              createNewSaveSearch: false,
              showResumeDetail: true,
            });

            const info = {
              message: this.props.labels.InfoISearchSavedSuccessfully,
              status: "success",
            };
            this.props.notificationAction(info);
            if (this.props.isSaveSearch) {
              this.props.newSavedSearchSave();
            }
            return;
          }
          const info = {
            message: data.message,
            status: "error",
          };
          this.props.notificationAction(info);
        })
        .catch((err) => {
          this.props.onStateChangeAction({ isLoading: false });
        });
      return;
    } else {
      updateSearchApi(saveSearch)
        .then((data) => {
          if (data.success) {
            this.props.onStateChangeAction({
              newSaveSearchValue: "",
              savedSearchesList: [],
              showResumeList: true,
              isLoading: false,
              showExistingShortlists: false,
              createNewShortlist: false,
              createNewSaveSearch: false,
              showResumeDetail: true,
              showExistingSaveSearches: false,
            });

            const info = {
              message: this.props.labels.InfoISearchSavedSuccessfully,
              status: "success",
            };
            this.props.notificationAction(info);
            getSavedSearchesApi()
              .then((data) => {
                if (data.success) {
                  this.props.onStateChangeAction({
                    savedSearchesList: data.items,
                  });
                  this.props.handleSavedSearchUpdate(data.items);
                } else {
                  const info = {
                    message: this.props.labels
                      .SEARCH_NO_SAVED_SEARCH_VALIDATION,
                    status: "info",
                  };
                  this.props.notificationAction(info);
                }
              })
              .catch(() => {});
            return;
          }
          const info = {
            message: data.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            isLoading: false,
          });
        })
        .catch((err) => {
          this.props.onStateChangeAction({ isLoading: false });
        });
      return;
    }
  };
  getOpportunities = () => {
    this.props.onStateChangeAction({ fetchingOpportunities: true });
    getOppuntinitiesApi()
      .then((data) => {
        this.props.onStateChangeAction({
          opportunityList: data.items,
          fetchingOpportunities: false,
        });
        if (data.items.length > 0) {
          this.props.onStateChangeAction({
            showOpportunities: true,
          });
        } else {
          const info = {
            message: this.props.labels.InfoISearchListNoQuickOpportunity,
            status: "info",
          };
          this.props.notificationAction(info);
        }
      })
      .then((err) => {
        this.props.onStateChangeAction({
          fetchingOpportunities: false,
        });
      });
  };
  getShortlist = () => {
    getShortlistApi()
      .then((data) => {
        this.props.onStateChangeAction({
          shortlist: data.items,
        });
      })
      .catch(() => {});
  };

  handleSelectShortlist = (item) => {
    this.props.onStateChangeAction({
      selectedShortlist: item,
      fetchingResumes: true,
      shortlistResumes: [],
      createNewShortlist: false,
      showOpportunities: false,
    });
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        this.props.onStateChangeAction({
          shortlistResumes: data.items,
          fetchingResumes: false,
          selectedResume: first(data.items) || {},
        });
      })
      .catch((err) => {
        this.props.onStateChangeAction({ fetchingResumes: false });
      });
  };

  handleResumeSelect = (item) => {
    const { shortlistResumes } = this.props;
    const selectedResume = find(shortlistResumes, { ResumeId: item.ResumeId });
    addResumeClickedApi(item.ResumeId).catch((response) =>
      console.log(response.message)
    );
    this.props.onStateChangeAction({ selectedResume });
  };

  handleShortlistActive = (activeItem) => {
    const shortlistResumes = this.props.shortlistResumes.map((item) => ({
      ...item,
      active:
        activeItem.ResumeId === item.ResumeId ? !item.active : item.active,
    }));
    this.props.onStateChangeAction({
      shortlistResumes,
    });
  };
  handleCreateNewShorlistClick = () => {
    const { newShortlistValue, shortlistResumes } = this.props;
    this.props.onStateChangeAction({ isLoading: true });
    const filterActiveResume = shortlistResumes.filter((item) => {
      if (!isNullOrUndefined(item.active) && item.active) return item.ResumeId;
    });
    const ResumeIds = map(filterActiveResume, (item) => {
      return item.ResumeId;
    });
    postShortlistApi({
      ShortlistId: -1,
      ShortlistName: newShortlistValue,
      ResumeIdsList: ResumeIds,
    })
      .then((data) => {
        if (data.success) {
          this.getShortlist();
          this.props.onStateChangeAction({
            isLoading: false,
            createNewShortlist: false,
          });
          const info = {
            message: this.props.labels.shortlistSuccessAdded,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({ isLoading: false });
          return;
        }
        const info = {
          message: data.message,
          status: "error",
        };
        this.props.notificationAction(info);
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch((err) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  handleNewShortList = () => {
    this.props.onStateChangeAction({
      newShortlistValue: "",
      selectedResume: {},
      createNewShortlist: true,
      selectedShortlist: {},
    });
  };
  handleDeleteShortlist = (id) => {
    this.props.onStateChangeAction({
      deletedId: id,
      dialogMessage: this.props.labels.shortlistDeletionConfirmation,
    });
  };
  handleRegisterButtonClick = () => {
    this.props.history.push(
      publicRoutes.login.path + "/?showRegistrationForm=true"
    );
  };

  handleYesClick = () => {
    const { deletedId } = this.props;
    this.props.onStateChangeAction({ dialogMessage: "", isLoading: true });
    deleteShortlistApi(deletedId)
      .then((data) => {
        if (data.success) {
          this.getShortlist();
          const info = {
            message: this.props.labels.shortlistSuccessfullDeletion,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            selectedShortlist: {},
            deletedId: null,
          });
        } else {
          const info = {
            message: data.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  handleCreateQuickOpportunity = () => {
    const { shortlistResumes } = this.props;
    const { labels } = this.props;
    const activeResume = find(shortlistResumes, "active");
    if (!activeResume) {
      this.getShortlist();
      const info = {
        message: labels.InfoIShortlistResumeListSelectionError,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({
      dialogOpportunityMessage: labels.InfoIShortlistResumeListQuickOpportunity,
      showExistingShortlists: false,
      showOpportunities: false,
      createNewShortlist: false,
      newShortlistValue: "",
    });
  };
  handleCreateShortlist = () => {
    const { shortlistResumes } = this.props;
    const { labels } = this.props;
    const activeResume = find(shortlistResumes, "active");
    if (!activeResume) {
      this.getShortlist();
      const info = {
        message: labels.InfoIShortlistResumeListSelectionError,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({
      dialogShortlistMessage:
        labels.InfoISearchShortlistResumeListQuickShortlist,
      newShortlistValue: "",
      showExistingShortlists: false,
      showOpportunities: false,
      createNewShortlist: false,
    });
  };
  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "", deletedId: null });
  };
  handleExistingClick = () => {
    this.props.onStateChangeAction({ dialogOpportunityMessage: "" });
    this.getOpportunities();
  };
  handleCreateNewClick = () => {
    this.createNewShortlist().then((data) => {
      this.props.onStateChangeAction({
        newSaveSearchValue: `SaveSearch at ${moment(new Date()).format(
          "D MMMM YYYY"
        )} at ${moment(new Date()).format("LTS")}`,
      });
      this.handleSaveSearchClick({ existingSaveSearchId: 0 });
      sessionStorage.setItem("opportunity-new", true);
      this.props.history.push(privateRoutes.searcherCreateOpportunity.path);
    });
  };
  createNewShortlist = () => {
    const { shortlistResumes } = this.props;
    const selectedResumesIds = shortlistResumes
      .filter((item) => item.active)
      .map((item) => item.ResumeId);
    this.props.onStateChangeAction({ isLoading: true });
    const info = {
      ShortlistName: `New shortlist for ${
        selectedResumesIds.length
      } resume(s) at ${moment(new Date()).format("D MMMM YYYY")} at ${moment(
        new Date()
      ).format("LTS")}`,
      ResumeIdsList: selectedResumesIds,
    };
    return postShortlistApi(info)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  handleCancelClick = () => {
    this.props.onStateChangeAction({ dialogOpportunityMessage: "" });
  };
  handleSelectOpportunity = (item) => {
    sessionStorage.setItem("opportunity", JSON.stringify(item));
    this.props.history.push(privateRoutes.searcherCreateOpportunity.path);
  };
  handleSelectExistingShortlist = (item) => {
    const filterActiveResume = this.props.shortlistResumes.filter((item) => {
      if (!isNullOrUndefined(item.active) && item.active) return item.ResumeId;
    });
    const ResumeIds = map(filterActiveResume, (item) => {
      return item.ResumeId;
    });
    postShortlistApi({
      ShortlistId: item.ShortlistId,
      ShortlistName: item.ShortlistName,
      ResumeIdsList: ResumeIds,
    })
      .then((data) => {
        if (data.success) {
          this.props.onStateChangeAction({
            isLoading: false,
            showExistingShortlists: false,
            createNewShortlist: false,
          });

          const info = {
            message: this.props.labels
              .SEARCH_RESUME_ADDED_SHORTLIST_SUCESSS_MESSAGE,
            status: "success",
          };
          this.props.notificationAction(info);
          return;
        }
        const info = {
          message: data.message,
          status: "error",
        };
        this.props.notificationAction(info);
      })
      .catch((err) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  handleNewShortlistClick = () => {
    this.props.onStateChangeAction({
      createNewShortlist: true,
      dialogShortlistMessage: "",
    });
  };
  handleExistingShortlistClick = () => {
    this.props.onStateChangeAction({
      dialogShortlistMessage: "",
    });
    getShortlistApi()
      .then((data) => {
        if (data.sucess) {
          this.props.onStateChangeAction({
            shortlist: data.items,
          });
        }
        if (data.items.length > 0) {
          this.props.onStateChangeAction({
            showExistingShortlists: true,
          });
        } else {
          const info = {
            message: this.props.labels.InfoISearchListNoExistingShortlist,
            status: "info",
          };
          this.props.notificationAction(info);
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({});
      });
  };
  handleCancelShortlistClick = () => {
    this.props.onStateChangeAction({ dialogShortlistMessage: "" });
  };
  handleCancelSaveSearchClick = () => {
    this.props.onStateChangeAction({ dialogSaveSearchMessage: "" });
  };
  handleSearchTabChange = (name) => {
    this.props.onStateChangeAction({ selectedSearchTab: name });
  };
  //Save Search
  handleExistingSaveSearchClick = () => {
    this.props.onStateChangeAction({
      newShortlistValue: "",
      newSaveSearchValue: "",
      dialogSaveSearchMessage: "",
      createNewSaveSearch: false,
      showExistingShortlists: false,
      showOpportunities: false,
      createNewShortlist: false,
      showResumeDetail: false,
    });
    getSavedSearchesApi()
      .then((data) => {
        if (data.success) {
          this.props.onStateChangeAction({
            savedSearchesList: data.items,
          });
        }
        if (data.items.length > 0) {
          this.props.onStateChangeAction({
            showExistingSaveSearches: true,
            showResumeList: false,
          });
        } else {
          const info = {
            message: this.props.labels.SEARCH_NO_SAVED_SEARCH_VALIDATION,
            status: "info",
          };
          this.props.notificationAction(info);
        }
      })
      .catch(() => {});
  };
  handleSelectExistingSaveSearch = (item) => {
    this.handleSaveSearchClick({ existingSaveSearchId: item.SavedSearchId });
  };
  handleNewSaveSearchClick = () => {
    this.props.onStateChangeAction({
      dialogSaveSearchMessage: "",
      newShortlistValue: "",
      newSaveSearchValue: "",
      createNewSaveSearch: true,
      showExistingShortlists: false,
      showOpportunities: false,
      createNewShortlist: false,
      showResumeList: false,
      showResumeDetail: false,
    });
  };
  renderSearchCollapseBtn = () => {
    const { labels, isHelpActive } = this.props;
    return (
      <Button
        className="collapseBtn"
        tooltipPlace="left"
        tooltipButton={labels.SEARCH_FILTER_LIST_COLLAPSE_TOOLTIP}
        onClick={() =>
          this.props.onStateChangeAction({ searchCollapsed: true })
        }
        isHelpActive={isHelpActive}
        tooltipHelp={labels.SEARCH_FILTER_LIST_COLLAPSE_HELP}
        testId={"resume-search-lookup-collapse-btn"}
        testIdHelp={"resume-search-lookup-collapse-help-btn"}
      />
    );
  };

  showDrawer = () => {
    this.setState((st) => ({
      ...st,
      modal: true,
    }));
  };
  onClose = () => {
    this.setState((st) => ({
      ...st,
      modal: false,
    }));
  };

  handleModalClose = () => {
    this.setState((st) => ({
      ...st,
      modal: false,
    }));
  };

  render() {
    const {
      searchCollapsed,
      saveSearchCollapse,
      resumeCollapsed,
      resumeDetailCollapsed,
      opportunitiesCollapse,
      shortlistCollapse,
      createCollapse,

      showOpportunities,
      showExistingShortlists,
      createNewSaveSearch,
      showResumeList,
      showResumeDetail,
      showExistingSaveSearches,
      savedSearchesList,
      dialogSaveSearchMessage,
      selectedShortlist,
      selectedResume,
      shortlistResumes,
      dialogMessage,
      newShortlistValue,
      isLoading,
      fetchingResumes,
      dialogOpportunityMessage,
      dialogShortlistMessage,
      opportunityList,
      fetchingOpportunities,
      selectedSearchTab,
      newSaveSearchValue,
      createNewShortlist,
      labels,
      notificationAction,
      isHelpActive,
      isSaveSearchBtnDisable,
      isQuickOppBtnDisable,
      isCreateShortlistBtnDisable,
      isVisitor,
      isSaveSearch,
    } = this.props;
    const { isFetchMore, items } = this.state;

    return (
      <PageWrapper className="search-page">
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleYesClick}
                testId="delete-yes"
              >
                {labels.shortlistDeletionCurtainYESBtnText}
              </ConfirmDialog.Button>

              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              >
                {labels.shortlistDeletionCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {dialogOpportunityMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>
              {dialogOpportunityMessage}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleExistingClick}
                testId={"resume-search-existing-opportunity-btn"}
              >
                {labels.ShortlistResumeListCurtainExistingBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleCreateNewClick}
                testId={"resume-search-new-opportunity-btn"}
              >
                {labels.ShortlistResumeListCurtainCreateNewBtnText}
              </ConfirmDialog.Button>

              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleCancelClick}
                testId={"resume-search-cancel-opportunity-btn"}
              >
                {labels.ShortlistResumeListCurtainCancelBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {dialogShortlistMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>
              {dialogShortlistMessage}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleExistingShortlistClick}
                testId={"resume-search-existing-shortlist-btn"}
              >
                {labels.ShortlistResumeListCurtainExistingBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNewShortlistClick}
                testId={"resume-search-new-shortlist-btn"}
              >
                {labels.ShortlistResumeListCurtainCreateNewBtnText}
              </ConfirmDialog.Button>

              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleCancelShortlistClick}
                testId={"resume-search-cancel-shortlist-btn"}
              >
                {labels.ShortlistResumeListCurtainCancelBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {dialogSaveSearchMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>
              {dialogSaveSearchMessage}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleExistingSaveSearchClick}
                testId={"resume-search-existing-saved-search-btn"}
              >
                {labels.ShortlistResumeListCurtainExistingBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNewSaveSearchClick}
                testId={"resume-search-new-saved-search-btn"}
              >
                {labels.ShortlistResumeListCurtainCreateNewBtnText}
              </ConfirmDialog.Button>

              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleCancelSaveSearchClick}
                testId={"resume-search-cancel-saved-search-btn"}
              >
                {labels.ShortlistResumeListCurtainCancelBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {/* {isVisitor && (
          <ConfirmDialog>
            <ConfirmDialog.Message>
              {htmlParser(labels.VISITOR_SEARCH_NOTIFICATION_LABEL)}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleRegisterButtonClick}
                testId={"resume-search-existing-saved-search-btn"}
              >
                {htmlParser(labels.VISITOR_SEARCH_REGISTER_BUTTON_LABEL)}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )} */}
        <Column collapse={searchCollapsed} className="col-1 search-column">
          <Column.Collapsed
            onClick={() =>
              this.props.onStateChangeAction({ searchCollapsed: false })
            }
            tooltipPlace="left"
            text={labels.SEARCH_FILTER_LIST_VIEW_TITLE}
            tooltipButton={labels.SEARCH_FILTER_LIST_EXPAND_TOOLTIP}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.SEARCH_FILTER_LIST_EXPAND_HELP}
          />
          {isSaveSearch && (
            <Column.Head className="saved-search-header">
              <div className="search-list-wrapper">
                <SearchInput
                  placeholder={`${
                    this.props.filterSavedSearch.length > 0
                      ? `${labels.SAVED_SEARCH_LIST_PLACEHOLER}`
                      : `${labels.NO_SAVED_SEARCH_LIST_PLACEHOLDER}`
                  }`}
                  onChange={this.props.handleShortlistSearchChange}
                  onFocus={this.props.handleSearchFocus}
                  onBlur={this.props.handleSearchBlur}
                  testId="search-input"
                  value={this.props.selectedSavedSearchName}
                />
                {this.props.isSearchFocus && (
                  <List className="searched-shortlists">
                    {map(this.props.filterSavedSearch, (savedSearches) => (
                      <List.ListItem
                        key={savedSearches.SavedSearchId}
                        testId="opportunity-savedSearches-search-list-item"
                        onClick={(e) =>
                          this.props.handleSaveSearchSelect({
                            selectedSavedSearch: savedSearches,
                          })
                        }
                        onMouseEnter={this.props.handleMouseEnter}
                        onMouseLeave={this.props.handleMouseLeave}
                      >
                        {savedSearches.SearchName}
                        <List.Close
                          testId={
                            "opportunity-savedSearches-search-list-item-delete-btn"
                          }
                          onClick={(e) => {
                            this.props.handleSaveSearchDelete(
                              savedSearches.SavedSearchId
                            );
                            e.stopPropagation();
                          }}
                        />
                      </List.ListItem>
                    ))}
                  </List>
                )}
              </div>

              {this.renderSearchCollapseBtn()}
            </Column.Head>
          )}
          <Column.Head className="search-header">
            <Column.HeaderTitle isActive>
              <Button
                className={`profileIcon ${
                  selectedSearchTab === "role" ? "activeBtn" : ""
                }`}
                tooltipPlace="left"
                tooltipButton={labels.ToolTipSearchProfile}
                onClick={() => this.handleSearchTabChange("role")}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.SEARCH_ROLE_BTN_HELP}
                testId={"resume-search-profile-btn"}
                testIdHelp={"resume-search-profile-help-btn"}
              />

              <Button
                className={`skillIcon ${
                  selectedSearchTab === "skill" ? "activeBtn" : ""
                }`}
                tooltipPlace="left"
                tooltipButton={labels.ToolTipSearchSkill}
                onClick={() => this.handleSearchTabChange("skill")}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.SEARCH_SKILL_BTN_HELP}
                testId={"resume-search-skill-btn"}
                testIdHelp={"resume-search-skill-help-btn"}
              />
              <Button
                className={`keywordIcon ${
                  selectedSearchTab === "keyword" ? "activeBtn" : ""
                }`}
                tooltipPlace="left"
                tooltipButton={labels.ToolTipSearchKeyword}
                onClick={() => this.handleSearchTabChange("keyword")}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.SEARCH_KEYWORD_BTN_HELP}
                testId={"resume-search-keyword-btn"}
                testIdHelp={"resume-search-keyword-help-btn"}
              />
              <Button
                className={`companyIcon ${
                  selectedSearchTab === "industry" ? "activeBtn" : ""
                }`}
                tooltipPlace="left"
                tooltipButton={labels.ToolTipSearchIndustry}
                onClick={() => this.handleSearchTabChange("industry")}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.SEARCH_INDUSTRY_BTN_HELP}
                testId={"resume-search-industry-btn"}
                testIdHelp={"resume-search-industry-help-btn"}
              />
              <Button
                className={`educationIcon ${
                  selectedSearchTab === "certification" ? "activeBtn" : ""
                }`}
                tooltipPlace="left"
                tooltipButton={labels.ToolTipSearchCertification}
                onClick={() =>
                  this.props.onStateChangeAction({
                    selectedSearchTab: "certification",
                  })
                }
                isHelpActive={isHelpActive}
                tooltipHelp={labels.SEARCH_CERTIFICATION_BTN_HELP}
                testId={"resume-search-certification-btn"}
                testIdHelp={"resume-search-certification-help-btn"}
              />
              <Button
                className={`countryIcon ${
                  selectedSearchTab === "country" ? "activeBtn" : ""
                }`}
                tooltipPlace="left"
                tooltipButton={labels.ToolTipSearchCountry}
                onClick={() => this.handleSearchTabChange("country")}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.SEARCH_COUNTRY_BTN_HELP}
                testId={"resume-search-country-btn"}
                testIdHelp={"resume-search-country-help-btn"}
              />
              <Button
                className={`languageIcon ${
                  selectedSearchTab === "language" ? "activeBtn" : ""
                }`}
                tooltipPlace="left"
                tooltipButton={labels.ToolTipSearchLanguage}
                onClick={() => this.handleSearchTabChange("language")}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.SEARCH_LANGUAGE_BTN_HELP}
                testId={"resume-search-language-btn"}
                testIdHelp={"resume-search-language-help-btn"}
              />
            </Column.HeaderTitle>
            <Button
              className="SaveCloudBtn"
              onClick={this.handleSaveSearch}
              tooltipPlace="left"
              tooltipButton={labels.ToolTipSearchMenuSave}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.SEARCH_SAVE_CRITERIA_BTN_HELP}
              testId={"resume-search-savesearch-btn"}
              testIdHelp={"resume-search-savesearch-help-btn"}
              disabled={isSaveSearchBtnDisable}
            />
            {!isSaveSearch && this.renderSearchCollapseBtn()}
          </Column.Head>
          <Column.Body className="search-body">
            <SearchList
              isSaveSearch={isSaveSearch}
              isHelpActive={isHelpActive}
              labels={labels}
              selectedSearchTab={selectedSearchTab}
              onSearchListChange={this.handleSearchListChange}
              notificationAction={notificationAction}
              onSearchClick={this.handleSearchClick}
              onSearchTabChange={this.handleSearchTabChange}
              onSearchAvailablityChange={this.handleSearchAvailablityChange}
              savedSearchSettings={this.props.savedSearchSettings}
              isVisitor={isVisitor}
            />
          </Column.Body>
        </Column>
        {showResumeList && (
          <Column
            collapse={resumeCollapsed}
            className={`${shortlistResumes.length > 0 ? "col-1" : "col-2"}`}
          >
            <Column.Collapsed
              onClick={() =>
                this.props.onStateChangeAction({ resumeCollapsed: false })
              }
              tooltipButton={labels.TooltipShortlistResumeListExpand}
              tooltipPlace="left"
              text={labels.ViewTitleShortlistResult}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipShortlistResumeListExpand}
              testId={"resume-search-lookup-expand-btn"}
              testIdHelp={"resume-search-lookup-expand-help-btn"}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={selectedShortlist}>
                {fetchingResumes
                  ? ""
                  : //shortlistResumes.length > 0?
                    shortlistResumes.length +
                    " " +
                    labels.HeadingShorlistResumeListResult
                // : labels.HeadingShorlistResumeListNoResume
                }
              </Column.HeaderTitle>
              <Button
                onClick={this.handleCreateQuickOpportunity}
                className="create-opportunity"
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={
                  labels.HelpTooltipShortlistResumeListQuickOpportunity
                }
                tooltipButton={labels.ToolTipShortlistQuickOpportunity}
                testId={"resume-search-quick-opportunity-btn"}
                testIdHelp={"resume-search-quick-opportunity-help-btn"}
                disabled={isQuickOppBtnDisable}
              />
              <Button
                onClick={this.handleCreateShortlist}
                className="topShortlistBtn"
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.SEARCH_CREATE_QUICK_SHORTLIST_BTN_HELP}
                tooltipButton={labels.HlpSearchCreateShortlist}
                testId={"resume-search-quick-shortlist-btn"}
                testIdHelp={"resume-search-quick-shortlist-help-btn"}
                disabled={isCreateShortlistBtnDisable}
              />
              <Button
                className="collapseBtn"
                tooltipButton={labels.TooltipShortlistResumeListCollapse}
                onClick={() =>
                  this.props.onStateChangeAction({ resumeCollapsed: true })
                }
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistResumeListCollapse}
                testId={"resume-search-resumeList-collapse-btn"}
                testIdHelp={"resume-search-resumeList-collapse-help-btn"}
              />
            </Column.Head>
            <Column.Body className="flex shortlist-resume-body">
              {shortlistResumes.length > 0 ? (
                <ShortlistResumes
                  showCorrelation
                  hideRemoveButton
                  fetchMoreResumes={this.fetchMoreResumes}
                  isFetchMore={isFetchMore}
                  fetchingResumes={fetchingResumes}
                  handleSelectShortlist={this.handleSelectShortlist}
                  selectedShortlist={selectedShortlist}
                  onResumeSelect={this.handleResumeSelect}
                  onShortlistActive={this.handleShortlistActive}
                  selectedResume={selectedResume}
                  shortlistResumes={shortlistResumes}
                  searchedDate={this.props.availibility}
                />
              ) : (
                <EmptyInfo>{labels.Company_Search}</EmptyInfo>
              )}
            </Column.Body>
          </Column>
        )}
        {showOpportunities && (
          <Column collapse={opportunitiesCollapse} className="col-3">
            <Column.Collapsed
              onClick={() =>
                this.props.onStateChangeAction({ opportunitiesCollapse: false })
              }
              tooltipPlace="left"
              text={labels.ViewTitleShortlistOpportunityList}
              isHelpActive={isHelpActive}
              tooltipButton={labels.TooltipShortlistOpportunityListExpand}
              tooltipHelp={labels.HelpTooltipShortlistOpportunityListExpand}
              testId={"resume-search-opportunity-list-expand-btn"}
              testIdHelp={"resume-search-opportunity-list-expand-help-btn"}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={true}>
                {labels.HeadingShortlistOpportunityList}
              </Column.HeaderTitle>
              <Button
                className="HeaderCloseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({ showOpportunities: false })
                }
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipButton={
                  labels.TooltipShortlistSelectOpportunityListCloseButton
                }
                tooltipHelp={
                  labels.HelpTooltipShortlistSelectOpportunityListCloseButton
                }
                testId={"resume-search-opportunity-list-close-btn"}
                testIdHelp={"resume-search-opportunity-list-close-help-btn"}
              />
              <Button
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                onClick={() =>
                  this.props.onStateChangeAction({
                    opportunitiesCollapse: true,
                  })
                }
                tooltipButton={labels.TooltipShortlistOpportunityListCollapse}
                tooltipHelp={labels.HelpTooltipShortlistOpportunityListCollapse}
                testId={"resume-search-opportunity-list-collapse-btn"}
                testIdHelp={"resume-search-opportunity-list-collapse-help-btn"}
              />
            </Column.Head>
            <Column.Body>
              <List isFetching={fetchingOpportunities}>
                {opportunityList.map((item) => (
                  <List.ListItem
                    onClick={() => this.handleSelectOpportunity(item)}
                    key={item.RequestId}
                    testId={"resume-search-existing-opportunity-list-item"}
                  >
                    {item.RequestName}
                  </List.ListItem>
                ))}
              </List>
            </Column.Body>
          </Column>
        )}
        {showExistingShortlists && (
          <Column collapse={shortlistCollapse} className="col-3">
            <Column.Collapsed
              onClick={() =>
                this.props.onStateChangeAction({ shortlistCollapse: false })
              }
              tooltipPlace="left"
              text={labels.ViewTitleSearchShortlistList} //{labels.ViewTitleShortlistOpportunityList}
              isHelpActive={isHelpActive}
              tooltipButton={labels.HelpTooltipSearchShortlistListExpand}
              tooltipHelp={labels.HelpTooltipShortlistOpportunityListExpand}
              testId={"resume-search-shortlist-list-expand-btn"}
              testIdHelp={"resume-search-shortlist-list-expand-help-btn"}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={true}>
                {labels.HeadingSearchShortlistList}
              </Column.HeaderTitle>
              <Button
                className="HeaderCloseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({
                    showExistingShortlists: false,
                  })
                }
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipButton={
                  labels.TooltipShortlistSelectOpportunityListCloseButton
                }
                tooltipHelp={
                  labels.HelpTooltipShortlistSelectOpportunityListCloseButton
                }
                testId={"resume-search-shortlist-list-close-btn"}
                testIdHelp={"resume-search-shortlist-list-close-help-btn"}
              />
              <Button
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                onClick={() =>
                  this.props.onStateChangeAction({ shortlistCollapse: true })
                }
                tooltipButton={labels.HelpTooltipSearchShortlistListCollapse}
                tooltipHelp={labels.HelpTooltipShortlistOpportunityListCollapse}
                testId={"resume-search-shortlist-list-collapse-btn"}
                testIdHelp={"resume-search-shortlist-list-collapse-help-btn"}
              />
            </Column.Head>
            <Column.Body>
              <List>
                {this.props.shortlist.map((item) => (
                  <List.ListItem
                    onClick={() => this.handleSelectExistingShortlist(item)}
                    key={item.ShortlistId}
                    testId={"resume-search-existing-shortlist-list-item"}
                  >
                    {item.ShortlistName}
                  </List.ListItem>
                ))}
              </List>
            </Column.Body>
          </Column>
        )}
        {createNewShortlist && (
          <Column collapse={createCollapse} className="col-1">
            <Column.Collapsed
              onClick={() =>
                this.props.onStateChangeAction({ createCollapse: false })
              }
              tooltipButton={labels.TooltipShortlistCreateShortlistExpand}
              tooltipPlace="left"
              text={labels.ViewTitleShortlistCreateShortlist}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipShortlistCreateShortlistExpand}
              testId={"resume-search-shortlist-list-create-expand-btn"}
              testIdHelp={"resume-search-shortlist-list-create-expand-help-btn"}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={true}>
                {labels.HeadingShortlistCreateShortlist}
              </Column.HeaderTitle>
              <Button
                className="HeaderCloseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({ createNewShortlist: false })
                }
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipButton={
                  labels.TooltipShortlistSelectOpportunityListCloseButton
                }
                tooltipHelp={
                  labels.HelpTooltipShortlistSelectOpportunityListCloseButton
                }
                testId={"resume-search-shortlist-create-close-btn"}
                testIdHelp={"resume-search-shortlist-create-close-help-btn"}
              />
              <Button
                className="SaveCloudBtn"
                onClick={this.handleCreateNewShorlistClick}
                tooltipPlace="left"
                disabled={isLoading || !newShortlistValue}
                isHelpActive={isHelpActive}
                tooltipButton={labels.TooltipShortlistCreateShortlistSaveButton}
                tooltipHelp={
                  labels.HelpTooltipShortlistCreateShortlistSaveButton
                }
                testId={"resume-search-shortlist-list-create-save-btn"}
                testIdHelp={"resume-search-shortlist-list-create-save-help-btn"}
              />
              <Button
                className="collapseBtn"
                tooltipButton={labels.TooltipShortlistCreateShortlistCollapse}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistCreateShortlistCollapse}
                tooltipPlace="left"
                onClick={() =>
                  this.props.onStateChangeAction({ createCollapse: true })
                }
                testId={"resume-search-shortlist-list-create-collapse-btn"}
                testIdHelp={
                  "resume-search-shortlist-list-create-collapse-help-btn"
                }
              />
            </Column.Head>
            <Column.Body className="form-editor">
              <div className="form-row">
                <label className="form-label">
                  {labels.LabelSearchCreateShortlistName}
                </label>
                <Input
                  autoFocus
                  type="text"
                  testId="input-text-shortlist-name"
                  className="input-text"
                  value={newShortlistValue ? newShortlistValue : ""}
                  onChange={(e) =>
                    this.props.onStateChangeAction({
                      newShortlistValue: e.target.value,
                    })
                  }
                />
              </div>
            </Column.Body>
          </Column>
        )}
        {showExistingSaveSearches && (
          <Column collapse={saveSearchCollapse} className="col-3">
            <Column.Collapsed
              onClick={() =>
                this.props.onStateChangeAction({ saveSearchCollapse: false })
              }
              tooltipPlace="left"
              text={labels.ViewTitleSearchSavedSearchesList}
              isHelpActive={isHelpActive}
              tooltipButton={labels.TooltipSearchSavedSearchListExpand}
              tooltipHelp={labels.HelpTooltipSearchSavedSearchListExpand}
              testId={"resume-search-saved-search-list-expand-btn"}
              testIdHelp={"resume-search-saved-search-list-expand-help-btn"}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={true}>
                {labels.HeadingSearchSavedSearchList}
              </Column.HeaderTitle>
              <Button
                className="HeaderCloseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({
                    showExistingSaveSearches: false,
                    showResumeList: true,
                  })
                }
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipButton={labels.TooltipSavedSearchListCloseButton}
                tooltipHelp={labels.HelpTooltipSavedSearchListCloseButton}
                testId={"resume-search-saved-search-list-close-btn"}
                testIdHelp={"resume-search-saved-search-list-close-help-btn"}
              />
              <Button
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                onClick={() =>
                  this.props.onStateChangeAction({ saveSearchCollapse: true })
                }
                tooltipButton={labels.TooltipSearchSavedSearchListCollapse}
                tooltipHelp={labels.HelpTooltipSearchSavedSearchListCollapse}
                testId={"resume-search-saved-search-list-collapse-btn"}
                testIdHelp={"resume-search-saved-search-list-collapse-help-btn"}
              />
            </Column.Head>
            <Column.Body>
              <List>
                {savedSearchesList.map((item) => (
                  <List.ListItem
                    onClick={() => this.handleSelectExistingSaveSearch(item)}
                    key={item.SavedSearchId}
                    testId={"resume-search-existing-shortlist-list-item"}
                  >
                    {item.SearchName}
                  </List.ListItem>
                ))}
              </List>
            </Column.Body>
          </Column>
        )}
        {createNewSaveSearch && (
          <Column collapse={createCollapse} className="col-1">
            <Column.Collapsed
              onClick={() =>
                this.props.onStateChangeAction({ createCollapse: false })
              }
              tooltipButton={labels.TooltipSearchCreateSaveSearchExpand}
              tooltipPlace="left"
              text={labels.ViewTitleSearchCreateSaveSearch} //{"labels.ViewTitleShortlistCreateShortlist"}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipSearchCreateSaveSearchExpand}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={true}>
                {labels.HeadingSearchCreateSaveSearch}
              </Column.HeaderTitle>
              <Button
                className="HeaderCloseBtn"
                onClick={() => {
                  this.props.onStateChangeAction({
                    createNewSaveSearch: false,
                    newSaveSearchValue: "",
                    showResumeList: true,
                  });
                }}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipButton={
                  labels.TooltipShortlistSelectOpportunityListCloseButton
                }
                tooltipHelp={
                  labels.HelpTooltipShortlistSelectOpportunityListCloseButton
                }
                testId={"resume-search-opportunity-list-close-btn"}
                testIdHelp={"resume-search-opportunity-list-close-help-btn"}
              />
              <Button
                className="SaveCloudBtn"
                onClick={this.handleSaveSearchClick}
                tooltipPlace="left"
                testId="button-shortlist-save"
                disabled={isLoading || !newSaveSearchValue}
                isHelpActive={isHelpActive}
                tooltipButton={labels.TooltipSearchSaveSearchButton}
                tooltipHelp={labels.HelpTooltipSearchSaveSearchButton}
              />
              <Button
                className="collapseBtn"
                tooltipButton={labels.TooltipSearchCreateSaveSearchCollapse}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipSearchCreateSaveSearchCollapse}
                tooltipPlace="left"
                onClick={() =>
                  this.props.onStateChangeAction({ createCollapse: true })
                }
              />
            </Column.Head>
            <Column.Body className="form-editor">
              <div className="form-row">
                <label className="form-label">
                  {labels.HeadingSearchCreateSaveSearch}
                </label>
                <Input
                  autoFocus
                  type="text"
                  testId="input-text-shortlist-name"
                  className="input-text"
                  value={newSaveSearchValue ? newSaveSearchValue : ""}
                  onChange={(e) =>
                    this.props.onStateChangeAction({
                      newSaveSearchValue: e.target.value,
                    })
                  }
                />
              </div>
            </Column.Body>
          </Column>
        )}
        {!showOpportunities &&
          !showExistingShortlists &&
          !createNewShortlist &&
          showResumeDetail &&
          selectedResume &&
          selectedResume.ResumeId && (
            <Column collapse={resumeDetailCollapsed} className="col-3">
              <Column.Collapsed
                tooltipButton={labels.TooltipShortlistResumeDetailExpand}
                text={labels.ViewTitleShortlistDetail}
                onClick={() =>
                  this.props.onStateChangeAction({
                    resumeDetailCollapsed: false,
                  })
                }
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistResumeDetailExpand}
              />
              <Column.Head>
                <div className="heading">
                  {labels?.searcher_seach_resume_detail_title}
                </div>
                <Button
                  className="collapseBtn"
                  onClick={() =>
                    this.props.onStateChangeAction({
                      resumeDetailCollapsed: true,
                    })
                  }
                  tooltipButton={labels.ToolTipShortlistCollaspeResumeDetail}
                  tooltipPlace="left"
                  isHelpActive={isHelpActive}
                  tooltipHelp={labels.HelpTooltipShortlistResumeDetailCollapse}
                />
              </Column.Head>
              <Column.Body>
                {fetchingResumes ? (
                  <LoadingMaskRow />
                ) : (
                  <ShortlistResumeDetail
                    showCorrelation
                    resume={selectedResume}
                    searchedDate={this.props.availibility}
                  />
                )}
              </Column.Body>
            </Column>
          )}
      </PageWrapper>
    );
  }
}
Search.defaultProps = {
  isSaveSearch: false,
};
const mapStateToProps = (
  { systemLabel, navigation, search, savedSearch },
  { isSaveSearch }
) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  const searchProps = isSaveSearch ? savedSearch : search;
  return { labels, isHelpActive, ...searchProps };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(withRouter(Search));
