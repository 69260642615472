import { ActionTypes } from "../../actions";
export const onStateChangeAction = ({
  userPhoneList,
  userEmailList,
  selectedProfile,
  countries,
  socialMedia,
  UserSocialMediaLinks,
  dialogMessage,
  dialogMessagePass,
  isInvalidUserFirstname,
  isInvalidUserLastname,
  isInvalidPhone,
  isInvalidEmail,
  isUserNameSelected,
  isPhoneNumberSelected,
  addItemMessage,
  isEmailSelected,
  isDeleteEmail,
  isDeletePhoneNumber,
  emailIdToDelete,
  phoneIdToDelete,
  isChangePassSelected,
  isEmailNotificationSelected,
  rightcoloumHeading,
  isLoading,
  isFetching,
  passwordEmailsList,
  settingsList,
  expanded,
  listCollapsed,
  formCollapsed,
  invalidOldPassword,
  selectedItem,
  oldPassword,
  newPassword,
  confirmPassword,
  UserId,
  UserNotificationId,
  notificationList,
  isFetchNotification,
  isSocialMediaSelected,
}) => (dispatch) => {
  userPhoneList !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.USER_PHONE_LIST,
      payload: userPhoneList,
    });
  socialMedia !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.SOCIAL_MEDIA,
      payload: socialMedia,
    });
  UserSocialMediaLinks !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.USER_SOCIAL_MEDIA,
      payload: socialMedia,
    });
  userEmailList !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.USER_EMAIL_LIST,
      payload: userEmailList,
    });
  selectedProfile !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.SELECTED_PROFILE,
      payload: selectedProfile,
    });
  countries !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.COUNTRIES,
      payload: countries,
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.DIALOG_MESSAGE,
      payload: dialogMessage,
    });
  dialogMessagePass !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.DIALOG_MESSAGE_PASS,
      payload: dialogMessagePass,
    });
  isInvalidUserFirstname !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_INVALIDUSER_FIRST_NAME,
      payload: isInvalidUserFirstname,
    });
  isInvalidUserLastname !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_INVALIDUSER_LAST_NAME,
      payload: isInvalidUserLastname,
    });
  isInvalidPhone !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_INVALID_PHONE,
      payload: isInvalidPhone,
    });
  isInvalidEmail !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_INVALID_EMAIL,
      payload: isInvalidEmail,
    });
  isUserNameSelected !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_USER_NAME_SELECTED,
      payload: isUserNameSelected,
    });
  isPhoneNumberSelected !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_PHONE_NUMBER_SELECTED,
      payload: isPhoneNumberSelected,
    });
  addItemMessage !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.ADD_ITEM_MESSAGE,
      payload: addItemMessage,
    });
  isEmailSelected !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_EMAIL_SELECTED,
      payload: isEmailSelected,
    });
  isSocialMediaSelected !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_SOCIALMEDIA_SELECTED,
      payload: isSocialMediaSelected,
    });
  isDeleteEmail !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_DELETE_EMAIL,
      payload: isDeleteEmail,
    });
  isDeletePhoneNumber !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_DELETE_PHONE_NUMBER,
      payload: isDeletePhoneNumber,
    });
  emailIdToDelete !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.EMAILID_TO_DELETE,
      payload: emailIdToDelete,
    });
  phoneIdToDelete !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.PHONE_ID_TO_DELETE,
      payload: phoneIdToDelete,
    });
  isChangePassSelected !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_CHANGE_PASS_SELECTED,
      payload: isChangePassSelected,
    });
  isEmailNotificationSelected !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_EMAIL_NOTIFICATION_SELECTED,
      payload: isEmailNotificationSelected,
    });
  rightcoloumHeading !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.RIGHT_COLOUM_HEADING,
      payload: rightcoloumHeading,
    });
  isLoading !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_LOADING,
      payload: isLoading,
    });
  isFetching !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_FETCHING,
      payload: isFetching,
    });
  passwordEmailsList !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.PASSWORD_EMAILS_LIST,
      payload: passwordEmailsList,
    });
  settingsList !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.SETTINGS_LIST,
      payload: settingsList,
    });
  expanded !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.EXPANDED,
      payload: expanded,
    });
  listCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.LIST_COLLAPSED,
      payload: listCollapsed,
    });
  formCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.FORM_COLLAPSED,
      payload: formCollapsed,
    });
  invalidOldPassword !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.INVALID_OLD_PASSWORD,
      payload: invalidOldPassword,
    });
  selectedItem !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.SELECTED_ITEM,
      payload: selectedItem,
    });
  oldPassword !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.OLD_PASSWORD,
      payload: oldPassword,
    });
  newPassword !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.NEW_PASSWORD,
      payload: newPassword,
    });
  confirmPassword !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.CONFIRM_PASSWORD,
      payload: confirmPassword,
    });
  UserId !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.USER_ID,
      payload: UserId,
    });
  UserNotificationId !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.USER_NOTIFICATION_ID,
      payload: UserNotificationId,
    });
  notificationList !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.NOTIFICATION_LIST,
      payload: notificationList,
    });
  isFetchNotification !== undefined &&
    dispatch({
      type: ActionTypes.SETTING.IS_FETCH_NOTIFICATION,
      payload: isFetchNotification,
    });
};
