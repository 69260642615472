import React, { Component } from "react";
import { connect } from "react-redux";
import "./presentationdetail.scss";
import Select from "react-select";
import { LoadingMask, Button } from "../../../../../common";
import { notificationAction } from "./../../../../../actions";
import ImgSrc from "../../../../../assets/images/company.svg";

import {
  getAllPresenationApi,
  acceptOpportunityApi,
} from "./../opportunityApi";

class PresentationDetails extends Component {
  state = {
    selectedOption: null,
    allpresentation: [],
    selectedPresentation: {},
    selectedUserDetail: null,
    isLoading: false,
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getAllPresentation();
  }

  getAllPresentation = () => {
    getAllPresenationApi().then((data) => {
      if (data.success) {
        const allpresentation = data.items.map((item) => ({
          ...item,
          Logo: item.Logo,
          value: item.UserDetailId,
          label: item.Title,
        }));

        this.setState({
          allpresentation,
          selectedPresentation: allpresentation[0],
        });
        this.setState({ isLoading: false });
      }
    });
  };
  renderSocialMedia = (links) => {
    var slinks = this.state.selectedPresentation.SocialLinks;
    var list = [];
    for (var i = 0; i < slinks.length; i++) {
      var name = this.props.socialMedia.find(
        (e) => e.Id === slinks[i].SocialMediaId
      );
      list.push(
        <label
          className={`social-links ${name.Name.toLowerCase()}`}
          key={slinks[i].SocialMediaId}
        >
          <a href={slinks.URL} target="_blank" key={slinks[i].SocialMediaId}>
            {name.Name}
          </a>
          <br />
        </label>
      );
    }
    return list;
  };

  handPresentSelectChange = (selectedOption) => {
    this.setState({ selectedOption, selectedPresentation: selectedOption });
  };

  handleSubmitPresentDetail = (data) => {
    this.setState({ userDetail: data });
    const { selectedOpportunityNew, userDetail } = this.state;
    const obj = new Object();
    obj.RequestId = this.props.selectedOpportunityNew.RequestId;
    obj.UserDetailId = this.state.selectedPresentation.UserDetailId;
    //obj.CoverLetter = this.state.selectedPresentation.Detail;
    obj.ResumeId = 0;
    //obj.RequestName = this.props.selectedOpportunityNew.RequestName;

    this.acceptOpportunity(obj);
  };

  acceptOpportunity = (info) => {
    acceptOpportunityApi(info).then((data) => {
      if (data.success) {
        const info = {
          message: this.props.labels.iproOppNewAcceptMessage,
          status: "success",
        };
        this.props.notificationAction(info);
        this.props.getAllOpportunity();
      }
    });
  };

  render() {
    const {
      labels,
      selectedOpportunityNew,
      showCompanyDetails,
      showPresentationDetail,
      isLoading,
      socialMedia,
      getAllOpportunity,
    } = this.props;
    const {
      selectedOption,
      allpresentation,
      selectedPresentation,
    } = this.state;
    return (
      <div className="present-detail-component-with-opportunity">
        {isLoading && <LoadingMask />}
        <Select
          name="AllPresentation"
          className="select-input"
          value={selectedPresentation}
          // className={`select-input ${invalidPresent ? "inValid" : ""}`}
          // value={(Profiles && Profiles.ProfileValue) || ""}
          placeholder={labels.presentPrimaryRolePlaceholder}
          onChange={(selectedOption) =>
            this.handPresentSelectChange(selectedOption)
          }
          options={allpresentation}
          clearable={false}
        />
        <div className="MsgWrapper">
          <div className="MsgSubject">
            <div className="company-image-wrap">
              {selectedPresentation && selectedPresentation.Logo && (
                <img
                  src={selectedPresentation.Logo || ImgSrc}
                  className={
                    selectedPresentation.Logo ? "roundImg" : "emptyRoundImag"
                  }
                />
              )}
            </div>
            <label className="company-name">{selectedPresentation.Title}</label>
          </div>
          <div className="msgItemDiv">
            {selectedPresentation.UserName && (
              <div className="msgItem">
                <label className="labelName">
                  {this.props.labels.IproOpportunityNewUserNameLabel}
                </label>
                <label className="answerLabel">
                  {selectedPresentation.UserName}
                </label>
              </div>
            )}
            {selectedPresentation.Profiles && (
              <div className="msgItem">
                <label className="labelName">
                  {this.props.labels.IproOpportunityNewUserPositionLabel}
                </label>
                <label className="answerLabel">
                  {selectedPresentation.Profiles &&
                    selectedPresentation.Profiles.ProfileValue}
                </label>
              </div>
            )}
            {selectedPresentation.UserEmails && (
              <div className="msgItem">
                <label className="labelName">
                  {this.props.labels.IproOpportunityNewUserEmailLabel}
                </label>
                <label className="answerLabel">
                  {selectedPresentation.UserEmails &&
                    selectedPresentation.UserEmails.UserEmailValue}
                </label>
              </div>
            )}
            {selectedPresentation.UserPhonenumbers && (
              <div className="msgItem">
                <label className="labelName">
                  {this.props.labels.IproOpportunityNewUserPhoneLabel}
                </label>
                <label className="answerLabel">
                  {selectedPresentation.UserPhonenumbers &&
                    selectedPresentation.UserPhonenumbers.UserPhonenumberValue}
                </label>
              </div>
            )}
            {selectedPresentation.WebUrl && (
              <div className="msgItem">
                <label className="labelName">
                  {this.props.labels.IproOpportunityNewUserWebURL}
                </label>
                <label className="answerLabel">
                  <a target="_blank" href={selectedPresentation.WebUrl}>
                    {selectedPresentation.WebUrl}
                  </a>
                </label>
              </div>
            )}
            {selectedPresentation.Detail && (
              <div className="MsgDetailWrap">{selectedPresentation.Detail}</div>
            )}
            {selectedPresentation.SocialLinks && (
              <div className="MsgDetailWrap">
                {this.renderSocialMedia(selectedPresentation.SocialLinks)}
              </div>
            )}

            <Button
              className="SendButton"
              text={"Send"}
              onClick={this.handleSubmitPresentDetail}
              tooltipButton={"Send"}
              tooltipHelp={"Send"}
              tooltipPlace="left"
              testId="presentform-iproopportunity-submit"
            />
            
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(PresentationDetails);
