import React, { Component } from "react";
import { Panel, ListGroup, Input, Select } from "../../../../common";
import AsyncSelect from "react-select/lib/Async";
import { map, isEmpty } from "lodash";
import { getKeywordApi } from "../resumeEditApi";

class Keyword extends Component {
  handleKeywordsLoad = (searchKey, callback) => {
    if (isEmpty(searchKey)) {
      const { keywordDefaultOptions } = this.props;
      callback(null, {
        options: map(keywordDefaultOptions, (Keyword) => {
          return {
            ...Keyword,
            label: Keyword.KeywordValue,
            value: Keyword.KeywordId,
          };
        }),
      });
      return;
    }
    if (searchKey.length < 3) {
      callback([]);
      return;
    }
    getKeywordApi({ searchKey }).then((response) => {
      if (response.success) {
        callback(null, {
          options: map(response.items, (Keyword) => {
            return {
              ...Keyword,
              label: Keyword.KeywordValue,
              value: Keyword.KeywordId,
            };
          }),
        });
      }
    });
  };
  render() {
    const {
      isHelpActive,
      labels,
      keywordsList,
      onKeywordNameChange,
      onKeywordDelete,
      onKeywordAdd,
      onKeywordBlur,
    } = this.props;
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>{labels.RESUME_EDIT_KEYWORD_SECTION_TITLE}</Panel.Title>
          <Panel.Add
            onClick={onKeywordAdd}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={labels.RESUME_EDIT_KEYWORD_ADD_BUTTON_TOOLTIP}
            tooltipHelp={labels.RESUME_EDIT_KEYWORD_ADD_BUTTON_HELP}
            testId="resume-edit-keyword-add-btn"
            testIdHelp="resume-edit-keyword-add-help-btn"
          />
        </Panel.Heading>
        <Panel.Body>
          <ListGroup>
            {keywordsList &&
              keywordsList.map((item) => (
                <ListGroup.Item key={item.uniqueId}>
                  <ListGroup.Column>
                    <AsyncSelect
                      className={`select-keyword-name select-input ${
                        item.isInvalid ? "inValid" : ""
                      }`}
                      placeholder={labels.RESUME_EDIT_KEYWORD_NAME_PLACEHOLDER}
                      cacheOptions
                      defaultOptions
                      searchable={true}
                      onBlurResetsInput={false}
                      onCloseResetsInput={false}
                      loadOptions={this.handleKeywordsLoad}
                      onChange={(options) =>
                        onKeywordNameChange(options, item.uniqueId)
                      }
                      value={{
                        value: item.KeywordId,
                        label: item.KeywordValue,
                      }}
                      onBlur={(options) =>
                        onKeywordBlur(
                          item.KeywordId,
                          item.uniqueId,
                          options,
                          item.ExperienceLevel
                        )
                      }
                      disabled={item.isCandidate}
                    />
                  </ListGroup.Column>
                  <ListGroup.Column size={0}>
                    <div
                      // disabled={isDisabled}
                      onClick={() => onKeywordDelete(item)}
                      className="close_btn_purple"
                    >
                      <i className="fa fa-times-circle" />
                    </div>
                  </ListGroup.Column>
                </ListGroup.Item>
              ))}
          </ListGroup>
        </Panel.Body>
      </Panel>
    );
  }
}

export default Keyword;
