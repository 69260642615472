import { ActionTypes } from "../../../actions";

export const onStateChangeAction = ({
  isFetching,
  isLoading,
  opportunities,
  filterOpportunires,
  formCollapsed,
  shortlistCollapsed,
  resumeListCollapsed,
  resumeDetailCollapsed,
  selectedResume,
  isSearchFocus,
  searchListHover,
  selectedShortlist,
  selectedShortlists,
  filterShortlists,
  shortlists,
  dialogMessage,
  yesClickCount,
  savedSearches,
  userCompanies,
  selectedSaveSearch,
  selectedCompany,
  languages,
  isFetchingShortlist,
  isFetchingShortlistResume,
  opportunitySaveLoading,
  allLocationsList,
  allLanguagesList,
  searchKey,
  selectedOpportunity,
  allCurrenciesList,
  shortlistResumes
}) => dispatch => {
  isFetching !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.IS_FETCHING,
      payload: isFetching
    });
  isLoading !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.IS_LOADING,
      payload: isLoading
    });
  opportunities !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.OPPORTUNITIES,
      payload: opportunities
    });
  filterOpportunires !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.FILTER_OPPORTUNIRES,
      payload: filterOpportunires
    });
  formCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.FORM_COLLAPSED,
      payload: formCollapsed
    });
  shortlistCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SHORTLIST_COLLAPSED,
      payload: shortlistCollapsed
    });
  resumeListCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.RESUME_LIST_COLLAPSED,
      payload: resumeListCollapsed
    });
  resumeDetailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.RESUME_DETAIL_COLLAPSED,
      payload: resumeDetailCollapsed
    });
  selectedResume !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_RESUME,
      payload: selectedResume
    });
  isSearchFocus !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.IS_SEARCH_FOCUS,
      payload: isSearchFocus
    });
  searchListHover !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SEARCH_LIST_HOVER,
      payload: searchListHover
    });
  selectedShortlist !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_SHORTLIST,
      payload: selectedShortlist
    });
  selectedShortlists !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_SHORTLISTS,
      payload: selectedShortlists
    });
  filterShortlists !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.FILTER_SHORTLISTS,
      payload: filterShortlists
    });
  shortlists !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SHORTLISTS,
      payload: shortlists
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.DIALOG_MESSAGE,
      payload: dialogMessage
    });
  yesClickCount !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.YES_CLICK_COUNT,
      payload: yesClickCount
    });
  savedSearches !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SAVED_SEARCHES,
      payload: savedSearches
    });
  userCompanies !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.USER_COMPANIES,
      payload: userCompanies
    });
  selectedSaveSearch !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_SAVE_SEARCH,
      payload: selectedSaveSearch
    });
  selectedCompany !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_COMPANY,
      payload: selectedCompany
    });
  languages !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.LANGUAGES,
      payload: languages
    });
  isFetchingShortlist !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.IS_FETCHING_SHORTLIST,
      payload: isFetchingShortlist
    });
  isFetchingShortlistResume !== undefined &&
    dispatch({
      type:
        ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER
          .IS_FETCHING_SHORTLIST_RESUME,
      payload: isFetchingShortlistResume
    });
  opportunitySaveLoading !== undefined &&
    dispatch({
      type:
        ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.OPPORTUNITY_SAVE_LOADING,
      payload: opportunitySaveLoading
    });
  allLocationsList !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.ALL_LOCATIONS_LIST,
      payload: allLocationsList
    });
  allLanguagesList !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.ALL_LANGUAGES_LIST,
      payload: allLanguagesList
    });
  searchKey !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SEARCH_KEY,
      payload: searchKey
    });
  selectedOpportunity !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SELECTED_OPPORTUNITY,
      payload: selectedOpportunity
    });
  allCurrenciesList !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.ALL_CURRENCIES_LIST,
      payload: allCurrenciesList
  });
  shortlistResumes !== undefined &&
    dispatch({
      type: ActionTypes.CREATE_NEW_OPPORTUNITY_SEARCHER.SHORTLIST_RESUMES,
      payload: shortlistResumes
  });
};
