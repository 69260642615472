import React, { Component } from "react";
import { map, isEmpty } from "lodash";
import Select from "react-select";
import moment from "moment";
import Slider from "rc-slider";
import "./searcherOpportunityForm.scss";
import {
  LoadingMask,
  CapsuleList,
  DatePicker,
  Button,
} from "../../../../common";
import { CloseBtn } from "../../../../common/CloseBtn";
const Handle = Slider.Handle;
class SearcherOpportunityForm extends Component {
  constructor(props) {
    super(props);

    this.durationTypeList = [
      { value: "Days", label: "Days" },
      { value: "Weeks", label: "Weeks" },
      { value: "Months", label: "Months" },
      { value: "Years", label: "Years" },
    ];
  }
  handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Handle value={value} key={index} {...restProps}>
        {value}
      </Handle>
    );
  };
  render() {
    const {
      selectedOpportunity,
      selectedSaveSearch,
      selectedCompany,
      userCompanies,
      savedSearches,
      isLoading,
      onFormFieldChange,
      onFormSelectChange,
      onOpportunityUpdate,
      onDateChange,
      onSliderChange,
      onLanguageDelete,
      onCountryDelete,
      onLanguageSelect,
      onLocationSelect,
      onOpportunitySend,
      allLocations,
      allLanguages,
      titlePlaceholder,
      startDateLabel,
      durationLabel,
      hourlyFeeLabel,
      companyLabel,
      locationLabel,
      languageLabel,
      savedSearchLabel,
      savedSearchPlaceholder,
      descriptionPlaceholder,
      durationPlaceholder,
      locationPlaceholder,
      languagePlaceholder,
      saveButtonText,
      sendButtonText,
      hourlyFeePlaceholder,
      hourlyFeeDropDownPlaceholder,
      companyPlaceholder,
      allCurrencies,
    } = this.props;
    const {
      invalidRequestName,
      invalidSelectedCompany,
      invalidSelectedSavedSearch,
      invalidFeeCurrencyType,
      invalidStartDate,
      invalidDurationType,
      Countries,
      Description,
      Duration,
      DurationType,
      FeeCurrencyType,
      HourlyFee,
      Language,
      RequestName,
      StartDate,
    } = selectedOpportunity;

    return (
      <div
        className="searcher-opportunity-form"
        test-data-id="searcher-opportunity-form"
      >
        {isLoading && <LoadingMask />}
        <div className="form-row">
          <input
            autoFocus={invalidRequestName}
            type="text"
            autoComplete="off"
            name="RequestName"
            className={`input-text ${invalidRequestName ? "inValid" : ""}`}
            value={RequestName ? RequestName : ""}
            placeholder={titlePlaceholder}
            onChange={onFormFieldChange}
            onBlur={onFormFieldChange}
            test-data-id="input-text-opportunity-title"
          />
        </div>
        <div className="form-row" style={{zIndex: '999'}}>
          <label className="form-label">{startDateLabel}:</label>
          <label className="datepicker-wrapper">
            <DatePicker
              autoFocus={invalidStartDate}
              name="StartDate"
              dateFormat="DD-MM-YYYY"
              selected={StartDate && moment(StartDate)}
              onChange={onDateChange}
              todayButton="Today"
              className={`${invalidStartDate ? "inValid" : ""}`}
              placeholderText={"DD-MM-YYYY"}
              minDate={moment()}
              autoComplete="off"
            />
          </label>
        </div>
        <div className="form-row">
          <label className="form-label">{durationLabel}:</label>
          <Slider
            name="durationSlider"
            min={0}
            max={12}
            defaultValue={4}
            value={parseInt(Duration)}
            handle={this.handle}
            onAfterChange={onSliderChange}
            onChange={onSliderChange}
          />
          <Select
            autoFocus={invalidDurationType}
            name="DurationType"
            className={`select-input select-durationType ${
              invalidDurationType ? "inValid" : ""
            }`}
            placeholder={durationPlaceholder}
            value={DurationType.value}
            onChange={(selectedOption) =>
              onFormSelectChange("DurationType", selectedOption)
            }
            options={this.durationTypeList}
            clearable={false}
            searchable={false}
          />
        </div>
        <div className="form-row">
          <label className="form-label">{hourlyFeeLabel}:</label>
          <input
            type="text"
            autoComplete="off"
            name="HourlyFee"
            className={`input-text hourly-fee`}
            placeholder={hourlyFeePlaceholder}
            value={HourlyFee ? HourlyFee : ""}
            onChange={onFormFieldChange}
            onBlur={onFormFieldChange}
            test-data-id="input-txt-opportunity-hourlyRate"
          />
          <Select
            name="FeeCurrencyType"
            className={`select-input select-hourlyRate ${
              invalidFeeCurrencyType ? "inValid" : ""
            }`}
            placeholder={hourlyFeeDropDownPlaceholder}
            value={FeeCurrencyType?.value || ""}
            onChange={(selectedOption) =>
              onFormSelectChange("FeeCurrencyType", selectedOption)
            }
            clearable={false}
            searchable={false}
            options={allCurrencies}
          />
        </div>
        <div className="form-row">
          <label className="form-label">{companyLabel}:</label>
          <Select
            name="SelectedCompany"
            className={`select-input select-company ${
              invalidSelectedCompany ? "inValid" : ""
            }`}
            placeholder={companyPlaceholder}
            value={selectedCompany && selectedCompany}
            onChange={(selectedOption) =>
              onFormSelectChange("SelectedCompany", selectedOption)
            }
            autoBlur={true}
            options={userCompanies}
            clearable={false}
            searchable={false}
          />
        </div>
        <div className="form-row">
          <label className="form-label">{locationLabel}:</label>
          <Select
            name="LOCATION"
            placeholder={locationPlaceholder}
            className="select-input select-locations"
            onChange={onLocationSelect}
            options={allLocations}
            clearable={true}
            searchable={true}
          />
        </div>
        {Countries.length > 0 && (
          <div className="form-row">
            <CapsuleList className="selected-locations-list">
              {map(Countries, (country) => (
                <CapsuleList.Capsule
                  testId="selected-location-item"
                  key={country.CountryId}
                >
                  <CapsuleList.Label>{country.CountryName}</CapsuleList.Label>
                  <CloseBtn
                    classes="close_btn_purple for-capsule"
                    testId="selected-location-item-closeBtn"
                    onClick={() =>
                      onCountryDelete({ selectedCountry: country })
                    }
                  />
                </CapsuleList.Capsule>
              ))}
            </CapsuleList>
          </div>
        )}
        <div className="form-row">
          <label className="form-label">{languageLabel}:</label>
          <Select
            name="Language"
            className="select-input select-languages"
            placeholder={languagePlaceholder}
            onChange={onLanguageSelect}
            options={allLanguages}
            clearable={true}
            searchable={true}
          />
        </div>
        {Language.length > 0 && (
          <div className="form-row">
            <CapsuleList className="selected-languages-list">
              {map(Language, (language) => (
                <CapsuleList.Capsule
                  testId="selected-language-item"
                  key={language.LanguageId}
                >
                  <CapsuleList.Label>
                    {" "}
                    {language.LanguageValue}
                  </CapsuleList.Label>
                  <CloseBtn
                    classes="close_btn_purple for-capsule"
                    testId="selected-language-item-closeBtn"
                    onClick={() =>
                      onLanguageDelete({ selectedLanguage: language })
                    }
                  />
                </CapsuleList.Capsule>
              ))}
            </CapsuleList>
          </div>
        )}
        <div className="form-row">
          <label className="form-label">{savedSearchLabel}:</label>
          <Select
            placeholder={savedSearchPlaceholder}
            name="SelectedSavedSearch"
            className={`select-input select-savedsearch ${
              invalidSelectedSavedSearch ? "inValid" : ""
            }`}
            value={selectedSaveSearch && selectedSaveSearch}
            onChange={(selectedOption) =>
              onFormSelectChange("selectedSavedSearch", selectedOption)
            }
            options={savedSearches}
            searchable={false}
            clearable
          />
        </div>
        <textarea
          autoComplete="off"
          name="Description"
          className="textarea-field"
          rows="9"
          placeholder={descriptionPlaceholder}
          value={Description || ""}
          onChange={onFormFieldChange}
          test-data-id="input-text-opportunity-description"
        />
        <div className="form-row action-buttons">
          <Button
            className="SendButton"
            text={saveButtonText}
            onClick={onOpportunityUpdate}
            tooltipButton={saveButtonText}
            tooltipHelp={saveButtonText}
            tooltipPlace="left"
            testId="input-button-opportunity-saveBtn"
          />
          <Button
            className="SendButton"
            text={sendButtonText}
            onClick={onOpportunitySend}
            tooltipButton={sendButtonText}
            tooltipHelp={sendButtonText}
            tooltipPlace="left"
            testId="input-button-opportunity-sendBtn"
          />
        </div>
      </div>
    );
  }
}

export default SearcherOpportunityForm;
