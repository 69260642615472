import React, { Component } from "react";
import {
  Column,
  Button,
  SearchInput,
  BulletList,
  EmptyInfo,
  ConfirmDialog,
  Input,
  LoadingMaskRow,
  List,
} from "../../common";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ShortlistResumes from "./component/ShortlistResumes";
import { notificationAction } from "./../../actions";
import toLower from "lodash/toLower";
import ShortlistResumeDetail from "./component/ShortlistResumeDetail";
import filter from "lodash/filter";
import { connect } from "react-redux";
import find from "lodash/find";
import first from "lodash/first";
import moment from "moment";
import includes from "lodash/includes";
import {
  getShortlistApi,
  getShortlistResumeApi,
  postShortlistApi,
  deleteShortlistApi,
  getOppuntinitiesApi,
  deleteResumeApi,
} from "./shortlistApi";
import { privateRoutes } from "../../Routes/routing";

import "./shortlist.scss";
import { onStateChangeAction } from "./shortlistAction";
import { CloseBtn } from "../../common/CloseBtn";

class Shortlist extends Component {
  state = {};

  componentDidMount() {
    this.getShortlist().then(() => {
      const { history, selectedShortlist } = this.props;
      if (history) {
        const {
          location: { state },
        } = history;
        if (state) {
          const { shortlist } = state;
          this.handleSelectShortlist(shortlist);
        }
        const { onBoardShortlistId } = this.props;
        if (onBoardShortlistId) {
          this.getShortlist();
        }
      }
    });
  }

  getOpportunities = () => {
    this.props.onStateChangeAction({ fetchingOpportunities: true });
    getOppuntinitiesApi()
      .then((data) => {
        this.props.onStateChangeAction({
          opportunityList: data.items,
          fetchingOpportunities: false,
        });
        if (data.items.length > 0) {
          this.props.onStateChangeAction({
            showOpportunities: true,
          });
        } else {
          const info = {
            message: this.props.labels.InfoIShortlistNoQuickOpportunity,
            status: "info",
          };
          this.props.notificationAction(info);
        }
      })
      .then((err) => {
        this.props.onStateChangeAction({
          fetchingOpportunities: false,
        });
      });
  };
  getShortlist = () => {
    this.props.onStateChangeAction({ fetchingShortlist: true });
    return getShortlistApi()
      .then((data) => {
        this.props.onStateChangeAction({
          shortlist: data.items,
          filteredShortlist: data.items,
          fetchingShortlist: false,
        });
        const { onBoardShortlistId } = this.props;
        if (onBoardShortlistId) {
          this.props.onStateChangeAction({
            shortlist: data.items,
            filteredShortlist: data.items.filter(
              (a) => a.ShortlistId == onBoardShortlistId
            ),
          });
          this.handleSelectShortlist(
            data.items.find((a) => a.ShortlistId == onBoardShortlistId)
          );
        }
      })
      .catch(() => {
        this.props.onStateChangeAction({
          fetchingShortlist: false,
        });
      });
  };

  handleSelectShortlist = (item) => {
    this.props.onStateChangeAction({
      selectedShortlist: item,
      createNewShortlist: false,
      fetchingResumes: true,
      // shortlistResumes: [],
      showOpportunities: false,
    });
    sessionStorage.setItem("shortList", JSON.stringify(item));
    getShortlistResumeApi(item.ShortlistId)
      .then((data) => {
        this.props.onStateChangeAction({
          shortlistResumes: data.items.map((item, index) => {
            return {
              ...item,
              active: true,
            };
          }),
          fetchingResumes: false,
          selectedResume: first(data.items) || {},
        });
      })
      .catch((err) => {
        this.props.onStateChangeAction({ fetchingResumes: false });
      });
  };

  handleResumeSelect = (item) => {
    const { shortlistResumes } = this.props;
    const selectedResume = find(shortlistResumes, { ResumeId: item.ResumeId });
    this.props.onStateChangeAction({ selectedResume });
  };

  handleResumeDelete = (item) => {
    this.props.onStateChangeAction({ isLoading: true });
    deleteResumeApi(item.ResumeId, this.props.selectedShortlist.ShortlistId)
      .then((data) => {
        if (data.success) {
          this.props.onStateChangeAction({
            deleteResumeApi: false,
            isLoading: false,
            shortlistResumes: this.props.shortlistResumes.filter(
              (resume) => resume.ResumeId !== item.ResumeId
            ),
          });
          const info = {
            message: data.message,
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: this.props.labels.shortlistAlreadyExist,
            status: "info",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            isLoading: false,
            newShortlistValue: null,
          });
        }
      })
      .catch((err) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { shortlist } = this.props;

    const filteredShortlist = filter(shortlist, (opp) =>
      includes(toLower(opp.ShortlistName), toLower(value))
    );
    this.props.onStateChangeAction({ filteredShortlist, searchKey: value });
  };
  handleShortlistActive = (activeItem) => {
    const shortlistResumes = this.props.shortlistResumes.map((item) => ({
      ...item,
      active:
        activeItem.ResumeId === item.ResumeId ? !item.active : item.active,
    }));
    this.props.onStateChangeAction({
      shortlistResumes,
    });
  };
  handleCreateNewShorlistClick = () => {
    const { newShortlistValue } = this.props;
    this.props.onStateChangeAction({ isLoading: true });
    postShortlistApi({ ShortlistName: newShortlistValue })
      .then((data) => {
        if (data.success) {
          this.getShortlist();
          this.props.onStateChangeAction({
            createNewShortlist: false,
            isLoading: false,
          });
          const info = {
            message: this.props.labels.shortlistSuccessAdded,
            status: "success",
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            message: this.props.labels.shortlistAlreadyExist,
            status: "info",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            isLoading: false,
            newShortlistValue: null,
          });
        }
      })
      .catch((err) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  handleNewShortList = () => {
    this.props.onStateChangeAction({
      createNewShortlist: true,
      newShortlistValue: "",
      selectedResume: {},
      selectedShortlist: {},
    });
  };
  handleDeleteShortlist = (id) => {
    this.props.onStateChangeAction({
      deletedId: id,
      dialogMessage: this.props.labels.shortlistDeletionConfirmation,
    });
  };
  handleYesClick = () => {
    const { deletedId } = this.props;
    this.props.onStateChangeAction({ dialogMessage: "" });
    this.props.onStateChangeAction({ isLoading: true });
    deleteShortlistApi(deletedId)
      .then((data) => {
        if (data.success) {
          this.getShortlist();
          const info = {
            message: this.props.labels.shortlistSuccessfullDeletion,
            status: "success",
          };
          this.props.notificationAction(info);
          this.props.onStateChangeAction({
            selectedShortlist: {},
            selectedResume: {},
            deletedId: null,
          });
        } else {
          const info = {
            message: data.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  handleCreateQuickOpportunity = () => {
    const { shortlistResumes } = this.props;
    const { labels } = this.props;
    this.createNewShortlist();
    const activeResume = find(shortlistResumes, "active");
    if (!activeResume) {
      this.getShortlist();
      const info = {
        message: labels.InfoIShortlistResumeListSelectionError,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({
      dialogOpportunityMessage: labels.InfoIShortlistResumeListQuickOpportunity,
    });
  };
  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "", deletedId: null });
  };
  handleExistingClick = () => {
    this.props.onStateChangeAction({ dialogOpportunityMessage: "" });
    this.getOpportunities();
  };
  handleCreateNewClick = () => {
    sessionStorage.setItem("opportunity-new", true);
    this.props.history.push(privateRoutes.searcherCreateOpportunity.path);
  };
  createNewShortlist = () => {
    const { shortlistResumes } = this.props;
    const selectedResumesIds = shortlistResumes
      .filter((item) => item.active)
      .map((item) => item.ResumeId);
    this.props.onStateChangeAction({ isLoading: true });
    const info = {
      ShortlistName: `New shortlist for ${
        selectedResumesIds.length
      } resume(s) at ${moment(new Date()).format("D MMMM YYYY")} at ${moment(
        new Date()
      ).format("LTS")}`,
      ResumeIdsList: selectedResumesIds,
    };
    return postShortlistApi(info)
      .then((data) => {
        this.props.onStateChangeAction({ isLoading: false });
        return data;
      })
      .catch((err) => {
        this.props.onStateChangeAction({ isLoading: false });
      });
  };
  handleCancelClick = () => {
    this.props.onStateChangeAction({ dialogOpportunityMessage: "" });
  };
  handleSelectOpportunity = (item) => {
    sessionStorage.setItem("opportunity", JSON.stringify(item));
    this.props.history.push(privateRoutes.searcherCreateOpportunity.path);
  };

  render() {
    const {
      searchCollapsed,
      resumeCollapsed,
      resumeDetailCollapsed,
      selectedShortlist,
      selectedResume,
      shortlistResumes,
      dialogMessage,
      filteredShortlist,
      newShortlistValue,
      createNewShortlist,
      isLoading,
      createCollapse,
      fetchingShortlist,
      fetchingResumes,
      dialogOpportunityMessage,
      opportunityList,
      showOpportunities,
      fetchingOpportunities,
      opportunitiesCollapse,
      searchKey,
    } = this.props;
    const { labels, isHelpActive } = this.props;
    return (
      <PageWrapper className="short-list-page">
        {dialogMessage && (
          <ConfirmDialog testId="confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleYesClick}
                testId="delete-yes"
              >
                {labels.shortlistDeletionCurtainYESBtnText}
              </ConfirmDialog.Button>

              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              >
                {labels.shortlistDeletionCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {dialogOpportunityMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>
              {dialogOpportunityMessage}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleExistingClick}
              >
                {labels.ShortlistResumeListCurtainExistingBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleCreateNewClick}
              >
                {labels.ShortlistResumeListCurtainCreateNewBtnText}
              </ConfirmDialog.Button>

              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleCancelClick}
              >
                {labels.ShortlistResumeListCurtainCancelBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {!this.props.onBoardShortlistId && (
          <Column collapse={searchCollapsed} className="col-1">
            <Column.Collapsed
              onClick={() =>
                this.props.onStateChangeAction({ searchCollapsed: false })
              }
              tooltipPlace="left"
              text={labels.ViewTitleOpportunityShortlistSection}
              tooltipButton={labels.ToolTipShortlistExpandList}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpToolTipShortlistExpand}
            />
            <Column.Head>
              <SearchInput
                testId="search-input"
                value={searchKey}
                placeholder={labels.ShortlistSearchPlacehoder}
                onChange={this.handleSearchChange}
              />
              <Button
                className="AddBtn"
                onClick={this.handleNewShortList}
                tooltipPlace="left"
                testId="create-shortlist-btn"
                tooltipButton={labels.ToolTipShortlistAddBtn}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HlpShortlistAddBtn}
              />
              <Button
                className="collapseBtn"
                tooltipPlace="left"
                tooltipButton={labels.ToolTipShortlistCollapse}
                onClick={() =>
                  this.props.onStateChangeAction({ searchCollapsed: true })
                }
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistCollapse}
              />
            </Column.Head>
            <Column.Body>
              {filteredShortlist?.length > 0 ? (
                <BulletList
                  isFetching={fetchingShortlist}
                  testId="shortlist-list"
                >
                  {filteredShortlist.map((item) => (
                    <BulletList.ListItem
                      onClick={() => this.handleSelectShortlist(item)}
                      key={item.ShortlistId}
                      isSelected={
                        item.ShortlistId === selectedShortlist.ShortlistId
                      }
                    >
                      <label className="listLabel">{item.ShortlistName}</label>
                      <CloseBtn
                        className="close_btn_purple for-list"
                        testId="shortlist-delete-btn"
                        onClick={() =>
                          this.handleDeleteShortlist(item.ShortlistId)
                        }
                      />
                    </BulletList.ListItem>
                  ))}
                </BulletList>
              ) : (
                <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
              )}
            </Column.Body>
          </Column>
        )}
        {createNewShortlist && (
          <Column collapse={createCollapse} className="col-2">
            <Column.Collapsed
              onClick={() =>
                this.props.onStateChangeAction({ createCollapse: false })
              }
              tooltipButton={labels.TooltipShortlistCreateShortlistExpand}
              tooltipPlace="left"
              text={labels.ViewTitleShortlistCreateShortlist}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipShortlistCreateShortlistExpand}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={true}>
                {labels.HeadingShortlistCreateShortlist}
              </Column.HeaderTitle>
              <Button
                className="SaveCloudBtn"
                onClick={this.handleCreateNewShorlistClick}
                tooltipPlace="left"
                testId="button-shortlist-save"
                disabled={isLoading || !newShortlistValue}
                isHelpActive={isHelpActive}
                tooltipButton={labels.TooltipShortlistCreateShortlistSaveButton}
                tooltipHelp={
                  labels.HelpTooltipShortlistCreateShortlistSaveButton
                }
              />
              <Button
                className="collapseBtn"
                tooltipButton={labels.TooltipShortlistCreateShortlistCollapse}
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistCreateShortlistCollapse}
                tooltipPlace="left"
                onClick={() =>
                  this.props.onStateChangeAction({ createCollapse: true })
                }
              />
            </Column.Head>
            <Column.Body className="form-editor">
              <label className="form-label">
                {labels.LabelShortlistCreateShortlistName}
              </label>
              <Input
                autoFocus
                type="text"
                testId="input-text-shortlist-name"
                className="input-text"
                value={newShortlistValue ? newShortlistValue : ""}
                onChange={(e) =>
                  this.props.onStateChangeAction({
                    newShortlistValue: e.target.value,
                  })
                }
              />
            </Column.Body>
          </Column>
        )}

        <Column collapse={resumeCollapsed} className="col-2">
          <Column.Collapsed
            onClick={() =>
              this.props.onStateChangeAction({ resumeCollapsed: false })
            }
            tooltipButton={labels.TooltipShortlistResumeListExpand}
            tooltipPlace="left"
            text={labels.ViewTitleShortlistResult}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.HelpTooltipShortlistResumeListExpand}
          />
          <Column.Head>
            <Column.HeaderTitle isActive={selectedShortlist}>
              {fetchingResumes || !selectedShortlist.ShortlistId
                ? ""
                : shortlistResumes.length > 0
                ? shortlistResumes.length +
                  " " +
                  labels.HeadingShorlistResumeListResult
                : labels.HeadingShorlistResumeListNoResume}
            </Column.HeaderTitle>
            <Button
              onClick={this.handleCreateQuickOpportunity}
              className="create-opportunity"
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              tooltipHelp={
                labels.HelpTooltipShortlistResumeListQuickOpportunity
              }
              tooltipButton={labels.ToolTipShortlistQuickOpportunity}
            />
            <Button
              className="collapseBtn"
              tooltipButton={labels.TooltipShortlistResumeListCollapse}
              onClick={() =>
                this.props.onStateChangeAction({ resumeCollapsed: true })
              }
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipShortlistResumeListCollapse}
            />
          </Column.Head>
          <Column.Body className="flex">
            {selectedShortlist.ShortlistId ? (
              <ShortlistResumes
                fetchingResumes={fetchingResumes}
                handleSelectShortlist={this.handleSelectShortlist}
                selectedShortlist={selectedShortlist}
                onResumeSelect={this.handleResumeSelect}
                onResumeDelete={this.handleResumeDelete}
                onShortlistActive={this.handleShortlistActive}
                selectedResume={selectedResume}
                shortlistResumes={shortlistResumes}
              />
            ) : (
              <EmptyInfo>{labels.Company_Shortlist}</EmptyInfo>
            )}
          </Column.Body>
        </Column>
        {showOpportunities && (
          <Column collapse={opportunitiesCollapse} className="col-3">
            <Column.Collapsed
              onClick={() =>
                this.props.onStateChangeAction({ opportunitiesCollapse: false })
              }
              tooltipPlace="left"
              text={labels.ViewTitleShortlistOpportunityList}
              isHelpActive={isHelpActive}
              tooltipButton={labels.TooltipShortlistOpportunityListExpand}
              tooltipHelp={labels.HelpTooltipShortlistOpportunityListExpand}
            />
            <Column.Head>
              <Column.HeaderTitle isActive={true}>
                {labels.HeadingShortlistOpportunityList}
              </Column.HeaderTitle>

              <Button
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                onClick={() =>
                  this.props.onStateChangeAction({
                    opportunitiesCollapse: true,
                  })
                }
                tooltipButton={labels.TooltipShortlistOpportunityListCollapse}
                tooltipHelp={labels.HelpTooltipShortlistOpportunityListCollapse}
              />
            </Column.Head>
            <Column.Body>
              <List isFetching={fetchingOpportunities}>
                {opportunityList.map((item) => (
                  <List.ListItem
                    onClick={() => this.handleSelectOpportunity(item)}
                    key={item.RequestId}
                  >
                    {item.RequestName}
                  </List.ListItem>
                ))}
              </List>
            </Column.Body>
          </Column>
        )}
        {!showOpportunities && selectedResume.ResumeId && (
          <Column collapse={resumeDetailCollapsed} className="col-3">
            <Column.Collapsed
              tooltipButton={labels.TooltipShortlistResumeDetailExpand}
              text={labels.ViewTitleShortlistDetail}
              onClick={() =>
                this.props.onStateChangeAction({ resumeDetailCollapsed: false })
              }
              isHelpActive={isHelpActive}
              tooltipHelp={labels.HelpTooltipShortlistResumeDetailExpand}
            />
            <Column.Head>
              <div className="heading">Resume Detail</div>
              <Button
                className="collapseBtn"
                onClick={() =>
                  this.props.onStateChangeAction({
                    resumeDetailCollapsed: true,
                  })
                }
                tooltipButton={labels.ToolTipShortlistCollaspeResumeDetail}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                tooltipHelp={labels.HelpTooltipShortlistResumeDetailCollapse}
              />
            </Column.Head>
            <Column.Body>
              {fetchingResumes ? (
                <LoadingMaskRow />
              ) : (
                <ShortlistResumeDetail resume={selectedResume} />
              )}
            </Column.Body>
          </Column>
        )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, shortlist }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, ...shortlist };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(Shortlist);
