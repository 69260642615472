import React from "react";
import { Button } from "../../../../common";

const FLOWITEM = "FLOWITEM";

export default function FlowItems(props) {
  const { labels } = props;
  return (
    <React.Fragment>
      <div className="tictell-list">
        {props.flowItems &&
          props.flowItems.length > 0 &&
          props.flowItems.map((item) => (
            <div
              key={item.Id}
              test-data-id={`flow-items-list${
                item.isActive ? "activeItem" : ""
              }`}
              className={`tictell-list-item-container  ${
                item.isSelected ? "selected-item" : ""
              } ${item.isActive ? "activeItem" : ""}`}
              onClick={() => props.handleItemClick(FLOWITEM, item)}
            >
              <div className="name-container flow-container">
                <label test-data-id={`collabs-list-owner-username`}>
                  {item.Title}
                </label>
                <label test-data-id={`collabs-list-owner-title`}>
                  {item.Description}
                </label>
              </div>
              <Button
                onClick={() => props.handleFlowItemDelete(item)}
                className="closeBtn"
                tooltipButton={labels.EMBARK_DELETE_FLOW_ITEM}
                tooltipHelp={labels.EMBARK_BTN_DELETE_FLOW_ITEM}
                tooltipPlace="left"
                isHelpActive={props.isHelpActive}
                testId="delete-flow-item-btn"
              />
            </div>
          ))}
      </div>
    </React.Fragment>
  );
}
