import React, { Component } from "react";
import { EmptyInfo } from "../../../../common";
import { connect } from "react-redux";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import "./messagedetail.scss";
import { formatDate, Messages } from "../../../../utilities";
import { Col, Row, Space, Typography } from "antd";
const { Title, Text } = Typography

class MessageDetail extends Component {
  renderDate = (date) => {
    return moment(date).format("M/D/YY");
  };
  renderTime = (date) => {
    return moment
      .utc(date)
      .local()
      .format("hh:mm a");
  };
  render() {
    const {
      labels,
      selectedMessage,
      IsFreelancer,
      handleVisitRequest,
      isReply,
      deleteMessage,
      handleOpen,
    } = this.props;
    return (
      <>
        <div
          className="d-flex justify-content-between gap-8 align-items-center"
          style={{ padding: "16px", borderBottom: "1px solid #f4f2fe" }}
        >
          <Title level={5} className="m-0 text-clamp flex-1">
            Message Details
          </Title>

          {/* {IsSearcher && isSentView && ( */}
          <Title
            level={5}
            style={{ color: "#FF6363", cursor: "pointer" }}
            className="m-0"
            onClick={() => { deleteMessage(selectedMessage) }}
          >
            Delete Message
          </Title>
        </div>
        <div className="collaboration-detail message-detail-inner">
          <div className="workplace-detail">
            <Row gutter={[16, 8]}>

              <Col md={8} xs={24}>
                <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                  {Messages.message.labels.from}:
                </Text>
                <div>
                  <Text className="fnt-s-14 ">
                    {ReactHtmlParser(selectedMessage.SenderName)}
                  </Text>
                </div>
              </Col>
              <Col md={8} xs={12}>
                <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                  {Messages.message.labels.date}:
                </Text>
                <div>
                  <Text className="fnt-s-14 ">
                    {formatDate(selectedMessage.DateCreated)}
                  </Text>
                </div>
              </Col>
              <Col md={8} xs={12}>
                <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                  {Messages.message.labels.time}:
                </Text>
                <div>
                  <Text className="fnt-s-14 ">
                    {this.renderTime(selectedMessage.DateCreated)}
                  </Text>
                </div>
              </Col>
            </Row>
          </div>
          <div className="mt-3">
            <Text className="fnt-s-12" style={{ color: "#00000066" }}>
              Message Title
            </Text>
            <div className="mt-1">
              <Text className="fnt-s-14 ">
                {selectedMessage.Subject}
              </Text>
            </div>
            <div className="mt-3">
              <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                Message
              </Text>
              <div className="mt-1">
                <Text className="fnt-s-14 ">
                  {ReactHtmlParser(selectedMessage.MessageBody)}
                </Text>
              </div>
            </div>

          </div>

        </div>
        <div className="w-100 d-flex justify-content-end gap-16" style={{ padding: "12px", borderTop: "1px solid #f4f2fe" }}>
        <Space size={[8, 8]}>
              {selectedMessage &&
                selectedMessage.RequestId &&
                IsFreelancer && (
                  <button
                    className="button-footer-opportunity pl-4 pr-4"
                    onClick={handleVisitRequest}
                  >
                    Visit
                  </button>
                )}
              {(!selectedMessage || !selectedMessage?.RequestId) && (
                <button
                  className="button-footer-opportunity pl-4 pr-4"
                  onClick={handleOpen}
                >
                  Reply
                </button>
              )}
            </Space>
          </div>
      </>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(MessageDetail);
