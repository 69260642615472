import React from "react";
import Tippy from "@tippy.js/react";
import { htmlParser } from "../../utilities";

export const Tooltip = ({ children, content, ...rest }) => (
  <Tippy
    delay={[500, 0]}
    allowHTML
    arrow
    theme="light"
    content={htmlParser(content)}
    {...rest}
  >
    {children}
  </Tippy>
);

export const HelpTooltip = ({ testId, content, ...rest }) => (
  <Tippy
    allowHTML
    followCursor="initial"
    trigger="click"
    arrow
    content={htmlParser(content)}
    theme="dark"
    {...rest}
  >
    <span
      className="help-icon"
      test-data-id={testId}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    />
  </Tippy>
);

Tooltip.defaultProps = {
  arrow: true
};
