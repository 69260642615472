import React, { Component } from "react";
import { connect } from "react-redux";
import {
  LoadingMask,
  Input,
  Button,
  Panel,
  ListGroup,
  EmptyInfo
} from "../../../common";

class UserEmails extends Component {
  componentDidMount = () => {};

  render() {
    const {
      emails,
      onEmailDelete,
      onSaveUserSetting,
      onEmailChange,
      isFetching,
      labels
    } = this.props;
    return (
      <div
        className={`userSettings ${
          emails.length === 0 ? "center-content" : ""
        }`}
      >
        {isFetching && <LoadingMask />}

        <div className="form-row">
          <ListGroup>
            {emails.map(item => (
              <ListGroup.Item key={item.uniqueId}>
                <ListGroup.Column>
                  <Input
                    type="email"
                    name="email"
                    className={`input-text ${
                      item.isInvalidEmail ? "inValid" : ""
                    }`}
                    value={item.UserEmailValue || ""}
                    placeholder={"Enter email address"}
                    testId="txtsettingsemailaddress"
                    onChange={e => onEmailChange(item.uniqueId, e)}
                    maxlength={"300"}
                  />
                </ListGroup.Column>

                <ListGroup.Column size={0}>
                  <ListGroup.Close onClick={() => onEmailDelete(item)} />
                </ListGroup.Column>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>

        {emails.length > 0 && (
          <Button
            className="SendButton"
            onClick={onSaveUserSetting}
            testId="btnsettingupdate"
            tooltipHelp="Update user settings."
            tooltipButton="Update user settings."
          >
            {"Update"}
          </Button>
        )}
        {emails.length === 0 && (
          <EmptyInfo>
            {labels.USER_SETTING_LIST_PROFILE_EMAIL_EMPTY_INFO}
          </EmptyInfo>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(UserEmails);
