import React, { Component } from "react";
import { connect } from "react-redux";
import Company from "./Company";
import Professional from "./Professional";
import "./how-it-works.scss";
class HowItWorks extends Component {
  state = {
    activeTab: {
      professional: true,
    },
  };
  handleTabChange = (name) => {
    this.setState({
      activeTab: { [name]: true },
    });
  };
  render() {
    const { labels,isCommon } = this.props;
    const { activeTab } = this.state;
    return (
      <div className="how-it-works mainContent lightBg">
        <section className="contentContainer">
          <h1 className="proHeading">{labels.ProfessionalPageTitle}</h1>
          <div className="tabButton">
            <button
              onClick={() => this.handleTabChange("professional")}
              className={`tabBtn ${activeTab.professional && "active"}`}
            >
              {labels.ProfessionalButton}
            </button>
            {isCommon && <button
              onClick={() => this.handleTabChange("company")}
              className={`tabBtn ${activeTab.company && "active"}`}
            >
              {labels.CompanyButton}
            </button>}
          </div>
          {activeTab.company && isCommon && <Company />}
          {activeTab.professional && <Professional />}
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(HowItWorks);
