import React, { Component } from "react";
import {
  Button,
  ConfirmDialog,
  LoadingMask,
  Column,
  DatePicker,
} from "../../../common";
import Grid from "./component/Grid";
import "./resume-edit.scss";
import { PageWrapper } from "../../../components";
import uuidv1 from "uuid/v1";
import moment from "moment";
import { map, filter, isEqual, difference } from "lodash";
import { connect } from "react-redux";
import { notificationAction, resumeChangeAction } from "./../../../actions";
import LoactionPanel from "./component/Location";
import LanguagePanel from "./component/Language";
import Role from "./component/Role";
import Skill from "./component/Skill";
import Certification from "./component/Certification";
import Keyword from "./component/Keyword";
import Education from "./component/Education";
import Industry from "./component/Industry";
import ProfessionalExperience from "./component/ProfessionalExperience";
import OtherAcheivement from "./component/OtherAcheivement";
import { withRouter } from "react-router-dom";
import { privateRoutes } from "../../../Routes/routing";
import {
  getResumesApi,
  saveUpdatedResumeApi,
  getRoleSuggestionsApi,
  getSkillSuggestionsApi,
  getCertificationSuggestionsApi,
  getRolesApi,
  getSkillsApi,
  getCertificationsApi,
  getEducationsApi,
  getKeywordApi,
  getIndustriesApi,
} from "./resumeEditApi";
import { isNullOrUndefined } from "util";
import { onStateChangeAction } from "./resumeEditAction";

const JobAvailabilityBar = ({ children }) => (
  <div className="job-availability-bar">{children}</div>
);
class ResumeEdit extends Component {
  handleYesClick = () => {
    const { isLogoutTrigger, isRoleSwitchTrigger } = this.props;
    if (isLogoutTrigger || isRoleSwitchTrigger) {
      this.handleResumeSave({ isLogoutTrigger, isRoleSwitchTrigger });
    }
    if (this.isLeaving) {
      this.props.onStateChangeAction({
        dialogMessage: "",
      });
      this.handleResumeSave();
      return;
    }
    const { Type, UniqueId, ItemValue } = this.props.currentCandidate;
    if (Type === "Language") {
      const languageList = this.props.languageList.map((item) => ({
        ...item,
        LanguageId: item.uniqueId === UniqueId ? 0 : item.LanguageId,
        LanguageValue:
          item.uniqueId === UniqueId ? ItemValue : item.LanguageValue,
        isInvalid: item.uniqueId === UniqueId ? false : item.isInvalid,
        isCandidate: item.uniqueId === UniqueId ? true : item.isCandidate,
      }));
      this.props.onStateChangeAction({
        languageList,
      });
    } else if (Type === "Role") {
      const rolesList = this.props.rolesList.map((item) => ({
        ...item,
        ProfileId: item.uniqueId === UniqueId ? 0 : item.ProfileId,
        ProfileValue:
          item.uniqueId === UniqueId ? ItemValue : item.ProfileValue,
        isInvalid: item.uniqueId === UniqueId ? false : item.isInvalid,
        isCandidate: item.uniqueId === UniqueId ? true : item.isCandidate,
      }));
      this.props.onStateChangeAction({
        rolesList,
      });
    } else if (Type === "Skill") {
      const skillsList = this.props.skillsList.map((item) => ({
        ...item,
        SkillId: item.uniqueId === UniqueId ? 0 : item.SkillId,
        SkillValue: item.uniqueId === UniqueId ? ItemValue : item.SkillValue,
        isInvalid: item.uniqueId === UniqueId ? false : item.isInvalid,
        isCandidate: item.uniqueId === UniqueId ? true : item.isCandidate,
      }));
      this.props.onStateChangeAction({
        skillsList,
      });
    } else if (Type === "Certification") {
      const certificationsList = this.props.certificationsList.map((item) => ({
        ...item,
        CertificationId: item.uniqueId === UniqueId ? 0 : item.CertificationId,
        CertificationValue:
          item.uniqueId === UniqueId ? ItemValue : item.CertificationValue,
        isInvalid: item.uniqueId === UniqueId ? false : item.isInvalid,
        isCandidate: item.uniqueId === UniqueId ? true : item.isCandidate,
      }));
      this.props.onStateChangeAction({
        certificationsList,
      });
    } else if (Type === "Education") {
      const educationsList = this.props.educationsList.map((item) => ({
        ...item,
        EducationId: item.uniqueId === UniqueId ? 0 : item.EducationId,
        EducationValue:
          item.uniqueId === UniqueId ? ItemValue : item.EducationValue,
        isInvalid: item.uniqueId === UniqueId ? false : item.isInvalid,
        isCandidate: item.uniqueId === UniqueId ? true : item.isCandidate,
      }));
      this.props.onStateChangeAction({
        educationsList,
      });
    } else if (Type === "Keyword") {
      const keywordsList = this.props.keywordsList.map((item) => ({
        ...item,
        KeywordId: item.uniqueId === UniqueId ? 0 : item.KeywordId,
        KeywordValue:
          item.uniqueId === UniqueId ? ItemValue : item.KeywordValue,
        isInvalid: item.uniqueId === UniqueId ? false : item.isInvalid,
        isCandidate: item.uniqueId === UniqueId ? true : item.isCandidate,
      }));
      this.props.onStateChangeAction({
        keywordsList,
      });
    } else if (Type === "Industry") {
      const industriesList = this.props.industriesList.map((item) => ({
        ...item,
        IndustryId: item.uniqueId === UniqueId ? 0 : item.IndustryId,
        IndustryValue:
          item.uniqueId === UniqueId ? ItemValue : item.IndustryValue,
        isInvalid: item.uniqueId === UniqueId ? false : item.isInvalid,
        isCandidate: item.uniqueId === UniqueId ? true : item.isCandidate,
      }));
      this.props.onStateChangeAction({
        industriesList,
      });
    }
    this.props.onStateChangeAction({
      dialogMessage: "",
    });
  };

  handleResumeChange = () => {
    const {
      isLogoutTrigger,
      isRoleSwitchTrigger,
      resumeChangeAction,
    } = this.props;
    if (isLogoutTrigger || isRoleSwitchTrigger) {
      resumeChangeAction({
        isResumeChange: false,
        message: "",
        isLogout: isLogoutTrigger,
        isRoleSwitchToggle: isRoleSwitchTrigger,
      });
    }
    return;
  };

  handleNoClick = () => {
    this.handleResumeChange();
    if (this.isLeaving) {
      this.props.history.push(this.pathname);
      return;
    }
    const { Type, UniqueId } = this.props.currentCandidate;
    if (Type === "Language") {
      const languageList = this.props.languageList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === UniqueId && item.LanguageValue === ""
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        languageList,
      });
    } else if (Type === "Role") {
      const rolesList = this.props.rolesList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === UniqueId && item.ProfileValue === ""
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        rolesList,
      });
    } else if (Type === "Skill") {
      const skillsList = this.props.skillsList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === UniqueId && item.SkillValue === ""
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        skillsList,
      });
    } else if (Type === "Certification") {
      const certificationsList = this.props.certificationsList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === UniqueId && item.CertificationValue === ""
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        certificationsList,
      });
    } else if (Type === "Education") {
      const educationsList = this.props.educationsList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === UniqueId && item.EducationValue === ""
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        educationsList,
      });
    } else if (Type === "Keyword") {
      const keywordsList = this.props.keywordsList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === UniqueId && item.KeywordValue === ""
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        keywordsList,
      });
    } else if (Type === "Industry") {
      const industriesList = this.props.industriesList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === UniqueId && item.IndustryValue === ""
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        industriesList,
      });
    }
    this.props.onStateChangeAction({
      currentCandidate: {},
    });
    this.props.onStateChangeAction({ dialogMessage: "" });
  };
  //life cycle events

  componentDidMount = () => {
    this.props.onStateChangeAction({
      dialogMessage: "",
    });
    this.props.isLoading && this.getResume();
    this.handleRoleDefaultOptionsLoad();
    this.handleSkillDefaultOptionsLoad();
    this.handleCertificationDefaultOptionsLoad();
    this.handleEducationDefaultOptionsLoad();
    this.handleKeywordDefaultOptionsLoad();
    this.handleIndustryDefaultOptionsLoad();
    this.getResume();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.message !== this.props.message) {
      this.props.onStateChangeAction({
        dialogMessage: this.props.message,
      });
    }
  }

  isThereIsAnyUnSavedChange = () => {
    let { AvailablityType, AvailabilityDate } = this.props.resumes;
    const {
      busyUntil,
      locationList,
      languageList,
      rolesList,
      skillsList,
      certificationsList,
      educationsList,
      industriesList,
      keywordsList,
      experiencesList,
      otherAchivenmentsList,
    } = this.props;
    if (
      AvailablityType !== this.props.AvailablityType ||
      !moment(AvailabilityDate).isSame(moment(busyUntil)) ||
      !(
        this.initialLocations.length === locationList.length &&
        this.initialLocations.every(
          (v, i) => v.CountryName === locationList[i].CountryName
        )
      ) ||
      !isEqual(this.intialLanguages, languageList) ||
      !(
        this.intialRoles.length === rolesList.length &&
        this.intialRoles.every(
          (v, i) =>
            v.ProfileValue === rolesList[i].ProfileValue &&
            v.ExperienceLevel === rolesList[i].ExperienceLevel
        )
      ) ||
      !(
        this.intialSkills.length === skillsList.length &&
        this.intialSkills.every(
          (v, i) =>
            v.SkillValue === skillsList[i].SkillValue &&
            v.ExperienceLevel === skillsList[i].ExperienceLevel
        )
      ) ||
      !(
        this.intialCertifications.length === certificationsList.length &&
        this.intialCertifications.every(
          (v, i) =>
            v.CertificationValue === certificationsList[i].CertificationValue &&
            v.CertificationDate === certificationsList[i].CertificationDate
        )
      ) ||
      !isEqual(this.intialEducations, educationsList) ||
      !isEqual(this.intialIndustries, industriesList) ||
      !isEqual(this.intialExperiences, experiencesList) ||
      !isEqual(this.intialOtherAcheivemnts, otherAchivenmentsList) ||
      !isEqual(this.intialKeywords, keywordsList)
    ) {
      return true;
    } else {
      return false;
    }
  };
  blockRoute = this.props.history.block((targetLocation) => {
    if (this.isLeaving || this.isSaved) {
      return true;
    }
    if (this.isThereIsAnyUnSavedChange()) {
      this.props.onStateChangeAction({
        dialogMessage: this.props.labels.RESUME_EDIT_UNSAVED_CHANGES_MESSAGE,
      });
      this.isLeaving = true;
      this.pathname = targetLocation.pathname;
      return false;
    }
    return true;
  });

  // Default Options Load
  handleRoleDefaultOptionsLoad = () => {
    getRolesApi({ searchKey: "a" }).then((response) => {
      if (response.success) {
        const roleDefaultOptions = map(response.items, (profile) => {
          return {
            ...profile,
            label: profile.ProfileValue,
            value: profile.ProfileId,
          };
        });
        this.props.onStateChangeAction({
          roleDefaultOptions,
        });
      }
    });
  };
  handleSkillDefaultOptionsLoad = () => {
    getSkillsApi({ searchKey: "a" }).then((response) => {
      if (response.success) {
        const skillDefaultOptions = map(response.items, (skill) => {
          return {
            ...skill,
            label: skill.SkillValue,
            value: skill.SkillId,
          };
        });
        this.props.onStateChangeAction({
          skillDefaultOptions,
        });
      }
    });
  };
  handleCertificationDefaultOptionsLoad = () => {
    getCertificationsApi({ searchKey: "a" }).then((response) => {
      if (response.success) {
        const certificationDefaultOptions = map(
          response.items,
          (certification) => {
            return {
              ...certification,
              label: certification.CertificationValue,
              value: certification.CertificationId,
            };
          }
        );
        this.props.onStateChangeAction({
          certificationDefaultOptions,
        });
      }
    });
  };
  handleEducationDefaultOptionsLoad = () => {
    getEducationsApi({ searchKey: "a" }).then((response) => {
      if (response.success) {
        const educationDefaultOptions = map(response.items, (education) => {
          return {
            ...education,
            label: education.EducationValue,
            value: education.EducationId,
          };
        });
        this.props.onStateChangeAction({
          educationDefaultOptions,
        });
      }
    });
  };
  handleKeywordDefaultOptionsLoad = () => {
    getKeywordApi({ searchKey: "a" }).then((response) => {
      if (response.success) {
        const keywordDefaultOptions = map(response.items, (Keyword) => {
          return {
            ...Keyword,
            label: Keyword.KeywordValue,
            value: Keyword.KeywordId,
          };
        });
        this.props.onStateChangeAction({
          keywordDefaultOptions,
        });
      }
    });
  };
  handleIndustryDefaultOptionsLoad = () => {
    getIndustriesApi({ searchKey: "a" }).then((response) => {
      if (response.success) {
        const IndustryDefaultOptions = map(response.items, (Industry) => {
          return {
            ...Industry,
            label: Industry.IndustryValue,
            value: Industry.IndustryId,
          };
        });
        this.props.onStateChangeAction({
          IndustryDefaultOptions,
        });
      }
    });
  };
  //
  //get resumes
  getResume = () => {
    this.props.onStateChangeAction({ isLoading: true });
    getResumesApi()
      .then((data) => {
        if (data.success) {
          this.props.onStateChangeAction({
            resumes: data.items,
          });
          this.InitialAvailabilityDate = isNullOrUndefined(
            data.items.AvailabilityDate
          )
            ? null
            : moment(data.items.AvailabilityDate);
          this.InitialAvailabilityType =
            data.items.AvailablityType && data.items.AvailablityType;
          const locationList = data.items.Regions.map((item) => ({
            ...item,
            uniqueId: uuidv1(),
            isInvalid: false,
          }));
          //Language
          const languageList = data.items.Languages.map((item) => ({
            ...item,
            uniqueId: uuidv1(),
            isInvalid: false,
            isCandidate: false,
          }));
          const candidatelanguageList = data.items.ResumeCandidateLanguage.map(
            (item) => ({
              ExperienceLevel: item.ExperienceLevel,
              LanguageId: item.ResumeCandidateLanguageId,
              LanguageValue: item.ResumeCandidateLanguageValue,
              uniqueId: uuidv1(),
              isInvalid: false,
              isCandidate: true,
            })
          );
          languageList.push(...candidatelanguageList);
          ////////
          //Role Mapping
          const rolesList = data.items.Profiles.map((item) => ({
            ...item,
            uniqueId: uuidv1(),
            isInvalid: false,
            isCandidate: false,
            isActive: false,
          }));
          const candidateRolesList = data.items.ResumeCandidateProfiles.map(
            (item) => ({
              ExperienceLevel: item.ExperienceLevel,
              ProfileId: item.ResumeCandidateProfileId,
              ProfileValue: item.ResumeCandidateProfileName,
              uniqueId: uuidv1(),
              isInvalid: false,
              isCandidate: true,
              isActive: false,
            })
          );

          rolesList.push(...candidateRolesList);
          this.props.onStateChangeAction({
            rolesList,
          });
          //another array
          //mapping suggestions
          const firstApprovedRole = rolesList.find((x) => !x.isCandidate);
          {
            firstApprovedRole &&
              this.handleRoleSuggestions(
                firstApprovedRole.ProfileId,
                "Profile",
                firstApprovedRole.ProfileValue,
                firstApprovedRole.uniqueId
              );
          }
          ///skill mapping
          const skillsList = data.items.Skills.map((item) => ({
            ...item,
            uniqueId: uuidv1(),
            isInvalid: false,
            isCandidate: false,
            isActive: false,
          }));
          const candidateSkillsList = data.items.ResumeCandidateSkills.map(
            (item) => ({
              ExperienceLevel: item.ExperienceLevel,
              SkillId: item.ResumeCandidateSkillId,
              SkillValue: item.ResumeCandidateSkillName,
              uniqueId: uuidv1(),
              isInvalid: false,
              isCandidate: true,
              isActive: false,
            })
          );
          skillsList.push(...candidateSkillsList);
          //another array
          //skills mapping ends here

          //Certification Mapping
          const certificationsList = data.items.Certifications.map((item) => ({
            ...item,
            CertificationDate: moment(
              item.CertificationDate,
              "YYYY-MM-DDTHH:mm:ss"
            ).format("MM/DD/YYYY"),
            uniqueId: uuidv1(),
            isCandidate: false,
            isInvalid: false,
            isActive: false,
          }));
          const candidateCertificationList = data.items.ResumeCandidateCertifications.map(
            (item) => ({
              CertificationDate: moment(
                item.CertificationDate,
                "YYYY-MM-DDTHH:mm:ss"
              ).format("MM/DD/YYYY"),
              CertificationId: item.ResumeCandidateCertificationId,
              CertificationValue: item.ResumeCandidateCertificationValue,
              uniqueId: uuidv1(),
              isInvalid: false,
              isCandidate: true,
              isActive: false,
            })
          );
          certificationsList.push(...candidateCertificationList);

          //another array
          //Education Mapping
          const educationsList = data.items.Educations.map((item) => ({
            ...item,
            uniqueId: uuidv1(),
            isInvalid: false,
          }));
          const candidateEducationList = data.items.ResumeCandidateEducations.map(
            (item) => ({
              EducationYear: item.EducationYear,
              EducationId: item.ResumeCandidateEducationId,
              EducationValue: item.ResumeCandidateEducationValue,
              EducationTypeId: item.EducationTypeId,
              uniqueId: uuidv1(),
              isInvalid: false,
              isCandidate: true,
            })
          );
          educationsList.push(...candidateEducationList);

          //////////
          //Keyword mapping
          const keywordsList = data.items.Keywords.map((item) => ({
            ...item,
            uniqueId: uuidv1(),
            isCandidate: false,
            isInvalid: false,
          }));
          const candidateKeywordList = data.items.ResumeCandidateKeywords.map(
            (item) => ({
              KeywordId: item.ResumeCandidateKeywordId,
              KeywordValue: item.ResumeCandidateKeywordValue,
              uniqueId: uuidv1(),
              isInvalid: false,
              isCandidate: true,
            })
          );
          keywordsList.push(...candidateKeywordList);
          ///////////////////////

          //Industruy
          const industriesList = data.items.Industries.map((item) => ({
            ...item,
            uniqueId: uuidv1(),
            isInvalid: false,
            isCandidate: false,
          }));
          const candidateIndustriesList = data.items.ResumeCandidateIndustries.map(
            (item) => ({
              ExperienceLevel: item.ExperienceLevel,
              IndustryId: item.ResumeCandidateIndustryId,
              IndustryValue: item.ResumeCandidateIndustryValue,
              uniqueId: uuidv1(),
              isInvalid: false,
              isCandidate: true,
            })
          );
          industriesList.push(...candidateIndustriesList);
          ////////
          const otherAchivenmentsList = data.items.ResumeOtherAchivenments.map(
            (item) => ({
              ...item,
              uniqueId: uuidv1(),
              isInvalid: false,
            })
          );
          const experiencesList = data.items.ResumeExperience.map((item) => ({
            ...item,
            uniqueId: uuidv1(),
            isWorking: isNullOrUndefined(item.EndDate) ? true : false,
            StartDate: moment(item.StartDate, "YYYY-MM-DDTHH:mm:ssZ").format(
              "MM/DD/YYYY"
            ),
            EndDate: !isNullOrUndefined(item.EndDate)
              ? moment(item.EndDate, "YYYY-MM-DDTHH:mm:ssZ").format(
                  "MM/DD/YYYY"
                )
              : moment(new Date(), "MM/DD/YYYY"),
            isInvalid: false,
            isRoleInvalid: false,
            isIndustryInvalid: false,
          }));
          this.props.onStateChangeAction({
            IsAvailable: data.items.IsAvailable,
            AvailablityType: data.items.AvailablityType,
            busyUntil: moment(data.items.AvailabilityDate),
            locationList,
            languageList,
            keywordsList,
            otherAchivenmentsList,
            skillsList,
            certificationsList,
            educationsList,
            industriesList,
            experiencesList,
            educationsList,
            certificationsList,
            skillsList,
            languageList,
            keywordsList,
            industriesList,
          });
          this.initialLocations = locationList;
          this.intialRoles = rolesList;
          this.intialOtherAcheivemnts = otherAchivenmentsList;
          this.intialExperiences = experiencesList;
          this.intialEducations = educationsList;
          this.intialCertifications = certificationsList;
          this.intialSkills = skillsList;
          this.intialLanguages = languageList;
          this.intialKeywords = keywordsList;
          this.intialIndustries = industriesList;
        }
        this.props.onStateChangeAction({ isLoading: false });
      })
      .catch(() => {});
  };

  //location methods start
  handleLocationChange = (option, uniqueId) => {
    if (
      !isNullOrUndefined(option) &&
      this.props.locationList.findIndex((x) => x.CountryName === option.label) >
        -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    const locationList = this.props.locationList.map((item) => ({
      ...item,
      CountryId:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.CountryId,
      isInvalid:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isInvalid
          : item.uniqueId === uniqueId
          ? false
          : item.isInvalid,
      CountryName:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? ""
          : item.uniqueId === uniqueId
          ? option.label
          : item.CountryName,
    }));
    this.props.onStateChangeAction({
      locationList,
    });
  };
  handleLocationDelete = (deletedItem) => {
    const locationList = this.props.locationList.filter(
      (item) => deletedItem.uniqueId !== item.uniqueId
    );
    this.props.onStateChangeAction({ locationList }, this.resumeChange);
  };
  handleLocationAdd = () => {
    const { locationList } = this.props;
    const LocationNewItem = {
      CountryId: 0,
      uniqueId: uuidv1(),
      CountryName: "",
    };
    this.props.onStateChangeAction(
      {
        locationList: [LocationNewItem, ...locationList],
      },
      this.resumeChange
    );
  };
  handleLocationBlur = (value, id) => {
    if (value === 0) {
      const locationList = this.props.locationList.map((item) => ({
        ...item,
        isInvalid: item.uniqueId === id ? true : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        locationList,
      });
    }
  };
  //location methods ends

  //language methods start
  handleLanguageNameChange = (option, uniqueId) => {
    if (
      !isNullOrUndefined(option) &&
      this.props.languageList.findIndex(
        (x) => x.LanguageValue === option.label
      ) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    const languageList = this.props.languageList.map((item) => ({
      ...item,
      LanguageId:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.LanguageId,
      LanguageValue:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? ""
          : item.uniqueId === uniqueId
          ? option.label
          : item.LanguageValue,
      isInvalid:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isInvalid
          : item.uniqueId === uniqueId
          ? false
          : item.isInvalid,
      isCandidate:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isCandidate
          : item.uniqueId === uniqueId
          ? false
          : item.isCandidate,
    }));
    this.props.onStateChangeAction({
      languageList,
    });
  };
  handleLanguageLevelChange = (option, uniqueId) => {
    const languageList = this.props.languageList.map((item) => ({
      ...item,
      ExperienceLevel:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.ExperienceLevel,
    }));
    this.props.onStateChangeAction({
      languageList,
    });
  };
  handleLanguageDelete = (deletedItem) => {
    const languageList = this.props.languageList.filter(
      (item) => deletedItem.uniqueId !== item.uniqueId
    );
    this.props.onStateChangeAction({ languageList }, this.resumeChange);
  };
  handleLanguageAdd = () => {
    const { languageList } = this.props;
    const languageItem = {
      LanguageValue: "",
      ExperienceLevel: 3,
      LanguageId: 0,
      ResumeLanguageId: 0,
      uniqueId: uuidv1(),
      isInvalid: false,
      isCandidate: false,
    };
    this.props.onStateChangeAction(
      {
        languageList: [languageItem, ...languageList],
      },
      this.resumeChange
    );
  };
  handleLanguageBlur = (itemId, uniqueId, option) => {
    const { value } = option.target;
    if (
      option.target.value !== "" &&
      this.props.languageList.findIndex((x) => x.LanguageValue === value) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    if (
      (value !== "" && itemId === 0) ||
      (value !== "" &&
        this.props.languageList.find(
          (x) =>
            x.uniqueId === uniqueId &&
            x.LanguageId === itemId &&
            x.LanguageValue !== value
        ))
    ) {
      this.props.onStateChangeAction({
        currentCandidate: {
          Type: "Language",
          ItemId: itemId,
          UniqueId: uniqueId,
          ItemValue: value,
        },
      });
      const { RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE } = this.props.labels;
      var content_holder = this.stripHtml(RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE);
      this.props.onStateChangeAction({
        dialogMessage: isNullOrUndefined(content_holder)
          ? "Want to add new role"
          : content_holder,
      });
    } else if (value === "" && itemId === 0) {
      const languageList = this.props.languageList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === uniqueId && isNullOrUndefined(option)
            ? 0
            : item.uniqueId === uniqueId
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        languageList,
      });
    }
  };
  //language methods ends

  //roles methods start
  handleRoleNameChange = (option, uniqueId) => {
    if (
      !isNullOrUndefined(option) &&
      this.props.rolesList.findIndex((x) => x.ProfileValue === option.label) >
        -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    const rolesList = this.props.rolesList.map((item) => ({
      ...item,
      ProfileId:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.ProfileId,
      ProfileValue:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? ""
          : item.uniqueId === uniqueId
          ? option.label
          : item.ProfileValue,
      isInvalid:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isInvalid
          : item.uniqueId === uniqueId
          ? false
          : item.isInvalid,
      isCandidate:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isCandidate
          : item.uniqueId === uniqueId
          ? false
          : item.isCandidate,
    }));
    this.props.onStateChangeAction({
      rolesList,
    });
  };
  handleRoleLevelChange = (option, uniqueId) => {
    const rolesList = this.props.rolesList.map((item) => ({
      ...item,
      ExperienceLevel:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.ExperienceLevel,
    }));
    this.props.onStateChangeAction({
      rolesList,
    });
  };
  handleRoleDelete = (deletedItem) => {
    const rolesList = this.props.rolesList.filter(
      (item) => deletedItem.uniqueId !== item.uniqueId
    );
    this.props.onStateChangeAction({ rolesList }, this.resumeChange);
  };
  handleRoleAdd = () => {
    const { rolesList } = this.props;
    const roleItem = {
      ProfileValue: "",
      ExperienceLevel: 3,
      ProfileId: 0,
      ResumeProfileId: 0,
      uniqueId: uuidv1(),
      isInvalid: false,
      isCandidate: false,
    };
    this.props.onStateChangeAction(
      {
        rolesList: [roleItem, ...rolesList],
      },
      this.resumeChange
    );
  };
  stripHtml(html) {
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  handleRoleBlur = (itemId, uniqueId, option) => {
    const { value } = option.target;
    if (
      option.target.value !== "" &&
      this.props.rolesList.findIndex((x) => x.ProfileValue === value) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    if (
      (value !== "" && itemId === 0) ||
      (value !== "" &&
        this.props.rolesList.find(
          (x) =>
            x.uniqueId === uniqueId &&
            x.ProfileId === itemId &&
            x.ProfileValue !== value
        ))
    ) {
      this.props.onStateChangeAction({
        currentCandidate: {
          Type: "Role",
          ItemId: itemId,
          UniqueId: uniqueId,
          ItemValue: value,
        },
      });
      const { RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE } = this.props.labels;
      var content_holder = this.stripHtml(RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE);
      this.props.onStateChangeAction({
        dialogMessage: isNullOrUndefined(content_holder)
          ? "Want to add new role"
          : content_holder,
      });
    } else if (value === "" && itemId === 0) {
      const rolesList = this.props.rolesList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === uniqueId && isNullOrUndefined(option)
            ? 0
            : item.uniqueId === uniqueId
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        rolesList,
      });
    }
  };
  handleRoleSuggestions = (id, type, value, uniqueId) => {
    if (id === this.props.suggestObject.Id) {
      const rolesList = this.props.rolesList.map((item) => ({
        ...item,
        isActive: item.uniqueId === uniqueId ? false : false,
      }));
      this.props.onStateChangeAction({
        suggestObject: {},
        suggestedSkills: [],
        suggestedRoles: [],
        suggestedCertifications: [],
        rolesList,
      });
      return;
    }
    const suggestObject = {
      Id: id,
      Value: value,
      Type: type,
    };
    this.props.onStateChangeAction({
      suggestObject,
    });
    const rolesList = this.props.rolesList.map((item) => ({
      ...item,
      isActive: item.uniqueId === uniqueId ? true : false,
    }));
    this.props.onStateChangeAction({
      rolesList,
    });
    const skillsList = this.props.skillsList.map((item) => ({
      ...item,
      isActive: item.isActive ? false : item.isActive,
    }));
    this.props.onStateChangeAction({
      skillsList,
    });
    const certificationsList = this.props.certificationsList.map((item) => ({
      ...item,
      isActive: item.isActive ? false : item.isActive,
    }));
    this.props.onStateChangeAction({
      certificationsList,
    });
    getRoleSuggestionsApi({ Id: id, type: type }).then((data) => {
      if (data.success) {
        var suggestedRoles = data.items.map((item) => ({
          ProfileId: item.CorrelatedProfiles.ProfileId,
          ProfileValue: item.CorrelatedProfiles.ProfileValue,
        }));
        let uersObj = this.props.rolesList.reduce(
          (a, c) => Object.assign(a, { [c.ProfileValue]: c.ProfileValue }),
          {}
        );
        suggestedRoles = suggestedRoles.filter(
          (v) =>
            !uersObj[v.ProfileValue] ||
            uersObj[v.ProfileValue] !== v.ProfileValue
        );
        this.props.onStateChangeAction({
          suggestedRoles,
        });
      }
    });
    getSkillSuggestionsApi({ Id: id, type: type }).then((data) => {
      if (data.success) {
        var suggestedSkills = data.items.map((item) => ({
          SkillId: item.Skills.SkillId,
          SkillValue: item.Skills.SkillValue,
        }));
        let uersObj = this.props.skillsList.reduce(
          (a, c) => Object.assign(a, { [c.SkillValue]: c.SkillValue }),
          {}
        );
        suggestedSkills = suggestedSkills.filter(
          (v) =>
            !uersObj[v.SkillValue] || uersObj[v.SkillValue] !== v.SkillValue
        );
        this.props.onStateChangeAction({
          suggestedSkills,
        });
      }
    });
    getCertificationSuggestionsApi({ Id: id, type: type }).then((data) => {
      if (data.success) {
        var suggestedCertifications = data.items.map((item) => ({
          CertificationId: item.Certifications.CertificationId,
          CertificationValue: item.Certifications.CertificationValue,
        }));
        let uersObj = this.props.certificationsList.reduce(
          (a, c) =>
            Object.assign(a, { [c.CertificationValue]: c.CertificationValue }),
          {}
        );
        suggestedCertifications = suggestedCertifications.filter(
          (v) =>
            !uersObj[v.CertificationValue] ||
            uersObj[v.CertificationValue] !== v.CertificationValue
        );
        this.props.onStateChangeAction({
          suggestedCertifications,
        });
      }
    });
  };
  handleSkillSuggestions = (id, type, value, uniqueId) => {

    if (id === this.props.suggestObject.Id) {
      const skillsList = this.props.skillsList.map((item) => ({
        ...item,
        isActive: item.uniqueId === uniqueId ? false : false,
      }));
      this.props.onStateChangeAction({
        suggestObject: {},
        suggestedSkills: [],
        suggestedRoles: [],
        suggestedCertifications: [],
        skillsList,
      });
      return;
    }
    const suggestObject = {
      Id: id,
      Value: value,
      Type: type,
    };
    this.props.onStateChangeAction({
      suggestObject,
    });
    const skillsList = this.props.skillsList.map((item) => ({
      ...item,
      isActive: item.uniqueId === uniqueId ? true : false,
    }));
    this.props.onStateChangeAction({
      skillsList,
    });
    const rolesList = this.props.rolesList.map((item) => ({
      ...item,
      isActive: item.isActive ? false : item.isActive,
    }));
    this.props.onStateChangeAction({
      rolesList,
    });
    const certificationsList = this.props.certificationsList.map((item) => ({
      ...item,
      isActive: item.isActive ? false : item.isActive,
    }));
    this.props.onStateChangeAction({
      certificationsList,
    });
    getRoleSuggestionsApi({ Id: id, type: type }).then((data) => {
      if (data.success) {
        var suggestedRoles = data.items.map((item) => ({
          ProfileId: item.Profiles.ProfileId,
          ProfileValue: item.Profiles.ProfileValue,
        }));
        let uersObj = this.props.rolesList.reduce(
          (a, c) => Object.assign(a, { [c.ProfileValue]: c.ProfileValue }),
          {}
        );
        suggestedRoles = suggestedRoles.filter(
          (v) =>
            !uersObj[v.ProfileValue] ||
            uersObj[v.ProfileValue] !== v.ProfileValue
        );
        this.props.onStateChangeAction({
          suggestedRoles,
        });
      }
    });
    getSkillSuggestionsApi({ Id: id, type: type }).then((data) => {
      if (data.success) {
        var suggestedSkills = data.items.map((item) => ({
          SkillId: item.CorrelatedSkills.SkillId,
          SkillValue: item.CorrelatedSkills.SkillValue,
        }));
        let uersObj = this.props.skillsList.reduce(
          (a, c) => Object.assign(a, { [c.SkillValue]: c.SkillValue }),
          {}
        );
        suggestedSkills = suggestedSkills.filter(
          (v) =>
            !uersObj[v.SkillValue] || uersObj[v.SkillValue] !== v.SkillValue
        );
        this.props.onStateChangeAction({
          suggestedSkills,
        });
      }
    });
    this.props.onStateChangeAction({
      suggestedCertifications: [],
    });
  };
  handleCertificationSuggestions = (id, type, value, uniqueId) => {
    if (id === this.props.suggestObject.Id) {
      const certificationsList = this.props.certificationsList.map((item) => ({
        ...item,
        isActive: item.uniqueId === uniqueId ? false : false,
      }));
      this.props.onStateChangeAction({
        suggestObject: {},
        suggestedSkills: [],
        suggestedRoles: [],
        suggestedCertifications: [],
        certificationsList,
      });
      return;
    }
    const suggestObject = {
      Id: id,
      Value: value,
      Type: type,
    };
    this.props.onStateChangeAction({
      suggestObject,
    });
    const certificationsList = this.props.certificationsList.map((item) => ({
      ...item,
      isActive: item.uniqueId === uniqueId ? true : false,
    }));
    this.props.onStateChangeAction({
      certificationsList,
    });
    const rolesList = this.props.rolesList.map((item) => ({
      ...item,
      isActive: item.isActive ? false : item.isActive,
    }));
    this.props.onStateChangeAction({
      rolesList,
    });
    const skillsList = this.props.skillsList.map((item) => ({
      ...item,
      isActive: item.isActive ? false : item.isActive,
    }));
    this.props.onStateChangeAction({
      skillsList,
    });
    getRoleSuggestionsApi({ Id: id, type: type }).then((data) => {
      if (data.success) {
        var suggestedRoles = data.items.map((item) => ({
          ProfileId: item.CorrelatedProfiles.ProfileId,
          ProfileValue: item.CorrelatedProfiles.ProfileValue,
        }));
        let uersObj = this.props.rolesList.reduce(
          (a, c) => Object.assign(a, { [c.ProfileValue]: c.ProfileValue }),
          {}
        );
        suggestedRoles = suggestedRoles.filter(
          (v) =>
            !uersObj[v.ProfileValue] ||
            uersObj[v.ProfileValue] !== v.ProfileValue
        );
        this.props.onStateChangeAction({
          suggestedRoles,
        });
      }
    });
    this.props.onStateChangeAction({
      suggestedSkills: [],
    });
    getCertificationSuggestionsApi({ Id: id, type: type }).then((data) => {
      if (data.success) {
        var suggestedCertifications = data.items.map((item) => ({
          CertificationId: item.CorrelatedCertifications.CertificationId,
          CertificationValue: item.CorrelatedCertifications.CertificationValue,
        }));
        let uersObj = this.props.certificationsList.reduce(
          (a, c) =>
            Object.assign(a, { [c.CertificationValue]: c.CertificationValue }),
          {}
        );
        suggestedCertifications = suggestedCertifications.filter(
          (v) =>
            !uersObj[v.CertificationValue] ||
            uersObj[v.CertificationValue] !== v.CertificationValue
        );
        this.props.onStateChangeAction({
          suggestedCertifications,
        });
      }
    });
  };
  handleSuggestedRoleAdd = (Id, Value) => {
    const { rolesList } = this.props;
    const roleItem = {
      ProfileValue: Value,
      ExperienceLevel: 3,
      ProfileId: Id,
      uniqueId: uuidv1(),
      isInvalid: false,
    };
    this.props.onStateChangeAction({
      rolesList: [roleItem, ...rolesList],
    });
    const suggestedRoles = this.props.suggestedRoles.filter(
      (item) => Id !== item.ProfileId
    );
    this.props.onStateChangeAction({ suggestedRoles });
  };
  //roles methods ends
  //Skills New Methods
  handleSkillNameChange = (option, uniqueId) => {
    if (
      !isNullOrUndefined(option) &&
      this.props.skillsList.findIndex((x) => x.SkillValue === option.label) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    const skillsList = this.props.skillsList.map((item) => ({
      ...item,
      SkillId:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.SkillId,
      SkillValue:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? ""
          : item.uniqueId === uniqueId
          ? option.label
          : item.SkillValue,
      isInvalid:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isInvalid
          : item.uniqueId === uniqueId
          ? false
          : item.isInvalid,
      isCandidate:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? false
          : item.isCandidate,
    }));
    this.props.onStateChangeAction({
      skillsList,
    });
  };
  handleSkillLevelChange = (option, uniqueId) => {
    const skillsList = this.props.skillsList.map((item) => ({
      ...item,
      ExperienceLevel:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.ExperienceLevel,
    }));
    this.props.onStateChangeAction({
      skillsList,
    });
  };
  handleSkillDelete = (deletedItem) => {
    const skillsList = this.props.skillsList.filter(
      (item) => deletedItem.uniqueId !== item.uniqueId
    );
    this.props.onStateChangeAction({ skillsList }, this.resumeChange);
  };
  handleSkillAdd = () => {
    const { skillsList } = this.props;
    const skillItem = {
      SkillValue: "",
      ExperienceLevel: 3,
      SkillId: 0,
      uniqueId: uuidv1(),
      isInvalid: false,
      isCandidate: false,
    };
    this.props.onStateChangeAction(
      {
        skillsList: [skillItem, ...skillsList],
      },
      this.resumeChange
    );
    
  };
  handleSkillBlur = (itemId, uniqueId, option) => {
    const { value } = option.target;
    if (
      option.target.value !== "" &&
      this.props.skillsList.findIndex((x) => x.SkillValue === value) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    if (
      (value !== "" && itemId === 0) ||
      (value !== "" &&
        this.props.skillsList.find(
          (x) =>
            x.uniqueId === uniqueId &&
            x.SkillId === itemId &&
            x.SkillValue !== value
        ))
    ) {
      this.props.onStateChangeAction({
        currentCandidate: {
          Type: "Skill",
          ItemId: itemId,
          UniqueId: uniqueId,
          ItemValue: value,
        },
      });
      const { RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE } = this.props.labels;
      var content_holder = this.stripHtml(RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE);
      this.props.onStateChangeAction({
        dialogMessage: isNullOrUndefined(content_holder)
          ? "Want to add new skill"
          : content_holder,
      });
    } else if (value === "" && itemId === 0) {
      const skillsList = this.props.skillsList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === uniqueId && isNullOrUndefined(option)
            ? 0
            : item.uniqueId === uniqueId
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        skillsList,
      });
    }
  };
  handleSuggestedSkillAdd = (Id, Value) => {
    const { skillsList } = this.props;
    const skillItem = {
      SkillValue: Value,
      ExperienceLevel: 3,
      SkillId: Id,
      uniqueId: uuidv1(),
      isInvalid: false,
    };
    this.props.onStateChangeAction({
      skillsList: [skillItem, ...skillsList],
    });
    const suggestedSkills = this.props.suggestedSkills.filter(
      (item) => Id !== item.SkillId
    );
    this.props.onStateChangeAction({ suggestedSkills });
  };
  ///
  //keywords methods start
  handleKeywordNameChange = (option, uniqueId) => {
    if (
      !isNullOrUndefined(option) &&
      this.props.keywordsList.findIndex(
        (x) => x.KeywordValue === option.label
      ) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    const keywordsList = this.props.keywordsList.map((item) => ({
      ...item,
      KeywordId:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.KeywordId,
      KeywordValue:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? ""
          : item.uniqueId === uniqueId
          ? option.label
          : item.KeywordValue,
      isInvalid:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isInvalid
          : item.uniqueId === uniqueId
          ? false
          : item.isInvalid,
      isCandidate:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isCandidate
          : item.uniqueId === uniqueId
          ? false
          : item.isCandidate,
    }));
    this.props.onStateChangeAction({
      keywordsList,
    });
  };
  handleKeywordDelete = (deletedItem) => {
    const keywordsList = this.props.keywordsList.filter(
      (item) => deletedItem.uniqueId !== item.uniqueId
    );
    this.props.onStateChangeAction({ keywordsList }, this.resumeChange);
  };
  handleKeywordAdd = () => {
    const { keywordsList } = this.props;
    const keyworditem = {
      KeywordId: 0,
      KeywordValue: "",
      uniqueId: uuidv1(),
      isCandidate: false,
    };
    this.props.onStateChangeAction(
      {
        keywordsList: [keyworditem, ...keywordsList],
      },
      this.resumeChange
    );
  };
  handleKeywordBlur = (itemId, uniqueId, option) => {
    const { value } = option.target;
    if (
      option.target.value !== "" &&
      this.props.keywordsList.findIndex((x) => x.KeywordValue === value) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    if (
      (value !== "" && itemId === 0) ||
      (value !== "" &&
        this.props.keywordsList.find(
          (x) =>
            x.uniqueId === uniqueId &&
            x.KeywordId === itemId &&
            x.KeywordValue !== value
        ))
    ) {
      this.props.onStateChangeAction({
        currentCandidate: {
          Type: "Keyword",
          ItemId: itemId,
          UniqueId: uniqueId,
          ItemValue: value,
        },
      });
      const { RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE } = this.props.labels;
      var content_holder = this.stripHtml(RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE);
      this.props.onStateChangeAction({
        dialogMessage: isNullOrUndefined(content_holder)
          ? "Want to add new role"
          : content_holder,
      });
    } else if (value === "" && itemId === 0) {
      const keywordsList = this.props.keywordsList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === uniqueId && isNullOrUndefined(option)
            ? 0
            : item.uniqueId === uniqueId
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        keywordsList,
      });
    }
  };
  //keyword methods ends

  //Other Acheivments start
  handleOtherAcheivementNameChange = (uniqueId, e) => {
    const { value } = e.target;
    const otherAchivenmentsList = this.props.otherAchivenmentsList.map(
      (item) => ({
        ...item,
        Title: item.uniqueId === uniqueId ? value : item.Title,
        isInvalid: item.uniqueId === uniqueId ? !value : item.isInvalid,
      })
    );
    this.props.onStateChangeAction({
      otherAchivenmentsList,
    });
  };
  handleOtherAcheivementYearChange = (option, uniqueId) => {
    const otherAchivenmentsList = this.props.otherAchivenmentsList.map(
      (item) => ({
        ...item,
        Year:
          item.uniqueId === uniqueId && isNullOrUndefined(option)
            ? 0
            : item.uniqueId === uniqueId
            ? option.value
            : item.Year,
      })
    );
    this.props.onStateChangeAction({
      otherAchivenmentsList,
    });
  };
  handleOtherAcheivementTypeChange = (option, uniqueId) => {
    const otherAchivenmentsList = this.props.otherAchivenmentsList.map(
      (item) => ({
        ...item,
        AchivenmentId:
          item.uniqueId === uniqueId && isNullOrUndefined(option)
            ? 0
            : item.uniqueId === uniqueId
            ? option.value
            : item.AchivenmentId,
      })
    );
    this.props.onStateChangeAction({
      otherAchivenmentsList,
    });
  };
  handleOtherAcheivementDescriptionChange = (uniqueId, content) => {
    const otherAchivenmentsList = this.props.otherAchivenmentsList.map(
      (item) => ({
        ...item,
        Description: item.uniqueId === uniqueId ? content : item.Description,
      })
    );
    this.props.onStateChangeAction({
      otherAchivenmentsList,
    });
  };
  handleOtherAcheivementDelete = (deletedItem) => {
    const otherAchivenmentsList = this.props.otherAchivenmentsList.filter(
      (item) => deletedItem.uniqueId !== item.uniqueId
    );
    this.props.onStateChangeAction(
      { otherAchivenmentsList },
      this.resumeChange
    );
  };
  //Skills Methods Ends

  //Certification Methods Starts
  handleCertificationNameChange = (option, uniqueId) => {
    if (
      !isNullOrUndefined(option) &&
      this.props.certificationsList.findIndex(
        (x) => x.CertificationValue === option.label
      ) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    const certificationsList = this.props.certificationsList.map((item) => ({
      ...item,
      CertificationId:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.CertificationId,
      CertificationValue:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? ""
          : item.uniqueId === uniqueId
          ? option.label
          : item.CertificationValue,
      isInvalid:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isInvalid
          : item.uniqueId === uniqueId
          ? false
          : item.isInvalid,
      isCandidate:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isCandidate
          : item.uniqueId === uniqueId
          ? false
          : item.isCandidate,
    }));
    this.props.onStateChangeAction({
      certificationsList,
    });
  };
  handleCertificationLevelChange = (option, uniqueId) => {
    const certificationsList = this.props.certificationsList.map((item) => ({
      ...item,
      CertificationDate:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? moment(item.CertificationDate, "MM/DD/YYYY").year(option.value)
          : item.CertificationDate,
    }));
    this.props.onStateChangeAction({
      certificationsList,
    });
  };
  handleCertificationAdd = () => {
    const { certificationsList } = this.props;
    const CertificationItem = {
      CertificationValue: "",
      CertificationDate: new Date(),
      CertificationId: 0,
      uniqueId: uuidv1(),
      isCandidate: false,
      isInvalid: false,
    };
    this.props.onStateChangeAction(
      {
        certificationsList: [CertificationItem, ...certificationsList],
      },
      this.resumeChange
    );
  };
  handleCertificationDelete = (deletedItem) => {
    const certificationsList = this.props.certificationsList.filter(
      (item) => deletedItem.uniqueId !== item.uniqueId
    );
    this.props.onStateChangeAction({ certificationsList }, this.resumeChange);
  };
  handleCertificationBlur = (itemId, uniqueId, option) => {
    const { value } = option.target;
    if (
      option.target.value !== "" &&
      this.props.certificationsList.findIndex(
        (x) => x.CertificationValue === value
      ) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    if (
      (value !== "" && itemId === 0) ||
      (value !== "" &&
        this.props.certificationsList.find(
          (x) =>
            x.uniqueId === uniqueId &&
            x.CertificationId === itemId &&
            x.CertificationValue !== value
        ))
    ) {
      this.props.onStateChangeAction({
        currentCandidate: {
          Type: "Certification",
          ItemId: itemId,
          UniqueId: uniqueId,
          ItemValue: value,
        },
      });
      const { RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE } = this.props.labels;
      var content_holder = this.stripHtml(RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE);
      this.props.onStateChangeAction({
        dialogMessage: isNullOrUndefined(content_holder)
          ? "Want to add new role"
          : content_holder,
      });
    } else if (value === "" && itemId === 0) {
      const certificationsList = this.props.certificationsList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === uniqueId && isNullOrUndefined(option)
            ? 0
            : item.uniqueId === uniqueId
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        certificationsList,
      });
    }
  };
  handleSuggestedCertificationAdd = (Id, Value) => {
    const { certificationsList } = this.props;
    const certificationItem = {
      CertificationValue: Value,
      CertificationDate: new Date(),
      CertificationId: Id,
      uniqueId: uuidv1(),
      isInvalid: false,
    };
    this.props.onStateChangeAction({
      certificationsList: [certificationItem, ...certificationsList],
    });
    const suggestedCertifications = this.props.suggestedCertifications.filter(
      (item) => Id !== item.CertificationId
    );
    this.props.onStateChangeAction({ suggestedCertifications });
  };
  //Certification Methods Ends
  //Education Methods Starts
  handleEducationNameChange = (option, uniqueId) => {
    if (
      !isNullOrUndefined(option) &&
      this.props.educationsList.findIndex(
        (x) => x.EducationValue === option.label
      ) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    const educationsList = this.props.educationsList.map((item) => ({
      ...item,
      EducationId:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.EducationId,
      EducationValue:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? ""
          : item.uniqueId === uniqueId
          ? option.label
          : item.EducationValue,
      isInvalid:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isInvalid
          : item.uniqueId === uniqueId
          ? false
          : item.isInvalid,
      isCandidate:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isCandidate
          : item.uniqueId === uniqueId
          ? false
          : item.isCandidate,
    }));
    this.props.onStateChangeAction({
      educationsList,
    });
  };
  handleEducationLevelChange = (option, uniqueId) => {
    const educationsList = this.props.educationsList.map((item) => ({
      ...item,
      EducationTypeId:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.EducationTypeId,
    }));
    this.props.onStateChangeAction({
      educationsList,
    });
  };
  handleEducationYearChange = (option, uniqueId) => {
    const educationsList = this.props.educationsList.map((item) => ({
      ...item,
      EducationYear:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.EducationYear,
    }));
    this.props.onStateChangeAction({
      educationsList,
    });
  };
  handleEducationDelete = (deletedItem) => {
    const educationsList = this.props.educationsList.filter(
      (item) => deletedItem.uniqueId !== item.uniqueId
    );
    this.props.onStateChangeAction({ educationsList }, this.resumeChange);
  };
  handleEducationAdd = () => {
    const { educationsList } = this.props;
    const EducationItem = {
      EducationValue: "",
      EducationId: 0,
      EducationTypeId: 3,
      uniqueId: uuidv1(),
      isInvalid: false,
      isCandidate: false,
      EducationYear: 2018,
    };
    this.props.onStateChangeAction(
      {
        educationsList: [EducationItem, ...educationsList],
      },
      this.resumeChange
    );
  };
  handleEducationBlur = (itemId, uniqueId, option) => {
    const { value } = option.target;
    if (
      option.target.value !== "" &&
      this.props.educationsList.findIndex((x) => x.EducationValue === value) >
        -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    if (
      (value !== "" && itemId === 0) ||
      (value !== "" &&
        this.props.educationsList.find(
          (x) =>
            x.uniqueId === uniqueId &&
            x.EducationId === itemId &&
            x.EducationValue !== value
        ))
    ) {
      this.props.onStateChangeAction({
        currentCandidate: {
          Type: "Education",
          ItemId: itemId,
          UniqueId: uniqueId,
          ItemValue: value,
        },
      });
      const { RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE } = this.props.labels;
      var content_holder = this.stripHtml(RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE);
      this.props.onStateChangeAction({
        dialogMessage: isNullOrUndefined(content_holder)
          ? "Want to add new role"
          : content_holder,
      });
    } else if (value === "" && itemId === 0) {
      const educationsList = this.props.educationsList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === uniqueId && isNullOrUndefined(option)
            ? 0
            : item.uniqueId === uniqueId
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        educationsList,
      });
    }
  };

  //Education Methods Ends
  //Industry Methods Starts
  handleIndustryNameChange = (option, uniqueId) => {
    if (
      !isNullOrUndefined(option) &&
      this.props.industriesList.findIndex(
        (x) => x.IndustryValue === option.label
      ) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    const industriesList = this.props.industriesList.map((item) => ({
      ...item,
      IndustryId:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.IndustryId,
      IndustryValue:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? ""
          : item.uniqueId === uniqueId
          ? option.label
          : item.IndustryValue,
      isInvalid:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isInvalid
          : item.uniqueId === uniqueId
          ? false
          : item.isInvalid,
      isCandidate:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isCandidate
          : item.uniqueId === uniqueId
          ? false
          : item.isCandidate,
    }));
    this.props.onStateChangeAction({
      industriesList,
    });
  };
  handleIndustryLevelChange = (option, uniqueId) => {
    const industriesList = this.props.industriesList.map((item) => ({
      ...item,
      ExperienceLevel:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.ExperienceLevel,
    }));
    this.props.onStateChangeAction({
      industriesList,
    });
  };
  handleIndustryDelete = (deletedItem) => {
    const industriesList = this.props.industriesList.filter(
      (item) => deletedItem.uniqueId !== item.uniqueId
    );
    this.props.onStateChangeAction({ industriesList }, this.resumeChange);
  };
  handleIndustryAdd = () => {
    const { industriesList } = this.props;
    const IndustryItem = {
      IndustryValue: "",
      ExperienceLevel: 3,
      IndustryId: 0,
      uniqueId: uuidv1(),
      isCandidate: false,
    };
    this.props.onStateChangeAction(
      {
        industriesList: [IndustryItem, ...industriesList],
      },
      this.resumeChange
    );
  };
  handleIndustryBlur = (itemId, uniqueId, option) => {
    const { value } = option.target;
    if (
      option.target.value !== "" &&
      this.props.industriesList.findIndex((x) => x.IndustryValue === value) > -1
    ) {
      let duplicateMessage = this.props.labels
        .RESUME_EDIT_DUPLICATE_VALIDATION_MESSAGE;
      duplicateMessage = duplicateMessage.replace("@item@", option.label);
      const info = {
        message: duplicateMessage,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    if (
      (value !== "" && itemId === 0) ||
      (value !== "" &&
        this.props.industriesList.find(
          (x) =>
            x.uniqueId === uniqueId &&
            x.IndustryId === itemId &&
            x.IndustryValue !== value
        ))
    ) {
      this.props.onStateChangeAction({
        currentCandidate: {
          Type: "Industry",
          ItemId: itemId,
          UniqueId: uniqueId,
          ItemValue: value,
        },
      });
      const { RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE } = this.props.labels;
      var content_holder = this.stripHtml(RESUME_EDIT_CANDIDATE_PROMPT_MESSAGE);
      this.props.onStateChangeAction({
        dialogMessage: isNullOrUndefined(content_holder)
          ? "Want to add new role"
          : content_holder,
      });
    } else if (value === "" && itemId === 0) {
      const industriesList = this.props.industriesList.map((item) => ({
        ...item,
        isInvalid:
          item.uniqueId === uniqueId && isNullOrUndefined(option)
            ? 0
            : item.uniqueId === uniqueId
            ? true
            : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        industriesList,
      });
    }
  };

  //Industry Methods Ends
  //Expierence Methods Starts
  handleExperienceCompanyNameChange = (uniqueId, e) => {
    const { value } = e.target;
    const experiencesList = this.props.experiencesList.map((item) => ({
      ...item,
      CompanyWorked: item.uniqueId === uniqueId ? value : item.CompanyWorked,
      isInvalid: item.uniqueId === uniqueId ? !value : item.isInvalid,
    }));
    this.props.onStateChangeAction({
      experiencesList,
    });
  };
  handleExperienceIndustryNameChange = (option, uniqueId) => {
    const experiencesList = this.props.experiencesList.map((item) => ({
      ...item,
      IndustryId:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.IndustryId,
      IndustryValue:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? ""
          : item.uniqueId === uniqueId
          ? option.label
          : item.IndustryValue,
      isIndustryInvalid:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isIndustryInvalid
          : item.uniqueId === uniqueId
          ? false
          : item.isIndustryInvalid,
    }));
    this.props.onStateChangeAction({
      experiencesList,
    });
  };
  handleExperienceRoleNameChange = (option, uniqueId) => {
    const experiencesList = this.props.experiencesList.map((item) => ({
      ...item,
      ProfileId:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? option.value
          : item.ProfileId,
      ProfileValue:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? ""
          : item.uniqueId === uniqueId
          ? option.label
          : item.ProfileValue,
      isRoleInvalid:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? item.isRoleInvalid
          : item.uniqueId === uniqueId
          ? false
          : item.isRoleInvalid,
    }));
    this.props.onStateChangeAction({
      experiencesList,
    });
  };
  handleExperienceFromYearChange = (option, uniqueId) => {
    if (isNullOrUndefined(option)) {
      return;
    }
    const experiencesList = this.props.experiencesList.map((item) => ({
      ...item,
      StartDate:
        item.uniqueId === uniqueId
          ? moment(item.StartDate, "MM/DD/YYYY").year(option.value)
          : item.StartDate,
    }));
    this.props.onStateChangeAction({
      experiencesList,
    });
  };
  handleExperienceFromMonthChange = (option, uniqueId) => {
    const experiencesList = this.props.experiencesList.map((item) => ({
      ...item,
      StartDate:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? moment(item.StartDate, "MM/DD/YYYY").month(option.value)
          : item.StartDate,
    }));
    this.props.onStateChangeAction({
      experiencesList,
    });
  };
  handleExperienceToYearChange = (option, uniqueId) => {
    if (isNullOrUndefined(option)) {
      return;
    }
    const experiencesList = this.props.experiencesList.map((item) => ({
      ...item,
      EndDate:
        item.uniqueId === uniqueId
          ? moment(item.EndDate, "MM/DD/YYYY").year(option.value)
          : item.EndDate,
    }));
    this.props.onStateChangeAction({
      experiencesList,
    });
  };
  handleExperienceToMonthChange = (option, uniqueId) => {
    const experiencesList = this.props.experiencesList.map((item) => ({
      ...item,
      EndDate:
        item.uniqueId === uniqueId && isNullOrUndefined(option)
          ? 0
          : item.uniqueId === uniqueId
          ? moment(item.EndDate, "MM/DD/YYYY").month(option.value)
          : item.EndDate,
    }));
    this.props.onStateChangeAction({
      experiencesList,
    });
  };
  handleExperienceAdd = () => {
    const { experiencesList } = this.props;
    const ExperienceItem = {
      CompanyWorked: "",
      StartDate: new Date(),
      EndDate: new Date(),
      ProfileId: 0,
      ProfileValue: "",
      IndustryId: 0,
      IndustryValue: "",
      uniqueId: uuidv1(),
      Description: "",
      isInvalid: false,
      isRoleInvalid: false,
      isIndustryInvalid: false,
      isWorking: false,
    };
    this.props.onStateChangeAction(
      {
        experiencesList: [ExperienceItem, ...experiencesList],
      },
      this.resumeChange
    );
  };
  handleExpierenceDelete = (deletedItem) => {
    const experiencesList = this.props.experiencesList.filter(
      (item) => deletedItem.uniqueId !== item.uniqueId
    );
    this.props.onStateChangeAction({ experiencesList }, this.resumeChange);
  };
  handleExperienceDescriptionChange = (uniqueId, content) => {
    const experiencesList = this.props.experiencesList.map((item) => ({
      ...item,
      Description: item.uniqueId === uniqueId ? content : item.Description,
    }));
    this.props.onStateChangeAction({
      experiencesList,
    });
  };
  handleExperienceIndustryBlur = (value, id) => {
    if (value === 0) {
      const experiencesList = this.props.experiencesList.map((item) => ({
        ...item,
        isIndustryInvalid: item.uniqueId === id ? true : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        experiencesList,
      });
    }
  };
  handleExperienceRoleBlur = (value, id) => {
    if (value === 0) {
      const experiencesList = this.props.experiencesList.map((item) => ({
        ...item,
        isRoleInvalid: item.uniqueId === id ? true : item.isInvalid,
      }));
      this.props.onStateChangeAction({
        experiencesList,
      });
    }
  };
  handleIsWorkingInputChange = (uniqueId) => {
    const experiencesList = this.props.experiencesList.map((item) => ({
      ...item,
      isWorking: item.uniqueId === uniqueId ? !item.isWorking : item.isWorking,
    }));
    this.props.onStateChangeAction({
      experiencesList,
    });
  };
  //Expierence Methods Ends
  handleOtherAcheivementAdd = () => {
    const { otherAchivenmentsList } = this.props;
    const languageItem = {
      AchivenmentId: 2,
      ResumeOtherAchivenmentId: 0,
      uniqueId: uuidv1(),
      Description: "",
      Title: "",
      Year: 2018,
      isInvalid: false,
    };
    this.props.onStateChangeAction(
      {
        otherAchivenmentsList: [languageItem, ...otherAchivenmentsList],
      },
      this.resumeChange
    );
  };
  //language methods ends
  handleResumeSave = () => {
    const {
      IsAvailable,
      AvailablityType,
      busyUntil,
      locationList,
      languageList,
      rolesList,
      keywordsList,
      otherAchivenmentsList,
      skillsList,
      certificationsList,
      educationsList,
      industriesList,
      experiencesList,
      resumes,
    } = this.props;
    let resume = {
      AvailablityType: AvailablityType,
      IsAvailable: IsAvailable,
      Regions: [],
      ResumeCandidateCertifications: [],
      ResumeCandidateEducations: [],
      ResumeCandidateIndustries: [],
      ResumeCandidateKeywords: [],
      ResumeCandidateLanguages: [],
      ResumeCandidateProfiles: [],
      ResumeCandidateSkills: [],
      ResumeCertifications: [],
      ResumeEducations: [],
      ResumeExperiences: [],
      ResumeId: resumes.ResumeId,
      ResumeIndustries: [],
      ResumeKeywords: [],
      ResumeLanguages: [{}],
      ResumeOtherAchivenment: [],
      ResumeProfiles: [],
      ResumeSkills: [],
    };
    this.props.onStateChangeAction({
      isLoading: true,
    });
    if (this.props.AvailablityType === 3) {
      resume = {
        ...resume,
        AvailabilityDate: moment(busyUntil),
      };
    }

    const locationListNew = this.props.locationList.map((item) => ({
      ...item,
      isInvalid: item.CountryId === 0 ? true : item.isInvalid,
    }));
    this.props.onStateChangeAction({
      locationList: locationListNew,
    });

    const languageListNew = this.props.languageList.map((item) => ({
      ...item,
      isInvalid:
        item.LanguageId === 0 && !item.isCandidate ? true : item.isInvalid,
    }));
    this.props.onStateChangeAction({
      languageList: languageListNew,
    });
    const roleListNew = this.props.rolesList.map((item) => ({
      ...item,
      isInvalid:
        item.ProfileId === 0 && !item.isCandidate ? true : item.isInvalid,
    }));
    this.props.onStateChangeAction({
      rolesList: roleListNew,
    });

    const skillListNew = this.props.skillsList.map((item) => ({
      ...item,
      isInvalid:
        item.SkillId === 0 && !item.isCandidate ? true : item.isInvalid,
    }));
    this.props.onStateChangeAction({
      skillsList: skillListNew,
    });

    const certificationListNew = this.props.certificationsList.map((item) => ({
      ...item,
      isInvalid:
        item.CertificationId === 0 && !item.isCandidate ? true : item.isInvalid,
    }));
    this.props.onStateChangeAction({
      certificationsList: certificationListNew,
    });
    const educationListNew = this.props.educationsList.map((item) => ({
      ...item,
      isInvalid:
        item.EducationId === 0 && !item.isCandidate ? true : item.isInvalid,
    }));
    this.props.onStateChangeAction({
      educationsList: educationListNew,
    });
    const keywordListNew = this.props.keywordsList.map((item) => ({
      ...item,
      isInvalid:
        item.KeywordId === 0 && !item.isCandidate ? true : item.isInvalid,
    }));
    this.props.onStateChangeAction({
      keywordsList: keywordListNew,
    });
    const industryListNew = this.props.industriesList.map((item) => ({
      ...item,
      isInvalid:
        item.IndustryId === 0 && !item.isCandidate ? true : item.isInvalid,
    }));
    this.props.onStateChangeAction({
      industriesList: industryListNew,
    });
    const experienceListNew = this.props.experiencesList.map((item) => ({
      ...item,
      isInvalid: item.CompanyWorked === "" ? true : item.isInvalid,
      isIndustryInvalid:
        item.IndustryId === 0 && !item.isCandidate
          ? true
          : item.isIndustryInvalid,
      isRoleInvalid:
        item.ProfileId === 0 && !item.isCandidate ? true : item.isRoleInvalid,
    }));
    this.props.onStateChangeAction({
      experiencesList: experienceListNew,
    });
    const otherAcheivementListNew = this.props.otherAchivenmentsList.map(
      (item) => ({
        ...item,
        isInvalid: item.Title === "" ? true : item.isInvalid,
      })
    );
    this.props.onStateChangeAction({
      otherAchivenmentsList: otherAcheivementListNew,
    });
    //Validation
    if (
      locationListNew.findIndex((x) => x.isInvalid) > -1 ||
      languageListNew.findIndex((x) => x.isInvalid) > -1 ||
      roleListNew.findIndex((x) => x.isInvalid) > -1 ||
      skillListNew.findIndex((x) => x.isInvalid) > -1 ||
      certificationListNew.findIndex((x) => x.isInvalid) > -1 ||
      educationListNew.findIndex((x) => x.isInvalid) > -1 ||
      industryListNew.findIndex((x) => x.isInvalid) > -1 ||
      keywordListNew.findIndex((x) => x.isInvalid) > -1 ||
      experienceListNew.findIndex(
        (x) => x.isRoleInvalid || x.isIndustryInvalid || x.isInvalid
      ) > -1 ||
      otherAcheivementListNew.findIndex((x) => x.isInvalid) > -1
    ) {
      const info = {
        message: this.props.labels.RESUME_EDIT_FIX_VALIDATION_MESSAGE,
        status: "error",
      };
      this.props.notificationAction(info);
      this.props.resumeChangeAction({
        isResumeChange: true,
        isLogoutTrigger: false,
      });
      this.props.onStateChangeAction({
        isLoading: false,
      });
      return;
    }
    //Validation Ends

    resume.Regions = map(locationList, (item) => ({
      CountryId: item.CountryId,
    }));

    //Language
    let filteredLanguages = filter(languageList, (item) => {
      if (!item.isCandidate && item.LanguageId !== 0) {
        const newItem = {
          ExperienceLevel: item.ExperienceLevel,
          LanguageId: item.LanguageId,
        };
        return newItem;
      }
    });
    resume.ResumeLanguages = map(filteredLanguages, (item) => ({
      ExperienceLevel: item.ExperienceLevel,
      LanguageId: item.LanguageId,
    }));
    filteredLanguages = filter(languageList, (item) => {
      if (item.isCandidate || item.LanguageId === 0) {
        const newItem = {
          ExperienceLevel: item.ExperienceLevel,
          LanguageId: item.LanguageId,
          LanguageValue: item.LanguageValue,
        };
        return newItem;
      }
    });
    {
      filteredLanguages &&
        (resume.ResumeCandidateLanguages = map(filteredLanguages, (item) => {
          const newItem = {
            ExperienceLevel: item.ExperienceLevel,
            ResumeCandidateLanguageId: "0",
            ResumeCandidateLanguageValue: item.LanguageValue,
          };
          return newItem;
        }));
    }
    //////////////
    let filteredProfiles = filter(rolesList, (item) => {
      if (!item.isCandidate && item.ProfileId !== 0) {
        const newItem = {
          ExperienceLevel: item.ExperienceLevel,
          ProfileId: item.ProfileId,
        };
        return newItem;
      }
    });
    resume.ResumeProfiles = map(filteredProfiles, (item) => ({
      ExperienceLevel: item.ExperienceLevel,
      ProfileId: item.ProfileId,
    }));
    filteredProfiles = filter(rolesList, (item) => {
      if (item.isCandidate || item.ProfileId === 0) {
        const newItem = {
          ExperienceLevel: item.ExperienceLevel,
          ProfileId: item.ProfileId,
          ProfileValue: item.ProfileValue,
        };
        return newItem;
      }
    });
    {
      filteredProfiles &&
        (resume.ResumeCandidateProfiles = map(filteredProfiles, (item) => {
          const newItem = {
            ExperienceLevel: item.ExperienceLevel,
            ResumeCandidateProfileId: "0",
            ResumeCandidateProfileName: item.ProfileValue,
          };
          return newItem;
        }));
    }
    //skills
    let filteredSkills = filter(skillsList, (item) => {
      if (!item.isCandidate && item.SkillId !== 0) {
        const newItem = {
          ExperienceLevel: item.ExperienceLevel,
          SkillId: item.SkillId,
        };
        return newItem;
      }
    });
    resume.ResumeSkills = map(filteredSkills, (item) => ({
      ExperienceLevel: item.ExperienceLevel,
      SkillId: item.SkillId,
    }));
    filteredSkills = filter(skillsList, (item) => {
      if (item.isCandidate || item.SkillId === 0) {
        const newItem = {
          ExperienceLevel: item.ExperienceLevel,
          SkillId: item.SkillId,
          SkillValue: item.SkillValue,
        };
        return newItem;
      }
    });
    {
      filteredSkills &&
        (resume.ResumeCandidateSkills = map(filteredSkills, (item) => {
          const newItem = {
            ExperienceLevel: item.ExperienceLevel,
            ResumeCandidateSkillId: "0",
            ResumeCandidateSkillName: item.SkillValue,
          };
          return newItem;
        }));
    }

    ////industry
    let filteredIndustries = filter(industriesList, (item) => {
      if (!item.isCandidate && item.IndustryId !== 0) {
        const newItem = {
          ExperienceLevel: item.ExperienceLevel,
          IndustryId: item.IndustryId,
        };
        return newItem;
      }
    });
    resume.ResumeIndustries = map(filteredIndustries, (item) => ({
      ExperienceLevel: item.ExperienceLevel,
      IndustryId: item.IndustryId,
    }));
    filteredIndustries = filter(industriesList, (item) => {
      if (item.isCandidate || item.IndustryId === 0) {
        const newItem = {
          ExperienceLevel: item.ExperienceLevel,
          IndustryId: item.IndustryId,
          IndustryValue: item.IndustryValue,
        };
        return newItem;
      }
    });
    {
      filteredIndustries &&
        (resume.ResumeCandidateIndustries = map(filteredIndustries, (item) => {
          const newItem = {
            ExperienceLevel: item.ExperienceLevel,
            ResumeCandidateIndustryId: "0",
            ResumeCandidateIndustryValue: item.IndustryValue,
          };
          return newItem;
        }));
    }
    //
    ////certification
    let filteredCertifications = filter(certificationsList, (item) => {
      if (!item.isCandidate && item.IndustryId !== 0) {
        const newItem = {
          CertificationDate: moment(
            item.CertificationDate,
            "MM/DD/YYYY"
          ).format("YYYY-MM-DDTHH:mm:ss"),
          CertificationId: item.CertificationId,
        };
        return newItem;
      }
    });
    resume.ResumeCertifications = map(filteredCertifications, (item) => ({
      CertificationDate: moment(item.CertificationDate, "MM/DD/YYYY").format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      CertificationId: item.CertificationId,
    }));
    filteredCertifications = filter(certificationsList, (item) => {
      if (item.isCandidate || item.IndustryId === 0) {
        const newItem = {
          CertificationDate: item.CertificationDate,
          CertificationId: item.CertificationId,
          CertificationValue: item.CertificationValue,
        };
        return newItem;
      }
    });
    {
      filteredCertifications &&
        (resume.ResumeCandidateCertifications = map(
          filteredCertifications,
          (item) => {
            const newItem = {
              CertificationDate: item.CertificationDate,
              ResumeCertificationId: "0",
              ResumeCandidateCertificationValue: item.CertificationValue,
            };
            return newItem;
          }
        ));
    }

    //Education
    let filteredEducations = filter(educationsList, (item) => {
      if (!item.isCandidate && item.EducationId !== 0) {
        const newItem = {
          EducationId: item.EducationId,
          EducationTypeId: item.EducationTypeId,
          EducationYear: Number(item.EducationYear),
        };
        return newItem;
      }
    });
    resume.ResumeEducations = map(filteredEducations, (item) => ({
      EducationTypeId: item.EducationTypeId,
      EducationYear: Number(item.EducationYear),
      EducationId: item.EducationId,
    }));
    filteredEducations = filter(educationsList, (item) => {
      if (item.isCandidate || item.EducationId === 0) {
        const newItem = {
          ExperienceLevel: item.ExperienceLevel,
          EducationId: item.EducationId,
          EducationValue: item.EducationValue,
        };
        return newItem;
      }
    });
    {
      filteredEducations &&
        (resume.ResumeCandidateEducations = map(filteredEducations, (item) => {
          const newItem = {
            ExperienceLevel: item.ExperienceLevel,
            ResumeCandidateEducationId: "0",
            ResumeCandidateEducationValue: item.EducationValue,
            EducationTypeId: item.EducationTypeId,
            EducationYear: Number(item.EducationYear),
          };
          return newItem;
        }));
    }
    ////
    let filteredKeywords = filter(keywordsList, (item) => {
      if (!item.isCandidate && item.KeywordId !== 0) {
        const newItem = {
          KeywordId: item.KeywordId,
        };
        return newItem;
      }
    });
    resume.ResumeKeywords = map(filteredKeywords, (item) => ({
      KeywordId: item.KeywordId,
    }));
    filteredKeywords = filter(keywordsList, (item) => {
      if (item.isCandidate || item.KeywordId === 0) {
        const newItem = {
          KeywordId: item.KeywordId,
          KeywordValue: item.KeywordValue,
        };
        return newItem;
      }
    });
    {
      filteredKeywords &&
        (resume.ResumeCandidateKeywords = map(filteredKeywords, (item) => {
          const newItem = {
            ResumeCandidateKeywordId: "0",
            ResumeCandidateKeywordValue: item.KeywordValue,
          };
          return newItem;
        }));
    }

    resume.ResumeExperiences = map(experiencesList, (item) => ({
      CompanyWorked: item.CompanyWorked,
      Description: item.Description,
      EndDate: item.isWorking ? null : item.EndDate,
      IndustryId: item.IndustryId,
      ProfileId: item.ProfileId,
      ResumeExperienceId: item.ResumeExperienceId || "0",
      ResumeId: resumes.ResumeId,
      StartDate: item.StartDate,
    }));
    resume.ResumeOtherAchivenment = map(otherAchivenmentsList, (item) => ({
      AchivenmentId: item.AchivenmentId,
      Description: item.Description,
      ResumeId: resume.ResumeId,
      ResumeOtherAchivenmentId: "" + item.ResumeOtherAchivenmentId || "0",
      Title: item.Title,
      Year: Number(item.Year),
    }));
    saveUpdatedResumeApi({ resume }).then((response) => {
      if (response.success) {
        if (this.props.isLogoutTrigger || this.props.isRoleSwitchTrigger) {
          this.props.resumeChangeAction({
            isResumeChange: false,
            message: "",
            isLogout: this.props.isLogoutTrigger,
            isRoleSwitchToggle: this.props.isRoleSwitchTrigger,
          });
          return;
        }
        this.props.resumeChangeAction({ isResumeChange: false });
        this.props.onStateChangeAction({
          isLoading: false,
        });
        const info = {
          message: this.props.labels[response.message],
          status: "success",
          className: `${
            response.message == "resumeFirstEditSuccessMessage"
              ? "first-resume-save"
              : "resume-save"
          }`,
        };
        this.props.notificationAction(info);

        if (this.isLeaving) {
          this.props.history.push(this.pathname);
          return;
        }
        this.isSaved = true;
        this.props.history.push(privateRoutes.resume.path);
        return;
      }
      this.props.onStateChangeAction({
        isLoading: false,
      });
    });
  };
  handleAvailiblitybtnClick = () => {
    this.props.onStateChangeAction(
      {
        IsAvailable: true,
        AvailablityType: 1,
      },
      this.resumeChange
    );
  };
  handleMonthNoticebtnClick = () => {
    this.props.onStateChangeAction(
      {
        IsAvailable: false,
        AvailablityType: 2,
      },
      this.resumeChange
    );
  };

  handleDateChange = (d) => {
    const dateLimit = moment(d, "YYYY-MM-DD");
    const now = moment();
    if (!now.isSameOrBefore(dateLimit)) {
      return;
    }
    this.props.onStateChangeAction(
      {
        busyUntil: d,
        IsAvailable: false,
        AvailablityType: 3,
      },
      this.resumeChange
    );
  };

  resumeChange = () => {
    
    if (this.isThereIsAnyUnSavedChange()) {
      this.props.resumeChangeAction({ isResumeChange: true });
      return;
    }
    this.props.resumeChangeAction({ isResumeChange: false });
  };
  render() {
    const { labels, isHelpActive } = this.props;
    const {
      dialogMessage,
      isLoading,
      AvailablityType,
      busyUntil,
      languageList,
      rolesList,
      roleDefaultOptions,
      keywordsList,
      otherAchivenmentsList,
      skillsList,
      certificationsList,
      educationsList,
      industriesList,
      experiencesList,
      suggestedRoles,
      suggestObject,
      suggestedCertifications,
      suggestedSkills,
      skillDefaultOptions,
      certificationDefaultOptions,
      educationDefaultOptions,
      keywordDefaultOptions,
      IndustryDefaultOptions,
      locationList,
    } = this.props;

    return (
      <PageWrapper className="resume-edit-component">
        {isLoading && <LoadingMask text="" />}
        {dialogMessage && (
          <ConfirmDialog className="info-dialog">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="resume-edit-save-no"
                onClick={this.handleNoClick}
              >
                No
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="resume-edit-save-yes"
                onClick={this.handleYesClick}
              >
                Yes
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column className="col-1">
          <Column.Head>
            <div className="heading">{labels.RESUME_EDIT_TITLE}</div>
            <Button
              className="SaveCloudBtn"
              onClick={this.handleResumeSave}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              tooltipButton={labels.RESUME_EDIT_SAVE_BUTTON_TOOLTIP}
              tooltipHelp={labels.RESUME_EDIT_SAVE_BUTTON_HELP}
              testId="resume-edit-save-btn"
              testIdHelp="resume-edit-save-help-btn"
            />
          </Column.Head>

          <Column.Body>
            <JobAvailabilityBar>
              <Button
                mainClassName="button-flex"
                className={`availibility-btn ${
                  AvailablityType === 1 ? "active" : ""
                }`}
                onClick={this.handleAvailiblitybtnClick}
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={labels.RESUME_EDIT_IS_AVAILABLE_BUTTON_TOOLTIP}
                tooltipHelp={labels.RESUME_EDIT_IS_AVAILABLE_BUTTON_HELP}
                testId="resume-edit-isAvailable-btn"
                testIdHelp="resume-edit-isAvailable-help-btn"
              >
                {labels.RESUME_EDIT_IS_AVAILABLE_BUTTON_LABEL}
              </Button>
              <Button
                mainClassName="button-flex"
                className={`notice-btn ${
                  AvailablityType === 2 ? "active" : ""
                }`}
                onClick={this.handleMonthNoticebtnClick}
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={labels.RESUME_EDIT_ONE_MONTH_BUTTON_TOOLTIP}
                tooltipHelp={labels.RESUME_EDIT_ONE_MONTH_BUTTON_HELP}
                testId="resume-edit-oneMonth-btn"
                testIdHelp="resume-edit-oneMonth-help-btn"
              >
                {labels.RESUME_EDIT_ONE_MONTH_BUTTON_LABEL}
              </Button>
              <Button
                mainClassName="button-flex"
                className={`date-btn ${AvailablityType === 3 ? "active" : ""}`}
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={labels.RESUME_EDIT_BUSY_UNTIL_BUTTON_TOOLTIP}
                tooltipHelp={labels.RESUME_EDIT_BUSY_UNTIL_BUTTON_HELP}
                testId="resume-edit-busyUntil-btn"
                testIdHelp="resume-edit-busyUntil-help-btn"
              >
                <label className="date-label">
                  {labels.RESUME_EDIT_BUSY_UNTIL_BUTTON_LABEL}:
                </label>
                <label>
                  <DatePicker
                    name="DatePicker"
                    todayButton="Today"
                    className={`${AvailablityType === 3 ? "active" : ""}`}
                    selected={moment(busyUntil) || ""}
                    dateFormat="DD-MM-YYYY"
                    placeholderText="DD-MM-YYYY"
                    onChange={this.handleDateChange}
                    minDate={moment().add(1, "d")}
                  />
                </label>
              </Button>
            </JobAvailabilityBar>
            <Grid>
              <Grid.Row>
                <Grid.Column columns={2}>
                  <LoactionPanel
                    isHelpActive={isHelpActive}
                    labels={labels}
                    locationList={locationList}
                    onLocationChange={this.handleLocationChange}
                    onLocationBlur={this.handleLocationBlur}
                    onLocationDelete={this.handleLocationDelete}
                    onLocationAdd={this.handleLocationAdd}
                  />
                </Grid.Column>
                <Grid.Column>
                  <LanguagePanel
                    labels={labels}
                    isHelpActive={isHelpActive}
                    languageList={languageList}
                    onLanguageNameChange={this.handleLanguageNameChange}
                    onLanguageLevelChange={this.handleLanguageLevelChange}
                    onLanguageDelete={this.handleLanguageDelete}
                    onLanguageAdd={this.handleLanguageAdd}
                    onLanguageBlur={this.handleLanguageBlur}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Role
                    isHelpActive={isHelpActive}
                    labels={labels}
                    rolesList={rolesList}
                    onRoleNameChange={this.handleRoleNameChange}
                    onRoleLevelChange={this.handleRoleLevelChange}
                    onRoleDelete={this.handleRoleDelete}
                    onRoleAdd={this.handleRoleAdd}
                    onRoleBlur={this.handleRoleBlur}
                    onRoleSuggestionButtonClick={this.handleRoleSuggestions}
                    suggestedRoles={suggestedRoles}
                    onSuggesstedRoleAdd={this.handleSuggestedRoleAdd}
                    suggestObject={suggestObject}
                    roleDefaultOptions={roleDefaultOptions}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Skill
                    isHelpActive={isHelpActive}
                    labels={labels}
                    skillsList={skillsList}
                    onSkillNameChange={this.handleSkillNameChange}
                    onSkillLevelChange={this.handleSkillLevelChange}
                    onSkillDelete={this.handleSkillDelete}
                    onSkillAdd={this.handleSkillAdd}
                    onSkillBlur={this.handleSkillBlur}
                    suggestedSkills={suggestedSkills}
                    onSkillSuggestionButtonClick={this.handleSkillSuggestions}
                    onSuggesstedSkillAdd={this.handleSuggestedSkillAdd}
                    suggestObject={suggestObject}
                    skillDefaultOptions={skillDefaultOptions}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Certification
                    isHelpActive={isHelpActive}
                    labels={labels}
                    certificationsList={certificationsList}
                    onCertificationNameChange={
                      this.handleCertificationNameChange
                    }
                    onCertificationLevelChange={
                      this.handleCertificationLevelChange
                    }
                    onCertificationDelete={this.handleCertificationDelete}
                    onCertificationAdd={this.handleCertificationAdd}
                    onCertificationBlur={this.handleCertificationBlur}
                    onCertificationSuggestionButtonClick={
                      this.handleCertificationSuggestions
                    }
                    suggestObject={suggestObject}
                    suggestedCertifications={suggestedCertifications}
                    onSuggesstedCertificationAdd={
                      this.handleSuggestedCertificationAdd
                    }
                    certificationDefaultOptions={certificationDefaultOptions}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Education
                    isHelpActive={isHelpActive}
                    labels={labels}
                    educationsList={educationsList}
                    onEducationNameChange={this.handleEducationNameChange}
                    onEducationLevelChange={this.handleEducationLevelChange}
                    onEducationYearChange={this.handleEducationYearChange}
                    onEducationDelete={this.handleEducationDelete}
                    onEducationAdd={this.handleEducationAdd}
                    onEducationBlur={this.handleEducationBlur}
                    educationDefaultOptions={educationDefaultOptions}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Keyword
                    isHelpActive={isHelpActive}
                    labels={labels}
                    keywordsList={keywordsList}
                    onKeywordNameChange={this.handleKeywordNameChange}
                    onKeywordDelete={this.handleKeywordDelete}
                    onKeywordAdd={this.handleKeywordAdd}
                    onKeywordBlur={this.handleKeywordBlur}
                    keywordDefaultOptions={keywordDefaultOptions}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Industry
                    isHelpActive={isHelpActive}
                    labels={labels}
                    industriesList={industriesList}
                    onIndustryNameChange={this.handleIndustryNameChange}
                    onIndustryLevelChange={this.handleIndustryLevelChange}
                    onIndustryDelete={this.handleIndustryDelete}
                    onIndustryAdd={this.handleIndustryAdd}
                    onIndustryBlur={this.handleIndustryBlur}
                    industryDefaultOptions={IndustryDefaultOptions}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <ProfessionalExperience
                    isHelpActive={isHelpActive}
                    labels={labels}
                    experiencesList={experiencesList}
                    onExpierenceCompanyNameChange={
                      this.handleExperienceCompanyNameChange
                    }
                    onExpierenceIndustryNameChange={
                      this.handleExperienceIndustryNameChange
                    }
                    onExpierenceRoleNameChange={
                      this.handleExperienceRoleNameChange
                    }
                    onExpierenceDescriptionChange={
                      this.handleExperienceDescriptionChange
                    }
                    onExpierenceFromMonthChange={
                      this.handleExperienceFromMonthChange
                    }
                    onExpierenceFromYearChange={
                      this.handleExperienceFromYearChange
                    }
                    onExpierenceToMonthChange={
                      this.handleExperienceToMonthChange
                    }
                    onExpierenceToYearChange={this.handleExperienceToYearChange}
                    onExperienceDelete={this.handleExpierenceDelete}
                    onExperienceAdd={this.handleExperienceAdd}
                    onExpierenceIndustryBlur={this.handleExperienceIndustryBlur}
                    onExperienceRoleBlur={this.handleExperienceRoleBlur}
                    industryDefaultOptions={IndustryDefaultOptions}
                    roleDefaultOptions={roleDefaultOptions}
                    onIsWorkingInputChange={this.handleIsWorkingInputChange}
                  />
                </Grid.Column>
                <Grid.Column columns={2}>
                  <OtherAcheivement
                    isHelpActive={isHelpActive}
                    labels={labels}
                    otherAchivenmentsList={otherAchivenmentsList}
                    onOtherAcheivementNameChange={
                      this.handleOtherAcheivementNameChange
                    }
                    onOtherAcheivementYearChange={
                      this.handleOtherAcheivementYearChange
                    }
                    onOtherAcheivementTypeChange={
                      this.handleOtherAcheivementTypeChange
                    }
                    onOtherAcheivementDescriptionChange={
                      this.handleOtherAcheivementDescriptionChange
                    }
                    onOtherAcheivementDelete={this.handleOtherAcheivementDelete}
                    onOtherAcheivementAdd={this.handleOtherAcheivementAdd}
                    // onOtherAcheivementBlur={this.handleOtherAcheivementBlur}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Column.Body>
        </Column>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  navigation,
  resumeEdit,
  resume: { message, isLogoutTrigger, isRoleSwitchTrigger },
}) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return {
    labels,
    isHelpActive,
    message,
    isLogoutTrigger,
    isRoleSwitchTrigger,
    ...resumeEdit,
  };
};
export default connect(mapStateToProps, {
  notificationAction,
  resumeChangeAction,
  onStateChangeAction,
})(withRouter(ResumeEdit));
