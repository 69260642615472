import React, { Component } from "react";
import { RichTextEditor, DragImage } from "../../../components";
import { connect } from "react-redux";
import "./createfeedback.scss";

class CreateFeedback extends Component {
  render() {
    const {
      feedbackForm,
      handleContentChange,
      handleTitleChange,
      getImgSrc,
      labels
    } = this.props;
    const {
      title,
      content,
      imgSrc,
      isInvalidTitle,
      isInvalidContent
    } = feedbackForm;
    return (
      <div className="create-feedback">
        <div className="form-row">
          <label className="form-label">{labels.TITLE_LABEL}</label>
          <input
            type="text"
            id="txtTitle"
            className={`input-text ${isInvalidTitle ? "inValid" : ""}`}
            value={title ? title : ""}
            placeholder={labels.searcherFeedbackTitle}
            onChange={e => handleTitleChange(e.target.value)}
          />
        </div>

        <RichTextEditor
          id="richtxtFeedbackContent"
          value={content}
          isInvalidValue={isInvalidContent}
          handleChange={handleContentChange}
        />
        <DragImage getImgSrc={getImgSrc} imgSrc={imgSrc} labels={labels} />
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(CreateFeedback);
