import React, { Component } from "react";
import "./presentation.scss";
import ImgSrc from "../../../assets/images/company.svg";

function getBtnClass(url) {
  if (url.indexOf("facebook") > 0) return "facebook";
  else if (url.indexOf("twitter") > 0) return "twitter";
  else if (url.indexOf("google") > 0) return "googleplus";
  else if (url.indexOf("instagram") > 0) return "instagram";
  else if (url.indexOf("linkedin") > 0) return "linkedin";
  else if (url.indexOf("pinterest") > 0) return "pinterest";
  else if (url.indexOf("driblle") > 0) return "driblle";
  else if (url.indexOf("behance") > 0) return "behance";
  else return "";
}
class Presentations extends Component {
  render() {
    const { selectedUser, labels } = this.props;
    return (
      <div
        className="user-detail-component"
        test-data-id="collaboation-presentation-detail"
      >
        <div className="MsgWrapper">
          <div className="MsgSubject">
            <div className="presentaion-image-wrap">
              <img
                src={(selectedUser && selectedUser.Logo) || ImgSrc}
                className={
                  selectedUser && selectedUser.Logo
                    ? "roundImg"
                    : "emptyRoundImag"
                }
              />
            </div>
            <label className="presentaion-name">
              {selectedUser && selectedUser.Title}
            </label>
          </div>
          <div className="msgItemDiv">
            {selectedUser && selectedUser.UserName && (
              <div className="msgItem">
                <label className="labelName">
                  {"Name"}:
                </label>
                <label test-data-id={`collabs-present-name`} className="answerLabel">{selectedUser.UserName}</label>
              </div>
            )}
            {selectedUser.UserProfile && selectedUser.UserProfile && (
              <div className="msgItem">
                <label className="labelName">
                  {labels.SearcherSentCollaborationPositionLabel}:
                </label>
                <label className="answerLabel">
                  {selectedUser.UserProfile}
                </label>
              </div>
            )}
            {selectedUser.UserEmail && selectedUser.UserEmail.UserEmailValue && (
              <div className="msgItem">
                <label className="labelName">
                  {labels.SearcherSentCollaborationEmailLabel}:
                </label>
                <label className="answerLabel">
                  {selectedUser.UserEmail.UserEmailValue}
                </label>
              </div>
            )}
            {selectedUser && selectedUser.UserPhonenumbers && (
              <div className="msgItem">
                <label className="labelName">
                  {labels.SearcherSentCollaborationPhoneLabel}:
                </label>
                <label className="answerLabel">
                  {selectedUser.UserPhonenumbers.UserPhonenumberValue}
                </label>
              </div>
            )}
            {selectedUser && selectedUser.WebUrl && (
              <div className="msgItem">
                <label className="labelName">
                  {"Web URL"}:
                </label>
                <label className="answerLabel">
                  <a target="_blank" href={selectedUser.WebUrl}>
                    {selectedUser.WebUrl}
                  </a>
                </label>
              </div>
            )}
            {selectedUser && selectedUser.Detail && (
              <div className="msgItem">
                <label className="labelName">
                  {"Detail"}:
                </label>
                <label className="answerLabel">{selectedUser.Detail}</label>
              </div>
            )}
            <div className="socialCnt">
              {selectedUser.UserDetailSocialLink &&
                selectedUser.UserDetailSocialLink.map((item, index) => (
                  <button
                    key={index}
                    className={`socialBtn ${getBtnClass(item.URL)}Btn ${
                      item.Id ? "activeBtn" : ""
                    }`}
                    onClick={() => {
                      window.open(item.URL, "_blank");
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Presentations;
