import React from "react";
import "./default-button.scss";

const DefaultButton = ({
  type = "button",
  className,
  children,
  onClick,
  testId,
  ...rest
}) => {
  return (
    <button
      type={type}
      className={`default-button ${className}`}
      onClick={onClick}
      test-data-id={testId}
      {...rest}
    >
      {children}
    </button>
  );
};

DefaultButton.defaultProps = {
  className: ""
};

export default DefaultButton;
