import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  listCollapsed: false,
  formCollapsed: false,
  users: [],
  filterUsers: [],
  isFetching: false,
  messageForm: {
    title: "",
    content: ""
  },
  isSearchFocus: false,
  searchListHover: false,
  selectedUsers: [],
  createMessageCollapsed: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.MESSAGE_NEW.LIST_COLLAPSED:
      return {
        ...state,
        listCollapsed: action.payload
      };
    case ActionTypes.MESSAGE_NEW.FORM_COLLAPSED:
      return {
        ...state,
        formCollapsed: action.payload
      };
    case ActionTypes.MESSAGE_NEW.USERS:
      return {
        ...state,
        users: action.payload
      };
    case ActionTypes.MESSAGE_NEW.MESSAGE_FORM:
      return {
        ...state,
        messageForm: action.payload
      };
    case ActionTypes.MESSAGE_NEW.IS_SEARCH_FOCUS:
      return {
        ...state,
        isSearchFocus: action.payload
      };
    case ActionTypes.MESSAGE_NEW.SELECTED_USERS:
      return {
        ...state,
        selectedUsers: action.payload
      };
    case ActionTypes.MESSAGE_NEW.IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case ActionTypes.MESSAGE_NEW.FILTER_USERS:
      return {
        ...state,
        filterUsers: action.payload
      };
    case ActionTypes.MESSAGE_NEW.CREATE_MESSAGE_COLLAPSED:
      return {
        ...state,
        createMessageCollapsed: action.payload
      };
    default:
      return state;
  }
};
