import React, { Component } from "react";
import { connect } from "react-redux";
import { Messages } from "../../../utilities";
import {
  LoadingMask,
  Input,
  Button,
  Panel,
  ListGroup,
  EmptyInfo,
} from "../../../common";
import { map, filter } from "lodash";
import "./socialMedialLinks.scss";
class SocialMediaLinks extends Component {
  componentDidMount = () => {};

  render() {
    const {
      SocialLinks,
      onSocialMediaChange,
      onSaveUserSetting,
      onSocialMediaActive,
      isFetching,
      labels,
    } = this.props;
    console.log(SocialLinks);
    return (
      <div className={`userSettings`}>
        {isFetching && <LoadingMask />}
        <div className="social-media-wrap">
          <div className="socialCnt">
            {SocialLinks &&
              SocialLinks.map((item, index) => (
                <button
                  key={index}
                  onClick={() => onSocialMediaActive(item)}
                  className={`socialBtn ${
                    item.Name ? item.Name : "hidden "
                  }Btn ${item.isActive ? "activeBtn" : ""}`}
                  test-data-id={"input-button-present-sm" + item.Name}
                />
              ))}
          </div>
          {SocialLinks &&
            SocialLinks.filter((item) => item.isActive).map((item, index) => {
              return (
                <>
                  <Input
                    key={index}
                    name={item.Name}
                    className="input-text"
                    testId={"input-text-present-sm" + item.Name}
                    value={item.URL ? item.URL : ""}
                    placeholder={Messages.present.placeholder[item.Name]}
                    onChange={(e) => onSocialMediaChange(item, e.target.value)}
                    // onBlur={(e) => onSaveUserSetting()}
                  />
                  <Button
                    className="SendButton"
                    onClick={onSaveUserSetting}
                    testId="btnsettingupdate"
                    tooltipHelp="Update user settings."
                    tooltipButton="Update user settings."
                  >
                    {"Update"}
                  </Button>
                </>
              );
            })}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(SocialMediaLinks);
