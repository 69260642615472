import React, { Component } from "react";
import { PageWrapper } from "../../components";
import "./saved-search.scss";
import Search from "../Search/Search";
import {
  getSavedSearchesApi,
  getSaveSearchDetailApi,
  deleteSavedSearchApi
} from "../Search/searchApi";
import toLower from "lodash/toLower";
import filter from "lodash/filter";
import includes from "lodash/includes";
import { notificationAction } from "./../../actions";
import { connect } from "react-redux";
import { onStateChangeAction } from "./savedSearchAction";

class SavedSearch extends Component {
  state = {
    //For Save Search
    isSearchFocus: false,
    searchListHover: false
  };
  componentDidMount() {
    this.getSavedSearches();
  }
  componentDidUpdate(prevProps,prevState){
    if(prevProps.onBoardSavedSearchId != this.props.onBoardSavedSearchId)
      if(this.props.onBoardSavedSearchId)
      {
        this.getSavedSearches();
      }
        
  }
  //for SaveSearch
  handleShortlistSearchChange = e => {
    const { value } = e.target;
    const { savedSearches } = this.props;
    const filterSavedSearch = filter(savedSearches, shortlist =>
      includes(toLower(shortlist.SearchName), toLower(value))
    );
    this.props.onStateChangeAction({
      filterSavedSearch,
      selectedSavedSearchName: value
    });
  };
  handleSearchBlur = () => {
    const { searchListHover } = this.state;
    if (searchListHover) return;
    this.setState({ isSearchFocus: false });
  };
  handleMouseEnter = () => {
    this.setState({ searchListHover: true });
  };
  handleMouseLeave = () => {
    this.setState({ searchListHover: false });
  };
  getSavedSearches = () => {
    getSavedSearchesApi()
      .then(response => {
        if (response.success) {
          this.props.onStateChangeAction({
            savedSearches: response.items,
            filterSavedSearch: response.items
          });
          const{
            onBoardSavedSearchId
          } = this.props;
          if(onBoardSavedSearchId){
            this.props.onStateChangeAction({
              savedSearches: response.items,
              filterSavedSearch: response.items.filter(a=>a.SavedSearchId == onBoardSavedSearchId)
            });
            this.handleSaveSearchSelect({selectedSavedSearch:response.items.find(a=>a.SavedSearchId == onBoardSavedSearchId)})
          }
          // this.handleSearchBlur();
        }
      })
      .catch(response => response);
  };
  handleSavedSearchUpdate=(items)=>{
    this.props.onStateChangeAction({
      savedSearches: items,
      filterSavedSearch: items
    });
  }
  handleSaveSearchSelect = ({ selectedSavedSearch }) => {
    this.setState({ isSearchFocus: false });
    getSaveSearchDetailApi(selectedSavedSearch.SavedSearchId)
      .then(response => {
        let newSetting = {
          roles: [],
          skills: [],
          keywords: [],
          certifications: [],
          industries: [],
          countries: [],
          languages: []
        };
        if (response.success) {
          const savedSearchSettings = response.items.SavedSearchSettings.reduce(
            function(filtered, option) {
              switch (option.LookupTypeId) {
                case 1: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    ProfileId: option.SearchedPK,
                    ProfileValue: option.SearchedValue
                  };
                  newSetting.roles.push(someNewValue);
                  break;
                }
                case 2: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    SkillId: option.SearchedPK,
                    SkillValue: option.SearchedValue
                  };
                  newSetting.skills.push(someNewValue);
                  break;
                }
                case 3: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    KeywordId: option.SearchedPK,
                    KeywordValue: option.SearchedValue
                  };
                  newSetting.keywords.push(someNewValue);
                  break;
                }
                case 4: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    IndustryId: option.SearchedPK,
                    IndustryValue: option.SearchedValue
                  };
                  newSetting.industries.push(someNewValue);
                  break;
                }
                case 5: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    CertificationId: option.SearchedPK,
                    CertificationValue: option.SearchedValue
                  };
                  newSetting.certifications.push(someNewValue);
                  break;
                }
                case 6: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    LanguageId: option.SearchedPK,
                    LanguageValue: option.SearchedValue
                  };
                  newSetting.languages.push(someNewValue);
                  break;
                }
                case 7: {
                  const someNewValue = {
                    ExperienceLevel: option.ExperienceLevel,
                    CountryId: option.SearchedPK,
                    CountryName: option.SearchedValue
                  };
                  newSetting.countries.push(someNewValue);
                  break;
                }
              }
              filtered = newSetting;
              return filtered;
            },
            []
          );
          this.props.onStateChangeAction({
            savedSearchSettings: savedSearchSettings.length
              ? savedSearchSettings
              : newSetting,
            selectedSavedSearchName: selectedSavedSearch.SearchName
          });
        }
      })
      .catch(res => console.log("Error ", res));
  };
  handleSearchFocus = () => {
    this.setState({ isSearchFocus: true });
  };
  handleSaveSearchDelete = id => {
    deleteSavedSearchApi({ id: id })
      .then(response => {
        if (response.success) {
          const info = {
            message: this.props.labels.SAVED_SEARCH_DELETE_SUCCESS_MESSAGE,
            status: "success"
          };
          this.props.notificationAction(info);
          this.getSavedSearches();
        } else {
          const info = {
            message: response.message,
            status: "error"
          };
          this.props.notificationAction(info);
        }
        this.setState({
          searchListHover: false
        });
      })
      .catch(error => console.log("Deleted ", error));
  };
  render() {
    const {
      savedSearchSettings,
      selectedSavedSearchName,
      filterSavedSearch
    } = this.props;
    const { isSearchFocus } = this.state;
    return (
      <Search
        handleShortlistSearchChange={this.handleShortlistSearchChange}
        handleSearchBlur={this.handleSearchBlur}
        handleSaveSearchSelect={this.handleSaveSearchSelect}
        savedSearches
        isSaveSearch
        isSearchFocus={isSearchFocus}
        filterSavedSearch={filterSavedSearch}
        handleSearchFocus={this.handleSearchFocus}
        handleMouseEnter={this.handleMouseEnter}
        handleMouseLeave={this.handleMouseLeave}
        savedSearchSettings={savedSearchSettings}
        newSavedSearchSave={this.getSavedSearches}
        handleSaveSearchDelete={this.handleSaveSearchDelete}
        selectedSavedSearchName={selectedSavedSearchName}
        handleSavedSearchUpdate={this.handleSavedSearchUpdate}
      />
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, savedSearchRed }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, ...savedSearchRed };
};

export default connect(
  mapStateToProps,
  { notificationAction, onStateChangeAction }
)(SavedSearch);
