import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  resumeList: [],
  savedSearch: null,
  filterSaveSearch:null,
  selected: {
    CertificationIds: [],
    Countries: [],
    IndusteryIds: [],
    KeywordIds: [],
    Languages: [],
    ProfileIds: [],
    SkillIds: [],
    checked: [],
    Type: "Only20",
    limit: 20,
    page: 1,
  },
  defaultList: {
    CertificationIds: [],
    Countries: [],
    IndusteryIds: [],
    KeywordIds: [],
    Languages: [],
    ProfileIds: [],
    SkillIds: [],
  },
  collapse: {
    role: true,
    location: true,
    language: false,
    skill: false,
    keyword: false,
    industry: false,
    certification: false,
    date: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.NEWSAVEDSEARCH.RESUME_LIST_SAVED_SEARCH:
      return {
        ...state,
        resumeList: action.payload,
      };
    case ActionTypes.NEWSAVEDSEARCH.SELECTED_RESUME_SAVED_SEARCH:
      return { ...state, selected: action.payload };
    case ActionTypes.NEWSAVEDSEARCH.SEARCH_COLLAPSED_SAVED_SEARCH:
      return { ...state, collapse: action.payload };
    case ActionTypes.NEWSAVEDSEARCH.DEFAULT_LIST_SAVED_SEARCH:
      return { ...state, defaultList: action.payload };
    case ActionTypes.NEWSAVEDSEARCH.SAVED_SEARCH_SAVED_SEARCH:
      return { ...state, savedSearch: action.payload };
      case ActionTypes.NEWSAVEDSEARCH.FILTER_SELECTED_SAVE_SEARCH:
        return { ...state, filterSaveSearch: action.payload };
    default:
      return state;
  }
};
