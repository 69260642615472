import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./login-form.scss";
import { loginAuth } from "./loginAction";
import ReactHtmlParser from "react-html-parser";
import { notificationAction } from "../../../../actions";
import { FacebookProvider, LoginButton } from "react-facebook";
import { LinkedIn } from "react-linkedin-login-oauth2";
import GoogleSignup from "./GoogleSignup";

class LoginForm extends Component {
  state = {
    isGoogleSignInActive: false,
  };
  handleHideMainForm = (value, email) => {
    this.setState({
      isGoogleSignInActive: value,
    });
    if(email) this.props.setEmail(email);
  };
  render() {
    const {
      email,
      password,
      invalidemail,
      invalidpassword,
      isLoading = false,
      handleFormFieldChange,
      handleKeyPress,
      handleSubmit,
      facebookClientId,
      handleFacebookLoginSuccess,
      handleFacebookFailure,
      handleFailure,
      handleSuccess,
      linkedInUrl,
      onClose,
      labels,
      onForgotPassword,
      onSignup,
      isLoginWithFacebook,
      isLoginWithLinkedIn,
      showResendPopUp,
    } = this.props;
    return (
      <div className="login-page">
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        <div
          className={`LoginForm RegisterForm ${
            this.state.isGoogleSignInActive ? "small" : ""
          }`}
        >
          <form>
            <div className="login">
            <button
                    type="button"
                    className="close-btn"
                    onClick={onClose}
                  />
              {!this.state.isGoogleSignInActive && (
                <React.Fragment>
                  <input
                    id="Email"
                    name="email"
                    type="text"
                    placeholder={labels.loginEmail}
                    className={`LoginInput ${invalidemail ? "inValid" : ""}`}
                    value={email}
                    onChange={handleFormFieldChange}
                    onKeyPress={handleKeyPress}
                    autoComplete="new-email"
                  />
                  <input
                    id="Password"
                    name="password"
                    type="password"
                    placeholder={labels.loginPassword}
                    className={`LoginInput ${invalidpassword ? "inValid" : ""}`}
                    value={password}
                    onChange={handleFormFieldChange}
                    onKeyPress={handleKeyPress}
                    autoComplete="new-password"
                  />
                  <input
                    id="LoginBtn"
                    type="button"
                    value={labels.loginLoginButton}
                    className="LoginSubmit"
                    onClick={handleSubmit}
                  />
                </React.Fragment>
              )}
              <GoogleSignup
                onClose={this.handleGoogleClose}
                onHideMainForm={this.handleHideMainForm}
              />
              <div className="LoginBtn">
                {(isLoginWithFacebook || isLoginWithLinkedIn) && (
                  <div className="otherOption">
                    <span>{labels.loginOrSignInWith} </span>
                  </div>
                )}
                <div className="SocialBtn">
                  {isLoginWithFacebook && (
                    <FacebookProvider appId={facebookClientId}>
                      <LoginButton
                        scope="email"
                        onCompleted={handleFacebookLoginSuccess}
                        onError={handleFacebookFailure}
                        className="FacebookBtn"
                        spinner={false}
                      >
                        Facebook
                      </LoginButton>
                    </FacebookProvider>
                  )}
                  {isLoginWithLinkedIn && (
                    <LinkedIn
                      clientId="78lrnmu1g30w3a"
                      onFailure={handleFailure}
                      onSuccess={handleSuccess}
                      redirectUri={linkedInUrl}
                      renderElement={({ onClick, disabled }) => (
                        <a
                          className="LinkedinBtn"
                          onClick={onClick}
                          disabled={disabled}
                        >
                          {labels.loginLinkedInButton}
                        </a>
                      )}
                    />
                  )}
                </div>
              </div>
              <label className="noAccount">
                <a className="Forgot" onClick={onForgotPassword}>
                  {ReactHtmlParser(labels.loginForgotPassword)}
                </a>
              </label>
              <label className="noAccount Forgot">
                <a onClick={onSignup}>
                  {ReactHtmlParser(labels.SignupLinkLabel)}
                </a>
              </label>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
const mapActionToProps = { loginAuth, notificationAction };
export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(LoginForm));
