import React from "react";
import { Modal } from "antd";
import CompanyDetailNew from "../../../../../common/companyDetail/companyDetail";
import PresentationView from "./presentationView";

const PresentationMobileModal = (props) => {
  const {
    handleModalClick,
    selectedCompany,
    addressLabel,
    industryLabel,
    countryLabel,
    phoneLabel,
    webUrlLabel,
    vatLabel,
    setIsModalOpen,
    isModalOpen,
    selectedOpportunityNew,
    showCompanyDetails,
    showPresentationDetail,
    loading,
    labels,
    socialMedia,
    getAllOpportunity,
  } = props;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        style={{ top: 20 }}
        onOk={handleOk}
        zIndex={100}
        onCancel={handleCancel}
        footer={false}
        className="new-search-resume-main for-mobile-modal-shortlist"
      >
        <div style={{ marginTop: "5px" }} className="new-search-resume-main">
          <div className="h-100 flex flex-column">
            <div
              style={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ overflow: "auto" }}>
                <PresentationView
                  selectedOpportunityNew={selectedOpportunityNew}
                  showCompanyDetails={showCompanyDetails}
                  showPresentationDetail={showPresentationDetail}
                  loading={loading}
                  labels={labels}
                  socialMedia={socialMedia}
                  getAllOpportunity={getAllOpportunity}
                  handleOk={handleOk}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default PresentationMobileModal;
