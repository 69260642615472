import React, { Component } from "react";
import ModalPopup from "react-responsive-modal";
import "./modal.scss";
const Title = ({ children }) => <div className="modal-title">{children}</div>;
const Body = ({ children }) => <div className="modal-body">{children}</div>;
const Footer = ({ children }) => <div className="modal-footer">{children}</div>;
class Modal extends Component {
  static Title = Title;
  static Body = Body;
  static Footer = Footer;
  render() {
    const { open, onCloseModal, children, className } = this.props;

    return (
      <ModalPopup
        classNames={{
          modal: `modal-popup ${className}`,
          overlay: "modal-overlay",
          closeIcon: "close-icon",
          closeButton: "close-button"
        }}
        open={open}
        onClose={onCloseModal}
        center
      >
        {children}
      </ModalPopup>
    );
  }
}
Modal.defaultProps = {
  className: "",
  onCloseModal: () => { }
};

export default Modal;
