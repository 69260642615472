import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  selectedOpportunity: {},
  opportunityList: [],
  filteredOpportunity: [],
  selectedCompany: {},
  fetchingOpportunity: true,
  isLoading: false,
  showPresentationDetail: false,
  showCompanyDetails: true,
  socialMedia: [],
  deletedId: "",
  requestName: "",
  dialogMessage: "",
  restoreDialogMessage: "",
  companyPresentationHeadingText: "",
  listCollapsed: false,
  detailCollapsed: false,
  companyPresentDetailCollapsed: false,
  searchKey: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.OPPORTUNITY_IPRO.SELECTED_OPPORTUNITY:
      return {
        ...state,
        selectedOpportunity: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.OPPORTUNITY_LIST:
      return {
        ...state,
        opportunityList: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.FILTERED_OPPORTUNITY:
      return {
        ...state,
        filteredOpportunity: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.FETCHING_OPPORTUNITY:
      return {
        ...state,
        fetchingOpportunity: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.SHOW_PRESENTATION_DETAIL:
      return {
        ...state,
        showPresentationDetail: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.SHOW_COMPANY_DETAILS:
      return {
        ...state,
        showCompanyDetails: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.SOCIAL_MEDIA:
      return {
        ...state,
        socialMedia: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.DELETED_ID:
      return {
        ...state,
        deletedId: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.REQUEST_NAME:
      return {
        ...state,
        requestName: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.RESTORE_DIALOG_MESSAGE:
      return {
        ...state,
        restoreDialogMessage: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.COMPANY_PRESENTATION_HEADING_TEXT:
      return {
        ...state,
        companyPresentationHeadingText: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.LIST_COLLAPSED:
      return {
        ...state,
        listCollapsed: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.DETAIL_COLLAPSED:
      return {
        ...state,
        detailCollapsed: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.COMPANY_PRESENT_DETAIL_COLLAPSED:
      return {
        ...state,
        companyPresentDetailCollapsed: action.payload
      };
    case ActionTypes.OPPORTUNITY_IPRO.SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload
      };

    default:
      return state;
  }
};

export const createOpportunityWrapperReducer = (
  reducerFunction,
  reducerName
) => {
  return (state, action) => {
    const { actionName } = action;
    const isInitializationCall = state === undefined;
    if (actionName !== reducerName && !isInitializationCall) return state;

    return reducerFunction(state, action);
  };
};
