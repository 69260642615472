import React, { Component } from "react";
import { Steps, Select, Button } from "antd";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { isEmpty, map, filter } from "lodash";
import "./market-analyzer.scss";
import { withRouter } from "react-router-dom";
import {
  GetUserExpectedSalaryApi,
  UploadAndGetRolesAndSkillsFromResumeApi,
  GetSuggestedProfilesForResumeApi,
  GetMarketPopularityIndexApi,
  GetSuggestedSkillsForResumeApi,
  addResumeProfileApi,
  addResumeSkillApi,
  getCurrenciesApi,
} from "../../nextstepApi";
import "react-circular-progressbar/dist/styles.css";
import ReactApexChart from "react-apexcharts";
import { PageWrapper } from "../../../../components";
// import { Select } from "../../../../common";

const { Step } = Steps;
const { Option } = Select;

const LOOKUPTYPES = {
  PROFILE: 1,
  SKILL: 2,
  KEYWORD: 3,
  CERTIFICATION: 4,
  COUNTRY: 5,
  LANGUAGE: 6,
  INDUSTRY: 7,
};
const countriesList = [{ value: 46, label: "Pakistan" }];

const durationLists = [
  { value: 1, label: "Yearly" },
  { value: 2, label: "Monthly" },
  { value: 3, label: "Hourly" },
];
class MarketAnalyzer extends Component {
  state = {
    allCurrenciesList: [],
    selectedCurrency: 22,
    selectedCountry: 46,
    selectedDuration: 1,
    top3Salaries: [],
    currentSlide: 0,
    keywords: "",
    isLoading: false,
    extractedSearchSettings: {
      Profiles: [],
      Skills: [],
    },
    resumeUploaded: false,
    progress: 0,
    popularityIndex: -1,
    salary: "",
    isLoading: false,
    options: {
      // colors: ["#b84644", "#4576b5"],
      // fill: {
      //   colors: ["#F44336", "#E91E63", "#9C27B0"],
      // },

      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function(chart, w, e) {},
        },
      },
      // colors: ["#3e335e", "#66DA26", "#546E7A"],
      bar: {
        distributed: true,
      },
      dataLabels: {
        enabled: false,
        align: "left",
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ["Entry", "Average", "Expert"],
        colors: ["#3e335e", "#66DA26", "#546E7A"],
      },
      labels: {
        style: {
          // colors: ["#2E93fA", "#66DA26", "#546E7A"],
          fontSize: "12px",
        },
      },
      title: {
        text: "",
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            markers: {
              size: 1.5,
            },
          },
        },
      ],
    },
    activeStep: 0,
  };

  componentDidMount = () => {
    this.getCurrencies();
    GetSuggestedProfilesForResumeApi()
      .then((res) => {
        this.setState({
          extractedSearchSettings: {
            ...this.state.extractedSearchSettings,
            Profiles: res.items.map((a) => ({
              Id: a.CorrelatedProfiles.ProfileId,
              LookupValue: a.CorrelatedProfiles.ProfileValue,
            })),
          },
        });
      })
      .catch((err) => {});
    GetSuggestedSkillsForResumeApi()
      .then((res) => {
        this.setState({
          extractedSearchSettings: {
            ...this.state.extractedSearchSettings,
            Skills: res.items.map((a) => ({
              Id: a.SkillId,
              LookupValue: a.SkillValue,
            })),
          },
        });
      })
      .catch((err) => {});
  };

  onFormSelectChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleAnalyzeClick = () => {
    this.setState({
      popularityIndex: -1,
      isLoading: true,
    });
    GetMarketPopularityIndexApi()
      .then((res) => {
        // emulating progress
        let progress = 0;
        while (progress < res.items) {
          const interval = setInterval(() => {
            this.setState({
              popularityIndex: progress,
              isLoading: false,
            });
          }, 10000);
          progress++;
        }
      })
      .catch((err) => console.log(err));
    const { selectedCountry, selectedCurrency, selectedDuration } = this.state;
    GetUserExpectedSalaryApi(
      selectedCountry,
      selectedCurrency,
      selectedDuration
    )
      .then((res) => {
        let selectedSalary =
          res.items.Top3Salaries.length > 0 ? res.items.Top3Salaries[0] : {};
        const top3Salaries = res.items.Top3Salaries.map((a) => ({
          ...a,
          isSelected: a.Lookupvalue == selectedSalary.Lookupvalue,
        }));
        const graphData = [];
        graphData.push({
          name: `Salary in ${selectedSalary.Currency}`,
          data: [
            selectedSalary.Entry,
            selectedSalary.Average,
            selectedSalary.Senior,
          ],
        });
        this.setState({
          selectedSalary: { ...selectedSalary, isSelected: true },
          graphData,
          top3Salaries,
          isLoading: false,
        });
      })
      .catch((err) => console.log(err));
  };

  uploadLinkedinPdf = (e, successName) => {
    var fileData = e.target.files[0];
    var types = "pdf";
    const { labels, notificationAction } = this.props;
    var fileType = fileData.name.split(".");
    const me = this;
    if (types === fileType[1].toLowerCase()) {
      var reader = new FileReader();
      reader.onload = (function(theFile) {
        return function(e) {
          var fileDataInBytes = e.target.result.split(",")[1];
          me.setState({ isLoading: true });
          UploadAndGetRolesAndSkillsFromResumeApi(fileDataInBytes)
            .then((response) => {
              if (response.success) {
                let {
                  Certifications = [],
                  Countries = [],
                  Industries = [],
                  Keywords = [],
                  Languages = [],
                  Profiles = [],
                  Skills = [],
                } = response.items;

                Certifications = map(Certifications, (certification) => ({
                  Id: certification.CertificationId,
                  ExpLevel: 0,
                  LookupValue: certification.CertificationValue,
                }));
                Countries = map(Countries, (country) => ({
                  Id: country.CountryId,
                  ExpLevel: 0,
                  LookupValue: country.CountryName,
                }));
                Industries = map(Industries, (industry) => ({
                  Id: industry.IndustryId,
                  ExpLevel: 0,
                  LookupValue: industry.IndustryValue,
                }));
                Keywords = map(Keywords, (keyword) => ({
                  Id: keyword.KeywordId,
                  ExpLevel: 0,
                  LookupValue: keyword.KeywordValue,
                }));
                Languages = map(Languages, (language) => ({
                  Id: language.LanguageId,
                  ExpLevel: 0,
                  LookupValue: language.LanguageValue,
                }));
                Profiles = map(Profiles, (profile) => ({
                  Id: profile.ProfileId,
                  ExpLevel: 0,
                  LookupValue: profile.ProfileValue,
                }));
                Skills = map(Skills, (skill) => ({
                  Id: skill.SkillId,
                  ExpLevel: 0,
                  LookupValue: skill.SkillValue,
                }));

                if (
                  isEmpty(Certifications) &&
                  isEmpty(Countries) &&
                  isEmpty(Industries) &&
                  isEmpty(Keywords) &&
                  isEmpty(Languages) &&
                  isEmpty(Profiles) &&
                  isEmpty(Skills)
                ) {
                  const info = {
                    message: labels.searcherOnboardingInvalidDescription,
                    status: "success",
                  };
                  notificationAction(info);
                  this.setState({
                    isLoading: false,
                  });
                  return;
                }
                const saveResumeParams = {
                  Certifications,
                  Countries,
                  Industries,
                  Keywords,
                  Languages,
                  Profiles,
                  Skills,
                };
                me.setState({
                  extractedSearchSettings: saveResumeParams,
                  isLoading: false,
                  resumeUploaded: true,
                });
              } else {
                this.setState({
                  isLoading: false,
                });
              }
            })
            .catch((err) => {
              const info = {
                message: "Unable to upload to file.",
                status: "error",
              };
              notificationAction(info);
              me.setState({ isLoading: false });
            });
        };
      })(fileData);

      reader.readAsDataURL(fileData);
    } else {
      const info = {
        message: "Please select pdf file.",
        status: "error",
      };
      notificationAction(info);
    }
  };

  extractedSettingUpdate = (type, item) => {
    const { extractedSearchSettings } = this.state;
    let {
      Certifications,
      Countries,
      Industries,
      Keywords,
      Languages,
      Profiles,
      Skills,
    } = extractedSearchSettings;
    switch (type) {
      case LOOKUPTYPES.PROFILE: {
        addResumeProfileApi(item.Id)
          .then((res) => {
            if (res.success) {
              Profiles = filter(Profiles, (a) => a.Id != item.Id);
              this.setState({
                extractedSearchSettings: {
                  ...extractedSearchSettings,
                  Profiles,
                },
              });
            }
          })
          .catch((err) => {});
        break;
      }
      case LOOKUPTYPES.SKILL: {
        addResumeSkillApi(item.Id)
          .then((res) => {
            if (res.success) {
              Skills = filter(Skills, (a) => a.Id != item.Id);
              this.setState({
                extractedSearchSettings: {
                  ...extractedSearchSettings,
                  Skills,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      }
      case LOOKUPTYPES.CERTIFICATION: {
        Certifications = filter(Certifications, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.COUNTRY: {
        Countries = filter(Countries, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.INDUSTRY: {
        Industries = filter(Industries, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.KEYWORD: {
        Keywords = filter(Keywords, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.LANGUAGE: {
        Languages = filter(Languages, (a) => a.Id != item.Id);
        break;
      }
    }
    this.setState({
      extractedSearchSettings: {
        ...extractedSearchSettings,
        Profiles,
        Skills,
        Certifications,
        Keywords,
        Languages,
        Countries,
      },
    });
  };

  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          const allCurrenciesList = res.items.map((item) => ({
            ...item,
            value: item.CurrencyId,
            label: item.Name,
          }));
          this.setState({
            allCurrenciesList: allCurrenciesList,
            selectedCurrency:
              allCurrenciesList[allCurrenciesList.length - 1].value,
          });
          this.handleAnalyzeClick();
        }
      })
      .catch((err) => console.log("Err ", err));
  }

  handleSalaryClick = (item) => {
    const graphData = [];
    graphData.push({
      name: `Salary in ${item.Currency}`,
      data: [item.Entry, item.Average, item.Senior],
    });
    this.setState({
      top3Salaries: this.state.top3Salaries.map((a) => ({
        ...a,
        isSelected: a.Lookupvalue == item.Lookupvalue,
      })),
      selectedSalary: item,
      graphData,
    });
  };

  render() {
    const {
      extractedSearchSettings,
      popularityIndex,
      isLoading,
      allCurrenciesList,
      selectedCurrency,
      selectedCountry,
      selectedDuration,
      selectedSalary,
    } = this.state;
    const { Profiles, Skills } = extractedSearchSettings;

    return (
      <>
        <div className="analyzer-root ">
          <div className="nextstep-analyzer">
            <Steps
              className="mobileResponsive"
              progressDot
              current={this.state.activeStep}
            >
              <Step title="Step 1" description="Resume Analyzed" />
              <Step title="Step 2" description="Boost your Value" />
              <Step title="Step 3" description="Your Career" />
            </Steps>

            {this.state.activeStep === 0 && (
              <>
                <p className="txt1">
                 {this?.props?.labels?.searcherOnboardingResumeTitle}
                </p>
                <p className="txt2">
                  Market is changing rapidly but we are there for you to look
                  what roles and skills are trending in the market but first we
                  will need to analyze your resume against the market.
                </p>

                <div className="select-warper d-flex justify-content-around align-items-center mt-4">
                  <div className="custom-select">
                    <p>Country</p>
                    <Select
                      defaultValue={selectedCountry || ""}
                      onChange={(selectedOption) =>
                        this.onFormSelectChange(
                          "selectedCountry",
                          selectedOption
                        )
                      }
                      placeholder="Select Country"
                    >
                      {countriesList.map((country) => (
                        <Option value={country.value}>{country.label}</Option>
                      ))}
                    </Select>
                  </div>

                  <div className="custom-select">
                    <p>Currency</p>
                    <Select
                      defaultValue={selectedCurrency || null}
                      onChange={(selectedOption) =>
                        this.onFormSelectChange(
                          "selectedCurrency",
                          selectedOption
                        )
                      }
                      placeholder="Select Currency"
                    >
                      {allCurrenciesList.map((currency) => (
                        <Option value={currency.CurrencyId}>
                          {currency.Name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="custom-select">
                    <p>Date</p>
                    <Select
                      defaultValue={selectedDuration || null}
                      onChange={(selectedOption) =>
                        this.onFormSelectChange(
                          "selectedDuration",
                          selectedOption
                        )
                      }
                      placeholder="Select Duration"
                    >
                      {durationLists.map((duration) => (
                        <Option value={duration.value}>{duration.label}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="custom-select">
                    <Button
                      type="primary"
                      className="analyze-btn"
                      onClick={this.handleAnalyzeClick}
                    >
                      Analyze my resume
                    </Button>
                  </div>
                </div>

                <div className="analyze-box">
                  {selectedSalary && (
                    <p className="txt1">
                      {`Average Salary for ${selectedSalary?.Lookupvalue} in  ${selectedSalary?.Country} is ${selectedSalary?.Average} ${selectedSalary?.Currency}/${selectedSalary?.DurationType}`}
                    </p>
                  )}
                  <>
                    {selectedSalary && (
                      <div className="chart-warper d-flex align-items-center mt-5">
                        <div className="top-salaries">
                          {this.state.top3Salaries.map((item, i) => (
                            <div
                              key={i}
                              className={`salary-item ${
                                item.isSelected ? "selected" : ""
                              }`}
                              onClick={() => this.handleSalaryClick(item)}
                            >
                              <label>{item.Lookupvalue}</label>{" "}
                              <label>{`: ${item.Average} ${item.Currency}`}</label>
                            </div>
                          ))}
                        </div>
                        <div className="bar-chart">
                          <ReactApexChart
                            options={this.state.options}
                            height="180"
                            series={this.state.graphData || []}
                            type="bar"
                            // width="100%"
                          />
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </>
            )}
            {this.state.activeStep === 1 && (
              <div className="mt-3">
                <p className="txt1">
                  Boost your value or better relation to market needs.
                </p>
                <p className="txt2">
                  These skills can Boost your value or relation to market needs
                </p>
                <div className="d-flex mt-3 flex-wrap  align-items-center">
                  {Skills &&
                    Skills.map((item) => (
                      <div key={item.Id} className="add-button">
                        <p className="icon-button-text"> {item.LookupValue}</p>
                        <p
                          className="plus-button"
                          onClick={() =>
                            this.extractedSettingUpdate(LOOKUPTYPES.SKILL, item)
                          }
                        >
                          +
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {this.state.activeStep === 2 && (
              <div className="mt-3">
                <p className="txt1">Next step in your career</p>
                <p className="txt2">
                  These are potential next step in your career path
                </p>
                <div className="d-flex mt-3 flex-wrap align-items-center">
                  {Profiles &&
                    Profiles.map((item) => (
                      <div key={item.Id} className="add-button">
                        <p className="icon-button-text"> {item.LookupValue}</p>
                        <p
                          className="plus-button"
                          onClick={() =>
                            this.extractedSettingUpdate(
                              LOOKUPTYPES.PROFILE,
                              item
                            )
                          }
                        >
                          +
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div
            className={`steps-btns-warper d-flex ${
              this.state.activeStep > 0
                ? "justify-content-between"
                : "justify-content-end"
            }  align-items-center`}
          >
            <Button
              type="default"
              className="step-btn"
              hidden={this.state.activeStep === 0}
              onClick={() =>
                this.setState((prev) => {
                  if (prev.activeStep > 0) {
                    return { activeStep: prev.activeStep - 1 };
                  }
                })
              }
            >
              Back
            </Button>
            <Button
              type="default"
              className="step-btn"
              hidden={this.state.activeStep === 2}
              onClick={() =>
                this.setState((prev) => {
                  if (prev.activeStep < 2) {
                    return { activeStep: prev.activeStep + 1 };
                  }
                })
              }
            >
              Next
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  let { FirstName } = user;
  FirstName =
    FirstName !== undefined ? FirstName : sessionStorage.getItem("userName");
  return { labels, FirstName };
};
export default connect(mapStateToProps, { notificationAction })(
  withRouter(MarketAnalyzer)
);
