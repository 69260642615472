import React, { Component } from "react";
import "./new-dashboard.scss";
import { withRouter } from "react-router-dom";
import { LoadingMaskRow } from "../../common";
import { connect } from "react-redux";
import { Avatar, Col, Progress, Row, Typography } from "antd";
import CButton from "./components/CButton/CButton";
import { IproPhillip, SearcherPhillip } from "../../components";
import { isNullOrUndefined } from "util";
import ProfileWidget from "./components/ProfileWidget";
import PresentationWidget from "./components/PresentationWidget";
import ShortlistWidget from "./components/ShortlistWidget";
import ResumeWidget from "./components/ResumeWidget";
import UserCompanyWidget from "./components/UserCompanyWidget";
import { notificationAction } from "../../actions";
import { updatePhillipPopupStatusApi } from "../../components/Phillip/phillipApi";
import { loadImageOrientation } from "../../utilities/helpers";
import uuidv1 from "uuid/v1";
import {
    getUserDataForDashboard,
    getSocialMediaApi,
    SaveUserDashboardDetails,
    getUserSocialMediaLinks,
    getResumesApi,
    saveUpdatedResumeApi,
    getMyResumeDetails,
    getUserWidgets,
} from "./dashboardApi";
import { StorageService } from "../../api";
import { find, map, filter } from "lodash";
import moment from "moment";
import "./antd.css";
import { EditFilled } from "@ant-design/icons";
import EditEducationPopUp from "./popups/EditEducationPopUp/EditEducationPopUp";
import EditLocationPopUp from "./popups/EditLocationPopUp/EditLocationPopUp";
import EditLanguagePopUp from "./popups/EditLanguagePopUp/EditLanguagePopUp";
import EditCertificationPopup from "./popups/EditCertificationPopup/EditCertificationPopup";
import EditSkillPopup from "./popups/EditSkillPopup/EditSkillPopup";
import ExperiencePopup from "./popups/ExperiencePopup/ExperiencePopup";
import OtherAchievementPopup from "./popups/OtherAchievementPopup/OtherAchievementPopup";
import IndustryPopup from "./popups/IndustryPopup/IndustryPopup";
import KeywordPopup from "./popups/KeywordPopup/KeywordPopup";
import RolePopup from "./popups/RolePopup/RolePopup";
import MissingRoleSkill from "./popups/MissingRoleAndSkill/MissingRoleSkill";
import FeatureCard from "./components/FeatureCard/FeatureCard";
import { onStateChangeActions } from "../../Routes/phillipModalAction";
import SearchNormalIcon from '../../assets/images/search-normal.png'
import QuickIcon from '../../assets/images/quick.png'
import RankingIcon from '../../assets/images/ranking.png'
import RebortIcon from '../../assets/images/rebort.png'
import ArrowRight from '../../assets/images/Icons/arrow-right.png'
import Checklist from '../../assets/images/checklist.png'
import UserIcon from '../../assets/images/Icons/profile-tick.png'

const { Title, Text } = Typography;
class NewDashboard extends Component {
    state = {
        userDetails: {
            CountryName: "",
            UserFirstname: "",
            UserLastname: "",
            ProfilePicture: "",
            UserEmail: "",
            UserPhonenumberValue: "",
        },
        userFormDetail: {},
        resumeCurrentIndustry: {},
        currentRole: {},
        otherAchievementForm: { ResumeOtherAchivenmentId: "0" },
        resumeExperienceEdit: {
            isEndDate: false,
            Description: "",
            EndDate: null,
            CompanyWorked: null,
            StartDate: null,
            IndustryId: null,
            ProfileId: null,
            ResumeExperienceId: null,
            ResumeId: null,
        },
        userResumeDetails: {},
        socialMedias: [],
        socialLinks: [],
        UserSocialMediaLinks: [],
        isLoading: false,
        errorMessage: "",
        linkedInClientId: "",
        facebookClientId: "",
        fetchingUserDetails: false,
        error: false,
        fetchingPresentation: false,
        isReloadResumeShortlistWidget: false,
        isReloadCompanyPresentationsWidget: false,
        showPhillip: false,
        isReloadResumeWidget: false,
        languageList: {},
        keywordsList: {},
        otherAchivenmentsList: {},
        skillsList: {},
        certificationsList: {},
        educationsList: {
            EducationTypeId: 0,
            EducationYear: 0,
            EducationId: 0,
        },
        industriesList: {},
        experiencesList: {},
        resumeEducation: {},
        resumeLocation: {},
        currentKeyword: {},
        resumeCertificationEdit: {},
        resumeSkillCurrent: {},
        resumes: {
            Profiles: [],
            Profiles: [],
            Skills: [],
            Educations: [],
            Certifications: [],
            Industries: [],
            Languages: [],
            ResumeExperience: [],
        },
        UserDetailsWidget: false,
        IProProfileProgress: false,
        PhilipsIpro: false,
        PresentationIpro: false,
        ResumeIpro: false,
        CompanySearcher: false,
        PhilipsSearcher: false,
        ResumeShortlists: false,
        SearcherResources: false,
        resumeLanguage: {
            ExperienceLevel: 1,
        },
    };

    componentDidMount() {
        const { User } = this.props;
        this.setState({ fetchingUserDetails: true, fetchingPresentation: true });
        this.getUserDetails();
        this.getSocialMedia();
        if (User.IsFreelancer) {
            this.getMyResumeDetails();
            this.getResume();
        }
        this.GetWIdgets(User.IsFreelancer);
    }

    componentDidUpdate(prevProps) {
        const { User } = this.props;
        if (User !== prevProps.User) {
            if (User.IsFreelancer) {
                this.getMyResumeDetails();
                this.getResume();
            }
            this.GetWIdgets(User.IsFreelancer);
        }
    }

    GetWIdgets = (IsFreelancer) => {
        var requiredFeaturesNames = [];
        if (IsFreelancer) {
            // var requiredFeaturesNames = ["OnBoardingIpro", "MessagesIpro", "OpportunitiesIpro", "PresentationIpro", "ResumeIpro", "TictellTimer"]
            requiredFeaturesNames = [
                "ResumeIpro",
                "PhilipsIpro",
                "PresentationIpro",
                "IProProfileProgress",
                "UserDetailsWidget",
            ];
        } else {
            requiredFeaturesNames = [
                "PhilipsSearcher",
                "CompanySearcher",
                "ResumeShortlists",
                "UserDetailsWidget",
                "SearcherResources",
            ];
        }
        getUserWidgets(requiredFeaturesNames)
            .then((response) => {
                if (response.success) {
                    let dashboardWidgets = {};
                    response.items.map((item) => {
                        dashboardWidgets[item.Name] = item.IsActive;
                    });
                    this.setState({ ...dashboardWidgets });
                }
            })
            .catch((e) => { });
    };

    getResume = () => {
        this.setState({ isLoading: true });
        getResumesApi()
            .then((data) => {
                if (data.success) {
                    this.setState({
                        resumes: data.items,
                    });
                    this.InitialAvailabilityDate = isNullOrUndefined(
                        data.items.AvailabilityDate
                    )
                        ? null
                        : moment(data.items.AvailabilityDate);
                    this.InitialAvailabilityType =
                        data.items.AvailablityType && data.items.AvailablityType;
                    const locationList = data.items.Regions.map((item) => ({
                        ...item,
                        uniqueId: uuidv1(),
                        isInvalid: false,
                    }));
                    //Language
                    const languageList = data.items.Languages.map((item) => ({
                        ...item,
                        uniqueId: uuidv1(),
                        isInvalid: false,
                        isCandidate: false,
                    }));
                    const candidatelanguageList = data.items.ResumeCandidateLanguage.map(
                        (item) => ({
                            ExperienceLevel: item.ExperienceLevel,
                            LanguageId: item.ResumeCandidateLanguageId,
                            LanguageValue: item.ResumeCandidateLanguageValue,
                            uniqueId: uuidv1(),
                            isInvalid: false,
                            isCandidate: true,
                        })
                    );
                    languageList.push(...candidatelanguageList);
                    ////////
                    //Role Mapping
                    const rolesList = data.items.Profiles.map((item) => ({
                        ...item,
                        uniqueId: uuidv1(),
                        isInvalid: false,
                        isCandidate: false,
                        isActive: false,
                    }));
                    const candidateRolesList = data.items.ResumeCandidateProfiles.map(
                        (item) => ({
                            ExperienceLevel: item.ExperienceLevel,
                            ProfileId: item.ResumeCandidateProfileId,
                            ProfileValue: item.ResumeCandidateProfileName,
                            uniqueId: uuidv1(),
                            isInvalid: false,
                            isCandidate: true,
                            isActive: false,
                        })
                    );

                    rolesList.push(...candidateRolesList);
                    this.setState({
                        rolesList,
                    });
                    ///skill mapping
                    const skillsList = data.items.Skills.map((item) => ({
                        ...item,
                        uniqueId: uuidv1(),
                        isInvalid: false,
                        isCandidate: false,
                        isActive: false,
                    }));
                    const candidateSkillsList = data.items.ResumeCandidateSkills.map(
                        (item) => ({
                            ExperienceLevel: item.ExperienceLevel,
                            SkillId: item.ResumeCandidateSkillId,
                            SkillValue: item.ResumeCandidateSkillName,
                            uniqueId: uuidv1(),
                            isInvalid: false,
                            isCandidate: true,
                            isActive: false,
                        })
                    );
                    skillsList.push(...candidateSkillsList);
                    //another array
                    //skills mapping ends here

                    //Certification Mapping
                    const certificationsList = data.items.Certifications.map((item) => ({
                        ...item,
                        CertificationDate: moment(
                            item.CertificationDate,
                            "YYYY-MM-DDTHH:mm:ss"
                        ).format("MM/DD/YYYY"),
                        uniqueId: uuidv1(),
                        isCandidate: false,
                        isInvalid: false,
                        isActive: false,
                    }));
                    const candidateCertificationList = data.items.ResumeCandidateCertifications.map(
                        (item) => ({
                            CertificationDate: moment(
                                item.CertificationDate,
                                "YYYY-MM-DDTHH:mm:ss"
                            ).format("MM/DD/YYYY"),
                            CertificationId: item.ResumeCandidateCertificationId,
                            CertificationValue: item.ResumeCandidateCertificationValue,
                            uniqueId: uuidv1(),
                            isInvalid: false,
                            isCandidate: true,
                            isActive: false,
                        })
                    );
                    certificationsList.push(...candidateCertificationList);

                    //another array
                    //Education Mapping
                    const educationsList = data.items.Educations.map((item) => ({
                        ...item,
                        uniqueId: uuidv1(),
                        isInvalid: false,
                    }));
                    const candidateEducationList = data.items.ResumeCandidateEducations.map(
                        (item) => ({
                            EducationYear: item.EducationYear,
                            EducationId: item.ResumeCandidateEducationId,
                            EducationValue: item.ResumeCandidateEducationValue,
                            EducationTypeId: item.EducationTypeId,
                            uniqueId: uuidv1(),
                            isInvalid: false,
                            isCandidate: true,
                        })
                    );
                    educationsList.push(...candidateEducationList);

                    //////////
                    //Keyword mapping
                    const keywordsList = data.items.Keywords.map((item) => ({
                        ...item,
                        uniqueId: uuidv1(),
                        isCandidate: false,
                        isInvalid: false,
                    }));
                    const candidateKeywordList = data.items.ResumeCandidateKeywords.map(
                        (item) => ({
                            KeywordId: item.ResumeCandidateKeywordId,
                            KeywordValue: item.ResumeCandidateKeywordValue,
                            uniqueId: uuidv1(),
                            isInvalid: false,
                            isCandidate: true,
                        })
                    );
                    keywordsList.push(...candidateKeywordList);
                    ///////////////////////

                    //Industruy
                    const industriesList = data.items.Industries.map((item) => ({
                        ...item,
                        uniqueId: uuidv1(),
                        isInvalid: false,
                        isCandidate: false,
                    }));
                    const candidateIndustriesList = data.items.ResumeCandidateIndustries.map(
                        (item) => ({
                            ExperienceLevel: item.ExperienceLevel,
                            IndustryId: item.ResumeCandidateIndustryId,
                            IndustryValue: item.ResumeCandidateIndustryValue,
                            uniqueId: uuidv1(),
                            isInvalid: false,
                            isCandidate: true,
                        })
                    );
                    industriesList.push(...candidateIndustriesList);
                    ////////
                    const otherAchivenmentsList = data.items.ResumeOtherAchivenments.map(
                        (item) => ({
                            ...item,
                            uniqueId: uuidv1(),
                            isInvalid: false,
                        })
                    );
                    const experiencesList = data.items.ResumeExperience.map((item) => ({
                        ...item,
                        uniqueId: uuidv1(),
                        isWorking: isNullOrUndefined(item.EndDate) ? true : false,
                        StartDate: moment(item.StartDate, "YYYY-MM-DDTHH:mm:ssZ").format(
                            "MM/DD/YYYY"
                        ),
                        EndDate: !isNullOrUndefined(item.EndDate)
                            ? moment(item.EndDate, "YYYY-MM-DDTHH:mm:ssZ").format(
                                "MM/DD/YYYY"
                            )
                            : moment(new Date(), "MM/DD/YYYY"),
                        isInvalid: false,
                        isRoleInvalid: false,
                        isIndustryInvalid: false,
                    }));
                    this.setState({
                        IsAvailable: data.items.IsAvailable,
                        AvailablityType: data.items.AvailablityType,
                        busyUntil: moment(data.items.AvailabilityDate),
                        locationList,
                        keywordsList,
                        otherAchivenmentsList,
                        certificationsList,
                        educationsList,
                        industriesList,
                        experiencesList,
                        skillsList,
                        languageList,
                        isLoading: false,
                    });
                }
                this.setState({ isLoading: false });
            })
            .catch((e) => { });
    };

    SaveUserDetails = () => {
        const { userFormDetail, socialLinks } = this.state;
        const {
            UserPhonenumberId,
            UserPhonenumberValue,
            UserEmailId,
            UserEmail,
            UserDetailId,
            UserFirstname,
            UserLastname,
            isPhoneAvailable,
            ProfilePicture,
        } = userFormDetail;

        if (
            (!UserPhonenumberValue && isPhoneAvailable) ||
            !UserEmail ||
            !UserLastname ||
            !UserFirstname
        ) {
            this.setState({
                errorMessage: this.props.labels.FILL_REQUIRED_FIELD_LBL,
            });
            return;
        }
        let dataObject = {
            UserPhonenumberId: isPhoneAvailable ? UserPhonenumberId : null,
            UserPhonenumberValue: isPhoneAvailable ? UserPhonenumberValue : null,
            UserEmailId: UserEmailId,
            UserEmail: UserEmail,
            UserFirstname: UserFirstname,
            UserLastname: UserLastname,
            ProfilePicture: ProfilePicture,
            SocialLinks: socialLinks
                .filter((x) => x.URL)
                .map((item) => ({
                    ...item,
                    UserDetailId: UserDetailId,
                })),
        };
        this.setState({ isLoading: true });
        SaveUserDashboardDetails(dataObject)
            .then((data) => {
                if (data.success) {
                    this.getSocialMedia();
                    this.getUserDetails();
                    // const info = {
                    //   message: this.props.labels.presentSuccessfullySaved,
                    //   status: "success",
                    // };
                    // this.props.notificationAction(info);
                    this.setState({ isLoading: false });
                }
            })
            .catch(() => {
                // this.setState({ isLoading: false });
            });

        this.setState({ isLoading: false });
    };

    handleResumeSave = () => {
        const {
            IsAvailable,
            AvailablityType,
            busyUntil,
            languageList,
            resumeLanguage,
            resumeCertificationEdit,
            resumeExperienceEdit,
            rolesList,
            currentRole,
            keywordsList,
            currentKeyword,
            otherAchivenmentsList,
            otherAchievementForm,
            skillsList,
            resumeSkillCurrent,
            certificationsList,
            educationsList,
            resumeEducation,
            industriesList,
            resumeCurrentIndustry,
            experiencesList,
            resumes,
            resumeLocation,
        } = this.state;
        let resume = {
            AvailablityType: AvailablityType,
            IsAvailable: IsAvailable,
            Regions: [],
            ResumeCandidateCertifications: [],
            ResumeCandidateEducations: [],
            ResumeCandidateIndustries: [],
            ResumeCandidateKeywords: [],
            ResumeCandidateLanguages: [],
            ResumeCandidateProfiles: [],
            ResumeCandidateSkills: [],
            ResumeCertifications: [],
            ResumeEducations: [],
            ResumeExperiences: [],
            ResumeId: resumes.ResumeId,
            ResumeIndustries: [],
            ResumeKeywords: [],
            ResumeLanguages: [{}],
            ResumeOtherAchivenment: [],
            ResumeProfiles: [],
            ResumeSkills: [],
        };
        this.setState({
            isLoading: true,
        });
        if (this.props.AvailablityType === 3) {
            resume = {
                ...resume,
                AvailabilityDate: moment(busyUntil),
            };
        }
        let locationList = this.state.locationList;
        if (resumeLocation.CountryId > 0) {
            locationList = [];
            locationList.push(resumeLocation);
        }

        const locationListNew = this.state.locationList.map((item) => ({
            ...item,
            isInvalid: item.CountryId === 0 ? true : item.isInvalid,
        }));
        this.setState({
            locationList: locationListNew,
        });

        if (resumeLanguage.LanguageId > 0) {
            languageList.push(resumeLanguage);
        }

        const languageListNew = this.state.languageList.map((item) => ({
            ...item,
            isInvalid:
                item.LanguageId === 0 && !item.isCandidate ? true : item.isInvalid,
        }));
        this.setState({
            languageList: languageListNew,
        });

        if (currentRole.ProfileId > 0) {
            rolesList.push(currentRole);
        }
        const roleListNew = this.state.rolesList.map((item) => ({
            ...item,
            isInvalid:
                item.ProfileId === 0 && !item.isCandidate ? true : item.isInvalid,
        }));
        this.setState({
            rolesList: roleListNew,
        });
        if (resumeSkillCurrent.SkillId > 0) {
            skillsList.push(resumeSkillCurrent);
        }
        const skillListNew = this.state.skillsList.map((item) => ({
            ...item,
            isInvalid:
                item.SkillId === 0 && !item.isCandidate ? true : item.isInvalid,
        }));
        this.setState({
            skillsList: skillListNew,
        });
        if (resumeCertificationEdit.CertificationId > 0) {
            certificationsList.push(resumeCertificationEdit);
        }

        const certificationListNew = this.state.certificationsList.map((item) => ({
            ...item,
            isInvalid:
                item.CertificationId === 0 && !item.isCandidate ? true : item.isInvalid,
        }));
        this.setState({
            certificationsList: certificationListNew,
        });

        if (currentKeyword.KeywordId > 0) {
            keywordsList.push(currentKeyword);
        }

        const keywordListNew = this.state.keywordsList.map((item) => ({
            ...item,
            isInvalid:
                item.KeywordId === 0 && !item.isCandidate ? true : item.isInvalid,
        }));
        this.setState({
            keywordsList: keywordListNew,
        });

        if (resumeCurrentIndustry.IndustryId > 0) {
            industriesList.push(resumeCurrentIndustry);
        }
        const industryListNew = this.state.industriesList.map((item) => ({
            ...item,
            isInvalid:
                item.IndustryId === 0 && !item.isCandidate ? true : item.isInvalid,
        }));
        this.setState({
            industriesList: industryListNew,
        });

        if (resumeExperienceEdit.IndustryId > 0) {
            experiencesList.push(resumeExperienceEdit);
        }

        const experienceListNew = this.state.experiencesList.map((item) => ({
            ...item,
            isInvalid: item.CompanyWorked === "" ? true : item.isInvalid,
            isIndustryInvalid:
                item.IndustryId === 0 && !item.isCandidate
                    ? true
                    : item.isIndustryInvalid,
            isRoleInvalid:
                item.ProfileId === 0 && !item.isCandidate ? true : item.isRoleInvalid,
        }));
        this.setState({
            experiencesList: experienceListNew,
        });

        if (otherAchievementForm.AchivenmentId > 0) {
            otherAchivenmentsList.push(otherAchievementForm);
        }
        const otherAcheivementListNew = this.state.otherAchivenmentsList.map(
            (item) => ({
                ...item,
                isInvalid: item.Title === "" ? true : item.isInvalid,
            })
        );
        this.setState({
            otherAchivenmentsList: otherAcheivementListNew,
        });

        if (resumeEducation.EducationId > 0) {
            educationsList.push(resumeEducation);
        }
        const educationListNew = this.state.educationsList.map((item) => ({
            ...item,
            isInvalid: item.EducationId === 0 ? true : item.isInvalid,
        }));
        //Validation
        if (
            locationListNew.findIndex((x) => x.isInvalid) > -1 ||
            languageListNew.findIndex((x) => x.isInvalid) > -1 ||
            roleListNew.findIndex((x) => x.isInvalid) > -1 ||
            skillListNew.findIndex((x) => x.isInvalid) > -1 ||
            certificationListNew.findIndex((x) => x.isInvalid) > -1 ||
            educationListNew.findIndex((x) => x.isInvalid) > -1 ||
            industryListNew.findIndex((x) => x.isInvalid) > -1 ||
            keywordListNew.findIndex((x) => x.isInvalid) > -1 ||
            experienceListNew.findIndex(
                (x) => x.isRoleInvalid || x.isIndustryInvalid || x.isInvalid
            ) > -1 ||
            otherAcheivementListNew.findIndex((x) => x.isInvalid) > -1
        ) {
            const info = {
                message: this.props.labels.RESUME_EDIT_FIX_VALIDATION_MESSAGE,
                status: "error",
            };
            this.props.notificationAction(info);
            this.setState({
                isLoading: false,
                error: true,
                errorMessage: this.props.labels.RESUME_EDIT_FIX_VALIDATION_MESSAGE,
            });
            return;
        }
        //Validation Ends

        resume.Regions = map(locationList, (item) => ({
            CountryId: item.CountryId,
        }));

        //Language
        let filteredLanguages = filter(languageList, (item) => {
            if (!item.isCandidate && item.LanguageId !== 0) {
                const newItem = {
                    ExperienceLevel: item.ExperienceLevel,
                    LanguageId: item.LanguageId,
                };
                return newItem;
            }
        });
        resume.ResumeLanguages = map(filteredLanguages, (item) => ({
            ExperienceLevel: item.ExperienceLevel,
            LanguageId: item.LanguageId,
        }));
        filteredLanguages = filter(languageList, (item) => {
            if (item.isCandidate || item.LanguageId === 0) {
                const newItem = {
                    ExperienceLevel: item.ExperienceLevel,
                    LanguageId: item.LanguageId,
                    LanguageValue: item.LanguageValue,
                };
                return newItem;
            }
        });
        {
            filteredLanguages &&
                (resume.ResumeCandidateLanguages = map(filteredLanguages, (item) => {
                    const newItem = {
                        ExperienceLevel: item.ExperienceLevel,
                        ResumeCandidateLanguageId: "0",
                        ResumeCandidateLanguageValue: item.LanguageValue,
                    };
                    return newItem;
                }));
        }
        //////////////
        let filteredProfiles = filter(rolesList, (item) => {
            if (!item.isCandidate && item.ProfileId !== 0) {
                const newItem = {
                    ExperienceLevel: item.ExperienceLevel,
                    ProfileId: item.ProfileId,
                };
                return newItem;
            }
        });
        resume.ResumeProfiles = map(filteredProfiles, (item) => ({
            ExperienceLevel: item.ExperienceLevel,
            ProfileId: item.ProfileId,
        }));
        filteredProfiles = filter(rolesList, (item) => {
            if (item.isCandidate || item.ProfileId === 0) {
                const newItem = {
                    ExperienceLevel: item.ExperienceLevel,
                    ProfileId: item.ProfileId,
                    ProfileValue: item.ProfileValue,
                };
                return newItem;
            }
        });
        {
            filteredProfiles &&
                (resume.ResumeCandidateProfiles = map(filteredProfiles, (item) => {
                    const newItem = {
                        ExperienceLevel: item.ExperienceLevel,
                        ResumeCandidateProfileId: "0",
                        ResumeCandidateProfileName: item.ProfileValue,
                    };
                    return newItem;
                }));
        }
        //skills
        let filteredSkills = filter(skillsList, (item) => {
            if (!item.isCandidate && item.SkillId !== 0) {
                const newItem = {
                    ExperienceLevel: item.ExperienceLevel,
                    SkillId: item.SkillId,
                };
                return newItem;
            }
        });
        resume.ResumeSkills = map(filteredSkills, (item) => ({
            ExperienceLevel: item.ExperienceLevel,
            SkillId: item.SkillId,
        }));
        filteredSkills = filter(skillsList, (item) => {
            if (item.isCandidate || item.SkillId === 0) {
                const newItem = {
                    ExperienceLevel: item.ExperienceLevel,
                    SkillId: item.SkillId,
                    SkillValue: item.SkillValue,
                };
                return newItem;
            }
        });
        {
            filteredSkills &&
                (resume.ResumeCandidateSkills = map(filteredSkills, (item) => {
                    const newItem = {
                        ExperienceLevel: item.ExperienceLevel,
                        ResumeCandidateSkillId: "0",
                        ResumeCandidateSkillName: item.SkillValue,
                    };
                    return newItem;
                }));
        }

        ////industry
        let filteredIndustries = filter(industriesList, (item) => {
            if (!item.isCandidate && item.IndustryId !== 0) {
                const newItem = {
                    ExperienceLevel: item.ExperienceLevel,
                    IndustryId: item.IndustryId,
                };
                return newItem;
            }
        });
        resume.ResumeIndustries = map(filteredIndustries, (item) => ({
            ExperienceLevel: item.ExperienceLevel,
            IndustryId: item.IndustryId,
        }));
        filteredIndustries =
            industriesList && industriesList.length > 0
                ? filter(industriesList, (item) => {
                    if (item.isCandidate || item.IndustryId === 0) {
                        const newItem = {
                            ExperienceLevel: item.ExperienceLevel,
                            IndustryId: item.IndustryId,
                            IndustryValue: item.IndustryValue,
                        };
                        return newItem;
                    }
                })
                : [];
        {
            filteredIndustries &&
                filteredIndustries.length > 0 &&
                (resume.ResumeCandidateIndustries = map(filteredIndustries, (item) => {
                    const newItem = {
                        ExperienceLevel: item.ExperienceLevel,
                        ResumeCandidateIndustryId: "0",
                        ResumeCandidateIndustryValue: item.IndustryValue,
                    };
                    return newItem;
                }));
        }
        //
        ////certification
        let filteredCertifications = filter(certificationsList, (item) => {
            if (!item.isCandidate && item.IndustryId !== 0) {
                const newItem = {
                    CertificationDate: moment(
                        item.CertificationDate,
                        "MM/DD/YYYY"
                    ).format("YYYY-MM-DDTHH:mm:ss"),
                    CertificationId: item.CertificationId,
                };
                return newItem;
            }
        });
        resume.ResumeCertifications = map(filteredCertifications, (item) => ({
            CertificationDate: moment(item.CertificationDate, "MM/DD/YYYY").format(
                "YYYY-MM-DDTHH:mm:ss"
            ),
            CertificationId: item.CertificationId,
        }));
        filteredCertifications = filter(certificationsList, (item) => {
            if (item.isCandidate || item.IndustryId === 0) {
                const newItem = {
                    CertificationDate: item.CertificationDate,
                    CertificationId: item.CertificationId,
                    CertificationValue: item.CertificationValue,
                };
                return newItem;
            }
        });
        {
            filteredCertifications &&
                (resume.ResumeCandidateCertifications = map(
                    filteredCertifications,
                    (item) => {
                        const newItem = {
                            CertificationDate: item.CertificationDate,
                            ResumeCertificationId: "0",
                            ResumeCandidateCertificationValue: item.CertificationValue,
                        };
                        return newItem;
                    }
                ));
        }

        //Education

        let filteredEducations = filter(educationsList, (item) => {
            if (item.EducationId !== 0) {
                const newItem = {
                    EducationId: item.EducationId,
                    EducationTypeId: item.EducationTypeId,
                    EducationYear: Number(item.EducationYear),
                };
                return newItem;
            }
        });
        resume.ResumeEducations = map(filteredEducations, (item) => ({
            EducationTypeId: item.EducationTypeId,
            EducationYear: Number(item.EducationYear),
            EducationId: item.EducationId,
        }));
        filteredEducations = filter(educationsList, (item) => {
            if (item.isCandidate || item.EducationId === 0) {
                const newItem = {
                    ExperienceLevel: item.ExperienceLevel,
                    EducationId: item.EducationId,
                    EducationValue: item.EducationValue,
                };
                return newItem;
            }
        });
        {
            filteredEducations &&
                (resume.ResumeCandidateEducations = map(filteredEducations, (item) => {
                    const newItem = {
                        ExperienceLevel: item.ExperienceLevel,
                        ResumeCandidateEducationId: "0",
                        ResumeCandidateEducationValue: item.EducationValue,
                        EducationTypeId: item.EducationTypeId,
                        EducationYear: Number(item.EducationYear),
                    };
                    return newItem;
                }));
        }
        ////
        let filteredKeywords = filter(keywordsList, (item) => {
            if (!item.isCandidate && item.KeywordId !== 0) {
                const newItem = {
                    KeywordId: item.KeywordId,
                };
                return newItem;
            }
        });
        resume.ResumeKeywords = map(filteredKeywords, (item) => ({
            KeywordId: item.KeywordId,
        }));
        filteredKeywords = filter(keywordsList, (item) => {
            if (item.isCandidate || item.KeywordId === 0) {
                const newItem = {
                    KeywordId: item.KeywordId,
                    KeywordValue: item.KeywordValue,
                };
                return newItem;
            }
        });
        {
            filteredKeywords &&
                (resume.ResumeCandidateKeywords = map(filteredKeywords, (item) => {
                    const newItem = {
                        ResumeCandidateKeywordId: "0",
                        ResumeCandidateKeywordValue: item.KeywordValue,
                    };
                    return newItem;
                }));
        }

        resume.ResumeExperiences = map(experiencesList, (item) => ({
            CompanyWorked: item.CompanyWorked,
            Description: item.Description,
            EndDate: item.isWorking ? null : item.EndDate,
            IndustryId: item.IndustryId,
            ProfileId: item.ProfileId,
            ResumeExperienceId: item.ResumeExperienceId || "0",
            ResumeId: resumes.ResumeId,
            StartDate: item.StartDate,
        }));
        resume.ResumeOtherAchivenment = map(otherAchivenmentsList, (item) => ({
            AchivenmentId: item.AchivenmentId,
            Description: item.Description,
            ResumeId: resume.ResumeId,
            ResumeOtherAchivenmentId: "" + item.ResumeOtherAchivenmentId || "0",
            Title: item.Title,
            Year: Number(item.Year),
        }));
        saveUpdatedResumeApi({ resume }).then((response) => {
            if (response.success) {
                const info = {
                    message: this.props.labels[response.message],
                    status: "success",
                    className: `${response.message == "resumeFirstEditSuccessMessage"
                        ? "first-resume-save"
                        : "resume-save"
                        }`,
                };
                this.props.notificationAction(info);
                this.getMyResumeDetails();
                this.getResume();
            }
            this.setState({
                isLoading: false,
            });
        });
    };

    getSocialMedia = () => {
        getSocialMediaApi().then((data) => {
            if (data.success) {
                const socialMediaList = data.items
                    .filter(
                        (x) =>
                            x.Name == "facebook" ||
                            x.Name == "twitter" ||
                            x.Name == "instagram" ||
                            x.Name == "googleplus"
                    )
                    .map((item) => ({
                        ...item,
                        URL: "",
                        SocialMediaId: item.Id,
                    }));
                this.setState({
                    socialMedias: socialMediaList,
                });
                this.getUserSocialMediaLinks();
            }
        });
    };

    getMyResumeDetails = () => {
        getMyResumeDetails()
            .then((res) => {
                if (res.success) {
                    let trueVlues = Object.values(res.items).reduce(
                        (a, item) => a + (item === true ? 1 : 0),
                        0
                    );
                    let falseVlues = Object.values(res.items).reduce(
                        (a, item) => a + (item === false ? 1 : 0),
                        0
                    );
                    this.setState({
                        userResumeDetails: { ...res.items, trueVlues, falseVlues },
                    });
                }
            })
            .catch((err) => console.log("Err ", err));
    };

    handleUserForm = (e) => {
        const { userFormDetail } = this.state;

        this.setState({
            userFormDetail: {
                ...userFormDetail,
                [e.target.name]: e.target.value,
            },
        });
    };

    handleEducationChange = (name, e) => {
        const { resumeEducation } = this.state;
        this.setState({
            resumeEducation: {
                ...resumeEducation,
                [name]: e,
            },
        });
    };

    handleDescriptionChange = (e) => {
        const { resumeExperienceEdit } = this.state;
        this.setState({
            resumeExperienceEdit: {
                ...resumeExperienceEdit,
                Description: e,
            },
        });
    };

    handleExperienceChange = (name, value) => {
        const { resumeExperienceEdit } = this.state;
        if (name === "isEndDate") {
            this.setState({
                resumeExperienceEdit: {
                    ...resumeExperienceEdit,
                    [name]: !value,
                    EndDate: null,
                },
            });
            return;
        }

        this.setState({
            resumeExperienceEdit: {
                ...resumeExperienceEdit,
                [name]: value,
            },
        });
    };

    handleStartDate = (date, dateString) => {
        const { resumeExperienceEdit } = this.state;
        this.setState({
            resumeExperienceEdit: {
                ...resumeExperienceEdit,
                StartDate: moment(dateString, "YYYY/MM/DD"),
            },
        });
    };

    handleEndDate = (date, dateString) => {
        const { resumeExperienceEdit } = this.state;
        this.setState({
            resumeExperienceEdit: {
                ...resumeExperienceEdit,
                EndDate: moment(dateString, "YYYY/MM/DD"),
            },
        });
    };
    handleLocationChange = (name, e) => {
        const { resumeLocation } = this.state;
        this.setState({
            resumeLocation: { ...resumeLocation, [name]: e },
        });
    };
    handleCertificationChange = (name, e) => {
        const { resumeCertificationEdit } = this.state;
        if (name === "CertificationDate") {
            var newdate = moment(new Date(), "YYYY-MM-DDTHH:mm:ss").year(e);
            this.setState({
                resumeCertificationEdit: {
                    ...resumeCertificationEdit,
                    [name]: newdate,
                },
            });
            return;
        }
        this.setState({
            resumeCertificationEdit: { ...resumeCertificationEdit, [name]: e },
        });
    };
    handleLanguageChange = (name, e) => {
        const { resumeLanguage } = this.state;
        this.setState({
            resumeLanguage: { ...resumeLanguage, [name]: e },
        });
    };

    handleSkillCHange = (name, e) => {
        const { resumeSkillCurrent } = this.state;
        this.setState({
            resumeSkillCurrent: { ...resumeSkillCurrent, [name]: e },
        });
    };

    handleRoleChange = (name, e) => {
        const { currentRole } = this.state;
        this.setState({
            currentRole: { ...currentRole, [name]: e },
        });
    };

    handleOtherAchievementChange = (name, e) => {
        const { otherAchievementForm } = this.state;
        this.setState({
            otherAchievementForm: { ...otherAchievementForm, [name]: e },
        });
    };

    handleIndustryChange = (name, e) => {
        const { resumeCurrentIndustry } = this.state;
        this.setState({
            resumeCurrentIndustry: { ...resumeCurrentIndustry, [name]: e },
        });
    };
    handleKeywordChange = (name, e) => {
        const { currentKeyword } = this.state;
        this.setState({
            currentKeyword: { ...currentKeyword, [name]: e },
        });
    };

    handleSocialMediaChange = (selectedItem, value) => {
        const { socialLinks } = this.state;
        const SocialLinks = socialLinks.map((item) => ({
            ...item,
            URL: selectedItem.Id === item.Id ? value : item.URL,
        }));
        this.setState({
            socialLinks: SocialLinks,
        });
    };

    mergeSocialMediaList = (UserSocialMediaLinks) => {
        const { socialMedias } = this.state;
        var socialLinks = map(socialMedias, function (item) {
            return {
                ...item,
                ...find(UserSocialMediaLinks, ["SocialMediaId", item.Id]),
            };
        });
        this.setState({ socialLinks: socialLinks });

        this.handleSocialMediaActive(socialLinks[0]);
    };

    handleSocialMediaActive = (activeItem) => {
        const { socialLinks } = this.state;
        const SocialLinks = socialLinks.map((item) => ({
            ...item,
            isActive: item.SocialMediaId == activeItem.SocialMediaId ? true : false,
        }));
        this.setState({
            socialLinks: SocialLinks,
        });
    };

    handleShowPhllip = () => {
        if (!this?.props?.User?.IsFreelancer) {
            this.props.onStateChangeActions({ show: "large" });
        } else {
            this.setState({ showPhillip: true });
        }
    };
    handleIproPhillipSkip = () => {
        const { IsFreelancer } = this.props.User;
        updatePhillipPopupStatusApi({ isFreelancer: IsFreelancer }).then(
            (response) => {
                if (response.success) {
                    const info = {
                        message: this.props.labels.iProOnboardingSkipButtonInfo,
                        status: "success",
                    };
                    this.props.notificationAction(info);
                }
            }
        );
        this.setState({ showPhillip: false, firstTimeClosed: true });
    };
    reloadResumeWidgetHandler = () => {
        this.setState({ isReloadResumeWidget: !this.state.isReloadResumeWidget });
    };

    handleClosePhillip = () => {
        const { IsFreelancer } = this.props.User;
        updatePhillipPopupStatusApi({
            isFreelancer: IsFreelancer,
        }).then((response) => { });
        this.setState({ showPhillip: false, firstTimeClosed: true });
    };

    handleUpdateResumeShortlistWidgetWidget = () => {
        this.setState({
            isReloadResumeShortlistWidget: !this.state.isReloadResumeShortlistWidget,
        });
    };

    handleUpdateCompanyPresentationsWidget = () => {
        this.setState({
            isReloadCompanyPresentationsWidget: !this.state
                .isReloadCompanyPresentationsWidget,
        });
    };

    getUserSocialMediaLinks = () => {
        getUserSocialMediaLinks().then((data) => {
            if (data.success) {
                this.setState({
                    UserSocialMediaLinks: data.items,
                });
                this.mergeSocialMediaList(data.items);
            }
        });
    };

    getUserDetails = () => {
        this.setState({ fetchingUserDetails: true });
        const { labels, User, history } = this.props;

        getUserDataForDashboard(!User.IsFreelancer)
            .then((res) => {
                if (res.success) {
                    var userDetailsItem = !res.items
                        ? { ...this.state.userDetails }
                        : {
                            ...res.items,
                            isPhoneAvailable: res.items.UserPhonenumberId ? true : false,
                            isAvailable: true,
                        };
                    this.setState({
                        fetchingUserDetails: false,
                        userFormDetail: userDetailsItem,
                        userDetails: res.items,
                    });
                }
            })
            .catch((err) => console.log("Err ", err));
    };

    updateAvatar = (image) => {
        const { userFormDetail } = this.state;

        this.setState({
            userFormDetail: {
                ...userFormDetail,
                ProfilePicture: image,
            },
        });
    };

    handleAvatarChange = (e) => {
        if (e) {
            loadImageOrientation(e, this.updateAvatar);
        }
    };

    render() {
        const {
            socialLinks,
            userFormDetail,
            userDetails = {},
            isLoading,
            errorMessage,
            fetchingUserDetails,
            showPhillip,
            linkedInClientId,
            facebookClientId,
            userResumeDetails,
            resumeExperienceEdit,
            resumeCurrentIndustry,
            otherAchievementForm,
            currentKeyword,
            currentRole,
            resumes,
            UserDetailsWidget,
            IProProfileProgress,
            PhilipsIpro,
            PresentationIpro,
            ResumeIpro,
            CompanySearcher,
            PhilipsSearcher,
            ResumeShortlists,
            SearcherResources,
            resumeEducation,
            resumeLocation,
            resumeCertificationEdit,
            resumeLanguage,
            resumeSkillCurrent,
        } = this.state;
        const { labels, User, history } = this.props;
        return (
            <div className={" container-fluid new-dashboard"}>
                {isLoading && (
                    <div id="loader-wrapper">
                        <div test-data-id={"loader"} id="loader" />
                    </div>
                )}

                {showPhillip && User.IsFreelancer && (
                    <IproPhillip
                        open={showPhillip}
                        onSkip={this.handleIproPhillipSkip}
                        onCloseModal={this.handleClosePhillip}
                        facebookClientId={facebookClientId}
                        linkedInClientId={linkedInClientId}
                        onResumeUpdate={this.reloadResumeWidgetHandler}
                    />
                )}

                {showPhillip && !User.IsFreelancer && (
                    <SearcherPhillip
                        open={showPhillip}
                        onSkip={this.handleIproPhillipSkip}
                        onCloseModal={this.handleClosePhillip}
                        facebookClientId={facebookClientId}
                        linkedInClientId={linkedInClientId}
                        onCompanyPresentationsUpdate={
                            this.handleUpdateCompanyPresentationsWidget
                        }
                        onResumeShortlistUpdate={
                            this.handleUpdateResumeShortlistWidgetWidget
                        }
                    />
                )}

                <div className={"firstContainer row"}>
                    {/* <div className="first-container-child-warper col-md-6">
                        {fetchingUserDetails ? (
                            <LoadingMaskRow />
                        ) : (
                            UserDetailsWidget && (
                                <ProfileWidget
                                    handleAvatarChange={this.handleAvatarChange}
                                    errorMessage={errorMessage}
                                    userFormDetail={userFormDetail}
                                    socialLinks={socialLinks}
                                    handleUserForm={this.handleUserForm}
                                    SaveUserDetails={this.SaveUserDetails}
                                    handleSocialMediaActive={this.handleSocialMediaActive}
                                    handleSocialMediaChange={this.handleSocialMediaChange}
                                    isLoading={isLoading}
                                    userDetails={userDetails}
                                    Avatar={Avatar}
                                    labels={labels}
                                />
                            )
                        )}
                        {User.IsFreelancer
                            ? PresentationIpro && <PresentationWidget labels={labels} />
                            : CompanySearcher && <UserCompanyWidget labels={labels} />}
                    </div> */}
                    {/* <div className="first-container-child-warper col-md-6">
                        {(PhilipsIpro || PhilipsSearcher) && (
                            <div className="do-for-you">
                                <div>
                                    <Title className="mb-0" level={4}>
                                        {labels.WHAT_CAN_PHILIP_DO}
                                    </Title>
                                    <Text className="text-muted">
                                        {labels.PHILLIP_AI_DETAIL_INFO}
                                    </Text>
                                    <CButton
                                        whiteBackground
                                        className="mt-2 button-blick-effect"
                                        title="Getting Started"
                                        onclick={this.handleShowPhllip}
                                    />
                                    <img className="waves-image" src="assets/images/waves.svg" />
                                </div>
                            </div>
                        )}

                        {User && User.IsFreelancer && ResumeIpro && (
                            <ResumeWidget
                                resumes={resumes}
                                labels={labels}
                                userDetails={userDetails}
                            />
                        )}

                        {User && !User.IsFreelancer && ResumeShortlists && (
                            <ShortlistWidget labels={labels} />
                        )}
                    </div> */}
                </div>

                {User.IsFreelancer
                    ? IProProfileProgress && (
                        <>
                            <div className="main-dashboard">
                                <div className="cards-new">
                                    <Row gutter={[0, 12]}>
                                        <Col xs={24}>
                                            <div className="d-flex profile align-items-center gap-16">
                                                <div>
                                                    <Title level={3} style={{ marginBottom: 0, whiteSpace: "pre" }} >Profile Completion</Title>
                                                </div>
                                                <div className="d-flex w-100 flex-fill align-items-center gap-16">
                                                    <Progress
                                                        strokeColor="#8F82F5"
                                                        className="progress-container"
                                                        style={{ flex: 1, height: "12px" }}
                                                        percent={userResumeDetails.Score}
                                                        size="small"
                                                        showInfo={false}
                                                    />
                                                    <Text style={{ color: "#8E81F5", fontWeight: 600 }} className="fnt-s-16">{userResumeDetails.Score}%</Text>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="d-flex gap-8 mt-3 flex-wrap align-items-center">

                                        {userResumeDetails.Score < 100 && (
                                            <Text className="fnt-s-16">Complete the following steps to boost your profile:</Text>
                                        )}

                                        {!userResumeDetails.Score ||
                                            userResumeDetails.Score < 100 ? (
                                            <>
                                                {(userResumeDetails.IsProfileEmpty ||
                                                    userResumeDetails.IsSkillEmpty) && (
                                                        <MissingRoleSkill
                                                            handleRoleChange={this.handleRoleChange}
                                                            labels={labels}
                                                            currentRole={currentRole}
                                                        />
                                                    )}

                                                {userResumeDetails.IsLoctionEmpty && (
                                                    <EditLocationPopUp
                                                        handleLocationChange={this.handleLocationChange}
                                                        labels={labels}
                                                        resumeLocation={resumeLocation}
                                                        SaveResumeDetails={this.handleResumeSave}
                                                    />
                                                )}

                                                {userResumeDetails.IsLanguageEmpty && (
                                                    <EditLanguagePopUp
                                                        handleLanguageChange={this.handleLanguageChange}
                                                        labels={labels}
                                                        resumeLanguage={resumeLanguage}
                                                        SaveResumeDetails={this.handleResumeSave}
                                                    />
                                                )}

                                                {userResumeDetails.IsProfileEmpty && (
                                                    <RolePopup
                                                        handleRoleChange={this.handleRoleChange}
                                                        labels={labels}
                                                        currentRole={currentRole}
                                                        SaveResumeDetails={this.handleResumeSave}
                                                    />
                                                )}

                                                {userResumeDetails.IsSkillEmpty && (
                                                    <EditSkillPopup
                                                        handleSkillCHange={this.handleSkillCHange}
                                                        labels={labels}
                                                        resumeSkillCurrent={resumeSkillCurrent}
                                                        SaveResumeDetails={this.handleResumeSave}
                                                    />
                                                )}

                                                {userResumeDetails.IsKeywordEmpty && (
                                                    <KeywordPopup
                                                        handleKeywordChange={this.handleKeywordChange}
                                                        labels={labels}
                                                        currentKeyword={currentKeyword}
                                                        SaveResumeDetails={this.handleResumeSave}
                                                    />
                                                )}

                                                {userResumeDetails.IsCertificationEmpty && (
                                                    <EditCertificationPopup
                                                        handleCertificationChange={
                                                            this.handleCertificationChange
                                                        }
                                                        labels={labels}
                                                        resumeCertificationEdit={resumeCertificationEdit}
                                                        SaveResumeDetails={this.handleResumeSave}
                                                    />
                                                )}

                                                {userResumeDetails.IsEducationEmpty && (
                                                    <EditEducationPopUp
                                                        handleEducationChange={this.handleEducationChange}
                                                        labels={labels}
                                                        resumeEducation={resumeEducation}
                                                        SaveResumeDetails={this.handleResumeSave}
                                                    />
                                                )}

                                                {userResumeDetails.IsIndustryEmpty && (
                                                    <IndustryPopup
                                                        handleIndustryChange={this.handleIndustryChange}
                                                        labels={labels}
                                                        resumeCurrentIndustry={resumeCurrentIndustry}
                                                        SaveResumeDetails={this.handleResumeSave}
                                                    />
                                                )}

                                                {userResumeDetails.IsExperienceEmpty && (
                                                    <ExperiencePopup
                                                        handleExperienceChange={
                                                            this.handleExperienceChange
                                                        }
                                                        handleDescriptionChange={
                                                            this.handleDescriptionChange
                                                        }
                                                        handleEndDate={this.handleEndDate}
                                                        handleStartDate={this.handleStartDate}
                                                        labels={labels}
                                                        resumeExperienceEdit={resumeExperienceEdit}
                                                        SaveResumeDetails={this.handleResumeSave}
                                                    />
                                                )}

                                                {userResumeDetails.IsOtherAchievementEmpty && (
                                                    <OtherAchievementPopup
                                                        handleOtherAchievementChange={
                                                            this.handleOtherAchievementChange
                                                        }
                                                        handleDescriptionChange={
                                                            this.handleDescriptionChange
                                                        }
                                                        labels={labels}
                                                        otherAchievementForm={otherAchievementForm}
                                                        SaveResumeDetails={this.handleResumeSave}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <Text className="fnt-s-16">{labels?.ipro_dashboard_profile_complete}</Text>
                                        )}
                                    </div>
                                </div>
                                <Row gutter={[{ xs: 10, md: 16 }, { xs: 10, md: 16 }]}>
                                    <Col xs={24} md={16} className="gutter-row d-flex flex-column gap-16" >
                                        <PresentationWidget labels={labels}
                                            history={history}
                                        />
                                        <div className="cards-new">
                                            <div className="d-flex gap-16 what-phillips">
                                                <div>
                                                    <Title level={3}>Hey! How Can I Help You?</Title>
                                                    <Text className="fnt-s-16">Meet Phillip, the ProDoo AI a premium service offered
                                                        by ProDoo. Phillip is your ultimate solution, handling the
                                                        entire process of finding your perfect gig and keeping you informed with real-time progress updates along the way.
                                                    </Text>
                                                    <button className="button-getting-started position-abs"
                                                        onClick={this.handleShowPhllip}
                                                    >
                                                        Getting Started <img src={ArrowRight} alt="" />
                                                    </button>
                                                </div>
                                                <div style={{ textAlign: "right" }}>
                                                    <img className="img-rebort" src={RebortIcon} alt="rebort" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={8} xs={24} className="gutter-row">
                                        <ResumeWidget
                                            resumes={resumes}
                                            labels={labels}
                                            userDetails={userDetails}
                                            history={history}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </>
                    )
                    : SearcherResources && (
                        <div className="main-dashboard">
                            <div className="cards-new">
                                <Row gutter={[0, 12]}>
                                    <Col xs={24}>
                                        <Title level={3} >Resources</Title>
                                    </Col>
                                </Row>
                                <Row gutter={[{ xs: 10, md: 16 }, { xs: 10, md: 16 }]}>
                                    <Col className="gutter-row" xs={24} md={8}>
                                        <div className="inner-card pointer"
                                            onClick={() => {
                                                history.push("/resume-search")
                                            }}
                                        >
                                            <div>
                                                <img src={SearchNormalIcon} alt="search" />
                                            </div>
                                            <div>
                                                <Title level={5}>Find Resources</Title>
                                                <Text>Find the perfect match from thousands of candidates.</Text>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={24} md={8}>
                                        <div className="inner-card pointer"
                                            onClick={() => {
                                                history.push("/create-opportunity")
                                            }}
                                        >
                                            <div>
                                                <img src={RankingIcon} alt="search" />
                                            </div>
                                            <div>
                                                <Title level={5}>Create an Opportunity</Title>
                                                <Text>Define an appealing possibility to attract top talent.</Text>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={24} md={8}>
                                        <div className="inner-card pointer"
                                            onClick={() => {
                                                history.push("/create-collaboration")
                                            }}
                                        >
                                            <div>
                                                <img src={QuickIcon} alt="search" />
                                            </div>
                                            <div>
                                                <Title level={5}>Quick Collaboration</Title>
                                                <Text>Start working together in a simple and initiative manner. </Text>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <Row gutter={[{ xs: 12, sm: 12, lg: 16, md: 16 }, { xs: 12, sm: 12, lg: 16, md: 16 }]}>
                                <Col xs={24} md={16} className="gutter-row d-flex flex-column gap-16" >
                                    <UserCompanyWidget labels={labels}
                                        history={history}
                                    />
                                    <div className="cards-new">
                                        <div className="d-flex gap-16 what-phillips">
                                            <div>
                                                <Title level={3}>What can Philip do for you?</Title>
                                                <Text className="fnt-s-16">Meet Phillip, the ProDoo AI—a premium service offered by ProDoo.
                                                    Phillip is your ultimate solution, handling the entire process of finding your
                                                    perfect gig and keeping you informed with real-time progress updates along the way.
                                                </Text>
                                                <button className="button-getting-started position-abs"
                                                    onClick={this.handleShowPhllip}                                                >
                                                    Getting Started <img src={ArrowRight} alt="" />
                                                </button>
                                            </div>
                                            <div style={{ textAlign: "right" }}>
                                                <img className="img-rebort" src={RebortIcon} alt="rebort" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={8} xs={24} className="gutter-row">
                                    <ShortlistWidget labels={labels}
                                        history={history}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
            </div>
        );
    }
}

const mapStateToProps = ({ systemLabel, userInfo }) => {
    const { labels } = systemLabel;
    const { user } = userInfo;
    const User = user ? user : StorageService.getUser();
    return { labels, User };
};
const actions = {
    notificationAction,
    onStateChangeActions,
};
export default connect(mapStateToProps, actions)(withRouter(NewDashboard));
