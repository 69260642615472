import React from "react";
import {
  Column,
  Button,
  Select,
  LoadingMask,
  ConfirmDialog,
} from "../../../../common";
import { map, isEmpty } from "lodash";
import {
  GetResumesByResumeId,
  GetUserDetailByResumeAndRequestId,
  getAllCollaborationsByStatusApi,
  GetSharedCollaborationInfo,
  ShareCollaborationApi,
  UpdateCollaborationEndDate,
  renameCollaborationApi,
} from "../../assortApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import "./finish-collaboration.scss";
import ImgSrc from "../../../../assets/images/present.svg";
import CollaborationDetail from "./components/CollaborationDetail";
import { isValidEmail } from "../../../../utilities";
import ResumeDetail from "../../components/ResumeDetail";
import Presentations from "../../components/PresentationDetails";
import moment from "moment";
import {
  getAllOpportunitiesApi,
  getOpportunityIprosResumeApi,
  addCollaborationApi,
  updateCollaborationApi,
  submitCollaborationReview,
  getReviewQuestions,
  getCollaborationReviewApi,
  getCollaborationReasonsApi,
  getAllCollaborationApi
} from "../../../Collaboration/common/collaborationApi";
import CreateNewCollaboration from "./components/CreateNewCollaboration";
import ReviewForm from "../../../../common/ReviewForm/ReviewForm";

class Collaborations extends React.Component {
  state = {
    CollborationCloseReasonId: null,
    reasonOptions: [
      { value: "1", label: "Job completed successfully" },
      { value: "2", label: "Job cancelled due to freelancer performance" },
      { value: "3", label: "Job canceled for other reasons" },
      { value: "4", label: "Another Reason" },
    ],
    options: [],
    acceptedCollaborations: [],
    selectedUser: [],
    selectedResume: [],
    allCollaborations: [],
    formCollapsed: false,
    isCollapsed1: false,
    isCollapsed2: false,
    isCollapsed3: true,
    isCollapsed4: true,
    isCollapsed5: true,
    allTimeSheets: [],
    weekTimelogsList: [],
    weekExpenselogsList: [],
    currentExpenselog: {},
    currentTimeReport: {
      StatusId: 1,
      isEmpty: true,
      Amount: "",
      Time: "",
    },
    currentTimelog: {},
    TimeSheetStatusId: 2,
    ExpenseSheetStatusId: 2,
    isLoading: false,
    selectedCollaboration: {},
    durationOptions: [
      { label: "Current Month", value: 1 },
      { label: "Last Month", value: 2 },
      { label: "Year to date", value: 3 },
    ],
    StartDate: moment().startOf("months"),
    EndDate: moment(),
    TimeDetail: {},
    ExpenseDetail: {},
    selectedDuration: {},
    isEndDateCalendarOpen: false,
    collaborationForm: {},
    opportunityList: [],
    iprosList: [],
    selectedIpro: {},
    collaborationForm: {
      invalidRequestId: false,
    },
    isCreateCollaboration: false,
  };

  getOpportunities = () => {
    getAllOpportunitiesApi(0).then((data) => {
      if (data.success) {
        const opportunityList = data.items.Sent.map((item) => ({
          ...item,
          value: item.RequestId,
          label: item.RequestName,
        }));
        this.setState({ opportunityList });
      }
    });
  };
  getOpportunityIProsResume = (requestId) => {
    getOpportunityIprosResumeApi(requestId).then((data) => {
      if (data.success) {
        const iprosList = map(data.items.user, (item) => {
          const newItem = {
            ...item,
            value: item.ResumeId,
            label: item.UserName,
          };

          return newItem;
        });
        this.setState({
          iprosList,
        });
      }
    });
  };

  handleFormSelectChange = (name, selectedOption) => {
    if (!selectedOption) return;
    const { collaborationForm } = this.state;
    const { value, RequestId } = selectedOption;
    if (name === "OpprtunityId") {
      if (RequestId) {
        this.getOpportunityIProsResume(RequestId);
        this.setState({
          collaborationForm: {
            ...collaborationForm,
            invalidRequestId: false,
            Request: RequestId,
            RequestId,
          },
        });
        this.GetIproPresentation(
          this.state.collaborationForm.ResumeId,
          RequestId
        );
        this.GetResumeDetail(this.state.collaborationForm.ResumeId);
        return;
      }
    }
    const { ResumeId } = selectedOption;
    if (name === "ResumeId") {
      if (ResumeId) {
        this.setState({
          collaborationForm: {
            ...collaborationForm,
            invalidResumeId: false,
            Resume: ResumeId,
            ResumeId,
          },
          selectedIpro: this.state.iprosList.find(
            (item) => item.ResumeId === ResumeId
          ),
        });
        this.GetIproPresentation(
          ResumeId,
          this.state.collaborationForm.RequestId
        );
        this.GetResumeDetail(ResumeId);
        return;
      }
    }
    if (name == "HourlyRateType") {
      if (value) {
        this.setState({
          collaborationForm: {
            ...collaborationForm,
            invalidHourlyRateType: false,
            HourlyRateType: value,
            value,
          },
        });
        return;
      }
    }
    if (name == "DurationType") {
      if (value) {
        this.setState({
          collaborationForm: {
            ...collaborationForm,
            invalidDurationType: false,
            DurationType: value,
            value,
          },
        });
        return;
      }
    }
  };

  handleDateChange = (d) => {
    const { collaborationForm } = this.state;
    try {
      this.setState({
        collaborationForm: {
          ...collaborationForm,
          invalidStartDate: false,
          StartDate: d,
          d,
        },
      });
    } catch (e) {
    }
  };

  handleSliderChange = (value) => {
    const { collaborationForm } = this.state;
    if (value) {
      this.setState({
        collaborationForm: {
          ...collaborationForm,
          Duration: value,
          value,
        },
      });
    }
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "Title") {
      this.validateField({ name, value });
      return;
    }
    if (name == "Description") {
      this.validateField({ name, value });
      return;
    }
    if (name === "HourlyRate") {
      if (isNaN(value)) return;
      this.validateField({ name, value });
    }
  };

  validateSelect = (name) => {
    const { collaborationForm } = this.state;
    this.setState({
      collaborationForm: {
        ...collaborationForm,
        [`invalid${name}`]: true,
      },
    });
  };

  validateField = ({ name, value }) => {
    const { collaborationForm } = this.state;

    this.setState({
      collaborationForm: {
        ...collaborationForm,
        [name]: value,
        [`invalid${name}`]: !value,
      },
    });
  };

  makeMandatoryFieldRed = () => {
    const { collaborationForm } = this.state;
    this.setState({
      collaborationForm: {
        ...collaborationForm,
        invalidTitle: !undefined,
        Title: undefined,

        invalidStartDate: !undefined,
        StartDate: undefined,

        invalidDurationType: !undefined,
        DurationType: undefined,

        invalidHourlyRate: !undefined,
        HourlyRate: undefined,

        invalidRequestId: !undefined,
        RequestId: undefined,

        invalidResumeId: !undefined,
        ResumeId: undefined,

        invalidHourlyRateType: !undefined,
        HourlyRateType: undefined,
      },
    });
  };

  handleCollaborationSave = () => {
    const { labels } = this.props;
    const { collaborationForm } = this.state;
    const { collTitleRequired } = labels;
    const {
      Title = "",
      HourlyRate = "",
      RequestId = "",
      ResumeId = "",
      Description = "",
      HourlyRateType = "",
      StartDate = "",
      DurationType = "",
      Duration = "4",
    } = collaborationForm;
    var collaboration = {
      Title,
      HourlyRate,
      HourlyRateType,
      RequestId,
      ResumeId,
      Description,
      StartDate,
      DurationType,
      Duration,
    };

    if (
      isEmpty(Title) &&
      !StartDate &&
      !DurationType &&
      isEmpty(HourlyRate) &&
      !HourlyRateType &&
      !RequestId &&
      !ResumeId
    ) {
      const info = {
        message: labels.COLLABORATION_CREATE_REQURIED_FIELD_MESSAGE,
        status: "error",
      };
      this.makeMandatoryFieldRed();
      this.props.notificationAction(info);
      return;
    }

    if (isEmpty(Title)) {
      const info = {
        message: collTitleRequired,
        status: "error",
      };
      this.validateField({ name: "Title", value: Title });
      this.props.notificationAction(info);
      return;
    }

    if (!StartDate) {
      const info = {
        message: labels.collStartDateRequired,
        status: "error",
      };
      this.validateField({ name: "StartDate", value: StartDate });
      this.props.notificationAction(info);
      return;
    }
    if (!DurationType) {
      const info = {
        message: labels.collDurationTypeRequired,
        status: "error",
      };
      this.validateField({ name: "DurationType", value: DurationType });
      this.props.notificationAction(info);
      return;
    }
    if (isEmpty(HourlyRate)) {
      const info = {
        message: "Hourly rate required",
        status: "error",
      };
      this.validateField({ name: "HourlyRate", value: HourlyRate });
      this.props.notificationAction(info);
      return;
    }
    if (!HourlyRateType) {
      const info = {
        message: labels.collCurrancyTypeRequired,
        status: "error",
      };
      this.validateField({ name: "HourlyRateType", value: HourlyRateType });
      this.props.notificationAction(info);
      return;
    }
    if (!RequestId) {
      const info = {
        message: labels.collOppRequired,
        status: "error",
      };
      this.validateField({ name: "RequestId", value: RequestId });
      this.props.notificationAction(info);
      return;
    }
    if (!ResumeId) {
      const info = {
        message: labels.collIproRequired,
        status: "error",
      };
      this.validateField({ name: "ResumeId", value: ResumeId });
      this.props.notificationAction(info);
      return;
    }
    this.setState({ isLoading: true });
    const duration = Duration + " " + DurationType;
    collaboration.Duration = duration;
    collaboration.Decription = Description;
    collaboration.StartDate = moment(StartDate).format("MM/DD/YYYY");
    addCollaborationApi({ collaboration })
      .then(() => {
        const info = {
          message: labels.collSuccessfullySent,
          status: "success",
        };
        this.props.notificationAction(info);
        this.setState({
          isLoading: false,
          collaborationForm: {},
          selectedIpro: {},
          iprosList: [],
        });
        this.GetAllCollaborations(this.state.selectedOption.url);
      })
      .catch(() => {
        this.setState({ isLoading: false });
        const info = {
          message: "Error",
          status: "error",
        };
        this.props.notificationAction(info);
      });
    return;
  };
  componentDidMount() {
    const {
      PENDING_COLLABORATIONS,
      DECLINED_COLLABORATIONS,
      ALL_ACTIVE_COLLAB,
    } = this.props.labels;
    this.setState({
      options: [
        {
          label: ALL_ACTIVE_COLLAB || "Active Collaborations",
          value: 1,
          url: "GetAllCollaborationByUserId?IsAccepted=true",
        },
        {
          label: PENDING_COLLABORATIONS || "Pending Collaborations",
          value: 2,
          url: "GetAllCollaborationByUserId",
        },
        {
          label: DECLINED_COLLABORATIONS || "Declined Collaborations",
          value: 3,
          url: "GetAllCollaborationByUserId?isDeclined=true",
        },
      ],
      selectedOption: { value: 1, label: ALL_ACTIVE_COLLAB, url: "GetAllCollaborationByUserId?IsAccepted=true" },
    });
     this.getOpportunities();
    this.GetAllCollaborations("GetAllCollaborationByUserId?IsAccepted=true");
  }

  GetAllCollaborations = (url) => {
    this.setState({isLoading: true})
    getAllCollaborationApi({ url }).then((res) => {
      if (res.items.length > 0) {
        const allCollaborations = res.items.map((coll) => {
          return {
            ...coll,
            Description: coll.Decription,
            HourlyRateType: !isNaN(coll.HourlyRateType)
              ? (this.props.Currencies ? this.props.Currencies.find(
                  (a) => (a.CurrencyId == parseInt(coll.HourlyRateType))
                ).Name:"")
              : (this.props.Currencies?  this.props.Currencies.find(
                (a) => (a.Name == coll.HourlyRateType)
              ).Name : ""),
          };
        });

        this.setState({
          allCollaborations
        });
        const spliturl = this.props.locationProp.pathname.match(/\d+/g);
        if (spliturl != null) {
          let filteredCollab = allCollaborations.filter(
            (coll) => coll.CollaborationId == spliturl[0]
          );
          this.handleCollaborationClick(filteredCollab[0]);
        } else {
          this.handleCollaborationClick(allCollaborations[0]);
        }
      }
      this.setState({
        isLoading: false
      });
    });
  };

  handleOptionChange = (option) => {
    this.setState({
      selectedOption: option,
    });
    this.GetAllCollaborations(option.url);
  };

  
  
  handleStartDateChange = (d) => {
    if (moment(d) >= moment(this.state.EndDate)) {
      const info = {
        status: "error",
        message: this.props.labels
          .Collaboration_Time_And_Expense_Start_Date_Validation_Message,
      };
      this.props.notificationAction(info);
      return;
    }
    this.setState({
      StartDate: d,
    });
    this.GetCollaborationTimeDetail(
      moment(d).format("DD/MM/YYYY"),
      moment(this.state.EndDate).format("DD/MM/YYYY"),
      this.state.selectedCollaboration
    );
    this.GetCollaborationExpenseDetail(
      moment(d).format("DD/MM/YYYY"),
      moment(this.state.EndDate).format("DD/MM/YYYY"),
      this.state.selectedCollaboration
    );
  };
  handleEndDateChange = (d) => {
    if (moment(d) <= moment(this.state.StartDate)) {
      const info = {
        status: "error",
        message: this.props.labels
          .Collaboration_Time_And_Expense_Date_Validation_Message,
      };
      this.props.notificationAction(info);
      return;
    }
    this.setState({
      EndDate: d,
    });
    this.GetCollaborationTimeDetail(
      moment(this.state.StartDate).format("DD/MM/YYYY"),
      moment(d).format("DD/MM/YYYY"),
      this.state.selectedCollaboration
    );
    this.GetCollaborationExpenseDetail(
      moment(this.state.StartDate).format("DD/MM/YYYY"),
      moment(d).format("DD/MM/YYYY"),
      this.state.selectedCollaboration
    );
  };
  handleCollaborationClick = (item, collaborations = null) => {
    if (!item) {
      this.setState({
        selectedCollaboration: {},
      });
      return;
    }
    this.getReviewDetails(item.CollaborationId);
    this.getCollaborationCloseReasons();
    if (!item.IsShared) {
      GetSharedCollaborationInfo(item.CollaborationId, this.props.token)
        .then((res) => {
          item = {
            ...item,
            SharedInfo: res.items.map((a) => ({
              ...a,
              CollaborationId: item.CollaborationId,
            })),
          };
          const updatedCollaborations =
            collaborations != null
              ? collaborations
              : this.state.allCollaborations;
          const allCollaborations = updatedCollaborations.map((a) => ({
            ...a,
            isSelected: a.CollaborationId == item.CollaborationId,
            isActive: a.CollaborationId == item.CollaborationId,
            IsShared: a.UserId != this.props.UserId,
          }));
          this.setState({
            selectedCollaboration: {
              ...item,
              newEmail: "",
              PreviousEndDate: item.EndDate,
            },
            allCollaborations: allCollaborations,
            isCollapsed2: false,
            isCollapsed3: false,
            collaborationForm: {},
            IsCloned: false,
            isCreateCollaboration: false,
            isLoading: false
          });
        })
        .catch((err) => console.log("Err ", err));
    } else {
      const updatedCollaborations =
        collaborations != null ? collaborations : this.state.allCollaborations;
      const allCollaborations = updatedCollaborations.map((a) =>
        a.CollaborationId == item.CollaborationId
          ? { ...a, isSelected: true, isActive: true }
          : { ...a, isSelected: false, isActive: false }
      );
      this.setState({
        selectedCollaboration: {
          ...item,
          newEmail: "",
          PreviousEndDate: item.EndDate,
        },
        collaborationForm: {},
        allCollaborations: allCollaborations,
        isCollapsed2: false,
        isLoading: false
      });
    }
    this.GetIproPresentation(item.ResumeId, item.RequestId);
    this.GetResumeDetail(item.ResumeId);
  };
  handleShareCollaborationInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      selectedCollaboration: {
        ...this.state.selectedCollaboration,
        newEmail: value,
      },
    });
  };
  handleShareCollaboration = () => {
    const {
      CollaborationId,
      newEmail,
      SharedInfo,
    } = this.state.selectedCollaboration;
    if (!isValidEmail(newEmail)) {
      const info = {
        status: "error",
        message: this.props.labels.VALID_EMAIL,
      };
      this.props.notificationAction(info);
      return;
    }
    ShareCollaborationApi(CollaborationId, newEmail, this.props.token).then(
      (res) => {
        if (res.success) {
          const sharedInfo = SharedInfo.concat({
            CollaborationId: CollaborationId,
            UserEmail: newEmail,
            UserId: res.items.UserId,
          });
          this.setState({
            selectedCollaboration: {
              ...this.state.selectedCollaboration,
              SharedInfo: sharedInfo,
              newEmail: "",
              PreviousEndDate: sharedInfo.EndDate,
            },
          });
          const info = {
            status: "success",
            message: this.props.labels.SNAPSHOT_COLLABORATIONS_SHARE_SUCCESS,
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            status: "error",
            message: res.message,
          };
          this.props.notificationAction(info);
        }
      }
    );
  };
  handleCollaborationEndDateChange = (d) => {
    this.setState({
      selectedCollaboration: {
        ...this.state.selectedCollaboration,
        EndDate: d,
      },
    });
    const EndDate = moment(d);
    const { CollaborationId } = this.state.selectedCollaboration;
    UpdateCollaborationEndDate(
      CollaborationId,
      moment(EndDate).format("DD/MM/YYYY")
    ).then((res) => {
      if (res.success) {
        const info = {
          status: "success",
          message: this.props.labels.Collaboration_Extend_Success_Message,
        };
        this.props.notificationAction(info);
        let allCollaborations = this.state.allCollaborations.map((a) =>
          a.CollaborationId == CollaborationId
            ? { ...a, EndDate: EndDate, PreviousEndDate: EndDate }
            : a
        );
        if (this.state.selectedOption.value == "2") {
          allCollaborations = allCollaborations.filter(
            (a) => a.CollaborationId != CollaborationId
          );
        }
        this.setState({
          allCollaborations,
          selectedCollaboration: {
            ...this.state.selectedCollaboration,
            PreviousEndDate: EndDate,
          },
          isEndDateCalendarOpen: false,
        });
        this.handleCollaborationClick(allCollaborations[0]);
        return;
      } else {
        const info = {
          status: "error",
          message: this.props.labels.Collaboration_Extend_Error_Message,
        };
        this.props.notificationAction(info);
        return;
      }
    });
  };
  handleCollaborationExtend = () => {
    this.setState({
      isEndDateCalendarOpen: true,
    });
  };
  handleDateOnBlur = () => {
    this.setState({
      isEndDateCalendarOpen: false,
    });
  };
  handleCollborationNameBlur = () => {
    const { CollaborationId, Title } = this.state.selectedCollaboration;
    renameCollaborationApi(CollaborationId, Title)
      .then((res) => {
        if (res.success) {
          const info = {
            status: "success",
            message: this.props.labels.collaborationTitleUpdatedLabel,
          };
          this.props.notificationAction(info);
          const { allCollaborations, selectedCollaboration } = this.state;
          this.setState({
            allCollaborations: allCollaborations.map((item) =>
              item.CollaborationId == selectedCollaboration.CollaborationId
                ? { ...selectedCollaboration, isActive: true, isSelected: true }
                : item
            ),
          });
        }
      })
      .catch((err) => console.log("Err ", err));
  };
  handleCollborationNameChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      selectedCollaboration: {
        ...this.state.selectedCollaboration,
        Title: value,
      },
    });
  };
  handleCollaborationClone = (item) => {
    const allCollaborations = this.state.allCollaborations.map((a) => ({
      ...a,
      isSelected: false,
      isActive: false,
    }));
    this.getOpportunityIProsResume(item.RequestId);
    this.setState({
      formCollapsed: false,
      collaborationForm: {
        ...item,
        Title: `${this.props.labels.copyofLabel} ${item.Title}`,
        IsCloned: true,
        Duration: item.Duration.split(" ")[0],
        DurationType: item.Duration.split(" ")[1],
      },
      selectedCollaboration: {},
      allCollaborations,
      IsCloned: true,
      isCreateCollaboration: false
    });
  };
  handleAddNewCollaboration = () => {
    const allCollaborations = this.state.allCollaborations.map((a) => ({
      ...a,
      isSelected: false,
      isActive: false,
    }));
    this.setState({
      collaborationForm: {
        Duration: 4,
      },
      selectedCollaboration: {},
      formCollapsed: false,
      allCollaborations,
      selectedResume: {},
      selectedUser: {},
    });
  };
  GetResumeDetail(ResumeId) {
    GetResumesByResumeId(ResumeId)
      .then((res) => {
        if (res.success) {
          this.setState({
            selectedResume: res.items,
            isLoading: false
          });
        }
      })
      .catch((response) => {
        console.log("error in get resume request ");
      });
  }

  GetIproPresentation(ResumeId, RequestId) {
    GetUserDetailByResumeAndRequestId(ResumeId, RequestId).then((res) => {
      if (res.success) {
        this.setState({
          selectedUser: res.items,
        });
      }
    });
  }
  handleCollaborationNameEdit = () => {
    this.setState({
      selectedCollaboration: {
        ...this.state.selectedCollaboration,
        isNameEdit: !this.state.selectedCollaboration.isNameEdit,
      },
    });
  };
  handleCollaborationClose = () => {
    const {
      InfoSearcherSentCollaborationCloseConfirmationMsg,
    } = this.props.labels;
    this.setState({
      dialogMessage: InfoSearcherSentCollaborationCloseConfirmationMsg,
    });
  };

  handleYesClick = () => {
    const { yesClickCount } = this.state;
    const { likeToRateCollaborationMessage } = this.props.labels;
    if (!yesClickCount || yesClickCount === 0) {
      this.setState({
        dialogMessage: likeToRateCollaborationMessage,
        yesClickCount: 1,
      });
      return;
    }
    this.setState({
      dialogMessage: "",
      yesClickCount: 2,
    });
  };

  closeCollaboration = () => {
    const { InfoSearcherCollaborationClosed } = this.props.labels;
    const { CollaborationId } = this.state.selectedCollaboration;
    const apiParams = `collaborationId=${CollaborationId}&IsClosed=true&IsSearcher=true`;
    this.setState({ isLoading: true, dialogMessage: "" });
    updateCollaborationApi({ apiParams }).then((response) => {
      const info = {
        message: InfoSearcherCollaborationClosed,
        status: "success",
      };
      this.props.notificationAction(info);
      this.setState({
        isLoading: false,
        selectedCollaboration: {},
        yesClickCount: 0,
      });
      this.GetAllCollaborations(this.state.selectedOption.url)
    });
  };

  handleNoClick = () => {
    const { yesClickCount } = this.props;
    if (yesClickCount === 0) {
      this.setState({
        dialogMessage: "",
        selectedCompany: null,
      });
      return;
    }
    this.closeCollaboration();
  };

  getReviewDetails = (CollaborationId) => {
    getReviewQuestions({ questionType: 1 })
      .then((res) => {
        const reviewQuestions = res.items.map((r) => ({
          ...r,
          ReviewQuestionValue: this.props.labels[r.Question],
          Answer: r.Question !== "Rating_Question5" ? r.Answer || 0 : "",
          CollaborationId: CollaborationId,
        }));
        getCollaborationReviewApi({
          collaborationId: CollaborationId,
          IsIProReview: false,
        })
          .then((reviewRes) => {
            const reviews = reviewRes.items;
            let currentCollborationCloseReasonId = null
            this.setState({
              reviewQuestions: reviewQuestions.map((a) => {
                const currentReview = reviews ? reviews.find(
                  (b) => b.ReviewQuestionId == a.ReviewQuestionId
                ): null;
                if(currentReview && a.ReviewQuestionId == currentReview.ReviewQuestionId){
                  currentCollborationCloseReasonId =  currentReview.CollborationCloseReasonId
                }
                return {
                  ...a,
                  Answer:
                    currentReview != null ? currentReview.Answer : a.Answer,
                  IsIProReview: false,
                };
              }),
              isAlreadyRated: reviews && reviews.length > 0 ? true : false,
            });
            this.onReasonFormSelectChange("", { value: currentCollborationCloseReasonId });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  getCollaborationCloseReasons = () => {
    getCollaborationReasonsApi()
      .then((reasons) => {
        const reasonsList = reasons.items.map((item) => ({
          ...item,
          value: item.CollborationCloseReasonId,
          label: this.props.labels[item.ReasonLabel],
        }));
        var systemlabels = this.props.labels;
        this.setState({
          reasonOptions: reasonsList,
        });
      })
      .catch((err) => console.log(err));
  };

  onReasonFormSelectChange = (answer, selectedOption) => {
    if (!selectedOption) return;
    const { reviewQuestions } = this.state;
    const { value } = selectedOption;
    this.setState({
      CollborationCloseReasonId: value,
      reviewQuestions: reviewQuestions.map((a) =>
        a.Question === "Rating_Question6"
          ? {
              ...a,
              Answer: null,
              CollborationCloseReasonId: value,
            }
          : a
      ),
    });
    
  };

  handleRatingChange = (rating, value) => {
    const { reviewQuestions } = this.state;
    this.setState({
      reviewQuestions: reviewQuestions.map((a) =>
        a.ReviewQuestionId == rating.ReviewQuestionId
          ? {
            ...a,
            Answer:
              rating.Question === "Rating_Question5"
                ? value.target.value
                : value,
          }
          : a
      ),
    });
  };

  handleSubmit = () => {
    const { reviewQuestions } = this.props;
    let ReviewSubmitModel = reviewQuestions;
    submitCollaborationReview({ collaborationReview: ReviewSubmitModel })
      .then((data) => { })
      .catch((err) => { });
    this.closeCollaboration();
  };

  render() {
    const {
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      isCollapsed4,
      isCollapsed5,
      selectedOption,
      selectedCollaboration,
      selectedUser,
      selectedResume,
      isEndDateCalendarOpen,
      formCollapsed,
      collaborationForm,
      isLoading,
      opportunityList,
      iprosList,
      dialogMessage,
      yesClickCount,
      isCreateCollaboration,
      IsCloned
    } = this.state;
    const {
      labels,
      isHelpActive,
      UserId,
      popupYesText = "Yes",
      popupNoText = "No",
      declinedStatus
    } = this.props;
    const {
      SNAPSHOT_COLLABORATIONS,
      SNAPSHOT_COLLABORATION_DETAIL,
      SNAPSHOT_COLLABORATION_TIME_AND_EXPENSES,
      SNAPSHOT_COLLABORATION_PRESENT,
      SNAPSHOT_COLLABORATION_RESUME,
    } = labels;

    return (
      <div className="collaborations-view">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="company-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-yes"
                onClick={this.handleYesClick}
              >
                {popupYesText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-no"
                onClick={this.handleNoClick}
              >
                {popupNoText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={SNAPSHOT_COLLABORATIONS}
            onClick={() => this.setState({ isCollapsed1: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn1"
          />
          <Column.Head>
            <div className="heading">{SNAPSHOT_COLLABORATIONS}</div>
            <Button
              className="collapseBtn"
              testId="Collapasebtn1"
              onClick={() => this.setState({ isCollapsed1: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          {(
            <Select
            readonly={true}
            searchable={false}
            testId={"collaborationDropdown"}
            name="AllPresentation"
            className="select-input"
            value={selectedOption}
            className={`select-input`}
            placeholder={labels.presentPrimaryRolePlaceholder}
            onChange={(selectedOption) =>
              this.handleOptionChange(selectedOption)
            }
            options={this.state.options}
            clearable={false}
          />
          )}
          <Column.Body>
            <div className="tictell-list">
              {this.state.allCollaborations.map((item) => (
                <div
                  key={item.CollaborationId}
                  test-data-id={`collaborations-list-item${item.isActive ? "activeItem" : ""
                    }`}
                  className={`tictell-list-item-container  ${item.isSelected ? "selected-item" : ""
                    } ${item.isActive ? "activeItem" : ""}`}
                >
                  <div
                    onClick={() => this.handleCollaborationClick(item)}
                    className={`dashItemImg ${item.PresenatationLogo ? "" : "no-dp"
                      }`}
                  >
                    <img
                      className="roundImg"
                      src={
                        item.PresenatationLogo ? item.PresenatationLogo : ImgSrc
                      }
                      alt={"UserName"}
                    />
                  </div>
                  <div
                    onClick={() => this.handleCollaborationClick(item)}
                    className="name-container"
                  >
                    <label test-data-id={`collabs-list-owner-username`}>
                      {item.UserName}
                    </label>
                    <label test-data-id={`collabs-list-owner-title`}>
                      {item.Title}
                    </label>
                  </div>
                  <Button
                    className={"clone-icon"}
                    onClick={() => this.handleCollaborationClone(item)}
                    testId={"collab-clone-btn"}
                    tooltipButton={"Clone this collaboration"}
                  />
                </div>
              ))}
              <Button
                mainClassName={"action-button-wrapper"}
                testid={`add-newLog-btn`}
                className={`add-btn`}
                onClick={() =>
                  this.setState({
                    isCreateCollaboration: true,
                    IsCloned: false,
                    collaborationForm: {},
                  })
                }
                // isHelpActive={isHelpActive}
                testIdHelp={"add-newLog-btn-help"}
                tooltipHelp={labels.tictell_add_timelog}
                tooltipButton={labels.ToolTipAddTimelog}
              />
            </div>
          </Column.Body>
        </Column>
        {(IsCloned || isCreateCollaboration ) ? (
          <Column collapse={formCollapsed} className="col-2">
            <Column.Collapsed
              collapse={formCollapsed}
              onClick={() => this.setState({ formCollapsed: false })}
              isHelpActive={isHelpActive}
              tooltipPlace="left"
              text={"Collaboration Details"}
              tooltipButton={
                labels.COLLABORATION_CREATE_SECTION_EXPAND_BUTTON_TOOLTIP
              }
              tooltipHelp={
                labels.COLLABORATION_CREATE_SECTION_EXPAND_BUTTON_HELP
              }
              testId="collaboration-form-expand-btn"
              testIdHelp="collaboration-form-expand-help-btn"
            />
            <Column.Head>
              <Column.HeaderTitle isActive={true}>
                {"Collaboration Details"}
              </Column.HeaderTitle>

              <Button
                onClick={() => this.setState({ formCollapsed: true })}
                className="collapseBtn"
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                tooltipButton={
                  labels.COLLABORATION_CREATE_SECTION_COLLAPSE_BUTTON_TOOLTIP
                }
                tooltipHelp={
                  labels.COLLABORATION_CREATE_SECTION_COLLAPSE_BUTTON_HELP
                }
                testId="collaboration-form-collapse-btn"
                testIdHelp="collaboration-form-collapse-help-btn"
              />
            </Column.Head>
            <Column.Body>
              <CreateNewCollaboration
                collaborationForm={collaborationForm}
                onCollaborationSave={this.handleCollaborationSave}
                onFormSelectChange={this.handleFormSelectChange}
                labels={labels}
                isLoading={isLoading}
                onFormFieldChange={this.handleFormFieldChange}
                onDateChange={this.handleDateChange}
                onSliderChange={this.handleSliderChange}
                opportunityList={opportunityList}
                isCreateCollaboration={isCreateCollaboration}
                iprosList={iprosList}
                Currencies={this.props.Currencies}
              />
            </Column.Body>
          </Column>
        ): (
          <Column collapse={isCollapsed2} className="col-2">
            <Column.Collapsed
              text={SNAPSHOT_COLLABORATION_DETAIL}
              onClick={() => this.setState({ isCollapsed2: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandbtn2"
            />
            <Column.Head>
              <div className="heading">{SNAPSHOT_COLLABORATION_DETAIL}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed2: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapsebtn2"
              />
            </Column.Head>
            {!isCollapsed2 && (
              <div
                className={`tictell-list-item-container activeItem collaboration-heading`}
              >
                <div
                  className={`dashItemImg ${selectedCollaboration.PresenatationLogo ? "" : "no-dp"
                    }`}
                >
                  <img
                    className="roundImg"
                    src={
                      selectedCollaboration.PresenatationLogo
                        ? selectedCollaboration.PresenatationLogo
                        : ImgSrc
                    }
                    alt={"UserName"}
                  />
                </div>
                <div className="name-container">
                  <label test-data-id={`collabs-details-owner-username`}>
                    {selectedCollaboration.UserName}
                  </label>
                  <label test-data-id={`collabs-details-owner-title`}>
                    {selectedCollaboration.Title}
                  </label>
                </div>
                <span
                  className={`${selectedCollaboration.IsShared ? "shared-icon" : ""
                    }`}
                />
              </div>
            )}
            <Column.Body>
              <CollaborationDetail
                labels={labels}
                declinedStatus = {declinedStatus}
                selectedCollaboration={selectedCollaboration}
                handleShareCollaborationDelete={
                  this.handleShareCollaborationDelete
                }
                handleShareCollaborationInputChange={
                  this.handleShareCollaborationInputChange
                }
                handleCollaborationShare={this.handleShareCollaboration}
                handleCollaborationEndDateChange={
                  this.handleCollaborationEndDateChange
                }
                handleCollaborationNameEdit={this.handleCollaborationNameEdit}
                isEndDateCalendarOpen={isEndDateCalendarOpen}
                handleDateOnBlur={this.handleDateOnBlur}
                handleCollborationNameBlur={this.handleCollborationNameBlur}
                handleCollborationNameChange={this.handleCollborationNameChange}
                isHelpActive={isHelpActive}
                onCollaborationClose={this.handleCollaborationClose}
              ></CollaborationDetail>
            </Column.Body>
          </Column>
        )}
        {yesClickCount && yesClickCount == 2 && (
          <Column collapse={isCollapsed5} className="col-4">
            <Column.Collapsed
              text={SNAPSHOT_COLLABORATION_RESUME}
              onClick={() => this.setState({ isCollapsed5: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandbtn5"
            />
            <Column.Head>
              <div className="heading">{SNAPSHOT_COLLABORATION_RESUME}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed5: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapsebtn5"
              />
            </Column.Head>
            <Column.Body className="flex">
              <ReviewForm
                selectedCollaboration={selectedCollaboration}
                onCloseCollaboration={this.closeCollaboration}
                reviewQuestions={this.state.reviewQuestions}
                onSubmit={this.handleSubmit}
                onRatingChange={this.handleRatingChange}
                isAlreadyRated={this.state.isAlreadyRated}
                starRatedColor="#9783cf"
                starHoverColor="#9783cf"
                onFormSelectChange={this.onReasonFormSelectChange}
                options={this.state.reasonOptions}
                CollborationCloseReasonId={this.state.CollborationCloseReasonId}
                reviewQuestions={this.props.reviewQuestions}
                isInActiveView = {this.props.isInActiveView}
              />
            </Column.Body>
          </Column>
        )}
        {
          <Column collapse={isCollapsed4} className="col-3">
            <Column.Collapsed
              text={SNAPSHOT_COLLABORATION_PRESENT}
              onClick={() => this.setState({ isCollapsed4: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandbtn4"
            />
            <Column.Head>
              <div className="heading">{SNAPSHOT_COLLABORATION_PRESENT}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed4: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapsebtn4"
              />
            </Column.Head>
            <Column.Body>
              <Presentations
                selectedUser={selectedUser}
                labels={this.props.labels}
              />
            </Column.Body>
          </Column>
        }
        {
          <Column collapse={isCollapsed5} className="col-4">
            <Column.Collapsed
              text={SNAPSHOT_COLLABORATION_RESUME}
              onClick={() => this.setState({ isCollapsed5: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipFeedBackExpandCreate}
              tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
              tooltipPlace="left"
              testId="expandbtn5"
            />
            <Column.Head>
              <div className="heading">{SNAPSHOT_COLLABORATION_RESUME}</div>
              <Button
                className="collapseBtn"
                onClick={() => this.setState({ isCollapsed5: true })}
                tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
                tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
                testId="collapsebtn5"
              />
            </Column.Head>
            <Column.Body>
              <ResumeDetail resume={selectedResume} />
            </Column.Body>
          </Column>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Collaborations);
