import React, { Component } from "react";
import { Panel, ListGroup, Input, Select, Button } from "../../../../common";
import AsyncSelect from "react-select/lib/Async";
import { map, isEmpty, filter } from "lodash";
import { getCertificationsApi } from "../resumeEditApi";
import moment from "moment";
import SuggestedCertification from "./SuggestedCertification";
class Certification extends Component {
  state = {
    yearsList: [],
  };
  componentDidMount() {
    this.handleYearsLoad();
  }
  handleYearsLoad = () => {
    const currentYear = new Date().getFullYear();
    var startYear = 1970;
    var yearsList = [];
    while (startYear <= currentYear) {
      startYear++;
      const newYear = {
        value: startYear,
        label: startYear,
      };
      yearsList.push(newYear);
    }
    this.setState({
      yearsList,
    });
  };
  handleCertificationLoad = (searchKey, callback) => {
    if (isEmpty(searchKey)) {
      const { certificationDefaultOptions } = this.props;
      callback(null, {
        options: map(certificationDefaultOptions, (Certification) => {
          return {
            ...Certification,
            label: Certification.CertificationValue,
            value: Certification.CertificationId,
          };
        }),
      });
      return;
    }
    if (searchKey.length < 3) {
      const { certificationDefaultOptions } = this.props;
      const filteredCertifications = filter(
        certificationDefaultOptions,
        (item) => {
          if (item.CertificationValue.indexOf(searchKey) > -1) return item;
        }
      );
      callback(null, {
        options: map(filteredCertifications, (Certification) => {
          return {
            ...Certification,
            label: Certification.CertificationValue,
            value: Certification.CertificationId,
          };
        }),
      });
      return;
    }
    getCertificationsApi({ searchKey }).then((response) => {
      if (response.success) {
        callback(null, {
          options: map(response.items, (Certification) => {
            return {
              ...Certification,
              label: Certification.CertificationValue,
              value: Certification.CertificationId,
            };
          }),
        });
      }
    });
  };
  render() {
    const {
      isHelpActive,
      labels,
      certificationsList,
      onCertificationNameChange,
      onCertificationLevelChange,
      onCertificationDelete,
      onCertificationAdd,
      onCertificationBlur,
      suggestedCertifications,
      onCertificationSuggestionButtonClick,
      onSuggesstedCertificationAdd,
      suggestObject,
    } = this.props;
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>
            {labels.RESUME_EDIT_CERTIFICATION_SECTION_TITLE}
          </Panel.Title>
          <Panel.Add
            onClick={onCertificationAdd}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={labels.RESUME_EDIT_CERTIFICATION_ADD_BUTTON_TOOLTIP}
            tooltipHelp={labels.RESUME_EDIT_CERTIFICATION_ADD_BUTTON_HELP}
            testId="resume-edit-certification-add-btn"
            testIdHelp="resume-edit-certification-add-help-btn"
          />
        </Panel.Heading>
        <Panel.Body>
          {suggestedCertifications && !isEmpty(suggestObject) && (
            <SuggestedCertification
              suggestedCertificationsList={suggestedCertifications}
              onSuggesstedCertificationAdd={onSuggesstedCertificationAdd}
              suggestObject={suggestObject}
              labels={labels}
            />
          )}
          <ListGroup>
            {certificationsList.map((item) => (
              <ListGroup.Item key={item.uniqueId}>
                <ListGroup.Column size={0}>
                  <Button
                    onClick={() =>
                      onCertificationSuggestionButtonClick(
                        item.CertificationId,
                        "Certification",
                        item.CertificationValue,
                        item.uniqueId
                      )
                    }
                    className={`draggable-btn ${
                      item.isCandidate ? "candidate" : ""
                    } ${item.isActive ? "active" : ""}`}
                    isHelpActive={isHelpActive}
                    tooltipPlace="left"
                    tooltipButton={
                      item.isCandidate
                        ? `${item.CertificationValue} ${labels.RESUME_EDIT_CANDIDATE_SUGGESTION_BUTTON_TOOLTIP}`
                        : `${labels.RESUME_EDIT_SUGGESTION_BUTTON_TOOLTIP} ${item.CertificationValue}`
                    }
                    tooltipHelp={
                      item.isCandidate
                        ? `${item.CertificationValue} ${labels.RESUME_EDIT_CANDIDATE_SUGGESTION_BUTTON_HELP}`
                        : `${labels.RESUME_EDIT_SUGGESTION_BUTTON_HELP} ${item.CertificationValue}`
                    }
                    testId="resume-edit-certification-suggestion-btn"
                    testIdHelp="resume-edit-certification-suggestion-help-btn"
                    disabled={
                      item.isCandidate || item.CertificationValue === ""
                    }
                  />
                </ListGroup.Column>
                <ListGroup.Column>
                  <AsyncSelect
                    className={`select-certification-name select-input ${
                      item.isInvalid ? "inValid" : ""
                    }`}
                    placeholder={
                      labels.RESUME_EDIT_CERTIFICATION_NAME_PLACEHOLDER
                    }
                    defaultOptions
                    searchable={true}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    loadOptions={this.handleCertificationLoad}
                    onChange={(options) =>
                      onCertificationNameChange(options, item.uniqueId)
                    }
                    value={{
                      value: item.CertificationId,
                      label: item.CertificationValue,
                    }}
                    onBlur={(options) =>
                      onCertificationBlur(
                        item.CertificationId,
                        item.uniqueId,
                        options
                      )
                    }
                    clearable
                    disabled={item.isCandidate}
                  />
                </ListGroup.Column>
                <ListGroup.Column>
                  <Select
                    name={"Language"}
                    className={`select-certification-level select-input`}
                    value={moment(item.CertificationDate, "MM/DD/YYYY").format(
                      "YYYY"
                    )}
                    onChange={(options) =>
                      onCertificationLevelChange(options, item.uniqueId)
                    }
                    options={this.state.yearsList}
                  />
                </ListGroup.Column>
                <ListGroup.Column size={0}>
                  <div
                    // disabled={isDisabled}
                    onClick={() => onCertificationDelete(item)}
                    className="close_btn_purple"
                  >
                    <i className="fa fa-times-circle" />
                  </div>
                </ListGroup.Column>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Panel.Body>
      </Panel>
    );
  }
}

export default Certification;
