import React, { Component } from "react";
import { PageWrapper } from "../../components";
import { connect } from "react-redux";
import { filter, find, map } from "lodash";
import { Column, List, Button, ConfirmDialog, LoadingMask } from "../../common";
import "./components/usersettings.scss";
import Profile from "./components/Profile";
import UserPhoneNumbers from "./components/UserPhoneNumbers";
import UserEmails from "./components/UserEmails";
import ChangePassword from "./components/ChangePassword";
import SocialMediaLinks from "./components/SocialMediaLinks";
import UserNotifications from "./components/UserNotifications";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion";
import {
  getUserProfileApi,
  getCountriesApi,
  updateUserProfileApi,
  getUserPhoneNumberApi,
  getUserEmailAddressApi,
  updatePhoneNumberSettingApi,
  updateEmailSettingApi,
  deleteUserEmailApi,
  deleteUserPhoneApi,
  getSocialMediaApi,
  getUserSocialMediaLinks,
  SaveSocialMediaLink
} from "../Settings/settingsApi";
import { RESET_LANDINGPAGE_URL } from "../../utilities/enviroments";
import { StorageService } from "../../../src/api/storage";
import "./settings.scss";
import uuidv1 from "uuid/v1";
import { notificationAction } from "../../actions";
import {
  logoutApi,
  getUserApi,
} from "../../../src/components/Navigation/navigationApi";
import { helpActiveAction } from "../../actions";
import {
  logoutAction,
  loginAcionUpdate,
} from "../../../src/store/initialConfig";
import { loadImageOrientation } from "../../utilities/helpers";
import { onStateChangeAction } from "./settingAction";

class Settings extends Component {
  state = {
    userPhoneList: [],
    userEmailList: [],
    selectedProfile: null,
    countries: null,
    dialogMessage: "",
    dialogMessagePass: "",
    isInvalidUserFirstname: false,
    isInvalidUserLastname: false,
    isInvalidPhone: false,
    isInvalidEmail: false,
    isUserNameSelected: true,
    isPhoneNumberSelected: false,
    addItemMessage: "",
    isEmailSelected: false,
    isDeleteEmail: false,
    isDeletePhoneNumber: false,
    emailIdToDelete: 0,
    phoneIdToDelete: 0,
    isChangePassSelected: false,
    isEmailNotificationSelected: false,
    rightcoloumHeading: "",
    isLoading: false,
    isFetching: false,
    passwordEmailsList: [],
    settingsList: [],
    socialLinks: [],
    selectedItem: {
      Id: 1,
    },
  };

  componentDidMount() {
    this.props.onStateChangeAction({
      passwordEmailsList: [
        {
          Id: 4,
          Name: this.props.labels
            .USER_SETTING_LIST_NOTIFICATION_PASSWORD_HEADING,
        },
        {
          Id: 5,
          Name: this.props.labels
            .USER_SETTING_LIST_NOTIFICATION_NOTIFICATION_HEADING,
        },
      ],
      settingsList: [
        {
          Id: 1,
          Name: this.props.labels.USER_INFO_LABEL,
        },
        {
          Id: 2,
          Name: this.props.labels.USER_SETTING_LIST_PROFILE_PHONE_HEADING,
        },
        {
          Id: 3,
          Name: this.props.labels.USER_SETTING_LIST_PROFILE_EMAIL_HEADING,
        },
        {
          Id: 7,
          Name: this.props.labels.USER_SOCIAL_LINKS_LBL ||"User Social Links",
        },
      ],
    });
    if (this.props.isFetching) {
      this.getUserEmailAddress();
      this.getUserPhoneNumber();
    }
    this.getUserProfile();
    this.getSocialMedia();
    this.getCountries();
    if (this.props.location.pathname.includes("unsubscribe")) {
      this.openEmailNotificationAccordionOpen();
    }
    const query = new URLSearchParams(this.props.location.search);
    const isPasswordChange = query.get("isPasswordChange");
    if (isPasswordChange != null) {
      this.props.onStateChangeAction({
        isChangePassSelected: true,
      });
    }
  }

  openEmailNotificationAccordionOpen = () => {
    const { passwordEmailsList } = this.props;
    let selectedItem = find(passwordEmailsList, { Id: 4 });
    selectedItem = {
      Id: 5,
      Name: '"Email Notifications"',
    };
    this.props.onStateChangeAction({
      isEmailNotificationSelected: true,
      isChangePassSelected: false,
      isEmailSelected: true,
      isPhoneNumberSelected: false,
      isUserNameSelected: false,
      selectedItem,
      expanded: {
        notification: true,
      },
    });
  };

  handleHelpClick = () => {
    const { helpActiveAction, isHelpActive } = this.props;
    helpActiveAction({ isHelpActive: !isHelpActive });
  };

  getUserEmailAddress = () => {
    getUserEmailAddressApi().then((response) => {
      if (response.success) {
        const userEmailList = response.items.EmailList.map((item) => ({
          ...item,
          uniqueId: uuidv1(),
          isInvalid: false,
        }));

        this.props.onStateChangeAction({
          userEmailList: userEmailList,
        });
      }
    });
  };

  getUserPhoneNumber = () => {
    getUserPhoneNumberApi().then((response) => {
      if (response.success) {
        const userPhoneList = response.items.PhoneList.map((item) => ({
          ...item,
          uniqueId: uuidv1(),
          isInvalid: false,
        }));
        this.props.onStateChangeAction({
          userPhoneList: userPhoneList,
        });
      }
    });
  };

  getUserProfile = () => {
    this.props.onStateChangeAction({ isFetching: true });
    getUserProfileApi().then((response) => {
      if (response.success) {
        this.props.onStateChangeAction({
          selectedProfile: response.items,
          isFetching: false,
        });
      }
    });
  };

  getUserSocialMediaLinks = () => {
    getUserSocialMediaLinks().then((data) => {
      if (data.success) {
        this.props.onStateChangeAction({
          UserSocialMediaLinks: data.items,
        });
        this.mergeSocialMediaList(data.items);
      }
    });
  };
  getSocialMedia = () => {
    getSocialMediaApi().then((data) => {
      if (data.success) {
        this.getUserSocialMediaLinks();
        const socialMedia = data.items.map((item) => ({
          ...item,
          URL: "",
          SocialMediaId: item.Id,
        }));
        this.props.onStateChangeAction({
          socialMedia,
        });
      }
    });
  };

  mergeSocialMediaList = (UserSocialMediaLinks) => {
    const { socialMedia } = this.props;
    var socialLinks = map(socialMedia, function(item) {
      return {
        ...item,
        ...find(UserSocialMediaLinks, ["SocialMediaId", item.Id]),
      };
    });
    this.handleSocialMediaActive(socialLinks[0]);
    this.setState({
      socialLinks
    })
  };

  handleSocialMediaActive = (activeItem) => {
    const { socialLinks } = this.state;
    const SocialLinks = socialLinks.map((item) => ({
      ...item,
      isActive: item.SocialMediaId === activeItem.SocialMediaId,
    }));
    this.setState({
      socialLinks: SocialLinks
    });
  };

  handleSocialMediaSave = () => {
    const { socialLinks } = this.state;
      const SocialLinks = socialLinks.find((item) => item.isActive);
      
      SaveSocialMediaLink(SocialLinks).then((res) => {
        if(res.success){
          const info = {
            message: "Social Link saved successfully",
            status: "success",
          };
          this.props.notificationAction(info);
        }
       
      });
  };

  handleSocialMediaChange = (selectedItem, value) => {
    const { socialLinks } = this.state;
    const SocialLinks = socialLinks.map((item) => ({
      ...item,
      URL: selectedItem.Id === item.Id ? value : item.URL,
    }));
    this.setState({
      socialLinks: SocialLinks
    });
  };

  getCountries = () => {
    getCountriesApi().then((data) => {
      const filterCountries = data.items.filter((item) => item.CountryName);

      const countries = filterCountries.map((item) => ({
        ...item,
        value: item.CountryId,
        label: item.CountryName,
      }));
      this.props.onStateChangeAction({ countries });
    });
  };
  

  handleSettingsList = (id) => {
    if (id == 1 || id == 2 || id == 3 || id == 7) {
      const { settingsList } = this.props;
      let selectedItem = find(settingsList, { Id: id });
      selectedItem = {
        ...selectedItem,
        Id: selectedItem.Id,
      };
      this.props.onStateChangeAction({ selectedItem });
    }
    if (id == 4 || id == 5) {
      const { passwordEmailsList } = this.props;
      let selectedItem = find(passwordEmailsList, { Id: id });
      selectedItem = {
        ...selectedItem,
        Id: selectedItem.Id,
      };
      this.props.onStateChangeAction({ selectedItem });
    }

    switch (id) {
      case 1:
        this.props.onStateChangeAction({
          isUserNameSelected: true,
          isPhoneNumberSelected: false,
          isSocialMediaSelected: false,
          isEmailSelected: false,
          isChangePassSelected: false,
          isEmailNotificationSelected: false,
          rightcoloumHeading: this.props.labels
            .USER_SETTING_LIST_PROFILE_PROFILE_HEADING,
        });
        break;
      case 2:
        this.props.onStateChangeAction({
          isUserNameSelected: false,
          isPhoneNumberSelected: true,
          isEmailSelected: false,
          isChangePassSelected: false,
          isSocialMediaSelected: false,
          isEmailNotificationSelected: false,
          rightcoloumHeading: this.props.labels
            .USER_SETTING_LIST_PROFILE_PHONE_HEADING,
          addItemMessage: this.props.labels
            .USER_SETTING_DETAILS_ADD_PHONE_NUMBER,
        });
        break;
      case 3:
        this.props.onStateChangeAction({
          isUserNameSelected: false,
          isPhoneNumberSelected: false,
          isEmailSelected: true,
          isChangePassSelected: false,
          isSocialMediaSelected: false,
          isEmailNotificationSelected: false,
          rightcoloumHeading: this.props.labels
            .USER_SETTING_LIST_PROFILE_EMAIL_HEADING,
          addItemMessage: this.props.labels.USER_SETTING_DETAILS_ADD_EMAIL,
        });
        break;
      case 4:
        this.props.onStateChangeAction({
          isUserNameSelected: false,
          isPhoneNumberSelected: false,
          isEmailSelected: false,
          isChangePassSelected: true,
          isEmailNotificationSelected: false,
          isSocialMediaSelected: false,
          rightcoloumHeading: this.props.labels
            .USER_SETTING_LIST_NOTIFICATION_PASSWORD_HEADING,
        });
        break;
      case 5:
        this.props.onStateChangeAction({
          isUserNameSelected: false,
          isPhoneNumberSelected: false,
          isEmailSelected: false,
          isChangePassSelected: false,
          isEmailNotificationSelected: true,
          isSocialMediaSelected: false,
          rightcoloumHeading: this.props.labels
            .USER_SETTING_LIST_NOTIFICATION_NOTIFICATION_HEADING,
        });
        break;
      case 7:
        this.props.onStateChangeAction({
          isUserNameSelected: false,
          isPhoneNumberSelected: false,
          isEmailSelected: false,
          isChangePassSelected: false,
          isEmailNotificationSelected: false,
          isSocialMediaSelected: true,
          rightcoloumHeading: this.props.labels
            .USER_SETTING_LIST_NOTIFICATION_NOTIFICATION_HEADING,
        });
        break;
    }
  };

  handleItemAdd = () => {
    const {
      isPhoneNumberSelected,
      isEmailSelected,
      userEmailList,
    } = this.props;

    if (isPhoneNumberSelected) {
      this.handlePhoneNumberAdd();
    }

    if (isEmailSelected) {
      this.handleEmailAdd(userEmailList);
    }
  };

  handlePhoneNumberAdd = () => {
    const { userPhoneList } = this.props;
    const invalidPhone = filter(userPhoneList, (item) => item.isInvalidPhone);
    if (invalidPhone.length > 0) {
      const info = {
        message: this.props.labels.USER_SETTING_PHONE_VALIDATION_MESSAGE,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }

    const phoneItem = {
      UserPhonenumberId: 0,
      UserId: 0,
      uniqueId: uuidv1(),
    };
    this.props.onStateChangeAction({
      userPhoneList: [phoneItem, ...userPhoneList],
    });
  };

  handleEmailAdd = (emailList) => {
    const invalidEmails = filter(emailList, (item) => item.isInvalidEmail);
    if (invalidEmails.length > 0) {
      const info = {
        message: this.props.labels.USER_SETTING_EMAIL_VALIDATION_MESSAGE,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }

    const { userEmailList } = this.props;
    const emailItem = {
      UserEmailId: 0,
      UserId: 0,
      uniqueId: uuidv1(),
    };
    this.props.onStateChangeAction({
      userEmailList: [emailItem, ...userEmailList],
    });
  };

  handlePhoneNumberDelete = (deletedItem) => {
    if (deletedItem.UserPhonenumberId == 0) {
      const phoneList = this.props.userPhoneList.filter(
        (item) => deletedItem.uniqueId !== item.uniqueId
      );
      this.props.onStateChangeAction({ userPhoneList: phoneList });
    } else {
      this.props.onStateChangeAction({
        dialogMessage: "Do you want to delete phone number?",
        isDeletePhoneNumber: true,
        phoneIdToDelete: deletedItem.UserPhonenumberId,
      });
    }
  };
  handleEmailDelete = (deletedItem) => {
    if (deletedItem.UserEmailId == 0) {
      const emailList = this.props.userEmailList.filter(
        (item) => deletedItem.uniqueId !== item.uniqueId
      );
      this.props.onStateChangeAction({ userEmailList: emailList });
    } else {
      this.props.onStateChangeAction({
        dialogMessage: "Do you want to delete email address?",
        isDeleteEmail: true,
        emailIdToDelete: deletedItem.UserEmailId,
      });
    }
  };

  //Text Field Change Event Handler
  handlePhoneNumberChange = (uniqueId, e) => {
    const { value } = e.target;
    const userPhoneList = this.props.userPhoneList.map((item) => ({
      ...item,
      UserPhonenumberValue:
        item.uniqueId === uniqueId ? value : item.UserPhonenumberValue,
      isInvalidPhone:
        item.uniqueId === uniqueId ? this.validatePhone(value) : false,
    }));
    this.props.onStateChangeAction({
      userPhoneList,
    });
  };

  handleEmailChange = (uniqueId, e) => {
    const { value } = e.target;
    const userEmailList = this.props.userEmailList.map((item) => ({
      ...item,
      UserEmailValue: item.uniqueId === uniqueId ? value : item.UserEmailValue,
      isInvalidEmail:
        item.uniqueId === uniqueId ? this.validatEmail(value) : false,
    }));
    this.props.onStateChangeAction({
      userEmailList,
    });
  };

  validatEmail = (email) => {
    var re = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (
      email.trim() === null ||
      email === "" ||
      !re.test(email) ||
      this.emailAvailability(email)
    ) {
      return true;
    }
  };

  emailAvailability = (email) => {
    const filteremail = filter(this.props.userEmailList, function(o) {
      return o.UserEmailId != 0;
    });
    const found = find(filteremail, function(f) {
      return f.UserEmailValue === email;
    });
    if (found) {
      return true;
    }
  };

  phoneAvailability = (phone) => {
    const filterphone = filter(this.props.userPhoneList, function(o) {
      return o.UserPhonenumberId != 0;
    });
    const found = find(filterphone, function(f) {
      return f.UserPhonenumberValue === phone;
    });
    if (found) {
      return true;
    }
  };

  validatePhone = (phone) => {
    var re = /^[\d\s\+?\(?\)?\-?\.?]+$/;
    if (phone.length > 20) {
      return true;
    }
    if (
      phone.trim() === null ||
      phone === "" ||
      !re.test(phone) ||
      this.phoneAvailability(phone)
    ) {
      return true;
    }
  };
  isValidName = (value) => {
    return value.length < 50 ? true : false;
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    const { selectedProfile } = this.props;
    this.props.onStateChangeAction({
      selectedProfile: {
        ...selectedProfile,
        [name]: value,
      },
      [`isInvalid${name}`]: !(value && this.isValidName(value)),
    });
  };

  handleSaveUserSetting = () => {
    const {
      isInvalidUserFirstname,
      isInvalidUserLastname,
      isUserNameSelected,
      isPhoneNumberSelected,
      isEmailSelected,
      userEmailList,
      userPhoneList,
      isSocialMediaSelected
    } = this.props;

    if (isEmailSelected) {
      const invalidEmails = filter(
        userEmailList,
        (item) => item.isInvalidEmail
      );
      if (invalidEmails.length > 0) {
        const info = {
          message: this.props.labels.USER_SETTING_EMAIL_VALIDATION_MESSAGE,
          status: "error",
        };
        this.props.notificationAction(info);
        return;
      }
    }

    if (isSocialMediaSelected) {
      const { socialLinks } = this.state;
      const selectedLink = socialLinks.filter((item) => item.isActive);
      if(!!selectedLink.URL){
          const info = {
            message: "Please enter social media link to save",
            status: "error",
          };
          this.props.notificationAction(info);
          return;
      }
    }
    

    if (isPhoneNumberSelected) {
      const invalidPhone = filter(userPhoneList, (item) => item.isInvalidPhone);
      if (invalidPhone.length > 0) {
        const info = {
          message: this.props.labels.USER_SETTING_PHONE_VALIDATION_MESSAGE,
          status: "error",
        };
        this.props.notificationAction(info);
        return;
      }
    }

    if (isInvalidUserFirstname || isInvalidUserLastname) {
      const info = {
        message: this.props.labels.USER_SETTING_PROFILE_NAME_VALIDATION_MESSAGE,
        status: "info",
      };
      this.props.notificationAction(info);
      return;
    }

    this.props.onStateChangeAction({
      dialogMessage: this.props.labels.USER_SETTING_SAVE_CONFIRMATION_MESSAGE,
    });
  };

  SaveUserProfile = () => {
    const { selectedProfile } = this.props;
    const profileObj = {
      UserFirstname: selectedProfile.UserFirstname,
      UserLastname: selectedProfile.UserLastname,
      ProfilePicture: selectedProfile.ProfilePicture,
      CountryId: selectedProfile.CountryId,
    };

    updateUserProfileApi(profileObj).then((response) => {
      if (response.success) {
        const user = { ...this.props.user, FirstName: response.items };
        this.props.loginAcionUpdate({ user });
        const info = {
          message: this.props.labels.USER_SETTING_SAVE_PROFILE_SUCCESS_MESSAGE,
          status: "success",
        };
        this.props.notificationAction(info);
        this.getUserProfile();
        this.getCountries();
      }
    });
  };

  SaveUserPhones = () => {
    const { userPhoneList } = this.props;
    var settingObj = {
      PhoneNumberList: userPhoneList,
    };

    updatePhoneNumberSettingApi(settingObj).then(() => {
      const info = {
        message: this.props.labels.USER_SETTING_SAVE_PHONE_SUCCESS_MESSAGE,
        status: "success",
      };
      this.props.notificationAction(info);
      this.getUserPhoneNumber();
    });
  };

  SaveUserEmail = () => {
    const { userEmailList } = this.props;
    var settingObj = {
      EmailList: userEmailList,
    };

    updateEmailSettingApi(settingObj).then(() => {
      const info = {
        message: this.props.labels.USER_SETTING_SAVE_EMAIL_SUCCESS_MESSAGE,
        status: "success",
      };
      this.props.notificationAction(info);
      this.getUserEmailAddress();
    });
  };

  DeleteUserEmail = (emailId) => {
    deleteUserEmailApi({ emailId }).then((response) => {
      if (response.success) {
        const info = {
          message: "User email is successfully deleted.",
          status: "success",
        };
        this.props.notificationAction(info);
      } else {
        const info = {
          message: "User email is associated with user details.",
          status: "info",
        };
        this.props.notificationAction(info);
      }
      this.getUserEmailAddress();
      this.props.onStateChangeAction({ isDeleteEmail: false });
    });
  };

  DeleteUserPhoneNumber = (phoneId) => {
    deleteUserPhoneApi({ phoneId }).then((response) => {
      if (response.success) {
        const info = {
          message: "Phone number is successfully deleted.",
          status: "success",
        };
        this.props.notificationAction(info);
      } else {
        const info = {
          message: "Phone number is associated with company or presentaion.",
          status: "info",
        };
        this.props.notificationAction(info);
      }
      this.getUserPhoneNumber();
      this.props.onStateChangeAction({ isDeletePhoneNumber: false });
    });
  };

  handleYesClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "" });
    const {
      isUserNameSelected,
      isPhoneNumberSelected,
      isEmailSelected,
      isDeleteEmail,
      isDeletePhoneNumber,
      emailIdToDelete,
      phoneIdToDelete,
      isSocialMediaSelected
    } = this.props;
    if (isUserNameSelected) {
      this.SaveUserProfile();
      return;
    }
    if (isPhoneNumberSelected && !isDeletePhoneNumber) {
      this.SaveUserPhones();
      return;
    }
    if (isDeletePhoneNumber && phoneIdToDelete > 0) {
      this.DeleteUserPhoneNumber(phoneIdToDelete);
      return;
    }

    if (isEmailSelected && !isDeleteEmail) {
      this.SaveUserEmail();
    }
    if (isDeleteEmail && emailIdToDelete > 0) {
      this.DeleteUserEmail(emailIdToDelete);
      return;
    }
    if (isSocialMediaSelected ) {
      this.handleSocialMediaSave();
      return;
    }
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({ dialogMessage: "" });
  };

  handleLogoutClick = () => {
    logoutApi().then((response) => {
      if (response.success) {
        this.props.logoutAction();
        StorageService.clearAll();
        window.location.href = RESET_LANDINGPAGE_URL;
      }
    });
  };

  handleFormSelectChange = (event) => {
    if (!event) return;
    const { selectedProfile } = this.props;
    this.props.onStateChangeAction({
      selectedProfile: {
        ...selectedProfile,
        CountryId: event.CountryId,
      },
    });
  };
  updateAvatar = (image) => {
    const { selectedProfile } = this.props;

    this.props.onStateChangeAction({
      selectedProfile: {
        ...selectedProfile,
        ProfilePicture: image,
      },
    });
  };
  handleAvatarChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      loadImageOrientation(e.target.files[0], this.updateAvatar);
      e.target.value = "";
    }
  };

  OldPassCallback = (value) => {
    this.props.onStateChangeAction({
      invalidOldPassword: value,
    });
  };

  dialogMessagePassCallback = (message) => {
    this.props.onStateChangeAction({
      dialogMessagePass: message,
    });
  };

  getCurrentUser = () => {
    getUserApi().then((response) => {
      if (response.success) {
        const { user } = this.props;
        user.UserFirstname = response.items.UserFirstname;
        this.props.loginAcionUpdate({ user });
      }
    });
  };

  handleGetImgSrc = () => {
    const { selectedProfile } = this.props;
    this.props.onStateChangeAction({
      selectedProfile: {
        ...selectedProfile,
        ProfilePicture: null,
      },
    });
  };
  handleExpanded = (name) => {
    const { expanded } = this.props;
    this.props.onStateChangeAction({
      expanded: {
        // ...expanded,
        [name]: !expanded[name],
      },
    });
  };
  render() {
    const { labels, isHelpActive } = this.props;
    const {
      selectedItem,
      settingsList,
      passwordEmailsList,
      selectedProfile,
      listCollapsed,
      formCollapsed,
      userPhoneList,
      userEmailList,
      dialogMessage,
      dialogMessagePass,
      isInvalidUserFirstname,
      isInvalidUserLastname,
      isUserNameSelected,
      isPhoneNumberSelected,
      isEmailSelected,
      isChangePassSelected,
      isEmailNotificationSelected,
      rightcoloumHeading,
      isLoading,
      addItemMessage,
      countries,
      isFetching,
      invalidOldPassword,
      expanded,
      isSocialMediaSelected,
      SocialLinks
    } = this.props;
    return (
      <PageWrapper className="settings-page">
        {isLoading && <LoadingMask />}
        {dialogMessage && (
          <ConfirmDialog testId="passwrod-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="user-setting-save-yes"
                onClick={this.handleYesClick}
              >
                {"Yes"}
              </ConfirmDialog.Button>

              <ConfirmDialog.Button
                className="dialog-btn"
                testId="user-setting-save-no"
                onClick={this.handleNoClick}
              >
                {"No"}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}

        {dialogMessagePass && (
          <ConfirmDialog testId="setting-password-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessagePass}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="setting-password-delete-yes"
                onClick={this.handleLogoutClick}
              >
                {"Logout"}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={listCollapsed} className="col-1">
          <Column.Collapsed
            text={labels.USER_SETTING_LIST_TITLE}
            onClick={() =>
              this.props.onStateChangeAction({ listCollapsed: false })
            }
            tooltipButton={
              labels.USER_SETTING_LIST_SECTION_EXPAND_BUTTON_TOOLTIP
            }
            isHelpActive={isHelpActive}
            tooltipHelp={labels.USER_SETTING_LIST_SECTION_EXPAND_BUTTON_HELP}
            tooltipPlace="left"
          />
          <Column.Head className>
            <div className="heading">{labels.USER_SETTING_LIST_HEADING}</div>
            <Button
              className="collapseBtn"
              tooltipButton={
                labels.USER_SETTING_LIST_SECTION_COLLAPSE_BUTTON_TOOLTIP
              }
              tooltipHelp={
                labels.USER_SETTING_LIST_SECTION_COLLAPSE_BUTTON_HELP
              }
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              onClick={() =>
                this.props.onStateChangeAction({ listCollapsed: true })
              }
            />
          </Column.Head>
          <Column.Body>
            <Accordion>
              <AccordionItem
                expanded={expanded.basic}
                test-data-id="accordionBasicInfo"
              >
                <AccordionItemTitle
                  onClick={() => {
                    this.handleExpanded("basic");
                  }}
                  test-data-id="namePhoneEmailDiv"
                >
                  {labels.USER_SETTING_LIST_PROFILE_HEADING}

                  <div className="accordion__arrow" />
                </AccordionItemTitle>
                <AccordionItemBody>
                  <List>
                    {settingsList.map((item) => (
                      <List.ListItem
                        onClick={() => this.handleSettingsList(item.Id)}
                        isSelected={item.Id === selectedItem.Id}
                        key={item.Id}
                      >
                        {item.Name}
                      </List.ListItem>
                    ))}
                  </List>
                </AccordionItemBody>
              </AccordionItem>

              <AccordionItem
                expanded={expanded.notification}
                test-data-id="accordionEmailSetting"
              >
                <AccordionItemTitle
                  onClick={() => {
                    this.handleExpanded("notification");
                  }}
                  test-data-id="emailnotificationDiv"
                >
                  {labels.USER_SETTING_LIST_NOTIFICATION_HEADING}
                  <div className="accordion__arrow" />
                </AccordionItemTitle>
                <AccordionItemBody>
                  <List>
                    {passwordEmailsList.map((item) => (
                      <List.ListItem
                        onClick={() => this.handleSettingsList(item.Id)}
                        isSelected={item.Id === selectedItem.Id}
                        key={item.Id}
                        testId={`password-emails-list-${item.Id}`}
                      >
                        {item.Name}
                      </List.ListItem>
                    ))}
                  </List>
                </AccordionItemBody>
              </AccordionItem>
            </Accordion>
          </Column.Body>
        </Column>
        <Column collapse={formCollapsed} className="col-2">
          <Column.Collapsed
            onClick={() =>
              this.props.onStateChangeAction({ formCollapsed: false })
            }
            text={labels.USER_SETTING_DETAILS_SECTION_TITLE}
            tooltipButton={
              labels.USER_SETTING_DETAILS_SECTION_EXPAND_BUTTON_TOOLTIP
            }
            isHelpActive={isHelpActive}
            tooltipHelp={labels.USER_SETTING_DETAILS_SECTION_EXPAND_BUTTON_HELP}
            tooltipPlace="left"
          />
          <Column.Head>
            <div className="heading">{rightcoloumHeading}</div>
            {(isPhoneNumberSelected || isEmailSelected) && (
              <Button
                className="AddBtn"
                tooltipButton={addItemMessage}
                tooltipHelp={addItemMessage}
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                testId="user-setting-update-btn"
                testIdHelp="user-setting-update-help-btn"
                onClick={() => this.handleItemAdd()}
              />
            )}

            {(isPhoneNumberSelected ||
              isEmailSelected ||
              isUserNameSelected) && (
              <Button
                className="SaveCloudBtn"
                tooltipButton={
                  labels.USER_SETTING_DETAILS_SECTION_SAVE_BUTTON_TOOLTIP
                }
                tooltipHelp={
                  labels.USER_SETTING_DETAILS_SECTION_SAVE_BUTTON_TOOLTIP
                }
                isHelpActive={isHelpActive}
                tooltipPlace="left"
                testId="user-setting-update-btn"
                testIdHelp="user-setting-update-help-btn"
                onClick={() => this.handleSaveUserSetting()}
              />
            )}

            <Button
              className="collapseBtn"
              tooltipButton={
                labels.USER_SETTING_DETAILS_SECTION_COLLAPSE_BUTTON_TOOLTIP
              }
              tooltipHelp={
                labels.USER_SETTING_DETAILS_SECTION_COLLAPSE_BUTTON_HELP
              }
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              onClick={() =>
                this.props.onStateChangeAction({ formCollapsed: true })
              }
            />
          </Column.Head>
          <Column.Body>
            {isUserNameSelected && (
              <Profile
                selectedProfile={selectedProfile}
                onFormFieldChange={this.handleFormFieldChange}
                isInvalidUserFirstname={isInvalidUserFirstname}
                isInvalidUserLastname={isInvalidUserLastname}
                countries={countries}
                onFormSelectChange={this.handleFormSelectChange}
                onAvatarChange={this.handleAvatarChange}
                onSaveUserSetting={this.handleSaveUserSetting}
                isFetching={isFetching}
                getImgSrc={this.handleGetImgSrc}
              />
            )}
            {isPhoneNumberSelected && (
              <UserPhoneNumbers
                phoneNumbers={userPhoneList}
                onPhoneNumberDelete={this.handlePhoneNumberDelete}
                onSaveUserSetting={this.handleSaveUserSetting}
                onPhoneNumberChange={this.handlePhoneNumberChange}
              />
            )}
            {isEmailSelected && (
              <UserEmails
                emails={userEmailList}
                onEmailDelete={this.handleEmailDelete}
                onSaveUserSetting={this.handleSaveUserSetting}
                onEmailChange={this.handleEmailChange}
              />
            )}
            {isSocialMediaSelected && (
              <SocialMediaLinks
                emails={userEmailList}
                onEmailDelete={this.handleEmailDelete}
                onSaveUserSetting={this.handleSaveUserSetting}
                onEmailChange={this.handleEmailChange}
                SocialLinks={this.state.socialLinks}
                onSocialMediaActive={this.handleSocialMediaActive}
                onSocialMediaChange={this.handleSocialMediaChange}
              />
            )}

            {isChangePassSelected && (
              <ChangePassword
                dialogMessagePass={this.dialogMessagePassCallback}
                OldPassCallback={this.OldPassCallback}
                invalidOldPassword={invalidOldPassword}
              />
            )}

            {isEmailNotificationSelected && <UserNotifications />}
          </Column.Body>
        </Column>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation, setting }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  return {
    labels,
    user,
    isHelpActive,
    ...setting,
  };
};

const actions = {
  loginAcionUpdate,
  logoutAction,
  helpActiveAction,
  notificationAction,
  onStateChangeAction,
};
export default connect(
  mapStateToProps,
  actions
)(Settings);
