import React from "react";
import { Button, Col, Divider, Drawer, Dropdown, Grid, Progress, Row, Space, Typography } from "antd";
import closeIcon from '../../../assets/images/Icons/close-new.png'
import ImageIcon from "../../../assets/images/saved.png";
import { EnvironmentOutlined, LeftOutlined } from "@ant-design/icons";
import SelectedIcon from '../../../assets/images/Icons/selectedResume.svg'
import DateIcon from '../../../assets/images/Icons/calendar.png'

import { join } from "lodash";
import moment from "moment";
const { useBreakpoint } = Grid;
const { Title, Text } = Typography

const DetailModal = ({ open, setOpen, selected, menuOpportunities, menu }) => {
  const screens = useBreakpoint();
  const scoreStyle = {
    color: "#8F82F5",
    fontWeight: 600,
    marginRight: "4px",
    padding: "0px 10px",
    borderRadius: "4px",
    height: 'fit-content',
    fontSize: "18px"
  };

  const onClose = () => {
    setOpen({show:false,modal:false});
  };

  const GetExperienceLevel = (value) => {
    switch (value) {
      case 1:
        return "Beginner";
      case 2:
        return "Proficient";
      case 3:
        return "Expert";
      default:
        return "Expert";
    }
  };

  const GetYear = (value) => {
    var d = new Date(value);
    return d.getFullYear();
  };

  const renderValue = (resume) => {
    const ProfileValue = resume?.map((item) => item?.ProfileValue);
    return join(ProfileValue, ", ");
  };

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => {
              onClose();
            }}
          >
            <span className="resume-detail-title" style={{ marginLeft: "8px" }}>
              Resume Detail
            </span>
            <div>
              <img src={closeIcon} alt="" />
            </div>
          </div>
        }
        className="new-detail-modal new-search-resume-main"
        placement="right"
        width={"500px"}
        zIndex={101}
        closable={false}
        onClose={() => setOpen()}
        open={open?.show}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="title-main-resumes"
        >
          <p
            className="list-item-title"
            style={{
              flex: 1,
            }}
          >
            {renderValue(open?.data?.Profiles)}
          </p>
          <div style={{ display: "flex", gap: "8px" }}>
            <Dropdown overlay={menu(open?.data)} trigger={["click"]}>
              <button
                style={{
                  background: "#fff",
                  borderRadius: "50%",
                  border: "1px solid #DFDFDF",
                  padding: "7px"
                }}
              >
                <img style={{ width: "18px", height: "14px" }} src={SelectedIcon} alt="" />
              </button>
            </Dropdown>
            <Dropdown
              overlay={menuOpportunities(open?.data)}
              trigger={["click"]}
            >
              <button style={{
                background: "#fff",
                borderRadius: "25px",
                border: "1px solid #DFDFDF",
                padding: "7px 14px",
                color: "#8E81F5"
              }}>
                {screens?.xs ? "Invite" : "Invite to opportunity"}
              </button>
            </Dropdown>
          </div>
        </div>
        <Row
          gutter={16}
          style={{
            marginTop: "5px",
          }}
        >
          <Col span={20}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <EnvironmentOutlined
                size={"large"}
                style={{
                  fontSize: "large",
                }}
              />
              <p
                className="location-"
                style={{
                  marginLeft: "12px",
                }}
              >
                {open?.data?.Region}
              </p>
            </div>
          </Col>
          <Col span={24} style={{ marginTop: "8px", marginBottom: "8px" }}>
            <div className="d-flex profile align-items-center gap-16">
              <div>
                <Title level={5} style={{ marginBottom: 0, whiteSpace: "pre" }} >Score</Title>
              </div>
              <div className="d-flex w-100 flex-fill align-items-center gap-16">
                <Progress
                  strokeColor="#8F82F5"
                  className="progress-container"
                  style={{ flex: 1 }}
                  percent={50}
                  size="small"
                  showInfo={false}
                />
                <Text style={{ color: "#8E81F5", fontWeight: 600 }} className="fnt-s-16">{open?.data?.CorrelationScore}%</Text>
              </div>
            </div>

          </Col>
        </Row>
        <Divider />
        <Row className="detail-content-main">
          <div className="detail-content-inner">
            <div className="skill-main">
              <div >
                <p className="role-title">Availability Date</p>
                <div className=" d-flex align-items-center gap-8">
                  <div>
                    <img src={DateIcon} alt=""
                    />                  </div>
                  <p style={{ color: "#000", fontWeight: 600, fontSize: "14px", margin: 0 }}>
                    {moment(open?.data?.AvailabilityDate).format(
                      "DD-MM-YYYY hh:mm"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {open?.data?.Profiles?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Role</p>
              <div className="skill-main">
                {open?.data?.Profiles?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.ProfileValue}</p>
                    <p className="btn-role">
                      {GetExperienceLevel(single?.ExperienceLevel)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Skills?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Skill</p>
              <div className="skill-main">
                {open?.data?.Skills?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.SkillValue}</p>
                    <p className="btn-role">
                      {GetExperienceLevel(single?.ExperienceLevel)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Certifications?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Certification and Education</p>
              <div className="skill-main">
                {open?.data?.Certifications?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">
                      {single?.CertificationValue}
                    </p>
                    <p className="btn-role">
                      {GetYear(single?.CertificationDate)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Educations?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Education</p>
              <div className="skill-main">
                {open?.data?.Educations?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.EducationValue}</p>
                    <p className="btn-role">{single?.EducationYear}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Industries?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Industry</p>
              <div className="skill-main">
                {open?.data?.Industries?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.IndustryValue}</p>
                    <p className="btn-role">
                      {GetExperienceLevel(single?.ExperienceLevel)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Location?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Location</p>
              <div className="skill-main">
                {open?.data?.Location?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.IndustryValue}</p>
                    <p className="btn-role">Expert</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Languages?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Languages</p>
              <div className="skill-main">
                <Space size={[8, 8]} wrap style={{ marginTop: "5px" }}>
                  {open?.data?.Languages?.map((single, index) => (
                    <button className="btn-action" key={index}>
                      {single?.LanguageValue}
                    </button>
                  ))}
                </Space>
              </div>
            </div>
          )}
          {open?.data?.Keywords?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Keywords</p>
              <div className="skill-main">
                <Space size={[8, 8]} wrap style={{ marginTop: "5px" }}>
                  {open?.data?.Keywords?.map((single, index) => (
                    <button className="btn-action" key={index}>
                      {single?.KeywordValue}
                    </button>
                  ))}
                </Space>
              </div>
            </div>
          )}
          {open?.data?.ResumeExperience?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Professional experience</p>
              <div className="skill-main">
                {open?.data?.ResumeExperience?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.CompanyWorked}</p>
                    <p className="btn-role">{single?.ProfileValue}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.ResumeOtherAchivenments?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Achivenments</p>
              <div className="skill-main">
                {open?.data?.ResumeOtherAchivenments?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.AchivenmentName}</p>
                    <p className="btn-role">{single?.Title}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Row>
      </Drawer>
    </>
  );
};
export default DetailModal;
