import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { notificationAction } from "../../../actions";
import { map, find, compact } from "lodash";

import "./phillip.scss";
import {
  getSearcherPhillipSocialMediaStatusApi,
  uploadLinkedinPDF,
  updateResumeAvailabilityApi,
  getLoginFeaturesApi,
  saveParsedResume,
} from "../../../components/Phillip/phillipApi";
import Logo from "../../../assets/images/logo1.png";
import { htmlParser } from "../../../utilities/helpers";
import { LoadingMask, DatePicker } from "../../../common";
import { withRouter } from "react-router-dom";
import { privateRoutes } from "../../../Routes/routing";

const Slide = ({ className, children, testId }) => (
  <div className={`js_slide ${className}`} test-data-id={testId}>
    {children}
  </div>
);
const CustomInput = ({ onClick, value }) => (
  <button className="custom-date-picker" onClick={onClick}>
    {value}
  </button>
);
const ResumeValues = [
  {
    id: 0,
    name: "Resume",
    notFound: "iProOnboardingResumeCreated",
    found: "iProOnboardingResumeCreated",
    active: true,
  },
  {
    id: 1,
    name: "Keywords",
    notFound: "iProOnboardingKeywordNotFound",
    found: "iProOnboardingKeywordFound",
  },
  {
    id: 2,
    name: "Certifications",
    notFound: "iProOnboardingCertificationNotFound",
    found: "iProOnboardingCertificationFound",
  },
  {
    id: 3,
    name: "Skills",
    notFound: "iProOnboardingSkillNotFound",
    found: "iProOnboardingSkillFound",
  },
  {
    id: 4,
    name: "Profiles",
    notFound: "iProOnboardingRoleNotFound",
    found: "iProOnboardingRoleFound",
  },
  {
    id: 5,
    name: "Educations",
    notFound: "iProOnboardingEducationNotFound",
    found: "iProOnboardingEducationFound",
  },
  {
    id: 6,
    name: "Experiences",
    notFound: "iProOnboardingExperienceNotFound",
    found: "iProOnboardingExperienceFound",
  },
  {
    id: 7,
    name: "Countries",
    notFound: "iProOnboardingCountryNotFound",
    found: "iProOnboardingCountryFound",
  },
  {
    id: 8,
    name: "Languages",
    notFound: "iProOnboardingLanguageNotFound",
    found: "iProOnboardingLanguageFound",
  },
  // {
  //   id: 9,
  //   name: "Present",
  //   notFound: "iProOnboardingPresentNotFound",
  //   found: "iProOnboardingPresentFound"
  // }
];
class IproPhillip extends Component {
  state = {
    currentSlide: 0,
    date: "",
    companyUrl: "",
    pageUrl: "",
    linkedinUrl: "",
    isFacebookConnected: false,
    isLinkedInConnected: false,
    isCompanyConnected: false,
    isOpportunityCreated: false,
    isShortlistCreated: false,
    isSavedSearchCreated: false,
    keywords: "",
    resumeValues: ResumeValues,
    foundResumeValues: {},
    dateValue: "select a date",
    isDatePickerOpen: false,
    isLoading: false,
    LoginWithFb: false,
    LoginWithLinkedin: false,
    availabilities: [
      {
        id: 1,
        name: "now",
        isActive: false,
        value: this.props.labels.iProOnboardingAvailabilityGig,
      },
      {
        id: 2,
        name: "notice",
        isActive: false,
        value: this.props.labels.iProOnboardingAvailabilityOnNotice,
      },
      {
        id: 3,
        name: "date",
        isActive: false,
        value: this.props.labels.iProOnboardingAvailabilityUntil,
      },
    ],
    selectedAvailability: "notice",
    showDatePicker: false,
  };
  componentDidMount() {
    this.getLoginFeatures();
    getSearcherPhillipSocialMediaStatusApi()
      .then((response) => {
        if (response.success) {
          const { FacebookEnabled, LinkedInEnabled } = response.items;
          this.setState({
            isFacebookConnected: FacebookEnabled,
            isLinkedInConnected: LinkedInEnabled,
          });
        }
      })
      .catch(() => {});
    window.addEventListener("keydown", this.handleTabPress);
  }

  handleTabPress = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      this.handleContinueClick();
    }
  };

  handleFinishClick = () => {
    const { onCloseModal, notificationAction } = this.props;
    const { availabilities, date = new moment(), resumeData } = this.state;
    this.setState({
      isLoading: true,
    });
    if (resumeData) {
      saveParsedResume(resumeData)
        .then(() => {
          const selectedAvailability = find(availabilities, "isActive");
          if (selectedAvailability) {
            let data = {
              IsAvailable: false,
              AvailabilityDate: moment(date).format("MM/DD/YYYY"),
            };
            if (selectedAvailability.name === "notice") {
              data = {
                IsAvailable: false,
                AvailabilityDate: new moment().add(1, "M").format("MM/DD/YYYY"),
                AvailablityType: 2,
              };
            } else if (selectedAvailability.name === "date") {
              data = {
                IsAvailable: false,
                AvailabilityDate: moment(date).format("MM/DD/YYYY"),
                AvailablityType: 3,
              };
            } else if (selectedAvailability.name === "now") {
              data = {
                IsAvailable: true,
                AvailabilityDate: new moment().format("MM/DD/YYYY"),
                AvailablityType: 1,
              };
            }
            updateResumeAvailabilityApi(data).then(({ data }) => {
              const info = {
                message: data.message,
                status: "success",
              };
              notificationAction(info);
            });
          }
          const info = {
            message: "Resume saved successfully",
            status: "success",
          };
          notificationAction(info);
          onCloseModal();
          this.setState({
            isLoading: false,
          });
          if (this.state.otherResumePdfName || this.state.linkedinPdfName) {
            this.props.history.push(privateRoutes.resumeEdit.path);
          }
        })
        .catch((err) => console.log("Err", err));
    }
  };
  handleContinueClick = () => {
    const { currentSlide } = this.state;
    this.setState({ currentSlide: currentSlide + 1 });
  };

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;
    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };

  handlAvalibalitySelect = (availability) => {
    const { id, name } = availability;
    const availabilities = map(this.state.availabilities, (item) => {
      item.isActive = item.id === id;
      return item;
    });
    this.setState({
      availabilities,
      selectedAvailability: name,
      isDatePickerOpen: name === "date",
    });
  };

  handleFacebookClick = () => {
    this.setState({ isFacebookConnected: !this.state.isFacebookConnected });
  };

  handleLinkedInClick = () => {
    this.setState({ isLinkedInConnected: !this.state.isLinkedInConnected });
  };

  getLoginFeatures = () => {
    getLoginFeaturesApi().then((response) => {
      this.setState({
        LoginWithFb: response.items.LoginWithFb,
        LoginWithLinkedin: response.items.LoginWithLinkedin,
      });
    });
  };
  renderResumeValues = (item) => {
    const { foundResumeValues } = this.state;
    if (foundResumeValues[item.name] > 0) {
      const value = this.props.labels[item.found];
      return value.replace("@count", foundResumeValues[item.name]);
    }
    return this.props.labels[item.notFound];
  };
  uploadLinkedinPdf = (e, successName) => {
    var fileData = e.target.files[0];
    var types = "pdf";
    const { labels, notificationAction } = this.props;
    var fileType = fileData.name.split(".");
    const me = this;
    let { resumeValues } = this.state;
    if (types === fileType[1].toLowerCase()) {
      var reader = new FileReader();
      reader.onload = (function() {
        return function(e) {
          var fileDataInBytes = e.target.result.split(",")[1];
          me.setState({ isLoading: true });
          uploadLinkedinPDF(fileDataInBytes)
            .then((res) => {
              const info = {
                message: htmlParser(labels[successName]),
                status: "success",
              };
              notificationAction(info);
              const foundResumeValues = {
                Profiles: res.items.Profiles && res.items.Profiles.length,
                Skills: res.items.Skills && res.items.Skills.length,
                Certifications:
                  res.items.Certifications && res.items.Certifications.length,
                Keywords: res.items.Keywords && res.items.Keywords.length,
                Countries: res.items.Countries && res.items.Countries.length,
                Languages: res.items.Languages && res.items.Languages.length,
                Industries: res.items.Industries && res.items.Industries.length,
              };
              resumeValues = resumeValues.map((item) => {
                return {
                  ...item,
                  active:
                    (foundResumeValues[item.name] &&
                      foundResumeValues[item.name] > 0) ||
                    item.name === "Resume",
                };
              });
              me.setState({
                foundResumeValues,
                resumeData: res.items,
                resumeValues,
                isLoading: false,
                otherResumePdfName:
                  successName === "OtherResumeSuccessMessage"
                    ? fileData.name
                    : me.state.otherResumePdfName,
                linkedinPdfName:
                  successName === "LinkedInResumeSuccessMessage"
                    ? fileData.name
                    : me.state.linkedinPdfName,
              });
              me.handleContinueClick();
            })
            .catch(() => {
              const info = {
                message: "Unable to upload to file.",
                status: "error",
              };
              notificationAction(info);
              me.setState({ isLoading: false });
            });
        };
      })(fileData);

      reader.readAsDataURL(fileData);
    } else {
      const info = {
        message: "Please select pdf file.",
        status: "error",
      };
      notificationAction(info);
    }
  };
  generateCards = () => {
    const { labels } = this.props;
    const {
      date,
      resumeValues,
      dateValue,
      isDatePickerOpen,
      availabilities,
      otherResumePdfName,
    } = this.state;
    const slideHtml = [
      <Slide className="js_slide_3">
        <div className="slide-heading">
          <b>{htmlParser(labels.iProOnboardingResumeUploadTitle)}</b>
        </div>
        <br />
        <br />
        {htmlParser(labels.iProOnboardingResumeUploadMessage)}
        <br />

        <div className="resume-upload-row">
          <div
            onChange={(e) =>
              this.uploadLinkedinPdf(e, "OtherResumeSuccessMessage")
            }
            className="js_upload_btn"
          >
            {labels.iProOnboardingResumeOtherBtnText}
            <input className="linkedInPdf" type="file" title=" " />
          </div>

          <div className={`resume-name ${otherResumePdfName || "blur"}`}>
            {otherResumePdfName || " No file selected "}
          </div>
        </div>
      </Slide>,
      <Slide className="js_slide_2">
        {resumeValues.map((item) => (
          <div
            key={item.id}
            className={`js_item ${item.active ? "active" : ""}`}
          >
            <span>{this.renderResumeValues(item)}</span>
          </div>
        ))}
        <br />
        <div className="js_query">
          {labels.iProOnboardingAvailabilityQuestion}
          <br />
          <div className="js_selection">
            {map(availabilities, (item) => (
              <div
                key={item.id}
                className={`js_item js_sel_item ${item.isActive && "active"}`}
                onClick={() => this.handlAvalibalitySelect(item)}
                test-data-id={`ipro-phillip-avalibility-${item.name}`}
              >
                <span>{htmlParser(item.value)}</span>
                {item.id === 3 && (
                  <DatePicker
                    name="DatePicker"
                    minDate={moment()}
                    todayButton="Today"
                    dateFormat="MM/DD/YYYY"
                    selected={date}
                    value={dateValue ? dateValue : date.format("L").toString()}
                    onChange={(date, e) => {
                      e.stopPropagation();
                      this.setState({
                        date,
                        dateValue: "",
                        isDatePickerOpen: false,
                      });
                    }}
                    onBlur={() => {
                      this.setState({
                        isDatePickerOpen: false,
                      });
                    }}
                    customInput={<CustomInput />}
                    open={isDatePickerOpen}
                    stop
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </Slide>,
    ];
    return compact(slideHtml);
  };

  render() {
    const { labels } = this.props;
    const { currentSlide, isLoading } = this.state;

    return (
      <div
        className="phillip-component ipro-phillip"
        test-data-id="ipro-phillip-popup"
      >
        {isLoading && <LoadingMask text="" />}
        <Carousel
          swipeable
          showStatus={false}
          showArrows={false}
          // infiniteLoop
          showThumbs={false}
          selectedItem={currentSlide}
          onChange={this.updateCurrentSlide}
        >
          {this.generateCards()}
        </Carousel>
        <div className="phillip-footer">
          {currentSlide !== 1 && (
            <button
              type="button"
              test-data-id="ipro-phillip-continue-button"
              className="continue-button"
              onClick={this.handleContinueClick}
            >
              {labels.searcherOnboardingContinue}
            </button>
          )}
          {(this.state.otherResumePdfName || this.state.linkedinPdfName) &&
            currentSlide === 1 && (
              <button
                type="button"
                test-data-id="ipro-phillip-finish-button"
                className="finish-button"
                onClick={this.handleFinishClick}
              >
                {labels.searcherOnboardingFinished}
              </button>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  let { FirstName } = user;
  FirstName =
    FirstName !== undefined ? FirstName : sessionStorage.getItem("userName");
  return { labels, FirstName };
};
export default connect(mapStateToProps, { notificationAction })(
  withRouter(IproPhillip)
);
