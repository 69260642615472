import React from "react";
import "./dashboard.scss";
import Widget from "./Widget";
import {
  Column,
  HelpTooltip,
} from "../../../../common";
import {
  getAllAcceptedRequestsApi,
  getRequestsCountsApi,
  getCollaborationsCountsApi,
} from "../../assortApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
class Dashboard extends React.Component {
  state = {
    allOpportunites: [],
  };
  componentDidMount() {
    getAllAcceptedRequestsApi()
      .then((res) => {
        this.setState({
          allOpportunites: res.items,
        });
      })
      .catch((err) => console.log("Error"));
    getRequestsCountsApi()
      .then((res) => {
        this.setState({
          requestCounts: res.items,
        });
      })
      .catch((err) => console.log("Error"));
    getCollaborationsCountsApi()
      .then((res) => {
        this.setState({
          collaborationsCount: res.items,
        });
      })
      .catch((err) => console.log("Error"));
  }

  render() {
    const { widgetTestId, isHelpActive, labels } = this.props;
    const { requestCounts, collaborationsCount } = this.state;
    return (
      <div className="dashboard-view">
        <React.Fragment>
          <Column className="column-1">
            <Widget testId={widgetTestId}>
              <Widget.Header>
                {isHelpActive && (
                  <HelpTooltip content={"Opportunites Dashboard"} />
                )}{" "}
                <i className="assort-request-icon" />
                <label>{"Opportunites"}</label>
              </Widget.Header>
              <Widget.Content>
                <div className="widget-content">
                  <div>
                    <label>
                      {(requestCounts && requestCounts.length) || 0}
                    </label>
                    <label>{"Accepted"}</label>
                  </div>
                </div>
              </Widget.Content>
            </Widget>
            <Widget>
              <Widget.Header>
                {isHelpActive && (
                  <HelpTooltip content={"Collaborations Dashboard"} />
                )}
                <i className="assort-collab-icon" />
                <label>{"Collaborations"}</label>
              </Widget.Header>
              <Widget.Content>
                {collaborationsCount && (
                  <div className="widget-content">
                    <div>
                      <label>{collaborationsCount.PendingCounts || 0}</label>
                      <label>{"Pending"}</label>
                    </div>
                    <div>
                      <label>{collaborationsCount.AcceptedCounts || 0}</label>
                      <label>{"Accepted"}</label>
                    </div>
                    <div>
                      <label>{collaborationsCount.DeclinedCounts || 0}</label>
                      <label>{"Declined"}</label>
                    </div>
                  </div>
                )}
              </Widget.Content>
            </Widget>
          </Column>
          <Column className="column-2">
            <Widget>
              <Widget.Header>
                {isHelpActive && (
                  <HelpTooltip content={"Accepted Opportunities"} />
                )}
                <i className="accepted-request-icon" />
                <label>{"Accepted Opportunites"}</label>
              </Widget.Header>
              <Widget.Content>
                <div className="collab-widget-content">
                  {requestCounts &&
                    requestCounts.map((item) => (
                      <div>
                        <label>{`${item.TotalResumes}`}</label>
                        <label>{item.RequestName}</label>
                      </div>
                    ))}
                </div>
              </Widget.Content>
            </Widget>
          </Column>
        </React.Fragment>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Dashboard);
