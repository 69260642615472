import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { isEmpty, map } from "lodash";
import { LoadingMask, Select, CapsuleList } from "../../../common";
import { notificationAction } from "../../../actions";
import "./phillip.scss";
import Slider from "rc-slider";

import {
  dateFormate,
} from "../../../utilities/helpers";
import {
  getSearcherPhillipInfoApi,
  saveResumeSearchApi,
  resumeSearchesApi,
} from "../../../components/Phillip/phillipApi";
import {
  getCompaniesApi,
} from "../../../views/Company/companyApi";
import filter from "lodash/filter";
import { getCurrenciesApi } from "../../Collaboration/common/collaborationApi";
import { Col, Row, Steps } from "antd";
import { getAllLocationsApi } from "../../Opportunity/opportunityApi";
const LOOKUPTYPES = {
  PROFILE: 1,
  SKILL: 2,
  KEYWORD: 3,
  CERTIFICATION: 4,
  COUNTRY: 5,
  LANGUAGE: 6,
  INDUSTRY: 7,
};
const Handle = Slider.Handle;


class SearcherPhillip extends Component {
  constructor(props) {
    super(props);

    this.durationTypeList = [
      { value: "Days", label: "Days" },
      { value: "Weeks", label: "Weeks" },
      { value: "Months", label: "Months" },
      { value: "Years", label: "Years" },
    ];
  }
  state = {
    isLoading: false,
    currentPage: 1,
    currentStep: 0,
    isJobParsed: false,
    isLookupFiltered: false,
    isOpportunitySent: false,

    extractedSearchSettings: {
      Certifications: [],
      Countries: [],
      Industries: [],
      Keywords: [],
      Languages: [],
      Profiles: [],
      Skills: [],
    },

    allWorkplaces: [],
    allCurrencies: [],

    currentSearch: {
      OpportunityName: "",
      HourlyRate: "",
      Countries: [],
      selectedHourlyRateType: {},
      selectedWorkplace: {},
    },
  };

  getCompaniesApi = () => {
    getCompaniesApi().then((data) => {
      const { success, items } = data;
      if (success) {
        const allWorkplaces = items.map((item) => ({
          ...item,
          value: item.UserCompanyId,
          label: item.CompanyName,
        }));
        this.setState({
          allWorkplaces,
          currentSearch: {
            ...this.state.currentSearch,
            selectedWorkplace: allWorkplaces[0],
          },
        });
      }
    });

    getCurrenciesApi().then((data) => {
      const { success, items } = data;
      if (success) {
        const allCurrencies = items.map((item) => ({
          ...item,
          value: item.CurrencyId,
          label: item.Name,
        }));
        this.setState({
          allCurrencies,
          currentSearch: {
            ...this.state.currentSearch,
            selectedHourlyRateType: allCurrencies[0],
            Duration: 4,
            DurationType: this.durationTypeList[1],
          },
        });
      }
    });
  };
  getAllLocations = () => {
    getAllLocationsApi().then((response) => {
      if (response.success) {
        const allLocationsList = response.items.map((item) => ({
          ...item,
          value: item.CountryId,
          label: item.CountryName,
        }));
        this.setState({
          ...this.state,
          allLocationsList,
        });
      }
    });
  };

  componentDidMount() {
    this.getCompaniesApi();
    this.getAllLocations();
  }

  handleJobParse = () => {
    const { keywords, opportunityStartDate } = this.state;
    const { labels } = this.props;
    if (!isEmpty(keywords)) {
      this.setState({ isLoading: true });
      getSearcherPhillipInfoApi({ keywords })
        .then((response) => {
          if (response.success) {
            let {
              Certifications = [],
              Countries = [],
              Industries = [],
              Keywords = [],
              Languages = [],
              Profiles = [],
              Skills = [],
            } = response.items;

            Certifications = map(Certifications, (certification) => ({
              Id: certification.CertificationId,
              ExpLevel: 0,
              LookupValue: certification.CertificationValue,
            }));
            Countries = map(Countries, (country) => ({
              Id: country.CountryId,
              ExpLevel: 0,
              LookupValue: country.CountryName,
            }));
            Industries = map(Industries, (industry) => ({
              Id: industry.IndustryId,
              ExpLevel: 0,
              LookupValue: industry.IndustryValue,
            }));
            Keywords = map(Keywords, (keyword) => ({
              Id: keyword.KeywordId,
              ExpLevel: 0,
              LookupValue: keyword.KeywordValue,
            }));
            Languages = map(Languages, (language) => ({
              Id: language.LanguageId,
              ExpLevel: 0,
              LookupValue: language.LanguageValue,
            }));
            Profiles = map(Profiles, (profile) => ({
              Id: profile.ProfileId,
              ExpLevel: 0,
              LookupValue: profile.ProfileValue,
            }));
            Skills = map(Skills, (skill) => ({
              Id: skill.SkillId,
              ExpLevel: 0,
              LookupValue: skill.SkillValue,
            }));

            if (
              isEmpty(Certifications) &&
              isEmpty(Countries) &&
              isEmpty(Industries) &&
              isEmpty(Keywords) &&
              isEmpty(Languages) &&
              isEmpty(Profiles) &&
              isEmpty(Skills)
            ) {
              const info = {
                message: labels.searcherOnboardingInvalidDescription,
                status: "success",
              };
              notificationAction(info);
              this.setState({
                isLoading: false,
              });
              return;
            }
            const saveResumeParams = {
              Certifications,
              Countries,
              Industries,
              Keywords,
              Languages,
              Profiles,
              Skills,
              SavedSearchId: 0,
              SearchName: `Philip SavedSearch at ${dateFormate(new Date())}`,
            };
            this.setState({
              extractedSearchSettings: saveResumeParams,
              opportunityStartDate,
              isLoading: false,
              isJobParsed: true,
              currentStep: 1,
            });
            const element = document.getElementById("step2");
            element.scrollIntoView();
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch(() =>
          this.setState({
            isLoading: false,
          })
        );
    } else {
      this.setState({
        validation: {
          ...this.state.validation,
          textareaError: true,
        },
      });
    }
  };

  saveResumeSettings = () => {
    this.setState({
      isLoading: true,
    });
    const { extractedSearchSettings } = this.state;
    const {
      Certifications,
      Industries,
      Keywords,
      Profiles,
      Skills,
    } = extractedSearchSettings;
    saveResumeSearchApi(extractedSearchSettings)
      .then((response) => {
        this.setState({
          isSavedSearchCreated: true,
          onBoardSavedSearchId: response.items.SavedSearchId,
          isLookupFiltered: true,
          isLoading: false,
        });
        let CertificationIds = [],
          Countries = [],
          IndusteryIds = [],
          KeywordIds = [],
          Languages = [],
          ProfileIds = [],
          SkillIds = [];

        CertificationIds = map(
          Certifications,
          (certification) => certification.Id
        );
        Countries = map(Countries, (country) => country.Id);
        IndusteryIds = map(Industries, (industry) => industry.Id);
        KeywordIds = map(Keywords, (keyword) => keyword.Id);
        Languages = map(Languages, (language) => language.Id);
        ProfileIds = map(Profiles, (profile) => profile.Id);
        SkillIds = map(Skills, (skill) => skill.Id);
        const params = {
          CertificationIds,
          Countries,
          IndusteryIds,
          KeywordIds,
          Languages,
          ProfileIds,
          SkillIds,
          Type: "Only20",
          AvailabilityType: 1,
          AvailabilityDate: "05/09/2018",
          page: 1,
          limit: 10,
          OpportunityStartDate: moment()
            .add(1, "m")
            .startOf("month"),
          prodooStartDateTime: `${moment(new Date()).format(
                  "D MMMM YYYY - HH:mm"
                )}`,
        };
        this.setState({
          opportunityParams: params,
          currentStep: 2,
        });
        const element = document.getElementById("step3");
        element.scrollIntoView();
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  };

  saveResumeSearch = () => {
    this.setState({
      isLoading: true,
    });
    const { labels } = this.props;
    let { opportunityParams, currentSearch } = this.state;
    const {
      OpportunityName,
      HourlyRate,
      selectedHourlyRateType,
      selectedWorkplace,
      CompanyName,
    } = currentSearch;
    if (!OpportunityName) {
      const info = {
        message: labels?.searcher_phillip_automate_no_oppertunity_alert,
        status: "error",
      };
      this.props.notificationAction(info);
      this.setState({
        isLoading: false,
        validation: {
          title: true,
        },
      });
      return;
    }
    if (!HourlyRate) {
      const info = {
        message: labels?.searcher_phillip_automate_oppertunity_hourlyrate_alert,
        status: "error",
      };
      this.props.notificationAction(info);
      this.setState({
        isLoading: false,
        validation: {
          hourelyRate: true,
        },
      });
      return;
    }
    if (this?.state?.allWorkplaces?.length < 1 && !CompanyName) {
      const info = {
        message:
          labels?.searcher_phillip_automate_oppertunity_no_workplace_alert,
        status: "error",
      };
      this.props.notificationAction(info);
      this.setState({
        isLoading: false,
        validation: {
          companyName: true,
        },
      });
      return;
    }
    opportunityParams = {
      ...opportunityParams,
      HourlyRate,
      OpportunityName,
      CompanyID: CompanyName && 0,
      CompanyName,
      CurrencyId: selectedHourlyRateType?.value,
      CompanyId: selectedWorkplace?.value,
      IsPhilipApp: true,
    };
    resumeSearchesApi(opportunityParams)
      .then((response) => {
        if (response.success) {
          this.setState({
            isLoading: false,
            isJobParsed: false,
            isLookupFiltered: false,
            isOpportunitySent: false,
            currentStep: 0,
            extractedSearchSettings: {
              Certifications: [],
              Countries: [],
              Industries: [],
              Keywords: [],
              Languages: [],
              Profiles: [],
              Skills: [],
            },
            opportunityParams: {},
            allWorkplaces: [],
            allCurrencies: [],
            currentSearch: {
              OpportunityName: "",
              HourlyRate: "",
              selectedHourlyRateType: {},
              selectedWorkplace: {},
            },
            keywords: "",
          });
          const info = {
            message: "Opportunity sent successfully",
            status: "success",
          };
          this.getCompaniesApi();
          this.props.notificationAction(info);
          return;
        } else {
          const info = {
            message: response?.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            isLoading: false,
          });
        }
        this.props.onResumeShortlistUpdate();
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  };

  extractedSettingUpdate = (type, item) => {
    const { extractedSearchSettings } = this.state;
    let {
      Certifications,
      Countries,
      Industries,
      Keywords,
      Languages,
      Profiles,
      Skills,
    } = extractedSearchSettings;
    switch (type) {
      case LOOKUPTYPES.PROFILE: {
        Profiles = filter(Profiles, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.SKILL: {
        Skills = filter(Skills, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.CERTIFICATION: {
        Certifications = filter(Certifications, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.COUNTRY: {
        Countries = filter(Countries, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.INDUSTRY: {
        Industries = filter(Industries, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.KEYWORD: {
        Keywords = filter(Keywords, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.LANGUAGE: {
        Languages = filter(Languages, (a) => a.Id != item.Id);
        break;
      }
    }
    this.setState({
      extractedSearchSettings: {
        ...extractedSearchSettings,
        Profiles,
        Skills,
        Certifications,
        Keywords,
        Languages,
        Countries,
      },
    });
  };

  handleDateChange = (date) => {
    // e.stopPropagation();
    this.setState({
      date: moment(date),
      isDatePickerOpen: false,
    });
  };

  onActionButtonClick = (currentPage) => {
    this.setState({
      currentPage: currentPage + 1,
    });
  };

  onFormFieldChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      validation: {
        title: name == "OpportunityName" && false,
        hourelyRate: name == "HourlyRate" && false,
        CompanyName: name == "CompanyName" && false,
      },
      currentSearch: {
        ...this.state.currentSearch,
        [name]: value,
      },
    });
  };
  onFormSelectChange = (name, value) => {
    this.setState({
      currentSearch: {
        ...this.state.currentSearch,
        [name]: value,
      },
    });
  };

  handleSliderChange = (value) => {
    const { currentSearch } = this.state;
    if (value) {
      this.setState({
        currentSearch: {
          ...this.state.currentSearch,
          Duration: value,
        },
      });
    }
  };

  handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Handle value={value} key={index} {...restProps}>
        {value}
      </Handle>
    );
  };

  handleLocationSelect = ({ label, value }) => {
    const { currentSearch } = this.state;
    const alreadySelcted = filter(
      currentSearch.Countries,
      (country) => country.CountryId === value
    );
    if (!isEmpty(alreadySelcted)) return;
    this.setState({
      currentSearch: {
        ...this.state.currentSearch,
        Countries: [
          ...currentSearch.Countries,
          { CountryId: value, CountryName: label },
        ],
      },
    });
  };

  handleCountryDelete = ({ selectedCountry }) => {
    const { currentSearch } = this.state;
    const filterCountries = filter(
      currentSearch.Countries,
      (country) => country.CountryId !== selectedCountry.CountryId
    );
    this.setState({
      currentSearch: {
        ...this.state.currentSearch,
        Countries: filterCountries,
      },
    });
  };

  render() {
    const { labels } = this.props;
    const items = [
      {
        title: labels?.searcher_phillip_automate_step1_title,
        description: labels?.searcher_phillip_automate_step1_description,
      },
      {
        title: labels?.searcher_phillip_automate_step2_title,
        description: labels?.searcher_phillip_automate_step2_description,
      },
      {
        title: labels?.searcher_phillip_automate_step3_title,
        description: labels?.searcher_phillip_automate_step3_description,
      },
    ];
    const {
      isLoading,
      extractedSearchSettings,
      allWorkplaces: [],
      allCurrencies: [],
      allLocationsList,
      currentSearch,
      isJobParsed,
      isLookupFiltered,
    } = this.state;
    const { Profiles, Skills } = extractedSearchSettings;

    return (
      <React.Fragment>
        {isLoading && <LoadingMask text="" />}
        <div className="phillips-search-steps-">
          <div
            className="phillip-component searcher-phillip marketing-page page-2"
            style={{
              padding: "20px 12px",
              background: "#fff!important",
              height: "-webkit-fill-available",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div>
                <Steps current={this.state.currentStep} items={items} />
              </div>
              <div
                style={{
                  flex: 1,
                  marginTop: "25px",
                }}
              >
                <Row
                  gutter={[24, 24]}
                  style={{
                    height: "100%",
                  }}
                >
                  <Col
                    xs={24}
                    sm={8}
                    md={8}
                    className="steps"
                    style={{
                      margin: 0,
                    }}
                  >
                    <div
                      className="step"
                      style={{
                        background: "#F1EFFB",
                        borderRadius: "12px",
                        padding: "12px",
                        height: "100%",
                        border: this.state?.validation?.textareaError
                          ? "1px solid #f54949"
                          : null,
                      }}
                    >
                      <div
                        className="js_textarea_wrap body"
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <div className="text-area-wrapper">
                          <textarea
                            autoComplete="off"
                            className="js_textarea"
                            style={{
                              flex: 1,
                            }}
                            test-data-id="searcher-phillip-keyword-input"
                            placeholder={
                              labels?.searcher_phillip_automate_step1_textarea_placeholder
                            }
                            onChange={(e) => {
                              this.setState({
                                keywords: e.target.value,
                                validation: {
                                  textareaError: false,
                                },
                              });
                            }}
                            value={this.state.keywords}
                          >
                            {this.state.keywords}
                          </textarea>
                          <button
                            onClick={this.handleJobParse}
                            className="action-btn"
                          >
                            {
                              labels?.searcher_phillip_automate_step1_button_text
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={8}
                    md={8}
                    className="steps"
                    style={{ margin: 0 }}
                  >
                    {
                      <div
                        id="step2"
                        className={`step ${!isJobParsed ? "disabled" : ""}`}
                        style={{
                          background: "#F1EFFB",
                          borderRadius: "12px",
                          padding: "12px",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className="body"
                          style={{
                            flex: 1,
                          }}
                        >
                          <div className="settings-container">
                            <label>
                              {
                                labels?.searcher_phillip_automate_step2_firstpara
                              }
                            </label>
                            {Profiles &&
                              Profiles.map((item) => (
                                <label
                                  test-data-id="resume-edit-suggested-role-item"
                                  className="draggable"
                                  onClick={() =>
                                    this.extractedSettingUpdate(
                                      LOOKUPTYPES.PROFILE,
                                      item
                                    )
                                  }
                                  key={item.Id}
                                >
                                  {item.LookupValue}
                                </label>
                              ))}
                          </div>
                          <div className="settings-container">
                            <label>
                              {
                                labels?.searcher_phillip_automate_step2_secondpara
                              }
                            </label>
                            {Skills &&
                              Skills.map((item) => (
                                <label
                                  test-data-id="resume-edit-suggested-role-item"
                                  className="draggable"
                                  onClick={() =>
                                    this.extractedSettingUpdate(
                                      LOOKUPTYPES.SKILL,
                                      item
                                    )
                                  }
                                  key={item.Id}
                                >
                                  {item.LookupValue}
                                </label>
                              ))}
                          </div>
                        </div>
                        <button
                          onClick={this.saveResumeSettings}
                          className="action-btn"
                        >
                          {labels?.searcher_phillip_automate_step2_button_text}{" "}
                        </button>
                      </div>
                    }
                  </Col>
                  <Col
                    xs={24}
                    sm={8}
                    md={8}
                    className="steps"
                    style={{ margin: 0 }}
                  >
                    {
                      <div
                        id="step3"
                        className={`step ${
                          !isLookupFiltered ? "disabled" : ""
                        }`}
                        style={{
                          background: "#F1EFFB",
                          borderRadius: "12px",
                          padding: "12px",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className="body"
                          style={{
                            flex: 1,
                          }}
                        >
                          <label>
                            {labels?.searcher_phillip_automate_step3_firstpara}
                          </label>
                          <input
                            type="text"
                            autoComplete="off"
                            name="OpportunityName"
                            className="input-text opportunity-name"
                            placeholder={
                              labels?.SearcherOpportunityDraftDetailTitlePlaceholder
                            }
                            style={{
                              background: "#fff",
                              borderRadius: "8px",
                              margin: "10px 0px",
                              height: "36px",
                              border: this.state?.validation?.title
                                ? "1px solid #f54949"
                                : "1px solid #fff",
                            }}
                            value={
                              (currentSearch &&
                                currentSearch.OpportunityName) ||
                              ""
                            }
                            onChange={this.onFormFieldChange}
                            onBlur={this.onFormFieldChange}
                            test-data-id="input-txt-opportunity-hourlyRate"
                          />
                          <label>
                            {labels?.searcher_phillip_automate_step3_secondpara}
                          </label>
                          <Row
                            gutter={[18, 4]}
                            style={{
                              marginTop: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Col span={24}>
                              <label
                                className="form-label"
                                style={{
                                  margin: 0,
                                  color: "#8d82ab",
                                }}
                              >
                                {labels?.SearcherOpportunityDraftDetailDuration}
                                :
                              </label>
                            </Col>
                            <Col span={12}>
                              <Slider
                                name="durationSlider"
                                min={0}
                                max={12}
                                defaultValue={4}
                                value={currentSearch?.Duration}
                                handle={this.handle}
                                onChange={this.handleSliderChange}
                              />
                            </Col>
                            <Col span={12}>
                              <Select
                                name="DurationType"
                                className={`select-input select-Workplace dropdown-phillip-searcher`}
                                placeholder={
                                  labels?.SearcherOpportunityDraftDetailDurationPlaceholder
                                }
                                value={currentSearch?.DurationType?.value}
                                onChange={(selectedOption) => {
                                  this.setState({
                                    currentSearch: {
                                      ...this.state.currentSearch,
                                      DurationType: selectedOption,
                                    },
                                  });
                                }}
                                options={this.durationTypeList}
                                clearable={false}
                                searchable={false}
                              />
                            </Col>
                            <Col span={12}>
                              <input
                                type="text"
                                autoComplete="off"
                                name="HourlyRate"
                                style={{
                                  background: "#fff",
                                  borderRadius: "8px",
                                  height: "36px",
                                  border: this.state?.validation?.hourelyRate
                                    ? "1px solid #f54949"
                                    : "1px solid #fff",
                                }}
                                className="input-text opportunity-rate"
                                placeholder={
                                  labels?.searcher_phillip_automate_step3_hourly_placeholder
                                }
                                value={
                                  (currentSearch && currentSearch.HourlyRate) ||
                                  ""
                                }
                                onChange={this.onFormFieldChange}
                                onBlur={this.onFormFieldChange}
                                test-data-id="input-txt-opportunity-hourlyRate"
                              />
                            </Col>
                            <Col span={12}>
                              <Select
                                name="FeeCurrencyType"
                                className={`select-input select-hourlyRate dropdown-phillip-searcher ${
                                  currentSearch.HourlyRateType ? "inValid" : ""
                                }`}
                                placeholder={
                                  labels?.SearcherOpportunityDraftDetailHourlyFeeDropDownPlaceholder
                                }
                                value={
                                  (currentSearch &&
                                    currentSearch.selectedHourlyRateType) ||
                                  ""
                                }
                                onChange={(selectedOption) =>
                                  this.onFormSelectChange(
                                    "selectedHourlyRateType",
                                    selectedOption
                                  )
                                }
                                clearable={false}
                                searchable={false}
                                options={this.state.allCurrencies}
                              />
                            </Col>
                            <Col span={24}>
                              <Select
                                name="LOCATION"
                                placeholder={
                                  labels?.SearcherOpportunityDraftDetailLocationPlaceholder
                                }
                                className={`select-input select-Workplace dropdown-phillip-searcher`}
                                onChange={this.handleLocationSelect}
                                options={allLocationsList}
                                clearable={true}
                                searchable={true}
                              />
                            </Col>
                            {currentSearch?.Countries?.length > 0 && (
                              <Col span={24} className="form-row">
                                <CapsuleList className="selected-locations-list">
                                  {map(currentSearch?.Countries, (item) => (
                                    <label
                                      test-data-id="resume-edit-suggested-role-item"
                                      className="draggable"
                                      style={{
                                        marginRight: "8px",
                                      }}
                                      onClick={() =>
                                        this.handleCountryDelete({
                                          selectedCountry: item,
                                        })
                                      }
                                      key={item.CountryId}
                                    >
                                      {item.CountryName}
                                    </label>
                                  ))}
                                </CapsuleList>
                              </Col>
                            )}
                            <Col span={24} style={{ marginBottom: "8px" }}>
                              <label>
                                {
                                  labels?.searcher_phillip_automate_step3_thirdpara
                                }
                              </label>
                            </Col>
                            <Col span={24}>
                              <Select
                                name="WorkplaceId"
                                className={`select-input select-Workplace dropdown-phillip-searcher`}
                                placeholder={
                                  labels?.SearcherOpportunityDraftDetailCompanyPlaceholder
                                }
                                value={
                                  (currentSearch &&
                                    currentSearch.selectedWorkplace) ||
                                  ""
                                }
                                onChange={(selectedOption) =>
                                  this.onFormSelectChange(
                                    "selectedWorkplace",
                                    selectedOption
                                  )
                                }
                                clearable={false}
                                searchable={false}
                                options={
                                  this?.state?.allWorkplaces?.length > 0
                                    ? this.state.allWorkplaces
                                    : [
                                        {
                                          label: "Add new workplace",
                                          value: 0,
                                        },
                                      ]
                                }
                              />
                            </Col>
                          </Row>

                          <div
                            style={{
                              marginTop: "3px",
                            }}
                          >
                            {currentSearch?.selectedWorkplace?.value == 0 && (
                              <input
                                type="text"
                                autoComplete="off"
                                name="CompanyName"
                                className="input-text opportunity-rate"
                                placeholder={labels?.companyNamePlaceholder}
                                style={{
                                  background: "#fff",
                                  borderRadius: "8px",
                                  height: "36px",
                                  marginBottom: "10px",
                                  border: this.state?.validation?.companyName
                                    ? "1px solid #f54949"
                                    : "1px solid #fff",
                                }}
                                value={
                                  (currentSearch &&
                                    currentSearch?.CompanyName) ||
                                  ""
                                }
                                onChange={this.onFormFieldChange}
                                onBlur={this.onFormFieldChange}
                                test-data-id="input-txt-opportunity-hourlyRate"
                              />
                            )}
                          </div>
                        </div>
                        <button
                          onClick={this.saveResumeSearch}
                          className="action-btn"
                        >
                          {labels?.searcher_phillip_automate_step3_button_text}{" "}
                        </button>
                      </div>
                    }
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  let { FirstName } = user;
  FirstName =
    FirstName !== undefined ? FirstName : sessionStorage.getItem("userName");
  return { labels, FirstName };
};
export default connect(mapStateToProps, { notificationAction })(
  SearcherPhillip
);
