import { ActionTypes } from "../../../actions";

export const onStateChangeAction = ({
  isLoading,
  isFetching,
  opportunities,
  filterOpportunires,
  selectedOpportunity,
  isEmptyCollapsed,
  listCollapsed,
  formCollapsed,
  shortlistCollapsed,
  resumesCollapsed,
  resumeDetailCollapsed,
  shortlists,
  shortlistResumes,
  selectedResume,
  selectedShortlist,
  selectedShortlists,
  dialogMessage,
  yesClickCount,
  selectedSaveSearch,
  selectedCompany,
  savedSearches,
  userCompanies,
  languages,
  locations,
  isFetchingShortlist,
  isFetchingShortlistResume,
  opportunitySaveLoading,
  filterShortlists,
  searchKey,
  opportunityToDelete,
  allCurrenciesList
}) => dispatch => {
  opportunityToDelete !== undefined &&
  dispatch({
    type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.OPPORTUNITY_TO_DELETE,
    payload: opportunityToDelete
  });
  isLoading !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.IS_LOADING,
      payload: isLoading
    });
  isFetching !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.IS_FETCHING,
      payload: isFetching
    });
  opportunities !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.OPPORTUNITIES,
      payload: opportunities
    });
  filterOpportunires !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.FILTER_OPPORTUNIRES,
      payload: filterOpportunires
    });
  selectedOpportunity !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_OPPORTUNITY,
      payload: selectedOpportunity
    });
  isEmptyCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.IS_EMPTY_COLLAPSED,
      payload: isEmptyCollapsed
    });
  listCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.LIST_COLLAPSED,
      payload: listCollapsed
    });
  formCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.FORM_COLLAPSED,
      payload: formCollapsed
    });
  shortlistCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SHORTLIST_COLLAPSED,
      payload: shortlistCollapsed
    });
  resumesCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.RESUMES_COLLAPSED,
      payload: resumesCollapsed
    });
  resumeDetailCollapsed !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.RESUME_DETAIL_COLLAPSED,
      payload: resumeDetailCollapsed
    });
  shortlists !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SHORTLISTS,
      payload: shortlists
    });
  shortlistResumes !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SHORTLIST_RESUMES,
      payload: shortlistResumes
    });
  selectedResume !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_RESUME,
      payload: selectedResume
    });
  selectedShortlist !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_SHORTLIST,
      payload: selectedShortlist
    });
  selectedShortlists !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_SHORTLISTS,
      payload: selectedShortlists
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.DIALOG_MESSAGE,
      payload: dialogMessage
    });
  yesClickCount !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.YES_CLICK_COUNT,
      payload: yesClickCount
    });
  selectedSaveSearch !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_SAVE_SEARCH,
      payload: selectedSaveSearch
    });
  selectedCompany !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SELECTED_COMPANY,
      payload: selectedCompany
    });
  savedSearches !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SAVED_SEARCHES,
      payload: savedSearches
    });
  userCompanies !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.USER_COMPANIES,
      payload: userCompanies
    });
  languages !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.LANGUAGES,
      payload: languages
    });
  locations !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.LOCATIONS,
      payload: locations
    });
  isFetchingShortlist !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.IS_FETCHING_SHORTLIST,
      payload: isFetchingShortlist
    });
  isFetchingShortlistResume !== undefined &&
    dispatch({
      type:
        ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.IS_FETCHING_SHORTLIST_RESUME,
      payload: isFetchingShortlistResume
    });
  opportunitySaveLoading !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.OPPORTUNITY_SAVE_LOADING,
      payload: opportunitySaveLoading
    });
  filterShortlists !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.FILTER_SHORTLISTS,
      payload: filterShortlists
    });
  searchKey !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.SEARCH_KEY,
      payload: searchKey
    });
  allCurrenciesList !== undefined &&
    dispatch({
      type: ActionTypes.OPPORTUNITIES_DRAFT_SEARCHER.ALL_CURRENCIES_LIST,
      payload: allCurrenciesList
  });
};
