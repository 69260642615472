import { Button, Input, Modal, Space } from "antd";
import React from "react";
import "../NewResumeSearch/search.scss";
import { useSelector } from "react-redux";
import { useState } from "react";
import closeIcon from '../../assets/images/Icons/close-new.png'
import ImageModal from '../../assets/images/delete-popup.png'

const DeleteSaveSearchModal = ({ open, setOpen, handleOk }) => {
    const label = useSelector((state) => state?.systemLabel?.labels);
    const [error, setError] = useState(false);

    const handleCancel = () => {
        setOpen({ show: false });
    }

    return (
        <>
            <Modal
                zIndex={105}
                open={open?.show}
                onCancel={() => {
                    handleCancel()
                }}
                centered
                footer={null}
                closable={false}
                style={{ maxHeight: "calc(100% - 32px)", overflow: "auto" }}
                className={`new-design-modal shortlist-modal `}
            >
                <div className="text-center header-modal">
                    <h5>{`Delete Save Search`}</h5>
                    <div
                        onClick={() => handleCancel()}
                        className="pointer"
                    >
                        <img className="close-icon" src={closeIcon} alt="" />
                    </div>
                </div>
                <div className="form">
                    <div className="images-icon">
                        <img src={ImageModal} alt="" />
                        <p className="paragraph">Are you sure you want to<br /> delete save search?</p>
                    </div>
                    <div className="d-flex gap-16 justify-content-between">
                        <Button
                            type="primary"
                            block
                            size="large"
                            htmlType="submit"
                            className="login-form-button button-last-footer mt-4"
                            onClick={() => { handleCancel() }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            block
                            size="large"
                            htmlType="submit"
                            className="login-form-button mt-4"
                            onClick={() => { handleOk() }}
                        >
                            Delete
                        </Button>
                    </div>
                </div>

            </Modal>
        </>
    );
};
export default DeleteSaveSearchModal;
