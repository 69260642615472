import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  isHelpActive: false,
  unreadMessages: 0,
  unreadCollaborations: 0,
  acceptedCollaboration: 0,
  declinedCollaboration: 0,
  searcherInActiveCollaboration: 0,
  unreadproInactiveCollaborations: 0,
  unreadOpportunities: {
    newOpportunities: 0
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.HELP_ACTIVE:
      return {
        ...state,
        isHelpActive: action.payload
      };
    case ActionTypes.UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: action.payload
      };
    case ActionTypes.UNREAD_COLLABORATION:
      return {
        ...state,
        ...action.payload
      };
    case ActionTypes.UNREAD_OPPORTUNITIES:
      return {
        ...state,
        unreadOpportunities: {
          ...state.unreadOpportunities,
          ...action.payload
        }
      };
    default:
      return state;
  }
};
