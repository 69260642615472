import React, { Component } from "react";
import { Panel, ListGroup, Input, Select, Button } from "../../../../common";
import AsyncSelect from "react-select/lib/Async";
import { map, isEmpty } from "lodash";
import { getSkillsApi } from "../resumeEditApi";
import SuggestedSkill from "./SuggestedSkill";

class Skill extends Component {
  SkillProficiencyList = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Proficient" },
    { value: 3, label: "Expert" },
  ];

  handleSkillsLoad = (searchKey, callback) => {
    if (isEmpty(searchKey)) {
      const { skillDefaultOptions } = this.props;
      callback(null, {
        options: map(skillDefaultOptions, (Skill) => {
          return {
            ...Skill,
            label: Skill.SkillValue,
            value: Skill.SkillId,
          };
        }),
      });
      return;
    }
    if (searchKey.length < 3) {
      callback([]);
      return;
    }
    getSkillsApi({ searchKey }).then((response) => {
      if (response.success) {
        callback(null, {
          options: map(response.items, (Skill) => {
            return {
              ...Skill,
              label: Skill.SkillValue,
              value: Skill.SkillId,
            };
          }),
        });
      }
    });
  };

  render() {
    const {
      isHelpActive,
      labels,
      skillsList,
      onSkillNameChange,
      onSkillLevelChange,
      onSkillDelete,
      onSkillAdd,
      onSkillBlur,
      onSkillSuggestionButtonClick,
      suggestedSkills,
      onSuggesstedSkillAdd,
      suggestObject,
    } = this.props;

    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>{labels.RESUME_EDIT_SKILL_SECTION_TITLE}</Panel.Title>
          <Panel.Add
            onClick={onSkillAdd}
            isHelpActive={isHelpActive}
            tooltipPlace="left"
            tooltipButton={labels.RESUME_EDIT_SKILL_ADD_BUTTON_TOOLTIP}
            tooltipHelp={labels.RESUME_EDIT_SKILL_ADD_BUTTON_HELP}
            testId="resume-edit-skill-add-btn"
            testIdHelp="resume-edit-skill-add-help-btn"
          />
        </Panel.Heading>
        <Panel.Body>
          {suggestedSkills && !isEmpty(suggestObject) && (
            <SuggestedSkill
              onSuggesstedSkillAdd={onSuggesstedSkillAdd}
              suggestedSkillsList={suggestedSkills}
              suggestObject={suggestObject}
              labels={labels}
            />
          )}
          <ListGroup>
            {skillsList.map((item) => (
              <ListGroup.Item key={item.uniqueId}>
                <ListGroup.Column size={0}>
                  <Button
                    onClick={() =>
                      onSkillSuggestionButtonClick(
                        item.SkillId,
                        "Skill",
                        item.SkillValue,
                        item.uniqueId
                      )
                    }
                    // className={`draggable-btn ${item.isActive ? "active" : ""}`}
                    className={`draggable-btn ${
                      item.isCandidate ? "candidate" : ""
                    } ${item.isActive ? "active" : ""}`}
                    isHelpActive={isHelpActive}
                    tooltipPlace="left"
                    tooltipButton={
                      item.isCandidate
                        ? `${item.SkillValue} ${labels.RESUME_EDIT_CANDIDATE_SUGGESTION_BUTTON_TOOLTIP}`
                        : `${labels.RESUME_EDIT_SUGGESTION_BUTTON_TOOLTIP} ${item.SkillValue}`
                    }
                    tooltipHelp={
                      item.isCandidate
                        ? `${item.SkillValue} ${labels.RESUME_EDIT_CANDIDATE_SUGGESTION_BUTTON_HELP}`
                        : `${labels.RESUME_EDIT_SUGGESTION_BUTTON_HELP} ${item.SkillValue}`
                    }
                    testId="resume-edit-skill-suggestion-btn"
                    testIdHelp="resume-edit-skill-suggestion-help-btn"
                    disabled={item.isCandidate || item.SkillValue === ""}
                  />
                </ListGroup.Column>
                <ListGroup.Column>
                  <AsyncSelect
                    className={`select-input select-skill-name select-skill ${
                      item.isInvalid == true ? "inValid" : ""
                    }`}
                    placeholder={labels.RESUME_EDIT_SKILL_NAME_PLACEHOLDER}
                    cacheOptions
                    defaultOptions
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    loadOptions={this.handleSkillsLoad}
                    onChange={(options) =>
                      onSkillNameChange(options, item.uniqueId)
                    }
                    value={{
                      value: item.SkillId,
                      label: item.SkillValue,
                    }}
                    onBlur={(options) =>
                      onSkillBlur(
                        item.SkillId,
                        item.uniqueId,
                        options,
                        item.ExperienceLevel
                      )
                    }
                    clearable
                    disabled={item.isCandidate}
                  />
                </ListGroup.Column>
                <ListGroup.Column>
                  <Select
                    name={"Skill Level"}
                    className={`select-skill-level select-input`}
                    value={item.ExperienceLevel}
                    onChange={(options) =>
                      onSkillLevelChange(options, item.uniqueId)
                    }
                    options={this.SkillProficiencyList}
                    placeholder={labels.RESUME_EDIT_SKILL_NAME_PLACEHOLDER}
                  />
                </ListGroup.Column>
                <ListGroup.Column size={0}>
                  <div
                    // disabled={isDisabled}
                    onClick={() => onSkillDelete(item)}
                    className="close_btn_purple"
                  >
                    <i className="fa fa-times-circle" />
                  </div>
                </ListGroup.Column>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Panel.Body>
      </Panel>
    );
  }
}

export default Skill;
