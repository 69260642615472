import React, { Component } from "react";
import { LoadingMask, DatePicker } from "../../../../../common";
import Select from "react-select";
import Slider from "rc-slider";
import moment from "moment";
import "./createnewcollaboration.scss";
import { CustomInputNew } from "../../../../Tictell/views/components/CustomInput";

const Handle = Slider.Handle;
class CreateNewCollaboration extends Component {
  viewRender = () => {
    const {
      collaborationForm,
      labels,
      onFormFieldChange,
      onFormSelectChange,
      onDateChange,
      onSliderChange,
      opportunityList,
      iprosList,
      onCollaborationSave,
      Currencies,
    } = this.props;
    const handle = (props) => {
      const { value, dragging, index, ...restProps } = props;
      return (
        <Handle value={value} key={index} {...restProps}>
          {value}
        </Handle>
      );
    };
    const {
      Title,
      invalidTitle,
      StartDate,
      invalidStartDate,
      DurationType,
      invalidDurationType,
      HourlyRate,
      invalidHourlyRate,
      HourlyRateType,
      invalidHourlyRateType,
      RequestId,
      invalidRequestId,
      ResumeId,
      invalidResumeId,
      Description,
      Duration,
    } = collaborationForm;

    const durationTypeList = [
      { value: "Days", label: "Days" },
      { value: "Weeks", label: "Weeks" },
      { value: "Months", label: "Months" },
      { value: "Years", label: "Years" },
    ];
    return (
      <div className="Collaboration-form-editor-searcher">
        <CustomInputNew isValid={!invalidTitle} label={labels.collTitle}>
          <input
            autoFocus={invalidTitle}
            type="text"
            name="Title"
            className={`input-text`}
            value={Title ? Title : ""}
            placeholder={labels.collFormTitle}
            onChange={onFormFieldChange}
            onBlur={onFormFieldChange}
            test-data-id="input-text-collaboration-title"
          />
        </CustomInputNew>
        <CustomInputNew
          isValid={!invalidStartDate}
          label={labels.collStartDate}
        >
          <label className="datepicker-wrapper">
            <DatePicker
              name="DatePicker"
              dateFormat="DD-MM-YYYY"
              selected={StartDate && moment(StartDate)}
              onChange={onDateChange}
              todayButton="Today"
              placeholder={labels.collStartDate}
              className={``}
              minDate={moment()}
              placeholderText={"DD-MM-YYYY"}
            />
          </label>
        </CustomInputNew>
        <div className="start-end">
          <CustomInputNew label={labels.collDuration}>
            <Slider
              name="durationSlider"
              min={0}
              max={20}
              defaultValue={4}
              value={Duration}
              handle={handle}
              onChange={onSliderChange}
            />
          </CustomInputNew>
          <CustomInputNew
            isValid={!invalidDurationType}
            label={labels.collDurationtypelabel}
          >
            <Select
              name="DurationType"
              className={`select-input select-durationType `}
              value={DurationType || ""}
              onChange={(selectedOption) =>
                onFormSelectChange("DurationType", selectedOption)
              }
              options={durationTypeList}
              clearable={false}
            />
          </CustomInputNew>
        </div>
        <div className="start-end">
          <CustomInputNew
            isValid={!invalidHourlyRate}
            label={labels.collHourlyFee}
          >
            <input
              autoFocus={invalidHourlyRate}
              type="text"
              name="HourlyRate"
              className={`input-text`}
              value={HourlyRate ? HourlyRate : ""}
              placeholder={labels.SearcherOpportunityDraftDetailHourlyFee}
              onChange={onFormFieldChange}
              onBlur={onFormFieldChange}
              test-data-id="input-txt-collaboration-hourlyRate"
            />
          </CustomInputNew>
          <CustomInputNew
            isValid={!invalidHourlyRateType}
            label={labels.snapshotCurrencyLabel}
          >
            <Select
              name="HourlyRateType"
              className={`select-input select-hourlyRate `}
              value={HourlyRateType || ""}
              onChange={(selectedOption) =>
                onFormSelectChange("HourlyRateType", selectedOption)
              }
              clearable={false}
              options={Currencies}
            />
          </CustomInputNew>
        </div>
        <CustomInputNew
          isValid={!invalidRequestId}
          label={labels.collOpportunity}
        >
          <Select
            name="OpprtunityId"
            className={`select-input select-opportunity `}
            value={RequestId || ""}
            onChange={(selectedOption) =>
              onFormSelectChange("OpprtunityId", selectedOption)
            }
            autoBlur={true}
            options={opportunityList}
            clearable={false}
          />
        </CustomInputNew>
        <CustomInputNew isValid={!invalidResumeId} label={labels.colliPro}>
          <Select
            name="ResumeId"
            className={`select-input select-ipro`}
            value={ResumeId || ""}
            onChange={(selectedOption) =>
              onFormSelectChange("ResumeId", selectedOption)
            }
            options={iprosList}
            clearable={false}
          />
        </CustomInputNew>
        <CustomInputNew label={labels.SNAPSHOT_COLLABORATION_DESC}>
          <textarea
            name="Description"
            className="textarea-field"
            rows="9"
            placeholder={labels.collDescription}
            value={Description || ""}
            onChange={onFormFieldChange}
            test-data-id="input-text-collaboration-description"
          />
        </CustomInputNew>

        <button
          className="SendButton"
          onClick={onCollaborationSave}
          test-data-id="input-button-collaboration-sendBtn"
        >
          {"Send collaboration for approval"}
        </button>
      </div>
    );
  };

  render() {
    const { isLoading } = this.props;
    return (
      <>
        {isLoading && <LoadingMask />}
        {this.viewRender()}
      </>
    );
  }
}

export default CreateNewCollaboration;
