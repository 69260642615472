import React from "react";
import { Modal } from "antd";
import CompanyDetailNew from "../../../../../common/companyDetail/companyDetail";

const CompanyDetailMobileModal = (props) => {
  const {
    handleModalClick,
    selectedCompany,
    addressLabel,
    industryLabel,
    countryLabel,
    phoneLabel,
    webUrlLabel,
    vatLabel,
    setIsModalOpen,
    isModalOpen,
    labels,
  } = props;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        style={{ top: 20 }}
        onOk={handleOk}
        zIndex={100}
        onCancel={handleCancel}
        footer={false}
        className="new-search-resume-main for-mobile-modal-shortlist"
      >
        <div style={{ marginTop: "5px" }} className="new-search-resume-main">
          <div className="h-100 flex flex-column">
            <div
              style={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ overflow: "auto" }}>
                <CompanyDetailNew
                  selectedCompany={selectedCompany}
                  addressLabel={addressLabel}
                  industryLabel={industryLabel}
                  countryLabel={countryLabel}
                  phoneLabel={phoneLabel}
                  webUrlLabel={webUrlLabel}
                  vatLabel={vatLabel}
                  labels={labels}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CompanyDetailMobileModal;
