import React from 'react';
import { Column, Button, Select, EmptyInfo, LoadingMaskRow } from "../../../../common";
import {
  GetResumesByResumeId,
  GetUserDetailByResumeAndRequestId,
  getAllCollaborationsApi,
  GetSharedCollaborationInfo,
  DeleteShareCollaborationApi,
  ShareCollaborationApi,
  GetPoolsApi,
  GetPoolCollaborationsApi,
  DeletePoolApi,
  UpdatePoolApi,
  AddPoolApi,
  GetCollaborationExpenseDetailApi,
  GetCollaborationTimeDetailApi,
  GetPoolTimeDetailApi,
  GetPoolExpenseDetailApi,
  GetSharedPoolInfo,
  SharePoolApi,
  DeleteSharePoolApi
} from "../../snapshotApi";
import {isEmpty} from "lodash"
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import './pools.scss';
import ImgSrc from "../../../../assets/images/present.svg";
import { isValidEmail } from '../../../../utilities';
import TimeAndExpenses from './components/TimeAndExpenses';
import ResumeDetail from '../../components/ResumeDetail';
import Presentations from '../../components/PresentationDetails';
import { CustomInputNew } from '../../../Tictell/views/components/CustomInput';
import { addUserPhoneNumberApi } from '../../../../components/Phillip/phillipApi';
import Collaboration from '../../../Collaboration/common/Collaboration';
import moment from "moment";
// import PresentationDetails from '../../Components/PresentationDetails';
// import ResumeDetail from '../../Components/ResumeDetail';
class Pools extends React.Component{
    state = {
        options : [
          { label: "", value: 1 },
          { label: "", value: 2 },
          { label: "", value: 3 },
          { label: "", value: 4 }
        ],
        acceptedCollaborations: [],
        selectedUser:{},
        selectedResume:[],
        allPools:[],
        isCollapsed1: false,
        isCollapsed2: false,
        isCollapsed3: true,
        isCollapsed4: true,
        isCollapsed5: true,
        allTimeSheets: [],
        weekTimelogsList: [],
        weekExpenselogsList: [],
        currentExpenselog: {},
        currentTimeReport: {
          StatusId: 1,
          isEmpty:true,
          Amount:"",
          Time:""
        },
        currentTimelog: {},
        TimeSheetStatusId:2,
        ExpenseSheetStatusId:2,
        isLoading: true,
        selectedCollaboration:{},
        selectedPool:{},
        allActiveCollaborations:[],
        poolDetails:{
          PoolId:0,
          Title:'',
          Collaborations:[]
        },
        durationOptions : [
          { label: "", value: 1 },
          { label: "", value: 2 },
          { label: "", value: 3 }],
        StartDate: moment().startOf('months'),
        EndDate:moment(),
        TimeDetail:{},
        ExpenseDetail:{},
        PoolTimeDetail:{},
        PoolExpenseDetail:{},
        selectedDuration:{},
        isPoolsLoading:true,
        sharedPoolInfo: []
      };
      componentDidUpdate(prevProps,prevState){
        if(prevState.selectedCollaboration.CollaborationId != this.state.selectedCollaboration.CollaborationId){
          this.GetPoolAndCollaborationTimeAndExpenseDetail(this.state.selectedCollaboration,this.state.poolDetails,this.state.StartDate,this.state.EndDate);
        }
      }
      componentDidMount() {
        this.setState({
          durationOptions : [
            { label: "Current Month", value: 1 },
            { label: "Last Month", value: 2 },
            { label: "Year to date", value: 3 }],
            selectedDuration:{ label: "Current Month", value: 1 }
        });
        GetPoolsApi(this.props.token).then(res => {
            if (res.items.length>0) {
                const allPools = res.items.map(pool=>({
                  ...pool,
                  IsShared:pool.UserId != this.props.UserId
                }));
              this.setState({
                allPools
              });
              this.handlePoolClick(res.items[0]);
            }
            this.setState({
              isPoolsLoading:false
            });
          });
          getAllCollaborationsApi(1, this.props.token).then(res => {
            if (res.items.length>0) {
              const allActiveCollaborations = res.items.map(coll=>({
                ...coll,
                IsShared:coll.UserId != this.props.UserId,
                value:coll.CollaborationId,
                label:coll.UserName+"("+coll.Title+")"
              }));
              this.setState({
                allActiveCollaborations
              });
              // this.handleActiveCollaborationClick(allActiveCollaborations[0]);
            }
          });
      }
      handleCollaborationClick = (item)=>{
        const {poolDetails}= this.state;
        if(!item){
          this.setState({
              selectedCollaboration:{}
          })
          return;
        }
        if(!item.IsShared){
          GetSharedCollaborationInfo(item.CollaborationId,this.props.token)
          .then(res=>{
              item = {
                ...item,
                SharedInfo : res.items.map(a=>({
                  ...a,
                  CollaborationId : item.CollaborationId
                }))
              }
              this.setState({
                selectedCollaboration:{
                  ...item,
                  newEmail:""
                },
                poolDetails:{
                  ...this.state.poolDetails,
                  Collaborations: this.state.poolDetails.Collaborations.map(a =>
                    a.CollaborationId == item.CollaborationId
                      ? { ...a, isSelected: true, isActive: true }
                      : { ...a, isSelected: false, isActive: false }
                  ),
                },
                isCollapsed2: false,
                isCollapsed3: false                
              })
          })
          .catch(err=>console.log("Err ",err))
        }else{
          this.setState({
            selectedCollaboration:{
              ...item,
              newEmail:""
            },
            poolDetails:{
              ...this.state.poolDetails,
              Collaborations: this.state.poolDetails.Collaborations.map(a =>
                a.CollaborationId == item.CollaborationId
                  ? { ...a, isSelected: true, isActive: true }
                  : { ...a, isSelected: false, isActive: false }
              ),
            },
            isCollapsed2: false
          })
        }
        GetUserDetailByResumeAndRequestId(item.ResumeId, item.RequestId)
        .then(res => {
          if (res.success) {
            this.setState({
              selectedUser: res.items
            });
          }
        });
        GetResumesByResumeId(item.ResumeId)
        .then(res => {
          if (res.success) {
            this.setState({
              selectedResume: res.items
          });
        }
        })
        .catch(response => {
          console.log("error in get resume request ");
        });    
      }
      handleShareCollaborationDelete = (item)=>{
          DeleteShareCollaborationApi(item.CollaborationId,item.UserId,this.props.token)
          .then(res=>{
            if(res.success){
              this.setState({
                selectedCollaboration:{
                  ...this.state.selectedCollaboration,
                  SharedInfo:this.state.selectedCollaboration.SharedInfo.filter(a=>a.UserId != item.UserId)
                }
              })
              const info = {
                status:"success",
                message: this.props.labels.SNAPSHOT_COLLABORATIONS_UN_SHARE_SUCCESS
              };
              this.props.notificationAction(info);
            }
          })
      }
      handleShareCollaborationInputChange = (e)=>{
          const { name, value } = e.target;
          this.setState({
            selectedCollaboration: {
              ...this.state.selectedCollaboration,
              newEmail: value
            }
          });
      }
      handleShareCollaboration = ()=>{
        const {CollaborationId,newEmail,SharedInfo } =  this.state.selectedCollaboration;
        if(!isValidEmail(newEmail)){
          const info = {
            status:"error",
            message: this.props.labels.VALID_EMAIL
          };
          this.props.notificationAction(info);
          return;
        }
        ShareCollaborationApi(CollaborationId,newEmail,this.props.token)
        .then(res=>{
          if(res.success){
            const sharedInfo = SharedInfo.concat({CollaborationId:CollaborationId,UserEmail:newEmail,UserId:res.items.UserId})
            this.setState({
              selectedCollaboration:{
                ...this.state.selectedCollaboration,
                SharedInfo : sharedInfo,
                newEmail:""
              }
            })
            const info = {
              status:"success",
              message: this.props.labels.SNAPSHOT_COLLABORATIONS_SHARE_SUCCESS
            };
            this.props.notificationAction(info);
          }else{
            const info = {
              status:"error",
              message: res.message
            };
            this.props.notificationAction(info);
          }
        })
      }
      handlePoolClick=(item)=>{
        if(!item){
          this.setState({
              poolDetails:{}
          })
          return;
        }
        if(!item.IsShared){
          GetSharedPoolInfo(item.PoolId,this.props.token)
          .then(res=>{
              item = {
                ...item,
                SharedInfo : res.items.map(a=>({
                  ...a,
                  PoolId : item.PoolId
                }))
              }
              this.setState({
                sharedPoolInfo:{
                  ...item,
                  newEmail:""
                },
                isCollapsed2: false,
                isCollapsed3: false                
              })
          })
          .catch(err=>console.log("Err ",err))
        }
        GetPoolCollaborationsApi(item.PoolId,this.props.token)
        .then(res=>{
            if(res.success){
              this.setState({
                poolDetails:{
                  Title:item.Title,
                  PoolId:item.PoolId,
                  Collaborations:res.items  
                },
                allPools: this.state.allPools.map(a =>
                  a.PoolId == item.PoolId
                    ? { ...a, isSelected: true, isActive: true }
                    : { ...a, isSelected: false, isActive: false }
                ),
                selectedActiveOption: undefined
              });
              
              this.handleCollaborationClick(res.items[0]);
            }
        })
        .catch(err=>console.log("Error ",err))
      }
    onDelete=(item)=>{
      DeletePoolApi(item.PoolId,this.props.token)
      .then(res=>{
        let poolDetailsIndex = this.state.allPools.findIndex(a=>a.PoolId == item.PoolId);
        const allPools = this.state.allPools.filter(
          a => a.PoolId != item.PoolId
        );
        const index = this.state.poolDetails.PoolId == item.PoolId ? (poolDetailsIndex == allPools.length ? 0 : poolDetailsIndex++) : allPools.findIndex(a=>a.PoolId == this.state.poolDetails.PoolId);
        if(res.success){
          this.setState({
            allPools
          })
          this.handlePoolClick(allPools[index]);
          const info = {
            status:"success",
            message: this.props.labels.SNAPSHOT_POOL_DELETE_SUCCESS
          };
          this.props.notificationAction(info);
        }else{
          const info = {
            status:"error",
            message: this.props.labels.SNAPSHOT_POOL_DELETE_ERROR
          };
          this.props.notificationAction(info);
        }
      })
      .catch(err=>console.log("Err ",err))
    }
    handlePoolNameChange=(e)=>{
      this.setState({
        poolDetails:{
          ...this.state.poolDetails,
          Title : e.target.value
        }
      })
    }
    handlePoolNameBlur=(e)=>{
      this.savePool();
    }
    handleActiveCollaborationClick = option => {
      this.setState({
        selectedActiveOption: option
      });
      const { poolDetails } = this.state;
      const { Collaborations } = poolDetails;
      const updatePoolDetail = {
        ...poolDetails,
        Collaborations: Collaborations && Collaborations.findIndex(a=>a.CollaborationId == option.CollaborationId) < 0 ? Collaborations.concat(option) : Collaborations
      };
      this.setState({
        poolDetails:updatePoolDetail
      })
      this.savePool(updatePoolDetail);
    };
    handleCollaborationRemove=(option)=>{
      const { poolDetails } = this.state;
      const { Collaborations } = poolDetails;
      const updatePoolDetail = {
        ...poolDetails,
        Collaborations: Collaborations.filter(a=>a.CollaborationId != option.CollaborationId)
      };
      this.setState({
        poolDetails:updatePoolDetail
      })
      this.savePool(updatePoolDetail);
    }
    savePool=(updatePoolDetail)=>{
      let {poolDetails} = this.state;
      if(updatePoolDetail)
        poolDetails = updatePoolDetail;
      const {token} = this.props;
      //Save or Update Pool Collaborations If Name Fields is not Empty
      if(poolDetails.Title){
        const pool = {
          PoolId : poolDetails.PoolId,
          Title : poolDetails.Title,
          CollaborationIds : poolDetails.Collaborations.map(a=>a.CollaborationId)
        };
        if(poolDetails.PoolId > 1 ){
          UpdatePoolApi(token,pool)
          .then(res=>{
            this.GetPools(poolDetails.PoolId);
            const info = {
              status: "success",
              message: this.props.labels.Pool_Updated_Succesfully
            };
            this.props.notificationAction(info);
          })
          .catch(err=>console.log("Error ",err))
        }else{
          AddPoolApi(token,pool)
          .then(res=>{
            if (res.success) {
              this.GetPools(res.items.PoolId);
              const info = {
              status: "success",
              message: this.props.labels.Pool_Added_Succesfully
            };
            this.props.notificationAction(info);
            }else{
              const info = {
                status: "error",
                message: this.props.labels.Pool_Already_Exist
              };
              this.props.notificationAction(info);
            }
          })
          .catch(err=>console.log("Error ",err))
        }
      }
    }
    GetPools(currentPoolId){
      this.setState({
        isPoolsLoading:true
      })
      GetPoolsApi(this.props.token).then(res => {
        if (res.items.length>0) {
          this.setState({
            allPools: res.items,
            isPoolsLoading:false
          });
          this.handlePoolClick(res.items.find(a=>a.PoolId == currentPoolId));
        }
      });
    }
    handleAddNewPool=()=>{
      this.setState({
        poolDetails:{
          PoolId:0,
          Title:'',
          Collaborations:[]
        },
        selectedActiveOption: undefined
      })
    }
    GetPoolAndCollaborationTimeAndExpenseDetail = (colllaboration,poolDetail,StartDate,EndDate) =>{
        this.GetCollaborationTimeDetail(moment(StartDate).format("DD/MM/YYYY"),moment(EndDate).format("DD/MM/YYYY"),colllaboration,poolDetail);
        this.GetCollaborationExpenseDetail(moment(StartDate).format("DD/MM/YYYY"),moment(EndDate).format("DD/MM/YYYY"),colllaboration,poolDetail);
        this.GetPoolTimeDetail(moment(StartDate).format("DD/MM/YYYY"),moment(EndDate).format("DD/MM/YYYY"),colllaboration,poolDetail);
        this.GetPoolExpenseDetail(moment(StartDate).format("DD/MM/YYYY"),moment(EndDate).format("DD/MM/YYYY"),colllaboration,poolDetail);
    }
    GetCollaborationTimeDetail(StartDate,EndDate,collaboration){
      const { token } = this.props;
      const {CollaborationId } = collaboration; 
      if(!CollaborationId) return;
      GetCollaborationTimeDetailApi(CollaborationId,StartDate,EndDate,token)
      .then(res=>{
        this.setState({
          TimeDetail:res.items
        })
      })
      .catch(err=>console.log("Err ",err))
    }
    GetCollaborationExpenseDetail(StartDate,EndDate,collaboration){
      const { token } = this.props;
      const {CollaborationId } = collaboration; 
      if(!CollaborationId) return;
      GetCollaborationExpenseDetailApi(CollaborationId,StartDate,EndDate,token)
      .then(res=>{
        this.setState({
          ExpenseDetail:res.items
        })
      })
      .catch(err=>console.log("Err ",err))
    }
    GetPoolTimeDetail(StartDate,EndDate,collaboration,poolDetail){
      const { token } = this.props;
      const {PoolId } = poolDetail; 
      GetPoolTimeDetailApi(PoolId,StartDate,EndDate,token)
      .then(res=>{
        this.setState({
          PoolTimeDetail:res.items
        })
      })
      .catch(err=>console.log("Err ",err))
    }
    GetPoolExpenseDetail(StartDate,EndDate,collaboration,poolDetail){
      const { token } = this.props;
      const {PoolId } = poolDetail; 
      GetPoolExpenseDetailApi(PoolId,StartDate,EndDate,token)
      .then(res=>{
        this.setState({
          PoolExpenseDetail:res.items
        })
      })
      .catch(err=>console.log("Err ",err))
    }
    handleStartDateChange = d => {
      if(moment(d) >= moment(this.state.EndDate)){
        const info = {
          status:"error",
          message: this.props.labels.Collaboration_Time_And_Expense_Start_Date_Validation_Message
        };
        this.props.notificationAction(info);
        return;
      }
      this.setState({
        StartDate:d
      });
      this.GetCollaborationTimeDetail(moment(d).format("DD/MM/YYYY"),moment(this.state.EndDate).format("DD/MM/YYYY"),this.state.selectedCollaboration,this.state.poolDetails);
      this.GetCollaborationExpenseDetail(moment(d).format("DD/MM/YYYY"),moment(this.state.EndDate).format("DD/MM/YYYY"),this.state.selectedCollaboration,this.state.poolDetails);
      this.GetPoolTimeDetail(moment(d).format("DD/MM/YYYY"),moment(this.state.EndDate).format("DD/MM/YYYY"),this.state.selectedCollaboration,this.state.poolDetails);
      this.GetPoolExpenseDetail(moment(d).format("DD/MM/YYYY"),moment(this.state.EndDate ).format("DD/MM/YYYY"),this.state.selectedCollaboration,this.state.poolDetails);
    };
    handleEndDateChange = d => {
      if(moment(d) <= moment(this.state.StartDate)){
        const info = {
          status:"error",
          message: this.props.labels.Collaboration_Time_And_Expense_Date_Validation_Message
        };
        this.props.notificationAction(info);
        return;
      }
      this.setState({
        EndDate:d
      });
      this.GetCollaborationTimeDetail(moment(this.state.StartDate).format("DD/MM/YYYY"),moment(d).format("DD/MM/YYYY"),this.state.selectedCollaboration,this.state.poolDetails);
      this.GetCollaborationExpenseDetail(moment(this.state.StartDate).format("DD/MM/YYYY"),moment(d).format("DD/MM/YYYY"),this.state.selectedCollaboration,this.state.poolDetails);
      this.GetPoolTimeDetail(moment(this.state.StartDate).format("DD/MM/YYYY"),moment(d).format("DD/MM/YYYY"),this.state.selectedCollaboration,this.state.poolDetails);
      this.GetPoolExpenseDetail(moment(this.state.StartDate).format("DD/MM/YYYY"),moment(d).format("DD/MM/YYYY"),this.state.selectedCollaboration,this.state.poolDetails);
    };
    handleDurationOptionChange = option => {
      this.setState({
        selectedDuration: option
      });
      let StartDate,EndDate;
      const { value } = option;
      if(value == 3){
          StartDate = moment().startOf('year');
          EndDate = moment();
        }else if(value == 2){
          StartDate = moment().subtract(1,'month').startOf('month');
          EndDate = moment().subtract(1,'month').endOf('month');
        }else {
          StartDate = moment().startOf('month');
          EndDate = moment();
      }
      this.setState({
        StartDate:StartDate,
        EndDate:EndDate
      })
      if(this.state.selectedCollaboration.CollaborationId)
        this.GetPoolAndCollaborationTimeAndExpenseDetail(this.state.selectedCollaboration,this.state.poolDetails,StartDate,EndDate);
    };

    handleSharePoolInputChange = (e)=>{
      const { name, value } = e.target;
      this.setState({
        sharedPoolInfo: {
          ...this.state.sharedPoolInfo,
          newEmail: value
        }
      });
  }

  handleSharePool = ()=>{
    const {newEmail,SharedInfo } =  this.state.sharedPoolInfo;
    const {PoolId } =  this.state.poolDetails;
    if(!isValidEmail(newEmail)){
      const info = {
        status:"error",
        message: this.props.labels.VALID_EMAIL
      };
      this.props.notificationAction(info);
      return;
    }
    SharePoolApi(PoolId,newEmail,this.props.token)
    .then(res=>{
      if(res.success){
        const sharedInfo = SharedInfo.concat({PoolId:PoolId,UserEmail:newEmail,UserId:res.items.UserId})
        this.setState({
          sharedPoolInfo:{
            ...this.state.sharedPoolInfo,
            SharedInfo : sharedInfo,
            newEmail:"",
            PreviousEndDate:sharedInfo.EndDate
          }
        })
        const info = {
          status:"success",
          message: this.props.labels.POOL_SHARE_SUCCESS_LABEL
        };
        this.props.notificationAction(info);
      }else{
        let info ={};
        if(res.message=="Pool already shared with that user."){
           info = {
            status:"error",
            message: this.props.labels.POOL_ALREADY_SHARED_LABEL
          };
        }else if(res.message=="Email Address Not exist in PDCore"){
          info = {
            status:"error",
            message: this.props.labels.USER_EMAIL_DOESNOT_EXIST
          };
        }else{
          info = {
            status:"error",
            message: this.props.labels.COLLABORATION_SHARE_FAILED
          };
        }
        this.props.notificationAction(info);
      }
    })
  }

  handleSharePoolDelete = (item)=>{
    DeleteSharePoolApi(item.PoolId,item.UserId,this.props.token)
    .then(res=>{
      if(res.success){
        this.setState({
          sharedPoolInfo:{
            ...this.state.sharedPoolInfo,
            SharedInfo:this.state.sharedPoolInfo.SharedInfo.filter(a=>a.UserId != item.UserId)
          }
        })
        const info = {
          status:"success",
          message: this.props.labels.POOL_SHARE_REMOVE_SUCCESS_LABEL
        };
        this.props.notificationAction(info);
      }
    })
}
    render(){
        const {
            isCollapsed1,
            isCollapsed2,
            isCollapsed3,
            isCollapsed4,
            isCollapsed5,
            selectedOption,
            selectedCollaboration,
            selectedUser,
            selectedResume,
            poolDetails,
            allPools,
            isPoolsLoading
          } = this.state;
          const { labels, isHelpActive,UserId } = this.props;
          const{
            SNAPSHOT_COLLABORATIONS,
            SNAPSHOT_COLLABORATION_DETAIL,
            SNAPSHOT_COLLABORATION_TIME_AND_EXPENSES,
            SNAPSHOT_COLLABORATION_PRESENT,
            SNAPSHOT_COLLABORATION_RESUME
          } = labels;
          return(
        <div className="pools-view">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={"Pools"}
            onClick={() => this.setState({ isCollapsed1: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn1"
          />
          <Column.Head>
            <div className="heading">{labels.Snapshot_Pools}</div>
            <Button
              className="collapseBtn"
              testId="Collapasebtn1"
              onClick={() => this.setState({ isCollapsed1: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          <Column.Body>
          <React.Fragment>
              {isPoolsLoading ? <LoadingMaskRow /> :(
                <div className="tictell-list">
                {this.state.allPools &&
                  this.state.allPools.map(item => (
                    <div
                    key={item.PoolId}
                    test-data-id={`pools-list-item${item.isActive ? "activeItem" : ""}`}
                      className={`tictell-list-item-container  ${
                        item.isSelected ? "selected-item" : ""
                      } ${item.isActive ? "activeItem" : ""}`}
                    >
                      <div 
                      onClick={() => this.handlePoolClick(item)}
                      className="name-container">
                        <label className="pool-label" test-data-id={`collabs-list-owner-title`}>{item.Title}</label>
                      </div>
                      <span className={`${item.IsShared  ? "shared-icon":""}`} />
                      <button
                          onClick={() => this.onDelete(item)}
                          className="closeBtn"
                          test-data-id={`timelog-delete-btn`}
                        />
                    </div>
                  ))}
              </div>
              )}
            <Button
              testId={`sendTimesheetBtn`}
              className={`add-btn`}
              mainClassName={`action-button-wrapper`}
              onClick={this.handleAddNewPool}
              isHelpActive={isHelpActive}
              tooltipHelp={labels.tictell_send_timesheet}
              tooltipButton={labels.ToolTipSendTimesheet}
              testIdHelp={'sendTimesheetBtn-help'}
            />
        </React.Fragment>
          </Column.Body>
        </Column>
        {selectedCollaboration && <Column collapse={isCollapsed2} className="col-2">
          <Column.Collapsed
            text={labels.Snapshot_Pool_Details}
            onClick={() => this.setState({ isCollapsed2: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipFeedBackExpandCreate}
            tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
            tooltipPlace="left"
            testId="expandbtn2"
          />
          <Column.Head>
            <div className="heading">{labels.Snapshot_Pool_Details}</div>
            <Button
              className="collapseBtn"
              onClick={() => this.setState({ isCollapsed2: true })}
              tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
              tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId="collapsebtn2"
            />
          </Column.Head>
    
          <Column.Body >
            <div className="tictell-list">
            {!isCollapsed2 && (
            <div className="pool-head">
          <CustomInputNew label={labels.Snapshot_Pool_Title} >
            <input
                type="text"
                autoComplete="off"
                value={poolDetails && poolDetails.Title} 
                name="Amount"
                className={`input-text `}
                placeholder={"Title"}
                test-data-id="input-expanse-amount"
                onChange={this.handlePoolNameChange}
                onBlur={this.handlePoolNameBlur}
              />
          </CustomInputNew>
          <CustomInputNew label={labels.Snapshot_Pool_Add_Collaboration}>
            <Select
              readonly={true} 
              searchable={false}
                name="Active Collaborations"
                className="select-input"
                value={this.state.selectedActiveOption}
                 // value={(Profiles && Profiles.ProfileValue) || ""}
                placeholder={"Please select a collaboration to add"}
                onChange={selectedOption =>
                  this.handleActiveCollaborationClick(selectedOption)
                }
                options={this.state.allActiveCollaborations}
                clearable={false}
              />
              </CustomInputNew>
            </div>
          )}
              {this.state.poolDetails && this.state.poolDetails.Collaborations &&
                this.state.poolDetails.Collaborations.map(item => (
                  <div
                   key={item.CollaborationId}
                   test-data-id={`collaborations-list-item${item.isActive ? "activeItem" : ""}`}
                    className={`tictell-list-item-container  ${
                      item.isSelected ? "selected-item" : ""
                    } ${item.isActive ? "activeItem" : ""}`}
                    onClick={() => this.handleCollaborationClick(item)}
                  >
                    <div className={`dashItemImg ${item.PresenatationLogo ? "" : "no-dp"}`}>
                      <img
                        className="roundImg"
                        src={item.PresenatationLogo ? item.PresenatationLogo : ImgSrc}
                        alt={"UserName"}
                      />
                    </div>
                    <div className="name-container">
                      <label test-data-id={`collabs-list-owner-username`}>{item.UserName}</label>
                      <label test-data-id={`collabs-list-owner-title`}>{item.Title}</label>
                    </div>
                    <span className={`${item.UserId != UserId  ? "shared-icon":""}`} />
                    <button
                          onClick={() => this.handleCollaborationRemove(item)}
                          className="closeBtn"
                          test-data-id={`timelog-delete-btn`}
                    />
                  </div>
                ))}
            </div>
          </Column.Body>
        </Column>}
        {<Column collapse={isCollapsed3} className="col-3">
          <Column.Collapsed
            text={SNAPSHOT_COLLABORATION_TIME_AND_EXPENSES}
            onClick={() => this.setState({ isCollapsed3: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipFeedBackExpandCreate}
            tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
            tooltipPlace="left"
            testId="expandbtn3"
          />
          <Column.Head>
            <div className="heading">{SNAPSHOT_COLLABORATION_TIME_AND_EXPENSES}</div>
            <Button
              className="collapseBtn"
              onClick={() => this.setState({ isCollapsed3: true })}
              tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
              tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId="collapsebtn3"
            />
          </Column.Head>
          <Column.Body>
          {selectedCollaboration &&
            <TimeAndExpenses
                CollaborationId={selectedCollaboration.CollaborationId}
                labels={labels}
                token={this.props.token}
                PoolId={poolDetails.PoolId}
                options={this.state.durationOptions}
                selectedOption={this.state.selectedDuration}
                StartDate={this.state.StartDate}
                EndDate={this.state.EndDate}
                TimeDetail={this.state.TimeDetail}
                ExpenseDetail={this.state.ExpenseDetail}
                PoolTimeDetail = {this.state.PoolTimeDetail}
                PoolExpenseDetail= {this.state.PoolExpenseDetail}
                handleOptionChange = {this.handleDurationOptionChange}
                handleStartDateChange = {this.handleStartDateChange}
                handleEndDateChange = {this.handleEndDateChange}
                handleSharePoolInputChange = {this.handleSharePoolInputChange}
                handlePoolShare = {this.handlePoolShare}
                handleSharePoolDelete = {this.handleSharePoolDelete}
                handleSharePool = {this.handleSharePool}
                 sharedPoolInfo = {this.state.sharedPoolInfo}
            />}
          </Column.Body>
        </Column>}
        {<Column collapse={isCollapsed4} className="col-4">
          <Column.Collapsed
            text={SNAPSHOT_COLLABORATION_PRESENT}
            onClick={() => this.setState({ isCollapsed4: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipFeedBackExpandCreate}
            tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
            tooltipPlace="left"
            testId="expandbtn4"
          />
          <Column.Head>
            <div className="heading">{SNAPSHOT_COLLABORATION_PRESENT}</div>
            <Button
              className="collapseBtn"
              onClick={() => this.setState({ isCollapsed4: true })}
              tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
              tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId="collapsebtn4"
            />
          </Column.Head>
          <Column.Body>
            {!isEmpty(selectedUser) && <Presentations
              selectedUser={selectedUser}
              labels={this.props.labels}
            />}
          </Column.Body>
        </Column>}
        {<Column collapse={isCollapsed5} className="col-5">
          <Column.Collapsed
            text={SNAPSHOT_COLLABORATION_RESUME}
            onClick={() => this.setState({ isCollapsed5: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipFeedBackExpandCreate}
            tooltipHelp={labels.HlpTooltipFeedbackExpandCreateButton}
            tooltipPlace="left"
            testId="expandbtn5"
          />
          <Column.Head>
            <div className="heading">{SNAPSHOT_COLLABORATION_RESUME}</div>
            <Button
              className="collapseBtn"
              onClick={() => this.setState({ isCollapsed5: true })}
              tooltipButton={labels.ToolTipFeedBackCollaspeCreate}
              tooltipHelp={labels.HlpTooltipFeedbackCollapseCreateButton}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId="collapsebtn5"
            />
          </Column.Head>
          <Column.Body>
          {!isEmpty(selectedResume) && <ResumeDetail resume={selectedResume} />}
          </Column.Body>
        </Column>}
      </div> 
      )
    }
} 


const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
    const { labels } = systemLabel;
    const { user } = userInfo;
    const User = user ? user : StorageService.getUser();
    const { isHelpActive } = navigation;
    return { labels, isHelpActive, User };
  };
  export default connect(
    mapStateToProps,
    { notificationAction }
  )(Pools);