import React, { Component, Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import { htmlParser } from "../../../utilities/helpers";
import "./resumedetail.scss";
import { connect } from "react-redux";
import moment from "moment";
import { isNullOrUndefined } from "util";

const AccordionHeading = ({ heading, onToggle, active, iconCls }) => (
  <div className="dropdownListTitle">
    <span className={iconCls} />
    <div className="dropdownTitle">{heading}</div>
    <div
      className={`DownArrow ${active ? "" : "UpArrow"}`}
      onClick={onToggle}
    />
  </div>
);

const AccordionDetail = ({ children }) => children;

class Accordion extends Component {
  state = {
    active: true,
  };
  handleToggle = () => {
    this.setState({ active: !this.state.active });
  };
  render() {
    const { active } = this.state;
    const { heading, children, iconCls } = this.props;
    return (
      <div className="AvailableList">
        <AccordionHeading
          heading={heading}
          active={active}
          onToggle={this.handleToggle}
          iconCls={iconCls}
        />

        {active && <AccordionDetail>{children}</AccordionDetail>}
      </div>
    );
  }
}

const ChildAccordionHeading = ({ heading, onToggle, active }) => (
  <div className="ResumeHeadingCnt">
    {heading}
    <div
      className={`DownArrow ${active ? "" : "UpArrow"}`}
      onClick={onToggle}
    />
  </div>
);

class ChildAccordion extends Component {
  state = {
    active: true,
  };
  handleToggle = () => {
    this.setState({ active: !this.state.active });
  };
  render() {
    const { active } = this.state;
    const { heading, children } = this.props;
    return (
      <li className="AvailableList ResumeItem">
        <ChildAccordionHeading
          heading={heading}
          active={active}
          onToggle={this.handleToggle}
        />
        {active && <AccordionDetail>{children}</AccordionDetail>}
      </li>
    );
  }
}

class ShortlistResumeDetail extends Component {
  availability = (record) => {
    if (record.AvailablityType === 1) {
      return "markGreen";
    } else if (record.AvailablityType === 2) {
      return "markYellow";
    } else if (record.AvailablityType === 3) {
      return "markRed";
    }
  };

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getAvailable = (value) => {
    const { labels } = this.props;
    if (value.AvailablityType === 1) {
      return labels.IPRO_DASHBOARD_RESUME_AVAILABLE_HEADER;
    }
    if (value.AvailablityType === 2) {
      return labels.IPRO_DASHBOARD_RESUME_ONE_MONTH_HEADER;
    }
    if (value.AvailablityType === 3) {
      var atypemessage = labels.IPRO_DASHBOARD_RESUME_BUSY_UNTIL_HEADER;
      return htmlParser(
        atypemessage &&
          atypemessage.replace(
            "@@date",
            moment(value.AvailabilityDate).format("MM-DD-YYYY")
          )
      );
    }
  };

  GetExperienceLevel = (value) => {
    switch (value) {
      case 1:
        return "Beginner";
      case 2:
        return "Proficient";
      case 3:
        return "Expert";
      default:
        return;
    }
  };
  GetYear = (value) => {
    var d = new Date(value);
    return d.getFullYear();
  };

  allItemsWithCommaSeprated = (data) => {
    var item = "";
    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (i != data.length - 1) item += data[i].KeywordValue + ", ";
        else item += data[i].KeywordValue + ".";
      }

      item = item.substr(0, item.length - 1);
    }
    return item;
  };
  GetLanguageLevel = (value) => value;
  GetExperienceYears = (value) => value;

  render() {
    const { resume, labels } = this.props;
    let {
      Regions,
      Profiles,
      Languages,
      Skills,
      Keywords,
      Certifications,
      Educations,
      Industries,
      ResumeExperience,
      ResumeOtherAchivenments,
      ResumeCandidateLanguage,
      ResumeCandidateProfiles,
      ResumeCandidateSkills,
      ResumeCandidateCertifications,
      ResumeCandidateEducations,
      ResumeCandidateIndustries,
      ResumeCandidateKeywords,
    } = resume;
    console.log("resume", resume);
    ResumeCandidateLanguage &&
      ResumeCandidateLanguage.forEach(function(element) {
        Languages.push({
          LanguageId: element.ResumeCandidateLanguageId,
          LanguageValue: element.ResumeCandidateLanguageValue,
          ExperienceLevel: element.ExperienceLevel,
        });
      });
    ResumeCandidateProfiles &&
      ResumeCandidateProfiles.forEach(function(element) {
        Profiles.push({
          ProfileId: element.ResumeCandidateProfileId,
          ProfileValue: element.ResumeCandidateProfileName,
          ExperienceLevel: element.ExperienceLevel,
        });
      });
    ResumeCandidateSkills &&
      ResumeCandidateSkills.forEach(function(element) {
        Skills.push({
          SkillId: element.ResumeCandidateSkillId,
          SkillValue: element.ResumeCandidateSkillName,
          ExperienceLevel: element.ExperienceLevel,
        });
      });
    ResumeCandidateCertifications &&
      ResumeCandidateCertifications.forEach(function(element) {
        Certifications.push({
          CertificationId: element.ResumeCandidateCertificationId,
          CertificationValue: element.ResumeCandidateCertificationValue,
          CertificationDate: element.CertificationDate,
        });
      });
    ResumeCandidateEducations &&
      ResumeCandidateEducations.forEach(function(element) {
        Educations.push({
          EducationId: element.ResumeCandidateEducationId,
          EducationTypeId: element.EducationTypeId,
          EducationValue: element.ResumeCandidateEducationValue,
          EducationYear: element.EducationYear,
        });
      });
    ResumeCandidateIndustries &&
      ResumeCandidateIndustries.forEach(function(element) {
        Industries.push({
          IndustryId: element.ResumeCandidateIndustryId,
          IndustryValue: element.ResumeCandidateIndustryValue,
          ExperienceLevel: element.ExperienceLevel,
        });
      });
    ResumeCandidateKeywords &&
      ResumeCandidateKeywords.forEach(function(element) {
        Keywords.push({
          KeywordId: element.ResumeCandidateKeywordId,
          KeywordValue: element.ResumeCandidateKeywordValue,
        });
      });
    return (
      <div className="resume-detail-component">
        <div className="ResumeListWrap">
          {!this.getAvailable(resume) == "" && (
            <div className="AvailableTitle">
              <span className={`AvailableHeading ${this.availability(resume)}`}>
                {this.getAvailable(resume)}
              </span>
            </div>
          )}

          <Accordion
            iconCls="countryIconResume"
            heading={labels.ResumeHeadingLocation}
          >
            <ul className="ResumeList">
              {console.log("All regions details", Regions)}
              {Regions.length > 0 ? (
                Regions.map((item) => (
                  <li key={item.CountryId} className="ResumeItem">
                    <div className="LocName">{item.CountryName}</div>
                  </li>
                ))
              ) : (
                <li style={{ fontSize: 12, padding: 10 }}>
                  <span>No data available</span>
                </li>
              )}
            </ul>
          </Accordion>

          <Accordion
            iconCls="languageIconResume"
            heading={labels.ResumeHeadingLanguage}
          >
            <ul className="ResumeList">
              {Languages && Languages.length > 0 ? (
                Languages.map((item) => (
                  <li key={item.LanguageId} className="ResumeItem">
                    <div className="SkillName">{item.LanguageValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperienceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))
              ) : (
                <li style={{ fontSize: 12, padding: 10 }}>
                  <span>No data available</span>
                </li>
              )}
            </ul>
          </Accordion>

          <Accordion
            iconCls="profileIconResume"
            heading={labels.ResumeHeadingRole}
          >
            <ul className="ResumeList">
              {Profiles && Profiles.length > 0 ? (
                Profiles.map((item) => (
                  <li key={item.ProfileId} className="ResumeItem">
                    <div className="SkillName">{item.ProfileValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperienceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))
              ) : (
                <li style={{ fontSize: 12, padding: 10 }}>
                  <span>No data available</span>
                </li>
              )}
            </ul>
          </Accordion>

          <Accordion
            iconCls="skillIconResume"
            heading={labels.ResumeHeadingSkill}
          >
            <ul className="ResumeList">
              {Skills && Skills.length > 0 ? (
                Skills.map((item) => (
                  <li key={item.SkillId} className="ResumeItem">
                    <div className="SkillName">{item.SkillValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperienceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))
              ) : (
                <li style={{ fontSize: 12, padding: 10 }}>
                  <span>No data available</span>
                </li>
              )}
            </ul>
          </Accordion>

          <Accordion
            iconCls="keywordIconResume"
            heading={labels.ResumeHeadingKeyword}
          >
            <ul className="ResumeList">
              {Keywords && Keywords.length > 0 ? (
                Keywords.map((item) => (
                  <li key={item.KeywordId} className="ResumeItem">
                    <div className="SkillName">{item.KeywordValue}</div>
                  </li>
                ))
              ) : (
                <li style={{ fontSize: 12, padding: 10 }}>
                  <span>No data available</span>
                </li>
              )}
            </ul>
          </Accordion>

          <Accordion
            iconCls="certificationIconResume"
            heading={labels.ResumeHeadingCertification}
          >
            <ul className="ResumeList">
              {Certifications && Certifications.length > 0 ? (
                Certifications.map((item) => (
                  <li key={item.CertificationId} className="ResumeItem">
                    <div className="SkillName">{item.CertificationValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetYear(item.CertificationDate)}
                      </span>
                    </div>
                  </li>
                ))
              ) : (
                <li style={{ fontSize: 12, padding: 10 }}>
                  <span>No data available</span>
                </li>
              )}
            </ul>
          </Accordion>

          <Accordion
            iconCls="educationIconResume"
            heading={labels.ResumeHeadingEducation}
          >
            <ul className="ResumeList">
              {Educations && Educations.length > 0 ? (
                Educations.map((item) => (
                  <li key={item.EducationId} className="ResumeItem">
                    <div className="SkillName">{item.EducationValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">{item.EducationYear}</span>
                    </div>
                  </li>
                ))
              ) : (
                <li style={{ fontSize: 12, padding: 10 }}>
                  <span>No data available</span>
                </li>
              )}
            </ul>
          </Accordion>

          <Accordion
            iconCls="companyIconResume"
            heading={labels.ResumeHeadingIndustry}
          >
            <ul className="ResumeList">
              {Industries && Industries.length > 0 ? (
                Industries.map((item) => (
                  <li key={item.IndustryId} className="ResumeItem">
                    <div className="SkillName">{item.IndustryValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperienceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))
              ) : (
                <li style={{ fontSize: 12, padding: 10 }}>
                  <span>No data available</span>
                </li>
              )}
            </ul>
          </Accordion>

          <Accordion
            iconCls="experienceIconResume"
            heading={labels.oppResumeDetailExperience}
          >
            <ul className="ResumeList">
              {ResumeExperience?.length > 0 ? (
                <>
                  {ResumeExperience?.map((item, index) => (
                    <ChildAccordion
                      key={index}
                      heading={
                        <Fragment>
                          <div className="CompanyName">
                            {item.CompanyWorked}
                          </div>
                          <div className="CompanyDate">
                            {moment(item.StartDate).format("MM/YYYY")}-
                            {!isNullOrUndefined(item.EndDate)
                              ? moment(item.EndDate).format("MM/YYYY")
                              : labels.SHORTLIST_RESUME_CURRENT_WORKING}
                          </div>
                        </Fragment>
                      }
                    >
                      <div className="ResumeList">
                        {item.IndustryValue && (
                          <div className="resumeProfile">
                            {labels.SHORTLIST_RESUME_INDUSTRY}
                            <div className="ExpName">{item.IndustryValue}</div>
                          </div>
                        )}
                        {item.ProfileValue && (
                          <div className="resumeProfile">
                            {labels.ROLE_LABEL}
                            <div className="ExpName">{item.ProfileValue}</div>
                          </div>
                        )}
                        {item.Description && (
                          <div className="resumeProfile">
                            {ReactHtmlParser(item.Description)}
                          </div>
                        )}
                      </div>
                    </ChildAccordion>
                  ))}
                </>
              ) : (
                <li style={{ fontSize: 12, padding: 10 }}>
                  <span>No data available</span>
                </li>
              )}
            </ul>
          </Accordion>

          <Accordion
            iconCls="workshopIconResume"
            heading={labels?.RESUME_EDIT_ACHIEVEMENTS_SECTION_TITLE}
          >
            <ul className="ResumeList">
              {ResumeOtherAchivenments?.length > 0 ? (
                <>
                  {ResumeOtherAchivenments?.map((item, index) => (
                    <ChildAccordion
                      key={item.AchivenmentId}
                      heading={
                        <div
                          className="CompanyName"
                          style={{ textAlign: "left", padding: "10px" }}
                        >
                          {item.Title}
                        </div>
                      }
                    >
                      <div className="ResumeList">
                        {item.AchivenmentName && (
                          <div className="resumeProfile">
                            {item.AchivenmentName}
                            <div className="ExpName">{item.Year}</div>
                          </div>
                        )}

                        {item.Description && (
                          <div className="resumeProfile">
                            {ReactHtmlParser(item.Description)}
                          </div>
                        )}
                      </div>
                    </ChildAccordion>
                  ))}
                </>
              ) : (
                <li style={{ fontSize: 12, padding: 10 }}>
                  <span>No data available</span>
                </li>
              )}
            </ul>
          </Accordion>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(ShortlistResumeDetail);
