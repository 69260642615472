import React, { Component } from "react";
import { connect } from "react-redux";
import "./about.scss";
import Img1 from "../../../../assets/images/snapshot/robert.png";
import Img2 from "../../../../assets/images/snapshot/henrik.png";
import Img3 from "../../../../assets/images/snapshot/nauman.png";
const localLabels = {
  aboutPageTitle: "About Prodoo",
  aboutPageSubHeading:
    "Prodoo was created to streamline the recruitment process for both freelancers (iPros) and organizations (Searchers). The AI implemented includes a variation of analytical techniques that make it simpler for both parties to find the best opportunity.Prodoo is an effective recruitment platform that combines the potential development of individuals with the prospective growth of companies. Typically, the match is made based on early experiences followed by an assessment of compatibility. This process is overseen by Prodoo.The aim of Prodoo is to make appropriate use of the labor market. Our services utilize the details available and render traditional CVs futile. Instead, we tap into professional profiles, which consist of market statistics. These are used to determine how the iPro’s profile can be adapted to the needs that may arise in the future. This system also allows the independent worker to find a direction where they can achieve success.",
  ceoName: "Robert Kinnerfelt - CEO",
  ceoEmail: "robert@prodoo.com",
  cfoName: "Henrik Haugaard - CFO",
  cfoEmail: "henrik@prodoo.com",
  ctoName: "Nauman Sharif - CTO",
  ctoEmail: "nauman@prodoo.com",
};
class Professional extends Component {
  render() {
    const { labels } = this.props;
    return (
      <div className="about-page mainContent darkBg" id="aboutUs">
        <section className="contentContainer">
          <h1 className="proHeading">{localLabels.aboutPageTitle}</h1>
          <div className="aboutPanel">
            <h6 className="subHeading">{localLabels.aboutPageSubHeading}</h6>
            <ul className="tabList">
              <li className="tabItem">
                <img className="tabImg purple" src={Img1} alt="img" />
                <h5>{localLabels.ceoName}</h5>
                <p>{localLabels.ceoEmail}</p>
              </li>
              <li className="tabItem">
                <img className="tabImg orange" src={Img2} alt="img" />
                <h5>{localLabels.cfoName}</h5>
                <p>{localLabels.cfoEmail}</p>
              </li>
              {/* <li className="tabItem">
                <img className="tabImg green" src={Img3} alt="img" />
                <h5>{localLabels.ctoName}</h5>
                <p>{localLabels.ctoEmail}</p>
              </li> */}
            </ul>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(Professional);
