import React, { Component } from "react";
import { EmptyInfo } from "../../../common";
import { connect } from "react-redux";
import moment from "moment";
import ImgSrc from "../../../assets/images/present.svg";
import { Col, Row, Space } from "antd";
import NetworkForm from "./NetworkForm";

class NetworkDetail extends Component {
  renderDetailDate = (date) => {
    return moment(date).format("ddd MMM DD YYYY H:mm:ss");
  };
  render() {
    const {
      labels,
      selectedUser,
      currentPresent,
      companyList,
      Emails,
      Profiles,
      isInvitationsView,
      isConnectedView,
      handleDeleteNetwork,
      handleAcceptNetwork,
      BtnAddOpportunity,
      BtnAddCollaboration,
      handleCreateNewOpportunityClick,
      handleCreateNewShortlistClick,
      isCreateNew,
      onFormFieldChange,
      networkData,
      handleNetworkInvitationSend,
    } = this.props;
    let loggedUser = JSON.parse(localStorage.getItem("User"));
    return (
      <div className="new-user-detail-component">
        {!selectedUser.UserId ? (
          <EmptyInfo>{labels.NO_ITEM_LABEL}</EmptyInfo>
        ) : (
          <>
            {isCreateNew ? (
              <NetworkForm
                onFormFieldChange={onFormFieldChange}
                networkData={networkData}
                labels={labels}
                handleNetworkInvitationSend={handleNetworkInvitationSend}
              />
            ) : (
              <div className="inner-user">
                <div className="image-user">
                  <img
                    src={
                      (selectedUser && selectedUser.ProfilePicture) || ImgSrc
                    }
                    className={
                      selectedUser && selectedUser.ProfilePicture
                        ? "roundImg"
                        : "emptyRoundImag"
                    }
                  />
                </div>
                <p className="user-title">
                  {selectedUser.UserFirstname +
                    " " +
                    (selectedUser.UserLastname
                      ? selectedUser.UserLastname
                      : "")}
                </p>
                <Row
                  gutter={[8, 12]}
                  style={{ marginTop: "10px", flexFlow: "unset" }}
                >
                  <Col flex="100px">
                    <p className="user-detail">
                      {labels.SearcherSentCollaborationUserNameLabel} :
                    </p>
                  </Col>
                  <Col flex="auto">
                    <p className="user-detail">{selectedUser.UserFirstname}</p>
                  </Col>
                </Row>
                {selectedUser.UserProfile && selectedUser.UserProfile && (
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="100px">
                      <p className="user-detail"> {labels.TYPE_LABEL} :</p>
                    </Col>
                    <Col flex="auto">
                      <p className="user-detail">
                        {selectedUser.IsFreelancer
                          ? labels.IPRO_NAVIGATION_ROLE_LABEL
                          : labels.SEARCHER_NAVIGATION_ROLE_LABEL}
                      </p>
                    </Col>
                  </Row>
                )}
                {selectedUser.UserEmail && selectedUser.UserEmail && (
                  <Row
                    gutter={[8, 12]}
                    style={{ marginTop: "10px", flexFlow: "unset" }}
                  >
                    <Col flex="100px">
                      <p className="user-detail">{labels.EMAIL_LABEL} :</p>
                    </Col>

                    <Col flex="auto">
                      <p className="user-detail">{selectedUser.UserEmail}</p>
                    </Col>
                  </Row>
                )}

                {selectedUser.IsFreelancer ? (
                  <React.Fragment>
                    {currentPresent && currentPresent.Title && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="120px">
                          <p className="user-detail">{labels.TITLE_LABEL} :</p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">{currentPresent.Title}</p>
                        </Col>
                      </Row>
                    )}
                    {currentPresent && currentPresent.UserName && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="120px">
                          <p className="user-detail">
                            {labels.USER_NAME_LABEL} :
                          </p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">
                            {" "}
                            {currentPresent.UserName}
                          </p>
                        </Col>
                      </Row>
                    )}
                    {Emails && Emails.UserEmailValue && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="120px">
                          <p className="user-detail">{labels.EMAIL_LABEL} :</p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">{Emails.UserEmailValue}</p>
                        </Col>
                      </Row>
                    )}
                    {Profiles && Profiles.ProfileValue && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="120px">
                          <p className="user-detail">{labels.ROLE_LABEL} :</p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">{Profiles.ProfileValue}</p>
                        </Col>
                      </Row>
                    )}
                    {currentPresent && currentPresent.WebUrl && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="120px">
                          <p className="user-detail">
                            {labels.WEB_URL_LABEL} :
                          </p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">{currentPresent.WebUrl}</p>
                        </Col>
                      </Row>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {companyList && companyList.CompanyName && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="100px">
                          <p className="user-detail">{labels.NAME_LABEL} :</p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">
                            {companyList.CompanyName}
                          </p>
                        </Col>
                      </Row>
                    )}
                    {companyList && companyList.IndustryValue && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="100px">
                          <p className="user-detail">
                            {labels.INDUSTRY_LABEL} :
                          </p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">
                            {companyList.IndustryValue}
                          </p>
                        </Col>
                      </Row>
                    )}
                    {companyList && companyList.CountryName && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="100px">
                          <p className="user-detail">
                            {labels.COUNTRY_LABEL} :
                          </p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">
                            {companyList.CountryName}
                          </p>
                        </Col>
                      </Row>
                    )}
                    {companyList && companyList.CompanyAddress && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="100px">
                          <p className="user-detail">
                            {labels.ADDRESS_LABEL} :
                          </p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">
                            {companyList.CompanyAddress}
                          </p>
                        </Col>
                      </Row>
                    )}
                    {companyList && companyList.UserPhonenumberValue && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="100px">
                          <p className="user-detail">{labels.PHONE_LABEL} :</p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">
                            {companyList.UserPhonenumberValue}
                          </p>
                        </Col>
                      </Row>
                    )}
                    {companyList && companyList.WebUrl && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="100px">
                          <p className="user-detail">
                            {labels.WEB_URL_LABEL} :
                          </p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">{companyList.WebUrl}</p>
                        </Col>
                      </Row>
                    )}
                    {companyList && companyList.Vat && (
                      <Row
                        gutter={[8, 12]}
                        style={{ marginTop: "10px", flexFlow: "unset" }}
                      >
                        <Col flex="100px">
                          <p className="user-detail">{labels.VAT_LABEL} :</p>
                        </Col>

                        <Col flex="auto">
                          <p className="user-detail">{companyList.Vat}</p>
                        </Col>
                      </Row>
                    )}
                    <React.Fragment>
                      {isInvitationsView ? (
                        <Row className="">
                          <Space
                            size={[8, 8]}
                            style={{
                              margin: "auto",
                              marginTop: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <button
                              className="button-footer-opportunity pl-4 pr-4"
                              onClick={() => handleAcceptNetwork(selectedUser)}
                            >
                              {labels.iProOpportunityNewDetailBtnAccept}
                            </button>
                            <button
                              className="button-footer-opportunity pl-4 pr-4"
                              onClick={() => handleDeleteNetwork(selectedUser)}
                            >
                              {labels.iProOpportunityNewDetailBtnDecline}{" "}
                            </button>
                          </Space>
                        </Row>
                      ) : null}
                    </React.Fragment>
                    <React.Fragment>
                      {isConnectedView && !loggedUser.IsFreelancer ? (
                        <Row className="">
                          <Space
                            size={[8, 8]}
                            style={{
                              margin: "auto",
                              marginTop: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <button
                              className="button-footer-opportunity pl-4 pr-4"
                              onClick={() =>
                                handleCreateNewShortlistClick(selectedUser)
                              }
                            >
                              {BtnAddOpportunity}
                            </button>
                            <button
                              className="button-footer-opportunity pl-4 pr-4"
                              onClick={() =>
                                handleCreateNewOpportunityClick(selectedUser)
                              }
                            >
                              {BtnAddCollaboration}
                            </button>
                          </Space>
                        </Row>
                      ) : null}
                    </React.Fragment>
                  </React.Fragment>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(NetworkDetail);
