import { ActionTypes } from "../actions";
const INITIAL_STATE = {
  isFetching: false,
  isLoading: false,
  opportunities: [],
  filterOpportunires: [],
  selectedOpportunity: null,
  listCollapse: false,
  detailCollapse: false,
  shortlistCollapse: false,
  resumeListCollapse: false,
  shortlistResumeCollapse: false,
  shortlistResumes: [],
  selectedResume: {},
  selectedIpro: {},
  filterShortlistss: [],
  isSearchFocus: false,
  searchListHover: false,
  selectedShortlist: null,
  selectedShortlists: [],
  dialogMessage: "",
  yesClickCount: 0,
  isFetchingShortlist: false,
  isFetchingShortlistResume: false,
  hideShortList: false,
  showResumeList: false,
  opportunityResumes: [],
  currentOpportunityId: null,
  searchkey: "",
  isEmptyCollapsed: false,
  iprosList: [],
  selectedAction: "",
  allCurrenciesList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IS_EMPTY_COLLAPSED:
      return { ...state, isEmptyCollapsed: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.LIST_COLLAPSE:
      return { ...state, listCollapse: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.DETAIL_COLLAPSE:
      return { ...state, detailCollapse: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SHORTLIST_COLLAPSE:
      return { ...state, shortlistCollapse: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.RESUME_LIST_COLLAPSE:
      return { ...state, resumeListCollapse: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SHORTLIST_RESUME_COLLAPSE:
      return { ...state, shortlistResumeCollapse: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.DIALOG_MESSAGE:
      return { ...state, dialogMessage: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IS_LOADING:
      return { ...state, isLoading: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IS_FETCHING:
      return { ...state, isFetching: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IS_FETCHING_SHORTLIST:
      return { ...state, isFetchingShortlist: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IS_FETCHING_SHORTLIST_RESUME:
      return { ...state, isFetchingShortlistResume: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.FILTER_OPPORTUNIRES:
      return { ...state, filterOpportunires: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_OPPORTUNITY:
      return { ...state, selectedOpportunity: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_SHORTLISTS:
      return { ...state, selectedShortlists: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_SHORTLIST:
      return { ...state, selectedShortlist: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SHORTLIST_RESUMES:
      return { ...state, shortlistResumes: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_RESUME:
      return { ...state, selectedResume: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.CURRENT_OPPORTUNITY_ID:
      return { ...state, currentOpportunityId: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SEARCH_KEY:
      return { ...state, searchkey: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.HIDE_SHORTLIST:
      return { ...state, hideShortList: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SHOW_RESUME_LIST:
      return { ...state, showResumeList: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_IPRO:
      return { ...state, selectedIpro: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.OPPORTUNITIES:
      return { ...state, opportunities: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.YES_CLICK_COUNT:
      return { ...state, yesClickCount: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.IPROS_LIST:
      return { ...state, iprosList: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.SELECTED_ACTION:
      return { ...state, selectedAction: action.payload };
    case ActionTypes.OPPORTUNITIES_SENT_SEARCHER.ALL_CURRENCIES_LIST:
      return {
        ...state,
        allCurrenciesList: action.payload
      };
    default:
      return state;
  }
};
