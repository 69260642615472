import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  isLoading: false,
  opportunityList: [],
  iprosList: [],
  selectedIpro: {},
  formCollapsed: false,
  detailCollapsed: false,
  dialogMessage: "",
  collaborationForm: {
    invalidRequestId: false
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_COLLABORATION.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case ActionTypes.CREATE_COLLABORATION.OPPORTUNITY_LIST:
      return {
        ...state,
        opportunityList: action.payload
      };
    case ActionTypes.CREATE_COLLABORATION.IPROS_LIST:
      return {
        ...state,
        iprosList: action.payload
      };
    case ActionTypes.CREATE_COLLABORATION.SELECTED_IPRO:
      return {
        ...state,
        selectedIpro: action.payload
      };
    case ActionTypes.CREATE_COLLABORATION.FORM_COLLAPSED:
      return {
        ...state,
        formCollapsed: action.payload
      };
    case ActionTypes.CREATE_COLLABORATION.DETAIL_COLLAPSED:
      return {
        ...state,
        detailCollapsed: action.payload
      };
    case ActionTypes.CREATE_COLLABORATION.DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: action.payload
      };
    case ActionTypes.CREATE_COLLABORATION.COLLABORATION_FORM:
      return {
        ...state,
        collaborationForm: action.payload
      };

    default:
      return state;
  }
};
