import React, { Component } from "react";
import { connect } from "react-redux";
import { notificationAction } from "../../../actions";
import { StorageService } from "../../../api";
import { logoutAction } from "../../../store/initialConfig";
import { RESET_LANDINGPAGE_URL } from "../../../utilities/enviroments";
import { logoutApi } from "../../../components/Navigation/navigationApi";
import NewOpportunity from "./common/NewOpportunity";

class NewOpportunityIpro extends Component {
  componentDidMount = () => {
    const spliturl = this.props.location.pathname.match(/\d+/g);
    if (spliturl) {
      const user = JSON.parse(localStorage.getItem("User"));
      const userId = parseInt(spliturl[1]);
      if (user.UserId !== userId)
        logoutApi().then((response) => {
          this.props.history.push("new-opportunity");
          if (response.success) {
            this.props.logoutAction();
            const info = {
              message: "Please login with your appropriate account.",
              status: "error",
            };
            this.props.notificationAction(info);
            StorageService.clearAll();
            window.location.href = RESET_LANDINGPAGE_URL;
            return;
          }
        });
    }
  };
  render() {
    const { labels, notificationAction, location, isHelpActive } = this.props;
    return (
      <NewOpportunity
        url={`?type=3`}
        isAccepted={true}
        isNewOpportunityView
        opportunityType="NewRequests"
        IsSearcher={false}
        isHelpActive={isHelpActive}
        notificationAction={notificationAction}
        // list section system label
        Lbltitle={labels?.iProOpportunityNewDetailLblTitle}
        locationsLabel={labels?.iProOpportunityNewDetailLblLocation}
        startDateLabel={labels?.iProOpportunityNewDetailLblStartDate}
        durationLabel={labels?.iProOpportunityNewDetailLblDuration}
        companyLabel={labels?.iProOpportunityNewDetailLblCompany}
        descriptionLabel={labels?.iProOpportunityNewDetailLblDescription}
        countryLabel={labels?.iProOpportunityNewDetailLblCountry}
        SearchParameterHeading={
          labels?.iProOpportunityNewDetailLblSearchParameterHeading
        }
        LblProfile={labels?.iProOpportunityNewDetailLblProfile}
        LblSkills={labels?.iProOpportunityNewDetailLblSkills}
        LblKeyword={labels?.iProOpportunityNewDetailLblKeyword}
        LblIndustry={labels?.iProOpportunityNewDetailLblIndustry}
        LblCertification={labels?.iProOpportunityNewDetailLblCertification}
        LblCountry={labels?.iProOpportunityNewDetailLblCountry}
        LblLanguage={labels?.iProOpportunityNewDetailLblLanguage}
        LblInterest={labels?.iProOpportunityNewDetailLblInterest}
        BtnAccept={labels?.iProOpportunityNewDetailBtnAccept}
        BtnDecline={labels?.iProOpportunityNewDetailBtnDecline}
        companyAddressLabel={labels?.IproOpportunityNewCompanyAddressLabel}
        companyIndustryLabel={labels?.IproOpportunityNewCompanyIndustryLabel}
        companyCountryLabel={labels?.IproOpportunityNewCompanyCountryLabel}
        companyPhoneLabel={labels?.IproOpportunityNewCompanyPhoneLabel}
        companyWebUrlLabel={labels?.IproOpportunityNewCompanyWebUrlLabel}
        companyVatLabel={labels?.IproOpportunityNewCompanyVatLabel}
        searchInputPlaceholder={labels?.iproOpportunityNewSearchField}
        toolTipExpandList={labels?.ToolTipInvitationExpandList}
        helpTooltipExpandList={labels?.HlpTooltipIproOpportunityNewListExpand}
        toolTipCollapseList={labels?.TooltipIproOpportunityNewListCollapse}
        helpTooltipCollapseList={
          labels?.HlpTooltipIproOpportunityNewListCollapse
        }
        listColumnText={labels?.ViewTitleInvitationList}
        // detail section system label
        detailHeadingText={labels?.iproOpportunityNewDetailsHeading}
        toolTipExpandDetail={labels?.ToolTipInvitationExpandDetail}
        helpTooltipExpandDetail={
          labels?.HlpTooltipIproOpportunityNewDetailExpand
        }
        toolTipCollapseDetail={labels?.ToolTipInvitationCollaspeDetail}
        helpTooltipCollapseDetail={
          labels?.HlpTooltipIproOpportunityNewDetailCollapse
        }
        detailsColumnText={labels?.ViewTitleInvitationDetail}
        // selected user detail and company section system label
        userDetailHeadingText={labels?.iproOpportunityNewCompanyDetailHeading}
        toolTipExpandUserDetail={labels?.ToolTipCompanyExpandDetail}
        helpTooltipExpandUserDetail={
          labels?.HlpTooltipIproOpportunityNewUserDetailExpand
        }
        toolTipCollapseUserDetail={labels?.ToolTipCompanyCollaspeDetail}
        helpTooltipCollapseUserDetail={
          labels?.HlpTooltipIproOpportunityNewUserDetailCollapse
        }
        userDetailsColumnText={labels?.ViewTitleCompanyDetail}
        emptyOpportunityInfo={labels?.emptyOpportunityInfo}
        listCollapsedTestId={"listCollapsedTestId1"}
        listCollapsedHelpTestId={"listCollapsedTestId2"}
        listExpandTestId={"listCollapsedTestId3"}
        listExpandHelpTestId={"listCollapsedTestId4"}
        detailCollapsedTestId={"listCollapsedTestId5"}
        detailCollapsedHelpTestId={"listCollapsedTestId6"}
        detailExpandTestId={"listCollapsedTestId7"}
        detailExpandHelpTestId={"listCollapsedTestId8"}
        userDetailCollapsedTestId={"listCollapsedTestId9"}
        userDetailCollapsedHelpTestId={"listCollapsedTestId10"}
        userDetailExpandTestId={"listCollapsedTestId11"}
        userDetailExpandHelpTestId={"listCollapsedTestId12"}
        searchInputTestId="opportunity-new-search-input"
        iproOpportunityListItem="iproOpportunityListItem-New"
        iproInvitationSuccessfullyDeleted={
          labels?.iproInvitationSuccessfullyDeleted
        }
        locationProp={location}
      />
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { UserId, IsFreelancer } = user;
  UserId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    userId: UserId,
    IsSearcher: !IsFreelancer,
    IsFreelancer,
    isHelpActive,
  };
};

const actions = { notificationAction, logoutAction };
export default connect(mapStateToProps, actions)(NewOpportunityIpro);
