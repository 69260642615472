import React, { Children } from "react";
import moment from "moment";
import { DatePicker, Select } from "../../../../common";
import "moment/locale/en-gb";
import { CustomInputNew } from "../components/CustomInput";

class ExpenseDetail extends React.Component {
  render() {
    const {
      invalidStartDate = false,
      handleExpenseDateChange,
      handleExpenseInputChange,
      handleExpenseSelectChange,
      currentExpense,
      currenciesList,
      expenseCategoriesList,
      handleExpenseInputBlur,
      onAddAttachment,
      handleExpenseFileDelete,
      labels,
      isDisabled,
      endDate,
      startDate,
      status,
      selectedCollaboration,
    } = this.props;
    const {
      ExpenseId,
      Comment = "",
      SpentOn,
      Amount = "",
      CurrencyId,
      ExpenseCategoryId,
      ExpenseFiles,
      ReviewedBy,
      ReviewedDate,
    } = currentExpense;
    return (
      <div className="expense-detail">
        <CustomInputNew
          label={labels.Expense_DateField}
          validate={true}
          value={SpentOn}
        >
          <label className="datepicker-wrapper">
            <DatePicker
              testId="datepicker-expense"
              selected={SpentOn && moment(SpentOn)}
              onChange={handleExpenseDateChange}
              name="expenseDate"
              dateFormat="DD-MM-YYYY"
              className={`${
                invalidStartDate && invalidStartDate ? "inValid" : ""
              }`}
              placeholderText={labels.Expense_Date_Placeholder}
              maxDate={
                moment(endDate).isoWeek() ===
                moment(selectedCollaboration.EndDate).isoWeek()
                  ? moment(selectedCollaboration.EndDate)
                  : moment(endDate)
              }
              minDate={
                moment(startDate).isoWeek() ===
                moment(selectedCollaboration.StartDate).isoWeek()
                  ? moment(selectedCollaboration.StartDate)
                  : moment(startDate)
              }
              disabled={isDisabled && isDisabled}
              locale="en-gb"
              autoComplete="off"
            />
          </label>
        </CustomInputNew>
        <CustomInputNew
          label={labels.Expense_Category}
          validate={true}
          value={ExpenseCategoryId}
        >
          <Select
            name="Category"
            className={`select-input select-category`}
            value={ExpenseCategoryId}
            onChange={(selectedOption) =>
              handleExpenseSelectChange(selectedOption, "ExpenseCategory")
            }
            
            clearable={false}
            searchable={false}
            open={true}
            defaultOpen={true}
            menuIsOpen={true}
            testId="category-input"
            options={expenseCategoriesList}
            disabled={isDisabled}
          />
        </CustomInputNew>
        <div className="expense-amount">
          <CustomInputNew
            label={labels.Expense_Amount}
            validate={true}
            value={Amount}
          >
            <input
              type="text"
              autoComplete="off"
              value={Amount}
              name="Amount"
              className={`input-text `}
              placeholder={labels.Expense_Amount_Placeholder}
              onChange={handleExpenseInputChange}
              onBlur={handleExpenseInputBlur}
              test-data-id="amount-input"
              disabled={isDisabled}
            />
          </CustomInputNew>
          <CustomInputNew
            label={labels.Expense_Currency}
            validate={true}
            value={CurrencyId}
          >
            <Select
              className={`select-input select-currency`}
              value={CurrencyId}
              onChange={(selectedOption) =>
                handleExpenseSelectChange(selectedOption, "Currency")
              }
              open={true}
              clearable={false}
              searchable={false}
              options={currenciesList}
              testId="currency-input"
              disabled={isDisabled}
            />
          </CustomInputNew>
        </div>
        <CustomInputNew label={labels.Expense_Details}>
          <textarea
            type="text"
            value={!Comment ? "" : Comment}
            onChange={handleExpenseInputChange}
            onBlur={handleExpenseInputBlur}
            name="Comment"
            className="textarea-field"
            rows="9"
            rows="6"
            placeholder={labels.Expense_Comment_Placeholder}
            test-data-id="comment-input"
            disabled={isDisabled}
          />
        </CustomInputNew>

        <CustomInputNew label={labels.Expense_Reciept}>
          {ExpenseFiles &&
            ExpenseFiles.map((attach) => (
              <div className={"attach-list-item"} key={attach.Name}>
                <label>
                  <a
                    target="_blank"
                    href={`${
                      "https://tictell.prodoo.com/TictellFileContainer/"
                    }${attach.Name}`}
                    download
                  >
                    {/* <a
                    target="_blank"
                    href={`${
                      window.location.origin.indexOf("stageapp") > -1
                        ? "https://tictell-stage.prodoo.com/TictellFileContainer/"
                        : "https://tictell.prodoo.com/TictellFileContainer/"
                    }${attach.Name}`}
                    download
                  > */}
                    {attach.Name}
                  </a>
                </label>
                <div
                  // disabled={isDisabled}
                  onClick={() => handleExpenseFileDelete(attach)}
                  className="close_btn"
                >
                  <i className="fa fa-times-circle" />
                </div>
              </div>
            ))}
          <div className="js_upload_btn">
            Add Attachment
            <input
              className="linkedInPdf"
              type="file"
              multiple
              title=" "
              onChange={onAddAttachment}
              disabled={!ExpenseId || !Amount ? true : false}
            />
          </div>
        </CustomInputNew>

        {(status === 2 || status === 3) && ReviewedBy !== null && (
          <div>
            <CustomInputNew label={labels.ReviewedBy_Label}>
              <input
                type="text"
                autoComplete="off"
                value={ReviewedBy || ""}
                name="ReviewedBy"
                className={`input-text `}
                placeholder={labels.ReviewedBy_Label}
                test-data-id="input-text-opportunity-title"
                disabled
              />
            </CustomInputNew>
            <CustomInputNew label={labels.ReviewedDate_Label}>
              <input
                type="text"
                autoComplete="off"
                value={moment(ReviewedDate).format("DD-MM-YYYY") || ""}
                name="ReviewedDate"
                className={`input-text `}
                placeholder={labels.ReviewedDate_Label}
                test-data-id="input-text-opportunity-title"
                disabled
              />
            </CustomInputNew>
          </div>
        )}
      </div>
    );
  }
}

export default ExpenseDetail;
