import { ActionTypes } from "../actions";
// import initialLabels from "../systemLabel.json";

const labels = {};
// initialLabels.items.forEach((label) => {
//   labels[label.Identifier] = label.Message;
// });

const INITIAL_STATE = {
  labels,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SYSTEM_LABEL:
      return {
        ...state,
        labels: action.payload,
      };
    default:
      return state;
  }
};
