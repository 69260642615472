import { ActionTypes } from "../../actions";
window.view = "SEARCH";
window.actionName = "search";
export const onStateChangeAction = ({
  shortlist,
  selectedResume,
  selectedShortlist,
  shortlistResumes,
  loading,
  fetchingResumes,
  opportunityList,
  fetchingOpportunities,
  searchList,
  selectedSearchTab,
  availibility,
  savedSearchesList,
  dialogSaveSearchMessage,
  newShortlistValue,
  newSaveSearchValue,
  dialogMessage,
  deletedId,
  dialogOpportunityMessage,
  dialogShortlistMessage,
  showResumeList,
  createNewSaveSearch,
  showExistingSaveSearches,
  showOpportunities,
  showExistingShortlists,
  createNewShortlist,
  showResumeDetail,
  searchCollapsed,
  saveSearchCollapse,
  resumeCollapsed,
  resumeDetailCollapsed,
  opportunitiesCollapse,
  shortlistCollapse,
  createCollapse,
  isLoading,
  actionName = window.actionName
}) => dispatch => {
  shortlist !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SHORTLIST,
      payload: shortlist,
      actionName
    });
  selectedResume !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SELECTED_RESUME,
      payload: selectedResume,
      actionName
    });
  selectedShortlist !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SELECTED_SHORTLIST,
      payload: selectedShortlist,
      actionName
    });
  shortlistResumes !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SHORTLIST_RESUMES,
      payload: shortlistResumes,
      actionName
    });
  loading !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.IS_LOADING,
      payload: isLoading,
      actionName
    });
  fetchingResumes !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.FETCHING_RESUMES,
      payload: fetchingResumes,
      actionName
    });
  opportunityList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.OPPORTUNITY_LIST,
      payload: opportunityList,
      actionName
    });
  fetchingOpportunities !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.FETCHING_OPPORTUNITIES,
      payload: fetchingOpportunities,
      actionName
    });
  searchList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_LIST,
      payload: searchList,
      actionName
    });
  selectedSearchTab !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SELECTED_SEARCH_TAB,
      payload: selectedSearchTab,
      actionName
    });
  availibility !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.AVAILIBILITY,
      payload: availibility,
      actionName
    });
  savedSearchesList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SAVED_SEARCHES_LIST,
      payload: savedSearchesList,
      actionName
    });
  dialogSaveSearchMessage !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.DIALOG_SAVE_SEARCH_MESSAGE,
      payload: dialogSaveSearchMessage,
      actionName
    });
  newShortlistValue !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.NEW_SHORTLIST_VALUE,
      payload: newShortlistValue,
      actionName
    });
  newSaveSearchValue !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.NEW_SAVE_SEARCH_VALUE,
      payload: newSaveSearchValue,
      actionName
    });
  dialogMessage !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.DIALOG_MESSAGE,
      payload: dialogMessage,
      actionName
    });
  deletedId !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.DELETED_ID,
      payload: deletedId,
      actionName
    });
  dialogOpportunityMessage !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.DIALOG_OPPORTUNITY_MESSAGE,
      payload: dialogOpportunityMessage,
      actionName
    });
  dialogShortlistMessage !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.DIALOG_SHORTLIST_MESSAGE,
      payload: dialogShortlistMessage,
      actionName
    });
  showResumeList !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SHOW_RESUME_LIST,
      payload: showResumeList,
      actionName
    });
  createNewSaveSearch !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.CREATE_NEW_SAVE_SEARCH,
      payload: createNewSaveSearch,
      actionName
    });
  showExistingSaveSearches !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SHOW_EXISTING_SAVE_SEARCHES,
      payload: showExistingSaveSearches,
      actionName
    });
  showOpportunities !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SHOW_OPPORTUNITIES,
      payload: showOpportunities,
      actionName
    });
  showExistingShortlists !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SHOW_EXISTING_SHORTLISTS,
      payload: showExistingShortlists,
      actionName
    });
  createNewShortlist !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.CREATE_NEW_SHORTLIST,
      payload: createNewShortlist,
      actionName
    });
  showResumeDetail !== undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SHOW_RESUME_DETAIL,
      payload: showResumeDetail,
      actionName
    });
  searchCollapsed != undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SEARCH_COLLAPSED,
      payload: searchCollapsed,
      actionName
    });
  saveSearchCollapse != undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SAVE_SEARCH_COLLAPSE,
      payload: saveSearchCollapse,
      actionName
    });
  resumeCollapsed != undefined &&
    dispatch({
      type: ActionTypes.SEARCH.RESUME_COLLAPSED,
      payload: resumeCollapsed,
      actionName
    });
  resumeDetailCollapsed != undefined &&
    dispatch({
      type: ActionTypes.SEARCH.RESUME_DETAIL_COLLAPSED,
      payload: resumeDetailCollapsed,
      actionName
    });
  opportunitiesCollapse != undefined &&
    dispatch({
      type: ActionTypes.SEARCH.OPPORTUNITIES_COLLAPSE,
      payload: opportunitiesCollapse,
      actionName
    });
  shortlistCollapse != undefined &&
    dispatch({
      type: ActionTypes.SEARCH.SHORTLIST_COLLAPSE,
      payload: shortlistCollapse,
      actionName
    });
  createCollapse != undefined &&
    dispatch({
      type: ActionTypes.SEARCH.CREATE_COLLAPSE,
      payload: createCollapse,
      actionName
    });
};
