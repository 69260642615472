import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import {
  connectSocialMedia,
  disconnectSocialMedia
} from "../Phillip/phillipApi";
import { htmlParser } from "../../utilities/helpers";
import linkedInLogo from "../../assets/images/onboarding/linkedin.png";

class ExternalLinkedInLogin extends Component {
  constructor(props) {
    super(props);
    this.linkedInClientId = "78lrnmu1g30w3a";
  }

  componentDidMount() {
    var liRoot = document.createElement("div");
    liRoot.id = "linkedin-root";

    document.body.appendChild(liRoot);

    (function (d, s, id) {
      const element = d.getElementsByTagName(s)[0];
      const ljs = element;
      var js = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//platform.linkedin.com/in.js";
      js.text = "api_key: 77qc0nevp6rw4b";
      ljs.parentNode.insertBefore(js, ljs);
    })(document, "script", "linkedin-sdk");
  }

  callbackFunction = () => {
    window.IN.API.Profile("me")
      .fields([
        "id",
        "firstName",
        "lastName",
        "emailAddress",
        "location",
        "industry",
        "headline",
        "summary",
        "specialties",
        "public-profile-url",
        "picture-url"
      ])
      .result(this.handleLinkedInLoginSuccess)
      .error(this.handleLinkedInFailure);
  };

  handleClick = e => {
    e.preventDefault();
    console.dir(window.IN);
    window.IN.User.authorize(this.callbackFunction, "");
  };

  handleLinkedInLoginSuccess = profiles => {
    if (profiles) {
      const member = profiles.values[0];
      let locationCode = "";
      if (
        !isEmpty(member.location) &&
        !isEmpty(member.location.country) &&
        !isEmpty(member.location.country.code)
      ) {
        locationCode = member.location.country.code;
      }

      const user = {
        type: 1,
        ExternalId: member.id || "",
        ExternalEmail: member.emailAddress || "",
        ExternalName: member.firstName || "",
        ExternalFirstName: member.firstName || "",
        ExternalLastName: member.lastName || "",
        LocationCode: locationCode,
        IndustryName: member.industry || "",
        PresentHeadline: member.headline || "",
        PresentSummary: member.summary || "",
        PresentSpecialities: member.specialties || "",
        PresentProfileUrl: member.publicProfileUrl | "",
        PresentPictureUrl: member.pictureUrl || ""
      };
      connectSocialMedia({ user })
        .then(response => {
          if (response.success) {
            const { labels, onLinkedInClick, notificationAction } = this.props;
            const info = {
              status: "success",
              message: labels.SEARCHER_PHILLIP_LINKEDIN_CONNECT_MESSAGE
            };
            notificationAction(info);
            onLinkedInClick();
          }
        })
        .catch(response => { });
    }
  };

  handleLinkedInDisconnectClick = () => {
    const { isLinkedInConnected } = this.props;
    if (isLinkedInConnected) {
      const socialMediaType = { Type: 1 };
      disconnectSocialMedia({ socialMediaType }).then(response => {
        if (response.success) {
          const { labels, onLinkedInClick, notificationAction } = this.props;
          const info = {
            status: "success",
            message: labels.SEARCHER_PHILLIP_LINKEDIN_DISCONNECT_MESSAGE
          };
          notificationAction(info);
          onLinkedInClick();
        }
      });
    }
  };

  handleLinkedInFailure = err => {
  };

  render() {
    const {
      labels,
      isLinkedInConnected,
      testId,
      textTestId,
      text = "linked in",
      visibility = true,
      name = "abc"
    } = this.props;
    return (
      <div className="js_social_wrap">
        <div>
          {isLinkedInConnected ? (
            <button
              className="loginBtn loginBtn--linkedin"
              onClick={this.handleLinkedInDisconnectClick}
            >
              Login with LinkedIn
            </button>
          ) : (
            <button
              className="loginBtn loginBtn--linkedin"
              onClick={this.handleClick}
            >
              Login with LinkedIn
            </button>
          )}
        </div>
        <p test-data-id={textTestId}>
          {isLinkedInConnected
            ? htmlParser(labels.SearcherConnectedLinkedIn)
            : htmlParser(labels.SearcherNotConnectedLinkedIn)}
        </p>
      </div>
    );
  }
}

export default ExternalLinkedInLogin;
