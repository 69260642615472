import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  selectedCollaboration: {},
  collaborationList: [],
  filteredCollaborations: [],
  selectedUser: {},
  selectedCompany: {},
  fetchingCollaborations: false,
  isLoading: false,
  isHelpActive: false, //this.props.match.params.isHelp
  systemLabels: null,
  dialogMessage: "",
  listCollapsed: false,
  detailCollapsed: false,
  userCompanyDetailCollapsed: false,
  searchKey: "",
  yesClickCount: 0,
  currencies: [],
  reviewQuestions: [],
  isAlreadyRated: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.COLLABORATION.SELECTED_COLLABORATION:
      return {
        ...state,
        selectedCollaboration: action.payload
      };
    case ActionTypes.COLLABORATION.COLLABORATION_LIST:
      return {
        ...state,
        collaborationList: action.payload
      };
    case ActionTypes.COLLABORATION.FILTERED_COLLABORATIONS:
      return {
        ...state,
        filteredCollaborations: action.payload
      };
    case ActionTypes.COLLABORATION.SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload
      };
    case ActionTypes.COLLABORATION.SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload
      };
    case ActionTypes.COLLABORATION.FETCHING_COLLABORATIONS:
      return {
        ...state,
        fetchingCollaborations: action.payload
      };
    case ActionTypes.COLLABORATION.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case ActionTypes.COLLABORATION.IS_HELP_ACTIVE:
      return {
        ...state,
        isHelpActive: action.payload
      };
    case ActionTypes.COLLABORATION.SYSTEM_LABELS:
      return {
        ...state,
        systemLabels: action.payload
      };
    case ActionTypes.COLLABORATION.DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: action.payload
      };
    case ActionTypes.COLLABORATION.LIST_COLLAPSED:
      return {
        ...state,
        listCollapsed: action.payload
      };
    case ActionTypes.COLLABORATION.DETAIL_COLLAPSED:
      return {
        ...state,
        detailCollapsed: action.payload
      };
    case ActionTypes.COLLABORATION.USER_COMPANY_DETAIL_COLLAPSED:
      return {
        ...state,
        userCompanyDetailCollapsed: action.payload
      };
    case ActionTypes.COLLABORATION.SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload
      };
    case ActionTypes.COLLABORATION.YES_CLICK_COUNT:
        return {
          ...state,
          yesClickCount: action.payload
        };
    case ActionTypes.COLLABORATION.CURRENCIES:
      return {
        ...state,
        currencies: action.payload
      };
    case ActionTypes.COLLABORATION.REVIEW_QUESTIONS:
      return {
        ...state,
        reviewQuestions: action.payload
      };
    case ActionTypes.COLLABORATION.IS_ALREADY_RATED:
      return {
        ...state,
        isAlreadyRated: action.payload
      };

    default:
      return state;
  }
};

export const createCollaborationWrapperReducer = (
  reducerFunction,
  reducerName
) => {
  return (state, action) => {
    const { actionName } = action;
    const isInitializationCall = state === undefined;
    if (actionName !== reducerName && !isInitializationCall) return state;

    return reducerFunction(state, action);
  };
};
