import { ActionTypes } from "../actions";

const INITIAL_STATE = {
  listCollapsed: false,
  detailCollapsed: false,
  selectedMessage: {},
  filterMessages: [],
  messageList: [],
  isFetching: true,
  dialogMessage: "",
  searchKey: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.MESSAGE_SENT.LIST_COLLAPSED:
      return {
        ...state,
        listCollapsed: action.payload
      };
    case ActionTypes.MESSAGE_SENT.DETAIL_COLLAPSED:
      return {
        ...state,
        detailCollapsed: action.payload
      };
    case ActionTypes.MESSAGE_SENT.SELECTED_MESSAGE:
      return {
        ...state,
        selectedMessage: action.payload
      };
    case ActionTypes.MESSAGE_SENT.FILTER_MESSAGES:
      return {
        ...state,
        filterMessages: action.payload
      };
    case ActionTypes.MESSAGE_SENT.MESSAGE_LIST:
      return {
        ...state,
        messageList: action.payload
      };
    case ActionTypes.MESSAGE_SENT.IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      };
    case ActionTypes.MESSAGE_SENT.DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: action.payload
      };
    case ActionTypes.MESSAGE_SENT.SEARCH_KEY:
      return {
        ...state,
        searchKey: action.payload
      };
    default:
      return state;
  }
};
