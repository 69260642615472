import React, { Component } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../components";
import { notificationAction } from "../../actions";
import {
  tictellLogin,
  tictellAppLoginApi
} from "../Tictell/tictellApi";
 import "./searcher-phillip.scss";

import { privateRoutes } from "../../Routes/routing";
import { StorageService } from "../../api";
import { updatePhillipPopupStatusApi } from "../../components/Phillip/phillipApi";
import SearcherPhillip from "./components/SearcherPhillip";
import { getSnapshotMenusApi } from "../Snapshot/snapshotApi";
import PhilipNavigation from "./Navigation/Navigation";
import Dashboard from "./views/Dashboard/Dashboard";
import Monitoring from "./views/Monitoring/Monitoring";

import StartNewSearch from "./views/StartNewSearch/StartNewSearch";
import Reports from "./views/ApproveTimeExpenses/ApproveTimeExpenses";
import Opportunity from "./views/Opportunity/Opportunity";
class SearcherPhilipModule extends Component {
  state = {
    UserFeatures: [],
    token: "",
    ExpenseCategories: [],
    Currencies: [],
    isLoading: true,
    UserId: -1,
    dialogMessage: "",
    showPhillip: true,
    facebookClientId: "",
    linkedInClientId: "",
  };
  componentDidUpdate(prevProps) {
    // if (this.props.location !== prevProps.location) {
    //   this.onRouteChanged();
    // }
  }

  onRouteChanged() {
    const firstUrl = this.state.UserFeatures.find(a => a.Url);
    var queryString = window.location.href;
    queryString = queryString.toLowerCase().split('searcher-philip')[1];
    if (queryString == "") {
      if (firstUrl) {
        const isApp = window.location.hash.toLowerCase().indexOf('apps') > - 1;
        this.props.history.push((isApp ? "/apps" : "") + "/searcher-philip/" + firstUrl.Url)
      }
    }
  }
  componentDidMount() {
    getSnapshotMenusApi({ featureId: 108 })
      .then(res => {
        let usersFeaturesList = res.items;
        usersFeaturesList = usersFeaturesList.filter(a => a.Url);
        usersFeaturesList.push(usersFeaturesList.shift());
        const list=usersFeaturesList?.map((single,index)=>{
          return {
            ...single,
            mobileName:single?.Url?.charAt(0).toUpperCase() + single?.Url.slice(1),
            mobileIcon:`icon-phillips-mobile-${index}`
          }
        })
         this.setState({
          UserFeatures: list,
          isLoading: false
        })
        const firstUrl = usersFeaturesList.find(a => a.Url);
        
        var queryString = window.location.href;
        queryString = queryString.toLowerCase().split('searcher-philip')[1];
        if (queryString == "") {
          if (firstUrl) {
            const isApp = window.location.hash.toLowerCase().indexOf('apps') > - 1;
            this.props.history.push((isApp ? "/apps" : "") + "/searcher-philip/" + firstUrl.Url)
          }
        }
      })
      .catch(err => console.log("Err ", err))

    let { UserEmail } = this.props.User;
    let Email = UserEmail;
    if (!Email) {
      const User = StorageService.getUser();
      Email = User.Email;
    }

    tictellAppLoginApi(Email)
      .then((res) => {
        if (res.success) {
          tictellLogin(Email)
            .then((res) => {
              if (res.success) {
                const { access_token } = res.items;
                this.setState({
                  token: access_token,
                });
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((Err) => console.log("Error ", Err));
    this.setState({
      UserId: this.props.User.UserId
    })
  }

  handleOkClick = () => {
    this.props.history.push(privateRoutes.dashboard.path);
  }

  handleClosePhillip = () => {
    const { IsFreelancer } = this.props;
    updatePhillipPopupStatusApi({ isFreelancer: IsFreelancer }).then(
      () => { }
    );
    this.setState({ showPhillip: false });
  };

  handleShowPhillip = () => {
    this.setState({ showPhillip: true });
  };

  handleSearcherPhillipSkip = () => {
    const { IsFreelancer } = this.props;
    updatePhillipPopupStatusApi({ isFreelancer: IsFreelancer }).then(
      response => {
        if (response.success) {
          const info = {
            message: this.props.searcherPhillipSkip,
            status: "success"
          };
          this.props.notificationAction(info);
        }
      }
    );
    this.setState({ showPhillip: false });
  };

  handleUpdateCompanyPresentationsWidget = () => {
    this.setState({
      isReloadCompanyPresentationsWidget: !this.state
        .isReloadCompanyPresentationsWidget
    });
  }

  handleUpdateResumeShortlistWidgetWidget = () => {
    this.setState({
      isReloadResumeShortlistWidget: !this.state.isReloadResumeShortlistWidget
    });
  };

  render() {
    const { labels, isHelpActive } = this.props;
    const {
      showPhillip,
      facebookClientId,
      linkedInClientId,
      UserFeatures, token, Currencies, isLoading } = this.state;
    const currentViewHash = window.location.hash.toLowerCase().split('/')[1] == 'apps' ? window.location.hash.toLocaleLowerCase().split('/')[3] : window.location.hash.toLocaleLowerCase().split('/')[2];
    return (
      <PageWrapper className="philip-page">
        {isLoading && <div id="loader-wrapper"><div test-data-id={"loader"} id="loader" /></div>}
        <PhilipNavigation
          labels={labels}
          disabled={`${isLoading ? "disabled" : ""}`}
          isHelpActive={isHelpActive}
          UserFeatures={UserFeatures}
        />
        {currentViewHash == "automate" && UserFeatures.findIndex(a => a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash) > -1 && <SearcherPhillip
          open={showPhillip}
          onCloseModal={this.handleClosePhillip}
          onSkip={this.handleSearcherPhillipSkip}
          facebookClientId={facebookClientId}
          linkedInClientId={linkedInClientId}
          onCompanyPresentationsUpdate={this.handleUpdateCompanyPresentationsWidget}
          onResumeShortlistUpdate={this.handleUpdateResumeShortlistWidgetWidget}
        />}
        {UserFeatures.findIndex(a => a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash) > -1 && currentViewHash == "statistics" &&
          <Dashboard
            token={token}
            UserFeatures={UserFeatures}
          />
        }
        {UserFeatures.findIndex(a => a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash) > -1 && currentViewHash == "monitoring" &&
          <Monitoring
            token={token}
          />
        }

        {currentViewHash == "start-new-search" &&
          UserFeatures.findIndex(
            (a) =>
              a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
          ) > -1 && (
            <StartNewSearch />
          )}
        {currentViewHash == "approve-time-and-expenses" &&
          UserFeatures.findIndex(
            (a) =>
              a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
          ) > -1 && (
            <Reports />
          )}
        {currentViewHash == "phillip-opportunites" &&
          UserFeatures.findIndex(
            (a) =>
              a.Url && a.Url.toLocaleLowerCase().trim() == currentViewHash
          ) > -1 && <Opportunity token={token} Currencies={Currencies} />}
      </PageWrapper>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(SearcherPhilipModule);
