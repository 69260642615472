import React, { Component } from "react";
import { connect } from "react-redux";
import { Messages } from "../../../utilities";
import { LoadingMask, Input, Button, ListGroup } from "../../../common";
import { map, filter } from "lodash";
import ImgSrc from "../../../assets/images/present.svg";
import Select from "react-select";

class Profile extends Component {
  componentDidMount = () => {};

  render() {
    const {
      selectedProfile,
      onSaveUserSetting,
      onFormFieldChange,
      isFetching,
      isInvalidUserFirstname,
      isInvalidUserLastname,
      labels,
      countries,
      onFormSelectChange,
      onAvatarChange,
      IsFreelancer,
      getImgSrc
    } = this.props;

    const avatar = selectedProfile && selectedProfile.ProfilePicture;
    return (
      <div className="userSettings">
        {isFetching && <LoadingMask text="Loading..." />}

        <div className="profile-form">
          <div className="profileBigIcon">
            {avatar && <button className="closeBtn" onClick={getImgSrc} />}
            <div className={`dashItemImg ${avatar ? "" : "no-dp"}`}>
              <img
                className="roundeImg"
                src={avatar ? avatar : ImgSrc}
                alt="avatar"
              />
            </div>

            <div className="uploadBtn" testid="profile-avatar-btn">
              <input
                testid="profile-avatar-input"
                type="file"
                name="profileAvatar"
                className="input-upload-file"
                onChange={onAvatarChange}
                accept="image/*"
              />
            </div>
          </div>

          <div className="form-row">
            <label className="form-label">{labels.FIRSTNAME_LABEL}</label>
            <input
              type="text"
              name="UserFirstname"
              className={`input-text ${
                isInvalidUserFirstname ? "inValid" : ""
              }`}
              value={
                selectedProfile && selectedProfile.UserFirstname
                  ? selectedProfile.UserFirstname
                  : ""
              }
              placeholder={labels.USER_SETTING_FIRSTNAME_PLACEHOLDER}
              testid="txtsettingsUserName"
              onChange={e => onFormFieldChange(e)}
            />
          </div>

          <div className="form-row">
            <label className="form-label">{labels.LASTNAME_LABEL}</label>
            <input
              type="text"
              name="UserLastname"
              className={`input-text ${isInvalidUserLastname ? "inValid" : ""}`}
              value={
                selectedProfile && selectedProfile.UserLastname
                  ? selectedProfile.UserLastname
                  : ""
              }
              placeholder={labels.USER_SETTING_LASTNAME_PLACEHOLDER}
              testid="txtsettingsLastUserName"
              onChange={e => onFormFieldChange(e)}
            />
          </div>

          <div className="form-row">
            <label className="form-label">{"Registration Email"}</label>
            <input
              type="text"
              name="UserLastname"
              className="input-text-disabled"
              value={
                selectedProfile && selectedProfile.UserEmail
                  ? selectedProfile.UserEmail
                  : ""
              }
              testid="txtprimaryemail"
              disabled
            />
          </div>

          {!IsFreelancer && (
            <div className="form-row">
              <label className="form-label">{labels.COUNTRY_LABEL}</label>
              <Select
                name="CountryId"
                className="select-input"
                value={(selectedProfile && selectedProfile.CountryId) || ""}
                placeholder={labels.companyCountryPlaceholder}
                onChange={selectedProfile =>
                  onFormSelectChange(selectedProfile)
                }
                options={countries}
                clearable={false}
              />
            </div>
          )}

          <button
            className="SendButton"
            onClick={onSaveUserSetting}
            testid="btnsettingupdate"
            tooltiphelp={labels.USER_SETTING_DETAILS_SECTION_SAVE_BUTTON_HELP}
            tooltipbutton={
              labels.USER_SETTING_DETAILS_SECTION_SAVE_BUTTON_TOOLTIP
            }
          >
            {labels.USER_SETTING_UPDATE_BUTTON_TEXT}
          </button>
        </div>
      </div>

      //New Imp
    );
  }
}
const mapStateToProps = ({ systemLabel, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  let { IsFreelancer } = user;
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return { labels, IsFreelancer };
};
export default connect(mapStateToProps)(Profile);
