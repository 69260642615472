import React from "react";
import "./dashboard.scss";
import Widget from "./Widget";
import {
  Column,
  HelpTooltip,
  EmptyInfo,
} from "../../../../common";
import {
  getAllWorkplacesWithIProsCountApi,
  getFlowsCountApi,
  getFlowItemsCountApi,
  getFlowGroupsCountApi,
  getFlowGroupItemsCountApi,
  getSearcherCompletedPercentagetApi,
} from "../../embarkApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
class Dashboard extends React.Component {
  state = {
    Pendingtimesheet: "",
    Approvedtimesheet: "",
    Rejectedtimesheet: "",
    Rejectedexpense: "",
    Pendingexpense: "",
  };
  componentDidMount() {
    getAllWorkplacesWithIProsCountApi().then((res) => {
      if (res.success) {
        this.setState({
          WorkplacesWithIPros: res.items,
        });
      }
    });
    getFlowsCountApi(this.props.token).then((res) => {
      if (res.success)
        this.setState({
          FlowCounts: res.items,
        });
    });
    getFlowItemsCountApi(this.props.token).then((res) => {
      if (res.success)
        this.setState({
          FlowItemCounts: res.items,
        });
    });
    getFlowGroupsCountApi(this.props.token).then((data) => {
      if (data.success) {
        this.setState({
          FlowGroupCounts: data.items,
        });
      } else {
        this.setState({
          FlowGroupCounts: {},
        });
      }
    });
    getFlowGroupItemsCountApi(this.props.token).then((data) => {
      if (data.success) {
        this.setState({
          FlowGroupItemsCounts: data.items,
        });
      } else {
        this.setState({
          FlowGroupItemsCounts: "",
        });
      }
    });
    getSearcherCompletedPercentagetApi(this.props.token).then((data) => {
      if (data.success) {
        this.setState({
          CompletePercentage: data.items,
        });
      } else {
        this.setState({
          CompletePercentage: "",
        });
      }
    });
  }

  render() {
    const {
      widgetTestId,
      contentTestId,
      isHelpActive,
      labels,
      UserFeatures,
    } = this.props;
    const {
      FlowCounts,
      FlowGroupCounts,
      FlowItemCounts,
      FlowGroupItemsCounts,
      WorkplacesWithIPros,
      CompletePercentage,
      Pendingtimesheet,
      Approvedtimesheet,
      Rejectedtimesheet,
      Rejectedexpense,
      Pendingexpense,
      activeCollabCount,
      myCollaborationsAsOwner,
    } = this.state;
    return (
      <div className="dashboard-view">
        <React.Fragment>
          <Column className="column-1">
            {UserFeatures &&
              UserFeatures.find(
                (x) => x.Name == "Embark-Home-FlowCounts" && x.IsActive
              ) && (
                <Widget testId={widgetTestId}>
                  <Widget.Header>
                    {isHelpActive && <HelpTooltip content={labels?.searcher_embark_home_column1_title} />}{" "}
                    <i className="timesheet-icon" />
                    <label>{labels?.searcher_embark_home_column1_title}</label>
                  </Widget.Header>
                  <Widget.Content>
                    <div className="widget-content">
                      <div>
                        <label>
                          {(FlowCounts && FlowCounts.AllCount) || 0}
                        </label>
                        <label>{"Total"}</label>
                      </div>
                      <div>
                        <label>
                          {(FlowCounts && FlowCounts.MineCount) || 0}
                        </label>
                        <label>{"Mine"}</label>
                      </div>
                      <div>
                        <label>
                          {(FlowCounts && FlowCounts.OthersCount) || 0}
                        </label>
                        <label>{"Others"}</label>
                      </div>
                    </div>
                  </Widget.Content>
                </Widget>
              )}
            {UserFeatures &&
              UserFeatures.find(
                (x) => x.Name == "Embark-Home-FlowGroupsCount" && x.IsActive
              ) && (
                <Widget testId={widgetTestId}>
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip content={"Flow Groups Count"} />
                    )}{" "}
                    <i className="timesheet-icon" />
                    <label>{"Flow Groups Count"}</label>
                  </Widget.Header>
                  <Widget.Content>
                    <div className="widget-content">
                      <div>
                        <label>
                          {(FlowGroupCounts && FlowGroupCounts.AllCount) || 0}
                        </label>
                        <label>{"Total"}</label>
                      </div>
                    </div>
                  </Widget.Content>
                </Widget>
              )}
            {UserFeatures &&
              UserFeatures.find(
                (x) => x.Name == "Embark-Home-CompletedPercentage" && x.IsActive
              ) && (
                <Widget testId={widgetTestId}>
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip content={"Completed Percentage"} />
                    )}{" "}
                    <i className="timesheet-icon" />
                    <label>{"Completed Percentage"}</label>
                  </Widget.Header>
                  <Widget.Content>
                    <div className="widget-content">
                      <div>
                        <label>{`${(CompletePercentage && CompletePercentage) ||
                          0} %`}</label>
                        <label>{"Work Completed"}</label>
                      </div>
                    </div>
                  </Widget.Content>
                </Widget>
              )}
          </Column>
          <Column className="column-2">
            {UserFeatures &&
              UserFeatures.find(
                (x) => x.Name == "Embark-Home-FlowsAndItems" && x.IsActive
              ) && (
                <Widget testId={widgetTestId}>
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip content={labels?.searcher_embark_home_column2_title} />
                    )}{" "}
                    <i className="timesheet-icon" />
                    <label>{labels?.searcher_embark_home_column2_title}</label>
                  </Widget.Header>
                  <Widget.Content>
                    <div className="collab-widget-content">
                      {FlowItemCounts && FlowItemCounts.length > 0 ? (
                        FlowItemCounts.map((item) => (
                          <div>
                            <label>{item.Count}</label>
                            <label>{`${item.Title}`}</label>
                          </div>
                        ))
                      ) : (
                        <EmptyInfo>
                          {labels.EMBARK_NO_ITEM_TO_DISPLAY}
                        </EmptyInfo>
                      )}
                    </div>
                  </Widget.Content>
                </Widget>
              )}
          </Column>
          <Column className="column-2">
            {UserFeatures &&
              UserFeatures.find(
                (x) => x.Name == "Embark-Home-FlowGroupsAndItems" && x.IsActive
              ) && (
                <Widget testId={widgetTestId}>
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip content={"Flow Groups And Items"} />
                    )}{" "}
                    <i className="timesheet-icon" />
                    <label>{"Flow Groups And Items"}</label>
                  </Widget.Header>
                  <Widget.Content>
                    <div className="collab-widget-content">
                      {FlowGroupItemsCounts && FlowGroupItemsCounts.length ? (
                        FlowGroupItemsCounts.map((item) => (
                          <div>
                            <label>{item.Count}</label>
                            <label>{`${item.Title}`}</label>
                          </div>
                        ))
                      ) : (
                        <EmptyInfo>
                          {labels.EMBARK_NO_ITEM_TO_DISPLAY}
                        </EmptyInfo>
                      )}
                    </div>
                  </Widget.Content>
                </Widget>
              )}
          </Column>
        </React.Fragment>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(Dashboard);
