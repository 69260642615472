import React, { Component } from "react";
import moment from "moment";
import { Button } from "../../../../../common";
import { ListItem } from "../../../../Tictell/views/components/ListItem";

class TimelogList extends React.Component {
  render() {
    const { timelogList, onItemClick ,updateTimeSheetStatus,TimeSheetStatusId,isHelpActive } = this.props;
    return (
      <React.Fragment>
        <div className="tictell-list">
          {timelogList &&
            timelogList.map(item => (
              <ListItem
                onClick={onItemClick}
                item={item}
                key={item.TimeLogId}
              >
                <div className="name-container">
                  <div>
                    <label test-data-Id={`timelog-date-item ${item.isSelected ?"selected-item" : ""}${item.isActive ? "activeItem" : ""}`} >
                      {item.Date && moment(item.Date).format("ddd, MMM Do YYYY")}
                    </label>
                    <label>{`${item.Amount} ${item.Currency}`}</label>
                  </div>
                  <label>{item.Duration}</label>
                </div>
              </ListItem>
            ))}
        </div>
        {TimeSheetStatusId == 1  &&
          <Button
            testId={`reject-timelog-btn`}
            mainClassName={'action-button-wrapper'}
            className={`reject-btn ${TimeSheetStatusId == 3 || TimeSheetStatusId == 2 ? "hidden":""}`}
            onClick={()=>updateTimeSheetStatus(3)}
            isHelpActive={isHelpActive}
            tooltipHelp={"Click this button to reject timesheet"}
            testIdHelp={'add-reject-btn-help'}
          />
        }    
        {TimeSheetStatusId == 1  &&   
          <Button
            testId={`approve-timelog-btn`}
            mainClassName={'action-button-wrapper'}
            className={`approve-btn ${TimeSheetStatusId == 3 || TimeSheetStatusId == 2 ? "hidden":""}`}
            onClick={()=>updateTimeSheetStatus(2)}
            isHelpActive={isHelpActive}
            tooltipHelp={"Click this button to approve timesheet"}
            testIdHelp={'add-reject-btn-help'}
          />
        }
      </React.Fragment>
    );
  }
}

export default TimelogList;
