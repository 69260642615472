import React, { Component } from "react";
import { EmptyInfo, Button } from "../../../../../common";
import moment from "moment";
import "./opportunityDetail.scss";

class OpportunityDetail extends Component {
  renderDate = (date) => {
    return moment(date).format("MM/DD/YY");
  };

  render() {
    const {
      selectedOpportunity,
      emptyOpportunityInfo,
      selectInterest,
      onOpportunityRestore,
      IsSearcher,
      locationsLabel,
      startDateLabel,
      durationLabel,
      SearcherOpportunitySentDetailFee,
      companyLabel,
      descriptionLabel,

      SearchParameterHeading,
      Lbltitle,
      LblProfile,
      LblSkills,
      LblKeyword,
      LblIndustry,
      LblCertification,
      LblCountry,
      LblLanguage,
      LblInterest,
      LblRestore,
      BtnAccept,
      BtnDecline,
      BtnRestore,
      handleOpportunityDetailAcceptClick,
      handleOpportunityDetailDeclinedClick,
      handleOpportunityDetailCompanyClick,
    } = this.props;
    const retrieveSearch = selectedOpportunity.retrieveResumeSearch;
    return (
      <div className="opportunity-detail-component">
        {!selectedOpportunity.RequestId ? (
          <EmptyInfo>{emptyOpportunityInfo}</EmptyInfo>
        ) : (
          <div className="MsgWrapper">
            <div className="invSenderSum MsgDetailWrap">
              <b> {selectedOpportunity.UserFirstname} </b>
              {"from"}
              {selectedOpportunity.Company && (
                <span
                  className="invCompValue"
                  test-data-id="showcompanyfromheading-opportunitynew-label"
                  onClick={handleOpportunityDetailCompanyClick}
                >
                  &nbsp;{selectedOpportunity.Company.CompanyName}&nbsp;
                </span>
              )}
              {Lbltitle}
            </div>

            <div className="titleLabel">{selectedOpportunity.RequestName}</div>

            <div className="feedbackWrap">
              <div className="msgItemDiv">
                {selectedOpportunity.Countries.length > 0 && (
                  <div className="msgItem">
                    <label className="labelName">{locationsLabel}:</label>
                    <label className="answerLabel">
                      {selectedOpportunity.Countries[0].CountryName}
                    </label>
                  </div>
                )}

                {selectedOpportunity.StartDate && (
                  <div className="msgItem">
                    <label className="labelName">{startDateLabel}:</label>
                    <label className="answerLabel">
                      {this.renderDate(selectedOpportunity.StartDate)}
                    </label>
                  </div>
                )}

                {selectedOpportunity.Duration && (
                  <div className="msgItem">
                    <label className="labelName">{durationLabel}:</label>
                    <label className="answerLabel">
                      {selectedOpportunity.Duration}&nbsp;
                      {selectedOpportunity.DurationType}
                    </label>
                  </div>
                )}

                {selectedOpportunity.HourlyFee && (
                  <div className="msgItem">
                    <label className="labelName">{SearcherOpportunitySentDetailFee}:</label>
                    <label className="answerLabel">
                      {selectedOpportunity.HourlyFee} &nbsp;
                      {selectedOpportunity.FeeCurrencyType}
                    </label>
                  </div>
                )}

                {selectedOpportunity.Company && (
                  <div className="msgItem">
                    <label className="labelName">{companyLabel}:</label>
                    <label
                      className="answerLabel invCompValue"
                      test-data-id="showcompany-opportunitynew-label"
                      onClick={handleOpportunityDetailCompanyClick}
                    >
                      {selectedOpportunity.Company.CompanyName}
                    </label>
                  </div>
                )}

                {selectedOpportunity.Description && (
                  <div className="msgItem">
                    <label className="labelName">{descriptionLabel}:</label>
                    <label className="answerLabel">
                      {selectedOpportunity.Description}
                    </label>
                  </div>
                )}
                {retrieveSearch && (
                  <div className="invSelScore">
                    {SearchParameterHeading && (
                      <div className="MsgDetailWrap">
                        {SearchParameterHeading}
                      </div>
                    )}

                    {retrieveSearch.Profiles && (
                      <div className="msgItem">
                        <label className="labelName">{LblProfile}:</label>
                        <div className="answerLabel">
                          {retrieveSearch.Profiles.map((searchItem) => (
                            <span
                              key={searchItem.ProfileId}
                              className="invScore"
                            >
                              {searchItem.ProfileValue}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                    {retrieveSearch && (
                      <div className="msgItem">
                        <label className="labelName">{LblSkills}:</label>
                        <div className="answerLabel">
                          {retrieveSearch.Skills.map((searchItem) => (
                            <span
                              key={searchItem.SearchedPK}
                              className="invScore"
                            >
                              {searchItem.SearchedValue}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                    {retrieveSearch.Keywords && (
                      <div className="msgItem">
                        <label className="labelName">{LblKeyword}:</label>
                        <div className="answerLabel">
                          {retrieveSearch.Keywords.map((searchItem) => (
                            <span
                              key={searchItem.KeywordId}
                              className="invScore"
                            >
                              {searchItem.KeywordValue}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                    {retrieveSearch.Industeries && (
                      <div className="msgItem">
                        <label className="labelName">{LblIndustry}:</label>
                        <div className="answerLabel">
                          {retrieveSearch.Industeries.map((searchItem) => (
                            <span
                              key={searchItem.IndustryId}
                              className="invScore"
                            >
                              {searchItem.IndustryValue}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                    {retrieveSearch.Certifications && (
                      <div className="msgItem">
                        <label className="labelName">{LblCertification}:</label>
                        <div className="answerLabel">
                          {retrieveSearch.Certifications.map((searchItem) => (
                            <span
                              key={searchItem.CertificationId}
                              className="invScore"
                            >
                              {searchItem.CertificationValue}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                    {retrieveSearch.Countries && (
                      <div className="msgItem">
                        <label className="labelName">{LblCountry}:</label>
                        <div className="answerLabel">
                          {retrieveSearch.Countries.map((searchItem) => (
                            <span
                              key={searchItem.CountryId}
                              className="invScore"
                            >
                              {searchItem.CountryName}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}

                    {retrieveSearch.Languages && (
                      <div className="msgItem">
                        <label className="labelName">{LblLanguage}:</label>
                        <div className="answerLabel">
                          {retrieveSearch.Languages.map((searchItem) => (
                            <span
                              key={searchItem.LanguageId}
                              className="invScore"
                            >
                              {searchItem.LanguageValue}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {selectedOpportunity.IsDeclined &&
                  !selectedOpportunity.IsDeleted && (
                    <div className="MsgDetailWrap">
                      <div>
                        {LblRestore}
                        <div className="invRequest">
                          <button
                            className="invRestore SendButton"
                            onClick={onOpportunityRestore}
                            test-data-id="restore-opportunitynew-btn"
                          >
                            {BtnRestore}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="MsgDetailWrap">
                  {!selectedOpportunity.IsConfirmed &&
                    !selectedOpportunity.IsDeleted &&
                    !selectedOpportunity.IsDeclined && (
                      <div className="msgItem">
                        <label className="labelName invLabelInterest">
                          {LblInterest}:
                        </label>
                        <div className="invRequest">
                          <Button
                            className="invAccept SendButton"
                            text={BtnAccept}
                            onClick={() => handleOpportunityDetailAcceptClick()}
                            tooltipButton={BtnAccept}
                            tooltipHelp={BtnAccept}
                            tooltipPlace="left"
                            testId="accept-opportunitynew-btn"
                          />
                          <Button
                            className="invDecline SendButton"
                            text={BtnDecline}
                            onClick={() =>
                              handleOpportunityDetailDeclinedClick()
                            }
                            tooltipButton={BtnDecline}
                            tooltipHelp={BtnDecline}
                            tooltipPlace="left"
                            testId="declined-opportunitynew-btn"
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default OpportunityDetail;
