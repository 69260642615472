import { ActionTypes } from "../../actions";
window.view = "NEWSAVEDSEARCH";
window.actionName = "newSavedSearch";
export const onStateChangeAction = ({
  resumeList,
  selected,
  defaultList,
  collapse,
  savedSearch,
  filterSaveSearch,
  actionName = window.actionName,
}) => (dispatch) => {
  resumeList !== undefined &&
    dispatch({
      type: ActionTypes.NEWSAVEDSEARCH.RESUME_LIST_SAVED_SEARCH,
      payload: resumeList,
      actionName,
    });
  selected !== undefined &&
    dispatch({
      type: ActionTypes.NEWSAVEDSEARCH.SELECTED_RESUME_SAVED_SEARCH,
      payload: selected,
      actionName,
    });
  defaultList !== undefined &&
    dispatch({
      type: ActionTypes.NEWSAVEDSEARCH.DEFAULT_LIST_SAVED_SEARCH,
      payload: defaultList,
      actionName,
    });
  collapse !== undefined &&
    dispatch({
      type: ActionTypes.NEWSAVEDSEARCH.SEARCH_COLLAPSED_SAVED_SEARCH,
      payload: collapse,
      actionName,
    });
  savedSearch !== undefined &&
    dispatch({
      type: ActionTypes.NEWSAVEDSEARCH.SAVED_SEARCH_SAVED_SEARCH,
      payload: savedSearch,
      actionName,
    });
    filterSaveSearch !== undefined &&
    dispatch({
      type: ActionTypes.NEWSAVEDSEARCH.FILTER_SELECTED_SAVE_SEARCH,
      payload: filterSaveSearch,
      actionName,
    });
};
