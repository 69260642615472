import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { isEmpty, map, filter } from "lodash";
import "./resume-suggestor.scss";
import { withRouter } from "react-router-dom";
import {
  GetSuggestedProfilesForResumeApi,
  GetSuggestedSkillsForResumeApi,
  UpdateResumeApi,
  UploadAndGetRolesAndSkillsFromResumeApi,
  addResumeProfileApi,
  addResumeSkillApi,
} from "../../nextstepApi";

const LOOKUPTYPES = {
  PROFILE: 1,
  SKILL: 2,
  KEYWORD: 3,
  CERTIFICATION: 4,
  COUNTRY: 5,
  LANGUAGE: 6,
  INDUSTRY: 7,
};
class ResumeSuggestor extends Component {
  state = {
    currentSlide: 0,
    keywords: "",
    isLoading: false,
    extractedSearchSettings: {
      Profiles: [],
      Skills: [],
    },
    resumeUploaded: false,
  };
  componentDidMount() {
    window.addEventListener("keydown", this.handleTabPress);
    GetSuggestedProfilesForResumeApi()
      .then((res) => {
        this.setState({
          extractedSearchSettings: {
            ...this.state.extractedSearchSettings,
            Profiles: res.items.map((a) => ({
              Id: a.CorrelatedProfiles.ProfileId,
              LookupValue: a.CorrelatedProfiles.ProfileValue,
            })),
          },
        });
      })
      .catch((err) => { });
    GetSuggestedSkillsForResumeApi()
      .then((res) => {
        this.setState({
          extractedSearchSettings: {
            ...this.state.extractedSearchSettings,
            Skills: res.items.map((a) => ({
              Id: a.SkillId,
              LookupValue: a.SkillValue,
            })),
          },
        });
      })
      .catch((err) => { });
  }

  handleFinishClick = () => {
    const { extractedSearchSettings } = this.state;
    const { Profiles, Skills } = extractedSearchSettings;
    const resume = {
      ResumeProfiles: Profiles.map((p) => ({
        ProfileId: p.Id,
        ExperienceLevel: 2,
      })),
      ResumeSkills: Skills.map((s) => ({
        SkillId: s.Id,
        ExperienceLevel: 2,
      })),
    };
    this.setState({
      isLoading: true,
    });
    UpdateResumeApi(resume).then((res) => {
      const info = {
        message: "Resume Updated Successfully",
        status: "success",
      };
      this.props.notificationAction(info);
      this.setState({
        isLoading: false,
        resumeUploaded: false,
        extractedSearchSettings: {
          Profiles: [],
          Skills: [],
        },
      });
      return;
    });
  };

  uploadLinkedinPdf = (e, successName) => {
    var fileData = e.target.files[0];
    var types = "pdf";
    const { labels, notificationAction } = this.props;
    var fileType = fileData.name.split(".");
    const me = this;
    if (types === fileType[1].toLowerCase()) {
      var reader = new FileReader();
      reader.onload = (function (theFile) {
        return function (e) {
          var fileDataInBytes = e.target.result.split(",")[1];
          me.setState({ isLoading: true });
          UploadAndGetRolesAndSkillsFromResumeApi(fileDataInBytes)
            .then((response) => {
              if (response.success) {
                let {
                  Certifications = [],
                  Countries = [],
                  Industries = [],
                  Keywords = [],
                  Languages = [],
                  Profiles = [],
                  Skills = [],
                } = response.items;

                Certifications = map(Certifications, (certification) => ({
                  Id: certification.CertificationId,
                  ExpLevel: 0,
                  LookupValue: certification.CertificationValue,
                }));
                Countries = map(Countries, (country) => ({
                  Id: country.CountryId,
                  ExpLevel: 0,
                  LookupValue: country.CountryName,
                }));
                Industries = map(Industries, (industry) => ({
                  Id: industry.IndustryId,
                  ExpLevel: 0,
                  LookupValue: industry.IndustryValue,
                }));
                Keywords = map(Keywords, (keyword) => ({
                  Id: keyword.KeywordId,
                  ExpLevel: 0,
                  LookupValue: keyword.KeywordValue,
                }));
                Languages = map(Languages, (language) => ({
                  Id: language.LanguageId,
                  ExpLevel: 0,
                  LookupValue: language.LanguageValue,
                }));
                Profiles = map(Profiles, (profile) => ({
                  Id: profile.ProfileId,
                  ExpLevel: 0,
                  LookupValue: profile.ProfileValue,
                }));
                Skills = map(Skills, (skill) => ({
                  Id: skill.SkillId,
                  ExpLevel: 0,
                  LookupValue: skill.SkillValue,
                }));

                if (
                  isEmpty(Certifications) &&
                  isEmpty(Countries) &&
                  isEmpty(Industries) &&
                  isEmpty(Keywords) &&
                  isEmpty(Languages) &&
                  isEmpty(Profiles) &&
                  isEmpty(Skills)
                ) {
                  const info = {
                    message: labels.searcherOnboardingInvalidDescription,
                    status: "success",
                  };
                  notificationAction(info);
                  this.setState({
                    isLoading: false,
                  });
                  return;
                }
                const saveResumeParams = {
                  Certifications,
                  Countries,
                  Industries,
                  Keywords,
                  Languages,
                  Profiles,
                  Skills,
                };
                me.setState({
                  extractedSearchSettings: saveResumeParams,
                  isLoading: false,
                  resumeUploaded: true,
                });
              } else {
                this.setState({
                  isLoading: false,
                });
              }
            })
            .catch((err) => {
              const info = {
                message: "Unable to upload to file.",
                status: "error",
              };
              notificationAction(info);
              me.setState({ isLoading: false });
            });
        };
      })(fileData);

      reader.readAsDataURL(fileData);
    } else {
      const info = {
        message: "Please select pdf file.",
        status: "error",
      };
      notificationAction(info);
    }
  };

  extractedSettingUpdate = (type, item) => {
    const { extractedSearchSettings } = this.state;
    let {
      Certifications,
      Countries,
      Industries,
      Keywords,
      Languages,
      Profiles,
      Skills,
    } = extractedSearchSettings;
    switch (type) {
      case LOOKUPTYPES.PROFILE: {
        addResumeProfileApi(item.Id)
          .then((res) => {
            if (res.success) {
              Profiles = filter(Profiles, (a) => a.Id != item.Id);
              this.setState({
                extractedSearchSettings: {
                  ...extractedSearchSettings,
                  Profiles,
                },
              });
            }
          })
          .catch((err) => {
          });
        break;
      }
      case LOOKUPTYPES.SKILL: {
        addResumeSkillApi(item.Id)
          .then((res) => {
            if (res.success) {
              Skills = filter(Skills, (a) => a.Id != item.Id);
              this.setState({
                extractedSearchSettings: {
                  ...extractedSearchSettings,
                  Skills,
                },
              });
            }
          })
          .catch((err) => {
          });
        break;
      }
      case LOOKUPTYPES.CERTIFICATION: {
        Certifications = filter(Certifications, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.COUNTRY: {
        Countries = filter(Countries, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.INDUSTRY: {
        Industries = filter(Industries, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.KEYWORD: {
        Keywords = filter(Keywords, (a) => a.Id != item.Id);
        break;
      }
      case LOOKUPTYPES.LANGUAGE: {
        Languages = filter(Languages, (a) => a.Id != item.Id);
        break;
      }
    }
    this.setState({
      extractedSearchSettings: {
        ...extractedSearchSettings,
        Profiles,
        Skills,
        Certifications,
        Keywords,
        Languages,
        Countries,
      },
    });
  };

  render() {
    const { labels } = this.props;
    const { extractedSearchSettings, resumeUploaded } = this.state;
    const { Profiles, Skills } = extractedSearchSettings;
    return (
      <div
        className="phillip-component ipro-phillip resume-suggestor"
        test-data-id="ipro-phillip-popup"
      >
        <div className="upload-div">
          <b>
            {
              "Upgrade your Resume in relation to market needs and get a higher hourly wage"
            }
          </b>
        </div>
        <div className={`step`}>
          <div className="body">
            {Profiles && Profiles.length > 0 ? (
              <div>
                <div className="lookup-head">
                  <h3>Suggested Roles</h3>
                  <label>
                    {" "}
                    We would like to suggest following roles for your resume
                  </label>
                </div>
                <div className="settings-container">
                  {Profiles &&
                    Profiles.map((item) => (
                      <label
                        test-data-id="resume-edit-suggested-role-item"
                        className="draggable"
                        onClick={() =>
                          this.extractedSettingUpdate(LOOKUPTYPES.PROFILE, item)
                        }
                        key={item.Id}
                      >
                        {item.LookupValue}
                      </label>
                    ))}
                </div>
              </div>
            ) : (
              <div className="lookup-head">
                <h3>Suggested Roles</h3>
                <label>
                  {" "}
                  Sorry we didn't find any suggested role for you we will be
                  able to suggest once we are more aware of you.
                </label>
              </div>
            )}
            {Skills && Skills.length > 0 ? (
              <div>
                <div className="lookup-head">
                  <h3>Suggested Skills</h3>
                  <label>
                    {" "}
                    We would like to suggest following skills for your resume
                  </label>
                </div>
                <div className="settings-container">
                  {Skills &&
                    Skills.map((item) => (
                      <label
                        test-data-id="resume-edit-suggested-role-item"
                        className="draggable"
                        onClick={() =>
                          this.extractedSettingUpdate(LOOKUPTYPES.SKILL, item)
                        }
                        key={item.Id}
                      >
                        {item.LookupValue}
                      </label>
                    ))}
                </div>
              </div>
            ) : (
              <div className="lookup-head">
                <h3>Suggested Skills</h3>
                <label>
                  {" "}
                  Sorry we didn't find any suggested skills for you we will be
                  able to suggest once we are more aware of you.
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  let { FirstName } = user;
  FirstName =
    FirstName !== undefined ? FirstName : sessionStorage.getItem("userName");
  return { labels, FirstName };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(withRouter(ResumeSuggestor));
