import { StorageService } from ".";
import axios from "axios";
// const BASE_URL = process.env.REACT_APP_API_URL;
// const BASE_URL = "https://stageapp.prodoo.com/WebAPI/api/";
 const BASE_URL = "/webapi/api/";

class AuthService {
  static setDefaultAuthHeaders = () => {
    axios.defaults.headers.common[".ASPXAUTH"] = StorageService.getToken();
  };

  static setDefaults = () => {
    axios.defaults.baseURL = BASE_URL;
    AuthService.setDefaultAuthHeaders();
  };

  static isLoggedIn = () => StorageService.tokenExists();

  static clearToken = () => StorageService.clearToken();
  static clearUser = () => StorageService.clearUser();
}
export { AuthService };
