import React from "react";
import {
  Column,
  Button,
  Select,
  EmptyInfo,
  LoadingMaskRow,
} from "../../../../common";
import {
  getAllSearcherCompaniesApi,
  getAllFlowsApi,
  getAllFlowGroupsApi,
  getAllUserCreatedFlowsAndFlowGroupsApi,
  getAllFlowItemsApi,
  getAllFlowGroupItemsApi,
  getCompanyUsersApi,
  addFlowApi,
  updateFlowApi,
  addFlowItemApi,
  updateFlowItemApi,
  deleteFlowApi,
  deleteFlowItemApi,
  addFlowGroupApi,
  updateFlowGroupApi,
  deleteFlowGroupItemApi,
  addFlowGroupItemApi,
  deleteFlowGroupApi,
} from "../../embarkApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
import "./flows.scss";
import Flows from "./Flows";
import FlowGroups from "./FlowGroups";
import FlowItems from "./FlowItems";
import UserFlowAndFlowGroups from "./UserFlowAndFlowGroups";
import { CustomInputNew } from "../../../Tictell/views/components/CustomInput";
import { isEmpty } from "lodash";

const FLOW = "FLOW";
const FLOWGROUP = "FLOWGROUP";
const USERFLOWANDFLOWGROUP = "USERFLOWANDFLOWGROUP";
const FLOWITEM = "FLOWITEM";
const FLOWGROUPITEM = "FLOWGROUPITEM";

const addOptions = {
  FLOW: 1,
  FLOWGROUP: 2,
  USERFLOW: 3,
  FLOWITEM: 4,
  FLOWGROUPITEM: 5,
};

class ManageFlows extends React.Component {
  state = {
    options: [
      { label: "", value: 1 },
      { label: "", value: 2 },
      { label: "", value: 3 },
    ],
    isCollapsed1: false,
    isCollapsed2: false,
    isCollapsed3: true,
    isCollapsed4: true,
    isCollapsed5: true,
    isCollapsed6: true,
    isCollapsed7: true,
    allFlows: [],
    allFlowGroups: [],
    allUserFlowsAndGroups: [],
    allWorkPlaces: [],
    currentAdd: 1,
    islistloading: true,
  };
  componentDidMount() {
    const {
      EMBARK_MYFLOWS_AND_GROUPFLOWS,
      EMBARK_FLOWS,
      EMBARK_FLOWS_GROUPS,
    } = this.props.labels;
    this.setState({
      options: [
        { label: EMBARK_MYFLOWS_AND_GROUPFLOWS, value: 3 },
        { label: EMBARK_FLOWS, value: 1 },
        { label: EMBARK_FLOWS_GROUPS, value: 2 },
      ],
      selectedOption: { value: 3, label: EMBARK_MYFLOWS_AND_GROUPFLOWS },
    });
    getAllSearcherCompaniesApi()
      .then((res) => {
        const newArray = res.items;
        // .slice(0);
        // newArray.splice(res.items,0,{UserCompanyId : 0,CompanyName:"All Workplaces" })
        this.setState({
          allWorkPlaces: newArray.map((item) => ({
            ...item,
            value: item.UserCompanyId,
            label: item.CompanyName,
          })),
        });
        this.handleSelectChange("selectedWorkplace", newArray[0]);
      })
      .catch((err) => console.log("Err ", err));
    //get all workplaces
    //get all flows and select the first flow
  }
  handleSelectChange = (name, selectedOption) => {
    this.setState({
      [`${name}`]: selectedOption,
      islistloading: false,
    });
    //get flow, group or user flows or groups as per option
    const { token } = this.props;
    let selectedOptionValue = 1;
    let slectedWorkplaceValue = 0;
    if (name == "selectedOption") {
      const { value } = selectedOption;
      const { UserCompanyId } = this.state.selectedWorkplace;
      selectedOptionValue = value;
      slectedWorkplaceValue = UserCompanyId;
    } else if (name == "selectedWorkplace") {
      const { value } = this.state.selectedOption;
      const { UserCompanyId } = selectedOption;
      selectedOptionValue = value;
      slectedWorkplaceValue = UserCompanyId;
    }
    switch (selectedOptionValue) {
      case 1:
        this.getAllFlows(slectedWorkplaceValue);
        break;
      case 2:
        this.getAllFlowGroups(slectedWorkplaceValue);
        break;
      case 3:
        this.getAllUserCreatedFlowsAndFlowGroupsApi(slectedWorkplaceValue);
        break;
    }
  };
  getAllFlows = (UserCompanyId) => {
    const { token } = this.props;
    getAllFlowsApi(UserCompanyId, token)
      .then((res) => {
        this.setState({
          allFlows: res.items,
          islistloading: false,
        });
        this.handleItemClick(FLOW, res.items[0]);
      })
      .catch((err) => console.log("Err ", err));
  };
  getAllFlowGroups(UserCompanyId) {
    const { token } = this.props;
    getAllFlowGroupsApi(UserCompanyId, token)
      .then((res) => {
        this.setState({
          allFlowGroups: res.items,
          islistloading: false,
        });
        this.handleItemClick(FLOWGROUP, res.items[0]);
      })
      .catch((err) => console.log("Err ", err));
  }
  getAllUserCreatedFlowsAndFlowGroupsApi(UserCompanyId) {
    const { token } = this.props;
    getAllUserCreatedFlowsAndFlowGroupsApi(UserCompanyId, token)
      .then((res) => {
        this.setState({
          allUserFlowsAndGroups: res.items,
          islistloading: false,
        });
        this.handleItemClick(USERFLOWANDFLOWGROUP, res.items[0]);
      })
      .catch((err) => console.log("Err ", err));
  }
  handleItemClick = (type, selectedOption) => {
    const { token } = this.props;
    switch (type) {
      case FLOW: {
        if (!selectedOption) {
          this.setState({
            selectedFlow: {},
            selectedFlowGroup: {},
          });
          return;
        }
        if (selectedOption.FlowId) {
          this.setState({
            selectedFlowGroup: {
              ...this.state.selectedFlowGroup,
              FlowGroupItems: this.state.selectedFlowGroup.FlowGroupItems.map(
                (item) => ({
                  ...item,
                  isActive: selectedOption.FlowId == item.FlowId,
                  isSelected: selectedOption.FlowId == item.FlowId,
                })
              ),
              selectedFlow: {
                ...selectedOption,
              },
            },
          });
        } else {
          this.setState({
            selectedFlowGroup: {},
          });
          getCompanyUsersApi(selectedOption.CompanyId).then((res) => {
            const otherFlows = this.state.allFlows
              .filter(
                (x) =>
                  x.Id != selectedOption.Id &&
                  x.CompanyId == selectedOption.CompanyId
              )
              .map((k) => ({ ...k, value: k.Id, label: k.Title }));
            otherFlows.unshift({ value: -1, label: "None", LinkedFlowId: -1 });
            selectedOption = {
              ...selectedOption,
              WorkplaceUsers: res.items.map((user) => ({
                ...user,
                value: user.UserId,
                label: user.UserName,
              })),
              WorkplaceOtherFlows: otherFlows,
              LinkedFlowId: !selectedOption.LinkedFlowId
                ? -1
                : selectedOption.LinkedFlowId,
            };
            this.getFlowItems(selectedOption, token);
          });
        }
        break;
      }
      case FLOWGROUP: {
        if (!selectedOption) {
          this.setState({
            selectedFlow: {},
            selectedFlowGroup: {},
          });
          return;
        }
        getCompanyUsersApi(selectedOption.CompanyId).then((res) => {
          const otherFlowGroups = this.state.allFlowGroups
            .filter(
              (x) =>
                x.Id != selectedOption.Id &&
                x.CompanyId == selectedOption.CompanyId
            )
            .map((k) => ({ ...k, value: k.Id, label: k.Title }));
          otherFlowGroups.unshift({
            value: -1,
            label: "None",
            LinkedFlowId: -1,
          });

          selectedOption = {
            ...selectedOption,
            WorkplaceUsers: res.items.map((user) => ({
              ...user,
              value: user.UserId,
              label: user.UserName,
            })),
            WorkplaceOtherFlowGroups: otherFlowGroups,
          };
          this.getFlowGroupItems(selectedOption, token);
        });
        break;
      }
      case USERFLOWANDFLOWGROUP: {
        this.setState({
          allUserFlowsAndGroups: this.state.allUserFlowsAndGroups.map(
            (item) => ({
              ...item,
              isActive: selectedOption.Id == item.Id,
              isSelected: selectedOption.Id == item.Id,
            })
          ),
          selectedFlow: {},
          selectedFlowGroup: {},
        });
        if (selectedOption.IsGroup) {
          this.handleItemClick(FLOWGROUP, selectedOption);
        } else {
          this.handleItemClick(FLOW, selectedOption);
        }
        break;
      }
      case FLOWITEM: {
        const otherFlowItems = this.state.selectedFlow.FlowItems.filter(
          (x) => x.Id != selectedOption.Id && x.FlowId == selectedOption.FlowId
        ).map((k) => ({ ...k, value: k.Id, label: k.Title }));
        otherFlowItems.unshift({
          value: -1,
          label: "None",
          LinkedFlowItemId: -1,
        });
        this.setState({
          selectedFlow: {
            ...this.state.selectedFlow,
            FlowItems: this.state.selectedFlow.FlowItems.map((item) => ({
              ...item,
              isActive: selectedOption.Id == item.Id,
              isSelected: selectedOption.Id == item.Id,
            })),
            selectedFlowItem: {
              ...selectedOption,
              LinkedFlowItemId:
                selectedOption && selectedOption.LinkedFlowItemId
                  ? selectedOption.LinkedFlowItemId
                  : -1,
              FlowOtherItems: otherFlowItems,
            },
          },
        });
        break;
      }
    }
  };
  getFlowGroupItems(selectedOption, token) {
    getAllFlowGroupItemsApi(selectedOption.Id, token)
      .then((res) => {
        selectedOption = {
          ...selectedOption,
          FlowGroupItems: res.items,
        };
        getAllFlowsApi(selectedOption.CompanyId, token)
          .then((response) => {
            selectedOption = {
              ...selectedOption,
              FlowsToAdd: response.items.filter(
                (x) =>
                  !selectedOption.FlowGroupItems.find((k) => k.FlowId == x.Id)
              ),
            };
            this.setState({
              selectedFlowGroup: selectedOption,
              allFlowGroups: this.state.allFlowGroups.map((item) => ({
                ...item,
                isActive: selectedOption.Id == item.Id,
                isSelected: selectedOption.Id == item.Id,
              })),
              selectedFlow: {},
              isCollapsed2: false,
              isCollapsed4: false,
              isCollapsed5: false,
            });
            //select first item
            // this.handleItemClick(FLOW,res.items[0]);
          })
          .catch((err) => console.log("Err ", err));
      })
      .catch((err) => console.log("Err ", err));
  }
  getFlowItems(selectedOption, token) {
    getAllFlowItemsApi(selectedOption.Id || selectedOption.FlowId, token)
      .then((res) => {
        this.setState({
          selectedFlow: {
            ...selectedOption,
            FlowItems: res.items,
          },
          allFlows: this.state.allFlows.map((item) => ({
            ...item,
            isActive: selectedOption.Id == item.Id,
            isSelected: selectedOption.Id == item.Id,
          })),
          selectedFlowGroup: {},
          isCollapsed3: false,
          isCollapsed4: false,
          isCollapsed7: false,
        });
        if (res.items.length < 1) {
          this.handleAddBtnClick(4);
        } else {
          //select first item
          this.handleItemClick(FLOWITEM, res.items[0]);
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  handleAddBtnClick = (option) => {
    switch (option) {
      case addOptions.FLOW: {
        getCompanyUsersApi(this.state.selectedWorkplace.UserCompanyId).then(
          (res) => {
            const otherFlows = this.state.allFlows
              .filter(
                (x) => x.CompanyId == this.state.selectedWorkplace.UserCompanyId
              )
              .map((k) => ({ ...k, value: k.Id, label: k.Title }));
            otherFlows.unshift({ value: -1, label: "None", LinkedFlowId: -1 });
            const workplaceUsers = res.items.map((user) => ({
              ...user,
              value: user.UserId,
              label: user.UserName,
            }));
            let selectedFlow = {
              Id: 0,
              WorkplaceUsers: workplaceUsers,
              LinkedFlowId: -1,
              OwnerId: workplaceUsers[0].UserId,
              WorkplaceOtherFlows: otherFlows,
              CompanyId: this.state.selectedWorkplace.UserCompanyId,
              OwnerId: workplaceUsers[0].UserId,
              Description: "",
            };
            this.setState({
              selectedFlow,
              allFlows: this.state.allFlows.map((a) => ({
                ...a,
                isActive: false,
                isSelected: false,
              })),
              isCollapsed3: false,
            });
          }
        );
        break;
      }
      case addOptions.FLOWGROUP: {
        getCompanyUsersApi(this.state.selectedWorkplace.UserCompanyId).then(
          (res) => {
            const workplaceUsers = res.items.map((user) => ({
              ...user,
              value: user.UserId,
              label: user.UserName,
            }));
            let selectedFlowGroup = {
              Id: 0,
              WorkplaceUsers: workplaceUsers,
              CompanyId: this.state.selectedWorkplace.UserCompanyId,
              OwnerId: workplaceUsers[0].UserId,
            };
            this.setState({
              selectedFlowGroup,
              allFlowGroups: this.state.allFlowGroups.map((a) => ({
                ...a,
                isActive: false,
                isSelected: false,
              })),
              isCollapsed3: false,
            });
          }
        );
        break;
      }
      case addOptions.USERFLOW: {
        break;
      }
      case addOptions.FLOWITEM: {
        const otherFlowItems =
          this.state.selectedFlow.FlowItems &&
          this.state.selectedFlow.FlowItems.filter(
            (x) => x.FlowId == this.state.selectedFlow.Id
          ).map((k) => ({ ...k, value: k.Id, label: k.Title }));
        otherFlowItems.unshift({
          value: -1,
          label: "None",
          LinkedFlowItemId: -1,
        });
        this.setState({
          isCollapsed7: false,
          selectedFlow: {
            ...this.state.selectedFlow,
            selectedFlowItem: {
              Id: 0,
              FlowId: this.state.selectedFlow.Id,
              FlowOtherItems: otherFlowItems || [],
              OwnerId: this.state.selectedFlow.OwnerId,
              LinkedFlowItemId: -1,
            },
            FlowItems:
              this.state.selectedFlow.FlowItems &&
              this.state.selectedFlow.FlowItems.map((a) => ({
                ...a,
                isActive: false,
                isSelected: false,
              })),
          },
        });
        break;
      }
      case addOptions.FLOWGROUPITEM: {
        break;
      }
    }
  };
  handleInputSelectChange = (type, name, selectedOption) => {
    if (type == FLOW) {
      let selectedFlow = {
        ...this.state.selectedFlow,
        [`${name}`]: selectedOption.value,
      };
      this.setState({
        selectedFlow,
      });
      if (name == "CompanyId") {
        const otherFlows = this.state.allFlows
          .filter((x) => x.CompanyId == selectedOption.value)
          .map((k) => ({ ...k, value: k.Id, label: k.Title }));
        otherFlows.unshift({ value: -1, label: "None", LinkedFlowId: -1 });
        getCompanyUsersApi(selectedOption.value).then((res) => {
          const workplaceUsers = res.items.map((user) => ({
            ...user,
            value: user.UserId,
            label: user.UserName,
          }));
          selectedFlow = {
            ...selectedFlow,
            WorkplaceUsers: workplaceUsers,
            WorkplaceOtherFlows: otherFlows,
            OwnerId: workplaceUsers[0].UserId,
            LinkedFlowId: selectedFlow.LinkedFlowId,
          };
          this.setState({
            selectedFlow,
          });
          this.updateAndSaveFlow(selectedFlow);
        });
      } else {
        this.updateAndSaveFlow(selectedFlow);
      }
    } else if (type == FLOWITEM) {
      let selectedFlowItem = {
        ...this.state.selectedFlow.selectedFlowItem,
        [`${name}`]: selectedOption.value,
      };
      this.setState({
        selectedFlow: {
          ...this.state.selectedFlow,
          selectedFlowItem,
        },
      });
      this.updateAndSaveFlowItem(selectedFlowItem);
    } else if (type == FLOWGROUP) {
      let selectedFlowGroup = {
        ...this.state.selectedFlowGroup,
        [`${name}`]: selectedOption.value,
      };
      this.setState({
        selectedFlowGroup,
      });
      if (name == "CompanyId") {
        const otherFlows = this.state.allFlowGroups
          .filter((x) => x.CompanyId == selectedOption.value)
          .map((k) => ({ ...k, value: k.Id, label: k.Title }));
        otherFlows.unshift({ value: -1, label: "None", LinkedFlowId: -1 });
        getCompanyUsersApi(selectedOption.value).then((res) => {
          const workplaceUsers = res.items.map((user) => ({
            ...user,
            value: user.UserId,
            label: user.UserName,
          }));
          selectedFlowGroup = {
            ...selectedFlowGroup,
            WorkplaceUsers: workplaceUsers,
            WorkplaceOtherFlowGroups: otherFlows,
            OwnerId: workplaceUsers[0].UserId,
          };
          this.setState({
            selectedFlowGroup,
          });
          this.updateAndSaveFlowGroup(selectedFlowGroup);
        });
      } else {
        this.updateAndSaveFlowGroup(selectedFlowGroup);
      }
    }
  };
  updateAndSaveFlowGroup = (selectedFlowGroup) => {
    selectedFlowGroup = {
      ...selectedFlowGroup,
      isActive: true,
      isSelected: true,
    };
    const { Title, Description, CompanyId, OwnerId } = selectedFlowGroup;
    if (Title && Description && CompanyId && OwnerId) {
      const { token } = this.props;
      if (selectedFlowGroup.Id == 0) {
        addFlowGroupApi(selectedFlowGroup, token)
          .then((res) => {
            if (res.success) {
              selectedFlowGroup = {
                ...selectedFlowGroup,
                Id: res.items.Id,
              };
              this.setState({
                selectedFlowGroup,
                allFlowGroups: this.state.allFlowGroups.concat(
                  selectedFlowGroup
                ),
              });
              const info = {
                status: "success",
                message: this.props.labels.EMBARK_FLOWGROUP_ADDED_SUCCESS,
              };
              this.props.notificationAction(info);
            } else {
              const info = {
                status: "error",
                message: res.message,
              };
              this.props.notificationAction(info);
            }
          })
          .catch((err) => console.log("Err ", err));
      } else {
        updateFlowGroupApi(selectedFlowGroup, token)
          .then((res) => {
            if (res.success) {
              this.setState({
                allFlowGroups: this.state.allFlowGroups.map((x) =>
                  x.Id == selectedFlowGroup.Id ? selectedFlowGroup : x
                ),
              });
              const info = {
                status: "success",
                message: this.props.labels.EMBARK_FLOWGORUP_UPDATED_SUCCESS,
              };
              this.props.notificationAction(info);
            } else {
              const info = {
                status: "error",
                message: res.message,
              };
              this.props.notificationAction(info);
            }
          })
          .catch((err) => console.log("Err ", err));
      }
    }
  };
  updateAndSaveFlow = (selectedFlow) => {
    selectedFlow = {
      ...selectedFlow,
      isActive: true,
      isSelected: true,
    };
    const {
      Title,
      Description,
      CompanyId,
      OwnerId,
      LinkedFlowId,
    } = selectedFlow;
    if (Title && Description && CompanyId && OwnerId && LinkedFlowId) {
      const { token } = this.props;
      if (selectedFlow.Id == 0) {
        addFlowApi(selectedFlow, token)
          .then((res) => {
            if (res.success) {
              selectedFlow = {
                ...selectedFlow,
                Id: res.items.Id,
                FlowItems: [],
              };
              this.setState({
                selectedFlow,
                allFlows: this.state.allFlows.concat(selectedFlow),
              });
              const info = {
                status: "success",
                message: this.props.labels.EMBARK_FLOW_ADDED_SUCCESS,
              };
              this.props.notificationAction(info);
            } else {
              const info = {
                status: "error",
                message: res.message,
              };
              this.props.notificationAction(info);
            }
          })
          .catch((err) => console.log("Err ", err));
      } else {
        updateFlowApi(selectedFlow, token)
          .then((res) => {
            if (res.success) {
              this.setState({
                allFlows: this.state.allFlows.map((x) =>
                  x.Id == selectedFlow.Id ? selectedFlow : x
                ),
              });
              const info = {
                status: "success",
                message: this.props.labels.EMBARK_FLOW_UPDATED_SUCCESS,
              };
              this.props.notificationAction(info);
            } else {
              const info = {
                status: "error",
                message: res.message,
              };
              this.props.notificationAction(info);
            }
          })
          .catch((err) => console.log("Err ", err));
      }
    }
  };
  updateAndSaveFlowItem = (selectedFlowItem) => {
    selectedFlowItem = {
      ...selectedFlowItem,
      isActive: true,
      isSelected: true,
    };
    const { Title, Description, FlowId, OwnerId } = selectedFlowItem;
    if (Title && Description && FlowId && OwnerId) {
      const { token } = this.props;
      if (selectedFlowItem.Id == 0) {
        addFlowItemApi(selectedFlowItem, token)
          .then((res) => {
            if (res.success) {
              selectedFlowItem = {
                ...selectedFlowItem,
                Id: res.items.Id,
                isActive: true,
                isSelected: true,
              };
              this.setState({
                selectedFlow: {
                  ...this.state.selectedFlow,
                  selectedFlowItem,
                  FlowItems: this.state.selectedFlow.FlowItems.concat(
                    selectedFlowItem
                  ),
                },
              });
              const info = {
                status: "success",
                message: this.props.labels.EMBARK_FLOWITEM_ADDED_SUCCESS,
              };
              this.props.notificationAction(info);
            } else {
              const info = {
                status: "error",
                message: res.message,
              };
              this.props.notificationAction(info);
            }
          })
          .catch((err) => console.log("Err ", err));
      } else {
        updateFlowItemApi(selectedFlowItem, token)
          .then((res) => {
            if (res.success) {
              this.setState({
                selectedFlow: {
                  ...this.state.selectedFlow,
                  FlowItems: this.state.selectedFlow.FlowItems.map((x) =>
                    x.Id == selectedFlowItem.Id ? selectedFlowItem : x
                  ),
                },
              });
              const info = {
                status: "success",
                message: this.props.labels.EMBARK_FLOWITEM_UPDATED_SUCCESS,
              };
              this.props.notificationAction(info);
            } else {
              const info = {
                status: "error",
                message: res.message,
              };
              this.props.notificationAction(info);
            }
          })
          .catch((err) => console.log("Err ", err));
      }
    }
  };
  handleInputChange = (type, name, value) => {
    if (type == FLOW) {
      const selectedFlow = {
        ...this.state.selectedFlow,
        [`${name}`]: value.target.value,
      };
      this.setState({
        selectedFlow,
      });
    } else if (type == FLOWITEM) {
      const selectedFlowItem = {
        ...this.state.selectedFlow.selectedFlowItem,
        [`${name}`]: value.target.value,
      };
      this.setState({
        selectedFlow: {
          ...this.state.selectedFlow,
          selectedFlowItem: selectedFlowItem,
        },
      });
    } else if (type == FLOWGROUP) {
      const selectedFlowGroup = {
        ...this.state.selectedFlowGroup,
        [`${name}`]: value.target.value,
      };
      this.setState({
        selectedFlowGroup,
      });
    }
  };
  handleInputBlur = (type, name, value) => {
    if (type == FLOW) {
      const { selectedFlow } = this.state;
      this.updateAndSaveFlow(selectedFlow);
    } else if (type == FLOWITEM) {
      const { selectedFlow } = this.state;
      this.updateAndSaveFlowItem(selectedFlow.selectedFlowItem);
    } else if (type == FLOWGROUP) {
      const { selectedFlowGroup } = this.state;
      this.updateAndSaveFlowGroup(selectedFlowGroup);
    }
  };
  handleFlowDelete = (item) => {
    const { token } = this.props;
    deleteFlowApi(item.Id, token)
      .then((res) => {
        if (res.success) {
          this.setState({
            allFlows: this.state.allFlows.filter((x) => x.Id != item.Id),
            selectedFlow:
              this.state.selectedFlow.Id == item.Id
                ? {}
                : this.state.selectedFlow,
          });
          const info = {
            status: "success",
            message: this.props.labels.EMBARK_FLOW_DELETED_SUCCESS,
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            status: "error",
            message: res.message,
          };
          this.props.notificationAction(info);
        }
      })
      .catch((err) => console.log("Err ", err));
  };
  handleFlowGroupDelete = (item) => {
    const { token } = this.props;
    deleteFlowGroupApi(item.Id, token)
      .then((res) => {
        if (res.success) {
          this.setState({
            allFlowGroups: this.state.allFlowGroups.filter(
              (x) => x.Id != item.Id
            ),
            selectedFlowGroup:
              this.state.selectedFlowGroup.Id == item.Id
                ? {}
                : this.state.selectedFlowGroup,
          });
          const info = {
            status: "success",
            message: this.props.labels.EMBARK_FLOWGROUP_DELETED_SUCCESS,
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            status: "error",
            message: res.message,
          };
          this.props.notificationAction(info);
        }
      })
      .catch((err) => console.log("Err ", err));
  };
  handleFlowItemDelete = (item) => {
    const { token } = this.props;
    deleteFlowItemApi(item.Id, token)
      .then((res) => {
        if (res.success) {
          this.setState({
            selectedFlow: {
              ...this.state.selectedFlow,
              FlowItems: this.state.selectedFlow.FlowItems.filter(
                (x) => x.Id != item.Id
              ),
              selectedFlowItem:
                this.state.selectedFlow.selectedFlowItem.Id == item.Id
                  ? {}
                  : this.state.selectedFlow.selectedFlowItem,
            },
          });
          const info = {
            status: "success",
            message: this.props.labels.EMBARK_FLOW_ITEM_DELETED_SUCCESS,
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            status: "error",
            message: res.message,
          };
          this.props.notificationAction(info);
        }
      })
      .catch((err) => console.log("Err ", err));
  };
  handleFlowAddToGroup = (item) => {
    const flowGroupItem = {
      FlowId: item.Id,
      FlowGroupId: this.state.selectedFlowGroup.Id,
    };
    addFlowGroupItemApi(flowGroupItem, this.props.token)
      .then((res) => {
        if (res.success) {
          item = {
            ...item,
            FlowGroupItemId: res.items.FlowGroupItemId,
          };
          this.setState({
            selectedFlowGroup: {
              ...this.state.selectedFlowGroup,
              FlowGroupItems: this.state.selectedFlowGroup.FlowGroupItems.concat(
                item
              ),
              FlowsToAdd: this.state.selectedFlowGroup.FlowsToAdd.filter(
                (k) => k.Id != item.Id
              ),
            },
          });
          const info = {
            status: "success",
            message: this.props.labels.EMBARK_FLOW_ADDED_TO_FLOWGROUP,
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            status: "error",
            message: res.message,
          };
          this.props.notificationAction(info);
        }
      })
      .catch((err) => console.log("Err ", err));
  };
  handleRemoveFlowFromGroup = (item) => {
    deleteFlowGroupItemApi(item.FlowGroupItemId, this.props.token)
      .then((res) => {
        if (res.success) {
          this.setState({
            selectedFlowGroup: {
              ...this.state.selectedFlowGroup,
              FlowsToAdd: this.state.selectedFlowGroup.FlowsToAdd.concat(item),
              FlowGroupItems: this.state.selectedFlowGroup.FlowGroupItems.filter(
                (k) => k.FlowGroupItemId != item.FlowGroupItemId
              ),
            },
          });
          const info = {
            status: "success",
            message: this.props.labels.EMBARK_FLOW_DELETED_FROM_FLOWGROUP,
          };
          this.props.notificationAction(info);
        } else {
          const info = {
            status: "error",
            message: res.message,
          };
          this.props.notificationAction(info);
        }
      })
      .catch((err) => console.log("Err ", err));
  };
  render() {
    const {
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      isCollapsed4,
      isCollapsed5,
      isCollapsed6,
      isCollapsed7,
      allWorkPlaces,
      allFlows,
      allFlowGroups,
      allUserFlowsAndGroups,
      selectedOption,
      selectedWorkplace,
      selectedFlow,
      selectedFlowGroup,
      islistloading,
    } = this.state;

    const { labels, isHelpActive, UserId } = this.props;
    return (
      <div className="manageflows-view">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={"Flows and Flow Groups"}
            onClick={() => this.setState({ isCollapsed1: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn1"
          />
          <Column.Head>
            <div className="heading">{labels.EMBARK_FLOWS_AND_FLOW_GROUPS}</div>
            <Button
              className="collapseBtn"
              testId="collapasebtn1"
              onClick={() => this.setState({ isCollapsed1: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          {!isCollapsed1 && (
            <div>
              <Select
                readonly={true}
                searchable={false}
                testId={"collaborationDropdown"}
                name="AllPresentation"
                className="select-input select-ipros"
                value={
                  selectedWorkplace && {
                    value: selectedWorkplace.UserCompanyId,
                    label: selectedWorkplace.CompanyName,
                  }
                }
                className={`select-input`}
                placeholder={labels.embarkWorkplaceSelectPlaceholder}
                onChange={(selectedOption) =>
                  this.handleSelectChange("selectedWorkplace", selectedOption)
                }
                options={allWorkPlaces.map((company) => ({
                  ...company,
                  value: company.UserCompanyId,
                  label: company.CompanyName,
                }))}
                clearable={false}
              />
              <Select
                readonly={true}
                searchable={false}
                name="AllPresentation"
                className="select-input select-flows-type"
                value={selectedOption}
                className={`select-input`}
                placeholder={labels.presentPrimaryRolePlaceholder}
                onChange={(selectedOption) =>
                  this.handleSelectChange("selectedOption", selectedOption)
                }
                options={this.state.options}
                clearable={false}
              />
            </div>
          )}
          <Column.Body>
            {islistloading ? (
              <LoadingMaskRow />
            ) : (
              <React.Fragment>
                {selectedOption && selectedOption.value == 1 && (
                  <div>
                    {allFlows.length > 0 ? (
                      <Flows
                        flows={allFlows}
                        handleItemClick={this.handleItemClick}
                        handleFlowDelete={this.handleFlowDelete}
                        isDelete={true}
                        isIcon={true}
                        labels={labels}
                      />
                    ) : (
                      <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                    )}
                  </div>
                )}
                {selectedOption && selectedOption.value == 2 && (
                  <div>
                    {allFlowGroups.length > 0 ? (
                      <FlowGroups
                        flowGroups={allFlowGroups}
                        handleItemClick={this.handleItemClick}
                        isDelete={true}
                        handleFlowGroupDelete={this.handleFlowGroupDelete}
                        labels={labels}
                      />
                    ) : (
                      <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                    )}
                  </div>
                )}
                {selectedOption && selectedOption.value == 3 && (
                  <div>
                    {allUserFlowsAndGroups.length > 0 ? (
                      <UserFlowAndFlowGroups
                        flowOrFlowGroups={allUserFlowsAndGroups}
                        handleItemClick={this.handleItemClick}
                      />
                    ) : (
                      <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                    )}
                  </div>
                )}
                {selectedOption &&
                  (selectedOption.value == 1 || selectedOption.value == 2) && (
                    <Button
                      testId={`add-record-btn`}
                      mainClassName={"action-button-wrapper"}
                      className={`add-btn`}
                      onClick={() =>
                        this.handleAddBtnClick(selectedOption.value)
                      }
                      isHelpActive={isHelpActive}
                      tooltipHelp={"Click this button to reject timesheet"}
                      testIdHelp={"add-reject-btn-help"}
                    />
                  )}
              </React.Fragment>
            )}
          </Column.Body>
        </Column>
        {!isEmpty(selectedFlowGroup) && (
          <Column collapse={isCollapsed2} className="col-2">
            <Column.Collapsed
              text={labels.EMBARK_FLOW_GROUP_DETAILS}
              onClick={() => this.setState({ isCollapsed2: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipSnapshotExpand}
              tooltipHelp={labels.hlptxtSnapshotExpand}
              tooltipPlace="left"
              testId="expandbtn2"
            />
            <Column.Head>
              <div className="heading">{labels.EMBARK_FLOW_GROUP_DETAILS}</div>
              <Button
                className="collapseBtn"
                testId="collapsebtn2"
                onClick={() => this.setState({ isCollapsed2: true })}
                tooltipButton={labels.ToolTipSnapshotCollapse}
                tooltipHelp={labels.hlptxtSnapshotCollapse}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
              />
            </Column.Head>
            <Column.Body>
              <div className="flow-detail">
                <CustomInputNew
                  label={labels.EMBARK_FLOW_GROUP}
                  value={selectedFlowGroup && selectedFlowGroup.Title}
                  validate={true}
                >
                  <input
                    type="text"
                    autoComplete="off"
                    value={(selectedFlowGroup && selectedFlowGroup.Title) || ""}
                    name="Title"
                    className={`input-text `}
                    placeholder={labels.EMBARK_ENTER_TITLE}
                    test-data-id="input-text_flowgroup-title"
                    onChange={(value) =>
                      this.handleInputChange(FLOWGROUP, "Title", value)
                    }
                    onBlur={(value) =>
                      this.handleInputBlur(FLOWGROUP, "Title", value)
                    }
                  />
                </CustomInputNew>
                <CustomInputNew
                  label={labels.EMBARK_DESCRIPTION}
                  value={selectedFlowGroup && selectedFlowGroup.Description}
                  validate={true}
                >
                  <textarea
                    type="text"
                    autoComplete="off"
                    name="Description"
                    className={`input-text `}
                    value={
                      (selectedFlowGroup && selectedFlowGroup.Description) || ""
                    }
                    placeholder={labels.EMBARK_ENTER_DESCRIPTION}
                    test-data-id="input-text-description-title"
                    onChange={(value) =>
                      this.handleInputChange(FLOWGROUP, "Description", value)
                    }
                    onBlur={(value) =>
                      this.handleInputBlur(FLOWGROUP, "Description", value)
                    }
                  >
                    {(selectedFlowGroup && selectedFlowGroup.Description) || ""}
                  </textarea>
                </CustomInputNew>
                <CustomInputNew label={labels.EMBARK_WORKPLACE}>
                  <Select
                    readonly={true}
                    searchable={false}
                    testId={"collaborationDropdown"}
                    name="AllPresentation"
                    className="select-input"
                    value={
                      selectedFlowGroup &&
                      (selectedFlowGroup.Id == 0
                        ? selectedWorkplace.UserCompanyId
                        : selectedFlowGroup.CompanyId)
                    }
                    className={`select-input`}
                    placeholder={labels.EMBARK_SELECT_FLOW}
                    onChange={(selectedOption) =>
                      this.handleInputSelectChange(
                        FLOWGROUP,
                        "CompanyId",
                        selectedOption
                      )
                    }
                    options={allWorkPlaces.filter((x) => x.value != 0)}
                    clearable={false}
                    disabled={selectedFlowGroup && selectedFlowGroup.Id == 0}
                  />
                </CustomInputNew>
                <CustomInputNew label={labels.EMBARK_OWNER}>
                  <Select
                    readonly={true}
                    searchable={false}
                    testId={"collaborationDropdown"}
                    name="AllPresentation"
                    className="select-input"
                    value={selectedFlowGroup && selectedFlowGroup.OwnerId}
                    className={`select-input`}
                    placeholder={labels.EMBARK_SELECT_FLOW_OWNER}
                    onChange={(selectedOption) =>
                      this.handleInputSelectChange(
                        FLOWGROUP,
                        "OwnerId",
                        selectedOption
                      )
                    }
                    options={
                      selectedFlowGroup && selectedFlowGroup.WorkplaceUsers
                    }
                    clearable={false}
                  />
                </CustomInputNew>
              </div>
            </Column.Body>
          </Column>
        )}
        {!isEmpty(selectedFlow) && (
          <Column collapse={isCollapsed3} className="col-2">
            <Column.Collapsed
              text={"Flow Detail"}
              onClick={() => this.setState({ isCollapsed3: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipSnapshotExpand}
              tooltipHelp={labels.hlptxtSnapshotExpand}
              tooltipPlace="left"
              testId="expandbtn3"
            />
            <Column.Head>
              <div className="heading">{labels.embark_flow_details}</div>
              <Button
                className="collapseBtn"
                testId="collapasebtn3"
                onClick={() => this.setState({ isCollapsed3: true })}
                tooltipButton={labels.ToolTipSnapshotCollapse}
                tooltipHelp={labels.hlptxtSnapshotCollapse}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
              />
            </Column.Head>
            <Column.Body>
              <div className="flow-detail">
                <CustomInputNew
                  value={selectedFlow && selectedFlow.Title}
                  validate={true}
                  label={labels.EMBARK_FLOWS}
                >
                  <input
                    type="text"
                    autoComplete="off"
                    value={(selectedFlow && selectedFlow.Title) || ""}
                    name="Title"
                    className={`input-text `}
                    placeholder={labels.EMBARK_ENTER_TITLE}
                    test-data-id="input-text-flow-title"
                    onChange={(value) =>
                      this.handleInputChange(FLOW, "Title", value)
                    }
                    onBlur={(value) =>
                      this.handleInputBlur(FLOW, "Title", value)
                    }
                  />
                </CustomInputNew>
                <CustomInputNew
                  value={selectedFlow && selectedFlow.Description}
                  validate={true}
                  label={labels.EMBARK_DESCRIPTION}
                >
                  <textarea
                    type="text"
                    autoComplete="off"
                    name="Description"
                    value={(selectedFlow && selectedFlow.Description) || ""}
                    className={`input-text `}
                    placeholder={labels.EMBARK_ENTER_DESCRIPTION}
                    test-data-id="input-text-flow-description"
                    onChange={(value) =>
                      this.handleInputChange(FLOW, "Description", value)
                    }
                    onBlur={(value) =>
                      this.handleInputBlur(FLOW, "Description", value)
                    }
                  >
                    {selectedFlow && selectedFlow.Description
                      ? selectedFlow.Description
                      : ""}
                  </textarea>
                </CustomInputNew>
                <CustomInputNew label={labels.EMBARK_WORKPLACE}>
                  <Select
                    readonly={true}
                    searchable={false}
                    testId={"collaborationDropdown"}
                    name="AllPresentation"
                    className="select-input"
                    value={
                      selectedFlow &&
                      (selectedFlow.Id == 0
                        ? selectedWorkplace.UserCompanyId
                        : selectedFlow.CompanyId)
                    }
                    className={`select-input`}
                    placeholder={labels.EMBARK_SELECT_FLOW}
                    onChange={(selectedOption) =>
                      this.handleInputSelectChange(
                        FLOW,
                        "CompanyId",
                        selectedOption
                      )
                    }
                    options={allWorkPlaces.filter((x) => x.value != 0)}
                    clearable={false}
                    disabled={selectedFlow && selectedFlow.Id == 0}
                  />
                </CustomInputNew>
                <CustomInputNew label={labels.EMBARK_OWNER}>
                  <Select
                    readonly={true}
                    searchable={false}
                    testId={"collaborationDropdown"}
                    name="AllPresentation"
                    className="select-input"
                    value={selectedFlow && selectedFlow.OwnerId}
                    className={`select-input`}
                    placeholder={labels.EMBARK_SELECT_FLOW_OWNER}
                    onChange={(selectedOption) =>
                      this.handleInputSelectChange(
                        FLOW,
                        "OwnerId",
                        selectedOption
                      )
                    }
                    options={selectedFlow && selectedFlow.WorkplaceUsers}
                    clearable={false}
                  />
                </CustomInputNew>
                <CustomInputNew label={labels.EMBARK_LINK}>
                  <Select
                    readonly={true}
                    searchable={false}
                    testId={"collaborationDropdown"}
                    name="AllPresentation"
                    className="select-input"
                    value={selectedFlow && selectedFlow.LinkedFlowId}
                    className={`select-input`}
                    placeholder={labels.EMBARK_SELECT_FLOW_LINK}
                    onChange={(selectedOption) =>
                      this.handleInputSelectChange(
                        FLOW,
                        "LinkedFlowId",
                        selectedOption
                      )
                    }
                    options={selectedFlow && selectedFlow.WorkplaceOtherFlows}
                    clearable={false}
                  />
                </CustomInputNew>
              </div>
            </Column.Body>
          </Column>
        )}
        <Column collapse={isCollapsed4} className="col-2">
          <Column.Collapsed
            text={
              selectedOption && selectedOption.value == 2
                ? labels.EMBARK_FLOW
                : labels.EMBARK_FLOW_ITEMS
            }
            onClick={() => this.setState({ isCollapsed4: false })}
            isHelpActive={isHelpActive}
            tooltipButton={labels.ToolTipSnapshotExpand}
            tooltipHelp={labels.hlptxtSnapshotExpand}
            tooltipPlace="left"
            testId="expandbtn4"
          />
          <Column.Head>
            <div className="heading">
              {selectedOption && selectedOption.value == 2
                ? labels.EMBARK_ASSOCIATED_FLOWS
                : labels.EMBARK_FLOW_ITEMS}
            </div>
            <Button
              className="collapseBtn"
              testId="collapsebtn4"
              onClick={() => this.setState({ isCollapsed4: true })}
              tooltipButton={labels.ToolTipSnapshotCollapse}
              tooltipHelp={labels.hlptxtSnapshotCollapse}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
            />
          </Column.Head>
          <Column.Body>
            {selectedOption &&
              (selectedOption.value == 2 || selectedOption.value == 3) &&
              selectedFlowGroup &&
              selectedFlowGroup.FlowGroupItems && (
                <React.Fragment>
                  {selectedFlowGroup.FlowGroupItems.length > 0 ? (
                    <React.Fragment>
                      <Flows
                        flows={selectedFlowGroup.FlowGroupItems}
                        handleItemClick={() => {}}
                        isDelete={true}
                        handleFlowDelete={this.handleRemoveFlowFromGroup}
                        labels={labels}
                      />
                    </React.Fragment>
                  ) : (
                    <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                  )}
                </React.Fragment>
              )}
            {selectedOption &&
              (selectedOption.value == 1 || selectedOption.value == 3) &&
              selectedFlow &&
              selectedFlow.FlowItems &&
              (!selectedFlowGroup || !selectedFlowGroup.FlowGroupItems) && (
                <React.Fragment>
                  {selectedFlow.FlowItems.length > 0 ? (
                    <FlowItems
                      flowItems={selectedFlow.FlowItems}
                      handleItemClick={this.handleItemClick}
                      handleFlowItemDelete={this.handleFlowItemDelete}
                      labels={labels}
                    />
                  ) : (
                    <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                  )}

                  {selectedOption.value == 1 && (
                    <Button
                      testId={`add-flow-item-btn`}
                      mainClassName={"action-button-wrapper"}
                      className={`add-btn`}
                      onClick={() => this.handleAddBtnClick(4)}
                      isHelpActive={isHelpActive}
                      tooltipHelp={"Click this button to reject timesheet"}
                      testIdHelp={"add-reject-btn-help"}
                      labels={labels}
                    />
                  )}
                </React.Fragment>
              )}
          </Column.Body>
        </Column>
        {!isEmpty(selectedFlowGroup) && (
          <Column collapse={isCollapsed5} className="col-2">
            <Column.Collapsed
              text={labels.EMBARK_FLOWS_TO_ADD}
              onClick={() => this.setState({ isCollapsed5: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipSnapshotExpand}
              tooltipHelp={labels.hlptxtSnapshotExpand}
              tooltipPlace="left"
              testId="expandbtn5"
            />
            <Column.Head>
              <div className="heading">{labels.EMBARK_FLOWS_TO_ADD}</div>
              <Button
                className="collapseBtn"
                testId="collapsebtn5"
                onClick={() => this.setState({ isCollapsed5: true })}
                tooltipButton={labels.ToolTipSnapshotCollapse}
                tooltipHelp={labels.hlptxtSnapshotCollapse}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
              />
            </Column.Head>
            <Column.Body>
              {selectedFlowGroup &&
              selectedFlowGroup.FlowsToAdd &&
              selectedFlowGroup.FlowsToAdd.length > 0 ? (
                <div>
                  <Flows
                    flows={selectedFlowGroup.FlowsToAdd}
                    handleItemClick={() => {}}
                    isCheckbox={true}
                    isDelete={false}
                    isIcon={false}
                    handleCheckBoxClick={this.handleFlowAddToGroup}
                    labels={labels}
                  />
                </div>
              ) : (
                <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
              )}
            </Column.Body>
          </Column>
        )}
        {!isEmpty(selectedFlow) &&
          selectedOption &&
          (selectedOption.value == 2 || selectedOption.value == 3) &&
          selectedFlow &&
          selectedFlow.FlowItems &&
          selectedFlowGroup &&
          selectedFlowGroup.FlowGroupItems && (
            <Column collapse={isCollapsed6} className="col-2">
              <Column.Collapsed
                text={"Items"}
                onClick={() => this.setState({ isCollapsed6: false })}
                isHelpActive={isHelpActive}
                tooltipButton={labels.ToolTipSnapshotExpand}
                tooltipHelp={labels.hlptxtSnapshotExpand}
                tooltipPlace="left"
                testId="expandbtn7"
              />
              <Column.Head>
                <div className="heading">{labels.EMBARK_ITEMS}</div>
                <Button
                  className="collapseBtn"
                  testId="Collapasebtn7"
                  onClick={() => this.setState({ isCollapsed6: true })}
                  tooltipButton={labels.ToolTipSnapshotCollapse}
                  tooltipHelp={labels.hlptxtSnapshotCollapse}
                  tooltipPlace="left"
                  isHelpActive={isHelpActive}
                />
              </Column.Head>
              <Column.Body>
                <React.Fragment>
                  {selectedFlow.FlowItems.length > 0 ? (
                    <FlowItems
                      flowItems={selectedFlow.FlowItems}
                      handleItemClick={this.handleItemClick}
                      handleFlowItemDelete={this.handleFlowItemDelete}
                      labels={labels}
                    />
                  ) : (
                    <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                  )}
                  <Button
                    testId={`reject-timelog-btn`}
                    mainClassName={"action-button-wrapper"}
                    className={`add-btn`}
                    onClick={() => this.handleAddBtnClick(4)}
                    isHelpActive={isHelpActive}
                    tooltipHelp={labels.EMBARK_BTN_TO_REJECT_TIMESHEET}
                    testIdHelp={"add-reject-btn-help"}
                  />
                </React.Fragment>
              </Column.Body>
            </Column>
          )}
        {!isEmpty(selectedFlow) && (
          <Column collapse={isCollapsed7} className="col-2">
            <Column.Collapsed
              text={"Item Detail"}
              onClick={() => this.setState({ isCollapsed7: false })}
              isHelpActive={isHelpActive}
              tooltipButton={labels.ToolTipSnapshotExpand}
              tooltipHelp={labels.hlptxtSnapshotExpand}
              tooltipPlace="left"
              testId="expandbtn6"
            />
            <Column.Head>
              <div className="heading">{labels.embark_item_detail}</div>
              <Button
                className="collapseBtn"
                testId="collapasebtn6"
                onClick={() => this.setState({ isCollapsed7: true })}
                tooltipButton={labels.ToolTipSnapshotCollapse}
                tooltipHelp={labels.hlptxtSnapshotCollapse}
                tooltipPlace="left"
                isHelpActive={isHelpActive}
              />
            </Column.Head>
            <Column.Body>
              <div className="flow-detail">
                <CustomInputNew
                  label={labels.EMBARK_FLOW}
                  value={
                    selectedFlow &&
                    selectedFlow.selectedFlowItem &&
                    selectedFlow.selectedFlowItem.Title
                  }
                  validate={true}
                >
                  <input
                    type="text"
                    autoComplete="off"
                    value={
                      (selectedFlow &&
                        selectedFlow.selectedFlowItem &&
                        selectedFlow.selectedFlowItem.Title) ||
                      ""
                    }
                    name="Title"
                    className={`input-text `}
                    placeholder={labels.EMBARK_ENTER_TITLE}
                    test-data-id="input-flow-item-title"
                    onChange={(value) =>
                      this.handleInputChange(FLOWITEM, "Title", value)
                    }
                    onBlur={(value) =>
                      this.handleInputBlur(FLOWITEM, "Title", value)
                    }
                  />
                </CustomInputNew>
                <CustomInputNew
                  label={labels.EMBARK_DESCRIPTION}
                  value={
                    selectedFlow &&
                    selectedFlow.selectedFlowItem &&
                    selectedFlow.selectedFlowItem.Description
                  }
                  validate={true}
                >
                  <textarea
                    type="text"
                    autoComplete="off"
                    value={
                      (selectedFlow &&
                        selectedFlow.selectedFlowItem &&
                        selectedFlow.selectedFlowItem.Description) ||
                      ""
                    }
                    name="Description"
                    className={`input-text `}
                    placeholder={labels.EMBARK_ENTER_DESCRIPTION}
                    test-data-id="input-flow-item-description"
                    onChange={(value) =>
                      this.handleInputChange(FLOWITEM, "Description", value)
                    }
                    onBlur={(value) =>
                      this.handleInputBlur(FLOWITEM, "Description", value)
                    }
                  >
                    {(selectedFlow && selectedFlow.Description) || ""}
                  </textarea>
                </CustomInputNew>
                <CustomInputNew label={labels.EMBARK_OWNER}>
                  <Select
                    readonly={true}
                    searchable={false}
                    testId={"collaborationDropdown"}
                    name="AllPresentation"
                    className="select-input"
                    value={
                      selectedFlow &&
                      selectedFlow.selectedFlowItem &&
                      selectedFlow.selectedFlowItem.OwnerId
                    }
                    className={`select-input`}
                    placeholder={labels.EMBARK_SELECT_FLOW_OWNER}
                    onChange={(selectedOption) =>
                      this.handleInputSelectChange(
                        FLOWITEM,
                        "OwnerId",
                        selectedOption
                      )
                    }
                    options={selectedFlow && selectedFlow.WorkplaceUsers}
                    clearable={false}
                  />
                </CustomInputNew>
                <CustomInputNew label={labels.EMBARK_LINK}>
                  <Select
                    readonly={true}
                    searchable={false}
                    testId={"collaborationDropdown"}
                    name="AllPresentation"
                    className="select-input"
                    value={
                      selectedFlow &&
                      selectedFlow.selectedFlowItem &&
                      selectedFlow.selectedFlowItem.LinkedFlowItemId
                    }
                    className={`select-input`}
                    placeholder={labels.EMBARK_SELECT_FLOW_LINK}
                    onChange={(selectedOption) =>
                      this.handleInputSelectChange(
                        FLOWITEM,
                        "LinkedFlowItemId",
                        selectedOption
                      )
                    }
                    options={
                      selectedFlow &&
                      selectedFlow.selectedFlowItem &&
                      selectedFlow.selectedFlowItem.FlowOtherItems
                    }
                    clearable={false}
                  />
                </CustomInputNew>
              </div>
            </Column.Body>
          </Column>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(
  mapStateToProps,
  { notificationAction }
)(ManageFlows);
