import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificationAction } from "../../actions";
import Select from "react-select";
import StarRatings from "react-star-ratings";
import "./review-form.scss";
import { Button } from "../../common";


class ReviewForm extends React.Component {
  render() {
    const {
      reviewQuestions,
      onRatingChange,
      onSubmit,
      isAlreadyRated,
      starRatedColor = "#9783cf",
      starHoverColor = "#dfd9f7",
      options,
      selectedCollaboration,
      CollborationCloseReasonId,
      labels,
      onFormSelectChange,
      isInActiveView,
      location,
    } = this.props;
    let isIproInactive =
      location.pathname === "/ipro-inactive-collaborations" ? true : false;
    return (
      <div className={`review-form ${isAlreadyRated ? "disabled" : ""}`}>
        { (
          reviewQuestions &&
          reviewQuestions.map((r, i) => {
            return (
              <React.Fragment key={i}>
                {r.Question === "Rating_Question6" && (
                  <Select
                    name="ReasonType"
                    className={`select-input select-durationType `}
                    value={CollborationCloseReasonId || ""}
                    onChange={(selectedOption) =>
                      onFormSelectChange(r.Answer, selectedOption)
                    }
                    placeholder={"Select a reason"}
                    options={options}
                    clearable={false}
                  />
                )}
                <p> {r.ReviewQuestionValue} </p>
                {r.Question !== "Rating_Question5" &&
                  r.Question !== "Rating_Question6" && (
                    <StarRatings
                      rating={Number.parseInt(r.Answer)}
                      starRatedColor={starRatedColor}
                      starHoverColor={starHoverColor}
                      starWidthAndHeight="10px"
                      changeRating={(value) => onRatingChange(r, value)}
                      numberOfStars={5}
                      name="star0rating"
                      starDimension="24px"
                      starSpacing="5px"
                      className="rattingsitems"
                    />
                  )}
                {r.Question === "Rating_Question5" && (
                  <textarea
                    autoComplete="off"
                    name="Description"
                    className="textarea-field"
                    rows="9"
                    placeholder={labels.collDescription}
                    value={r.Answer || ""}
                    onChange={(value) => onRatingChange(r, value)}
                    test-data-id="input-text-opportunity-description"
                  />
                )}
              </React.Fragment>
            );
          })
        )}
        {selectedCollaboration.CollaborationId > 0 &&
          (!isAlreadyRated && (
                <Button
                  className="SendButton"
                  text={"Submit"}
                  onClick={onSubmit}
                  tooltipButton={"Submit"}
                  tooltipHelp={"Submit"}
                  tooltipPlace="left"
                />
              ))}
      </div>
    );
  }
}

const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};

const mapActionToProps = {
  notificationAction,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(ReviewForm));
