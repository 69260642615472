import { ActionTypes } from "../actions";
import moment from "moment";
import { EmptySearchList } from "../utilities";
const INITIAL_STATE = {
  filterSavedSearch: [],
  savedSearches: [],
  selectedSavedSearch: [],
  savedSearchSettings: [],
  selectedSavedSearchName: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SAVED_SEARCH.FILTER_SAVED_SEARCH:
      return {
        ...state,
        filterSavedSearch: action.payload
      };
    case ActionTypes.SAVED_SEARCH.SAVED_SEARCHES:
      return {
        ...state,
        savedSearches: action.payload
      };
    case ActionTypes.SAVED_SEARCH.SELECTED_SAVED_SEARCH:
      return {
        ...state,
        selectedSavedSearch: action.payload
      };
    case ActionTypes.SAVED_SEARCH.SAVED_SEARCH_SETTINGS:
      return {
        ...state,
        savedSearchSettings: action.payload
      };
    case ActionTypes.SAVED_SEARCH.SELECTED_SAVED_SEARCH_NAME:
      return {
        ...state,
        selectedSavedSearchName: action.payload
      };

    default:
      return state;
  }
};
