import React, { useEffect } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { map, isEmpty } from "lodash";
function DebounceSelect({
  selectName,
  handleChange,
  fetchOptions,
  placeholder,
  debounceTimeout = 800,
  ...props
}) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);

  useEffect(() => {
    setFetching(true);
    fetchOptions("a").then((newOptions) => {
      setOptions(newOptions);
      setFetching(false);
    });
  }, []);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      if (isEmpty(value)) {
        return;
      }
      if (value.length < 1) {
        return;
      }
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      showSearch
      filterOption={false}
      placeholder ={placeholder}
      onChange={(e) => handleChange(selectName, e)}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
    />
  );
} // Usage of DebounceSelect

export default DebounceSelect;
