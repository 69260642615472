import React, { Component } from "react";
import { PageWrapper } from "../../../components";
import { connect } from "react-redux";
import { LoadingMaskRow, ConfirmDialog, EmptyInfo } from "../../../common";
import { notificationAction } from "../../../actions";
import "./messagesent.scss";
import { getSentMessagesApi, updateMessageApi } from "../messageApi";
import MessageDetail from "./components/MessageDetail";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import { onStateChangeAction } from "./messageSentAction";
import { Col, Row, Input, Space, Typography, Select } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import MobileSentDetailModal from "./components/mobileModal";
import ArrowLeft from "../../../assets/images/Icons/arrow-left.png";
import DeleteIcon from '../../../assets/images/Icons/delete-modal.png'
import SearchIcon from '../../../assets/images/secrch-icon.png'

const { Title } = Typography

class MessageSent extends Component {
  state = {
    mobileModal: false,
    windowWidth: window.innerWidth,
    isMobileDevice: false,
    active: "",
  };

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    this.getAllMessages();
    window.addEventListener("resize", this.handleResize);
    this.setState(st => ({ ...st, active: window.location.hash?.slice(1) }))
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getAllMessages = () => {
    this.props.onStateChangeAction({ isFetching: true });
    getSentMessagesApi().then((data) => {
      if (data.success) {
        const messageList = data.items.map((item) => ({
          ...item,
          UserRequestMessage: first(item.UserRequestMessage),
        }));
        this.props.onStateChangeAction({
          messageList,
          filterMessages: messageList,
        });
      }
      this.props.onStateChangeAction({ isFetching: false });
    });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { messageList } = this.props;
    const filterMessages = filter(messageList, (message) =>
      includes(toLower(message.Subject), toLower(value))
    );
    this.props.onStateChangeAction({ filterMessages, searchKey: value });
  };

  handleContentChange = (e) => {
    const { value, name } = e.target;
    const { messageForm } = this.props;
    const data = {
      ...messageForm,
      [name]: value,
    };
    this.props.onStateChangeAction({ messageForm: data });
  };

  handleDelete = (message) => {
    const { labels } = this.props;
    this.props.onStateChangeAction({
      selectedMessage: message,
      dialogMessage: labels.InfoIProSentMsgDeleteConfirmation,
    });
  };

  handleYesClick = () => {
    const { notificationAction, labels } = this.props;

    const message = {
      MessageId: this.props.selectedMessage.MessageId,
      IsDeleteSentMessage: true,
    };
    this.props.onStateChangeAction({
      isFetching: true,
      selectedMessage: {},
      dialogMessage: "",
    });
    updateMessageApi({ message }).then(() => {
      notificationAction({
        status: "success",
        message: labels.InfoIProSentMsgDeleteSuccessfully,
      });
      this.getAllMessages();
    });
  };

  handleNoClick = () => {
    this.props.onStateChangeAction({
      dialogMessage: "",
      selectedCompany: null,
    });
  };

  renderDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };
  handleClose = () => {
    this.setState((st) => ({ ...st, mobileModal: false }));
  };

  render() {
    const { labels, isHelpActive } = this.props;
    const {
      selectedMessage,
      filterMessages,
      isFetching,
      dialogMessage,
      searchKey,
    } = this.props;
    const getTabs = () => {
      return [
        {
          id: 1,
          label: "Inbox",
          active: window?.location?.hash == "#/inbox-messages",
          value: "/inbox-messages"
        },
        {
          id: 2,
          label: "Send",
          active: window?.location?.hash == "#/sent-messages",
          value: "/sent-messages"
        }
      ]
    }
    return (
      <PageWrapper className="collaboration">
        {dialogMessage && (
          <ConfirmDialog testId="message-sent-confirm-diloag">
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="message-sent-delete-yes"
                onClick={this.handleYesClick}
              >
                {labels.companyDeleteCurtainYESBtnText}
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="message-sent-delete-no"
                onClick={this.handleNoClick}
              >
                {labels.companyDeleteCurtainNOBtnText}
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <div className="h-100 new-design-search w-100 d-flex flex-column" >
          <div className="tabs-header-col">
            {this?.state?.isMobileDevice ?
              <div className="d-flex align-items-center gap-8 cursor-pointer"
                onClick={() => { this.setState(st => ({ ...st, isMobileDevice: false })) }}>
                <div>
                  <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                </div>
                <Title level={5} className="m-0"> Messages</Title>
              </div>
              :
              <div className="d-flex gap-16 justify-content-between align-items-center">
                <div className="d-flex gap-16 align-items-center main-header">
                  {(this?.state?.windowWidth > 480) && (
                    <button className={`tab-btn-coll w-100 ${window?.location?.hash == "#/create-message" ? "active-url" : "inactive-url"}`}
                      onClick={() => { this.props.history.push("/create-message") }}
                    >
                      + New Message
                    </button>
                  )}
                  {this.state.windowWidth < 767 ?
                    <Select
                      className="dropdown-callooration"
                      size="medium"
                      bordered={false}
                      onChange={(e) => {
                        this.setState(st => ({ ...st, active: e }))
                        this.props.history.push(`${e}`)
                      }}
                      value={this?.state?.active}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.props?.label
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      }
                      options={getTabs() || []}
                    />
                    :
                    <>
                      {getTabs()?.map(single =>
                        <button className="tab-btn-coll"
                          key={single?.id}
                          style={{ background: single?.active ? "#8E81F5" : "", color: single?.active ? "#fff" : "#000" }}
                          onClick={() => {
                            this.props.history.push(single?.value)
                          }}
                        >
                          {single?.label}
                        </button>
                      )}
                    </>
                  }
                </div>
              </div>
            }
          </div>
          <Row className=" h-100" style={{ overflow: "auto" }}>
            <Col xs={24} md={8} className="collboration-column1">
              {this.state.isMobileDevice ?
                <div className=" resume-list h-100" style={{ background: "#fff", borderRadius: "12px" }}>
                  <div className="new-collaboration-detail-component message-detail-new">
                    {isEmpty(selectedMessage) ? (
                      <EmptyInfo>{labels.Message_Sent}</EmptyInfo>
                    ) : (
                      <MessageDetail
                        selectedMessage={selectedMessage}
                        labels={labels}
                        deleteMessage={this.handleDelete}
                      />
                    )}
                  </div>
                </div>
                :
                <div className="h-100 d-flex flex-column">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "12px",
                      borderBottom: "1px solid #F3F3F3",
                      gap: "10px"
                    }}
                  >
                    <Input
                      placeholder={labels?.searchInputPlaceholderMessage}
                      size="medium"
                      bordered={false}
                      style={{ border: "1px solid #F3F3F3" }}
                      onChange={this.handleSearchChange}
                    />
                    <div>
                      <img style={{ height: "30px" }} src={SearchIcon} alt="" />
                    </div>
                  </div>
                  <div
                    style={{
                      overflow: "auto",
                      padding: "12px",
                      flex: 1
                    }}
                  >
                    {filterMessages?.length > 0 ? (
                      <Space size={[6, 6]} wrap className="short-list-new">
                        {filterMessages?.map((item) => (
                          <div
                            onClick={() => {
                              if (this.state.windowWidth < 767) {
                                this.setState(st => ({ ...st, isMobileDevice: true }))
                              }
                              this.props.onStateChangeAction({
                                selectedMessage: item,
                              })
                            }}
                            key={item?.UserCompanyId}
                            className={`d-flex gap-8 justify-content-between align-items-center pointer ${item.MessageId === selectedMessage.MessageId
                              ? "background-shortlist short-list-item "
                              : "short-list-item"
                              }`}>
                            <div className="d-flex  gap-8 w-100 justify-content-betwee align-items-center">

                              <div className="text-clamp" style={{ flex: 1 }}>
                                <label style={{ fontSize: "14px" }}>
                                  {item?.SenderName}
                                </label>
                                <p className="text-clamp" style={{ fontSize: "12px", marginBottom: 0 }}>{item?.Subject}</p>
                              </div>

                              {item.MessageId === selectedMessage.MessageId &&
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.handleDelete(item);
                                  }}
                                >
                                  <img src={DeleteIcon} alt="" style={{ height: "20px" }} />
                                </div>
                              }
                            </div>

                          </div>
                        ))}
                      </Space>
                    ) : (
                      <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
                    )}
                  </div>
                  {(this?.state?.windowWidth < 480) && (
                    <div style={{ margin: "auto" }}>
                      <div className="tabs-header-col mt-3">
                        <button className="tab-btn-coll"
                          style={{ background: "#6C63FF", color: "#fff" }}
                          onClick={() => { this.props.history.push("/create-message") }}
                        >
                          + New Message
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              }
            </Col>
            <Col md={16} xs={0} offset={0.5}
              className={"h-100"}
            >
              <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                <div className="new-collaboration-detail-component message-detail-new">
                  {isEmpty(selectedMessage) ? (
                    <EmptyInfo>{labels.Message_Sent}</EmptyInfo>
                  ) : (
                    <MessageDetail
                      selectedMessage={selectedMessage}
                      labels={labels}
                      deleteMessage={this.handleDelete}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper >
    );
  }
}

const mapStateToProps = ({
  systemLabel,
  userInfo,
  navigation,
  messageSent,
}) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  const { UserId } = user;
  const userId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  return { labels, user, UserId: userId, isHelpActive, ...messageSent };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(MessageSent);
