import React, { useEffect, useState } from "react";
import uuidv1 from "uuid/v1";
import {
  getIndustriesApi,
  getRolesApi,
  addExperienceApi,
  resumeItemDeleteApi,
  getResumeExperiencesApi,
} from "../../../Resume/ResumeEdit/resumeEditApi";
import { map, isEmpty } from "lodash";
import AsyncSelect from "react-select/lib/Async";
import { RichTextEditor } from "../../../../components";
import Trash from "../../../../assets/newResume/trash";
import Edit from "../../../../assets/newResume/edit";
import classes from "./experience.module.scss";
import EmptyResume from "../../../../common/emptyMessage/emptyResume";
import { Col, Divider, Popover, Row } from "antd";
import MenuDots from "../../../../assets/newResume/menuDots";
import DetailIcon from "../../../../assets/images/svg/detail.svg";
import { ConfirmDialog } from "../../../../common";
import ExperienceDetail from "./components/resumeDetail";
import { notificationAction } from "../../../../actions";
import { useDispatch } from "react-redux";

const Experience = ({
  labels,
  levelOptions,
  onExpierenceCompanyNameChange,
  onExpierenceIndustryNameChange,
  onExpierenceRoleNameChange,
  onExpierenceIndustryBlur,
  onExperienceRoleBlur,
  industryDefaultOptions,
  roleDefaultOptions,
  getResume,
  onStateChangeAction,
  userDetails,
}) => {
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
   const [detail, setDetail] = useState({
    show: false,
  });
  const [deleteResume, setDeleteResume] = useState({
    show: false,
  });
  const [experience, setExperience] = useState([]);
  const [industry, setIndustry] = useState({
    IndustryId: "",
    IndustryValue: "",
  });
  const [profile, setProfile] = useState({
    ProfileId: "",
    ProfileValue: "",
  });

  const [newExperience, setNewExperience] = useState({
    uniqueId: uuidv1(),
    CompanyWorked: "",
    IndustryId: "",
    IndustryValue: "",
    ProfileId: "",
    ProfileValue: "",
    StartDate: "",
    EndDate: "",
    isWorking: false,
    Description: "",
  });
  const [resumeCurentItemList, setResumeCurentItemList] = useState([]);

  useEffect(() => {
    getResumeExperiences();
  }, []);

  const handleIndustriesLoad = (searchKey, callback) => {
    if (isEmpty(searchKey)) {
      callback(null, {
        options: map(industryDefaultOptions, (Industry) => {
          return {
            ...Industry,
            label: Industry.IndustryValue,
            value: Industry.IndustryId,
          };
        }),
      });
      return;
    }
    if (searchKey.length < 3) {
      callback([]);
      return;
    }
    getIndustriesApi({ searchKey }).then((response) => {
      if (response.success) {
        callback(null, {
          options: map(response.items, (Industry) => {
            return {
              ...Industry,
              label: Industry.IndustryValue,
              value: Industry.IndustryId,
            };
          }),
        });
      }
    });
  };
  const getResumeExperiences = () => {
    onStateChangeAction({ isLoading: true });

    getResumeExperiencesApi()
      .then((data) => {
        if (data.success) {
          setResumeCurentItemList(
            data.items.map((i) => {
              return { ...i, uniqueId: uuidv1() };
            })
          );
          onStateChangeAction({ isLoading: false });
        }
      })
      .catch(() => {});
  };
  const handleRolesLoad = (searchKey, callback) => {
    if (isEmpty(searchKey)) {
      callback(null, {
        options: map(roleDefaultOptions, (profile) => {
          return {
            ...profile,
            label: profile.ProfileValue,
            value: profile.ProfileId,
          };
        }),
      });
      return;
    }
    if (searchKey.length < 3) {
      callback([]);
      return;
    }
    getRolesApi({ searchKey }).then((response) => {
      if (response.success) {
        callback(null, {
          options: map(response.items, (profile) => {
            return {
              ...profile,
              label: profile.ProfileValue,
              value: profile.ProfileId,
            };
          }),
        });
      }
    });
  };
  const deleteExperience = (expr) => {
    onStateChangeAction({ isLoading: true });
    resumeItemDeleteApi({
      LookupTypeId: 9,
      ResumeId: userDetails.ResumeId,
      ObjectPK: expr.ResumeExperienceId,
    })
      .then((res) => {
        if (res.success) {
          getResumeExperiences();
          // setExperience(tempExperience);
          // getResume();
          // getResumeExperiences();
          setDeleteResume((st) => ({
            ...st,
            show: false,
            message: "",
            item: "",
          }));
        } else {
          getResumeExperiences();
          dispatch(
            notificationAction({
              status: "error",
              message:
                "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
            })
          );
        }
      })
      .catch((err) => {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (edit) {
      onStateChangeAction({ isLoading: true });
      addExperienceApi(newExperience)
        .then((data) => {
          if (data.success) {
            getResumeExperiences();
            dispatch(
              notificationAction({
                status: "success",
                message: "Resume experience updated successfully!",
              })
            );
          } else {
            getResumeExperiences();
            dispatch(
              notificationAction({
                status: "error",
                message:
                  "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
              })
            );
          }
        })
        .catch(() => {
          getResumeExperiences();
          dispatch(
            notificationAction({
              status: "error",
              message:
                "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
            })
          );
        });
    } else {
      onStateChangeAction({ isLoading: true });
      addExperienceApi(newExperience)
        .then((data) => {
          if (data.success) {
            onStateChangeAction({ isLoading: false });
            getResumeExperiences();
          } else {
            getResumeExperiences();
            dispatch(
              notificationAction({
                status: "error",
                message:
                  "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            notificationAction({
              status: "error",
              message:
                "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
            })
          );
        });
    }

    setNewExperience({
      uniqueId: "",
      CompanyWorked: "",
      IndustryValue: "",
      ProfileValue: "",
      StartDate: "",
      EndDate: "",
      isWorking: false,
    });
    setAdd(false);
    setEdit(false);
  };

  const content = (item) => {
    return (
      <div className={classes.dropDownExperienceContent}>
        <div
          onClick={() => {
            setEdit(true);
            setNewExperience(item);
            setDetail((st) => ({
              ...st,
              show: false,
            }));
            setOpen("");
          }}
        >
          <p>Edit</p>
          <Edit />
        </div>

        <Divider />
        <div
          onClick={() => {
            setDeleteResume((st) => ({
              ...st,
              show: true,
              message: "Are you sure, you want to delete this?",
              item: item,
            }));
          }}
        >
          <p>Delete</p>
          <Trash />
        </div>
      </div>
    );
  };

  return (
    <Row className={classes.userResume} justify="center">
      <Col xs={24} lg={18} md={22}>
        {!add && !edit && resumeCurentItemList?.length > 0 ? (
          <>
            {resumeCurentItemList &&
              resumeCurentItemList.map((item, index) => (
                <Row
                  gutter={[16, 16]}
                  justify="space-between"
                  className={classes.resumeTitle}
                  align="center"
                  key={index}
                >
                  <Col
                    xs={22}
                    md={22}
                    className={classes.title}
                    onClick={() => {
                      setDetail((st) => ({
                        ...st,
                        show: st.show === index ? false : index,
                        item: item,
                      }));
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={DetailIcon}
                        alt=""
                        style={
                          detail?.show === index
                            ? {
                                transform: "rotate(180deg)",
                              }
                            : {}
                        }
                      />
                      <p
                        className={classes.paragraphResume}
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {`${item?.CompanyWorked}, ${item?.IndustryValue}, ${item?.ProfileValue}`}
                      </p>
                    </div>
                  </Col>
                  <Col xs={0} md={2}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="pointer"
                        onClick={() => {
                          setEdit(true);
                          setNewExperience(item);
                          setDetail((st) => ({
                            ...st,
                            show: false,
                          }));
                        }}
                      >
                        <Edit />
                      </div>
                      <div
                        className="pointer"
                        onClick={() => {
                          setDeleteResume((st) => ({
                            ...st,
                            show: true,
                            message: "Are you sure, you want to delete this?",
                            item: item,
                          }));
                        }}
                      >
                        <Trash />
                      </div>
                    </div>
                  </Col>
                  <Col xs={2} md={0} className={classes.dotSection}>
                    <Popover
                      content={content(item)}
                      placement="leftTop"
                      trigger="click"
                      open={open === index}
                      onOpenChange={() => {
                        setOpen(open === index ? "" : index);
                      }}
                    >
                      <MenuDots style={{ cursor: "pointer" }} />
                    </Popover>
                  </Col>
                  {detail?.show === index && (
                    <Col
                      xs={24}
                      style={{
                        background: "#fff",
                        borderRadius: "8px",
                      }}
                    >
                      <ExperienceDetail detail={detail} />
                    </Col>
                  )}
                </Row>
              ))}
          </>
        ) : (
          <>
            {!add && !edit && (
              <EmptyResume text={`"No resume experience added yet"`} />
            )}
          </>
        )}
        {add == false && edit == false && (
          <div className="w-100 d-flex justify-content-center mt-4">
            <button
              className={classes.btn}
              style={{
                marginRight: 0,
              }}
              onClick={() => {
                setAdd(true);
              }}
            >
              Add Experience
            </button>
          </div>
        )}
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {(add || edit) && (
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <div className={classes.workplace}>
                  <input
                    type="text"
                    placeholder="Type your working place where you work"
                    value={newExperience.CompanyWorked}
                    required
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setNewExperience((prev) => ({
                        ...prev,
                        CompanyWorked: value,
                      }));
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} lg={12} md={12}>
                <div className=" newResumeEduValue newExperienceEditSelect">
                  <AsyncSelect
                    className={`select-location select-input`}
                    placeholder={labels.RESUME_EDIT_INDUSTRY_NAME_PLACEHOLDER}
                    cacheOptions
                    clearable={false}
                    required
                    defaultOptions
                    searchable={true}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    value={
                      newExperience.IndustryId && {
                        value: newExperience.IndustryId,
                        label: newExperience.IndustryValue,
                      }
                    }
                    loadOptions={handleIndustriesLoad}
                    onChange={(options) => {
                      setNewExperience((prev) => ({
                        ...prev,
                        IndustryId: options?.IndustryId,
                        IndustryValue: options?.IndustryValue,
                      }));
                    }}
                  />
                </div>
              </Col>
              <Col xs={24} lg={12} md={12}>
                <div className=" newResumeEduValue newExperienceEditSelect">
                  <AsyncSelect
                    className={`select-location select-input`}
                    placeholder={labels.RESUME_EDIT_ROLE_NAME_PLACEHOLDER}
                    cacheOptions
                    defaultOptions
                    clearable={false}
                    required
                    searchable={true}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    loadOptions={handleRolesLoad}
                    value={
                      newExperience.ProfileId && {
                        value: newExperience.ProfileId,
                        label: newExperience.ProfileValue,
                      }
                    }
                    onChange={(options) => {
                      setNewExperience((prev) => ({
                        ...prev,
                        ProfileId: options?.ProfileId,
                        ProfileValue: options?.ProfileValue,
                      }));
                    }}
                  />
                </div>
              </Col>

              <Col
                xs={{ span: 24 }}
                lg={{ span: 6 }}
                md={6}
                className={classes.dates}
              >
                <Row justify="space-between" className="align-items-center">
                  <Col xs={4}>
                    <p className={classes.dateLabel}>From</p>
                  </Col>
                  <Col xs={19} className="pl-2">
                    <input
                      id="inputId2"
                      className={classes.dateInput}
                      required
                      value={newExperience?.StartDate?.slice(0, 10)}
                      onClick={() => {
                        const inputDate = document.getElementById("inputId2");
                        inputDate.addEventListener("click", function(evt) {
                          if (this.getAttribute("type") === "date") {
                            this.showPicker();
                          }
                        });
                      }}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setNewExperience((prev) => ({
                          ...prev,
                          StartDate: value,
                        }));
                      }}
                      type="date"
                    />
                  </Col>
                </Row>
              </Col>

              <Col
                xs={{ span: 24 }}
                lg={{ span: 6 }}
                md={6}
                className={classes.dates}
              >
                <Row justify="space-between" className="align-items-center">
                  <Col xs={4}>
                    <p className={classes.dateLabel}>To</p>
                  </Col>
                  <Col xs={19} className="pl-2">
                    <input
                      id="inputId"
                      className={classes.dateInput}
                      value={newExperience?.EndDate?.slice(0, 10)}
                      required={!newExperience.isWorking}
                      min={newExperience?.StartDate?.slice(0, 10)}
                      onClick={() => {
                        const inputDate = document.getElementById("inputId");

                        inputDate.addEventListener("click", function(evt) {
                          if (this.getAttribute("type") === "date") {
                            this.showPicker();
                          }
                        });
                      }}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setNewExperience((prev) => ({
                          ...prev,
                          EndDate: value,
                        }));
                      }}
                      type="date"
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                xs={{ span: 24 }}
                lg={{ span: 8 }}
                md={8}
                className={classes.dates}
              >
                <div className={classes.Working}>
                  <p className={classes.dateLabel}>Ongoing</p>
                  <input
                    className={classes.workingCheck}
                    value={newExperience.isWorking}
                    checked={newExperience.isWorking}
                    required={!newExperience?.EndDate}
                    disabled={newExperience?.EndDate}
                    onChange={(e) => {
                      const { checked } = e.target;
                      setNewExperience((prev) => ({
                        ...prev,
                        isWorking: checked,
                      }));
                    }}
                    type="checkbox"
                  />
                </div>
              </Col>
              <Col xs={24}>
                <div className="experience-edit-rich-text">
                  <RichTextEditor
                    id="resume-edit-experience-description"
                    name="Description"
                    value={newExperience.Description || ""}
                    handleChange={(e) => {
                      setNewExperience((prev) => ({
                        ...prev,
                        Description: e,
                      }));
                    }}
                    placeholder={
                      labels.RESUME_EDIT_EXPERIENCE_DESCRIPTION_LABEL
                    }
                  />
                </div>
              </Col>
            </Row>
          )}
          <div className={classes.buttons}>
            {(add || edit) && (
              <>
                <button className={classes.btn} type="submit">
                  SAVE
                </button>
                <button
                  className={classes.discard}
                  onClick={() => {
                    setNewExperience({
                      uniqueId: "",
                      CompanyWorked: "",
                      IndustryValue: "",
                      ProfileValue: "",
                      StartDate: "",
                      EndDate: "",
                      isWorking: false,
                    });
                    setAdd(false);
                    setEdit(false);
                  }}
                >
                  DISCARD
                </button>
              </>
            )}
          </div>
        </form>

        {deleteResume?.show && (
          <ConfirmDialog testId="company-confirm-diloag">
            <ConfirmDialog.Message>
              {deleteResume?.message}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-yes"
                onClick={() => {
                  deleteExperience(deleteResume?.item);
                }}
              >
                Yes
              </ConfirmDialog.Button>
              <ConfirmDialog.Button
                className="dialog-btn"
                testId="company-delete-no"
                onClick={() => {
                  setDeleteResume((st) => ({
                    ...st,
                    show: false,
                    message: "",
                    item: "",
                  }));
                }}
              >
                No
              </ConfirmDialog.Button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
      </Col>
    </Row>
  );
};

export default Experience;
