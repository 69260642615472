import React from "react";
import Select from "react-select";
import "./dashboard.scss";
import Widget from "./Widget";
import { Column, HelpTooltip } from "../../../../common";
import {
  GetAllActiveCollaborationsCount,
  GetmyCollaborationsAsOwnerCount,
  GetAllTimeSheetCount,
  GetSearcherAllExpenseSheetsCount,
  getAllCollaborationsApi,
  DownloadColllabTimeLogReport,
  GetPoolsApi,
  DownloadPoolTimeLogReport,
} from "../../snapshotApi";
import { GetCollaborationsEndsInMonthCount } from "../../../Tictell/tictellApi";
import { connect } from "react-redux";
import { notificationAction } from "../../../../actions";
import { StorageService } from "../../../../api";
class Dashboard extends React.Component {
  state = {
    Pendingtimesheet: "",
    Approvedtimesheet: "",
    Rejectedtimesheet: "",
    Rejectedexpense: "",
    Pendingexpense: "",
  };

  componentDidMount() {
    GetAllTimeSheetCount(this.props.token).then((res) => {
      if (res.success) {
        this.setState({
          Pendingtimesheet: res.items.Pending,
          Rejectedtimesheet: res.items.Rejected,
        });
      }
    });
    GetSearcherAllExpenseSheetsCount(this.props.token).then((res) => {
      if (res.success)
        this.setState({
          Pendingexpense: res.items.Pending,
          Rejectedexpense: res.items.Rejected,
        });
    });
    GetAllActiveCollaborationsCount(this.props.token).then((res) => {
      if (res.success)
        this.setState({
          activeCollabCount: res.items,
        });
    });
    GetCollaborationsEndsInMonthCount().then((data) => {
      if (data.success) {
        this.setState({
          ColabsEndsInAMonthCount: data.items,
        });
      } else {
        this.setState({
          ColabsEndsInAMonthCount: "",
        });
      }
    });
    GetmyCollaborationsAsOwnerCount().then((data) => {
      if (data.success) {
        this.setState({
          myCollaborationsAsOwner: data.items,
        });
      } else {
        this.setState({
          myCollaborationsAsOwner: "",
        });
      }
    });
    getAllCollaborationsApi(1, this.props.token).then((data) => {
      if (data.success === true) {
        let arr = [];
        data.items.map((item, index) => {
          let obj = { label: item.Title, value: item.CollaborationId };
          arr.push(obj);
        });
        this.setState({
          allActiveCollaborations: arr,
        });
      } else {
        this.setState({
          allActiveCollaborations: "",
        });
      }
    });
    GetPoolsApi(this.props.token).then((data) => {
      if (data.success === true) {
        const allPools = data.items.map((item) => {
          return { label: item.Title, value: item.PoolId };
        });
        this.setState({
          allPools,
        });
      } else {
        this.setState({
          allPools: [],
        });
      }
    });
  }
  handleDownloadCollabReport = () => {
    DownloadColllabTimeLogReport(
      parseInt(this.state.selectedCollaboration.value),
      this.props.token
    )
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Collaboration TimeLog report.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleDownloadPoolReport = () => {
    DownloadPoolTimeLogReport(
      parseInt(this.state.selectedPool.value),
      this.props.token
    )
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Pool TimeLog report.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  render() {
    const {
      widgetTestId,
      contentTestId,
      UserFeatures,
      isHelpActive,
      labels,
    } = this.props;
    const {
      ColabsEndsInAMonthCount,
      Pendingtimesheet,
      Rejectedtimesheet,
      Rejectedexpense,
      Pendingexpense,
      activeCollabCount,
      myCollaborationsAsOwner,
      allActiveCollaborations,
      selectedCollaboration,
      selectedPool,
    } = this.state;
    return (
      <div className="dashboard-view">
        <React.Fragment>
          <Column className="column-1">
            {UserFeatures &&
              UserFeatures.find(
                (x) => x.Name == "Snapshot-Home-Widget-Timesheets" && x.IsActive
              ) && (
                <Widget testId={widgetTestId}>
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip
                        content={labels.Timesheet_Dashboard_Message}
                      />
                    )}{" "}
                    <i className="snapsot-icons timesheet-icon" />
                    <label>{labels.TimesheetWidget}</label>
                  </Widget.Header>
                  <Widget.Content>
                    <div className="widget-content">
                      <div>
                        <label>{Pendingtimesheet || 0}</label>
                        <label>{labels.pendingForApproval}</label>
                      </div>
                      <div>
                        <label>{Rejectedtimesheet || 0}</label>
                        <label>{labels.Rejected}</label>
                      </div>
                    </div>
                  </Widget.Content>
                </Widget>
              )}
            {UserFeatures &&
              UserFeatures.find(
                (x) => x.Name == "Snapshot-Home-Widget-Expenses" && x.IsActive
              ) && (
                <Widget>
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip content={labels.Expense_Dashboard_Message} />
                    )}
                    <i className="snapsot-icons expensesheet-icon" />
                    <label>{labels.ExpenseWidget}</label>
                  </Widget.Header>
                  <Widget.Content>
                    <div className="widget-content">
                      <div>
                        <label>{Pendingexpense || 0}</label>
                        <label>{labels.pendingForApproval}</label>
                      </div>
                      <div>
                        <label>{Rejectedexpense || 0}</label>
                        <label>{labels.Rejected}</label>
                      </div>
                    </div>
                  </Widget.Content>
                </Widget>
              )}
          </Column>
          <Column className="column-2">
            {UserFeatures &&
              UserFeatures.find(
                (x) =>
                  x.Name == "Snapshot-Home-Widget-CollaborationReporting" &&
                  x.IsActive
              ) && (
                <Widget className="report-widgets">
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip
                        content={labels.Collaboration_Dashboard_Message}
                      />
                    )}
                    <i className="snapsot-icons collab-icon" />
                    <label>
                      {labels?.searcher_snapshot_home_column2_title}
                    </label>
                  </Widget.Header>
                  <Widget.Content>
                    <div
                      className="collab-widget-content"
                      style={{ margin: "0px 5px auto" }}
                    >
                      <Select
                        name="Collaboration"
                        className="select-input"
                        placeholder={
                          labels?.searcher_snapshot_home_column2_select_placeholder
                        }
                        value={selectedCollaboration || ""}
                        onChange={(selectedOption) =>
                          this.setState({
                            selectedCollaboration: selectedOption,
                          })
                        }
                        clearable={false}
                        searchable={true}
                        options={allActiveCollaborations}
                      />

                      <button
                        className="SendButton"
                        onClick={this.handleDownloadCollabReport}
                        data-test-id="input-button-opportunity-saveBtn"
                      >
                        {labels?.searcher_snapshot_home_btn_text}
                      </button>
                    </div>
                  </Widget.Content>
                </Widget>
              )}
            {UserFeatures &&
              UserFeatures.find(
                (x) =>
                  x.Name == "Snapshot-Home-Widget-Collabortions" && x.IsActive
              ) && (
                <Widget>
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip
                        content={labels.Collaboration_Dashboard_Message}
                      />
                    )}
                    <i className="snapsot-icons collab-icon" />
                    <label>{"Collaborations"}</label>
                  </Widget.Header>
                  <Widget.Content>
                    <div className="collab-widget-content">
                      <div>
                        <label>{activeCollabCount || 0}</label>
                        <label>{labels.ActiveCollaboration}</label>
                      </div>
                      <hr />
                      <div>
                        <label>{ColabsEndsInAMonthCount || 0}</label>
                        <label>{labels.CollaborationDeadline}</label>
                      </div>
                      <div>
                        <label>{myCollaborationsAsOwner || 0}</label>
                        <label>{labels.Collaborationowner}</label>
                      </div>
                    </div>
                  </Widget.Content>
                </Widget>
              )}
          </Column>
          <Column className="column-2">
            {UserFeatures &&
              UserFeatures.find(
                (x) =>
                  x.Name == "Snapshot-Home-Widget-PoolReporting" && x.IsActive
              ) && (
                <Widget className="report-widgets">
                  <Widget.Header>
                    {isHelpActive && (
                      <HelpTooltip
                        content={labels.Collaboration_Dashboard_Message}
                      />
                    )}
                    <i className="snapsot-icons collab-icon" />
                    <label>
                      {labels?.searcher_snapshot_home_column3_title}
                    </label>
                  </Widget.Header>
                  <Widget.Content>
                    <div
                      className={"collab-widget-content"}
                      style={{ margin: "0px 5px auto" }}
                    >
                      <Select
                        name="Pool"
                        className={`select-input`}
                        placeholder={
                          labels?.searcher_snapshot_home_column3_select_placeholder
                        }
                        value={selectedPool || ""}
                        onChange={(selectedOption) =>
                          this.setState({ selectedPool: selectedOption })
                        }
                        clearable={false}
                        searchable={true}
                        options={this.state.allPools}
                      />

                      <button
                        className="SendButton"
                        onClick={() => this.handleDownloadPoolReport()}
                        test-data-id="input-button-opportunity-saveBtn"
                      >
                        {labels?.searcher_snapshot_home_btn_text}
                      </button>
                    </div>
                  </Widget.Content>
                </Widget>
              )}
          </Column>
        </React.Fragment>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel, navigation, userInfo }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const User = user ? user : StorageService.getUser();
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, User };
};
export default connect(mapStateToProps, { notificationAction })(Dashboard);
