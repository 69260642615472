import React, { Component } from "react";
import { BulletList, EmptyInfo } from "../../../../common";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import "./collaborationList.scss";
class CollaborationIproList extends Component {
  renderDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  isActiveCollaboration = ({ collaboration }) => {
    const { IsSearcher } = this.props;
    const { IsAccepted } = collaboration;
    if (IsSearcher) {
      return false;
    }
    return !IsAccepted;
  };

  render() {
    const {
      collaborationList,
      selectedCollaboration,
      handleSelectCollaboration,
      labels,
    } = this.props;
    return (
      <>
        {collaborationList?.length > 0 ? (
          <BulletList
            className="collaboration-list-component"
            testId="sent-collaboration-list"
          >
            {collaborationList.map((item) => (
              <BulletList.ListItem
                isActive={!item.IsRead}
                isSelected={
                  item.CollaborationId === selectedCollaboration.CollaborationId
                }
                onClick={() => handleSelectCollaboration(item)}
                key={item.CollaborationId}
                testId={
                  !isEmpty(collaborationList)
                    ? item.IsAccepted
                      ? "sent-collaboration-accepted"
                      : "sent-collaboration-unaccepted"
                    : ""
                }
              >
                <div className="listLabel">{item.Title}</div>
                <div className="dateItem">
                  {this.renderDate(item.StartDate)}
                </div>
              </BulletList.ListItem>
            ))}
          </BulletList>
        ) : (
          <EmptyInfo>{labels.EMBARK_NO_ITEM_TO_DISPLAY}</EmptyInfo>
        )}
      </>
    );
  }
}
export default CollaborationIproList;
