import React, { Component } from "react";
import LogoSrc from "../../../../assets/images/logo.png";
import WhiteLogoSrc from "../../../../assets/images/logoWhite.png";
import TopbarSrc from "../../../../assets/images/topbar.png";
import Search from "./Search";
import { connect } from "react-redux";
import "./header.scss";
import { getAllProfileLookupApi } from "../../../VisitorSearch/visitorApi";
import { HashLink as Link } from "react-router-hash-link";
class Header extends Component {
  state = {
    subNav: false,
    navigation: false,
    countries:null
  };
  componentDidMount() {
    this.getProfileLookup();
  }

  getProfileLookup = () => {
    getAllProfileLookupApi().then((data) => {
      const countries = data.items.map((item) => ({
        ...item,
        value: item.ProfileId,
        label: item.ProfileValue,
      }));
      this.setState({ countries });
    });
  };

  toggleSubNav = (value) => {
    this.setState({
      subNav: value === false ? value : !this.state.subNav,
    });
  };
  onNavigationToggle = () => {
    this.setState({ 
      navigation: !this.state.navigation,
    })
  }
  render() {
    const {
      onLoginClick,
      onSignupClick,
      labels,
      onSearchClick,
      aboutUsMenu,
    } = this.props;
    const { subNav, navigation } = this.state;
    return (
      <React.Fragment>
        <header className="landing-header main-header">
          <div className="TopBar">
            <img className="topBarImg" src={TopbarSrc} alt="img" />
          </div>
          <div>
            <div className="btnWrap">
              <a
                onClick={onLoginClick}
                id="showLogin"
                className="loginBtnClick"
              >
                {labels.ShowLoginFormButton || 'Login'}
              </a>
              <a
                onClick={onSignupClick}
                className="loginBtnClick"
                test-data-id="signup"
              >
                {labels.ShowSignupFormButton || 'Sign Up'}
              </a>
            </div>
            <Search onSearchClick={onSearchClick} countries={this.state.countries} />
            <div className="top-navigation">
              <div>
                <span onClick={this.toggleSubNav}>Why ProDoo</span>
                {subNav && (
                  <div className="sub-nav">
                    <Link to="/cms/162">{"How does it work?"}</Link>
                    <Link to="/cms/154">{"Work as an iPro"}</Link>
                    <Link to="/cms/152">{"Work as a sercher"}</Link>
                    <Link to="/cms/156">{"Concepts and terminologies"}</Link>
                  </div>
                )}
              </div>
              {aboutUsMenu && (
                <Link
                  onClick={() => this.toggleSubNav(false)}
                  smooth
                  to="/#aboutUs"
                >
                  About Us
                </Link>
              )}
              <Link onClick={() => this.toggleSubNav(false)} smooth to="/#blog">
                Blog
              </Link>
              <Link
                onClick={() => this.toggleSubNav(false)}
                smooth
                to="/#contactUs"
              >
                Contact Us
              </Link>
            </div>
            <div className="Logo">
              <img className="loginScreen" src={WhiteLogoSrc} alt="logo" />
              <img className="otherScreen" src={LogoSrc} alt="logo" />
            </div>
          </div>
        </header>
        <header className="landing-header tab-header">
          <div className="TopBar">
            <img className="topBarImg" src={TopbarSrc} alt="img" />
          </div>
          <div className="top-wrapper">
            <div className="logo-wrapper">
              <div className="btnWrap">
                <a
                  onClick={onLoginClick}
                  id="showLogin"
                  className="loginBtnClick"
                >
                  {labels.ShowLoginFormButton || 'Login'}
                </a>
                <a
                  onClick={onSignupClick}
                  className="loginBtnClick"
                  test-data-id="signup"
                >
                  {labels.ShowSignupFormButton || 'Sign Up'}
                </a>
              </div>
              <Search onSearchClick={onSearchClick} countries={this.state.countries} />
              <div className="Logo">
                <img className="loginScreen" src={WhiteLogoSrc} alt="logo" />
                <img className="otherScreen" src={LogoSrc} alt="logo" />
              </div>
            </div>
            <div className="top-navigation">
              <div>
                <span onClick={this.toggleSubNav}>Why ProDoo</span>
                {subNav && (
                  <div className="sub-nav">
                    <Link to="/cms/162">{"How does it work?"}</Link>
                    <Link to="/cms/154">{"Work as an iPro"}</Link>
                    <Link to="/cms/152">{"Work as a sercher"}</Link>
                    <Link to="/cms/156">{"Concepts and terminologies"}</Link>
                  </div>
                )}
              </div>
              {aboutUsMenu && (
                <Link smooth to="/#aboutUs">
                  About Us
                </Link>
              )}
              <Link smooth to="/#blog">
                Blog
              </Link>
              <Link smooth to="/#contactUs">
                Contact Us
              </Link>
            </div>
          </div>
        </header>
        <header className="landing-header mobile-header">
          <div className="TopBar">
            <img className="topBarImg" src={TopbarSrc} alt="img" />
          </div>
          <div className="top-wrapper">
            <div className="logo-wrapper">
              <div className="btnWrap">
                <a
                  onClick={onLoginClick}
                  id="showLogin"
                  className="loginBtnClick"
                >
                  {labels.ShowLoginFormButton || 'Login'}
                </a>
                <a
                  onClick={onSignupClick}
                  className="loginBtnClick"
                  test-data-id="signup"
                >
                  {labels.ShowSignupFormButton || 'Sign Up'}
                </a>
              </div>
              <div className="Logo">
                <img className="loginScreen" src={WhiteLogoSrc} alt="logo" />
                <img className="otherScreen" src={LogoSrc} alt="logo" />
              </div>
            </div>
            <div className="menu-wrapper">
              <Search onSearchClick={onSearchClick} countries={this.state.countries} />
              <button className="ToggleMenu" onClick={this.onNavigationToggle} />
            </div>
            {navigation && <div className="top-navigation">
              <div>
                <span onClick={this.toggleSubNav}>Why ProDoo</span>
                {subNav && (
                  <div className="sub-nav">
                    <Link to="/cms/162">{"How does it work?"}</Link>
                    <Link to="/cms/154">{"Work as an iPro"}</Link>
                    <Link to="/cms/152">{"Work as a sercher"}</Link>
                    <Link to="/cms/156">{"Concepts and terminologies"}</Link>
                  </div>
                )}
              </div>
              {aboutUsMenu && (
                <Link smooth to="/#aboutUs">
                  About Us
                </Link>
              )}
              <Link smooth to="/#blog">
                Blog
              </Link>
              <Link smooth to="/#contactUs">
                Contact Us
              </Link>
            </div>}
          </div>
        </header>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return {
    labels,
  };
};
export default connect(mapStateToProps)(Header);
