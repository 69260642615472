import React, { Component, Fragment } from "react";
import "./shortlistresumedetail.scss";
import { connect } from "react-redux";
import { htmlParser } from "../../../utilities/helpers";
import moment from "moment";
import {
  AvailablityGreenCriteria,
  AvailablityYellowCriteria,
} from "../../../utilities/constants";

const AccordionDetail = ({ children }) => children;
const AccordionHeading = ({ heading, onToggle, active }) => (
  <div className="ResumeHeadingCnt">
    <div className="ResumeHeading">{heading}</div>
    <div
      className={`DownArrow ${active ? "" : "UpArrow"}`}
      onClick={onToggle}
    />
  </div>
);

class Accordion extends Component {
  state = {
    active: true,
  };
  handleToggle = () => {
    this.setState({ active: !this.state.active });
  };
  render() {
    const { active } = this.state;
    const { heading, children } = this.props;
    return (
      <div className="AvailableList">
        <AccordionHeading
          heading={heading}
          active={active}
          onToggle={this.handleToggle}
        />
        {active && <AccordionDetail>{children}</AccordionDetail>}
      </div>
    );
  }
}

const ChildAccordionHeading = ({ heading, onToggle, active }) => (
  <div className="ResumeHeadingCnt">
    {heading}
    <div
      className={`DownArrow ${active ? "" : "UpArrow"}`}
      onClick={onToggle}
    />
  </div>
);

class ChildAccordion extends Component {
  state = {
    active: true,
  };
  handleToggle = () => {
    this.setState({ active: !this.state.active });
  };
  render() {
    const { active } = this.state;
    const { heading, children, testId } = this.props;
    return (
      <li className="AvailableList ResumeItem" test-data-id={testId}>
        <ChildAccordionHeading
          heading={heading}
          active={active}
          onToggle={this.handleToggle}
        />
        {active && <AccordionDetail>{children}</AccordionDetail>}
      </li>
    );
  }
}
class ShortlistResumeDetail extends Component {
  availability = (record) => {
    //A resume is GREEN if ResumeBusyUntilDate <= (SearchAvailDate + 14) which in our case would be up until Jan 13th
    //A resume is YELLOW if ResumeBusyUntilDate > (SearchAvailDate +14) AND < (SearchAvailDate + 60) which in our case would be up until (and including) February 26th
    //A resume is RED if ResumeBusyUntilDate >= (SearchAvailDate + 60) which in our case would be up from February 27th and onward.

    const availableDate = record.AvailabilityDate;
    if (availableDate) {
      const ResumeBusyUntilDate = new Date(availableDate);
      const SearchAvailDate =
        (this.props.searchedDate && new Date(this.props.searchedDate.date)) ||
        new Date();
      const green = this.addDays(SearchAvailDate, AvailablityGreenCriteria); //SearchAvailDate.getDate() + 14;
      const yallow = this.addDays(SearchAvailDate, AvailablityYellowCriteria); //SearchAvailDate.getDate() + 60;

      if (ResumeBusyUntilDate <= green) {
        return "markGreen";
      } else if (ResumeBusyUntilDate > green && ResumeBusyUntilDate < yallow) {
        return "markYellow";
      } else if (ResumeBusyUntilDate >= yallow) {
        return "markRed";
      }
    } else {
      return "markYellow";
    }
  };
  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  getAvailable = (value) => {
    const availableDate = value.AvailabilityDate;
    const ResumeBusyUntilDate = new Date(availableDate);
    const SearchAvailDate = new Date();

    const green = this.addDays(SearchAvailDate, 14);
    const yallow = this.addDays(SearchAvailDate, 60);
    const { labels } = this.props;
    if (ResumeBusyUntilDate <= green) {
      return labels.ResumeAvailabilityGreenText;
    } else if (ResumeBusyUntilDate > green && ResumeBusyUntilDate < yallow) {
      return labels.ResumeAvailabilityYallowText;
    } else if (ResumeBusyUntilDate >= yallow) {
      return labels.ResumeAvailabilityRedText;
    } else {
      return labels.ResumeHeadingTypeMonthNotice;
    }
  };
  GetExperianceLevel = (value) => {
    switch (value) {
      case 1:
        return "Beginner";
      case 2:
        return "Proficient";
      case 3:
        return "Expert";
    }
  };

  allItemsWithCommaSeprated = (data) => {
    var item = "";
    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (i != data.length - 1) item += data[i].KeywordValue + ", ";
        else item += data[i].KeywordValue + ".";
      }

      item = item.substr(0, item.length - 1);
    }
    return item;
  };
  GetLanguageLevel = (value) => value;
  GetExperienceYears = (value) => value;

  render() {
    const {
      values = {},
      resume,
      labels,
      showCorrelation,
      showAvailableTitle = true,
    } = this.props;
    const {
      Region,
      CorrelationScore,
      Profiles,
      Skills,
      Certifications,
      Educations,
      Industries,
      Languages,
      ResumeExperience,
      ResumeOtherAchivenments,
      Keywords,
    } = resume;
    return (
      <div className="shortlist-resume-detail-component">
        <div className="ResumeListWrap">
          {showAvailableTitle && (
            <div className="AvailableTitle">
              {showCorrelation && (
                <span className="TotalSkill">{CorrelationScore}</span>
              )}
              <span className={`AvailableHeading ${this.availability(resume)}`}>
                {this.getAvailable(resume)}
              </span>
            </div>
          )}
          <Accordion heading={labels.oppResumeDetailProfile}>
            <ul className="ResumeList roles-list">
              {Profiles &&
                Profiles.map((item, index) => (
                  <li key={index} className="ResumeItem">
                    {showCorrelation ? (
                      <div className="SkillNumber">{item.Score}</div>
                    ) : (
                      ""
                    )}
                    <div className="SkillName">{item.ProfileValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperianceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion>

          <Accordion heading={labels.oppResumeDetailSkill}>
            <ul className="ResumeList skills-list">
              {Skills &&
                Skills.map((item, index) => (
                  <li key={index} className="ResumeItem">
                    {showCorrelation && (
                      <div className="SkillNumber">{item.Score}</div>
                    )}
                    <div className="SkillName">{item.SkillValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperianceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion>

          <Accordion heading={labels.oppResumeDetailCertification}>
            <ul className="ResumeList certifications-list">
              {Certifications &&
                Certifications.map((item, index) => (
                  <li key={index} className="ResumeItem">
                    {showCorrelation && (
                      <div className="SkillNumber">{item.Score}</div>
                    )}
                    <div className="SkillName">{item.CertificationValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {moment(item.CertificationDate).year()}
                      </span>
                    </div>
                    {/* <div className="Experience">{}</div> */}
                  </li>
                ))}
            </ul>
          </Accordion>

          <Accordion heading={labels.oppResumeDetailEducation}>
            <ul className="ResumeList educations-list">
              {Educations &&
                Educations.map((item, index) => (
                  <li key={index} className="ResumeItem">
                    {showCorrelation && (
                      <div className="SkillNumber">{item.Score}</div>
                    )}
                    <div className="SkillName">{item.EducationValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">{item.EducationYear}</span>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion>
          <Accordion heading={labels.oppResumeDetailIndustry}>
            <ul className="ResumeList industries-list">
              {Industries &&
                Industries.map((item, index) => (
                  <li key={index} className="ResumeItem">
                    {showCorrelation && (
                      <div className="SkillNumber">{item.Score}</div>
                    )}
                    <div className="SkillName">{item.IndustryValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperianceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion>

          <Accordion heading={labels.oppResumeDetailKeywords}>
            <ul className="ResumeList keyword-list">
              {Keywords &&
                Keywords.map((item, index) => (
                  <li className="ResumeItem" key={index}>
                    {showCorrelation && (
                      <div className="SkillNumber">{item.Score}</div>
                    )}
                    <div className="SkillName">{item.KeywordValue}</div>
                  </li>
                ))}
            </ul>
          </Accordion>
          <Accordion heading={labels.oppResumeDetailLocation}>
            <ul className="ResumeList locations-list">
              {Region && (
                <li className="ResumeItem">
                  <div className="LocName">{Region}</div>
                </li>
              )}
            </ul>
          </Accordion>
          <Accordion heading={labels.oppResumeDetailLanguage}>
            <ul className="ResumeList languages-list">
              {Languages &&
                Languages.map((item, index) => (
                  <li key={index} className="ResumeItem">
                    <div className="SkillName">{item.LanguageValue}</div>
                    <div className="YearsCnt">
                      <span className="SkillYears">
                        {this.GetExperianceLevel(item.ExperienceLevel)}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </Accordion>

          <Accordion heading={labels.oppResumeDetailExperience}>
            <ul className="ResumeList experiences-list">
              {ResumeExperience.map((item, index) => (
                <ChildAccordion
                  key={index}
                  heading={
                    <Fragment>
                      <div className="CompanyName">{item.CompanyWorked}</div>
                      <div className="CompanyDate">
                        {item.StartDateYear}-
                        {item.EndDateYear === 0
                          ? htmlParser(labels.SHORTLIST_RESUME_CURRENT_WORKING)
                          : item.EndDateYear}
                      </div>
                    </Fragment>
                  }
                >
                  <div className="ResumeList otherAcheivements-list">
                    {item.IndustryValue && (
                      <div className="resumeProfile">
                        {labels.SHORTLIST_RESUME_INDUSTRY}
                        <div className="ExpName">{item.IndustryValue}</div>
                      </div>
                    )}
                    {item.ProfileValue && (
                      <div className="resumeProfile">
                        {labels.ROLE_LABEL}
                        <div className="ExpName">{item.ProfileValue}</div>
                      </div>
                    )}
                    {item.Description && (
                      <div className="resumeProfile">
                        {htmlParser(item.Description)}
                      </div>
                    )}
                  </div>
                </ChildAccordion>
              ))}
            </ul>
          </Accordion>

          <Accordion heading={labels.oppResumeDetailWorkshops}>
            <ul className="ResumeList">
              {ResumeOtherAchivenments.map((item, index) => (
                <ChildAccordion
                  key={index}
                  heading={<div className="CompanyName">{item.Title}</div>}
                >
                  <div className="ResumeList">
                    {item.AchivenmentName && (
                      <div className="resumeProfile">
                        {item.AchivenmentName}
                        <div className="ExpName">{item.Year}</div>
                      </div>
                    )}

                    {item.Description && (
                      <div className="resumeProfile">
                        {htmlParser(item.Description)}
                      </div>
                    )}
                  </div>
                </ChildAccordion>
              ))}
            </ul>
          </Accordion>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ systemLabel }) => {
  const { labels } = systemLabel;
  return { labels };
};
export default connect(mapStateToProps)(ShortlistResumeDetail);
