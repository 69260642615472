// import React from "react";
// import { Col, Divider, Drawer, Row, Space } from "antd";
// import { EnvironmentOutlined, LeftOutlined } from "@ant-design/icons";
// import { join } from "lodash";

// const DetailModalCreateOpportunity = ({ open, handleCloseModal }) => {
//   const onClose = () => {
//     handleCloseModal();
//   };
//   const scoreStyle = {
//     color: "#8F82F5",
//     fontWeight: 600,
//     marginRight: "4px",
//     padding: "4px 10px",
//     borderRadius: "4px",
//     height:'fit-content',
//     background: "rgb(240, 238, 255)",
//   };

//   const GetExperienceLevel = (value) => {
//     switch (value) {
//       case 1:
//         return "Beginner";
//       case 2:
//         return "Proficient";
//       case 3:
//         return "Expert";
//       default:
//         return "Expert";
//     }
//   };

//   const GetYear = (value) => {
//     var d = new Date(value);
//     return d.getFullYear();
//   };

//   const renderValue = (resume) => {
//     const ProfileValue = resume?.map((item) => item?.ProfileValue);
//     return join(ProfileValue, ", ");
//   };

//   return (
//     <>
//       <Drawer
//         title={
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               cursor: "pointer",
//             }}
//             onClick={() => {
//               onClose();
//             }}
//           >
//             <LeftOutlined className="resume-detail-title" />
//             <span className="resume-detail-title" style={{ marginLeft: "8px" }}>
//               Resume Detail
//             </span>
//           </div>
//         }
//         className="new-detail-modal new-search-resume-main"
//         placement="right"
//         width={"450px"}
//         zIndex={101}
//         closable={false}
//         onClose={() => onClose()}
//         open={open?.detailModal}
//       >
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//           }}
//           className="title-main-resumes"
//         >
//           <p
//             className="list-item-title"
//             style={{
//               flex: 1,
//             }}
//           >
//             {renderValue(open?.data?.Profiles)}
//           </p>
//         </div>
//         <Row
//           gutter={16}
//           style={{
//             marginTop: "5px",
//           }}
//         >
//           <Col span={20}>
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//               }}
//             >
//               <EnvironmentOutlined
//                 size={"large"}
//                 style={{
//                   fontSize: "large",
//                 }}
//               />
//               <p
//                 className="location-"
//                 style={{
//                   marginLeft: "12px",
//                 }}
//               >
//                 {open?.data?.Region}
//               </p>
//             </div>
//           </Col>
//         </Row>
//         <Divider />
//         <Row className="detail-content-main">
//           {open?.data?.Profiles?.length > 0 && (
//             <div className="detail-content-inner">
//               <p className="role-title">Role</p>
//               <div className="skill-main">
//                 {open?.data?.Profiles?.map((single, index) => (
//                   <div className="flex-" key={index}>
//                      <span
//                       style={scoreStyle}
//                     >
//                       {single?.Score || 0}
//                     </span>
//                     <p className="skill-content">{single?.ProfileValue}</p>
//                     <p className="btn-role">
//                       {GetExperienceLevel(single?.ExperienceLevel)}
//                     </p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//           {open?.data?.Skills?.length > 0 && (
//             <div className="detail-content-inner">
//               <p className="role-title">Skill</p>
//               <div className="skill-main">
//                 {open?.data?.Skills?.map((single, index) => (
//                   <div className="flex-" key={index}>
//                       <span
//                       style={scoreStyle}
//                     >
//                       {single?.Score || 0}
//                     </span>
//                     <p className="skill-content">{single?.SkillValue}</p>
//                     <p className="btn-role">
//                       {GetExperienceLevel(single?.ExperienceLevel)}
//                     </p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//           {open?.data?.Certifications?.length > 0 && (
//             <div className="detail-content-inner">
//               <p className="role-title">Certification and Education</p>
//               <div className="skill-main">
//                 {open?.data?.Certifications?.map((single, index) => (
//                   <div className="flex-" key={index}>
//                       <span
//                       style={scoreStyle}
//                     >
//                       {single?.Score || 0}
//                     </span>
//                     <p className="skill-content">
//                       {single?.CertificationValue}
//                     </p>
//                     <p className="btn-role">
//                       {GetYear(single?.CertificationDate)}
//                     </p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//           {open?.data?.Educations?.length > 0 && (
//             <div className="detail-content-inner">
//               <p className="role-title">Education</p>
//               <div className="skill-main">
//                 {open?.data?.Educations?.map((single, index) => (
//                   <div className="flex-" key={index}>
//                       <span
//                       style={scoreStyle}
//                     >
//                       {single?.Score || 0}
//                     </span>
//                     <p className="skill-content">{single?.EducationValue}</p>
//                     <p className="btn-role">{single?.EducationYear}</p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//           {open?.data?.Industries?.length > 0 && (
//             <div className="detail-content-inner">
//               <p className="role-title">Industry</p>
//               <div className="skill-main">
//                 {open?.data?.Industries?.map((single, index) => (
//                   <div className="flex-" key={index}>
//                       <span
//                       style={scoreStyle}
//                     >
//                       {single?.Score || 0}
//                     </span>
//                     <p className="skill-content">{single?.IndustryValue}</p>
//                     <p className="btn-role">
//                       {GetExperienceLevel(single?.ExperienceLevel)}
//                     </p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//           {open?.data?.Location?.length > 0 && (
//             <div className="detail-content-inner">
//               <p className="role-title">Location</p>
//               <div className="skill-main">
//                 {open?.data?.Location?.map((single, index) => (
//                   <div className="flex-" key={index}>
//                       <span
//                       style={scoreStyle}
//                     >
//                       {single?.Score || 0}
//                     </span>
//                     <p className="skill-content">{single?.IndustryValue}</p>
//                     <p className="btn-role">Expert</p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//           {open?.data?.Languages?.length > 0 && (
//             <div className="detail-content-inner">
//               <p className="role-title">Languages</p>
//               <div className="skill-main">
//                 <Space size={[8, 8]} wrap style={{ marginTop: "5px" }}>
//                   {open?.data?.Languages?.map((single, index) => (
//                     <button className="btn-action" key={index}>
//                       {single?.LanguageValue}
//                     </button>
//                   ))}
//                 </Space>
//               </div>
//             </div>
//           )}
//           {open?.data?.Keywords?.length > 0 && (
//             <div className="detail-content-inner">
//               <p className="role-title">Keywords</p>
//               <div className="skill-main">
//                 <Space size={[8, 8]} wrap style={{ marginTop: "5px" }}>
//                   {open?.data?.Keywords?.map((single, index) => (
//                     <button className="btn-action" key={index}>
//                       {single?.KeywordValue}
//                     </button>
//                   ))}
//                 </Space>
//               </div>
//             </div>
//           )}
//           {open?.data?.ResumeExperience?.length > 0 && (
//             <div className="detail-content-inner">
//               <p className="role-title">Professional experience</p>
//               <div className="skill-main">
//                 {open?.data?.ResumeExperience?.map((single, index) => (
//                   <div className="flex-" key={index}>
//                       <span
//                       style={scoreStyle}
//                     >
//                       {single?.Score || 0}
//                     </span>
//                     <p className="skill-content">{single?.CompanyWorked}</p>
//                     <p className="btn-role">{single?.ProfileValue}</p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//           {open?.data?.ResumeOtherAchivenments?.length > 0 && (
//             <div className="detail-content-inner">
//               <p className="role-title">Achivenments</p>
//               <div className="skill-main">
//                 {open?.data?.ResumeOtherAchivenments?.map((single, index) => (
//                   <div className="flex-" key={index}>
//                       <span
//                       style={scoreStyle}
//                     >
//                       {single?.Score || 0}
//                     </span>
//                     <p className="skill-content">{single?.AchivenmentName}</p>
//                     <p className="btn-role">{single?.Title}</p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}
//         </Row>
//       </Drawer>
//     </>
//   );
// };
// export default DetailModalCreateOpportunity;


import React from "react";
import { Button, Col, Divider, Drawer, Dropdown, Grid, Progress, Row, Space, Typography } from "antd";
import closeIcon from '../../../../../assets/images/Icons/close-new.png'
import { EnvironmentOutlined, LeftOutlined } from "@ant-design/icons";
import DateIcon from '../../../../../assets/images/Icons/calendar.png'

import { join } from "lodash";
import moment from "moment";
const { useBreakpoint } = Grid;
const { Title, Text } = Typography

const DetailModalCreateOpportunity = ({ open, handleCloseModal }) => {
  const screens = useBreakpoint();
  const scoreStyle = {
    color: "#8F82F5",
    fontWeight: 600,
    marginRight: "4px",
    padding: "0px 10px",
    borderRadius: "4px",
    height: 'fit-content',
    fontSize: "18px"
  };

  const onClose = () => {
    handleCloseModal()
  };

  const GetExperienceLevel = (value) => {
    switch (value) {
      case 1:
        return "Beginner";
      case 2:
        return "Proficient";
      case 3:
        return "Expert";
      default:
        return "Expert";
    }
  };

  const GetYear = (value) => {
    var d = new Date(value);
    return d.getFullYear();
  };

  const renderValue = (resume) => {
    const ProfileValue = resume?.map((item) => item?.ProfileValue);
    return join(ProfileValue, ", ");
  };

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => {
              onClose();
            }}
          >
            <span className="resume-detail-title" >
              Resume Detail
            </span>
            <div>
              <img src={closeIcon} alt="" />
            </div>
          </div>
        }
        className="new-detail-modal new-search-resume-main"
        placement="right"
        width={"500px"}
        zIndex={101}
        closable={false}
        onClose={() => handleCloseModal()}
        open={open?.detailModal}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="title-main-resumes"
        >
          <p
            className="list-item-title"
            style={{
              flex: 1,
            }}
          >
            {renderValue(open?.data?.Profiles)}
          </p>
        </div>
        <Row
          gutter={16}
          style={{
            marginTop: "5px",
          }}
        >
          <Col span={20}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <EnvironmentOutlined
                size={"large"}
                style={{
                  fontSize: "large",
                }}
              />
              <p
                className="location-"
                style={{
                  marginLeft: "12px",
                }}
              >
                {open?.data?.Region}
              </p>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row className="detail-content-main">
          <div className="detail-content-inner">
            <div className="skill-main">
              <div >
                <p className="role-title">Availability Date</p>
                <div className=" d-flex align-items-center gap-8">
                  <div>
                    <img src={DateIcon} alt=""
                    />                  </div>
                  <p style={{ color: "#000", fontWeight: 600, fontSize: "14px", margin: 0 }}>
                    {moment(open?.data?.AvailabilityDate).format(
                      "DD-MM-YYYY hh:mm"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {open?.data?.Profiles?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Role</p>
              <div className="skill-main">
                {open?.data?.Profiles?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.ProfileValue}</p>
                    <p className="btn-role">
                      {GetExperienceLevel(single?.ExperienceLevel)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Skills?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Skill</p>
              <div className="skill-main">
                {open?.data?.Skills?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.SkillValue}</p>
                    <p className="btn-role">
                      {GetExperienceLevel(single?.ExperienceLevel)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Certifications?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Certification and Education</p>
              <div className="skill-main">
                {open?.data?.Certifications?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">
                      {single?.CertificationValue}
                    </p>
                    <p className="btn-role">
                      {GetYear(single?.CertificationDate)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Educations?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Education</p>
              <div className="skill-main">
                {open?.data?.Educations?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.EducationValue}</p>
                    <p className="btn-role">{single?.EducationYear}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Industries?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Industry</p>
              <div className="skill-main">
                {open?.data?.Industries?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.IndustryValue}</p>
                    <p className="btn-role">
                      {GetExperienceLevel(single?.ExperienceLevel)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Location?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Location</p>
              <div className="skill-main">
                {open?.data?.Location?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.IndustryValue}</p>
                    <p className="btn-role">Expert</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.Languages?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Languages</p>
              <div className="skill-main">
                <Space size={[8, 8]} wrap style={{ marginTop: "5px" }}>
                  {open?.data?.Languages?.map((single, index) => (
                    <button className="btn-action" key={index}>
                      {single?.LanguageValue}
                    </button>
                  ))}
                </Space>
              </div>
            </div>
          )}
          {open?.data?.Keywords?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Keywords</p>
              <div className="skill-main">
                <Space size={[8, 8]} wrap style={{ marginTop: "5px" }}>
                  {open?.data?.Keywords?.map((single, index) => (
                    <button className="btn-action" key={index}>
                      {single?.KeywordValue}
                    </button>
                  ))}
                </Space>
              </div>
            </div>
          )}
          {open?.data?.ResumeExperience?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Professional experience</p>
              <div className="skill-main">
                {open?.data?.ResumeExperience?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.CompanyWorked}</p>
                    <p className="btn-role">{single?.ProfileValue}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {open?.data?.ResumeOtherAchivenments?.length > 0 && (
            <div className="detail-content-inner">
              <p className="role-title">Achivenments</p>
              <div className="skill-main">
                {open?.data?.ResumeOtherAchivenments?.map((single, index) => (
                  <div className="flex-" key={index}>
                    <span
                      style={scoreStyle}
                    >
                      {single?.Score || 0}
                    </span>
                    <p className="skill-content">{single?.AchivenmentName}</p>
                    <p className="btn-role">{single?.Title}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Row>
      </Drawer>
    </>
  );
};
export default DetailModalCreateOpportunity;
