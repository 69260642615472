
import React from 'react';
import './list-item.scss';

export const ListItem = ({ onClick, className, testId, onDelete, children, item,timeSheetStatusId }) => (
      <div test-data-id={`timelog-list`} 
        className={`tictell-list-item-container ${className} ${
          item.isSelected ? "selected-item" : ""
        } ${item.isActive ? "activeItem" : ""}`}
        onClick={() => onClick(item)}>
          {children}
      </div>
  );