import React, { Component } from "react";
import { Panel, Draggable } from "../../../../common";
import ReactHtmlParser from "react-html-parser";
import { ApiUrl } from "../../../../api";
import { Link } from "react-router-dom";

class SuggestedCertification extends Component {
  // replaceAll(str, find, replace) {
  //   return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
  // }
  render() {
    const {
      suggestedCertificationsList,
      onSuggesstedCertificationAdd,
      suggestObject,
      labels
    } = this.props;
    let { RESUME_EDIT_SUGGESTED_CERTIFICATION_MESSAGE } = this.props.labels;
    return (
      <>
        {suggestedCertificationsList && suggestedCertificationsList.length > 0 && (
          <div
            test-data-id="resume-edit-certification-suggestion-availible-message"
            className="sub-heading"
          >
            {`Suggested Certification for ${suggestObject.Value}`}{" "}
          </div>
        )}

        {suggestedCertificationsList &&
          suggestedCertificationsList.length === 0 &&
          Object.keys(suggestObject).length !== 0 &&
          suggestObject.Type === "Certification" && (
            <div
              test-data-id="resume-edit-certification-suggestion-not-availible-message"
              className="add-correletion-text"
            >
              <div className="sub-heading">
                {`Suggested Certification for ${suggestObject.Value}`}
              </div>
              <p>
                {ReactHtmlParser(
                  labels.RESUME_EDIT_SUGGESTED_CERTIFICATION_MESSAGE.replace(
                    /ddCERTIFICATIONNAME/g,
                    suggestObject.Value
                  )
                )}
                <a
                  href={`${window.location.origin}/BI/#certification/${
                    suggestObject.Id
                  }`}
                  target="_blank"
                >
                  {ReactHtmlParser(
                    labels.RESUME_EDIT_SUGGESTED_CERTIFICATION_URL_MESSAGE
                  )}
                </a>
              </p>
            </div>
          )}
        {suggestedCertificationsList &&
          suggestedCertificationsList.map(item => (
            <label
              test-data-id="resume-edit-suggested-certification-item"
              className="draggable"
              onClick={() =>
                onSuggesstedCertificationAdd(
                  item.CertificationId,
                  item.CertificationValue
                )
              }
              key={item.CertificationId}
            >
              {item.CertificationValue}
            </label>
          ))}
      </>
    );
  }
}

export default SuggestedCertification;
