import React, { Component } from "react";
import { connect } from "react-redux";
import Collaboration from "./common/Collaboration";
import { notificationAction } from "../../actions";

class IproCollaboration extends Component {
  render() {
    const {
      userId,
      labels,
      IsFreelancer,
      notificationAction,
      isHelpActive,
      location
    } = this.props;
    const {
      IproCollaborationSearchPlaceholder,
      IproCollaborationDetailHeadingText,
      TooltipIproCollaborationListExpand,
      TooltipIproCollaborationListCollapse,
      HlpTooltipIproCollaborationListExpand,
      HlpTooltipIproCollaborationListCollapse,
      IproCollaborationListColumnText,

      TooltipIproCollaborationDetailExpand,
      TooltipIproCollaborationDetailCollapse,
      HlpTooltipIproCollaborationDetailExpand,
      HlpTooltipIproCollaborationDetailCollapse,
      IproCollaborationDetailColumnText,

      IproCollaborationCompanyDetailHeaderText,
      TooltipIproCollaborationCompanyDetailExpand,
      TooltipIproCollaborationCompanyDetailCollapse,
      HlpTooltipIproCollaborationCompanyDetailExpand,
      HlpTooltipIproCollaborationCompanyDetailCollapse,
      IproCollaborationCompanyDetailColumnText,

      IproCollaborationCompanyAddressLabel,
      IproCollaborationCompanyIndustryLabel,
      IproCollaborationCompanyCountryLabel,
      IproCollaborationCompanyPhoneLabel,
      IproCollaborationCompanyWebUrlLabel,
      IproCollaborationCompanyVatLabel,

      IproCollaborationStartDateLabel,
      IproCollaborationDurationLabel,
      IproCollaborationCompensationLabel,
      IproCollaborationCompanyLabel,
      IproCollaborationDescriptionLabel,
      IproCollaborationAcceptedLabel,
      IproCollaborationInterestOrNotLabel,
      IproCollaborationEmptyInfo,
      InfoIProCollaborationAccepted,
      InfoIProCollaborationDeclined,
      InfoIProCollaborationDeleted,
      InfoSearcherCollaborationClosed,
      InfoSearcherSentCollaborationDeleteConfirmationMsg,
      InfoSearcherSentCollaborationCloseConfirmationMsg,
      likeToRateCollaborationMessage,
    } = labels;
    return (
      <Collaboration
        IsFreelancer={IsFreelancer}
        isHelpActive={isHelpActive}
        isIproCollaboration
        isIproActiveCollaboration
        notificationAction={notificationAction}
        url={`GetAllCollaboration/?IsAccepted=true`}
        // list section system label
        searchInputPlaceholder={IproCollaborationSearchPlaceholder}
        toolTipExpandList={TooltipIproCollaborationListExpand}
        helpTooltipExpandList={HlpTooltipIproCollaborationListExpand}
        toolTipCollapseList={TooltipIproCollaborationListCollapse}
        helpTooltipCollapseList={HlpTooltipIproCollaborationListCollapse}
        listColumnText={IproCollaborationListColumnText}
        // detail section system label
        detailHeadingText={IproCollaborationDetailHeadingText}
        toolTipExpandDetail={TooltipIproCollaborationDetailExpand}
        helpTooltipExpandDetail={HlpTooltipIproCollaborationDetailExpand}
        toolTipCollapseDetail={TooltipIproCollaborationDetailCollapse}
        helpTooltipCollapseDetail={HlpTooltipIproCollaborationDetailCollapse}
        detailsColumnText={IproCollaborationDetailColumnText}
        // selected user section system label
        userDetailHeadingText={IproCollaborationCompanyDetailHeaderText}
        toolTipExpandUserDetail={TooltipIproCollaborationCompanyDetailExpand}
        helpTooltipExpandUserDetail={
          HlpTooltipIproCollaborationCompanyDetailExpand
        }
        toolTipCollapseUserDetail={
          TooltipIproCollaborationCompanyDetailCollapse
        }
        helpTooltipCollapseUserDetail={
          HlpTooltipIproCollaborationCompanyDetailCollapse
        }
        userDetailsColumnText={IproCollaborationCompanyDetailColumnText}
        emptyCollaorationInfo={IproCollaborationEmptyInfo}
        startDateLabel={IproCollaborationStartDateLabel}
        durationLabel={IproCollaborationDurationLabel}
        compensationLabel={IproCollaborationCompensationLabel}
        companyLabel={IproCollaborationCompanyLabel}
        descriptionLabel={IproCollaborationDescriptionLabel}
        acceptedCollaboration={IproCollaborationAcceptedLabel}
        selectInterest={IproCollaborationInterestOrNotLabel}
        onCollaborationDelete={this.handleCollaborationDelete}
        companyAddressLabel={IproCollaborationCompanyAddressLabel}
        comapnyIndustryLabel={IproCollaborationCompanyIndustryLabel}
        companyCountryLabel={IproCollaborationCompanyCountryLabel}
        companyPhoneLabel={IproCollaborationCompanyPhoneLabel}
        companyWebUrlLabel={IproCollaborationCompanyWebUrlLabel}
        companyVatLabel={IproCollaborationCompanyVatLabel}
        collaborationAcceptedMsg={InfoIProCollaborationAccepted}
        collaborationDeclinedMsg={InfoIProCollaborationDeclined}
        collaborationDeletedMsg={InfoIProCollaborationDeleted}
        listCollapsedTestId={"listCollapsedTestId1"}
        listCollapsedHelpTestId={"listCollapsedTestId2"}
        listExpandTestId={"listCollapsedTestId3"}
        listExpandHelpTestId={"listCollapsedTestId4"}
        detailCollapsedTestId={"listCollapsedTestId5"}
        detailCollapsedHelpTestId={"listCollapsedTestId6"}
        detailExpandTestId={"listCollapsedTestId7"}
        detailExpandHelpTestId={"listCollapsedTestId8"}
        userDetailCollapsedTestId={"listCollapsedTestId9"}
        userDetailCollapsedHelpTestId={"listCollapsedTestId10"}
        userDetailExpandTestId={"listCollapsedTestId11"}
        userDetailExpandHelpTestId={"listCollapsedTestId12"}
        searchInputTestId="search-input"
        locationProp={location}
        collaborationClosedMsg={InfoSearcherCollaborationClosed}
        likeToRateCollaborationMessage = {likeToRateCollaborationMessage}
        collaborationDeleteConfirmation={
          InfoSearcherSentCollaborationDeleteConfirmationMsg
        }
        collaborationCloseConfirmation={
          InfoSearcherSentCollaborationCloseConfirmationMsg
        }
        popupYesText={"Yes"}
        popupNoText={"No"}
      />
    );
  }
}

const mapStateToProps = ({ systemLabel, userInfo, navigation }) => {
  const { labels } = systemLabel;
  const { user } = userInfo;
  const { isHelpActive } = navigation;
  let { UserId, IsFreelancer } = user;
  UserId =
    UserId !== undefined ? UserId : parseInt(sessionStorage.getItem("userId"));
  IsFreelancer =
    IsFreelancer !== undefined
      ? IsFreelancer
      : JSON.parse(localStorage.getItem("IsFreelancer"));
  return {
    labels,
    userId: UserId,
    IsSearcher: !IsFreelancer,
    IsFreelancer,
    isHelpActive
  };
};

const actions = { notificationAction };
export default connect(
  mapStateToProps,
  actions
)(IproCollaboration);
