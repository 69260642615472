import { Input, Row, Space } from "antd";
import React from "react";
class NetworkForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      onFormFieldChange,
      networkData,
      labels,
      handleNetworkInvitationSend,
    } = this.props;
    const { Email } = networkData;
    return (
      <div className="container-collapse">
        <div className="new-collapse-main">
          <p className="collapse-title">Email</p>
        </div>
        <Input
          size="large"
          type="text"
          name="Email"
          className={` ${!Email ? "inValid" : ""}`}
          style={{ background: "#fff", borderRadius: "5px" }}
          value={Email ? Email : ""}
          placeholder={labels.NETWORK_EMAIL_PLACEHOLDER_LABEL}
          onChange={onFormFieldChange}
          onBlur={onFormFieldChange}
          bordered={false}
          maxLength="50"
        />
        <Row className="">
          <Space
            size={[8, 8]}
            style={{
              margin: "auto",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <button
              className="button-footer-opportunity pl-4 pr-4"
              onClick={() => handleNetworkInvitationSend()}
            >
              {"Save"}
            </button>
          </Space>
        </Row>
      </div>
    );
  }
}

export default NetworkForm;
