import React from "react";
import { Modal } from "antd";
import NewUserDetail from "./newUserDetail";
import NewRatingView from "./newRatingView";
import CompanyDetailNew from "../../../../common/companyDetail/companyDetail";

const UserDetailModal = (props) => {
  const {
    selectedUser,
    setIsModalOpen,
    isModalOpen,

    reviewQuestions,
    onRatingChange,
    onCloseCollaboration,
    onSubmit,
    isAlreadyRated,
    starRatedColor = "#9783cf",
    starHoverColor = "#dfd9f7",
    options,
    selectedCollaboration,
    CollborationCloseReasonId,
    labels,
    onFormSelectChange,
    isInActiveView,
    location,
    IsSearcher,
    selectedCompany,
    isRatingView,
    addressLabel,
    industryLabel,
    countryLabel,
    phoneLabel,
    webUrlLabel,
    vatLabel,
  } = props;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        style={{ top: 20 }}
        onOk={handleOk}
        zIndex={100}
        onCancel={handleCancel}
        footer={false}
        className="new-search-resume-main for-mobile-modal-shortlist"
      >
        <div style={{ marginTop: "5px" }} className="new-search-resume-main">
          <div className="h-100 flex flex-column">
            <div
              style={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ overflow: "auto" }}>
                {!IsSearcher && !isRatingView ? (
                  <CompanyDetailNew
                    selectedCompany={selectedCompany}
                    addressLabel={addressLabel}
                    industryLabel={industryLabel}
                    countryLabel={countryLabel}
                    phoneLabel={phoneLabel}
                    webUrlLabel={webUrlLabel}
                    vatLabel={vatLabel}
                  />
                ) : (
                  <>
                    {isRatingView && (
                      <NewRatingView
                        selectedCollaboration={selectedCollaboration}
                        onFormSelectChange={onFormSelectChange}
                        options={options}
                        onCloseCollaboration={onCloseCollaboration}
                        CollborationCloseReasonId={CollborationCloseReasonId}
                        reviewQuestions={reviewQuestions}
                        onSubmit={onSubmit}
                        onRatingChange={onRatingChange}
                        isAlreadyRated={props.isAlreadyRated}
                        isInActiveView={isInActiveView}
                      />
                    )}
                    {!isRatingView && (
                      <NewUserDetail
                        selectedUser={selectedUser}
                        labels={labels}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default UserDetailModal;
