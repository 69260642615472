export const RoleProficiencyList = [
  { value: 1, label: "Beginner" },
  { value: 2, label: "Proficient" },
  { value: 3, label: "Expert" },
];

export const EmptySearchList = {
  roles: [],
  skills: [],
  keywords: [],
  industries: [],
  certifications: [],
  countries: [],
  languages: [],
};

export const AvailablityGreenCriteria = 14;
export const AvailablityYellowCriteria = 60;
