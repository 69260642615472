import React from "react";
import { Input } from "../../../common";
class NetworkForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      onFormFieldChange,
      networkData,
      labels,
    } = this.props;
    const { Email } = networkData;
    return (
      <div className="network-save-form">
        <div className="form-row">
            <label className="form-label">{labels.EMAIL_LABEL}:</label>
            <Input
              // autoFocus={invalidCompanyName}
              type="text"
              name="Email"
              className={`input-text ${!Email ? "inValid" : ""}`}
              value={Email ? Email : ""}
              placeholder={labels.NETWORK_EMAIL_PLACEHOLDER_LABEL}
              onChange={onFormFieldChange}
              test-data-id="input-text-company-name"
              maxLength="50"
            />
          </div>
      </div>
    );
  }
}

export default NetworkForm;
