import React, { Component } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "../../../components";
import { Column, ConfirmDialog, Button, EmptyInfo } from "../../../common";
import { notificationAction } from "../../../actions";
import isEmpty from "lodash/isEmpty";
import {
  addCollaborationApi,
  getAllOpportunitiesApi,
  getOpportunityIprosResumeApi,
  getCurrenciesApi,
} from "../common/collaborationApi";
import moment from "moment";
import map from "lodash/map";
import CreateNewCollaborationForm from "./components/createNewCollobration/createForm";
import { Col, Row, Typography } from "antd";
import NewUserDetail from "../common/components/newUserDetail";
import CreateCollobrationModal from "../common/components/mobileModal";
import { onStateChangeAction } from "../searcher/SearcherCollaborationCreateAction";
import ArrowLeft from "../../../assets/images/Icons/arrow-left.png";
import { withRouter } from "react-router-dom";

const { Title } = Typography

class NewCreateCollaboration extends Component {
  state = {
    Currencies: [],
    mobileModal: false,
  };

  componentDidMount() {
    this.getCurrencies();
    this.getOpportunities();
    this.networkToCollaboration();
  }

  networkToCollaboration = () => {
    const RequestId = JSON.parse(localStorage.getItem("OpportunityId"));
    const ResumeId = JSON.parse(localStorage.getItem("ResumeId"));
    if (RequestId && ResumeId) {
      const selectedOpportunity = {
        RequestId: RequestId,
        value: RequestId,
      };
      this.handleFormSelectChange("OpprtunityId", selectedOpportunity);
      const { collaborationForm } = this.props;
      this.props.onStateChangeAction({
        collaborationForm: {
          ...collaborationForm,
          invalidRequestId: false,
          Request: RequestId,
          RequestId,
          invalidResumeId: false,
          ResumeId: ResumeId,
          ResumeId,
        },
      });
    }
  };

  componentWillUnmount() {
    localStorage.removeItem("OpportunityId");
    localStorage.removeItem("ResumeId");
    this.props.onStateChangeAction({
      selectedIpro: {},
      iprosList: [],
    });
  }

  getCurrencies() {
    getCurrenciesApi()
      .then((res) => {
        if (res.success) {
          this.setState({
            Currencies: res.items.map((item) => ({
              ...item,
              value: item.CurrencyId,
              label: item.Name,
            })),
          });
        }
      })
      .catch((err) => console.log("Err ", err));
  }

  getOpportunities = () => {
    getAllOpportunitiesApi(0).then((data) => {
      if (data.success) {
        const opportunityList = data.items.Sent.map((item) => ({
          ...item,
          value: item.RequestId,
          label: item.RequestName,
        }));
        this.props.onStateChangeAction({ opportunityList });
      }
    });
  };

  getOpportunityIProsResume = (requestId) => {
    getOpportunityIprosResumeApi(requestId).then((data) => {
      if (data.success) {
        const iprosList = map(data.items.user, (item) => {
          const newItem = {
            ...item,
            value: item.ResumeId,
            label: item.UserName,
          };

          return newItem;
        });
        this.props.onStateChangeAction({
          iprosList,
        });
      }
    });
  };

  handleFormSelectChange = (name, selectedOption) => {
    if (!selectedOption) return;
    const { collaborationForm } = this.props;
    const { value, RequestId } = selectedOption;
    if (name === "OpprtunityId") {
      if (RequestId) {
        this.getOpportunityIProsResume(RequestId);
        this.props.onStateChangeAction({
          collaborationForm: {
            ...collaborationForm,
            invalidRequestId: false,
            Request: RequestId,
            RequestId,
          },
        });
        return;
      }
    }
    const { ResumeId } = selectedOption;
    if (name === "ResumeId") {
      if (ResumeId) {
        this.props.onStateChangeAction({
          collaborationForm: {
            ...collaborationForm,
            invalidResumeId: false,
            Resume: ResumeId,
            ResumeId,
          },
          selectedIpro: this.props.iprosList.find(
            (item) => item.ResumeId === ResumeId
          ),
        });
        return;
      }
    }
    if (name === "HourlyRateType") {
      if (value) {
        this.props.onStateChangeAction({
          collaborationForm: {
            ...collaborationForm,
            invalidHourlyRateType: false,
            HourlyRateType: value,
            value,
          },
        });
        return;
      }
    }
    if (name === "DurationType") {
      if (value) {
        this.props.onStateChangeAction({
          collaborationForm: {
            ...collaborationForm,
            invalidDurationType: false,
            DurationType: value,
            value,
          },
        });
        return;
      }
    }
  };

  handleDateChange = (date) => {
    this.props.onStateChangeAction({
      collaborationForm: {
        ...this.props.collaborationForm,
        StartDate: date || null, // Set to null or "" when clearing
        invalidStartDate: !date, // Validation logic
      },
    });
    this.forceUpdate(); // Force update if necessary
  };


  handleSliderChange = (value) => {
    const { collaborationForm } = this.props;
    if (value) {
      this.props.onStateChangeAction({
        collaborationForm: {
          ...collaborationForm,
          Duration: value,
          value,
        },
      });
    }
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "Title") {
      this.validateField({ name, value });
      return;
    }
    if (name === "Description") {
      this.validateField({ name, value });
      return;
    }
    if (name === "HourlyRate") {
      if (isNaN(value)) return;
      this.validateField({ name, value });
    }
  };

  validateSelect = (name) => {
    const { collaborationForm } = this.props;
    this.props.onStateChangeAction({
      collaborationForm: {
        ...collaborationForm,
        [`invalid${name}`]: true,
      },
    });
  };

  validateField = ({ name, value }) => {
    const { collaborationForm } = this.props;

    this.props.onStateChangeAction({
      collaborationForm: {
        ...collaborationForm,
        [name]: value,
        [`invalid${name}`]: !value,
      },
    });
  };

  makeMandatoryFieldRed = () => {
    const { collaborationForm } = this.props;
    this.props.onStateChangeAction({
      collaborationForm: {
        ...collaborationForm,
        invalidTitle: true,
        Title: "",

        invalidStartDate: true,
        StartDate: "",

        invalidDurationType: true,
        DurationType: "",

        invalidHourlyRate: true,
        HourlyRate: "",

        invalidRequestId: true,
        RequestId: "",

        invalidResumeId: true,
        ResumeId: "",

        invalidHourlyRateType: true,
        HourlyRateType: "",
      },
    });
  };

  handleCollaborationSave = () => {
    const { labels } = this.props;
    const { collaborationForm } = this.props;
    const { collTitleRequired } = labels;
    const {
      Title = "",
      HourlyRate = "",
      RequestId = "",
      ResumeId = "",
      Description = "",
      HourlyRateType = "",
      StartDate = "",
      DurationType = "",
      Duration = "4",
    } = collaborationForm;

    var collaboration = {
      Title,
      HourlyRate,
      HourlyRateType,
      RequestId,
      ResumeId,
      Description,
      StartDate,
      DurationType,
      Duration,
    };

    if (
      isEmpty(Title) &&
      !StartDate &&
      !DurationType &&
      isEmpty(HourlyRate) &&
      !HourlyRateType &&
      !RequestId &&
      !ResumeId
    ) {
      const info = {
        message: labels.COLLABORATION_CREATE_REQURIED_FIELD_MESSAGE,
        status: "error",
      };
      this.makeMandatoryFieldRed();
      this.props.notificationAction(info);
      return;
    }

    if (isEmpty(Title)) {
      const info = {
        message: collTitleRequired,
        status: "error",
      };
      this.validateField({ name: "Title", value: Title });
      this.props.notificationAction(info);
      return;
    }

    if (!StartDate) {
      const info = {
        message: labels.collStartDateRequired,
        status: "error",
      };
      this.validateField({ name: "StartDate", value: StartDate });
      this.props.notificationAction(info);
      return;
    }
    if (!DurationType) {
      const info = {
        message: labels.collDurationTypeRequired,
        status: "error",
      };
      this.validateField({ name: "DurationType", value: DurationType });
      this.props.notificationAction(info);
      return;
    }
    if (isEmpty(HourlyRate)) {
      const info = {
        message: "Hourly rate required",
        status: "error",
      };
      this.validateField({ name: "HourlyRate", value: HourlyRate });
      this.props.notificationAction(info);
      return;
    }
    if (!HourlyRateType) {
      const info = {
        message: labels.collCurrancyTypeRequired,
        status: "error",
      };
      this.validateField({ name: "HourlyRateType", value: HourlyRateType });
      this.props.notificationAction(info);
      return;
    }
    if (!RequestId) {
      const info = {
        message: labels.collOppRequired,
        status: "error",
      };
      this.validateField({ name: "RequestId", value: RequestId });
      this.props.notificationAction(info);
      return;
    }
    if (!ResumeId) {
      const info = {
        message: labels.collIproRequired,
        status: "error",
      };
      this.validateField({ name: "ResumeId", value: ResumeId });
      this.props.notificationAction(info);
      return;
    }
    this.props.onStateChangeAction({ isLoading: true });
    const duration = Duration + " " + DurationType;
    collaboration.Duration = duration;
    collaboration.Description = Description;
    collaboration.StartDate = moment(StartDate).format("MM/DD/YYYY");

    addCollaborationApi({ collaboration })
      .then(() => {
        const info = {
          message: labels.collSuccessfullySent,
          status: "success",
        };
        this.props.notificationAction(info);
        this.handleCreateNewClick();
      })
      .catch(() => {
        this.props.onStateChangeAction({ isLoading: false });
        const info = {
          message: "Error",
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  handleCreateNewClick = () => {
    this.props.onStateChangeAction({
      isLoading: false,
      collaborationForm: {
        Title: "",
        HourlyRate: "",
        RequestId: "",
        ResumeId: "",
        Description: "",
        StartDate: null,
        DurationType: "",
        Duration: "4",
        HourlyRateType: "",
        invalidTitle: false,
        invalidStartDate: false,
        invalidDurationType: false,
        invalidHourlyRate: false,
        invalidRequestId: false,
        invalidResumeId: false,
        invalidHourlyRateType: false,
      },
      selectedIpro: {},
      iprosList: [],
    });

    this.forceUpdate();
  };





  handleListOpenMobile = () => {
    this.setState({ mobileModal: true });
  };

  handleMobileModalClose = () => {
    this.setState({ mobileModal: false });
  };

  render() {
    const { labels, isHelpActive } = this.props;
    const {
      collaborationForm,
      formCollapsed,
      detailCollapsed,
      dialogMessage,
      isLoading,
      opportunityList,
      iprosList,
      selectedIpro,
    } = this.props;

    return (
      <PageWrapper className="create-collaboration-searcher-page collaboration">
        {dialogMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>{dialogMessage}</ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleYesClick}
              />
              <ConfirmDialog.Button
                className="dialog-btn"
                onClick={this.handleNoClick}
              />
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        {/* <CreateCollobrationModal
          isModalOpen={this.state.mobileModal}
          setIsModalOpen={this.handleMobileModalClose}
          selectedUser={selectedIpro}
          labels={labels}
        /> */}
        <div className="h-100 new-design-search w-100 d-flex flex-column">
          {this?.state?.mobileModal ?
            <>
              <div className="tabs-header-col">
                <div className="d-flex align-items-center gap-8 pointer"
                  onClick={() => {
                    this.handleMobileModalClose()
                  }}
                >
                  <div>
                    <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                  </div>
                </div>
              </div>
              <div className=" resume-list h-100" style={{ background: "#fff", borderRadius: "12px" }}>
                {isEmpty(selectedIpro) ? (
                  <EmptyInfo>{labels.EMPTY_IPRO_DETAIL}</EmptyInfo>
                ) : (
                  <NewUserDetail selectedUser={selectedIpro} labels={labels} />
                )}
              </div>
            </>
            :
            <>
              <div className="tabs-header-col">
                <div className="d-flex align-items-center gap-8 pointer"
                  onClick={() => {
                    this.props.history.goBack()
                  }}
                >
                  <div>
                    <img style={{ height: "16px" }} src={ArrowLeft} alt="" />
                  </div>
                  <Title level={5} className="m-0"> New Collaboration</Title>
                </div>
              </div>
              <Row className=" h-100" style={{ overflow: "auto" }}>
                <Col xs={24} md={16} className="collboration-column1">
                  <div className="h-100 d-flex flex-column">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "12px",
                        borderBottom: "1px solid #F3F3F3"
                      }}
                    >
                      <Title level={5} className="m-0 text-clamp flex-1">
                        {labels?.collaboration_details}
                      </Title>
                    </div>
                    <div
                      style={{
                        overflow: "auto",
                        padding: "12px",
                        flex: 1
                      }}
                    >
                      <CreateNewCollaborationForm
                        collaborationForm={collaborationForm}
                        onCollaborationSave={this.handleCollaborationSave}
                        onFormSelectChange={this.handleFormSelectChange}
                        labels={labels}
                        isLoading={isLoading}
                        onFormFieldChange={this.handleFormFieldChange}
                        onDateChange={this.handleDateChange}
                        onSliderChange={this.handleSliderChange}
                        opportunityList={opportunityList}
                        iprosList={iprosList}
                        Currencies={this.state.Currencies}
                        handleListOpenMobile={this.handleListOpenMobile}
                      />
                    </div>
                  </div>
                </Col>

                <Col md={8} xs={0} offset={0.5} className="h-100">
                  <div className=" resume-list h-100" style={{ marginLeft: "16px", background: "#fff", borderRadius: "12px" }}>
                    {isEmpty(selectedIpro) ? (
                      <EmptyInfo>{labels.EMPTY_IPRO_DETAIL}</EmptyInfo>
                    ) : (
                      <NewUserDetail selectedUser={selectedIpro} labels={labels} />
                    )}
                  </div>
                </Col>
              </Row>
            </>
          }
        </div>
      </PageWrapper>
    );
  }
}

const mapStateToProps = ({ systemLabel, navigation, createCollaboration }) => {
  const { labels } = systemLabel;
  const { isHelpActive } = navigation;
  return { labels, isHelpActive, ...createCollaboration };
};

export default connect(mapStateToProps, {
  notificationAction,
  onStateChangeAction,
})(withRouter(NewCreateCollaboration));
