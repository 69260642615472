import { Input, Modal, Space } from "antd";
import React from "react";
import "../../views/NewResumeSearch/search.scss";
import { useState } from "react";

const UpdateTitleModal = ({ open, setOpen, handleUpdateTitle }) => {
  const [error, setError] = useState(false);

  return (
    <>
      <Modal
        title={`Update ${open?.title || ""}`}
        zIndex={105}
        className="shortlist-modal "
        open={open?.open}
        onCancel={() => {
          setOpen({ open: false });
        }}
        footer={
          <Space size={[12, 8]}>
            <button
              className="create-button background"
              onClick={() => {
                if (open?.value == "") {
                  setError(true);
                  return;
                } else {
                  handleUpdateTitle();
                }
              }}
            >
              Update
            </button>
          </Space>
        }
      >
        <label>{`Do you want to give the title of your latest auto generated ${open?.title ||
          ""}?`}</label>
        <Input
          placeholder="Enter value to update"
          value={open?.value || ""}
          autoFocus={true}
          onChange={(e) => {
            e.persist();
            setOpen((St) => ({ ...St, value: e.target.value }));
            setError(false);
          }}
          style={
            error
              ? { marginTop: "8px", border: "1px solid red" }
              : { marginTop: "8px" }
          }
        />
      </Modal>
    </>
  );
};
export default UpdateTitleModal;
