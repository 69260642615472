import React from "react";

function BtnComponent(props) {
  return (
    <div>
      {props.status === 0 ? (
        <div className="btnContainer">
          <button className="timerBtns btn-gre autoPadding" onClick={props.start}>
            Start
          </button>
        </div>
      ) : (
        ""
      )}

      {props.status === 1 ? (
        <div className="btnContainer">
          <button className="timerBtns btn-gre" onClick={props.pause}>
            Pause
          </button>
          <button className="timerBtns btn-red" onClick={props.stop}>
            Stop
          </button>
        </div>
      ) : (
        ""
      )}

      {props.status === 2 ? (
        <div className="btnContainer">
          <button className="timerBtns btn-gre" onClick={props.resume}>
            Resume
          </button>
          <button className="timerBtns btn-red" onClick={props.stop}>
            Stop
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default BtnComponent;
